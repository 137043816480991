import _ from "lodash"
import { MESSAGES } from "../actions/types/messagesTypes"

const initialState = {
  chatList: [],
  currentChat: null,
  chatSearchList: null,
}

export const messageReducer = (state = initialState, action) => {
    const {payload} = action;

    switch (action.type) {
        case MESSAGES.CHATS.LIST: {
            return {
                ...state,
                chatList: state.chatList ? [...state.chatList, ...payload] : payload,
            };
        }
        case MESSAGES.LIST: {
            return {
                ...state,
                currentChat: state.currentChat ? {
                    chat_id: payload.chat_id,
                    messages: [...state.currentChat.messages, ...payload.messages],
                } : payload,
            };
        }
        case MESSAGES.CREATE: {
            return {
                ...state,
                /*currentChat: {...state.currentChat, messages: [...state.currentChat.messages, payload]}*/
            };
        }
        case MESSAGES.UPDATE: {
            return {
                ...state,
                currentChat: {...state.currentChat, messages: _.uniqBy([...state.currentChat.messages, payload], "id")},
            };
        }
        case MESSAGES.CHATS.CLEAR: {
            return {
                ...state,
                chatList: [],
                chatSearchList: null,
            };
        }
        case MESSAGES.CHATS.UPDATE: {
            return {
                ...state,
                chatList: _.uniqBy([payload, ...state.chatList], (chat) => chat.id),
            };
        }
        case MESSAGES.SEARCH: {
            return {
                ...state,
                chatSearchList: state.chatSearchList ? {
                    chats: state.chatSearchList && !payload.chats.length ? state.chatSearchList.chats : payload.chats,
                    messages: state.chatSearchList.messages ? [...state.chatSearchList.messages, ...payload.messages] : payload.messages,
                } : payload,
            };
        }
        case MESSAGES.CLEAR:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};
