import React from "react"
import { useCurrentUser } from "../../../../../../hooks/data/user/useUser"
import { KNOWMAP } from "../../../../../../library/store/actions/types/knowmapTypes"
import GridListPage from "../../../../../../components/v2/layout/superListPage/GridListPage"
import BookmarksProvider from "../../../../../../hooks/modals/useBookmarksModal"
import { BOOKMARKS_KNOWCRED } from "../../../../../../library/constants/routes"
import { mutationTypes } from "../../../../../../library/utils/reactQuery"
import ComplaintsProvider from "../../../../../../hooks/modals/useComplaintsModal"
import CredCard from "../../../../../../components/v2/layout/cards/CredCard"

const TeacherTrackingCoursesCreds = () => {
  const { id } = useCurrentUser()
  const [courseId, setCourseId] = React.useState()

  const queryKey = [KNOWMAP.KNOWCRED.TEACHER_LIST]

  return (
    <GridListPage
      queryConfig={{
        // key: [
        //   {
        //     type: "learner-course-creds-tracking",
        //     url: KNOWCREDS.LIST,
        //     fetchParams: { user_id: id },
        //   },
        // ],
        // url: KNOWCREDS.LIST,
        key: queryKey,
        limit: 30,
        searchByNameParam: "title",
        // searchParams: filters.map((f) => ({
        //   param: f.searchParam,
        //   transformer: (p) =>
        //     f.acceptOne ? parseInt(p, 10) : !Array.isArray(p) ? [p] : p,
        // })),
        dataKey: "knowcreds",
      }}
      headerProps={{ title: "Creds" }}
      gridClassName={"grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5"}
      getListItemComponent={({ item }) => (
        <BookmarksProvider
          key={item.id}
          keyToUpdate={queryKey}
          params={{ knowcred_id: item.id }}
          pathToBookmarks={BOOKMARKS_KNOWCRED}
          mutationType={mutationTypes.infinite}
        >
          <ComplaintsProvider
            entityUpdater={(c) =>
              c.id === item.id
                ? {
                    ...c,
                    is_reported: true,
                  }
                : c
            }
            keyToUpdate={queryKey}
            params={{ knowcred_id: item.id }}
            mutationType={mutationTypes.infinite}
          >
            <CredCard cred={item} queryKey={queryKey} showOwner />
          </ComplaintsProvider>
        </BookmarksProvider>
      )}
    />
  )
}

export default TeacherTrackingCoursesCreds
