import { usePostCtx } from "../post/PostContext"
import Comment from "./comment"
import KeyboardArrowDownOutlined from "@material-ui/icons/KeyboardArrowDownOutlined"
import React from "react"
import CommentCreate from "./commentCreate"
import { PlainButton } from "../../controls"
import CommentProvider from "./CommentContext"
import { useCommentsList } from "../../../../hooks/data/feed/comments"
import Loader from "../../../ui/loader"

const CommentsList = () => {
  const { post, queryKey } = usePostCtx()

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
    isFetchedAfterMount,
  } = useCommentsList({
    postId: post.id,
    queryKey,
    options: {
      enabled: false,
    },
  })

  const onShowAll = async () => {
    await fetchNextPage()
  }

  return (
    <div className="post-replies">
      {post.comments.map((comment, index) => (
        <CommentProvider comment={comment} key={comment.id}>
          <Comment index={index} />
        </CommentProvider>
      ))}

      {((!isFetchedAfterMount && post.comments_number > 3) || hasNextPage) &&
        !isFetchingNextPage && (
          <div className="full-width f jcc mt10">
            <PlainButton onClick={onShowAll}>
              <span>Show more comments</span>
              <KeyboardArrowDownOutlined />
            </PlainButton>
          </div>
        )}

      {isFetchingNextPage && <Loader />}

      <CommentCreate />
    </div>
  )
}

export default CommentsList
