import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { withSnackbar } from "notistack"
import styled from "@emotion/styled"
import Dialog from "@material-ui/core/Dialog/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import Button from "@material-ui/core/Button"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup"

import { complaintsTypes } from "../../library/constants/complaintsTypes"
import { call } from "../../library/networking/API"
import { COMPLAINTS } from "../../library/networking/apiEndpoints"
import getErrorText from "../../library/constants/errorTypes"
import { colors } from "../../library/constants/styles/colors"

class ComplaintsModal extends Component {
  state = {
    type: "1",
  }
  handleClose = () => {
    this.props.onClose()
  }
  proceedComplaintsCreate = async () => {
    const {
      user_id,
      group_id,
      post_id,
      comment_id,
      message_id,
      product_id,
      knowcred_id,
      knowmix_id,
      callback,
    } = this.props
    const type = Number(this.state.type)
    const token = this.props.auth.userData.token
    try {
      const response = await call(COMPLAINTS.CREATE, {
        token,
        type,
        user_id,
        group_id,
        post_id,
        comment_id,
        message_id,
        product_id,
        knowcred_id,
        knowmix_id,
      })
      if (response.status === 200) {
        this.props.enqueueSnackbar(
          "Your report has been sent and will be reviewed as soon as possible",
          { variant: "success" }
        )
        callback && callback()
        this.handleClose()
      } else {
        const { code } = response.error
        this.props.enqueueSnackbar(getErrorText(code), { variant: "error" })
      }
    } catch ({ error }) {
      error &&
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
    }
  }
  handleTypeChange = (event) => {
    this.setState({ type: event.target.value })
  }

  render() {
    return (
      <Dialog
        aria-labelledby="create-product-dialog"
        onClose={this.handleClose}
        open={this.props.open}
        onExited={this.onExited}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title" className="text-center">
          What's going on?
        </DialogTitle>
        <DialogContent>
          <Container>
            <LabelContainer className="text-center">Reason *</LabelContainer>
            <form>
              <RadioGroup
                aria-label="Type"
                name="type"
                value={this.state.type}
                onChange={this.handleTypeChange}
              >
                {Object.keys(complaintsTypes).map((key) => {
                  return (
                    <CustomFormLabel
                      key={key}
                      value={String(key)}
                      control={<Radio />}
                      labelPlacement="end"
                      checked={String(key) === this.state.type}
                      onChange={this.handleTypeChange}
                      label={
                        <div>
                          <p className="m-radio-button__title">
                            {complaintsTypes[key]}
                          </p>
                        </div>
                      }
                    />
                  )
                })}
              </RadioGroup>
            </form>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            onClick={this.proceedComplaintsCreate}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const CustomFormLabel = styled(FormControlLabel)`
    span {
        padding: 3px !important;
    }
`;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
`;

const LabelContainer = styled.div`
    width: 35%;
    padding-top: 10px;
    color: ${colors.black38}
`;

const mapState = ({auth}) => ({auth});
const mapDispatch = dispatch => bindActionCreators({}, dispatch);

export default connect(mapState, mapDispatch)(withSnackbar(ComplaintsModal));
