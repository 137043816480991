import React from "react"
import styled from "@emotion/styled"
import { colors } from "../../library/constants/styles/colors"
import SelectedMeetData from "./selectedMeetData"
import Button from "@material-ui/core/Button"
import { ChevronLeftOutlined } from "@material-ui/icons"
import { useBookMeet } from "./bookMeetContext"
import * as Yup from "yup"
import { Formik } from "formik"
import Form from "../../components/v2/layout/forms/form/form"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import { useSelector } from "react-redux"
import { APPOINTMENTS } from "../../library/store/actions/types/calendarTypes"
import { call } from "../../library/networking/API"
import { getUtcFromZonedDateTime } from "../../library/utils/dateConvert"
import { phoneRegEx } from "../../library/utils/validators"
import OuterInviterContactLinkPicker from "./component/OuterInviterContactLinkPicker"
import InnerInviterContactLinkPicker from "./component/InnerInviterContactLinkPicker"
import { pick } from "lodash"
import { linkProviderNames } from "../../components/v2/inputs/pickers/ContactLinkPicker"
import UserPicker from "../../components/v2/inputs/pickers/userPicker"
import { FRIENDS } from "../../library/networking/apiEndpoints"
import { PlainButton } from "../../components/v2/controls"
import OutsiderInviteeFormModal from "./component/OutsiderInviteeFormModal"
import { useAlert } from "../../hooks/useAlert"

const Container = styled.div`
  display: grid;
  grid-template-columns: 25% 75%;
  width: 100%;

  & > div {
    padding: 15px;
    &:not(:last-of-type) {
      border-right: 1px solid ${colors.black12};
    }
  }
`

const validationSchema = Yup.object({
  title: Yup.string().max(200).required(),
  location: Yup.string().max(200),
  details: Yup.string().max(2000),
  conference_link: Yup.string().url(),
  email: Yup.string().email().required(),
  first_name: Yup.string().max(200).required(),
  last_name: Yup.string().max(200).required(),
  phone: Yup.string().max(50).matches(phoneRegEx, "Invalid phone format"),
})

const BookMeetStep4 = () => {
  const { errorAlert } = useAlert()
  const { isLoggedIn, userData } = useSelector(({ auth }) => auth)

  const [activeOutsiderInvitee, setActiveOutsiderInvitee] = React.useState({
    data: null,
    idx: null,
  })
  const [isOutsiderInviteeFormOpen, setIsOutsiderInviteeFormOpen] =
    React.useState(false)

  const {
    data,
    setData,
    setStep,
    user,
    selectedTimezone,
    onClose,
    setSavedLinkType,
    savedLinkType,
    friendInvitees,
    setFriendInvitees,
    outsiderInvitees,
    setOutsiderInvitees,
  } = useBookMeet()

  const initialValues = {
    title: data.title || "",
    location: data.location || "",
    details: data.details || "",
    conference_link: data.conference_link || "",
    email: data.email || "",
    first_name: data.first_name || "",
    last_name: data.last_name || "",
    phone: data.phone || "",
    outsider_invitees: data.outsider_invitees || [],
  }

  const onSubmit = async (values) => {
    const {
      title,
      location,
      details,
      conference_link,
      email,
      first_name,
      last_name,
      phone,
    } = values

    const body = {
      creator_id: userData?.id || 0,
      member_id: user?.id,
      hold_ids_member: data?.holds.map((h) => h.id),
      hold_ids: [],
      duration: data?.type,
      datetime: getUtcFromZonedDateTime(
        data.date.date,
        data.time.start,
        "America/Chicago"
      ).toISOString(),
      time_zone: selectedTimezone.id,
      title,
      location,
      details,
      conference_link,
      email: email,
      first_name: first_name,
      last_name: last_name,
      phone,
      invitees: [
        ...friendInvitees.map((u) => ({ id: u.id })),
        ...outsiderInvitees,
      ],
    }
    setData((prevState) => ({ ...prevState, ...values }))

    try {
      const response = await call(APPOINTMENTS.CREATE, body)

      if (response.status === 200) {
        setStep(5)
      }
    } catch (e) {
      errorAlert(e)
    }
  }

  const inviteeContactLinks = pick(user, Object.keys(linkProviderNames))
  const inviteeHasLinks = Object.values(inviteeContactLinks).some(Boolean)

  const onSaveOutsiderInvitee = (value) => {
    if (activeOutsiderInvitee.idx !== null) {
      setOutsiderInvitees((prevState) => {
        const newState = [...prevState]
        newState[activeOutsiderInvitee.idx] = value
        return newState
      })
      setActiveOutsiderInvitee({ data: null, idx: null })
    } else {
      setOutsiderInvitees((prevState) => [...prevState, value])
    }
  }

  const onDeleteOutsiderInvitee = (idx) => {
    setOutsiderInvitees((prevState) => {
      const newState = [...prevState]
      newState.splice(idx, 1)
      return newState
    })
  }

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={true}
      >
        {(fk) => {
          return (
            <>
              <div className={"f fdc aifs jcsb"}>
                <SelectedMeetData />
                <Button
                  variant={"text"}
                  color={"primary"}
                  startIcon={<ChevronLeftOutlined />}
                  onClick={() => {
                    setData((prevState) => ({ ...prevState, ...fk.values }))
                    setStep(3)
                  }}
                >
                  Back
                </Button>
              </div>

              <div className="f fdc aic">
                <div>
                  <DialogTitle className={"text-center pt0"}>
                    Enter Details
                  </DialogTitle>

                  <Form isModal>
                    <Form.Row label={"Title"} name={"title"}>
                      <Form.TextField
                        name={"title"}
                        placeholder={"Type meet title"}
                      />
                    </Form.Row>
                    <Form.Row
                      label={"Physical Location (optional)"}
                      name={"location"}
                    >
                      <Form.TextField
                        name={"location"}
                        placeholder={"Type meet physical location"}
                      />
                    </Form.Row>
                    <Form.Row label={"Message"} name={"details"}>
                      <Form.TextField
                        name={"details"}
                        placeholder={"Type invitation message"}
                        multiline
                      />
                    </Form.Row>
                    <Form.Row
                      label={"Conference link"}
                      name={"conference_link"}
                    >
                      {isLoggedIn ? (
                        <InnerInviterContactLinkPicker
                          value={fk.values.conference_link}
                          onChange={fk.handleChange("conference_link")}
                          leftLabel={false}
                          allowCustomLink={isLoggedIn}
                          fallbackToCustomLink={!inviteeHasLinks}
                          additionalLinks={inviteeContactLinks}
                          setSavedLinkType={setSavedLinkType}
                          savedLinkType={savedLinkType}
                          inviteeName={user.first_name}
                          error={fk.errors.conference_link}
                        />
                      ) : (
                        <OuterInviterContactLinkPicker
                          value={fk.values.conference_link}
                          onChange={fk.handleChange("conference_link")}
                          leftLabel={false}
                          inviteeLinks={inviteeContactLinks}
                          inviteeName={user.first_name}
                          error={fk.errors.conference_link}
                        />
                      )}
                    </Form.Row>
                    <Form.Row label={"Email"} name={"email"}>
                      <Form.TextField
                        name={"email"}
                        placeholder={"Type your email"}
                      />
                    </Form.Row>
                    <Form.Row label={"Phone"} name={"phone"}>
                      <Form.TextField
                        name={"phone"}
                        placeholder={"Type your phone"}
                      />
                    </Form.Row>
                    <Form.Row label={"First name"} name={"first_name"}>
                      <Form.TextField
                        name={"first_name"}
                        placeholder={"Type your first name"}
                      />
                    </Form.Row>
                    <Form.Row label={"Last name"} name={"last_name"}>
                      <Form.TextField
                        name={"last_name"}
                        placeholder={"Type your last name"}
                      />
                    </Form.Row>
                    {isLoggedIn && (
                      <UserPicker
                        multiple
                        value={friendInvitees}
                        onChange={setFriendInvitees}
                        getDisabledValue={(option) => option.id === user.id}
                        requestConfig={{
                          fetchConfig: {
                            apiUrl: FRIENDS.LIST,
                            params: {
                              user_id: userData?.id,
                            },
                          },
                          key: "users",
                        }}
                        fieldName={"users"}
                        userLabel={"Invited friends"}
                      />
                    )}
                    <Form.Row label={"Invited Non-Members"}>
                      <div className="form__fieldbox">
                        <div className="form__input form__input--select">
                          {outsiderInvitees.map((u, index) => (
                            <div key={index} className={"mb15 f aifs"}>
                              <div className="f1">
                                <p>
                                  {u.first_name} {u.last_name}
                                </p>
                                <p className={"color-black-54 font-13"}>
                                  {u.email}
                                </p>
                              </div>
                              <div className="f jcfe f1">
                                <PlainButton
                                  onClick={() => {
                                    setActiveOutsiderInvitee({
                                      data: u,
                                      idx: index,
                                    })
                                    setIsOutsiderInviteeFormOpen(true)
                                  }}
                                  actionType={"edit"}
                                  className={"mr10"}
                                />
                                <PlainButton
                                  onClick={() => onDeleteOutsiderInvitee(index)}
                                  actionType={"delete"}
                                />
                              </div>
                            </div>
                          ))}
                          <PlainButton
                            onClick={() => setIsOutsiderInviteeFormOpen(true)}
                            actionType={"add"}
                          />
                        </div>
                      </div>
                    </Form.Row>
                  </Form>
                </div>
                <div className={"f jcfe full-width"}>
                  <Button color="primary" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button color="primary" onClick={() => fk.handleSubmit()}>
                    Next
                  </Button>
                </div>
              </div>
            </>
          )
        }}
      </Formik>
      {isOutsiderInviteeFormOpen && (
        <OutsiderInviteeFormModal
          invitee={activeOutsiderInvitee.data}
          onSave={onSaveOutsiderInvitee}
          isOpen={isOutsiderInviteeFormOpen}
          onClose={() => setIsOutsiderInviteeFormOpen(false)}
        />
      )}
    </Container>
  )
}

export default BookMeetStep4
