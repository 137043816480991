import React from "react"
import BoxPage from "../../../../../components/v2/layout/boxPage/boxPage"
import { useCurrentUser } from "../../../../../hooks/data/user/useUser"
import { useSelector } from "react-redux"
import Form, {
  Submit,
} from "../../../../../components/v2/layout/forms/form/form"
import FormCheckbox from "../../../../../components/formComponents/formCheckbox"
import Checkbox from "@material-ui/core/Checkbox/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import { notificationTypes } from "../../../../../library/constants/notitficationTypes"
import Loader from "../../../../../components/ui/loader"
import { useAlert } from "../../../../../hooks/useAlert"
import Button from "@material-ui/core/Button"
import { createTheme } from "@material-ui/core/styles"
import theme from "../../../../../library/m-ui-theme"
import { ThemeProvider } from "@material-ui/styles"
import useCreator from "../../../../../hooks/useCreator"
import {
  getData,
  updateInfo,
} from "../../../../../library/store/actions/creators/authCreators"

const buttonTheme = createTheme({
  ...theme,
  palette: {
    secondary: {
      main: "#f44336",
    },
  },
})

const ProfileSettingsNotifications = () => {
  const { errorAlert, successAlert } = useAlert()
  const creator = useCreator()
  const { userData } = useSelector(({ auth }) => auth)

  const [userNotificationTypes, setUserNotificationTypes] = React.useState([])

  const [dataReceived, setDataReceived] = React.useState(false)

  React.useEffect(() => {
    const prepareNotificationTypes = () =>
      Object.entries(notificationTypes).map(([key, value]) => {
        return {
          value: key,
          label: value,
          // checked: ![2, 9, 10].includes(Number(key)),
          checked: !userData.ignored_email_types.includes(Number(key)),
        }
      })

    setUserNotificationTypes(prepareNotificationTypes())
    setDataReceived(true)
  }, [])

  const onChange = (notificationType, isChecked) => {
    setUserNotificationTypes((prevState) => {
      return prevState.map((type) =>
        type.value === notificationType.value
          ? { ...type, checked: isChecked }
          : type
      )
    })

    console.log(Object.keys(notificationTypes).map((t) => Number(t)))
  }

  const onSubmit = async () => {
    try {
      console.log(
        userNotificationTypes
          .filter(({ checked }) => !checked)
          .map(({ value }) => Number(value))
      )
      await creator(
        updateInfo({
          ignored_email_types: userNotificationTypes
            .filter(({ checked }) => !checked)
            .map(({ value }) => Number(value)),
        })
      )
      successAlert("You have been unsubscribed from all notifications")
      await creator(getData())
    } catch (e) {
      errorAlert(e)
    }
  }

  const onUnsubscribeFromAll = async () => {
    setUserNotificationTypes((prevState) => {
      return prevState.map((type) => {
        return { ...type, checked: false }
      })
    })

    try {
      await creator(
        updateInfo({
          ignored_email_types: Object.keys(notificationTypes).map((t) =>
            Number(t)
          ),
        })
      )
      successAlert("You have been unsubscribed from all notifications")
      await creator(getData())
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <BoxPage title={"Notifications"}>
      <h3>Email Notifications</h3>
      {dataReceived ? (
        <>
          {userNotificationTypes.map((notificationType, index) => (
            <div key={notificationType.value}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationType.checked}
                    value={notificationType.value}
                    disableRipple
                    onChange={(e) =>
                      onChange(notificationType, e.target.checked)
                    }
                  />
                }
                label={notificationType.label}
              />
            </div>
          ))}
          <div className="w-full flex justify-center my-4">
            <Submit onClick={onSubmit} color={"primary"} variant={"contained"}>
              Save
            </Submit>
          </div>
          <hr className={"border-divider mb-2"} />
          <div>
            <ThemeProvider theme={buttonTheme}>
              <Button
                color={"secondary"}
                variant={"outlined"}
                onClick={onUnsubscribeFromAll}
              >
                Unsubscribe from all
              </Button>
            </ThemeProvider>
            <p className={"text-black54 mt-2"}>
              * Account recovery may still send you email if you forget your
              password and reset requires an email be sent for you to confirm.​
            </p>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </BoxPage>
  )
}

export default ProfileSettingsNotifications
