import React, { Component } from "react"
import "./productTestimonials.scss"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"

class ProductTestimonials extends Component {
  state = {
    reviewsList: this.props.products.currentProduct.info_reviews.slice(0, 3),
    reviewsListFull: this.props.products.currentProduct.info_reviews,
    reviewsListShort: this.props.products.currentProduct.info_reviews.slice(
      0,
      3
    ),
    showAll: this.props.products.currentProduct.info_reviews.length > 3,
    showLess: false,
  }

  showAll = () => {
    const { reviewsListFull } = this.state

    this.setState({
      reviewsList: reviewsListFull,
      showAll: false,
      showLess: true,
    })
  }

  showLess = () => {
    const { reviewsListShort } = this.state

    this.setState({
      reviewsList: reviewsListShort,
      showAll: true,
      showLess: false,
    })
  }

  render() {
    const { reviewsList, showAll, showLess } = this.state

    return (
      <div className="box product-testimonials">
        <div className="box__content">
          <p className="box__subheading">Editorial reviews</p>
          <div className="product-testimonials">
            {reviewsList.map((item) => (
              <div className="product-testimonials__record" key={item.id}>
                <p className="product-testimonials__author-name">
                  - {item.author}
                </p>
                <pre className="product-testimonials__testimonial">
                  {item.message}
                </pre>
              </div>
            ))}
          </div>
          {showAll && (
            <button
              className="btn btn--plain flex-centered-x"
              onClick={this.showAll}
            >
              <span className="mr8">Show all reviews</span>
              <KeyboardArrowDown />
            </button>
          )}
          {showLess && (
            <button
              className="btn btn--plain flex-centered-x"
              onClick={this.showLess}
            >
              <span className="mr8">Show less reviews</span>
              <KeyboardArrowUp />
            </button>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, products }) => ({ auth, products })
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ProductTestimonials))
