import { useEffect, useState } from "react"
import { call } from "../library/networking/API"

const useEntityPicker = ({ fetchConfig, key, loadOnMount = true }) => {
  const [isWaitingForOtherFields, setIsWaitingForOtherFields] = useState(
    !loadOnMount
  )
  const [dataReceived, setDataReceived] = useState(false)
  const [list, setList] = useState([])

  const fetchList = async () => {
    const response = await call(fetchConfig.apiUrl, fetchConfig.params)
    const data = (await response.data?.[key]) || []

    setList(data)
    setDataReceived(true)
    setIsWaitingForOtherFields(false)
  }

  useEffect(() => {
    if (loadOnMount) {
      fetchList()
    }
  }, [])

  return {
    list,
    dataReceived,
    isWaitingForOtherFields,
    fetchList,
  }
}

export default useEntityPicker
