import React from "react"
import CameraAltOutlined from "@material-ui/icons/CameraAltOutlined"
import { useToggle } from "../../hooks/useToggle"
import bgDefault from "../../assets/img/bg-default.png"
import { imageAspectRatios } from "../../library/constants/images"
import ImagePickerModal from "../imagePickerModal/imagePickerModal"
import { clsx } from "clsx"

const EntityPhotoPicker = ({
  photo,
  defaultPhoto,
  aspectRatio,
  buttonLabel = "Edit photo",
  buttonClassName,
  onSave,
}) => {
  const [open, toggle] = useToggle(false)

  return (
    <>
      <button
        className={clsx(
          "absolute flex items-center overflow-hidden py-2 pl-2 pr-1 radius-sm text-primary bg-white/70 leading-none group hover:pr-2 duration-200 transition-all",
          buttonClassName
        )}
        onClick={toggle}
      >
        <CameraAltOutlined />
        <span className="w-0 ml-1 opacity-0 nowrap group-hover:w-full group-hover:opacity-100">
          {buttonLabel}
        </span>
      </button>
      {open && (
        <ImagePickerModal
          // dimensions={{width: 50, x: 20, y: 20}}
          originImage={photo || defaultPhoto}
          defaultImage={defaultPhoto}
          open={open}
          onSave={onSave}
          onClose={toggle}
          aspectRatio={aspectRatio || imageAspectRatios.square}
        />
      )}
    </>
  )
}

export default EntityPhotoPicker
