import React from "react"
import SubjectFormModal from "./components/subjectFormModal/subjectFormModal"
import {
  clearScheduleSubjectsList,
  createScheduleSubject,
  deleteScheduleSubject,
  getScheduleSubjectsList,
  updateScheduleSubject,
} from "../../../../../../../library/store/actions/creators/calendarCreators"
import { useDispatch, useSelector } from "react-redux"
import SubjectsListItem from "./components/subjectsListItem"
import GroupSettingsCalendarProvider from "../GroupSettingsCalendarContext"
import ListPage from "../../../../../../../components/v2/layout/listPage/listPage"
import useCreator from "../../../../../../../hooks/useCreator"

const GroupSettingsCalendarSubjects = () => {
  const dispatch = useDispatch()
  const creator = useCreator()

  const { currentCalendar, subjectsList, subjectsListEndReached } = useSelector(
    ({ calendar }) => calendar
  )
  const { currentGroup } = useSelector(({ groups }) => groups)

  const onCreateSubject = async (data) =>
    await creator(createScheduleSubject(data))
  const onEditSubject = async (data) =>
    await creator(updateScheduleSubject(data))
  const onDeleteSubject = async (id) =>
    await creator(deleteScheduleSubject({ id }))

  const isSchool = [4, 7].includes(currentGroup?.type)
  const isDistrict = currentGroup?.type === 5
  const isIndependentSchedule =
    isSchool && !currentCalendar.has_district_calendar
  const canCreate = isDistrict || isIndependentSchedule

  const calendarId =
    isIndependentSchedule || isDistrict
      ? currentCalendar?.id
      : currentCalendar?.calendar_id

  return (
    <ListPage
      title={"Subjects"}
      creationButtonTitle={"Create subject"}
      hasButton={canCreate}
      noResultsLabel={"This Calendar doesn't have subjects yet"}
      getList={async ({ name, offset }) =>
        await dispatch(
          getScheduleSubjectsList({
            [isIndependentSchedule ? "schedule_id" : "calendar_id"]: calendarId,
            name,
            offset,
          })
        )
      }
      clearList={() => dispatch(clearScheduleSubjectsList())}
      list={subjectsList}
      listEndReached={subjectsListEndReached}
      getListItemComponent={({ item, openEditDialog, openDeletionDialog }) => (
        <GroupSettingsCalendarProvider key={item.id}>
          {({ onSubjectDialogOpen }) => (
            <SubjectsListItem
              subjectInfo={item}
              openDeleteSubject={openDeletionDialog}
              openEditSubject={openEditDialog}
              openSubjectDetails={() => onSubjectDialogOpen(item.id)}
              isStatic={!canCreate}
              groupType={currentGroup?.type}
            />
          )}
        </GroupSettingsCalendarProvider>
      )}
      getFormDialogComponent={({ open, onClose, activeItem }) => {
        return (
          <SubjectFormModal
            open={open}
            handleClose={onClose}
            onSubmit={!activeItem ? onCreateSubject : onEditSubject}
            activeSubject={activeItem}
            edit={!!activeItem}
            isSchool={isSchool}
          />
        )
      }}
      getDeletionDialogComponent={{
        entityName: "subject",
        onDelete: onDeleteSubject,
      }}
      // getInfoDialogComponent={({ open, onClose, activeItem }) => (
      //   <SubjectDialog
      //     open={open}
      //     onClose={onClose}
      //     activeSubject={activeItem}
      //     onCalendarDialogOpen={this.onCalendarDialogOpen}
      //   />
      // )}
    />
  )
}

export default GroupSettingsCalendarSubjects
