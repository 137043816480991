import "react-app-polyfill/ie9"
import "react-app-polyfill/stable"

import "./library/services/errorService/errorReporter"
import "./yupConfig"

import React from "react"
import ReactDOM from "react-dom"
import "./index.scss"
import AppRouter from "./screens/AppRouter"
import Provider from "react-redux/es/components/Provider"
import { persistor, store } from "./library/store/configureStore"
import { PersistGate } from "redux-persist/integration/react"
import { Global } from "@emotion/react"
import globalStyle from "./theme/globalStyle"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"

import { queryFn } from "./library/queryFn"
;(async () => {
  if (!("IntersectionObserver" in window)) await import("intersection-observer")
})()

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      queryFn,
    },
  },
})

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <QueryClientProvider client={queryClient}>
        <Global styles={globalStyle} />
        <AppRouter />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
)
