import { keyframes } from "@emotion/react"

export const blinkAnimation = keyframes`
  0% {
    background-position: -150% 0;
  }

  50% {
    background-position: 150% 0;
  }

  100% {
    background-position: -150% 0;
  }
`
