import React from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  acceptMeet,
  declineMeet,
  forwardMeet,
  incomingMeetsList,
  removeMeet,
} from "../../../../../library/store/actions/creators/uknowCreators"
import { Route, useHistory, useLocation, useRouteMatch } from "react-router-dom"
import MeetModalsProvider, {
  useMeetModals,
} from "../../../../../hooks/entities/useMeetsModals"
import UpdateIcon from "@material-ui/icons/UpdateOutlined"
import {
  KNOWMAP_INCOMING_KNOWMEET,
  KNOWMAP_INCOMING_MEET,
  KNOWMAP_INCOMING_MEETS,
} from "../../../../../library/constants/routes"
import MeetDialog from "./MeetDialog"
import { useAlert } from "../../../../../hooks/useAlert"
import CheckOutlined from "@material-ui/icons/CheckOutlined"
import CloseOutlined from "@material-ui/icons/CloseOutlined"
import MergeIcon from "@material-ui/icons/MergeTypeOutlined"
import HideIcon from "@material-ui/icons/VisibilityOffOutlined"
import BoxPage from "../../../../../components/v2/layout/boxPage/boxPage"
import DataTable from "../../../../../components/v2/dataTable/components"

const columns = [
  { label: "Name", role: "name" },
  { label: "Target/Set Date", width: 100 },
  { label: "Creator", width: 120 },
  { label: "Instructions/Message", width: 250 },
  { label: "Location", width: 120 },
  { label: "Virtual Link", width: 130 },
  { label: "Manage", width: 130, role: "actions" },
]

const MeetsTable = ({ getRecords }) => {
  const history = useHistory()
  const { path } = useRouteMatch()

  const { successAlert, errorAlert } = useAlert()

  const dispatch = useDispatch()
  const { incomingMeets } = useSelector(({ uknow }) => uknow)

  const { onInfoOpen, onAssignHoldOpen } = useMeetModals()

  const onAccept = async (
    { public_meeting_id, public_meeting_participant_id },
    refetch
  ) => {
    try {
      await dispatch(
        acceptMeet({ public_meeting_id, public_meeting_participant_id })
      )

      successAlert("Meet accepted")

      if (path === KNOWMAP_INCOMING_KNOWMEET) {
        history.replace(KNOWMAP_INCOMING_MEETS)
      } else {
        await refetch()
      }
    } catch (e) {
      errorAlert(e)
    }
  }

  const onDecline = async ({ public_meeting_id }, refetch) => {
    try {
      await dispatch(declineMeet({ id: public_meeting_id }))

      successAlert("Meet declined")

      if (path === KNOWMAP_INCOMING_KNOWMEET) {
        history.replace(KNOWMAP_INCOMING_MEETS)
      } else {
        await refetch()
      }
    } catch (e) {
      errorAlert(e)
    }
  }

  const onForward = async ({ id }, refetch) => {
    try {
      await dispatch(forwardMeet({ id }))

      successAlert("You've been moved to the next cohort")

      if (path === KNOWMAP_INCOMING_KNOWMEET) {
        history.replace(KNOWMAP_INCOMING_MEETS)
      } else {
        await refetch()
      }
    } catch (e) {
      errorAlert(e)
    }
  }

  const onRemove = async ({ id }, refetch) => {
    try {
      await dispatch(removeMeet({ id }))

      successAlert("Record hidden")

      await refetch()
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <BoxPage title={"Incoming Meets"}>
      <DataTable
        columns={columns}
        data={incomingMeets.list}
        isSearchable
        actionsPinned
        isFirstColumnPinned
        customColumnsWidth
        onDetailsOpen={(record) => {
          // onInfoOpen(record.raw.public_meeting_id, "confirmable")
          onInfoOpen(record.raw.id, "confirmable")
        }}
        getData={async ({ name, offset, limit }) => {
          await dispatch(incomingMeetsList({ name, offset, limit }))
        }}
        availableOffsets={incomingMeets.availableOffsets}
        notFoundText={"You don't have incoming Meets yet"}
        actions={(record, refetch) =>
          [
            record.accepted &&
              !record.declined && {
                type: "button",
                label: () => (
                  <>
                    <UpdateIcon />
                    <span>Push forward</span>
                  </>
                ),
                onClick: () => {
                  onForward(
                    {
                      id: record.id,
                    },
                    refetch
                  )
                },
              },
            !record.accepted &&
              !record.declined && {
                type: "button",
                label: () => (
                  <>
                    <CheckOutlined />
                    <span>Accept</span>
                  </>
                ),
                onClick: () => {
                  onAccept(
                    {
                      public_meeting_id: record.public_meeting_id,
                      public_meeting_participant_id: record.id,
                    },
                    refetch
                  )
                },
              },
            // !record.accepted &&
            //   !record.declined && {
            //     type: "button",
            //     label: () => (
            //       <>
            //         <MergeIcon />
            //         <span>Assign Hold</span>
            //       </>
            //     ),
            //     onClick: () => {
            //       onAssignHoldOpen(record.public_meeting_id)
            //     },
            //   },
            !record.accepted &&
              !record.declined && {
                type: "button",
                label: () => (
                  <>
                    <CloseOutlined />
                    <span>Decline</span>
                  </>
                ),
                onClick: () => {
                  onDecline(
                    {
                      public_meeting_id: record.public_meeting_id,
                    },
                    refetch
                  )
                },
              },
            {
              type: "button",
              label: () => (
                <>
                  <HideIcon />
                  <span>Hide</span>
                </>
              ),
              onClick: () => {
                onRemove(
                  {
                    id: record.id,
                  },
                  refetch
                )
              },
            },
          ].filter(Boolean)
        }
      />

      <Route
        path={KNOWMAP_INCOMING_MEET}
        render={() => (
          <MeetDialog
            open
            handleClose={(replace = false) => {
              replace
                ? history.replace(KNOWMAP_INCOMING_MEETS)
                : history.push(KNOWMAP_INCOMING_MEETS)
            }}
            isConfirmable
          />
        )}
      />
    </BoxPage>
  )
}

const IncomingMeets = () => {
  const dispatch = useDispatch()
  const { errorAlert } = useAlert()

  const getRecords = async ({ name, offset, limit } = {}) => {
    try {
      await dispatch(incomingMeetsList({ name, offset, limit }))
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <MeetModalsProvider updateList={getRecords}>
      <MeetsTable />
    </MeetModalsProvider>
  )
}

export default IncomingMeets
