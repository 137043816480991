import React, { PureComponent } from "react"
import "./listItem.scss"
import { generatePath, Link, withRouter } from "react-router-dom"
import MoreDropdown from "../dropdowns/moreDropdown/moreDropdown"
import Button from "@material-ui/core/Button/Button"
import * as routes from "../../../library/constants/routes"
import { GROUP_TYPES } from "../../../library/constants/groupTypes"
import VerticalMenu from "../verticalMenu"
import DropdownControl from "../dropdowns/dropdownControl/dropdownControl"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import MenuList from "@material-ui/core/MenuList/MenuList"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Dialog from "@material-ui/core/Dialog/Dialog"
import { withStyles } from "@material-ui/core"
import { colors } from "../../../library/constants/styles/colors"
import getErrorText from "../../../library/constants/errorTypes"
import { bindActionCreators } from "redux"
import {
  createBookmark,
  deleteBookmark,
  foldersList,
} from "../../../library/store/actions/creators/bookmarksCreators"
import { groupInviteDecline } from "../../../library/store/actions/creators/groupsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import AddToFolderModal from "../../../screens/main/bookmarks/folders/addToFolder/addToFolder"
import LineSlice from "../lineSlice"
import ComplaintsModal from "../../../components/complaints/complaintsModal"
import { getGroupLogo } from "../../../library/utils/getGroupLogo"

const CustomMenuItem = withStyles({
  root: {
    color: colors.primary,
  },
})(MenuItem)

class ListItemGroup extends PureComponent {
  state = {
    renderCard: true,
    isAdmin: this.props.group.is_admin,
    isMember: this.props.group.is_member,
    isRequesting: this.props.group.is_requesting,
    isCreator: this.props.group.is_creator || undefined,
    noRelations: !!(
      !this.props.group.is_requesting &&
      !this.props.group.is_member &&
      !this.props.group.is_admin &&
      !this.props.group.is_invited
    ),
    isConnected:
      this.props.group.is_connected !== undefined
        ? this.props.group.is_connected
        : true,
    participantsNumber: this.props.group.participants_number,
    type: this.props.type,
    openDeleteModal: false,
    openBookmarksModal: false,
    bookmarked: !!this.props.group.bookmark,
    isInvited: this.props.group.is_invited,
    complaintsModal: false,
    isReported: this.props.group.is_reported || false,
  }

  showComplaintsModal = () => {
    this.setState({ complaintsModal: true })
  }
  hideComplaintsModal = () => {
    this.setState({ complaintsModal: false })
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }

  initiateGroupDelete = () => {
    this.setState({
      openDeleteModal: true,
    })
  }

  handleClickOpen = (type) => {
    if (type === 'deleteGroup') {
      this.setState({ openDeleteModal: true })
    } else {
      this.setState({ openBookmarksModal: true })
    }
  }

  handleClose = (type) => {
    if (type === 'deleteGroup') {
      this.setState({ openDeleteModal: false })
    } else {
      this.setState({ openBookmarksModal: false })
    }
  }

  addToBookmarks = async () => {
    const {
      group: { id },
    } = this.props

    try {
      await this.props.createBookmark({ group_id: id })
      await this.props.foldersList()
      this.setState({ bookmarked: true })
      await this.props.enqueueSnackbar('Saved to bookmarks', {
        autoHideDuration: 5000,
        action: (key) => {
          return (
            <>
              <Button
                color="primary"
                onClick={() => {
                  this.props.closeSnackbar(key)
                  this.props.history.push(routes.BOOKMARKS_GROUPS)
                }}
              >
                View
              </Button>
              {this.props.bookmarks.folders.length > 0 && (
                <Button
                  color="primary"
                  onClick={() => this.handleClickOpen('addToFolder')}
                >
                  Add to folder
                </Button>
              )}
            </>
          )
        },
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: 'error' })
    }
  }

  deleteBookmark = async () => {
    const {
      group: { id },
    } = this.props

    try {
      await this.props.deleteBookmark({ group_id: id })
      this.setState({ bookmarked: false })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: 'error' })
    }
  }

  processDeleteGroup = async () => {
    const { id } = this.props.group
    try {
      await this.props.deleteGroup(id)
      if (this.props.updateGroupsList) {
        await this.props.updateGroupsList()
      }
      await this.setStateAsync({
        openDeleteModal: false,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: 'error' })
    }
  }

  render() {
    const { renderCard } = this.state
    const { id, name, description, type, logo, closed, deleted, is_reported } =
      this.props.group

    const {
      isMember,
      isAdmin,
      isRequesting,
      isCreator,
      noRelations,
      isConnected,
      participantsNumber,
      bookmarked,
      isInvited,
    } = this.state

    const groupStats = `${GROUP_TYPES[type].title} (${
      closed ? 'closed' : 'open'
    } group), ${participantsNumber} members`
    const groupUrl = generatePath(routes.GROUP, { groupId: id })

    const dropdownControl = () => {
      const { currentGroupId } = this.props

      const buttonLabel = () => {
        if (this.props.type === 'settings-related') {
          if (isConnected) {
            return 'Related'
          }
        } else {
          if (isMember) {
            return 'Member'
          } else if (isRequesting || isInvited) {
            return 'Joining'
          } else {
            return null
          }
        }
      }
      switch (this.props.type) {
        case 'settings-related':
          return isConnected ? (
            <DropdownControl buttonLabel={buttonLabel()}>
              <MenuList>
                {isConnected ? (
                  <CustomMenuItem
                    onClick={() => {
                      this.props.groupDeleteConnection({
                        group_id: currentGroupId,
                        related_group_id: id,
                      })
                      this.setState({ isConnected: false })
                    }}
                  >
                    Remove from related
                  </CustomMenuItem>
                ) : null}
              </MenuList>
            </DropdownControl>
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                this.props.groupAddConnection({
                  group_id: currentGroupId,
                  related_group_id: id,
                })
                this.setState({ isConnected: true })
              }}
            >
              Add to related
            </Button>
          )

        case 'requests-pending':
          return !deleted ? (
            <Button
              color="primary"
              variant="contained"
              onClick={async () => {
                await this.props.joinGroup(id)
                await this.props.updateRequestList()
                this.setState({ renderCard: false })
              }}
            >
              Join group
            </Button>
          ) : null

        case 'requests-outgoing':
          return (
            <Button
              color="primary"
              variant="contained"
              onClick={async () => {
                await this.props.groupRequestsCancel(id)
                this.setState({ renderCard: false })
                await this.props.updateRequestList()
              }}
            >
              Cancel request
            </Button>
          )

        default:
          return noRelations ? (
            !deleted ? (
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  this.props.joinGroup(id)
                  if (closed) {
                    this.setState({ noRelations: false, isRequesting: true })
                  } else {
                    this.setState({
                      noRelations: false,
                      isMember: true,
                      participantsNumber: participantsNumber + 1,
                    })
                  }
                }}
              >
                Join group
              </Button>
            ) : null
          ) : (
            <DropdownControl buttonLabel={buttonLabel()}>
              <MenuList>
                {isMember && !isCreator ? (
                  <CustomMenuItem
                    onClick={() => {
                      this.props.leaveGroup(id)
                      this.setState({
                        isMember: false,
                        noRelations: true,
                        participantsNumber: participantsNumber - 1,
                      })
                    }}
                  >
                    Leave group
                  </CustomMenuItem>
                ) : null}
                {isCreator ? (
                  !deleted ? (
                    <CustomMenuItem
                      onClick={() => this.handleClickOpen('deleteGroup')}
                    >
                      Delete group
                    </CustomMenuItem>
                  ) : (
                    <CustomMenuItem
                      onClick={() => {
                        this.props.leaveGroup(id)
                        this.setState({
                          isMember: false,
                          noRelations: true,
                          participantsNumber: participantsNumber - 1,
                        })
                      }}
                    >
                      Leave group
                    </CustomMenuItem>
                  )
                ) : null}
                {isRequesting ? (
                  <CustomMenuItem
                    onClick={() => {
                      this.props.groupRequestsCancel(id)
                      this.setState({ isRequesting: false, noRelations: true })
                    }}
                  >
                    Cancel request
                  </CustomMenuItem>
                ) : null}
                {isInvited ? (
                  <CustomMenuItem
                    onClick={() => {
                      this.props.groupInviteDecline({
                        group_id: this.props.group.id,
                      })
                      this.setState({ isInvited: false, noRelations: true })
                    }}
                  >
                    Decline request
                  </CustomMenuItem>
                ) : null}
              </MenuList>
            </DropdownControl>
          )
      }
    }

    const dropdownMenuItems = () => {
      const groupTypes = [1, 2, 3, 4, 5]
      switch (this.props.type) {
        case 'all':
          return isCreator
            ? [
                !deleted && {
                  type: 'button',
                  action: () => {
                    this.props.history.push(
                      generatePath(routes.GROUP_SETTINGS, { groupId: id })
                    )
                  },
                  label: 'Edit group',
                },
                !bookmarked &&
                  !deleted && {
                    type: 'button',
                    action: this.addToBookmarks,
                    label: 'Add to bookmarks',
                  },
                bookmarked &&
                  !deleted && {
                    type: 'button',
                    action: this.deleteBookmark,
                    label: 'Remove from bookmarks',
                  },
                !deleted && {
                  type: 'button',
                  action: this.initiateGroupDelete,
                  label: 'Delete group',
                  disabled: type === 5,
                },
                deleted && {
                  type: 'button',
                  action: () => {
                    this.props.leaveGroup(id)
                    this.setState({ renderCard: false })
                  },
                  label: 'Leave the group',
                },
              ]
            : isAdmin
            ? [
                {
                  type: 'button',
                  action: this.showComplaintsModal,
                  label: 'Report',
                  disabled: this.state.isReported,
                },
                !bookmarked &&
                  !deleted && {
                    type: 'button',
                    action: this.addToBookmarks,
                    label: 'Add to bookmarks',
                  },
                bookmarked &&
                  !deleted && {
                    type: 'button',
                    action: this.deleteBookmark,
                    label: 'Remove from bookmarks',
                  },
                !deleted && {
                  type: 'button',
                  action: this.initiateGroupDelete,
                  label: 'Delete group',
                  disabled: type === 5,
                },
                {
                  type: 'button',
                  action: () => {
                    this.props.leaveGroup(id)
                    this.setState({ renderCard: false })
                  },
                  label: 'Leave the group',
                },
              ]
            : [
                {
                  type: 'button',
                  action: this.showComplaintsModal,
                  label: 'Report',
                  disabled: this.state.isReported,
                },
                !bookmarked &&
                  !deleted && {
                    type: 'button',
                    action: this.addToBookmarks,
                    label: 'Add to bookmarks',
                  },
                bookmarked &&
                  !deleted && {
                    type: 'button',
                    action: this.deleteBookmark,
                    label: 'Remove from bookmarks',
                  },
                {
                  type: 'button',
                  action: () => {
                    this.props.leaveGroup(id)
                    this.setState({ renderCard: false })
                  },
                  label: 'Leave the group',
                },
              ]

        case 'managed':
          return [
            !isCreator && {
              type: 'button',
              action: this.showComplaintsModal,
              label: 'Report',
              disabled: this.state.isReported,
            },
            !deleted && {
              type: 'button',
              action: () => {
                this.props.history.push(
                  generatePath(routes.GROUP_SETTINGS, { groupId: id })
                )
              },
              label: 'Edit group',
            },
            !bookmarked &&
              !deleted && {
                type: 'button',
                action: this.addToBookmarks,
                label: 'Add to bookmarks',
              },
            bookmarked &&
              !deleted && {
                type: 'button',
                action: this.deleteBookmark,
                label: 'Remove from bookmarks',
              },
            isCreator &&
              !deleted && {
                type: 'button',
                action: this.initiateGroupDelete,
                label: 'Delete group',
              },
            isCreator &&
              deleted && {
                type: 'button',
                action: () => {
                  this.props.leaveGroup(id)
                  this.setState({ renderCard: false })
                },
                label: 'Leave the group',
              },
          ]

        case 'requests-pending':
          return [
            {
              type: 'button',
              action: this.showComplaintsModal,
              label: 'Report',
              disabled: this.state.isReported,
            },
            !bookmarked &&
              !deleted && {
                type: 'button',
                action: this.addToBookmarks,
                label: 'Add to bookmarks',
              },
            bookmarked &&
              !deleted && {
                type: 'button',
                action: this.deleteBookmark,
                label: 'Remove from bookmarks',
              },
            {
              type: 'button',
              action: async () => {
                await this.props.groupRequestsDecline(id)
                this.setState({ renderCard: false })
                await this.props.updateRequestList()
              },
              label: 'Decline request',
            },
          ]

        default:
          return groupTypes.includes(this.state.type)
            ? isCreator
              ? [
                  !deleted && {
                    type: 'button',
                    action: () => {
                      this.props.history.push(
                        generatePath(routes.GROUP_SETTINGS, { groupId: id })
                      )
                    },
                    label: 'Edit group',
                  },
                  !bookmarked &&
                    !deleted && {
                      type: 'button',
                      action: this.addToBookmarks,
                      label: 'Add to bookmarks',
                    },
                  bookmarked &&
                    !deleted && {
                      type: 'button',
                      action: this.deleteBookmark,
                      label: 'Remove from bookmarks',
                    },
                  !deleted && {
                    type: 'button',
                    action: this.initiateGroupDelete,
                    label: 'Delete group',
                  },
                  deleted && {
                    type: 'button',
                    action: () => {
                      this.props.leaveGroup(id)
                      this.setState({ renderCard: false })
                    },
                    label: 'Leave the group',
                  },
                ]
              : [
                  {
                    type: 'button',
                    action: this.showComplaintsModal,
                    label: 'Report',
                    disabled: this.state.isReported,
                  },
                  !bookmarked &&
                    !deleted && {
                      type: 'button',
                      action: this.addToBookmarks,
                      label: 'Add to bookmarks',
                    },
                  bookmarked &&
                    !deleted && {
                      type: 'button',
                      action: this.deleteBookmark,
                      label: 'Remove from bookmarks',
                    },
                  {
                    type: 'button',
                    action: () => {
                      this.props.leaveGroup(id)
                      this.setState({ renderCard: false })
                    },
                    label: 'Leave the group',
                  },
                ]
            : null
      }
    }

    const controls = () => {
      if (this.props.type) {
        switch (this.props.type) {
          case 'search':
          case 'settings-related':
          case 'related':
          case 'requests-outgoing':
            return dropdownControl()

          case 'requests-pending':
            return (
              <>
                <MoreDropdown>
                  <VerticalMenu
                    menuItems={dropdownMenuItems()}
                    classes={'more-dropdown-menu__list'}
                  />
                </MoreDropdown>
                {dropdownControl()}
              </>
            )

          default:
            return (
              <MoreDropdown>
                <VerticalMenu
                  menuItems={dropdownMenuItems()}
                  classes={'more-dropdown-menu__list'}
                />
              </MoreDropdown>
            )
        }
      }
    }

    if (renderCard) {
      return (
        <div className="box list-item">
          <ComplaintsModal
            open={this.state.complaintsModal}
            group_id={id}
            onClose={this.hideComplaintsModal}
            callback={() => this.setState({ isReported: true })}
          />
          <div className="f aic full-width">
            <Link to={groupUrl} className="list-item__img">
              <img src={getGroupLogo(this.props.group)} alt={name} />
            </Link>
            <div className="list-item__info">
              <Link to={groupUrl} className="link">
                <div className="list-item__name">{name}</div>
              </Link>
              <span className="list-item__description">{groupStats}</span>
              {description !== 'Unknown' && (
                <div className="break-word">
                  <LineSlice text={description} />
                </div>
              )}
            </div>
          </div>
          <div className={`list-item__control-panel`}>{controls()}</div>
          <Dialog
            open={this.state.openDeleteModal}
            onClose={() => this.handleClose('deleteGroup')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Delete group from site?
            </DialogTitle>
            <DialogContent>
              <p className="color-black-54 font-16">
                If you delete group you will not be able to view information on
                the site. You can restore the group only through the support
                site.
              </p>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.handleClose('deleteGroup')}
                color="primary"
              >
                Decline
              </Button>
              <Button
                onClick={this.processDeleteGroup}
                color="primary"
                autoFocus
              >
                Accept
              </Button>
            </DialogActions>
          </Dialog>

          {this.state.openBookmarksModal && (
            <AddToFolderModal
              open={this.state.openBookmarksModal}
              handleClose={() => this.handleClose('addToFolder')}
            />
          )}
        </div>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = ({ auth, bookmarks }) => ({ auth, bookmarks })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createBookmark,
      deleteBookmark,
      foldersList,
      groupInviteDecline,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(ListItemGroup)))
