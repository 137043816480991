export const mutationTypes = {
  infinite: 1,
  record: 2,
  list: 3,
}

export const mutateInfinite = async ({ queryClient, key, itemUpdater }) => {
  await queryClient.cancelQueries(key)

  const updatedPages = queryClient
    .getQueryData(key)
    .pages.map((page) => page.map((item) => itemUpdater(item)).filter(Boolean))

  queryClient.setQueryData(key, (prevData) => ({
    pages: updatedPages,
    pageParams: prevData.pageParams,
  }))
}

export const deleteByIdInfinite = async ({ queryClient, key, id }) => {
  await queryClient.cancelQueries(key)

  const updatedPages = queryClient
    .getQueryData(key)
    .pages.map((page) => page.filter((item) => item.id !== id))

  queryClient.setQueryData(key, (prevData) => ({
    pages: updatedPages,
    pageParams: prevData.pageParams,
  }))
}

export const mutateRecord = async ({ queryClient, key, recordUpdater }) => {
  await queryClient.cancelQueries(key)

  console.log(key, queryClient.getQueryData(key), recordUpdater)

  const data = await queryClient.getQueryData(key)

  const updatedRecord = recordUpdater(data)

  // console.log(data, updatedRecord)

  queryClient.setQueryData(key, () => updatedRecord)
}
