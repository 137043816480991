import React, { Component } from "react"
import "./knowCredForm.scss"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import {
  knowCredBorderColors,
  knowCredLocations,
  knowCredTypesIds,
  knowCredTypesList,
} from "../../../../library/constants/knowCred"
import FormControl from "@material-ui/core/FormControl"
import { Button, Tooltip } from "@material-ui/core"
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons"
import * as routes from "../../../../library/constants/routes"
import { generatePath, Link } from "react-router-dom"
import { withSnackbar } from "notistack"
import {
  knowCredCreate,
  knowCredEdit,
  knowCredGet,
} from "../../../../library/store/actions/creators/knowCredsCreators"
import * as qs from "query-string"
import Loader from "../../../../components/ui/loader"
import { urlValidation } from "../../../../library/utils/validators"
import { getObjectById } from "../../../../library/utils/arrays"
import FormHelperText from "@material-ui/core/es/FormHelperText/FormHelperText"
import getErrorText from "../../../../library/constants/errorTypes"
import { colors } from "../../../../library/constants/styles/colors"
import { call } from "../../../../library/networking/API"
import { USERS } from "../../../../library/networking/apiEndpoints"
import InfoIcon from "@material-ui/icons/Info"
import GroupPicker from "../../../../components/v2/inputs/pickers/groupPicker"
import BackgroundsPicker from "./components/backgroundsPicker"
import ForegroundsPicker from "./components/foregroundsPicker"
import { MultipleTextField } from "../../../../components/formComponents"
import FormRadioGroup from "../../../../components/formComponents/formRadioGroup"
import UserPicker from "../../../../components/v2/inputs/pickers/userPicker"
import { FRIENDS } from "../../../../library/store/actions/types/friendsTypes"
import { pick } from "lodash"
import { GROUPS } from "../../../../library/store/actions/types/groupsTypes"

class KnowCredForm extends Component {
  state = {
    knowCredEdit: false,
    knowCredId: "",
    createFrom: "1",
    group: null,
    groupError: false,
    forPerson: "false",
    userIdTo: "",
    user: null,
    userError: false,
    title: "",
    typesList: knowCredTypesList.slice(0, 2),
    type: "",
    typeError: false,
    activity: "",
    activityError: false,
    activitiesList: [],
    location: "",
    description: "",
    links: [],
    customForegroundImage: "",
    foregroundImage: "",
    fgSource: "library",
    customBackgroundImage: "",
    backgroundImage: "",
    bgSource: "library",
    borderColor: knowCredBorderColors[0],
    dataReceived: false,
    userSendTo: null,
    emailSendTo: "",
    status: "public",
  }

  onEmailChange = async (e) => {
    const email = e.target.value
    this.setState({ emailSendTo: email })
    const re =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    if (re.test(email)) {
      try {
        const response = await call(USERS.SEARCH, {
          token: this.props.auth.userData.token,
          limit: 1,
          offset: 0,
          email: email,
        })
        if (response.status === 200) {
          const users = response.data.users
          if (!!users.length) {
            this.setState({
              userSendTo: users[0],
            })
          } else {
            this.setState({ userSendTo: null })
          }
        }
      } catch (e) {
        console.error(e)
      }
    } else {
      this.setState({ userSendTo: null })
    }
  }

  async componentDidMount() {
    ValidatorForm.addValidationRule("isLink", (value) => urlValidation(value))

    try {
      await this.setData({})

      if (this.props.match.path === routes.KNOWCRED_EDIT) {
        const id = parseInt(this.props.match.params.knowCredId)
        await this.props.knowCredGet({ id })
        await this.setData({ type: "edit" })
        this.props.showError(false)
      } else {
        if (qs.parse(this.props.location.search).for) {
          const userIdTo = parseInt(qs.parse(this.props.location.search).for)

          const userResponse = await call(USERS.GET, {
            id: userIdTo,
            friends_limit: 5,
            products_limit: 5,
          })

          const userData = await userResponse.data

          this.setState({
            forPerson: "true",
            userIdTo,
            user: pick(userData, ["id", "first_name", "last_name"]),
          })
        }
      }
    } catch ({ error }) {
      if (error.code === 21000) {
        this.props.showError(true)
      } else {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    }

    await this.setState({ dataReceived: true })
  }

  setData = async ({ type }) => {
    let {
      knowCreds: { currentKnowCred },
    } = this.props
    const id = parseInt(this.props.match.params.knowCredId)

    if (type === "edit") {
      this.setState({
        knowCredEdit: true,
        knowCredId: id,
        forPerson:
          currentKnowCred.user_id !== ""
            ? currentKnowCred.email
              ? "email"
              : "false"
            : "true",
        userId: currentKnowCred.user_id !== "" ? currentKnowCred.user_id : "",
        title: currentKnowCred.title,
        description: currentKnowCred.description,
        type: currentKnowCred.type,
        activity: currentKnowCred.activity,
        activitiesList: getObjectById(knowCredTypesList, currentKnowCred.type)
          .activities,
        location: currentKnowCred.location,
        links: currentKnowCred.links,
        emailSendTo: currentKnowCred.email || "",
        foregroundImage: currentKnowCred.foreground_image,
        backgroundImage: currentKnowCred.background_image,
        borderColor: currentKnowCred.border_color,
        status: currentKnowCred.public ? "public" : "private",
      })
    }
  }

  handleCreateFromChange = (v) => {
    this.setState((prevState) => {
      if (v === "1") {
        return {
          createFrom: v,
          typesList: knowCredTypesList.slice(0, 2),
          type: [3, 4, 5].includes(prevState.type) ? "" : prevState.type,
        }
      }

      return {
        createFrom: v,
        user: "",
      }
    })
  }

  handleChange = (name) => (event) => {
    event.persist()

    this.setState({
      [name]: event.target.value,
    })
  }

  handleChangeRequired = (name, event) => {
    switch (name) {
      case "group":
        const g = event.target.value
        const isPaid = g.paid || false

        this.setState((prevState) => ({
          groupError: !event.target.value,
          group: event.target.value,
          typesList: isPaid ? knowCredTypesList : knowCredTypesList.slice(0, 2),
          type:
            !isPaid && [3, 4, 5].includes(prevState.type) ? "" : prevState.type,
        }))
        break

      case "userId":
        this.setState({
          userIdError: event.target.value.length === 0,
          userId: event.target.value,
        })
        break

      case "user":
        this.setState({
          userError: !event.target.value,
          user: event.target.value,
        })
        break

      case "type":
        this.setState({
          type: event.target.value.id || "",
          typeError: !event.target.value.id,
          activitiesList: getObjectById(
            knowCredTypesList,
            event.target.value.id
          ).activities,
          activity: [1, 5].includes(event.target.value.id) ? 1 : "",
        })
        break

      case "activity":
        this.setState({
          activityError: event.target.value.length === 0,
          activity: event.target.value,
        })
        break

      default:
        this.setState({
          [name]: event.target.value,
        })
        break
    }
  }

  validateSelects = async () => {
    const { createFrom, group, forPerson, userId, user, type, activity } =
      this.state

    if (createFrom === "2") {
      this.setState({ groupError: !group })
    }

    if (forPerson === "true") {
      this.setState({ userError: !user })
    }

    if (!type === "") {
      this.setState({ typeError: true })
    }

    if (type !== "" && ![1, 5].includes(type) && activity === "") {
      this.setState({ activityError: true })
    }
  }

  processInfoSave = async () => {
    const {
      auth: { userData },
    } = this.props

    const {
      knowCredEdit,
      knowCredId,
      createFrom,
      group,
      groupError,
      forPerson,
      userId,
      title,
      description,
      type,
      typeError,
      activity,
      activityError,
      location,
      links,
      customBackgroundImage,
      backgroundImage,
      customForegroundImage,
      foregroundImage,
      bgSource,
      fgSource,
      borderColor: border_color,
      userSendTo,
      emailSendTo,
      status,
      userIdTo,
      user,
      userError,
    } = this.state

    if (
      (createFrom === "2" && groupError) ||
      (forPerson === "true" && userError) ||
      typeError ||
      activityError
    )
      return

    const data = {
      group_id: createFrom === "2" ? group?.id : undefined,
      title,
      description,
      type,
      activity: ![1, 5].includes(type) ? activity : 1,
      location,
      links: links.filter((link) => link !== ""),
      foreground_image:
        fgSource === "own" && !!customForegroundImage
          ? customForegroundImage
          : foregroundImage,
      background_image:
        bgSource === "own" && !!customBackgroundImage
          ? customBackgroundImage
          : backgroundImage,
      border_color,
      is_public: status === "public",
    }

    const { history } = this.props
    try {
      if (!knowCredEdit) {
        if (forPerson === "false") {
          await this.props.knowCredCreate(data)
        } else if (forPerson === "email") {
          await this.props.knowCredCreate({
            ...data,
            user_id: userSendTo ? userSendTo.id : undefined,
            email: userSendTo ? undefined : emailSendTo,
          })
        } else {
          await this.props.knowCredCreate({ ...data, user_id: user.id })
        }

        await history.push({
          pathname: userIdTo
            ? generatePath(routes.USER, {
                userId: userIdTo,
              })
            : generatePath(routes.KNOWCRED, {
                userId: userData.id,
              }),
        })
      } else {
        if (forPerson === "false") {
          await this.props.knowCredEdit({ id: knowCredId, ...data })
        } else if (forPerson === "email") {
          await this.props.knowCredEdit({
            id: knowCredId,
            user_id: userId,
            email: userId ? undefined : emailSendTo,
            ...data,
          })
        } else {
          await this.props.knowCredEdit({
            id: knowCredId,
            user_id: userId,
            ...data,
          })
        }

        await history.push({
          pathname: generatePath(routes.KNOWCRED_SINGLE, { knowCredId }),
        })
      }
    } catch ({ error }) {
      error &&
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
    }
  }

  render() {
    const {
      knowCredEdit,
      knowCredId,
      createFrom,
      group,
      groupError,
      forPerson,
      title,
      description,
      type,
      typeError,
      activity,
      activityError,
      activitiesList,
      location,
      links,
      customForegroundImage,
      foregroundImage,
      customBackgroundImage,
      backgroundImage,
      borderColor,
      dataReceived,
      emailSendTo,
      userSendTo,
      status,
      user,
      userError,
      userIdTo,
    } = this.state

    const pathOnCancel = knowCredEdit
      ? generatePath(routes.KNOWCRED_SINGLE, { knowCredId })
      : userIdTo
        ? generatePath(routes.USER, {
            userId: userIdTo,
          })
        : generatePath(routes.KNOWCRED, { userId: this.props.auth.userData.id })

    const hideActivitiesField = [1, 5]
    const showSubtitleLabel = [3, 4]

    return dataReceived ? (
      <div className="box">
        <div className="box__heading">
          <h1>
            {this.props.match.path === routes.KNOWCRED_EDIT ? "Edit" : "Create"}{" "}
            Cred
          </h1>
        </div>
        <div className="box__content">
          <ValidatorForm
            onSubmit={this.processInfoSave}
            className="form knowcred-form"
          >
            {!knowCredEdit && (
              <>
                <FormRadioGroup
                  label={"Create from *"}
                  value={createFrom}
                  onChange={this.handleCreateFromChange}
                  options={[
                    { label: "Yourself", value: "1" },
                    { label: "Group", value: "2" },
                  ]}
                  name={"createFrom"}
                />

                {createFrom === "2" && (
                  <GroupPicker
                    value={group}
                    hasError={groupError}
                    required
                    onChange={(g) =>
                      this.setState((prevState) => ({
                        group: g,
                        groupError: !g,
                        typesList: g.paid
                          ? knowCredTypesList
                          : knowCredTypesList.slice(0, 2),
                        type:
                          !g.paid && [3, 4, 5].includes(prevState.type)
                            ? ""
                            : prevState.type,
                        user: "",
                      }))
                    }
                    getRequestConfig={(config) => {
                      return {
                        ...config,
                        fetchConfig: {
                          ...config.fetchConfig,
                          params: {
                            ...config.fetchConfig.params,
                            paid: [3, 4, 5].includes(type),
                            managed: true,
                          },
                        },
                      }
                    }}
                    emptyListMessage={"You are not an admin of any group"}
                  />
                )}

                <FormRadioGroup
                  label={"Select who is Cred for *"}
                  value={forPerson}
                  onChange={(v) => this.setState({ forPerson: v })}
                  options={[
                    { label: "Me", value: "false" },
                    {
                      label:
                        createFrom === "2" ? "Group member" : "Someone else",
                      value: "true",
                    },
                    { label: "Send by email", value: "email" },
                  ]}
                  name={"forPerson"}
                />

                {forPerson === "true" && (
                  <UserPicker
                    required={forPerson === "true"}
                    userLabel={createFrom === "2" ? "Group member" : "friend"}
                    value={user}
                    fieldName={createFrom === "2" ? "users" : "friends"}
                    onChange={(u) => this.setState({ user: u, userError: !u })}
                    hasError={userError}
                    isWaitingForOtherFields={createFrom === "2" && !group}
                    isWaitingForOtherFieldsMessage={"First select group"}
                    requestConfig={{
                      fetchConfig: {
                        apiUrl:
                          createFrom === "2"
                            ? GROUPS.PARTICIPANTS.LIST
                            : FRIENDS.LIST,
                        params: {
                          user_id:
                            createFrom === "2"
                              ? undefined
                              : this.props.auth.userData.id,
                          group_id: createFrom === "2" ? group?.id : undefined,
                          tags: createFrom === "2" ? [] : undefined,
                        },
                      },
                      key: "users",
                    }}
                  />
                )}

                {forPerson === "email" && (
                  <div className="form__fieldset">
                    <label htmlFor="title" className="form__label">
                      Email to send *
                    </label>
                    <div className="form__fieldbox">
                      <div className="form__input">
                        <TextValidator
                          id="email"
                          name="email"
                          value={emailSendTo}
                          placeholder="Type email"
                          className="edit-form__input"
                          margin="normal"
                          fullWidth
                          validators={[
                            "required",
                            "minStringLength:3",
                            "maxStringLength:100",
                            "isEmail",
                          ]}
                          errorMessages={[
                            "Field is required",
                            "Min length is 3 characters",
                            "Max length is 100 characters",
                            "Email is not valid",
                          ]}
                          withRequiredValidator
                          onChange={this.onEmailChange}
                        />
                        {userSendTo && (
                          <FormHelperText style={{ color: colors.primary }}>
                            user already exist:{" "}
                            <Link
                              className="link link--inline"
                              to={generatePath(routes.USER, {
                                userId: userSendTo.id,
                              })}
                            >
                              {userSendTo.first_name} {userSendTo.last_name}
                            </Link>
                          </FormHelperText>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            <div className="form__fieldset">
              <label htmlFor="title" className="form__label">
                Cred name *
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <TextValidator
                    id="title"
                    name="title"
                    value={title}
                    placeholder="Type Cred name"
                    className="edit-form__input"
                    margin="normal"
                    fullWidth
                    validators={[
                      "required",
                      "minStringLength:3",
                      "maxStringLength:100",
                    ]}
                    errorMessages={[
                      "Field is required",
                      "Min length is 3 characters",
                      "Max length is 100 characters",
                    ]}
                    withRequiredValidator
                    onChange={this.handleChange("title")}
                  />
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor="type" className="form__label f aic">
                <Tooltip
                  title={
                    "Choose paid group if you need to create Grades/Marks, Certificates and Diplomas/Degrees Creds"
                  }
                  arrow
                >
                  <InfoIcon
                    style={{
                      marginRight: 5,
                      fontSize: 20,
                      color: colors.primary,
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
                Type of Cred *
              </label>
              <div className="form__fieldbox">
                <div className="form__input form__input--select">
                  <FormControl error={typeError} fullWidth>
                    <Select
                      id="type"
                      name="type"
                      fullWidth
                      value={type}
                      displayEmpty
                      renderValue={(value) => {
                        if (!value) {
                          return (
                            <span className="color-black-38">
                              Select a type of Cred
                            </span>
                          )
                        }
                        return knowCredTypesIds[type]
                      }}
                      onChange={(e) => this.handleChangeRequired("type", e)}
                    >
                      {this.state.typesList.map((item) => {
                        return (
                          <MenuItem value={item} key={item.id}>
                            {item.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                    {typeError && (
                      <FormHelperText>Field is required</FormHelperText>
                    )}
                  </FormControl>
                </div>
              </div>
            </div>

            {!hideActivitiesField.includes(type) && !!type && (
              <div className="form__fieldset">
                <label htmlFor="activity" className="form__label">
                  {showSubtitleLabel.includes(type) ? "Label" : "Activity"} *
                </label>
                <div className="form__fieldbox">
                  <div className="form__input form__input--select">
                    <FormControl error={activityError} fullWidth>
                      <Select
                        id="activity"
                        name="activity"
                        fullWidth
                        value={activity}
                        displayEmpty
                        onChange={(e) =>
                          this.handleChangeRequired("activity", e)
                        }
                        renderValue={(value) => {
                          if (value.length === 0) {
                            return (
                              <span className="color-black-38">
                                Select an activity
                              </span>
                            )
                          }
                          return getObjectById(activitiesList, value).name
                        }}
                      >
                        {activitiesList.map((item) => (
                          <MenuItem value={item.id} key={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {activityError && (
                        <FormHelperText>Field is required</FormHelperText>
                      )}
                    </FormControl>
                  </div>
                </div>
              </div>
            )}

            <div className="form__fieldset">
              <label htmlFor="location" className="form__label">
                Location
              </label>
              <div className="form__fieldbox">
                <div className="form__input form__input--select">
                  <Select
                    id="location"
                    name="location"
                    fullWidth
                    displayEmpty
                    value={location}
                    onChange={this.handleChange("location")}
                  >
                    <MenuItem value="">
                      <span className="color-black-38">Select location</span>
                    </MenuItem>
                    {knowCredLocations.map((item) => (
                      <MenuItem value={item.name} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor="description" className="form__label">
                Description of activities
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <TextValidator
                    id="description"
                    name="description"
                    value={description}
                    placeholder="Write a short description of activities"
                    className="edit-form__input"
                    margin="normal"
                    fullWidth
                    multiline
                    validators={["maxStringLength:2000"]}
                    errorMessages={["Max length is 2000 characters"]}
                    withRequiredValidator
                    onChange={this.handleChange("description")}
                  />
                </div>
              </div>
            </div>

            <MultipleTextField
              values={links}
              setValues={(v) => this.setState({ links: v })}
              limit={10}
              fieldLabel="Additional links"
              fieldPlaceholder="Type URL"
              addButtonLabel="Add link"
              validators={["isLink", "maxStringLength:250"]}
              validatorMessages={[
                "Invalid URL",
                "Max length is 250 characters",
              ]}
            />

            {!!type && (
              <ForegroundsPicker
                libraryImage={foregroundImage}
                savedLibraryImage={
                  this.props.knowCreds?.currentKnowCred?.foreground_image
                }
                onChangeLibraryImage={(v) =>
                  this.setState({ foregroundImage: v })
                }
                ownImage={customForegroundImage}
                onChangeOwnImage={(v) =>
                  this.setState({ customForegroundImage: v })
                }
                onImageSourceChange={(v) => this.setState({ fgSource: v })}
                type={type}
                activity={activity}
              />
            )}

            <BackgroundsPicker
              libraryImage={backgroundImage}
              savedLibraryImage={
                this.props.knowCreds?.currentKnowCred?.background_image
              }
              onChangeLibraryImage={(v) =>
                this.setState({ backgroundImage: v })
              }
              ownImage={customBackgroundImage}
              onChangeOwnImage={(v) =>
                this.setState({ customBackgroundImage: v })
              }
              onImageSourceChange={(v) => this.setState({ bgSource: v })}
            />

            <div className="form__fieldset">
              <label htmlFor="custom-background-image" className="form__label">
                Border color for credicon *
              </label>
              <div className="f aife">
                <div className="f jcfs aic">
                  {knowCredBorderColors.map((color) => (
                    <div className="f" key={color}>
                      {borderColor !== color ? (
                        <CheckBoxOutlineBlank
                          style={{ color }}
                          className="pointer"
                          onClick={() => this.setState({ borderColor: color })}
                        />
                      ) : (
                        <CheckBox style={{ color }} />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <FormRadioGroup
              label={"Status *"}
              value={status}
              onChange={(v) => this.setState({ status: v })}
              options={[
                { label: "Public", value: "public" },
                { label: "Private", value: "private" },
              ]}
              name={"status"}
            />

            <div className="btn-group jcfe pl15 pr15">
              <Button
                color="primary"
                variant="outlined"
                component={Link}
                to={pathOnCancel}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                onClick={this.validateSelects}
              >
                Save
              </Button>
            </div>
          </ValidatorForm>
        </div>
      </div>
    ) : (
      <Loader />
    )
  }
}

const mapStateToProps = ({ auth, knowCreds }) => ({
  auth,
  knowCreds,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      knowCredGet,
      knowCredEdit,
      knowCredCreate,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(KnowCredForm))
