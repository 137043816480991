import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  addEducation,
  deleteEducation,
  getData,
  updateEducation,
} from "../../../../../../library/store/actions/creators/authCreators"
import {
  MAX_DATE,
  MIN_DATE,
} from "../../../../../../library/constants/defaultDates"
import { educationTypes } from "../../../../../../library/constants/educationalDictionary"
import { key } from "../../../../../../library/utils/generateKey"
import { Divider } from "../../../../../../components/v2/layout/forms/form/form"
import { PlainButton } from "../../../../../../components/v2/controls"
import EducationForm from "../educationForm/educationForm"
import { useAlert } from "../../../../../../hooks/useAlert"

const newEducation = {
  id: "",
  name: "",
  country: "",
  city: "",
  department: "",
  date_from: null,
  date_to: null,
}

const educationsLimit = 20

const ProfileEditEducationContent = ({ type }) => {
  const { userData } = useSelector(({ auth }) => auth)
  const dispatch = useDispatch()
  const { successAlert, errorAlert } = useAlert()

  const [records, setRecords] = useState(
    userData.educations.filter((e) => e.type === type).length
      ? userData.educations.filter((e) => e.type === type)
      : [{ ...newEducation, type }]
  )

  useEffect(() => {
    setRecords(userData.educations.filter((e) => e.type === type))
  }, [userData.educations])

  const processAddEducation = async (values) => {
    try {
      await dispatch(
        addEducation({
          ...values,
          id: undefined,
          date_from:
            values.date_from === null
              ? MIN_DATE
              : new Date(values.date_from).toISOString(),
          date_to:
            values.date_to === null
              ? MAX_DATE
              : new Date(values.date_to).toISOString(),
        })
      )
      await dispatch(getData())
      successAlert()
    } catch (error) {
      errorAlert(error)
    }
  }

  const processDeleteEducation = async (id) => {
    try {
      await dispatch(deleteEducation(id))
      await dispatch(getData())
      successAlert()
    } catch (error) {
      errorAlert(error)
    }
  }

  const processUpdateEducation = async (values) => {
    try {
      await dispatch(
        updateEducation({
          ...values,
          date_from:
            values.date_from === null
              ? MIN_DATE
              : new Date(values.date_from).toISOString(),
          date_to:
            values.date_to === null
              ? MAX_DATE
              : new Date(values.date_to).toISOString(),
        })
      )
      await dispatch(getData())
      successAlert()
    } catch (error) {
      errorAlert(error)
    }
  }

  const handleAdd = () =>
    setRecords((prevState) => [...prevState, { ...newEducation, type }])

  const handleDelete = (idx) => {
    if (!records[idx].id) {
      return setRecords((prevState) => prevState.filter((w, i) => idx !== i))
    }

    return processDeleteEducation(records[idx].id)
  }

  const onSubmit = async (education) => {
    if (education.id) return await processUpdateEducation(education)

    return await processAddEducation(education)
  }

  return (
    <div>
      {records.map((education, idx) => (
        <div key={education.id ? education.id : key()}>
          <EducationForm
            education={education}
            onSubmit={onSubmit}
            onDelete={() => handleDelete(idx)}
          />
          {idx + 1 < educationsLimit && <Divider className={'mt15'} />}
        </div>
      ))}
      {records.length < educationsLimit && (
        <div className="f jcc">
          <PlainButton actionType={'add'} onClick={handleAdd}>
            {`Add another ${
              type === 4
                ? 'college or university'
                : `${educationTypes[type].toLowerCase()} education`
            }`}
          </PlainButton>
        </div>
      )}
    </div>
  )
}

export default ProfileEditEducationContent
