import React, { useEffect, useState } from "react"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import Loader from "../../../../../../../../../components/ui/loader"
import { Dialog, DialogContent } from "@material-ui/core"
import Button from "@material-ui/core/Button/Button"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import { FormDatePicker } from "../../../../../../../../../components/formComponents"
import FormControl from "@material-ui/core/FormControl/FormControl"
import Select from "@material-ui/core/Select/Select"
import { getObjectById } from "../../../../../../../../../library/utils/arrays"
import { calendarGrades } from "../../../../../../../../../library/constants/educationalDictionary"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import FormHelperText from "@material-ui/core/FormHelperText"
import { useParams } from "react-router-dom"
import { useAlert } from "../../../../../../../../../hooks/useAlert"
import {
  useSegment,
  useSegmentMutations,
} from "../../../../../../../../../hooks/data/calendar/schedules"

export const sectionDurations = [
  {
    value: 1,
    label: "1 week",
  },
  {
    value: 2,
    label: "2 weeks",
  },
]

const SegmentFormModal = ({ edit, activeSegment, onClose, open, queryKey }) => {
  const params = useParams()
  const { errorAlert } = useAlert()

  const { data: currentSegment, isSuccess } = useSegment(activeSegment, {
    enabled: !!activeSegment,
  })

  const { createSegment, updateSegment } = useSegmentMutations(queryKey)

  const [name, setName] = useState("")
  const [grades, setGrades] = useState([])
  const [gradesError, setGradesError] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [startDateError, setStartDateError] = useState(false)
  const [endDate, setEndDate] = useState(null)
  const [endDateError, setEndDateError] = useState(false)
  const [sectionDuration, setSectionDuration] = useState(1)

  useEffect(() => {
    if (edit && currentSegment) {
      setName(currentSegment.name)
      setGrades(currentSegment.grades)
      setStartDate(currentSegment.date_from)
      setEndDate(currentSegment.date_to)
      setSectionDuration(currentSegment.section_duration)
    }
  }, [currentSegment])

  const processFormValidate = () => {
    if (!grades.length) {
      setGradesError(true)
    }

    if (!endDate) {
      setEndDateError(true)
    }

    if (!startDate) {
      setStartDateError(true)
    }
  }

  const onSubmit = async () => {
    if (!grades.length || gradesError) {
      return setGradesError(true)
    }

    if (startDateError || endDateError) {
      return
    }

    const data = {
      name,
      grades,
      date_from: startDate,
      date_to: endDate,
      section_duration: sectionDuration,
    }

    try {
      if (!edit) {
        await createSegment.mutateAsync({
          schedule_id: parseInt(params.calendarId, 10),
          ...data,
        })
      } else {
        await updateSegment.mutateAsync({
          id: activeSegment,
          ...data,
        })
      }

      onClose()
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <Dialog
      aria-labelledby="calendar-form-dialog"
      onClose={onClose}
      open={open}
      maxWidth="md"
    >
      <ValidatorForm onSubmit={onSubmit}>
        <DialogTitle id="alert-dialog-title" className="text-center">
          {edit ? "Edit Segment" : "Create Segment"}
        </DialogTitle>
        <DialogContent>
          {(!!edit && isSuccess) || !edit ? (
            <div className="f jcc">
              <div className="form form--modal">
                <div className="form__fieldset">
                  <label htmlFor="name" className="form__label">
                    Name *
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="name"
                        name="name"
                        value={name}
                        placeholder="Type segment name"
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        validators={[
                          "required",
                          "minStringLength:3",
                          "maxStringLength:200",
                        ]}
                        errorMessages={[
                          "Field is required",
                          "Min length is 3 characters",
                          "Max length is 200 characters",
                        ]}
                        withRequiredValidator
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="form__fieldset">
                  <label htmlFor="grades" className="form__label">
                    Grades
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input form__input--select">
                      <FormControl error={gradesError} fullWidth>
                        <Select
                          id="grades"
                          name="grades"
                          fullWidth
                          displayEmpty
                          multiple
                          value={grades}
                          onChange={(e) => {
                            console.log(e.target.value)
                            setGradesError(!e.target.value.length)
                            setGrades(e.target.value)
                          }}
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return (
                                <span className="color-black-38">
                                  Select grades
                                </span>
                              )
                            }

                            let selectedNames = selected.map((item) => {
                              return getObjectById(calendarGrades, item).name
                            })

                            return selectedNames.join(", ")
                          }}
                        >
                          {calendarGrades.map((item) => (
                            <MenuItem value={item.id} key={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {gradesError && (
                          <FormHelperText className="error-message">
                            Field is required
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  </div>
                </div>

                <FormDatePicker
                  dateFrom={{
                    label: "Start date",
                    value: startDate,
                    required: true,
                    error: startDateError,
                    handleChange: (value) => {
                      setStartDate(value)
                      setStartDateError(!value)
                    },
                  }}
                  dateTo={{
                    label: "End date",
                    value: endDate,
                    required: true,
                    error: endDateError,
                    handleChange: (value) => {
                      setEndDate(value)
                      setEndDateError(!value)
                    },
                  }}
                />

                <div className="form__fieldset">
                  <label htmlFor={"section_duration"} className="form__label">
                    Section change period
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input form__input--select">
                      <Select
                        id={"section_duration"}
                        name={"section_duration"}
                        fullWidth
                        displayEmpty
                        value={sectionDuration}
                        onChange={(e) => {
                          setSectionDuration(e.target.value)
                        }}
                        renderValue={(value) => {
                          if (value.length === 0) {
                            return (
                              <span className="color-black-38">
                                Select section change period
                              </span>
                            )
                          }

                          return sectionDurations.find((t) => t.value === value)
                            .label
                        }}
                      >
                        {sectionDurations.map((item) => (
                          <MenuItem value={item.value} key={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Loader />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary" onClick={processFormValidate}>
            Save
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  )
}

export default SegmentFormModal
