import * as qs from "query-string"
import { useHistory, useRouteMatch } from "react-router-dom"

export const useSearchQuery = () => {
  const history = useHistory()
  const { path } = useRouteMatch()

  const compose = (fields) => {
    const result = qs.stringify(fields, { arrayFormat: "comma" })

    history.push({
      pathname: path,
      search: `?${result}`,
    })
  }

  const parse = () => {
    return qs.parse(history.location.search)
  }

  const fields = parse()

  const hasField = (fieldName) => {
    return typeof fields[fieldName] === "string"
  }

  return {
    compose,
    parse,
    fields,
    hasField,
  }
}
