import React, { Component } from "react"
import * as qs from "query-string"
import ListSearchHeader from "../../../../../components/ui/listSearchHeader/listSearchHeader"
import ProductsListItem from "../../../marketplace/productsList/productsListItem/productsListItem"
import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../../../library/constants/routes"
import Loader from "../../../../../components/ui/loader"
import { bindActionCreators } from "redux"
import {
  clearProductsList,
  getProductsList,
} from "../../../../../library/store/actions/creators/productsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { getGroup } from "../../../../../library/store/actions/creators/groupsCreators"
import withScroll from "../../../../../hocs/withScroll/withScroll"
import { LIMIT } from "../../../../../library/constants/limits"
import getErrorText from "../../../../../library/constants/errorTypes"
import { getGroupLogo } from "../../../../../library/utils/getGroupLogo"

class GroupProducts extends Component {
  state = {
    list: [],
    dataReceived: false,
  }

  async componentDidMount() {
    const {
      match: { params },
      location: { search },
    } = this.props
    const { q: query } = qs.parse(search)
    try {
      await this.props.getGroup({ id: parseInt(params.groupId) })
      if (
        this.props.groups.currentGroup &&
        (this.props.groups.currentGroup.deleted ||
          (this.props.groups.currentGroup.closed &&
            !this.props.groups.currentGroup.is_member) ||
          (this.props.groups.currentGroup.closed &&
            this.props.groups.currentGroup.is_member &&
            this.props.groups.currentGroup.tags.includes(1)))
      ) {
        return this.props.history.push(
          generatePath(routes.GROUP, { groupId: params.groupId })
        )
      }
      await this.props.getProductsList({
        group_id: parseInt(params.groupId),
        name: query || undefined,
      })
      this.setState({
        list: this.props.products.list,
        dataReceived: true,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      match: { params },
      location: { search },
    } = this.props
    const { q: query } = qs.parse(search)

    if (search && search !== prevProps.location.search) {
      this.setState({ dataReceived: false })
      await this.props.getProductsList({
        group_id: parseInt(params.groupId),
        name: query || undefined,
      })
      this.setState({ list: this.props.products.list, dataReceived: true })
    }
  }

  searchProducts = (name) => {
    const { history } = this.props
    this.props.clearProductsList()
    history.push({
      search: `?q=${name}`,
    })
    this.setState({ list: [] })
  }

  componentWillUnmount() {
    this.props.clearProductsList()
  }

  onScroll = async () => {
    if (!this.state.dataReceived) return
    this.setState({ dataReceived: false })
    const {
      match: { params },
      location: { search },
    } = this.props
    const { q: query } = qs.parse(search)
    await this.props.getProductsList({
      group_id: parseInt(params.groupId),
      name: query || undefined,
      offset: this.state.list.length,
      limit: LIMIT,
    })
    this.setState({ list: this.props.products.list, dataReceived: true })
  }

  render() {
    const { list, dataReceived } = this.state
    const {
      groups: { currentGroup },
      match: { params },
      location: { search },
    } = this.props
    const { q: query } = qs.parse(search)
    return (
      <div className="common-page__content">
        <main className="common-page__main">
          <ListSearchHeader
            title="Products"
            placeholder="Start typing product name"
            search={this.searchProducts}
            noResults={dataReceived && !list.length}
            noResultsLabel={query ? "" : "This group doesn't have products yet"}
          />
          <div className="products-list">
            {list.map((item) => (
              <ProductsListItem
                product={item}
                group={currentGroup}
                manageable={false}
                key={item.id}
              />
            ))}
          </div>
          {!dataReceived && <Loader />}
        </main>
        {this.props.groups.currentGroup && (
          <aside className="common-page__sidebar">
            <div className="box aside-header">
              <Link
                to={generatePath(routes.GROUP, { groupId: params.groupId })}
                className="box__content aside-header__content"
              >
                <img
                  src={getGroupLogo(currentGroup)}
                  className="aside-header__img"
                  alt=""
                />
                <div className="aside-header__info">
                  <div className="aside-header__name">{currentGroup.name}</div>
                  <div className="aside-header__help">back to page</div>
                </div>
              </Link>
            </div>
            {/*<Ads/>*/}
          </aside>
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ auth, groups, products }) => ({
  auth,
  groups,
  products,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getGroup,
      getProductsList,
      clearProductsList,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withScroll(GroupProducts)))
