import BoxPage from "../../../../../../../components/v2/layout/boxPage/boxPage"
import React, { useEffect, useState } from "react"
import Select from "@material-ui/core/Select/Select"
import { getObjectById } from "../../../../../../../library/utils/arrays"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import { calendarGrades } from "../../../../../../../library/constants/educationalDictionary"
import { useDispatch, useSelector } from "react-redux"
import { useAlert } from "../../../../../../../hooks/useAlert"
import { call } from "../../../../../../../library/networking/API"
import { SEGMENTS } from "../../../../../../../library/store/actions/types/groupsTypes"
import { useParams } from "react-router-dom"
import { getScheduleForManage } from "../../../../../../../library/store/actions/creators/calendarCreators"
import ScheduleTable from "./scheduleTable"
import styled from "@emotion/styled"
import { colors } from "../../../../../../../library/constants/styles/colors"

const Container = styled.div`
  padding: 15px 10px;
  box-shadow: 0 0 2px 0 ${colors.black12};
  background-color: ${colors.white};
  border-radius: 2px;
`

const GroupSettingsCalendarSchedule = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const { errorAlert } = useAlert()

  const { schoolSchedule } = useSelector(({ calendar }) => calendar)

  console.log(schoolSchedule)

  const [grade, setGrade] = useState('')
  const [segment, setSegment] = useState('')
  const [segmentsList, setSegmentsList] = useState([])
  const [segmentsReceived, setSegmentsReceived] = useState(false)
  const [section, setSection] = useState('')
  const [sectionsList, setSectionsList] = useState([])
  const [sectionsReceived, setSectionsReceived] = useState(false)

  const [offset, setOffset] = useState(0)
  const [scheduleReceived, setScheduleReceived] = useState(false)

  const getSegments = async () => {
    try {
      const response = await call(SEGMENTS.LIST, {
        schedule_id: parseInt(params.calendarId),
        grade: grade || undefined,
        offset: 0,
        limit: 100,
      })

      const data = await response.data

      setSegmentsList(data.segments)
      setSegmentsReceived(true)

      setSection('')
      setSectionsList([])
      setSectionsReceived(false)
    } catch (e) {
      errorAlert(e)
    }
  }

  useEffect(() => {
    setSegmentsList([])
    setSegmentsReceived(false)
    setSegment('')

    if (grade) {
      getSegments()
    }
  }, [grade])

  const getSections = async () => {
    try {
      const response = await call(SEGMENTS.SECTIONS.LIST, {
        segment_id: segment,
        offset: 0,
        limit: 100,
      })

      const data = await response.data

      setSectionsList(data.sections)
      setSectionsReceived(true)
    } catch (e) {
      errorAlert(e)
    }
  }

  useEffect(() => {
    if (segment) {
      getSections()
    }
  }, [segment])

  const getSchedule = async () => {
    try {
      setScheduleReceived(false)

      await dispatch(getScheduleForManage({ section_id: section, offset }))
      // await dispatch(getScheduleForManage({ section_id: 16, offset }))

      setScheduleReceived(true)
    } catch (e) {
      errorAlert(e)
    }
  }

  useEffect(() => {
    // getSchedule()

    if (section) {
      getSchedule()
    }
  }, [section, offset])

  return (
    <>
      <BoxPage title={'Manage schedule'}>
        <div className="form mb15">
          <div className="form__fieldset mb15">
            <label htmlFor="grade" className="form__label">
              Grade
            </label>
            <div className="form__fieldbox">
              <div className="form__input form__input--select">
                <Select
                  id="grade"
                  name="grade"
                  fullWidth
                  displayEmpty
                  value={grade}
                  onChange={(e) => setGrade(e.target.value)}
                  renderValue={(value) => {
                    if (value.length === 0) {
                      return (
                        <span className="color-black-38">Select grade</span>
                      )
                    }

                    return getObjectById(calendarGrades, value)
                      ? getObjectById(calendarGrades, value).name
                      : ''
                  }}
                >
                  {calendarGrades.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>

          {/*{segmentsReceived && (*/}
          <div className="form__fieldset mb15">
            <label htmlFor="segment" className="form__label">
              Segment
            </label>
            <div className="form__fieldbox">
              <div className="form__input form__input--select">
                <Select
                  id="segment"
                  name="segment"
                  fullWidth
                  displayEmpty
                  value={segment}
                  disabled={!segmentsReceived}
                  onChange={(e) => setSegment(e.target.value)}
                  renderValue={(value) => {
                    if (value.length === 0) {
                      return (
                        <span className="color-black-38">Select segment</span>
                      )
                    }

                    return getObjectById(segmentsList, value)
                      ? getObjectById(segmentsList, value).name
                      : ''
                  }}
                >
                  {segmentsList.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          {/*)}*/}

          {/*{sectionsReceived && (*/}
          <div className="form__fieldset mb15">
            <label htmlFor="section" className="form__label">
              Section
            </label>
            <div className="form__fieldbox">
              <div className="form__input form__input--select">
                <Select
                  id="section"
                  name="section"
                  fullWidth
                  displayEmpty
                  value={section}
                  disabled={!sectionsReceived}
                  onChange={(e) => setSection(e.target.value)}
                  renderValue={(value) => {
                    if (value.length === 0) {
                      return (
                        <span className="color-black-38">Select section</span>
                      )
                    }

                    return getObjectById(sectionsList, value)
                      ? getObjectById(sectionsList, value).name
                      : ''
                  }}
                >
                  {sectionsList.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          {/*)}*/}
        </div>
      </BoxPage>

      <Container>
        {!scheduleReceived ? (
          <p className="color-black-54 text-center">
            Chose section to see schedule
          </p>
        ) : (
          <>
            <p className={'mb15'}>Monday, track A</p>
            <ScheduleTable timePeriod={'7am — 1pm'} />
            <ScheduleTable timePeriod={'1pm — 6pm'} isLast />
          </>
        )}
      </Container>
    </>
  )
}

export default GroupSettingsCalendarSchedule
