import React from "react"
import styled from "@emotion/styled"
import InvenstoryHeader from "./invenstoryHeader"
import { Route, Switch, withRouter } from "react-router-dom"
import { colors } from "../../../library/constants/styles/colors"
import * as routes from "../../../library/constants/routes"
import InvenstoryTable from "./invenstoryTable"
import InvenstoryAccessRequests from "./invenstoryAccessRequests"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchOutlined from "@material-ui/icons/SearchOutlined"
import * as qs from "query-string"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import {
  clearInvenstory,
  clearRecordsList,
  getConnectionsList,
  getInvenstoryList,
  switchInvenstoryView,
} from "../../../library/store/actions/creators/invenstoryCreators"
import {
  clearGroupsList,
  getGroupsList,
} from "../../../library/store/actions/creators/groupsCreators"
import getErrorText from "../../../library/constants/errorTypes"
import { withSnackbar } from "notistack"
import MultiSelect from "../../../components/ui/multiSelect"
import Loader from "../../../components/ui/loader"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import FormControl from "@material-ui/core/FormControl"
import { call } from "../../../library/networking/API"
import { STANDARDS } from "../../../library/store/actions/types/standardsTypes"
import { debounce } from "lodash"

import ProductCreate from "../marketplace/productCreate/productCreate"
import SEO from "../../../components/SEO"

const Container = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-gap: 15px;
`

const HeaderContainer = styled.div`
  box-shadow: 0 0 2px 0 ${colors.black12};
  background-color: ${colors.white};
  border-radius: 2px;
`

const GroupsFormContainer = styled.div`
  padding: 15px;
`

const initialTableState = {
  page: 1,
  count: 0,
  maxCount: 0,
  countPerPage: 10,
  startRange: 0,
  prevOffset: 0,
}

class Invenstory extends React.Component {
  state = {
    noResults: false,
    accessRequestQuery: "",
    selectedGroup: null,
    selectedOptions: [],
    groupsList: [],
    hideGroupsInvenstory: true,
    multiSelectInputValue: "",
    dataReceived: true,
    selectedGroupConnections: [],
    tableData: [],
    tableQuery: "",
    filterOptions: {},
    includeSelectedGroup: true,
    filterValues: {},
    tableSearchQuery: "",
    tableState: JSON.parse(JSON.stringify(initialTableState)),
    standards: [],
    standardsDataReceived: true,
    standardsName: "",
    initialRender: true,
    productOpen: false,
    fieldsToProduct: null,
    listDataReceived: true,
  }

  async componentDidMount() {
    const {
      location: { search },
    } = this.props
    const { q: query } = qs.parse(search)
    query &&
      this.setState({
        accessRequestQuery: query,
      })
    await this.getGroupsList()
    await this.getStandards({})
    await this.getInitialData()
    this.setState({ initialRender: false })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match.path !== this.props.match.path) {
      this.setState(
        {
          tableState: JSON.parse(JSON.stringify(initialTableState)),
          tableData: [],
        },
        async () => await this.getInitialData()
      )
    }
  }

  componentWillUnmount() {
    this.props.clearGroupsList()
    this.props.clearInvenstory()
    this.props.clearRecordsList()
  }

  getInitialData = async () => {
    const { path } = this.props.match
    this.props.clearRecordsList()
    this.props.clearInvenstory()
    switch (path) {
      case routes.INVENSTORY:
        await this.getTableData({
          user_id: parseInt(this.props.auth.userData.id),
        })
        break
      case routes.INVENSTORY_GROUPS:
        if (this.state.groupsList.length) {
          const { selectedGroup } = this.state
          if (selectedGroup) {
            await this.getGroupConnection({
              group_id: this.state.selectedGroup.value,
            })
          }
          // this.setState({
          //     selectedGroup: this.state.selectedGroup ? this.state.selectedGroup : groupsList[0] && {label: groupsList[0].name, value: groupsList[0].id},
          //
          // }, async () => {
          //     await this.getGroupConnection({group_id: this.state.selectedGroup ? this.state.selectedGroup.value : groupsList[0].id});
          // });
        }
        break
    }
  }

  setColumnsFilter = (filter) => {
    const { selectedGroup } = this.state
    const { path } = this.props.match
    this.setState(
      {
        filterValues: {
          ...filter,
          standards: filter.standards.map((s) => s.value),
        },
        tableState: JSON.parse(JSON.stringify(initialTableState)),
      },
      async (_) => {
        await this.props.clearRecordsList()
        switch (path) {
          case routes.INVENSTORY:
            await this.getTableData({
              user_id: parseInt(this.props.auth.userData.id),
            })
            break
          case routes.INVENSTORY_GROUPS:
            await this.getTableData({
              group_id: selectedGroup ? selectedGroup.value : undefined,
            })
            break
        }
      }
    )
  }

  setTableQuery = (e) => {
    this.setState(
      {
        tableSearchQuery: e.target.value,
      },
      () => this.processTableSearch()
    )
  }

  processTableSearch = debounce(async () => {
    const { selectedGroup } = this.state
    const { path } = this.props.match
    if (this.props.invenstory.view !== "table") {
      await this.props.clearRecordsList()
    }
    switch (path) {
      case routes.INVENSTORY:
        await this.getTableData({
          user_id: parseInt(this.props.auth.userData.id),
        })
        break

      case routes.INVENSTORY_GROUPS:
        await this.getTableData({
          group_id: selectedGroup ? selectedGroup.value : undefined,
        })
        break

      default:
        return
    }
  }, 500)

  nextPage = async () => {
    const { tableState, selectedGroup } = this.state
    const { path } = this.props.match
    switch (path) {
      case routes.INVENSTORY:
        await this.getTableData({
          user_id: parseInt(this.props.auth.userData.id),
          offset: tableState.count,
        })
        break
      case routes.INVENSTORY_GROUPS:
        await this.getTableData({
          group_id: selectedGroup ? selectedGroup.value : undefined,
          offset: tableState.count,
        })
        break
    }
  }
  prevPage = async () => {
    const { tableState, selectedGroup } = this.state
    const offset = tableState.prevOffset - tableState.countPerPage
    const { path } = this.props.match
    switch (path) {
      case routes.INVENSTORY:
        await this.getTableData({
          user_id: parseInt(this.props.auth.userData.id),
          offset: offset > 0 ? offset : 0,
        })
        break
      case routes.INVENSTORY_GROUPS:
        await this.getTableData({
          group_id: selectedGroup ? selectedGroup.value : undefined,
          offset: offset > 0 ? offset : 0,
        })
        break
    }
  }
  lastPage = async () => {
    const { tableState, selectedGroup } = this.state
    const { path } = this.props.match
    switch (path) {
      case routes.INVENSTORY:
        await this.getTableData({
          user_id: parseInt(this.props.auth.userData.id),
          offset: tableState.maxCount - tableState.countPerPage,
        })
        break
      case routes.INVENSTORY_GROUPS:
        await this.getTableData({
          group_id: selectedGroup ? selectedGroup.value : undefined,
          offset: tableState.maxCount - tableState.countPerPage,
        })
        break
    }
  }
  firstPage = async () => {
    const { selectedGroup } = this.state
    const { path } = this.props.match
    switch (path) {
      case routes.INVENSTORY:
        await this.getTableData({
          user_id: parseInt(this.props.auth.userData.id),
        })
        break
      case routes.INVENSTORY_GROUPS:
        await this.getTableData({
          group_id: selectedGroup ? selectedGroup.value : undefined,
        })
        break
    }
  }

  getGroupsList = async (offset, name) => {
    if (!this.state.dataReceived) return
    this.setState({ dataReceived: false })
    const user_id = this.props.auth.userData.id
    try {
      await Promise.all([
        this.props.getGroupsList({
          user_id,
          type: 4,
          limit: 10,
          managed: true,
          offset,
          name,
          active: true,
        }),
        this.props.getGroupsList({
          user_id,
          type: 5,
          limit: 10,
          managed: true,
          offset,
          name,
          active: true,
        }),
      ])
      const list = {
        4: this.props.groups.list[4] || [],
        5: this.props.groups.list[5] || [],
      }
      const groupsList = [...list[5], ...list[4]]
      this.setState({
        groupsList,
        hideGroupsInvenstory: !groupsList.length,
        dataReceived: true,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  getTableData = async ({ group_id, user_id, offset = 0 } = {}) => {
    if (!this.state.dataReceived) return
    this.setState({ dataReceived: false })
    const {
      groupsList,
      includeSelectedGroup,
      selectedOptions,
      tableState,
      filterValues,
      tableSearchQuery,
    } = this.state
    const group = groupsList.find((g) => g.id === group_id)
    if (!group_id && !user_id) return
    const { subjects, grades, standards } = filterValues
    try {
      await this.props.getInvenstoryList({
        name: tableSearchQuery || undefined,
        group_id,
        group_ids: group
          ? group.type === 5
            ? includeSelectedGroup
              ? [group_id, ...selectedOptions]
              : selectedOptions
            : includeSelectedGroup
              ? [group_id]
              : undefined
          : undefined,
        user_ids: group && group.type === 4 ? selectedOptions : undefined,
        user_id,
        offset,
        limit: tableState.countPerPage,
        subjects: subjects.length ? subjects : undefined,
        grades: grades.length ? grades : undefined,
        standards: standards.length ? standards : undefined,
      })
      const tableData = this.props.invenstory.recordsList
      const length = this.props.invenstory.recordsList.length
      tableState.count = offset + length
      tableState.startRange = offset + 1
      tableState.prevOffset = offset
      const page = Math.ceil(tableState.count / tableState.countPerPage)
      const recordsCount = this.props.invenstory.recordsCount
      tableState.maxCount = recordsCount
      tableState.page = page > 0 ? page : 1
      this.setState({
        tableData,
        tableState,
        dataReceived: true,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  onScroll = async () => {
    if (!this.state.listDataReceived) return
    this.setState({ listDataReceived: false })
    const { selectedGroup, tableData } = this.state
    await this.getTableData({
      user_id:
        this.props.match.path === routes.INVENSTORY
          ? parseInt(this.props.auth.userData.id)
          : undefined,
      group_id:
        this.props.match.path === routes.INVENSTORY_GROUPS && selectedGroup
          ? selectedGroup.value
          : undefined,
      offset: tableData.length,
    })
    this.setState({ listDataReceived: true })
  }

  handleRequestsQueryChange = (event) => {
    this.setState({
      accessRequestQuery: event.target.value,
    })
  }

  processRequestsSearch = (event) => {
    event.preventDefault()
    const { history } = this.props

    const searchParams = {
      q: this.state.accessRequestQuery || undefined,
    }

    const result = qs.stringify(searchParams, { arrayFormat: "comma" })

    history.push({
      search: `?${result}`,
    })
  }

  processGroupSearch = async (name) => {
    try {
      this.props.clearGroupsList()
      await this.getGroupsList(0, name || undefined)
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  getGroupConnection = async ({ group_id, offset } = {}) => {
    try {
      await this.props.getConnectionsList({ group_id, type: 2, offset })

      const selectedGroupConnections = this.props.invenstory.connectionsList
      this.setState(
        {
          selectedGroupConnections,
          tableState: JSON.parse(JSON.stringify(initialTableState)),
        },
        async () => await this.getTableData({ group_id })
      )
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  setErr = (val) => {
    this.setState({ noResults: val })
  }

  setSelectedGroup = async (group) => {
    this.setState({
      selectedGroup: group,
      selectedOptions: [],
    })
    this.props.clearInvenstory()
    this.props.clearRecordsList()
    this.setState({
      tableState: JSON.parse(JSON.stringify(initialTableState)),
    })
    await this.getGroupConnection({ group_id: group.value })
    await this.getTableData({ group_id: group.value })
  }

  onMultiSelectInputChange = async (val) => {
    this.setState({
      multiSelectInputValue: val,
    })
    await this.processGroupSearch(val)
  }

  setOrRemoveSelectedGroupOnRequest = (e) => {
    const { selectedGroup } = this.state
    this.setState(
      {
        includeSelectedGroup: !!e.target.checked,
      },
      (_) => this.getTableData({ group_id: selectedGroup.value })
    )
  }

  onMultiSelectScroll = async () => {
    await this.getGroupsList(
      this.state.groupsList.length,
      this.state.inputValue
    )
  }

  setSelectedOptions = (e, option) => {
    const { selectedOptions, selectedGroup } = this.state
    let newOptions
    if (e.target.checked) {
      selectedOptions.push(option)
      newOptions = [...selectedOptions]
    } else {
      newOptions = selectedOptions.filter((o) => o !== option)
    }
    this.setState(
      {
        selectedOptions: newOptions,
        tableState: JSON.parse(JSON.stringify(initialTableState)),
      },
      (_) => this.getTableData({ group_id: selectedGroup.value })
    )
  }

  setStandardsName = (standardsName) => {
    this.setState(
      {
        standardsName,
        standardsDataReceived: false,
        standards: [],
      },
      this.searchStandards
    )
  }

  searchStandards = async () => {
    const {
      standardsName,
      filterValues: { grades, subjects },
    } = this.state
    await this.getStandards({ standardsName, grades, subjects })
  }

  getStandards = async ({
    standardsName,
    grades = [],
    limit = 20,
    offset = 0,
  }) => {
    this.setState({ standardsDataReceived: false })
    const { standards } = this.state
    try {
      const response = await call(STANDARDS.SEARCH_LIST, {
        token: this.props.auth.userData.token,
        name: standardsName || undefined,
        grades: grades.length ? grades : undefined,
        limit,
        offset,
      })
      this.setState({
        standardsDataReceived: true,
        standards: [...standards, ...response.data.standards],
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  onStandardsScroll = async () => {
    if (!this.state.standardsDataReceived) return
    const {
      standardsName,
      filterValues: { grades },
      standards,
    } = this.state
    await this.getStandards({
      standardsName,
      grades,
      offset: standards.length,
    })
  }

  handleProductClose = () => {
    this.setState({ productOpen: false })
  }

  createProduct = (recordId) => {
    const record = this.state.tableData.find((r) => r.id === recordId)
    this.setState({ fieldsToProduct: record, productOpen: true })
  }

  switchView = async (view) => {
    await this.props.clearRecordsList()
    await this.props.switchInvenstoryView(view)
    const { selectedGroup } = this.state
    await this.getTableData({
      user_id:
        this.props.match.path === routes.INVENSTORY
          ? parseInt(this.props.auth.userData.id)
          : undefined,
      group_id:
        this.props.match.path === routes.INVENSTORY_GROUPS && selectedGroup
          ? selectedGroup.value
          : undefined,
    })
  }

  render() {
    const iconStyle = { color: "#9b9a9b" }
    const {
      noResults,
      accessRequestQuery,
      selectedGroup,
      selectedOptions,
      groupsList,
      hideGroupsInvenstory,
      selectedGroupConnections,
      dataReceived,
      tableData,
      includeSelectedGroup,
      tableState,
      tableSearchQuery,
      standards,
      standardsDataReceived,
      standardsName,
      productOpen,
      fieldsToProduct,
    } = this.state
    const renderGroups = groupsList
      .filter((g) => !g.deleted)
      .map((g) => {
        g.name = g.name.length > 40 ? `${g.name.slice(0, 40)}...` : g.name
        return g
      })
    const renderConnections = selectedGroupConnections.filter(
      (c) =>
        c.type !== 1 &&
        (c.group
          ? !c.group.deleted && !c.group.blocked
          : !c.user.deleted && !c.user.blocked)
    )
    const {
      location: { search },
    } = this.props
    const { q: query } = qs.parse(search)

    const noResultsTableLabel = tableSearchQuery
      ? "Result not found"
      : "No records yet"
    return (
      <Container>
        <SEO title="Invenstory" />
        <HeaderContainer>
          <InvenstoryHeader
            bottomRadius={this.props.match.path !== routes.INVENSTORY_GROUPS}
            hideGroupsInvenstory={hideGroupsInvenstory}
            updateData={async () => {
              await this.props.clearRecordsList()
              await this.getTableData({
                offset: tableState.prevOffset,
                user_id:
                  this.props.match.path === routes.INVENSTORY
                    ? parseInt(this.props.auth.userData.id)
                    : undefined,
                group_id:
                  this.props.match.path === routes.INVENSTORY_GROUPS &&
                  selectedGroup
                    ? selectedGroup.value
                    : undefined,
              })
            }}
          />
          {this.props.match.path === routes.INVENSTORY_ACCESS && (
            <div className="box__content">
              <form onSubmit={this.processRequestsSearch}>
                <TextField
                  className="search-header__input full-width"
                  id="search-header-input"
                  placeholder={"Start typing school name"}
                  margin="none"
                  autoComplete="off"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlined style={iconStyle} />
                      </InputAdornment>
                    ),
                  }}
                  value={accessRequestQuery}
                  onChange={this.handleRequestsQueryChange}
                />
              </form>
              {noResults && (
                <div className="aic color-black-54 f jcc pt15">
                  {query
                    ? "Result not found"
                    : "You don`t have access request yet"}
                </div>
              )}
            </div>
          )}
          {this.props.match.path === routes.INVENSTORY_GROUPS && (
            <GroupsFormContainer>
              <div className="form">
                <div className="form__fieldset">
                  <label htmlFor="groupName" className="form__label">
                    Group name
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input form__input--select">
                      <MultiSelect
                        list={renderGroups}
                        id="groupName"
                        defaultValue={selectedGroup}
                        placeholder="Select group"
                        onScroll={this.onMultiSelectScroll}
                        onChange={this.setSelectedGroup}
                        onInputChange={this.onMultiSelectInputChange}
                        emptyArrayMessage={
                          dataReceived ? <span>No results</span> : <Loader />
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="form__fieldset">
                  <span className="form__label"></span>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <FormControl component="fieldset" className="">
                        {selectedGroup && (
                          <FormGroup>
                            {selectedGroup.label.length > 40
                              ? `${selectedGroup.label.slice(0, 40)}...`
                              : selectedGroup.label}
                            <FormControlLabel
                              key={"all"}
                              control={
                                <Checkbox
                                  checked={includeSelectedGroup}
                                  className="options"
                                  onChange={
                                    this.setOrRemoveSelectedGroupOnRequest
                                  }
                                />
                              }
                              label={
                                selectedGroup.label.length > 40
                                  ? `${selectedGroup.label.slice(0, 40)}...`
                                  : selectedGroup.label
                              }
                            />
                            {renderConnections.map((v) => {
                              const name = v.group
                                ? v.group.name
                                : `${v.user.first_name} ${v.user.last_name}`
                              const value = v.group ? v.group.id : v.user.id
                              return (
                                <FormControlLabel
                                  key={v}
                                  control={
                                    <Checkbox
                                      checked={
                                        !!selectedOptions.find(
                                          (o) => o === value
                                        )
                                      }
                                      onChange={(e) => {
                                        this.setSelectedOptions(e, value)
                                      }}
                                    />
                                  }
                                  label={
                                    name.length > 40
                                      ? `${name.slice(0, 40)}...`
                                      : name
                                  }
                                />
                              )
                            })}
                          </FormGroup>
                        )}
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
            </GroupsFormContainer>
          )}
        </HeaderContainer>

        <Switch>
          <Route
            exact
            path={routes.INVENSTORY}
            render={(props) => (
              <InvenstoryTable
                {...props}
                data={tableData}
                updateData={async () => {
                  await this.props.clearRecordsList()
                  await this.getTableData({
                    offset: tableState.prevOffset,
                    user_id: parseInt(this.props.auth.userData.id),
                  })
                }}
                processTableSearch={this.setTableQuery}
                setFilterValues={this.setColumnsFilter}
                tableSearchQuery={tableSearchQuery}
                tableState={tableState}
                standards={standards}
                standardsDataReceived={standardsDataReceived}
                standardsName={standardsName}
                onStandardsScroll={this.onStandardsScroll}
                setStandardsName={this.setStandardsName}
                tableControls={{
                  nextPage: this.nextPage,
                  prevPage: this.prevPage,
                  lastPage: this.lastPage,
                  firstPage: this.firstPage,
                }}
                initialRender={this.state.initialRender}
                noResultsLabel={noResultsTableLabel}
                createProduct={this.createProduct}
                view={this.props.invenstory.view}
                switchView={this.switchView}
                onScroll={this.onScroll}
                listDataReceived={this.state.listDataReceived}
              />
            )}
          />
          <Route
            exact
            path={routes.INVENSTORY_GROUPS}
            render={(props) => {
              if (!selectedGroup) return null
              return (
                <InvenstoryTable
                  {...props}
                  data={tableData}
                  updateData={async () => {
                    await this.props.clearRecordsList()
                    await this.getTableData({
                      offset: tableState.prevOffset,
                      group_id: selectedGroup ? selectedGroup.value : undefined,
                    })
                  }}
                  setFilterValues={this.setColumnsFilter}
                  processTableSearch={this.setTableQuery}
                  tableSearchQuery={tableSearchQuery}
                  tableState={tableState}
                  standards={standards}
                  selectedGroupId={
                    selectedGroup ? selectedGroup.value : undefined
                  }
                  standardsDataReceived={standardsDataReceived}
                  standardsName={standardsName}
                  onStandardsScroll={this.onStandardsScroll}
                  setStandardsName={this.setStandardsName}
                  tableControls={{
                    nextPage: this.nextPage,
                    prevPage: this.prevPage,
                    lastPage: this.lastPage,
                    firstPage: this.firstPage,
                  }}
                  initialRender={this.state.initialRender}
                  noResultsLabel={noResultsTableLabel}
                  createProduct={this.createProduct}
                  view={this.props.invenstory.view}
                  switchView={this.switchView}
                  onScroll={this.onScroll}
                  listDataReceived={this.state.listDataReceived}
                />
              )
            }}
          />
          <Route
            exact
            path={routes.INVENSTORY_ACCESS}
            render={(props) => (
              <InvenstoryAccessRequests
                {...props}
                setNoResults={this.setErr}
                noResults={noResults}
              />
            )}
          />
        </Switch>
        <ProductCreate
          open={productOpen}
          fields={fieldsToProduct}
          onClose={this.handleProductClose}
        />
      </Container>
    )
  }
}

const mapState = ({ groups, invenstory, auth }) => ({
  groups,
  invenstory,
  auth,
})
const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      getConnectionsList,
      clearInvenstory,
      getGroupsList,
      clearGroupsList,
      getInvenstoryList,
      clearRecordsList,
      switchInvenstoryView,
    },
    dispatch
  )

export default withRouter(
  connect(mapState, mapDispatch)(withSnackbar(Invenstory))
)
