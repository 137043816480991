import useEntityPicker from "../../../../hooks/useEntityPicker"
import { useEffect } from "react"
import PickerSelect from "../select/pickerSelect"
import { SEGMENTS } from "../../../../library/store/actions/types/groupsTypes"

const SegmentPicker = ({
  value,
  onChange,
  scheduleId,
  hasError = false,
  errorMessage,
  required = true,
  multiple = false,
  emptyListMessage,
  emptyListLink,
  disabled = false,
  isWaitingForOtherFieldsMessage,
  grade,
}) => {
  const { fetchList, list, dataReceived, isWaitingForOtherFields } =
    useEntityPicker({
      fetchConfig: {
        apiUrl: SEGMENTS.LIST,
        params: {
          schedule_id: scheduleId,
          offset: 0,
          limit: 100,
          grade,
        },
      },
      key: "segments",
      loadOnMount: !!scheduleId,
    })

  useEffect(() => {
    if (scheduleId) {
      fetchList()
    }
  }, [scheduleId, grade])

  const entityName = multiple ? "segments" : "segment"

  return (
    <PickerSelect
      name={"segment"}
      entityName={entityName}
      value={value}
      onChange={onChange}
      required={required}
      hasError={hasError}
      errorMessage={errorMessage}
      disabled={disabled || !dataReceived}
      multiple={multiple}
      list={list}
      isWaitingForOtherFields={isWaitingForOtherFields}
      isWaitingForOtherFieldsMessage={
        isWaitingForOtherFieldsMessage || "First select schedule"
      }
      emptyListMessage={emptyListMessage}
      emptyListLink={emptyListLink}
    />
  )
}

export default SegmentPicker
