import React from "react"
import getErrorText from "../../../../library/constants/errorTypes"
import logoDefault from "../../../../assets/img/product_unavailable.svg"
import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"

const ProductError = ({ product }) => {
  const restoreProduct = async () => {
    try {
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  return (
    <div className="common-page__content common-page__content--static">
      <main className="common-page__main">
        <div className="box product-header">
          <div className="box__heading">
            <h1>{product.name}</h1>
          </div>
          <div className="box__content">
            {product.blocked && <p className="text-center">Product blocked</p>}
            {product.deleted && <p className="text-center">Product deleted</p>}
          </div>
        </div>
        <div className="box p30 text-center">
          {product.deleted && (
            <p className="color-black-54">This product has been deleted.</p>
          )}
          {product.blocked && (
            <p className="color-black-54">This product has been blocked.</p>
          )}
          {product.is_creator ? (
            <p className="color-black-54">
              You can{" "}
              <Link
                to={generatePath(routes.CONTACT_FORM)}
                className="link link--inline"
                onClick={restoreProduct}
              >
                restore your product.
              </Link>
            </p>
          ) : (
            <p className="color-black-54">
              Information on this product is unavailable.
            </p>
          )}
        </div>
      </main>
      <aside className="common-page__sidebar">
        <div className="box">
          <div className="box__content">
            <img src={logoDefault} alt="Product deleted" />
          </div>
        </div>
      </aside>
    </div>
  )
}

export default ProductError
