import React, { Component } from "react"
import VerticalMenu from "../../../../../components/ui/verticalMenu"
import FormControl from "@material-ui/core/FormControl/FormControl"
import FormGroup from "@material-ui/core/FormGroup/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox/Checkbox"
import MoreDropdown from "../../../../../components/ui/dropdowns/moreDropdown/moreDropdown"
import getErrorText from "../../../../../library/constants/errorTypes"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { bindActionCreators } from "redux"
import Loader from "../../../../../components/ui/loader/loader"
import KeyboardArrowUpOutlined from "@material-ui/icons/KeyboardArrowUpOutlined"
import KeyboardArrowDownOutlined from "@material-ui/icons/KeyboardArrowDownOutlined"
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener"
import * as _ from "lodash"

class BookmarkedItemDropdown extends Component {
  state = {
    foldersList: [],
    listOpen: false,
    dataReceived: false,
  }

  handleCheckboxChange = (name) => (event) => {
    this.setState(
      {
        [name]: event.target.checked,
      },
      () => this.manageFolders()
    )
  }

  composeValues = () => {
    const obj = this.state

    return _.map(
      _.keys(obj).filter(
        (key) =>
          !["foldersList", "listOpen", "dataReceived"].includes(key) &&
          obj[key] === true
      ),
      _.parseInt
    )
  }

  manageFolders = async () => {
    try {
      await this.props.addToFolder(this.composeValues())
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  openFoldersList = async () => {
    this.setState(
      (prevState) => {
        return { listOpen: !prevState.listOpen }
      },
      async () => {
        if (this.state.listOpen) {
          await this.props.bookmarkFoldersList()
          this.props.bookmarks.currentBookmarkFolders.map((item) => {
            this.setState({ [String(item.id)]: item.is_checked })
          })
          this.setState({
            foldersList: this.props.bookmarks.currentBookmarkFolders,
            dataReceived: true,
          })
        } else {
          this.setState({
            foldersList: [],
            dataReceived: false,
          })
        }
      }
    )
  }

  handleClickOutside = (event) => {
    this.setState({ dataReceived: false, listOpen: false })
  }

  render() {
    const { foldersList, listOpen, dataReceived } = this.state

    const dropdownMenuItems = [
      {
        type: "button",
        action: this.props.deleteBookmark,
        label: "Remove from bookmarks",
      },
      !!this.props.bookmarks.folders.length && { type: "h-divider", id: "hd1" },
    ]

    return (
      <ClickAwayListener onClickAway={this.handleClickOutside}>
        <MoreDropdown>
          <VerticalMenu
            menuItems={dropdownMenuItems}
            classes={"more-dropdown-menu__list"}
          >
            {!!this.props.bookmarks.folders.length && (
              <div className="pb15 pl15 pr15">
                <div
                  role="button"
                  className="f aic color-primary pointer"
                  onClick={this.openFoldersList}
                >
                  Folders{" "}
                  {listOpen ? (
                    <KeyboardArrowUpOutlined />
                  ) : (
                    <KeyboardArrowDownOutlined />
                  )}
                </div>

                {listOpen &&
                  (dataReceived ? (
                    <FormControl component="fieldset">
                      <FormGroup>
                        {foldersList.map((item) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state[String(item.id)]}
                                onChange={this.handleCheckboxChange(
                                  String(item.id)
                                )}
                                value={String(item.id)}
                              />
                            }
                            label={
                              <span
                                className="break-all"
                                style={{
                                  display: "block",
                                  maxWidth: "200px",
                                }}
                              >
                                {item.name}
                              </span>
                            }
                            key={item.id}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  ) : (
                    <Loader size={24} />
                  ))}
              </div>
            )}
          </VerticalMenu>
        </MoreDropdown>
      </ClickAwayListener>
    )
  }
}

const mapStateToProps = ({bookmarks}) => ({bookmarks});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(BookmarkedItemDropdown));
