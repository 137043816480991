import React from "react"
import FavouriteOutlined from "@material-ui/icons/FavoriteOutlined"
import FavouriteBorderedOutlined from "@material-ui/icons/FavoriteBorderOutlined"
import ChatBubbleOutlineOutlined from "@material-ui/icons/ChatBubbleOutlineOutlined"
import ReplyOutlined from "@material-ui/icons/ReplyOutlined"
import { usePostCtx } from "../PostContext"
import { useCurrentUser } from "../../../../../hooks/data/user/useUser"
import { usePostsMutations } from "../../../../../hooks/data/feed/posts"
import { useLikesMutations } from "../../../../../hooks/data/likes"
import { mutationTypes } from "../../../../../library/utils/reactQuery"

const PostFooter = () => {
  const { post, isMine, queryKey, setIsCommenting } = usePostCtx()
  const { id: currentUserId } = useCurrentUser()

  const { likes } = useLikesMutations({
    key: queryKey,
    mutationType: mutationTypes.infinite,
  })
  const { share } = usePostsMutations({ queryKey })

  const onLike = async () => {
    if (!post.is_liked) {
      await likes.create.mutateAsync({
        data: { post_id: post.id },
        updater: (p) =>
          p.id === post.id
            ? {
                ...p,
                is_liked: true,
                likes_number: p.likes_number + 1,
              }
            : p,
      })
    } else {
      await likes.delete.mutateAsync({
        data: { post_id: post.id },
        updater: (p) =>
          p.id === post.id
            ? {
                ...p,
                is_liked: false,
                likes_number: p.likes_number - 1,
              }
            : p,
      })
    }
  }

  return (
    <div className="post__footer">
      <button type={"button"} className="post__social-control" onClick={onLike}>
        {post.is_liked ? (
          <FavouriteOutlined className="color-primary" />
        ) : (
          <FavouriteBorderedOutlined />
        )}
        {!!post.likes_number && <span>{post.likes_number}</span>}
      </button>
      <button
        type={"button"}
        className="post__social-control"
        onClick={() => setIsCommenting(true)}
      >
        <ChatBubbleOutlineOutlined
          className={post.is_commented ? "color-primary" : ""}
        />
        {!!post.comments_number && <span>{post.comments_number}</span>}
      </button>
      {!isMine &&
        !(post.origin_user && post.origin_user.id === currentUserId) && (
          <button
            type={"button"}
            className="post__social-control"
            onClick={() => share.mutateAsync({ post_id: post.id })}
          >
            <ReplyOutlined
              className={`${post.is_shared ? "color-primary" : ""} flip-h`}
            />
            {!!post.shares_number && <span>{post.shares_number}</span>}
          </button>
        )}
    </div>
  )
}

export default PostFooter
