import React from "react"
import { useCurrentUser } from "../../../../hooks/data/user/useUser"
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import SendOutlined from "@material-ui/icons/SendOutlined"
import { useGroup } from "../../../../hooks/data/groups/groups"
import { usePostCtx } from "../post/PostContext"
import styled from "@emotion/styled"
import { colors } from "../../../../library/constants/styles/colors"
import { devices } from "../../../../library/constants/styles/responsive"
import { Form, Formik } from "formik"
import * as yup from "yup"
import { useAlert } from "../../../../hooks/useAlert"
import { TextField } from "@material-ui/core"
import {
  useCommentsList,
  useCommentsMutations,
} from "../../../../hooks/data/feed/comments"
import Loader from "../../../ui/loader"
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress"
import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import ClearOutlined from "@material-ui/icons/ClearOutlined"
import { getUserName } from "../../../../library/utils/user/userUtils"
import PlainLink from "../../../ui/link"
import { PlainButton } from "../../controls"
import { useCommentCtx } from "../comments/CommentContext"

const DropdownContainer = styled.div`
  position: relative;
  margin-right: 10px;
`

const DropdownBtn = styled.button`
  display: flex;
  color: ${colors.black38};
`

const DropdownContentWrapper = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  left: -10px;
  z-index: 1;
`

const DropdownContent = styled.div`
  position: relative;
  min-width: 195px;
  padding: 15px;
  background-color: ${colors.white};
  box-shadow: 0 0 2px 0 rgba(210, 210, 210, 0.5);
  border-radius: 2px;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -6px;
    left: 14px;
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
    border-radius: 1px;
    background-color: ${colors.white};
    box-shadow: 0 0 2px 0 rgba(210, 210, 210, 0.5);

    @media ${devices.xs} {
      right: 12px;
    }
  }
`

const validationSchema = yup.object({
  message: yup.string().max(5000).required(),
})

const ReplyCreate = () => {
  const { errorAlert } = useAlert()

  const { post, queryKey } = usePostCtx()
  const { comment, setIsReplying, replyFormId } = useCommentCtx()
  const { group, groupLogo } = useGroup()
  const { avatar, fullName } = useCurrentUser()

  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false)
  const [shouldCreateFromGroup, setShouldCreateFromGroup] =
    React.useState(false)

  const creatorPhoto = !!group && shouldCreateFromGroup ? groupLogo : avatar
  const creatorName = !!group && shouldCreateFromGroup ? group.name : fullName

  const recipientUrl = !!comment.group
    ? generatePath(routes.GROUP, { groupId: comment.group.id })
    : generatePath(routes.USER, { userId: comment.user.id })
  const recipientName = !!comment.group
    ? comment.group.name
    : getUserName(comment.user)

  const initialValues = {
    message:
      (comment.group ? comment.group.name : comment.user.first_name) + ", ",
  }

  const { createComment } = useCommentsMutations({
    queryKey,
  })

  const { refetch: refetchComments } = useCommentsList({
    postId: post.id,
    commentId: comment.id,
    queryKey,
    options: { enabled: false },
  })

  const onSubmit = async ({ message }, { resetForm }) => {
    try {
      await createComment.mutateAsync({
        post_id: post.id,
        comment_id: comment.id,
        message,
        personal: !shouldCreateFromGroup,
      })
      await refetchComments()
      resetForm()
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <div id={replyFormId}>
      <div className="post-reply-field-header">
        <div className="post-reply-field-header__text">
          Reply to{" "}
          <PlainLink to={recipientUrl} inline newTab>
            {recipientName}
          </PlainLink>
        </div>
        <PlainButton onClick={() => setIsReplying(false)}>
          <ClearOutlined /> Cancel
        </PlainButton>
      </div>
      <div className="post-reply-field">
        <img
          src={creatorPhoto}
          alt={creatorName}
          className="post-comment-field__avatar"
        />

        {group?.is_admin ||
          (group?.is_creator && (
            <ClickAwayListener onClickAway={() => setIsDropdownOpen(false)}>
              <DropdownContainer>
                <DropdownBtn
                  type="button"
                  onClick={() => setIsDropdownOpen((prevState) => !prevState)}
                >
                  {isDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </DropdownBtn>
                {isDropdownOpen && (
                  <DropdownContentWrapper>
                    <DropdownContent>
                      <FormControlLabel
                        control={
                          <Checkbox
                            autoFocus
                            checked={shouldCreateFromGroup}
                            onChange={(e) =>
                              setShouldCreateFromGroup(e.target.checked)
                            }
                            value={"Reply as group"}
                          />
                        }
                        label="Reply as group"
                      />
                    </DropdownContent>
                  </DropdownContentWrapper>
                )}
              </DropdownContainer>
            </ClickAwayListener>
          ))}

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(fk) => (
            <Form className={"f full-width"}>
              <TextField
                name={"message"}
                placeholder="Add your reply…"
                multiline
                value={fk.values.message}
                onChange={fk.handleChange}
                fullWidth
                margin="none"
                variant="outlined"
                className={"flex-1"}
                autoFocus
                onFocus={(e) => {
                  const len = fk.values.message.length
                  e.target.setSelectionRange(len, len)
                }}
              />
              <button
                type={"submit"}
                className="post-reply-field__send"
                disabled={createComment.isLoading}
              >
                {!createComment.isLoading ? (
                  <SendOutlined />
                ) : (
                  <CircularProgress size={24} />
                )}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default ReplyCreate
