export const KNOWCREDS = {
    CREATE: "knowcreds.create",
    EDIT: "knowcreds.update",
    DELETE: "knowcreds.delete",
    GET: "knowcreds.get",
    CLEAR: "knowcreds.clear",
    CLEAR_CREATED: "knowcreds.clear.created",

    FOREGROUNDS_LIST: "knowcreds.foregrounds.list",
    BACKGROUNDS_LIST: "knowcreds.backgrounds.list",

    LIKES: {
        CREATE: "likes.create",
        DELETE: "likes.delete",
    },

    MY_LIST: "knowcreds.my_list",
    SHARED_LIST: "knowcreds.shared_list",
    MY_LAST_LIST: "knowcreds.my_last_list",
    MY_LAST_LIST_CLEAR: "knowcreds.my_last_list_clear",

    LIST: "knowcreds.list",
    LIST_CLEAR: "knowcreds.list_clear",
    LAST_LIST: "knowcreds.last_list",
    LAST_LIST_CLEAR: "knowcreds.last_list_clear",

    UPDATE_PUBLIC_STATUS: "knowcreds.update_public_status",
};
