import React from "react"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import Loader from "../../../../../../../components/ui/loader"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import Dialog from "@material-ui/core/Dialog/Dialog"
import { weekDays } from "../../../../../../../library/utils/timeConvert"
import { getLocalePeriod } from "../../../../../../../library/utils/timeString/timeString"
import { useHold } from "../../../../../../../hooks/data/calendar/useHold"

const CalendarHoldInfoDialog = ({
  open,
  onClose,
  activeHold,
  user = false,
}) => {
  const { hold } = useHold(activeHold, {}, user)

  console.log(hold)

  return (
    <Dialog
      aria-labelledby="calendar-hold-dialog"
      onClose={onClose}
      open={open}
    >
      <DialogTitle id="hold-title" className="text-center">
        Hold details
      </DialogTitle>
      <DialogContent>
        {!!hold ? (
          <div className="f jcc full-width">
            <table className="product-details product-details--invenstory-record">
              <tbody>
                <tr className="product-details__row">
                  <td className="product-details__label">Name</td>
                  <td className="product-details__value">{hold.name}</td>
                </tr>

                {!!hold.days_of_week.length && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Days</td>
                    <td className="product-details__value">
                      {hold.days_of_week.map((d) => weekDays[d]).join(", ")}
                    </td>
                  </tr>
                )}

                <tr className="product-details__row">
                  <td className="product-details__label">Time</td>
                  <td className="product-details__value">
                    {!hold.is_all_day
                      ? getLocalePeriod(hold.time_from, hold.time_to)
                      : "All day"}
                  </td>
                </tr>

                {!!hold.breaks?.length && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Break(s)</td>
                    <td className="product-details__value">
                      <div>
                        {hold.breaks.map((b, i) => (
                          <p key={i}>{getLocalePeriod(b[0], b[1])}</p>
                        ))}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <Loader />
        )}
      </DialogContent>
      <DialogActions>
        <Button type="submit" color="primary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CalendarHoldInfoDialog
