import React from "react";
import { useReplyCtx } from "../ReplyContext";

const ReplyRestore = () => {
  const { onRestore } = useReplyCtx()

  return (
    <div className="post">
      <div className="f jcc aic">
        <div>
          Comment deleted.{" "}
          <span
            className="post__restore"
            onClick={onRestore}
          >
            Undo.
          </span>
        </div>
      </div>
    </div>
  );
};

export default ReplyRestore;
