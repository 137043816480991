import React from "react"
import styled from "@emotion/styled"
import { colors } from "../../../../../../library/constants/styles/colors"
import { generatePath, Link } from "react-router-dom"
import PlainLink from "../../../../../../components/ui/link"
import { KeyboardArrowRight, LinkOutlined } from "@material-ui/icons"
import InsertDriveFile from "@material-ui/icons/InsertDriveFile"
import * as routes from "../../../../../../library/constants/routes"
import productLogoDefault from "../../../../../../assets/img/product.svg"
import returnExternalUrl from "../../../../../../library/utils/returnExternalUrl"

const Container = styled.div`
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 2px;
  background-color: ${colors.white};
  box-shadow: 0 0 2px 0 rgba(210, 210, 210, 0.5);

  @supports (display: grid) {
    margin-bottom: 0;
  }
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const Title = styled.div``

const Counter = styled.span`
  color: ${colors.black54};
`

const List = styled.div`
  display: flex;
  //justify-content: space-between;
  padding: 8px 0;

  @supports (display: grid) {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(5, 1fr);
  }

  & > a:not(:last-child) {
    margin-right: 10px;
    @supports (display: grid) {
      margin-right: 0;
    }
`

const ListItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  :not(:last-child) {
    margin-right: 10px;
  }
`

const Image = styled.img`
  width: 127px;
  height: 127px;
  margin-bottom: 8px;
  object-fit: cover;
  object-position: center;
  border-radius: 0;
`

const Name = styled.div`
  width: 127px;
  font-size: 1rem;
  color: ${colors.primary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const LinkContainer = styled.div`
  display: flex;
  width: 100%;
`

const ImageContainer = styled.div`
  width: 127px;
  height: 127px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const GroupMultiTypesHorizontalList = ({
  links = [],
  images = [],
  blobs = [],
  videos = [],
  products = [],
  title,
  id,
  url,
}) => {
  // FIXED
  const composeItems = () => {
    const linksCopy = links
      .slice()
      .map((l) => ({ ...l, type: "link", url: returnExternalUrl(l.url) }))
    const imagesCopy = images.slice().map((i) => ({ ...i, type: "image" }))
    const videosCopy = videos.slice().map((v) => ({ ...v, type: "image" }))
    const blobsCopy = blobs.slice().map((b) => ({ ...b, type: "blobs" }))
    const productsCopy = products.slice().map((p) => ({
      ...p,
      type: "product",
      url: generatePath(routes.PRODUCT, { productId: p.id }),
    }))

    return [
      ...linksCopy,
      ...imagesCopy,
      ...videosCopy,
      ...blobsCopy,
      ...productsCopy,
    ]
      .map((a) => ({
        ...a,
        name: a.name.length > 15 ? a.name.slice(0, 15) + "..." : a.name,
      }))
      .sort((a, b) => {
        return new Date(b.created).getTime() - new Date(a.created).getTime()
      })
  }

  const link = url
    ? url
    : generatePath(routes.GROUP_ATTACHMENTS, { groupId: id })
  const counter = composeItems().length

  return (
    <Container>
      <TitleContainer>
        {/*<Link to={link}><Title>{title} {!!counter && <Counter>{counter}</Counter>}</Title></Link>*/}
        <Link to={link}>
          <Title>{title}</Title>
        </Link>
      </TitleContainer>
      <div className="d-none-xs">
        <List>
          {composeItems()
            .slice(0, 5)
            .map((item) => {
              // item.name = item.name.length > 15 ? item.name.slice(0, 15) + "..." : item.name;
              const image =
                item.type === "image" ? (
                  <Image src={item.url} />
                ) : item.type === "blob" ? (
                  <InsertDriveFile
                    className="post__file-icon"
                    style={{ width: "80px", height: "80px" }}
                  />
                ) : item.type === "product" ? (
                  item.image ? (
                    <Image src={item.image} />
                  ) : (
                    <Image src={productLogoDefault} />
                  )
                ) : (
                  <LinkOutlined
                    style={{
                      width: "80px",
                      height: "80px",
                      fill: "rgba(0,0,0,0.38)",
                    }}
                  />
                )
              const name =
                item.type === "link" ? (
                  <a href={item.url} target="_blank" className="link">
                    {item.name}
                  </a>
                ) : item.type === "product" ? (
                  <Link
                    className="link"
                    to={generatePath(routes.PRODUCT, { productId: item.id })}
                  >
                    {item.name}
                  </Link>
                ) : (
                  <a href={`${item.url}`} className="link" target="_blank">
                    {item.name}
                  </a>
                )
              return (
                <a href={item.url} key={item.id}>
                  <ListItem>
                    <ImageContainer>{image}</ImageContainer>

                    <Name>{name}</Name>
                  </ListItem>
                </a>
              )
            })}
        </List>
      </div>
      <LinkContainer>
        {composeItems().length > 5 && (
          <PlainLink to={link}>
            Show all <KeyboardArrowRight />
          </PlainLink>
        )}
      </LinkContainer>
    </Container>
  )
}

export default GroupMultiTypesHorizontalList
