import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  generatePath,
  Link,
  Route,
  Switch,
  useHistory,
  useParams,
} from "react-router-dom"
import * as routes from "../../../../../../../../../../../library/constants/routes"
import { getSchoolCalendarSegmentSection } from "../../../../../../../../../../../library/store/actions/creators/groupsCreators"
import { getGroupLogo } from "../../../../../../../../../../../library/utils/getGroupLogo"
import { useAlert } from "../../../../../../../../../../../hooks/useAlert"
import Loader from "../../../../../../../../../../../components/ui/loader"
import withGroupSettingsGuard from "../../../../../../../../../../../hocs/guards/withGroupSettingsGuard"
import SegmentSectionBlocks from "./segmentSectionBlocks/segmentSectionBlocks"
import SidebarEntityInfo from "../../../../../../../../../../../components/v2/layout/sidebarEntityInfo/sidebarEntityInfo"
import { rotationTypes } from "../../../../../../../../../../../library/constants/groupTypes"
import { weekDaysArray } from "../../../../../../../../../../../library/utils/timeConvert"
import VerticalMenu from "../../../../../../../../../../../components/ui/verticalMenu"
import SegmentSectionTracks from "./segmentSectionTracks/segmentSectionTracks"
import SEO from "../../../../../../../../../../../components/SEO"

const SegmentSection = () => {
  const { currentGroup, currentSection } = useSelector(({ groups }) => groups)

  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const { errorAlert } = useAlert()
  const [dataReceived, setDataReceived] = useState(false)

  useEffect(() => {
    const getData = async () => {
      try {
        await dispatch(
          getSchoolCalendarSegmentSection({
            id: parseInt(params.sectionId, 10),
          })
        )

        // await dispatch(
        //   getSegmentSectionBlocks({
        //     section_id: parseInt(params.sectionId, 10),
        //   })
        // )

        setDataReceived(true)
      } catch (error) {
        errorAlert(error)
      }
    }

    getData()
  }, [])

  const menuItems = [
    {
      path: generatePath(
        routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT_SECTION_TRACKS,
        {
          ...params,
        }
      ),
      type: "parent",
      label: "Tracks",
    },
    {
      path: generatePath(
        routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT_SECTION_BLOCKS,
        { ...params }
      ),
      type: "parent",
      label: "Blocks",
    },
  ]

  const infoRecords = dataReceived
    ? [
        {
          label: "Name",
          value: currentSection.name,
          dividerAfter: true,
        },
        ...currentSection.rotations.map((r, i) => {
          return {
            label: weekDaysArray[i],
            value: rotationTypes.find((t) => t.id === r.type).name,
          }
        }),
      ]
    : []

  return (
    <>
      <div className="common-page__content">
        <SEO title={"Section | Group Settings"} />
        <main className="common-page__main">
          {dataReceived ? (
            <Switch>
              <Route
                exact
                path={
                  routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT_SECTION_BLOCKS
                }
                component={SegmentSectionBlocks}
              />
              <Route
                exact
                path={
                  routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT_SECTION_TRACKS
                }
                component={SegmentSectionTracks}
              />
            </Switch>
          ) : (
            <Loader />
          )}
        </main>
        <aside className="common-page__sidebar">
          <div className="box aside-header">
            <Link
              to={generatePath(
                routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT_SECTIONS,
                { ...params }
              )}
              className="box__content aside-header__content"
            >
              <img
                src={getGroupLogo(currentGroup)}
                className="aside-header__img"
                alt={currentGroup?.name}
              />
              <div className="aside-header__info">
                <div className="aside-header__name">{currentGroup?.name}</div>
                <div className="aside-header__help">back to sections</div>
              </div>
            </Link>
          </div>
          <VerticalMenu menuItems={menuItems} />
          <SidebarEntityInfo
            entityName={"Section"}
            records={infoRecords}
            isLoading={!dataReceived}
          />
        </aside>
      </div>
    </>
  )
}

export default withGroupSettingsGuard(SegmentSection)
