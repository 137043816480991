import React, { Component } from "react"
import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../../../library/constants/routes"
import logoDefault from "../../../../../assets/img/product.svg"
import StarRating from "../../../../../components/ui/starRating"
import { getObjectById } from "../../../../../library/utils/arrays"
import { productContentTypes } from "../../../../../library/constants/productDictionary"
import { withSnackbar } from "notistack"
import BookmarkedItemDropdown from "../bookmarkedItemDropdown/bookmarkedItemDropdown"

class BookmarkedProduct extends Component {
  state = {
    deleted: false,
  }

  deleteBookmark = () => {
    const { product } = this.props
    this.props
      .deleteBookmark({ product_id: product.id })
      .then(this.setState({ deleted: true }))
  }

  render() {
    const { product, user, group } = this.props
    const { deleted } = this.state

    if (!product || deleted) return null

    const creator = product.creator_user
      ? {
          name: `${product.creator_user.first_name} ${product.creator_user.last_name}`,
          url: generatePath(routes.USER, { userId: product.creator_user.id }),
        }
      : product.creator_group
      ? {
          name: product.creator_group.name,
          url: generatePath(routes.GROUP, {
            groupId: product.creator_group.id,
          }),
        }
      : user
      ? {
          name: `${user.first_name} ${user.last_name}`,
          url: generatePath(routes.USER, { userId: user.id }),
        }
      : {
          name: group.name,
          url: generatePath(routes.GROUP, { groupId: group.id }),
        }

    return (
      <div className="product-tile">
        <div className="product-tile__menu">
          <BookmarkedItemDropdown
            deleteBookmark={this.deleteBookmark}
            bookmarkFoldersList={() =>
              this.props.bookmarkFoldersList(product.bookmark.id)
            }
            addToFolder={(folders) =>
              this.props.addToFolder({
                bookmark_id: product.bookmark.id,
                folders,
              })
            }
            bookmarksFoldersListClear={() =>
              this.props.bookmarksFoldersListClear()
            }
          />
        </div>
        <Link
          to={generatePath(routes.PRODUCT, { productId: product.id })}
          className="product-tile__logo"
        >
          <img src={product.image || logoDefault} alt="Product image" />
        </Link>
        <Link
          to={generatePath(routes.PRODUCT, { productId: product.id })}
          className="product-tile__name link"
        >
          {product.name}
        </Link>
        <table className="product-tile__info">
          <tbody>
            <tr>
              <td
                className={`product-tile__info-label ${
                  getObjectById(productContentTypes, product.content_type)
                    ? "pb4"
                    : ""
                }`}
              >
                <span>Creator:</span>
              </td>
              <td
                className={`product-tile__info-value ${
                  getObjectById(productContentTypes, product.content_type)
                    ? "pb4"
                    : ""
                }`}
              >
                <span>
                  <Link to={creator.url} className="link font-weight-500">
                    {creator.name}
                  </Link>
                </span>
              </td>
            </tr>
            <tr>
              <td className="product-tile__info-label">
                <span>
                  {getObjectById(productContentTypes, product.content_type)
                    ? "Content type:"
                    : ""}
                  {!getObjectById(
                    productContentTypes,
                    product.content_type
                  ) && <span style={{ height: "20px" }}></span>}
                </span>
              </td>
              <td className="product-tile__info-value">
                <span>
                  {getObjectById(productContentTypes, product.content_type)
                    ? getObjectById(productContentTypes, product.content_type)
                        .name
                    : ""}
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <StarRating rating={product.rating} />

        <div className="h-divider h-divider--vertical-margin"></div>

        {product.paid && (
          <>
            {!!product.user_price && (
              <div className="product-tile__price">
                <span className="product-tile__price-label">
                  Price per user:
                </span>
                <span className="product-tile__price-value">
                  ${product.user_price}
                </span>
              </div>
            )}
            {!!product.group_price && (
              <div className="product-tile__price">
                <span className="product-tile__price-label">
                  Price per school:
                </span>
                <span className="product-tile__price-value">
                  ${product.group_price}
                </span>
              </div>
            )}
            {!!product.others_price && (
              <div className="product-tile__price">
                <span className="product-tile__price-label">Other price:</span>
                <span className="product-tile__price-value">
                  ${product.others_price}
                </span>
              </div>
            )}
          </>
        )}
        {product.paid &&
          product.user_price === 0 &&
          product.group_price === 0 &&
          product.others_price === 0 && (
            <div className="product-tile__price free">
              <span className="product-tile__price-value">Paid</span>
            </div>
          )}
        {!product.paid && (
          <div className="product-tile__price free">
            <span className="product-tile__price-value">Free</span>
          </div>
        )}
      </div>
    )
  }
}

export default withSnackbar(BookmarkedProduct);
