import { useQuery } from "@tanstack/react-query"
import { SCHEDULE } from "../../../library/store/actions/types/calendarTypes"

export const useUnit = (id) => {
  const { data, isLoading, isError, isSuccess } = useQuery({
    queryKey: [SCHEDULE.UNITS.GET, { id }],
  })

  return {
    unit: data,
    isLoading,
    isError,
    isSuccess,
  }
}
