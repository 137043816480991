import React, { useEffect, useState } from "react"
import {
  generatePath,
  Link,
  Route,
  Switch,
  useHistory,
  useParams,
} from "react-router-dom"
import * as routes from "../../../../../../../../../library/constants/routes"
import { getSchoolCalendarSegment } from "../../../../../../../../../library/store/actions/creators/groupsCreators"
import { useDispatch, useSelector } from "react-redux"
import Loader from "../../../../../../../../../components/ui/loader"
import SegmentSections from "../segmentSections"
import { getGroupLogo } from "../../../../../../../../../library/utils/getGroupLogo"
import { useAlert } from "../../../../../../../../../hooks/useAlert"
import withGroupSettingsGuard from "../../../../../../../../../hocs/guards/withGroupSettingsGuard"
import SidebarEntityInfo from "../../../../../../../../../components/v2/layout/sidebarEntityInfo/sidebarEntityInfo"
import {
  getArrayByIds,
  renderArrayOfNames,
} from "../../../../../../../../../library/utils/arrays"
import { calendarGrades } from "../../../../../../../../../library/constants/educationalDictionary"
import { formatDate } from "../../../../../../../../../library/utils/dateConvert"
import SEO from "../../../../../../../../../components/SEO"

const Segment = () => {
  const params = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const { errorAlert } = useAlert()

  const { currentGroup, currentSegment } = useSelector(
    ({ groups: { currentGroup, currentSegment } }) => ({
      currentGroup,
      currentSegment,
    })
  )

  const [dataReceived, setDataReceived] = useState(false)

  useEffect(async () => {
    try {
      await dispatch(
        getSchoolCalendarSegment({ id: parseInt(params.segmentId, 10) })
      )

      setDataReceived(true)
    } catch (e) {
      errorAlert(e)
    }
  }, [])

  const menuItems = [
    // {
    //   path: generatePath(routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT, {
    //     ...params,
    //   }),
    //   type: 'parent',
    //   label: 'Segment info',
    // },
    {
      path: generatePath(
        routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT_SECTIONS,
        { ...params }
      ),
      type: "parent",
      label: "Sections",
    },
  ]

  const infoRecords = dataReceived
    ? [
        {
          label: "Name",
          value: currentSegment.name,
        },
        {
          label: "Grades",
          value: renderArrayOfNames(
            getArrayByIds(calendarGrades, currentSegment.grades)
          ),
        },
        {
          label: "From",
          value: formatDate(currentSegment.date_from),
        },
        {
          label: "To",
          value: formatDate(currentSegment.date_to),
        },
        {
          label: "Section change period",
          value:
            "Each " +
            currentSegment.section_duration +
            `${currentSegment.section_duration === 1 ? " week" : " weeks"}`,
        },
      ]
    : []

  return (
    <>
      <div className="common-page__content">
        <SEO title={"Segment | Group Settings"} />
        <main className="common-page__main">
          {dataReceived ? (
            <Switch>
              {/*<Route*/}
              {/*  exact*/}
              {/*  path={routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT}*/}
              {/*  component={SegmentInfo}*/}
              {/*/>*/}
              <Route
                exact
                path={routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT_SECTIONS}
                component={SegmentSections}
              />
            </Switch>
          ) : (
            <Loader />
          )}
        </main>
        <aside className="common-page__sidebar">
          <div className="box aside-header">
            <Link
              to={generatePath(routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENTS, {
                ...params,
              })}
              className="box__content aside-header__content"
            >
              <img
                src={getGroupLogo(currentGroup)}
                className="aside-header__img"
                alt={currentGroup?.name}
              />
              <div className="aside-header__info">
                <div className="aside-header__name">{currentGroup?.name}</div>
                <div className="aside-header__help">back to segments</div>
              </div>
            </Link>
          </div>
          {/*<VerticalMenu menuItems={menuItems} />*/}

          <SidebarEntityInfo
            records={infoRecords}
            entityName={"Segment"}
            isLoading={!dataReceived}
          />
        </aside>
      </div>
    </>
  )
}

export default withGroupSettingsGuard(Segment)
