import React from "react"
import styled from "@emotion/styled"
import { colors } from "../../../library/constants/styles/colors"

const Container = styled.div`
  border-radius: 2px;
  background-color: ${colors.white};
  box-shadow: 0 0 2px 0 rgba(210, 210, 210, 0.5);
  padding: 15px;
`

const Box = (props) => {
  return <Container>{props.children}</Container>
}

export default Box
