import React, { Component } from "react"
import "./reset-password-request.scss"
import { bindActionCreators } from "redux"
import { resetPasswordRequest } from "../../../../library/store/actions/creators/authCreators"
import { connect } from "react-redux"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import getErrorText from "../../../../library/constants/errorTypes"
import { withSnackbar } from "notistack"
import Button from "@material-ui/core/Button"

class ResetPasswordRequest extends Component {
  state = {
    email: "",
  }

  processResetPasswordRequest = async () => {
    const { email } = this.state
    try {
      await this.props.resetPasswordRequest(email.toLowerCase().trim())
      this.props.enqueueSnackbar(
        "Your request received. Check your email to reset password.",
        { variant: "success" }
      )
    } catch ({ error }) {
      if (error.code === 20006)
        return this.props.enqueueSnackbar("Invalid email", { variant: "error" })
      error &&
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
    }
  }

  render() {
    const { email } = this.state

    return (
      <div className="sign-up-form hero-form">
        <div className="sign-up-form__header">
          <h2 className="sign-up-form__heading">Reset password</h2>
          <p className="hero-form__description">
            Enter your email address below. We'll look for your account and send
            you a password reset email
          </p>
        </div>
        <ValidatorForm
          className="sign-up-form__form"
          instantValidate={false}
          onSubmit={this.processResetPasswordRequest}
        >
          <div className="sign-up-form__field-wrapper mb20">
            <TextValidator
              type="text"
              name="email"
              value={email}
              label="Email *"
              className="m-text-field"
              margin="none"
              fullWidth
              helperText="&nbsp;"
              validators={["required"]}
              errorMessages={["Field is required"]}
              onChange={(email) => this.setState({ email: email.target.value })}
            />
          </div>

          <Button color="primary" variant="contained" type="submit">
            Send password reset
          </Button>
        </ValidatorForm>
      </div>
    )
  }
}

const mapStateToProps = ({ auth }) => ({ auth })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ resetPasswordRequest }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ResetPasswordRequest))
