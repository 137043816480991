import React from "react"
import { getUserName } from "../../library/utils/user/userUtils"
import styled from "@emotion/styled"
import { useBookMeet } from "./bookMeetContext"
import { addMinutes, format } from "date-fns"
import { getUtcFromZonedDateTime } from "../../library/utils/dateConvert"
import withErrorBoundary from "../../hocs/guards/withErrorBoundary"
import TimezoneLabel from "../../components/timezones/timezoneLabel"

const ImgContainer = styled.div`
  width: 90px;
  height: 90px;
  overflow: hidden;
  border-radius: 50%;
  background-color: #fff;
  margin-bottom: 15px;
`

const Img = styled.img`
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
`

const formatDate = (date) => format(date, "eee, MMM do")
const formatTime = (date) => format(date, "h:mm aaa")

const SelectedMeetData = () => {
  const { user, data, step, selectedTimezone } = useBookMeet()

  let startTime,
    endTime,
    formattedDate = null

  if (data.date && data.time && step === 4) {
    const generatedDateTime = getUtcFromZonedDateTime(
      data.date.date,
      data.time.start,
      user.timezone || "America/Chicago"
    )

    startTime = formatTime(generatedDateTime)
    endTime = formatTime(addMinutes(generatedDateTime, data.type))
    formattedDate = formatDate(generatedDateTime)
  }

  return (
    <div>
      <ImgContainer>
        <Img src={user.photo} alt={getUserName(user)} aria-label={"Avatar"} />
      </ImgContainer>
      <p className={"mb15 font-16"}>{getUserName(user)}</p>
      <p className={"mb15 font-18 font-weight-500"}>
        {data.type}-Minute Meeting
      </p>
      {data.hold && (
        <p className={"mb15 color-black-54"}>
          Hold: <span className={"color-black-87"}>{data.hold.name}</span>
        </p>
      )}
      {step === 4 && (
        <>
          <div className={"mb10"}>
            <p className={"font-weight-500"}>{formattedDate}</p>
            <p className={"font-weight-500"}>
              {startTime} - {endTime}
            </p>
            <TimezoneLabel
              tz={selectedTimezone.id}
              className={"color-black-54"}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default withErrorBoundary(SelectedMeetData)
