import React from "react"
import { call } from "../library/networking/API"
import { LIMIT } from "../library/constants/limits"
import { useAlert } from "./useAlert"

const useSelect = ({
  requestConfig,
  loadOnMount = true,
  search = true,
  infiniteScroll = true,
}) => {
  const { errorAlert } = useAlert()
  const [isWaitingForOtherFields, setIsWaitingForOtherFields] =
    React.useState(!loadOnMount)
  const [dataReceived, setDataReceived] = React.useState(false)
  const [data, setData] = React.useState([])

  const [offset, setOffset] = React.useState(0)
  const [name, setName] = React.useState("")

  const [hasMore, setHasMore] = React.useState(false)

  const [isLoadingMore, setIsLoadingMore] = React.useState(false)

  const [position, setPosition] = React.useState(0)
  const [listboxNode, setListboxNode] = React.useState("")

  const fetchList = async (refetch = false) => {
    try {
      const response = await call(requestConfig.fetchConfig.apiUrl, {
        ...requestConfig.fetchConfig.params,
        offset: refetch ? 0 : offset,
        name: name || undefined,
        limit: LIMIT,
      })

      const _data = (await response.data?.[requestConfig.key]) || []

      setData((prevState) => (refetch ? _data : [...prevState, ..._data]))
      setOffset((prevState) => (refetch ? LIMIT : prevState + LIMIT))
      setHasMore(_data.length >= LIMIT)

      if (refetch) {
        setDataReceived(true)
      }

      if (isWaitingForOtherFields) {
        setIsWaitingForOtherFields(false)
      }
    } catch (e) {
      errorAlert(e)
    }
  }

  // React.useEffect(() => {
  //   if (loadOnMount) {
  //     fetchList(true)
  //   }
  // }, [])

  React.useEffect(() => {
    if (listboxNode !== "") {
      listboxNode.scrollTop = position
    }
  }, [position, listboxNode])

  const loadMore = async () => {
    if (!isLoadingMore && hasMore) {
      setIsLoadingMore(true)
      await fetchList()
      setIsLoadingMore(false)
    }
  }

  const onScroll = async (e) => {
    setListboxNode(e.currentTarget)

    const x = listboxNode.scrollTop + listboxNode.clientHeight

    if (listboxNode.scrollHeight - x <= 1) {
      setPosition(x)
      await loadMore()
    }
  }

  const processSearch = async () => {
    if (dataReceived) {
      setDataReceived(false)
      await fetchList(true)
    }
  }

  React.useEffect(() => {
    const search = setTimeout(() => {
      processSearch()
    }, 1000)

    return () => clearTimeout(search)
  }, [name])

  const onListBoxClose = () => {
    setDataReceived(false)
    setName("")
    setData([])
    setOffset(0)
  }

  return {
    data,
    dataReceived,
    isWaitingForOtherFields,
    fetchList,
    isLoadingMore,
    onScroll,
    processSearch,
    onSearchChange: setName,
    onListBoxClose,
  }
}

export default useSelect
