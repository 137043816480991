import React, { Component } from "react"
import "./productReviewCreate.scss"
import Button from "@material-ui/core/es/Button/Button"
import Dialog from "@material-ui/core/Dialog/Dialog"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import withStyles from "@material-ui/core/styles/withStyles"
import StarRating from "../../../../../../components/ui/starRating/starRating"
import { bindActionCreators } from "redux"
import {
  createProductReview,
  getProduct,
  productReviewsList,
} from "../../../../../../library/store/actions/creators/productsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { withRouter } from "react-router-dom"
import getErrorText from "../../../../../../library/constants/errorTypes"

const CustomizedDialog = withStyles({
  paper: {
    maxWidth: 640,
    minWidth: 640,
  },
})(Dialog)

class ProductReviewCreate extends Component {
  state = {
    rating: 0,
    review: "",
    open: false,
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = async () => {
    this.setState({ open: false })
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    })
  }

  rate = (rating) => {
    this.setState({ rating })
  }

  processInfoSave = async () => {
    const {
      match: { params },
    } = this.props
    const { rating, review: message } = this.state

    try {
      await this.props.createProductReview({
        product_id: parseInt(params.productId),
        message,
        rating,
      })
      await this.handleClose()
      await this.props.getProduct({ id: parseInt(params.productId) })
      await this.props.enqueueSnackbar("Review successfully submitted", {
        variant: "success",
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  render() {
    const { rating, review, open } = this.state
    return (
      <div className="product-review-create">
        <p className="mb8">Review this product</p>
        <p className="mb15">Share your thoughts with other customers</p>
        <Button variant="outlined" color="primary" onClick={this.handleOpen}>
          Write review
        </Button>

        <CustomizedDialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <ValidatorForm onSubmit={this.processInfoSave} instantValidate={true}>
            <DialogContent>
              <div className="group-create__header">
                <h3 className="group-create__title">Write review</h3>
                <h4 className="group-create__subtitle group-create__subtitle--2 mb45">
                  Share your thoughts with other customers
                </h4>
              </div>
              <div className="f aic jcc mb30">
                <span className="mr15">Rating:</span>
                <StarRating
                  rating={rating}
                  rate={(rating) => this.rate(rating)}
                />
              </div>
              <div className="form form--modal">
                <div className="form__fieldset">
                  <label htmlFor="review" className="form__label">
                    Review*
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="review"
                        name="review"
                        value={review}
                        multiline
                        placeholder="Type your review"
                        margin="normal"
                        fullWidth
                        validators={["required", "maxStringLength:2000"]}
                        errorMessages={[
                          "Field is required",
                          "Max length is 2000 characters",
                        ]}
                        withRequiredValidator
                        onChange={this.handleChange("review")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Save
              </Button>
            </DialogActions>
          </ValidatorForm>
        </CustomizedDialog>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, products }) => ({ auth, products })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createProductReview,
      productReviewsList,
      getProduct,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(ProductReviewCreate)))
