import React from "react"
import logoDefault from "../../../../../assets/img/product.svg"
import { Link } from "react-router-dom"
import {
  calendarGrades,
  grades as gradesNames,
  subjects as subjectsName,
} from "../../../../../library/constants/educationalDictionary"
import MoreDropdown from "../../../../../components/ui/dropdowns/moreDropdown/moreDropdown"
import VerticalMenu from "../../../../../components/ui/verticalMenu"
import ComplaintsModal from "../../../../../components/complaints/complaintsModal"
import { safeGetEntity } from "../../../../../library/utils/entities"
import { getObjectById } from "../../../../../library/utils/arrays"
import PlainLink from "../../../../../components/ui/link"

const KnowMixSquareCard = (props) => {
  const {
    knowmix: {
      creator_user,
      creator_group,
      photo,
      name,
      grades,
      subjects,
      id,
      subject,
    },
    hasMenu,
    menuItems,
    bottomLabel,
    linkUrl,
  } = props
  const knowmixLogo = photo || logoDefault

  // const creatorName = creator_user
  //   ? `${creator_user?.first_name} ${creator_user?.last_name}`
  //   : creator_group?.name
  //
  // const creatorUrl = creator_user
  //   ? generatePath(routes.USER, { userId: creator_user?.id })
  //   : generatePath(routes.GROUP, { groupId: creator_group?.id })

  return (
    <div
      className="relative grid grid-rows-subgrid gap-2 rounded-sm shadow-card bg-white relative p-3"
      style={{ gridRow: `span ${bottomLabel ? 5 : 3}` }}
    >
      <ComplaintsModal
        open={props.complaintsModal === id}
        knowmix_id={id}
        onClose={props.hideComplaintsModal}
        callback={props.updateData}
      />
      {hasMenu && (
        <div className="absolute right-2 top-2 self-end text-black38">
          <MoreDropdown>
            <VerticalMenu
              menuItems={menuItems}
              classes={"more-dropdown-menu__list"}
            />
          </MoreDropdown>
        </div>
      )}
      <Link
        to={linkUrl}
        className="aspect-square flex items-center justify-center rounded-[2px]"
      >
        <img
          src={knowmixLogo}
          alt={name}
          className={"object-contain object-center max-h-[100%]"}
        />
      </Link>
      <div>
        <PlainLink to={linkUrl} bold className={"!line-clamp-2"}>
          {name}
        </PlainLink>
      </div>

      <div>
        {!!safeGetEntity(subject) && (
          <div className={"mb-1"}>
            <p className="text-black54 text-xs mb-0.5">Subject:</p>
            <p>
              {`${getObjectById(calendarGrades, subject.grade).name} grade, ${
                subject.name
              }`}
            </p>
          </div>
        )}
        {!!subjects.length && (
          <div className={"mb-1"}>
            <p className="text-black54 text-xs mb-0.5">
              <span>Subjects:</span>
            </p>
            <p>
              {subjects
                .map((id) => subjectsName.find((s) => s.id === id).name)
                .join(", ")}
            </p>
          </div>
        )}
        {!!grades.length && (
          <div>
            <p className="text-black54 text-xs mb-0.5">
              <span>Grades:</span>
            </p>
            <p>
              {grades
                .map((id) => gradesNames.find((g) => g.id === id).name)
                .join(", ")}
            </p>
          </div>
        )}
      </div>
      {!!bottomLabel && (
        <>
          <hr className={"my-2 border-divider"} />
          <div className="flex items-center justify-end">
            <span className="text-base font-medium leading-none">
              {bottomLabel}
            </span>
          </div>
        </>
      )}
    </div>
  )
}

export default KnowMixSquareCard
