import React, { useEffect, useState } from "react"
import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import logoDefault from "../../../../assets/img/product.svg"
import VerticalMenu from "../../../../components/ui/verticalMenu"
import MoreDropdown from "../../../../components/ui/dropdowns/moreDropdown/moreDropdown"
import styled from "@emotion/styled"

const InvenstoryTile = (props) => {
  const { record } = props
  const [deleted] = useState(false)
  const [bookmarked, setBookmarked] = useState(!!record.bookmark)
  useEffect(() => {
    setBookmarked(!!props.record.bookmark)
  }, [props.record.bookmark])
  const addToBookmarks = async () => {
    try {
      await props.createBookmark(record.id)
      setBookmarked(true)
    } catch (e) {}
  }
  const removeFromBookmarks = async () => {
    try {
      await props.deleteBookmark(record.id)
      setBookmarked(false)
    } catch (e) {}
  }
  if (!record || deleted) return null

  const creator = record.creator_user
    ? {
        name: `${record.creator_user.first_name} ${record.creator_user.last_name}`,
        url: generatePath(routes.USER, { userId: record.creator_user.id }),
      }
    : record.creator_group
    ? {
        name: record.creator_group.name,
        url: generatePath(routes.GROUP, { groupId: record.creator_group.id }),
      }
    : {
        name: "",
        url: "/",
      }

  const dropdownMenuItems = [
    !bookmarked
      ? {
          type: "button",
          action: addToBookmarks,
          label: "Add to bookmarks",
        }
      : {
          type: "button",
          action: removeFromBookmarks,
          label: "Remove from bookmarks",
        },
    {
      type: "button",
      action: () => props.createProduct(record.id),
      label: "Create product ad tile",
    },
    record.can_manage && {
      type: "button",
      action: () => props.editRecord(record.id),
      label: "Edit record",
    },
    record.can_manage && {
      type: "h-divider",
      id: "hd1",
    },
    record.can_manage && {
      type: "button",
      action: () => props.deleteRecord(record.id),
      label: "Delete record",
    },
  ]
  return (
    <div className="product-tile">
      <div className="product-tile__menu">
        <MoreDropdown>
          <VerticalMenu
            menuItems={dropdownMenuItems}
            classes={"more-dropdown-menu__list"}
          />
        </MoreDropdown>
      </div>
      <div
        onClick={() => props.openRecord(record.id)}
        className="product-tile__logo"
      >
        <Logo src={record.image || logoDefault} alt="Product image" />
      </div>
      <div
        onClick={() => props.openRecord(record.id)}
        className="product-tile__name link"
      >
        {record.info["1"]}
      </div>
      <table className="product-tile__info">
        <tbody>
          <tr>
            <td
              className={`product-tile__info-label ${
                record.info["3"] ? "pb4" : ""
              }`}
            >
              <span>Creator:</span>
            </td>
            <td
              className={`product-tile__info-value ${
                record.info["3"] ? "pb4" : ""
              }`}
            >
              <span>
                <Link to={creator.url} className="link font-weight-500">
                  {creator.name}
                </Link>
              </span>
            </td>
          </tr>
          <tr>
            <td className="product-tile__info-label">
              <span>
                {record.info["3"] ? "Content type:" : ""}
                {!record.info["3"] && <span style={{ height: "20px" }}></span>}
              </span>
            </td>
            <td className="product-tile__info-value">
              <span>{record.info["3"] ? record.info["3"] : ""}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

const Logo = styled.img`
    object-fit: cover;
    object-position: center;
    width: 135px;
    height: 135px;
`;

export default InvenstoryTile;
