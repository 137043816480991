import React from "react"

const withScroll = (Component, isUpScrollComponent) => {
  return class extends React.PureComponent {
    state = {
      inProcess: false,
      scrollContainer: null,
      isCustomContainer: false,
    }
    callRefCallback = async (callback) => {
      if (this.state.inProcess) return
      const { scrollContainer, isCustomContainer } = this.state
      const container = isCustomContainer
        ? scrollContainer
        : scrollContainer.scrollingElement || scrollContainer.documentElement
      const flag =
        (!isUpScrollComponent &&
          container.scrollHeight -
            container.scrollTop -
            container.clientHeight <=
            10) ||
        (isUpScrollComponent && container.scrollTop === 0)
      if (flag) {
        this.setState({ inProcess: true })
        await callback()
      }
      setTimeout(() => {
        this.setState({ inProcess: false })
      }, 400)
    }

    setScrollContainer = (ref) => {
      this.setState({
        scrollContainer: ref,
        isCustomContainer: true,
      })
    }

    setScroll = () => {
      this.callRefCallback((_) => this.cmp && this.cmp.onScroll())
    }

    componentDidMount() {
      this.setState({
        scrollContainer: document,
      })
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevState.scrollContainer !== this.state.scrollContainer) {
        prevState.scrollContainer &&
          prevState.scrollContainer.removeEventListener(
            "scroll",
            this.setScroll
          )
        this.state.scrollContainer &&
          this.state.scrollContainer.addEventListener("scroll", this.setScroll)
      }
    }

    componentWillUnmount() {
      this.state.scrollContainer &&
        this.state.scrollContainer.removeEventListener("scroll", this.setScroll)
    }

    render() {
      return (
        <Component
          ref={(ref) => (this.cmp = ref)}
          {...this.props}
          setScrollContainer={this.setScrollContainer}
        />
      )
    }
  }
}

export default withScroll;
