import React, { Component } from "react"
import "./conversationMessage.scss"
import avatarDefault from "../../../../../assets/img/user_avatar.svg"
import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../../../library/constants/routes"
import ImageModal from "../../../../../components/ui/modals/imageModal"
import InsertDriveFile from "@material-ui/icons/InsertDriveFile"
import { formatBytes } from "../../../../../library/utils/fileSizeConverter"
import { getMessageTime } from "../../../../../library/utils/timeConvert"
import unavailableAvatar from "../../../../../assets/img/user_unavailable.svg"
import TextWithExternalURL from "../../../../../components/externalURL/externalURL"

class ConversationMessage extends Component {
  state = {
    open: false,
    activeImage: "",
  }

  handleClickOpen = (url) => {
    this.setState({
      open: true,
      activeImage: url,
    })
  }

  handleClose = () => {
    this.setState({
      open: false,
    })
  }

  render() {
    const { message } = this.props
    const avatar =
      message.user.deleted || message.user.blocked
        ? unavailableAvatar
        : message.user.photo
        ? message.user.photo
        : avatarDefault
    const fullName = `${message.user.first_name} ${message.user.last_name}`

    return (
      <div className="message">
        <Link
          to={generatePath(routes.USER, { userId: message.user.id })}
          className="message__author-avatar"
        >
          <img src={avatar} alt={fullName} />
        </Link>
        <div className="f fdc aifs fg1">
          <div className="message__info">
            <Link
              to={generatePath(routes.USER, { userId: message.user.id })}
              className="message__author-name link mr8"
            >
              {fullName}
            </Link>
            <span className="color-black-54 font-13">
              {getMessageTime(message.created)}
            </span>
          </div>

          <TextWithExternalURL text={message.message} />

          {/*<Linkify tagName={"pre"} options={{className: "link"}}>*/}
          {/*    {message.message}*/}
          {/*</Linkify>*/}

          {message.attachments.images.length > 0 && (
            <div
              className={`message__images message__attachments-block ${
                message.attachments.images.length === 1
                  ? "message__images--single"
                  : ""
              }`}
            >
              {message.attachments.images.map((image) => (
                <img
                  src={image.url}
                  alt="Image"
                  className={`message__image${
                    message.attachments.images.length > 1 ? "--multiple" : ""
                  }`}
                  onClick={() => this.handleClickOpen(image.url)}
                  key={image.id}
                />
              ))}
              <ImageModal
                url={this.state.activeImage}
                open={this.state.open}
                handleClose={() => this.handleClose()}
              />
            </div>
          )}
          {message.attachments.videos.length > 0 && (
            <div
              className={`message__videos message__attachments-block ${
                message.attachments.videos.length === 1
                  ? "message__videos--single"
                  : ""
              }`}
            >
              {message.attachments.videos.map((video) => (
                <div key={video.id}>
                  <div className="post__video">
                    <video className="post__video-player" controls>
                      <source src={video.url} />
                    </video>
                    <span className="post__video-name">{video.name}</span>
                  </div>
                </div>
              ))}
            </div>
          )}
          {message.attachments.blobs.length > 0 && (
            <div className="message__files message__attachments-block">
              {message.attachments.blobs.map((file) => (
                <a
                  href={file.url}
                  target="_blank"
                  className="message__file"
                  key={file.id}
                >
                  <InsertDriveFile
                    className="message__file-icon"
                    style={{ fontSize: "30px" }}
                  />
                  <div className="message__file-info">
                    <span className="message__file-name">{file.name}</span>
                    <span className="message__file-size">
                      {formatBytes(file.size)}
                    </span>
                  </div>
                </a>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default ConversationMessage
