import React from "react"
import { getObjectById } from "../../../../../library/utils/arrays"
import { calendarGrades } from "../../../../../library/constants/educationalDictionary"
import UserCard from "../../../../../components/v2/layout/cards/userCard"
import { useCourseMembersMutations } from "../../../../../hooks/data/courses/members"
import { useIdParam } from "../../../../../hooks/useIdParam"

const CourseMemberCard = ({ user, mutatedQueryKey }) => {
  const knowmixId = useIdParam("knowmixId")
  const { addMember, deleteMember, mutatingIds } = useCourseMembersMutations({
    queryKey: mutatedQueryKey,
  })

  const isUpdating =
    (addMember.isLoading || deleteMember.isLoading) &&
    mutatingIds.includes(user.id)

  return (
    <UserCard
      user={user}
      infoComponent={() => (
        <p>Grade: {getObjectById(calendarGrades, user.grade).name}</p>
      )}
      controls={
        user?.knowmix.is_member
          ? {
              button: {
                label: "Remove",
                action: () =>
                  deleteMember.mutate({
                    user_id: user.id,
                    knowmix_id: Number(knowmixId),
                  }),
                isLoading: isUpdating,
              },
            }
          : {
              button: {
                label: "Add",
                action: () =>
                  addMember.mutate({
                    user_id: user.id,
                    knowmix_id: Number(knowmixId),
                  }),
                isLoading: isUpdating,
              },
            }
      }
    />
  )
}

export default CourseMemberCard
