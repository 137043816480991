import React from "react"
import { useBookmarksMutations } from "../../../../hooks/data/bookmarks"
import { mutationTypes } from "../../../../library/utils/reactQuery"
import { useBookmarksModal } from "../../../../hooks/modals/useBookmarksModal"
import LockOutlined from "@material-ui/icons/LockOutlined"
import PlainLink from "../../../ui/link"
import { generatePath, Link } from "react-router-dom"
import {
  KNOWCRED,
  KNOWCRED_SINGLE,
  KNOWMIX,
} from "../../../../library/constants/routes"
import {
  knowCredActivitiesIds,
  knowcredStatusTypes,
  knowCredTypesIds,
} from "../../../../library/constants/knowCred"
import FavouriteOutlined from "@material-ui/icons/FavoriteOutlined"
import FavouriteBorderedOutlined from "@material-ui/icons/FavoriteBorderOutlined"
import { clsx } from "clsx"
import { useLikesMutations } from "../../../../hooks/data/likes"
import { useComplaintsModal } from "../../../../hooks/modals/useComplaintsModal"
import Menu from "../../controls/menu/menu"
import UserLink from "../../utils/UserLink"

const CredCard = ({ cred, showOwner = false, queryKey }) => {
  const { likes } = useLikesMutations({
    key: queryKey,
    mutationType: mutationTypes.infinite,
  })

  const { createBookmark, deleteBookmark } = useBookmarksModal()

  const { onComplainModalOpen } = useComplaintsModal()

  const onAddToBookmarks = () => {
    createBookmark({
      params: {
        knowcred_id: cred.id,
      },
      updater: (c, bId) =>
        c.id === cred.id
          ? {
              ...c,
              bookmark: { id: bId },
            }
          : c,
    })
  }

  const onRemoveFromBookmarks = async () => {
    deleteBookmark({
      params: {
        knowcred_id: cred.id,
      },
      updater: (c, bId) =>
        c.id === cred.id
          ? {
              ...c,
              bookmark: undefined,
            }
          : c,
    })
  }

  const onLike = () => {
    if (!cred.is_liked) {
      likes.create.mutate({
        data: { knowcred_id: cred.id },
        updater: (c) =>
          c.id === cred.id
            ? {
                ...c,
                is_liked: true,
                likes_number: c.likes_number + 1,
              }
            : c,
      })
    } else {
      likes.delete.mutate({
        data: { knowcred_id: cred.id },
        updater: (c) =>
          c.id === cred.id
            ? {
                ...c,
                is_liked: false,
                likes_number: c.likes_number - 1,
              }
            : c,
      })
    }
  }

  const dropdownMenuItems = [
    !cred.can_edit && {
      type: "button",
      action: onComplainModalOpen,
      label: !cred.is_reported ? "Report" : "Reported",
      disabled: cred.is_reported,
    },
    !cred.bookmark
      ? {
          type: "button",
          action: onAddToBookmarks,
          label: "Add to bookmarks",
        }
      : {
          type: "button",
          action: onRemoveFromBookmarks,
          label: "Remove from bookmarks",
        },
  ].filter(Boolean)

  const showActivity = ![0, 1].includes(cred.activity)

  return (
    <div
      className={
        "p-3 rounded-sm bg-white shadow-card grid grid-rows-subgrid row-span-5 gap-2 relative"
      }
    >
      <div className={"absolute right-3 top-3 z-10 bg-white/40"}>
        <Menu items={dropdownMenuItems} />
      </div>
      <Link
        to={generatePath(KNOWCRED_SINGLE, { knowCredId: cred.id })}
        className={
          "aspect-square w-full border-4 object-center object-cover bg-center bg-cover bg-no-repeat flex items-center justify-center p-4 relative rounded-sm border-solid"
        }
        style={{
          borderColor: cred.border_color,
          backgroundImage: `url(${cred.background_image})`,
        }}
      >
        <img
          src={cred.foreground_image}
          alt={"icon"}
          className={"max-h-full"}
        />
        {!cred.public && (
          <div
            className={
              "absolute top-0 left-0 bg-white rounded-full w-6 h-6 flex items-center justify-center shadow-[0_2px_4px_rgba(0,_0,_0,_.2)]"
            }
          >
            <LockOutlined className="color-primary !text-lg" />
          </div>
        )}
        {!!cred.status_type && cred.status_type !== 0 && (
          <div
            className="absolute bottom-0 left-0 w-full text-center text-white text-xs px-2 py-1 leading-none"
            style={{ backgroundColor: cred.border_color }}
          >
            {knowcredStatusTypes[cred.status_type]}
          </div>
        )}
      </Link>
      <PlainLink
        to={generatePath(KNOWCRED_SINGLE, { knowCredId: cred.id })}
        bold
        className={"!line-clamp-2"}
      >
        {cred.title}
      </PlainLink>
      <div>
        <div className="mb-1">
          <p className={`text-black54 text-xs mb-0.5`}>Type:</p>
          <p>{knowCredTypesIds[cred.type]}</p>
        </div>
        {showActivity && (
          <div className="mb-1">
            <p className={`text-black54 text-xs mb-0.5`}>Label/Activity:</p>
            <p>{knowCredActivitiesIds[cred.activity]}</p>
          </div>
        )}
        {!!cred?.knowmix?.id && (
          <div className="mb-1">
            <p className={`text-black54 text-xs mb-0.5`}>Course:</p>
            <PlainLink
              to={generatePath(KNOWMIX, { knowmixId: cred.knowmix.id })}
              newTab
            >
              {cred?.knowmix?.name}
            </PlainLink>
          </div>
        )}
        {showOwner && (
          <div className="mb-1">
            <p className={`text-black54 text-xs mb-0.5`}>Created for:</p>
            <UserLink user={cred.owner} />
          </div>
        )}
      </div>
      <p className={"line-clamp-3"}>{cred.description}</p>
      <div className={"flex items-center justify-between"}>
        <button onClick={onLike} className={"flex items-center text-black38"}>
          {cred.is_liked ? (
            <FavouriteOutlined className="text-primary" />
          ) : (
            <FavouriteBorderedOutlined />
          )}
          {!!cred.likes_number && (
            <span className={clsx(cred.is_liked && "text-primary")}>
              {cred.likes_number}
            </span>
          )}
        </button>
      </div>
    </div>
  )
}

export default CredCard
