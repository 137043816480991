import React, { Component } from "react"
import { generatePath } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import Dialog from "@material-ui/core/Dialog/Dialog"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import Post from "../post/post"
import { bindActionCreators } from "redux"
import { getData } from "../../../../library/store/actions/creators/authCreators"
import { getPerson } from "../../../../library/store/actions/creators/personCreators"
import {
  clearPost,
  getPost,
  getPostsList,
} from "../../../../library/store/actions/creators/postsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import Loader from "../../../../components/ui/loader/loader"
import getErrorText from "../../../../library/constants/errorTypes"

class PostModal extends Component {
  state = {
    open: true,
    userInfo: this.props.userInfo,
    groupInfo: this.props.groupInfo,
    post: {},
    type: this.props.type,
    dataReceived: false,
    isDeleted: false,
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }

  componentDidMount() {
    this.getPost()
  }

  componentWillUnmount() {
    this.props.clearPost()
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.posts.currentPost !== prevProps.posts.currentPost) {
      await this.setStateAsync({ post: this.props.posts.currentPost })
    }
  }

  handleClose = async () => {
    const { type } = this.state

    try {
      switch (type) {
        case "my-profile":
          await this.setStateAsync({ open: false })
          this.props.history.push(
            generatePath(routes.USER, { userId: this.state.userInfo.id }),
            { fromPost: parseInt(this.props.match.params.postId) }
          )
          break

        case "other-profile":
          await this.setStateAsync({ open: false })
          this.props.history.push(
            generatePath(routes.USER, { userId: this.state.userInfo.id }),
            { fromPost: parseInt(this.props.match.params.postId) }
          )
          break

        case "group":
          await this.setStateAsync({ open: false })
          this.props.history.push(
            generatePath(routes.GROUP, { groupId: this.state.groupInfo.id }),
            { fromPost: parseInt(this.props.match.params.postId) }
          )
          break

        default:
          return
      }
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  getPost = async () => {
    const postId = parseInt(this.props.match.params.postId)
    try {
      await this.props.getPost({ id: postId })
      await this.setStateAsync({
        post: this.props.posts.currentPost,
        dataReceived: true,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
      if (error.code === 20701) {
        this.setState({
          isDeleted: true,
          dataReceived: true,
        })
      }
    }
  }

  render() {
    const { userInfo, groupInfo, post, type, dataReceived, isDeleted } =
      this.state

    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
      >
        <DialogContent>
          {dataReceived ? (
            isDeleted ? (
              <h4>Post deleted</h4>
            ) : (
              <Post
                post={post}
                type={type}
                userInfo={userInfo}
                groupInfo={groupInfo}
                updateData={this.getPost}
                postLocation="modal"
              />
            )
          ) : (
            <Loader />
          )}
        </DialogContent>
      </Dialog>
    )
  }
}

const mapStateToProps = ({auth, person, posts}) => ({auth, person, posts});
const mapDispatchToProps = dispatch => bindActionCreators({
    getData,
    getPerson,
    getPostsList,
    getPost,
    clearPost,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(PostModal));
