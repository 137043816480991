import React from "react"
import BoxPage, { BoxContent } from "../components/v2/layout/boxPage/boxPage"
import { useSelector } from "react-redux"
import { usePaidPlansQuery } from "../screens/info/pricing/usePlans"
import { Li, Ul } from "../screens/info/accounts/accountsInfoMain"
import PlainLink from "../components/ui/link"
import { PRICING } from "../library/constants/routes"

const PagePermissionDenied = ({ title, allowedPlans = [] }) => {
  const { subscriptions } = useSelector(({ auth }) => auth)

  const { paidPlans } = usePaidPlansQuery()

  const getCurrentPlanName = () => {
    if (!subscriptions.personal_subscription) {
      return "Free"
    }

    return subscriptions.personal_subscription.plan_name
  }

  return (
    <BoxPage title={title}>
      <BoxContent>
        <p>You don't have permission to access this page.</p>
        <p>Please upgrade your subscription to access this feature.</p>
        <p>You are on {getCurrentPlanName()} plan</p>
        <p>Check the plans that support this feature:</p>
        <Ul className={"mt20"}>
          {paidPlans
            ?.filter((p) => allowedPlans.includes(p.id))
            .map((p) => (
              <Li key={p.id}>
                <PlainLink to={PRICING}>{p.name}</PlainLink>
              </Li>
            ))}
        </Ul>
      </BoxContent>
    </BoxPage>
  )
}

export default PagePermissionDenied
