import React from "react"
import {
  FormControl,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core"
import { linkProviderNames } from "../../../components/v2/inputs/pickers/ContactLinkPicker"
import FormRadioGroup from "../../../components/formComponents/formRadioGroup"
import { useContactLinks } from "../../main/user/userProfileSettings/profileSettingsAppointmentLinks/useContactLinks"
import { colors } from "../../../library/constants/styles/colors"
import FormHelperText from "@material-ui/core/FormHelperText"

const InnerInviterContactLinkPicker = ({
  value,
  onChange,
  leftLabel = true,
  additionalLinks = {},
  setSavedLinkType,
  savedLinkType,
  inviteeName,
  error = "",
}) => {
  const { links, ...query } = useContactLinks()

  const [allLinks, setAllLinks] = React.useState([additionalLinks])

  const [linkType, setLinkType] = React.useState(savedLinkType)
  const [customLink, setCustomLink] = React.useState(
    savedLinkType === "custom" ? value : ""
  )
  const [savedLinkValue, setSavedLinkValue] = React.useState(
    savedLinkType === "saved" ? value : ""
  )

  React.useEffect(() => {
    if (query.isSuccess && links && allLinks.length < 2) {
      setAllLinks((prevState) => [...prevState, links])
    }
  }, [query.isSuccess])

  const handleChange = (e) => {
    if (linkType === "custom") {
      setCustomLink(e.target.value)
    } else {
      setSavedLinkValue(e.target.value)
    }

    onChange(e.target.value)
  }

  const onTypeChange = (v) => {
    setLinkType(v)
    setSavedLinkType(v)
  }

  const renderList = () => {
    const items = []
    const inviteeHasLinks = Object.values(additionalLinks).some(Boolean)
    const inviterHasLinks = Object.values(links).some(Boolean)
    let linkOrigin = ""
    allLinks.forEach((linksSet, i) => {
      const entries = Object.entries(linksSet).filter(([k, v]) => !!v)

      if (inviteeHasLinks && i === 0) {
        linkOrigin = `${inviteeName}'s`
        items.push({
          type: "title",
          value: `${linkOrigin} links`,
        })
      }

      if (
        inviterHasLinks &&
        ((i === 0 && !inviteeHasLinks) || (i === 1 && inviteeHasLinks))
      ) {
        linkOrigin = "Your"
        items.push({
          type: "title",
          value: `${linkOrigin} links`,
        })
      }

      entries.forEach(([k, v]) => {
        items.push({ type: "link", value: { k, v }, linkOrigin })
      })
    })

    return items.map((item, i) => {
      if (item.type === "title") {
        return (
          <MenuItem
            value={""}
            disabled
            className={"color-black-54"}
            style={{ bgColor: colors.black12 }}
            key={i}
          >
            {item.value}
          </MenuItem>
        )
      } else {
        return (
          <MenuItem value={item.value.v} key={item.value.k + "_" + i}>
            <div>
              <p>
                {item.linkOrigin} {linkProviderNames[item.value.k]}
              </p>
              <p
                className={
                  "color-black-54 font-13 overflow-x-hidden overflow-ellipsis max-w-[300px]"
                }
              >
                {item.value.v}
              </p>
            </div>
          </MenuItem>
        )
      }
    })
  }

  const fieldBox = (
    <div className="form__fieldbox">
      <div className="form__input form__input--select">
        {query.isSuccess ? (
          <>
            {!!allLinks.length ? (
              <>
                <FormRadioGroup
                  leftLabel={false}
                  options={[
                    {
                      label: "Pick from the invitee or your own saved links",
                      value: "saved",
                    },
                    { label: "Custom one", value: "custom" },
                  ]}
                  value={linkType}
                  onChange={onTypeChange}
                  name={"conferenceLink"}
                />
                {linkType === "saved" ? (
                  <FormControl fullWidth className={"mt15"} error={!!error}>
                    <Select
                      id={"savedLink"}
                      name={"savedLink"}
                      fullWidth
                      onChange={handleChange}
                      value={savedLinkValue}
                      displayEmpty
                    >
                      <MenuItem
                        value={""}
                        disabled
                        className={"color-black-54"}
                      >
                        Select link
                      </MenuItem>
                      {renderList()}
                    </Select>
                    {!!error && (
                      <FormHelperText className={"error-message"}>
                        {error}
                      </FormHelperText>
                    )}
                  </FormControl>
                ) : (
                  <TextField
                    onChange={handleChange}
                    value={customLink}
                    fullWidth
                    placeholder="Type conference link"
                    margin={"normal"}
                    className={"mt15"}
                    error={!!error}
                    helperText={
                      !!error && (
                        <FormHelperText style={{ color: colors.error }}>
                          {error}
                        </FormHelperText>
                      )
                    }
                  />
                )}
              </>
            ) : (
              <TextField
                onChange={handleChange}
                value={customLink}
                fullWidth
                placeholder="Type conference link"
                margin={"normal"}
                error={!!error}
                helperText={
                  !!error && (
                    <FormHelperText style={{ color: colors.error }}>
                      {error}
                    </FormHelperText>
                  )
                }
              />
            )}
          </>
        ) : (
          <span className={"color-black-54"}>Loading...</span>
        )}
      </div>
    </div>
  )

  return leftLabel ? (
    <div className="form__fieldset">
      <label htmlFor={"conferenceLink"} className={"form__label"}>
        Conference Link
      </label>
      {fieldBox}
    </div>
  ) : (
    fieldBox
  )
}

export default InnerInviterContactLinkPicker
