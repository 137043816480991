import React from "react"
import {
  getData,
  highlightEducation,
  highlightWork,
  updateInfo,
} from "../../../../../library/store/actions/creators/authCreators"
import { useDispatch, useSelector } from "react-redux"
import { getObjectById } from "../../../../../library/utils/arrays"
import BoxPage from "../../../../../components/v2/layout/boxPage/boxPage"
import Form from "../../../../../components/v2/layout/forms/form/form"
import { Formik } from "formik"
import * as Yup from "yup"
import { useAlert } from "../../../../../hooks/useAlert"

const validationSchema = Yup.object({
  status: Yup.string().max(700),
  first_name: Yup.string().required().max(75),
  last_name: Yup.string().required().max(75),
  show_work: Yup.bool(),
  show_education: Yup.bool(),
  highlighted_work: Yup.mixed().when("show_work", {
    is: true,
    then: Yup.number().required(),
    otherwise: Yup.number().notRequired(),
  }),
  highlighted_education: Yup.mixed().when("show_education", {
    is: true,
    then: Yup.number().required(),
    otherwise: Yup.number().notRequired(),
  }),
})

const ProfileEditMain = () => {
  const { userData } = useSelector(({ auth }) => auth)
  const dispatch = useDispatch()
  const { successAlert, errorAlert } = useAlert()

  const initialValues = {
    status: userData.status,
    first_name: userData.first_name,
    last_name: userData.last_name,
    show_work: userData.highlighted === 1,
    show_education: userData.highlighted === 2,
    highlighted_work: userData.works.find((w) => w.highlighted)?.id,
    highlighted_education: userData.educations.find((e) => e.highlighted)?.id,
  }

  const onSubmit = async (values) => {
    const {
      status,
      first_name,
      last_name,
      show_work,
      show_education,
      highlighted_work,
      highlighted_education,
    } = values

    try {
      await dispatch(
        updateInfo({
          status,
          first_name,
          last_name,
          highlighted: show_work ? 1 : show_education ? 2 : 0,
        })
      )

      if (show_education) {
        !!highlighted_education &&
          (await dispatch(highlightEducation(highlighted_education)))
      }

      if (show_work) {
        !!highlighted_work && (await dispatch(highlightWork(highlighted_work)))
      }

      await dispatch(getData())
      successAlert()
    } catch (error) {
      errorAlert(error)
    }
  }

  return (
    <BoxPage title="Main">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(fk) => {
          return (
            <Form>
              <Form.Row name={"status"} label={"Favorite quote"}>
                <Form.TextField
                  name={"status"}
                  placeholder={"Type your favorite quote"}
                  multiline
                />
              </Form.Row>
              <Form.Divider />
              <Form.Row name={"first_name"} label={"First name"}>
                <Form.TextField
                  name={"first_name"}
                  placeholder={"Type your first name"}
                />
              </Form.Row>
              <Form.Row name={"last_name"} label={"Last name"}>
                <Form.TextField
                  name={"last_name"}
                  placeholder={"Type your last name"}
                />
              </Form.Row>
              <Form.Divider />
              <Form.Row name={"highlighted_work"} label={"Main work"}>
                <Form.Checkbox
                  name={"show_work"}
                  label={"Show work"}
                  onChange={(e) => {
                    fk.setFieldValue("show_work", e.target.checked)
                    if (e.target.checked) {
                      fk.setFieldValue("show_education", false)
                    }
                  }}
                />
                {fk.values.show_work && (
                  <Form.Select
                    name={"highlighted_work"}
                    placeholder={"Select main work"}
                    options={[
                      { label: "Select work" },
                      ...userData.works.map((w) => ({
                        value: w.id,
                        label: [w.role, w.name].filter(Boolean).join(", "),
                      })),
                    ]}
                    renderedValue={(id) => {
                      const work = getObjectById(userData.works, id)

                      return [work.role, work.name].filter(Boolean).join(", ")
                    }}
                  />
                )}
              </Form.Row>
              <Form.Row name={"highlighted_education"} label={"Main education"}>
                <Form.Checkbox
                  name={"show_education"}
                  label={"Show education"}
                  onChange={(e) => {
                    fk.setFieldValue("show_education", e.target.checked)
                    if (e.target.checked) {
                      fk.setFieldValue("show_work", false)
                    }
                  }}
                />
                {fk.values.show_education && (
                  <Form.Select
                    name={"highlighted_education"}
                    placeholder={"Select main education"}
                    options={[
                      { label: "Select education" },
                      ...userData.educations.map((e) => ({
                        value: e.id,
                        label: [e.name, e.department]
                          .filter(Boolean)
                          .join(", "),
                      })),
                    ]}
                    renderedValue={(id) => {
                      const education = getObjectById(userData.educations, id)

                      return [education.name, education.department]
                        .filter(Boolean)
                        .join(", ")
                    }}
                  />
                )}
              </Form.Row>
            </Form>
          )
        }}
      </Formik>
    </BoxPage>
  )
}

export default ProfileEditMain
