import React, { Component } from "react"
import "./productReview.scss"
import { generatePath, Link, withRouter } from "react-router-dom"
import * as routes from "../../../../../../library/constants/routes"
import DeleteForeverOutlined from "@material-ui/icons/DeleteForeverOutlined"
import EditOutlined from "@material-ui/icons/EditOutlined"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import Button from "@material-ui/core/es/Button/Button"
import StarRating from "../../../../../../components/ui/starRating/starRating"
import FavouriteBorderedOutlined from "@material-ui/icons/FavoriteBorderOutlined"
import FavouriteOutlined from "@material-ui/icons/FavoriteOutlined"
import { bindActionCreators } from "redux"
import {
  deleteProductReview,
  getProduct,
  productReviewsList,
  updateProductReview,
} from "../../../../../../library/store/actions/creators/productsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import {
  like,
  unLike,
} from "../../../../../../library/store/actions/creators/postsCreators"
import avatarDefault from "../../../../../../assets/img/user_avatar.svg"
import getErrorText from "../../../../../../library/constants/errorTypes"
import { format, formatDistanceToNow } from "date-fns"

class ProductReview extends Component {
  state = {
    rating: this.props.review.rating,
    textHidden: this.props.review.message.length > 500,
    showLess: false,
    messageFull: this.props.review.message,
    message:
      this.props.review.message.length > 500
        ? this.props.review.message.slice(0, 500).concat("...")
        : this.props.review.message,
    updateReview: false,
    updatedMessage: "",
    isLiked: this.props.review.is_liked,
    likesNumber: this.props.review.likes_number,
    canEdit: this.props.review.can_edit,
    canDelete: this.props.review.can_delete,
    isMine: this.props.review.isMine,
    deleted: false,
  }

  handleChange = (event) => {
    this.setState({
      updatedMessage: event.target.value,
    })
  }

  showFullText = () => {
    const { messageFull } = this.state
    this.setState({
      textHidden: false,
      showLess: true,
      message: messageFull,
    })
  }

  showLessText = () => {
    const { messageFull } = this.state
    this.setState({
      textHidden: true,
      showLess: false,
      message: messageFull.slice(0, 500).concat("..."),
    })
  }

  getReviewDate = () => {
    const { review } = this.props
    const date = new Date(review.created)

    if (
      formatDistanceToNow(date).includes("days") ||
      formatDistanceToNow(date).includes("month")
    ) {
      return format(date, "PPP")
    } else {
      return formatDistanceToNow(date, { addSuffix: true })
    }
  }

  openEditMode = () => {
    const { messageFull } = this.state
    this.setState({
      updateReview: true,
      updatedMessage: messageFull,
    })
  }

  closeEditMode = async () => {
    this.setState({
      updateReview: false,
      updatedMessage: "",
      rating: this.props.review.rating,
    })
  }

  processEdit = async () => {
    const {
      match: { params },
    } = this.props
    const { updatedMessage, rating } = this.state

    try {
      await this.props.updateProductReview({
        product_id: parseInt(params.productId),
        message: updatedMessage,
        rating,
      })
      this.setState({
        messageFull: updatedMessage,
        message:
          updatedMessage.length > 500
            ? updatedMessage.slice(0, 500).concat("...")
            : updatedMessage,
        textHidden: updatedMessage.length > 500,
        showLess: false,
        updateReview: false,
      })
      await this.props.getProduct({ id: parseInt(params.productId) })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  rate = (rating) => {
    this.setState({ rating })
  }

  processDelete = async () => {
    const {
      match: { params },
    } = this.props

    try {
      await this.props.deleteProductReview({
        product_id: parseInt(params.productId),
      })
      await this.props.getProduct({ id: parseInt(params.productId) })
      this.setState({ deleted: true })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  processLike = async () => {
    const { isLiked, likesNumber } = this.state
    const {
      review: { id },
    } = this.props

    if (
      this.props.products.currentProduct.deleted ||
      this.props.products.currentProduct.blocked
    )
      return false

    try {
      if (!isLiked) {
        this.setState({ isLiked: true, likesNumber: likesNumber + 1 })
        await this.props.like({ product_review_id: id })
      } else {
        this.setState({ isLiked: false, likesNumber: likesNumber - 1 })
        await this.props.unLike({ product_review_id: id })
      }
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
      if (!isLiked) {
        this.setState({ isLiked: false })
      } else {
        this.setState({ isLiked: true })
      }
    }
  }

  render() {
    const {
      rating,
      message,
      textHidden,
      showLess,
      isLiked,
      likesNumber,
      canDelete,
      canEdit,
      updatedMessage,
      updateReview,
      deleted,
    } = this.state
    const {
      review,
      products: { currentProduct },
    } = this.props

    const fullName = `${review.user.first_name} ${review.user.last_name}`

    if (deleted) {
      return null
    }

    return (
      <div className="review">
        <div className="review__header">
          <Link to={generatePath(routes.USER, { userId: review.user.id })}>
            <img
              src={review.user.photo || avatarDefault}
              alt={fullName}
              className="review__author-avatar"
            />
          </Link>
          <div className="f fdc jcc fg1">
            <div className="f fdc aifs asfs">
              <Link
                to={generatePath(routes.USER, { userId: review.user.id })}
                className="review__author-name link"
              >
                {fullName}
              </Link>
              {!updateReview ? (
                <StarRating rating={rating} starSize={20} />
              ) : (
                <StarRating
                  rating={rating}
                  rate={(rate) => this.rate(rate)}
                  starSize={20}
                />
              )}
            </div>
          </div>
          {!!(
            !updateReview &&
            (canEdit || canDelete) &&
            !currentProduct.deleted &&
            !currentProduct.blocked
          ) && (
            <div className="review__manage">
              {canEdit && (
                <button className="btn btn--plain" onClick={this.openEditMode}>
                  <EditOutlined />
                  Edit
                </button>
              )}
              {canDelete && (
                <button className="btn btn--plain" onClick={this.processDelete}>
                  <DeleteForeverOutlined />
                  Delete
                </button>
              )}
            </div>
          )}
        </div>
        <div className="review__content">
          {!updateReview ? (
            <>
              {message.length > 0 && (
                <pre className="review__text">{message}</pre>
              )}
              {textHidden && (
                <span
                  className="review__show-more link"
                  onClick={this.showFullText}
                >
                  Show more...
                </span>
              )}
              {showLess && (
                <span
                  className="review__show-more link"
                  onClick={this.showLessText}
                >
                  Show less
                </span>
              )}
            </>
          ) : (
            <div className="review__update">
              <div className="review__update-input">
                <ValidatorForm
                  className="full-width"
                  onSubmit={this.processEdit}
                >
                  <TextValidator
                    name="updatedMessage"
                    placeholder="Type your review"
                    multiline
                    autoFocus
                    fullWidth
                    value={updatedMessage}
                    onChange={this.handleChange}
                    margin="none"
                    validators={["required", "maxStringLength:2000"]}
                    errorMessages={[
                      "Field is required",
                      "Max length is 2000 characters",
                    ]}
                    withRequiredValidator
                  />
                  <div className="review__update-controls">
                    <div className="btn-group">
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={this.closeEditMode}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        type="submit"
                        // disabled={!updatedMessage.length}
                        // onClick={this.processEdit}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </ValidatorForm>
              </div>
            </div>
          )}
        </div>
        <div className="review__footer">
          <div className="review__social-control" onClick={this.processLike}>
            {isLiked ? (
              <FavouriteOutlined className="color-primary" />
            ) : (
              <FavouriteBorderedOutlined />
            )}
            {likesNumber > 0 && <span>{likesNumber}</span>}
          </div>
          <span className="review__date">{this.getReviewDate()}</span>
        </div>
        {!this.props.isLast && (
          <div className="h-divider h-divider--comment"></div>
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ auth, products, posts }) => ({
  auth,
  products,
  posts,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      productReviewsList,
      like,
      unLike,
      updateProductReview,
      deleteProductReview,
      getProduct,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(ProductReview)))
