import React from "react"
import GroupHeaderSkeleton from "./groupHeaderSkeleton"
import HorizontalListSkeleton from "../components/horizontalListSkeleton"
import GroupSidebarSkeleton from "./groupSidebarSkeleton"
import { PageContentLayout, PageMain } from "../../layout"

const GroupPageSkeleton = () => {
  return (
    <PageContentLayout>
      <PageMain>
        <GroupHeaderSkeleton />
        <HorizontalListSkeleton />
      </PageMain>
      <GroupSidebarSkeleton />
    </PageContentLayout>
  )
}

export default GroupPageSkeleton
