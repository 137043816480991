import { safeGetEntity } from "./entities"
import { getObjectById } from "./arrays"
import { calendarGrades } from "../constants/educationalDictionary"

export const getSubjectName = (subject) => {
  if (!safeGetEntity(subject)) return ""

  return `${getObjectById(calendarGrades, subject.grade).name} grade, ${
    subject.name
  }`
}
