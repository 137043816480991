import React from "react"
import BoxPage from "../../../../../../components/v2/layout/boxPage/boxPage"
import SuperDataTable from "../../../../../../components/v2/dataTable/SuperDataTable"
import UserLink from "../../../../../../components/v2/utils/UserLink"
import { generatePath } from "react-router-dom"
import * as routes from "../../../../../../library/constants/routes"
import { KeyboardArrowRightOutlined } from "@material-ui/icons"
import { KNOWMAP } from "../../../../../../library/store/actions/types/knowmapTypes"
import FormControl from "@material-ui/core/FormControl/FormControl"
import { InputLabel } from "@material-ui/core"
import SuperSelect from "../../../../../../components/v2/inputs/select/superSelect"
import { KNOWMIX } from "../../../../../../library/store/actions/types/knowmixTypes"

const TeacherTrackingCoursesStepsGrading = () => {
  const [course, setCourse] = React.useState("")
  return (
    <BoxPage title={"Course Steps Grading"}>
      <SuperDataTable
        columns={[
          { label: "Student" },
          { label: "Course" },
          { label: "Step" },
          { label: "Status" },
          { label: "Grade" },
          { label: "", role: "actions" },
        ]}
        queryConfig={{
          key: [
            {
              type: "teacher-courses-tracking",
              url: KNOWMAP.KNOWMIX.TEACHER_WORKS_LIST,
              fetchParams: {
                knowmix_id: course?.id || undefined,
              },
            },
          ],
          listKey: "works",
          counterKey: "works_number",
          composeRowData: (record) => [
            {
              type: "component",
              component: () => <UserLink user={record.learner} />,
            },
            {
              type: "link",
              path: {
                pathname: generatePath(routes.KNOWMIX, {
                  knowmixId: record.knowmix.id,
                }),
              },
              label: record.knowmix.name || "",
            },
            {
              type: "link",
              path: {
                pathname: generatePath(routes.KNOWMIX, {
                  knowmixId: record.knowmix.id,
                }),
                search: `?stepId=${record.step.id}`,
              },
              label: record.step.name || "",
            },
            record.grade ? "Completed" : "To Teacher",
            record.grade || "—",
          ],
        }}
        customColumnsWidth
        // isSearchable
        actions={(record) => [
          !record.grade
            ? {
                type: "link",
                label: () => (
                  <>
                    Examine <KeyboardArrowRightOutlined />
                  </>
                ),
                path: {
                  pathname: generatePath(routes.KNOWMIX_CHECK, {
                    knowmixId: record.knowmix.id,
                    checkId: record.id,
                  }),
                  search: `?redirectToKnowmap=true`,
                },
              }
            : {
                type: "link",
                label: () => (
                  <>
                    Review <KeyboardArrowRightOutlined />
                  </>
                ),
                path: {
                  pathname: generatePath(routes.KNOWMIX_GRADES_CHECK, {
                    knowmixId: record.knowmix.id,
                    learnerId: record.learner.id,
                  }),
                  search: `?stepId=${record.step.id}`,
                },
              },
        ]}
        getFilterComponent={() => {
          return (
            <FormControl className={"w-64"}>
              <InputLabel shrink id="course">
                Course
              </InputLabel>
              <SuperSelect
                fieldOnly
                value={course}
                onChange={setCourse}
                fieldName={"course"}
                entityName={"Course"}
                requestConfig={{
                  fetchConfig: {
                    apiUrl: KNOWMIX.LIST,
                    params: {
                      managed: true,
                      active: true,
                      finished: false,
                      graded: false,
                    },
                  },
                  key: "knowmixes",
                }}
              />
            </FormControl>
          )
        }}
      />
    </BoxPage>
  )
}

export default TeacherTrackingCoursesStepsGrading
