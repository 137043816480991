import React from "react"
import styled from "@emotion/styled"
import returnExternalUrl from "../../../library/utils/returnExternalUrl"

const Container = styled.div`
  width: 100%;
`

const PageHeading = styled.h1`
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
`

const Subheading = styled.h3`
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
`

const TextContainer = styled.div`
  margin-bottom: 15px;
  line-height: 1.43;
`

const P = styled.p`
  margin-bottom: ${(props) => (props.margin === "big" ? "20px" : "5px")};
`

const Ul = styled.ul`
  margin: 0 0 15px 25px;
`

const Ol = styled.ol`
  margin: 0 0 5px 25px;

  Ul {
    margin-bottom: 0;
    list-style-type: disc;
  }
`

const TermsAndConditions = () => {
  return (
    <Container>
      <PageHeading>Terms & Conditions</PageHeading>

      <TextContainer>
        <P>
          These terms are subject to change or be updated. Please keep yourself
          apprised of these terms as they are updated in future releases
        </P>
      </TextContainer>

      <TextContainer>
        <Subheading>Student Data Privacy Pledge</Subheading>
        <P>
          Pursuant to the Student Privacy Pledge by the Software and Information
          Industry Association, We PLEDGE to:
        </P>
        <Ul>
          <li>
            Not collect, maintain, use or share student personal information
            beyond that needed for authorized educational/school purposes, or as
            authorized by the parent/student.
          </li>
          <li>
            We plan to comply with all applicable Federal and State laws, the
            most strict of which is the new SOPIPA law in California.
          </li>
          <li>Not sell student personal information.</li>
          <li>
            Not use or disclose student information collected through an
            educational/school service (whether personal information or
            otherwise) for behavioral targeting of advertisements to students.
          </li>
          <li>
            Not build a personal profile of a student other than for supporting
            authorized educational/school purposes or as authorized by the
            parent/student.
          </li>
          <li>
            Not make material changes to school service provider consumer
            privacy policies without first providing prominent notice to the
            account holder(s) (i.e., the educational institution/agency, or the
            parent/student when the information is collected directly from the
            student with student/parent consent) and allowing them choices
            before data is used in any manner inconsistent with terms they were
            initially provided; and not make material changes to other policies
            or practices governing the use of student personal information that
            are inconsistent with contractual requirements.
          </li>
          <li>
            Not knowingly retain student personal information beyond the time
            period required to support the authorized educational/school
            purposes, or as authorized by the parent/student.
          </li>
          <li>
            Collect, use, share, and retain student personal information only
            for purposes for which we were authorized by the educational
            institution/agency, teacher or the parent/student.
          </li>
          <li>
            Disclose clearly in contracts or privacy policies, including in a
            manner easy for parents to understand, what types of student
            personal information we collect, if any, and the purposes for which
            the information we maintain is used or shared with third parties.
          </li>
          <li>
            Support access to and correction of student personally identifiable
            information by the student or their authorized parent, either by
            assisting the educational institution in meeting its requirements or
            directly when the information is collected directly from the student
            with student/parent consent.
          </li>
          <li>
            Maintain a comprehensive security program that is reasonably
            designed to protect the security, privacy, confidentiality, and
            integrity of student personal information against risks – such as
            unauthorized access or use, or unintended or inappropriate
            disclosure – through the use of administrative, technological, and
            physical safeguards appropriate to the sensitivity of the
            information.
          </li>
          <li>
            Require that our vendors with whom student personal information is
            shared in order to deliver the educational service, if any, are
            obligated to implement these same commitments for the given student
            personal information. (At this time KnowStory does not resell for
            any companies or publishers but provides only links and so does not
            technically have "vendors." In future actual ecommerce functions,
            when opted into by companies or publishers will require they sign
            legal documentation with KnowStory requiring point be in on their
            end.)
          </li>
          <li>
            Allow a successor entity to maintain the student personal
            information, in the case of our merger or acquisition by another
            entity, provided the successor entity is subject to these same
            commitments for the previously collected student personal
            information.
          </li>
          <li>
            We welcome requirements by schools for additional legal obligations,
            contractual commitments, or requests from educational institutions
            or parents/students that direct or otherwise authorize (or hide from
            viewing or use) additional uses of student data, other than those
            specified above.
          </li>
          <li>
            Nothing in this pledge is intended to prohibit the use of student
            personal information for purposes of adaptive learning or customized
            education. (KnowStory is by its nature an adaptive recommendations
            engine for any/all types of content for learners or schools, but not
            itself actual learning content. It may provide "news" and viewpoints
            in traditional media style.)
          </li>
          <li>
            This pledge is intended to be applicable to new contracts and
            policies going forward and addressed — where inconsistent and as
            agreed to by the educational institution or agency — in existing
            contracts as updated over time.
          </li>
          <li>This pledge shall be effective as of September 25, 2015.</li>
        </Ul>

        <P>Definitions:</P>
        <P margin="big">
          'School service provider' refers to any entity that: (1) is providing,
          and is operating in its capacity as a provider of, an online or mobile
          application, online service or website that is both designed and
          marketed for use in United States elementary and secondary educational
          institutions/ agencies and is used at the direction of their teachers
          or other employees; and (2) collects, maintains or uses student
          personal information in digital/electronic format. The term 'school
          service provider' does not include an entity that is providing, and
          that is operating in its capacity as a provider of, general audience
          software, applications, services or websites not designed and marketed
          for schools. (KnowStory is a general audience software and is not
          specifically marketed only for schools as an internal-use-only
          software device, but is an external Social Media program for the
          entire general industry of Education globally.)
        </P>
        <P margin="big">
          'Educational/School purposes' are services or functions that
          customarily take place at the direction of the educational
          institution/agency or their teacher/employee, for which the
          institutions or agency would otherwise use its own employees, and that
          aid in the administration or improvement of educational and school
          activities (e.g., instruction, administration, and development and
          improvement of products/services intended for educational/school use).
          'Student personal information' is personally identifiable information
          as well as other information when it is both collected and maintained
          on an individual level and is linked to personally identifiable
          information.
        </P>
        <P margin="big">
          'Student' applies to students of United States elementary and
          secondary schools, and with regard to notice and consent applies only
          to students of appropriate age as authorized under relevant United
          States federal law.
        </P>
        <P margin="big">
          'Consumer privacy policies' include those privacy policies that are
          posted by the company to be available to all users to the site or
          service.
        </P>
        <P>'Parent' includes a student’s legal guardian.</P>
      </TextContainer>

      <TextContainer>
        <Subheading>Student Data Privacy Pledge</Subheading>
        <P>
          KnowStory users provide their real names and information, and we need
          your help to keep it that way. Here are some of the agreements you
          make when you create and keep an account with us:
        </P>
        <Ol>
          <li>
            If you are under 13, you will only use KnowStory with parent
            approval.
          </li>
          <li>
            You will not provide any fraudulent or false personal information,
            or create an account for anyone other than yourself without
            permission.
          </li>
          <li>You will not create more than one personal account.</li>
          <li>
            If we disable your account, you will not create another one without
            our permission.
          </li>
          <li>You will not use KnowStory if you are a convicted criminal.</li>
          <li>
            You will keep your contact information accurate and up-to-date.
          </li>
          <li>
            You will not share your password or let anyone else access your
            account, or do anything else that might jeopardize the security of
            your account.
          </li>
          <li>
            If you select a username or similar identifier for your account or
            Page, we reserve the right to remove or reclaim it if we believe it
            is appropriate (such as when a trademark owner complains about a
            username that does not closely relate to a user's actual name).
          </li>
          <li>
            You acknowledge that KnowStory reserves the right to take ownership
            of or cancel your account at any time without prior notification.
            This is here for security reasons. Though this is a very unlikely
            occurrence it is still in place to maintain security and privacy for
            users and to keep KnowStory safe for all users.
          </li>
          <li>
            As KnowStory is in beta it is encouraged and requested that if any
            bugs are found that they are reported immediately on our Reports
            form. Please include as much information as possible.
          </li>
          <li>
            In alignment with KnowStory being in beta you acknowledge that bugs
            may be encountered and we make no guarantees about any of our
            products or services. On any reported bugs our team will work as
            fast as possible to fix the issue.
          </li>
          <li>During the beta KnowStory is intended for US residents only.</li>
        </Ol>
      </TextContainer>

      <TextContainer>
        <Subheading>Privacy</Subheading>
        <P>
          Your privacy is very important to us. We have zero tolerance with any
          violation of the terms of this agreement, and any user who violates
          them. The safety and privacy of our users and visitors of utmost
          importance to us. Because of this we require that you have an account
          and are registered and have agreed to these terms to visit any part of
          the site other than the products pages.
        </P>
      </TextContainer>

      <TextContainer>
        <Subheading>Advertising</Subheading>
        <P margin="big">
          KnowStory offers commercial interests a means of advertising through
          Stories and ads to reach people on KnowStory. When you search for any
          particular thing you are automatically indicating your own interests
          so that we can use our algorithms to line up search results or
          advertising to help you specifically find learning content or products
          you want. When we deliver ads, we do not share your information
          (information that personally identifies you, such as your name or
          contact information) with advertisers. We may provide advertisers with
          information when we have removed your name and other personally
          identifying information from it, or combined it with other information
          so that it no longer personally identifies you. For example, we may
          tell an advertiser how its ads perform or how many people viewed or
          clicked on their ads or install an app after seeing an ad.
        </P>
        <P>
          Additionally, so that we can show you content that you may find
          interesting, we may use information you have provided about yourself
          to serve relevant recommendations. For example:
        </P>
        <Ul>
          <li>
            information you provide at registration or add to your account or
            Story that is not your name or contact information;
          </li>
          <li>
            things you share and do on KnowStory, such as what you like, and
            your interactions with advertisements, partners, or apps;
          </li>
          <li>things we infer from your use of KnowStory.</li>
        </Ul>
        <P>
          For ads we may serve, advertisers may choose their audience by
          location, demographics, likes, keywords, and any other information we
          receive or infer about users. Here are some of the ways advertisers
          may target relevant ads:
        </P>
        <Ul>
          <li>
            demographics and interests, such as grade ranges, or interests in an
            e-book, subject, game-type, lesson-plan type, etc.
          </li>
          <li>topics or keywords: for example, "Science" or "Civil War."</li>
        </Ul>
        <P>
          If an advertiser chooses to run ads, we serve the ads to people who
          meet criteria the advertiser selects. So, if someone views or
          otherwise interacts with the ad, the advertiser might assume that the
          person meets the criteria they selected. We require advertisers, just
          like all other users, to comply with all of the terms of this
          agreement and are committed to keeping privacy and security standards
          of KnowStory.
        </P>
      </TextContainer>

      <TextContainer>
        <Subheading>KnowStory Info</Subheading>
        <P>
          We may tell you about some of the features and tools your friends and
          others use on KnowStory, to help you have a better experience. For
          example, if a classmate of yours really enjoys an e-learning game, we
          may tell you about it to encourage you to use it as well. This, of
          course, means your friend may similarly see suggestions based on the
          things you do and we hope this creates more learning for everyone. We
          will try to only show it to your particular friends that could benefit
          from your experience. This is just like other Social Media.
        </P>
      </TextContainer>

      <TextContainer>
        <Subheading>Cookies, Pixels and Other Similar Technologies</Subheading>
        <P margin="big">
          Cookies are small pieces of data that are stored on your computer,
          mobile phone or other device. Pixels are small blocks of code on
          webpages that do things like allow another server to measure viewing
          of a webpage and often are used in connection with cookies.
        </P>
        <P margin="big">
          We will use technologies like cookies, pixels, and local storage (like
          on your browser or device, which is similar to a cookie but holds more
          information) to provide and understand a range of products and
          services to help your user experience.
        </P>
        <P>These will do things like:</P>
        <Ul>
          <li>make KnowStory easier or faster to use;</li>
          <li>
            enable features and store information about you (including on your
            device or in your browser cache so you can do things like log-in
            easier) and your use of KnowStory;
          </li>
          <li>deliver, understand and improve advertising;</li>
          <li>
            monitor and understand the use of our products and services; and
          </li>
          <li>protect you, others and KnowStory.</li>
        </Ul>
        <P margin="big">
          We may use these tools to know you are logged in to KnowStory, to help
          you use social plugins and share buttons, or to know when you are
          interacting with our advertising or Platform partners.
        </P>
        <P margin="big">
          We may ask advertisers or other partners to serve ads or services to
          computers, mobile phones or other devices, which may use a cookie,
          pixel or other similar technology placed by KnowStory or the third
          party (although we would not share information that personally
          identifies you with an advertiser). Our vision of any third party is
          not a commercial interested one for information but one that would
          contribute function to how we deliver such as certain software code
          and text or email delivery engines.
        </P>
        <P margin="big">
          Most companies on the web use cookies (or other similar technological
          tools), including our advertising and Platform partners. For example,
          our Platform partners, advertisers or Page administrators may use
          cookies or similar technologies when you access their apps, ads, Pages
          or other content.
        </P>
        <P margin="big">
          Cookies and things like local storage help make KnowStory work, like
          allowing pages to load faster because certain content is stored on
          your browser or by helping us authenticate you to deliver personalized
          content.
        </P>
        <P margin="big">
          To learn more about how advertisers generally use cookies and the
          choices advertisers provide, visit the Network Advertising Initiative
          at{" "}
          <a
            href={`${returnExternalUrl(
              "http://www.networkadvertising.org/managing/opt_out.asp"
            )}`}
            className="link link--inline break-word"
          >
            http://www.networkadvertising.org/managing/opt_out.asp
          </a>
          , the Digital Advertising Alliance at{" "}
          <a
            href={`${returnExternalUrl("http://www.aboutads.info")}`}
            className="link link--inline break-word"
          >
            http://www.aboutads.info
          </a>
          , the Internet Advertising Bureau (US) at{" "}
          <a
            href={`${returnExternalUrl("http://www.iab.net")}`}
            className="link link--inline break-word"
          >
            http://www.iab.net
          </a>{" "}
          or the Internet Advertising Bureau (EU) at{" "}
          <a
            href={`${returnExternalUrl("http://youronlinechoices.eu")}`}
            className="link link--inline break-word"
          >
            http://youronlinechoices.eu
          </a>
          .
        </P>
        <P>
          Refer to your browser or device's help material to learn what controls
          you can often use to remove or block cookies or other similar
          technologies or block or remove other data stored on your computer or
          device (such as by using the various settings in your browser). If you
          do this, it may affect your ability to use KnowStory or other websites
          and apps.
        </P>
      </TextContainer>

      <TextContainer>
        <Subheading>Sharing Your Content and Information</Subheading>
        <P>You own all of the content and information you post on KnowStory.</P>
        <Ol>
          <li>
            For content that is covered by intellectual property rights, like
            photos and videos (IP content), you specifically give us the
            following permission: you grant us a non-exclusive, transferable,
            sub-licensable, royalty-free, worldwide license to use any IP
            content that you post on or in connection with KnowStory (IP
            License). This IP License ends when you delete your IP content or
            your account unless your content has been shared with others, and
            they have not deleted it. This is also a protection for KnowStory so
            that we are not so embroiled in legal actions that we can’t provide
            KnowStory for all our great users because you didn’t make sure your
            photo was yours to use and we all got sued. Big not okay. You
            guarantee your photo or video or any other content you uploaded was
            in fact yours or you paid for the unlimited rights to it and could
            prove it.
          </li>
          <li>
            When you delete IP content, it is deleted in a manner similar to
            emptying the recycle bin on a computer. However, you understand that
            removed content may persist in backup copies for a reasonable period
            of time (but will not be available to others).
          </li>
          <li>
            When you publish content or information using the Public setting, it
            means that you are allowing everyone, including people not members
            but who could search on the open Internet the repositories of
            products in KnowStory, to access and use that information, and to
            associate it with you (i.e., your name and profile picture).
          </li>
          <li>
            We always appreciate your feedback or other suggestions about
            KnowStory, but you understand that we may use them without any
            obligation to compensate you for them (just as you have no
            obligation to offer them.)
          </li>
        </Ol>
      </TextContainer>

      <TextContainer>
        <Subheading>Safety</Subheading>
        <P>
          We do our best to keep KnowStory safe, but we cannot guarantee it. We
          need your help to keep KnowStory safe, which includes the following
          commitments by you:
        </P>
        <Ol>
          <li>
            You will not post unauthorized commercial communications (such as
            spam) on KnowStory.
          </li>
          <li>
            You will not collect users' content or information, or otherwise
            access or visit KnowStory, using automated means (such as harvesting
            bots, robots, spiders, or scrapers) without our prior permission.
          </li>
          <li>
            You will not engage in unlawful multi-level marketing, such as a
            pyramid scheme, on KnowStory.
          </li>
          <li>You will not upload viruses or other malicious code.</li>
          <li>
            You will not solicit login information or access an account
            belonging to someone else.
          </li>
          <li>You will not bully, intimidate, or harass any user.</li>
          <li>
            You will not post content that: is hate speech, threatening, or
            pornographic; incites violence; or contains nudity or graphic or
            gratuitous violence.
          </li>
          <li>
            You will not develop or operate a third-party application containing
            alcohol-related, dating or other mature content (including
            advertisements).
          </li>
          <li>
            You will not use KnowStory to do anything unlawful, misleading,
            malicious, or discriminatory.
          </li>
          <li>
            You will not do anything that could disable, overburden, or impair
            the proper working or appearance of KnowStory, such as a denial of
            service attack or interference with page rendering or other
            KnowStory functionality.
          </li>
          <li>
            You will not facilitate or encourage any violations of this
            Statement or our policies.
          </li>
        </Ol>
      </TextContainer>

      <TextContainer>
        <Subheading>Protecting Other People's Rights</Subheading>
        <P>We respect other people's rights, and expect you to do the same.</P>
        <Ol>
          <li>
            You will not post content or take any action on KnowStory that
            infringes or violates someone else's rights or otherwise violates
            the law.
          </li>
          <li>
            We can remove any content or information you post on KnowStory if we
            believe that it violates this Statement or our policies.
          </li>
          <li>
            If we remove your content for infringing someone else's copyright,
            and you believe we removed it by mistake, we will provide you with
            an opportunity to appeal.
          </li>
          <li>
            If you repeatedly infringe other people's intellectual property
            rights, we will disable your account when appropriate.
          </li>
          <li>
            You will not use our copyrights or trademarks (including KnowStory,
            the KnowStory and K Logos, KS, My Story, Creds, InvenStory), or any
            confusingly similar marks, except as expressly permitted by written
            permission from an authorized KnowStory representative.
          </li>
          <li>
            If you collect information from users, you will: obtain their
            consent, make it clear you (and not KnowStory) are the one
            collecting their information, and post a privacy policy explaining
            what information you collect and how you will use it.
          </li>
          <li>
            You will not post anyone's identification documents or sensitive
            financial information on KnowStory.
          </li>
        </Ol>
      </TextContainer>

      <TextContainer>
        <Subheading>Mobile and Other Devices</Subheading>
        <Ol>
          <li>
            We currently provide our mobile services for free, but please be
            aware that your carrier's normal rates and fees, such as text
            messaging and data charges, will still apply.
          </li>
          <li>
            You provide consent and all rights necessary to enable users to sync
            (including through an application) their devices with any
            information that is visible to them on KnowStory.
          </li>
        </Ol>
      </TextContainer>

      <TextContainer>
        <Subheading>
          Even More Info About Advertisements and Other Commercial Content
          Served or Enhanced by KnowStory
        </Subheading>
        <P>
          Our goal is to deliver advertising and other commercial or sponsored
          content that is valuable to our users and advertisers. In order to
          help us do that, you agree to the following:
        </P>
        <Ol>
          <li>
            Personal information will never be given by us (KnowStory) to
            another entity or third party without the full permission of the
            person or account that the information belongs to. Personal
            information is defined as name, address, any field filled out by
            that person, and pictures when attributed to that person singly.
          </li>
          <li>
            Information that can be used as statistics might be collected and
            used on this site. None of this information will contain any
            identifying factors singly. These will be for statistics such as how
            many people have downloaded "X" product, or purchased "B" book from
            "C" publisher. These pieces of information serve as tabulations and
            will only be expressed or shared, anonymously with no record or
            association with the single user(s) the information was generated
            from.
          </li>
          <li>
            Information may be collected from you to generate or supply
            recommendations and products to you that may be of benefit, such as
            learning apps and services.
          </li>
          <li>
            We do not give your content or identifiable information (such as
            name, or address) to advertisers without your consent.
          </li>
          <li>
            You understand that we may not always identify paid services and
            communications as such.
          </li>
        </Ol>
      </TextContainer>

      <TextContainer>
        <Subheading>Special Provisions Applicable to Advertisers</Subheading>
        <P>
          You can target your desired audience by buying ads on KnowStory or our
          publisher network. The following additional terms apply to you if you
          place an order through our online advertising portal (Order):
        </P>
        <Ol>
          <li>
            When you place an Order, you will tell us the type of advertising
            you want to buy, the amount you want to spend, and your bid. If we
            accept your Order, we will deliver your ads as inventory becomes
            available. When serving your ad, we do our best to deliver the ads
            to the audience you specify, although we cannot guarantee in every
            instance that your ad will reach its intended target.
          </li>
          <li>
            In instances where we believe doing so will enhance the
            effectiveness of your advertising campaign, we may broaden the
            targeting criteria you specify.
          </li>
          <li>
            We will determine the size, placement, and positioning of your ads.
          </li>
          <li>
            We do not guarantee the activity that your ads will receive, such as
            the number of clicks your ads will get.
          </li>
          <li>
            We cannot control how clicks are generated on your ads. We have
            systems that attempt to detect and filter certain click activity,
            but we are not responsible for click fraud, technological issues, or
            other potentially invalid click activity that may affect the cost of
            running ads.
          </li>
          <li>
            You can cancel your Order at any time through our online portal, but
            it may take up to 24 hours before the ad stops running. You are
            responsible for paying for all ads that run.
          </li>
          <li>
            Our license to run your ad will end when we have completed your
            Order. You understand, however, that if users have interacted with
            your ad, your ad may remain until the users delete it.
          </li>
          <li>
            We can use your ads and related content and information for
            marketing or promotional purposes.
          </li>
          <li>
            You will not issue any press release or make public statements about
            your relationship with KnowStory without our prior written
            permission.
          </li>
          <li>We may reject or remove any ad for any reason.</li>
          <li>
            If you are placing ads on someone else's behalf, you must have
            permission to place those ads, including the following:
          </li>
          <li>
            -You warrant that you have the legal authority to bind the
            advertiser to this -Statement.
          </li>
        </Ol>
        <P>
          You agree that if the advertiser you represent violates this
          Statement, we may hold you responsible for that violation.
        </P>
      </TextContainer>

      <TextContainer>
        <Subheading>Marketplace</Subheading>
        <P>
          KnowStory’s marketplace is where you can upload, list, display,
          advertise your products, learning content, software, curriculum,
          games, media, and other items (further referred to as 'items') as well
          as find items from others – for free, or for purchase. By
          participating or using KnowStory, you consent to these policies as
          pertain to the marketplace:
        </P>
        <Ol>
          <li>
            KnowStory is not responsible for the delivery of items from a seller
            to a purchaser. These transactions are to be fulfilled by the seller
            or provider and transactions are created, maintained and completed
            by the relationship formed between a seller/provider and the user
            who is accessing or purchasing the item
          </li>
          <li>
            By listing, displaying or having an item on KnowStory you are
            acknowledging:
            <Ul>
              <li>
                You own the item(s) or have legal rights to the item or you have
                the legal rights to upload, display, sell or market the item(s).
              </li>
              <li>
                That the content or description of the item is suitable for all
                audiences. There is a variety of age ranges for the other users
                on KnowStory, and the item needs to be appropriate for all
                audiences, even if the item is intended for a mature end user.
                This means there is no allowance for sexual, graphic, violent,
                drug-related, or other content that is intended for a mature
                viewer only.
              </li>
              <li>
                KnowStory retains the right to remove, revoke, alter or delete
                your listing or display and the content associated with it on
                KnowStory for the item, for violation of any of the points in
                policy, or for any reason at all.
              </li>
              <li>
                KnowStory is granted a royalty-free, non-exclusive, worldwide,
                perpetual, irrevocable right and license to use, reproduce,
                perform, display, distribute, adapt, modify, re-format, create
                derivative works of, and otherwise commercially or
                non-commercially exploit in any manner, any and all of the
                content you submit to KnowStory and its affiliates.
              </li>
              <li>
                KnowStory is given the right to display, advertise, promote,
                share, use the item, along with any pictures, logos,
                description, content, trademarks or anything else associated
                with the item when it is uploaded, listed, edited, altered or
                added to KnowStory.
              </li>
            </Ul>
          </li>
          <li>
            KnowStory provides a means for users to find and discover item(s)
            provided and/or delivered from others. Itself is not the provider or
            creator of the item(s), but a means for discovery. As such,
            KnowStory is not associated, or partnered with, or contribute to the
            providers or sellers of item(s) in any way other than the points
            covered in this policy and the displaying of item(s) as an
            independent third-party. Further, KnowStory does not represent or
            speak for or on behalf of entities selling or displaying item(s) and
            does not guarantee or fulfill any claims conveyed by entities. The
            content provided by selling/providing entities is entirely provided
            by or created by that entity, unless otherwise covered under point
            2, and so responsibility, fulfillment and liability of statements
            belongs entirely to the selling/providing enity or one who listed
            the item(s).
          </li>
          <li>
            KnowStory provides a means for users to find and discover item(s)
            provided and/or delivered from others. Itself is not the provider or
            creator of the item(s), but a means for discovery. As such,
            KnowStory is not associated, or partnered with, or contribute to the
            providers or sellers of item(s) in any way other than the points
            covered in this policy and the displaying of item(s) as an
            independent third-party. Further, KnowStory does not represent or
            speak for or on behalf of entities selling or displaying item(s) and
            does not guarantee or fulfill any claims conveyed by entities. The
            content provided by selling/providing entities is entirely provided
            by or created by that entity, unless otherwise covered under point
            2, and so responsibility, fulfillment and liability of statements
            belongs entirely to the selling/providing enity or one who listed
            the item(s).
          </li>
          <li>
            General Release. BECAUSE KNOWSTORY IS NOT INVOLVED IN TRANSACTIONS
            BETWEEN BUYERS AND SELLERS/PROVIDERS OR OTHER PARTICIPANT DEALINGS,
            IF A DISPUTE ARISES BETWEEN ONE OR MORE PARTICIPANTS, EACH OF YOU
            RELEASE KNOWSTORY (AND THEIR RESPECTIVE AGENTS AND EMPLOYEES) FROM
            CLAIMS, DEMANDS, AND DAMAGES (ACTUAL AND CONSEQUENTIAL) OF EVERY
            KIND AND NATURE, KNOWN AND UNKNOWN, SUSPECTED AND UNSUSPECTED,
            DISCLOSED AND UNDISCLOSED, ARISING OUT OF OR IN ANY WAY CONNECTED
            WITH SUCH DISPUTES.
          </li>
          <li>
            Indemnity and Defense. You will defend, indemnify and hold harmless
            KnowStory and its affiliates (and their respective employees,
            directors, agents and representatives) from and against any and all
            claims, costs, losses, damages, judgments, penalties, interest and
            expenses (including reasonable attorneys' fees) arising out of any
            Claim that arises out of or relates to: (i) any actual or alleged
            breach of your representations, warranties, or obligations set forth
            in this Agreement and Policy; or (ii) your own website or other
            sales channels, the products you sell, any content you provide, the
            advertisement, offer, sale or return of any products you sell, any
            actual or alleged infringement of any intellectual property or
            proprietary rights by any products you sell or content you provide,
            or Seller Taxes or the collection, payment or failure to collect or
            pay Seller Taxes. For purposes hereof: "Claim" means any claim,
            action, audit, investigation, inquiry or other proceeding instituted
            by a person or entity.
          </li>
          <li>
            Limitation of Liability. KNOWSTORY WILL NOT BE LIABLE FOR ANY
            DAMAGES OF ANY KIND, INCLUDING WITHOUT LIMITATION DIRECT, INDIRECT,
            INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES, ARISING OUT OF OR
            IN CONNECTION WITH THIS AGREEMENT AND POLICY, THE SITE, THE
            SERVICES, THE TRANSACTION PROCESSING SERVICE, THE INABILITY TO USE
            THE SERVICES OR THE TRANSACTION PROCESSING SERVICE, OR THOSE
            RESULTING FROM ANY GOODS OR SERVICES PURCHASED OR OBTAINED OR
            MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH THE SERVICES.
          </li>
          <li>
            If you encounter any violation of the points in this policy or
            questionable activity not otherwise noted, you will at once report
            it to the KnowStory administrators and allow KnowStory to take
            proper action to handle within a reasonable amount of time.
          </li>
        </Ol>
      </TextContainer>

      <TextContainer>
        <Subheading>Amendments</Subheading>
        <P>
          Your continued use of KnowStory following changes to our terms
          constitutes your acceptance of our amended terms. To ensure you keep
          up to date on our terms it is advised you check them out by clicking
          the Policies link at the bottom of the website and check the post date
          regularly to ensure you have read the most recent issue. When changes
          or revisions are made to our terms we will send you an email notifying
          you as well.
        </P>
      </TextContainer>

      <TextContainer>
        <Subheading>Termination</Subheading>
        <P>
          If you violate the letter or spirit of this Statement, or otherwise
          create risk or possible legal exposure for us, we can stop providing
          all or part of KnowStory to you. We will notify you by email or at the
          next time you attempt to access your account. You may also delete your
          account or disable your application at any time.
        </P>
      </TextContainer>

      <TextContainer>
        <Subheading>Disputes</Subheading>
        <P>
          WE TRY TO KEEP KNOWSTORY UP, BUG-FREE, AND SAFE, BUT YOU USE IT AT
          YOUR OWN RISK. WE ARE PROVIDING KNOWSTORY AS IS WITHOUT ANY EXPRESS OR
          IMPLIED WARRANTIES INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES
          OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT. WE DO NOT GUARANTEE THAT KNOWSTORY WILL ALWAYS BE
          SAFE, SECURE OR ERROR-FREE OR THAT KNOWSTORY WILL ALWAYS FUNCTION
          WITHOUT DISRUPTIONS, DELAYS OR IMPERFECTIONS. KNOWSTORY IS NOT
          RESPONSIBLE FOR THE ACTIONS, CONTENT, INFORMATION, OR DATA OF THIRD
          PARTIES, AND YOU RELEASE US, OUR DIRECTORS, OFFICERS, EMPLOYEES, AND
          AGENTS FROM ANY CLAIMS AND DAMAGES, KNOWN AND UNKNOWN, ARISING OUT OF
          OR IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE AGAINST ANY SUCH THIRD
          PARTIES. IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL
          CODE §1542, WHICH SAYS: A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS
          WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT
          THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM MUST HAVE
          MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR. WE WILL NOT BE
          LIABLE TO YOU FOR ANY LOST PROFITS OR OTHER CONSEQUENTIAL, SPECIAL,
          INDIRECT, OR INCIDENTAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH
          THIS STATEMENT OR KNOWSTORY, EVEN IF WE HAVE BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES. OUR AGGREGATE LIABILITY ARISING OUT OF
          THIS STATEMENT OR KNOWSTORY WILL NOT EXCEED THE GREATER OF ONE HUNDRED
          DOLLARS ($100) OR THE AMOUNT YOU HAVE PAID US IN THE PAST TWELVE
          MONTHS. APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF
          LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
          LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN SUCH CASES,
          KNOWSTORY'S LIABILITY WILL BE LIMITED TO THE FULLEST EXTENT PERMITTED
          BY APPLICABLE LAW.
        </P>
      </TextContainer>
    </Container>
  )
}

export default TermsAndConditions
