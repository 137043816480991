import React from "react"
import { twc } from "react-twc"
import { useTable } from "../../../../hooks/useTable"
import { useElementWidth } from "../../../../hooks/useElementWidth"
import Stub from "../../../stub"
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined"
import EditOutlinedIcon from "@material-ui/icons/EditOutlined"
import { TablePagination, Tooltip } from "@material-ui/core"
import { SearchOutlined } from "@material-ui/icons"
import Loader from "../../../ui/loader"

export const DataTableRow = twc.div.attrs({ role: "row" })((props) => [
  `grid grid-cols-subgrid border-b border-gray-200`,
  !props.$isHeader ? "group" : "border-t",
])

export const DataTableCell = twc.div.attrs({ role: "cell" })((props) => [
  "flex items-center py-2 px-1 min-h-11 max-h-18 text-sm transition-colors transition-200 first:border-l-2 first:border-l-transparent",
  props.$isFirstColumnPinned &&
    "first:border-r first:border-gray-200 first:sticky first:left-0 first:z-10",
  props.$isLastColumnPinned &&
    "last:border-l last:border-gray-200 last:sticky last:right-0 last:z-10",
  // props.isScrolled && "first:border-r-2",
  !props.$isHeaderCell && "group-hover:first:border-l-primary ",
])

export const MIN_TABLE_CELL_WIDTH = 160

const DataTable = ({
  columns,
  data,
  actions,
  onDetailsOpen,
  onEditOpen,
  isFirstColumnPinned = true,
  actionsPinned = true,
  customColumnsWidth = false,
  isSearchable = false,
  searchInputPlaceholder = "Search by name",
  getData,
  availableOffsets,
  notFoundText = "No records found",
  total = 0,
}) => {
  const tableRef = React.useRef(null)

  const {
    composeValue,
    composeActions,
    pagination,
    search,
    dataReceived,
    refetch,
  } = useTable({ isSearchable, getData, availableOffsets, total })

  const [isTableScrolled, setIsTableScrolled] = React.useState(false)
  const [gridCols, setGridCols] = React.useState(
    `repeat(${columns.length}, 1fr)`
  )

  const containerId = "table-container"

  const containerWidth = useElementWidth(containerId)

  React.useEffect(() => {
    const table = tableRef.current

    const colFixedWidthSum = columns.reduce(
      (prev, cur) =>
        prev +
        (customColumnsWidth
          ? cur.width || MIN_TABLE_CELL_WIDTH
          : MIN_TABLE_CELL_WIDTH),
      0
    )

    const isFluid = colFixedWidthSum <= containerWidth

    if (customColumnsWidth) {
      const calculateGridCols = () => {
        return columns.reduce((prev, column) => {
          if (!isFluid) {
            return (
              prev +
              ` ${
                customColumnsWidth
                  ? column.width || MIN_TABLE_CELL_WIDTH
                  : MIN_TABLE_CELL_WIDTH
              }px`
            )
          } else {
            return prev + ` ${column.width ? column.width + "px" : "1fr"}`
          }
        }, "")
      }

      setGridCols(calculateGridCols())
    }

    const setScroll = (e) => {
      if (isFirstColumnPinned) {
        if (e.target.scrollLeft > 0) {
          setIsTableScrolled(true)
        } else {
          setIsTableScrolled(false)
        }
      }
    }

    if (containerWidth) {
      table.addEventListener("scroll", setScroll)
      return () => {
        table.removeEventListener("scroll", setScroll)
      }
    }
  }, [containerWidth, dataReceived])

  const canOpenDetails = typeof onDetailsOpen === "function"
  const canEditData = typeof onEditOpen === "function"
  const hasLeftControls = canOpenDetails || canEditData

  return (
    <div>
      {/*{dataReceived && !!data.length && (*/}
      <>
        {isSearchable && dataReceived && !!data.length && (
          <div className="mb-4">
            <div className="search-form search-form--compact">
              <div className="search-form__icon">
                <SearchOutlined />
              </div>
              <input
                type="text"
                id="search"
                name="search"
                placeholder={searchInputPlaceholder}
                className="search-form__field flex-1"
                autoComplete="off"
                value={search.value}
                onChange={(e) => search.setValue(e.target.value)}
              />
            </div>
          </div>
        )}
        <div id={containerId}>
          {!dataReceived && <Loader />}

          {dataReceived && !data.length && <Stub text={notFoundText} />}

          {!!containerWidth && (
            <div
              role={"table"}
              className={"pb-4 overflow-x-auto grid"}
              ref={tableRef}
              style={{
                gridTemplateColumns: gridCols,
                width: containerWidth,
              }}
            >
              {dataReceived && !!data.length && (
                <>
                  <DataTableRow
                    $isHeader
                    style={{
                      gridColumn: `span ${columns.length}`,
                    }}
                  >
                    {columns.map((column, index) => (
                      <DataTableCell
                        key={index}
                        $isFirstColumnPinned={isFirstColumnPinned}
                        $isLastColumnPinned={actionsPinned}
                        $isScrolled={isTableScrolled}
                        $isHeaderCell
                        className={"bg-grey7"}
                      >
                        <div
                          className={
                            hasLeftControls && index === 0
                              ? "pl-[calc(2rem_+_1px)]"
                              : ""
                          }
                        >
                          {column.label}
                        </div>
                      </DataTableCell>
                    ))}
                  </DataTableRow>
                  {data.map((record, index) => (
                    <DataTableRow
                      key={index}
                      style={{
                        gridColumn: `span ${columns.length}`,
                      }}
                    >
                      {record.info.map((recordValue, valueIdx) => (
                        <DataTableCell
                          key={valueIdx}
                          $isFirstColumnPinned={isFirstColumnPinned}
                          $isScrolled={isTableScrolled}
                          className={"bg-white"}
                        >
                          {hasLeftControls && valueIdx === 0 ? (
                            <div className={"flex items-center gap-1"}>
                              <div
                                className={
                                  "flex flex-col gap-2 items-center justify-center border-r pr-1 border-gray-200"
                                }
                              >
                                {canOpenDetails && (
                                  <Tooltip title="Open details" arrow>
                                    <button
                                      className={"text-primary"}
                                      onClick={() => onDetailsOpen(record)}
                                    >
                                      <InfoOutlinedIcon />
                                    </button>
                                  </Tooltip>
                                )}
                                {canEditData && (
                                  <Tooltip title="Edit record" arrow>
                                    <button
                                      className={"text-primary"}
                                      onClick={() => onEditOpen(record)}
                                    >
                                      <EditOutlinedIcon />
                                    </button>
                                  </Tooltip>
                                )}
                              </div>
                              <div className="flex-1">
                                {composeValue(recordValue)}
                              </div>
                            </div>
                          ) : (
                            composeValue(recordValue)
                          )}
                        </DataTableCell>
                      ))}
                      {typeof actions === "function" && (
                        <DataTableCell
                          $isFirstColumnPinned={isFirstColumnPinned}
                          $isLastColumnPinned={actionsPinned}
                          $isScrolled={isTableScrolled}
                          className={"bg-white"}
                        >
                          <div className="flex flex-wrap gap-1 items-center text-primary">
                            {actions(record.raw, refetch).map((a, aIdx) => (
                              <div key={aIdx}>{composeActions(a)}</div>
                            ))}
                          </div>
                        </DataTableCell>
                      )}
                      {/*{actions(record)}*/}
                    </DataTableRow>
                  ))}
                </>
              )}
            </div>
          )}
        </div>

        {dataReceived && !!data.length && <TablePagination {...pagination} />}
      </>
    </div>
  )
}

export default DataTable
