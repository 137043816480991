import React from "react"
import { generatePath, Link } from "react-router-dom"
import { getGroupLogo } from "../../../../../library/utils/getGroupLogo"
import * as routes from "../../../../../library/constants/routes"
import { GROUP_TYPES } from "../../../../../library/constants/groupTypes"
import LinesEllipsis from "react-lines-ellipsis"
import MoreDropdown from "../../../../../components/ui/dropdowns/moreDropdown/moreDropdown"
import VerticalMenu from "../../../../../components/ui/verticalMenu/verticalMenu"
import Button from "@material-ui/core/Button/Button"

const InvenstoryGroupCard = (props) => {
  const {
    group,
    hasMenu,
    hasButton,
    buttonType,
    buttonText,
    buttonHandler,
    dropdownMenuItems,
  } = props
  if (group && !group.photo && group.photo !== "") {
    const { id, name, description, type, closed, participants_number } = group
    const groupStats = `${GROUP_TYPES[type].title} (${
      closed ? "closed" : "open"
    } group), ${participants_number} members`
    return (
      <div className="box list-item">
        <div className="f aic full-width">
          <Link
            to={generatePath(routes.GROUP, { groupId: id })}
            className="list-item__img"
          >
            <img src={getGroupLogo(props.group)} alt={name} />
          </Link>
          <div className="list-item__info">
            <Link
              to={generatePath(routes.GROUP, { groupId: id })}
              className="link"
            >
              <div className="list-item__name">{name}</div>
            </Link>
            <span className="list-item__description">{groupStats}</span>
            {description !== "Unknown" && (
              <div className="break-word">
                <LinesEllipsis
                  text={description}
                  maxLine="2"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
              </div>
            )}
          </div>
        </div>
        <div className={`list-item__control-panel`}>
          {hasMenu && (
            <MoreDropdown>
              <VerticalMenu
                menuItems={dropdownMenuItems}
                classes={"more-dropdown-menu__list"}
              />
            </MoreDropdown>
          )}
          {hasButton && (
            <>
              <Button
                color="primary"
                variant={buttonType}
                onClick={buttonHandler}
              >
                {buttonText}
              </Button>
            </>
          )}
        </div>
      </div>
    )
  }
  return null
}

export default InvenstoryGroupCard;
