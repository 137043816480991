import { useQuery } from "@tanstack/react-query"
import { GROUPS } from "../../../library/store/actions/types/groupsTypes"
import { useIdParam } from "../../useIdParam"
import { useCurrentUser } from "../user/useUser"
import { useSelector } from "react-redux"

export const useGroup = ({ id = null, options = {} } = {}) => {
  const groupIdParam = useIdParam("groupId")
  const __id = id || groupIdParam

  const { isLoggedIn } = useSelector(({ auth }) => auth)

  const queryKey = [
    GROUPS.GET,
    {
      id: __id,
      participants_limit: 5,
      connections_limit: isLoggedIn ? 5 : 0,
      products_limit: isLoggedIn ? 5 : 0,
    },
  ]

  const { data: group, ...query } = useQuery({
    queryKey: queryKey,
    staleTime: Infinity,
    ...options,
  })

  return {
    group,
    isAvailable: !group?.blocked && !group?.deleted,
    canEdit: group?.is_admin,
    ...query,
  }
}
