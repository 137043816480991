import PickerSelect from "../select/pickerSelect"

const GradePicker = ({
  value,
  onChange,
  grades = [],
  hasError = false,
  errorMessage = "Field is required",
  multiple = false,
  required = false,
  disabled = false,
}) => {
  const entityName = multiple ? "grades" : "grade"

  return (
    <PickerSelect
      name={"grade"}
      entityName={entityName}
      value={value}
      onChange={onChange}
      required={required}
      hasError={hasError}
      errorMessage={errorMessage}
      disabled={disabled}
      multiple={multiple}
      list={grades}
    />
  )
}

export default GradePicker
