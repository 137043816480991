import styled from "@emotion/styled"
import { colors } from "../../../../../library/constants/styles/colors"
import { blinkAnimation } from "../../../../../library/constants/styles/animation"

export const LogoSkeleton = styled.div`
  width: 210px;
  height: 210px;
  background-color: ${colors.white};
  background: linear-gradient(
    to right,
    ${colors.black7} 0%,
    ${colors.black4} 50%,
    ${colors.black7} 100%
  );
  background-size: 150% 150%;
  animation: ${blinkAnimation} 2s ease-in-out infinite;
`
