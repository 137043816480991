import React, { Component } from "react"
import "./expandableText.scss"

class ExpandableText extends Component {
  state = {
    text:
      this.props.text.length > this.props.limit
        ? this.props.text.slice(0, this.props.limit).concat("...")
        : this.props.text,
    isHidden: this.props.text.length > this.props.limit,
    isExpanded: false,
  }

  showFullText = () => {
    this.setState({
      isHidden: false,
      isExpanded: true,
      text: this.props.text,
    })
  }

  showLessText = () => {
    this.setState({
      isHidden: true,
      isExpanded: false,
      text: this.props.text.slice(0, this.props.limit).concat("..."),
    })
  }

  render() {
    const { text, isExpanded, isHidden } = this.state

    return (
      <>
        <pre className="expandable-text">{text}</pre>
        {isHidden && (
          <button className="btn btn--plain" onClick={this.showFullText}>
            Show more...
          </button>
        )}
        {isExpanded && (
          <button className="btn btn--plain" onClick={this.showLessText}>
            Show less
          </button>
        )}
      </>
    )
  }
}

export default ExpandableText;
