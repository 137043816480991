import React, { useMemo } from "react"
import {
  useGroup,
  useGroupMutations,
  useGroupRelations,
} from "../../../../../../hooks/data/groups/groups"
import DropdownControl from "../../../../../../components/ui/dropdowns/dropdownControl"
import MenuList from "@material-ui/core/MenuList/MenuList"
import { withStyles } from "@material-ui/core"
import { colors } from "../../../../../../library/constants/styles/colors"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import { useToggle } from "../../../../../../hooks/useToggle"
import AlertDialog from "../../../../../../components/ui/alertDialog"
import Button from "@material-ui/core/Button"
import { useSelector } from "react-redux"
import AuthorizationCTA from "../../../../../../library/utils/AuthorizationCTA"

const CustomMenuItem = withStyles({
  root: {
    color: colors.primary,
  },
})(MenuItem)

const GroupHeaderControls = () => {
  const { isLoggedIn } = useSelector(({ auth }) => auth)

  const { group, canEdit, queryKey } = useGroup()

  const { joinGroup, leaveGroup, requests } = useGroupRelations({
    queryKey,
    isMutatingInfinite: false,
  })

  const { deleteGroup } = useGroupMutations({ queryKey })

  const onJoin = async () => await joinGroup.mutateAsync(group.id)
  const onLeave = async () => await leaveGroup.mutateAsync(group.id)
  const onCancelRequest = async () =>
    await requests.cancel.mutateAsync(group.id)

  const [deletionOpen, toggleDeletion] = useToggle(false)

  const onDelete = async () => {
    await deleteGroup.mutateAsync(group.id)
  }

  const dropdownControl = () => {
    const buttonLabel = () => {
      if (group.is_member || canEdit) return "Member"
      if (group.is_requesting) return "Request sent"
      return null
    }

    return (
      <DropdownControl buttonLabel={buttonLabel()}>
        <MenuList>
          {group.is_creator && (
            <CustomMenuItem onClick={toggleDeletion}>
              Delete group
            </CustomMenuItem>
          )}
          {!group.is_creator && group.is_member && (
            <CustomMenuItem onClick={onLeave}>Leave the group</CustomMenuItem>
          )}
          {group.is_requesting && (
            <CustomMenuItem onClick={onCancelRequest}>
              Cancel request
            </CustomMenuItem>
          )}
        </MenuList>
      </DropdownControl>
    )
  }

  const noRelations = useMemo(() => {
    return !!(!group.is_requesting && !group.is_member && !group.is_admin)
  }, [group])

  if (!isLoggedIn) {
    return (
      <div className={"flex justify-center items-center relative"}>
        <AuthorizationCTA
          component={({ toggle }) => (
            <Button variant={"contained"} color={"primary"} onClick={toggle}>
              Join
            </Button>
          )}
        />
      </div>
    )
  }

  return (
    <div className={"flex justify-center items-center relative"}>
      {noRelations || group.is_invited ? (
        <Button variant="contained" color="primary" onClick={onJoin}>
          Join group
        </Button>
      ) : (
        dropdownControl()
      )}
      {deletionOpen && (
        <AlertDialog
          open={deletionOpen}
          handleClose={toggleDeletion}
          handleAccept={onDelete}
          title={"Delete group from site?"}
          message={
            "If you delete group you will not be able to view information on the site. You can restore the group only through the support site."
          }
        />
      )}
    </div>
  )
}

export default GroupHeaderControls
