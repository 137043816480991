import React, { Component } from "react"
import "./productsListItem.scss"
import { generatePath, Link, withRouter } from "react-router-dom"
import * as routes from "../../../../../library/constants/routes"
import { productContentTypes } from "../../../../../library/constants/productDictionary"
import StarRating from "../../../../../components/ui/starRating"
import { withSnackbar } from "notistack"
import MoreDropdown from "../../../../../components/ui/dropdowns/moreDropdown/moreDropdown"
import VerticalMenu from "../../../../../components/ui/verticalMenu"
import { bindActionCreators } from "redux"
import { deleteProduct } from "../../../../../library/store/actions/creators/productsCreators"
import { connect } from "react-redux"
import AlertDialog from "../../../../../components/ui/alertDialog/alertDialog"
import getErrorText from "../../../../../library/constants/errorTypes"
import {
  createBookmark,
  deleteBookmark,
  foldersList,
} from "../../../../../library/store/actions/creators/bookmarksCreators"
import {
  createInvenstoryRecord,
  deleteInvenstoryRecord,
} from "../../../../../library/store/actions/creators/invenstoryCreators"
import Button from "@material-ui/core/Button/Button"
import AddToFolderModal from "../../../bookmarks/folders/addToFolder/addToFolder"
import ComplaintsModal from "../../../../../components/complaints/complaintsModal"
import PlainLink from "../../../../../components/ui/link"
import logoDefault from "../../../../../assets/img/product.svg"

class ProductsListItem extends Component {
  state = {
    dataReceived: true,
    openBookmarksModal: false,
    openDeleteModal: false,
    snackbarOpen: false,
    deleted: false,
    bookmarked: !!this.props.product.bookmark,
    inInvenstory: !!this.props.product.record,
    recordId: this.props.product.record ? this.props.product.record.id : null,
    complaintsModal: false,
    isReported: this.props.product.is_reported,
  }

  handleClickOpen = (type) => {
    if (type === "deleteProduct") {
      this.setState({ openDeleteModal: true })
    } else {
      this.setState({ openBookmarksModal: true })
    }
  }

  handleClose = async (type) => {
    if (type === "deleteProduct") {
      this.setState({ openDeleteModal: false })
    } else {
      this.setState({ openBookmarksModal: false })
    }
  }

  composeContentType = () => {
    const { content_type } = this.props.product

    return productContentTypes.filter((item) => {
      return item.id === content_type
    })[0]
  }

  addToBookmarks = async () => {
    const {
      product: { id },
    } = this.props

    try {
      await this.props.createBookmark({ product_id: id })
      await this.props.foldersList()
      this.setState({ bookmarked: true })
      await this.props.enqueueSnackbar("Saved to bookmarks", {
        autoHideDuration: 5000,
        action: (key) => {
          return (
            <>
              <Button
                color="primary"
                onClick={() => {
                  this.props.closeSnackbar(key)
                  this.props.history.push(routes.BOOKMARKS_PRODUCTS)
                }}
              >
                View
              </Button>
              {this.props.bookmarks.folders.length > 0 && (
                <Button
                  color="primary"
                  onClick={() => this.handleClickOpen("addToFolder")}
                >
                  Add to folder
                </Button>
              )}
            </>
          )
        },
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  deleteBookmark = async () => {
    const { product } = this.props

    try {
      await this.props.deleteBookmark({ product_id: product.id })
      this.setState({ bookmarked: false })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  processDelete = async () => {
    const {
      product: { id },
    } = this.props

    try {
      await this.props.deleteProduct({ id })
      await this.handleClose("deleteProduct")
      this.setState({ deleted: true })
      this.props.enqueueSnackbar("Product deleted", { variant: "success" })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }
  addToInvenstory = async () => {
    if (!this.state.dataReceived) return
    this.setState({ dataReceived: false })
    const {
      name,
      subjects,
      content_type,
      grades,
      platforms,
      file_types,
      url,
      version,
      id,
    } = this.props.product
    try {
      const res = await this.props.createInvenstoryRecord({
        name,
        subjects,
        content_type,
        grades,
        platforms,
        file_types,
        url,
        version,
        product_id: id,
      })
      this.setState({ inInvenstory: true, recordId: res.id })
      this.props.enqueueSnackbar("Product added to your InvenStory", {
        variant: "success",
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
    this.setState({ dataReceived: true })
  }

  removeFromInvenstory = async () => {
    if (!this.state.dataReceived) return
    this.setState({ dataReceived: false })
    const { recordId } = this.state
    try {
      await this.props.deleteInvenstoryRecord({ id: recordId })
      this.setState({ inInvenstory: false })
      this.props.enqueueSnackbar("Product removed from your InvenStory", {
        variant: "success",
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
    this.setState({ dataReceived: true })
  }
  showComplaintsModal = () => {
    this.setState({ complaintsModal: true })
  }
  hideComplaintsModal = () => {
    this.setState({ complaintsModal: false })
  }

  render() {
    const { deleted, bookmarked, inInvenstory } = this.state
    const { product, user, group, manageable } = this.props

    const creator = product.creator_user
      ? {
          name: `${product.creator_user.first_name} ${product.creator_user.last_name}`,
          url: generatePath(routes.USER, { userId: product.creator_user.id }),
        }
      : product.creator_group
        ? {
            name: product.creator_group.name,
            url: generatePath(routes.GROUP, {
              groupId: product.creator_group.id,
            }),
          }
        : user
          ? {
              name: `${user.first_name} ${user.last_name}`,
              url: generatePath(routes.USER, { userId: user.id }),
            }
          : {
              name: group.name,
              url: generatePath(routes.GROUP, { groupId: group.id }),
            }

    const dropdownMenuItems = [
      !product.can_edit && {
        type: "button",
        action: this.showComplaintsModal,
        label: "Report",
        disabled: this.state.isReported,
      },
      !product.blocked &&
        !product.deleted &&
        (!bookmarked
          ? {
              type: "button",
              action: this.addToBookmarks,
              label: "Add to bookmarks",
            }
          : {
              type: "button",
              action: this.deleteBookmark,
              label: "Remove from bookmarks",
            }),
      !product.blocked &&
        !product.deleted &&
        (!inInvenstory
          ? {
              type: "button",
              action: this.addToInvenstory,
              label: "Add to my InvenStory",
            }
          : {
              type: "button",
              action: this.removeFromInvenstory,
              label: "Remove from my InvenStory",
            }),
      manageable &&
        product.can_edit && {
          path: generatePath(routes.PRODUCT_SETTINGS, {
            productId: product.id,
          }),
          label: "Edit product",
        },
      manageable &&
        product.can_delete && {
          type: "h-divider",
          id: "hd1",
        },
      manageable &&
        product.can_delete && {
          type: "button",
          action: () => this.handleClickOpen("deleteProduct"),
          label: "Delete product",
        },
    ]

    if (deleted) {
      return null
    }

    const productUrl = generatePath(routes.PRODUCT, { productId: product.id })

    return (
      <div
        className={
          "relative grid grid-rows-subgrid gap-2 rounded-[2px] shadow-card bg-white relative p-3"
        }
        style={{ gridRow: "span 6" }}
      >
        <ComplaintsModal
          open={this.state.complaintsModal}
          product_id={product.id}
          onClose={this.hideComplaintsModal}
          callback={() => this.setState({ isReported: true })}
        />
        {!product.deleted && (
          <div className="absolute right-2 top-2 self-end text-black38">
            <MoreDropdown>
              <VerticalMenu
                menuItems={dropdownMenuItems}
                classes={"more-dropdown-menu__list"}
              />
            </MoreDropdown>
          </div>
        )}
        <Link
          to={generatePath(routes.PRODUCT, { productId: product.id })}
          className={
            "aspect-square flex items-center justify-center rounded-[2px]"
          }
        >
          <img
            src={product.image || logoDefault}
            alt={product.name}
            className={"object-contain object-center max-h-[100%]"}
          />
        </Link>
        <div>
          <PlainLink to={productUrl} bold className={"!line-clamp-2"}>
            {product.name}
          </PlainLink>
        </div>
        <div>
          <div className={"mb-1"}>
            <p className={"text-black54 text-xs mb-0.5"}>Creator:</p>
            <PlainLink to={creator.url} className={"!line-clamp-2"}>
              {creator.name}
            </PlainLink>
          </div>
          {!!this.composeContentType() && (
            <div>
              <p className={"text-black54 text-xs mb-0.5"}>Content type:</p>
              <p>{this.composeContentType()?.name}</p>
            </div>
          )}
        </div>
        <div>
          <StarRating rating={product.rating} />
        </div>
        <hr className={"my-2 border-divider"} />
        <div>
          {product.paid && (
            <>
              {!!product.price && (
                <div className="product-tile__price">
                  <span className="product-tile__price-label">
                    Minimal price:
                  </span>
                  <span className="product-tile__price-value">
                    ${product.price.toFixed(2)}
                  </span>
                </div>
              )}
              {/*{!!product.pricing_levels && !!product.pricing_levels.length && (*/}
              {/*    product.pricing_levels.map((level, index) => {*/}
              {/*        return (*/}
              {/*            <div className="product-tile__price" key={index}>*/}
              {/*                <span className="product-tile__price-label">Price {index + 1}:</span>*/}
              {/*                <div className="product-tile__price-value f aic">*/}
              {/*                    <span>${level.price}</span>*/}
              {/*                    <Tooltip title={level.description} arrow>*/}
              {/*                        <InfoIcon style={{*/}
              {/*                            marginLeft: 5,*/}
              {/*                            fontSize: 20,*/}
              {/*                            color: colors.primary,*/}
              {/*                            cursor: "pointer",*/}
              {/*                        }}/>*/}
              {/*                    </Tooltip>*/}
              {/*                </div>*/}
              {/*            </div>*/}
              {/*        );*/}
              {/*    })*/}
              {/*)}*/}
            </>
          )}
          {product.paid &&
            product.price === 0 &&
            !product.pricing_levels.length && (
              <div className="product-tile__price free">
                <span className="product-tile__price-value">Paid</span>
              </div>
            )}
          {!product.paid && (
            <div className="product-tile__price free">
              <span className="product-tile__price-value">Free</span>
            </div>
          )}
        </div>

        <AlertDialog
          open={this.state.openDeleteModal}
          handleClose={() => this.handleClose("deleteProduct")}
          handleAccept={this.processDelete}
          title="Delete product?"
          message="Deleting this product deletes all descriptions, links and information."
        />
        {this.state.openBookmarksModal && (
          <AddToFolderModal
            open={this.state.openBookmarksModal}
            handleClose={() => this.handleClose("addToFolder")}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ products, bookmarks }) => ({ products, bookmarks })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteProduct,
      createBookmark,
      deleteBookmark,
      foldersList,
      createInvenstoryRecord,
      deleteInvenstoryRecord,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(ProductsListItem)))
