import React, { Component } from "react"
import ListSearchHeader from "../../../../components/ui/listSearchHeader/listSearchHeader"
import * as routes from "../../../../library/constants/routes"
import ConversationsListItem from "./conversationsListItem"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import {
  clearChatList,
  getChatList,
  searchChats,
  updateChatList,
} from "../../../../library/store/actions/creators/messagesCreators"
import { decrementChatsCount } from "../../../../library/store/actions/creators/notificationCreators"
import Loader from "../../../../components/ui/loader"
import { wsUrl } from "../../../../library/networking/API"
import InterlocutorListItem from "./interlocutorListItem/interlocutorListItem"
import { LIMIT } from "../../../../library/constants/limits"
import withScroll from "../../../../hocs/withScroll/withScroll"
import getErrorText from "../../../../library/constants/errorTypes"
import * as qs from "query-string"

class ConversationsList extends Component {
  state = {
    dataReceived: true,
  }

  async componentDidMount() {
    const { search } = this.props.location
    const { q: query } = qs.parse(search)
    query
      ? await this.searchConversations()
      : await this.getChatList({ offset: 0 })

    this.connectSocket()
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const { search } = this.props.location
    const { q: query } = qs.parse(search)
    if (
      this.props.match.path !== prevProps.match.path ||
      (prevProps.location !== this.props.location && this.state.dataReceived)
    ) {
      try {
        await this.props.clearChatList()
        query ? await this.searchConversations() : await this.getChatList()
        // this.state.content ? await this.searchConversations(this.state.content) : await this.getChatList();
      } catch ({ error }) {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    }
  }

  componentWillUnmount() {
    this.socket.close()
    this.socket = null
    this.props.clearChatList()
  }

  getChatList = async ({
    offset = this.props.message.chatList
      ? this.props.message.chatList.length
      : 0,
  } = {}) => {
    if (!this.state.dataReceived) return
    this.setState({ dataReceived: false })

    try {
      switch (this.props.match.path) {
        case routes.CONVERSATIONS: {
          await this.props.getChatList({ unread: false, offset, limit: LIMIT })
          break
        }
        case routes.CONVERSATIONS_UNREAD: {
          await this.props.getChatList({ unread: true, offset, limit: LIMIT })
          break
        }
        default: {
          await this.props.getChatList({ unread: false, offset, limit: LIMIT })
          break
        }
      }
      this.setState({ dataReceived: true })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  onScroll = async () => {
    if (!this.state.dataReceived) return
    const { search } = this.props.location
    const { q: query } = qs.parse(search)
    const { chatSearchList } = this.props.message
    try {
      query
        ? await this.searchConversations({
            offset: chatSearchList.messages.length,
          })
        : await this.getChatList()
      this.setState({ dataReceived: true })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  connectSocket = () => {
    this.socket = new WebSocket(`${wsUrl}messages.chats.subscribe`)
    // this.socket.onclose = () => this.connectSocket();
    this.socket.onopen = () => this.heartbeat()
    this.socket.onmessage = (event) => {
      this.props.updateChatList({ payload: JSON.parse(event.data) })
    }
  }

  heartbeat = () => {
    if (!this.socket) return
    if (this.socket.readyState !== 1) return
    this.socket.send(
      JSON.stringify({
        token: this.props.auth.userData.token,
      })
    )
    setTimeout(this.heartbeat, 15000)
  }

  setContent = (content) => {
    const { history } = this.props
    this.props.clearChatList()
    history.push({
      search: `?q=${content}`,
    })
  }

  searchConversations = async ({ offset = 0 } = {}) => {
    const { search } = this.props.location
    const { q: query } = qs.parse(search)

    this.setState({ dataReceived: false })
    try {
      await this.props.searchChats({
        content: query || undefined,
        offset,
        limit: LIMIT,
        unread: this.props.match.path === routes.CONVERSATIONS_UNREAD,
      })
      this.setState({ dataReceived: true })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  decrementChatsCounter(isRead) {
    if (isRead) return
    this.props.decrementChatsCount()
  }

  getNoResultLabel = () => {
    const { search } = this.props.location
    const { q: query } = qs.parse(search)
    switch (this.props.match.path) {
      case routes.CONVERSATIONS:
        return query ? undefined : "You don`t have chats yet"
      case routes.CONVERSATIONS_UNREAD:
        return query ? undefined : "You don`t have unread chats yet"
    }
  }

  render() {
    const { chatList, chatSearchList } = this.props.message
    const { search } = this.props.location
    const { q: query } = qs.parse(search)
    const { dataReceived } = this.state
    const headerTitle = () => {
      switch (this.props.match.path) {
        case routes.CONVERSATIONS:
          return "All chats"

        case routes.CONVERSATIONS_UNREAD:
          return "Unread chats"

        case routes.CONVERSATIONS_FAVORITE:
          return "Favorite conversations"

        default:
          return ""
      }
    }

    return (
      <>
        <ListSearchHeader
          title={headerTitle()}
          placeholder="Search chat"
          search={this.setContent}
          showStartChat={true}
          noResults={
            query
              ? chatSearchList &&
                !chatSearchList.chats.length &&
                !chatSearchList.messages.length
              : dataReceived && !chatList.length
          }
          noResultsLabel={this.getNoResultLabel()}
        />
        {(() => {
          if (chatList) {
            return (
              <>
                {chatSearchList ? (
                  <>
                    {chatSearchList.chats.length > 0 && (
                      <>
                        <span className="font-weight-500">Chats</span>
                        {chatSearchList.chats.map((item) => (
                          <InterlocutorListItem
                            user={item.user}
                            key={item.id}
                          />
                        ))}
                      </>
                    )}
                    {chatSearchList.messages.length > 0 && (
                      <>
                        <span className="font-weight-500">Messages</span>
                        {chatSearchList.messages.map((item) => (
                          <ConversationsListItem
                            conversation={item}
                            type="search"
                            key={item.id}
                          />
                        ))}
                      </>
                    )}
                  </>
                ) : (
                  chatList.map((item) => (
                    <div
                      onClick={() => this.decrementChatsCounter(item.is_read)}
                    >
                      <ConversationsListItem
                        conversation={item}
                        key={item.id}
                      />
                    </div>
                  ))
                )}
                {!this.state.dataReceived && <Loader />}
              </>
            )
          } else {
            return <Loader />
          }
        })()}
      </>
    )
  }
}

const mapStateToProps = ({ auth, message }) => ({ auth, message })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getChatList,
      updateChatList,
      searchChats,
      clearChatList,
      decrementChatsCount,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withScroll(ConversationsList)))
