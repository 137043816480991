import React from "react"
import InfoLayout from "../infoLayout"
import { Li, Section, SectionHeading, Ul } from "./accountsInfoMain"
import { HybridLogisticsFeatures, InfoFooter } from "./accountsInfoEducator"

const AccountsInfoOrganization = () => {
  return (
    <InfoLayout>
      <div className={"flexgrid"}>
        <Section>
          <div className="container">
            <div className="full-width">
              <SectionHeading>School or District</SectionHeading>
              <div className="row">
                <div className="col-md-10 col-md-offset-1">
                  <p className={"mb30 text-center"}>
                    Create an Educator account, then create your Group.
                    Knowstory is the first hybrid logistics platform that upends
                    your master scheduling to help your personalize learning
                    with entirely flexible time and space use. You decide the
                    degree every learner gets a truly individualized path while
                    still intersecting with live teaching in-person or a mix
                    with remote video conferencing.
                  </p>
                  <p className="mb30">
                    Unburden teachers | Manage teacher shortages | Avert
                    attrition through flexibility and true personalization | Get
                    digital learning organized with advanced logistics
                  </p>
                  <Ul>
                    <Li>Build your Master Calendar</Li>
                    <Li>
                      Partition School Calendars with Segments (months);
                      Sections (weeks); Rotations (daily); Blocks and Tracks in
                      unlimited multiples, including down to individual learners
                    </Li>
                    <Li>Create grades and grade groups</Li>
                    <Li>Add your own Subjects</Li>
                    <Li>Find learning resources</Li>
                    <Li>List your Spaces for planning learning traffic</Li>
                    <Li>Assign Schedules to members</Li>
                    <Li>
                      Layer pacing Calendars with Units over your Master by
                      Subject
                    </Li>
                    <Li>
                      Set-up Courses to use Units and preplanned dynamic class
                      meetings
                    </Li>
                    <Li>
                      Create holds for meets outside of Block Schedule (large
                      time intervals which skip breaks, lunches, etc.)
                    </Li>
                    <Li>
                      Create individual learner Plans (Add Units + Standards,
                      select Courses)
                    </Li>
                    <Li>
                      Use Plan Reports to generate list of learner completions
                      within any date range
                    </Li>
                    <Li>Daily use Tracking to see learner progress</Li>
                    <Li>
                      Create official Awarded Creds only from Group Admin or
                      Teacher (Grades/Marks)
                    </Li>
                    <Li>
                      Get your learning resources organized with
                      institution-wide dynamic joined record-keeping with
                      Invenstory
                    </Li>
                  </Ul>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <HybridLogisticsFeatures isDark />
        {/*<HybridLogisticsFlow isDark />*/}
        <InfoFooter isFirstDark={false} />
      </div>
    </InfoLayout>
  )
}

export default AccountsInfoOrganization
