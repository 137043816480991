import { TextLineSkeleton } from "../components/textSkeleton"
import Box from "../../../../ui/box/box"
import { LogoSkeleton } from "../components/LogoSkeleton"
import EntityOperationsSkeleton from "../components/entityOperationsSkeleton"

const KnowmixPageSkeleton = () => {
  return (
    <div className="common-page__content">
      <main className="common-page__main">
        <div className="box">
          <div className="box__heading">
            <TextLineSkeleton height={"18px"} width={"50%"} />
          </div>
          <div className="box__content">
            <TextLineSkeleton width={"60%"} className={"mb15"} />
            <TextLineSkeleton width={"40%"} className={"mb15"} />
            <TextLineSkeleton width={"80%"} className={"mb15"} />
            <TextLineSkeleton width={"50%"} className={"mb15"} />
            <TextLineSkeleton width={"30%"} className={"mb15"} />
            <TextLineSkeleton width={"30%"} className={"mb15"} />
            <TextLineSkeleton width={"50%"} className={"mb15"} />
            <TextLineSkeleton width={"40%"} />
          </div>
        </div>
        <Box>
          <TextLineSkeleton width={"30%"} className={"mb15"} />
          <Box>
            <TextLineSkeleton width={"60%"} />
          </Box>
          <Box>
            <TextLineSkeleton width={"40%"} />
          </Box>
          <Box>
            <TextLineSkeleton width={"50%"} />
          </Box>
        </Box>
      </main>
      <aside className="common-page__sidebar">
        <Box>
          <LogoSkeleton />
        </Box>
        <EntityOperationsSkeleton />
      </aside>
    </div>
  )
}

export default KnowmixPageSkeleton
