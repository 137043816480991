import React, { Component } from "react"
import "./commentsList.scss"
import avatarDefault from "../../../../assets/img/user_avatar.svg"
import Comment from "../comment/comment"
import KeyboardArrowDownOutlined from "@material-ui/icons/KeyboardArrowDownOutlined"
import SendOutlined from "@material-ui/icons/SendOutlined"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import {
  createComment,
  getPostsList,
} from "../../../../library/store/actions/creators/postsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import { getGroupLogo } from "../../../../library/utils/getGroupLogo"
import getErrorText from "../../../../library/constants/errorTypes"
import styled from "@emotion/styled"
import { colors } from "../../../../library/constants/styles/colors"
import { devices } from "../../../../library/constants/styles/responsive"
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener"

const DropdownContainer = styled.div`
  position: relative;
  margin-right: 10px;
`

const DropdownBtn = styled.button`
display: flex;
color: ${colors.black38};
`;

const DropdownContentWrapper = styled.div`
position: absolute;
top: calc(100% + 10px);
left: -10px;
z-index: 1;
`;

const DropdownContent = styled.div`
position: relative;
min-width: 195px;
padding: 15px;
background-color: ${colors.white};
box-shadow: 0 0 2px 0 rgba(210, 210, 210, 0.5);
border-radius: 2px;

&::before {
content: '';
position: absolute;
z-index: -1;
top: -6px;
left: 14px;
width: 15px;
height: 15px;
transform: rotate(45deg);
border-radius: 1px;
background-color: ${colors.white};
box-shadow: 0 0 2px 0 rgba(210, 210, 210, 0.5);

@media ${devices.xs} {
  right: 12px;
}
}
`;

class CommentsList extends Component {
    state = {
        type: this.props.type,
        comments: this.props.comments || [],
        showCommentField: false,
        newCommentText: "",
        personal: true,
        dropdownOpen: false,
    };

    handleCheckboxChange = () => {
        this.setState({personal: !this.state.personal});
    };

    handleChange = event => {
        this.setState({newCommentText: event.target.value});
    };

    showCommentField = () => {
        this.setState({showCommentField: true});
    };

    toggleDropdown = () => {
        this.setState(prevState => ({dropdownOpen: !prevState.dropdownOpen}));
    };

    handleClickOutside = e => {
        if (this.state.dropdownOpen) this.setState({dropdownOpen: false});
    };

    processCreateComment = async () => {
        const {newCommentText: message, type} = this.state;
        if (!message.replace(/\s/g, "")) return;
        const {postId} = this.props;
        this.setState({newCommentText: ""});
        this.state.comments.length + 1 > 3 && this.props.showAllComments();
        try {
            await this.props.createComment({
                post_id: postId,
                message,
                type: ["other-profile", "group", "bookmarks"].includes(type) ? "person" : type === "feed" ? "feed" : "my",
                personal: this.state.personal,
            });
            await this.props.updateData(this.state.type);
            this.props.showAllComments();
        } catch ({error}) {
            this.props.enqueueSnackbar(getErrorText(error.code), {variant: "error"});
        }
    };

    componentDidUpdate(prevProps) {
        if (this.props.comments !== prevProps.comments) {
            this.setState({comments: this.props.comments});
        }
    }

    updateData = async () => {
        this.props.updateData(this.state.type);
    };

    render() {
        const {postId, auth: {userData}, createdByGroup, groupInfo} = this.props;
        const {comments, newCommentText, personal} = this.state;

        const avatar = userData.photo ? userData.photo : avatarDefault;

        return (
            <div className="post-replies">
                {comments.map((comment, index) => (
                    <Comment
                        key={comment.id}
                        comment={comment}
                        postId={postId}
                        addComment={this.showCommentField}
                        updateData={this.updateData}
                        type={this.props.type}
                        index={index}
                        createdByGroup={createdByGroup}
                        groupInfo={groupInfo}
                    />
                ))}
                {this.props.showAll ? (
                    <div className="full-width f jcc mt10">
                        <button className="btn btn--plain" onClick={() => this.props.showAllComments()}>Show all
                            comments <KeyboardArrowDownOutlined/></button>
                    </div>

                ) : null}
                <div className="post-comment-field">
                    {groupInfo && !personal ? <img src={getGroupLogo(groupInfo)} alt={groupInfo.name}
                                                   className="post-comment-field__avatar"/> :
                        <img src={avatar} alt={`${userData.first_name} ${userData.last_name}`}
                             className="post-comment-field__avatar"/>}

                    {createdByGroup && (
                        <ClickAwayListener onClickAway={this.handleClickOutside}>
                            <DropdownContainer>
                                <DropdownBtn type="button" onClick={this.toggleDropdown}>
                                    {this.state.dropdownOpen ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                                </DropdownBtn>
                                {this.state.dropdownOpen && (
                                    <DropdownContentWrapper>
                                        <DropdownContent>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        autoFocus
                                                        checked={!this.state.personal}
                                                        onChange={this.handleCheckboxChange}
                                                        value={"Comment as group"}/>
                                                }
                                                label="Comment as group"
                                            />
                                        </DropdownContent>
                                    </DropdownContentWrapper>
                                )}
                            </DropdownContainer>
                        </ClickAwayListener>
                    )}

                    <ValidatorForm onSubmit={() => {
                    }} style={{width: "100%"}}>
                        <TextValidator
                            name="comment"
                            placeholder="Add your comment…"
                            multiline
                            value={newCommentText}
                            onChange={this.handleChange}
                            fullWidth
                            validators={["maxStringLength:5000"]}
                            errorMessages={["Max length is 5000 characters"]}
                            margin="none"
                            variant="outlined"
                        />
                    </ValidatorForm>
                    <SendOutlined className="post-comment-field__send" onClick={() => this.processCreateComment()}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({auth, person, posts}) => ({auth, person, posts});
const mapDispatchToProps = dispatch => bindActionCreators({createComment, getPostsList}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withSnackbar(CommentsList)));
