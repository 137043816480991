import React from "react"
import InfoTable from "../../../../../components/v2/utils/infoTable"
import ChosenStandardsList from "../../../standards/chosenStandardsList/chosenStandardsList"
import { minutesToString } from "../../../../../library/utils/timeConvert"
import { safeFormatDate } from "../../../../../library/utils/dateConvert"
import { Box } from "../../../../../components/tw/layout"

const UnitInfo = ({ unit }) => {
  return (
    <>
      <Box className="mb-4">
        <div className="box__content">
          <div className="box__subheading">Unit details</div>
          <InfoTable
            data={[
              {
                label: "Name",
                text: unit.name,
              },
              !!unit.description && {
                label: "Description",
                text: unit.description,
              },
              !!unit.standards.length && {
                label: "Standards",
                component: <ChosenStandardsList standards={unit.standards} />,
              },
              !!unit.date_from && {
                label: "Start date",
                text: safeFormatDate(unit.date_from),
              },
              !!unit.date_to && {
                label: "Finish date",
                text: safeFormatDate(unit.date_to),
              },
              !!unit.minutes && {
                label: "Estimated time",
                text: minutesToString(unit.minutes),
              },
            ]}
          />
        </div>
      </Box>
      <hr className="border-divider my-4" />
    </>
  )
}

export default UnitInfo
