import React from "react"
import TrackFormModal from "./components/trackFormModal"
import TrackDialog from "./components/trackDialog"
import SuperListPage from "../../../../../../../../../../../../components/v2/layout/superListPage/superListPage"
import { useIdParam } from "../../../../../../../../../../../../hooks/useIdParam"
import { SEGMENTS } from "../../../../../../../../../../../../library/store/actions/types/groupsTypes"
import TrackCard from "../../../../../../../../../../../../components/v2/layout/cards/trackCard"
import { useTrackMutations } from "../../../../../../../../../../../../hooks/data/calendar/schedules"
import { BoxHeading } from "../../../../../../../../../../../../components/v2/layout/boxPage/boxPage"
import { Button } from "@material-ui/core"
import { useAlert } from "../../../../../../../../../../../../hooks/useAlert"
import {
  CARD_SIZES,
  getCardSize,
} from "../../../../../../../../../../../../library/constants/styles/cardSizes"

const SegmentSectionTracks = () => {
  const { successAlert } = useAlert()

  const section_id = useIdParam("sectionId")
  const queryKey = [SEGMENTS.SECTIONS.TRACKS.LIST, { section_id }]

  const { createTrack, deleteTrack } = useTrackMutations(queryKey)

  const onCreateTrack = async () => {
    await createTrack.mutate({ section_id })
    successAlert("Track created")
  }

  return (
    <SuperListPage
      headerProps={{
        title: "Section Tracks",
        button: { label: "Create Track", action: onCreateTrack },
        search: false,
      }}
      noResultsLabel={"This section doesn't have tracks yet"}
      listItemHeight={getCardSize(4).numValPx}
      queryConfig={{
        key: queryKey,
        fetchParams: {
          section_id,
        },
        listKey: "tracks",
      }}
      getListItemComponent={({
        item,
        openEditDialog,
        openDeletionDialog,
        openInfoDialog,
      }) => (
        <TrackCard
          track={item}
          openDeleteTrack={openDeletionDialog}
          // openEditTrack={openEditDialog}
          // openTrackDetails={openInfoDialog}
          key={item.id}
        />
      )}
      // getFormDialogComponent={({ open, onClose, activeItem }) => {
      //   return (
      //     <TrackFormModal
      //       open={open}
      //       onClose={onClose}
      //       activeTrack={activeItem}
      //     />
      //   )
      // }}
      getDeletionDialogComponent={{
        entityName: "track",
        onDelete: (id) => deleteTrack.mutateAsync({ id }),
      }}
      // getInfoDialogComponent={({ open, onClose, activeItem }) => (
      //   <TrackDialog open={open} onClose={onClose} activeBlock={activeItem} />
      // )}
    />
  )
}

export default SegmentSectionTracks
