import React, { Component } from "react"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { bindActionCreators } from "redux"
import {
  getGroup,
  groupInviteDecline,
  groupRequestsCancel,
  groupRequestsDecline,
  leaveGroup,
} from "../../../../library/store/actions/creators/groupsCreators"
import logoDefault from "../../../../assets/img/group_unavailable.svg"
import bgDefault from "../../../../assets/img/bg-default.png"
import MenuList from "@material-ui/core/MenuList/MenuList"
import DropdownControl from "../../../../components/ui/dropdowns/dropdownControl/dropdownControl"
import { withStyles } from "@material-ui/core"
import { colors } from "../../../../library/constants/styles/colors"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import getErrorText from "../../../../library/constants/errorTypes"
import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"

const CustomMenuItem = withStyles({
  root: {
    color: colors.primary,
  },
})(MenuItem)

class GroupError extends Component {

    state = {
        isMember: this.props.group.is_member,
    };

    leaveGroup = async () => {
        const {id} = this.props.group;

        try {
            await this.props.leaveGroup({id});
            this.setState({isMember: false});
        } catch ({error}) {
            this.props.enqueueSnackbar(getErrorText(error.code), {variant: "error"});
        }
    };

    groupRequestCancel = async (id) => {
        try {
            await this.props.groupRequestsCancel({group_id: id});
            await this.props.getGroup({id});
        } catch ({error}) {
            this.props.enqueueSnackbar(getErrorText(error.code), {variant: "error"});
        }
    };
    groupRequestDecline = async (id) => {
        try {
            await this.props.groupInviteDecline({group_id: id});
            await this.props.getGroup({id});
        } catch ({error}) {
            this.props.enqueueSnackbar(getErrorText(error.code), {variant: "error"});
        }
    };

    render() {
        const {id, name, is_creator, is_admin, deleted, blocked, is_requesting, is_invited} = this.props.group;
        const {isMember} = this.state;

        return (
            <div className="common-page__content">
                <main className="common-page__main">
                    <div className="box group-profile-header-container">
                        <div className="profile-header group-profile-header">
                            <div className="profile-header__top" style={{backgroundImage: `url(${bgDefault})`}}></div>
                            <div className="profile-header__bottom">
                                <div className={`${!is_creator && isMember ? "mb15" : ""} profile-info`}>
                                    <h1 className="title-2 mb8">{name}</h1>
                                    <div className="light">
                                        {deleted && "Group deleted"}
                                        {blocked && "Group blocked"}
                                    </div>
                                </div>
                                {(is_requesting) && (
                                    <DropdownControl buttonLabel="Request sent">
                                        <MenuList>
                                            <CustomMenuItem onClick={() => this.groupRequestCancel(id)}>
                                                Cancel request
                                            </CustomMenuItem>
                                        </MenuList>
                                    </DropdownControl>
                                )}
                                {((is_creator && isMember) || isMember) && (
                                    <DropdownControl buttonLabel="Member">
                                        <MenuList>
                                            <CustomMenuItem onClick={this.leaveGroup}>
                                                Leave the group
                                            </CustomMenuItem>
                                        </MenuList>
                                    </DropdownControl>
                                )}
                                {(is_invited) && (
                                    <DropdownControl buttonLabel="Invited">
                                        <MenuList>
                                            <CustomMenuItem onClick={() => this.groupRequestDecline(id)}>
                                                Decline request
                                            </CustomMenuItem>
                                        </MenuList>
                                    </DropdownControl>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="box p30 text-center">
                        {deleted && <p className="color-black-54">This group has been deleted.</p>}
                        {blocked && <p className="color-black-54">This group has been blocked.</p>}
                        {is_creator || is_admin ? (
                            <p className="color-black-54">You can <Link to={generatePath(routes.CONTACT_FORM)}
                                                                        className="link link--inline">restore your
                                group.</Link></p>
                        ) : (
                            <p className="color-black-54">Information on this group is unavailable.</p>
                        )}
                    </div>
                </main>
                <aside className="common-page__sidebar">
                    <div className="box">
                        <div className="box__content">
                            <div className="group-avatar">
                                <img src={logoDefault} className="group-avatar__img" alt="Logo"/>
                            </div>
                            <h4 className="subhead-2 group-name">{name}</h4>
                        </div>
                    </div>
                </aside>
            </div>
        );
    }
}

const mapStateToProps = ({auth, groups}) => ({auth, groups});
const mapDispatchToProps = dispatch => bindActionCreators({
    leaveGroup,
    groupInviteDecline,
    groupRequestsDecline,
    getGroup,
    groupRequestsCancel,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(GroupError));
