import { call } from "../../../networking/API"
import { MESSAGES } from "../types/messagesTypes"

export const createMessage = ({ chat_id, message, images, videos, blobs }) => {
  const attachments = [...images, ...videos, ...blobs]

  return async (dispatch, getState) => {
    const response = await call(MESSAGES.CREATE, {
      token: getState().auth.userData.token,
      chat_id,
      message,
      attachments: attachments.length > 0 ? attachments : undefined,
    })
    const json = await response.data

    if (response.status === 200) {
      /*return dispatch({
                type: MESSAGES.CREATE,
                payload: {
                    id: json.id,
                    message,
                    attachments: localAttachments,
                    created: new Date().toISOString(),
                    is_mine: true,
                    user: {
                        first_name: getState().auth.userData.first_name,
                        last_name: getState().auth.userData.last_name,
                        photo: getState().auth.userData.photo,
                        id: getState().auth.userData.id
                    }
                }
            })*/
    } else {
      throw json.error
    }
  }
}

export const updateMessage = ({payload}) => {
    return async (dispatch) => {
        return dispatch({
            type: MESSAGES.UPDATE,
            payload,
        });
    };
};

export const getMessageList = ({chat_id, user_id, offset = 0, limit = 50}) => {
    return async (dispatch, getState) => {
        const response = await call(MESSAGES.LIST, {
            token: getState().auth.userData.token,
            chat_id,
            user_id,
            offset,
            limit,
        });
        const json = await response.data;

        if (response.status === 200) {
            dispatch({
                type: MESSAGES.LIST,
                payload: json,
            });

            return json.chat_id;
        } else {
            throw json.error;
        }
    };
};

export const searchChats = ({content, offset = 0, limit = 100, unread = false}) => {
    return async (dispatch, getState) => {
        if (!!content) {
            const response = await call(MESSAGES.SEARCH, {
                token: getState().auth.userData.token,
                content,
                offset,
                limit,
                unread,
            });
            const json = await response.data;

            if (response.status === 200) {
                return dispatch({
                    type: MESSAGES.SEARCH,
                    payload: json,
                });
            } else {
                throw json.error;
            }
        } else {
            return dispatch({
                type: MESSAGES.SEARCH,
                payload: null,
            });
        }
    };
};

export const getChatList = ({unread = false, offset = 0, limit = 100}) => {
    return async (dispatch, getState) => {
        const response = await call(MESSAGES.CHATS.LIST, {
            token: getState().auth.userData.token,
            unread,
            offset,
            limit,
        });
        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: MESSAGES.CHATS.LIST,
                payload: json.chats,
            });
        } else {
            throw json.error;
        }
    };
};

export const clearChatList = () => {
    return async (dispatch) => {
        return dispatch({
            type: MESSAGES.CHATS.CLEAR,
        });
    };
};

export const updateChatList = ({payload}) => {
    return async (dispatch) => {
        return dispatch({
            type: MESSAGES.CHATS.UPDATE,
            payload,
        });
    };
};

export const readMessage = ({chat_id}) => {
    return async (dispatch, getState) => {
        const response = await call(MESSAGES.CHATS.READ, {
            token: getState().auth.userData.token,
            chat_id,
        });
        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: MESSAGES.CHATS.READ,
            });
        } else {
            throw json.error;
        }
    };
};
export const clearMessages = () => {
    return {
        type: MESSAGES.CLEAR,
    };
};
