import React, { Component } from "react"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import Button from "@material-ui/core/Button/Button"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import Select from "@material-ui/core/Select/Select"
import defaultDate, {
  defaultMaxDate,
} from "../../../../../library/constants/defaultDates"
import { DatePicker } from "@material-ui/pickers"
import { bindActionCreators } from "redux"
import {
  getProduct,
  updateProduct,
} from "../../../../../library/store/actions/creators/productsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment"
import { urlValidation } from "../../../../../library/utils/validators"
import getErrorText from "../../../../../library/constants/errorTypes"
import { floor } from "lodash"
import NumberFormat from "react-number-format"
import DeleteForeverOutlined from "@material-ui/icons/DeleteForeverOutlined"
import AddOutlined from "@material-ui/icons/AddOutlined"

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      decimalScale={2}
      fixedDecimalScale
      allowNegative={false}
    />
  )
}

class ProductSettingsMarketSpecifics extends Component {
  state = {
    targetMarket: this.props.products.currentProduct.target_market,
    tagline: this.props.products.currentProduct.tagline,
    link: this.props.products.currentProduct.url,
    version: this.props.products.currentProduct.version,
    dateFrom:
      this.props.products.currentProduct.date_from !== defaultDate
        ? this.props.products.currentProduct.date_from
        : null,
    dateTo:
      this.props.products.currentProduct.date_to !== defaultDate
        ? this.props.products.currentProduct.date_to
        : null,
    price: this.props.products.currentProduct.price || "",
    pricingLevels: this.props.products.currentProduct.pricing_levels || [
      {
        description: "",
        price: "",
      },
    ],
  }

  componentDidMount() {
    ValidatorForm.addValidationRule("isLink", (value) => urlValidation(value))
  }

  handleChange = (name) => (event) => {
    if (name === "price") {
      return this.setState({
        [name]: floor(parseFloat(event.target.value), 1),
      })
    }

    this.setState({
      [name]: event.target.value,
    })
  }

  handleDateChange = (date, name) => {
    const parsedDate = date ? new Date(date).toISOString() : null
    this.setState({ [name]: parsedDate })
  }

  addPriceLevel = () => {
    this.setState((prevState) => ({
      pricingLevels: [
        ...prevState.pricingLevels,
        {
          description: "",
          price: "",
        },
      ],
    }))
  }

  removePriceLevel = (index) => {
    this.setState((prevState) => ({
      pricingLevels: prevState.pricingLevels.filter((item, i) => index !== i),
    }))
  }

  handlePricingFieldChange = (index, name, value) => {
    console.log({ index, name, value })
    this.setState((prevState) => {
      const pricingLevels = prevState.pricingLevels.map((item, i) => {
        if (i !== index) return item

        if (name === "levelPrice") {
          return {
            ...item,
            price: floor(parseFloat(value), 1),
          }
        }

        return {
          ...item,
          [name]: value,
        }
      })

      return {
        pricingLevels,
      }
    })
  }

  processInfoSave = async () => {
    const {
      products: { currentProduct },
    } = this.props
    const {
      targetMarket,
      tagline,
      price,
      pricingLevels,
      dateTo,
      dateFrom,
      version,
      link,
    } = this.state

    console.log(price, pricingLevels)

    try {
      await this.props.updateProduct({
        id: currentProduct.id,
        target_market: targetMarket,
        tagline,
        price: parseFloat(price) || 0,
        pricing_levels: pricingLevels,
        date_from: dateFrom ? dateFrom : defaultDate,
        date_to: dateTo ? dateTo : defaultDate,
        version,
        url: link,
      })
      await this.props.enqueueSnackbar("Information updated", {
        variant: "success",
      })
      await this.props.getProduct({ id: currentProduct.id })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  render() {
    const {
      targetMarket,
      tagline,
      link,
      version,
      dateFrom,
      dateTo,
      pricePerSchool,
      price,
      otherPrice,
      pricingLevels,
    } = this.state

    return (
      <div className="box">
        <div className="box__heading">Market specifics</div>
        <div className="box__content">
          <ValidatorForm className="form" onSubmit={this.processInfoSave}>
            <div className="form__fieldset">
              <label htmlFor="target-market" className="form__label">
                Target market
              </label>
              <div className="form__fieldbox">
                <div className="form__input form__input--select">
                  <Select
                    id="target-market"
                    name="targetMarket"
                    fullWidth
                    displayEmpty
                    value={targetMarket}
                    onChange={this.handleChange("targetMarket")}
                    renderValue={(value) => {
                      if (value.length === 0) {
                        return (
                          <span className="color-black-38">
                            Select target market
                          </span>
                        )
                      }

                      return value
                    }}
                  >
                    <MenuItem value="Consumers">Consumers</MenuItem>
                    <MenuItem value="Institutions">Institutions</MenuItem>
                  </Select>
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor="tagline" className="form__label">
                Product tagline
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <TextValidator
                    id="tagline"
                    name="tagline"
                    value={tagline}
                    placeholder="Type product tagline"
                    className="edit-form__input"
                    margin="normal"
                    fullWidth
                    validators={["maxStringLength:300"]}
                    errorMessages={["Max length is 300 characters"]}
                    onChange={this.handleChange("tagline")}
                  />
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor="link" className="form__label">
                Link to product
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <TextValidator
                    id="link"
                    name="link"
                    value={link}
                    placeholder="Type product link"
                    className="edit-form__input"
                    margin="normal"
                    fullWidth
                    validators={["isLink", "maxStringLength:250"]}
                    errorMessages={[
                      "Invalid URL",
                      "Max length is 250 characters",
                    ]}
                    onChange={this.handleChange("link")}
                  />
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor="version" className="form__label">
                Version
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <TextValidator
                    id="version"
                    name="version"
                    value={version}
                    placeholder="Type version"
                    className="edit-form__input"
                    margin="normal"
                    fullWidth
                    validators={["maxStringLength:50"]}
                    errorMessages={["Max length is 50 characters"]}
                    onChange={this.handleChange("version")}
                  />
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor="concepts-number" className="form__label">
                Date
              </label>
              <div className="form__fieldbox">
                <div className="form__input-group">
                  <div className="form__input">
                    <DatePicker
                      id="start-date"
                      value={dateFrom !== defaultDate ? dateFrom : null}
                      emptyLabel="From"
                      fullWidth
                      format="d MMMM yyyy"
                      margin="normal"
                      clearable={true}
                      invalidLabel="From"
                      maxDate={
                        dateTo ? new Date(dateTo) : new Date(2100, 11, 31)
                      }
                      onChange={(date) =>
                        this.handleDateChange(date, "dateFrom")
                      }
                    />
                  </div>
                  <div className="form__input">
                    <DatePicker
                      id="start-date"
                      value={dateTo !== defaultMaxDate ? dateTo : null}
                      emptyLabel="To"
                      fullWidth
                      format="d MMMM yyyy"
                      margin="normal"
                      clearable={true}
                      invalidLabel="To"
                      minDate={
                        dateFrom ? new Date(dateFrom) : new Date(1910, 1, 1)
                      }
                      onChange={(date) => this.handleDateChange(date, "dateTo")}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor="minimal-price" className="form__label">
                Minimal price
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <TextValidator
                    id="minimal-price"
                    name="price"
                    value={String(price)}
                    placeholder="Type minimal price"
                    className="edit-form__input"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      inputComponent: NumberFormatCustom,
                    }}
                    validators={["isFloat", "minNumber:0"]}
                    errorMessages={[
                      "Incorrect price format",
                      "Incorrect price format",
                    ]}
                    onChange={this.handleChange("price")}
                  />
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor="other-price" className="form__label">
                Pricing levels
              </label>
              <div className="form__fieldbox">
                <div className="form__input-group">
                  {pricingLevels.map((item, index) => (
                    <div className="form__controllable-input" key={index}>
                      <div>
                        <div className="form__input mb15">
                          <TextValidator
                            name={`level-${index + "-description"}`}
                            value={item.description}
                            placeholder={"Type pricing level description"}
                            className="edit-form__input"
                            margin="normal"
                            fullWidth
                            validators={["maxStringLength:100"]}
                            errorMessages={["Max length is 100 characters"]}
                            onChange={(event) =>
                              this.handlePricingFieldChange(
                                index,
                                "description",
                                event.target.value
                              )
                            }
                            multiline
                          />
                        </div>
                        <div className="form__input mb15">
                          <TextValidator
                            name={`level-${
                              index + "-price-" + String(new Date())
                            }`}
                            value={item.price}
                            placeholder={"Type price"}
                            className="edit-form__input"
                            margin="normal"
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                              inputComponent: NumberFormatCustom,
                            }}
                            validators={["isFloat", "minNumber:0"]}
                            errorMessages={[
                              "Incorrect price format",
                              "Incorrect price format",
                            ]}
                            onChange={(event) =>
                              this.handlePricingFieldChange(
                                index,
                                "levelPrice",
                                event.target.value
                              )
                            }
                          />
                        </div>
                      </div>

                      <button
                        className="form__manage-input"
                        style={{ alignSelf: "flex-start", marginTop: 6 }}
                        type="button"
                        onClick={() => this.removePriceLevel(index)}
                      >
                        <DeleteForeverOutlined /> Delete
                      </button>
                    </div>
                  ))}
                </div>
                {pricingLevels.length < 5 && (
                  <button
                    className="form__add-input"
                    type="button"
                    onClick={this.addPriceLevel}
                  >
                    <AddOutlined /> Add pricing level
                  </button>
                )}
              </div>
            </div>

            {/*<div className="form__fieldset">*/}
            {/*    <label htmlFor="other-price" className="form__label">*/}
            {/*        Other price*/}
            {/*    </label>*/}
            {/*    <div className="form__fieldbox">*/}
            {/*        <div className="form__input">*/}
            {/*            <TextValidator*/}
            {/*                id="other-price"*/}
            {/*                name="otherPrice"*/}
            {/*                value={String(otherPrice)}*/}
            {/*                placeholder="Type other price"*/}
            {/*                className="edit-form__input"*/}
            {/*                margin="normal"*/}
            {/*                fullWidth*/}
            {/*                InputProps={{*/}
            {/*                    startAdornment: (*/}
            {/*                        <InputAdornment position="start">*/}
            {/*                            $*/}
            {/*                        </InputAdornment>*/}
            {/*                    ),*/}
            {/*                    inputComponent: NumberFormatCustom,*/}
            {/*                }}*/}
            {/*                validators={["isFloat", "minNumber:0"]}*/}
            {/*                errorMessages={["Incorrect price format", "Incorrect price format"]}*/}
            {/*                onChange={this.handleChange("otherPrice")}*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <Button
              color="primary"
              variant="contained"
              className="form__submit"
              type="submit"
            >
              Save
            </Button>
          </ValidatorForm>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, products }) => ({ auth, products })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getProduct, updateProduct }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ProductSettingsMarketSpecifics))
