import React, { useEffect, useState } from "react"
import getErrorText from "../../../../../../library/constants/errorTypes"
import Dialog from "@material-ui/core/Dialog/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import Loader from "../../../../../../components/ui/loader"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import { bindActionCreators } from "redux"
import { connect, useSelector } from "react-redux"
import { withSnackbar } from "notistack"
import { withRouter } from "react-router-dom"
import { useCalendar } from "../../../CalendarContext"
import {
  clearCurrentCalendarEvent,
  deleteCalendarEvent,
  getCalendarEvent,
} from "../../../../../../library/store/actions/creators/calendarCreators"
import styled from "@emotion/styled"
import returnExternalUrl from "../../../../../../library/utils/returnExternalUrl"
import InsertDriveFile from "@material-ui/icons/InsertDriveFile"
import { formatBytes } from "../../../../../../library/utils/fileSizeConverter"
import { safeGetEntity } from "../../../../../../library/utils/entities"
import { minutesToString } from "../../../../../../library/utils/timeConvert"
import UserLink from "../../../../../../components/v2/utils/UserLink"
import { safeFormatDate } from "../../../../../../library/utils/dateConvert"

const ColorIndicator = styled.div`
  width: 18px;
  height: 18px;
  background-color: ${(props) => props.color || "transparent"};
  border-radius: 2px;
`

const EventDialog = (props) => {
  const {
    calendar: { currentEvent },
  } = props
  const { onEventFormModalOpen } = useCalendar()

  const { userData } = useSelector(({ auth }) => auth)

  const [dataReceived, setDataReceived] = useState(false)
  useEffect(() => {
    const getEvent = async () => {
      try {
        switch (props.activeEventType) {
          case "appointments":
            await props.getCalendarEvent({
              id: props.activeEvent,
              entityType: props.activeEventType,
            })
            break

          case "book_appointments":
            await props.getCalendarEvent({
              id: props.activeEvent,
              entityType: props.activeEventType,
            })
            break

          case "events":
            await props.getCalendarEvent({
              id: props.activeEvent,
              entityType: props.activeEventType,
            })
            break

          case "public_meetings":
            await props.getCalendarEvent({
              id: props.activeEvent,
              entityType: props.activeEventType,
            })
            break

          default:
            await props.getCalendarEvent({
              id: props.activeEvent,
              entityType: props.activeEventType,
            })
            break
        }

        setDataReceived(true)
      } catch ({ error }) {
        props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
      }
    }

    getEvent()

    return props.clearCurrentCalendarEvent
  }, [])

  const onEdit = () => {
    onEventFormModalOpen()
  }

  const onDeleteEvent = async () => {
    try {
      await props.deleteCalendarEvent({ id: props.activeEvent })
      props.onClose()
    } catch ({ error }) {
      props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  const isTeacher =
    !!safeGetEntity(currentEvent?.teacher) &&
    currentEvent?.teacher?.id === userData.id

  const isCreator =
    currentEvent?.b_creator ||
    (!!safeGetEntity(currentEvent?.creator) &&
      currentEvent?.creator?.id === userData.id)

  const getEntityName = () => {
    switch (props.activeEventType) {
      case "book_appointments":
        return "Appointment booking"

      case "public_meetings":
        return "Meeting"

      case "course_meetings":
        return "Course meeting"

      default:
        return "Appointment"
    }
  }

  return (
    <Dialog
      aria-labelledby="event-dialog"
      onClose={props.onClose}
      open={props.open}
    >
      <DialogTitle id="alert-dialog-title" className="text-center">
        {getEntityName()} details
      </DialogTitle>
      <DialogContent>
        {dataReceived ? (
          <div className="f jcc full-width">
            <table className="product-details product-details--invenstory-record">
              <tbody>
                <tr className="product-details__row">
                  <td className="product-details__label">Name</td>
                  <td className="product-details__value">
                    {currentEvent.name}
                  </td>
                </tr>

                {!!currentEvent?.creator?.id && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Creator</td>
                    <td className="product-details__value">
                      <UserLink user={currentEvent.creator} />
                    </td>
                  </tr>
                )}

                {!!currentEvent.description && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Description</td>
                    <td className="product-details__value">
                      {currentEvent.description}
                    </td>
                  </tr>
                )}

                {isTeacher && !!currentEvent.reminder && (
                  <tr className="product-details__row">
                    <td className="product-details__label">
                      KnowMeet reminder
                    </td>
                    <td className="product-details__value">
                      {currentEvent.reminder}
                    </td>
                  </tr>
                )}

                {!isTeacher && !!currentEvent.instructions && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Instructions</td>
                    <td className="product-details__value">
                      {currentEvent.instructions}
                    </td>
                  </tr>
                )}

                {!currentEvent.is_all_day ? (
                  <>
                    {!!currentEvent.date_from && (
                      <tr className="product-details__row">
                        <td className="product-details__label">Start</td>
                        <td className="product-details__value">
                          {safeFormatDate(new Date(currentEvent.date_from), {
                            withTime: true,
                          })}
                        </td>
                      </tr>
                    )}

                    {!!currentEvent.date_to && (
                      <tr className="product-details__row">
                        <td className="product-details__label">Finish</td>
                        <td className="product-details__value">
                          {safeFormatDate(new Date(currentEvent.date_to), {
                            withTime: true,
                          })}
                        </td>
                      </tr>
                    )}
                  </>
                ) : (
                  <>
                    {!!currentEvent.date_from && (
                      <tr className="product-details__row">
                        <td className="product-details__label">Date</td>
                        <td className="product-details__value">
                          {safeFormatDate(new Date(currentEvent.date_from))}
                        </td>
                      </tr>
                    )}
                  </>
                )}

                {!!currentEvent?.meet_length && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Meet length</td>
                    <td className="product-details__value">
                      {minutesToString(currentEvent.meet_length)}
                    </td>
                  </tr>
                )}

                {!!safeGetEntity(currentEvent?.space) && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Space</td>
                    <td className="product-details__value">
                      {currentEvent.space.name}
                    </td>
                  </tr>
                )}

                {!!currentEvent.links.length && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Links</td>
                    <td className="product-details__value">
                      <ul className="text-list">
                        {currentEvent.links.map((link, index) => (
                          <li key={link + index}>
                            <a
                              href={`${returnExternalUrl(link)}`}
                              className="link"
                              target={"_blank"}
                            >
                              {link}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                )}

                {!!currentEvent.blobs.length && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Attachments</td>
                    <td className="product-details__value">
                      <div className="post__files">
                        {currentEvent.blobs.map(({ id, name, size, url }) => {
                          return (
                            <a
                              href={url}
                              target="_blank"
                              className="post__file"
                              key={id}
                            >
                              <InsertDriveFile
                                className="post__file-icon"
                                style={{ fontSize: "30px" }}
                              />
                              <div className="post__file-info">
                                <span className="link link--inline">
                                  {name}
                                </span>
                                <span className="post__file-size">
                                  {formatBytes(size)}
                                </span>
                              </div>
                            </a>
                          )
                        })}
                      </div>
                    </td>
                  </tr>
                )}

                {!!safeGetEntity(currentEvent?.teacher) && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Teacher</td>
                    <td className="product-details__value">
                      <UserLink user={currentEvent.teacher} />
                    </td>
                  </tr>
                )}

                {!!currentEvent.participants.length && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Participants</td>
                    <td className="product-details__value">
                      <div>
                        {currentEvent.participants.map((user) => (
                          <div className="mb5" key={user.id}>
                            <UserLink user={user} />
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                )}

                {!!currentEvent.color && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Color</td>
                    <td className="product-details__value">
                      <ColorIndicator color={currentEvent.color} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <Loader />
        )}
      </DialogContent>
      <DialogActions>
        <div className="full-width f jcsb">
          <div className="f">
            {isCreator && (
              <>
                <Button color="primary" onClick={onEventFormModalOpen}>
                  Edit appointment
                </Button>
                {/*<Button color="primary" onClick={onDeleteEvent}>*/}
                {/*  Delete appointment*/}
                {/*</Button>*/}
              </>
            )}
          </div>
          <Button color="primary" onClick={props.onClose}>
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = ({ auth, groups, calendar }) => ({
  auth,
  groups,
  calendar,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCalendarEvent,
      deleteCalendarEvent,
      clearCurrentCalendarEvent,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(EventDialog)))
