import React from "react"
import BoxPage from "../../../../../components/v2/layout/boxPage/boxPage"
import { Submit } from "../../../../../components/v2/layout/forms/form/form"
import { useDispatch } from "react-redux"
import { useAlert } from "../../../../../hooks/useAlert"
import {
  getAppointmentTypes,
  updateAppointmentTypes,
} from "../../../../../library/store/actions/creators/authCreators"
import Checkbox from "@material-ui/core/Checkbox/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import Loader from "../../../../../components/ui/loader"

const ProfileSettingsAppointmentTypes = () => {
  const dispatch = useDispatch()
  const { successAlert, errorAlert } = useAlert()

  const [dataReceived, setDataReceived] = React.useState(false)

  const [values, setValues] = React.useState([])

  React.useEffect(() => {
    const getTypes = async () => {
      const types = await dispatch(getAppointmentTypes({}))

      if (types.length) {
        setValues(types)
      }

      setDataReceived(true)
    }

    getTypes()
  }, [])

  const onSubmit = async () => {
    try {
      await dispatch(updateAppointmentTypes({ types: values }))
      successAlert()
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <BoxPage title={"Appointment types"}>
      {dataReceived ? (
        <>
          {[5, 15, 30, 45, 60].map((t) => (
            <div key={t}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.includes(t)}
                    onChange={(e) => {
                      setValues((prevState) => {
                        if (e.target.checked) {
                          return [...prevState, t]
                        } else {
                          return prevState.filter((v) => v !== t)
                        }
                      })
                    }}
                  />
                }
                label={`${t}-Minute Meeting`}
              />
            </div>
          ))}
          <div className="full-width f jcc mt20">
            <Submit onClick={onSubmit} color={"primary"} variant={"contained"}>
              Save
            </Submit>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </BoxPage>
  )
}

export default ProfileSettingsAppointmentTypes
