import React from "react"
import withScroll from "../../../../hocs/withScroll/withScroll"
import InvenstoryTile from "../invenstoryTile"
import Loader from "../../../../components/ui/loader"

class InvenstoryTilesList extends React.Component {
  onScroll = async () => {
    try {
      await this.props.onScroll()
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    const { data, dataReceived, initialRender, noResultsLabel } = this.props
    return (
      <main className="common-page__main">
        {(!data || !data.length) && !initialRender && dataReceived && (
          <div className="aic color-black-54 f jcc pt15">{noResultsLabel}</div>
        )}

        <div className="records-list">
          {data &&
            data.map((record) => (
              <InvenstoryTile
                key={record.id}
                record={record}
                createBookmark={this.props.createBookmark}
                deleteBookmark={this.props.deleteBookmark}
                openRecord={this.props.openRecord}
                deleteRecord={this.props.deleteRecord}
                createProduct={this.props.createProduct}
                editRecord={this.props.editRecord}
              />
            ))}
        </div>
        {!dataReceived && <Loader />}
      </main>
    )
  }
};

export default withScroll(InvenstoryTilesList);
