import React from "react"
import Button from "@material-ui/core/Button/Button"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import { useBookMeet } from "./bookMeetContext"
import BookingHoldsAndTypePicker from "./component/BookingHoldsAndTypePicker"

const BookMeetStep2 = () => {
  const {
    data,
    setData,
    setStep,
    availableHolds,
    availableTypes,
    onHoldChange,
    onClose,
    user,
  } = useBookMeet()

  const toNextStep = () => setStep(3)

  const canGoNext = data.holds && data.type

  return (
    <>
      <DialogContent className={"pb15"}>
        <div className="f jcc mb50">
          <p className="text-center" style={{ maxWidth: 340 }}>
            Please select by Hold and by appointment length in minutes. A Hold
            is a bracketed open time that can be scheduled with this user and
            will usually be labeled for selection appropriate to the requester.
            Not selecting a Hold will use any open time and the user can elect
            to accept or offer a different time.
          </p>
        </div>
        <BookingHoldsAndTypePicker
          user={user}
          selectedHolds={data.holds}
          onHoldsChange={onHoldChange}
          selectedType={data.type}
          types={availableTypes}
          onTypeChange={(v) =>
            setData((prevState) => ({
              ...prevState,
              type: v,
              time: null,
              date: null,
            }))
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button color="primary" onClick={toNextStep}>
          Next
        </Button>
      </DialogActions>
    </>
  )
}

export default BookMeetStep2
