import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"
import { call } from "../../../library/networking/API"
import { mutateInfinite } from "../../../library/utils/reactQuery"
import { LIMIT } from "../../../library/constants/limits"
import { POSTS } from "../../../library/store/actions/types/postsTypes"

export const useCommentsList = ({
  postId,
  commentId,
  queryKey,
  options = {},
}) => {
  const queryClient = useQueryClient()
  const query = useInfiniteQuery({
    staleTime: 30000,
    queryKey: [
      POSTS.COMMENTS.LIST,
      { postId },
      !!commentId && { commentId },
    ].filter(Boolean),
    queryFn: async (ctx) => {
      const response = await call(ctx.queryKey[0], {
        offset: ctx.pageParam || 0,
        post_id: !commentId ? postId : undefined,
        comment_id: commentId || undefined,
        limit: 30,
        replies_limit: 3,
      })

      return response.data?.comments || []
    },
    getNextPageParam: (prevPage, allPages) => {
      if (prevPage.length === LIMIT) return allPages.length * LIMIT
    },
    onSuccess: (data) => {
      const postCommentsUpdater = (item) =>
        item.id === postId
          ? {
              ...item,
              comments: data.pages.flat(),
            }
          : item

      const commentRepliesUpdater = (item) =>
        item.id === postId
          ? {
              ...item,
              comments: item.comments.map((c) =>
                c.id === commentId ? { ...c, replies: data.pages.flat() } : c
              ),
            }
          : item

      const updatedPages = queryClient
        .getQueryData(queryKey)
        .pages.map((page) =>
          !commentId
            ? page.map(postCommentsUpdater)
            : page.map(commentRepliesUpdater)
        )

      queryClient.setQueryData(queryKey, (prevData) => ({
        pages: updatedPages,
        pageParams: prevData.pageParams,
      }))
    },
    refetchOnWindowFocus: false,
    ...options,
  })

  return {
    ...query,
  }
}

export const useCommentsMutations = ({
  queryKey,
  isMutatingInfinite = true,
  options = {},
} = {}) => {
  const queryClient = useQueryClient()

  // const listQuery = useCommentsList()

  const createComment = useMutation({
    mutationFn: async ({ post_id, comment_id, message, personal }) =>
      await call(POSTS.COMMENTS.CREATE, {
        post_id: !comment_id && !!post_id ? post_id : undefined,
        comment_id,
        message,
        personal,
      }),
  })

  const updateComment = useMutation({
    mutationFn: async ({ id, message }) =>
      await call(POSTS.COMMENTS.UPDATE, {
        id,
        message,
      }),
    onSuccess: async (_, { id, post_id, comment_id, message }) => {
      await mutateInfinite({
        queryClient,
        key: queryKey,
        itemUpdater: (post) =>
          post_id === post.id
            ? {
                ...post,
                comments: post.comments.map((c) =>
                  c.id === (!comment_id ? id : comment_id)
                    ? {
                        ...c,
                        message: !comment_id ? message : c.message,
                        replies: !comment_id
                          ? c.replies
                          : c.replies.map((r) =>
                              r.id === id ? { ...r, message } : r
                            ),
                      }
                    : c
                ),
              }
            : post,
      })
    },
  })

  const deleteComment = useMutation({
    mutationFn: async ({ id }) => await call(POSTS.COMMENTS.DELETE, { id }),
  })

  const restoreComment = useMutation({
    mutationFn: async ({ id }) => await call(POSTS.COMMENTS.RESTORE, { id }),
  })

  return {
    createComment,
    updateComment,
    deleteComment,
    restoreComment,
  }
}
