import React from "react"
import { useTimezone } from "../../hooks/useTimezone"

const TimezoneLabel = ({ tz = "GMT", className = "", as = "span" }) => {
  const ContainerElement = as

  const { getTzName } = useTimezone()

  return (
    <ContainerElement className={className}>{getTzName(tz)}</ContainerElement>
  )
}

export default TimezoneLabel
