import React from "react"
import "./userDropdownMenu.scss"
import * as routes from "../../../../library/constants/routes"
import { generatePath, useHistory } from "react-router-dom"
import UserAvatar from "../../../userAvatar/userAvatar"
import { useSelector } from "react-redux"
import useCreator from "../../../../hooks/useCreator"
import { signOut } from "../../../../library/store/actions/creators/authCreators"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import { useQueryClient } from "@tanstack/react-query"

const UserDropdownMenu = () => {
  const history = useHistory()
  const creator = useCreator()
  const queryClient = useQueryClient()
  const { userData } = useSelector(({ auth }) => auth)

  const [anchorEl, setAnchorEl] = React.useState(null)

  const onOpen = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const onClose = () => {
    setAnchorEl(null)
  }

  const onSignOut = async () => {
    await creator(signOut())
    queryClient.removeQueries()
  }

  const menuItems = [
    {
      action: () => {
        history.push(
          generatePath(routes.USER, {
            userId: userData?.id,
          })
        )
        onClose()
      },
      label: "My page",
    },
    // { type: "h-divider", id: "hd1" },
    !userData.blocked &&
      !userData.deleted && {
        action: () => {
          history.push(routes.PROFILE_EDIT)
          onClose()
        },
        label: "Edit my profile",
      },
    !userData.blocked &&
      !userData.deleted && {
        action: () => {
          history.push(routes.PROFILE_SETTINGS)
          onClose()
        },
        label: "Settings",
      },
    { action: onSignOut, label: "Log Out" },
  ]

  return (
    <div className={"relative"}>
      <button className="flex items-center outline-0 relative" onClick={onOpen}>
        <span className="mr-2 font-medium hidden sm:block text-primary">
          {userData?.first_name ?? ""}
        </span>
        <UserAvatar user={userData} className={"h-10 w-10"} />
      </button>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {menuItems.map(({ action, label }) => (
          <MenuItem onClick={action} key={label}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default UserDropdownMenu
