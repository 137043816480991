import React, { Component } from "react"
import * as qs from "query-string"
import * as routes from "../../../../../library/constants/routes"
import getErrorText from "../../../../../library/constants/errorTypes"
import AllBookmarksList from "../../bookmarksList/allBookmarksList/allBookmarksList"
import PostsList from "../../../feed/postsList/postsList"
import List from "../../../../../components/ui/list/list"
import BookmarkedUser from "../../bookmarksList/bookmarkedUser/bookmarkedUser"
import BookmarkedGroup from "../../bookmarksList/bookmarkedGroup/bookmarkedGroup"
import BookmarkedProduct from "../../bookmarksList/bookmarkedProduct/bookmarkedProduct"
import GridList from "../../../../../components/ui/grid"
import BookmarkedKnowcred from "../../bookmarksList/bookmarkedKnowcred/bookmarkedKnowcred"
import BookmarkedStandard from "../../bookmarksList/bookmarkedStandard/bookmarkedStandard"
import { LIMIT } from "../../../../../library/constants/limits"
import ListSearchHeader from "../../../../../components/ui/listSearchHeader/listSearchHeader"
import Loader from "../../../../../components/ui/loader/loader"
import { bindActionCreators } from "redux"
import {
  bindFolder,
  bookmarkFoldersList,
  bookmarksFoldersListClear,
  bookmarksListClear,
  deleteBookmark,
  getBookmarksList,
} from "../../../../../library/store/actions/creators/bookmarksCreators"
import {
  groupsSearch,
  productsSearch,
  usersSearch,
} from "../../../../../library/store/actions/creators/searchCreators"
import { getInvenstoryList } from "../../../../../library/store/actions/creators/invenstoryCreators"
import { knowCredsList } from "../../../../../library/store/actions/creators/knowCredsCreators"
import { searchStandards } from "../../../../../library/store/actions/creators/standardsCreators"
import { getPostsList } from "../../../../../library/store/actions/creators/postsCreators"
import { connect } from "react-redux"
import withScroll from "../../../../../hocs/withScroll/withScroll"
import { withSnackbar } from "notistack"
import { omit } from "lodash"
import { Redirect } from "react-router-dom"
import BookmarkedRecord from "../../bookmarksList/bookmarkedRecord/bookmarkedRecord"

const redirectErrors = [20004, 22000, 22001, 22002]

class Folder extends Component {
  state = {
    globalList: [],
    list: [],
    dataReceived: false,
    initialRender: true,
    redirect: false,
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }

  async componentDidMount() {
    await this.getList()
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.match.path !== prevProps.match.path) {
      this.props.bookmarksListClear()
      this.setState({ dataReceived: false, initialRender: true })
      await this.getList()
      this.setState({ initialRender: false })
    } else if (
      this.props.match.params.folderId !== prevProps.match.params.folderId
    ) {
      this.props.bookmarksListClear()
      this.setState({ dataReceived: false, initialRender: true })
      await this.getList()
      this.setState({ initialRender: false })
    } else if (this.props.location.search !== prevProps.location.search) {
      this.setState({ dataReceived: false, initialRender: true })
      await this.getList()
      this.setState({ initialRender: false })
    }
  }

  componentWillUnmount() {
    this.props.bookmarksListClear()
  }

  getList = async () => {
    const {
      match: { path, params },
      location: { search },
    } = this.props
    const { name } = qs.parse(search)

    try {
      switch (path) {
        case routes.FOLDER:
          await this.props.setFoldersListReady(false)
          await this.props.getBookmarksList({
            folder_id: parseInt(params.folderId),
          })
          await this.props.setFoldersListReady(true)
          this.setState({
            globalList: omit(this.props.bookmarks.currentFolder, ["folder"]),
            dataReceived: true,
          })
          break

        case routes.FOLDER_PEOPLE:
          await this.props.getBookmarksList({
            folder_id: parseInt(params.folderId),
          })
          await this.props.setFoldersListReady(true)
          await this.props.usersSearch({
            name: name || undefined,
            bookmarked: true,
            folder_id: parseInt(params.folderId),
          })
          this.setState({
            list: this.props.bookmarks.list.users,
            dataReceived: true,
          })
          break

        case routes.FOLDER_GROUPS:
          await this.props.getBookmarksList({
            folder_id: parseInt(params.folderId),
          })
          await this.props.setFoldersListReady(true)
          await this.props.groupsSearch({
            name: name || undefined,
            bookmarked: true,
            folder_id: parseInt(params.folderId),
          })
          this.setState({
            list: this.props.bookmarks.list.groups,
            dataReceived: true,
          })
          break

        case routes.FOLDER_PRODUCTS:
          await this.props.getBookmarksList({
            folder_id: parseInt(params.folderId),
          })
          await this.props.setFoldersListReady(true)
          await this.props.productsSearch({
            name: name || undefined,
            bookmarked: true,
            folder_id: parseInt(params.folderId),
          })
          this.setState({
            list: this.props.bookmarks.list.products,
            dataReceived: true,
          })
          break

        case routes.FOLDER_RECORDS:
          await this.props.getBookmarksList({
            folder_id: parseInt(params.folderId),
          })
          await this.props.setFoldersListReady(true)
          await this.props.getInvenstoryList({
            name: name || undefined,
            bookmarked: true,
            folder_id: parseInt(params.folderId),
          })
          this.setState({
            list: this.props.bookmarks.list.records,
            dataReceived: true,
          })
          break

        case routes.FOLDER_KNOWCRED:
          await this.props.getBookmarksList({
            folder_id: parseInt(params.folderId),
          })
          await this.props.setFoldersListReady(true)
          await this.props.knowCredsList({
            user_id: this.props.auth.userData.id,
            title: name || undefined,
            bookmarked: true,
            folder_id: parseInt(params.folderId),
          })
          this.setState({
            list: this.props.bookmarks.list.knowcreds,
            dataReceived: true,
          })
          break

        case routes.FOLDER_STANDARDS:
          await this.props.getBookmarksList({
            folder_id: parseInt(params.folderId),
          })
          await this.props.setFoldersListReady(true)
          await this.props.searchStandards({
            name: name || undefined,
            bookmarked: true,
            folder_id: parseInt(params.folderId),
          })
          this.setState({
            list: this.props.bookmarks.list.standards,
            dataReceived: true,
          })
          break

        case routes.FOLDER_POSTS:
          await this.props.getBookmarksList({
            folder_id: parseInt(params.folderId),
          })
          await this.props.setFoldersListReady(true)
          this.setState({ dataReceived: true })
          break

        default:
          return
      }
    } catch ({ error }) {
      if (redirectErrors.includes(error.code)) {
        this.setState({ redirect: true })
      } else {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    }
  }

  deleteBookmark = async ({
    user_id,
    group_id,
    product_id,
    post_id,
    knowcred_id,
    standard_id,
    record_id,
  }) => {
    try {
      await this.props.deleteBookmark({
        user_id,
        group_id,
        product_id,
        post_id,
        knowcred_id,
        record_id,
        standard_id,
      })
      this.props.enqueueSnackbar("Bookmark removed", { variant: "success" })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  bookmarkFoldersList = async (id) => {
    try {
      await this.props.bookmarkFoldersList(id)
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  addToFolder = async ({ bookmark_id, folders }) => {
    try {
      await this.props.bindFolder({ bookmark_id, folders })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  composeSearchQuery = (name) => {
    const { history } = this.props

    history.push({
      search: `${name ? "?name=" + name : ""}`,
    })
  }

  composeList = () => {
    const {
      match: { path },
    } = this.props
    const { globalList, list } = this.state
    switch (path) {
      case routes.FOLDER:
        return (
          <>
            <AllBookmarksList list={globalList} type="folder" />
            <PostsList
              type="bookmarks"
              subtype="folder"
              hideHeader
              userInfo={this.props.auth.userData}
            />
          </>
        )
      case routes.FOLDER_PEOPLE:
        return (
          <List>
            {list.map((item) => (
              <BookmarkedUser
                user={item}
                deleteBookmark={({ user_id }) =>
                  this.deleteBookmark({ user_id })
                }
                bookmarkFoldersList={(id) => this.bookmarkFoldersList(id)}
                addToFolder={({ bookmark_id, folders }) =>
                  this.addToFolder({ bookmark_id, folders })
                }
                bookmarksFoldersListClear={this.props.bookmarksFoldersListClear}
                key={item.id}
              />
            ))}
          </List>
        )

      case routes.FOLDER_GROUPS:
        return (
          <List>
            {list.map((item) => (
              <BookmarkedGroup
                group={item}
                deleteBookmark={({ group_id }) =>
                  this.deleteBookmark({ group_id })
                }
                bookmarkFoldersList={(id) => this.bookmarkFoldersList(id)}
                addToFolder={({ bookmark_id, folders }) =>
                  this.addToFolder({ bookmark_id, folders })
                }
                bookmarksFoldersListClear={this.props.bookmarksFoldersListClear}
                key={item.id}
              />
            ))}
          </List>
        )

      case routes.FOLDER_PRODUCTS:
        return (
          <div className="products-list">
            {list.map((item) => (
              <BookmarkedProduct
                product={item}
                user={this.props.auth.userData}
                deleteBookmark={({ product_id }) =>
                  this.deleteBookmark({ product_id })
                }
                bookmarkFoldersList={(id) => this.bookmarkFoldersList(id)}
                addToFolder={({ bookmark_id, folders }) =>
                  this.addToFolder({ bookmark_id, folders })
                }
                bookmarksFoldersListClear={this.props.bookmarksFoldersListClear}
                key={item.id}
              />
            ))}
          </div>
        )

      case routes.FOLDER_RECORDS:
        return (
          <div className="products-list">
            {list.map((item) => (
              <BookmarkedRecord
                record={item}
                user={this.props.auth.userData}
                deleteBookmark={({ record_id }) =>
                  this.deleteBookmark({ record_id })
                }
                bookmarkFoldersList={(id) => this.bookmarkFoldersList(id)}
                addToFolder={({ bookmark_id, folders }) =>
                  this.addToFolder({ bookmark_id, folders })
                }
                bookmarksFoldersListClear={this.props.bookmarksFoldersListClear}
                key={item.id}
              />
            ))}
          </div>
        )

      case routes.FOLDER_KNOWCRED:
        return (
          <GridList>
            {list.map((item) => (
              <BookmarkedKnowcred
                knowcred={item}
                deleteBookmark={({ knowcred_id }) =>
                  this.deleteBookmark({ knowcred_id })
                }
                bookmarkFoldersList={(id) => this.bookmarkFoldersList(id)}
                addToFolder={({ bookmark_id, folders }) =>
                  this.addToFolder({ bookmark_id, folders })
                }
                bookmarksFoldersListClear={this.props.bookmarksFoldersListClear}
                key={item.id}
              />
            ))}
          </GridList>
        )

      case routes.FOLDER_STANDARDS:
        return (
          <div className="products-list">
            {list.map((item) => (
              <BookmarkedStandard
                standard={item}
                deleteBookmark={({ standard_id }) =>
                  this.deleteBookmark({ standard_id })
                }
                bookmarkFoldersList={(id) => this.bookmarkFoldersList(id)}
                addToFolder={({ bookmark_id, folders }) =>
                  this.addToFolder({ bookmark_id, folders })
                }
                bookmarksFoldersListClear={this.props.bookmarksFoldersListClear}
                key={item.id}
              />
            ))}
          </div>
        )

      case routes.FOLDER_POSTS:
        return (
          <PostsList
            type="bookmarks"
            subtype="folder"
            userInfo={this.props.auth.userData}
          />
        )

      default:
        return null
    }
  }

  composeLabels = () => {
    const {
      match: { path },
      bookmarks: { list: bookmarksList },
    } = this.props
    const { globalList, list, initialRender, dataReceived } = this.state
    switch (path) {
      case routes.FOLDER:
        return {
          title:
            dataReceived &&
            this.props.bookmarks.currentFolder &&
            this.props.bookmarks.currentFolder.folder.name,
          placeholder: "",
          counter:
            globalList.users_number +
            globalList.groups_number +
            globalList.posts_number +
            // + globalList.products_number
            globalList.knowcreds_number +
            globalList.standards_number,
          showNoResults:
            globalList.users_number === 0 &&
            globalList.groups_number === 0 &&
            globalList.posts_number === 0 &&
            // && globalList.products_number === 0
            globalList.knowcreds_number === 0 &&
            // && globalList.records_number === 0
            globalList.standards_number === 0,
          noResultsLabel: "The folder is empty",
        }

      case routes.FOLDER_PEOPLE:
        return {
          title: "People",
          placeholder: "Start typing name",
          counter: bookmarksList.users_number,
          showNoResults: !initialRender && list.length === 0,
          noResultsLabel: "You don't have bookmarks yet",
        }

      case routes.FOLDER_GROUPS:
        return {
          title: "Groups",
          placeholder: "Start typing group name",
          counter: bookmarksList.groups_number,
          showNoResults: !initialRender && list.length === 0,
          noResultsLabel: "You don't have bookmarks yet",
        }

      case routes.FOLDER_POSTS:
        return {
          title: "Posts",
          placeholder: "",
          counter: bookmarksList.posts_number,
          showNoResults: this.props.posts.postsList.length === 0,
          noResultsLabel: "You don't have bookmarks yet",
        }

      case routes.FOLDER_KNOWCRED:
        return {
          title: "Creds",
          placeholder: "Start typing Cred name",
          counter: bookmarksList.knowcreds_number,
          showNoResults: !initialRender && list.length === 0,
          noResultsLabel: "You don't have bookmarks yet",
        }

      case routes.FOLDER_PRODUCTS:
        return {
          title: "Products",
          placeholder: "Start typing product name",
          counter: bookmarksList.products_number,
          showNoResults: !initialRender && list.length === 0,
          noResultsLabel: "You don't have bookmarks yet",
        }

      case routes.FOLDER_RECORDS:
        return {
          title: "Records",
          placeholder: "Start typing record name",
          counter: bookmarksList.records_number,
          showNoResults: !initialRender && list.length === 0,
          noResultsLabel: "You don't have bookmarks yet",
        }

      case routes.FOLDER_STANDARDS:
        return {
          title: "Standards",
          placeholder: "Start typing standard name",
          counter: bookmarksList.standards_number,
          showNoResults: !initialRender && list.length === 0,
          noResultsLabel: "You don't have bookmarks yet",
        }

      default:
        return {
          title: "",
          placeholder: "",
          counter: "",
          showNoResults: "",
          noResultsLabel: "",
        }
    }
  }

  onScroll = () => {
    const {
      match: { path, params },
    } = this.props

    switch (path) {
      case routes.FOLDER:
      case routes.FOLDER_POSTS:
        this.state.dataReceived &&
          this.props.getPostsList({
            bookmarked: true,
            folder_id: parseInt(params.folderId),
            offset: this.props.posts.postsList.length,
            limit: LIMIT,
          })
        break

      default:
        this.state.dataReceived && this.getList()
    }
  }

  render() {
    const {
      match: { path },
    } = this.props
    const { dataReceived, redirect } = this.state

    if (redirect) {
      return <Redirect to={routes.BOOKMARKS} />
    }

    const showSearch = ![routes.FOLDER, routes.FOLDER_POSTS].includes(path)

    return (
      <>
        {showSearch ? (
          <ListSearchHeader
            title={this.composeLabels().title}
            placeholder={this.composeLabels().placeholder}
            search={this.composeSearchQuery}
            noResults={this.composeLabels().showNoResults}
            noResultsLabel={this.composeLabels().noResultsLabel}
            counter={this.composeLabels().counter}
          />
        ) : (
          this.props.match.path !== routes.FOLDER_POSTS && (
            <div className="box">
              <div className="box__heading">
                <h1>
                  {/*{this.composeLabels().title} <span className="color-black-54">{dataReceived && this.composeLabels().counter}</span>*/}
                  {this.composeLabels().title}
                </h1>
              </div>
              {this.composeLabels().showNoResults && (
                <div className="box__content">
                  <p className="color-black-54 text-center">
                    {this.composeLabels().noResultsLabel}
                  </p>
                </div>
              )}
            </div>
          )
        )}
        {dataReceived ? this.composeList() : <Loader />}
      </>
    )
  }
}

const mapStateToProps = ({ auth, bookmarks, posts }) => ({
  auth,
  bookmarks,
  posts,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getBookmarksList,
      usersSearch,
      groupsSearch,
      productsSearch,
      knowCredsList,
      searchStandards,
      getPostsList,
      deleteBookmark,
      bookmarkFoldersList,
      bookmarksFoldersListClear,
      bindFolder,
      bookmarksListClear,
      getInvenstoryList,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withScroll(withSnackbar(Folder)))
