import React from "react"
import { generatePath } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import Card from "./card"
import { getGroupLogo } from "../../../../library/utils/getGroupLogo"
import { GROUP_TYPES } from "../../../../library/constants/groupTypes"

const GroupCard = ({ group, controls }) => {
  const groupStats = `${GROUP_TYPES[group.type].title} (${
    group.closed ? "closed" : "open"
  } group), ${group.participants_number} members`
  const groupUrl = generatePath(routes.GROUP, { groupId: group.id })

  return (
    <Card
      name={group.name}
      photo={getGroupLogo(group)}
      link={groupUrl}
      stats={groupStats}
      info={
        !!group.description &&
        group.description !== "Unknown" &&
        group.description
      }
      controls={controls}
    />
  )
}

export default GroupCard
