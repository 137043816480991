import React, { Component } from "react"
import "./groupCreate.scss"
import Dialog from "@material-ui/core/Dialog"
import { bindActionCreators } from "redux"
import { connect, useSelector } from "react-redux"
import { withSnackbar } from "notistack"
import { generatePath, withRouter } from "react-router-dom"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import GroupCreateForm from "./groupCreateForm/groupCreateForm"
import companyIcon from "../../../../assets/img/group-icons/company-icon.svg"
import districtIcon from "../../../../assets/img/group-icons/district-icon.svg"
import oneTeacherSchoolIcon from "../../../../assets/img/group-icons/tutor-icon.svg"
import schoolIcon from "../../../../assets/img/group-icons/school-icon.svg"
import interestsIcon from "../../../../assets/img/group-icons/regular-group-icon.svg"
import eventIcon from "../../../../assets/img/group-icons/event-group-icon.svg"
import * as routes from "../../../../library/constants/routes"
import ActionWithPermissions from "../../../../components/v2/controls/buttons/ActionWithPermissions"

const FirstStep = (props) => {
  const { userData } = useSelector((state) => state.auth)

  return (
    <>
      <DialogContent>
        <div className="group-create">
          <div className="group-create__header">
            <h3 className="group-create__title">KnowStory groups</h3>
            {/*<div className="group-create__subtitle">*/}
            {/*  Publish content of various formats, share photos and videos, have*/}
            {/*  conversations, make plans and more. To begin, select your*/}
            {/*  community type.*/}
            {/*</div>*/}
          </div>
          <div className="grid grid-cols-3 grid-flow-row justify-center gap-16 px-16">
            {userData?.role === 2 && (
              <>
                <div
                  className="grid grid-rows-subgrid row-span-2 gap-4"
                  onClick={() => props.switchForm("district")}
                >
                  <div className="flex items-end justify-center w-full">
                    <img src={districtIcon} alt="District" />
                  </div>
                  <h4 className="text-lg font-medium text-center">District</h4>
                  {/*<div className="subhead-6"></div>*/}
                </div>
                <ActionWithPermissions
                  allowedPlans={[3]}
                  action={() => props.switchForm("school")}
                  className={"grid grid-rows-subgrid row-span-2 gap-4"}
                >
                  <div className="flex items-end justify-center w-full">
                    <img src={schoolIcon} alt="School" />
                  </div>
                  <h4 className="text-lg font-medium text-center">School</h4>
                </ActionWithPermissions>

                <ActionWithPermissions
                  allowedPlans={[2]}
                  action={() => props.switchForm("private-school")}
                  className={"grid grid-rows-subgrid row-span-2 gap-4"}
                >
                  <div className="flex items-end justify-center w-full">
                    <img src={oneTeacherSchoolIcon} alt="One teacher school" />
                  </div>
                  <h4 className="text-lg font-medium text-center">
                    One teacher school
                  </h4>
                  {/*<div className="subhead-6"></div>*/}
                </ActionWithPermissions>
              </>
            )}

            {userData?.role === 4 && (
              <div
                className="grid grid-rows-subgrid row-span-2 gap-4"
                onClick={() => props.switchForm("company")}
              >
                <div className="flex items-end justify-center w-full">
                  <img src={companyIcon} alt="Company" />
                </div>
                <h4 className="text-lg font-medium text-center">Company</h4>
                {/*<div className="subhead-6"></div>*/}
              </div>
            )}

            <div
              onClick={() => props.switchForm("interest")}
              className={"grid grid-rows-subgrid row-span-2 gap-4"}
            >
              <div className="flex items-end justify-center w-full">
                <img src={interestsIcon} alt="Interest group" />
              </div>
              <h4 className="text-lg font-medium text-center">
                Interest group
              </h4>
              {/*<div className="subhead-6"></div>*/}
            </div>
            <div
              onClick={() => props.switchForm("event")}
              className={"grid grid-rows-subgrid row-span-2 gap-4"}
            >
              <div className="flex items-end justify-center w-full">
                <img src={eventIcon} alt="Event" />
              </div>
              <h4 className="text-lg font-medium text-center">Event</h4>
              {/*<div className="subhead-6"></div>*/}
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => props.handleCancelButton()}
          variant="text"
          color="primary"
        >
          Cancel
        </Button>
      </DialogActions>
    </>
  )
}

class GroupCreate extends Component {
  state = {
    groupType: "",
  }

  handleClose = () => {
    this.props.onClose()
  }

  handleCancelButton = () => {
    const { groupType } = this.state

    if (groupType === "") {
      this.handleClose()
    } else {
      this.setState({ groupType: "" })
    }
  }

  switchForm = (type) => {
    this.setState({ groupType: type })
  }

  render() {
    const { groupType } = this.state

    const content = () => {
      switch (groupType) {
        case "":
          return (
            <FirstStep
              switchForm={(type) => this.switchForm(type)}
              handleCancelButton={this.handleCancelButton}
            />
          )

        case "interest":
          return (
            <GroupCreateForm
              type={1}
              handleCancelButton={this.handleCancelButton}
            />
          )

        case "event":
          return (
            <GroupCreateForm
              type={2}
              handleCancelButton={this.handleCancelButton}
            />
          )

        case "company":
          return (
            <GroupCreateForm
              type={3}
              handleCancelButton={this.handleCancelButton}
            />
          )

        case "school":
          return (
            <GroupCreateForm
              type={4}
              handleCancelButton={this.handleCancelButton}
            />
          )

        case "private-school":
          return (
            <GroupCreateForm
              type={7}
              handleCancelButton={this.handleCancelButton}
            />
          )

        // case 'district':
        //     return (
        //         <div className="p30 color-primary font-16">Contact admin</div>
        //     );

        case "district":
          return (
            <>
              {((_) =>
                this.props.history.push(generatePath(routes.CONTACT_FORM)))()}
            </>
          )

        default:
          return (
            <FirstStep
              switchForm={(type) => this.switchForm(type)}
              handleCancelButton={this.handleCancelButton}
            />
          )
      }
    }

    return (
      <Dialog
        aria-labelledby="create-group-dialog"
        onClose={this.handleClose}
        open={this.props.open}
        maxWidth="md"
      >
        {content()}
      </Dialog>
    )
  }
}

const mapStateToProps = ({ auth }) => ({ auth })
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(GroupCreate)))
