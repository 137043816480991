import styled from "@emotion/styled"
import { colors } from "../../../../library/constants/styles/colors"

const Container = styled.div`
  margin-right: 15px;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 2px;
  background-color: ${colors.white};
  box-shadow: 0 0 2px 0 rgba(210, 210, 210, 0.5);

  &:nth-child(${(props) => props.cardsInRow}n) {
    margin-right: 0;
  }

  @supports (display: grid) {
    margin-right: 0;
    margin-bottom: 0;
  }
`

const VerticalCard = ({ cardsInRow = 3, children }) => {
  return <Container cardsInRow={cardsInRow}>{children}</Container>
}

export default VerticalCard
