import React from "react"
import {
  useCourse,
  useCourseMutations,
} from "../../../../../hooks/data/courses/courses"
import logoDefault from "../../../../../assets/img/product.svg"
import EntityPhotoPicker from "../../../../../components/EntityPhotoPicker/EntityPhotoPicker"
import { Box } from "../../../../../components/tw/layout"

const CourseLogo = () => {
  const { course, canEdit, isAvailable, queryKey } = useCourse()

  const { updateCourse } = useCourseMutations({ queryKey })

  const onPhotoUpdate = async (photo) => {
    await updateCourse.mutateAsync({ id: course.id, full_image: photo })
  }

  return (
    <Box>
      <div className="product-logo">
        <img
          src={course?.photo || logoDefault}
          alt="Logo"
          className="product-logo__img"
        />
        {canEdit && isAvailable && (
          <EntityPhotoPicker
            photo={course?.photo || logoDefault}
            defaultPhoto={logoDefault}
            onSave={onPhotoUpdate}
            buttonClassName={"right-0 top-0"}
          />
        )}
      </div>
    </Box>
  )
}

export default CourseLogo
