import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core"
import React, { useEffect, useState } from "react"

export const extractMinutes = (t) => t % 60
export const extractHours = (t, withDays) =>
  withDays ? Math.floor((t / 60) % 24) : Math.floor(t / 60)
export const extractDays = (t) => Math.floor(t / 60 / 24)

const composeRangeOptions = (optionsNumber, minTime, maxTime) => {
  return Array(optionsNumber + 1)
    .fill("")
    .map((_, index) => {
      return {
        value: index,
        disabled: index < minTime || index > maxTime,
      }
    })
}
const useTimeDuration = ({
  time,
  maxTime = 43200,
  minTime = 0,
  daysPicker = false,
}) => {
  const [min, setMin] = useState({
    minutes: extractMinutes(minTime),
    hours: extractHours(minTime),
    days: extractDays(minTime),
  })

  const [max, setMax] = useState({
    minutes: extractMinutes(time) >= maxTime ? extractMinutes(maxTime) : 59,
    hours:
      extractDays(time) >= extractDays(maxTime) &&
      extractHours(time) >= extractHours(maxTime)
        ? extractHours(maxTime)
        : daysPicker
        ? 23
        : 99,
    days: extractDays(time) >= extractDays(maxTime) ? extractDays(maxTime) : 30,
  })

  const composeMinutesOptions = (_min = min.minutes, _max = max.minutes) =>
    composeRangeOptions(59, _min, _max)
  const composeHoursOptions = (_min = min.hours, _max = max.hours) =>
    composeRangeOptions(daysPicker ? 23 : 99, _min, _max)
  const composeDaysOptions = (_min = min.days, _max = max.days) =>
    composeRangeOptions(30, _min, _max)

  const [rangeOptions, setRangeOptions] = useState({
    minutes: composeMinutesOptions(),
    hours: composeHoursOptions(),
    days: composeDaysOptions(),
  })

  const [disabledFields, setDisabledFields] = useState({
    minutes: false,
    hours: false,
    days: false,
  })

  useEffect(() => {
    const _minMinutes =
      extractDays(time) > extractDays(minTime) ||
      extractHours(time) > extractHours(minTime)
        ? 0
        : min.minutes

    const _minHours = extractDays(time) > extractDays(minTime) ? 0 : min.hours

    setRangeOptions({
      minutes: composeMinutesOptions(_minMinutes),
      hours: composeHoursOptions(_minHours),
      days: composeDaysOptions(),
    })
  }, [time])

  const adjustToInitialLimits = (_t, _min, _max) => {
    return Number(_t) > _max
      ? String(_max)
      : Number(_t) < _min
      ? String(_min)
      : String(_t)
  }

  const adjustLimits = async (values) => {
    setMin((prevState) => ({
      ...prevState,
      hours: Number(values.days) > prevState.days ? 0 : prevState.hours,
      minutes:
        Number(values.hours) > prevState.hours ||
        Number(values.days) > prevState.days
          ? 0
          : prevState.minutes,
    }))
  }

  const adjustValues = async (values) => {
    const _min = {
      days: min.days,
      hours: Number(values.days) > min.days ? 0 : min.hours,
      minutes:
        Number(values.days) > min.days || Number(values.hours) > min.hours
          ? 0
          : min.minutes,
    }

    const limitedMinutes = adjustToInitialLimits(
      values.minutes,
      _min.minutes,
      max.minutes
    )

    const limitedHours = adjustToInitialLimits(
      values.hours,
      _min.hours,
      max.hours
    )

    const limitedDays = adjustToInitialLimits(values.days, _min.days, max.days)

    const adjustHours = () => {
      const shouldDisable = Number(limitedDays) === max.days

      setDisabledFields((prevState) => ({ ...prevState, hours: shouldDisable }))

      if (shouldDisable) return "0"

      if (min.hours < limitedHours < max.hours) {
        setMin((prevState) => ({ ...prevState }))
      }

      return limitedHours
    }
    const adjustMinutes = () => {
      const shouldDisable = Number(limitedDays) === max.days

      setDisabledFields((prevState) => ({
        ...prevState,
        minutes: shouldDisable,
      }))

      if (shouldDisable) return "0"

      return limitedMinutes
    }

    return {
      minutes: adjustMinutes(),
      hours: adjustHours(),
      days: limitedDays,
      time:
        Number(adjustMinutes()) +
        Number(adjustHours()) * 60 +
        Number(limitedDays) * 24 * 60,
    }
  }

  const adjust = async (values) => {
    await adjustLimits(values)
    return await adjustValues(values)
  }

  return {
    adjustValues,
    rangeOptions,
    disabledFields,
  }
}

const TimeDurationPicker = ({
  label = "Duration",
  time,
  minutesPicker = true,
  hoursPicker = true,
  daysPicker = false,
  minTime = 0,
  maxTime = 43200, // 30 days
  onChange,
  disabled = false,
}) => {
  const [values, setValues] = useState({
    days: String(extractDays(time)),
    hours: String(extractHours(time)),
    minutes: String(extractMinutes(time)),
  })

  const { adjustValues, rangeOptions, disabledFields } = useTimeDuration({
    time,
    minTime,
    maxTime,
    daysPicker,
  })
  const handleChange = (name) => async (event) => {
    const { days, hours, minutes } = await adjustValues({
      ...values,
      [name]: event.target.value,
    })
    setValues({ days, hours, minutes })
  }

  useEffect(() => {
    onChange(
      parseInt(values.days) * 60 * 24 +
        parseInt(values.hours) * 60 +
        parseInt(values.minutes)
    )
  }, [values])

  return (
    <div className="form__fieldset">
      <span className="form__label">{label}</span>
      <div className="form__fieldbox">
        <div className="f" style={{ width: 330 }}>
          {daysPicker && (
            <FormControl className="mr30">
              <InputLabel shrink id="days-label">
                Days
              </InputLabel>
              <Select
                labelId="days"
                value={values.days}
                onChange={handleChange("days")}
                style={{ width: 60 }}
                disabled={disabled || disabledFields.days}
              >
                {rangeOptions.days.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {hoursPicker && (
            <FormControl className="mr30">
              <InputLabel shrink id="hours-label">
                Hours
              </InputLabel>
              <Select
                labelId="hours"
                value={values.hours}
                onChange={handleChange("hours")}
                style={{ width: 60 }}
                disabled={disabled || disabledFields.hours}
              >
                {rangeOptions.hours.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {minutesPicker && (
            <FormControl>
              <InputLabel shrink id="minutes-label">
                Minutes
              </InputLabel>
              <Select
                labelId="minutes-label"
                value={values.minutes}
                onChange={handleChange("minutes")}
                style={{ width: 60 }}
                disabled={disabled || disabledFields.minutes}
              >
                {rangeOptions.minutes.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
      </div>
    </div>
  )
}

export default TimeDurationPicker
