import React, { useEffect, useState } from "react"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import { Dialog, DialogContent } from "@material-ui/core"
import Loader from "../../../../../../../../../../../components/ui/loader"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import { useAlert } from "../../../../../../../../../../../hooks/useAlert"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import {
  clearSchoolCalendarSegmentSection,
  createSchoolCalendarSegmentSection,
  getSchoolCalendarSegmentSection,
  updateSchoolCalendarSegmentSection,
} from "../../../../../../../../../../../library/store/actions/creators/groupsCreators"
import { weekDays } from "../../../../../../../../../../../library/utils/timeConvert"
import Select from "@material-ui/core/Select/Select"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import { rotationTypes } from "../../../../../../../../../../../library/constants/groupTypes"
import {
  useSection,
  useSectionMutations,
} from "../../../../../../../../../../../hooks/data/calendar/schedules"

const SectionFormModal = ({ edit, activeSection, onClose, open, queryKey }) => {
  const { errorAlert } = useAlert()
  const params = useParams()

  const { data: currentSection, isSuccess } = useSection(activeSection, {
    enabled: !!activeSection,
  })

  const { createSection, updateSection } = useSectionMutations(queryKey)

  const [name, setName] = useState("")
  const [rotations, setRotations] = useState({
    0: 1,
    1: 1,
    2: 1,
    3: 1,
    4: 1,
    5: 1,
    6: 1,
  })

  useEffect(() => {
    if (edit && currentSection) {
      setName(currentSection.name)
      setRotations(
        currentSection.rotations.reduce(
          (acc, cur, i) => ({ ...acc, [i]: cur.type }),
          {
            0: 1,
            1: 1,
            2: 1,
            3: 1,
            4: 1,
            5: 1,
            6: 1,
          }
        )
      )
    }
  }, [currentSection])

  const onSubmit = async () => {
    try {
      if (!edit) {
        await createSection.mutateAsync({
          segment_id: parseInt(params.segmentId, 10),
          name,
          rotations: Object.values(rotations),
        })
      } else {
        await updateSection.mutateAsync({
          id: activeSection,
          name,
          rotations: Object.values(rotations),
        })
      }

      onClose()
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <Dialog
      aria-labelledby="calendar-form-dialog"
      onClose={onClose}
      open={open}
      maxWidth="md"
    >
      <ValidatorForm onSubmit={onSubmit}>
        <DialogTitle id="alert-dialog-title" className="text-center">
          {edit ? "Edit Section" : "Create Section"}
        </DialogTitle>
        <DialogContent>
          {(edit && isSuccess) || !edit ? (
            <div className="f jcc">
              <div className="form form--modal">
                <div className="form__fieldset">
                  <label htmlFor="name" className="form__label">
                    Name *
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="name"
                        name="name"
                        value={name}
                        placeholder="Type section name"
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        validators={[
                          "required",
                          "minStringLength:3",
                          "maxStringLength:200",
                        ]}
                        errorMessages={[
                          "Field is required",
                          "Min length is 3 characters",
                          "Max length is 200 characters",
                        ]}
                        withRequiredValidator
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                {Object.keys(weekDays).map((d, i) => (
                  <div className="form__fieldset" key={d}>
                    <label htmlFor={d} className="form__label">
                      {weekDays[d]}
                    </label>
                    <div className="form__fieldbox">
                      <div className="form__input form__input--select">
                        <Select
                          id={d}
                          name={`rotation-${d}`}
                          fullWidth
                          displayEmpty
                          value={rotations[i]}
                          onChange={(e) => {
                            console.log(e.target.value)
                            setRotations((r) => ({
                              ...r,
                              [i]: e.target.value,
                            }))
                          }}
                          renderValue={(value) => {
                            if (value.length === 0) {
                              return (
                                <span className="color-black-38">
                                  Select rotation type
                                </span>
                              )
                            }

                            return rotationTypes.find((t) => t.id === value)
                              .name
                          }}
                        >
                          {rotationTypes.map((item) => (
                            <MenuItem value={item.id} key={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <Loader />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  )
}

export default SectionFormModal
