import React from "react"
import { SectionHeading } from "../uknow/uknowInfoPage"
import img1 from "../../../assets/img/pages/solutions/flex-master-schedule/1.jpg"
import img2 from "../../../assets/img/pages/solutions/flex-master-schedule/2.png"
import img3 from "../../../assets/img/pages/solutions/flex-master-schedule/3.png"
import img4 from "../../../assets/img/pages/solutions/flex-master-schedule/4.png"
import img5 from "../../../assets/img/pages/solutions/flex-master-schedule/5.gif"

const SolutionsMasterSchedulePage = () => {
  return (
    <>
      <section className={"bg-white w-full py-8"}>
        <div className="container">
          <SectionHeading>Flex Master Schedules</SectionHeading>
          <img src={img1} alt="Back bends" className={"rounded-2xl mb-8"} />
          <SectionHeading>
            Flexibility for Everyone
            <br />
            <span className={"text-xl leading-normal"}>
              Attach Products to Your Group for the Featured Products
              Marketplace
            </span>
          </SectionHeading>
        </div>
      </section>
      <section className={"bg-grey7 w-full py-8"}>
        <div className="container">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-start-2 col-span-10">
              <p className={"mb-4"}>
                <b>With Knowstory</b>, your master schedule or master calendar
                can be a background operation to give a general form to the
                school's hours, days, weeks and year, while magically providing
                vast flexibility in time use for every teacher and student.
                Inside the powerful Knowstory calendar-maker, teachers, schools
                and districts can create individual schedules and completely
                independent or "bi-level" schedules for teachers and students.
              </p>
              <p className={"mb-4"}>
                <b>Flexibility for everyone</b> is also enabled through custom
                personal Holds. A "Hold" is a bracketed open time set to any mix
                of the five-day week and given a label. It could be six hours of
                the teacher's classes teaching one subject to multiple grades.
                Inside that six hours a teacher can put "minus time" to account
                for breaks or lunch periods that no class meetings should be put
                over. Multiple-subject teachers can create "Holds" of time that
                are labeled appropriately and used in the creation of
                auto-cohorting class Meets. Students also see what their
                assigned schedule is for study per subject and can also create
                Holds for class meetings to fall into. The point is the amount
                of time a student should be spending on any one subject is
                adjustable. Students can even fast-forward their day after
                completing the day's work in a subject to save up time for
                afternoon extracurricular activities.
              </p>
              <p>
                <b>The Al calendaring</b> in Knowstory manages all class
                meetings dynamically in any master schedule, but a School can
                opt to keep traditional time schedules while still providing
                flexibility within class hours.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={"bg-white w-full py-8"}>
        <div className="container">
          <div className="grid grid-cols-12">
            <div className={"col-start-3 col-span-8"}>
              <img src={img2} alt="" className={"mx-auto"} />
            </div>
          </div>
        </div>
      </section>
      <section className={"bg-grey7 w-full py-8"}>
        <div className="container">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-start-2 col-span-10">
              <p className={"mb-4"}>
                <b>Easy to create</b> calendars are made from a
                One-Teacher-School, School or District Group. Tying schools to a
                district allow schools to copy-down master calendars of
                districts and the district to see every sub-group school
                calendar.
              </p>
              <p>
                <b>Tiered structure</b> in calendar making starts with
                bracketing the annual calendar start and end dates. From there,
                creating segments for months, sections for weeks, and tracks and
                blocks.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={"bg-white w-full py-8"}>
        <div className="container">
          <div className="grid grid-cols-12">
            <div className={"col-start-3 col-span-8"}>
              <img src={img3} alt="" className={"mx-auto"} />
            </div>
          </div>
        </div>
      </section>
      <section className={"bg-grey7 w-full py-8"}>
        <div className="container">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-start-2 col-span-10">
              <p>
                <b>Schedule assignment of individuals</b> happens in a grid. In
                this way, every student and every teacher gets a side-bar
                schedule to follow every day while dynamic auto-cohorting class
                meets happen over the top of the schedule. Again, using holds,
                several blocks can collapse into one bracketed and labeled
                amount of time applied to the individual's schedule for the Al
                to use.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={"bg-white w-full py-8"}>
        <div className="container">
          <div className="grid grid-cols-12">
            <div className={"col-start-3 col-span-8"}>
              <img src={img4} alt="" className={"mx-auto"} />
            </div>
          </div>
        </div>
      </section>
      <section className={"bg-grey7 w-full py-8"}>
        <div className="container">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-start-2 col-span-10">
              <p>
                <b>A Teacher's Day</b> would be different than they are used to
                and can be managed differently based on the number of units and
                variety of subjects they manage. Here is an example of one
                Teacher's day who is carrying several units and has collapsed
                their hours for all those courses to set any one of their class
                meetings in any of their open time. The Al takes care of not
                setting over other teachers.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={"bg-white w-full py-8"}>
        <div className="container">
          <img src={img5} alt="" className={"mx-auto"} />
        </div>
      </section>
    </>
  )
}

export default SolutionsMasterSchedulePage
