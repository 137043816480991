import React from "react"
import Loader from "../../../../../../../../../../../components/ui/loader"
import { minutesToString } from "../../../../../../../../../../../library/utils/timeConvert"
import { format } from "date-fns"
import KnowmixListForUnit from "./knowmixListForUnit"
import InsertDriveFile from "@material-ui/icons/InsertDriveFile"
import { formatBytes } from "../../../../../../../../../../../library/utils/fileSizeConverter"
import { useUnit } from "../../../../../../../../../../../hooks/data/calendar/useUnit"
import PlainLink from "../../../../../../../../../../../components/ui/link"
import InfoModal from "../../../../../../../../../../../components/v2/modals/infoModal"

const UnitDialog = (props) => {
  const { unit, isSuccess } = useUnit(props.activeUnit)

  const [knowmixListOpen, setKnowmixListOpen] = React.useState(false)

  return (
    <InfoModal
      title={"Unit details"}
      leftButton={
        isSuccess && {
          action: () => setKnowmixListOpen(true),
          label: "Choose Course",
        }
      }
      open={props.open}
      onClose={props.onClose}
    >
      {isSuccess ? (
        <div className="f jcc full-width">
          <table className="product-details product-details--invenstory-record">
            <tbody>
              <tr className="product-details__row">
                <td className="product-details__label">Name</td>
                <td className="product-details__value">{unit.name}</td>
              </tr>
              <tr className="product-details__row">
                <td className="product-details__label">Subject</td>
                <td className="product-details__value">
                  <button
                    className="link"
                    onClick={() => props.onSubjectDialogOpen(unit.subject.id)}
                  >
                    {unit.subject.name}
                  </button>
                </td>
              </tr>
              <tr className="product-details__row">
                <td className="product-details__label">Description</td>
                <td className="product-details__value">{unit.description}</td>
              </tr>
              {!!unit.standards.length && (
                <tr className="product-details__row">
                  <td className="product-details__label">Standards</td>
                  <td className="product-details__value">
                    <ul className="text-list">
                      {unit.standards.map((s) => (
                        <li key={s.id}>{s.name}</li>
                      ))}
                    </ul>
                  </td>
                </tr>
              )}
              <tr className="product-details__row">
                <td className="product-details__label">Start date</td>
                <td className="product-details__value">
                  {format(new Date(unit.date_from), "d MMMM yyy")}
                </td>
              </tr>

              <tr className="product-details__row">
                <td className="product-details__label">Finish date</td>
                <td className="product-details__value">
                  {format(new Date(unit.date_to), "d MMMM yyy")}
                </td>
              </tr>
              <tr className="product-details__row">
                <td className="product-details__label">Estimated time</td>
                <td className="product-details__value">
                  {minutesToString(unit.minutes)}
                </td>
              </tr>
              {!!unit.link && (
                <tr className="product-details__row">
                  <td className="product-details__label">Link</td>
                  <td className="product-details__value">
                    <PlainLink native to={unit.link}>
                      {unit.link}
                    </PlainLink>
                  </td>
                </tr>
              )}
              {!!unit.blobs.length && (
                <tr className="product-details__row">
                  <td className="product-details__label">Attachment</td>
                  <td className="product-details__value">
                    <div className="post__files">
                      {unit.blobs.map((f) => (
                        <a href={f.url} target="_blank" className="post__file">
                          <InsertDriveFile
                            className="post__file-icon"
                            style={{ fontSize: "30px" }}
                          />
                          <div className="post__file-info">
                            <span className="link link--inline">{f.name}</span>
                            <span className="post__file-size">
                              {formatBytes(f.size)}
                            </span>
                          </div>
                        </a>
                      ))}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {knowmixListOpen && (
            <KnowmixListForUnit
              open={knowmixListOpen}
              handleClose={() => setKnowmixListOpen(false)}
              unit={unit}
            />
          )}
        </div>
      ) : (
        <Loader />
      )}
    </InfoModal>
  )
}

export default UnitDialog
