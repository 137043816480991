import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { KNOWMIX } from "../../../library/store/actions/types/knowmixTypes"
import { call } from "../../../library/networking/API"
import { useAlert } from "../../useAlert"
import { useIdParam } from "../../useIdParam"

export const useCourseSteps = (courseId = null, options = {}) => {
  const __courseId = useIdParam("knowmixId")
  const id = courseId || __courseId

  const queryKey = [KNOWMIX.STEPS.LIST, { id }]

  const { data: steps, ...rest } = useQuery(queryKey, {
    queryFn: async () => {
      try {
        const res = await call(KNOWMIX.STEPS.LIST, { id })
        return await res.data.steps
      } catch (e) {
        console.log(e)
      }
    },
    refetchOnFocus: false,
    ...options,
  })

  return {
    queryKey,
    steps,
    ...rest,
  }
}

export const useCourseStep = ({ courseId, stepId }, options = {}) => {
  const { steps, ...rest } = useCourseSteps({ courseId })

  return {
    step: steps?.find((s) => s.id === stepId),
    ...rest,
  }
}

export const useCourseStepsMutations = ({ queryKey }) => {
  const queryClient = useQueryClient()
  const { errorAlert } = useAlert()

  const createCourseStep = useMutation({
    mutationFn: async ({
      knowmix_id,
      name,
      description,
      time,
      links = [],
      products,
      standards,
      attachments,
      questions,
    }) => {
      try {
        const res = await call(KNOWMIX.STEPS.CREATE, {
          knowmix_id,
          name,
          description,
          time,
          links,
          products,
          standards,
          attachments,
          questions,
        })
        return await res.data
      } catch (e) {
        errorAlert(e)
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey })
    },
  })
  const updateCourseStep = useMutation({
    mutationFn: async ({
      id,
      name,
      description,
      time,
      links = [],
      products,
      standards,
      attachments,
      questions,
    }) => {
      try {
        const res = await call(KNOWMIX.STEPS.UPDATE, {
          id,
          name,
          description,
          time,
          links,
          products,
          standards,
          attachments,
          questions,
        })
        return await res.data
      } catch (e) {
        errorAlert(e)
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey })
    },
  })
  const deleteCourseStep = useMutation({
    mutationFn: async ({ id }) => {
      try {
        const res = await call(KNOWMIX.STEPS.DELETE, { id })
        return await res.data
      } catch (e) {
        errorAlert(e)
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey })
    },
  })

  const startCourseStepQuiz = useMutation({
    mutationFn: async (id) => {
      const res = await call(KNOWMIX.WORKS.CREATE, { id })
      return await res.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey })
    },
  })
  const updateCourseStepQuiz = useMutation({
    mutationFn: async (id) => {
      const res = await call(KNOWMIX.WORKS.UPDATE, { id })
      return await res.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey })
    },
  })
  const finishCourseStepQuiz = useMutation({
    mutationFn: async (id) => {
      const res = await call(KNOWMIX.WORKS.FINISH, { id })
      return await res.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey })
    },
  })
  const gradeCourseStepQuiz = useMutation({
    mutationFn: async (id) => {
      const res = await call(KNOWMIX.WORKS.GRADE, { id })
      return await res.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey })
    },
  })
  const expressFinishCourseStepQuiz = useMutation({
    mutationFn: async (step_id) => {
      try {
        await call(KNOWMIX.WORKS.CREATE, { step_id })
        await call(KNOWMIX.WORKS.FINISH, { step_id })
      } catch (e) {
        if (e?.error?.code === 23401) {
          await call(KNOWMIX.WORKS.FINISH, { step_id })
        } else {
          errorAlert(e)
        }
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey })
    },
  })

  return {
    createCourseStep,
    updateCourseStep,
    deleteCourseStep,
    startCourseStepQuiz,
    updateCourseStepQuiz,
    finishCourseStepQuiz,
    expressFinishCourseStepQuiz,
  }
}
