import React from "react"
import BoxPage from "../../../../../../components/v2/layout/boxPage/boxPage"
import { KNOWMIX } from "../../../../../../library/store/actions/types/knowmixTypes"
import GroupLink from "../../../../../../components/v2/utils/GroupLink"
import UserLink from "../../../../../../components/v2/utils/UserLink"
import { getObjectById } from "../../../../../../library/utils/arrays"
import { calendarGrades } from "../../../../../../library/constants/educationalDictionary"
import {
  knowmixTypes,
  stepStatusTypes,
} from "../../../../../../library/constants/knowmix"
import { generatePath } from "react-router-dom"
import * as routes from "../../../../../../library/constants/routes"
import {
  KNOWCRED_SINGLE,
  KNOWMIX_WALKTHROUGH,
} from "../../../../../../library/constants/routes"
import { KeyboardArrowRightOutlined } from "@material-ui/icons"
import SuperDataTable from "../../../../../../components/v2/dataTable/SuperDataTable"
import { useQuery } from "@tanstack/react-query"
import { call } from "../../../../../../library/networking/API"
import Loader from "../../../../../../components/ui/loader"
import InfoTable from "../../../../../../components/v2/utils/infoTable"
import PlainLink from "../../../../../../components/ui/link"
import LaunchIcon from "@material-ui/icons/Launch"

const LearnerTrackingCoursesGrades = () => {
  return (
    <BoxPage title={"My Grades"}>
      <SuperDataTable
        columns={[
          { label: "Name", role: "name" },
          { label: "Created by" },
          { label: "Subject" },
          { label: "Status" },
          { label: "", role: "actions" },
        ]}
        queryConfig={{
          key: [
            {
              type: "learner-grades-tracking",
              url: KNOWMIX.LIST,
              fetchParams: {
                managed: false,
                active: false,
                finished: false,
                graded: true,
                types: [2, 3],
              },
            },
          ],
          listKey: "knowmixes",
          counterKey: "knowmixes_number",
          composeRowData: (record) => [
            record.name || "",
            {
              type: "component",
              component: record.creator_group
                ? () => <GroupLink group={record.creator_group} />
                : () => <UserLink user={record.creator_user} />,
            },
            {
              type: "component",
              component: () => {
                return (
                  <p>
                    {record?.subject?.id ? (
                      <>
                        <span className={""}>{record?.subject?.name}</span>
                        <br />
                        <span className={"text-xs"}>
                          Grade:{" "}
                          {
                            getObjectById(calendarGrades, record.subject.grade)
                              .name
                          }
                        </span>
                      </>
                    ) : (
                      <span className={"color-black-54"}>—</span>
                    )}
                  </p>
                )
              },
            },
            knowmixTypes[record.type],
          ],
        }}
        isSearchable
        isRowExpandable
        actions={(record) => [
          {
            type: "link",
            path: generatePath(routes.KNOWMIX, { knowmixId: record.id }),
            label: () => (
              <>
                Open Course <KeyboardArrowRightOutlined />
              </>
            ),
          },
        ]}
        getRowToggleComponent={({ record, isExpanded }) => (
          <ToggleContent record={record} isExpanded={isExpanded} />
        )}
      />
    </BoxPage>
  )
}

const ToggleContent = ({ record, isExpanded }) => {
  const { data, isSuccess, isLoading } = useQuery({
    queryKey: [
      {
        type: "learner-grades-tracking",
        url: KNOWMIX.GET,
        fetchParams: { id: record.id },
      },
    ],
    queryFn: async ({ queryKey }) => {
      const res = await call(queryKey[0].url, queryKey[0].fetchParams)
      return res.data
    },
    enabled: isExpanded,
    staleTime: 60 * 15 * 1000,
    refetchOnWindowFocus: false,
  })

  return (
    <div className={"p-4"}>
      {!isSuccess && isLoading && <Loader dense size={20} />}
      {isSuccess && (
        <div className={"text-sm"}>
          <div>
            <InfoTable
              dense
              data={[
                {
                  label: "Examiner",
                  component: <UserLink user={data.teacher} />,
                },
              ]}
            />
            <hr className={"border-divider my-4"} />
            <p className={"mb-4"}>Grade for Course:</p>
            <div className={"pl-2"}>
              {data?.grade ? (
                <InfoTable
                  dense
                  data={[
                    {
                      label: "Grade",
                      text: data.grade,
                    },
                    {
                      label: "Comment",
                      text: data.comment || "—",
                    },
                    {
                      label: "Cred",
                      link: data?.knowcred?.id
                        ? {
                            to: generatePath(KNOWCRED_SINGLE, {
                              knowCredId: data?.knowcred?.id,
                            }),
                            text: data?.knowcred?.name || "Cred",
                            newTab: true,
                          }
                        : undefined,
                      text: !data?.knowcred?.id ? "—" : undefined,
                    },
                  ]}
                />
              ) : (
                <p className={"color-black-54"}>
                  This Course is not graded by teacher yet
                </p>
              )}
            </div>
          </div>
          <hr className={"border-divider my-4"} />
          <p className={"mb-4"}>Grades for steps:</p>
          {data?.steps?.map((s, sIdx) => (
            <div className={"pl-2 mb-4 last:mb-0"}>
              <p className={"mb-2"}>
                Step {sIdx + 1} — {s.name}
              </p>
              <div className={"pl-2"}>
                <InfoTable
                  dense
                  data={[
                    {
                      type: "component",
                      label: "Status",
                      component: (
                        <p>
                          <span>{stepStatusTypes[s.type]}</span>
                          {s.type === 3 && !s.grade && (
                            <PlainLink
                              to={generatePath(KNOWMIX_WALKTHROUGH, {
                                knowmixId: record.id,
                                stepId: sIdx,
                              })}
                              newTab
                              inline
                              className={"ml-2"}
                            >
                              <LaunchIcon
                                color={"primary"}
                                style={{ fontSize: "1.25em" }}
                              />
                              <span>Review</span>
                            </PlainLink>
                          )}
                        </p>
                      ),
                    },
                    {
                      label: "Grade",
                      text: s.grade || "—",
                    },
                    !!s.grade && {
                      label: "Comment",
                      text: s.comment || "—",
                    },
                  ]}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default LearnerTrackingCoursesGrades
