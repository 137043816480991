import React, { Component } from "react"
import "./productPhotosSlider.scss"
import "../../../../../assets/libraries/slick/slick.css"
import "../../../../../assets/libraries/slick/slick-nav-theme.css"
import Slider from "react-slick"
import { bindActionCreators } from "redux"
import {
  getProduct,
  updateProduct,
} from "../../../../../library/store/actions/creators/productsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import CameraAltOutlined from "@material-ui/icons/CameraAltOutlined"
import logoDefault from "../../../../../assets/img/product.svg"
import ImagePickerModal from "../../../../../components/imagePickerModal/imagePickerModal"
import { imageAspectRatios } from "../../../../../library/constants/images"

class ProductPhotosSlider extends Component {
  state = {
    logo: this.props.products.currentProduct.image,
    openAvatarUpdate: false,
    uploadProgress: 0,
    isUploading: false,
    dataReceived: false,
    photos: [
      this.props.products.currentProduct.image || logoDefault,
      ...this.props.products.currentProduct.photos,
    ],
    nav1: null,
    nav2: null,
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    })
  }

  handleClickOpen = () => {
    this.setState({ openAvatarUpdate: true })
  }

  handleClose = () => {
    this.setState({ openAvatarUpdate: false })
  }

  updateImage = async (img) => {
    this.setState((prevState) => {
      let photos = prevState.photos
      photos[0] = img.cropped_image || logoDefault

      return {
        photos,
        logo: img.cropped_image || logoDefault,
        isUploading: false,
        uploadProgress: 0,
      }
    })
    await this.props.updateProduct({
      id: parseInt(this.props.products.currentProduct.id),
      full_image: img,
    })
  }

  render() {
    const { logo, openAvatarUpdate, photos, nav1 } = this.state
    const { can_edit } = this.props.products.currentProduct

    const imageContainerClasses =
      photos.length === 2
        ? "product-photos-nav__item product-photos-nav__item--fluid"
        : "product-photos-nav__item"
    const imageClasses =
      photos.length === 2
        ? "product-photos-nav__img product-photos-nav__img--fluid"
        : "product-photos-nav__img"
    return (
      <div className="product-photos-slider">
        {photos.length === 1 ? (
          <div className="product-logo">
            <img src={photos[0]} alt="Logo" className="product-logo__img" />
            {can_edit &&
              !this.props.products.currentProduct.deleted &&
              !this.props.products.currentProduct.blocked && (
                <div className="btn-update-bg" onClick={this.handleClickOpen}>
                  <CameraAltOutlined />
                  <span className="btn-update-bg__text">Edit photo</span>
                </div>
              )}
          </div>
        ) : (
          <>
            <Slider
              arrows={false}
              ref={(slider) => (this.slider1 = slider)}
              swipe={false}
            >
              {photos.map((item, i) => (
                <div key={item}>
                  <div className="product-logo no-outline">
                    {i === 0 && (
                      <>
                        <img
                          src={item || logoDefault}
                          alt="Logo"
                          className="product-logo__img"
                        />
                        {can_edit &&
                          !this.props.products.currentProduct.deleted &&
                          !this.props.products.currentProduct.blocked && (
                            <div
                              className="btn-update-bg"
                              onClick={this.handleClickOpen}
                            >
                              <CameraAltOutlined />
                              <span className="btn-update-bg__text">
                                Edit photo
                              </span>
                            </div>
                          )}
                      </>
                    )}

                    {i > 0 && (
                      <img
                        src={item.url || item}
                        className="product-logo__img"
                        alt={`Photo ${i + 1}`}
                      />
                    )}
                  </div>
                </div>
              ))}
            </Slider>
            {photos.length > 0 && (
              <div className="product-photos-nav">
                <Slider
                  asNavFor={nav1}
                  ref={(slider) => (this.slider2 = slider)}
                  slidesToShow={photos.length > 3 ? 3 : photos.length}
                  focusOnSelect={true}
                  dots={false}
                  swipe={false}
                >
                  {photos.map((item) => (
                    <div className="no-outline" key={item}>
                      <div className={imageContainerClasses}>
                        <img
                          src={item.url || item}
                          className={imageClasses}
                          alt="Additional photo"
                        />
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            )}
          </>
        )}
        {openAvatarUpdate && (
          <ImagePickerModal
            // dimensions={{width: 50, x: 20, y: 20}}
            originImage={logo || logoDefault}
            defaultImage={logoDefault}
            open={openAvatarUpdate}
            onSave={this.updateImage}
            onClose={this.handleClose}
            aspectRatio={imageAspectRatios.square}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ auth, products }) => ({ auth, products })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateProduct, getProduct }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ProductPhotosSlider))
