import Card from "./card"
import { weekDays } from "../../../../library/utils/timeConvert"
import { getLocalePeriod } from "../../../../library/utils/timeString/timeString"
import { getCardSize } from "../../../../library/constants/styles/cardSizes"

const HoldCard = ({ hold, openHoldDetails, openDeleteHold, openEditHold }) => {
  const dropdownMenuItems = [
    {
      type: "button",
      action: openEditHold,
      label: "Edit hold",
    },
    {
      type: "h-divider",
      id: "hd1",
    },
    {
      type: "button",
      action: openDeleteHold,
      label: "Delete hold",
    },
  ]

  const renderedDays = hold.days_of_week.map((d) => weekDays[d]).join(", ")

  const holdInfo = () => (
    <>
      {!!hold.days_of_week && <p>Days: {renderedDays}</p>}

      <p>
        Time:{" "}
        {!hold.is_all_day
          ? getLocalePeriod(hold.time_from, hold.time_to)
          : "All day"}
      </p>
    </>
  )

  return (
    <Card
      size={getCardSize(7).numValPx}
      name={hold.name}
      onNameClick={openHoldDetails}
      infoComponent={holdInfo}
      controls={{
        dropdownMenu: {
          items: dropdownMenuItems,
        },
      }}
    />
  )
}

export default HoldCard
