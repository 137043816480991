import React from "react"
import BoxPage from "../../../../../components/v2/layout/boxPage/boxPage"
import {
  useCourse,
  useCourseMutations,
} from "../../../../../hooks/data/courses/courses"
import Button from "@material-ui/core/Button/Button"

const CourseSettingsRelease = () => {
  const { course, queryKey } = useCourse()
  const { releaseCourse } = useCourseMutations({ queryKey })

  return (
    <BoxPage title={"Release"}>
      {!course?.is_finished ? (
        <div className={"flex flex-col items-center w-full"}>
          <p className={"mb-4 text-center"}>
            Click Finish to release this course to your Members including the
            Teacher. Finishing this course means you can edit it later on its
            dedicated page under your courses in your Group.
          </p>
          <Button
            color="primary"
            variant="contained"
            onClick={() => releaseCourse.mutate({ id: course.id })}
            disabled={!course?.steps?.length}
          >
            RELEASE
          </Button>
          {!course?.steps?.length && (
            <p className={"text-black38 mt-1 text-center"}>
              Course must have at least one step created
            </p>
          )}
        </div>
      ) : (
        <div className={"flex flex-col items-center"}>
          <p className="mb-4 text-center">This course is already released.</p>

          <Button
            color="primary"
            variant="contained"
            onClick={() => releaseCourse.mutate({ id: course.id })}
            // disabled={!props.knowmix.stepsList.length}
          >
            UNRELEASE
          </Button>
        </div>
      )}
    </BoxPage>
  )
}

export default CourseSettingsRelease
