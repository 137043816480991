import React, { Component } from "react"
import "./moreDropdownBtn.scss"
import MoreHoriz from "@material-ui/icons/MoreHoriz"
import Button from "@material-ui/core/Button"
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener"

class MoreDropdownBtn extends Component {
  state = {
    active: false,
  }

  toggleDropdown = () => {
    const active = this.state.active
    this.setState({ active: !active })
  }

  handleClickOutside = (event) => {
    this.setState({ active: false })
  }

  render() {
    const { active } = this.state
    const dropdownDisplay = active ? "more-dropdown-menu--active" : ""

    return (
      <ClickAwayListener onClickAway={this.handleClickOutside}>
        <div className={`more-dropdown-menu ${dropdownDisplay}`}>
          <Button
            variant="outlined"
            color="primary"
            onClick={this.toggleDropdown}
            fullWidth
            className="more-dropdown-menu__trigger"
          >
            <MoreHoriz />
          </Button>
          {this.props.children}
        </div>
      </ClickAwayListener>
    )
  }
}

export default MoreDropdownBtn;
