import React from "react"
import * as yup from "yup"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import SchoolCalendarPicker from "../../../../components/v2/inputs/pickers/schoolCalendarPicker"
import SubjectPicker from "../../../../components/v2/inputs/pickers/subjectPicker"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import Dialog from "@material-ui/core/Dialog/Dialog"
import { useAlert } from "../../../../hooks/useAlert"
import { useIdParam } from "../../../../hooks/useIdParam"
import { KNOWMIX } from "../../../../library/store/actions/types/knowmixTypes"
import { call } from "../../../../library/networking/API"
import Form from "../../../../components/v2/layout/forms/form/form"
import { Formik } from "formik"
import { generatePath, useHistory } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"

const validationSchema = yup.object({
  name: yup.string().required().min(3).max(200),
})

const CourseCreationFormModal = ({ isOpen, onClose }) => {
  const groupId = useIdParam("groupId")
  const history = useHistory()
  const { errorAlert } = useAlert()

  const initialValues = {
    name: "",
  }
  const [otherValues, setOtherValues] = React.useState({
    subject: "",
    schedule: "",
  })
  const [errors, setErrors] = React.useState({
    subject: false,
    schedule: false,
  })

  const handleChangeRequired = (name) => (e) => {
    setOtherValues((prev) => ({
      ...prev,
      [name]: e.target.value,
    }))
    setErrors((prev) => ({
      ...prev,
      [name]: !e.target.value,
    }))
  }

  const onSubmit = async (values) => {
    if (!otherValues.subject || !otherValues.schedule) {
      setErrors({
        subject: !otherValues.subject,
        schedule: !otherValues.schedule,
      })
      return
    }

    try {
      const res = await call(KNOWMIX.CREATE, {
        name: values.name,
        subject_id: otherValues.subject.id,
        schedule_id: otherValues.schedule.id,
        group_id: groupId,
      })

      const knowmixId = res.data.id
      history.push(generatePath(routes.KNOWMIX, { knowmixId }))
    } catch (e) {
      errorAlert(e)
    }
  }
  return (
    <Dialog
      aria-labelledby="create-knowmix-dialog"
      onClose={onClose}
      open={isOpen}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form isModal>
          <DialogTitle id="alert-dialog-title" className="text-center">
            New course
          </DialogTitle>
          <DialogContent>
            <Form.Row label={"Course name"} name={"name"}>
              <Form.TextField name={"name"} placeholder={"Type course name"} />
            </Form.Row>
            <SchoolCalendarPicker
              groupId={groupId}
              value={otherValues.schedule}
              hasError={errors.schedule}
              onChange={handleChangeRequired("schedule")}
            />
            <SubjectPicker
              value={otherValues.subject}
              required
              hasError={errors.subject}
              onChange={handleChangeRequired("subject")}
              calendarId={
                otherValues.schedule?.has_district_calendar
                  ? otherValues.schedule?.calendar_id
                  : undefined
              }
              scheduleId={
                !otherValues.schedule?.has_district_calendar
                  ? otherValues.schedule?.id
                  : undefined
              }
            />
          </DialogContent>
          <DialogActions style={{ alignSelf: "flex-end" }}>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  )
}

export default CourseCreationFormModal
