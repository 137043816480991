import React, { Component } from "react"
import Select from "@material-ui/core/Select/Select"
import { getObjectById } from "../../../library/utils/arrays"
import { standardsGrades as standardsGradesList } from "../../../library/constants/educationalDictionary"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import FormControl from "@material-ui/core/FormControl/FormControl"
import MultiSelect from "../../ui/multiSelect"
import Loader from "../../ui/loader"
import { call } from "../../../library/networking/API"
import { STANDARDS } from "../../../library/store/actions/types/standardsTypes"
import getErrorText from "../../../library/constants/errorTypes"
import { withSnackbar } from "notistack"
import { statesAndThames } from "../../../library/constants/statesAndThames"

class StandardsPicker extends Component {
  state = {
    standards: [],
    chosenStandards: [],
    standardsName: "",
    standardsReceived: false,
    dataReceived: false,
    chosenStandardSubjects: [],
    standardsGrades: [],
    chosenState: "",
  }

  async componentDidMount() {
    try {
      await this.getStandards({})
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  onScroll = async () => {
    if (!this.state.standardsReceived) return

    const {
      standardsName,
      standardsGrades,
      chosenState,
      chosenStandardSubjects,
      standards,
    } = this.state

    await this.getStandards({
      standardsName,
      grades: standardsGrades.length ? standardsGrades : undefined,
      chosenState: chosenState || undefined,
      chosenStandardSubjects,
      offset: standards.length,
    })
  }

  setStandardsName = (standardsName) => {
    this.setState(
      { standardsName, standardsReceived: false, standards: [] },
      this.searchStandards
    )
  }

  setChosenStandards = (chosenStandards) => {
    this.setState({ chosenStandards }, () =>
      this.props.setStandards(chosenStandards.map((item) => item.value))
    )
  }

  searchStandards = async () => {
    const {
      standardsName,
      standardsGrades,
      chosenState,
      chosenStandardSubjects,
    } = this.state

    await this.getStandards({
      standardsName,
      grades: standardsGrades.length ? standardsGrades : undefined,
      chosenState: chosenState || undefined,
      chosenStandardSubjects,
    })
  }

  getStandards = async ({
    standardsName,
    grades = [],
    chosenState,
    chosenStandardSubjects,
    limit = 20,
    offset = 0,
  }) => {
    this.setState({ standardsReceived: false })

    const { standards } = this.state

    try {
      const response = await call(STANDARDS.SEARCH_LIST, {
        name: standardsName || undefined,
        grades: grades.length ? grades : undefined,
        limit,
        offset,
        state: chosenState,
        subjects:
          chosenStandardSubjects && chosenStandardSubjects.length
            ? chosenStandardSubjects
            : undefined,
      })

      this.setState({
        standardsReceived: true,
        standards: [...standards, ...response.data.standards],
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  handleChange = (name) => (event) => {
    this.setState(
      {
        [name]: event.target.value,
      },
      async () => {
        this.setState(
          {
            standards: [],
            chosenStandardSubjects:
              name === "chosenState" ? [] : this.state.chosenStandardSubjects,
          },
          this.searchStandards
        )
      }
    )
  }

  render() {
    const {
      standards,
      standardsGrades,
      chosenStandards,
      chosenState,
      chosenStandardSubjects,
    } = this.state

    const states = statesAndThames
    const standardSubjects = states
      .filter((s) => {
        return s.id === chosenState
      })
      .map((s) => s.subjects)
      .flat()

    return (
      <>
        <div className="form__fieldset">
          <label htmlFor="grade" className="form__label">
            Standards grades{" "}
          </label>
          <div className="form__fieldbox">
            <div className="form__input form__input--select">
              <Select
                id="standardsGrades"
                name="standardsGrades"
                fullWidth
                multiple
                displayEmpty
                value={standardsGrades}
                onChange={this.handleChange("standardsGrades")}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <span className="color-black-38">
                        Select grades for standards
                      </span>
                    )
                  }

                  let selectedNames = selected.map((item) => {
                    return getObjectById(standardsGradesList, item).name
                  })

                  return selectedNames.join(", ")
                }}
              >
                {standardsGradesList.map((item) => (
                  <MenuItem
                    value={item.id}
                    style={{
                      maxWidth: 330,
                      display: "block",
                      textOverflow: "ellipsis",
                    }}
                    key={item.id}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </div>

        <div className="form__fieldset">
          <label htmlFor="contentType" className="form__label">
            Standards state{" "}
          </label>
          <div className="form__fieldbox">
            <div className="form__input form__input--select">
              <FormControl fullWidth>
                <Select
                  id="state"
                  name="state"
                  fullWidth
                  displayEmpty
                  value={chosenState}
                  onChange={this.handleChange("chosenState")}
                  renderValue={(value) => {
                    if (!value || value.length === 0) {
                      return (
                        <span className="color-black-38">
                          Select state for standards
                        </span>
                      )
                    }

                    return getObjectById(states, value).name
                  }}
                >
                  {states.map((item) => (
                    <MenuItem
                      value={item.id}
                      style={{
                        maxWidth: 330,
                        display: "block",
                        textOverflow: "ellipsis",
                      }}
                      key={item.id}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        {chosenState && (
          <div className="form__fieldset">
            <label htmlFor="grade" className="form__label">
              Standards subjects{" "}
            </label>
            <div className="form__fieldbox">
              <div className="form__input form__input--select">
                <Select
                  id="standardsSubjects"
                  name="standardsSubjects"
                  fullWidth
                  multiple
                  displayEmpty
                  value={chosenStandardSubjects}
                  onChange={this.handleChange("chosenStandardSubjects")}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return (
                        <span className="color-black-38">
                          Select subjects for standards
                        </span>
                      )
                    }

                    let selectedNames = selected.map((item) => {
                      return getObjectById(standardSubjects, item).name
                    })

                    return selectedNames.join(", ")
                  }}
                >
                  {standardSubjects.map((item) => (
                    <MenuItem
                      value={item.id}
                      style={{
                        maxWidth: 330,
                        display: "block",
                        textOverflow: "ellipsis",
                      }}
                      key={item.id}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        )}

        {(!!chosenStandards.length ||
          (chosenState && !!chosenStandardSubjects.length)) && (
          <div className="form__fieldset">
            <label className="form__label">Standards</label>
            <div className="form__fieldbox">
              <div className="form__input form__input--select">
                <MultiSelect
                  isMulti
                  list={standards}
                  defaultValue={
                    this.props.edit
                      ? this.props.invenstory.currentRecord.standards.map(
                          (s) => ({
                            value: s.id,
                            label: s.name,
                          })
                        )
                      : []
                  }
                  placeholder="Select standards"
                  onScroll={this.onScroll}
                  onChange={this.setChosenStandards}
                  onInputChange={this.setStandardsName}
                  emptyArrayMessage={
                    this.state.standardsReceived ? (
                      <span>No results</span>
                    ) : (
                      <Loader />
                    )
                  }
                />
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

export default withSnackbar(StandardsPicker)
