import React from "react"
import {
  generatePath,
  Link,
  Route,
  Switch,
  useParams,
  withRouter,
} from "react-router-dom"
import * as routes from "../../../../../../../../../library/constants/routes"
import { bindActionCreators } from "redux"
import { connect, useSelector } from "react-redux"
import { withSnackbar } from "notistack"
import GroupSettingsCalendarGradeMembers from "../groupSettingsCalendarGradeMembers/groupSettingsCalendarGradeMembers"
import GroupSettingsCalendarGradeInvites from "../groupSettingsCalendarGradeInvites/groupSettingsCalendarGradeInvites"
import {
  clearCurrentGroup,
  getGroup,
} from "../../../../../../../../../library/store/actions/creators/groupsCreators"
import VerticalMenu from "../../../../../../../../../components/ui/verticalMenu"
import withGroupSettingsGuard from "../../../../../../../../../hocs/guards/withGroupSettingsGuard"
import { getGroupLogo } from "../../../../../../../../../library/utils/getGroupLogo"
import Box from "../../../../../../../../../components/ui/box/box"
import { calendarGrades } from "../../../../../../../../../library/constants/educationalDictionary"
import SEO from "../../../../../../../../../components/SEO"

const GroupSettingsCalendarGrade = () => {
  const params = useParams()
  const { currentGroup, currentCalendar } = useSelector(
    ({ groups, calendar }) => ({
      currentGroup: groups.currentGroup,
      currentCalendar: groups.currentCalendar,
    })
  )

  const menuItems = [
    {
      path: generatePath(routes.GROUP_SETTINGS_CALENDAR_GRADE, params),
      type: "parent",
      label: "Members",
    },
    {
      path: generatePath(routes.GROUP_SETTINGS_CALENDAR_GRADE_INVITES, params),
      type: "parent",
      label: "Add members",
    },
  ]

  return (
    <>
      <div className="common-page__content">
        <SEO title={"Grade | Group Settings"} />
        <main className="common-page__main">
          <Switch>
            <Route
              exact
              path={routes.GROUP_SETTINGS_CALENDAR_GRADE}
              component={GroupSettingsCalendarGradeMembers}
            />
            <Route
              exact
              path={routes.GROUP_SETTINGS_CALENDAR_GRADE_INVITES}
              component={GroupSettingsCalendarGradeInvites}
            />
          </Switch>
        </main>
        <aside className="common-page__sidebar">
          <div className="box aside-header">
            <Link
              to={generatePath(routes.GROUP_SETTINGS_CALENDAR_GRADES, params)}
              className="box__content aside-header__content"
            >
              <img
                src={getGroupLogo(currentGroup)}
                className="aside-header__img"
                alt=""
              />
              <div className="aside-header__info">
                <div className="aside-header__name">{currentGroup?.name}</div>
                <div className="aside-header__help">back to grades</div>
              </div>
            </Link>
          </div>
          <Box>
            <div>
              <p className="color-black-54">Grade:</p>
              <p>
                {
                  calendarGrades.find(
                    (grade) => grade.id === parseInt(params.gradeId)
                  ).name
                }
              </p>
            </div>
          </Box>
          <VerticalMenu menuItems={menuItems} />
        </aside>
      </div>
    </>
  )
}

const mapStateToProps = ({ auth, groups, calendar }) => ({
  auth,
  groups,
  calendar,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getGroup,
      clearCurrentGroup,
    },
    dispatch
  )

export default withGroupSettingsGuard(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withSnackbar(withRouter(GroupSettingsCalendarGrade)))
)
