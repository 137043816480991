import React from "react"
import * as routes from "../../../../library/constants/routes"
import { generatePath, Link } from "react-router-dom"
import avatarDefault from "../../../../assets/img/user_avatar.svg"
import postDateConvert from "../../../../library/utils/postDateConvert"
import VerticalMenu from "../../../../components/ui/verticalMenu"
import MoreDropdown from "../../../../components/ui/dropdowns/moreDropdown/moreDropdown"
import { getGroupLogo } from "../../../../library/utils/getGroupLogo"
import { useSelector } from "react-redux"
import logoDefault from "../../../../assets/img/product.svg"
import { decrementUnreadCount } from "../../../../library/store/actions/creators/notificationCreators"
import useCreator from "../../../../hooks/useCreator"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { call } from "../../../../library/networking/API"
import { NOTIFICATION } from "../../../../library/store/actions/types/notificationTypes"
import { useAlert } from "../../../../hooks/useAlert"
import { mutateInfinite } from "../../../../library/utils/reactQuery"
import { getCardSize } from "../../../../library/constants/styles/cardSizes"

const NotificationCard = ({
  notification,
  queryKey,
  index,
  isMutationInfinite = false,
}) => {
  const { errorAlert } = useAlert()
  const creator = useCreator()
  const queryClient = useQueryClient()

  const { unreadCounter } = useSelector(({ notifications }) => notifications)

  const isRead = React.useMemo(() => {
    return index >= unreadCounter
  }, [index, unreadCounter])

  const onCounterDecrement = () => creator(decrementUnreadCount(1))

  const onDelete = useMutation(
    async () => {
      try {
        await call(NOTIFICATION.DELETE_NOTIFICATION, { id: notification.id })
      } catch (e) {
        errorAlert(e)
      }
    },
    {
      onSuccess: async () => {
        if (isMutationInfinite) {
          await mutateInfinite({
            queryClient,
            key: queryKey,
            itemUpdater: (n) => {
              return n.id !== notification.id ? n : null
            },
          })
        }

        queryClient.invalidateQueries(queryKey)
      },
    }
  )

  const removeNotification = async () => {
    await onDelete.mutateAsync({ id: notification.id })

    if (!isRead) onCounterDecrement()
  }

  const { userData } = useSelector(({ auth }) => auth)

  const getNotificationLayout = (notification) => {
    if (!notification) return {}

    const name = notification.user
      ? `${notification.user.first_name} ${notification.user.last_name}`
      : ""
    const shownName = name.length > 50 ? name.slice(0, 50) + "..." : name
    const groupName = notification.group ? notification.group.name : ""
    const shownGroupName =
      groupName.length > 50 ? groupName.slice(0, 50) + "..." : groupName
    const relatedGroupName = notification.related_group
      ? notification.related_group.name
      : ""
    const shownRelatedGroupName =
      relatedGroupName.length > 50
        ? relatedGroupName.slice(0, 50) + "..."
        : relatedGroupName
    const productName = notification.product ? notification.product.name : ""
    const shownProductName =
      productName.length > 50 ? productName.slice(0, 50) + "..." : productName
    const knowmixName = notification.knowmix ? notification.knowmix.name : ""
    const shownKnowmixName =
      knowmixName.length > 50 ? knowmixName.slice(0, 50) + "..." : knowmixName
    const source = notification.source_user
      ? generatePath(routes.USER_POST, {
          userId: notification.source_user.id,
          postId: notification.post.id,
        })
      : notification.source_group
        ? generatePath(routes.GROUP_POST, {
            groupId: notification.source_group.id,
            postId: notification.post.id,
          })
        : "/"
    switch (notification.type) {
      case 1:
        return {
          avatar: notification.user.photo || avatarDefault,
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.USER, { userId: notification.user.id })}
              >
                {shownName}
              </Link>
              <span className="color-black"> sent you a friend request</span>
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 2:
        return {
          avatar: notification.user.photo || avatarDefault,
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.USER, { userId: notification.user.id })}
              >
                {shownName}
              </Link>
              <span className="color-black"> accepted your friend request</span>
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 3:
        return {
          avatar: notification.user.photo || avatarDefault,
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.USER, { userId: notification.user.id })}
              >
                {shownName}
              </Link>{" "}
              commented your{" "}
              <Link className="link link--inline" to={source}>
                post
              </Link>
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 4:
        return {
          avatar: notification.user.photo || avatarDefault,
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.USER, { userId: notification.user.id })}
              >
                {shownName}
              </Link>{" "}
              liked your{" "}
              <Link className="link link--inline" to={source}>
                post
              </Link>
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 5:
        return {
          avatar: notification.user.photo || avatarDefault,
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.USER, { userId: notification.user.id })}
              >
                {shownName}
              </Link>{" "}
              shared your{" "}
              <Link
                className="link link--inline"
                to={generatePath(routes.USER_POST, {
                  userId: notification.user.id,
                  postId: notification.post.id,
                })}
              >
                post
              </Link>
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 6:
        return {
          avatar: notification.user.photo || avatarDefault,
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.USER, { userId: notification.user.id })}
              >
                {shownName}
              </Link>{" "}
              added{" "}
              <Link
                className="link link--inline"
                to={generatePath(routes.USER_POST, {
                  userId: userData.id,
                  postId: notification.post.id,
                })}
              >
                post
              </Link>{" "}
              on your wall
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 7:
        return {
          avatar: notification.user.photo || avatarDefault,
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.USER, { userId: notification.user.id })}
              >
                {shownName}
              </Link>{" "}
              replied to your comment in{" "}
              <Link className="link link--inline" to={source}>
                post
              </Link>
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 8:
        return {
          avatar: notification.user.photo || avatarDefault,
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.USER, { userId: notification.user.id })}
              >
                {shownName}
              </Link>{" "}
              liked your comment in{" "}
              <Link className="link link--inline" to={source}>
                post
              </Link>
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 9:
        return {
          avatar: getGroupLogo(notification.group),
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.USER, { userId: notification.user.id })}
              >
                {shownName}
              </Link>{" "}
              sent a request to{" "}
              <Link
                className="link link--inline"
                to={generatePath(routes.GROUP_SETTINGS_REQUESTS, {
                  groupId: notification.group.id,
                })}
              >
                {shownGroupName}
              </Link>
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 10:
        return {
          avatar: getGroupLogo(notification.group),
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.GROUP, {
                  groupId: notification.group.id,
                })}
              >
                {shownGroupName}
              </Link>{" "}
              accepted your request to join group
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 11:
        return {
          avatar: getGroupLogo(notification.group),
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.USER, { userId: notification.user.id })}
              >
                {shownName}
              </Link>{" "}
              added{" "}
              <Link
                className="link link--inline"
                to={generatePath(routes.GROUP_POST, {
                  groupId: notification.group.id,
                  postId: notification.post.id,
                })}
              >
                post
              </Link>{" "}
              on{" "}
              <Link
                className="link link--inline"
                to={generatePath(routes.GROUP, {
                  groupId: notification.group.id,
                })}
              >
                {shownGroupName}
              </Link>{" "}
              wall
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 12:
        return {
          avatar: notification.user.photo || avatarDefault,
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.USER, { userId: notification.user.id })}
              >
                {shownName}
              </Link>{" "}
              has invited you to join a group{" "}
              <Link
                className="link link--inline"
                to={generatePath(routes.GROUP, {
                  groupId: notification.group.id,
                })}
              >
                {shownGroupName}
              </Link>
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 13:
        return {
          avatar: getGroupLogo(notification.related_group),
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.GROUP, {
                  groupId: notification.related_group.id,
                })}
              >
                {shownRelatedGroupName}
              </Link>{" "}
              marked
              <Link
                className="link link--inline"
                to={generatePath(routes.GROUP, {
                  groupId: notification.group.id,
                })}
              >
                {" "}
                {shownGroupName}
              </Link>{" "}
              as related
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 14:
        return {
          avatar: notification.user.photo || avatarDefault,
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.USER, { userId: notification.user.id })}
              >
                {shownName}
              </Link>{" "}
              created
              <Link
                className="link link--inline"
                to={generatePath(routes.KNOWCRED_SINGLE, {
                  userId: notification.user.id,
                  knowCredId: notification.knowcred.id,
                })}
              >
                {" "}
                Cred
              </Link>{" "}
              for you
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 15:
        return {
          avatar: notification.user.photo || avatarDefault,
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.USER, { userId: notification.user.id })}
              >
                {shownName}
              </Link>{" "}
              liked your
              <Link
                className="link link--inline"
                to={generatePath(routes.KNOWCRED_SINGLE, {
                  userId: notification.user.id,
                  knowCredId: notification.knowcred.id,
                })}
              >
                {" "}
                Cred
              </Link>
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 16:
        return {
          avatar: notification.user.photo || avatarDefault,
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.USER, { userId: notification.user.id })}
              >
                {shownName}
              </Link>{" "}
              added your
              <Link
                className="link link--inline"
                to={generatePath(routes.PRODUCT, {
                  productId: notification.product.id,
                })}
              >
                {" "}
                {shownProductName}
              </Link>{" "}
              on purchases
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 17:
        return {
          avatar: getGroupLogo(notification.group),
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.GROUP, {
                  groupId: notification.group.id,
                })}
              >
                {shownGroupName}
              </Link>{" "}
              added your
              <Link
                className="link link--inline"
                to={generatePath(routes.PRODUCT, {
                  productId: notification.product.id,
                })}
              >
                {" "}
                {shownProductName}
              </Link>{" "}
              on
              <Link
                className="link link--inline"
                to={generatePath(routes.GROUP, {
                  groupId: notification.group.id,
                })}
              >
                {" "}
                {shownGroupName}
              </Link>{" "}
              page
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 18:
        return {
          avatar: notification.user.photo || avatarDefault,
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.USER, { userId: notification.user.id })}
              >
                {shownName}
              </Link>{" "}
              commented your
              <Link
                className="link link--inline"
                to={generatePath(routes.PRODUCT, {
                  productId: notification.product.id,
                })}
              >
                {" "}
                {shownProductName}
              </Link>
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 19:
        if (!notification.user) return {}
        return {
          avatar: notification.user.photo || avatarDefault,
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.USER, { userId: notification.user.id })}
              >
                {shownName}
              </Link>{" "}
              open access to
              <Link
                className="link link--inline"
                to={generatePath(routes.PRODUCT, {
                  productId: notification.product.id,
                })}
              >
                {" "}
                {shownProductName}
              </Link>
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 20:
        return {
          avatar: notification.user.photo || avatarDefault,
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.USER, { userId: notification.user.id })}
              >
                {shownName}
              </Link>{" "}
              shared your
              <Link
                className="link link--inline"
                to={generatePath(routes.USER_POST, {
                  userId: notification.user.id,
                  postId: notification.post.id,
                })}
              >
                {" "}
                Cred
              </Link>
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 21:
        return {
          avatar: getGroupLogo(notification.group),
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.USER, { userId: notification.user.id })}
              >
                {shownName}
              </Link>{" "}
              change your role in
              <Link
                className="link link--inline"
                to={generatePath(routes.GROUP, {
                  groupId: notification.group.id,
                })}
              >
                {" "}
                {shownGroupName}
              </Link>
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 22:
        return {
          avatar: notification.user.photo || avatarDefault,
          message: (
            <>
              It`s
              <Link
                className="link link--inline"
                to={generatePath(routes.USER, { userId: notification.user.id })}
              >
                {" "}
                {shownName}
              </Link>{" "}
              birthday today.
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 23:
        return {
          avatar: notification.user.photo || avatarDefault,
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.USER, { userId: notification.user.id })}
              >
                {shownName}
              </Link>{" "}
              added
              <Link
                className="link link--inline"
                to={generatePath(routes.PRODUCT, {
                  productId: notification.product.id,
                })}
              >
                {" "}
                {shownProductName}
              </Link>{" "}
              in marketplace
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 24:
        return {
          avatar: getGroupLogo(notification.group),
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.GROUP, {
                  groupId: notification.group.id,
                })}
              >
                {shownGroupName}
              </Link>{" "}
              added
              <Link
                className="link link--inline"
                to={generatePath(routes.GROUP_POST, {
                  groupId: notification.group.id,
                  postId: notification.post.id,
                })}
              >
                {" "}
                post
              </Link>{" "}
              on
              <Link
                className="link link--inline"
                to={generatePath(routes.GROUP, {
                  groupId: notification.group.id,
                })}
              >
                {" "}
                {shownGroupName}
              </Link>{" "}
              wall
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 27:
        if (!notification.knowmix) return {}
        return {
          avatar:
            (notification.knowmix && notification.knowmix.photo) || logoDefault,
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.KNOWMIX, {
                  knowmixId: notification.knowmix.id,
                })}
              >
                {shownKnowmixName}
              </Link>{" "}
              has been added to{" "}
              <Link
                className="link link--inline"
                to={generatePath(routes.KNOWMIX_LIST)}
              >
                your Courses list
              </Link>
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 28:
        return {
          avatar: notification.user.photo || avatarDefault,
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.USER, { userId: notification.user.id })}
              >
                {shownName}
              </Link>{" "}
              added you to examiners to{" "}
              <Link
                className="link link--inline"
                to={generatePath(routes.KNOWMIX, {
                  knowmixId: notification.knowmix.id,
                })}
              >
                Course
              </Link>
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 29:
        return {
          avatar: notification.knowmix.photo || logoDefault,
          message: (
            <>
              Got{" "}
              <Link
                className="link link--inline"
                to={generatePath(routes.KNOWMIX_CHECK, {
                  knowmixId: notification.knowmix.id,
                  checkId: notification.knowmix_work.id,
                })}
              >
                step
              </Link>{" "}
              from{" "}
              <Link
                className="link link--inline"
                to={generatePath(routes.KNOWMIX, {
                  knowmixId: notification.knowmix.id,
                })}
              >
                Course
              </Link>{" "}
              to check
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 30:
        return {
          avatar: notification.knowmix.photo || logoDefault,
          message: (
            <>
              Got{" "}
              <Link
                className="link link--inline"
                to={generatePath(routes.KNOWMIX_GRADES_CHECK, {
                  knowmixId: notification.knowmix.id,
                  learnerId: notification.user.id,
                })}
              >
                Course
              </Link>{" "}
              to check
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 31:
        return {
          avatar: notification.knowmix.photo || logoDefault,
          message: (
            <>
              Got{" "}
              <Link
                className="link link--inline"
                to={generatePath(routes.KNOWMIX_GRADES, {
                  knowmixId: notification.knowmix.id,
                })}
              >
                step
              </Link>{" "}
              grade for{" "}
              <Link
                className="link link--inline"
                to={generatePath(routes.KNOWMIX, {
                  knowmixId: notification.knowmix.id,
                })}
              >
                Course
              </Link>
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 32:
        return {
          avatar: notification.knowmix.photo || logoDefault,
          message: (
            <>
              Got{" "}
              <Link
                className="link link--inline"
                to={generatePath(routes.KNOWMIX_GRADES, {
                  knowmixId: notification.knowmix.id,
                })}
              >
                Course
              </Link>{" "}
              grade
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 33:
        return {
          avatar: getGroupLogo(notification.related_group),
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.GROUP, {
                  groupId: notification.related_group.id,
                })}
              >
                {shownRelatedGroupName}
              </Link>{" "}
              accepted{" "}
              <Link
                className="link link--inline"
                to={generatePath(routes.GROUP, {
                  groupId: notification.group.id,
                })}
              >
                {shownGroupName}
              </Link>{" "}
              request to access to InvenStory
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 34:
        return {
          avatar: getGroupLogo(notification.group),
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.USER, { userId: notification.user.id })}
              >
                {shownName}
              </Link>{" "}
              accepted{" "}
              <Link
                className="link link--inline"
                to={generatePath(routes.GROUP, {
                  groupId: notification.group.id,
                })}
              >
                {shownGroupName}
              </Link>{" "}
              request to access to InvenStory
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 35:
        return {
          avatar: getGroupLogo(notification.group),
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.GROUP, {
                  groupId: notification.group.id,
                })}
              >
                {shownGroupName}
              </Link>{" "}
              sent request to access to{" "}
              <Link
                className="link link--inline"
                to={generatePath(routes.GROUP, {
                  groupId: notification.related_group.id,
                })}
              >
                {shownRelatedGroupName}
              </Link>{" "}
              InvenStory
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 36:
        return {
          avatar: getGroupLogo(notification.group),
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.GROUP, {
                  groupId: notification.group.id,
                })}
              >
                {shownGroupName}
              </Link>{" "}
              sent request to access to your InvenStory
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 37:
        return {}
      case 38:
        return {
          avatar: getGroupLogo(notification.group),
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.GROUP, {
                  groupId: notification.group.id,
                })}
              >
                {shownGroupName}
              </Link>{" "}
              created{" "}
              <Link
                className="link link--inline"
                to={generatePath(routes.PRODUCT, {
                  productId: notification.product.id,
                })}
              >
                product
              </Link>
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 39:
        return {
          avatar: getGroupLogo(notification.group),
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.USER, { userId: notification.user.id })}
              >
                {shownName}
              </Link>{" "}
              joined{" "}
              <Link
                className="link link--inline"
                to={generatePath(routes.GROUP, {
                  groupId: notification.group.id,
                })}
              >
                {shownGroupName}
              </Link>
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 40:
        return {
          avatar: notification.user.photo || avatarDefault,
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.USER, { userId: notification.user.id })}
              >
                {shownName}
              </Link>{" "}
              is a new user on the site
            </>
          ),
          date: postDateConvert(notification.created),
        }
      case 26:
        return {
          avatar: notification.user.photo || avatarDefault,
          message: (
            <>
              <Link
                className="link link--inline"
                to={generatePath(routes.USER, { userId: notification.user.id })}
              >
                {shownName}
              </Link>{" "}
              would like to purchase{" "}
              <Link
                className="link link--inline"
                to={generatePath(routes.PRODUCT, {
                  productId: notification.product.id,
                })}
              >
                {notification.product.name}
              </Link>
            </>
          ),
          date: postDateConvert(notification.created),
        }
      default:
        return {}
    }
  }

  const { avatar, message, date } = getNotificationLayout(notification)
  return (
    <div
      style={{
        gridTemplateColumns: "max-content 1fr min-content",
        backgroundColor: isRead ? "#fff" : "#fafafa",
        height: getCardSize(6).numValPx,
      }}
      className="list-item"
    >
      <div
        className="list-item__img list-item__img"
        style={{
          width: "63px",
          height: "63px",
          minWidth: "63px",
        }}
      >
        <img src={avatar} alt="User" />
      </div>
      <div className="list-item__info">
        <div>
          <span className="">{message}</span>
          <br />
          <span className="color-black-54">{date}</span>
        </div>
      </div>
      <div className={`list-item__control-panel`}>
        <MoreDropdown>
          <VerticalMenu
            menuItems={[
              {
                type: "button",
                action: removeNotification,
                label: "Delete notification",
              },
            ]}
            classes={"more-dropdown-menu__list"}
          />
        </MoreDropdown>
      </div>
    </div>
  )
}

export default NotificationCard
