import React, { Component } from "react"
import List from "../../../../../../../components/ui/list/list"
import { generatePath, Link } from "react-router-dom"
import {
  groupMemberTagsIds,
  groupRolesIds,
  userRolesIds,
} from "../../../../../../../library/constants/userRoles"
import MoreDropdown from "../../../../../../../components/ui/dropdowns/moreDropdown/moreDropdown"
import VerticalMenu from "../../../../../../../components/ui/verticalMenu"
import userAvatarDefault from "../../../../../../../assets/img/user_avatar.svg"
import unavailableAvatar from "../../../../../../../assets/img/user_unavailable.svg"
import { bindActionCreators } from "redux"
import {
  changeGroupMemberTags,
  clearGroupsList,
  getGroupMembersList,
  getMembersInvitesList,
  groupChangeRole,
  groupInviteCancel,
  groupInviteCreate,
  groupInvitesSearch,
  groupInvitesSearchClear,
  groupMemberRemove,
  groupRequestsAccept,
  groupRequestsDecline,
  groupRequestsList,
} from "../../../../../../../library/store/actions/creators/groupsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import * as routes from "../../../../../../../library/constants/routes"
import Loader from "../../../../../../../components/ui/loader"
import MemberRoleChange from "../memberRoleChange/memberRoleChange"
import Button from "@material-ui/core/es/Button/Button"
import MenuList from "@material-ui/core/MenuList/MenuList"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import DropdownControl from "../../../../../../../components/ui/dropdowns/dropdownControl/dropdownControl"
import * as qs from "query-string"
import ListSearchHeader from "../../../../../../../components/ui/listSearchHeader/listSearchHeader"
import { withStyles } from "@material-ui/core"
import { colors } from "../../../../../../../library/constants/styles/colors"
import withScroll from "../../../../../../../hocs/withScroll/withScroll"
import { LIMIT } from "../../../../../../../library/constants/limits"
import LinesEllipsis from "react-lines-ellipsis"
import getErrorText from "../../../../../../../library/constants/errorTypes"
import {
  composeUserHighlightedEducation,
  composeUserHighlightedWork,
} from "../../../../../../../library/utils/getUserHighlightedInfo"
import { getUserLocationString } from "../../../../../../../library/utils/getUserLocationString"
import MemberTagsModal from "../groupSettingsMembersTags/memberTagsModal/memberTagsModal"
import { renderArrayOfNames } from "../../../../../../../library/utils/arrays"
import UsersRosterModal from "./usersRosterModal"
import InviteParticipantByEmailModal from "./InviteByEmailModal"

const CustomMenuItem = withStyles({
  root: {
    color: colors.primary,
  },
})(MenuItem)

class GroupSettingsMembersList extends Component {
  state = {
    list: [],
    type: null,
    initialRender: true,
    showUploadRosterDialog: false,
    showInviteByEmailDialog: false,
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }

  async componentDidMount() {
    const { search } = this.props.location
    const { q: name } = qs.parse(search)

    await this.getList({ name: name || undefined })
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const { search } = this.props.location
    const { q: name } = qs.parse(search)

    if (prevProps.match.path !== this.props.match.path) {
      await this.props.clearGroupsList()
      this.setState({ list: [] })
      await this.getList({ name: name || undefined })
    } else if (prevProps.location.search !== this.props.location.search) {
      await this.props.clearGroupsList()
      this.setState({ list: [] })
      await this.getList({ name: name || undefined })
    }
  }

  createInvite = async (user_id) => {
    const group_id = parseInt(this.props.match.params.groupId)

    try {
      await this.props.groupInviteCreate({ group_id, user_id })
    } catch ({ error }) {
      this.props.enqueueSnackbar(error.description, { variant: "error" })
    }
  }

  cancelInvite = async (user_id) => {
    const group_id = parseInt(this.props.match.params.groupId)

    try {
      await this.props.groupInviteCancel({ group_id, user_id })
    } catch ({ error }) {
      this.props.enqueueSnackbar(error.description, { variant: "error" })
    }
  }

  groupRequestsAccept = async (user_id) => {
    const group_id = parseInt(this.props.match.params.groupId)

    try {
      await this.props.groupRequestsAccept({ group_id, user_id })
    } catch ({ error }) {
      this.props.enqueueSnackbar(error.description, { variant: "error" })
    }
  }

  groupRequestsDecline = async (user_id) => {
    const group_id = parseInt(this.props.match.params.groupId)

    try {
      await this.props.groupRequestsDecline({ group_id, user_id })
    } catch ({ error }) {
      this.props.enqueueSnackbar(error.description, { variant: "error" })
    }
  }

  groupMemberRemove = async (user_id) => {
    const group_id = parseInt(this.props.match.params.groupId)

    try {
      await this.props.groupMemberRemove({ group_id, user_id })
    } catch ({ error }) {
      this.props.enqueueSnackbar(error.description, { variant: "error" })
    }
  }

  processSearch = async ({ name }) => {
    await this.props.clearGroupsList()
    this.props.history.push(`?q=${name}`)
    this.setState({ list: [] })
  }

  getList = async ({ name, offset = 0 }) => {
    const { path } = this.props.match
    const id = parseInt(this.props.match.params.groupId)
    this.setState({ dataReceived: false })

    try {
      switch (path) {
        case routes.GROUP_SETTINGS_MEMBERS: {
          await this.props.getGroupMembersList({
            name: name || undefined,
            group_id: id,
            offset,
            limit: LIMIT,
          })
          this.setState({
            type: "members",
            list: this.props.groups.membersList,
            initialRender: false,
            dataReceived: true,
          })
          break
        }
        case routes.GROUP_SETTINGS_REQUESTS: {
          await this.props.groupRequestsList({
            name: name || undefined,
            group_id: id,
            offset,
            limit: LIMIT,
          })
          this.setState({
            type: "requests",
            list: this.props.groups.requestsList,
            initialRender: false,
            dataReceived: true,
          })
          break
        }
        case routes.GROUP_SETTINGS_MANAGEMENT: {
          await this.props.getGroupMembersList({
            name: name || undefined,
            group_id: id,
            role: 2,
            offset,
            limit: LIMIT,
          })
          this.setState({
            type: "managements",
            list: this.props.groups.membersList,
            initialRender: false,
            dataReceived: true,
          })
          break
        }

        case routes.GROUP_SETTINGS_INVITE: {
          if (name) {
            await this.props.groupInvitesSearch({
              group_id: id,
              name: name || undefined,
              offset,
              limit: LIMIT,
            })
            await this.setStateAsync({
              type: "invites",
              list:
                this.props.groups.membersInvitesList &&
                this.props.groups.membersInvitesList.length > 0
                  ? this.props.groups.invitesSearch
                  : this.props.groups.invitesSearch,
              initialRender: false,
              dataReceived: true,
            })
          } else {
            await this.props.getMembersInvitesList({
              group_id: id,
              offset,
              limit: LIMIT,
            })
            await this.props.groupInvitesSearch({
              group_id: id,
              name: name || undefined,
              offset,
              limit: LIMIT,
            })

            await this.setStateAsync({
              type: "invites",
              list:
                this.props.groups.membersInvitesList.length > 0
                  ? this.props.groups.invitesSearch
                  : this.props.groups.invitesSearch,
              initialRender: false,
              dataReceived: true,
            })
          }

          break
        }
        default: {
          break
        }
      }
    } catch ({ error }) {
      this.props.enqueueSnackbar(error.description, { variant: "error" })
    }
  }

  composeHeader = () => {
    switch (this.props.match.path) {
      case routes.GROUP_SETTINGS_MEMBERS:
        return {
          title: "Members",
          placeholder: "Search by members",
          noResultsLabel: "Group doesn't have members yet",
        }

      case routes.GROUP_SETTINGS_MANAGEMENT:
        return {
          title: "Management",
          placeholder: "Search by management",
          noResultsLabel: "Group doesn't have members yet",
        }

      case routes.GROUP_SETTINGS_REQUESTS:
        return {
          title: "Requests",
          placeholder: "Start typing name",
          noResultsLabel: "You don't have pending requests yet",
        }

      case routes.GROUP_SETTINGS_INVITE:
        return {
          title: "Invite",
          placeholder: "Start typing name",
          noResultsLabel: "Group doesn't have invites yet",
        }

      default:
        return
    }
  }

  onScroll = async () => {
    const { search } = this.props.location
    const { q: name } = qs.parse(search)
    const { list } = this.state
    if (!this.state.dataReceived) return
    this.setState({ dataReceived: false })
    await this.getList({ name: name || undefined, offset: list.length })
    this.setState({ dataReceived: true })
  }

  changeRole = async ({ user, role }) => {
    const { search } = this.props.location
    const { q: name } = qs.parse(search)
    const group_id = parseInt(this.props.match.params.groupId, 10)
    try {
      await this.props.groupChangeRole({ group_id, user_id: user.id, role })

      if (role === 2 && user.tags.includes(1)) {
        await this.props.changeGroupMemberTags({
          user_id: user.id,
          group_id,
          tags: user.tags.filter((t) => t !== 1),
        })
      }

      await this.props.clearGroupsList()
      this.setState({ list: [] })
      await this.getList({ name: name || undefined })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  changeTags = async ({ group_id, user_id, tags }) => {
    const { search } = this.props.location
    const { q: name } = qs.parse(search)
    try {
      await this.props.changeGroupMemberTags({
        user_id,
        group_id,
        tags,
      })
      await this.props.clearGroupsList()
      this.setState({ list: [] })
      await this.getList({ name: name || undefined })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  componentWillUnmount() {
    this.props.clearGroupsList()
  }

  render() {
    const { list, type, dataReceived } = this.state
    const {
      auth: {
        userData: { id },
      },
    } = this.props
    const groupId = parseInt(this.props.match.params.groupId)
    const { search } = this.props.location
    const { q: name } = qs.parse(search)

    const canUploadRoster = [4, 5, 7].includes(
      this.props.groups.currentGroup.type
    )

    const canInviteByEmail = [2].includes(this.props.groups.currentGroup.type)

    return (
      <>
        <ListSearchHeader
          title={this.composeHeader().title}
          placeholder={this.composeHeader().placeholder}
          search={(name) => this.processSearch({ name })}
          noResultsLabel={
            name ? "Result not found" : this.composeHeader().noResultsLabel
          }
          noResults={dataReceived && !list.length}
          hasButton={
            this.props.match.path === routes.GROUP_SETTINGS_INVITE &&
            (canUploadRoster || canInviteByEmail)
          }
          buttonAction={() => {
            this.setState((prevState) => {
              if (canUploadRoster) {
                return { showUploadRosterDialog: true }
              } else if (canInviteByEmail) {
                return { showInviteByEmailDialog: true }
              } else {
                return {}
              }
            })
          }}
          buttonLabel={canUploadRoster ? "Upload Roster" : "Invite by email"}
        />
        <List>
          {list.map((item) => (
            <ListItemMember
              key={item.id}
              user={item}
              ownId={
                this.props.groups.currentGroup.creator
                  ? this.props.groups.currentGroup.creator.id
                  : id
              }
              groupId={groupId}
              type={type}
              groupRequestsAccept={(user_id) =>
                this.groupRequestsAccept(user_id)
              }
              groupRequestsDecline={(user_id) =>
                this.groupRequestsDecline(user_id)
              }
              changeUserRole={({ role }) =>
                this.changeRole({ user: item, role })
              }
              groupMemberRemove={(user_id) => this.groupMemberRemove(user_id)}
              createInvite={(user_id) => this.createInvite(user_id)}
              cancelInvite={(user_id) => this.cancelInvite(user_id)}
              currentUser={id}
              changeUserTags={this.changeTags}
            />
          ))}
        </List>
        {!dataReceived && <Loader />}

        {this.state.showUploadRosterDialog && (
          <UsersRosterModal
            open={this.state.showUploadRosterDialog}
            handleClose={() => this.setState({ showUploadRosterDialog: false })}
            groupId={this.props.groups.currentGroup.id}
          />
        )}

        {this.state.showInviteByEmailDialog && (
          <InviteParticipantByEmailModal
            open={this.state.showInviteByEmailDialog}
            handleClose={() =>
              this.setState({ showInviteByEmailDialog: false })
            }
            groupId={this.props.groups.currentGroup.id}
          />
        )}
      </>
    )
  }
}

class ListItemMember extends Component {
  state = {
    isInvited:
      this.props.user.is_invited !== undefined
        ? this.props.user.is_invited
        : true,
    isDeleted: false,
    open: false,
    tagsModalOpen: false,
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleTagsModalOpen = () => this.setState({ tagsModalOpen: true })
  handleTagsModalClose = () => this.setState({ tagsModalOpen: false })

  groupMemberRemove = async () => {
    const user_id = parseInt(this.props.user.id)

    await this.props.groupMemberRemove(user_id)
    await this.setStateAsync({ isDeleted: true })
  }

  groupRequestsDecline = async () => {
    const user_id = parseInt(this.props.user.id)

    await this.props.groupRequestsDecline(user_id)
    await this.setStateAsync({ isDeleted: true })
  }

  groupRequestsAccept = async () => {
    const user_id = parseInt(this.props.user.id)

    await this.props.groupRequestsAccept(user_id)
    await this.setStateAsync({ isDeleted: true })
  }

  createInvite = async () => {
    const user_id = parseInt(this.props.user.id)

    await this.props.createInvite(user_id)
    await this.setStateAsync({ isInvited: true })
  }

  cancelInvite = async () => {
    const user_id = parseInt(this.props.user.id)

    await this.props.cancelInvite(user_id)
    await this.setStateAsync({ isInvited: false })
  }

  render() {
    const { isInvited, isDeleted, open } = this.state
    const { user, type, ownId, currentUser } = this.props
    const photoUrl = user.deleted
      ? unavailableAvatar
      : user.photo !== ""
        ? user.photo
        : userAvatarDefault
    const fullName = `${user.first_name} ${user.last_name}`
    const profileUrl = generatePath(routes.USER, { userId: user.id })
    const dropdownMenuItems = () => {
      switch (type) {
        case "members":
        case "managements":
          return ownId !== user.id && user.id !== currentUser
            ? [
                {
                  type: "button",
                  action: this.handleClickOpen,
                  label: "Change role",
                },
                {
                  type: "button",
                  action: this.handleTagsModalOpen,
                  label: "Change occupation",
                },

                { type: "h-divider", id: "hd1" },

                {
                  type: "button",
                  action: this.groupMemberRemove,
                  label: "Remove from group",
                },
              ]
            : [
                {
                  type: "button",
                  action: this.handleTagsModalOpen,
                  label: "Change occupation",
                },
              ]

        case "requests":
          return [
            {
              type: "button",
              action: this.groupRequestsDecline,
              label: "Decline request",
            },
          ]

        default:
          return null
      }
    }

    const dropdownControl = () => {
      const buttonLabel = () => {
        return "Request sent"
      }

      switch (type) {
        case "requests":
          return (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={this.groupRequestsAccept}
            >
              Confirm request
            </Button>
          )

        case "invites":
          return isInvited ? (
            <DropdownControl buttonLabel={buttonLabel()}>
              <MenuList>
                {isInvited && (
                  <CustomMenuItem onClick={this.cancelInvite}>
                    Cancel request
                  </CustomMenuItem>
                )}
              </MenuList>
            </DropdownControl>
          ) : (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={this.createInvite}
            >
              Invite
            </Button>
          )

        default:
          return null
      }
    }

    const controls = () => {
      switch (type) {
        case "members":
        case "managements":
          return (
            // ownId !== user.id &&
            // user.id !== currentUser && (
            <MoreDropdown>
              <VerticalMenu
                menuItems={dropdownMenuItems()}
                classes="more-dropdown-menu__list"
              />
            </MoreDropdown>
          )

        case "requests":
          return (
            <>
              <MoreDropdown>
                <VerticalMenu
                  menuItems={dropdownMenuItems()}
                  classes={"more-dropdown-menu__list"}
                />
              </MoreDropdown>
              {dropdownControl()}
            </>
          )

        case "invites":
          return dropdownControl()
      }
    }
    // const addressInfoBlock = (user.city ? user.city : "") + (user.country ? `, ${user.country}` : "");
    if (!isDeleted) {
      return (
        <div className="box list-item">
          <div className="f aic full-width">
            <div className="list-item__img">
              <img src={photoUrl} alt={fullName} />
            </div>
            <div className="list-item__info">
              <span className="font-12 color-black-54">
                {userRolesIds[user.role].name}
              </span>
              <Link to={profileUrl} className="link list-item__name">
                {fullName}
              </Link>
              {/*<span className="list-item__description">{userRolesIds[user.role].name}</span>*/}
              <span className="list-item__description">
                {groupRolesIds[user.group_role]}
              </span>
              {type !== "invites" &&
                type !== "requests" &&
                !!this.props.user.tags.length && (
                  <span className="list-item__description">
                    Occupation:{" "}
                    {renderArrayOfNames(
                      user.tags.map((tag) => ({
                        name: groupMemberTagsIds[tag],
                      }))
                    )}
                  </span>
                )}
              <span>
                {getUserLocationString(user) && (
                  <LinesEllipsis
                    text={getUserLocationString(user)}
                    maxLine="1"
                    ellipsis="..."
                    trimRight
                    basedOn="letters"
                  />
                )}
              </span>

              {!!user.education &&
                composeUserHighlightedEducation(user.education)}

              {!!user.work && composeUserHighlightedWork(user.work)}
            </div>
          </div>
          <div className="list-item__control-panel">
            {controls()}

            {open && (
              <MemberRoleChange
                user={user}
                open={open}
                changeRole={this.props.changeUserRole}
                onClose={this.handleClose}
              />
            )}

            {this.state.tagsModalOpen && (
              <MemberTagsModal
                open={this.state.tagsModalOpen}
                handleClose={this.handleTagsModalClose}
                user={user}
                changeUserTags={this.props.changeUserTags}
              />
            )}
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

export const mapStateToProps = ({ auth, groups }) => ({ auth, groups })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getGroupMembersList,
      groupMemberRemove,
      groupRequestsList,
      groupRequestsAccept,
      groupRequestsDecline,
      getMembersInvitesList,
      groupInvitesSearch,
      groupInvitesSearchClear,
      groupInviteCreate,
      groupInviteCancel,
      clearGroupsList,
      groupChangeRole,
      changeGroupMemberTags,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withScroll(GroupSettingsMembersList)))
