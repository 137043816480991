import React, { Component } from "react"
import "./productHeader.scss"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import StarRating from "../../../../../components/ui/starRating/starRating"

class ProductHeader extends Component {
  state = {
    descriptionFull: this.props.products.currentProduct.description.length,
    description:
      this.props.products.currentProduct.description.length > 500
        ? this.props.products.currentProduct.description
            .slice(0, 500)
            .concat("...")
        : this.props.products.currentProduct.description,
    textHidden: this.props.products.currentProduct.description.length > 500,
    showLess: false,
  }

  showFullText = () => {
    this.setState({
      textHidden: false,
      showLess: true,
      description: this.props.products.currentProduct.description,
    })
  }

  showLessText = () => {
    this.setState({
      textHidden: true,
      showLess: false,
      description: this.props.products.currentProduct.description
        .slice(0, 500)
        .concat("..."),
    })
  }

  render() {
    const { description, showLess, textHidden } = this.state
    const { name, author, rating, reviews_number } =
      this.props.products.currentProduct
    const reviewsNumberLabel = `${reviews_number} customer review${
      reviews_number > 1 ? "s" : ""
    }`

    return (
      <div className="box product-header">
        <div className="box__heading">
          <h1>{name}</h1>
        </div>
        <div className="box__content">
          {author !== "" && (
            <div className="f aic mb8">
              <span className="mr8 color-black-54">Author: </span>
              <span>{author}</span>
            </div>
          )}
          <div className="f aic">
            <StarRating rating={rating} starSize={20} />
            <span className="color-primary ml8">{reviewsNumberLabel}</span>
          </div>
          <div className="h-divider h-divider--vertical-margin"></div>
          <p className="box__subheading">Description</p>
          <pre className="break-word">{description}</pre>
          {textHidden && (
            <button className="btn btn--plain" onClick={this.showFullText}>
              Show all...
            </button>
          )}
          {showLess && (
            <button className="btn btn--plain" onClick={this.showLessText}>
              Show less
            </button>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, products }) => ({ auth, products })
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ProductHeader))
