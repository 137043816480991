import React from "react"
import "./productRatingGeneral.scss"
import { withStyles } from "@material-ui/core"
import LinearProgress from "@material-ui/core/LinearProgress"
import StarRating from "../../../../../../../components/ui/starRating"

const BorderLinearProgress = withStyles({
  root: {
    height: 20,
    width: 200,
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    borderWidth: 0,
    borderRadius: 2,
  },
  bar: {
    borderRadius: 1,
    backgroundColor: "#dc9940",
  },
})(LinearProgress)

const ProductRatingGeneral = ({
  rating,
  reviewsNumber,
  oneStar,
  twoStars,
  threeStars,
  fourStars,
  fiveStars,
}) => {
  const reviewsNumberLabel = `${reviewsNumber} customer review${
    reviewsNumber > 1 ? "s" : ""
  }`

  return (
    <div className="product-rating">
      <div className="product-rating__star-rating">
        <StarRating rating={rating} />
        <span className="ml8 color-primary">{reviewsNumberLabel}</span>
      </div>
      <div className="product-rating__inline-rating">
        <div className="product-rating__inline-rating-row">
          <span className="product-rating__inline-rating-label">5 stars</span>
          <BorderLinearProgress
            variant="determinate"
            color="secondary"
            value={fiveStars}
          />
          <span className="product-rating__inline-rating-description">
            {fiveStars}%
          </span>
        </div>
        <div className="product-rating__inline-rating-row">
          <span className="product-rating__inline-rating-label">4 stars</span>
          <BorderLinearProgress
            variant="determinate"
            color="secondary"
            value={fourStars}
          />
          <span className="product-rating__inline-rating-description">
            {fourStars}%
          </span>
        </div>
        <div className="product-rating__inline-rating-row">
          <span className="product-rating__inline-rating-label">3 stars</span>
          <BorderLinearProgress
            variant="determinate"
            color="secondary"
            value={threeStars}
          />
          <span className="product-rating__inline-rating-description">
            {threeStars}%
          </span>
        </div>
        <div className="product-rating__inline-rating-row">
          <span className="product-rating__inline-rating-label">2 stars</span>
          <BorderLinearProgress
            variant="determinate"
            color="secondary"
            value={twoStars}
          />
          <span className="product-rating__inline-rating-description">
            {twoStars}%
          </span>
        </div>
        <div className="product-rating__inline-rating-row">
          <span className="product-rating__inline-rating-label">1 star</span>
          <BorderLinearProgress
            variant="determinate"
            color="secondary"
            value={oneStar}
          />
          <span className="product-rating__inline-rating-description">
            {oneStar}%
          </span>
        </div>
      </div>
    </div>
  )
}

export default ProductRatingGeneral
