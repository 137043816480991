import React from "react"
import Dialog from "@material-ui/core/Dialog/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import SubjectPicker from "../../../../../../../components/v2/inputs/pickers/subjectPicker"
import GroupMemberPicker from "../../../../../../../components/v2/inputs/pickers/groupMemberPicker"
import { useIdParam } from "../../../../../../../hooks/useIdParam"
import Button from "@material-ui/core/Button"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import {
  useBlock,
  useBlockMutations,
  useBlockTrackSlot,
  useSlotMutations,
} from "../../../../../../../hooks/data/calendar/schedules"
import { useAlert } from "../../../../../../../hooks/useAlert"
import { getArrayIds } from "../../../../../../../library/utils/arrays"
import { getUserName } from "../../../../../../../library/utils/user/userUtils"
import TextField from "@material-ui/core/TextField/TextField"
import UserPicker from "../../../../../../../components/v2/inputs/pickers/userPicker"
import {
  GROUPS,
  SCHOOLS,
} from "../../../../../../../library/store/actions/types/groupsTypes"

const LearnerScheduleAssignmentFormModal = ({
  open,
  onClose,
  activeCell,
  blocksQueryKey,
  activeLearner,
  activeGrade,
  type,
}) => {
  console.log({ activeCell })
  const { errorAlert } = useAlert()
  const groupId = useIdParam("groupId")
  const currentSlot = activeCell.track.slot

  const [selectedLearners, setSelectedLearners] = React.useState(
    currentSlot?.students || []
  )

  const { updateSlot } = useSlotMutations(blocksQueryKey, false)

  const onSubmit = async () => {
    try {
      await updateSlot.mutateAsync({
        id: currentSlot.id,
        students: selectedLearners.map((s) => ({ user_id: s.id })),
      })
      onClose()
    } catch (e) {
      console.log(e)
      errorAlert(e)
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle className={"text-center"}>
        {!(currentSlot?.teachers?.length && currentSlot?.subject_id)
          ? "Assign"
          : "Reassign"}{" "}
        Block
      </DialogTitle>
      <DialogContent>
        <div className="form form--modal">
          <UserPicker
            value={selectedLearners}
            onChange={setSelectedLearners}
            userLabel={"learner"}
            multiple={!!activeCell}
            requestConfig={{
              fetchConfig: {
                apiUrl: SCHOOLS.STUDENTS.LIST,
                params: {
                  group_id: groupId,
                  grade: activeGrade?.id,
                },
              },
              key: "users",
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="primary">
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LearnerScheduleAssignmentFormModal
