import PostCreate from "../../../../../components/v2/feed/post/components/postCreate"
import React from "react"
import { useUser } from "../../../../../hooks/data/user/useUser"
import UserFeedList from "./userFeedList"
import { POSTS } from "../../../../../library/store/actions/types/postsTypes"
import { useIdParam } from "../../../../../hooks/useIdParam"

const UserFeed = () => {
  const userId = useIdParam("userId")
  const { user, isCurrentUser } = useUser(userId)

  const postsListQueryKey = [POSTS.LIST, { userId: user.id }]
  return (
    <div className={"lg:w-[75%] lg:mx-auto"}>
      {(user.is_friend || isCurrentUser) && (
        <PostCreate postsListQueryKey={postsListQueryKey} />
      )}
      <UserFeedList postsListQueryKey={postsListQueryKey} />
    </div>
  )
}

export default UserFeed
