import { KNOWCREDS } from "../types/knowCredsTypes"
import { call } from "../../../networking/API"
import { BOOKMARKS } from "../types/bookmarksTypes"

export const knowCredCreate = ({
  title,
  description,
  user_id,
  type,
  activity,
  location,
  links,
  foreground_image,
  background_image,
  border_color,
  knowmix_id,
  email,
  group_id,
  is_public,
}) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWCREDS.CREATE, {
      token: getState().auth.userData.token,
      title,
      description,
      user_id,
      type,
      activity,
      location,
      links,
      foreground_image,
      background_image,
      border_color,
      knowmix_id,
      email,
      group_id,
      public: is_public,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWCREDS.CREATE,
        payload: json.id,
      })
    } else {
      throw json.error
    }
  }
}

export const clearCred = () => {
  return {
    type: KNOWCREDS.CLEAR_CREATED,
  }
}

export const knowCredEdit = ({
  id,
  title,
  description,
  user_id,
  type,
  activity,
  location,
  links,
  foreground_image,
  background_image,
  border_color,
  email,
  is_public,
}) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWCREDS.EDIT, {
      token: getState().auth.userData.token,
      id,
      title,
      description,
      user_id,
      type,
      activity,
      location,
      links,
      foreground_image,
      background_image,
      border_color,
      email,
      public: is_public,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWCREDS.EDIT,
      })
    } else {
      throw json.error
    }
  }
}

export const updateKnowcredPublicStatus = ({ id, is_public }) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWCREDS.EDIT, {
      token: getState().auth.userData.token,
      id,
      public: is_public,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWCREDS.UPDATE_PUBLIC_STATUS,
        payload: { id, public: is_public },
      })
    } else {
      throw json.error
    }
  }
}

export const getCrediconForegrounds = ({ type, offset = 0, limit = 100 }) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWCREDS.FOREGROUNDS_LIST, {
      token: getState().auth.userData.token,
      type,
      offset,
      limit,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWCREDS.FOREGROUNDS_LIST,
        payload: { type: type, list: json.foregrounds },
      })
    } else {
      throw json.error
    }
  }
}

export const getCrediconBackgrounds = ({ offset = 0, limit = 100 } = {}) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWCREDS.BACKGROUNDS_LIST, {
      token: getState().auth.userData.token,
      offset,
      limit,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWCREDS.BACKGROUNDS_LIST,
        payload: json.backgrounds,
      })
    } else {
      throw json.error
    }
  }
}

export const knowCredGet = ({ id }) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWCREDS.GET, {
      token: getState().auth.userData.token,
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWCREDS.GET,
        payload: json,
      })
    } else {
      throw json.error
    }
  }
}

export const knowCredClear = () => {
  return async (dispatch) => {
    return dispatch({
      type: KNOWCREDS.CLEAR,
    })
  }
}

export const knowCredDelete = ({ id }) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWCREDS.DELETE, {
      token: getState().auth.userData.token,
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWCREDS.DELETE,
      })
    } else {
      throw json.error
    }
  }
}

export const knowCredLike = ({ knowcred_id }) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWCREDS.LIKES.CREATE, {
      token: getState().auth.userData.token,
      knowcred_id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWCREDS.LIKES.CREATE,
      })
    } else {
      throw json.error
    }
  }
}

export const knowCredUnLike = ({ knowcred_id }) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWCREDS.LIKES.DELETE, {
      token: getState().auth.userData.token,
      knowcred_id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWCREDS.LIKES.DELETE,
      })
    } else {
      throw json.error
    }
  }
}

export const lastKnowCredsList = ({
  list_type,
  user_id,
  type,
  offset = 0,
  limit = 5,
}) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWCREDS.LIST, {
      token: getState().auth.userData.token,
      user_id,
      status_type: 0,
      type,
      offset,
      limit,
    })

    const json = await response.data

    if (response.status === 200) {
      if (list_type === "my") {
        return dispatch({
          type: KNOWCREDS.MY_LAST_LIST,
          payload: json.knowcreds,
        })
      } else {
        return dispatch({
          type: KNOWCREDS.LAST_LIST,
          payload: json.knowcreds,
        })
      }
    } else {
      throw json.error
    }
  }
}

export const knowCredsList = ({
  list_type,
  user_id,
  type,
  status_type,
  bookmarked,
  folder_id,
  title,
  offset = 0,
  limit = 50,
  order,
}) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWCREDS.LIST, {
      token: getState().auth.userData.token,
      user_id,
      type,
      status_type,
      bookmarked,
      folder_id,
      title,
      offset,
      limit,
      sort: !!order,
      order,
    })

    const json = await response.data

    if (response.status === 200) {
      if (list_type === "my") {
        return dispatch({
          type: bookmarked ? BOOKMARKS.LIST : KNOWCREDS.MY_LIST,
          payload: json,
        })
      } else {
        return dispatch({
          type: bookmarked ? BOOKMARKS.LIST : KNOWCREDS.LIST,
          payload: json,
        })
      }
    } else {
      throw json.error
    }
  }
}

export const sharedKnowCredsList = ({
  type,
  title,
  shared = true,
  offset = 0,
  limit = 50,
}) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWCREDS.LIST, {
      token: getState().auth.userData.token,
      user_id: getState().auth.userData.id,
      type,
      title,
      shared,
      offset,
      limit,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWCREDS.SHARED_LIST,
        payload: json,
      })
    } else {
      throw json.error
    }
  }
}

export const knowCredsListClear = () => {
  return async (dispatch) => {
    return dispatch({
      type: KNOWCREDS.LIST_CLEAR,
    })
  }
}

export const lastKnowCredsListClear = (list_type) => {
  return async (dispatch) => {
    if (list_type === "my") {
      return dispatch({ type: KNOWCREDS.MY_LAST_LIST_CLEAR })
    } else {
      return dispatch({ type: KNOWCREDS.LAST_LIST_CLEAR })
    }
  }
}
