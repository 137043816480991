import React, { useState } from "react"
import { Route, Switch } from "react-router-dom"
import * as routes from "../../../library/constants/routes"
import BookmarksList from "./bookmarksList/bookmarksList"
import VerticalMenu from "../../../components/ui/verticalMenu/verticalMenu"
import FoldersList from "./folders/foldersList"
import Folder from "./folders/folder/folder"
import SEO from "../../../components/SEO"

const Bookmarks = () => {
  const [foldersReceived, setFoldersReceived] = useState(false)

  const setFoldersListReady = async (isReady) => {
    setFoldersReceived(isReady)
  }

  const menuItems = [
    {
      path: routes.BOOKMARKS,
      label: "All bookmarks",
    },

    {
      path: routes.BOOKMARKS_PEOPLE,
      label: "People",
    },

    {
      path: routes.BOOKMARKS_GROUPS,
      label: "Groups",
    },

    {
      path: routes.BOOKMARKS_POSTS,
      label: "Posts",
    },

    {
      path: routes.BOOKMARKS_KNOWCRED,
      label: "Creds",
    },

    // {
    //   path: routes.BOOKMARKS_PRODUCTS,
    //   label: "Products",
    // },

    // {
    //   path: routes.BOOKMARKS_RECORDS,
    //   label: "Records",
    // },

    {
      path: routes.BOOKMARKS_STANDARDS,
      label: "Standards",
    },
  ]

  return (
    <div className="common-page__content">
      <SEO title={"Bookmarks"} />
      <main className="common-page__main">
        <Switch>
          <Route exact path={routes.BOOKMARKS} component={BookmarksList} />
          <Route
            exact
            path={routes.BOOKMARKS_PEOPLE}
            component={BookmarksList}
          />
          <Route
            exact
            path={routes.BOOKMARKS_GROUPS}
            component={BookmarksList}
          />
          <Route
            exact
            path={routes.BOOKMARKS_POSTS}
            component={BookmarksList}
          />
          <Route
            exact
            path={routes.BOOKMARKS_KNOWCRED}
            component={BookmarksList}
          />
          <Route
            exact
            path={routes.BOOKMARKS_PRODUCTS}
            component={BookmarksList}
          />
          <Route
            exact
            path={routes.BOOKMARKS_RECORDS}
            component={BookmarksList}
          />
          <Route
            exact
            path={routes.BOOKMARKS_STANDARDS}
            component={BookmarksList}
          />

          <Route
            exact
            path={routes.FOLDER}
            render={(props) => (
              <Folder {...props} setFoldersListReady={setFoldersListReady} />
            )}
          />
          <Route
            exact
            path={routes.FOLDER_PEOPLE}
            render={(props) => (
              <Folder {...props} setFoldersListReady={setFoldersListReady} />
            )}
          />
          <Route
            exact
            path={routes.FOLDER_GROUPS}
            render={(props) => (
              <Folder {...props} setFoldersListReady={setFoldersListReady} />
            )}
          />
          <Route
            exact
            path={routes.FOLDER_POSTS}
            render={(props) => (
              <Folder {...props} setFoldersListReady={setFoldersListReady} />
            )}
          />
          <Route
            exact
            path={routes.FOLDER_KNOWCRED}
            render={(props) => (
              <Folder {...props} setFoldersListReady={setFoldersListReady} />
            )}
          />
          <Route
            exact
            path={routes.FOLDER_PRODUCTS}
            render={(props) => (
              <Folder {...props} setFoldersListReady={setFoldersListReady} />
            )}
          />
          <Route
            exact
            path={routes.FOLDER_RECORDS}
            render={(props) => (
              <Folder {...props} setFoldersListReady={setFoldersListReady} />
            )}
          />
          <Route
            exact
            path={routes.FOLDER_STANDARDS}
            render={(props) => (
              <Folder {...props} setFoldersListReady={setFoldersListReady} />
            )}
          />
        </Switch>
      </main>
      <aside className="common-page__sidebar">
        <VerticalMenu menuItems={menuItems} />
        <FoldersList showList={foldersReceived} />
      </aside>
    </div>
  )
}

export default Bookmarks
