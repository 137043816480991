import FormControl from "@material-ui/core/FormControl"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"

const FormRadioGroup = ({
  value,
  onChange,
  options,
  label,
  name,
  leftLabel = true,
}) => {
  const fieldBox = (
    <div className="form__fieldbox">
      <div className="form__input">
        <FormControl component="fieldset" id={name}>
          <RadioGroup
            aria-label={name}
            name={name}
            value={value}
            onChange={(e) => onChange(e.target.value)}
          >
            {options.map((option) => (
              <FormControlLabel
                value={option.value}
                control={<Radio color="primary" />}
                label={option.label}
                labelPlacement="end"
                key={option.value}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  )

  return leftLabel ? (
    <div className="form__fieldset">
      <label htmlFor={name} className="form__label">
        {label}
      </label>
      {fieldBox}
    </div>
  ) : (
    fieldBox
  )
}

export default FormRadioGroup
