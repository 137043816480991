import Card from "./card"

const SpaceCard = ({
  space,
  openSpaceDetails,
  openDeleteSpace,
  openEditSpace,
}) => {
  const dropdownMenuItems = [
    {
      type: "button",
      action: openEditSpace,
      label: "Edit space",
    },
    {
      type: "h-divider",
      id: "hd1",
    },
    {
      type: "button",
      action: openDeleteSpace,
      label: "Delete space",
    },
  ]

  return (
    <Card
      name={space.name}
      onNameClick={openSpaceDetails}
      info={space?.description || ""}
      controls={{
        dropdownMenu: {
          items: dropdownMenuItems,
        },
      }}
    />
  )
}

export default SpaceCard
