import React from "react"
import GroupSettingsAttachmentsLinks from "./groupSettingsAttachmentsLinks"
import GroupSettingsAttachmentsFiles from "./groupSettingAttachmentsFiles"
import { bindActionCreators } from "redux"
import {
  getGroup,
  updateGroup,
} from "../../../../../../library/store/actions/creators/groupsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import GroupsSettingsAttachmentsProducts from "./groupSettingsAttachmentsProducts"

class GroupSettingsAttachments extends React.Component {
  render() {
    const { type, auth, groups, updateGroup, getGroup } = this.props
    switch (type) {
      case "links":
        return (
          <GroupSettingsAttachmentsLinks
            auth={auth}
            groups={groups}
            updateGroup={updateGroup}
            getGroup={getGroup}
          />
        )
      case "files":
        return (
          <GroupSettingsAttachmentsFiles
            auth={auth}
            groups={groups}
            updateGroup={updateGroup}
            getGroup={getGroup}
          />
        )
      case "products":
        return (
          <GroupsSettingsAttachmentsProducts
            currentGroupAttachmentProducts={[]}
          />
        )
      default:
        return (
          <GroupSettingsAttachmentsLinks
            auth={auth}
            groups={groups}
            updateGroup={updateGroup}
            getGroup={getGroup}
          />
        )
    }
  }
}

const mapStateToProps = ({ auth, groups }) => ({ auth, groups })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateGroup, getGroup }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(GroupSettingsAttachments))
