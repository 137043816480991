import React from "react"
import SmallCalendar from "../smallCalendar"
import { useCalendar } from "../../CalendarContext"
import ProductsListFilter from "../../../marketplace/productsList/productsListFilter/productsListFilter"
import { useLocation } from "react-router-dom"

const CalendarLeftSidebar = () => {
  const {
    showCalendarFormModal,
    setShowCalendarFormModal,
    groups,
    groupsReceived,
  } = useCalendar()

  const location = useLocation()

  return (
    <>
      <div className={"box mb15"}>
        <div
          className="box__content"
          style={{ paddingLeft: 10, paddingRight: 10 }}
        >
          {/*<Button*/}
          {/*  fullWidth*/}
          {/*  variant={'contained'}*/}
          {/*  color={'primary'}*/}
          {/*  className={'mb15'}*/}
          {/*  onClick={() => setShowCalendarFormModal(true)}*/}
          {/*>*/}
          {/*  Create calendar*/}
          {/*</Button>*/}
          <SmallCalendar />
        </div>
      </div>
      {groupsReceived && !!groups.length && (
        <ProductsListFilter
          pathname={location.pathname}
          hiddenFields={[
            "states",
            "standardSubjects",
            "contentTypes",
            "standardsGrades",
            "subjects",
            "grades",
          ]}
          groups={groups}
        />
      )}
      {/*<CalendarsList />*/}
      {/*{showCalendarFormModal && (*/}
      {/*  <CalendarFormModal*/}
      {/*    open={showCalendarFormModal}*/}
      {/*    onClose={() => setShowCalendarFormModal(false)}*/}
      {/*  />*/}
      {/*)}*/}
    </>
  )
}

export default CalendarLeftSidebar
