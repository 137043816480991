import React from "react"
import IconButton from "@material-ui/core/IconButton"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import Menu from "@material-ui/core/Menu"
import { MenuItem } from "@material-ui/core"
import * as routes from "../../../library/constants/routes"
import {
  BOOKMARKS,
  CONVERSATIONS,
  FEED,
  GROUPS,
  USER,
} from "../../../library/constants/routes"
import { generatePath, NavLink as Link, useHistory } from "react-router-dom"
import { howToArticles } from "../../../screens/info/howTo/howToArticles"
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined"
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined"
import SubjectOutlinedIcon from "@material-ui/icons/SubjectOutlined"
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined"
import SupervisedUserCircleOutlinedIcon from "@material-ui/icons/SupervisedUserCircleOutlined"
import BookmarksOutlinedIcon from "@material-ui/icons/BookmarksOutlined"
import TrackingIcon from "@material-ui/icons/ImportExportOutlined"
import CoursesIcon from "@material-ui/icons/ClassOutlined"
import InvenstoryIcon from "@material-ui/icons/LibraryBooksOutlined"
import ProductsIcon from "@material-ui/icons/LocalOfferOutlined"
import StandardsIcon from "@material-ui/icons/PlaylistAddCheckOutlined"
import { shallowEqual, useSelector } from "react-redux"
import { useUserCalendar } from "../../../screens/main/calendar/UserCalendarProvider"
import { useCurrentUser } from "../../../hooks/data/user/useUser"

const HeaderNav = () => {
  const { user, isSuccess } = useCurrentUser()
  const { chatsCounter } = useSelector(
    ({ notifications }) => notifications,
    shallowEqual
  )

  const { onCalendarOpen } = useUserCalendar()
  const history = useHistory()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const navItems = [
    !!user && {
      path: generatePath(USER, { userId: user.id }),
      label: "My Page",
      Icon: AccountCircleOutlinedIcon,
    },
    {
      label: "Calendar",
      Icon: DateRangeOutlinedIcon,
      action: onCalendarOpen,
    },
    {
      path: FEED,
      label: "News",
      Icon: SubjectOutlinedIcon,
    },
    {
      path: CONVERSATIONS,
      label: !chatsCounter ? (
        "Chats"
      ) : (
        <span className={"font-12"}>
          Chats{" "}
          <span className={"color-primary font-weight-500"}>
            (+{chatsCounter})
          </span>
        </span>
      ),
      Icon: ChatBubbleOutlineOutlinedIcon,
    },
    !!user && {
      path: generatePath(GROUPS, { userId: user.id }),
      label: "Groups",
      Icon: SupervisedUserCircleOutlinedIcon,
    },
    {
      path: BOOKMARKS,
      label: "Bookmarks",
      Icon: BookmarksOutlinedIcon,
    },
    {
      // path: routes.KNOWMAP_MEETS,
      path: routes.KNOWMAP_INCOMING_APPOINTMENT_BOOKINGS,
      label: "Tracking",
      Icon: TrackingIcon,
    },
    !!user && {
      path: generatePath(routes.KNOWMIX_LIST, { userId: user.id }),
      label: "Courses",
      Icon: CoursesIcon,
    },
    !!user && {
      path: generatePath(routes.INVENSTORY, { userId: user.id }),
      label: "InvenStory",
      Icon: InvenstoryIcon,
    },
    {
      path: routes.MARKETPLACE,
      label: "Featured Products",
      Icon: ProductsIcon,
    },
    {
      path: routes.STANDARDS,
      label: "Standards",
      Icon: StandardsIcon,
    },
  ].filter(Boolean)

  const moreNavItems = [
    {
      path: routes.PRICING,
      label: "Pricing",
    },
    {
      path: routes.HOW_TO,
      label: "How To",
    },
    {
      path: routes.ACCOUNTS_INFO,
      label: "Info",
    },
    {
      path: routes.CONTACT_FORM,
      label: "Contact Us",
    },
    {
      path: routes.TERMS_AND_CONDITIONS,
      label: "Terms and Conditions",
    },
    // {
    //   path: routes.APP_PROMOTE,
    //   label: "App",
    // },
    // {
    //   path: routes.TEACHER_INTRO,
    //   label: "Teacher Intro",
    // },
  ]

  return (
    <div className="grid grid-cols-[1fr_minmax(min-content,_1200px)_1fr] w-full py-2 bg-grey7 shadow-layout h-auto">
      <div className="col-start-2 px-415 flex items-center justify-between gap-8 w-full container">
        <nav>
          <ul className={"list-none w-full grid grid-flow-col gap-6"}>
            {navItems.map(({ path, label, Icon, action }) => (
              <li className={"text-black54"} key={label}>
                {action ? (
                  <button
                    onClick={onCalendarOpen}
                    className={
                      "flex flex-col items-center justify-between h-full hover:text-primary transition-colors duration-200"
                    }
                  >
                    <div className={"text-xl lg:text-lg !leading-none"}>
                      <Icon style={{ fontSize: "inherit" }} />
                    </div>

                    {typeof label === "string" ? (
                      <span className={"text-sm block hidden lg:block"}>
                        {label}
                      </span>
                    ) : (
                      label
                    )}
                  </button>
                ) : (
                  <Link
                    exact
                    to={path}
                    activeClassName={"color-primary font-medium"}
                    className={
                      "flex flex-col items-center justify-between h-full hover:text-primary transition-colors duration-200"
                    }
                  >
                    <div className={"text-xl lg:text-lg !leading-none"}>
                      <Icon style={{ fontSize: "inherit" }} />
                    </div>
                    {typeof label === "string" ? (
                      <span className={"text-sm hidden lg:block"}>{label}</span>
                    ) : (
                      label
                    )}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </nav>
        <div>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            size={"small"}
            disableRipple
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            // keepMounted
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={
              {
                // style: {
                //   maxHeight: ITEM_HEIGHT * 4.5,
                //   width: '20ch',
                // },
              }
            }
          >
            {moreNavItems.map((item) => (
              <MenuItem key={item.path} onClick={() => history.push(item.path)}>
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>
    </div>
  )
}

export default HeaderNav
