import React from "react"
import BoxPage from "../../../../../components/v2/layout/boxPage/boxPage"
import Button from "@material-ui/core/Button"
import { useAlert } from "../../../../../hooks/useAlert"
import Card from "../../../../../components/v2/layout/cards/card"
import googleCalendarLogo from "../../../../../assets/img/Google_Calendar_icon.png"
import outlookCalendarLogo from "../../../../../assets/img/Outlook_Calendar_icon.png"
import useCreator from "../../../../../hooks/useCreator"
import List from "../../../../../components/ui/list"
import { useSelector } from "react-redux"
import { baseUrl } from "../../../../../library/networking/API"
import {
  AZURE_AUTH_SUCCESS,
  GOOGLE_AUTH_SUCCESS,
} from "../../../../../library/constants/routes"
import {
  revokeAzureAuthCode,
  revokeGoogleAuthCode,
} from "../../../../../library/store/actions/creators/authCreators"
import { createTheme } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/styles"
import theme from "../../../../../library/m-ui-theme"
import ActionWithPermissions from "../../../../../components/v2/controls/buttons/ActionWithPermissions"

const microsoftClientId = process.env.REACT_APP_AZURE_AUTH_CLINET_ID
const googleClientId = process.env.REACT_APP_GOOGLE_AUTH_CLINET_ID_DEV

const buttonTheme = createTheme({
  ...theme,
  palette: {
    secondary: {
      main: "#f44336",
    },
  },
})

const ProfileSettingsIntegrations = () => {
  const creator = useCreator()
  const microsoftSuccessRedirect = baseUrl + AZURE_AUTH_SUCCESS
  const googleSuccessRedirect = baseUrl + GOOGLE_AUTH_SUCCESS

  const { userData } = useSelector(({ auth }) => auth)

  const { errorAlert } = useAlert()

  const { has_google_integration, has_outlook_integration } = useSelector(
    ({ auth }) => auth.userData
  )

  const connectMicrosoft = async (e) => {
    e.preventDefault()
    try {
      const authForm = document.getElementById("form_outlook_oauth")
      authForm.submit()
    } catch (e) {
      return errorAlert("Unable to connect")
    }
  }

  const connectGoogle = async (e) => {
    e.preventDefault()
    try {
      const authForm = document.getElementById("form_google_oauth")
      authForm.submit()
    } catch (e) {
      return errorAlert("Unable to connect")
    }
  }

  const revokeGoogle = async () => {
    try {
      await creator(revokeGoogleAuthCode())
    } catch (e) {
      return errorAlert("Unable to connect")
    }
  }

  const revokeMicrosoft = async () => {
    try {
      await creator(revokeAzureAuthCode())
    } catch (e) {
      return errorAlert("Unable to connect")
    }
  }

  const getRevokeBtn = ({ onClick }) => (
    <ThemeProvider theme={buttonTheme}>
      <Button variant={"outlined"} color={"secondary"} onClick={onClick}>
        Revoke
      </Button>
    </ThemeProvider>
  )

  return (
    <BoxPage title={"Integrations"}>
      <List>
        <Card
          name={"Google Calendar"}
          photo={googleCalendarLogo}
          controls={{
            button: {
              getComponent: () =>
                !has_google_integration ? (
                  <>
                    <form
                      id="form_google_oauth"
                      name="form_google_oauth"
                      method="get"
                      action="https://accounts.google.com/o/oauth2/v2/auth"
                    >
                      <input
                        type="hidden"
                        name="scope"
                        value="email profile openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events"
                      />
                      <input
                        type="hidden"
                        name="include_granted_scopes"
                        value="true"
                      />
                      <input type="hidden" name="response_type" value="code" />
                      <input
                        type="hidden"
                        name="state"
                        value="state_parameter_passthrough_value"
                      />
                      <input
                        type="hidden"
                        name="redirect_uri"
                        value={googleSuccessRedirect}
                      />
                      <input
                        type="hidden"
                        name="client_id"
                        value={googleClientId}
                      />
                      <input type="hidden" name="access_type" value="offline" />
                    </form>
                    <Button
                      onClick={connectGoogle}
                      color={"primary"}
                      variant={"contained"}
                    >
                      Connect
                    </Button>
                  </>
                ) : (
                  getRevokeBtn({ onClick: revokeGoogle })
                ),
            },
          }}
        />
        <Card
          name={"Outlook"}
          photo={outlookCalendarLogo}
          controls={{
            button: {
              getComponent: () =>
                !has_outlook_integration ? (
                  <>
                    <form
                      id="form_outlook_oauth"
                      name="form_outlook_oauth"
                      method="get"
                      action="https://login.microsoftonline.com/common/oauth2/v2.0/authorize"
                    >
                      <input
                        type="hidden"
                        name="client_id"
                        value={microsoftClientId}
                      />
                      <input
                        type="hidden"
                        name="scope"
                        value="openid profile offline_access User.Read Calendars.ReadWrite"
                      />
                      <input type="hidden" name="response_type" value="code" />
                      <input type="hidden" name="response_mode" value="query" />
                      <input type="hidden" name="state" value={userData.id} />
                      <input
                        type="hidden"
                        name="redirect_uri"
                        value={microsoftSuccessRedirect}
                      />
                    </form>
                    <Button
                      onClick={connectMicrosoft}
                      color={"primary"}
                      variant={"contained"}
                    >
                      Connect
                    </Button>
                  </>
                ) : (
                  getRevokeBtn({ onClick: revokeMicrosoft })
                ),
            },
          }}
        />
      </List>
    </BoxPage>
  )
}

export default ProfileSettingsIntegrations
