import React, { useState } from "react"
import "./profileEditEducation.scss"
import ProfileEditEducationContent from "./profileEditEducationContent/profileEditEductionContent"

const tabs = [
  "Elementary",
  "Middle",
  "High",
  "College (university)",
  "Vocational",
  "Additional",
]

const ProfileEditEducation = () => {
  const [activeTab, setActiveTab] = useState('Elementary')

  const switchTabContent = (value) => setActiveTab(value)

  const HorizontalTabs = () => {
    return (
      <nav className="h-tabs">
        <ul className="h-tabs__nav-list">
          {tabs.map((item) => {
            return (
              <li
                key={item}
                className={`h-tabs__item ${
                  activeTab === item ? 'h-tabs__item--active' : ''
                }`}
              >
                <span
                  className="h-tabs__link"
                  onClick={() => switchTabContent(item)}
                >
                  {item}
                </span>
              </li>
            )
          })}
        </ul>
      </nav>
    )
  }

  const TabContent = () => {
    switch (activeTab) {
      case 'Elementary':
        return <ProfileEditEducationContent type={1} />

      case 'Middle':
        return <ProfileEditEducationContent type={2} />

      case 'High':
        return <ProfileEditEducationContent type={3} />

      case 'College (university)':
        return <ProfileEditEducationContent type={4} />

      case 'Vocational':
        return <ProfileEditEducationContent type={5} />

      case 'Additional':
        return <ProfileEditEducationContent type={6} />

      default:
        return <ProfileEditEducationContent type={1} />
    }
  }

  return (
    <div className="box profile-edit">
      <HorizontalTabs />
      <div className="box__content">
        <TabContent />
      </div>
    </div>
  )
}

export default ProfileEditEducation
