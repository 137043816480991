import { Form, Formik } from "formik"
import * as yup from "yup"
import { useCommentCtx } from "./CommentContext"
import { Button, TextField } from "@material-ui/core"
import PhotoOutlined from "@material-ui/icons/PhotoOutlined"
import AttachFileOutlined from "@material-ui/icons/AttachFileOutlined"
import FileLoader from "../../../formComponents/fileLoader"
import LoadingButton from "../../controls/buttons/loadingButton"
import React from "react"

const validationSchema = yup.object({
  message: yup.string().max(5000).required(),
})

const CommentUpdate = () => {
  const { comment, updateComment, onUpdate, setIsEdit } = useCommentCtx()

  const initialValues = { message: comment.message || "" }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onUpdate}
      validationSchema={validationSchema}
    >
      {(fk) => (
        <Form>
          <div className="post__update">
            <div className="post__update-input">
              <TextField
                placeholder="What’s happening?"
                multiline
                autoFocus
                fullWidth
                name="message"
                margin="none"
                onChange={fk.handleChange}
                value={fk.values.message}
              />
            </div>
            <div className="post__update-controls">
              <div className="btn-group">
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => setIsEdit(false)}
                >
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  size="small"
                  disabled={!fk.values.message.replace(/\s/g, "")}
                  isLoading={updateComment.isLoading}
                  isLoadingText={"Saving"}
                >
                  Save
                </LoadingButton>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default CommentUpdate
