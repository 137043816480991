import React, { useState } from "react"
import {
  clearGroupRelatedList,
  getGroupRelatedList,
} from "../../../../../../library/store/actions/creators/groupsCreators"
import ListPage from "../../../../../../components/v2/layout/listPage/listPage"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import GroupCard from "../../../../../../components/v2/layout/cards/groupCard"
import UsersRosterModal from "../groupSettingsMembers/groupSettingsMembersList/usersRosterModal"

const GroupSettingsSchoolsRostering = () => {
  const dispatch = useDispatch()
  const params = useParams()

  const { relatedList, relatedListEndReached } = useSelector(
    ({ groups }) => groups
  )

  const [isRosterModalOpen, setIsRosterModalOpen] = useState(false)
  const [activeSchool, setActiveSchool] = useState(null)

  const onOpenRosterModal = (school_id) => {
    setActiveSchool(school_id)
    setIsRosterModalOpen(true)
  }

  const onCloseRosterModal = (school_id) => {
    setActiveSchool(null)
    setIsRosterModalOpen(false)
  }

  return (
    <>
      <ListPage
        title={"Schools rostering"}
        noResultsLabel={"This district doesn't have schools yet"}
        getList={async ({ name, offset }) =>
          await dispatch(
            getGroupRelatedList({
              group_id: parseInt(params.groupId, 10),
              type: 4,
              name,
              offset,
            })
          )
        }
        clearList={() => dispatch(clearGroupRelatedList())}
        list={relatedList}
        listEndReached={relatedListEndReached}
        getListItemComponent={({ item }) => (
          <GroupCard
            group={item}
            controls={{
              button: {
                label: "Upload roster",
                action: () => onOpenRosterModal(item.id),
              },
            }}
            key={item.id}
          />
        )}
      />
      {isRosterModalOpen && (
        <UsersRosterModal
          groupId={parseInt(params.groupId, 10)}
          subgroupId={activeSchool}
          title={"Upload school roster"}
          handleClose={onCloseRosterModal}
          open={isRosterModalOpen}
        />
      )}
    </>
  )
}

export default GroupSettingsSchoolsRostering
