import React from "react"
import styled from "@emotion/styled"
import { calendarEventsColors } from "../../../library/constants/calendar"
import CheckIcon from "@material-ui/icons/CheckCircle"

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  width: 100%;
`

const MeetTypeButton = styled.button`
  position: relative;
  padding: 15px;
  border: 2px solid ${(props) => props.borderColor};
  border-radius: 4px;

  svg {
    position: absolute;
    top: 5px;
    right: 5px;
    color: ${(props) => props.borderColor};
  }
`

const MeetTypeSwitcher = ({ availableTypes = [], value, onChange }) => {
  return (
    <Container>
      {availableTypes.map((v, i) => {
        const isActive = value === v
        return (
          <MeetTypeButton
            key={v}
            onClick={() => onChange(v)}
            borderColor={calendarEventsColors[i].hex}
            isActive={isActive}
          >
            <p className={"mb5"}>{v}-Minute Meeting</p>
            <p className={"color-black-54"}>Set for {v} minutes meeting</p>
            {isActive && <CheckIcon />}
          </MeetTypeButton>
        )
      })}
    </Container>
  )
}

export default MeetTypeSwitcher
