import React, { useEffect, useRef, useState } from "react"
import TextField from "@material-ui/core/TextField"
import styled from "@emotion/styled"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import InputAdornment from "@material-ui/core/InputAdornment"
import { ArrowDropDown } from "@material-ui/icons"

const SuggestionContainer = styled.div`
  max-height: 200px;
  margin-top: 3px;
  overflow-y: auto;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  z-index: 2;
  position: absolute;
  width: 100%;
  background: #fff;
`

const SuggestionItem = styled.div`
  cursor: pointer;
  padding: 6px 16px;
`

const NoResultsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  padding: 0 6px;
  text-align: center;
  align-content: center;
  align-items: center;

  span {
    width: 100%;
  }
`

const MultiSelect = (props) => {
  const {
    list = [],
    isMulti,
    defaultValue,
    onScroll,
    onInputChange,
    onChange,
    placeholder = '',
    emptyArrayMessage = 'No results',
    disabled,
  } = props
  const stateDefaultValue = defaultValue
    ? Array.isArray(defaultValue)
      ? defaultValue
      : [defaultValue]
    : []
  const suggestions = list.map((suggestion) => ({
    value: suggestion.id,
    label: suggestion.name,
  }))
  const input = useRef()
  const [values, setValues] = useState(stateDefaultValue)
  const [value, setValue] = useState('')
  const [shouldRemove, setShouldRemove] = useState(false)
  const [showList, setShowList] = useState(false)

  const setCaretPosition = () => {
    const element = input.current
    if (element) {
      const pos = element.value.length
      if (element.createTextRange) {
        const range = element.createTextRange()
        return range.move('character', pos)
      }
      if (element.selectionStart) {
        element.focus()
        return element.setSelectionRange(pos, pos)
      }
      return element.focus()
    }
  }

  const handleInputChange = (e) => {
    setCaretPosition()
    const value = e.target.value
      .replace(values.map((i) => i.label).join(', '), '')
      .trimLeft()
    onInputChange && onInputChange(value)
    setValue(value)
  }

  const handleBackSpace = (e, currentValue) => {
    if (e.keyCode === 37 || e.keyCode === 39) {
      return setCaretPosition()
    }
    if ((e.keyCode === 8 || e.keyCode === 229) && !currentValue) {
      if (shouldRemove) {
        const newValues = [...values]
        newValues.pop()
        setValues(newValues)
      }
      return setShouldRemove(true)
    }
    setShouldRemove(false)
  }

  const handleInputFocus = () => {
    if (disabled) return
    setCaretPosition()
    setShowList(true)
  }

  const handleSelectChange = (values) => {
    onChange && onChange(isMulti ? values : values[0])
    setValues(values)
  }

  const renderValue = `${values.map((i) => i.label).join(', ')}${
    values.length || value ? ' ' + value : ''
  }`
  return (
    <ClickAwayListener onClickAway={() => setShowList(false)}>
      <div style={{ position: 'relative' }} onClick={handleInputFocus}>
        <TextField
          inputProps={{ ref: input }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ArrowDropDown className="color-black-54" />
              </InputAdornment>
            ),
          }}
          disabled={disabled}
          fullWidth
          placeholder={placeholder}
          value={renderValue}
          onKeyDown={(e) => handleBackSpace(e, value)}
          onChange={handleInputChange}
          multiline
        />
        {showList && (
          <SuggestionList
            list={suggestions}
            values={values}
            isMulti={isMulti}
            setValues={handleSelectChange}
            onScroll={onScroll}
            emptyArrayMessage={emptyArrayMessage}
          />
        )}
      </div>
    </ClickAwayListener>
  )
}

const SuggestionList = (props) => {
  const {
    list = [],
    values = [],
    isMulti,
    setValues,
    onScroll,
    emptyArrayMessage,
  } = props
  const [renderList, setRenderList] = useState([])
  const container = useRef()

  useEffect(() => {
    const renderList = list.map((item) => {
      item.isSelected = !!values.find((val) => val.value === item.value)
      return item
    })

    setRenderList(renderList)
  }, [values, list])

  const addElement = (element) => {
    if (isMulti) {
      const newValues = [...values]
      newValues.push(element)
      return setValues(newValues)
    }
    return setValues([element])
  }

  const removeElement = (element) => {
    const index = values.findIndex((el) => el.value === element.value)
    const newValues = [...values]
    newValues.splice(index, 1)
    return setValues([...newValues])
  }

  const handleElementClick = (element) => {
    element.isSelected ? removeElement(element) : addElement(element)
  }

  const scrollHandler = () => {
    if (container && onScroll) {
      const element = container.current

      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        onScroll()
      }
    }
  }

  return (
    <SuggestionContainer onScroll={scrollHandler} ref={container}>
      {renderList.map((item) => {
        return (
          <SuggestionItem
            style={{ background: item.isSelected ? '#ccc' : '#fff' }}
            key={item.value}
            onClick={() => handleElementClick(item)}
          >
            {item.label}
          </SuggestionItem>
        )
      })}
      {!renderList.length && (
        <NoResultsContainer>
          <span>{emptyArrayMessage}</span>
        </NoResultsContainer>
      )}
    </SuggestionContainer>
  )
}

export default MultiSelect
