import React from "react"
import Card from "./card"
import { generatePath, useParams } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import { weekDaysArray } from "../../../../library/utils/timeConvert"
import { rotationTypes } from "../../../../library/constants/groupTypes"
import { renderArrayOfNames } from "../../../../library/utils/arrays"
import {
  CARD_SIZES,
  getCardSize,
} from "../../../../library/constants/styles/cardSizes"

const SectionCard = ({ section, openEditSection, openDeleteSection }) => {
  const params = useParams()

  const sectionUrl = generatePath(
    routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT_SECTION_TRACKS,
    {
      ...params,
      sectionId: section.id,
    }
  )

  const dropdownMenuItems = [
    {
      type: "button",
      action: openEditSection,
      label: "Edit section",
    },
    {
      type: "h-divider",
      id: "hd1",
    },
    {
      type: "button",
      action: openDeleteSection,
      label: "Delete section",
    },
  ]

  const rotationsInfo = () => {
    const data = section.rotations.reduce(
      (acc, cur, i) => {
        return {
          ...acc,
          [cur]: [...acc[cur], { id: i, name: weekDaysArray[i] }],
        }
      },
      { 1: [], 2: [], 3: [], 4: [] }
    )

    return (
      <div className={"space-y-1"}>
        {[1, 2, 3, 4].map((type, i) => {
          const rotationType = rotationTypes.find((t) => t.id === type).name
          return (
            <p className={"color-black-54"} key={type}>
              {rotationType}:{" "}
              {!data[type].length
                ? "—"
                : data[type].length === 7
                  ? "Whole week"
                  : renderArrayOfNames(data[type])}
            </p>
          )
        })}
      </div>
    )
  }

  return (
    <Card
      size={getCardSize(11).numValPx}
      name={section.name}
      link={sectionUrl}
      // info={!!group.description && group.description !== 'Unknown' && group.description}
      infoComponent={rotationsInfo}
      controls={{
        dropdownMenu: {
          items: dropdownMenuItems,
        },
      }}
    />
  )
}

export default SectionCard
