import styled from "@emotion/styled"
import { colors } from "../../../../../library/constants/styles/colors"
import { blinkAnimation } from "../../../../../library/constants/styles/animation"

export const AvatarSkeleton = styled.div`
  width: ${({ size }) => size || "60px"};
  min-width: ${({ size }) => size || "60px"};
  height: ${({ size }) => size || "60px"};
  margin-right: ${({ mr }) => mr || "10px"};
  border-radius: 50%;
  background: linear-gradient(
    to right,
    ${colors.black7} 0%,
    ${colors.black4} 50%,
    ${colors.black7} 100%
  );
  background-size: 150% 150%;
  animation: ${blinkAnimation} 2s ease-in-out infinite;
`
