import { call } from "../../../networking/API"
import { BOOKMARKS } from "../types/bookmarksTypes"

export const getBookmarksList = ({
  folder_id,
  users_limit = 9,
  groups_limit = 9,
  products_limit = 5,
  knowcreds_limit = 5,
  standards_limit = 1,
  posts_limit = 1,
  records_limit = 10,
}) => {
  return async (dispatch, getState) => {
    const response = await call(BOOKMARKS.LIST, {
      token: getState().auth.userData.token,
      folder_id,
      users_limit,
      groups_limit,
      products_limit,
      knowcreds_limit,
      standards_limit,
      posts_limit,
      records_limit,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: folder_id ? BOOKMARKS.FOLDERS.GET : BOOKMARKS.LIST,
        payload: json,
      })
    }
  }
}

export const createBookmark = ({user_id, group_id, product_id, post_id, knowcred_id, standard_id, record_id}) => {
    return async (dispatch, getState) => {
        const response = await call(BOOKMARKS.CREATE, {
            token: getState().auth.userData.token,
            user_id,
            group_id,
            product_id,
            post_id,
            knowcred_id,
            standard_id,
            record_id,
        });

        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: BOOKMARKS.CREATE,
                payload: json,
            });
        }
    };
};

export const deleteBookmark = ({user_id, group_id, product_id, post_id, knowcred_id, standard_id, record_id}) => {
    return async (dispatch, getState) => {
        const response = await call(BOOKMARKS.DELETE, {
            token: getState().auth.userData.token,
            user_id,
            group_id,
            product_id,
            post_id,
            knowcred_id,
            standard_id,
            record_id,
        });

        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: BOOKMARKS.DELETE,
                payload: json,
            });
        }
    };
};

export const bookmarkFoldersList = (id) => {
    return async (dispatch, getState) => {
        const response = await call(BOOKMARKS.GET_FOLDERS, {
            token: getState().auth.userData.token,
            id,
        });

        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: BOOKMARKS.GET_FOLDERS,
                payload: json.folders,
            });
        }
    };
};

export const bookmarksListClear = () => {
    return {
        type: BOOKMARKS.CLEAR,
    };
};

export const createFolder = ({name}) => {
    return async (dispatch, getState) => {
        const response = await call(BOOKMARKS.FOLDERS.CREATE, {
            token: getState().auth.userData.token,
            name,
        });

        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: BOOKMARKS.FOLDERS.CREATE,
                payload: json,
            });
        }
    };
};

export const updateFolder = ({id, name}) => {
    return async (dispatch, getState) => {
        const response = await call(BOOKMARKS.FOLDERS.UPDATE, {
            token: getState().auth.userData.token,
            id,
            name,
        });

        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: BOOKMARKS.FOLDERS.UPDATE,
                payload: json,
            });
        }
    };
};

export const deleteFolder = ({id}) => {
    return async (dispatch, getState) => {
        const response = await call(BOOKMARKS.FOLDERS.DELETE, {
            token: getState().auth.userData.token,
            id,
        });

        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: BOOKMARKS.FOLDERS.DELETE,
                payload: json,
            });
        }
    };
};

export const getFolder = ({
                              folder_id,
                              users_limit = 9,
                              groups_limit = 9,
                              products_limit = 5,
                              knowcreds_limit = 5,
                              standards_limit = 1,
                              posts_limit = 1,
                          }) => {
    return async (dispatch, getState) => {
        const response = await call(BOOKMARKS.LIST, {
            token: getState().auth.userData.token,
            folder_id,
            users_limit,
            groups_limit,
            products_limit,
            knowcreds_limit,
            standards_limit,
            posts_limit,
        });

        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: BOOKMARKS.FOLDERS.GET,
                payload: json,
            });
        }
    };
};

export const foldersList = () => {
    return async (dispatch, getState) => {
        const response = await call(BOOKMARKS.FOLDERS.LIST, {
            token: getState().auth.userData.token,
        });

        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: BOOKMARKS.FOLDERS.LIST,
                payload: json.folders,
            });
        }
    };
};

export const bindFolder = ({bookmark_id, folders}) => {
    return async (dispatch, getState) => {
        const response = await call(BOOKMARKS.FOLDERS.BIND, {
            token: getState().auth.userData.token,
            bookmark_id,
            folders,
        });

        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: BOOKMARKS.FOLDERS.BIND,
                payload: json,
            });
        }
    };
};

export const unbindFolder = ({bookmark_id, folder_id}) => {
    return async (dispatch, getState) => {
        const response = await call(BOOKMARKS.FOLDERS.UNBIND, {
            token: getState().auth.userData.token,
            bookmark_id,
            folder_id,
        });

        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: BOOKMARKS.FOLDERS.UNBIND,
                payload: json,
            });
        }
    };
};

export const bookmarksFoldersListClear = () => {
    return {
        type: BOOKMARKS.CLEAR_FOLDERS,
    };
};
