import React, { Component } from "react"
import "../../knowCred/knowCredForm/knowCredForm.scss"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getFriendsList } from "../../../../library/store/actions/creators/friendsCreators"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import {
  knowCredBorderColors,
  knowCredLocations,
  knowCredTypesIds,
  knowCredTypesList,
} from "../../../../library/constants/knowCred"
import FormControl from "@material-ui/core/FormControl"
import { Button } from "@material-ui/core"
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons"
import * as routes from "../../../../library/constants/routes"
import { generatePath, Link } from "react-router-dom"
import { withSnackbar } from "notistack"
import {
  clearCred,
  getCrediconBackgrounds,
  getCrediconForegrounds,
  knowCredCreate,
  knowCredEdit,
  knowCredGet,
} from "../../../../library/store/actions/creators/knowCredsCreators"
import Loader from "../../../../components/ui/loader"
import { urlValidation } from "../../../../library/utils/validators"
import { getObjectById } from "../../../../library/utils/arrays"
import FormHelperText from "@material-ui/core/es/FormHelperText/FormHelperText"
import getErrorText from "../../../../library/constants/errorTypes"
import LinesEllipsis from "react-lines-ellipsis"
import logoDefault from "../../../../assets/img/product.svg"
import { MultipleTextField } from "../../../../components/formComponents"
import ForegroundsPicker from "../../knowCred/knowCredForm/components/foregroundsPicker"
import BackgroundsPicker from "../../knowCred/knowCredForm/components/backgroundsPicker"
import SEO from "../../../../components/SEO"

class KnowmixKnowcredForm extends Component {
  state = {
    user: this.props.location.state.user,
    title: "",
    type: "",
    typeError: false,
    activity: "",
    activityError: false,
    activitiesList: [],
    location: "",
    description: "",
    links: [],
    customForegroundImage: "",
    foregroundImage: "",
    fgSource: "library",
    customBackgroundImage: "",
    backgroundImage: "",
    bgSource: "library",
    borderColor: knowCredBorderColors[0],
    dataReceived: false,
  }

  async componentDidMount() {
    ValidatorForm.addValidationRule("isLink", (value) => urlValidation(value))

    try {
      await this.props.getFriendsList({})

      this.setState({
        dataReceived: true,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  componentWillUnmount() {
    this.props.clearCred()
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    })
  }

  handleChangeRequired = (name, event) => {
    switch (name) {
      case "userId":
        this.setState({
          userIdError: event.target.value.length === 0,
          userId: event.target.value,
        })
        break

      case "type":
        this.setState({
          type: event.target.value.id || "",
          typeError: !event.target.value.id,
          activitiesList: getObjectById(
            knowCredTypesList,
            event.target.value.id
          ).activities,
          activity: [1, 5].includes(event.target.value.id) ? 1 : "",
        })
        break

      case "activity":
        this.setState({
          activityError: event.target.value.length === 0,
          activity: event.target.value,
        })
        break

      default:
        this.setState({
          [name]: event.target.value,
        })
        break
    }
  }

  validateSelects = async () => {
    const { type, activity } = this.state

    if (type === "") {
      this.setState({ typeError: true })
    }

    if (type !== "" && ![1, 5].includes(type) && activity === "") {
      this.setState({ activityError: true })
    }
  }

  processInfoSave = async () => {
    const {
      user,
      title,
      description,
      type,
      typeError,
      activity,
      activityError,
      location,
      links,
      customBackgroundImage,
      backgroundImage,
      customForegroundImage,
      foregroundImage,
      bgSource,
      fgSource,
      borderColor: border_color,
    } = this.state

    if (typeError || activityError) return

    const data = {
      knowmix_id: this.props.location.state.knowmix.id,
      user_id: user.id,
      title,
      description,
      type,
      activity: ![1, 5].includes(type) ? activity : 1,
      location,
      links: links.filter((link) => link !== ""),
      foreground_image:
        fgSource === "own" && !!customForegroundImage
          ? customForegroundImage
          : foregroundImage,
      background_image:
        bgSource === "own" && !!customBackgroundImage
          ? customBackgroundImage
          : backgroundImage,
      border_color,
    }

    try {
      await this.props.knowCredCreate(data)
      this.props.history.push({
        pathname: generatePath(routes.KNOWCRED_SINGLE, {
          knowCredId: this.props.knowCreds.createdCred,
        }),
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  render() {
    const {
      user,
      title,
      description,
      type,
      typeError,
      activity,
      activityError,
      activitiesList,
      location,
      links,
      hasCustomForegroundImage,
      customForegroundImage,
      foregroundImage,
      foregroundsList,
      hasMoreForegrounds,
      hasCustomBackgroundImage,
      customBackgroundImage,
      backgroundImage,
      backgroundsList,
      hasMoreBackgrounds,
      borderColor,
      dataReceived,
    } = this.state

    const pathOnCancel = ""

    const hideActivitiesField = [1, 5]
    const showSubtitleLabel = [3, 4]

    return (
      <div className="common-page__content">
        <SEO title={"New Course Cred"} />
        <main className="common-page__main">
          {dataReceived ? (
            <div className="box">
              <div className="box__heading">
                <h1>Create Cred</h1>
              </div>
              <div className="box__content">
                <ValidatorForm
                  className="form knowcred-form"
                  onSubmit={this.processInfoSave}
                >
                  <div className="form__fieldset">
                    <label htmlFor="user-id" className="form__label">
                      Select who is Cred for *
                    </label>
                    <div className="form__fieldbox">
                      <div className="form__input form__input--select">
                        <Select
                          id="user-id"
                          name="userId"
                          fullWidth
                          displayEmpty
                          value={user}
                          onChange={(e) =>
                            this.handleChangeRequired("userId", e)
                          }
                          renderValue={(value) => {
                            return `${user.first_name} ${user.last_name}`
                          }}
                          disabled
                        >
                          <MenuItem value={user} style={{ maxWidth: "250px" }}>
                            <LinesEllipsis
                              text={`${user.first_name} ${user.last_name}`}
                              maxLine="1"
                              ellipsis="..."
                              trimRight
                              basedOn="letters"
                            />
                          </MenuItem>
                        </Select>
                      </div>
                    </div>
                  </div>

                  <div className="form__fieldset">
                    <label htmlFor="title" className="form__label">
                      KnowCred name *
                    </label>
                    <div className="form__fieldbox">
                      <div className="form__input">
                        <TextValidator
                          id="title"
                          name="title"
                          value={title}
                          placeholder="Type KnowCred name"
                          className="edit-form__input"
                          margin="normal"
                          fullWidth
                          validators={[
                            "required",
                            "minStringLength:3",
                            "maxStringLength:100",
                          ]}
                          errorMessages={[
                            "Field is required",
                            "Min length is 3 characters",
                            "Max length is 100 characters",
                          ]}
                          withRequiredValidator
                          onChange={this.handleChange("title")}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form__fieldset">
                    <label htmlFor="type" className="form__label">
                      Type of Cred *
                    </label>
                    <div className="form__fieldbox">
                      <div className="form__input form__input--select">
                        <FormControl error={typeError} fullWidth>
                          <Select
                            id="type"
                            name="type"
                            fullWidth
                            value={type}
                            displayEmpty
                            renderValue={(value) => {
                              if (!value) {
                                return (
                                  <span className="color-black-38">
                                    Select a type of Cred
                                  </span>
                                )
                              }
                              return knowCredTypesIds[type]
                            }}
                            onChange={(e) =>
                              this.handleChangeRequired("type", e)
                            }
                          >
                            {knowCredTypesList.map((item) => {
                              return (
                                <MenuItem value={item} key={item.id}>
                                  {item.name}
                                </MenuItem>
                              )
                            })}
                          </Select>
                          {typeError && (
                            <FormHelperText>Field is required</FormHelperText>
                          )}
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  {hideActivitiesField.includes(type) || !type ? null : (
                    <div className="form__fieldset">
                      <label htmlFor="activity" className="form__label">
                        {showSubtitleLabel.includes(type)
                          ? "Label"
                          : "Activity"}{" "}
                        *
                      </label>
                      <div className="form__fieldbox">
                        <div className="form__input form__input--select">
                          <FormControl error={activityError} fullWidth>
                            <Select
                              id="activity"
                              name="activity"
                              fullWidth
                              value={activity}
                              displayEmpty
                              onChange={(e) =>
                                this.handleChangeRequired("activity", e)
                              }
                              renderValue={(value) => {
                                if (value.length === 0) {
                                  return (
                                    <span className="color-black-38">
                                      Select an activity
                                    </span>
                                  )
                                }

                                return getObjectById(activitiesList, value).name
                              }}
                            >
                              {activitiesList.map((item) => (
                                <MenuItem value={item.id} key={item.id}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                            {activityError && (
                              <FormHelperText>Field is required</FormHelperText>
                            )}
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="form__fieldset">
                    <label htmlFor="location" className="form__label">
                      Location
                    </label>
                    <div className="form__fieldbox">
                      <div className="form__input form__input--select">
                        <Select
                          id="location"
                          name="location"
                          fullWidth
                          displayEmpty
                          value={location}
                          onChange={this.handleChange("location")}
                        >
                          <MenuItem value="">
                            <span className="color-black-38">
                              Select location
                            </span>
                          </MenuItem>
                          {knowCredLocations.map((item) => (
                            <MenuItem value={item.name} key={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>

                  <div className="form__fieldset">
                    <label htmlFor="description" className="form__label">
                      Description of activities
                    </label>
                    <div className="form__fieldbox">
                      <div className="form__input">
                        <TextValidator
                          id="description"
                          name="description"
                          value={description}
                          placeholder="Write a short description of activities"
                          className="edit-form__input"
                          margin="normal"
                          fullWidth
                          multiline
                          validators={["maxStringLength:2000"]}
                          errorMessages={["Max length is 2000 characters"]}
                          withRequiredValidator
                          onChange={this.handleChange("description")}
                        />
                      </div>
                    </div>
                  </div>

                  <MultipleTextField
                    values={links}
                    setValues={(v) => this.setState({ links: v })}
                    limit={10}
                    fieldLabel="Additional links"
                    fieldPlaceholder="Type URL"
                    addButtonLabel="Add link"
                    validators={["isLink", "maxStringLength:250"]}
                    validatorMessages={[
                      "Invalid URL",
                      "Max length is 250 characters",
                    ]}
                  />

                  {!!type && (
                    <ForegroundsPicker
                      libraryImage={foregroundImage}
                      savedLibraryImage={
                        this.props.knowCreds?.currentKnowCred?.foreground_image
                      }
                      onChangeLibraryImage={(v) =>
                        this.setState({ foregroundImage: v })
                      }
                      ownImage={customForegroundImage}
                      onChangeOwnImage={(v) =>
                        this.setState({ customForegroundImage: v })
                      }
                      onImageSourceChange={(v) =>
                        this.setState({ fgSource: v })
                      }
                      type={type}
                      activity={activity}
                    />
                  )}

                  <BackgroundsPicker
                    libraryImage={backgroundImage}
                    savedLibraryImage={
                      this.props.knowCreds?.currentKnowCred?.background_image
                    }
                    onChangeLibraryImage={(v) =>
                      this.setState({ backgroundImage: v })
                    }
                    ownImage={customBackgroundImage}
                    onChangeOwnImage={(v) =>
                      this.setState({ customBackgroundImage: v })
                    }
                    onImageSourceChange={(v) => this.setState({ bgSource: v })}
                  />

                  <div className="form__fieldset">
                    <label
                      htmlFor="custom-background-image"
                      className="form__label"
                    >
                      Border color for credicon *
                    </label>
                    <div className="f aife">
                      <div className="f jcfs aic">
                        {knowCredBorderColors.map((color) => (
                          <div className="f" key={color}>
                            {borderColor !== color ? (
                              <CheckBoxOutlineBlank
                                style={{ color }}
                                className="pointer"
                                onClick={() =>
                                  this.setState({ borderColor: color })
                                }
                              />
                            ) : (
                              <CheckBox style={{ color }} />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="btn-group jcfe pl15 pr15">
                    <Button
                      color="primary"
                      variant="outlined"
                      component={Link}
                      to={pathOnCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      onClick={this.validateSelects}
                    >
                      Save
                    </Button>
                  </div>
                </ValidatorForm>
              </div>
            </div>
          ) : (
            <Loader />
          )}
        </main>
        <aside className="common-page__sidebar">
          {dataReceived && (
            <div className="box aside-header">
              <Link
                to={generatePath(routes.KNOWMIX, {
                  knowmixId: this.props.location.state.knowmix.id,
                })}
                className="box__content aside-header__content"
              >
                <img
                  src={this.props.location.state.knowmix.photo || logoDefault}
                  className="aside-header__img"
                  alt=""
                />
                <div className="aside-header__info">
                  <div className="aside-header__name">
                    {this.props.location.state.knowmix.name}
                  </div>
                  <div className="aside-header__help">back to KnowMix</div>
                </div>
              </Link>
            </div>
          )}
        </aside>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, friends, knowCreds }) => ({
  auth,
  friends,
  knowCreds,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getFriendsList,
      knowCredGet,
      getCrediconBackgrounds,
      getCrediconForegrounds,
      knowCredEdit,
      knowCredCreate,
      clearCred,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(KnowmixKnowcredForm))
