import React from "react"
import { Transition } from "../groups/group/publicCalendar/publicCalendar"
import CalendarProvider from "./CalendarContext"
import ErrorBoundary from "../../../components/v2/ErrorBoundary"
import EventsCalendar from "./components/eventsCalendar"
import Dialog from "@material-ui/core/Dialog"

const UserCalendarContext = React.createContext(null)
export const useUserCalendar = () => React.useContext(UserCalendarContext)

const UserCalendarProvider = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const onCalendarOpen = () => setIsOpen(true)
  const onCalendarClose = () => setIsOpen(false)

  return (
    <UserCalendarContext.Provider value={{ onCalendarOpen, onCalendarClose }}>
      {children}

      {isOpen && (
        <Dialog
          fullScreen
          open={isOpen}
          onClose={onCalendarClose}
          TransitionComponent={Transition}
        >
          <CalendarProvider>
            <ErrorBoundary>
              <EventsCalendar
                calendarStyle={{ minHeight: 800 }}
                isFullScreen
                onClose={onCalendarClose}
              />
            </ErrorBoundary>
          </CalendarProvider>
        </Dialog>
      )}
    </UserCalendarContext.Provider>
  )
}

export default UserCalendarProvider
