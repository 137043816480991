export const colors = {
  primary: "#49853f",
  secondary: "#37633a",
  white: "#fff",
  orange: "#dc9940",
  danger: "#f44336",
  grey2: "#9b9a9b",
  grey3: "#b8b8b8",
  grey7: "#fafafa",
  black2: "rgba(0, 0, 0, .02)",
  black4: "rgba(0, 0, 0, .04)",
  black7: "rgba(0, 0, 0, .07)",
  black12: "rgba(0, 0, 0, .12)",
  black38: "rgba(0, 0, 0, .38)",
  black54: "rgba(0, 0, 0, .54)",
  black87: "rgba(0, 0, 0, .87)",
  primary15: "rgba(73, 133, 63, .15)",
  primary5: "rgba(73, 133, 63, .5)",
}
