import React, { useEffect, useState } from "react"
import Loader from "../../../../../components/ui/loader/loader"
import { generatePath, Link, useLocation, withRouter } from "react-router-dom"
import * as routes from "../../../../../library/constants/routes"
import logoDefault from "../../../../../assets/img/product.svg"
import { bindActionCreators } from "redux"
import {
  clearCurrentKnowmix,
  getKnowmix,
  getKnowMixProgress,
  knowmixGrade,
} from "../../../../../library/store/actions/creators/knowmixCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import getErrorText from "../../../../../library/constants/errorTypes"
import styled from "@emotion/styled"
import { colors } from "../../../../../library/constants/styles/colors"
import CheckOutlined from "@material-ui/icons/CheckOutlined"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp"
import PlainLink from "../../../../../components/ui/link/plainLink"
import KnowMixGradeForm from "../knowMixGradeForm"
import Button from "@material-ui/core/Button"
import { devices } from "../../../../../library/constants/styles/responsive"
import * as qs from "query-string"
import { getUserName } from "../../../../../library/utils/user/userUtils"
import { getObjectById } from "../../../../../library/utils/arrays"
import { calendarGrades } from "../../../../../library/constants/educationalDictionary"
import SEO from "../../../../../components/SEO"

const Container = styled.div`
  padding: 15px;
  border-radius: 2px;
  background-color: ${colors.white};
  box-shadow: 0 0 2px 0 ${colors.black12};
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  @media ${devices.xs} {
    flex-direction: column;
    align-items: flex-start;
  }
`

const Status = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.black54};

  span {
    margin-right: 15px;
  }

  svg:first-of-type {
    color: ${colors.primary};
  }
`

const Content = styled.div`
  padding-top: 15px;
`

const Td = styled.td`
  vertical-align: baseline;
`

const KnowMixFullCheck = (props) => {
  const location = useLocation()
  const [dataReceived, setDataReceived] = useState(false)
  const [activeStep, setActiveStep] = useState(null)
  const [redirect, setRedirect] = useState(false)
  const [grade, setGrade] = useState("")
  const [comment, setComment] = useState("")
  const { gradesStepsList, currentKnowmix } = props.knowmix

  useEffect(() => {
    getKnowmix()

    return () => props.clearCurrentKnowmix()
  }, [])

  const changeGrade = (e) => {
    setGrade(e.target.value)
  }
  const changeComment = (e) => {
    setComment(e.target.value)
  }
  const submitGrade = async () => {
    const knowmix_id = parseInt(props.match.params.knowmixId)
    const user_id = parseInt(props.match.params.learnerId)
    try {
      await props.knowmixGrade({
        knowmix_id,
        user_id,
        comment: comment || undefined,
        grade,
      })
      await getKnowmix()
    } catch ({ error }) {
      if (error.code === 20004) {
        props.enqueueSnackbar("KnowMix already graded", { variant: "error" })
      } else {
        props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
      }
    }
  }

  const getKnowmix = async () => {
    const { stepId } = qs.parse(location.search)

    try {
      const knowmix_id = parseInt(props.match.params.knowmixId)
      const user_id = parseInt(props.match.params.learnerId)
      await props.getKnowMixProgress({ user_id, knowmix_id })
      await props.getKnowmix({ id: knowmix_id })

      if (stepId) setActiveStep(parseInt(stepId, 10))

      setDataReceived(true)
    } catch ({ error }) {
      if (error.code === 23000) {
        setRedirect(true)
      } else {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    }
  }

  const createKnowcred = () => {
    props.history.push(
      generatePath(routes.KNOWMIX_CREATE_KNOWCRED, {
        knowmixId: currentKnowmix.id,
      }),
      {
        user: gradesStepsList.learner,
        knowmix: currentKnowmix,
      }
    )
  }

  // if (redirect || (dataReceived && (
  //     !props.knowmix.currentKnowmix.is_creator
  //     || props.knowmix.currentKnowmix.blocked
  //     || props.knowmix.currentKnowmix.deleted
  // ))) {
  //     return <Redirect to={generatePath(routes.KNOWMIX, {knowmixId: props.match.params.knowmixId})}/>
  // }

  return (
    <div className="common-page__content">
      <SEO title={"Course Check"} />
      <main className="common-page__main">
        {dataReceived ? (
          <>
            <div className="box">
              <div className="box__heading">
                <h1>{props.knowmix.gradesStepsList.name}</h1>
              </div>
              <div className="box__content">
                <table className="product-main-info__table product-main-info__table--kx-check">
                  <tbody>
                    <tr>
                      <Td className="product-main-info__table-label pb8">
                        KnowMix:
                      </Td>
                      <td className="product-main-info pb8">
                        <PlainLink
                          to={generatePath(routes.KNOWMIX, {
                            knowmixId: currentKnowmix.id,
                          })}
                          newTab
                        >
                          {currentKnowmix.name}
                        </PlainLink>
                      </td>
                    </tr>
                    <tr>
                      <Td className="product-main-info__table-label pb8">
                        Subject:
                      </Td>
                      <td className="product-main-info pb8">
                        {`${
                          getObjectById(
                            calendarGrades,
                            currentKnowmix.subject.grade
                          ).name
                        } grade, ${currentKnowmix.subject.name}`}
                      </td>
                    </tr>
                    <tr>
                      <Td className="product-main-info__table-label pb8">
                        Measurement:
                      </Td>
                      <td className="product-main-info pb8">
                        {currentKnowmix?.measurement || "—"}
                      </td>
                    </tr>
                    <tr>
                      <Td className="product-main-info__table-label">
                        Learner:
                      </Td>
                      <td className="product-main-info">
                        <PlainLink
                          to={generatePath(routes.USER, {
                            userId: props.knowmix.gradesStepsList.id,
                          })}
                          newTab
                        >
                          {getUserName(props.knowmix.gradesStepsList.learner)}
                        </PlainLink>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="box">
              <div className="box__content">
                <p className="box__subheading">Grades for steps</p>
                {props.knowmix.gradesStepsList.steps.map((step, index) => {
                  return (
                    <Step
                      step={step}
                      stepNumber={index + 1}
                      isActive={step.id === activeStep}
                      key={step.id}
                    />
                  )
                })}
              </div>
            </div>
            {!gradesStepsList.grade &&
              !gradesStepsList.steps.find((s) => !s.grade) && (
                <KnowMixGradeForm
                  grade={grade}
                  comment={comment}
                  submit={submitGrade}
                  changeGrade={changeGrade}
                  changeComment={changeComment}
                />
              )}
            {gradesStepsList.grade && (
              <div className="box p15">
                <p className="mb15">Grades for KnowMix</p>
                <table className="product-details product-details--kx">
                  <tbody>
                    {!!gradesStepsList.grade && (
                      <tr className="product-details__row">
                        <td className="product-details__label">Grade:</td>
                        <td className="product-details__value">
                          {gradesStepsList.grade}
                        </td>
                      </tr>
                    )}
                    {!!gradesStepsList.comment && (
                      <tr className="product-details__row">
                        <td className="product-details__label">Comment:</td>
                        <td className="product-details__value">
                          {gradesStepsList.comment}
                        </td>
                      </tr>
                    )}
                    {!!gradesStepsList.examiner && (
                      <tr className="product-details__row">
                        <td className="product-details__label">Examiner:</td>
                        <td className="product-details__value">
                          <PlainLink
                            to={generatePath(routes.USER, {
                              userId: gradesStepsList.examiner.id,
                            })}
                          >
                            {`${gradesStepsList.examiner.first_name} ${gradesStepsList.examiner.last_name}`}
                          </PlainLink>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
            {gradesStepsList.grade && !gradesStepsList.knowcred && (
              <div className="box p15">
                <p className="mb15">KnowCred</p>
                <div className="box-content">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={createKnowcred}
                  >
                    Create Cred
                  </Button>
                </div>
              </div>
            )}
            {gradesStepsList.knowcred && (
              <div className="box p15">
                <p className="mb15">KnowCred</p>
                <div className="box-content">
                  <Button
                    variant="outlined"
                    component={Link}
                    to={generatePath(routes.KNOWCRED_SINGLE, {
                      knowCredId: gradesStepsList.knowcred.id,
                    })}
                    color="primary"
                  >
                    Created Knowcred
                  </Button>
                </div>
              </div>
            )}
          </>
        ) : (
          <Loader />
        )}
      </main>
      <aside className="common-page__sidebar">
        {dataReceived && (
          <div className="box aside-header">
            <Link
              to={generatePath(routes.SUBMITTED_WORKS_KNOWMIX_EXAMINED)}
              className="box__content aside-header__content"
            >
              <img
                src={props.knowmix.gradesStepsList.photo || logoDefault}
                className="aside-header__img"
                alt=""
              />
              <div className="aside-header__info">
                <div className="aside-header__name">
                  {props.knowmix.gradesStepsList.name}
                </div>
                <div className="aside-header__help">back</div>
              </div>
            </Link>
          </div>
        )}
      </aside>
    </div>
  )
}

const Step = ({ step, stepNumber, isActive = false }) => {
  const [open, setOpen] = useState(isActive)

  const toggle = () => {
    setOpen(!open)
  }

  return (
    <Container>
      <Header onClick={toggle}>
        <span style={{ maxWidth: "80%" }}>
          Step {stepNumber} - {step.name}
        </span>
        <Status>
          <span>
            {step.type === 3 && !!step.grade && <CheckOutlined />}
            {step.type === 3 && !step.grade && "Completed"}
            {step.type === 2 && "Continue"}
            {step.type === 1 && "Not started"}
          </span>
          {!open ? (
            <KeyboardArrowDown className="color-black-38" />
          ) : (
            <KeyboardArrowUp className="color-black-38" />
          )}
        </Status>
      </Header>
      {open && (
        <Content>
          {step.type === 3 && !!step.grade ? (
            <table className="product-details product-details--kx">
              <tbody>
                {!!step.grade && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Grade:</td>
                    <td className="product-details__value">{step.grade}</td>
                  </tr>
                )}
                {!!step.comment && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Comment:</td>
                    <td className="product-details__value">{step.comment}</td>
                  </tr>
                )}
                {!!step.examiner && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Examiner:</td>
                    <td className="product-details__value">
                      <PlainLink
                        to={generatePath(routes.USER, {
                          userId: step.examiner.id,
                        })}
                      >
                        {`${step.examiner.first_name} ${step.examiner.last_name}`}
                      </PlainLink>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          ) : (
            <span className="color-black-54">
              {step.type === 3 && !step.grade && "Step has not been graded yet"}
              {step.type === 2 && "Step has not been completed yet"}
              {step.type === 1 && "Step has not been started yet"}
            </span>
          )}
        </Content>
      )}
    </Container>
  )
}

const mapStateToProps = ({ auth, knowmix, standards }) => ({
  auth,
  knowmix,
  standards,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearCurrentKnowmix,
      knowmixGrade,
      getKnowMixProgress,
      getKnowmix,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(KnowMixFullCheck)))
