import { usePostCtx } from "../../PostContext"
import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../../../../library/constants/routes"
import { getUserName } from "../../../../../../library/utils/user/userUtils"
import logoDefault from "../../../../../../assets/img/product.svg"
import StarRating from "../../../../../ui/starRating"
import React from "react"
import { productContentTypes } from "../../../../../../library/constants/productDictionary"

const SharedProduct = () => {
  const { post } = usePostCtx()

  const product = post.shared_product

  const contentType = productContentTypes.filter((item) => {
    return item.id === product.content_type
  })[0]

  const creator = product.creator_user
    ? {
        name: getUserName(product.creator_user),
        url: generatePath(routes.USER, { userId: product.creator_user.id }),
      }
    : product.creator_group
    ? {
        name: product.creator_group.name,
        url: generatePath(routes.GROUP, { groupId: product.creator_group.id }),
      }
    : {}

  return (
    <div className="shared_content">
      <Link
        to={generatePath(routes.PRODUCT, { productId: product.id })}
        className="product_link"
      >
        <img src={product.image || logoDefault} alt="Product image" />
      </Link>
      <div className="product-tile">
        <Link
          to={generatePath(routes.PRODUCT, { productId: product.id })}
          className="product-tile__name link"
        >
          {product.name}
        </Link>
        <table className="product-tile__info">
          <tbody>
            <tr>
              <td className={"product-tile__info-label"}>
                <span>Creator:</span>
              </td>
              <td className={"product-tile__info-value"}>
                <Link to={creator.url || ""} className="link font-weight-500">
                  {creator.name}
                </Link>
              </td>
            </tr>
            {!!contentType && (
              <tr>
                <td className="product-tile__info-label pt4">Content type:</td>
                <td className="product-tile__info-value pt4">
                  {contentType.name}
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <StarRating rating={product.rating} />

        <div className="h-divider h-divider--vertical-margin"></div>
        {product.paid && (
          <>
            {!!product.user_price && (
              <div className="product-tile__price">
                <span className="product-tile__price-label">
                  Price per user:
                </span>
                <span className="product-tile__price-value">
                  ${product.user_price}
                </span>
              </div>
            )}
            {!!product.group_price && (
              <div className="product-tile__price">
                <span className="product-tile__price-label">
                  Price per school:
                </span>
                <span className="product-tile__price-value">
                  ${product.group_price}
                </span>
              </div>
            )}
            {!!product.others_price && (
              <div className="product-tile__price">
                <span className="product-tile__price-label">Other price:</span>
                <span className="product-tile__price-value">
                  ${product.others_price}
                </span>
              </div>
            )}
          </>
        )}
        {product.paid &&
          !product.user_price &&
          !product.group_price &&
          !product.others_price && (
            <div className="product-tile__price free">
              <span className="product-tile__price-value">Paid</span>
            </div>
          )}
        {!product.paid && (
          <div className="product-tile__price free">
            <span className="product-tile__price-value">Free</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default SharedProduct
