import React from "react"
import SectionFormModal from "./components/sectionFormModal/sectionFormModal"
import { useParams } from "react-router-dom"
import SectionCard from "../../../../../../../../../components/v2/layout/cards/sectionCard"
import SuperListPage from "../../../../../../../../../components/v2/layout/superListPage/superListPage"
import { SEGMENTS } from "../../../../../../../../../library/store/actions/types/groupsTypes"
import { useSectionMutations } from "../../../../../../../../../hooks/data/calendar/schedules"
import { useIdParam } from "../../../../../../../../../hooks/useIdParam"
import {
  CARD_SIZES,
  getCardSize,
} from "../../../../../../../../../library/constants/styles/cardSizes"

const SegmentSections = () => {
  const segment_id = useIdParam("segmentId")

  const queryKey = [SEGMENTS.SECTIONS.LIST, { segment_id }]

  const { deleteSection } = useSectionMutations(queryKey)

  return (
    <SuperListPage
      headerProps={{
        title: "Segment Sections",
        button: { label: "Create Section" },
      }}
      noResultsLabel={"This segment doesn't have sections yet"}
      queryConfig={{
        key: queryKey,
        fetchParams: { segment_id },
        listKey: "sections",
      }}
      listItemHeight={getCardSize(11).numValPx}
      getListItemComponent={({ item, openEditDialog, openDeletionDialog }) => (
        <SectionCard
          section={item}
          openDeleteSection={openDeletionDialog}
          openEditSection={openEditDialog}
          key={item.id}
        />
      )}
      getFormDialogComponent={({ open, onClose, activeItem }) => {
        return (
          <SectionFormModal
            activeSection={activeItem}
            open={open}
            onClose={onClose}
            edit={!!activeItem}
            queryKey={queryKey}
          />
        )
      }}
      getDeletionDialogComponent={{
        entityName: "section",
        onDelete: (id) => deleteSection.mutateAsync({ id }),
      }}
    />
  )
}

export default SegmentSections
