export const questionTypes = [
  {
    id: 1,
    name: "True/False",
    description:
      "Put in question and select if True or False is the correct answer before saving.",
  },
  {
    id: 2,
    name: "Multiple Choice",
    description:
      "Put in question and make each answer.  Using the little boxes next to each answer, select the right one before saving.",
  },
  {
    id: 3,
    name: "Answer or Essay",
    description:
      "Put in question and input answer or expectations of the essay.",
  },
  {
    id: 4,
    name: "Fill in the (Blank)",
    description:
      "Put in question instructions. Use constructor to add either text part or blank question part. Text part contains static part of a question. Put the right answer to Blank part. Available patterns are: Text_Blank_Text, Blank_Text and Text_Blank",
  },
  {
    id: 5,
    name: "Matching",
    description:
      "Put in question instructions. Add statements and answer options. Select matches for right answers.",
  },
]

export const knowmixTypes = {
  1: "Not started",
  2: "Started",
  3: "Finished",
}

export const stepStatusTypes = {
  1: "Not started",
  2: "Not completed",
  3: "Completed",
}
