import { INVENSTORY } from "../types/invenstoryTypes"
import { call } from "../../../networking/API"
import { BOOKMARKS } from "../types/bookmarksTypes"

export const createInvite = ({
  group_id,
  associated_group_id,
  associated_user_id,
} = {}) => {
  return async (dispatch, getState) => {
    const response = await call(INVENSTORY.INVITES.CREATE, {
      token: getState().auth.userData.token,
      group_id,
      associated_user_id,
      associated_group_id,
    })
    const json = await response.data
    if (response.status === 200) {
      dispatch({
        type: INVENSTORY.INVITES.CREATE,
      })
    } else {
      throw json.error
    }
  }
}

export const acceptInvite = ({group_id, associated_group_id} = {}) => {
    return async (dispatch, getState) => {
        const response = await call(INVENSTORY.INVITES.ACCEPT, {
            token: getState().auth.userData.token,
            group_id,
            associated_group_id,
        });
        const json = await response.data;
        if (response.status === 200) {
            dispatch({
                type: INVENSTORY.INVITES.ACCEPT,
            });
        } else {
            throw json.error;
        }
    };
};


export const declineInvite = ({group_id, associated_group_id} = {}) => {
    return async (dispatch, getState) => {
        const response = await call(INVENSTORY.INVITES.DECLINE, {
            token: getState().auth.userData.token,
            group_id,
            associated_group_id,
        });
        const json = await response.data;
        if (response.status === 200) {
            dispatch({
                type: INVENSTORY.INVITES.DECLINE,
            });
        } else {
            throw json.error;
        }
    };
};

export const cancelInvite = ({group_id, associated_group_id, associated_user_id} = {}) => {
    return async (dispatch, getState) => {
        const response = await call(INVENSTORY.INVITES.CANCEL, {
            token: getState().auth.userData.token,
            group_id,
            associated_user_id,
            associated_group_id,
        });
        const json = await response.data;
        if (response.status === 200) {
            dispatch({
                type: INVENSTORY.INVITES.CANCEL,
            });
        } else {
            throw json.error;
        }
    };
};

export const getConnectionsList = ({name, group_id, type, offset = 0, limit = 50} = {}) => {
    return async (dispatch, getState) => {
        const response = await call(INVENSTORY.CONNECTIONS.LIST, {
            token: getState().auth.userData.token,
            group_id,
            type,
            name,
            offset,
            limit,
        });
        const json = await response.data;
        if (response.status === 200) {
            dispatch({
                type: INVENSTORY.CONNECTIONS.LIST,
                payload: json,
            });
        } else {
            throw json.error;
        }
    };
};

export const updateConnectionsList = ({name, group_id, type, offset = 0, limit = 50}) => {
    return async (dispatch, getState) => {
        const response = await call(INVENSTORY.CONNECTIONS.LIST, {
            token: getState().auth.userData.token,
            group_id,
            type,
            name,
            offset,
            limit,
        });
        const json = await response.data;
        if (response.status === 200) {
            dispatch({
                type: INVENSTORY.CONNECTIONS.LIST_UPDATE,
                payload: json,
            });
        } else {
            throw json.error;
        }
    };
};

export const removeConnection = ({group_id, associated_group_id, associated_user_id} = {}) => {
    return async (dispatch, getState) => {
        const response = await call(INVENSTORY.CONNECTIONS.REMOVE, {
            token: getState().auth.userData.token,
            group_id,
            associated_group_id,
            associated_user_id,
        });
        const json = await response.data;
        if (response.status === 200) {
            dispatch({
                type: INVENSTORY.CONNECTIONS.REMOVE,
            });
        } else {
            throw json.error;
        }
    };
};

export const restrictConnection = ({group_id, associated_group_id} = {}) => {
    return async (dispatch, getState) => {
        const response = await call(INVENSTORY.CONNECTIONS.RESTRICT, {
            token: getState().auth.userData.token,
            group_id,
            associated_group_id,
        });
        const json = await response.data;
        if (response.status === 200) {
            dispatch({
                type: INVENSTORY.CONNECTIONS.RESTRICT,
            });
        } else {
            throw json.error;
        }
    };
};

export const clearInvenstory = () => {
    return {
        type: INVENSTORY.CLEAR,
    };
};

export const createInvenstoryRecord = ({name, group_id, subjects, content_type, grades, platforms, file_types, standards, url, version, product_id, image}) => {
    return async (dispatch, getState) => {
        const response = await call(INVENSTORY.RECORDS.CREATE, {
            token: getState().auth.userData.token,
            name,
            group_id,
            subjects,
            content_type,
            grades,
            platforms,
            file_types,
            standards,
            url,
            version,
            product_id,
            image,
        });

        const json = await response.data;

        if (response.status === 200) {
            dispatch({
                type: INVENSTORY.RECORDS.CREATE,
            });
            if (product_id) {
                return json;
            }
        } else {
            throw json.error;
        }
    };
};

export const updateInvenstoryRecord = ({id, name, group_id, subjects, content_type, grades, platforms, file_types, standards, url, version, image}) => {
    return async (dispatch, getState) => {
        const response = await call(INVENSTORY.RECORDS.UPDATE, {
            token: getState().auth.userData.token,
            id,
            name,
            group_id,
            subjects,
            content_type,
            grades,
            platforms,
            file_types,
            standards,
            url,
            version,
            image,
        });

        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: INVENSTORY.RECORDS.UPDATE,
            });
        } else {
            throw json.error;
        }
    };
};

export const getInvenstoryRecord = ({id, group_id}) => {
    return async (dispatch, getState) => {
        const response = await call(INVENSTORY.RECORDS.GET, {
            token: getState().auth.userData.token,
            id,
            group_id,
        });

        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: INVENSTORY.RECORDS.GET,
                payload: json,
            });
        } else {
            throw json.error;
        }
    };
};

export const deleteInvenstoryRecord = ({id}) => {
    return async (dispatch, getState) => {
        const response = await call(INVENSTORY.RECORDS.DELETE, {
            token: getState().auth.userData.token,
            id,
        });

        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: INVENSTORY.RECORDS.DELETE,
            });
        } else {
            throw json.error;
        }
    };
};

export const getInvenstoryList = ({group_id, user_id, group_ids = [], user_ids = [], name, subjects, grades, standards, offset = 0, limit = 20, bookmarked, folder_id}) => {
    return async (dispatch, getState) => {
        const response = await call(INVENSTORY.RECORDS.LIST, {
            token: getState().auth.userData.token,
            name,
            group_id,
            group_ids,
            user_ids: !group_id && !group_ids.length && !user_ids.length && user_id ? [user_id] : user_ids,
            subjects,
            grades,
            standards,
            offset,
            limit,
            bookmarked,
            folder_id,
        });

        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: bookmarked ? BOOKMARKS.LIST : INVENSTORY.RECORDS.LIST,
                payload: json,
            });
        } else {
            throw json.error;
        }
    };
};

export const clearRecordsList = () => {
    return {
        type: INVENSTORY.RECORDS.CLEAR_LIST,
    };
};

export const clearCurrentRecord = () => {
    return {
        type: INVENSTORY.RECORDS.CLEAR,
    };
};

export const switchInvenstoryView = (view) => {
    return {
        type: INVENSTORY.SWITCH_VIEW,
        payload: view,
    };
};
