import React, { Component } from "react"
import UnitFormModal from "./components/unitFormModal"
import getErrorText from "../../../../../../../../../library/constants/errorTypes"
import AlertDialog from "../../../../../../../../../components/ui/alertDialog"
import { bindActionCreators } from "redux"
import {
  clearScheduleUnitsList,
  createScheduleUnit,
  deleteScheduleUnit,
  getScheduleUnitsList,
  updateScheduleUnit,
} from "../../../../../../../../../library/store/actions/creators/calendarCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import * as qs from "query-string"
import { LIMIT } from "../../../../../../../../../library/constants/limits"
import GroupSettingsCalendarProvider from "../../../GroupSettingsCalendarContext"
import UnitsListItem from "./components/unitsListItem"
import Loader from "../../../../../../../../../components/ui/loader"
import ListSearchHeader from "../../../../../../../../../components/ui/listSearchHeader/listSearchHeader"
import { withRouter } from "react-router-dom"

class GroupSettingsCalendarUnits extends Component {
  state = {
    showFormModal: {
      show: false,
      type: "create",
    },
    showDeletionDialog: false,
    activeUnit: null,
    offset: 0,
    dataReceived: false,
    noSubjectsError: false,
    subjects: {
      ready: false,
      list: [],
      value: "",
    },
  }

  getList = async (offset = 0) => {
    const { search } = this.props.location
    const { q } = qs.parse(search)

    try {
      this.setState({ dataReceived: false })
      await this.props.getScheduleUnitsList({
        subject_id: parseInt(this.props.match.params.subjectId),
        name: q || undefined,
      })
      this.setState({ dataReceived: true, offset })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  // getSubjectsList = async () => {
  //     const subjectsResponse = await call(SCHEDULE.SUBJECTS.LIST,
  //         {
  //             token: this.props.auth.userData.token,
  //             calendar_id: parseInt(this.props.match.params.calendarId),
  //             offset: 0,
  //             limit: 100,
  //         });
  //     const subjectsList = subjectsResponse.data.subjects;
  //
  //     this.setState({
  //         subjects: {
  //             ready: true,
  //             list: subjectsList,
  //             value: !!subjectsList.length ? subjectsList[0].id : "",
  //         },
  //     });
  // };

  async componentDidMount() {
    // const getUnits = async () => {
    //     if (this.state.subjects.ready && this.state.subjects.list.length) {
    //         await this.getList();
    //     } else {
    //         this.setState({noSubjectsError: true});
    //     }
    // }
    //
    // await this.getSubjectsList();
    // await getUnits();

    await this.getList()
  }

  componentWillUnmount() {
    this.props.clearScheduleUnitsList()
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.search !== prevProps.location.search) {
      this.props.clearScheduleUnitsList()
      await this.getList()
    }

    // if (prevState.subjects.value !== this.state.subjects.value) {
    //     this.props.clearScheduleUnitsList();
    //     await this.getList();
    // }
  }

  onScroll = async () => {
    if (!this.state.dataReceived || this.props.calendar.unitsListEndReached) {
      return
    }

    await this.getList(this.state.offset + LIMIT)
  }

  setSubject = (value) =>
    this.setState((state) => ({ subjects: { ...state.subjects, value } }))

  onCloseFormModal = () =>
    this.setState({
      activeUnit: null,
      showFormModal: { show: false, type: "create" },
    })
  onCloseDeletionDialog = () =>
    this.setState({ activeUnit: null, showDeletionDialog: false })

  initCreate = () =>
    this.setState({ showFormModal: { show: true, type: "create" } })

  onCreateUnit = async (data) => {
    try {
      await this.props.createScheduleUnit(data)
      this.onCloseFormModal()
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  initEdit = (unit_id) => {
    this.setState({
      activeUnit: unit_id,
      showFormModal: {
        show: true,
        type: "edit",
      },
    })
  }

  onEditUnit = async (data) => {
    try {
      await this.props.updateScheduleUnit(data)
      this.onCloseFormModal()
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  initDeletion = (unit_id) =>
    this.setState({ activeUnit: unit_id, showDeletionDialog: true })

  onDeleteUnit = async () => {
    try {
      await this.props.deleteScheduleUnit({ id: this.state.activeUnit })
      this.onCloseDeletionDialog()
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  searchUnits = (name) => {
    const { history } = this.props

    history.push({
      search: `?q=${name}`,
    })
  }

  render() {
    const { showDeletionDialog, showFormModal, activeUnit } = this.state
    const { search } = this.props.location
    const { q } = qs.parse(search)

    return (
      <>
        {/*{this.state.subjects.ready && (*/}
        {/*    <ListPageHeader*/}
        {/*        title={"Units"}*/}
        {/*        hasButton={this.props.groups.currentGroup.type === 5}*/}
        {/*        buttonTitle={"Create unit"}*/}
        {/*        buttonAction={this.initCreate}*/}
        {/*        pageType={"units"}*/}
        {/*        noResults={this.state.dataReceived && !this.props.calendar.unitsList.length}*/}
        {/*        noResultsLabel={q ? "Result not found" : "This Calendar doesn't have units yet"}*/}
        {/*        subjects={this.state.subjects}*/}
        {/*        setSubject={this.setSubject}*/}
        {/*    />*/}
        {/*)}*/}

        <ListSearchHeader
          title="Units"
          placeholder="Start typing name"
          search={this.searchKourses}
          noResultsLabel={q ? "" : "This subject doesn't have units yet"}
          noResults={
            this.state.dataReceived && !this.props.calendar.unitsList.length
          }
          hasButton={
            this.props.groups.currentGroup.type === 5 &&
            !this.props.calendar.currentCalendar.completed
          }
          buttonLabel="Create unit"
          buttonAction={this.initCreate}
        />

        {this.props.calendar.unitsList.map((item) => (
          <GroupSettingsCalendarProvider key={item.id}>
            {({ onUnitDialogOpen }) => (
              <UnitsListItem
                unit={item}
                openDeleteUnit={this.initDeletion}
                openEditUnit={this.initEdit}
                openUnitDetails={onUnitDialogOpen}
                isStatic={this.props.groups.currentGroup.type === 4}
              />
            )}
          </GroupSettingsCalendarProvider>
        ))}

        {/*{!this.state.dataReceived && !this.state.noSubjectsError && <Loader />}*/}
        {!this.state.dataReceived && <Loader />}

        {showFormModal.show && (
          <UnitFormModal
            open={showFormModal.show}
            handleClose={this.onCloseFormModal}
            onSubmit={
              showFormModal.type === "create"
                ? this.onCreateUnit
                : this.onEditUnit
            }
            activeUnit={activeUnit}
            edit={showFormModal.type === "edit"}
          />
        )}

        {showDeletionDialog && (
          <AlertDialog
            open={showDeletionDialog}
            handleClose={this.onCloseDeletionDialog}
            handleAccept={this.onDeleteUnit}
            title="Delete this unit?"
            message="If you delete this unit you will not be able to restore it"
          />
        )}
      </>
    )
  }
}

const mapStateToProps = ({ auth, groups, calendar }) => ({
  auth,
  groups,
  calendar,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createScheduleUnit,
      updateScheduleUnit,
      deleteScheduleUnit,
      getScheduleUnitsList,
      clearScheduleUnitsList,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(GroupSettingsCalendarUnits)))
