import React, { Component } from "react"
import "./reply.scss"
import getErrorText from "../../../../library/constants/errorTypes"
import avatarDefault from "../../../../assets/img/user_avatar.svg"
import { getGroupLogo } from "../../../../library/utils/getGroupLogo"
import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import UserAvatar from "../../../../components/userAvatar/userAvatar"
import EditOutlined from "@material-ui/icons/EditOutlined"
import DeleteForeverOutlined from "@material-ui/icons/DeleteForeverOutlined"
import Linkify from "linkifyjs/react"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import Button from "@material-ui/core/Button/Button"
import FavouriteOutlined from "@material-ui/icons/FavoriteOutlined"
import FavouriteBorderedOutlined from "@material-ui/icons/FavoriteBorderOutlined"
import ReplyOutlined from "@material-ui/icons/ReplyOutlined"
import postDateConvert from "../../../../library/utils/postDateConvert"
import { bindActionCreators } from "redux"
import {
  createComment,
  deleteComment,
  getPostsList,
  like,
  restoreComment,
  unLike,
  updateComment,
} from "../../../../library/store/actions/creators/postsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"

class Reply extends Component {
  state = {
    textHidden: this.props.reply.message.length > 150,
    showLess: false,
    message:
      this.props.reply.message.length > 150
        ? this.props.reply.message.slice(0, 150).concat("...")
        : this.props.reply.message,
    isLiked: this.props.reply.is_liked,
    likesNumber: this.props.reply.likes_number,
    repliesNumber: this.props.reply.replies_number,
    isMine: this.props.reply.is_mine,
    canEdit: this.props.reply.can_edit,
    canDelete: this.props.reply.can_delete,
    replyTo: this.props.reply.reply_to,

    updateReply: false,
    updatedMessage: "",
    updatedAttachments: [],
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }

  showFullText = () => {
    this.setState({
      textHidden: false,
      showLess: true,
      message: this.props.reply.message,
    })
  }

  showLessText = () => {
    this.setState({
      textHidden: true,
      showLess: false,
      message: this.props.reply.message.slice(0, 150).concat("..."),
    })
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value })
  }

  newReply = () => {
    const { reply } = this.props

    reply.user
      ? this.props.addReply(
          reply.id,
          reply.user.id,
          reply.user.first_name,
          reply.user.last_name
        )
      : this.props.addReply(reply.id, reply.group.id, reply.group.name, "")
  }

  processLike = async () => {
    const {
      reply: { id: comment_id },
    } = this.props
    const { isLiked, likesNumber } = this.state

    try {
      if (!isLiked) {
        this.setStateAsync({ isLiked: true, likesNumber: likesNumber + 1 })
        this.props.like({ type: "reply", comment_id })
      } else {
        this.setStateAsync({ isLiked: false, likesNumber: likesNumber - 1 })
        this.props.unLike({ type: "reply", comment_id })
      }
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  openEditMode = () => {
    this.setState({
      updateReply: true,
      updatedMessage: this.props.reply.message,
      updatedAttachments: this.props.reply.attachments,
    })
  }

  closeEditMode = async () => {
    this.setState({
      updateReply: false,
      updatedMessage: "",
      updatedAttachments: [],
    })
  }

  processEdit = async () => {
    const {
      reply: { id },
    } = this.props
    const { updatedMessage } = this.state
    this.closeEditMode()
    try {
      await this.props.updateComment({ id, message: updatedMessage })
      await this.props.updateData()
      await this.setStateAsync({
        updateReply: false,
        message: updatedMessage,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  processDelete = async () => {
    const {
      reply: { id: reply_id },
    } = this.props

    try {
      await this.props.deleteComment({ id: reply_id })
      await this.setStateAsync({ restore: true })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  processRestore = async () => {
    const {
      reply: { id: reply_id },
    } = this.props

    try {
      await this.props.restoreComment({ id: reply_id })
      await this.setStateAsync({ restore: false })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  render() {
    const {
      reply,
      auth: { userData },
    } = this.props
    const {
      textHidden,
      showLess,
      message,
      isLiked,
      likesNumber,
      canEdit,
      canDelete,
      updateReply,
      updatedMessage,
      restore,
    } = this.state

    const avatar = reply.user
      ? reply.user.photo
        ? reply.user.photo
        : avatarDefault
      : getGroupLogo(reply.group)
    const profileUrl = reply.user
      ? generatePath(routes.USER, { userId: reply.user.id })
      : generatePath(routes.GROUP, { groupId: reply.group.id })

    if (restore) {
      return (
        <div className="post">
          <div className="f jcc aic">
            <div>
              Comment deleted.{" "}
              <span
                className="post__restore"
                onClick={() => this.processRestore()}
              >
                Undo.
              </span>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="post-reply">
          <div className="h-divider h-divider--reply"></div>
          <div className="post-reply__header">
            <Link to={profileUrl}>
              {reply.user && reply.user.id === userData.id ? (
                <UserAvatar className="post-reply__author-avatar" />
              ) : (
                <img
                  src={avatar}
                  alt="Author Name"
                  className="post-reply__author-avatar post-reply__author-avatar--img"
                />
              )}
            </Link>
            <div className="f fdc jcc fg1">
              <Link to={profileUrl} className="asfs link">
                <span className="post-reply__author-name">
                  {reply.user
                    ? `${reply.user.first_name} ${reply.user.last_name}`
                    : reply.group.name}
                </span>
              </Link>
            </div>
            {!updateReply && (canEdit || canDelete) ? (
              <div className="post-reply__manage">
                {canEdit ? (
                  <button
                    className="btn btn--plain"
                    onClick={() => this.openEditMode()}
                  >
                    <EditOutlined />
                    Edit
                  </button>
                ) : null}
                {canDelete ? (
                  <button
                    className="btn btn--plain"
                    onClick={() => this.processDelete()}
                  >
                    <DeleteForeverOutlined />
                    Delete
                  </button>
                ) : null}
              </div>
            ) : null}
          </div>

          <div className="post-reply__offset-wrapper">
            {!updateReply ? (
              <div className="post-reply__content">
                <div>
                  {message.length ? (
                    <Linkify tagName={"pre"} options={{ className: "link" }}>
                      {message}
                    </Linkify>
                  ) : null}
                  {textHidden ? (
                    <span
                      className="post-reply__read-more link"
                      onClick={() => this.showFullText()}
                    >
                      Show more...
                    </span>
                  ) : null}
                  {showLess ? (
                    <span
                      className="post-reply__read-more link"
                      onClick={() => this.showLessText()}
                    >
                      Show less
                    </span>
                  ) : null}
                </div>
              </div>
            ) : (
              <ValidatorForm onSubmit={() => this.processEdit(reply.id)}>
                <div className="post__update">
                  <div className="post__update-input">
                    <TextValidator
                      placeholder="What’s happening?"
                      multiline
                      name="reply-item"
                      autoFocus
                      fullWidth
                      value={updatedMessage}
                      onChange={this.handleChange("updatedMessage")}
                      margin="none"
                      validators={["maxStringLength:5000"]}
                      errorMessages={["Max length is 5000 characters"]}
                    />
                  </div>
                  <div className="post__update-controls">
                    <div className="btn-group">
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => this.closeEditMode()}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={!updatedMessage.length}
                        type="submit"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </ValidatorForm>
            )}
            <div className="post-reply__footer">
              <div
                className="post-reply__social-control"
                onClick={() => this.processLike()}
              >
                {isLiked ? (
                  <FavouriteOutlined className="color-primary" />
                ) : (
                  <FavouriteBorderedOutlined />
                )}
                {likesNumber > 0 ? <span>{likesNumber}</span> : null}
              </div>
              <button
                className="btn btn--plain"
                onClick={() => this.newReply()}
              >
                <ReplyOutlined />
                Reply
              </button>
              <div className="post-reply__date">
                {postDateConvert(reply.created)}
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = ({ auth, person, posts }) => ({ auth, person, posts })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createComment,
      getPostsList,
      like,
      unLike,
      updateComment,
      deleteComment,
      restoreComment,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Reply))
