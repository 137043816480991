import React, { Component } from "react"
import "./comment.scss"
import avatarDefault from "../../../../assets/img/user_avatar.svg"
import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import FavouriteBorderedOutlined from "@material-ui/icons/FavoriteBorderOutlined"
import FavouriteOutlined from "@material-ui/icons/FavoriteOutlined"
import ReplyOutlined from "@material-ui/icons/ReplyOutlined"
import EditOutlined from "@material-ui/icons/EditOutlined"
import ClearOutlined from "@material-ui/icons/ClearOutlined"
import DeleteForeverOutlined from "@material-ui/icons/DeleteForeverOutlined"
import SendOutlined from "@material-ui/icons/SendOutlined"
import KeyboardArrowDownOutlined from "@material-ui/icons/KeyboardArrowDownOutlined"
import Button from "@material-ui/core/Button/Button"
import RepliesList from "../repliesList/repliesList"
import { bindActionCreators } from "redux"
import {
  createComment,
  deleteComment,
  getPostsList,
  like,
  restoreComment,
  unLike,
  updateComment,
} from "../../../../library/store/actions/creators/postsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import Linkify from "linkifyjs/react"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import { getGroupLogo } from "../../../../library/utils/getGroupLogo"
import CustomToolTip from "../../../../components/ui/customToolTip/customToolTip"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import postDateConvert from "../../../../library/utils/postDateConvert"
import getErrorText from "../../../../library/constants/errorTypes"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"

class Comment extends Component {
  state = {
    textHidden: this.props.comment.message.length > 255,
    showLess: false,
    message:
      this.props.comment.message.length > 255
        ? this.props.comment.message.slice(0, 255).concat("...")
        : this.props.comment.message,

    isLiked: this.props.comment.is_liked,
    likesNumber: this.props.comment.likes_number,
    repliesNumber: this.props.comment.replies_number,
    isMine: this.props.comment.is_mine,
    canEdit: this.props.comment.can_edit,
    canDelete: this.props.comment.can_delete,

    showReplyField: false,
    replyTo: "",
    newReplyText: "",
    newReplyAttachments: [],
    replyId: "",
    userId: "",
    userFirstName: "",
    userLastName: "",

    restore: false,

    updateComment: false,
    updatedMessage: "",
    updatedAttachments: [],

    replies: this.props.comment.replies.slice(0, 3),
    hasMoreReplies: this.props.comment.replies.length > 3,
    showAllReplies: false,
    personal: true,
    tooltipIsOpen: false,
  }
  handleCheckboxChange = () => {
    this.setState({ personal: !this.state.personal })
  }
  changeTooltipStateOpen = () => {
    this.setState({ tooltipIsOpen: true })
  }
  changeTooltipStateClose = () => {
    this.setState({ tooltipIsOpen: false })
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }

  showFullText = () => {
    this.setState({
      textHidden: false,
      showLess: true,
      message: this.props.comment.message,
    })
  }

  showLessText = () => {
    this.setState({
      textHidden: true,
      showLess: false,
      message: this.props.comment.message.slice(0, 255).concat("..."),
    })
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value })
  }

  showReplyField = (reply_id, user_id, user_first_name, user_last_name) => {
    this.setState({
      showReplyField: true,
      replyId: reply_id,
      userId: user_id,
      userFirstName: user_first_name,
      userLastName: user_last_name,
      newReplyText: `${user_first_name}, `,
    })
  }

  processCreateReply = async () => {
    if (!this.state.newReplyText.replace(/\s/g, "")) return
    this.setState({
      replyTo: "",
      newReplyText: "",
      showReplyField: false,
    })
    const { newReplyText: message } = this.state
    const {
      postId: post_id,
      comment: { id: comment_id },
    } = this.props
    try {
      this.props.comment.replies_number + 1 > 3 &&
        this.setState({ showAllReplies: true })
      await this.props.createComment({
        post_id,
        comment_id,
        message,
        type:
          this.props.type === "my-profile"
            ? "my"
            : this.props.type === "feed"
            ? "feed"
            : "person",
        personal: this.state.personal,
      })
      await this.props.updateData()
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  cancelReply = () => {
    this.setState({
      showReplyField: false,
      replyId: "",
      userId: "",
      userFirstName: "",
      userLastName: "",
      newReplyText: "",
    })
  }

  processLike = async () => {
    const {
      comment: { id: comment_id },
    } = this.props
    const { isLiked, likesNumber } = this.state

    try {
      if (!isLiked) {
        await this.props.like({ type: "comment", comment_id })
        await this.setStateAsync({
          isLiked: true,
          likesNumber: likesNumber + 1,
        })
      } else {
        await this.props.unLike({ type: "comment", comment_id })
        await this.setStateAsync({
          isLiked: false,
          likesNumber: likesNumber - 1,
        })
      }
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  openEditMode = () => {
    this.setState({
      updateComment: true,
      updatedMessage: this.props.comment.message,
      updatedAttachments: this.props.comment.attachments,
    })
  }

  closeEditMode = async () => {
    this.setState({
      updateComment: false,
      updatedMessage: "",
      updatedAttachments: [],
    })
  }

  processEdit = async () => {
    const {
      comment: { id },
    } = this.props
    const { updatedMessage } = this.state
    if (!updatedMessage.replace(/\s/g, "")) return
    try {
      await this.props.updateComment({ id, message: updatedMessage })
      await this.props.updateData()
      await this.setStateAsync({
        updateComment: false,
        message: updatedMessage,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  processDelete = async () => {
    const {
      comment: { id: comment_id },
    } = this.props

    try {
      await this.props.deleteComment({ id: comment_id })
      await this.setStateAsync({ restore: true })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  processRestore = async () => {
    const {
      comment: { id: comment_id },
    } = this.props

    try {
      await this.props.restoreComment({ id: comment_id })
      await this.setStateAsync({ restore: false })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  showAllReplies = () => {
    this.setState({
      replies: this.props.comment.replies,
      showAllReplies: true,
    })
  }

  updateData = async () => {
    this.props.updateData(this.state.type)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { showAllReplies: all } = this.state
    if (this.props.comment !== prevProps.comment) {
      this.setState({
        replies: all
          ? this.props.comment.replies
          : this.props.comment.replies.slice(0, 3),
        hasMoreReplies: this.props.comment.replies.length > 3,
        repliesNumber: this.props.comment.replies_number,
      })
    }
  }

  render() {
    const {
      comment,
      index,
      auth: { userData },
      createdByGroup,
      groupInfo,
    } = this.props
    const {
      textHidden,
      showLess,
      message,
      isLiked,
      likesNumber,
      canDelete,
      canEdit,
      showReplyField,
      userId,
      userFirstName,
      userLastName,
      newReplyText,
      restore,
      updateComment,
      updatedMessage,
      replies,
      hasMoreReplies,
      showAllReplies,
      personal,
      tooltipIsOpen,
    } = this.state
    const creatorAvatar = comment.user
      ? comment.user.photo
        ? comment.user.photo
        : avatarDefault
      : getGroupLogo(comment.group)
    const avatar = userData.photo ? userData.photo : avatarDefault
    const profileUrl = comment.user
      ? generatePath(routes.USER, { userId: comment.user.id })
      : generatePath(routes.GROUP, { groupId: comment.group.id })
    const toolTipContent = (
      <FormControlLabel
        control={
          <Checkbox
            autoFocus
            checked={!personal}
            onChange={this.handleCheckboxChange}
            value={"Reply as group"}
          />
        }
        label="Reply as group"
      />
    )
    if (restore) {
      return (
        <div className="post">
          <div className="f jcc aic">
            <div>
              Comment deleted.{" "}
              <span
                className="post__restore"
                onClick={() => this.processRestore()}
              >
                Undo.
              </span>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="post-comment">
          {index !== 0 ? (
            <div className="h-divider h-divider--comment"></div>
          ) : null}
          <div className="post-comment__body">
            <div className="post-comment__header">
              <Link to={profileUrl}>
                <img
                  src={creatorAvatar}
                  alt="Author Name"
                  className="post-comment__author-avatar post-comment__author-avatar--img"
                />
              </Link>
              <div className="f fdc jcc fg1">
                <Link to={profileUrl} className="asfs link">
                  {comment.user ? (
                    <span className="post-comment__author-name">{`${comment.user.first_name} ${comment.user.last_name}`}</span>
                  ) : (
                    <span className="post-comment__author-name">{`${comment.group.name}`}</span>
                  )}
                </Link>
              </div>

              {!updateComment && (canEdit || canDelete) ? (
                <div className="post-comment__manage">
                  {canEdit ? (
                    <button
                      className="btn btn--plain"
                      onClick={() => this.openEditMode()}
                    >
                      <EditOutlined />
                      Edit
                    </button>
                  ) : null}
                  {canDelete ? (
                    <button
                      className="btn btn--plain"
                      onClick={() => this.processDelete()}
                    >
                      <DeleteForeverOutlined />
                      Delete
                    </button>
                  ) : null}
                </div>
              ) : null}
            </div>
            <div className="post-comment__offset-wrapper">
              {!updateComment ? (
                <div className="post-comment__content">
                  <div>
                    {message.length ? (
                      <Linkify
                        tagName={"pre"}
                        options={{ className: "link link-inline" }}
                      >
                        {message}
                      </Linkify>
                    ) : null}
                    {textHidden ? (
                      <span
                        className="post-comment__read-more link"
                        onClick={() => this.showFullText()}
                      >
                        Show more...
                      </span>
                    ) : null}
                    {showLess ? (
                      <span
                        className="post-comment__read-more link"
                        onClick={() => this.showLessText()}
                      >
                        Show less
                      </span>
                    ) : null}
                  </div>
                </div>
              ) : (
                <ValidatorForm onSubmit={() => this.processEdit(comment.id)}>
                  <div className="post__update">
                    <div className="post__update-input">
                      <TextValidator
                        placeholder="What’s happening?"
                        multiline
                        autoFocus
                        fullWidth
                        name="comment"
                        value={updatedMessage}
                        onChange={this.handleChange("updatedMessage")}
                        margin="none"
                        validators={["maxStringLength:5000"]}
                        errorMessages={["Max length is 5000 characters"]}
                      />
                    </div>
                    <div className="post__update-controls">
                      <div className="btn-group">
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          onClick={() => this.closeEditMode()}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="small"
                          disabled={!updatedMessage.length}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </div>
                </ValidatorForm>
              )}
              <div className="post-comment__footer">
                <div
                  className="post-comment__social-control"
                  onClick={() => this.processLike()}
                >
                  {isLiked ? (
                    <FavouriteOutlined className="color-primary" />
                  ) : (
                    <FavouriteBorderedOutlined />
                  )}
                  {likesNumber > 0 ? <span>{likesNumber}</span> : null}
                </div>
                <button
                  className="btn btn--plain"
                  onClick={() =>
                    comment.user
                      ? this.showReplyField(
                          comment.id,
                          comment.user.id,
                          comment.user.first_name,
                          comment.user.last_name
                        )
                      : this.showReplyField(
                          comment.id,
                          comment.group.id,
                          comment.group.name,
                          ""
                        )
                  }
                >
                  <ReplyOutlined />
                  Reply
                </button>
                <div className="post-comment__date">
                  {postDateConvert(comment.created)}
                </div>
              </div>
            </div>
          </div>
          <RepliesList
            postId={this.props.postId}
            addReply={(reply_id, user_id, user_first_name, user_last_name) =>
              this.showReplyField(
                reply_id,
                user_id,
                user_first_name,
                user_last_name
              )
            }
            commentId={comment.id}
            replies={replies}
            updateData={() => this.updateData()}
          />
          {!showAllReplies && hasMoreReplies ? (
            <div className="full-width f jcc mt10">
              <button
                className="btn btn--plain"
                onClick={() => this.showAllReplies()}
              >
                Show all replies <KeyboardArrowDownOutlined />
              </button>
            </div>
          ) : null}

          {showReplyField ? (
            <div className="f fdc">
              <div className="post-reply-field-header">
                <div className="post-reply-field-header__text">
                  Reply,{" "}
                  <Link
                    to={
                      personal
                        ? generatePath(routes.USER, { userId })
                        : generatePath(routes.GROUP, { groupId: groupInfo.id })
                    }
                    className="link"
                  >
                    {`${userFirstName} ${userLastName}`}
                  </Link>
                </div>
                <button
                  className="btn btn--plain"
                  onClick={() => this.cancelReply()}
                >
                  <ClearOutlined /> Cancel
                </button>
              </div>
              <div className="post-reply-field">
                {groupInfo && !personal ? (
                  <img
                    src={getGroupLogo(groupInfo)}
                    alt={groupInfo.name}
                    className="post-comment-field__avatar"
                  />
                ) : (
                  <img
                    src={avatar}
                    alt={`${userData.first_name} ${userData.last_name}`}
                    className="post-comment-field__avatar"
                  />
                )}
                {createdByGroup && (
                  <CustomToolTip
                    content={toolTipContent}
                    open={this.changeTooltipStateOpen}
                    close={this.changeTooltipStateClose}
                    isOpen={tooltipIsOpen}
                    onBlur={(_) => {
                      this.setState({ tooltipIsOpen: false })
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "-10px",
                        color: "rgba(0, 0, 0, 0.38)",
                        cursor: "pointer",
                      }}
                    >
                      {this.state.tooltipIsOpen ? (
                        <ExpandLessIcon
                          onClick={this.changeTooltipStateClose}
                        />
                      ) : (
                        <ExpandMoreIcon onClick={this.changeTooltipStateOpen} />
                      )}
                    </div>
                  </CustomToolTip>
                )}
                <ValidatorForm onSubmit={() => {}} style={{ width: "100%" }}>
                  <TextValidator
                    name="new-comment"
                    placeholder="Add your comment…"
                    multiline
                    value={newReplyText}
                    onChange={this.handleChange("newReplyText")}
                    fullWidth
                    validators={["maxStringLength:5000"]}
                    errorMessages={["Max length is 5000 characters"]}
                    margin="none"
                    variant="outlined"
                  />
                </ValidatorForm>
                <SendOutlined
                  className="post-reply-field__send"
                  onClick={() => this.processCreateReply()}
                />
              </div>
            </div>
          ) : null}
        </div>
      )
    }
  }
}

const mapStateToProps = ({auth, person, posts}) => ({auth, person, posts});
const mapDispatchToProps = dispatch => bindActionCreators({
    createComment,
    getPostsList,
    like,
    unLike,
    updateComment,
    deleteComment,
    restoreComment,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Comment));
