import React from "react"
import ImageModal from "../../../../ui/modals/imageModal"

const PostImages = ({ images }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [activeImage, setActiveImage] = React.useState(null)

  const onModalOpen = (url) => {
    setActiveImage(url)
    setIsModalOpen(true)
  }

  const onModalClose = () => {
    setActiveImage(null)
    setIsModalOpen(false)
  }

  return (
    <div
      className={`post__images ${
        images.length === 1 ? "post__images--single" : ""
      }`}
    >
      {images.map(({ id, url }) => {
        return (
          <img
            src={url}
            className="post__img"
            alt="Image"
            onClick={() => onModalOpen(url)}
            key={id}
          />
        )
      })}
      {isModalOpen && (
        <ImageModal
          url={activeImage}
          open={isModalOpen}
          handleClose={onModalClose}
        />
      )}
    </div>
  )
}

export default PostImages
