import useEntityPicker from "../../../../hooks/useEntityPicker"
import { SCHEDULE } from "../../../../library/store/actions/types/calendarTypes"
import { useEffect } from "react"
import PickerSelect from "../select/pickerSelect"
import { getObjectById } from "../../../../library/utils/arrays"
import { calendarGrades } from "../../../../library/constants/educationalDictionary"

const SubjectPicker = ({
  value,
  onChange,
  calendarId,
  scheduleId,
  hasError = false,
  errorMessage,
  required = false,
  multiple = false,
  subjects,
  emptyListMessage,
  emptyListLink,
  disabled = false,
}) => {
  const { fetchList, list, dataReceived, isWaitingForOtherFields } =
    useEntityPicker({
      fetchConfig: {
        apiUrl: SCHEDULE.SUBJECTS.LIST,
        params: {
          calendar_id: calendarId,
          schedule_id: scheduleId,
          offset: 0,
          limit: 100,
        },
      },
      key: "subjects",
      loadOnMount: !subjects && !!(calendarId || scheduleId),
    })

  useEffect(() => {
    if (!subjects && (calendarId || scheduleId)) {
      fetchList()
    }
  }, [calendarId, scheduleId])

  const entityName = multiple ? "subjects" : "subject"

  return (
    <PickerSelect
      name={"subject"}
      entityName={entityName}
      value={value}
      onChange={onChange}
      required={required}
      hasError={hasError}
      errorMessage={errorMessage}
      disabled={disabled || (subjects ? false : !dataReceived)}
      multiple={multiple}
      list={subjects || list}
      isWaitingForOtherFields={subjects ? false : isWaitingForOtherFields}
      isWaitingForOtherFieldsMessage={"First select schedule"}
      emptyListMessage={emptyListMessage}
      emptyListLink={emptyListLink}
      getValueName={(v) =>
        `${getObjectById(calendarGrades, v.grade).name} grade, ${v.name}`
      }
    />
  )
}

export default SubjectPicker
