import React from "react"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox/Checkbox"

const FormCheckbox = ({ onChange, value, label = "" }) => {
  return (
    <div className="form__fieldset">
      <span className="form__label"></span>
      <div className="form__fieldbox">
        <div className="form__input">
          <FormControlLabel
            control={
              <Checkbox
                checked={value}
                value={label}
                disableRipple
                onChange={onChange}
              />
            }
            label={label}
          />
        </div>
      </div>
    </div>
  )
}

export default FormCheckbox
