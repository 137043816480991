import React from "react"
import { usePaidPlansQuery } from "../../../../../info/pricing/usePlans"
import { PlainButton } from "../../../../../../components/v2/controls"
import ImportExportIcon from "@material-ui/icons/ImportExport"
import { useToggle } from "../../../../../../hooks/useToggle"
import PlainLink from "../../../../../../components/ui/link"
import { generatePath } from "react-router-dom"
import { GROUP } from "../../../../../../library/constants/routes"
import { formatDate } from "../../../../../../library/utils/dateConvert"
import SeatsNumberPicker from "./SeatsNumberPicker"
import { colors } from "../../../../../../library/constants/styles/colors"
import Loader from "../../../../../../components/ui/loader"
import { useSubscriptions } from "../../../../user/userProfileSettings/profileSettingsSubscriptions/useSubscriptions"

const GroupSubscriptionInfo = ({ subscription, canManage = false }) => {
  const { paidPlans, isSuccess: isPlanSuccess } = usePaidPlansQuery()
  const { calcTieredSubscriptionPrice, calcFlatSubscriptionPrice } =
    useSubscriptions()
  const plan = paidPlans?.find((p) => p.id === subscription?.plan_id)

  const [isSeatsPickerOpen, toggleSeatsPicker] = useToggle(false)

  const getPrice = () => {
    return plan.prices[subscription.period]?.tiers?.length
      ? calcTieredSubscriptionPrice({
          tiers: plan.prices[subscription.period]?.tiers,
          quantity: subscription.quantity,
          period: subscription.period,
        })
      : calcFlatSubscriptionPrice({
          unit_amount: plan.prices[subscription.period].unit_amount,
          period: subscription.period,
        })
  }

  return isPlanSuccess ? (
    !!subscription && (
      <div className={"mb15"}>
        <div>
          <div className={"font-16 font-weight-500 mb5"}>
            <span>{subscription?.plan_name + ", "}</span>
            <span>{getPrice().total}</span>
          </div>
          <div
            className={"pl10"}
            style={{ borderLeft: `2px solid ${colors.black12}` }}
          >
            <p className={"mb5"}>
              Group:{" "}
              <PlainLink
                inline
                to={generatePath(GROUP, { groupId: subscription.group_id })}
              >
                {subscription.group_name}
              </PlainLink>
            </p>
            <p className={"mb5"}>
              Status: {subscription?.canceled ? "Canceled" : "Active"}
            </p>
            {!!plan.prices[subscription.period]?.tiers && (
              <p className={"mb5"}>
                Seats purchased: {subscription?.quantity + ", "}(
                {getPrice().perSeat})
              </p>
            )}
            {!subscription?.cancel_on_period_end ? (
              <p className={"mb5"}>
                Next payment: {formatDate(subscription.current_period_end)}
              </p>
            ) : (
              <p className={"error-message mb5"}>
                This subscription will be canceled on{" "}
                {formatDate(subscription.current_period_end)}
              </p>
            )}
            {canManage && (
              <PlainButton inline onClick={toggleSeatsPicker}>
                <ImportExportIcon /> Change seats number
              </PlainButton>
            )}

            {isSeatsPickerOpen && (
              <SeatsNumberPicker
                onClose={toggleSeatsPicker}
                open={isSeatsPickerOpen}
              />
            )}
          </div>
        </div>
      </div>
    )
  ) : (
    <Loader />
  )
}

export default GroupSubscriptionInfo
