import React from "react"
import SearchOutlined from "@material-ui/icons/SearchOutlined"

const TableSearch = (props) => {
  return (
    <div className="search-form">
      <div className="search-form__icon">
        <SearchOutlined />
      </div>
      <input
        type="text"
        id="search"
        name="search"
        placeholder="Search records"
        className="search-form__field"
        autoComplete="off"
        value={props.tableSearchQuery}
        onChange={props.processTableSearch}
      />
    </div>
  )
}

export default TableSearch;
