import React from "react"
import { InsertDriveFile, LinkOutlined } from "@material-ui/icons"
import { formatBytes } from "../../../../../../library/utils/fileSizeConverter"
import ProductsListItem from "../../../../marketplace/productsList/productsListItem/productsListItem"
import returnExternalUrl from "../../../../../../library/utils/returnExternalUrl"
import LineSlice from "../../../../../../components/ui/lineSlice"

class GroupsAttachmentsList extends React.Component {
  state = {
    list: [],
  }

  componentDidMount() {
    const { list } = this.props
    this.setState({ list })
  }

  render() {
    const { type, currentGroup, list } = this.props
    return ((_) => {
      switch (type) {
        case "documents":
          return (
            <div className="box p15">
              {[...list]
                .sort(
                  (a, b) =>
                    new Date(b.created).getTime() -
                    new Date(a.created).getTime()
                )
                .map(({ url, id, name, size }) => {
                  return (
                    <a
                      href={url}
                      target="_blank"
                      className="post__file"
                      key={id}
                    >
                      <InsertDriveFile
                        className="post__file-icon"
                        style={{ fontSize: "30px" }}
                      />
                      <div
                        className="post__file-info"
                        style={{ width: "100%" }}
                      >
                        <span
                          className="link link--inline"
                          style={{ fontSize: "1rem", lineHeight: "20px" }}
                        >
                          <LineSlice text={name} />
                        </span>
                        <span
                          className="post__file-size"
                          style={{ fontSize: "1rem", lineHeight: "20px" }}
                        >
                          <LineSlice text={formatBytes(size)} />
                        </span>
                      </div>
                    </a>
                  )
                })}
            </div>
          )
        case "links":
          return (
            <div className="box p15">
              {list.map((link) => {
                return (
                  <a
                    href={returnExternalUrl(link.url)}
                    target="_blank"
                    className="post__file"
                    key={link.id}
                  >
                    <LinkOutlined
                      className="post__file-icon"
                      style={{ fontSize: "30px" }}
                    />
                    <div className="post__file-info" style={{ width: "100%" }}>
                      <span className="link link--inline">
                        <LineSlice text={link.name} />
                      </span>
                      <span
                        className="post__file-size"
                        style={{ fontSize: "1rem", lineHeight: "20px" }}
                      >
                        <LineSlice text={link.url} />
                      </span>
                    </div>
                  </a>
                )
              })}
            </div>
          )
        case "products":
          return (
            <div className="products-list">
              {list.map((item) => {
                return (
                  <ProductsListItem
                    product={item}
                    group={currentGroup}
                    manageable={false}
                    key={item.id}
                  />
                )
              })}
            </div>
          )

        default:
          return <div></div>
      }
    })()
  }
}

export default GroupsAttachmentsList
