import React, { Component } from "react"
import { bindActionCreators } from "redux"
import {
  createFolder,
  foldersList,
  updateFolder,
} from "../../../../../library/store/actions/creators/bookmarksCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import getErrorText from "../../../../../library/constants/errorTypes"
import Dialog from "@material-ui/core/Dialog/Dialog"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import { withStyles } from "@material-ui/core"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"

const CustomDialogTitle = withStyles({
  root: {
    paddingBottom: 0,
    textAlign: "center",
  },
})(DialogTitle)

class CreateEditFolder extends Component {
    state = {
        name: this.props.type === "create" ? "" : this.props.folder.name,
    };

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleClose = async () => {
        const {type} = this.props;

        this.props.onClose(type);
    };

    processCreateEdit = async () => {
        const {type} = this.props;
        const {name} = this.state;

        try {
            if (type === "create") {
                await this.props.createFolder({name});
            } else {
                await this.props.updateFolder({id: this.props.folder.id, name});
            }
            await this.props.foldersList();
            await this.handleClose();
        } catch ({error}) {
            this.props.enqueueSnackbar(getErrorText(error.code), {variant: "error"});
        }
    };

    render() {
        const {open, type} = this.props;
        const {name} = this.state;

        return (
            <Dialog
                open={open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <CustomDialogTitle id="alert-dialog-title">
                    {type === "create" ? "New folder" : "Edit folder"}
                </CustomDialogTitle>
                <ValidatorForm onSubmit={this.processCreateEdit}>
                    <DialogContent>
                        <div className="form form--modal">
                            <div className="form__fieldset">
                                <label htmlFor="name" className="form__label">Folder name *</label>
                                <div className="form__fieldset">
                                    <div className="form__input">
                                        <TextValidator
                                            id="name"
                                            name="name"
                                            value={name}
                                            placeholder="Type folder name"
                                            className="edit-form__input"
                                            margin="normal"
                                            autoComplete="off"
                                            fullWidth
                                            validators={[
                                                "required",
                                                "maxStringLength:50",
                                            ]}
                                            errorMessages={[
                                                "Field is required",
                                                "Max length is 50 characters",
                                            ]}
                                            onChange={this.handleChange("name")}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button type="submit" color="primary" autoFocus>
                            Save
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        );
    }
}

const mapStateToProps = ({auth, bookmarks}) => ({auth, bookmarks});
const mapDispatchToProps = dispatch => bindActionCreators({createFolder, updateFolder, foldersList}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(CreateEditFolder));
