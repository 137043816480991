import React, { createContext, useContext, useState } from "react"
import UknowDialog from "../../screens/main/knowmix/knowmixSingle/knowmixSettings/knowmixSettingsKnowmeets/uknowDialog/uknowDialog"
import { useAlert } from "../useAlert"
import {
  createKnowmixStepUknow,
  deleteKnowmixStepUknow,
  updateKnowmixStepUknow,
} from "../../library/store/actions/creators/uknowCreators"
import { useDispatch } from "react-redux"
import AlertDialog from "../../components/ui/alertDialog"
import FullStepKnowmeetFormModal from "../../screens/main/knowmix/knowmixSingle/knowmixSettings/knowmixSettingsKnowmeets/uknowFormModal/fullStepKnowmeetFormModal"

export const KnowmeetModalsContext = createContext(null)

export const useKnowmeetModals = () => useContext(KnowmeetModalsContext)

const initialModalState = {
  type: null,
  open: false,
  activeId: null,
}

const KnowmeetModalsProvider = ({ updateList, children }) => {
  const dispatch = useDispatch()
  const { errorAlert, successAlert } = useAlert()

  const [infoState, setInfoState] = useState(initialModalState)
  const [formState, setFormState] = useState(initialModalState)
  const [deletionState, setDeletionState] = useState(initialModalState)

  const onInfoOpen = (activeId, type) => {
    setInfoState({
      type,
      activeId,
      open: true,
    })
  }
  const onFormOpen = (activeId, type) => {
    setFormState({
      type,
      activeId,
      open: true,
    })
  }
  const onDeletionOpen = (activeId, type) => {
    setDeletionState({
      type,
      activeId,
      open: true,
    })
  }

  const onInfoClose = () => setInfoState(initialModalState)
  const onFormClose = () => setFormState(initialModalState)
  const onDeletionClose = () => setDeletionState(initialModalState)

  const onSave = async (values) => {
    try {
      if (!formState.activeId) {
        await dispatch(createKnowmixStepUknow(values))
      } else {
        await dispatch(
          updateKnowmixStepUknow({
            id: formState.activeId,
            ...values,
          })
        )
      }

      if (updateList) await updateList()

      setFormState(initialModalState)
    } catch (e) {
      errorAlert(e)
    }
  }

  const onDelete = async () => {
    try {
      if (deletionState.type === "step") {
        await dispatch(deleteKnowmixStepUknow({ id: deletionState.activeId }))
      }

      if (updateList) await updateList()

      setDeletionState(initialModalState)
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <KnowmeetModalsContext.Provider
      value={{
        onFormOpen,
        onDeletionOpen,
        onInfoOpen,
      }}
    >
      {/*<KnowmeetModalsContext.Consumer>*/}
      {children}
      {/*</KnowmeetModalsContext.Consumer>*/}

      {infoState.open && (
        <UknowDialog
          open={infoState.open}
          handleClose={onInfoClose}
          activeUknow={infoState.activeId}
          type={infoState.type}
        />
      )}

      {formState.open && formState.type === "step" && (
        <FullStepKnowmeetFormModal
          activeKnowmeet={formState.activeId}
          handleClose={onFormClose}
          open={formState.open}
          afterSubmit={updateList}
        />
      )}

      {deletionState.open && (
        <AlertDialog
          open={deletionState.open}
          handleClose={onDeletionClose}
          handleAccept={onDelete}
          title="Delete this Meet?"
          message="If you delete this Meet you will not be able to restore it"
        />
      )}
    </KnowmeetModalsContext.Provider>
  )
}

export default KnowmeetModalsProvider
