import React from "react"
import styled from "@emotion/styled"
import { colors } from "../../../../../library/constants/styles/colors"
import { blinkAnimation } from "../../../../../library/constants/styles/animation"
import { TextLineSkeleton } from "../components/textSkeleton"

const Container = styled.div`
  border-radius: 2px;
  background-color: ${colors.white};
  box-shadow: 0 0 2px 0 rgba(210, 210, 210, 0.5);
`

const TopArea = styled.div`
  height: 155px;
  padding: 15px;
  background-color: ${colors.white};
  background: linear-gradient(
    to right,
    ${colors.black7} 0%,
    ${colors.black4} 50%,
    ${colors.black7} 100%
  );
  background-size: 150% 150%;
  animation: ${blinkAnimation} 2s ease-in-out infinite;
`

const Type = styled.div`
  display: flex;
  align-items: center;
  width: 120px;
  height: 44px;
  padding: 10px 12px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.7);
`
const BottomArea = styled.div`
  position: relative;
  display: flex;
  //justify-content: center;
  align-items: center;
  flex-direction: column;
  //height: 250px;
  padding: 15px;
  background-color: ${colors.white};
`

const GroupHeaderSkeleton = () => {
  return (
    <Container>
      <TopArea>
        <Type>
          <TextLineSkeleton height={"20px"} />
        </Type>
      </TopArea>
      <BottomArea>
        <TextLineSkeleton height={"20px"} width={"50%"} />
        <TextLineSkeleton width={"25%"} />
        <div className={"mb-4 w-full"}>
          <TextLineSkeleton />
          <TextLineSkeleton />
          <TextLineSkeleton width={"40%"} />
        </div>
        <div className={"w-full"}>
          <TextLineSkeleton width={"15%"} />
          <TextLineSkeleton width={"20%"} />
          <TextLineSkeleton width={"33%"} />
        </div>
      </BottomArea>
    </Container>
  )
}

export default GroupHeaderSkeleton
