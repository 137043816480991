import React from "react"
import FileProcessor from "./fileProcessor"
import styled from "@emotion/styled"

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
`

export default class LoadersWrapper extends React.PureComponent {
    uploadFile = (type, newFile, callback) => {
        this.props.uploadFile(type, newFile, callback);
    };

    deleteFile = (type, index, key) => {
        this.props.deleteAttachment(type, index, key);
    };

    render() {
        const {uploadedFiles, images, videos, blobs, disabled} = this.props;
        return (
            <Container>
                {images.map((file, i) => {
                    return (
                        <FileProcessor
                            key={file + i}
                            index={i}
                            file={file}
                            type={"image"}
                            uploadFile={this.uploadFile}
                            deleteAttachment={this.deleteFile}
                            fileToProgress={uploadedFiles.find(f => f.key === file || f.key === file.key)}
                            uploadedFiles={uploadedFiles}
                            disabled={disabled}
                        />
                    );
                })}
                {videos.map((file, i) => {
                    return (
                        <FileProcessor
                            key={file + i}
                            index={i}
                            file={file}
                            type={"video"}
                            uploadFile={this.uploadFile}
                            deleteAttachment={this.deleteFile}
                            fileToProgress={uploadedFiles.find(f => f.key === file || f.key === file.key)}
                            disabled={disabled}
                        />
                    );
                })}
                {blobs.map((file, i) => {
                    return (
                        <FileProcessor
                            index={i}
                            type={"blob"}
                            key={file.name + i}
                            uploadFile={this.uploadFile}
                            deleteAttachment={this.deleteFile}
                            fileToProgress={uploadedFiles.find(blob => blob.key === file.key)}
                            name={file.name}
                            lastModified={file.lastModified}
                            size={file.size}
                            disabled={disabled}
                        />
                    );
                })}

            </Container>
        );

    }
}
