import React from "react"
import { Autocomplete } from "@material-ui/lab"
import { FormControl, FormHelperText, TextField } from "@material-ui/core"
import Loader from "../../../ui/loader"
import SelectOption from "../../utils/forms/selectOption"
import EmptyListError from "../../utils/forms/emptyListError"
import ErrorMessage from "../../utils/forms/errorMessage"
import { capitalize } from "lodash"
import withErrorBoundary from "../../../../hocs/guards/withErrorBoundary"

const StaticDataSelect = ({
  data,
  dataReceived = true,
  fieldName,
  value = null,
  onChange,
  required = false,
  multiple = false,
  disabled = false,
  hasError = false,
  errorMessage = "Field is required",
  entityName,
  getItemPhoto,
  getValueName,
  getDisabledValue,
  disabledValueMessage,
  emptyListMessage,
  emptyListLink,
  isWaitingForOtherFields = false,
  isWaitingForOtherFieldsMessage = "",
  leftLabel = true,
  getOptionNameFallback,
  withOptionTooltip = false,
  getOptionTooltipText,
  getOptionTooltipIcon,
  getOptionSelected,
  getOptionHelperText,
}) => {
  const getOptionName = (option) => {
    if (!option) return ""

    if (typeof getValueName === "function" && getValueName(option).length) {
      return getValueName(option)
    }

    if (option?.name?.length) {
      return option.name
    }

    if (
      typeof getOptionNameFallback === "function" &&
      getOptionNameFallback().length
    ) {
      return getOptionNameFallback()
    }

    return "Unnamed"
  }

  const optionTooltipText = (option) => {
    return typeof getOptionTooltipText === "function"
      ? getOptionTooltipText(option)
      : ""
  }

  const optionTooltipIcon = () => {
    return typeof getOptionTooltipIcon === "function"
      ? getOptionTooltipIcon()
      : undefined
  }

  const fieldBox = () => {
    return (
      <div className="form__fieldbox">
        <div className="form__input form__input--select">
          {dataReceived && !data.length && !!emptyListMessage ? (
            <EmptyListError message={emptyListMessage} link={emptyListLink} />
          ) : (
            <FormControl error={hasError} fullWidth>
              <Autocomplete
                disableCloseOnSelect={!!multiple}
                multiple={multiple}
                options={data}
                value={value}
                onChange={(_e, option) => onChange(option)}
                disabled={disabled}
                disableClearable
                getOptionDisabled={(option) =>
                  getDisabledValue?.(option) || false
                }
                renderOption={(option) => {
                  const isItemDisabled = getDisabledValue?.(option) || false
                  const photo = getItemPhoto?.(option)
                  const itemHelperText = getOptionHelperText?.(option) || ""

                  return (
                    <SelectOption
                      name={getOptionName(option)}
                      photo={photo}
                      isDisabled={isItemDisabled}
                      disabledMessage={disabledValueMessage}
                      withTooltip={withOptionTooltip}
                      tooltipText={optionTooltipText(option)}
                      tooltipIcon={optionTooltipIcon()}
                      itemHelperText={itemHelperText}
                    />
                  )
                }}
                noOptionsText={
                  !dataReceived ? (
                    <div className={"f jcc"}>
                      <Loader size={20} />
                    </div>
                  ) : (
                    <p className={"text-center"}>No results</p>
                  )
                }
                renderInput={(params) => (
                  <TextField
                    error={hasError}
                    {...params}
                    label={!leftLabel ? capitalize(entityName) : undefined}
                    placeholder={`Select ${entityName}`}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
                getOptionSelected={(o, v) =>
                  typeof getOptionSelected === "function"
                    ? getOptionSelected(o, v)
                    : o.id === v.id
                }
                getOptionLabel={getOptionName}
              />
              <ErrorMessage error={hasError} message={errorMessage} />
              {isWaitingForOtherFields && (
                <FormHelperText className={"color-black-38"}>
                  {isWaitingForOtherFieldsMessage}
                </FormHelperText>
              )}
            </FormControl>
          )}
        </div>
      </div>
    )
  }

  return leftLabel ? (
    <div className="form__fieldset">
      <label htmlFor={fieldName} className={"form__label"}>
        {entityName} {required && "*"}
      </label>
      {fieldBox()}
    </div>
  ) : (
    fieldBox()
  )
}

export default withErrorBoundary(StaticDataSelect)
