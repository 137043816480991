import React from "react"
import LinkIcon from "@material-ui/icons/Link"
import { clsx } from "clsx"

const PostLinkPreview = ({ data }) => {
  return (
    <a
      href={data.url}
      target="_blank"
      rel="noopener noreferrer"
      className={clsx(
        "flex rounded-lg h-28 overflow-hidden text-sm bg-gray-white hover:bg-primary5 transition-all duration-200 group",
        !data.image && "border border-solid border-gray-200 rounded-lg"
      )}
    >
      {!!data.image ? (
        <img
          src={data.image}
          className="aspect-square object-center object-cover"
          alt={"Link Preview"}
        />
      ) : (
        <div className="flex items-center justify-center p-4 aspect-square text-primary15 text-2xl">
          <LinkIcon color={"inherit"} style={{ fontSize: "4rem" }} />
        </div>
      )}
      <div
        className={clsx(
          "p-2 flex flex-1 flex-col",
          !!data.image &&
            "border border-solid border-gray-200 rounded-tr-lg rounded-br-lg"
        )}
      >
        <p
          className={
            "mb-1 text-primary group-hover:underline line-clamp-1 font-medium"
          }
        >
          {data.title}
        </p>
        <div className={"flex-1 mb-1"}>
          <p className={"text-black54 line-clamp-2"}>{data.description}</p>
        </div>
        <p className={"text-black54 line-clamp-1"}>{data.og_site_name}</p>
      </div>
    </a>
  )
}

export default PostLinkPreview
