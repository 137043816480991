import React from "react"
import SuperListPage from "../../../../components/v2/layout/superListPage/superListPage"
import { FRIENDS } from "../../../../library/store/actions/types/friendsTypes"
import InviteFriendByEmailModal from "./inviteFriendByEmailModal"
import UserFriendshipCard from "../../../../components/v2/layout/cards/userFriendshipCard"

const FriendsSearch = () => {
  const [isInvitationFormOpen, setIsInvitationFormOpen] = React.useState(false)

  return (
    <>
      <SuperListPage
        queryConfig={{
          key: [FRIENDS.SEARCH],
          listKey: "users",
        }}
        headerProps={{
          title: "Find friends",
          button: {
            label: "Invite friend by email",
            action: () => setIsInvitationFormOpen(true),
          },
        }}
        getListItemComponent={({ item }) => (
          <UserFriendshipCard user={item} mutatedQueryKey={[FRIENDS.SEARCH]} />
        )}
      />

      {isInvitationFormOpen && (
        <InviteFriendByEmailModal
          open={isInvitationFormOpen}
          handleClose={() => setIsInvitationFormOpen(false)}
        />
      )}
    </>
  )
}

export default FriendsSearch
