import { persistReducer, persistStore } from "redux-persist"
import { combineReducers, configureStore } from "@reduxjs/toolkit"
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist/es/constants"
import { createLogger } from "redux-logger"
import storage from "redux-persist/lib/storage"

import { authReducer } from "./reducers/authReducer"
import { searchReducer } from "./reducers/searchReducer"
import { loadingReducer } from "./reducers/loadingReducer"
import { personReducer } from "./reducers/personReducer"
import { friendsReducer } from "./reducers/friendsReducer"
import { postsReducer } from "./reducers/postsReducer"
import { knowCredsReducer } from "./reducers/knowCredsReducer"
import { groupsReducer } from "./reducers/groupsReducer"
import { messageReducer } from "./reducers/messagesReducer"
import { productsReducer } from "./reducers/productsReducer"
import { bookmarksReducer } from "./reducers/bookmarksReducer"
import { standardsReducer } from "./reducers/standardsReducer"
import { notificationReducer } from "./reducers/notificationReducer"
import { knowmixReducer } from "./reducers/knowmixReducer"
import { invenstoryReducer } from "./reducers/invenstoryReducer"
import { calendarReducer } from "./reducers/calendarReducer"
import { calendarsAPI } from "./api/calendar/calendarsAPI"
import { spacesAPI } from "./api/calendar/spacesAPI"
import { uknowReducer } from "./reducers/uknowReducer"
import { knowmapReducer } from "./reducers/knowmapReducer"

const rootReducer = combineReducers({
  auth: authReducer,
  search: searchReducer,
  loading: loadingReducer,
  person: personReducer,
  friends: friendsReducer,
  posts: postsReducer,
  knowCreds: knowCredsReducer,
  groups: groupsReducer,
  message: messageReducer,
  products: productsReducer,
  bookmarks: bookmarksReducer,
  standards: standardsReducer,
  notifications: notificationReducer,
  knowmix: knowmixReducer,
  invenstory: invenstoryReducer,
  calendar: calendarReducer,
  uknow: uknowReducer,
  knowmap: knowmapReducer,

  [calendarsAPI.reducerPath]: calendarsAPI.reducer,
  [spacesAPI.reducerPath]: spacesAPI.reducer,
})

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
  timeout: null,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const logger = createLogger({
  predicate: (getState, action) => !!action.type,
})

// let appliedMiddleware = applyMiddleware(logger, thunk);

// if (process.env.NODE_ENV === "production") {
//     appliedMiddleware = applyMiddleware(thunk);
// }

// export const store = createStore(persistedReducer, composeWithDevTools(appliedMiddleware));

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV !== "production") {
      return getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat([logger])
    }

    return getDefaultMiddleware()
  },
})

export const persistor = persistStore(store)
