import { useQuery } from "@tanstack/react-query"
import { nestAPI } from "../../../../../library/networking/API"
import { useSelector } from "react-redux"
import * as qs from "query-string"
import { useAlert } from "../../../../../hooks/useAlert"

export const useSubscriptionsQuery = (options = {}) => {
  const {
    userData: { token },
  } = useSelector(({ auth }) => auth)
  const { data, ...query } = useQuery({
    queryKey: ["USER_SUBSCRIPTIONS"],
    queryFn: async () => {
      const response = await fetch(nestAPI + "users/subscriptions", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })

      if (!response.ok) throw new Error("Cant fetch subscriptions")

      return await response.json()
    },
    staleTime: Infinity,
    // ...options,
  })

  return {
    subscriptions: data,
    ...query,
  }
}

export const useGroupSubscriptionsQuery = (groupId, options = {}) => {
  const {
    userData: { token },
  } = useSelector(({ auth }) => auth)
  const { data, ...query } = useQuery({
    queryKey: ["GROUP_SUBSCRIPTIONS", { id: groupId }],
    queryFn: async (ctx) => {
      const response = await fetch(
        nestAPI + `groups/${groupId}/subscriptions`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )

      if (!response.ok) throw new Error("Cant fetch subscriptions")

      return await response.json()
    },
    staleTime: Infinity,
  })

  return {
    subscriptions: data,
    ...query,
  }
}

export const useSubscriptions = () => {
  const { errorAlert } = useAlert()

  const { userData } = useSelector(({ auth }) => auth)

  const openCheckoutPage = async ({
    plan_id,
    group_id,
    quantity = 1,
    period = "month",
    price_id,
    success_url,
    cancel_url,
  } = {}) => {
    const subscriptionEntity = group_id ? "groups" : "users"

    try {
      const response = await fetch(
        nestAPI + `${subscriptionEntity}/subscriptions/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData.token}`,
          },
          body: JSON.stringify({
            plan_id,
            group_id,
            success_url: success_url || window.location.origin,
            cancel_url: cancel_url || window.location.href,
            period,
            quantity,
            price_id,
          }),
        }
      )
      const data = await response.json()

      if (response.ok) {
        window.location.href = data.link
      }
    } catch (e) {
      errorAlert("Server Error")
    }
  }

  const applySubscriptionPlan = async ({
    subscription_id,
    plan_id,
    price_id,
  }) => {
    try {
      const response = await fetch(
        nestAPI + "users/subscriptions/" + subscription_id + "/change-plan",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData.token}`,
          },
          body: JSON.stringify({
            new_plan_id: plan_id,
            quantity: 1,
            price_id,
          }),
        }
      )

      const data = await response.json()
    } catch (e) {
      console.error(e)
    }
  }

  const openCustomerPortal = async () => {
    const query = qs.stringify({
      returnUrl: window.location.href,
    })

    const response = await fetch(
      nestAPI + "users/subscriptions/customer-portal-url?" + query,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData.token}`,
        },
      }
    )

    const data = await response.json()

    if (response.ok) {
      window.location.href = data.url
    }
  }

  const getUpcomingInvoice = async () => {
    const response = await fetch(
      nestAPI + "users/subscriptions/upcoming-invoice",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData.token}`,
        },
      }
    )

    const data = await response.json()

    return data
  }

  const calcTieredSubscriptionPrice = ({ tiers, quantity = 1, period }) => {
    const tierNumericLimits = tiers
      .map((t) => t.up_to)
      .filter(Boolean)
      .sort((a, b) => a - b)

    let tier = null

    for (let i = 0; i < tierNumericLimits.length; i++) {
      if (quantity > Math.max(...tierNumericLimits)) {
        tier = tiers.find((t) => !t.up_to)
        break
      } else if (quantity <= tierNumericLimits[i]) {
        tier = tiers.find((t) => t.up_to === tierNumericLimits[i])
        break
      }
    }

    return {
      perSeat: `$${(tier.unit_amount / 100).toFixed(2)}/seat ${period === "year" ? "yearly" : "monthly"}`,
      total: `$${((tier.unit_amount / 100) * Number(quantity)).toFixed(2)} ${period === "year" ? "yearly" : "monthly"}`,
    }
  }

  const calcFlatSubscriptionPrice = ({
    unit_amount,
    quantity = 1,
    period = "year",
  }) => {
    return {
      perSeat: `$${(unit_amount / 100).toFixed(2)}/seat ${period === "year" ? "yearly" : "monthly"}`,
      total: `$${((unit_amount / 100) * Number(quantity)).toFixed(2)} ${period === "year" ? "yearly" : "monthly"}`,
    }
  }

  return {
    openCheckoutPage,
    applySubscriptionPlan,
    openCustomerPortal,
    getUpcomingInvoice,
    calcTieredSubscriptionPrice,
    calcFlatSubscriptionPrice,
  }
}

export const useUpcomingInvoiceQuery = (options = {}) => {
  const {
    userData: { token },
  } = useSelector(({ auth }) => auth)
  const { data, ...query } = useQuery({
    queryKey: ["UPCOMING_INVOICE"],
    queryFn: async () => {
      const response = await fetch(
        nestAPI + "users/subscriptions/upcoming-invoice",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )

      if (!response.ok) throw new Error("Cant fetch upcoming invoice")

      return await response.json()
    },
    staleTime: Infinity,
    ...options,
  })

  return {
    upcomingInvoice: data,
    ...query,
  }
}
