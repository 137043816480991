import { store } from "./store/configureStore"
import axios from "axios"
import { apiUrl } from "./networking/API"

export const queryFn = async ({ queryKey, url, params }) => {
  const token = store.getState()?.auth?.userData?.token

  try {
    const response = await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token || ""}`,
      },
      url: apiUrl + queryKey[0],
      data: JSON.stringify(queryKey[1]),
    })

    return await response.data
  } catch (e) {
    if (e?.response?.data?.error?.code === 20003) {
      localStorage.clear()
      window.location.href = "/"
    }
    throw e.response.data
  }
}
