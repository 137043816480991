import React, { Component } from "react"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchOutlined from "@material-ui/icons/SearchOutlined"
import Button from "@material-ui/core/Button"
import { Link, NavLink, withRouter } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import * as qs from "query-string"
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core"

class KnowCredListHeader extends Component {
  state = {
    query: "",
    activeTab: this.props.match.path,
    focusField: true,
    order: "",
  }

  textField = React.createRef()

  componentDidMount() {
    const { name, order } = qs.parse(this.props.location.search)
    this.setState({ query: name || "", order: order || "" })

    switch (this.props.match.path) {
      case routes.KNOWCRED:
        this.setState({
          activeTab: "all",
        })
        break

      case routes.KNOWCRED_VALIDATED:
        this.setState({
          activeTab: "validated",
        })
        break

      default:
        return
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.match.path !== prevProps.match.path) {
      this.setState({
        query: "",
        // order: "",
      })
      this.textField.current.focus()
    }
  }

  handleChange = (event) => {
    this.setState({ query: event.target.value })
  }

  processSearch = (event) => {
    event.preventDefault()
    const { query } = this.state

    this.props.search(query)
  }

  processFilter = (event) => {
    this.setState({ order: event.target.value })
    this.props.sort(event.target.value)
  }

  render() {
    const { query, focusField, order } = this.state
    const { match, noResults, noResultsLabel } = this.props

    const iconStyle = { color: "#9b9a9b" }

    return (
      <div className="box search-header">
        <nav className="h-tabs f aic jcsb">
          <ul className="h-tabs__nav-list">
            {this.props.menuItems.map(({ label, path, counter }) => (
              <li
                className={`h-tabs__item ${
                  match.path === path || match.url === path
                    ? "h-tabs__item--active"
                    : ""
                }`}
                key={label}
              >
                <NavLink
                  exact
                  to={order ? `${path}?order=${order}` : path}
                  className="h-tabs__link"
                  activeClassName={"h-tabs__item--active"}
                >
                  {/*{label} {counter}*/}
                  {label}
                </NavLink>
              </li>
            ))}
          </ul>
          <Button
            color="primary"
            variant="contained"
            component={Link}
            to={routes.KNOWCRED_CREATE}
          >
            Create Cred
          </Button>
        </nav>
        <div className="box__content">
          <form onSubmit={this.processSearch} className={"mb20"}>
            <TextField
              className="search-header__input full-width"
              id="search-header-input"
              placeholder="Start typing Cred name"
              margin="none"
              autoComplete="off"
              autoFocus={focusField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined style={iconStyle} />
                  </InputAdornment>
                ),
              }}
              value={query}
              onChange={(e) => this.handleChange(e)}
              inputRef={this.textField}
            />
          </form>
          <div className="f aib">
            {/*<label htmlFor="sort" className={"color-black-54 mr20"}>*/}
            {/*  Order:{" "}*/}
            {/*</label>*/}
            <FormControl style={{ width: 130 }}>
              <InputLabel shrink id="sort">
                Order
              </InputLabel>
              <Select
                fullWidth
                labelId={"sort"}
                displayEmpty
                value={this.state.order}
                onChange={this.processFilter}
              >
                {/*<MenuItem value={""}>*/}
                {/*  <span className={"color-black-54"}>Default</span>*/}
                {/*</MenuItem>*/}
                <MenuItem value={""}>Newer first</MenuItem>
                <MenuItem value={"DESC"}>Older first</MenuItem>
              </Select>
            </FormControl>
          </div>
          {noResults && (
            <div className="aic color-black-54 f jcc pt15">
              {noResultsLabel}
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ auth }) => ({ auth })
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(KnowCredListHeader)))
