import { GROUPS } from "../../../../library/store/actions/types/groupsTypes"
import useEntityPicker from "../../../../hooks/useEntityPicker"
import PickerSelect from "../select/pickerSelect"
import {
  getUserName,
  getUserPhoto,
} from "../../../../library/utils/user/userUtils"

const GroupMemberPicker = ({
  value,
  onChange,
  userLabel = "user",
  groupId,
  tags = [],
  role,
  hasError = false,
  errorMessage,
  required = false,
  multiple = false,
  disabledValues = [],
  disabledValueMessage = "",
}) => {
  const { list, dataReceived } = useEntityPicker({
    fetchConfig: {
      apiUrl: GROUPS.PARTICIPANTS.LIST,
      params: {
        group_id: groupId,
        role,
        tags,
        offset: 0,
        limit: 100,
      },
    },
    key: "users",
  })

  return (
    <PickerSelect
      name={`membersRole${role}Tags${tags.join(",")}`}
      entityName={userLabel}
      value={value}
      onChange={onChange}
      required={required}
      hasError={hasError}
      errorMessage={errorMessage}
      disabled={!dataReceived}
      dataReceived={dataReceived}
      multiple={multiple}
      list={list}
      getValueName={getUserName}
      getItemPhoto={getUserPhoto}
      getDisabledValue={(o) => !!disabledValues.find((v) => v.id === o.id)}
      disabledValueMessage={disabledValueMessage}
    />
  )
}

export default GroupMemberPicker
