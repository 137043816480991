export const PRODUCTS = {
  LIST: 'products.list',
  SEARCH: 'products.search',
  CLEAR_SEARCH: 'products.search.clear',
  GET: 'products.get',
  CLEAR: 'products.clear',
  CREATE: 'products.create',
  UPDATE: 'products.update',
  DELETE: 'products.delete',
  LIBRARY: {
    ADD: 'products.library.add',
    DELETE: 'products.library.delete',
    LIST: 'products.library.list',
  },
  REVIEWS: {
    LIST: 'products.reviews.list',
    CREATE: 'products.reviews.create',
    UPDATE: 'products.reviews.update',
    DELETE: 'products.reviews.delete',
  },
  ACQUISITIONS: {
    SEARCH: 'products.acquisitions.search',
    ACCESS: 'products.acquisitions.access',
    CLEAR: 'products.acquisitions.clear',
  },
  SUGGESTIONS: {
    SEARCH: 'products.suggestions.search',
  },
  PURCHASE: 'products.purchase',
  SCHEDULE_DEMO: 'products.scheduleDemo',
  REQUEST_DEMO: 'products.requestDemo',
  DEMO_REQUESTS_LIST: 'products.listDemos',
  DEMO_REQUESTS_LIST_CLEAR: 'products.listDemosClear',
}
