export const INVENSTORY = {
    RECORDS: {
        GET: "invenstory.records.get",
        CREATE: "invenstory.records.create",
        UPDATE: "invenstory.records.update",
        DELETE: "invenstory.records.delete",
        CLEAR: "invenstory.record.clear",
        LIST: "invenstory.records.list",
        CLEAR_LIST: "ivenstory.clearList",
    },

    CONNECTIONS: {
        LIST: "invenstory.connections.list",
        LIST_UPDATE: "invenstory.connections.list.update",
        REMOVE: "invenstory.connections.remove",
        RESTRICT: "invenstory.connections.restrict",
        UPDATE: "invenstory.connections.list.update",
    },

    INVITES: {
        CREATE: "invenstory.connections.requests.create",
        DECLINE: "invenstory.connections.requests.decline",
        CANCEL: "invenstory.connections.requests.cancel",
        ACCEPT: "invenstory.connections.requests.accept",
    },
    SWITCH_VIEW: "invenstory.switch.view",
    CLEAR: "invenstory.clear",
};
