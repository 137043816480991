import { format, getHours, getMinutes } from "date-fns"

export const getMessageTime = (created) => {
  return format(new Date(created), "h:mm aaa")
}

export const minutesToString = (totalMinutes) => {
  const hours = Math.floor(Math.floor(totalMinutes) / 60)
  const minutes = Math.floor(totalMinutes) % 60

  return `${hours}h, ${minutes}m`
}

export const weekDays = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
}

export const weekDaysArray = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
]

export const formatTime = (date) => {
  const hh = getHours(date) < 10 ? `0${getHours(date)}` : getHours(date)
  const mm = getMinutes(date) < 10 ? `0${getMinutes(date)}` : getMinutes(date)
  console.log(date, hh, mm)

  return `${hh}:${mm}`
}

export const hours = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
]

export const minutes = [
  "00",
  "05",
  "10",
  "15",
  "20",
  "25",
  "30",
  "35",
  "40",
  "45",
  "50",
  "55",
]

export const hoursData = {
  am: [
    { value: "01", label: "01" },
    { value: "02", label: "02" },
    { value: "03", label: "03" },
    { value: "04", label: "04" },
    { value: "05", label: "05" },
    { value: "06", label: "06" },
    { value: "07", label: "07" },
    { value: "08", label: "08" },
    { value: "09", label: "09" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "00", label: "12" },
  ],
  pm: [
    { value: "13", label: "01" },
    { value: "14", label: "02" },
    { value: "15", label: "03" },
    { value: "16", label: "04" },
    { value: "17", label: "05" },
    { value: "18", label: "06" },
    { value: "19", label: "07" },
    { value: "20", label: "08" },
    { value: "21", label: "09" },
    { value: "22", label: "10" },
    { value: "23", label: "11" },
    { value: "12", label: "12" },
  ],
}

export const hoursUS = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
]

export const timePeriods = [
  { value: "am", label: "A.M." },
  { value: "pm", label: "P.M." },
]
