import React from "react"
import { FormGroup } from "@material-ui/core"
import { TimePicker } from "@material-ui/pickers"

const createDate = (time) => {
  const hh = time.substring(0, 2)
  const mm = time.substring(3, 5)

  return new Date().setHours(hh, mm, 0, 0)
}

const extractTime = (date) => {
  return new Date(date).toLocaleTimeString("en-GB").substring(0, 5)
}

const TimePeriodPicker = ({
  label = "Duration",
  required = true,
  startTime = "10:00",
  endTime = "11:00",
  onChange,
  compact = false,
}) => {
  const [period, setPeriod] = React.useState({
    start: createDate(startTime),
    end: createDate(endTime),
  })

  const handleDateChange = (date, name) => {
    console.log(date, name)
    setPeriod((prevState) => ({
      ...prevState,
      [name]: date,
    }))
  }

  React.useEffect(() => {
    onChange({
      start: extractTime(period.start),
      end: extractTime(period.end),
    })
  }, [period])

  const fields = (
    <div className={"f aib jcsb"} style={{ width: 330 }}>
      <FormGroup>
        <TimePicker
          style={{ width: 145 }}
          id="start-time"
          label="From"
          value={period.start}
          onChange={(date) => handleDateChange(date, "start")}
          KeyboardButtonProps={{
            "aria-label": "change start time",
          }}
          showTodayButton
          minutesStep={5}
          todayLabel="now"
        />
        {/*<FormHelperText>{getLocaleTime(startTime)}</FormHelperText>*/}
      </FormGroup>

      <span>—</span>

      <FormGroup>
        <TimePicker
          style={{ width: 145 }}
          id="end-time"
          label="To"
          value={period.end}
          onChange={(date) => handleDateChange(date, "end")}
          KeyboardButtonProps={{
            "aria-label": "change start time",
          }}
          showTodayButton
          minutesStep={5}
          todayLabel="now"
        />
        {/*<FormHelperText>{getLocaleTime(endTime)}</FormHelperText>*/}
      </FormGroup>
    </div>
  )

  return !compact ? (
    <div className="form__fieldset">
      <span className="form__label">
        {label} {required && "*"}
      </span>
      <div className="form__fieldbox">{fields}</div>
    </div>
  ) : (
    <div>
      {/*<p className={"color-black-54"}>*/}
      {/*  {label} {required && "*"}*/}
      {/*</p>*/}
      {fields}
    </div>
  )
}

export default TimePeriodPicker
