import React, { useState } from "react"
import { useParams } from "react-router-dom"
import {
  composeUserHighlightedEducation,
  composeUserHighlightedWork,
} from "../../../../../../../../../library/utils/getUserHighlightedInfo"
import { useDispatch, useSelector } from "react-redux"
import {
  addStudentToSchoolGrade,
  clearSchoolGradeStudentsSearchList,
  deleteStudentFromSchoolGrade,
  searchSchoolGradeStudents,
  transferStudentToSchoolGrade,
} from "../../../../../../../../../library/store/actions/creators/groupsCreators"
import { calendarGrades } from "../../../../../../../../../library/constants/educationalDictionary"
import ListPage from "../../../../../../../../../components/v2/layout/listPage/listPage"
import useCreator from "../../../../../../../../../hooks/useCreator"
import UserCard from "../../../../../../../../../components/v2/layout/cards/userCard"
import AlertDialog from "../../../../../../../../../components/ui/alertDialog"
import { getUserName } from "../../../../../../../../../library/utils/user/userUtils"

const GroupSettingsCalendarGradeInvites = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const creator = useCreator()

  const { userData } = useSelector(({ auth }) => auth)
  const {
    schoolGradeMembersSearchList,
    schoolGradeMembersSearchListEndReached,
  } = useSelector(({ groups }) => groups)

  const [activeUser, setActiveUser] = useState(null)
  const [transferInitiated, setTransferInitiated] = useState(false)

  const addMember = async (user) =>
    await creator(
      addStudentToSchoolGrade({
        grade: parseInt(params.gradeId),
        group_id: parseInt(params.groupId),
        user_id: user.id,
      })
    )

  const removeMember = async (user) =>
    await creator(
      deleteStudentFromSchoolGrade({
        group_id: parseInt(params.groupId),
        grade: parseInt(params.gradeId),
        user_id: user.id,
        removeFromList: false,
      })
    )

  const transferMember = async () => {
    if (activeUser) {
      await creator(
        transferStudentToSchoolGrade({
          group_id: parseInt(params.groupId),
          new_grade: parseInt(params.gradeId),
          user_id: activeUser.id,
        })
      )
    }

    closeTransfer()
  }

  const initTransfer = (user) => {
    setActiveUser(user)
    setTransferInitiated(true)
  }

  const closeTransfer = () => {
    setTransferInitiated(false)
    setActiveUser(null)
  }

  return (
    <>
      <ListPage
        title={"Add members"}
        noResultsLabel={"This group doesn't have members yet"}
        getList={async ({ name, offset }) =>
          await dispatch(
            searchSchoolGradeStudents({
              group_id: parseInt(params.groupId),
              name: name || undefined,
              offset,
            })
          )
        }
        clearList={() => dispatch(clearSchoolGradeStudentsSearchList())}
        list={schoolGradeMembersSearchList}
        listEndReached={schoolGradeMembersSearchListEndReached}
        getListItemComponent={({ item }) => {
          const isGradeMember =
            !!item.grade && parseInt(params.gradeId) === item.grade
          const isOtherGradeMember =
            !!item.grade && parseInt(params.gradeId) !== item.grade
          return (
            <UserCard
              user={item}
              infoComponent={() => (
                <>
                  {isGradeMember && <p>Member of this grade</p>}
                  {!item.grade && <p>Not a member of any grade</p>}
                  {isOtherGradeMember && (
                    <p>
                      Current user's grade:{" "}
                      {calendarGrades.find((g) => g.id === item.grade).name}
                    </p>
                  )}
                  {!!item.education &&
                    composeUserHighlightedEducation(item.education)}
                  {!!item.work && composeUserHighlightedWork(item.work)}
                </>
              )}
              controls={{
                button:
                  item.id !== userData.id &&
                  !isOtherGradeMember &&
                  !isGradeMember
                    ? {
                        label: "Add",
                        action: () => addMember(item),
                      }
                    : isOtherGradeMember && !!item.grade
                    ? {
                        label: "Add",
                        action: () => initTransfer(item),
                      }
                    : undefined,
                dropdownButton:
                  item.id !== userData.id && isGradeMember
                    ? {
                        buttonLabel: "Added",
                        items: [
                          {
                            label: "Remove from grade",
                            action: () => removeMember(item),
                          },
                        ],
                      }
                    : undefined,
              }}
              key={item.id}
            />
          )
        }}
      />

      {transferInitiated && activeUser && (
        <AlertDialog
          open={transferInitiated}
          handleClose={closeTransfer}
          handleAccept={transferMember}
          title={`Transfer user to this grade?`}
          message={`Are you sure you want to transfer ${getUserName(
            activeUser
          )} from ${
            calendarGrades.find((g) => g.id === activeUser.grade).name
          } grade to ${
            calendarGrades.find((g) => g.id === parseInt(params.gradeId)).name
          } grade?`}
        />
      )}
    </>
  )
}

export default GroupSettingsCalendarGradeInvites
