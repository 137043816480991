import React, { Component } from "react"
import CalendarFormModal from "../../../../calendar/components/modals/calendarFormModal"
import AlertDialog from "../../../../../../components/ui/alertDialog"
import getErrorText from "../../../../../../library/constants/errorTypes"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { withRouter } from "react-router-dom"
import {
  clearCalendarsList,
  clearSchedulesCalendarsList,
  clearSchedulesList,
  completeCalendar,
  createCalendar,
  createSchedule,
  deleteCalendar,
  deleteSchedule,
  getCalendarsList,
  getSchedulesCalendarsList,
  getSchedulesList,
  updateCalendar,
} from "../../../../../../library/store/actions/creators/calendarCreators"
import withScroll from "../../../../../../hocs/withScroll/withScroll"
import * as qs from "query-string"
import CalendarsListItem from "./components/calendarsListItem"
import Loader from "../../../../../../components/ui/loader"
import { LIMIT } from "../../../../../../library/constants/limits"
import CalendarsHeader from "./components/calendarsHeader/calendarsHeader"
import { getGroup } from "../../../../../../library/store/actions/creators/groupsCreators"
import GroupSettingsCalendarProvider from "./GroupSettingsCalendarContext"

class GroupSettingsCalendars extends Component {
  state = {
    showFormModal: {
      show: false,
      type: "create",
    },
    showCompletionDialog: false,
    showDeletionDialog: false,
    activeCalendar: null,
    offset: 0,
    dataReceived: false,
  }

  getList = async ({ offset = 0 }) => {
    const {
      location: { search },
      groups: { currentGroup },
    } = this.props
    const { q } = qs.parse(search)

    try {
      this.setState({ dataReceived: false })
      if (this.props.calendarType === "school") {
        await this.props.getSchedulesList({
          school_id: parseInt(this.props.match.params.groupId),
          name: q || undefined,
          offset,
        })
      } else {
        if (this.props.groups.currentGroup.type === 5) {
          await this.props.getCalendarsList({
            district_id: parseInt(this.props.match.params.groupId),
            name: q || undefined,
            not_deleted_only: true,
            offset,
          })
        } else {
          await this.props.getSchedulesCalendarsList({
            school_id: parseInt(this.props.match.params.groupId),
            name: q || undefined,
            offset,
          })
        }
      }
      this.setState({ dataReceived: true, offset })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  async componentDidMount() {
    await this.props.getGroup({ id: parseInt(this.props.match.params.groupId) })
    await this.getList({})
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      location: { search },
      match: { path },
    } = this.props

    if (path !== prevProps.match.path) {
      if (this.props.calendarType === "district") {
        if (this.props.groups.currentGroup.type === 5) {
          this.props.clearCalendarsList()
        } else {
          this.props.clearSchedulesCalendarsList()
        }
      } else {
        this.props.clearSchedulesList()
      }
      await this.getList({})
    }

    if (search && search !== prevProps.location.search) {
      if (this.props.calendarType === "district") {
        if (this.props.groups.currentGroup.type === 5) {
          this.props.clearCalendarsList()
        } else {
          this.props.clearSchedulesCalendarsList()
        }
      } else {
        this.props.clearSchedulesList()
      }

      await this.getList({})
    }
  }

  componentWillUnmount() {
    if (this.props.calendarType === "district") {
      if (this.props.groups.currentGroup.type === 5) {
        this.props.clearCalendarsList()
      } else {
        this.props.clearSchedulesCalendarsList()
      }
    } else {
      this.props.clearSchedulesList()
    }
  }

  onScroll = async () => {
    const listEndReached =
      this.props.calendarType === "district"
        ? this.props.groups.currentGroup.type === 5
          ? this.props.calendar.listEndReached
          : this.props.calendar.schedulesCalendarsListEndReached
        : this.props.calendar.schedulesListEndReached

    if (!this.state.dataReceived || listEndReached) return

    await this.getList({ offset: this.state.offset + LIMIT })
  }

  searchCalendars = (name) => {
    const { history } = this.props

    history.push({
      search: `?q=${name}`,
    })
  }

  onCloseFormModal = () =>
    this.setState({
      activeCalendar: null,
      showFormModal: { show: false, type: "create" },
    })
  onCloseCompletionDialog = () =>
    this.setState({ activeCalendar: null, showCompletionDialog: false })
  onCloseDeletionDialog = () =>
    this.setState({ activeCalendar: null, showDeletionDialog: false })

  initCompletion = (calendar_id) =>
    this.setState({ activeCalendar: calendar_id, showCompletionDialog: true })

  onCompleteCalendar = async () => {
    try {
      await this.props.completeCalendar({ id: this.state.activeCalendar })
      this.onCloseCompletionDialog()
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  initEdit = (calendar_id) => {
    this.setState({
      activeCalendar: calendar_id,
      showFormModal: {
        show: true,
        type: "edit",
      },
    })
  }

  initCreate = () =>
    this.setState({ showFormModal: { show: true, type: "create" } })

  onCreateCalendar = async (data) => {
    try {
      await this.props.createCalendar(data)
      this.onCloseFormModal()
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  onEditCalendar = async (data) => {
    try {
      await this.props.updateCalendar(data)
      this.onCloseFormModal()
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  initDeletion = (calendar_id) =>
    this.setState({ activeCalendar: calendar_id, showDeletionDialog: true })

  onDeleteCalendar = async () => {
    try {
      if (this.props.calendarType === "district") {
        await this.props.deleteCalendar({ id: this.state.activeCalendar })
      } else {
        await this.props.deleteSchedule({ id: this.state.activeCalendar })
      }

      this.onCloseDeletionDialog()
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  createSchedule = async (calendar_id) => {
    const {
      groups: { currentGroup },
    } = this.props

    try {
      await this.props.createSchedule({
        school_id: currentGroup.id,
        calendar_id,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  render() {
    const {
      showFormModal,
      showDeletionDialog,
      showCompletionDialog,
      activeCalendar,
    } = this.state
    const { q: query } = qs.parse(this.props.location.search)

    const list =
      this.props.calendarType === "district"
        ? this.props.groups.currentGroup.type === 5
          ? this.props.calendar.list
          : this.props.calendar.schedulesCalendarsList
        : this.props.calendar.schedulesList

    return (
      <>
        <CalendarsHeader
          noResultsLabel={
            query ? "" : "This district doesn't have Calendars yet"
          }
          noResults={this.state.dataReceived && !list.length}
          initCreate={this.initCreate}
          search={this.searchCalendars}
          groupType={this.props.groups.currentGroup.type}
        />

        {list.map((item) => (
          <GroupSettingsCalendarProvider key={item.id}>
            {({ onCalendarDialogOpen }) => (
              <CalendarsListItem
                calendar={item}
                openCompleteCalendar={this.initCompletion}
                openEditCalendar={this.initEdit}
                openDeleteCalendar={this.initDeletion}
                openCalendarDetails={onCalendarDialogOpen}
                schoolView={[4, 6].includes(
                  this.props.groups.currentGroup.type
                )}
                calendarType={this.props.calendarType}
                createSchedule={this.createSchedule}
              />
            )}
          </GroupSettingsCalendarProvider>
        ))}
        {!this.state.dataReceived && <Loader />}

        {/*<CalendarsList*/}
        {/*    openCreateCalendar={this.initCreate}*/}
        {/*    openEditCalendar={this.initEdit}*/}
        {/*    openDeleteCalendar={this.initDeletion}*/}
        {/*    openCompleteCalendar={this.initCompletion}*/}
        {/*/>*/}

        {showFormModal.show && (
          <CalendarFormModal
            open={showFormModal.show}
            onClose={this.onCloseFormModal}
            onSubmit={
              showFormModal.type === "create"
                ? this.onCreateCalendar
                : this.onEditCalendar
            }
            activeCalendar={activeCalendar}
            edit={showFormModal.type === "edit"}
            groupType={this.props.groups.currentGroup.type}
          />
        )}

        {showCompletionDialog && (
          <AlertDialog
            open={showCompletionDialog}
            handleClose={this.onCloseCompletionDialog}
            handleAccept={this.onCompleteCalendar}
            title="Complete this calendar?"
            message="You cannot edit or delete the calendar after completion."
          />
        )}

        {showDeletionDialog && (
          <AlertDialog
            open={showDeletionDialog}
            handleClose={this.onCloseDeletionDialog}
            handleAccept={this.onDeleteCalendar}
            title="Delete this calendar?"
            message="If you delete this Calendar you will not be able to restore it"
          />
        )}
      </>
    )
  }
}

const mapStateToProps = ({ auth, groups, calendar }) => ({
  auth,
  groups,
  calendar,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createCalendar,
      updateCalendar,
      deleteCalendar,
      completeCalendar,
      getCalendarsList,
      getSchedulesCalendarsList,
      clearCalendarsList,
      getGroup,
      getSchedulesList,
      clearSchedulesList,
      clearSchedulesCalendarsList,
      createSchedule,
      deleteSchedule,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(withScroll(GroupSettingsCalendars))))
