import React from "react"
import "./conversationsListItem.scss"
import avatarDefault from "../../../../../assets/img/user_avatar.svg"
import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../../../library/constants/routes"
import { USER } from "../../../../../library/constants/routes"
import dateConvert from "../../../../../library/utils/dateConvert"
import { getMessageTime } from "../../../../../library/utils/timeConvert"
import Linkify from "linkifyjs/react"
import LineSlice from "../../../../../components/ui/lineSlice"

const ConversationsListItem = ({ conversation, type = "" }) => {
  const avatar =
    type === "search"
      ? conversation.chat.user.photo
        ? conversation.chat.user.photo
        : avatarDefault
      : conversation.user.photo
      ? conversation.user.photo
      : avatarDefault
  const fullName =
    type === "search"
      ? `${conversation.chat.user.first_name} ${conversation.chat.user.last_name}`
      : `${conversation.user.first_name} ${conversation.user.last_name}`
  const created =
    type === "search" ? conversation.created : conversation.message.created
  const isRead =
    type === "search" ? conversation.chat.is_read : conversation.is_read
  const date =
    dateConvert(undefined, created) === "today"
      ? getMessageTime(created)
      : dateConvert(undefined, created)
  const userId =
    type !== "search" ? conversation.user.id : conversation.chat.user.id
  return (
    <Link
      to={{
        pathname: routes.CONVERSATION,
        search: `?userId=${userId}`,
      }}
      className={`box list-item conversation-preview ${
        !isRead && "conversation-preview--unread"
      }`}
    >
      <span className="list-item__img">
        <Link to={generatePath(USER, { userId: userId })}>
          <img src={avatar} alt="User" />
        </Link>
      </span>

      <div className="list-item__info">
        <div className="f aic jcsb">
          <span className="list-item__name">{fullName}</span>
          <span className="color-black-54">{date}</span>
        </div>

        <Linkify tagName={"pre"} options={{ className: "link" }}>
          {((_) => {
            if (type !== "search") {
              return conversation.message.message ? (
                <LineSlice text={conversation.message.message} linesCount={2} />
              ) : (
                <span className="link">Attachment</span>
              )
            }
            return conversation.message ? (
              <LineSlice text={conversation.message} linesCount={2} />
            ) : (
              <span className="link">Attachment</span>
            )
          })()}
        </Linkify>
      </div>
    </Link>
  )
}

export default ConversationsListItem
