import React, { Component } from "react"
import ProductRatingGeneral from "./productRating/productRatingGeneral"
import ProductReviewsList from "./productReviewsList"
import ProductReviewCreate from "./productReviewCreate"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { withRouter } from "react-router-dom"
import { getProduct } from "../../../../../library/store/actions/creators/productsCreators"
import Loader from "../../../../../components/ui/loader/loader"
import getErrorText from "../../../../../library/constants/errorTypes"

class ProductReviews extends Component {
  state = {
    acquired: !!this.props.products.currentProduct.acquisition,
    deleted: this.props.products.currentProduct.deleted,
    dataReceived: true,
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      match: { params },
    } = this.props

    if (this.props.products.reviews !== prevProps.products.reviews) {
      this.setState({ dataReceived: false })

      try {
        this.props.getProduct({ id: parseInt(params.productId) })
        this.setState({
          dataReceived: true,
          acquired: !!this.props.products.currentProduct.acquisition,
          deleted: this.props.products.currentProduct.deleted,
        })
      } catch ({ error }) {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    } else if (
      this.props.products.currentProduct !==
        prevProps.products.currentProduct &&
      !!this.props.products.currentProduct.acquisition !== this.state.acquired
    ) {
      this.setState({
        acquired: !!this.props.products.currentProduct.acquisition,
      })
    }
  }

  render() {
    const { acquired, deleted, dataReceived } = this.state

    if (dataReceived) {
      const {
        reviewed,
        rating,
        reviews,
        reviews_number,
        rating_1_percent,
        rating_2_percent,
        rating_3_percent,
        rating_4_percent,
        rating_5_percent,
      } = this.props.products.currentProduct
      return (
        <div className="box product-testimonials">
          <div className="box__content">
            <p className="box__subheading">Customer reviews</p>
            <ProductRatingGeneral
              rating={rating}
              reviewsNumber={reviews_number}
              oneStar={rating_1_percent}
              twoStars={rating_2_percent}
              threeStars={rating_3_percent}
              fourStars={rating_4_percent}
              fiveStars={rating_5_percent}
            />
          </div>
          {/*<div className="h-divider h-divider--horizontal-margin"></div>*/}
          {/*<div className="box__content">*/}
          {/*<ProductRatingDetailed/>*/}
          {/*</div>*/}
          <div className="h-divider h-divider--horizontal-margin"></div>
          <div className="box__content">
            {acquired && !reviewed && !deleted ? (
              <ProductReviewCreate />
            ) : (
              <span>Add product or purchase to write a review.</span>
            )}
          </div>
          {reviews.length > 0 && (
            <>
              <div className="h-divider h-divider--horizontal-margin"></div>
              <div className="box__content">
                <ProductReviewsList />
              </div>
            </>
          )}
        </div>
      )
    } else {
      return <Loader />
    }
  }
}

const mapStateToProps = ({ auth, products }) => ({ auth, products })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getProduct }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(ProductReviews)))
