import React, { useState } from "react"
import { useSelector } from "react-redux"
import BlockFormModal from "./components/blockFormModal"
import BlockDialog from "./components/blockDialog"
import BlockCard from "../../../../../../../../../../../../components/v2/layout/cards/blockCard"
import SuperListPage from "../../../../../../../../../../../../components/v2/layout/superListPage/superListPage"
import { SEGMENTS } from "../../../../../../../../../../../../library/store/actions/types/groupsTypes"
import { useIdParam } from "../../../../../../../../../../../../hooks/useIdParam"
import { useBlockMutations } from "../../../../../../../../../../../../hooks/data/calendar/schedules"
import { weekDays } from "../../../../../../../../../../../../library/utils/timeConvert"
import Button from "@material-ui/core/Button"
import {
  rotations,
  rotationTypes,
} from "../../../../../../../../../../../../library/constants/groupTypes"
import {
  CARD_SIZES,
  getCardSize,
} from "../../../../../../../../../../../../library/constants/styles/cardSizes"

const SegmentSectionBlocks = () => {
  const section_id = useIdParam("sectionId")
  const today = new Date().getDay()

  const { currentSection } = useSelector(({ groups }) => groups)

  const [activeTab, setActiveTab] = useState(today.toString())

  const queryKey = [
    SEGMENTS.SECTIONS.BLOCKS.LIST,
    { section_id, rotation: Number(activeTab) },
  ]

  const { deleteBlock } = useBlockMutations(queryKey)

  return (
    <>
      <SuperListPage
        getHeaderComponent={({ openFormDialog }) => (
          <div className="box">
            <nav className="h-tabs flex justify-between items-center">
              <ul className="h-tabs__nav-list">
                {Object.entries(weekDays).map(([key, value]) => {
                  return (
                    <li
                      key={value}
                      className={`relative text-center h-tabs__item h-tabs__item--dense ${
                        activeTab === key ? "h-tabs__item--active" : ""
                      }`}
                      onClick={() => setActiveTab(key)}
                    >
                      <p className="h-tabs__link text-sm lg:text-base border-b-0 leading-none">
                        {value}
                      </p>
                      <p className={"text-xs text-black38 leading-none nowrap"}>
                        {rotations[currentSection.rotations[key].type]}
                      </p>
                      {Number(key) === today && (
                        <div
                          className={
                            "absolute top-0 right-0 w-1 h-1 rounded-full bg-primary"
                          }
                        />
                      )}
                    </li>
                  )
                })}
              </ul>
              <Button
                variant={"contained"}
                color={"primary"}
                onClick={() => openFormDialog()}
              >
                Create Block
              </Button>
            </nav>
          </div>
        )}
        listItemHeight={getCardSize(9).numValPx}
        noResultsLabel={`This section doesn't have blocks for ${weekDays[activeTab]} yet`}
        queryConfig={{
          key: queryKey,
          fetchParams: {
            section_id,
            rotation: Number(activeTab),
          },
          listKey: "blocks",
        }}
        getListItemComponent={({
          item,
          openEditDialog,
          openDeletionDialog,
          openInfoDialog,
        }) => (
          <BlockCard
            block={item}
            openDeleteBlock={openDeletionDialog}
            openEditBlock={openEditDialog}
            openBlockDetails={openInfoDialog}
            key={item.id}
          />
        )}
        getFormDialogComponent={({ open, onClose, activeItem }) => {
          return (
            <BlockFormModal
              open={open}
              onClose={onClose}
              activeBlock={activeItem}
              queryKey={queryKey}
              chosenRotation={activeTab}
            />
          )
        }}
        getDeletionDialogComponent={{
          entityName: "block",
          onDelete: (id) => deleteBlock.mutateAsync({ id }),
        }}
        getInfoDialogComponent={({ open, onClose, activeItem }) => (
          <BlockDialog open={open} onClose={onClose} activeBlock={activeItem} />
        )}
      />
    </>
  )
}

export default SegmentSectionBlocks
