import React, { Component } from "react"
import "./reset-password-apply.scss"
import { bindActionCreators } from "redux"
import { resetPasswordApply } from "../../../../library/store/actions/creators/authCreators"
import { connect } from "react-redux"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import { withRouter } from "react-router-dom"
import * as qs from "query-string"
import getErrorText from "../../../../library/constants/errorTypes"
import * as routes from "../../../../library/constants/routes"
import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import { withoutSpaces } from "../../../../library/utils/validators"

class ResetPasswordApply extends Component {
  state = {
    password: "",
    confirmPassword: "",
    error: "",
    passwordsMatch: true,
  }

  componentDidMount() {
    ValidatorForm.addValidationRule("withoutSpaces", (value) =>
      withoutSpaces(value)
    )
  }

  processResetPasswordApply = async () => {
    const { password, confirmPassword } = this.state
    const { id } = qs.parse(this.props.location.search)

    if (password !== confirmPassword) {
      this.setState({ passwordsMatch: false })
    } else {
      try {
        await this.props.resetPasswordApply(password, id)
        this.props.history.push(routes.SIGN_IN)
      } catch ({ error }) {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    }
  }

  render() {
    const { password, confirmPassword, passwordsMatch } = this.state

    return (
      <div className="sign-up-form hero-form">
        <div className="sign-up-form__header">
          <h2 className="sign-up-form__heading">New Password</h2>
        </div>
        <ValidatorForm
          className="sign-up-form__form"
          onSubmit={this.processResetPasswordApply}
        >
          <div className="sign-up-form__field-wrapper">
            <FormControl error={!passwordsMatch} fullWidth>
              <TextValidator
                type="password"
                name="password"
                value={password}
                label="Password *"
                className="m-text-field"
                margin="none"
                fullWidth
                helperText="&nbsp;"
                validators={[
                  "withoutSpaces",
                  "required",
                  "minStringLength:8",
                  "maxStringLength:32",
                ]}
                errorMessages={[
                  "You can`t use space symbol",
                  "Field is required",
                  "Min length is 8 characters",
                  "Max length is 32 characters",
                ]}
                onChange={(password) =>
                  this.setState({
                    password: password.target.value,
                    passwordsMatch: true,
                  })
                }
              />
              {!passwordsMatch && (
                <FormHelperText className="error-message">
                  Passwords don't match
                </FormHelperText>
              )}
            </FormControl>
          </div>

          <div className="sign-up-form__field-wrapper mb20">
            <FormControl error={!passwordsMatch} fullWidth>
              <TextValidator
                type="password"
                name="confirmPassword"
                value={confirmPassword}
                label="Confirm password *"
                className="m-text-field"
                margin="none"
                fullWidth
                helperText="&nbsp;"
                validators={[
                  "withoutSpaces",
                  "required",
                  "minStringLength:8",
                  "maxStringLength:32",
                ]}
                errorMessages={[
                  "You can`t use space symbol",
                  "Field is required",
                  "Min length is 8 characters",
                  "Max length is 32 characters",
                ]}
                onChange={(confirmPassword) =>
                  this.setState({
                    confirmPassword: confirmPassword.target.value,
                    passwordsMatch: true,
                  })
                }
              />
              {!passwordsMatch && (
                <FormHelperText className="error-message">
                  Passwords don't match
                </FormHelperText>
              )}
            </FormControl>
          </div>

          <Button color="primary" variant="contained" type="submit">
            Save Password
          </Button>
        </ValidatorForm>
      </div>
    )
  }
}

const mapStateToProps = ({auth}) => ({auth});
const mapDispatchToProps = dispatch => bindActionCreators({resetPasswordApply}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordApply));
