import React from "react";
import {
  FormHelperText
} from "@material-ui/core";
import {
  extractDays,
  extractHours,
  extractMinutes
} from "../../../formComponents/timeDurationPicker";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { colors } from "../../../../library/constants/styles/colors";
import InfoIcon from "@material-ui/icons/Info";
import {
  HtmlTooltip
} from "../../../../screens/main/calendar/components/eventsCalendar/components/calendarUI";

const durationRegExp =
  /(?:\d+[d]\s\d+[h]\s\d+[m])|(?:\d+[d](?:\s\d+[h|m])?)|(?:\d+[h](?:\s\d+[m])?)|(?:\d+[m])/gm;
const DurationInput = ({
  name = "duration",
  label = "Duration",
  time,
  minTime = 0,
  maxTime = 43200,
  onChange,
  disabled,
  getHelperText,
  periodEntity,
  isWaitingForOtherFields = false,
  tooltipSubtext = "",
  showLimits = true
}) => {
  const [initialRender, setInitialRender] = React.useState(false);
  const [inputValue, setInputValue] = React.useState(
    `${extractDays(time)}d ${extractHours(time, true)}h ${extractMinutes(
      time
    )}m`
  );
  const [inputError, setInputError] = React.useState(false);

  const calcTotal = (v = inputValue) => {
    const minutes = parseInt(v.match(/\d+m/gm)?.[0]) || 0;
    const hours = parseInt(v.match(/\d+h/gm)?.[0]) || 0;
    const days = parseInt(v.match(/\d+d/gm)?.[0]) || 0;

    return minutes + hours * 60 + days * 24 * 60;
  };
  const handleChange = (e) => {
    // onValidate(e.target.value)
    setInputValue(e.target.value);
  };

  React.useEffect(() => {
    const minutes = parseInt(inputValue.match(/\d+m/gm)?.[0]) || 0;
    const hours = parseInt(inputValue.match(/\d+h/gm)?.[0]) || 0;
    const days = parseInt(inputValue.match(/\d+d/gm)?.[0]) || 0;

    const total = minutes + hours * 60 + days * 24 * 60;

    onChange(total);
  }, [inputValue]);

  React.useEffect(() => {
    ValidatorForm.addValidationRule("noDuplicates", (value) => {
      const minutes = value.match(/\d+m/gm) || [];
      const hours = value.match(/\d+h/gm) || [];
      const days = value.match(/\d+d/gm) || [];

      const hasRepetitions =
        minutes.length > 1 || hours.length > 1 || days.length > 1;

      const matched = Array.isArray(value.match(durationRegExp))
        ? value.match(durationRegExp)
        : [];
      const strongMatch = matched?.[0]?.length === value.trim().length;

      if (!value) return true;

      // if (hasRepetitions) return false

      return durationRegExp.test(value) && !hasRepetitions && strongMatch;
    });
    setInitialRender(true);
  }, []);

  React.useEffect(() => {
    ValidatorForm.addValidationRule(`minDuration${name}`, (value) => {
      if (!value) return true;

      return calcTotal(value) >= minTime;
    });
    ValidatorForm.addValidationRule(`maxDuration${name}`, (value) => {
      if (!value) return true;

      return calcTotal(value) <= maxTime;
    });
    if (periodEntity) {
      const total = calcTotal(inputValue);

      if (total > maxTime || total < minTime) {
        setInputValue(
          `${extractDays(minTime)}d ${extractHours(
            minTime,
            true
          )}h ${extractMinutes(minTime)}m`
        );
      }
    }
  }, [periodEntity, minTime, maxTime]);

  const getDurationString = (t) => {
    const minutes = extractMinutes(t);
    const hours = extractHours(t, true);
    const days = extractDays(t);

    return `${days} days, ${hours} hours, ${minutes} minutes`;
  };

  return (
    <div className="form__fieldset">
      <label htmlFor={name} className="form__label f aic">
        <HtmlTooltip
          placement={"bottom-end"}
          title={
            <div>
              <p className={"mb15"}>Duration format: 4d 8h 15m</p>
              <p>Where:</p>
              <ul className={"bullet-list pl10"}>
                <li className={"bullet-list__item bullet-list__item--compact"}>
                  d - days
                </li>
                <li className={"bullet-list__item bullet-list__item--compact"}>
                  h - hours
                </li>
                <li className={"bullet-list__item bullet-list__item--compact"}>
                  m - minutes
                </li>
              </ul>
              {!!tooltipSubtext && <p className={"mt15"}>{tooltipSubtext}</p>}
            </div>
          }
        >
          <InfoIcon
            style={{
              marginRight: 5,
              fontSize: 20,
              color: colors.primary,
              cursor: "pointer"
            }}
          />
        </HtmlTooltip>
        {label}
      </label>
      <div className="form__fieldbox">
        <div className="form__input">
          {/*{!inputError && (*/}
          {/*<FormHelperText*/}
          {/*  style={{ color: !inputError ? colors.primary : colors.danger }}*/}
          {/*>*/}
          {/*  {label}:{" "}*/}
          {/*  {!inputError*/}
          {/*    ? getDurationString(calcTotal(inputValue))*/}
          {/*    : "Invalid value"}*/}
          {/*</FormHelperText>*/}
          <FormHelperText>
            Duration format: 4d 8h 15m
          </FormHelperText>
          {/*)}*/}
          <TextValidator
            id={name}
            name={name}
            value={inputValue}
            onChange={handleChange}
            onBlur={() => setInputValue((prevState) => prevState.trim())}
            placeholder={"Type the duration value"}
            className="edit-form__input"
            margin="normal"
            fullWidth
            validators={[
              "required",
              "noDuplicates",
              `minDuration${name}`,
              `maxDuration${name}`
            ]}
            errorMessages={[
              "Field is required",
              "Incorrect duration format",
              `Min: ${getDurationString(minTime)}`,
              `Max: ${getDurationString(maxTime)}`
            ]}
            withRequiredValidator
            validatorListener={(isValid) => setInputError(!isValid)}
            disabled={disabled}
          />
          {showLimits && (
            <>
              <FormHelperText style={{ width: 330 }}>
                 Min: {getDurationString(minTime)}
              </FormHelperText>
              <FormHelperText style={{ width: 330 }}>
                Max: {getDurationString(maxTime)}
              </FormHelperText>
            </>
          )}
        </div>
        {typeof getHelperText === "function" && (
          <FormHelperText style={{ width: 330 }}>
            {getHelperText({ extractDays, extractHours, extractMinutes })}
          </FormHelperText>
        )}
      </div>
    </div>
  );
};

export default DurationInput;
