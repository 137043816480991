import React, { Component } from "react"
import "./searchResultsList.scss"
import * as qs from "query-string"
import { bindActionCreators } from "redux"
import {
  clearSearchList,
  globalSearch,
  groupsSearch,
  productsSearch,
  usersSearch,
} from "../../../../library/store/actions/creators/searchCreators"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import List from "../../../../components/ui/list"
import {
  acceptFriendRequest,
  cancelFriendRequest,
  declineFriendRequest,
  deleteFriend,
  sendFriendRequest,
} from "../../../../library/store/actions/creators/friendsCreators"
import { withSnackbar } from "notistack"
import ListSearchHeader from "../../../../components/ui/listSearchHeader/listSearchHeader"
import Loader from "../../../../components/ui/loader/loader"
import * as routes from "../../../../library/constants/routes"
import {
  deleteGroup,
  groupInviteDecline,
  groupRequestsCancel,
  groupRequestsDecline,
  joinGroup,
  leaveGroup,
} from "../../../../library/store/actions/creators/groupsCreators"
import SearchResultUser from "../searchResultItems/searchResultUser"
import SearchResultGroup from "../searchResultItems/searchResultGroup"
import ProductsListItem from "../../marketplace/productsList/productsListItem/productsListItem"
import KeyboardArrowRight from "@material-ui/core/es/internal/svg-icons/KeyboardArrowRight"
import SearchResultsGlobal from "./searchResultsGlobal/searchResultsGlobal"
import withScroll from "../../../../hocs/withScroll/withScroll"
import { LIMIT } from "../../../../library/constants/limits"
import getErrorText from "../../../../library/constants/errorTypes"
import composeQuery from "../../../../library/utils/composeQuery"

class SearchResultsList extends Component {
  state = {
    globalList: {
      users: [],
      groups: [],
      products: [],
    },
    usersList: [],
    groupsList: [],
    productsList: [],
    dataReceived: false,
    initialRender: true,
  }

  async componentDidMount() {
    await this.processSearch()
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      match: { path },
      location: { search },
    } = this.props

    if (path !== prevProps.match.path) {
      await this.props.clearSearchList()
      await this.setState({
        initialRender: true,
        usersList: [],
        groupsList: [],
        productsList: [],
      })
      await this.processSearch()
    } else if (search !== prevProps.location.search) {
      await this.props.clearSearchList()
      await this.setState({
        usersList: [],
        groupsList: [],
        productsList: [],
      })
      await this.processSearch()
    }
  }

  componentWillUnmount() {
    this.props.clearSearchList()
  }

  composeSearchQuery = (name) => {
    const {
      history,
      location: { search },
    } = this.props
    const filtersParsed = qs.parse(search, { parseBooleans: true })

    const fields = {
      ...filtersParsed,
      name: name || undefined,
    }

    composeQuery({ fields, history, path: this.props.match.path })
  }

  processSearch = async () => {
    const {
      match: { path },
      location: { search },
    } = this.props
    const { name } = qs.parse(search)
    this.setState({ dataReceived: false })

    try {
      switch (path) {
        case routes.SEARCH:
          await this.props.globalSearch({ name: name || undefined })
          this.setState({
            globalList: this.props.search.global,
            initialRender: false,
            dataReceived: true,
          })
          break

        case routes.SEARCH_USERS:
          await this.props.usersSearch({
            name: name || undefined,
            offset: this.state.usersList.length,
            limit: LIMIT,
          })
          this.setState({
            usersList: this.props.search.users.users,
            initialRender: false,
            dataReceived: true,
          })
          break

        case routes.SEARCH_GROUPS:
        case routes.GROUPS_SEARCH:
          await this.props.groupsSearch({
            name: name || undefined,
            offset: this.state.groupsList.length,
            limit: LIMIT,
          })
          this.setState({
            groupsList: this.props.search.groups.groups,
            initialRender: false,
            dataReceived: true,
          })
          break

        case routes.SEARCH_GROUPS_INTERESTS:
        case routes.GROUPS_SEARCH_INTERESTS:
          await this.props.groupsSearch({
            name: name || undefined,
            type: 1,
            offset: this.state.groupsList.length,
            limit: LIMIT,
          })
          this.setState({
            groupsList: this.props.search.groups.groups,
            initialRender: false,
            dataReceived: true,
          })
          break

        case routes.SEARCH_GROUPS_DISTRICTS:
        case routes.GROUPS_SEARCH_DISTRICTS:
          await this.props.groupsSearch({
            name: name || undefined,
            type: 5,
            offset: this.state.groupsList.length,
            limit: LIMIT,
          })
          this.setState({
            groupsList: this.props.search.groups.groups,
            initialRender: false,
            dataReceived: true,
          })
          break

        case routes.SEARCH_GROUPS_SCHOOLS:
        case routes.GROUPS_SEARCH_SCHOOLS:
          await this.props.groupsSearch({
            name: name || undefined,
            type: 4,
            offset: this.state.groupsList.length,
            limit: LIMIT,
          })
          this.setState({
            groupsList: this.props.search.groups.groups,
            initialRender: false,
            dataReceived: true,
          })
          break

        case routes.SEARCH_GROUPS_ONE_TEACHER_SCHOOLS:
        case routes.GROUPS_SEARCH_ONE_TEACHER_SCHOOLS:
          await this.props.groupsSearch({
            name: name || undefined,
            type: 7,
            offset: this.state.groupsList.length,
            limit: LIMIT,
          })
          this.setState({
            groupsList: this.props.search.groups.groups,
            initialRender: false,
            dataReceived: true,
          })
          break

        case routes.SEARCH_GROUPS_COMPANIES:
        case routes.GROUPS_SEARCH_COMPANIES:
          await this.props.groupsSearch({
            name: name || undefined,
            type: 3,
            offset: this.state.groupsList.length,
            limit: LIMIT,
          })
          this.setState({
            groupsList: this.props.search.groups.groups,
            initialRender: false,
            dataReceived: true,
          })
          break

        case routes.SEARCH_GROUPS_EVENTS:
        case routes.GROUPS_SEARCH_EVENTS:
          await this.props.groupsSearch({
            name: name || undefined,
            type: 2,
            offset: this.state.groupsList.length,
            limit: LIMIT,
          })
          this.setState({
            groupsList: this.props.search.groups.groups,
            initialRender: false,
            dataReceived: true,
          })
          break

        case routes.SEARCH_PRODUCTS:
          await this.props.productsSearch({
            name: name || undefined,
            offset: this.state.productsList.length,
            limit: LIMIT,
          })
          this.setState({
            productsList: this.props.search.products.products,
            initialRender: false,
            dataReceived: true,
          })
          break

        default:
          await this.props.globalSearch({ name: name || undefined })
          this.setState({
            list: this.props.search.global,
            initialRender: false,
            dataReceived: true,
          })
          break
      }
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  composeSearchTitle = () => {
    const {
      match: { path },
    } = this.props

    switch (path) {
      case routes.SEARCH:
        return "All"

      case routes.SEARCH_USERS:
        return "People"

      case routes.GROUPS_SEARCH:
        return "Global group search"

      case routes.SEARCH_GROUPS:
        return "Groups"

      case routes.GROUPS_SEARCH_INTERESTS:
        return (
          <div className="breadcrumbs">
            <span className="breadcrumbs__item">Global group search</span>
            <KeyboardArrowRight className="breadcrumbs__divider" />
            <span className="breadcrumbs__item breadcrumbs__item--active">
              Interest groups
            </span>
          </div>
        )

      case routes.SEARCH_GROUPS_INTERESTS:
        return (
          <div className="breadcrumbs">
            <span className="breadcrumbs__item">Groups</span>
            <KeyboardArrowRight className="breadcrumbs__divider" />
            <span className="breadcrumbs__item breadcrumbs__item--active">
              Interest groups
            </span>
          </div>
        )

      case routes.GROUPS_SEARCH_DISTRICTS:
        return (
          <div className="breadcrumbs">
            <span className="breadcrumbs__item">Global group search</span>
            <KeyboardArrowRight className="breadcrumbs__divider" />
            <span className="breadcrumbs__item breadcrumbs__item--active">
              Districts
            </span>
          </div>
        )

      case routes.SEARCH_GROUPS_DISTRICTS:
        return (
          <div className="breadcrumbs">
            <span className="breadcrumbs__item">Groups</span>
            <KeyboardArrowRight className="breadcrumbs__divider" />
            <span className="breadcrumbs__item breadcrumbs__item--active">
              Districts
            </span>
          </div>
        )

      case routes.GROUPS_SEARCH_SCHOOLS:
        return (
          <div className="breadcrumbs">
            <span className="breadcrumbs__item">Global group search</span>
            <KeyboardArrowRight className="breadcrumbs__divider" />
            <span className="breadcrumbs__item breadcrumbs__item--active">
              Schools
            </span>
          </div>
        )

      case routes.SEARCH_GROUPS_SCHOOLS:
        return (
          <div className="breadcrumbs">
            <span className="breadcrumbs__item">Groups</span>
            <KeyboardArrowRight className="breadcrumbs__divider" />
            <span className="breadcrumbs__item breadcrumbs__item--active">
              Schools
            </span>
          </div>
        )

      case routes.GROUPS_SEARCH_COMPANIES:
        return (
          <div className="breadcrumbs">
            <span className="breadcrumbs__item">Global group search</span>
            <KeyboardArrowRight className="breadcrumbs__divider" />
            <span className="breadcrumbs__item breadcrumbs__item--active">
              Companies
            </span>
          </div>
        )

      case routes.SEARCH_GROUPS_COMPANIES:
        return (
          <div className="breadcrumbs">
            <span className="breadcrumbs__item">Groups</span>
            <KeyboardArrowRight className="breadcrumbs__divider" />
            <span className="breadcrumbs__item breadcrumbs__item--active">
              Companies
            </span>
          </div>
        )

      case routes.GROUPS_SEARCH_EVENTS:
        return (
          <div className="breadcrumbs">
            <span className="breadcrumbs__item">Global group search</span>
            <KeyboardArrowRight className="breadcrumbs__divider" />
            <span className="breadcrumbs__item breadcrumbs__item--active">
              Events
            </span>
          </div>
        )

      case routes.SEARCH_GROUPS_EVENTS:
        return (
          <div className="breadcrumbs">
            <span className="breadcrumbs__item">Groups</span>
            <KeyboardArrowRight className="breadcrumbs__divider" />
            <span className="breadcrumbs__item breadcrumbs__item--active">
              Events
            </span>
          </div>
        )

      case routes.SEARCH_PRODUCTS:
        return "Products"

      default:
        return "Search"
    }
  }

  composeCounter = () => {
    const {
      match: { path },
      search,
    } = this.props

    switch (path) {
      case routes.SEARCH:
        return (
          search.global.users_number +
          search.global.groups_number +
          search.global.products_number
        )

      case routes.SEARCH_USERS:
        return search.users.users_number

      case routes.SEARCH_GROUPS:
      case routes.GROUPS_SEARCH:
      case routes.SEARCH_GROUPS_INTERESTS:
      case routes.GROUPS_SEARCH_INTERESTS:
      case routes.SEARCH_GROUPS_DISTRICTS:
      case routes.GROUPS_SEARCH_DISTRICTS:
      case routes.SEARCH_GROUPS_SCHOOLS:
      case routes.GROUPS_SEARCH_SCHOOLS:
      case routes.SEARCH_GROUPS_COMPANIES:
      case routes.GROUPS_SEARCH_COMPANIES:
      case routes.SEARCH_GROUPS_EVENTS:
      case routes.GROUPS_SEARCH_EVENTS:
        return search.groups.groups_number

      case routes.SEARCH_PRODUCTS:
        return search.products.products_number

      default:
        return null
    }
  }

  showResultNotFound = () => {
    const {
      match: { path },
    } = this.props
    const { globalList, groupsList, productsList, usersList, initialRender } =
      this.state

    switch (path) {
      case routes.SEARCH:
        return (
          globalList.users.length === 0 &&
          globalList.groups.length === 0 &&
          globalList.products.length === 0 &&
          !initialRender
        )

      case routes.SEARCH_USERS:
        return usersList.length === 0 && !initialRender

      case routes.SEARCH_GROUPS:
      case routes.GROUPS_SEARCH:
      case routes.SEARCH_GROUPS_INTERESTS:
      case routes.GROUPS_SEARCH_INTERESTS:
      case routes.SEARCH_GROUPS_DISTRICTS:
      case routes.GROUPS_SEARCH_DISTRICTS:
      case routes.SEARCH_GROUPS_SCHOOLS:
      case routes.GROUPS_SEARCH_SCHOOLS:
      case routes.SEARCH_GROUPS_COMPANIES:
      case routes.GROUPS_SEARCH_COMPANIES:
      case routes.SEARCH_GROUPS_EVENTS:
      case routes.GROUPS_SEARCH_EVENTS:
        return groupsList.length === 0 && !initialRender

      case routes.SEARCH_PRODUCTS:
        return productsList.length === 0 && !initialRender

      default:
        return false
    }
  }

  composeList = () => {
    const {
      match: { path },
    } = this.props
    const { globalList, groupsList, productsList, usersList } = this.state

    switch (path) {
      case routes.SEARCH:
        return <SearchResultsGlobal results={globalList} />

      case routes.SEARCH_USERS:
        return (
          <List>
            {usersList.map((item) => (
              <SearchResultUser
                auth={this.props.auth}
                user={item}
                type="search"
                counter={this.composeCounter()}
                key={item.id}
              />
            ))}
          </List>
        )

      case routes.SEARCH_GROUPS:
      case routes.GROUPS_SEARCH:
      case routes.SEARCH_GROUPS_INTERESTS:
      case routes.GROUPS_SEARCH_INTERESTS:
      case routes.SEARCH_GROUPS_DISTRICTS:
      case routes.GROUPS_SEARCH_DISTRICTS:
      case routes.SEARCH_GROUPS_SCHOOLS:
      case routes.GROUPS_SEARCH_SCHOOLS:
      case routes.SEARCH_GROUPS_COMPANIES:
      case routes.GROUPS_SEARCH_COMPANIES:
      case routes.SEARCH_GROUPS_EVENTS:
      case routes.GROUPS_SEARCH_EVENTS:
      case routes.SEARCH_GROUPS_ONE_TEACHER_SCHOOLS:
      case routes.GROUPS_SEARCH_ONE_TEACHER_SCHOOLS:
        return (
          <List>
            {groupsList.map((item) => (
              <SearchResultGroup key={item.id} group={item} />
            ))}
          </List>
        )

      case routes.SEARCH_PRODUCTS:
        return (
          <div className="products-list">
            {productsList.map((item) => (
              <ProductsListItem
                product={item}
                user={this.props.auth.userData}
                manageable={true}
                key={item.id}
              />
            ))}
          </div>
        )

      default:
        return <SearchResultsGlobal results={globalList} />
    }
  }
  onScroll = () => {
    this.state.dataReceived && this.processSearch()
  }

  render() {
    const { dataReceived } = this.state

    const isGroupsRoute = [
      routes.GROUPS_SEARCH,
      routes.GROUPS_SEARCH_EVENTS,
      routes.GROUPS_SEARCH_SCHOOLS,
      routes.GROUPS_SEARCH_COMPANIES,
      routes.GROUPS_SEARCH_DISTRICTS,
      routes.GROUPS_SEARCH_INTERESTS,
    ].includes(this.props.match.path)

    return (
      <>
        <ListSearchHeader
          title={this.composeSearchTitle()}
          placeholder={
            !isGroupsRoute
              ? "Type any name, title, word"
              : "Start typing group name"
          }
          search={this.composeSearchQuery}
          noResults={this.showResultNotFound()}
          counter={dataReceived ? this.composeCounter() : ""}
        />
        {this.composeList()}
        {!dataReceived && <Loader />}
      </>
    )
  }
}

const mapStateToProps = ({ auth, search }) => ({ auth, search })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      globalSearch,
      usersSearch,
      groupsSearch,
      productsSearch,
      clearSearchList,

      sendFriendRequest,
      cancelFriendRequest,
      acceptFriendRequest,
      declineFriendRequest,
      deleteFriend,

      joinGroup,
      leaveGroup,
      deleteGroup,
      groupRequestsDecline,
      groupInviteDecline,
      groupRequestsCancel,
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withSnackbar(withScroll(SearchResultsList)))
)
