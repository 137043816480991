import React, { Component } from "react"
import "./conversation.scss"
import * as routes from "../../../../library/constants/routes"
import { generatePath, Link, withRouter } from "react-router-dom"
import { KeyboardArrowLeft } from "@material-ui/icons"
import * as qs from "query-string"
import UserAvatar from "../../../../components/userAvatar/userAvatar"
import TextField from "@material-ui/core/TextField"
import SendOutlined from "@material-ui/icons/SendOutlined"
import InputAdornment from "@material-ui/core/InputAdornment"
import AttachFileOutlined from "@material-ui/icons/AttachFileOutlined"
import PhotoOutlined from "@material-ui/icons/PhotoOutlined"
import avatarDefault from "../../../../assets/img/user_avatar.svg"
import { bindActionCreators } from "redux"
import { getPerson } from "../../../../library/store/actions/creators/personCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import Loader from "../../../../components/ui/loader/loader"
import ConversationMessage from "./conversationMessage/conversationMessage"
import "react-perfect-scrollbar/dist/css/styles.css"
import {
  clearChatList,
  clearMessages,
  createMessage,
  getMessageList,
  readMessage,
  updateMessage,
} from "../../../../library/store/actions/creators/messagesCreators"
import { decrementChatsCount } from "../../../../library/store/actions/creators/notificationCreators"
import { wsUrl } from "../../../../library/networking/API"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl/FormControl"
import dateConvert from "../../../../library/utils/dateConvert"
import withScroll from "../../../../hocs/withScroll/withScroll"
import { LIMIT } from "../../../../library/constants/limits"
import FileLoader from "../../../../components/formComponents/fileLoader"
import unavailableAvatar from "../../../../assets/img/user_unavailable.svg"
import getErrorText from "../../../../library/constants/errorTypes"
import SEO from "../../../../components/SEO"

class Conversation extends Component {
  state = {
    chat_id: null,
    messageText: "",
    blobs: [],
    images: [],
    videos: [],
    attachments: [],
    newPostActive: false,
    dataReceived: false,
    clearFiles: false,
    messageReceived: false,
    disable: false,
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }

  onUploadProgress = (progressEvent) => {
    let percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    )
    this.setState({ uploadProgress: percentCompleted })
  }

  async componentDidMount() {
    const {
      auth: { userData },
    } = this.props
    const { userId } = qs.parse(this.props.location.search)
    try {
      await this.props.getPerson(userData.token, parseInt(userId))
      await this.props.setUser()
      await this.props
        .getMessageList({
          user_id: parseInt(userId),
        })
        .then((chat_id) => this.setState({ chat_id, messageReceived: true }))
      this.setActiveChat()
      this.connectSocket()
      await this.setStateAsync({ dataReceived: true })
      await this.setScroll()
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
    window.addEventListener("beforeunload", this.removeActiveChat)
    this.props.setScrollContainer(this.scrollComponent)
  }

  componentWillUnmount() {
    this.removeActiveChat()
    this.props.clearUser()
    this.props.clearMessages()
    this.props.clearChatList()
    this.socket.close()
    this.socket = null
    window.removeEventListener("beforeunload", this.removeActiveChat)
  }

  setActiveChat = () => {
    const { chat_id } = this.state
    const storage = localStorage.getItem("activeChats")
    if (storage) {
      const activeChats = JSON.parse(storage)
      activeChats.push(chat_id)
      localStorage.setItem("activeChats", JSON.stringify(activeChats))
    } else {
      localStorage.setItem("activeChats", JSON.stringify([chat_id]))
    }
  }

  removeActiveChat = () => {
    const { chat_id } = this.state
    const storage = localStorage.getItem("activeChats")
    if (storage) {
      const activeChats = JSON.parse(storage)
      const index = activeChats.findIndex((chat) => chat === chat_id)
      activeChats.splice(index, 1)
      localStorage.removeItem("activeChats")
      localStorage.setItem("activeChats", JSON.stringify(activeChats))
    }
  }

  onScroll = async () => {
    if (!this.state.messageReceived) return
    await this.setStateAsync({ messageReceived: false })
    const { userId } = qs.parse(this.props.location.search)
    try {
      await this.props.getMessageList({
        chat_id: this.state.chat_id,
        user_id: parseInt(userId),
        offset: this.props.message.currentChat.messages.length,
        limit: LIMIT,
      })
      await this.setStateAsync({ messageReceived: true })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  connectSocket = () => {
    this.socket = new WebSocket(`${wsUrl}messages.subscribe`)
    // this.socket.onclose = () => {
    //    const url = window.location.href;
    //    if(url.includes('chat/?userId=')){
    //        this.connectSocket();
    //    }
    // };
    this.socket.onopen = () => {
      this.heartbeat()
    }
    this.socket.onmessage = async (event) => {
      // const {auth: {userData}} = this.props;
      await this.props.readMessage({ chat_id: this.state.chat_id })
      const data = JSON.parse(event.data)
      // if(data.user.id !== userData.id){
      //     this.props.decrementChatsCount();
      // }
      this.props.updateMessage({ payload: data })
      this.scrollToBottom(document.getElementById("scroll-container"))
    }
  }

  heartbeat = () => {
    if (!this.socket) return
    if (this.socket.readyState !== 1) return
    this.socket.send(
      JSON.stringify({
        token: this.props.auth.userData.token,
        chat_id: this.state.chat_id,
      })
    )
    setTimeout(this.heartbeat, 15000)
  }

  handleChange = (event) => {
    this.setState({ messageText: event.target.value })
  }

  setScroll = async () => {
    let scrollContainer = document.getElementById("scroll-container")
    scrollContainer.scrollTo(0, scrollContainer.scrollHeight)
  }

  scrollToBottom = (element) => {
    return (element.scrollTop = element.scrollHeight)
  }
  setAttachments = ({ videos, blobs, images }) => {
    this.setStateAsync({
      videos,
      blobs,
      images,
    })
  }

  handleKeyPressed = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault()
      this.sendMessage()
    }
  }

  sendMessage = async () => {
    const { chat_id, messageText, blobs, images, videos } = this.state
    if (messageText.length > 5000) return
    await this.clearInput()
    const trimmedMessage = messageText.trim()

    if (
      !!trimmedMessage ||
      blobs.length > 0 ||
      images.length > 0 ||
      videos.length > 0
    ) {
      try {
        await this.props.createMessage({
          chat_id,
          message: !!messageText ? messageText : undefined,
          images: images.map((f) => f.id),
          blobs: blobs.map((f) => f.id),
          videos: videos.map((f) => f.id),
        })
        await this.setStateAsync({ clearFiles: true })
        await this.setScroll()
        await this.setStateAsync({ clearFiles: false })
      } catch ({ error }) {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    }
  }

  clearInput = async () => {
    this.setState({
      messageText: "",
      images: [],
      blobs: [],
      videos: [],
    })
  }

  render() {
    const { messageText, images, videos, blobs, dataReceived } = this.state
    const {
      person: { personData },
      auth: { userData },
    } = this.props

    if (dataReceived) {
      const { messages } = this.props.message.currentChat
      const avatar =
        personData.deleted || personData.blocked
          ? unavailableAvatar
          : personData.photo
            ? personData.photo
            : avatarDefault
      const fullName = `${personData.first_name} ${personData.last_name}`
      const renderName =
        fullName.length > 50 ? fullName.slice(0, 50) + "..." : fullName
      return (
        <div className="conversation">
          <SEO title={"Chat with " + fullName} />
          <div className="conversation__header">
            <Link to={routes.CONVERSATIONS}>
              <KeyboardArrowLeft className="color-black-54" />
            </Link>
            <Link
              to={generatePath(routes.USER, { userId: personData.id })}
              className="conversation__user-name"
            >
              {renderName}
            </Link>
            <div className="conversation__dropdown">
              <Link to={generatePath(routes.USER, { userId: personData.id })}>
                <img
                  src={avatar}
                  alt="user name"
                  className="conversation__user-avatar"
                />
              </Link>
              {/*<MoreDropdown>*/}
              {/*    <VerticalMenu menuItems={dropdownMenuItems} classes="more-dropdown-menu__list"/>*/}
              {/*</MoreDropdown>*/}
            </div>
          </div>
          <div
            className="conversation__wrapper"
            id="scroll-container"
            ref={(ref) => (this.scrollComponent = ref)}
          >
            <div className="conversation__body">
              {!this.state.messageReceived && <Loader />}
              {messages.length > 0 ? (
                messages.map((message, index) => (
                  <div className="message-container" key={message.id}>
                    {index > 0 ? (
                      dateConvert(
                        messages[index - 1]["created"],
                        message["created"]
                      ) ? (
                        <div className="message-container__block-date">
                          <span>
                            {dateConvert(
                              messages[index - 1]["created"],
                              message["created"]
                            )}
                          </span>
                        </div>
                      ) : null
                    ) : dateConvert(undefined, message["created"]) ? (
                      <div className="message-container__block-date">
                        <span>
                          {dateConvert(undefined, message["created"])}
                        </span>
                      </div>
                    ) : null}
                    <ConversationMessage
                      message={message}
                      userData={this.props.auth.userData}
                    />
                  </div>
                ))
              ) : (
                <div className="full-width f aic jcc p5">
                  <span className="color-black-38">Start messaging</span>
                </div>
              )}
            </div>
          </div>
          <div className="conversation__footer message-create">
            {!personData.deleted &&
            !personData.blocked &&
            !personData.is_blocking &&
            !personData.is_blocked ? (
              <>
                <div className="message-create__attachments">
                  <FileLoader
                    videos={videos}
                    images={images}
                    blobs={blobs}
                    onUpload={this.setAttachments}
                    token={userData.token}
                    clearState={this.state.clearFiles}
                  />
                </div>

                <div className="message-create__message-field">
                  <UserAvatar className="message-create__owner-avatar" />
                  <FormControl
                    error={this.state.messageText.length > 5000}
                    fullWidth
                  >
                    <TextField
                      placeholder="Write a message…"
                      multiline
                      autoFocus={true}
                      value={messageText}
                      onChange={this.handleChange}
                      onKeyDown={this.handleKeyPressed}
                      fullWidth
                      error={this.state.messageText.length > 5000}
                      margin="none"
                      variant="outlined"
                      InputProps={{
                        style: { alignItems: "flex-start" },
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ marginTop: "8px" }}
                          >
                            <label
                              htmlFor="img-input"
                              style={{ cursor: "pointer" }}
                            >
                              <PhotoOutlined className="color-black-38" />
                            </label>
                            <label
                              htmlFor="attachments-input"
                              style={{ cursor: "pointer" }}
                            >
                              <AttachFileOutlined className="color-black-38" />
                            </label>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {this.state.messageText.length > 5000 && (
                      <FormHelperText>Max length 5000 symbols</FormHelperText>
                    )}
                  </FormControl>
                  <SendOutlined
                    className="message-create__send-btn"
                    onClick={this.sendMessage}
                  />
                </div>
              </>
            ) : (
              <div className="f jcc">
                {!personData.is_blocking &&
                  !personData.is_blocked &&
                  personData.deleted && <span>Page deleted.</span>}
                {!personData.is_blocking &&
                  !personData.is_blocked &&
                  personData.blocked && <span>Page blocked.</span>}
                {personData.is_blocking && (
                  <span>You are not allowed to send messages to this user</span>
                )}
                {personData.is_blocked && (
                  <span>
                    You can't send messages to users that are in your blacklist
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      )
    } else {
      return <Loader />
    }
  }
}

const mapStateToProps = ({ auth, person, message }) => ({
  auth,
  person,
  message,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPerson,
      getMessageList,
      createMessage,
      readMessage,
      updateMessage,
      clearMessages,
      decrementChatsCount,
      clearChatList,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(withScroll(Conversation, true))))
