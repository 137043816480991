import React from "react"
import { generatePath, Link } from "react-router-dom"
import logoDefault from "../../../../../assets/img/product.svg"
import * as routes from "../../../../../library/constants/routes"
import LinesEllipsis from "react-lines-ellipsis"
import Button from "@material-ui/core/Button"

const KnowMixLineCard = (props) => {
  const {
    knowmix,
    step,
    hasButton,
    buttonHandler,
    linkUrl,
    knowMixLink,
    buttonText,
    type,
    subType,
    buttonType,
    emptyStyleButton,
  } = props
  let knowMixUrl
  if (knowmix) {
    knowMixUrl =
      knowmix && generatePath(routes.KNOWMIX, { knowmixId: knowmix.id })
  } else {
    knowMixUrl = "/"
  }

  // const creatorName = knowmix.creator_user
  //   ? `${knowmix.creator_user?.first_name} ${knowmix.creator_user?.last_name}`
  //   : knowmix.creator_group?.name
  //
  // const creatorUrl = knowmix.creator_user
  //   ? generatePath(routes.USER, { userId: knowmix.creator_user?.id })
  //   : generatePath(routes.GROUP, { groupId: knowmix.creator_group?.id })

  const knowmixLogo = (knowmix && knowmix.photo) || logoDefault
  const creator = type === "step" ? step.creator : knowmix.creator
  const learner = type === "step" ? step.learner : knowmix.learner
  const examiner = type === "step" ? step.examiner : knowmix.examiner
  const grade = type === "step" ? step.grade : knowmix.grade
  // const creatorName = `${creator.first_name} ${creator.last_name}`;
  const learnerName = learner
    ? `${learner.first_name} ${learner.last_name}`.length > 30
      ? `${learner.first_name} ${learner.last_name}`.slice(0, 30)
      : `${learner.first_name} ${learner.last_name}`
    : ""
  const examinerName = examiner
    ? `${examiner.first_name} ${examiner.last_name}`.length > 30
      ? `${examiner.first_name} ${examiner.last_name}`.slice(0, 30)
      : `${examiner.first_name} ${examiner.last_name}`
    : ""
  const displayedName =
    type === "work"
      ? knowmix
        ? knowmix.name
        : ""
      : step && step.step
      ? step.step.name
      : ""
  return (
    <div className="box line-card list-item">
      {knowmix && (
        <div className="f aic full-width">
          <Link
            to={knowMixLink ? knowMixLink : knowMixUrl}
            className="list-item__img"
          >
            <img src={knowmixLogo} alt={displayedName} />
          </Link>
          <div className="list-item__info">
            <span className="list-item__info-block name">
              <Link
                to={knowMixLink ? knowMixLink : knowMixUrl}
                className="link list-item__name"
              >
                {displayedName && displayedName.length > 40
                  ? `${displayedName.slice(0, 40)}...`
                  : displayedName}
              </Link>
            </span>
            <table>
              <tbody>
                {type === "step" && (
                  <tr>
                    <td className="knowmix-card--inline__info-label pb4">
                      Course:
                    </td>
                    <td className="knowmix-card--inline__info-value pb4">
                      <span>
                        {knowmix.name.length > 25
                          ? `${knowmix.name.slice(0, 25)}...`
                          : knowmix.name}
                      </span>
                    </td>
                  </tr>
                )}
                {/*<tr>*/}
                {/*  <td className="knowmix-card--inline__info-label pb4">*/}
                {/*    Learner:*/}
                {/*  </td>*/}
                {/*  <td className="knowmix-card--inline__info-value pb4">*/}
                {/*    <Link className={"link link--inline"}*/}
                {/*          to={generatePath(routes.USER, { userId: learner ? learner.id : creator.id })}>{learner ? learnerName : creatorName}</Link>*/}
                {/*  </td>*/}
                {/*</tr>*/}
                {grade && (
                  <tr>
                    <td className="knowmix-card--inline__info-label pb4">
                      Grade:
                    </td>
                    <td className="knowmix-card--inline__info-value pb4">
                      <LinesEllipsis
                        text={`${grade}`}
                        maxLine="1"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                      />
                    </td>
                  </tr>
                )}
                {/*{subType === "examined" && <tr>*/}
                {/*  <td className="knowmix-card--inline__info-label pb4">*/}
                {/*    Examiner:*/}
                {/*  </td>*/}
                {/*  <td className="knowmix-card--inline__info-value pb4">*/}
                {/*    <Link className={"link link--inline"}*/}
                {/*          to={generatePath(routes.USER, { userId: examiner ? examiner.id : creator.id })}>{examiner ? examinerName : creatorName}</Link>*/}
                {/*  </td>*/}
                {/*</tr>}*/}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <div className={`list-item__control-panel`}>
        {((_) => {
          if (hasButton) {
            if (buttonType === "link") {
              return (
                <Button
                  variant={emptyStyleButton ? "outlined" : "contained"}
                  color="primary"
                  component={Link}
                  to={linkUrl}
                >
                  {buttonText || "Examine work"}
                </Button>
              )
            } else {
              return (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={buttonHandler}
                >
                  {buttonText}
                </Button>
              )
            }
          }
        })()}
      </div>
    </div>
  )
}

export default KnowMixLineCard
