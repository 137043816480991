import React from "react"
import { useGetSpaceQuery } from "../../../../../../../../library/store/api/calendar/spacesAPI"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import Loader from "../../../../../../../../components/ui/loader"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import Dialog from "@material-ui/core/Dialog/Dialog"

const SpaceInfoDialog = ({ open, onClose, activeSpace }) => {
  const { data, isLoading } = useGetSpaceQuery(activeSpace)

  return (
    <Dialog aria-labelledby="space-dialog" onClose={onClose} open={open}>
      <DialogTitle id="alert-dialog-title" className="text-center">
        Space details
      </DialogTitle>
      <DialogContent>
        {!isLoading ? (
          <div className="f jcc full-width">
            <table className="product-details product-details--invenstory-record">
              <tbody>
                <tr className="product-details__row">
                  <td className="product-details__label">Name</td>
                  <td className="product-details__value">{data.name}</td>
                </tr>

                {!!data.description.length && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Description</td>
                    <td className="product-details__value">
                      {data.description}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <Loader />
        )}
      </DialogContent>
      <DialogActions>
        <Button type="submit" color="primary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SpaceInfoDialog
