import IconButton from "@material-ui/core/IconButton"
import { FilterListOutlined } from "@material-ui/icons"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import React, { useEffect, useState } from "react"
import { useHistory, useLocation, useRouteMatch } from "react-router-dom"
import * as qs from "query-string"
import composeQuery from "../../../../../../library/utils/composeQuery"
import * as _ from "lodash"

const CalendarFilters = ({ groups }) => {
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch()

  const [filterAnchorEl, setFilterAnchorEl] = useState(null)
  const handleOpenFilters = (event) => {
    setFilterAnchorEl(event.currentTarget)
  }
  const handleCloseFilters = () => {
    setFilterAnchorEl(null)
  }

  const [selected, setSelected] = useState([])

  const onSelect = (id) => {
    const newData = selected.includes(id)
      ? selected.filter((v) => v !== id)
      : [...selected, id]
    setSelected(newData)

    composeQuery({
      fields: {
        groups: newData,
      },
      history,
      match: match.path,
    })
  }

  useEffect(() => {
    const searchParsed = qs.parse(location.search, { arrayFormat: "comma" })
    if (searchParsed?.groups) {
      setSelected(_.map(_.split(searchParsed.groups, ","), _.parseInt))
    }
  }, [])

  return (
    <>
      <IconButton
        aria-label="Filters"
        size="small"
        onClick={handleOpenFilters}
        className={"mr15"}
      >
        <FilterListOutlined />
      </IconButton>
      <Menu
        id="calendar-views-menu"
        anchorEl={filterAnchorEl}
        keepMounted
        open={Boolean(filterAnchorEl)}
        onClose={handleCloseFilters}
        PaperProps={{
          style: {
            maxHeight: 250,
          },
        }}
      >
        {groups.length ? (
          groups.map((g) => (
            <MenuItem
              key={g.id}
              selected={selected.includes(g.id)}
              onClick={() => onSelect(g.id)}
            >
              {g.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>
            You don't have any groups with Calendar yet
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

export default CalendarFilters
