import React from "react"

const PostVideos = ({ videos }) => {
  return (
    <div className={`post__videos${videos.length === 1 ? "--single" : ""}`}>
      {videos.map(({ id, name, url }) => {
        return (
          <div key={id}>
            <div className="post__video">
              <video className="post__video-player" controls>
                <source src={url} />
              </video>
              <span className="post__video-name">{name}</span>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default PostVideos
