import React from "react"
import { Helmet } from "react-helmet"

const SEO = ({
  title,
  ogTitle,
  description,
  ogDescription,
  ogImage,
  ogUrl,
}) => {
  return (
    <Helmet>
      {!!title && <title>{title + " | KnowStory"}</title>}
      {!!description && <meta name={"description"} content={description} />}
      {!!(ogTitle || title) && (
        <meta property="og:title" content={ogTitle || title} />
      )}
      {!!ogImage && <meta property="og:image" content={ogImage} />}
      {!!(ogDescription || description) && (
        <meta
          property="og:description"
          content={ogDescription || description}
        />
      )}
      {!!ogUrl && <meta property="og:url" content={ogUrl} />}
    </Helmet>
  )
}

export default SEO
