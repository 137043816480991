import { useAlert } from "./useAlert"
import { useDispatch } from "react-redux"

const useCreator = () => {
  const { errorAlert } = useAlert()
  const dispatch = useDispatch()

  return async (creator) => {
    try {
      return await dispatch(creator)
    } catch (e) {
      errorAlert(e)
    }
  }
}

export default useCreator
