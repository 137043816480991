export const standardsFilters = () => {
  return subjectsPerState.map((item, index) => {
    return {
      id: index + 1,
      name: item.title,
      subjects: item.subjects.map((s, i) => ({ id: i + 1, name: s })),
    }
  })
}

export const subjectsPerState = [
  {
    title: "Alabama",
    subjects: [
      "Science (2005)",
      "Arts Education Dance",
      "Arts Education Theater",
      "Physical Education",
      "Health Education",
      "Technology Education",
      "English Language Arts",
      "Mathematics",
      "Arts Education Music",
      "Arts Education Visual Arts",
      "Social Studies",
      "Science (2015)",
      "Languages Other Than English—Foreign Languages (2006)",
      "Algebra with Finance",
      "Career Mathematics (2015)",
      "Career and Technical Education:  Hospitality and Tourism",
      "Career Tech",
      "Algebra with Finance (2017)",
      "English Language Arts (2016)",
      "Mathematics (2016)",
      "Gifted Education",
      "Mathematics (2019)",
    ],
  },
  {
    title: "Alaska",
    subjects: [
      "Mathematics",
      "Physical Education",
      "Skills for a Healthy Life",
      "Technology",
      "History",
      "World Languages (2006)",
      "Government and Citizenship",
      "English/Language Arts (2006)",
      "Mathematics (2006)",
      "Employability",
      "Science",
      "Geography",
      "Arts",
      "English/Language Arts",
      "Library/Information Literacy",
      "Arts (2016)",
      "Elementary Education",
    ],
  },
  {
    title: "Alberta",
    subjects: [
      "CALM 20",
      "French as a Second Language",
      "Math 10-C",
      "Social Studies",
      "English Language Arts",
      "Aboriginal Studies",
      "LANGUAGE ARTS",
    ],
  },
  {
    title: "Arizona",
    subjects: [
      "Science",
      "Arts: Visual Arts (2006)",
      "Arts: Music (2006)",
      "Arts: Theater (2006)",
      "Arts: Dance (2006)",
      "Technology",
      "Physical Education (2009)",
      "English Language Arts/Literacy",
      "Arts: Dance",
      "Arts: General Music",
      "Foreign and Native Language",
      "Arts: Harmonizing Instrument",
      "Mathematics",
      "Physical Education",
      "Social Studies",
      "Advanced Construction Technologies",
      "Arts: Media Arts",
      "Health",
      "Arts: Theatre",
      "Arts: Visual Arts",
      "Advertising and Public Relations",
      "Arts: Music Technology",
      "Aircraft Mechanics",
      "Arts: Music Theory and Composition",
      "Early Learning",
      "Accounting & Related Services",
      "Arts: Performing Ensemble",
      "World and Native Languages",
      "Fashion Design and Merchandising",
      "CTE - CMT Graphics and Web Design",
      "CTE Education Professions",
      "Mathematics (2016)",
      "Geometry (2016)",
      "English Language Arts (2016)",
      "Algebra I (2016)",
      "Algebra II (2016)",
      "Math Plus (2016)",
      "THERAPEUTIC MASSAGE",
      "Dental Assisting",
      "COSMETOLOGY AND RELATED SERVICES",
      "MEDICAL ASSISTING SERVICES",
      "FIRE SERVICE",
      "VETERINARY ASSISTING",
      "CTE - Music and Audio Production",
      "NURSING SERVICES",
      "Religion",
    ],
  },
  {
    title: "Arkansas",
    subjects: [
      "Science",
      "English/Language Arts",
      "Reading English/Language Arts",
      "Debate I",
      "Calculus",
      "Physical Education and Health",
      "Mathematics",
      "Bridge to Algebra II",
      "Music Lab II",
      "Health and Wellness",
      "Theatre",
      "Social Studies",
      "Dance K-8",
      "Library Media",
      "Orchestra III",
      "Providing Real Opportunities for Vocational Education",
      "Theatre III",
      "Stagecraft II",
      "Dance I",
      "Agricultural Marketing",
      "Visual Art",
      "Visual Art Appreciation",
      "Environmental Resources: Soil and Water",
      "Advanced Topics and Modeling",
      "Algebra A",
      "Agricultural Electricity",
      "Creative Writing",
      "Algebra II",
      "Journalism IV",
      "Oral Communication - One Semester",
      "African American History",
      "Economics",
      "Journalism II",
      "Algebra III",
      "Debate II",
      "Debate III",
      "Forensics I",
      "Psychology",
      "Band IV",
      "History",
      "ELA Drama",
      "Dramatic Literature",
      "Geometry A",
      "Geometry B",
      "Forensics III",
      "Algebra I",
      "Geometry",
      "Algebra B",
      "Music",
      "Band I",
      "Civics",
      "Art History (Baroque to Postmodern)",
      "Vocal Music III",
      "Physical Education and Leisure",
      "Sociology",
      "Oral Communication - One Year 1 Credit",
      "Turfgrass Management",
      "Pre-Calculus",
      "Recreational Sports and Activities",
      "Journalism I",
      "Forensics II",
      "Band",
      "Art History (Prehistoric to Renaissance)",
      "Math Applications and Algorithms",
      "Band II",
      "Computer Science and Math",
      "Linear Systems and Statistics",
      "Jazz Band IV",
      "Orchestra II",
      "Orchestra I",
      "Music Lab I",
      "Dance IV",
      "Studio Art 2-D",
      "Visual Art II",
      "Jobs for Arkansas' Graduates",
      "Floriculture",
      "Biological Plant Science",
      "Jazz Band I",
      "Vocal Music IV",
      "Jazz Band II",
      "Visual Art I",
      "Agricultural Metals",
      "Band III",
      "Agricultural Power Systems",
      "Vocal Music I",
      "Music Appreciation",
      "Stagecraft III",
      "Music Lab III",
      "Dance Appreciation",
      "Dance III",
      "Introduction to Horticulture",
      "Agricultural Mechanics",
      "Fundamentals of Television",
      "Forestry",
      "Greenhouse Management",
      "Journalism III",
      "Intermediate Television",
      "World Geography",
      "Vocal Music II",
      "Music Theory",
      "Studio Art 3-D",
      "Technology Based Career Orientation",
      "Stagecraft I",
      "Advanced Television",
      "Internship",
      "Aquaculture",
      "Music Lab IV",
      "Music Technology",
      "Small Engine Technology",
      "Personal Fitness",
      "World History Since 1450",
      "Theatre I",
      "Workforce Technology",
      "Theatre II",
      "Introduction to World Agriculture Science",
      "Jazz Band III",
      "Career Orientation",
      "Managing Our Natural Resources",
      "Keystone",
      "Agricultural Structural Systems",
      "Leadership and Communications",
      "Nursery/Landscaping",
      "United States Government",
      "United States History Since 1890",
      "Essentials of Computer Programming",
      "Orchestra IV",
      "Dance II",
      "Visual Art III",
      "Visual Art IV",
      "Agricultural Business",
      "Advanced Radio",
      "Advanced Audio/Video Tech and Film",
      "Introduction to Travel and Tourism",
      "Intermediate Advertising and Graphic Design",
      "Medical Office Management",
      "Web Design I",
      "Programming II",
      "Entrepreneurship I",
      "Food and Nutrition",
      "Web Design II",
      "Digital Communications I",
      "Computerized Accounting II",
      "Introduction to Hospitality",
      "Keyboarding",
      "Web Technologies",
      "Fundamentals of Radio",
      "Small Business Operations",
      "Arkansas Science Standards (K-4)",
      "Introduction to Object Oriented Programming",
      "Arkansas Science Standards (5-8)",
      "Survey of Agriculture Systems",
      "Family Dynamics",
      "Abnormal Psychology",
      "Leadership and Service Learning (Grades 7-8)",
      "Clothing Management I",
      "Introduction to Medical Professions Extended",
      "Housing and Interior Design",
      "Plant Science II",
      "Advanced Photography",
      "Entrepreneurship II",
      "Parenting",
      "Oracle - Database Programming",
      "Introduction to Media Communications",
      "Keyboarding Applications",
      "Keyboarding 9 Week Course",
      "Computer Applications II",
      "Lodging Management I",
      "Intermediate Graphic Communication",
      "Salesmanship",
      "Fundamentals of Advertising and Graphic Design",
      "Personal Fitness for Life",
      "Financial Literacy",
      "Food Production",
      "Sports and Entertainment Marketing",
      "Intermediate Audio/Video Technology and Film",
      "Intermediate Radio",
      "Word Processing I",
      "Banking and Finance: Teller Operations",
      "Architectural/CAD I",
      "Food Science Technology",
      "Introduction to Finance",
      "Intermediate Photography",
      "Orientation to Teaching II",
      "Programming I Java",
      "Fundamentals of Photography",
      "Consumer Services",
      "Engineering CAD I",
      "Fundamentals of Graphic Communication",
      "Introduction to Medical Professions",
      "Food Science",
      "Cosmetology I",
      "Advanced Advertising and Graphic Design",
      "Sports Medicine Injury Assessment",
      "Medical Procedures",
      "Case Studies in Health Informatics",
      "Professional Practice: Health Informatics Internship",
      "Human Relations",
      "Advanced Database",
      "Banking and Finance Principles",
      "Business Law I",
      "Cosmetology II",
      "Information Technology Fundamentals",
      "Computer Applications I",
      "Digital Communications III",
      "Digital Communications II",
      "Entrepreneurial Experience",
      "Marketing",
      "Advanced Graphic Communication",
      "Advanced Spreadsheet",
      "Programming I",
      "International Travel",
      "Programming II Java",
      "Banking and Finance: Consumer Lending",
      "Child Care Guidance, Management, and Services",
      "Introduction to Health Informatics",
      "Management",
      "Child Development",
      "Retailing",
      "Customer Relations",
      "Equine Science",
      "Web Design and Multimedia Productions",
      "Pharmacy Technology",
      "Human Behavior and Disorders",
      "Advertising",
      "Fundamentals of Audio/Video, Tech and Film",
      "Drafting and Design",
      "Clothing Management II",
      "Oracle - Database Fundamentals",
      "Computerized Accounting I",
      "Exploring Personal Finance",
      "Computer Technology: Introduction",
      "Nutrition and Wellness",
      "Family and Consumer Sciences",
      "Orientation to Teaching I",
      "Office Education Cooperative",
      "Banking and Finance Law",
      "Business Law II",
      "Keyboarding Connections",
      "Computerized Business Applications",
      "Word Processing II",
      "Computer Applications III",
      "Digital Communications IV",
      "Animal Science II",
      "Introduction to Marketing",
      "Poultry Science",
      "Marketing Management",
      "Travel Destinations",
      "Human Anatomy and Physiology",
      "Plant Science I",
      "Foundations of Sports Medicine",
      "Business Communications",
      "Family and Consumer Science Investigation",
      "Fashion Merchandising",
      "Office Management",
      "Leadership and Service Learning (Grades 9-12)",
      "Animal Science I",
      "Medical Terminology",
      "Medical Procedures Extended",
      "Culinary Arts I",
      "Introduction to Technology and Engineering",
      "Technology Communications",
      "Criminal Law",
      "Gas Metal Arc Welding",
      "Modern Languages III 2013 Modern Languages III",
      "Foreign Language Experiences (K-8) 2013 Foreign Language Experiences (K-8)",
      "Latin I 2013 Latin I",
      "Modern Languages I 2013 Modern Languages I",
      "Spanish for Heritage and Native Speakers III 2013 Spanish for Heritage and Native Speakers III",
      "American Sign Language III 2014 American Sign Language III",
      "American Sign Language IV 2014 American Sign Language IV",
      "Plumbing",
      "Gas Tungsten Arc Welding",
      "Advanced Shielded Metal Arc Welding",
      "Aviation",
      "Input Technologies",
      "Design Applications in Engineering and Technology Education II",
      "Information and Communications Technology",
      "Investments and Securities",
      "Computer Engineering",
      "Collision Repair - Nonstructural Analysis/Repair",
      "Collision Repair - Structural Analysis/Damage Repair",
      "Diesel Electrical Systems",
      "Foreign Language Acquisition",
      "Power Equipment Technology",
      "Welding Technology",
      "American Sign Language I 2014 American Sign Language I",
      "Introduction to Business Communications and Technology",
      "Introductory Craft Skills",
      "Furniture Manufacturing",
      "Foundations of Law Enforcement",
      "Technology Design and Applications",
      "Shielded Metal Arc Welding",
      "Design Applications in Engineering and Technology Education I",
      "Insurance and Risk Management",
      "Diesel Brakes",
      "Crime Scene Investigation",
      "Automotive Engine Performance",
      "Collision Repair - Painting & Refinishing",
      "Culinary Arts II",
      "Introduction to Culinary Arts",
      "Carpentry",
      "Modern Languages IV 2013 Modern Languages IV",
      "Pre-Mechatronics",
      "Transitional Literacy",
      "Beef Science 2015 Beef Science",
      "Pre-Mechatronics Intermediate",
      "American Sign Language II 2014 American Sign Language II",
      "Industrial Equipment Maintenance",
      "Machine Tool Technology",
      "Modern Languages II 2013 Modern Languages II",
      "Fundamentals of Technology and Engineering",
      "Introduction to Criminal Justice",
      "Veterinary Science 2015 Veterinary Science",
      "Automotive Brakes",
      "Latin II 2013 Latin II",
      "Mechanical Plumbing and Electrical",
      "Spanish for Heritage and Native Speakers I 2013 Spanish for Heritage and Native Speakers I",
      "Spanish for Heritage and Native Speakers II 2013 Spanish for Heritage and Native Speakers II",
      "Automotive Suspension and Steering",
      "Spanish for Heritage and Native Speakers II",
      "Modern Languages:  Level 1",
      "Spanish for Heritage and Native Speakers I",
      "Mathematics (6-8) (2016)",
      "Computer Science Coding Block (Grades 7 or 8) (2016)",
      "Mathematics (9-12) (2016)",
      "Computer Science (5-8) (2016)",
      "Computer Science (K-4) (2016)",
      "Mathematics (K-5) (2016)",
      "English Language Arts Standards (2016)",
      "Disciplinary Literacy Standards (6-12) (2016)",
      "Computer Science and Mathematics (2016)",
      "Family Dynamics (2015)",
      "Computer Science: Advanced Networking (2016)",
      "Computer Science: Advanced Information Security (2016)",
      "Computer Science (9-12) (2016)",
      "Statistics Content Standards (2016)",
      "Algebra I Part A (2016)",
      "Geometry A (2016)",
      "Pre-Calculus Content Standards (2016)",
      "Computer Science: Advanced Programming (2016)",
      "",
      "Career Guidance",
      "Calculus (2016)",
      "Algebra III Content Standards (2016)",
      "Advanced Topics and Modeling in Mathematics (2016)",
      "Quantitative Literacy (2016)",
      "Geometry B (2016)",
      "KeyCode (2017)",
      "Bridge to Algebra II (2016)",
      "Algebra I Part B (2016)",
      "Mathematical Applications and Algorithms (2016)",
      "Geometry (2016)",
      "Algebra I Content Standards (2016)",
      "Disciplinary Literacy Standards for History/Social Studies (2016)",
      "Disciplinary Literacy Standards for Fine Arts (2016)",
      "Disciplinary Literacy Standards for Science (2016)",
      "Algebra II Content Standards (2016)",
      "English Language Proficiency (ELP)",
      "Career Development",
      "ELA Drama (2016)",
      "Creative Writing - One Semester (2016)",
      "Science: Earth Science (2018)",
      "Science: Human Anatomy and Physiology (2018)",
      "Dramatic Literature (2016)",
      "Science: Environmental Science (2018)",
      "Creative Writing - One Year 1 Credit (2016)",
      "Science: Astronomy (2018)",
      "Critical Reading I (2016)",
      "Journalism IV (2016)",
      "Oral Communication - One Semester (2016)",
      "Oral Commuication - One Year 1 Credit (2016)",
      "Strategic Reading (6-8) (2016)",
      "Transitional Literacy (2016)",
      "Science: Chemistry II (2018)",
      "Science: Chemistry - Integrated (2018)",
      "Science: Physical Science - Integrated (2018)",
      "Science: Physics (2018)",
      "Critical Reading II (2016)",
      "Journalism I (2016)",
      "Journalism II (2016)",
      "Journalism III (2016)",
      "Science: Biology - Integrated (2018)",
      "Debate I (2016)",
      "Debate II (2016)",
      "Debate III (2016)",
      "Debate IV (2016)",
      "Forensics I (2016)",
      "Forensics II (2016)",
      "Forensics III (2016)",
      "Forensics IV (2016)",
      "Food Safety & Nutrition",
    ],
  },
  {
    title: "British Columbia",
    subjects: [
      "English Language Arts (2015)",
      "Arts Education (2015)",
      "Mathematics (2015)",
      "Physical and Health Education (2015)",
      "Core French (2015)",
      "Science (2015)",
      "Social Studies (2015)",
      "Science (2008)",
      "Chemistry (2006)",
      "Career Education Curriculum (K-9) (2016)",
      "Applied Design, Skills, and Technologies Curriculum (K-9) (2016)",
      "Core French 10 (Draft curriculum June 2016)",
      "Core French 9 (New Curriculum)",
      "Éducation à la Carrière (M-9) (2016)",
      "Sciences humaines (M-9) (2016)",
      "Français de base (5-12) (2016)",
      "Français Langue Première (M-9) (2016)",
      "Conception, compétences pratiques et technologies (M-9) (2016)",
      "Éducation artistique (M-9) (2016)",
      "English Language Arts (M-9) (2016)",
      "Sciences (M-9) (2016)",
      "Éducation physique et santé (M-9) (2016)",
      "Mathématiques (M-9) (2016)",
      "Mathematics (2016)",
      "Science (2016)",
      "Mathematics (2018/19)",
    ],
  },
  {
    title: "California",
    subjects: [
      "History & Social Studies",
      "Mathematics",
      "Visual & Performing Arts",
      "Physical Education",
      "Health",
      "English Language Arts & Literacy",
      "CTE: Arts, Media, and Entertainment",
      "World Language",
      "CTE: Education, Child Development, and Family Services",
      "Career Ready Practice",
      "CTE: Agriculture and Natural Resources",
      "CTE: Building and Construction Trades",
      "CTE: Fashion and Interior Design",
      "CTE: Energy, Environment, and Utilities",
      "CTE: Business and Finance",
      "CTE: Engineering and Architecture",
      "CTE: Health Science and Medical Technology",
      "Library",
      "CTE: Marketing, Sales, and Service",
      "Science",
      "CTE: Transportation",
      "CTE: Hospitality, Tourism, and Recreation",
      "English Language Development",
      "CTE: Manufacturing and Product Development",
      "CTE: Information and Communication Technologies",
      "CTE: Public Services",
      "Engineering Design",
      "Physical Development",
      "Life Science",
      "Language and Literacy",
      "Social-Emotional Development",
      "Religion",
      "SJND SLE",
      "Computer Science",
    ],
  },
  {
    title: "Colorado",
    subjects: [
      "Social Studies",
      "Music",
      "Science",
      "Drama & Theater Arts",
      "Visual Arts",
      "Dance",
      "Comprehensive Health and Physical Education",
      "Agriculture Cluster (2015)",
      "Reading, Writing, and Communicating",
      "Design and Pre-Construction (2015)",
      "Restaurants and Food & Beverage Services (2015)",
      "Animal Science (2015)",
      "Construction (2015)",
      "IT Cluster (2015)",
      "Maintenance and Operations (2015)",
      "Consumer Services (2015)",
      "Law Enforcement Services (2015)",
      "Programming & Software Engineering (2015)",
      "Mathematics",
      "Accounting (2015)",
      "Logistics, Planning & Management Services (2015)",
      "Health (2015)",
      "Fossil Energy Extraction, Processing & Distribution (2015)",
      "Corporate Finance (2015)",
      "Renewable Energy Production (2015)",
      "Counseling and Mental Health Services (2015)",
      "Early Childhood Development (2015)",
      "Information Support and Services (2015)",
      "Marketing Research (2015)",
      "Family and Community Services (2015)",
      "Network Systems & Telecommunications (2015)",
      "Postseconday and Workforce Readiness (2015)",
      "Business Information Technology (2015)",
      "Agribusiness Systems (2015)",
      "Corporate/General Management (2015)",
      "Energy Efficiency & Environmental Technology (2015)",
      "Marketing Communications (2015)",
      "Arts, AV and Communication (2015)",
      "Power, Structure, and Technical Systems (2015)",
      "Personal Care Services (2015)",
      "Administrative Services (2015)",
      "Journalism & Broadcasting (2015)",
      "Professional Sales (2015)",
      "Sales and Service (2015)",
      "Human Resource Management (2015)",
      "Electromechanical Generation & Maintenance (2015)",
      "Marketing (2015)",
      "STEM (2015)",
      "Transportation Operations (2015)",
      "Printing/Publishing (2015)",
      "Finance (2015)",
      "Performing Arts (2015)",
      "Therapeutic Services (2015)",
      "Marketing Management (2015)",
      "Food Products & Processing Systems (2015)",
      "Manufacturing Production (2015)",
      "Electrical Energy Transmission & Distribution (2015)",
      "Warehousing & Distribution Center Operations (2015)",
      "Energy (2015)",
      "Education & Training (2015)",
      "Securities and Investments (2015)",
      "Natural Resource and Environmental Systems (2015)",
      "Visual & Design Arts (2015)",
      "Emergency and Fire Management (2015)",
      "Banking Services (2015)",
      "Interactive Media (2015)",
      "Audio/Video Technology & Film (2015)",
      "Management (2015)",
      "Plant Science (2015)",
      "Maintenance, Installation & Repair (2015)",
      "Facility & Mobile Equipment Maintenance (2015)",
      "Insurance (2015)",
      "Operations Management (2015)",
      "Criminal Justice",
      "World Languages",
      "Mathematics (2020)",
      "Reading, Writing and Communicating (2020)",
    ],
  },
  {
    title: "Common Core State Standards",
    subjects: [
      "Common Core Mathematics",
      "Common Core English/Language Arts",
      "English Language Arts - Science and Technical Subjects",
      "",
      "English Language Arts - History/Social Studies",
      "English Language Arts - Writing (History/Social Studies, Science, & Technical Subjects)",
      "Arte Lenguaje en Español",
    ],
  },
  {
    title: "Connecticut",
    subjects: [
      "Science",
      "Core Mathematics",
      "Core English Language Arts/Literacy",
      "Social Studies",
      "Arts",
      "Health & Physical Education",
      "Information & Technology Literacy",
      "Next Generation Science Standards (2015)",
      "Sexual Health Education Curriculum Framework (2013)",
      "Connecticut English Language Proficiency",
    ],
  },
  {
    title: "Delaware",
    subjects: [
      "Science Content Standards",
      "Theatre",
      "Economis",
      "History",
      "Science Grade Level Exepectations",
      "Music",
      "Common Core Mathematics",
      "Civics",
      "Physical Education",
      "Dance",
      "Geography",
      "Personal Finance",
      "Health Education",
      "Visual Art",
      "Technology Education",
      "Common Core English/Language Arts",
      "Next Generation Science",
    ],
  },
  {
    title: "District of Columbia",
    subjects: [
      "Arts",
      "Science",
      "Health",
      "Physical Education",
      "Common Core Mathematics",
      "Social Studies",
      "Common Core English/Language Arts",
      "Health Education (2016)",
      "Physical Education (2017)",
    ],
  },
  {
    title: "Florida",
    subjects: [
      "Science",
      "Fine Arts - Music",
      "Social Studies",
      "Mathematics",
      "Fine Arts - Theater",
      "Language Arts Florida Standards (LAFS)",
      "Fine Arts - Visual Arts",
      "Fine Arts - Dance",
      "Health",
      "Physical Education",
      "World Languages",
      "CTE Curriculum Standards: Information Technology",
      "Arts, A/V Technology and Communication",
      "Computer Science (2016)",
      "Introduction to Technology (8600010)",
      "Foundations of Culinary Careers 200",
      "Adult Basic Education",
      "Learning Strategies",
      "Digital Information Technology",
      "CTE: Business Management and Administration",
      "CTE Curriculum Standards: Agriculture, Food, and Natural Resources",
      "CTE: Early Childhood Education",
    ],
  },
  {
    title: "Georgia",
    subjects: [
      "Science",
      "Social Studies",
      "Ecology",
      "Botany",
      "Entomology",
      "Fine Arts - Visual Arts",
      "Fine Arts - Music",
      "Fine Arts - Dance",
      "Health",
      "Science Research",
      "Mathematics - Foundations of Algebra",
      "Physical Education",
      "ELA",
      "Common Core English/Language Arts (2010)",
      "Zoology",
      "Common Core Standards Calculus",
      "Common Core Advanced Mathematical Topics",
      "Mathematics - Analytic Geometry",
      "Geology",
      "Piano",
      "Sociology",
      "Meteorology",
      "Forensic Science",
      "Fine Arts - Theatre Arts",
      "Oceanography",
      "Microbiology",
      "Mathematics - Geometry",
      "Mathematics of Finance",
      "Statistical Reasoning",
      "Music Technology",
      "Astronomy",
      "Music Appreciation",
      "Psychology",
      "Guitar",
      "Mathematics of Industry & Government",
      "Common Core Standards Engineering Calculus",
      "Mathematics - Coordinate Algebra",
      "Multivariable Calculus",
      "Mathematics - Algebra I",
      "History of Mathematics",
      "Advanced Mathematical Decision Making",
      "Mathematics",
      "Mathematics - Pre-Calculus",
      "Mathematics - Accelerated Coordinate Algebra / Analytic Geometry A",
      "Mathematics - Accelerated Pre-Calculus",
      "Mathematics - Standards for Mathematical Practice",
      "Algebra II / Advanced Algebra",
      "Mathematics - Accelerated Analytic Geometry B / Advanced Algebra",
      "Mathematics - Accelerated Algebra I/Geometry A",
      "Accelerated Geometry B / Algebra II",
      "American Sign Language",
      "",
      "Social Studies (2016)",
      "Social Studies - United States History (2016)",
      "Science (2016)",
      "Science - Biology (2016)",
      "Social Studies - Economics (2016)",
      "Social Studies - Sociology (2016)",
      "Science - Environmental Science (2016)",
      "Science - Earth Systems (2016)",
      "Social Studies - American Government/Civics (2016)",
      "Social Studies - World Geography (2016)",
      "Social Studies - Psychology (2016)",
      "Science - Chemistry (2016)",
      "Science - Physics (2016)",
      "Social Studies - World History (2016)",
      "Science - Physical Science (2016)",
      "Construction",
      "Career, Technical and Agricultural Education Foundation Skills",
      "Mathematics - Mathematics of Finance (2017)",
      "Mathematics - Advanced Mathematical Topics (2017)",
      "English - Technical College Readiness (2016)",
      "Mathematics - Technical College Readiness (2016)",
      "Mathematics - Advanced Mathematical Decision Making (2017)",
      "Mathematics - Mathematics of Industry and Government (2017)",
      "Mathematics - Multivariable Calculus (2017)",
      "Mathematics - Calculus (2017)",
      "Mathematics - Engineering Calculus (2017)",
      "Mathematics - Advanced Finite Mathematics (2016)",
      "Mathematics - Statistical Reasoning (2017)",
      "Mathematics - History of Mathematics (2017)",
      "Mathematics - College Readiness (2016)",
      "Georgia Pre-K",
      "Mathematics - Differential Equations (2017)",
      "Personal Financial Literacy Social Studies (2017)",
      "Media Arts (2017)",
      "Theatre (2017)",
      "Visual Art (2017)",
      "Modern Languages - Level II",
      "Modern Languages - Level I",
      "Career Development",
      "Exploring Engineering and Technology",
      "Engineering and Technology Education Grade 7",
      "Engineering and Technology Grade 8",
    ],
  },
  {
    title: "Hawaii",
    subjects: [
      "Social Studies",
      "Fine Arts",
      "Career and Technical Education",
      "Health",
      "Science",
      "Common Core Math",
      "Common Core English/Language Arts",
      "Physical Education",
      "Next Generation Science Standards (2016)",
    ],
  },
  {
    title: "Idaho",
    subjects: [
      "Science",
      "Social Studies",
      "Chemistry",
      "Mathematics",
      "Language Arts (2006)",
      "English Language (2006)",
      "Common Core Math",
      "Physical Education",
      "Humanities: Theatre",
      "Humanities: Music",
      "Humanities: Visual Arts",
      "Health",
      "Information and Communication Technology",
      "World Languages",
      "Humanities: Dance",
      "Interdisciplinary",
      "Common Core English/Language Arts",
      "Biology (End-Of-Course)",
      "Hand Writing",
      "Speech",
      "Chemistry (End-Of-Course)",
      "CTE",
      "Marketing",
      "Fine Arts - Music Technology (2017)",
      "Fine Arts - Music Composition and Theory (2017)",
      "Fine Arts - Harmonizing Instruments (2017)",
      "Fine Arts - Traditional and Emerging Ensembles (2017)",
      "Fine Arts - Visual Arts (2017)",
      "Fine Arts - Media Arts (2017)",
      "State Science Standards (2016)",
      "Fine Arts - General Music, Music Appreciation, Music History (2017)",
      "Fine Arts - Dance (2017)",
      "Geographical Information System",
      "Early Childhood Learning Standards",
      "Student Leadership",
      "Science (2018)",
    ],
  },
  {
    title: "Illinois",
    subjects: [
      "Science (1997)",
      "English Language Arts (1997)",
      "Mathematics",
      "Fine Arts",
      "Physical Development & Health",
      "Social/Emotional Development",
      "English Language Arts with Common Core",
      "Social Science",
      "Math with Common Core",
      "Next Generation Science Standards",
      "ABE/ASE Language Arts",
      "ABE/ASE Math Content",
      "ABE/ASE Writing Content",
      "ABE/ASE Reading Content",
      "Foreign Languages",
      "Social Science (2016)",
      "Science",
      "Language Arts",
      "The Arts",
      "Technology Integration",
      "English Language Learner Home Language Development",
      " Physical Development and Health",
      "Social Studies",
      "Visual Arts",
      "Visual Arts (2016)",
      "Media Arts (2016)",
      "Music (2016)",
      "Dance (2016)",
      "Theatre (2016)",
      "Library Media Education",
    ],
  },
  {
    title: "Indiana",
    subjects: [
      "Dance",
      "Theater",
      "Physical Education",
      "Visual Arts",
      "Health & Wellness",
      "Mathematics",
      "English/Language Arts",
      "Algebra I",
      "Finite",
      "Technology Education",
      "Science/Technical Subjects",
      "Music",
      "Science",
      "World Geography",
      "Pre-Calculus",
      "Trigonometry",
      "Process Standards for Mathematics",
      "Calculus",
      "Social Studies",
      "Algebra II",
      "United States Government",
      "Probability & Statistics",
      "Global Economics",
      "History/Social Studies",
      "Economics",
      "Psychology",
      "Financial Literacy Standards for End of 8th Grade",
      "United States History",
      "Sociology",
      "Geometry and History of the World",
      "Financial Literacy Standards for End of 12th Grade",
      "World History and Civilization",
      "Geometry",
      "Indiana Academic Science Standards: Biology (2016)",
      "Computer Science: 6-8 (2016)",
      "Indiana Academic Science Standards: Earth & Space Science (2016)",
      "Indiana Academic Science Standards: Physics II (2016)",
      "Indiana Academic Science Standards: Grade 3 (2016)",
      "Indiana Academic Science Standards: Integrated Chemistry & Physics (2016)",
      "Indiana Academic Science Standards: Grade 8 (2016)",
      "Indiana Academic Science Standards: Environmental Science (2016)",
      "Indiana Academic Science Standards: Physics I (2016)",
      "Indiana Academic Science Standards: Grade 4 (2016)",
      "Indiana Academic Science Standards: Chemistry (2016)",
      "Indiana Academic Science Standards: Grade 5 (2016)",
      "Indiana Academic Science Standards: Grade 7 (2016)",
      "Indiana Academic Science Standards: Kindergarten (2016)",
      "Indiana Academic Science Standards: Grade 6 (2016)",
      "Indiana Academic Science Standards: Grade 1 (2016)",
      "Indiana Academic Science Standards: Grade 2 (2016)",
      "Commercial Photography",
      "Computer Illustrations and Graphics",
      "Computer Science: K-2 (2016)",
      "Science: Science and Engineering Process Standards (2016)",
      "Computer Science: 3-5 (2016)",
      "East Asian Languages",
      "Computer Science",
      "Family and Consumer Science",
      "World Language Level 1",
      "Introduction to Accounting",
      "ICE Work Based Learning-Internship",
      "World Language Level 3",
      "Mathematics (2017)",
      "World Language Level 2",
      " Indiana Academic Standards: Economics",
      "Indiana Academic Standards Global Economics",
      "Indiana Academic Standards: Geography and History of the World",
      "Indiana Academic Standards: United States Government",
      "Indiana Academic Standards: Sociology",
      "Indiana Academic Standards: Psychology",
      "Indiana Academic Standards: World Geography",
      "Indiana Academic Standards: World History & Civilization",
      "Indiana Academic Standards: United States History",
      "Indiana Academic Standards: United States History Power Standards",
      "Indiana Academic Standards: Quantitative Reasoning",
      "Indiana Academic Science Standards: Anatomy and Physiology ",
      "Indiana Academic Standards: Business Law and Ethics",
      "Principles of Marketing",
      "Preparing for College and Careers",
      "Physical Education (9-12) (2017)",
      "Physical Education (6-8) (2017)",
      "Health & Wellness (2017)",
      "Fine Arts - Visual Arts (2017)",
      "Physical Education (K-5) (2017)",
      "Fine Arts - Theatre (2017)",
      "Fine Arts - Dance (2017)",
      "CTE: Arts, AV Communications",
      "Indiana Academic Standards: Animal Science",
      "Indiana Academic Standards: Business Math",
      "Indiana Academic Standards: Grade 4 Writing",
      "Mathematics: K-8 (2020)",
      "Mathematics: Precalculus: Algebra (2020)",
      "Mathematics: Analytical Algebra II (2018)",
      "Mathematics: Analytical Algebra II (2020)",
      "Mathematics: Finite (2020)",
      "Mathematics: Probability and Statistics (2020)",
      "Mathematics: Precalculus: Trigonometry (2020)",
      "Algebra II (2020)",
      "Mathematics: Algebra I (2020)",
      "Mathematics: Geometry (2020)",
      "Mathematics: Quantitative Reasoning (2020)",
      "Mathematics: Calculus (2020)",
      "English Language Arts (2020)",
      "Journalism (2020)",
      "Indiana Academic Standards: Exploring Agriculture Science and Business Ed",
      "Indiana Academic Standards: Digital Citizenship",
      "Indiana Academic Standards: Advanced Accounting",
      "Indiana Academic Standards: Mass Media and Media Literacy",
      "Indiana Academic Standards: Nutrition and Wellness",
      "Indiana Academic Standards: Visual Arts",
      "Indiana Academic Standards: Indiana Studies",
      "Indiana Academic Science Standards: Human Development and Wellness",
      "Indiana Academic Science Standards: Analytical Algebra II ",
      "Indiana Academic Science Standards: Introduction to Agriculture, Food, and Natural Resources",
    ],
  },
  {
    title: "Iowa",
    subjects: [
      "Science",
      "Mathematics",
      "Technology Literacy",
      "Literacy",
      "Next Generation Science Standards",
      "Financial Literacy",
      "Employability Skills",
      "Health Literacy",
      null,
      "Technology",
      "Social Studies",
      "Music",
      "Social Studies (2017)",
      "Core English Language Arts and Literacy in History/Social Studies, Science, and Technical Subjects (2016)",
      "Culinary",
      "Elementary Physical Education",
    ],
  },
  {
    title: "Kansas",
    subjects: [
      "Mathematics",
      "Visual Arts",
      "Dance and Creative Movement",
      "History, Government, and Social Studies",
      "Music",
      "Theatre",
      "English Lanauge Arts & Literacy",
      "Library Media and Technology",
      "Video Production",
      "Visual Arts (2015)",
      "Graphic Design",
      "Science",
      "Health Education",
      "Project Management and Resource Scheduling",
      "Nutrition and Wellness A",
      "Introduction to Drawing",
      "Physical Education",
      "Trends in Interior and Textile Design",
      "Business Essentials",
      "Media Technology–Workplace Experience",
      "Digital Media Technology",
      "Digital Media Design and Production",
      "Orientation in Early Childhood Education",
      "Graphic Design Fundamentals",
      "Human Growth and Development B",
      "Consumer and Personal Finance B",
      "Nutrition and Wellness B",
      "Essentials of Interior and Textile Design",
      "Career and Community Connections",
      "Marketing",
      "21st Century Journalism",
      "Computer Applications",
      "Entrepreneurship",
      "Commercial Graphic Design",
      "Principles of Illustration",
      "Family Studies B",
      "Interior and Textile Merchandising",
      "Audio/Video Production Fundamentals",
      "Human Growth and Development A",
      "Interior and Textile Design Studio",
      "Media and Public Relations",
      "Career and Life Planning",
      "Family Studies A",
      "Foundations in Early Childhood Education",
      "Introduction to Family and Consumer Sciences",
      "Introduction to Human Services",
      "Consumer and Personal Finance A",
      "Early Childhood Applications",
      "Photo Imaging",
      "Music: Ensembles (2015)",
      "Theatre (2013)",
      "Music: General Music (2015)",
      "Hospitality and tourism career cluster",
      "World Language Standards",
      "Mathematics (2017)",
      "English Language Arts Standards (2017)",
      "K-6 Handwriting Standards",
    ],
  },
  {
    title: "Kentucky",
    subjects: [
      "Mathematics",
      "Teacher Standards",
      "English/Language Arts",
      "Vocational Studies",
      "Science",
      "Technology",
      "Practical Living",
      "Arts and Humanities",
      "Consumer Economics (2015)",
      "Career Choices (2015)",
      "Culinary Arts II (2015)",
      "FACS Essentials (2015)",
      "Social Studies",
      "Introductory FACS Essentials (2015)",
      "Money Skills (2015)",
      "Early Lifespan Development (2015)",
      "Child Development Services I (2015)",
      "Foods & Nutrition (2015)",
      "Middle to Late Lifespan Development (2015)",
      "Principles of Hospitality (2015)",
      "Specialized Services in Hospitality (2015)",
      "Culinary Arts I (2015)",
      "Parenting (2015)",
      "Relationships (2015)",
      "Principles of Teaching (2015)",
      "Advanced Foods & Nutrition (2015)",
      "Fashion & Interior Design I (2015)",
      "Nutritional Food Science (2015)",
      "Fashion & Interior Design II (2015)",
      "Leadership Dynamics (2015)",
      "Fundamentals of Dietetics (2015)",
      "Child Development Services II (2015)",
      "FACS Essentials Health (2015)",
      "Money Skills for Math (2015)",
      "Early Learning Standards",
      "Music Technology (2015)",
      "Harmonizing Instruments (2015)",
      "Theatre (2015)",
      "Media Arts (2015)",
      "Dance (2015)",
      "Music (K-8) (2015)",
      "Visual Arts (2015)",
      "Music Composition and Theory (2015)",
      "Traditional and Emerging Ensembles (2015)",
      "Horticulture",
      "Kentucky Alternate Assessment",
      "Alternate Assessment",
    ],
  },
  {
    title: "Louisiana",
    subjects: [
      "Career-Technical",
      "Science",
      "Arts",
      "English/Language Arts (2005)",
      "Social Studies",
      "Health Education",
      "Common Core Math",
      "Common Core English/Language Arts",
      "Physical Education",
      "Social Studies (2005)",
      "Information Literacy Skills",
      "Louisiana World Language Content Standards",
      "Louisiana World Language Content Standards: Novice Low",
      "Mathematics (2016)",
      "Science (2017)",
      "English Language Arts (2016)",
    ],
  },
  {
    title: "Maine",
    subjects: [
      "Social Studies",
      "Career and Education Development Learning Results: Parameters for Essential Instruction",
      "Visual & Performing Arts",
      "Common Core English/Language Arts",
      "Science and Technology",
      "Common Core Mathematics",
      "Health and Physical Education",
      "World Language",
      "Archdiocesan Religion Curriculum Standards",
    ],
  },
  { title: "Manitoba", subjects: [] },
  {
    title: "Maryland",
    subjects: [
      "Technology Literacy Standards for Students",
      "Social Studies",
      "Science Core Learning Goals (2002)",
      "Science (2008)",
      "Health Education",
      "Dance",
      "Music",
      "Theater",
      "Physical Education",
      "Algebra I",
      "Mathematics",
      "English Language Arts",
      "Personal Financial Literacy",
      "Visual Arts",
      "Next Generation Science Standards",
      "Technology Literacy (2007)",
      "Library Media (PreK-8)",
      "Technology Education",
      "Literacy",
      "Geometry",
      "Library Media (9-12)",
      "Algebra II",
      "Maryland US History Standards",
      "Career Research and Development",
      "World Languages",
      "Visual Art",
      "Visual Art (2017)",
      "Dance (2017)",
      "Media Arts (2017)",
      "Theatre (2017)",
    ],
  },
  {
    title: "Massachusetts",
    subjects: [
      "Sciece, Technology, Engineering",
      "History & Social Studies",
      "Arts",
      "Comprehensive Health",
      "English Language Arts and Literacy",
      "Technology Literacy",
      "Foreign Language",
      "Mathematics",
      "Science and Technology/Engineering (2016)",
      "Digital Literacy and Computer Science (2016)",
      "21st Century Learning",
      "English Language Arts and Literacy (2017)",
      "Mathematics (2017)",
      "Vocational Technical Education Standardized Strands",
    ],
  },
  {
    title: "Michigan",
    subjects: [
      "Health",
      "Science",
      "Health Education",
      "World Language",
      "Common Core Mathematics",
      "Visual Arts, Music, Dance, and Theater",
      "Physical Education (2008)",
      "Social Studies",
      "English Lanauge (2004)",
      "Biology",
      "Earth Science",
      "Physical Education (2007)",
      "Chemistry",
      "Physics",
      "Science (2015)",
      "Common Core English/Language Arts",
      "Educational Technology",
      "Danielson Framework for Teaching",
      "Early Childhood Standards",
      "MVCA History Focus",
      "Alternative Content Standards-Mathematics",
      "Alternative Content Standards-English Language Arts",
      "Marketing and Entrepreneurship",
      "Career & Technical Education",
    ],
  },
  {
    title: "Minnesota",
    subjects: [
      "Health",
      "Mathematics",
      "Science",
      "Social Studies",
      "English Language Arts",
      "Physical Education",
      "Arts",
      "Physical Education (2018)",
      "Social Emotional Learning",
    ],
  },
  {
    title: "Mississippi",
    subjects: [
      "Music",
      "Social Studies",
      "Common Core Mathematics",
      "Dance",
      "Science",
      "Common Core English Language/Arts",
      "Physical Education",
      "Theater",
      "Visual Arts",
      "Health",
      "World Languages Framework (2016)",
      "Mathematics (2016)",
      "Broadcast Journalism",
      "Information and Communication Technology I (2014)",
      "Information and Communication Technology II (2014)",
      "Science (2018)",
      "English Language Arts (2016)",
      "Cyber Foundations 2",
      "Cyber Foundations 1",
      "Music (Performing Ensembles)",
    ],
  },
  {
    title: "Missouri",
    subjects: [
      "Visual Art",
      "Common Core English/Language Arts (2010)",
      "Music",
      "Social Studies",
      "Theater",
      "Common Core Math",
      "Health",
      "Physical Education",
      "Science",
      "Biology",
      "English Language Arts (6-12) (2016)",
      "Science (K-5) (2016)",
      "Science (6-12) (2016)",
      "Social Studies (6-12) (2016)",
      "English Language Arts (K-5) (2016)",
      "Mathematics (6-12) (2016)",
      "Social Studies (K-5) (2016)",
      "Mathematics (K-5) (2016)",
      "MLS Reading",
      "World Languages",
      "Web Design",
      "Family and Consumer Sciences",
      "Early Learning Goals",
      "English Language Arts",
      "Gifted Education",
    ],
  },
  {
    title: "Montana",
    subjects: [
      "Arts",
      "Science(2006)",
      "Science (ELE)",
      "Social Studies",
      "Common Core Math",
      "Common Core English/Language Arts",
      "Health Enhancement",
      "Information Literacy/Library Media",
      "Career & Vocational Technical Education",
      "Workplace Competencies",
      "Technology",
      "Physical Education (2016)",
      "Health Enhancement (2016)",
      "Science (2016)",
      "Music",
      "music",
      "Sociology",
      "World Languages",
      "Theatre Arts (2016)",
      "Music (2016)",
      "Dance (2016)",
      "Media Arts (2016)",
      "Visual Arts (2016)",
    ],
  },
  {
    title: "Nebraska",
    subjects: [
      "Language Arts",
      "Mathematics",
      "Science",
      "Social Studies",
      "English Language Arts",
      "Fine Arts: Music",
      "Fine Arts :Media Arts",
      "Fine Arts: Visual Arts",
      "Fine Arts: Theatre",
      "Fine Arts: Dance",
      "Mathematics (2015)",
      "Physical Education",
      "Foreign Language",
      "Career Technical Education",
      "Human/ Family and Consumer Sciences",
      "Physical Education (2016)",
      "Personal Finance",
      "Science (2017)",
      "AQuESTT- Evidence-Based Analysis (District)",
      "Industrial Arts",
    ],
  },
  {
    title: "Nevada",
    subjects: [
      "Theater",
      "Social Studies",
      "Health",
      "Visual Arts",
      "Mathematics",
      "English Language Arts",
      "Music",
      "Computer and Technology",
      "Information Literacy Standards",
      "Physical Education",
      "Science",
      "World Languages",
      "Social Studies (2017)",
      "Agricultural Mechanics Technology",
      "Diesel Technology",
    ],
  },
  {
    title: "New Hampshire",
    subjects: [
      "Reading",
      "Mathematics",
      "Written & Oral Communication",
      "Science Litearcy",
      "Health Education",
      "Common Core Mathematics",
      "Career Development",
      "Arts",
      "Physical Education",
      "Social Studies",
      "Common Core English Language Arts & Literacy",
      "Science (NGSS) (2016)",
    ],
  },
  {
    title: "New Jersey",
    subjects: [
      "Science",
      "National Association for The Education of Young People",
      "Common Core Mathematics",
      "Technology",
      "World Languages",
      "Common Core English Language Arts and Literacy",
      "Health and Physical Education",
      "Social Studies",
      "Life and Careers",
      "Visual & Performing Arts",
      "ELA",
      "Mathematics (2016)",
      "English Language Arts (2016)",
      "Teaching and Learning Standards",
      "Social/Emotional Development",
      "Mathematics ",
    ],
  },
  {
    title: "New Mexico",
    subjects: [
      "Social Studies",
      "Science",
      "Physical Education",
      "Health",
      "Modern, Classical, & Native Languages",
      "Mathematics",
      "Visual Arts",
      "Music",
      "Theater/Drama",
      "English Language Arts & Literacy",
      "Dance",
    ],
  },
  {
    title: "New York",
    subjects: [
      "Math, Science & Technology - Process Skills based on Standard 4",
      "Physical Setting/Earth Science",
      "Science Standard 4: Physical Setting & Living Environment",
      "Social Studies",
      "Physical Setting/Chemistry",
      "Math, Science & Technology - Standard 4:  The Physical Setting",
      "Health, Physical Education, and Family & Consumer Science (1996)",
      "Mathematics Core",
      "Common Core Mathematics (2010)",
      "English/Language Arts (1996)",
      "English/Language Arts Core",
      "Arts (1996)",
      "Social Studies (1996)",
      "Common Core English/Language Arts (2010)",
      "Math, Science & Technology - Standard 1 & 2 (1996)",
      "Math, Science & Technology - Standard 5 (1996)",
      "Math, Science & Technology - Standard 4 (1996)",
      "Math, Science & Technology - Standard 6 & 7 (1996)",
      "Career Development & Occupational Studies (1995)",
      "Math, Science & Technology - Standard 4: The Living Environment",
      "Biology",
      "Science",
      "Science (1999)",
      "Science (2016)",
      "French",
      "Spanish",
      "Arts: Music Technology (2017)",
      "Arts: Composition and Theory (2017)",
      "Arts: Music - Traditional and Emerging Ensembles (2017)",
      "Geometry (2017)",
      "Mathematics Plus Standards (2017)",
      "Algebra I (2017)",
      "Algebra II (2017)",
      "Arts: Music - Harmonizing Instruments (2017)",
      "Next Generation Grades 6-12 Learning Standards for Literacy (2017)",
      "Arts: Media Arts (2017)",
      "Arts: Visual Arts (2017)",
      "Arts: General Music (2017)",
      "Arts: Theater (2017)",
      "Arts: Dance (2017)",
      "Next Generation Mathematics Learning Standards (K-8) (2017)",
      "Next Generation English Language Arts Learning Standards (2017)",
      "NYS Learning Standards",
      "Science - Disciplinary Core Ideas",
      "Science - Crosscutting Concepts",
      "Science - Science and Engineering Practices",
    ],
  },
  { title: "Newfoundland and Labrador", subjects: [] },
  {
    title: "North Carolina",
    subjects: [
      "Mathematics",
      "Science",
      "Social Studies",
      "Visual Arts",
      "Dance",
      "Psychology",
      "Theater",
      "Information & Technology",
      "Music",
      "English/Language Arts (2011)",
      "Health Education",
      "Common Core Math",
      "Physical Education",
      "Common Core English/Language Arts",
      "World Language",
      "Science (K-8) (2011)",
      "Mathematics (2011)",
      "Introduction to Mathematics (2010)",
      "Social Studies (K-8) (2011)",
      "Turning Points in American History",
      "Civics and Governance (2011)",
      "American Humanities",
      "Latin American Studies",
      "21st Century Geography",
      "Occupational - Preparation 1 (2006)",
      "Twentieth Century Civil Liberties and Civil Rights",
      "The Cold War",
      "Occupational - Preparation 4 (2006)",
      "Life Science (2011)",
      "Sociology",
      "American Indian Studies",
      "Occupational - Preparation 2 (2006)",
      "Occupational - Preparation 3 (2006)",
      "Financial Management (2010)",
      "American History I and II (2011)",
      "World Humanities",
      "African American Studies",
      "Biology A&B (2011)",
      "Financial Management (2011)",
      "NC ABE/ASE Content Standards",
      "NC Adult Secondary Education Content Standards",
      "Fashion Merchandising (2016)",
      "Automotive Service II (2016)",
      "American History: The Founding Principles, Civics and Economics (2015)",
      "Equine Science II (2016)",
      "Computer Engineering Technology II (2016)",
      "Marketing Applications (2016)",
      "Equine Science I (2016)",
      "Digital Media (2016)",
      "Advanced Digital Media (2016)",
      "Adobe Video Design (2016)",
      "Public Safety I (2016)",
      "Introduction to Automotive Service (2016)",
      "Biomedical Technology I (2016)",
      "Automotive Service I (2016)",
      "Adobe Digital Design (2016)",
      "Adobe Visual Design (2016)",
      "Automotive Service III (2016)",
      "Principles of Family and Human Services (2016)",
      "Principles of Business and Finance (2016)",
      "Computer Engineering Technology I (2016)",
      "Biology (2016)",
      "Microsoft Excel and Access (2014)",
      "Math I (2016)",
      "Drafting II - Engineering (2016)",
      "Masonry III (2012)",
      "Office Productivity Applications (2016)",
      "Apparel and Textile Production I (2015)",
      "Microsoft Word and PowerPoint (2016)",
      "Personal Finance (2014)",
      "Career Development (2015)",
      "Interior Design II (2005)",
      "Technology Design and Innovation (2012)",
      "Foods I (2010)",
      "Personal Finance (2015)",
      "Microsoft Word and PowerPoint (2014)",
      "Introduction to Office Productivity (2016)",
      "Masonry II (2012)",
      "Keyboarding and Basic Word Processing (2016)",
      "Honors Health Science II (2014)",
      "Parenting and Child Development (2016)",
      "Foods II - Enterprise (2013)",
      "Drafting III - Engineering (2016)",
      "Masonry I (2012)",
      "Math III (2016)",
      "Multimedia and Web Page Design (2015)",
      "Math II (2016)",
      "Apparel and Textile Production II (2015)",
      "Health Science I (2012)",
      "Nursing Fundamentals (2011)",
      "Interior Design I (2016)",
      "Career Development Middle School",
      "Agriculture Education",
      "Standards for Mathematical Practice (2015)",
      "Drafting I (2013)",
      "Discrete Mathematics (2003)",
      "Horticulture II (2013)",
      "Agriscience Applications (2013)",
      "Advanced Functions and Modeling (2003)",
      "Mathematics (2015)",
      "Drafting II - Architectural (2015)",
      "Horticulture I (2012)",
      "Agricultural Mechanics II (2005)",
      "Agricultural Mechanics I (2004)",
      "ESL (WIDA STANDARDS)",
      "Animal Science I",
      "Criminal Justice 1",
      "Mathematics (2018)",
      "English Language Arts (2018)",
      "Math NCSOS (2017)",
      "Physics",
      "Earth/Environmental Science",
      "Physical Science",
      "Chemistry",
      "World History",
      "Leadership",
      "American History I: The Founding Principles",
      "American History II",
      "English Language Profiency Standards ",
      "Family and Consumer Sciences Education",
      "4th Level Math Standards (2019)",
    ],
  },
  {
    title: "North Dakota",
    subjects: [
      "Science",
      "Health",
      "Music",
      "Mathematics",
      "Social Studies",
      "Physical Education (2008)",
      "Dance",
      "Visual Arts",
      "English Language Arts & Literacy",
      "Physical Education",
      "Library & Technology",
      "Drama",
      "Foreign Language",
      "Business Education Standards",
      "Mathematics (2017)",
      "English Language Arts and Literacy (2017)",
    ],
  },
  { title: "Nova Scotia", subjects: [] },
  {
    title: "Ohio",
    subjects: [
      "English Language",
      "Early Childhood",
      "Math",
      "Catholic Theology",
      "Science",
      "Social Studies",
      "Technology",
      "Fine Arts - Drama/Theater",
      "Fine Arts - Visual Arts",
      "Mathematics",
      "Physical Education",
      "Fine Arts - Dance",
      "Fine Arts - Music",
      "World Languages",
      "Library Instructional Guidelines",
      "Physical Education (2015)",
      "Science (2011)",
      "Special Education",
      "Financial Literacy (2016)",
      "Mathematics (2017)",
      "Health",
      "History/Social Studies, Science, and Technical Subjects (2017)",
      "Technology (2017)",
      "English Language Arts (2017)",
      "Business Education",
      "Social-Emotional Learning",
      "Grades 2-3 English Language Proficiency Standards",
    ],
  },
  {
    title: "Oklahoma",
    subjects: [
      "The Arts",
      "Mathematics",
      "Science (2011)",
      "Social Studies",
      "Health, Safety and Physical Education",
      "Visual Arts",
      "Music",
      "Science",
      "English",
      "Technology Education",
      "Pre-Kindergarten C3 Standards (PASS)",
      "Kindergarten C3 Standards (PASS)",
      "Personal Financial Literacy",
      "Instructional Technology (PASS)",
      "ELA",
      "Gas Tungsten Arc Welding (2001)",
      "Physical Education (2016)",
      "Environmental Science and Natural Resources (2014)",
      "Health (2016)",
      "Marriage and Family Life (2014)",
      "Beverage Management (2016)",
      "Touring Oklahoma (2014)",
      "Wind Energy: Wind Turbine Technician (2012)",
      "English Language Arts (2016)",
      "Introduction to Horticulture (2014)",
      "Advertising and Design (2011)",
      "Oxyacetylene Welding (2002)",
      "Introduction to Agriscience (2014)",
      "Personal Financial Literacy (2014)",
      "Advanced Pastry Arts (2016)",
      "Press Operations, Binding and Finishing (2013)",
      "Gas Metal Arc Welding (2001)",
      "Agriscience Principles and Applications (2014)",
      "Residential HVAC System Design (2016)",
      "Practical Nursing (2012)",
      "Culinary Concepts (2016)",
      "Animal Science (2014)",
      "Introduction to Plant and Soil Science (2014)",
      "Mathematics (2016)",
      "Hydraulics (2012)",
      "Orientation to Graphic Communications (2012)",
      "Culinary Techniques (2016)",
      "Visual Arts (2017)",
      "World Languages",
      "Computer Science",
      "Social Studies (2019)",
      "Personal Financial Literacy (2019)",
    ],
  },
  {
    title: "Ontario",
    subjects: [
      "Mathematics (2005)",
      "Science and Technology (2007)",
      "Mathematics (2007)",
      "Mathematics Transfer Applied to Academic (2006)",
      "Science (2008)",
      "Health and Physical Education (2015)",
      "Social Sciences and Humanities (2013)",
      "Computer Studies (2008)",
      "Language (2006)",
      "Social Studies, History, & Geography (2013)",
      "English (2007)",
      "The Arts (2010)",
      "The Arts (2009)",
      "Interdisciplinary Studies (2002)",
      "English (EOSSLC) (2003)",
      "Technological Education (2009)",
      "Guidance and Career Education (2006)",
      "Business Studies (2006)",
      "Native Studies (1999)",
      "Canadian and World Studies (2013)",
      "Native Languages (2001)",
      "Native Languages (1999)",
      "Classical and International Languages (1999)",
      "Native Studies (2000)",
      "Canadian and World Studies (2015)",
      "English as a Second Language and English Literacy Developmente: Grades 9-12 (2007)",
      "Full-Day Early Learning–Kindergarten (2011)",
      "Classical and International Languages (2000)",
      "Native Languages (2000)",
      "French as a Second Language (2013)",
      "French as a Second Language (2014)",
      "Kindergarten Program (2016)",
      "Language",
      "Mathématiques",
      "Sciences et technologie",
      null,
      "Cooperative Education - Grades 11 & 12 (2018)",
      "English, 9e et 10e année (2007)",
      "Canadian and World Studies: Grades 9 & 10 (2018)",
      "Social Studies, Grades 1-6; History and Geography, Grades 7-8 (2018)",
      "English, 11e et 12e année (2007)",
      "Career Studies Grade 10 Open (GLC20) (2019)",
      "The Ontario Curriculum: First Nations, Métis, and Inuit Cultures (Grades 9-12) (2019)",
    ],
  },
  {
    title: "Oregon",
    subjects: [
      "Common Core English Language Arts & Literacy",
      "Common Core Math",
      "Social Sciences",
      "Science",
      "Arts",
      "Physical Education Content Standards",
      "Health Education",
      "Science (NGSS)",
      "Dance (2015)",
      "Theatre (2015)",
      "Visual Arts (2015)",
      "Music PreK-8 (2015)",
      "Media Arts (2015)",
      "Music Technology (2015)",
      "Educational Technology",
      "Harmonizing Instruments (2015)",
      "School Library Standards",
      "Traditional and Emerging Ensembles (2015)",
      "Music Composition and Theory (2015)",
      "Physical Education (2016)",
      "Standards for Literacy in History/Social Studies & Technical Subjects (2019)",
      "English Language Arts & Literacy Standards (2019)",
    ],
  },
  {
    title: "Pennsylvania",
    subjects: [
      "Music",
      "Science and Technology and Engineering Education",
      "Science, Technology, & Engineering",
      "Reading, Writing, Speaking, & Listening",
      "Mathematics",
      "Environment and Ecology",
      "Arts & Humanities",
      "Civics and Government",
      "Economics",
      "History",
      "Health, Safety, & Physical Education",
      "Geography",
      "Personal Finance Model Curriculum",
      "PA Core - Writing for History and Social Studies",
      "PA Core - English Language Arts",
      "PA Core - Reading for History And Social Studies",
      "PA Core - Mathematics",
      "Career Education and Work",
      "Family & Consumer Sciences",
      "PA Core - Reading for Science and Technical Subjects",
      "Business, Computer and Information Technology",
      "PA Core - Writing for Science and Technical Subjects",
      "Career and Technical",
      "World Languages (2002)",
      "Keystone Exams: Biology",
      "Keystone Exams: Keystone Literature Assessment Anchors and Eligible Content",
      "Science",
      "Keystone Exams: Algebra I",
    ],
  },
  {
    title: "Rhode Island",
    subjects: [
      "Earth and Space Science",
      "Physical Science",
      "Civics & Government and Historical Perspectives/Rhode Island History",
      "Arts - Theater",
      "Arts - Music",
      "Engineering and Technology",
      "Common Core Math",
      "Life Science",
      "Arts - Dance",
      "Arts - Visual Arts & Design",
      "Common Core English Language Arts/Literacy",
      "Health Education",
      "Economics",
      "Geography",
      "Science",
      "Physical Education",
      "CCSS ELA-Literacy: History/Social Studies (Grades 9-10)",
      "CCSS ELA-Literacy: History/Social Studies (Grades 11-12)",
      "Essential Elements- Math",
    ],
  },
  {
    title: "Saskatchewan",
    subjects: [
      "English Language Arts",
      "Mathematics",
      "Art",
      "Health",
      "Science",
      "Social Studies ",
    ],
  },
  {
    title: "South Carolina",
    subjects: [
      "Mathematics (2007)",
      "Science (2005)",
      "English/Language Arts (2008)",
      "Social Studies",
      "Visual and Performing Arts - Visual Arts",
      "Visual and Performing Arts - Theater",
      "Visual and Performing Arts - Dance",
      "Visual and Performing Arts - Media Arts",
      "Common Core Math",
      "Visual and Performing Arts - Choral Music",
      "Visual and Performing Arts - Instrumental Music",
      "Health & Safety Education",
      "Web Page Design and Development 1",
      "Child Development I",
      "Sciene",
      "Common Core English/Language Arts",
      "Emergency Medical Services (EMS)",
      "Entrepreneurship",
      "Accounting 1",
      "Mathematics",
      "English Language Arts",
      "Professional Development",
      "Computer Applications",
      "Digital Desktop Publishing",
      "Medical Terminology",
      "Physical Education",
      "Keyboarding",
      "Family Life Education I",
      "Health Science 1",
      "Human Development",
      "Integrated Business Applications 1",
      "Personal Finance",
      "World Language",
      "Architectural Design 1 & 2",
      "Parenting Education 1",
      "Science-2014",
      "Computer Programming I",
      "Workplace Communications fka Essential Communications",
      "Firefighter 1 (2014)",
      "Secondary Cosmetology (2015)",
      "Firefighter II (2014)",
      "Carpentry",
      "Health Science 2",
      "Visual and Performing Arts: Design (2017)",
      "Visual and Performing Arts: Visual Arts (2017)",
      "Visual and Performing Arts: Media Arts (2017)",
      "Visual and Performing Arts: Theatre (2017)",
      "Visual and Performing Arts: Dance (2017)",
      "Visual and Performing Arts: Choral Music (2017)",
      "Computer Science and Digital Literacy Standards (2017)",
      "Visual and Performing Arts: Instrumental Music (2017)",
      "Visual and Performing Arts: General Music (2017)",
      "Health and Safety Education (2017)",
      "Law Enforcement Services",
      "Image Editing I",
      "Sports Medicine 1",
      "Fundamentals of Computing",
    ],
  },
  {
    title: "South Dakota",
    subjects: [
      "Arts, A/V Technology and Communications",
      "Social Studies",
      "Science (2005)",
      "Fine Arts",
      "Health Education",
      "Common Core Math",
      "Common Core English/Language Arts",
      "Technology",
      "Educational Technology Standards",
      "Science",
      "Physical Education",
      "Fine Arts: Dance",
      "World Language",
      "Fine Arts: Visual Arts",
      "Fine Arts: Theatre Arts",
      "Fine Arts: Media Arts",
      "Fine Arts: Music",
      "Library",
      "Finance",
      "Foundations of Technology (formerly Computer Applications)",
      "Broadcast Technology",
    ],
  },
  {
    title: "Tennessee",
    subjects: [
      "Science",
      "Mathematics",
      "Physical Education",
      "Statistics",
      "Precalculus",
      "Health and Wellness",
      "English Language Arts & Literacy",
      "Competency English",
      "Social Studies",
      "Economics",
      "African American History",
      "Sociology",
      "Creative Writing",
      "Journalism",
      "Calculus",
      "Advanced Algebra and Trigonometry",
      "Finite Math",
      "Bridge Math",
      "Discrete Match",
      "Ancient History",
      "Contemporary Issues",
      "Psychology",
      "Content Area Reading",
      "United States Government and Civics",
      "History and Geography",
      "World Geography",
      "World History and Geography",
      "Speech",
      "Computer Applications",
      "Computer Literacy",
      "Keyboarding",
      "Lifetime Wellness",
      "Personal Finance",
      "Computer Technology",
      "Music",
      "Visual Art",
      "Theatre",
      "Visual Art History",
      "Visual Arts",
      "Dance",
      "Interactive Multimedia Design",
      "Music History",
      "Computer Programming I",
      "Computer Progamming II",
      "Music Theory",
      "Piano",
      "Guitar",
      "Developmental Standards",
      "Creative Arts PreK Music/Art",
      "Business",
      "AgriScience 5957",
      "Agriculture",
      "Science (2016)",
      "Chemistry I (2016)",
      "Biology I (2016)",
      "Environmental Science (2016)",
      "Chemistry II (2016)",
      "Earth and Space Science (2016)",
      "Geology (2016)",
      "Biology II (2016)",
      "Ecology (2016)",
      "Human Anatomy and Physiology (2016)",
      "Physical Science (2016)",
      "Physical Education (2016)",
      "English Language Arts (2016)",
      "Mathematics (2016)",
      "Bible",
      "World Languages (Modern)",
      "Vocal Music",
      "Social Studies (2019)",
      "CTE: Human Services",
    ],
  },
  {
    title: "Texas",
    subjects: [
      "Mathematics, Amended 2006",
      "Technology Applications",
      "English Language Arts and Reading",
      "Science",
      "Mathematics",
      "Health Education",
      "Career Development",
      "Social Studies",
      "Physical Education",
      "Economics",
      "Fine Arts",
      "Languages Other Than English",
      "Principles of Agriculture, Food, and Natural Resources",
      "Business Management and Administration",
      "Education and Training",
      "Mathematics: Algebraic Reasoning",
      "Marketing",
      "Manufacturing",
      "Architecture and Construction",
      "College and Career Readiness",
      "Mathematics: Statistics",
      "Mathematics: Discrete Mathematics",
      "Finance",
      "Human Services",
      "Arts, Audio/Video Technology, and Communications",
      "Government and Public Administration",
      "Information Technology",
      "Health Science",
      "Science, Technology, Engineering, and Mathematics",
      "Hospitality and Tourism",
      "Texas Essential Knowledge and Skills for Languages Other Than English",
      "Transportation, Distribution, and Logistics",
      "Law, Public Safety, Corrections, and Security",
      "Graphic Design and Illustration",
      "Animation",
      "Advanced Animation",
      "Personal Financial Literacy (2016)",
      "Spanish Language Arts",
      "CIRCLE TIME",
      "Professional Communications",
      "English Language Development and Acquisition (ELDA) (2017)",
      "Health Education (2017)",
      "English Language Arts and Reading - English III (2017)",
      "English Language Arts and Reading - English IV (2017)",
      "English Language Arts and Reading (2017)",
      "English Language Arts and Reading - English I (2017)",
      "English Language Arts and Reading - English II (2017)",
      "English II for English as a Second Language (2017)",
      "English I for English as a Second Language (2017)",
      "Methodology of Academic and Personal Success",
      "Career and Technical Education",
      "Medical Microbiology",
      "English Language Arts & Reading -- English II (New TEKS 2020-21)",
      "English Language Arts and Reading (2009)",
      "English I (2009)",
      "English II (2009)",
      "English III (2009)",
      "English IV (2009)",
    ],
  },
  {
    title: "Utah",
    subjects: [
      "Social Studies",
      "Educational Technology",
      "Keyboarding",
      "Fine Arts - Visual Arts",
      "Fine Arts - Theater",
      "Health",
      "Fine Arts - Dance",
      "Mathematics",
      "Physical Education",
      "English Language Arts",
      "Science with Engineering Education (SEEd) (2017)",
      "Fine Arts - Music",
      "Economic & Entrepreneurship",
      "Secondary Library Media",
      "Science",
      "Elementary Library Media (2015)",
      "Earth Science",
      "Career & Technical Education",
      "Mathematics (6-8) (2016)",
      "Mathematics (K-5) (2016)",
      "Mathematics (9-12) (2016)",
      "Physical Education (2016)",
      "General Financial Literacy (2015)",
      "Music",
      "Computer Technology",
      "World Geography (2016)",
      "U.S. Government & Citizenship (2016)",
      "United States History I (2016)",
      "Utah Studies (2016)",
      "United States History II (2016)",
      "World History (2016)",
      "Fine Arts K-6 (2016)",
      "Fine Arts 7-8 (2016)",
    ],
  },
  {
    title: "Vermont",
    subjects: [
      "Standards & Learning Opportunities",
      "Common Core Math",
      "English Language Arts",
      "Technology",
      "Science",
    ],
  },
  {
    title: "Virginia",
    subjects: [
      "History & Social Studies",
      "English",
      "Physical Education",
      "Health",
      "Physical Education (2015)",
      "Health (2015)",
      "History and Social Science (2015)",
      "Science",
      "Computer Technology",
      "Music",
      "Mathematics",
      "Culinary Arts II",
      "Driver Education",
      "Culinary Arts Specialization",
      "Dance",
      "Visual Art",
      "Theater",
      "Culinary Arts I",
      "Economics and Personal Finance",
      "French II",
      "French III",
      "Latin",
      "French I",
      "German 4",
      "German 3",
      "German 2",
      "German 1",
      "Teachers for Tomorrow",
      "Trigonometry (2016)",
      "Probability & Statistics (2016)",
      "Mathematical Analysis (2016)",
      "Mathematics (2016)",
      "Computer Mathematics (2016)",
      "Discrete Mathematics (2016)",
      "Algebra I (2016)",
      "Algebra, Functions, & Data Analysis (2016)",
      "Algebra II (2016)",
      "Geometry (2016)",
      "Computer Applications 6611",
      "Digital Input Technologies 6160",
      "French IV",
      "Algebra I (2009)",
      "Computer Science Programming (2017)",
      "Computer Science Principles (2017)",
      "Computer Science Foundations (2017)",
      "Computer Science Standards Middle School Elective (2017)",
      "Family Life Education (2016)",
      "Computer Science (K-8) (2017)",
      "English (2017)",
      "Computer Science SOL (Grade 8)",
      "Computer Science SOL (Grade 7)",
      "Computer Science SOL (Grade 6)",
      "ADAPTED PHYSICAL EDUCATION",
      "Cybersecurity",
      "Information Technology",
      "Nursing",
    ],
  },
  {
    title: "Washington",
    subjects: [
      "Mathematics",
      "Science",
      "Dance",
      "Social Studies",
      "Common Core Math",
      "Theater",
      "Health & Fitness",
      "Common Core English/Language Arts",
      "Music",
      "Visual Arts",
      "Educational Technology",
      "World Languages",
      "Physical Education Learning Standards (2016)",
      "Health Learning Standards (2016)",
      "CTE/STEM",
      "CTE - Agriculture",
      "Wa Kids",
      "Computer Science (2016)",
      "Financial Education (2016)",
    ],
  },
  {
    title: "West Virginia",
    subjects: [
      "Science",
      "Music",
      "Visual Arts",
      "Social Studies",
      "Physical Education",
      "Health Education",
      "Enlish Language Arts",
      "Learning Skills and Technology Tools",
      "Early Learning",
      "Mathematics",
      "Mathematics (2014)",
      "Wellness",
      "Theater",
      "Dance",
      "Library Media",
      "English Language Arts (2016)",
      "Mathematics (2016)",
      "Career and Technical Education (2015)",
      "Next Generation Health Education 5-12 (2015)",
      "Social Studies (2016)",
      "Foreign Language (2007)",
      "Career and Technical Education (2017)",
      "Computer Science - Introduction to Geographic Information Systems (2017)",
      "Discovering Computer Science (2017)",
      "Computer Science in the Modern World (2017)",
      "Computer Science & Mathematics (2017)",
      "Technology and Computer Science (2017)",
    ],
  },
  {
    title: "Wisconsin",
    subjects: [
      "Technology Education",
      "Nutrition Education",
      "English Language Arts",
      "Environmental Education",
      "Physical Education",
      "Business",
      "Science",
      "Dance",
      "Theater",
      "Foreign Languages",
      "Health Education",
      "Art & Design",
      "Music",
      "Common Core Math",
      "Career & Technical Standards",
      "Health Science",
      "Personal Financial Literacy",
      "Information and Technology Literacy",
      "Social Studies",
      "Early Learning",
      "Music (2017)",
      "Standards for Science (2017)",
      "Social Studies (2018)",
      "Black and Latino Male Achievement",
    ],
  },
  {
    title: "Wyoming",
    subjects: [
      "Language Arts Content",
      "Health Education Content",
      "Mathematics Content",
      "Science Content",
      "Social Studies Content",
      "Physical Education Content",
      "Career and Vocational Education Content Standards (2014)",
      "Career/Vocational Education Content (2008)",
      "Fine and Performing Arts Content",
      "Foreign Languages Content",
      "Technology",
      "Science (2016)",
    ],
  },
]
