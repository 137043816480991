import React, { Component } from "react"
import "./postCreate.scss"
import { bindActionCreators } from "redux"
import { getData } from "../../../../library/store/actions/creators/authCreators"
import { getPerson } from "../../../../library/store/actions/creators/personCreators"
import {
  clearPostsList,
  createPost,
  getPostsList,
  updatePostsList,
} from "../../../../library/store/actions/creators/postsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import avatarDefault from "../../../../assets/img/user_avatar.svg"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import { generatePath, Link, withRouter } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import UserAvatar from "../../../../components/userAvatar/userAvatar"
import Button from "@material-ui/core/Button/Button"
import AttachFileOutlined from "@material-ui/icons/AttachFileOutlined"
import PhotoOutlined from "@material-ui/icons/PhotoOutlined"
import FileLoader from "../../../../components/formComponents/fileLoader"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { getGroupLogo } from "../../../../library/utils/getGroupLogo"
import getErrorText from "../../../../library/constants/errorTypes"
import styled from "@emotion/styled"
import { colors } from "../../../../library/constants/styles/colors"
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener"
import { devices } from "../../../../library/constants/styles/responsive"

const DropdownContainer = styled.div`
  position: relative;
`

const DropdownBtn = styled.button`
  display: flex;
  color: ${colors.black38};
`

const DropdownContentWrapper = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  left: -10px;
  z-index: 1;
`

const DropdownContent = styled.div`
  position: relative;
  min-width: 160px;
  padding: 15px;
  background-color: ${colors.white};
  box-shadow: 0 0 2px 0 rgba(210, 210, 210, 0.5);
  border-radius: 2px;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -6px;
    left: 14px;
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
    border-radius: 1px;
    background-color: ${colors.white};
    box-shadow: 0 0 2px 0 rgba(210, 210, 210, 0.5);

    @media ${devices.xs} {
      right: 12px;
    }
  }
`

class PostCreate extends Component {
  state = {
    userInfo: this.props.userInfo ? this.props.userInfo : undefined,
    groupInfo: this.props.groupInfo ? this.props.groupInfo : undefined,
    newPost: "",
    blobs: [],
    images: [],
    videos: [],
    attachments: [],
    newPostActive: false,
    personalPost: true,
    dropdownOpen: false,
  }

  handleCheckboxChange = () => {
    this.setState({ personalPost: !this.state.personalPost })
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }

  onUploadProgress = (progressEvent) => {
    let percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    )
    this.setState({ uploadProgress: percentCompleted })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.type === "group") {
      if (
        this.props.groups.currentGroup.logo !==
        prevProps.groups.currentGroup.logo
      ) {
        this.setState({ groupInfo: this.props.groups.currentGroup })
      }
    }
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    })
  }

  toggleNewPostForm = () => {
    this.setState({ newPostActive: !this.state.newPostActive })
  }

  setAttachments = ({ videos, blobs, images }) => {
    this.setStateAsync({
      videos,
      blobs,
      images,
    })
  }

  processPostCreation = async () => {
    const { type } = this.props
    const { id } = this.props.auth.userData
    const { userInfo, groupInfo, newPost, blobs, images, videos } = this.state
    try {
      switch (type) {
        case "feed":
          if (
            newPost.replace(/\s/g, "") !== "" ||
            [...blobs, ...images, ...videos].length
          ) {
            await this.props.createPost({
              user_id: id,
              message: newPost,
              attachments: [...blobs, ...images, ...videos].map((a) => a.id),
            })
          } else {
            await this.props.createPost({
              user_id: id,
              attachments: [...blobs, ...images, ...videos].map((a) => a.id),
            })
          }
          await this.props.updatePostsList({
            type: "feed",
            offset: 0,
            limit: this.props.posts.feedList.length + 1,
          })
          break
        case "my-profile":
          if (
            newPost.replace(/\s/g, "") !== "" ||
            [...blobs, ...images, ...videos].length
          ) {
            await this.props.createPost({
              user_id: id,
              message: newPost,
              attachments: [...blobs, ...images, ...videos].map((a) => a.id),
            })
          } else {
            await this.props.createPost({
              user_id: id,
              attachments: [...blobs, ...images, ...videos].map((a) => a.id),
            })
          }
          await this.props.updatePostsList({
            type: "my",
            user_id: id,
            offset: 0,
            limit: this.props.posts.myPostsList.length + 1,
          })
          break

        case "other-profile":
          if (
            newPost.replace(/\s/g, "") !== "" ||
            [...blobs, ...images, ...videos].length
          ) {
            await this.props.createPost({
              user_id: userInfo.id,
              message: newPost,
              attachments: [...blobs, ...images, ...videos].map((a) => a.id),
            })
          } else {
            await this.props.createPost({
              user_id: userInfo.id,
              attachments: [...blobs, ...images, ...videos].map((a) => a.id),
            })
          }
          await this.props.updatePostsList({
            user_id: userInfo.id,
            offset: 0,
            limit: this.props.posts.postsList.length + 1,
          })
          break

        case "group":
          if (
            newPost.replace(/\s/g, "") !== "" ||
            [...blobs, ...images, ...videos].length
          ) {
            await this.props.createPost({
              group_id: groupInfo.id,
              message: newPost,
              personal: this.state.personalPost || undefined,
              attachments: [...blobs, ...images, ...videos].map((a) => a.id),
            })
          } else {
            await this.props.createPost({
              personal: this.state.personalPost || undefined,
              group_id: groupInfo.id,
              attachments: [...blobs, ...images, ...videos].map((a) => a.id),
            })
          }
          await this.props.updatePostsList({
            group_id: groupInfo.id,
            offset: 0,
            limit: this.props.posts.postsList.length + 1,
          })
          break

        default:
          return
      }
      await this.setStateAsync({
        newPost: "",
        blobs: [],
        blobsInfo: [],
        images: [],
        imagesInfo: [],
        videos: [],
        videosInfo: [],
        inputFilesCount: 0,
        newPostActive: false,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  isRenderField = () => {
    const { type } = this.props
    const { userInfo } = this.state

    switch (type) {
      case "feed":
      case "my-profile":
        return true

      case "other-profile":
        return !!userInfo.is_friend

      case "group":
        return !!this.props.isMember

      default:
        return
    }
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({ dropdownOpen: !prevState.dropdownOpen }))
  }

  handleClickOutside = (e) => {
    if (this.state.dropdownOpen) this.setState({ dropdownOpen: false })
  }

  render() {
    const {
      groupInfo,
      newPost,
      blobs,
      images,
      videos,
      newPostActive,
      personalPost,
    } = this.state
    const {
      auth: { userData },
      type,
      createdByGroup,
    } = this.props

    const creatorAvatar =
      type === "group"
        ? getGroupLogo(groupInfo)
        : userData.photo
          ? userData.photo
          : avatarDefault

    const creatorUrl =
      type === "group"
        ? generatePath(routes.GROUP, {
            groupId: this.props.match.params.groupId,
          })
        : generatePath(routes.USER, { userId: this.props.auth.userData.id })

    const canSubmit = !!(
      newPost.replace(/\s/g, "") !== "" ||
      blobs.length > 0 ||
      images.length > 0 ||
      videos.length > 0
    )

    if (this.isRenderField()) {
      return (
        <div className="post-create box">
          <ValidatorForm onSubmit={this.processPostCreation}>
            <div className={`f aic ${newPostActive ? "mb15" : ""}`}>
              <Link to={creatorUrl} className="asfs">
                {!createdByGroup || personalPost ? (
                  <UserAvatar
                    className="post-create__avatar"
                    user={this.props.auth.userData}
                  />
                ) : (
                  <div className="post-create__avatar">
                    <img
                      src={creatorAvatar}
                      alt="Logo"
                      className="user-avatar"
                    />
                  </div>
                )}
              </Link>

              {createdByGroup && (
                <ClickAwayListener onClickAway={this.handleClickOutside}>
                  <DropdownContainer>
                    <DropdownBtn type="button" onClick={this.toggleDropdown}>
                      {this.state.dropdownOpen ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </DropdownBtn>
                    {this.state.dropdownOpen && (
                      <DropdownContentWrapper>
                        <DropdownContent>
                          <FormControlLabel
                            control={
                              <Checkbox
                                autoFocus
                                checked={!personalPost}
                                onChange={this.handleCheckboxChange}
                                value={"Post as group"}
                              />
                            }
                            label="Post as group"
                          />
                        </DropdownContent>
                      </DropdownContentWrapper>
                    )}
                  </DropdownContainer>
                </ClickAwayListener>
              )}

              {!newPostActive ? (
                <div
                  className="post-create__placeholder"
                  onClick={() => this.toggleNewPostForm()}
                >
                  What’s happening?
                </div>
              ) : (
                <div className="full-width">
                  <TextValidator
                    name="createPost"
                    className="post-create__input"
                    placeholder="What’s happening?"
                    multiline
                    autoFocus
                    fullWidth
                    value={newPost}
                    validators={["maxStringLength:5000"]}
                    errorMessages={["Max length is 5000 characters"]}
                    onChange={this.handleChange("newPost")}
                    margin="none"
                  />
                </div>
              )}
              <div className="post-create__add-attachment">
                <label
                  htmlFor="img-input"
                  onClick={() =>
                    !newPostActive ? this.toggleNewPostForm() : undefined
                  }
                >
                  <PhotoOutlined className="post-create__btn-icon" />
                </label>
              </div>
              <div className="post-create__add-attachment">
                <label
                  htmlFor="attachments-input"
                  onClick={() =>
                    !newPostActive ? this.toggleNewPostForm() : undefined
                  }
                >
                  <AttachFileOutlined className="post-create__btn-icon" />
                </label>
              </div>
            </div>

            {newPostActive && (
              <div className="f jcfs aifs full-width">
                <div className="post-create__images">
                  <FileLoader
                    videos={videos}
                    images={images}
                    blobs={blobs}
                    onUpload={this.setAttachments}
                    token={userData.token}
                  />
                </div>
              </div>
            )}

            {newPostActive ? (
              <div className="f jcfe aic full-width">
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  color="primary"
                  disabled={!canSubmit}
                >
                  Post
                </Button>
              </div>
            ) : null}
          </ValidatorForm>
        </div>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = ({ auth, person, groups, posts }) => ({
  auth,
  person,
  groups,
  posts,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getData,
      getPerson,
      createPost,
      getPostsList,
      clearPostsList,
      updatePostsList,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(PostCreate)))
