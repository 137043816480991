import React, { Component } from "react"
import { bindActionCreators } from "redux"
import {
  clearCalendarsList,
  clearSchedulesList,
  getCalendarsList,
  getSchedulesList,
} from "../../../../../library/store/actions/creators/calendarCreators"
import { getGroup } from "../../../../../library/store/actions/creators/groupsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { generatePath, Link, withRouter } from "react-router-dom"
import withScroll from "../../../../../hocs/withScroll/withScroll"
import * as qs from "query-string"
import getErrorText from "../../../../../library/constants/errorTypes"
import { LIMIT } from "../../../../../library/constants/limits"
import * as routes from "../../../../../library/constants/routes"
import GroupSettingsCalendarProvider from "../groupSettings/groupSettingsCalendar/GroupSettingsCalendarContext"
import CalendarsListItem from "../groupSettings/groupSettingsCalendar/components/calendarsListItem"
import Loader from "../../../../../components/ui/loader"
import ListSearchHeader from "../../../../../components/ui/listSearchHeader/listSearchHeader"
import { getGroupLogo } from "../../../../../library/utils/getGroupLogo"

class GroupCalendars extends Component {
  state = {
    activeCalendar: null,
    offset: 0,
    dataReceived: false,
  }

  getList = async ({ offset = 0 }) => {
    const {
      location: { search },
      groups: { currentGroup },
    } = this.props
    const { q } = qs.parse(search)

    try {
      this.setState({ dataReceived: false })
      if (currentGroup.type === 4) {
        await this.props.getSchedulesList({
          school_id: parseInt(this.props.match.params.groupId),
          name: q || undefined,
          offset,
        })
      } else {
        await this.props.getCalendarsList({
          district_id: parseInt(this.props.match.params.groupId),
          name: q || undefined,
          not_deleted_only: true,
          offset,
        })
      }
      this.setState({ dataReceived: true, offset })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  async componentDidMount() {
    await this.props.getGroup({ id: parseInt(this.props.match.params.groupId) })

    if (
      this.props.groups.currentGroup &&
      (this.props.groups.currentGroup.deleted ||
        (this.props.groups.currentGroup.type !== 4 &&
          this.props.groups.currentGroup.type !== 5))
    ) {
      return this.props.history.replace(
        generatePath(routes.GROUP, { groupId: this.props.match.params.groupId })
      )
    }

    await this.getList({})
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      location: { search },
      match: { path },
    } = this.props

    if (search && search !== prevProps.location.search) {
      if (this.props.groups.currentGroup.type === 4) {
        this.props.clearSchedulesList()
      } else {
        this.props.clearCalendarsList()
      }

      await this.getList({})
    }
  }

  componentWillUnmount() {
    if (this.props.groups.currentGroup.type === 4) {
      this.props.clearSchedulesList()
    } else {
      this.props.clearCalendarsList()
    }
  }

  onScroll = async () => {
    const listEndReached =
      this.props.groups.currentGroup.type === 4
        ? this.props.calendar.schedulesListEndReached
        : this.props.calendar.listEndReached

    if (!this.state.dataReceived || listEndReached) return

    await this.getList({ offset: this.state.offset + LIMIT })
  }

  searchCalendars = (name) => {
    const { history } = this.props

    history.push({
      search: `?q=${name}`,
    })
  }

  render() {
    const { dataReceived } = this.state
    const {
      groups: { currentGroup },
    } = this.props
    const { q: query } = qs.parse(this.props.location.search)

    const list =
      currentGroup !== null
        ? currentGroup.type === 4
          ? this.props.calendar.schedulesList
          : this.props.calendar.list
        : []

    const noResultsLabel = `This ${
      currentGroup !== null && currentGroup.type === 4 ? "school" : "district"
    } doesn't have Calendars yet`

    return (
      <div className="common-page__content">
        <main className="common-page__main">
          <ListSearchHeader
            title={"Calendars"}
            placeholder={"Search Calendars"}
            search={this.searchCalendars}
            noResults={dataReceived && list && !list.length}
            noResultsLabel={query ? "" : noResultsLabel}
          />

          {list.map((item) => (
            <GroupSettingsCalendarProvider key={item.id}>
              {({ onCalendarDialogOpen }) => (
                <CalendarsListItem
                  calendar={item}
                  openCalendarDetails={onCalendarDialogOpen}
                  publicView
                />
              )}
            </GroupSettingsCalendarProvider>
          ))}

          {!this.state.dataReceived && <Loader />}
        </main>

        <aside className="common-page__sidebar">
          {currentGroup !== null && (
            <div className="box aside-header">
              <div className="box__content aside-header__content">
                <img
                  src={getGroupLogo(currentGroup)}
                  className="aside-header__img"
                  alt=""
                />
                <div className="aside-header__info">
                  <Link
                    to={generatePath(routes.GROUP, {
                      groupId: this.props.match.params.groupId,
                    })}
                    className="aside-header__name"
                  >
                    {currentGroup.name}
                  </Link>
                  <div className="aside-header__help">back to page</div>
                </div>
              </div>
            </div>
          )}
        </aside>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, groups, calendar }) => ({
  auth,
  groups,
  calendar,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getGroup,
      getCalendarsList,
      clearCalendarsList,
      getSchedulesList,
      clearSchedulesList,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(withScroll(GroupCalendars))))
