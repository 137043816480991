import React from "react"

export const composeUserHighlightedWork = (work) => {
  return work ? (
    <span>
      {work.role ? work.role : ""}
      {work.role && work.name ? " — " : ""}
      {[work.name, work.city, work.country].filter(Boolean).join(", ")}
    </span>
  ) : null
}

export const composeUserHighlightedEducation = (education) => {
  return education ? (
    <span>
      {[education.name, education.city, education.country]
        .filter(Boolean)
        .join(", ")}
    </span>
  ) : null
}
