import FavouriteOutlined from "@material-ui/icons/FavoriteOutlined"
import FavouriteBorderedOutlined from "@material-ui/icons/FavoriteBorderOutlined"
import ReplyOutlined from "@material-ui/icons/ReplyOutlined"
import postDateConvert from "../../../../../library/utils/postDateConvert"
import React from "react"
import { useCommentCtx } from "../CommentContext"

const CommentFooter = () => {
  const { comment, onLike, onReplyFormOpen } = useCommentCtx()

  return (
    <div className="post-comment__footer">
      <div className="post-comment__social-control" onClick={onLike}>
        {comment.is_liked ? (
          <FavouriteOutlined className="color-primary" />
        ) : (
          <FavouriteBorderedOutlined />
        )}
        {!!comment.likes_number && <span>{comment.likes_number}</span>}
      </div>
      <button className="btn btn--plain" onClick={onReplyFormOpen}>
        <ReplyOutlined />
        Reply
      </button>
      <div className="post-comment__date">
        {postDateConvert(comment.created)}
      </div>
    </div>
  )
}

export default CommentFooter
