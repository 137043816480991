import React from "react"
import classes from "./aboutUsPage.module.scss"
import appStore from "../../../assets/img/pages/about-us/appSrtore.png"
import playMarket from "../../../assets/img/pages/about-us/playmarket.png"
import learner from "../../../assets/img/pages/about-us/learner.svg"
import educator from "../../../assets/img/pages/about-us/educator.svg"
import company from "../../../assets/img/pages/about-us/company.png"
import social from "../../../assets/img/pages/about-us/social-part.jpeg"
import invenstory from "../../../assets/img/pages/about-us/invenstory.jpeg"
import standards from "../../../assets/img/pages/about-us/standards.jpg"
import marketplace from "../../../assets/img/pages/about-us/marketplace.jpg"
import calendar from "../../../assets/img/pages/about-us/calendar.jpg"
import knowmix from "../../../assets/img/pages/about-us/knowmix.jpg"
import knowcred from "../../../assets/img/pages/about-us/knowcred.jpg"

const AboutUs = () => {
  return (
    <div className={classes.page}>
      <div>
        <div className={classes.header}>
          <div className={classes.header__content}>
            <div className="f fdc aic jcc full-width">
              <h2 className={classes.header__heading}>Get KnowStory</h2>
              <div className={classes.btnGroup}>
                <a href="https://play.google.com/store/apps/details?id=com.ks_app">
                  <img src={playMarket} alt="Get it on Google Play" />
                </a>
                <a href="https://apps.apple.com/ua/app/knowstory/id1537940521?l=uk">
                  <img src={appStore} alt="Available on the App Store" />
                </a>
              </div>
            </div>
          </div>
        </div>

        {/*KnowStory social ecosystem */}
        <section className={classes.section}>
          <div className="bootstrap container">
            <div className="full-width">
              <h3 className={classes.section__heading}>
                KnowStory social ecosystem
              </h3>
              <div className="f jcc full-width">
                <p className={classes.section__text}>
                  The Knowstory social media site already has specialized
                  functions just for education institutions, curriculum and tech
                  companies, students, and teachers. It is now working on a
                  demonstration component to transform school logistics to
                  hybrid learning. Knowstory is currently a fully free site for
                  all educators, students, and companies. It will soon have paid
                  services to create a long-term sustainable model not fully
                  dependent on the goodwill of Learning Counsel. Here is more
                  detail on what’s done and under construction or under
                  revision, as in having new function and integrations put into
                  those sections of the site. The parts of Knowstory include
                  mobile App versions for iOS and Android that are limited to
                  immediate social functions while the full site version
                  contains many features. Investment in more mobile function is
                  delayed until the full function of the main site is completed,
                  and many of those functions will not be easy to make mobile
                  just like buying a book from Amazon cannot be done inside the
                  Kindle App. The vision of Knowstory is to assimilate all
                  educational institutions into a digital ecosystem of options
                  for students with their own independent life-long accounts.
                  Students can affiliate to schools at will and upon graduation
                  retain their full Story of what they know and learned along
                  the way. Knowstory commingles the social function with the
                  shopping and associating-with-schools functions.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/*Learners*/}
        <section
          className={[classes.section, classes.section_contrast].join(" ")}
        >
          <div className="container">
            <div className="flexgrid full-width">
              <h3 className={classes.section__heading}>Learners</h3>
              <div className="f fdc aic">
                <img
                  src={learner}
                  alt="Learner"
                  style={{ width: 200, height: 200 }}
                  className={"mb30"}
                />
                <p className={classes.section__text}>
                  You want your learning your way. Maybe you are connected to a
                  school or freely building up the story of what you know – or
                  both. KnowStory is a social media ecosystem that is more than
                  just posts and chats. Here you can look at academic standards
                  for your State and grade by subject. You can put those in
                  bookmarks and use the marketplace here or the open internet to
                  find things that teach you those standards. You can also give
                  and receive social or extra-curricular Creds.
                </p>
                <p className={classes.section__text}>
                  This site was built so that a set of steps in a Course to
                  learn something can be set as a “live meeting” point like a
                  class – virtual, or on a campus for students at home or
                  students in the building. Live meets will not have a specific
                  time until enough students reach that point from doing prior
                  steps like watching a video, reading something, turning in an
                  essay, or taking short quizzes. If a teacher set the live meet
                  at five students, when the fifth one reaches it Knowstory
                  automatically calendars the teacher and those five students
                  for a meet that may be a few minutes or hours. Students coming
                  after the first live meet will be the next set of five. It’s a
                  little like Uber in group travel mode, but for live learning
                  meets. Freelance teachers, parents and even you as a student
                  can set up a Knowmix with steps you want yourself to learn to,
                  say, play an instrument without the live meets.
                </p>
                <p className={classes.section__text}>
                  No teacher is allowed to post a marketplace Knowmix without
                  our checking if they are authentic. No school or district can
                  have a Group for you to join without us checking they are who
                  they say they are. Company Groups and products do not undergo
                  background checks – use those at your own risk.
                </p>
                <p className={classes.section__text}>
                  KnowStory has a long way to go to get a lot more choices in
                  the marketplace, more schools making their groups and using
                  Knowmixes. You will get to keep your history of learning, your
                  Creds, friends and groups forever – FREE and secure. We just
                  ask for no naughty words or actions. Some of you learners will
                  be young children – so its important you can learn without
                  joy.
                </p>
                <p className={classes.section__text}>
                  Help us help you by using the App and Site and telling others
                  – including your schools and parents and mentors who could all
                  be using it. There is strength in numbers.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/*Educators*/}
        <section className={classes.section}>
          <div className="container">
            <div className="flexgrid full-width">
              <h3 className={classes.section__heading}>Educators</h3>
              <div className="f fdc aic">
                <img
                  src={educator}
                  alt="Educator"
                  style={{ width: 200, height: 200 }}
                  className={"mb30"}
                />
                <p className={classes.section__text}>
                  Educators can now be part of the “uberization” of learning
                  with Courses, organize their inventories of digital things,
                  shop the marketplace, give badges called Creds, use bookmarks,
                  use Standards, and set up their own Group to invite students
                  and teachers to participate in. Social media features include
                  mobile Apps for both Android and iOS.
                </p>
                <p className={classes.section__text}>
                  Knowstory is a “social media ecosystem” that helps you, your
                  school or whole districts personalize learning with Knowmixes.
                  A Knowmix is a playlist of steps to take. Steps can be
                  instructions with links, documents, and quizzes. Steps can
                  also be set as a “live meeting” point like a traditional class
                  and calendared for all. A live meet can also be set to only be
                  triggered based on pace, sort of like Uber in group travel
                  mode. A teacher sets the number of students in a cohort who
                  must arrive to a live meet step before triggering the meet
                  set-up to all calendars. Schools can use Knowmixes by teachers
                  to do self-paced student learning without losing the live
                  intersection with teachers for some of the parts of lessons.
                  Teachers, schools, and districts may build self-paced
                  professional development with Knowmixes.
                </p>
                <p className={classes.section__text}>
                  Bookmarking allows teachers to set aside things like academic
                  standards into folders to organize for lesson plans.
                </p>
                <p className={classes.section__text}>
                  The Invenstory function “rolls up” records made by teachers
                  affiliated to schools and schools up to districts so that
                  management of resources can by dynamic.
                </p>
                <p className={classes.section__text}>
                  Vetted school and district staff may grant certificates,
                  grades and marks as well as diplomas and degrees with Creds.
                  Any teacher may grant social and extra-curricular Creds
                  without affiliation.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/*Companies*/}
        <section
          className={[classes.section, classes.section_contrast].join(" ")}
        >
          <div className="container">
            <div className="flexgrid full-width">
              <h3 className={classes.section__heading}>Companies</h3>
              <div className="f fdc aic">
                <img
                  src={company}
                  alt="Company"
                  style={{ width: 200, height: 200 }}
                  className={"mb30"}
                />
                <p className={classes.section__text}>
                  Companies, associations, and online schools can create groups
                  , events, and unlimited marketplace tiles, attaching
                  standards, and answering purchase requests. Attaching
                  standards to all products makes it easier for teachers and
                  learners to find needed resources to purchase. Media companies
                  can also post news channels.
                </p>
                <p className={classes.section__text}>
                  Agents of companies may build self-paced professional
                  development with Courses.
                </p>
                <p className={classes.section__text}>
                  Any agent or company group admin can grant social or
                  extra-curricular Creds. Paid groups can grant grades/marks and
                  certificates and other benefits.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/*Social part*/}
        <section className={classes.section}>
          <div className="container">
            <div className="flexgrid full-width">
              <h3 className={classes.section__heading}>Social part</h3>
              <div className="row aic">
                <div className="col-md-5 mb30xs">
                  <ul className={classes.list}>
                    <li className={classes.list__item}>
                      Personal Page: Your graphic background, personal picture,
                      name, favorite quote, city, state, education history, work
                      history, date of birth, interests, favorite subjects,
                      favorite films, favorite books, and favorite games.
                    </li>
                    <li className={classes.list__item}>
                      News feeds have posts that allow text, image, and video
                      off YouTube. Other users can like or comment.
                    </li>
                    <li className={classes.list__item}>
                      Messages offer personal chats between users.
                    </li>
                    <li className={classes.list__item}>
                      Find Friends lists all users, shows a subtab for just your
                      friends, and provides a way to manage friend requests.
                    </li>
                  </ul>
                </div>

                <div className="col-md-offset-1 col-md-6 f jcfe">
                  <img src={social} alt="Social part" />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*Groups*/}
        <section
          className={[classes.section, classes.section_contrast].join(" ")}
        >
          <div className="container">
            <div className="flexgrid full-width">
              <h3 className={classes.section__heading}>Groups</h3>
              <div className="f jcc full-width mb30">
                <p className={classes.section__text}>
                  Groups provides a way to create five types of social groups,
                  some with added functions. A Group page has a graphic
                  background, logo, name, descriptive overview, link to outside
                  site, phone, address, and management functions such as keeping
                  the group open for any new member to join or closed to only
                  allow membership upon review. Admins of a group can add
                  attachments, sub-groups, and a sub-page catalog of Kourses,
                  Knowmixes, and Products. The Groups tab also has management of
                  your requests out to join groups and requests to a group you
                  might run for a list of those pending you have to accept or
                  reject into membership.
                </p>
              </div>
              <div className="row aic mb30">
                <div className="col-md-5 mb30xs">
                  <ul className={classes.list}>
                    <li className={classes.list__item}>
                      Interest Groups are for any type of educational group,
                      clubs, teams, etc.
                    </li>
                    <li className={classes.list__item}>
                      District Groups are specialized with unique functions that
                      allows them to tie in their schools as separate groups
                      under their umbrella and other features like the inventory
                      (called Invenstory) function and affiliating Courses, Cred
                      and Products in the Marketplace. Members who join a
                      District group or are rostered into it are given extra
                      rights based on roles.
                    </li>
                    <li className={classes.list__item}>
                      School Groups are similarly specialized with unique
                      functions in that they can stand alone with the same added
                      functions as Districts or affiliate up to a district.
                    </li>
                  </ul>
                </div>

                <div className="col-md-offset-2 col-md-5 mb30xs">
                  <ul className={classes.list}>
                    <li className={classes.list__item}>
                      Company Groups are for private enterprise to create
                      membership, posts, and display Products independently of
                      the all-companies Marketplace.
                    </li>
                    <li className={classes.list__item}>
                      Events Groups are for promoting an individual upcoming
                      event, creating membership and discussion. Becoming a
                      member automatically drops the event on your personal
                      calendar.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*Standards*/}
        <section className={classes.section}>
          <div className="container">
            <div className="full-width flexgrid">
              <h3 className={classes.section__heading}>Standards</h3>

              <div className="row aic mb45">
                <div className="col-md-5 f jcc mb30xs">
                  <img src={standards} alt="Standards" />
                </div>
                <div className="col-md-offset-1 col-md-5">
                  <p>
                    The academic Standards catalog covers all 50 States and
                    Canada for K-12. Tiles can be dropped into Bookmarks and are
                    the background elements added in when any user makes a
                    Course or Product that has tags for Standards.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*Bookmarks*/}
        <section
          className={[classes.section, classes.section_contrast].join(" ")}
        >
          <div className="container">
            <div className="flexgrid full-width">
              <h3 className={classes.section__heading}>Bookmarks</h3>
              <div className="f jcc full-width mb30">
                <p className={classes.section__text}>
                  Just like many other social media sites, the bookmarking
                  function is for users to “pin” items they want to keep
                  organized into sections and folders. The sections include:
                </p>
              </div>
              <div className="f jcc full-width mb30">
                <ul className={classes.list}>
                  <li className={classes.list__item}>People</li>
                  <li className={classes.list__item}>Groups</li>
                  <li className={classes.list__item}>Posts</li>
                  <li className={classes.list__item}>Creds</li>
                  <li className={classes.list__item}>Products</li>
                  <li className={classes.list__item}>Records</li>
                  <li className={classes.list__item}>Standards</li>
                </ul>
              </div>
              <div className="f aic fdc full-width">
                <p className={classes.section__text}>
                  Foldering includes the ability to create and name new Folders
                  that would contain any of the above items. This helps teachers
                  and students organize.
                </p>
                <p className={classes.section__text}>
                  Future function will include the ability to bookmark off of
                  any site with a “pin-like” add-on to browsers.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/*Marketplace (has more construction to do)*/}
        <section className={classes.section}>
          <div className="container">
            <div className="flexgrid full-width">
              <h3 className={classes.section__heading}>
                Marketplace (has more construction to do)
              </h3>
              <div className="row aic">
                <div className="col-md-6 mb30xs f fdc jcc full-width-xs">
                  <p className={"mb30"}>
                    The marketplace is for all company learning products and any
                    free-and-open resources to be found and used by affiliated
                    group members or outside users. This includes Kourses and
                    Knowmixes “packaged” into products so they appear in the
                    marketplace and can be found by anyone who may then want to
                    use them or enroll in your school.
                  </p>
                  <p className="mb30">
                    Every item has its own page upon opening the marketplace
                    tile where more details can be found as well as a place for
                    customer reviews. On any marketplace item tile, users can:
                  </p>
                  <ul className={[classes.list, "mb30"].join(" ")}>
                    <li className={classes.list__item}>
                      “Add to Invenstory” a copy of the record which then
                      indicates that item is one they are using.
                    </li>
                    <li className={classes.list__item}>
                      “Add to Bookmarks” if they would like to make a wish list
                      folder.
                    </li>
                    <li className={classes.list__item}>
                      Share with Friends” by sending the item to others.
                    </li>
                  </ul>
                  <p>
                    Currently this market does not do full ecommerce for
                    reselling. It is an “advertising tile” structure. Purchase
                    inquires send email and notify the market item maker in the
                    system so that a transaction can be done separately.
                  </p>
                  <p className="mb30">
                    Makers of any item in the Marketplace have special
                    management and edit features.
                  </p>
                </div>
                <div className="col-md-6 f jcfe">
                  <img src={marketplace} alt="Marketplace" />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*InvenStory (under revision)*/}
        <section
          className={[classes.section, classes.section_contrast].join(" ")}
        >
          <div className="container">
            <div className="flexgrid full-width">
              <h3 className={classes.section__heading}>
                InvenStory (under revision)
              </h3>
              <div className="row aic mb45">
                <div className="col-md-6 mb30xs">
                  <img src={invenstory} alt="Invenstory" />
                </div>
                <div className="col-md-offset-1 col-md-5">
                  <p className={"mb30"}>
                    Invenstory is a record-keeping function for teachers and
                    schools or districts as to what is in use for resources. It
                    is built with a tied tiered structure so that when a teacher
                    affiliates with a school their list of inventory is
                    automatically shared with the school. When schools affiliate
                    up to their district, the same sharing occurs on sub-tabs.
                  </p>
                  <p className={"mb30"}>
                    Teachers can create simple records of all the digital things
                    they use but not attach them, or they can create a Course
                    and put that in the inventory as a record that contains the
                    full lesson, attachments, links, video, steps and quizzes.
                    They can also add products straight from the marketplace as
                    a placeholder showing it’s some piece of software they use
                    for their records.
                  </p>
                  <p className={"mb30"}>
                    Records are given a name, who added the record, what the
                    content type is, the subject, the grades it covers, what
                    standards it purports to cover, any URL link, the file type,
                    the platform, and the version.
                  </p>
                  <p>
                    Tabs show your inventory as a user versus any Group you are
                    a member of for what their inventory is. The admins of the
                    school-level or district-level can see the fully shared
                    lists of all users in order to see and sort by subject,
                    grades, and standards.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*KnowMixes (under revision)*/}
        <section className={classes.section}>
          <div className="container">
            <div className="flexgrid full-width">
              <h3 className={classes.section__heading}>
                Courses (under revision)
              </h3>
              <div className="row aic mb45">
                <div className="col-md-5 mb30xs">
                  <p>
                    Course is like a lesson sequence. It allows users to create
                    and name a lesson and then add steps and quizzes. As a
                    Course is created, it will ask the maker to assign it to
                    their Group which then makes it also available under that
                    Group’s sidebar and full page of all products separate from
                    the Marketplace. Packaging that Course into a final version
                    and sharing it into the Marketplace allows the global user
                    community to see it rather than just the members of your
                    school or district group. A Course can be a sub-part of a
                    Kourse. Users see a tab of Courses they have added as a
                    student as well as a tab for all the ones they are
                    personally managing because they made them. These are
                    sortable by grade and subject. Knowmixes require an
                    “Examiner” be assigned who will interact with students to
                    grade their answers to quizzes and grant them Cred.
                    Knowmixes are under revision for this assignment of
                    examiners to have more function and a tiered structure. Each
                    Step in a Course will soon contain details that allow it to
                    be part of the hybrid logistics functions that include
                    auto-cohorting students and calendaring live meetings.
                  </p>
                </div>
                <div className="col-md-offset-1 col-md-6 f jcfe">
                  <img src={knowmix} alt="Course" />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*Kourses (under construction)*/}
        <section
          className={[classes.section, classes.section_contrast].join(" ")}
        >
          <div className="container">
            <div className="flexgrid full-width">
              <h3 className={classes.section__heading}>
                Kourses (under construction)
              </h3>
              <div className="f fdc aic">
                <p className={classes.section__text}>
                  Kourses are what Knowmixes become when they are packaged into
                  a product form that appears in the global marketplace or just
                  for your Group.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/*KnowCred (under revision)*/}
        <section className={classes.section}>
          <div className="container">
            <div className="flexgrid full-width">
              <h3 className={classes.section__heading}>
                Creds (under revision)
              </h3>
              <div className="f fdc aic mb30">
                <p className={classes.section__text}>
                  Creds is a reimagined badging system that is useful for
                  student-to-student awarding of “cred” as well as authentically
                  Awarded and Valid credentials. There are five levels of Creds
                  that can be made as follows:
                </p>
              </div>
              <div className="row aic mb45">
                <div className="col-md-5 mb30xs">
                  <p className={"mb15"}>Who is it from</p>
                  <ul className={[classes.list, "mb30"].join(" ")}>
                    <li className={classes.list__item}>
                      from Yourself (to give) or
                    </li>
                    <li className={classes.list__item}>
                      from the Group you run
                    </li>
                  </ul>

                  <p className={"mb15"}>You then select if the Cred is</p>
                  <ul className={classes.list}>
                    <li className={classes.list__item}>
                      for yourself (self-congratulatory)
                    </li>
                    <li className={classes.list__item}>
                      someone else (which you find in Knowstory)
                    </li>
                    <li className={classes.list__item}>
                      someone you will have the system send the finished Cred by
                      email
                    </li>
                  </ul>
                </div>
                <div className="col-md-offset-2 col-md-5 f jcfe">
                  <img src={knowcred} alt="Cred" />
                </div>
              </div>
              <div className="f fdc aic mb30">
                <p className={classes.section__text}>
                  You give the Cred a name, then select its Type, give it a
                  Label, select it’s location (Home, school, church, camp, work,
                  district, association, or group).
                </p>
                <p className={classes.section__text}>
                  Describe the activity for the award, add links as needed,
                  chose a credicon or upload your own, and finally chose a
                  background and border.
                </p>
              </div>

              <p className={"mb15"}>The types are:</p>
              <ul className={classes.list}>
                <li className={classes.list__item}>
                  Granted Creds are social and no checks are done to ensure the
                  giver is a valid entity. Without at least the “granted” label,
                  the Cred was self-made because it wasn’t granted by someone
                  else.
                </li>
                <li className={classes.list__item}>
                  Awarded Creds are from any member of a school or district
                  group given that right -- a teacher or the admin(s). A Cred
                  that shows it is “Awarded” is only available from the admin or
                  any teacher affiliated with a group, not students. It’s
                  Awarded and not Valid if the school or district is not a paid
                  subscriber and able to grant Valid status.
                </li>
                <li className={classes.list__item}>
                  Valid Creds are from an annually paid Group that has been
                  vetted as authentic. Any member of a school or district group
                  given that right -- a teacher or the admin(s) can mark Valid
                  any Cred, no students.
                </li>
              </ul>
            </div>
          </div>
        </section>

        {/*Calendar (under construction)*/}
        <section
          className={[classes.section, classes.section_contrast].join(" ")}
        >
          <div className="container">
            <div className="flexgrid full-width">
              <h3 className={classes.section__heading}>
                Calendar (under construction)
              </h3>
              <div className="f fdc aic">
                <p className={classes.section__text}>
                  Calendar is a Knowstory function for all users with special
                  automation with steps inside Knowmixes. When a step is set to
                  "live meet," the Calendar is used to auto-schedule when a user
                  of that Knowmix arrives to the step with the Teacher or Maker
                  of that Knowmix. Calendar is an answer to hybrid school
                  logistics with a twist. It will present different levels of
                  calendaring so that schools can better manage learning.
                </p>
                <p className={[classes.section__text, "mb30"].join(" ")}>
                  What’s different about this feature is that it is part of the
                  Hybrid Logistics Project to reimagine the structure of
                  schooling.
                </p>
                <p className="mb15">The most important differences are:</p>
                <ul className={[classes.list, "mb30"].join(" ")}>
                  <li className={classes.list__item}>
                    Master Schedule Transformation automation. This means a way
                    to manage the alternate schedules happening as schools go
                    hybrid with A/B weeks, A/B days and divergent daily block
                    schedules, but with the added capacity to “uberize”
                    individual learning steps. This is the “invention” of
                    Knowstory and a protected intellectual property.
                  </li>
                  <li className={classes.list__item}>
                    Pacing Guide automation. Most schools have articulated their
                    plans for pacing academic standards and instructions to
                    teachers through documents and spreadsheets. The lack of
                    automation meant more difficulty in lesson planning because
                    teachers had to check documents against their allotted time
                    slots in the whole calendar while viewing separate lesson
                    planning work.
                  </li>
                  <li className={classes.list__item}>
                    Auto-cohorting and auto-calendaring. This feature allows
                    teachers to mark steps in Courses as points that are
                    necessarily live teacher intersections. That makes those
                    steps different than self-paced study steps, which may be
                    any asynchronous learning at links, Apps, or other
                    resources. Teachers can set the step to “trigger” a calendar
                    appointment for an x-number of students once that entire
                    cohort arrives at that step. If the cohort is set at 10,
                    then when the 10th student arrives, the Calendar checks
                    available teacher time and notifies all students for the
                    class moment together. This structure allows the schooling
                    to change its structure to a main “House” room for segments
                    of students who study every subject together until flighted
                    out to a class. All students can pace every subject
                    individually. Teachers may help in rotation to run the House
                    but are otherwise roaming to help individual students who
                    might need help to move faster. They may be doing one-on-one
                    direct instruction, office hours, checking analytics or
                    writing custom lessons for individuals not succeeding on the
                    normal steps when they are not alerted to hold a class step.
                  </li>
                  <li className={classes.list__item}>
                    Hybrid flexibility. Schools and districts have radically
                    altered their scheduling with students in segments within
                    alternating weeks, alternating days, changes to block
                    schedules and a lot of remote students. The Calendar
                    presents maximum flexibility.
                  </li>
                </ul>
                <img src={calendar} alt="Calendar" />
              </div>
            </div>
          </div>
        </section>

        {/*DataStory (under construction)*/}
        <section className={classes.section}>
          <div className="container">
            <div className="flexgrid full-width">
              <h3 className={classes.section__heading}>
                DataStory (under construction)
              </h3>
              <div className="f fdc aic">
                <p className={classes.section__text}>
                  This function shows metrics for users with dynamic graphics
                  based on different functions such as student(s) pace through
                  Kourses. When ecommerce is added to the marketplace, companies
                  will see more data and users their purchase histories and
                  spend in Invenstory.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/*Integrations Story (under construction)*/}
        <section
          className={[classes.section, classes.section_contrast].join(" ")}
        >
          <div className="container">
            <div className="flexgrid full-width">
              <h3 className={classes.section__heading}>
                Integrations Story (under construction)
              </h3>
              <div className="f fdc aic">
                <p className={classes.section__text}>
                  Schools and companies who need integrations for rostering,
                  badges, their Learning Management System (LMS), Student
                  Information System (SIS), will be able to use these functions
                  to integrate. Knowstory is planning to add functional
                  integration with Single Sign-on (SSO) services, including
                  within Knowstory and with Knowstory as one of the elements of
                  SSO services.
                </p>
                <p className={classes.section__text}>
                  Special services to integrate major catalogs of products like
                  courses and ebooks with redirects to those companies from the
                  marketplace will also be part of integrations.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default AboutUs
