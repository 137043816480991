import React, { Component } from "react"

import "./complete-signup.scss"
import Stepper from "@material-ui/core/Stepper/Stepper"
import Step from "@material-ui/core/Step/Step"
import StepLabel from "@material-ui/core/StepLabel/StepLabel"
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import Radio from "@material-ui/core/Radio/Radio"
import TextField from "@material-ui/core/TextField/TextField"
import { generatePath, Redirect, withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { signUp } from "../../../library/store/actions/creators/authCreators"
import * as routes from "../../../library/constants/routes"
import getErrorText from "../../../library/constants/errorTypes"
import { withSnackbar } from "notistack"
import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import { countries, states } from "../../../library/constants/states"
import { acceptFriendRequest } from "../../../library/store/actions/creators/friendsCreators"

const roles = [
  {
    id: 1,
    name: "Learner",
    description: "I am currently a student or parent of one.",
  },
  {
    id: 2,
    name: "Educator",
    description:
      "I am en educator, administrator, tutor or other school representative as well as learner. I may use Calendar, create Knowmeets, Courses, Creds, and publish paid or free products for my group or the global market.",
  },
  // {id: 3, name: 'Admin', description: 'I work in an organization.'}
  {
    id: 4,
    name: "Company Representative",
    description:
      'I am with a company or work as a free representative that may create both my own profile and a company group or interest group, post products to the global market, use the "Schedule Demo" function, make Courses for training, post news and find new friends.',
  },
]

class CompleteSignup extends Component {
  state = {
    activeStep: 0,
    role: "1",
    hasOrganization: "true",
    work: undefined,
    education: undefined,
    secondStepLabel: "",
    textError: false,
    country: "USA",
    city: "",
    state: "",
    stateError: false,
    ...this.props.location.state,
  }

  getSteps() {
    return ["Location", "Role", this.setSecondStepLabel()]
  }

  setSecondStepLabel() {
    return this.state.role === "1" ? "School" : "Organization"
  }

  handleRoleChange = (event) => {
    this.setState({ role: event.target.value })
  }

  handleHasOrganizationChange = (event) => {
    this.setState({ hasOrganization: event.target.value })
  }

  handleTextField = (input) => {
    this.state.role === "1"
      ? this.setState({
          education: input.target.value,
          work: undefined,
          textError: false,
        })
      : this.setState({
          education: undefined,
          work: input.target.value,
          textError: false,
        })
  }

  handleStateChange = (event) => {
    this.setState({
      state: event.target.value || "",
      stateError: this.state.country === "USA" && !event.target.value,
    })
  }

  getStepContent = (step) => {
    const { textError } = this.state
    switch (step) {
      case 0:
        return (
          <ValidatorForm className="form" onSubmit={() => {}}>
            <div className="form__fieldset">
              <label htmlFor="country" className="form__label">
                Country
              </label>
              <div className="form__fieldbox">
                <div className="form__input form__input--select">
                  <Select
                    // disabled
                    id="country"
                    name="country"
                    fullWidth
                    displayEmpty
                    value={this.state.country}
                    onChange={(event) =>
                      this.setState({ country: event.target.value })
                    }
                  >
                    <MenuItem value="">
                      <span className="color-black-38">Select country</span>
                    </MenuItem>
                    {countries.map((c) => (
                      <MenuItem value={c} key={c}>
                        {c}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            {this.state.country === "USA" && (
              <div className="form__fieldset">
                <label htmlFor="state" className="form__label">
                  State *
                </label>
                <div className="form__fieldbox">
                  <div className="form__input form__input--select">
                    <FormControl error={this.state.stateError} fullWidth>
                      <Select
                        id="state"
                        name="state"
                        fullWidth
                        value={this.state.state}
                        displayEmpty
                        onChange={this.handleStateChange}
                      >
                        <MenuItem value="">
                          <span className="color-black-38">Select state</span>
                        </MenuItem>
                        {states.map((item) => {
                          return (
                            <MenuItem value={item} key={item}>
                              {item}
                            </MenuItem>
                          )
                        })}
                      </Select>
                      {this.state.stateError && (
                        <FormHelperText>Field is required</FormHelperText>
                      )}
                    </FormControl>
                  </div>
                </div>
              </div>
            )}

            <div className="form__fieldset">
              <label htmlFor="city" className="form__label">
                City
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <TextValidator
                    id="city"
                    name="city"
                    value={this.state.city}
                    placeholder={"Type your city"}
                    margin="normal"
                    fullWidth
                    validators={["maxStringLength:50"]}
                    errorMessages={["Max length is 50 characters"]}
                    onChange={(value) =>
                      this.setState({ city: value.target.value })
                    }
                  />
                </div>
              </div>
            </div>
          </ValidatorForm>
        )

      case 1:
        return (
          <RadioGroup
            aria-label="Role"
            name="role"
            value={this.state.role}
            className="mb20"
            onChange={this.handleRoleChange}
          >
            {roles.map((role) => {
              return (
                <FormControlLabel
                  key={role.id}
                  value={String(role.id)}
                  control={<Radio />}
                  className="mb30"
                  labelPlacement="end"
                  label={
                    <div>
                      <h4 className="m-radio-button__title">{role.name}</h4>
                      <p className="m-radio-button__description">
                        {role.description}
                      </p>
                    </div>
                  }
                />
              )
            })}
          </RadioGroup>
        )

      case 2:
        return (
          <form>
            <RadioGroup
              aria-label="Role"
              name="role"
              value={this.state.hasOrganization}
              className="mb20"
              onChange={this.handleHasOrganizationChange}
            >
              <FormControlLabel
                value="true"
                control={<Radio />}
                className="mb30"
                disableRipple={true}
                label={
                  <FormControl error={textError} fullWidth>
                    <TextField
                      id="organization-name"
                      label={this.setSecondStepLabel()}
                      className="m-text-field"
                      name="organization-name"
                      margin="none"
                      fullWidth
                      onChange={this.handleTextField}
                    />
                    {textError && (
                      <FormHelperText className="error-message">
                        Max length is 150 characters
                      </FormHelperText>
                    )}
                  </FormControl>
                }
              />

              <FormControlLabel
                value="false"
                control={<Radio />}
                className="mb30"
                disableRipple={true}
                label={
                  <div>
                    None{" "}
                    <div
                      style={{ color: "rgba(0, 0, 0, 0.4)", fontSize: "12px" }}
                    >
                      I`m not associated with any organization
                    </div>
                  </div>
                }
              />
            </RadioGroup>
          </form>
        )

      default:
        return null
    }
  }

  handleNext = () => {
    const { activeStep } = this.state

    this.setState({
      activeStep: activeStep + 1,
    })
  }

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }))
  }

  backToMainForm = () => {
    const { first_name, last_name, email, password } = this.state

    const { history } = this.props

    history.push({
      pathname: "/sign-up",
      state: {
        first_name,
        last_name,
        email,
        password,
      },
    })
  }

  processSignUp = async () => {
    const {
      first_name,
      last_name,
      email,
      password,
      country,
      state,
      city,
      role,
      work,
      education,
      hasOrganization,
    } = this.state
    if (hasOrganization === "true") {
      if ((work && work.length > 150) || (education && education.length > 150))
        return this.setState({ textError: true })
    }
    try {
      await this.props.signUp({
        first_name,
        last_name,
        email: email.toLowerCase(),
        password,
        role: parseInt(role),
        education:
          education && hasOrganization === "true"
            ? { name: education, type: 4 }
            : undefined,
        work: hasOrganization === "true" ? work : undefined,
        country,
        state: country === "USA" ? state : "",
        city,
        newFriendId: this.props.auth.newFriendId,
        inviterGroupId: this.props.auth.inviterGroupId,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), {
        variant: "error",
      })
    }
  }

  render() {
    const steps = this.getSteps()
    const { activeStep } = this.state

    if (this.props.auth.isLoggedIn) {
      if (this.props.auth.knowcredId) {
        return (
          <Redirect
            to={{
              pathname: generatePath(routes.KNOWCRED_SINGLE, {
                knowCredId: this.props.auth.knowcredId,
              }),
              state: { from: this.props.location },
            }}
          />
        )
      }

      if (this.props.auth.meetHash) {
        return (
          <Redirect
            to={{
              pathname: generatePath(routes.BOOK_MEET, {
                hash: this.props.auth.meetHash,
              }),
            }}
          />
        )
      }

      return (
        <Redirect
          to={{
            pathname: generatePath(routes.USER, {
              userId: this.props.auth.userData.id,
            }),
            state: { from: this.props.location },
          }}
        />
      )
    } else {
      return (
        <div className="complete-signup">
          <div className="stepper-wrapper">
            <Stepper activeStep={activeStep}>
              {steps.map((label) => {
                const props = {}
                const labelProps = {}
                return (
                  <Step key={label} {...props}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                )
              })}
            </Stepper>
            <div>
              <div>
                {this.getStepContent(activeStep)}
                <div className="btn-group">
                  {activeStep === 0 ? (
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={this.backToMainForm}
                    >
                      Back
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={this.handleBack}
                    >
                      Back
                    </Button>
                  )}

                  {activeStep === steps.length - 1 ? (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={this.processSignUp}
                    >
                      Finish
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      disabled={
                        this.state.country === "USA" && !this.state.state
                      }
                      variant="contained"
                      onClick={this.handleNext}
                    >
                      Next
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = ({ auth }) => ({ auth })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ signUp, acceptFriendRequest }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(CompleteSignup)))
