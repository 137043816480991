import EventsCalendar from "../../components/eventsCalendar"
import ErrorBoundary from "../../../../../components/v2/ErrorBoundary"

const MainCalendar = ({ noFilters = false, calendarStyle }) => {
  return (
    <>
      <ErrorBoundary>
        <EventsCalendar
          noFilters={noFilters}
          calendarStyle={calendarStyle}
          isFullScreen
        />
      </ErrorBoundary>
    </>
  )
}

export default MainCalendar
