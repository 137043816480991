import React from "react"
import "../header/header.scss"
import SearchForm from "../ui/forms/searchForm/searchForm"
import UserDropdownMenu from "./components/userDropdownMenu/userDropdownMenu"
import { generatePath, Link } from "react-router-dom"
import { useSelector } from "react-redux"
import logo from "../../assets/img/logo.svg"
import { USER } from "../../library/constants/routes"
import HeaderNav from "./components/headerNav"
import NotificationsDropdown from "./components/notificationsDropdown/notificationsDropdown"
import { useCurrentUser } from "../../hooks/data/user/useUser"

const HeaderLoggedIn = () => {
  const { user, isSuccess } = useCurrentUser()

  const showNotifications = !!user && !user?.deleted && !user?.blocked

  return (
    <header className={"sticky top-0 z-[101]"}>
      <div
        className={
          "grid grid-cols-base-layout bg-white shadow-small z-100 relative"
        }
      >
        <div className="header__content header__content--default container">
          <div>
            <Link
              to={generatePath(USER, { userId: user?.id })}
              className="header__logo"
            >
              <img src={logo} alt="Logo" />
            </Link>
          </div>
          <div className="flex items-center justify-end gap-8 w-full">
            <div className={"flex"}>
              <SearchForm />
            </div>
            <div className="flex items-center gap-8">
              {showNotifications && <NotificationsDropdown />}
              <UserDropdownMenu />
            </div>
          </div>
        </div>
      </div>
      <HeaderNav />
    </header>
  )
}

export default HeaderLoggedIn
