import React, { Component } from "react"
import getErrorText from "../../../../../../../../../library/constants/errorTypes"
import * as qs from "query-string"
import Loader from "../../../../../../../../../components/ui/loader"
import withScroll from "../../../../../../../../../hocs/withScroll/withScroll"
import { LIMIT } from "../../../../../../../../../library/constants/limits"
import ListSearchHeader from "../../../../../../../../../components/ui/listSearchHeader/listSearchHeader"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import unavailableAvatar from "../../../../../../../../../assets/img/user_unavailable.svg"
import userAvatarDefault from "../../../../../../../../../assets/img/user_avatar.svg"
import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../../../../../../../library/constants/routes"
import {
  composeUserHighlightedEducation,
  composeUserHighlightedWork,
} from "../../../../../../../../../library/utils/getUserHighlightedInfo"
import Button from "@material-ui/core/Button"
import {
  clearSchoolGradeStudentsList,
  deleteStudentFromSchoolGrade,
  getSchoolGradeStudentsList,
} from "../../../../../../../../../library/store/actions/creators/groupsCreators"

class GroupSettingsCalendarGradeMembers extends Component {
  state = {
    offset: 0,
    dataReceived: false,
  }

  async componentDidMount() {
    await this.getList({})
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      location: { search },
    } = this.props
    const { q: name } = qs.parse(search)

    if (search && search !== prevProps.location.search) {
      this.props.clearSchoolGradeStudentsList()
      await this.getList({ name })
    }
  }

  componentWillUnmount() {
    this.props.clearSchoolGradeStudentsList()
  }

  getList = async ({ offset = 0, name }) => {
    try {
      this.setState({ dataReceived: false })
      await this.props.getSchoolGradeStudentsList({
        grade: parseInt(this.props.match.params.gradeId),
        group_id: parseInt(this.props.match.params.groupId),
        name: name || undefined,
        offset,
      })
      this.setState({ dataReceived: true, offset })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  onScroll = async () => {
    const { q: name } = qs.parse(this.props.location.search)

    if (
      !this.state.dataReceived ||
      this.props.groups.schoolGradeMembersListEndReached
    )
      return

    await this.getList({ offset: this.state.offset + LIMIT, name })
  }

  searchMembers = (name) => {
    const { history } = this.props

    history.push({
      search: `?q=${name}`,
    })
  }

  removeMember = async (user) => {
    try {
      await this.props.deleteStudentFromSchoolGrade({
        group_id: parseInt(this.props.match.params.groupId),
        user_id: user.id,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  render() {
    const { q: query } = qs.parse(this.props.location.search)

    return (
      <>
        <ListSearchHeader
          title="Grade members"
          placeholder="Start typing name"
          search={this.searchMembers}
          noResults={
            this.state.dataReceived &&
            !this.props.groups.schoolGradeMembersList.length
          }
          noResultsLabel={query ? "" : "This grade doesn't have members yet"}
        />

        {this.props.groups.schoolGradeMembersList.map((item) => (
          <ListItem
            member={item}
            removeMember={() => this.removeMember(item)}
            isCurrentUser={item.id === this.props.auth.userData.id}
            key={item.id}
          />
        ))}

        {!this.state.dataReceived && <Loader />}
      </>
    )
  }
}

const ListItem = ({ member, removeMember, isCurrentUser }) => {
  const photoUrl = member.deleted
    ? unavailableAvatar
    : member.photo !== ''
    ? member.photo
    : userAvatarDefault
  const fullName = `${member.first_name} ${member.last_name}`
  const profileUrl = generatePath(routes.USER, { userId: member.id })

  const addressInfoBlock =
    (member.city ? member.city : '') +
    (member.country ? `, ${member.country}` : '')

  return (
    <div className="box list-item">
      <div className="f aic full-width">
        <div className="list-item__img">
          <img src={photoUrl} alt={fullName} />
        </div>
        <div className="list-item__info">
          {/*<span className="font-12 color-black-54">{userRolesIds[member.role].name}</span>*/}
          <Link to={profileUrl} className="link list-item__name">
            {fullName}
          </Link>
          {/*<span className="list-item__description">{groupRolesIds[user.group_role]}</span>*/}
          {/*<span>*/}
          {/*    {addressInfoBlock && <LinesEllipsis*/}
          {/*        text={addressInfoBlock}*/}
          {/*        maxLine="1"*/}
          {/*        ellipsis="..."*/}
          {/*        trimRight*/}
          {/*        basedOn="letters"*/}
          {/*    />}*/}
          {/*</span>*/}

          {!!member.education &&
            composeUserHighlightedEducation(member.education)}

          {!!member.work && composeUserHighlightedWork(member.work)}
        </div>
      </div>
      <div className="list-item__control-panel">
        {!isCurrentUser && (
          <Button onClick={removeMember} color="primary" variant="outlined">
            Remove
          </Button>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth, groups }) => ({ auth, groups })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSchoolGradeStudentsList,
      clearSchoolGradeStudentsList,
      deleteStudentFromSchoolGrade,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withScroll(withSnackbar(GroupSettingsCalendarGradeMembers)))
