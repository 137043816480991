import React from "react"
import styled from "@emotion/styled"
import { devices } from "../../library/constants/styles/responsive"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

const Container = styled.div`
  margin: 0 auto;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "1170px")};
  height: ${({ fullHeight, isLoggedIn }) =>
    fullHeight
      ? isLoggedIn
        ? "calc(100vh - 50px)"
        : "calc(100vh - 100px)"
      : "auto"};

  @media ${devices.xs} {
    width: 100vw;
    height: auto;
    padding: ${(props) => (props.fullWidth ? 0 : "0 10px")};
  }
`

const Content = styled.div`
  margin-top: ${(props) => props.marginTop};
  height: ${(props) => (props.fullHeightContent ? "100%" : "auto")};
`

const InfoLayout = ({
  fullHeight = false,
  fullWidth = true,
  contentMarginTop = 0,
  children,
}) => {
  const { isLoggedIn } = useSelector(({ auth }) => auth)
  const { pathname } = useLocation()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <Container
      fullWidth={fullWidth}
      fullHeight={fullHeight}
      isLoggedIn={isLoggedIn}
    >
      <Content marginTop={contentMarginTop} fullHeightContent={fullHeight}>
        {children}
      </Content>
    </Container>
  )
}

export default InfoLayout
