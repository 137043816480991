import { meetTypes } from "../../library/constants/meets"

export const useBookingCreation = () => {
  const getMaxSegmentDuration = (hold_segments = []) => {
    const segmentDurations = hold_segments.map((ts) => Number(ts.duration))
    return Math.max(...segmentDurations)
  }

  const getMeetTypes = (hold_segments = [], types = []) => {
    if (!hold_segments.length && !!types.length) return types
    if (!hold_segments.length && !types.length) return meetTypes

    const maxSegmentDuration = getMaxSegmentDuration(hold_segments)
    return types.filter((mt) => mt <= maxSegmentDuration)
  }

  const getSmallestHold = (holds = []) => {
    const computedSmallestHold = holds
      .map((h) => ({
        id: h.id,
        segments: h.segments.map((s) => +s.duration).reduce((a, b) => a + b, 0),
      }))
      .sort((a, b) => a.segments - b.segments)[0]

    return holds.find((h) => h.id === computedSmallestHold.id)
  }

  return { getMeetTypes, getMaxSegmentDuration, getSmallestHold }
}
