import React, { Component } from "react"

class ErrorBoundary extends Component {
  state = {
    hasError: false,
  }

  static getDerivedStateFromError(error) {
    console.log(error)
    return {
      hasError: true,
    }
  }

  componentDidCatch(error, errorInfo) {
    console.groupCollapsed("Error:")
    console.log({ error })
    console.groupEnd()
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="box">
          <div className="box__content f aic jcc fdc">
            <span className="color-black-54 mb10">
              {this.props.message || "Something went wrong"}
            </span>
            <span style={{ fontSize: 30, lineHeight: "30px" }}>&#128533;</span>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary;
