import React, { useEffect, useState } from "react"
import { generatePath, NavLink, withRouter } from "react-router-dom"
import TextField from "@material-ui/core/TextField/TextField"
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment"
import SearchOutlined from "@material-ui/icons/SearchOutlined"
import * as qs from "query-string"
import { withSnackbar } from "notistack"
import * as routes from "../../../../../../../../library/constants/routes"
import Button from "@material-ui/core/Button"

const CalendarsHeader = (props) => {
  const tabs = [
    {
      label: "School Calendars",
      path: generatePath(routes.GROUP_SETTINGS_SCHOOL_CALENDARS, {
        groupId: props.match.params.groupId,
      }),
    },
    {
      label: "District Calendars",
      path: generatePath(routes.GROUP_SETTINGS_DISTRICT_CALENDARS, {
        groupId: props.match.params.groupId,
      }),
    },
  ]

  // const [activeTab, setActiveTab] = useState(this.props.match.path);
  const [query, setQuery] = useState(
    props.location.search ? qs.parse(props.location.search).q : ""
  )

  useEffect(() => {
    setQuery("")
  }, [props.match.path])

  const handleChange = (event) => setQuery(event.target.value)

  const processSearch = (event) => {
    event.preventDefault()

    props.search(query.trim())
  }

  return (
    <div className="box">
      {props.groupType === 4 ? (
        <nav className="h-tabs f aic jcsb">
          <ul className="h-tabs__nav-list">
            {tabs.map(({ label, path }) => {
              return (
                <li
                  className={`h-tabs__item ${
                    props.match.path === path || props.match.url === path
                      ? "h-tabs__item--active"
                      : ""
                  }`}
                  key={label}
                >
                  <NavLink
                    exact
                    to={path}
                    className="h-tabs__link"
                    activeClassName={"h-tabs__item--active"}
                  >
                    {label}
                  </NavLink>
                </li>
              )
            })}
          </ul>
          <Button
            color="primary"
            variant="contained"
            onClick={props.initCreate}
          >
            Create Calendar
          </Button>
        </nav>
      ) : (
        <div className="box__heading">
          <div className="f jcsb aic">
            <span>Calendars</span>
            <Button
              color="primary"
              variant="contained"
              onClick={props.initCreate}
            >
              Create Calendar
            </Button>
          </div>
        </div>
      )}

      <div className="box__content">
        <form onSubmit={processSearch}>
          <TextField
            className="search-header__input full-width"
            id="search-header-input"
            placeholder="Type Calendar name"
            margin="none"
            autoComplete="off"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined style={{ color: "#9b9a9b" }} />
                </InputAdornment>
              ),
            }}
            value={query}
            onChange={handleChange}
          />
        </form>
        {props.noResults && (
          <div className="aic color-black-54 f jcc pt15">
            {props.noResultsLabel || "Result not found"}
          </div>
        )}
      </div>
    </div>
  )
}

export default withSnackbar(withRouter(CalendarsHeader))
