import React, { useEffect, useState } from "react"
import {
  generatePath,
  Link,
  Route,
  Switch,
  useHistory,
  useParams,
} from "react-router-dom"
import * as routes from "../../../../../../../../library/constants/routes"
import Loader from "../../../../../../../../components/ui/loader"
import VerticalMenu from "../../../../../../../../components/ui/verticalMenu"
import { getGroupLogo } from "../../../../../../../../library/utils/getGroupLogo"
import { useDispatch, useSelector } from "react-redux"
import { useAlert } from "../../../../../../../../hooks/useAlert"
import { getScheduleSubject } from "../../../../../../../../library/store/actions/creators/calendarCreators"
import PacingCalendarInfo from "./pacingCalendarInfo"
import PacingCalendarUnits from "./paingCalendarUnits"
import UnitsCalendar from "./unitsCalendar"
import withGroupSettingsGuard from "../../../../../../../../hocs/guards/withGroupSettingsGuard"
import SEO from "../../../../../../../../components/SEO"

const PacingCalendar = () => {
  const { currentGroup } = useSelector(({ groups }) => groups)
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const { errorAlert } = useAlert()
  const [dataReceived, setDataReceived] = useState(false)

  useEffect(() => {
    const getData = async () => {
      try {
        await dispatch(
          getScheduleSubject({ id: parseInt(params.subjectId, 10) })
        )

        setDataReceived(true)
      } catch (error) {
        errorAlert(error)
      }
    }

    getData()
  }, [])

  const menuItems = [
    {
      path: generatePath(routes.GROUP_SETTINGS_PACING_CALENDAR, params),
      type: "parent",
      label: "Calendar info",
    },
    {
      path: generatePath(routes.GROUP_SETTINGS_PACING_CALENDAR_UNITS, params),
      type: "parent",
      label: "Units",
    },
    {
      path: generatePath(
        routes.GROUP_SETTINGS_PACING_CALENDAR_UNITS_CALENDAR,
        params
      ),
      type: "parent",
      label: "Units Calendar",
    },
  ]

  return (
    <>
      <div className="common-page__content">
        <SEO title={"Pacing Calendar"} />
        <main className="common-page__main">
          {dataReceived ? (
            <Switch>
              <Route
                exact
                path={routes.GROUP_SETTINGS_PACING_CALENDAR}
                component={PacingCalendarInfo}
              />
              <Route
                exact
                path={routes.GROUP_SETTINGS_PACING_CALENDAR_UNITS}
                component={PacingCalendarUnits}
              />
              <Route
                exact
                path={routes.GROUP_SETTINGS_PACING_CALENDAR_UNITS_CALENDAR}
                component={UnitsCalendar}
              />
            </Switch>
          ) : (
            <Loader />
          )}
        </main>
        <aside className="common-page__sidebar">
          {/*{dataReceived && (*/}
          <div className="box aside-header">
            <Link
              to={generatePath(routes.GROUP_SETTINGS_PACING_CALENDARS, {
                groupId: params.groupId,
              })}
              className="box__content aside-header__content"
            >
              <img
                src={getGroupLogo(currentGroup)}
                className="aside-header__img"
                alt={currentGroup?.name}
              />
              <div className="aside-header__info">
                <div className="aside-header__name">{currentGroup?.name}</div>
                <div className="aside-header__help">
                  back to pacing Calendars
                </div>
              </div>
            </Link>
          </div>
          {/*)}*/}
          <VerticalMenu menuItems={menuItems} />
        </aside>
      </div>
    </>
  )
}

export default withGroupSettingsGuard(PacingCalendar)
