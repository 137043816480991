export const getCardSize = (remValue) => {
  return {
    numValPx: remValue * 16,
    numValRem: remValue,
  }
}

export const CARD_SIZES = {
  XS: {
    twClass: "xs",
    numValPx: 56,
    numValRem: 3.5,
  },
  SM: {
    twClass: "sm",
    numValPx: 64,
    numValRem: 4,
  },
  MD: {
    twClass: "md",
    numValPx: 80,
    numValRem: 5,
  },
  LG: {
    twClass: "lg",
    numValPx: 96,
    numValRem: 6,
  },
  XL: {
    twClass: "xl",
    numValPx: 136,
    numValRem: 8.5,
  },
  "2XL": {
    twClass: "2xl",
    numValPx: 174,
    numValRem: 11,
  },
}
