import React from "react"
import Dialog from "@material-ui/core/Dialog/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import PlainLink from "../../../../../components/ui/link"

const PurchaseDialog = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Purchase request</DialogTitle>
      <DialogContent>
        <p className="color-black-54 font-16 mb15">
          Thank you, we have notified the maker to contact you.
        </p>

        <div className="f full-width">
          <table className="product-details product-details--invenstory-record">
            <tbody>
              <tr className="product-details__row">
                <td className="product-details__label">Product:</td>
                <td className="product-details__value">{props.productName}</td>
              </tr>
              <tr className="product-details__row">
                <td className="product-details__label">Maker:</td>
                <td className="product-details__value">
                  <PlainLink to={props.productCreator.link}>
                    {props.productCreator.name}
                  </PlainLink>
                </td>
              </tr>
              {!!props.contactEmail && (
                <tr className="product-details__row">
                  <td className="product-details__label">Contact email:</td>
                  <td className="product-details__value">
                    <a
                      href={`mailto:${props.contactEmail}`}
                      className={"link"}
                      target={"_blank"}
                    >
                      {props.contactEmail}
                    </a>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PurchaseDialog
