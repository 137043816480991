import React, { Component } from "react"
import Dialog from "@material-ui/core/Dialog/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import { withSnackbar } from "notistack"
import getErrorText from "../../../../../library/constants/errorTypes"
import { bindActionCreators } from "redux"
import {
  bindFolder,
  foldersList,
  unbindFolder,
} from "../../../../../library/store/actions/creators/bookmarksCreators"
import { connect } from "react-redux"
import Loader from "../../../../../components/ui/loader/loader"
import FormGroup from "@material-ui/core/FormGroup/FormGroup"
import FormControl from "@material-ui/core/FormControl/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox/Checkbox"
import * as _ from "lodash"
import { createTheme } from "@material-ui/core/styles"
import { appTheme } from "../../../../../library/m-ui-theme"
import ThemeProvider from "@material-ui/styles/ThemeProvider"

const checkboxTheme = createTheme({
  ...appTheme,
})

class AddToFolder extends Component {

    state = {
        list: this.props.bookmarks.folders,
        dataReceived: true,
    };

    handleCheckboxChange = name => event => {
        this.setState({
            [name]: event.target.checked,
        });
    };

    composeValues = () => {
        const obj = this.state;

        return _.map(_.keys(obj).filter(key => !["list", "dataReceived"].includes(key) && obj[key] === true), _.parseInt);
    };

    addToFolder = async () => {
        const {bookmarks: {newestBookmark}} = this.props;

        try {
            await this.props.bindFolder({
                bookmark_id: newestBookmark,
                folders: this.composeValues(),
            });
            await this.props.handleClose();
        } catch ({error}) {
            this.props.enqueueSnackbar(getErrorText(error.code), {variant: "error"});
        }
    };

    render() {
        const {list, dataReceived} = this.state;
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="text-center">Bookmarks folders</DialogTitle>
                <DialogContent>
                    <ThemeProvider theme={checkboxTheme}>
                        {dataReceived ? (
                            <FormControl component="fieldset">
                                <FormGroup>
                                    {list.map(item => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state[String(item.id)]}
                                                    onChange={this.handleCheckboxChange(String(item.id))}
                                                    value={String(item.id)}
                                                />
                                            }
                                            label={item.name}
                                            key={item.id}
                                        />
                                    ))}
                                </FormGroup>
                            </FormControl>
                        ) : <Loader/>}
                    </ThemeProvider>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={this.props.handleClose}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={this.addToFolder}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = ({auth, bookmarks}) => ({auth, bookmarks});
const mapDispatchToProps = dispatch => bindActionCreators({foldersList, bindFolder, unbindFolder}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(AddToFolder));
