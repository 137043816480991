import { statesAndThames } from "../../../../../../library/constants/statesAndThames"
import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../../../../library/constants/routes"
import { standardsGrades } from "../../../../../../library/constants/educationalDictionary"
import LinesEllipsis from "react-lines-ellipsis"
import React from "react"
import { usePostCtx } from "../../PostContext"
import { standardsFilters } from "../../../../../../library/constants/filters/standardsFilters"

const SharedStandard = () => {
  const { post } = usePostCtx()

  const standard = post.shared_standard

  const statesList = standardsFilters()
  const state = statesList[standard.state - 1]

  return (
    <div className="shared_content">
      <div className="product-tile">
        <Link
          className="product-tile__name link"
          to={generatePath(routes.STANDARD, { standardId: standard.id })}
        >
          {standard.name}
        </Link>
        <table className="product-tile__info">
          <tbody>
            <tr>
              <td className={`product-tile__info-label pb4`}>State:</td>
              <td className={`product-tile__info-value pb4`}>{state.name}</td>
            </tr>
            <tr>
              <td className="product-tile__info-label">Subject:</td>
              <td className="product-tile__info-value">
                {state.subjects.find((s) => s.id === standard.subject).name}
              </td>
            </tr>
            <tr>
              <td className="product-tile__info-label">Grades:</td>
              <td className="product-tile__info-value">
                {standard.grades
                  .map((g) => standardsGrades.find((gr) => gr.id === g).name)
                  .join(", ")}
              </td>
            </tr>
          </tbody>
        </table>
        <LinesEllipsis
          text={standard.description}
          maxLine="3"
          ellipsis="..."
          trimRight
          basedOn="letters"
        />
      </div>
    </div>
  )
}

export default SharedStandard
