import React from "react"
import withScroll from "../../../../hocs/withScroll/withScroll"
import ListPageHeader from "./listPageHeader/listPageHeader"
import Loader from "../../../ui/loader"
import { LIMIT } from "../../../../library/constants/limits"
import { withSnackbar } from "notistack"
import getErrorText from "../../../../library/constants/errorTypes"
import { withRouter } from "react-router-dom"
import * as qs from "query-string"
import AlertDialog from "../../../ui/alertDialog"

class ListPage extends React.Component {
  state = {
    dataReceived: false,
    isFormDialogOpen: false,
    isInfoDialogOpen: false,
    isDeletionDialogOpen: false,
    activeItem: null,
    offset: 0,

    filterId: this.props?.filter?.initialValue || "",

    initialRender: true,
  }

  onFilterChange = (e) => {
    this.setState({ filterId: e.target.value })
  }

  fetchList = async (newOffset = 0) => {
    const { filter } = this.props
    const { q, ...searchParams } = qs.parse(this.props.location.search)

    try {
      this.setState({ dataReceived: false })
      if (
        !filter ||
        !filter?.required ||
        (filter?.required && !!filter?.list?.length)
      ) {
        await this.props.getList({
          filterId: this.state.filterId || undefined,
          name: q || undefined,
          offset: newOffset,
          searchParams: { ...searchParams },
        })
      }
      this.setState({ dataReceived: true, offset: newOffset })
    } catch (error) {
      console.log(error)
      this.props.enqueueSnackbar(getErrorText(error.error.code), {
        variant: "error",
      })
    }
  }

  async componentDidMount() {
    await this.props.clearList()
    await this.fetchList()
    this.setState({ initialRender: false })
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.location.search !== prevProps.location.search ||
      (this.state.filterId !== prevState.filterId &&
        !this.state.initialRender) ||
      this.props.match.path !== prevProps.match.path
    ) {
      this.props.clearList()
      await this.fetchList()
    }
  }

  async componentWillUnmount() {
    await this.props.clearList()
  }

  onScroll = async () => {
    if (this.state.dataReceived && !this.props.listEndReached) {
      await this.fetchList(this.state.offset + LIMIT)
    }
  }

  openFormDialog = (id = null) => {
    this.setState({
      activeItem: id,
      isFormDialogOpen: true,
    })
  }

  closeFormDialog = () => {
    this.setState({
      activeItem: null,
      isFormDialogOpen: false,
    })
  }

  openInfoDialog = (id) => {
    this.setState({
      activeItem: id,
      isInfoDialogOpen: true,
    })
  }

  closeInfoDialog = () => {
    this.setState({
      activeItem: null,
      isInfoDialogOpen: false,
    })
  }

  openDeletionDialog = (id) => {
    this.setState({
      activeItem: id,
      isDeletionDialogOpen: true,
    })
  }

  closeDeletionDialog = () => {
    this.setState({
      activeItem: null,
      isDeletionDialogOpen: false,
    })
  }

  render() {
    const {
      dataReceived,
      isFormDialogOpen,
      isInfoDialogOpen,
      isDeletionDialogOpen,
      activeItem,
    } = this.state

    const {
      title = "",
      creationButtonTitle,
      hasButton = true,
      noResultsLabel = "",
      list = [],
      getListItemComponent,
      getFormDialogComponent,
      getInfoDialogComponent,
      getDeletionDialogComponent,
      filter,
      ListContainer,
      Header,
    } = this.props

    const { q } = qs.parse(this.props.location.search)

    const listComposer = list.map(
      (item, index) =>
        getListItemComponent?.({
          item,
          index,
          openDeletionDialog: () => this.openDeletionDialog(item.id),
          closeDeletionDialog: this.closeDeletionDialog,
          openInfoDialog: () => this.openInfoDialog(item.id),
          closeInfoDialog: this.closeInfoDialog,
          openEditDialog: () => this.openFormDialog(item.id),
          closeEditDialog: this.closeFormDialog,
          filterId: this.state.filterId || undefined,
        }) ?? null
    )

    const composeDeletionDialog = () => {
      if (!getDeletionDialogComponent) return null

      const hasDeletionConfig = !!(
        getDeletionDialogComponent?.entityName &&
        getDeletionDialogComponent?.onDelete
      )

      const hasDeletionComponent = !!getDeletionDialogComponent.component

      if (hasDeletionConfig) {
        return (
          <AlertDialog
            open={isDeletionDialogOpen}
            handleClose={this.closeDeletionDialog}
            handleAccept={async () => {
              await getDeletionDialogComponent.onDelete(activeItem)
              this.closeDeletionDialog()
            }}
            title={`Delete this ${getDeletionDialogComponent.entityName}?`}
            message={`If you delete this ${getDeletionDialogComponent.entityName} you will not be able to restore it`}
          />
        )
      }

      if (hasDeletionComponent) {
        return getDeletionDialogComponent.component({
          open: isDeletionDialogOpen,
          onClose: this.closeDeletionDialog,
          activeItem,
        })
      }

      return null
    }

    return (
      <>
        {Header ? (
          <Header />
        ) : (
          <ListPageHeader
            title={title}
            buttonTitle={creationButtonTitle}
            buttonAction={() => this.openFormDialog()}
            hasButton={hasButton}
            noResults={dataReceived && !list.length}
            noResultsLabel={q ? "" : noResultsLabel}
            filter={{
              ...filter,
              onChange: this.onFilterChange,
              value: this.state.filterId,
            }}
          />
        )}

        {ListContainer ? (
          <ListContainer>{listComposer}</ListContainer>
        ) : (
          listComposer
        )}

        {!dataReceived && <Loader />}

        {isDeletionDialogOpen && composeDeletionDialog()}

        {isInfoDialogOpen &&
          !!getInfoDialogComponent &&
          getInfoDialogComponent({
            open: isInfoDialogOpen,
            onClose: this.closeInfoDialog,
            activeItem,
          })}

        {isFormDialogOpen &&
          !!getFormDialogComponent &&
          getFormDialogComponent({
            open: isFormDialogOpen,
            onClose: this.closeFormDialog,
            activeItem,
            filterId: this.state.filterId || undefined,
          })}
      </>
    )
  }
}

export default withRouter(withSnackbar(withScroll(ListPage)))
