import React from "react"
import Dialog from "@material-ui/core/Dialog/Dialog"
import { generatePath, Redirect, useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import BookMeetStep1 from "./bookMeetStep1"
import BookMeetStep2 from "./bookMeetStep2"
import BookMeetStep3 from "./bookMeetStep3"
import BookMeetStep4 from "./bookMeetStep4"
import BookMeetStep5 from "./bookMeetStep5"
import { useBookMeet } from "./bookMeetContext"
import { USER } from "../../library/constants/routes"
import { useAlert } from "../../hooks/useAlert"

const BookMeetModal = () => {
  const { errorAlert } = useAlert()
  const { isLoggedIn, userData } = useSelector(({ auth }) => auth)

  const { user, step, onClose } = useBookMeet()

  if (isLoggedIn && userData.id === user.id) {
    errorAlert("You can't book a meet with yourself")
    return <Redirect to={generatePath(USER, { userId: userData.id })} />
  }

  const renderStep = () => {
    switch (step) {
      case 1:
        return <BookMeetStep1 />

      case 2:
        return <BookMeetStep2 />

      case 3:
        return <BookMeetStep3 />

      case 4:
        return <BookMeetStep4 />

      case 5:
        return <BookMeetStep5 />

      default:
        return null
    }
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth={step === 3 || step === 4 ? "md" : "sm"}
      fullWidth
    >
      {renderStep()}
    </Dialog>
  )
}

export default BookMeetModal
