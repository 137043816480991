import React from "react"
import styled from "@emotion/styled"
import { colors } from "../../../../../library/constants/styles/colors"
import { blinkAnimation } from "../../../../../library/constants/styles/animation"
import { TextLineSkeleton } from "../components/textSkeleton"

const Container = styled.div`
  border-radius: 2px;
  background-color: ${colors.white};
  box-shadow: 0 0 2px 0 rgba(210, 210, 210, 0.5);
`

const TopArea = styled.div`
  padding: 1rem;
  background-color: ${colors.white};
  background: linear-gradient(
    to right,
    ${colors.black7} 0%,
    ${colors.black4} 50%,
    ${colors.black7} 100%
  );
  background-size: 150% 150%;
  animation: ${blinkAnimation} 2s ease-in-out infinite;
`

const Role = styled.div`
  //position: absolute;
  display: flex;
  align-items: center;
  width: 100px;
  height: 44px;
  padding: 10px 12px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.7);
`

const AvatarContainer = styled.div`
  position: absolute;
  background-color: ${colors.white};
  border: 2px solid ${colors.white};
  border-radius: 50%;
  height: 150px;
  width: 150px;
  top: 0;
  left: 1rem;
  transform: translateY(calc(-50%));
  overflow: hidden;
`

const Avatar = styled.div`
  height: 100%;
  width: 100%;
  background: linear-gradient(
    to right,
    ${colors.black7} 0%,
    ${colors.black4} 50%,
    ${colors.black7} 100%
  );
  background-size: 150% 150%;
  animation: ${blinkAnimation} 2s ease-in-out infinite;
`

const BottomArea = styled.div`
  position: relative;
  //display: flex;
  //align-items: flex-end;
  //justify-content: center;
  min-height: 155px;
  padding: 1rem;
  background-color: ${colors.white};
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  //align-items: center;
  width: 280px;
`

const ProfileHeaderSkeleton = () => {
  return (
    <Container>
      <TopArea className={"aspect-page-banner"}>
        <Role>
          <TextLineSkeleton height={"20px"} />
        </Role>
      </TopArea>
      <BottomArea>
        <AvatarContainer>
          <Avatar />
        </AvatarContainer>
        <div className={"mt-28"}>
          <TextContainer>
            <TextLineSkeleton height={"22px"} width={"75%"} />
          </TextContainer>
          <div className="flex justify-between w-full mt-4">
            <TextContainer>
              <TextLineSkeleton height={"1rem"} />
              <TextLineSkeleton height={"1rem"} width={"70%"} />
              <TextLineSkeleton height={"1rem"} width={"80%"} />
            </TextContainer>
            <div className={"w-32"}>
              <TextLineSkeleton height={"1rem"} />
              <TextLineSkeleton height={"1rem"} />
              <TextLineSkeleton height={"1rem"} />
            </div>
          </div>
        </div>
      </BottomArea>
    </Container>
  )
}

export default ProfileHeaderSkeleton
