import React, { useEffect, useState } from "react"
import {
  addWork,
  deleteWork,
  getData,
  updateWork,
} from "../../../../../library/store/actions/creators/authCreators"
import { useDispatch, useSelector } from "react-redux"
import {
  MAX_DATE,
  MAX_DATE_ISO,
  MIN_DATE,
  MIN_DATE_ISO,
} from "../../../../../library/constants/defaultDates"
import BoxPage from "../../../../../components/v2/layout/boxPage/boxPage"
import WorkForm from "./workForm/workForm"
import { PlainButton } from "../../../../../components/v2/controls"
import { Divider } from "../../../../../components/v2/layout/forms/form/form"
import { key } from "../../../../../library/utils/generateKey"
import { useAlert } from "../../../../../hooks/useAlert"

const newWork = {
  id: "",
  name: "",
  role: "",
  country: "",
  city: "",
  description: "",
  date_from: null,
  date_to: null,
}

const ProfileEditWork = () => {
  const worksLimit = 20

  const { userData } = useSelector(({ auth }) => auth)
  const dispatch = useDispatch()
  const { successAlert, errorAlert } = useAlert()

  const [records, setRecords] = useState(
    userData.works.length ? userData.works : [newWork]
  )

  useEffect(() => {
    setRecords(userData.works)
  }, [userData.works])

  const processAddWork = async (values) => {
    try {
      await dispatch(
        addWork({
          ...values,
          id: undefined,
          date_from:
            values.date_from === null
              ? MIN_DATE_ISO
              : new Date(values.date_from).toISOString(),
          date_to:
            values.date_to === null
              ? MAX_DATE_ISO
              : new Date(values.date_to).toISOString(),
        })
      )
      await dispatch(getData())
      successAlert()
    } catch (error) {
      errorAlert(error)
    }
  }

  const processDeleteWork = async (id) => {
    try {
      await dispatch(deleteWork(id))
      await dispatch(getData())
      successAlert()
    } catch (error) {
      errorAlert(error)
    }
  }

  const processUpdateWork = async (values) => {
    try {
      await dispatch(
        updateWork({
          ...values,
          date_from:
            values.date_from === null
              ? MIN_DATE
              : new Date(values.date_from).toISOString(),
          date_to:
            values.date_to === null
              ? MAX_DATE
              : new Date(values.date_to).toISOString(),
        })
      )
      await dispatch(getData())
      successAlert()
    } catch (error) {
      errorAlert(error)
    }
  }

  const handleAdd = () => setRecords((prevState) => [...prevState, newWork])

  const handleDelete = (idx) => {
    if (!records[idx].id) {
      return setRecords((prevState) => prevState.filter((w, i) => idx !== i))
    }

    return processDeleteWork(records[idx].id)
  }

  const onSubmit = async (work) => {
    if (work.id) return await processUpdateWork(work)

    return await processAddWork(work)
  }

  return (
    <BoxPage title={'Work'}>
      {records.map((work, idx) => (
        <div key={work.id ? work.id : key()}>
          <WorkForm
            work={work}
            onSubmit={onSubmit}
            onDelete={() => handleDelete(idx)}
          />
          {idx + 1 < worksLimit && <Divider className={'mt15'} />}
        </div>
      ))}
      {records.length < worksLimit && (
        <div className="f jcc">
          <PlainButton actionType={'add'} onClick={handleAdd}>
            Add another workplace
          </PlainButton>
        </div>
      )}
    </BoxPage>
  )
}

export default ProfileEditWork
