export const userRolesNames = {
  Learner: {
    id: 1,
    description: "I am currently a student or parent of one.",
  },
  Educator: {
    id: 2,
    description:
      "I am en educator, administrator, tutor or other school representative as well as learner. I may use Calendar, create Knowmeets, Courses, Creds, and publish paid or free products for my group or the global market.",
  },
  // Admin: {
  //     id: 3,
  //     description: "I work in an organization."
  // },
  Agent: {
    id: 4,
    description:
      'I am with a company or work as a free representative that may create both my own profile and a company group or interest group, post products to the global market, use the "Schedule Demo" function, make Courses for training, post news and find new friends.',
  },
}

export const userRolesIds = {
  1: {
    name: "Learner",
    description: "I am currently a student or parent of one.",
  },
  2: {
    name: "Educator",
    description:
      "I am en educator, administrator, tutor or other school representative as well as learner. I may use Calendar, create Knowmeets, Courses, Creds, and publish paid or free products for my group or the global market.",
  },
  3: {
    name: "Admin",
    description: "I work in an organization.",
  },
  4: {
    name: "Company Representative",
    description:
      'I am with a company or work as a free representative that may create both my own profile and a company group or interest group, post products to the global market, use the "Schedule Demo" function, make Courses for training, post news and find new friends.',
  },
}

export const groupRolesIds = {
  1: "Member",
  2: "Administrator",
}

export const groupRoles = {
  member: {
    name: "Member",
    id: 1,
  },
  admin: {
    name: "Administrator",
    id: 2,
  },
}

export const groupMemberTags = [
  {
    id: 1,
    name: "Follower",
  },
  {
    id: 2,
    name: "Teacher",
  },
  {
    id: 3,
    name: "Learner",
  },
  {
    id: 4,
    name: "Staff",
  },
  {
    id: 5,
    name: "Sales manager",
  },
]

export const groupMemberTagsIds = {
  1: "Follower",
  2: "Teacher",
  3: "Learner",
  4: "Staff",
  5: "Sales manager",
}

export const GROUP_OCCUPATION_TYPES = {
  Follower: 1,
  Staff: 2,
  Teacher: 3,
  Student: 4,
  Parent: 5,
  Customer: 6,
}

export const GROUP_OCCUPATION_TYPE_IDS = {
  1: "Follower",
  2: "Staff",
  3: "Teacher",
  4: "Student",
  5: "Parent",
  6: "Customer",
}

const getOccupations = (list) => {
  return list.map((item) => GROUP_OCCUPATION_TYPES[item])
}

export const GROUP_OCCUPATION_TYPES_BY_GROUP_TYPE = {
  1: getOccupations(["Follower", "Staff"]),
  2: getOccupations(["Follower", "Staff"]),
  3: getOccupations(["Follower", "Staff", "Customer"]),
  4: getOccupations(["Follower", "Staff", "Parent", "Teacher", "Student"]),
  5: getOccupations(["Follower", "Staff", "Teacher", "Student"]),
  7: getOccupations(["Follower", "Staff", "Parent", "Teacher", "Student"]),
}
