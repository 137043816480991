import React from "react"
import "./searchForm.scss"
import SearchOutlined from "@material-ui/icons/SearchOutlined"
import { SEARCH } from "../../../../library/constants/routes"
import { useHistory } from "react-router-dom"

const SearchForm = () => {
  const history = useHistory()
  const [search, setSearch] = React.useState("")

  const processSearch = (event) => {
    event.preventDefault()

    history.push({ pathname: SEARCH, search: `?name=${search}` })

    return setSearch("")
  }

  return (
    <form onSubmit={processSearch}>
      <div className="search-form">
        <div className="search-form__icon">
          <SearchOutlined />
        </div>
        <input
          type="text"
          id="search"
          name="search"
          placeholder="Search"
          className="search-form__field"
          autoComplete="off"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
    </form>
  )
}

export default SearchForm
