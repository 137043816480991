import React from "react"
import { TextLineSkeleton } from "./textSkeleton"
import Box from "../../../../ui/box/box"

const EntityOperationsSkeleton = () => {
  return (
    <Box>
      <TextLineSkeleton width={"70%"} />
      <TextLineSkeleton width={"70%"} />
      <TextLineSkeleton width={"30%"} />
    </Box>
  )
}

export default EntityOperationsSkeleton
