import React, { Component } from "react"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp"
import styled from "@emotion/styled"
import { colors } from "../../../library/constants/styles/colors"

const Container = styled.div`
  border-radius: 2px;
  background-color: ${colors.white};
  box-shadow: 0 0 2px 0 rgba(210, 210, 210, 0.5);
`

const Heading = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => !props.isOpen ? "15px" : "15px 15px 0"};
  color: ${colors.primary};
  cursor: pointer;
  
  svg {
    color: currentColor;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 15px;
  
  > * {
    width: 100%;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 15px;
`;

class VerticalMenuCollapsible extends Component {
    state = {
        open: this.props.open || false,
    };

    toggleVisibility = () => {
        this.setState({open: !this.state.open});
    };

    render() {
        const {title, body, footer} = this.props;
        const {open} = this.state;

        return (
            <Container>
                <Heading isOpen={open} onClick={this.toggleVisibility}>
                    <span>{title}</span>
                    {!open ? (
                        <KeyboardArrowDown className="color-black-38"/>
                    ) : (
                        <KeyboardArrowUp className="color-black-38"/>
                    )}
                </Heading>
                {open && (
                    <Content>
                        {body}
                        <Footer>{footer}</Footer>
                    </Content>
                )}
            </Container>
        );
    }
}

export default VerticalMenuCollapsible;
