import React, { Component } from "react"
import ListSearchHeader from "../../../../../../components/ui/listSearchHeader/listSearchHeader"
import ProductsListItem from "../../../../marketplace/productsList/productsListItem/productsListItem"
import Loader from "../../../../../../components/ui/loader/loader"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { updateGroup } from "../../../../../../library/store/actions/creators/groupsCreators"
import {
  clearProductsList,
  getProductsList,
} from "../../../../../../library/store/actions/creators/productsCreators"
import * as qs from "query-string"
import { LIMIT } from "../../../../../../library/constants/limits"
import withScroll from "../../../../../../hocs/withScroll/withScroll"
import getErrorText from "../../../../../../library/constants/errorTypes"
import ProductCreate from "../../../../marketplace/productCreate/productCreate"
import * as routes from "../../../../../../library/constants/routes"
import { generatePath } from "react-router-dom"

class GroupSettingsProducts extends Component {
  state = {
    list: [],
    dataReceived: false,
    modalOpen:
      (!!this.props.history.location.state &&
        this.props.history.location.state.open) ||
      false,
  }

  async componentDidMount() {
    const {
      match: { params },
      location: { search },
    } = this.props
    const { q: query } = qs.parse(search)
    try {
      await this.props.getProductsList({
        group_id: parseInt(params.groupId),
        name: query || undefined,
      })
      this.setState({
        list: this.props.products.list,
        dataReceived: true,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      match: { params },
      location: { search },
    } = this.props
    const { q: query } = qs.parse(search)

    if (search && search !== prevProps.location.search) {
      this.props.clearProductsList()
      this.setState({ dataReceived: false, list: [] })
      await this.props.getProductsList({
        group_id: parseInt(params.groupId),
        name: query || undefined,
      })
      this.setState({ dataReceived: true, list: this.props.products.list })
    }
  }

  handleModalOpen = () => {
    const group = this.props.groups.currentGroup
    this.props.history.push(
      generatePath(routes.GROUP_SETTINGS_PRODUCTS, { groupId: group.id }),
      { open: true }
    )
    this.setState({ modalOpen: true })
  }

  handleModalClose = () => {
    const group = this.props.groups.currentGroup
    this.props.history.push(
      generatePath(routes.GROUP_SETTINGS_PRODUCTS, { groupId: group.id }),
      { open: false }
    )
    this.setState({ modalOpen: false })
  }

  searchProducts = (name) => {
    const { history } = this.props

    history.push({
      search: `?q=${name}`,
    })
  }

  onScroll = async () => {
    if (!this.state.dataReceived) return
    const {
      match: { params },
      location: { search },
    } = this.props
    const { q: query } = qs.parse(search)
    this.setState({ dataReceived: false })
    await this.props.getProductsList({
      group_id: parseInt(params.groupId),
      name: query || undefined,
      offset: this.state.list.length,
      limit: LIMIT,
    })
    this.setState({ dataReceived: true, list: this.props.products.list })
  }
  updateProductList = async () => {
    this.setState({ dataReceived: false })
    const {
      match: { params },
      location: { search },
    } = this.props
    const { q: query } = qs.parse(search)
    this.props.clearProductsList()
    await this.props.getProductsList({
      group_id: parseInt(params.groupId),
      name: query || undefined,
      offset: 0,
      limit:
        this.state.list.length > LIMIT ? this.state.list.length + 1 : LIMIT,
    })
    this.setState({ dataReceived: true, list: this.props.products.list })
  }

  afterProductCreate = async () => {
    this.handleModalClose()
    await this.updateProductList()
  }

  componentWillUnmount() {
    this.props.clearProductsList()
  }

  render() {
    const { list, dataReceived, modalOpen } = this.state
    const {
      location: { search },
    } = this.props
    const { q: query } = qs.parse(search)
    const group = this.props.groups.currentGroup
    return (
      <>
        <ListSearchHeader
          title="Products"
          placeholder="Start typing product name"
          search={this.searchProducts}
          hasButton={true}
          buttonLabel="Create product or Course ad tile"
          buttonAction={this.handleModalOpen}
          noResults={dataReceived && !list.length}
          noResultsLabel={query ? "" : "This group doesn't have products yet"}
        />
        {modalOpen && (
          <ProductCreate
            open={modalOpen}
            fields={this.props.history.location.state}
            onClose={this.handleModalClose}
            group={{ ...group }}
            afterProductCreate={this.afterProductCreate}
          />
        )}
        <div className="products-list">
          {list.map((item) => (
            <ProductsListItem
              product={item}
              group={this.props.groups.currentGroup}
              manageable={true}
              key={item.id}
            />
          ))}
        </div>

        {!dataReceived && <Loader />}
      </>
    )
  }
}

const mapStateToProps = ({ auth, groups, products }) => ({
  auth,
  groups,
  products,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { updateGroup, getProductsList, clearProductsList },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withScroll(GroupSettingsProducts)))
