import { call } from "../../../networking/API"
import { FRIENDS } from "../types/friendsTypes"

export const getFriendsList = ({ user_id, name, offset = 0, limit = 50 }) => {
  return async (dispatch, getState) => {
    const response = await call(FRIENDS.LIST, {
      token: getState().auth.userData.token,
      user_id: user_id || getState().auth.userData.id,
      name: !!name ? name : undefined,
      offset,
      limit,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: FRIENDS.LIST,
        payload: json,
      })
    } else {
      throw json.error
    }
  }
}

export const updateFriendsList = ({user_id, name, offset = 0, limit = 50}) => {
    return async (dispatch, getState) => {
        const response = await call(FRIENDS.LIST, {
            token: getState().auth.userData.token,
            user_id: user_id || getState().auth.userData.id,
            name: !!name ? name : undefined,
            offset,
            limit,
        });
        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: FRIENDS.LIST_UPDATE,
                payload: json,
            });
        } else {
            throw json.error;
        }
    };
};

export const getFriendsRequestsList = ({type, name, offset = 0, limit = 100}) => {
    return async (dispatch, getState) => {
        dispatch({type: FRIENDS.REQUESTS.REFRESHING});

        const response = await call(FRIENDS.REQUESTS.LIST, {
            token: getState().auth.userData.token,
            type,
            name,
            offset,
            limit,
        });
        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: type === 1 ? FRIENDS.REQUESTS.PENDING : FRIENDS.REQUESTS.OUTGOING,
                payload: json,
            });
        } else {
            throw json.error;
        }
    };
};


export const updateFriendsRequestsList = ({type, name, offset = 0, limit = 100}) => {
    return async (dispatch, getState) => {
        dispatch({type: FRIENDS.REQUESTS.REFRESHING});

        const response = await call(FRIENDS.REQUESTS.LIST, {
            token: getState().auth.userData.token,
            type,
            name,
            offset,
            limit,
        });
        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: type === 1 ? FRIENDS.REQUESTS.UPDATE_PENDING : FRIENDS.REQUESTS.UPDATE_OUTGOING,
                payload: json,
            });
        } else {
            throw json.error;
        }
    };
};

export const searchFriends = ({name, offset = 0, limit = 15}) => {
    return async (dispatch, getState) => {
        const response = await call(FRIENDS.SEARCH, {
            token: getState().auth.userData.token,
            name,
            offset,
            limit,
        });
        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: FRIENDS.SEARCH,
                payload: json,
            });
        } else {
            throw json.error;
        }
    };
};

export const sendFriendRequest = (user_id) => {
    return async (dispatch, getState) => {
        const response = await call(FRIENDS.REQUESTS.SEND, {
            token: getState().auth.userData.token,
            user_id,
        });
        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: FRIENDS.REQUESTS.SEND,
            });
        } else {
            throw json.error;
        }
    };
};

export const cancelFriendRequest = (user_id) => {
    return async (dispatch, getState) => {
        const response = await call(FRIENDS.REQUESTS.CANCEL, {
            token: getState().auth.userData.token,
            user_id,
        });
        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: FRIENDS.REQUESTS.CANCEL,
            });
        } else {
            throw json.error;
        }
    };
};

export const acceptFriendRequest = (user_id) => {
    return async (dispatch, getState) => {
        const response = await call(FRIENDS.REQUESTS.ACCEPT, {
            token: getState().auth.userData.token,
            user_id,
        });
        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: FRIENDS.REQUESTS.ACCEPT,
            });
        } else {
            throw json.error;
        }
    };
};

export const declineFriendRequest = (user_id) => {
    return async (dispatch, getState) => {
        const response = await call(FRIENDS.REQUESTS.DECLINE, {
            token: getState().auth.userData.token,
            user_id,
        });
        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: FRIENDS.REQUESTS.DECLINE,
            });
        } else {
            throw json.error;
        }
    };
};

export const deleteFriend = (user_id) => {
    return async (dispatch, getState) => {
        const response = await call(FRIENDS.DELETE, {
            token: getState().auth.userData.token,
            user_id,
        });
        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: FRIENDS.DELETE,
            });
        } else {
            throw json.error;
        }
    };
};

export const clearList = () => {
    return {
        type: FRIENDS.CLEAR,
    };
};

export const inviteFriendByEmail = (email) => {
    return async (dispatch, getState) => {
        const response = await call(FRIENDS.INVITE_BY_EMAIL, {
            token: getState().auth.userData.token,
            email,
        });
        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: FRIENDS.INVITE_BY_EMAIL,
            });
        } else {
            throw json.error;
        }
    };
};
