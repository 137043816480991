import interestsIcon from "../../assets/img/group-icons/regular-group-icon.svg"
import eventIcon from "../../assets/img/group-icons/event-group-icon.svg"
import companyIcon from "../../assets/img/group-icons/company-icon.svg"
import schoolIcon from "../../assets/img/group-icons/school-icon.svg"
import districtIcon from "../../assets/img/group-icons/district-icon.svg"
import oneTeacherSchoolIcon from "../../assets/img/group-icons/one-teacher-school-icon.svg"

export const GROUP_TYPES = {
  1: {
    title: "Interest group",
    subtitle:
      "Types of interest group Types of interest group Types of interest group Types of interest group Types of interest group",
    defaultLogo: interestsIcon,
  },
  2: {
    title: "Event",
    subtitle:
      "Types of events Types of events Types of events Types of events Types of events Types of events Types of events",
    defaultLogo: eventIcon,
  },
  3: {
    title: "Company",
    subtitle:
      "Types of companies Types of companies Types of companies Types of companies Types of companies",
    defaultLogo: companyIcon,
  },
  4: {
    title: "School",
    defaultLogo: schoolIcon,
  },
  5: {
    title: "District",
    defaultLogo: districtIcon,
  },
  7: {
    title: "One teacher school",
    compactTitle: "School",
    defaultLogo: oneTeacherSchoolIcon,
  },
}

export const rotationTypes = [
  {
    id: 1,
    name: "On-Campus",
  },
  {
    id: 2,
    name: "Virtual",
  },
  {
    id: 3,
    name: "Remote Home Study",
  },
  {
    id: 4,
    name: "Off",
  },
]

export const rotations = {
  1: "On-Campus",
  2: "Virtual",
  3: "Remote Home Study",
  4: "Off",
}
