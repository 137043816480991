import React from "react"
import BoxPage from "../../../../../../components/v2/layout/boxPage/boxPage"
import SuperDataTable from "../../../../../../components/v2/dataTable/SuperDataTable"
import { KNOWMIX } from "../../../../../../library/store/actions/types/knowmixTypes"
import { getObjectById } from "../../../../../../library/utils/arrays"
import { calendarGrades } from "../../../../../../library/constants/educationalDictionary"
import GroupLink from "../../../../../../components/v2/utils/GroupLink"
import UserLink from "../../../../../../components/v2/utils/UserLink"
import { knowmixTypes } from "../../../../../../library/constants/knowmix"
import { generatePath } from "react-router-dom"
import * as routes from "../../../../../../library/constants/routes"
import { KeyboardArrowRightOutlined } from "@material-ui/icons"
import Select from "@material-ui/core/Select/Select"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl/FormControl"
import { InputLabel } from "@material-ui/core"

const LearnerTrackingCoursesAll = () => {
  const [type, setType] = React.useState("")

  return (
    <BoxPage title={"My Courses"}>
      <SuperDataTable
        columns={[
          { label: "Name", role: "name" },
          { label: "Created by" },
          { label: "Subject" },
          { label: "Status" },
          { label: "", role: "actions" },
        ]}
        queryConfig={{
          key: [
            {
              type: "learner-courses-tracking",
              url: KNOWMIX.LIST,
              fetchParams: {
                managed: false,
                active: false,
                finished: false,
                graded: false,
                types: type ? [Number(type)] : undefined,
              },
            },
          ],
          listKey: "knowmixes",
          counterKey: "knowmixes_number",
          composeRowData: (record) => [
            record.name || "",
            {
              type: "component",
              component: record.creator_group
                ? () => <GroupLink group={record.creator_group} />
                : () => <UserLink user={record.creator_user} />,
            },
            {
              type: "component",
              component: () => {
                return (
                  <p>
                    {record?.subject?.id ? (
                      <>
                        <span className={""}>{record?.subject?.name}</span>
                        <br />
                        <span className={"text-xs"}>
                          Grade:{" "}
                          {
                            getObjectById(calendarGrades, record.subject.grade)
                              .name
                          }
                        </span>
                      </>
                    ) : (
                      <span className={"color-black-54"}>—</span>
                    )}
                  </p>
                )
              },
            },
            knowmixTypes[record.type],
          ],
        }}
        customColumnsWidth
        isSearchable
        actions={(record) => [
          {
            type: "link",
            path: generatePath(routes.KNOWMIX, { knowmixId: record.id }),
            label: () => (
              <>
                Open Course <KeyboardArrowRightOutlined />
              </>
            ),
          },
        ]}
        getFilterComponent={() => {
          return (
            <FormControl className={"w-32"}>
              <InputLabel shrink id="types-filter-label">
                Status
              </InputLabel>
              <Select
                id="types-filter"
                name="typesFilter"
                fullWidth
                displayEmpty
                value={type}
                onChange={(event) => setType(event.target.value)}
              >
                <MenuItem value="">All</MenuItem>
                {Object.entries(knowmixTypes).map(([key, value]) => (
                  <MenuItem value={key} key={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )
        }}
      />
    </BoxPage>
  )
}

export default LearnerTrackingCoursesAll
