import React from "react"
import { Checkbox as MuiCheckbox, FormControlLabel } from "@material-ui/core"
import { useField } from "formik"

const Checkbox = (props) => {
  const [field, meta] = useField(props)

  return (
    <FormControlLabel
      control={
        <MuiCheckbox
          color="primary"
          checked={field.value}
          {...field}
          onChange={props.onChange ? props.onChange : field.onChange}
        />
      }
      label={props.label}
    />
  )
}

export default Checkbox
