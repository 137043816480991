import React from "react"
import SidebarListSkeleton from "../components/sidebarListSkeleton"
import EntityOperationsSkeleton from "../components/entityOperationsSkeleton"
import { TextLineSkeleton } from "../components/textSkeleton"
import { LogoSkeleton } from "../components/LogoSkeleton"
import { PageRightSidebar } from "../../layout"

const GroupSidebarSkeleton = () => {
  return (
    <PageRightSidebar>
      <div className="box p15 f fdc aic">
        <LogoSkeleton className={"mb15"} />
        <TextLineSkeleton height={"16px"} />
        <TextLineSkeleton height={"16px"} width={"33%"} />
      </div>
      <EntityOperationsSkeleton />
      <SidebarListSkeleton />
    </PageRightSidebar>
  )
}

export default GroupSidebarSkeleton
