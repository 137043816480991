import React from "react"
import styled from "@emotion/styled"
import { colors } from "../../../../library/constants/styles/colors"
import PropTypes from "prop-types"
import { AddOutlined, DeleteForeverOutlined } from "@material-ui/icons"
import EditOutlined from "@material-ui/icons/EditOutlined"
import isPropValid from "@emotion/is-prop-valid"

const Button = styled("button", {
  shouldForwardProp: (prop) => isPropValid(prop),
})`
  display: ${(props) => (props.inline ? "inline-flex" : "flex")};
  align-items: center;
  transition: all 0.2s;
  color: ${(props) => (!props.disabled ? colors.primary : colors.black54)};
  text-align: left;
  font-size: ${(props) => (props.compact ? "14px" : "16px")};
  word-break: break-all;
  font-weight: ${(props) => (props.bold ? 500 : 400)};
  cursor: ${(props) => (!props.disabled ? "pointer" : "auto")};

  &:hover {
    text-decoration: ${(props) => (!props.disabled ? "underline" : "none")};
  }

  & > svg {
    margin-right: 3px;
    font-size: ${(props) => (props.compact ? "18px" : "24px")};
  }
`

const PlainButton = ({
  type,
  onClick,
  disabled,
  className,
  actionType,
  actionText,
  bold = false,
  inline = false,
  compact = false,
  children,
}) => {
  const getContent = () => {
    switch (actionType) {
      case "delete":
        return (
          <>
            <DeleteForeverOutlined /> {children || " Delete"}
          </>
        )
      case "add":
        return (
          <>
            <AddOutlined /> {children || " Add"}
          </>
        )
      case "edit":
        return (
          <>
            <EditOutlined /> {children || " Edit"}
          </>
        )
      default:
        return children || "BUTTON"
    }
  }

  return (
    <Button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={className}
      bold={bold}
      inline={inline}
      compact={compact}
    >
      {getContent()}
    </Button>
  )
}

PlainButton.propTypes = {
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  actionType: PropTypes.node,
  bold: PropTypes.bool,
  inline: PropTypes.bool,
  children: PropTypes.node,
}

PlainButton.defaultProps = {
  type: "button",
  disabled: false,
  bold: false,
  inline: false,
  className: "",
}

export default PlainButton
