import React from "react"
import logo from "../../assets/img/logo.svg"
import { Link, withRouter } from "react-router-dom"
import styled from "@emotion/styled"
import { shadows } from "../../library/constants/styles/shadows"
import { colors } from "../../library/constants/styles/colors"
import { devices } from "../../library/constants/styles/responsive"
import * as routes from "../../library/constants/routes"
import { PROMO } from "../../library/constants/routes"
import Button from "@material-ui/core/Button/Button"
import PlainLink from "../ui/link"

const Container = styled.header`
  @supports (display: grid) {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1200px) 1fr;
  }

  display: flex;
  position: relative;
  z-index: 1000;
  justify-content: center;
  box-shadow: ${shadows.layout};
  background-color: ${colors.white};
`

const Content = styled.div`
  @supports (display: grid) {
    grid-column: 2 / -2;
    width: auto;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  width: 1200px;

  @media ${devices.xs} {
    padding: 15px;
  }
`

const LogoLink = styled(Link)`
  display: block;
  color: ${colors.grey7};
  font-size: 36px;
  font-weight: 500;

  img {
    height: 80px;
  }
`

const Header = (props) => {
  const { isLoggedIn } = props

  const pathName = window.location.pathname

  const AuthContent = () => {
    if (isLoggedIn) {
      return null
    }

    switch (pathName) {
      case "/":
        return (
          <div className="flex items-center gap-2">
            <span className={"text-primary md:text-lg leading-none"}>
              Free & Premium Accounts
            </span>
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to={routes.SIGN_UP}
            >
              Sign Up
            </Button>
            <Button
              color="primary"
              variant="outlined"
              component={Link}
              to={routes.SIGN_IN}
            >
              Sign In
            </Button>
          </div>
        )

      case "/sign-up":
        return (
          <Button
            component={Link}
            to={{ pathname: routes.SIGN_IN, state: undefined }}
            variant="contained"
            color="primary"
          >
            Sign In
          </Button>
        )

      case "/sign-in":
        return (
          <Button
            component={Link}
            to={routes.SIGN_UP}
            variant="contained"
            color="primary"
          >
            Sign Up
          </Button>
        )

      case "/reset-password":
        return (
          <Button
            component={Link}
            to={routes.SIGN_UP}
            variant="contained"
            color="primary"
          >
            Sign Up
          </Button>
        )

      case "/confirmPasswordReset":
        return (
          <Button
            component={Link}
            to={routes.SIGN_IN}
            variant="contained"
            color="primary"
          >
            Sign In
          </Button>
        )

      default:
        return (
          <div className="flex items-center gap-2">
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to={routes.SIGN_UP}
            >
              Sign Up
            </Button>
            <Button
              color="primary"
              variant="outlined"
              component={Link}
              to={routes.SIGN_IN}
            >
              Sign In
            </Button>
          </div>
        )
    }
  }

  return (
    <header className={"sticky top-0 z-[101]"}>
      <div
        className={
          "grid grid-cols-base-layout bg-white shadow-small z-100 relative"
        }
      >
        <div className="col-start-2 col-span-1 flex justify-between items-center w-full py-1 px-4 container">
          <Link to="/">
            <img src={logo} alt="Logo" className={"h-16"} />
          </Link>
          <AuthContent />
        </div>
      </div>
      {pathName === "/" && (
        <div className="grid grid-cols-[1fr_minmax(min-content,_1200px)_1fr] w-full py-2 bg-grey7 shadow-layout">
          <div className="col-start-2 pl15 pr15 flex items-center justify-between gap-8 w-full container">
            <nav className={"mx-auto"}>
              <ul className={"list-none flex gap-6 text-lg"}>
                <li>
                  <a
                    href={"#member-types"}
                    className={
                      "text-primary transition duration-200 transition-all hover:underline"
                    }
                  >
                    Member Types
                  </a>
                </li>
                <li>
                  <PlainLink to={routes.SOLUTIONS}>Solutions</PlainLink>
                </li>
                <li>
                  <PlainLink to={routes.PRICING}>Pricing</PlainLink>
                </li>
                <li>
                  <PlainLink to={routes.ACCOUNTS_INFO}>Get Started</PlainLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      )}
    </header>
  )
}

export default withRouter(Header)
