import React from "react"
import styled from "@emotion/styled"
import { colors } from "../../../library/constants/styles/colors"
import bannerImg from "../../../assets/img/pages/uknow-info/new/banner.jpg"
import img1 from "../../../assets/img/pages/uknow-info/new/img1.png"
import img2 from "../../../assets/img/pages/uknow-info/new/img2.png"
import img3 from "../../../assets/img/pages/uknow-info/new/img3.png"
import img4 from "../../../assets/img/pages/uknow-info/new/img4.png"
import img5 from "../../../assets/img/pages/uknow-info/new/img5.png"
import { devices } from "../../../library/constants/styles/responsive"
import { Link } from "react-router-dom"
import {
  CONTACT_FORM,
  HOW_TO,
  PRICING,
} from "../../../library/constants/routes"
import { Button } from "@material-ui/core"
import { mq } from "../../../theme/globalStyle"

const Section = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 60px 0;
  background-color: ${(props) => (props.isDark ? colors.grey7 : colors.white)};

  @media ${devices.xs} {
    padding-left: 10px;
    padding-right: 10px;
  }
`

export const SectionHeading = styled.h2`
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 15px;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 70px;
    height: 4px;
    transform: translateX(-50%);
    background-color: ${colors.secondary};
  }
`

export const Text = styled.p`
  max-width: 970px;
  text-align: center;
`

const Img = styled.img`
  max-width: calc(970px * 0.8);

  @media ${devices.xs} {
    max-width: 100%;
  }
`

const ImgSection = styled.section`
  width: 100%;
  height: 460px;
  padding-top: 170px;
  background-image: url(${bannerImg});
  background-size: cover;
  background-position: center;
  text-align: center;
  font-size: 34px;
  color: ${colors.white};
  text-transform: uppercase;
  line-height: normal;
  font-weight: 500;
`

const ListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${(props) => props.width || "calc(970px * 0.8)"};
  margin-top: 30px;

  @media ${devices.xs} {
    flex-direction: column;
    width: 100%;
  }
`

export const Ul = styled.ul`
  display: ${(props) => (props.inline ? "flex" : "block")};
  justify-content: space-between;
  width: ${(props) => (props.inline ? "100%" : "auto")};
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-align: left;

  @media ${devices.xs} {
    display: block;
    width: auto;
    margin-bottom: 15px;
  }
`

export const Li = styled.li`
  position: relative;
  padding-left: 16px;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 6px;
    left: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${colors.primary};
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;

  ${mq.xs} {
    flex-direction: column;
    align-items: center;
  }

  & > *:first-of-type {
    margin-right: 15px;
    margin-bottom: 0;

    ${mq.xs} {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 970px;
  position: relative;

  ${mq.xs} {
    flex-direction: column;
    width: 100%;
  }

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  & > *:first-of-type {
    padding-right: 45px;
    padding-bottom: 0;

    ${mq.xs} {
      padding-right: 0;
      padding-bottom: 30px;
    }
  }

  & > *:last-of-type {
    padding-left: 45px;
    padding-bottom: 0;

    ${mq.xs} {
      padding-left: 0;
      padding-bottom: 30px;
    }
  }
`

const Col = styled.div`
  display: flex;
  align-items: center;
  width: ${(props) => (props.img ? "40%" : "60%")};
  text-align: center;
  order: initial;

  ${mq.xs} {
    width: ${(props) => (props.img ? "75%" : "100%")};
    order: ${(props) => (props.img ? "-1" : "initial")};
  }
`

const UknowInfoPage = () => {
  return (
    <div className={"flexgrid"}>
      <ImgSection>
        <p>
          Knowstory manages learning times and <br /> spaces in a whole new way
        </p>
      </ImgSection>
      <Section isDark>
        <div className="container">
          <div className="f fdc aic full-width text-center">
            <SectionHeading>
              Learning logistics for educators, learners, and agents of
              companies
            </SectionHeading>
            <Button
              color={"primary"}
              variant={"contained"}
              component={Link}
              to={CONTACT_FORM}
            >
              Request a Consult with Demo
            </Button>
          </div>
        </div>
      </Section>
      <Section>
        <div className="container">
          <div className="f fdc aic full-width text-center">
            <SectionHeading>Uberize</SectionHeading>
            <Text className={"mb15"}>
              Knowstory helps you “uberize” learning. Instead of normalizing
              based on age within a manufacturing model of learning, every
              student can be on their own path while not sacrificing live
              teaching intersection.
            </Text>
            <Text className={"mb30"}>
              Knowstory gives you the ability to set meetings that auto-cohort
              and calendar after being accepted. We call them Knowmeets.
            </Text>

            <img src={img1} alt="Uberize" style={{ maxWidth: 194 }} />
          </div>
        </div>
      </Section>

      <Section isDark>
        <div className="container">
          <div className="f fdc aic full-width">
            <Row>
              <Col img>
                <img src={img2} alt="Img1" />
              </Col>
              <Col>
                You can set your logistics for who gets what learning resources
                to study and when they need to intersect for live teaching. This
                can loosely follow traditional class models or diverge to
                entirely individual calendaring based on individual pace
              </Col>
            </Row>
            <Row>
              <Col>
                All users have individual profiles in Knowstory. Groups can be
                schools, districts, One-Teacher-Schools, companies, or interest
                groups. Students can be scheduled to be flexibly remote or
                required in a physical classroom. Live teaching can offer both
                real and virtual spaces
              </Col>
              <Col img>
                <img src={img3} alt="Img2" />
              </Col>
            </Row>
            <Row>
              <Col img>
                <img src={img4} alt="Img3" />
              </Col>
              <Col>
                Dynamic calendaring can be a simple or complex master schedule.
                Individual learning meetings can be set across the calendar for
                a brief period or whole year, accumulating within any one
                Kourse. <br />
                Building sequences for individual study can include many steps
                of instructions, each with their own resources, quizzes, and
                individual meets planned as the student(s) complete each step.
                Master schedules can provide concrete attendance time or act as
                guidelines for how much time to spend on which subject for
                student. <br />
                Teachers and administrators can set Holds for their time with
                labels so that those times are for specific types of use such as
                Parent calls or meetings.
              </Col>
            </Row>
            <Row>
              <Col>
                Social messaging, marketplace, bookmarking, badging, standards
                library, inventory management, resource sequencing, and
                logistics master schedule with dynamic meet setting.
              </Col>
              <Col img>
                <img src={img5} alt="Img4" />
              </Col>
            </Row>
          </div>
        </div>
      </Section>

      <Section>
        <div className="container">
          <div className="f jcc aic full-width text-center">
            <ButtonsContainer>
              <Button
                component={Link}
                to={PRICING}
                color={"primary"}
                variant={"contained"}
                style={{ width: 170 }}
              >
                See pricing
              </Button>
              <Button
                component={Link}
                to={HOW_TO}
                color={"primary"}
                variant={"contained"}
                style={{ width: 170 }}
              >
                See how to pages
              </Button>
            </ButtonsContainer>
          </div>
        </div>
      </Section>

      <Section isDark>
        <div className="container">
          <div className="f fdc aic full-width text-center">
            <ListContainer>
              <Ul inline>
                <Li>Learners</Li>
                <Li>Educators</Li>
                <Li>Agents</Li>
                <Li>Schools & Districts</Li>
              </Ul>
            </ListContainer>
          </div>
        </div>
      </Section>

      <Section>
        <div className="container">
          <div className="f fdc aic full-width text-center">
            <SectionHeading>Learners</SectionHeading>
            <Text>
              Here is where you learn at your own pace with Knowmeets. Find a
              teacher or join a school. Keep your profile for lifelong learning
              after you graduate.
            </Text>

            <ListContainer>
              <Ul>
                <Li>Accept Knowmeets, Knowmixes, or take a Kourse</Li>
                <Li>Give Cred to fellow students or yourself</Li>
                <Li>Use bookmarks</Li>
                <Li>Create folders</Li>
                <Li>Create events</Li>
              </Ul>
              <Ul>
                <Li>Create interest groups</Li>
                <Li>Shop the marketplace of learning</Li>
                <Li>Get organized with Invenstory</Li>
                <Li>Use social media and messaging</Li>
              </Ul>
            </ListContainer>
          </div>
        </div>
      </Section>

      <Section isDark>
        <div className="container">
          <div className="f fdc aic full-width text-center">
            <SectionHeading>Educators</SectionHeading>
            <Text>
              Freelance or associate with your school. A freelancer must create
              a new one-teacher school group. A U.S. database of all schools is
              already in the site with pre-association with respective
              districts.
              <br />
              Knowstory is a “social media ecosystem” that helps you, your
              school or whole districts personalize learning with entirely
              flexible time and space use. A Knowmeet is a live moment you set
              for a cohort or single student. A Knowmix is an expanded Knowmeet
              for more steps, quizzes, attachments and links. A Knowmix can be
              packaged into a Kourse.
            </Text>

            <ListContainer>
              <Ul>
                <Li>Knowmeet, Knowmix, Kourse</Li>
                <Li>Give Cred badges as granted, awarded or validated</Li>
                <Li>Use bookmarks</Li>
                <Li>Create folders</Li>
                <Li>Create events</Li>
              </Ul>
              <Ul>
                <Li>Create interest groups</Li>
                <Li>Shop the marketplace</Li>
                <Li>Get organized with Invenstory</Li>
                <Li>Use social media and messaging</Li>
              </Ul>
            </ListContainer>
          </div>
        </div>
      </Section>

      <Section>
        <div className="container">
          <div className="f fdc aic full-width text-center">
            <SectionHeading>Schools & Districts</SectionHeading>
            <Text>
              Claim or make your school or district. A U.S. database of all
              schools is already in the site with pre-association with
              respective districts.
              <br />
              Knowstory is a “calendaring interchange” that helps your schools
              personalize learning with entirely flexible time and space use. A
              Knowmeet is a live moment you set for a cohort or single student.
              A Knowmix is an expanded Knowmeet for more steps, quizzes,
              attachments and links. A Knowmix can be packaged into a Kourse.
              <br />
              Calendar has four levels: Master, Pacing, Partitioning and
              Personal. These use algorithms to operate together for planning
              time and space against academic standards to pace learning.
            </Text>

            <ListContainer>
              <Ul>
                <Li>Calendar for simple or complex master schedules</Li>
                <Li>Create Knowmeets, Knowmixes, Kourses</Li>
                <Li>Give Cred badges as granted, awarded or validated</Li>
                <Li>Use bookmarks</Li>
                <Li>Create folders</Li>
              </Ul>
              <Ul>
                <Li>Create events</Li>
                <Li>Shop the marketplace</Li>
                <Li>Get organized with Invenstory</Li>
                <Li>Manage rosters</Li>
              </Ul>
            </ListContainer>
          </div>
        </div>
      </Section>

      <Section isDark>
        <div className="container">
          <div className="f fdc aic full-width text-center">
            <SectionHeading>Agents</SectionHeading>
            <Text>
              Here is where you Calendar demos. Create your Company group to get
              started making marketplace product tiles.
            </Text>

            <ListContainer>
              <Ul>
                <Li>
                  Provide professional development Knowmeets and Knowmixes{" "}
                </Li>
                <Li>Give Creds to educators</Li>
                <Li>Use bookmarks</Li>
              </Ul>
              <Ul>
                <Li>Create folders of customers and groups</Li>
                <Li>Create events</Li>
                <Li>Use social media and messaging</Li>
              </Ul>
            </ListContainer>
          </div>
        </div>
      </Section>

      {/*<Section isDark>*/}
      {/*  <div className="container">*/}
      {/*    <div className="f fdc aic full-width text-center">*/}
      {/*      <SectionHeading>The full parts of KnowStory</SectionHeading>*/}
      {/*      <img*/}
      {/*        src={fullPartsImg}*/}
      {/*        alt={'The full parts of KnowStory'}*/}
      {/*        className={'mt30'}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</Section>*/}
    </div>
  )
}

export default UknowInfoPage
