import React, { useState } from "react"
import styled from "@emotion/styled"
import { colors } from "../../../library/constants/styles/colors"
import { devices } from "../../../library/constants/styles/responsive"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp"

const Container = styled.div`
  padding: 15px;
  border-radius: 2px;
  background-color: ${colors.white};
  box-shadow: 0 0 2px 0 ${colors.black12};
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  @media ${devices.xs} {
    flex-direction: column;
    align-items: flex-start;
  }

  p {
    max-width: ${(props) => (props.inSidebar ? "170px" : "480px")};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media ${devices.xs} {
      max-width: 250px;
    }
  }
`

const Status = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.black54};

  span {
    margin-right: 15px;
  }

  svg:first-of-type {
    color: ${colors.primary};
  }
`

const Content = styled.div`
  padding-top: 15px;
`

const ContentItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`

const ContentItemTitle = styled.p`
  margin-bottom: 8px;
`

export const AccordionContentItem = ({ title, children }) => {
  return (
    <ContentItem>
      <ContentItemTitle>{title}</ContentItemTitle>
      {children}
    </ContentItem>
  )
}

const Accordion = ({
  title,
  status,
  defaultOpen = false,
  inSidebar = false,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen)

  return (
    <Container>
      <Header inSidebar={inSidebar} onClick={() => setIsOpen(!isOpen)}>
        {typeof title === "string" ? <p>{title}</p> : title}
        <Status>
          {status}

          {!isOpen ? (
            <KeyboardArrowDown className="color-black-38" />
          ) : (
            <KeyboardArrowUp className="color-black-38" />
          )}
        </Status>
      </Header>
      {isOpen && <Content>{children}</Content>}
    </Container>
  )
}

export default Accordion
