import { call } from "../../../networking/API"
import { KNOWMIX } from "../types/knowmixTypes"
import { GROUPS, SCHOOLS } from "../types/groupsTypes"
import { LIMIT } from "../../../constants/limits"

export const createKnowmix = ({
  name,
  subject_id,
  grade,
  group_id,
  schedule_id,
}) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWMIX.CREATE, {
      token: getState().auth.userData.token,
      name,
      subject_id,
      // grade,
      group_id,
      schedule_id,
    })

    const json = await response.data

    if (response.status === 200) {
      dispatch({
        type: KNOWMIX.CREATE,
      })

      return json.id
    } else {
      throw json.error
    }
  }
}

export const updateKnowmix = ({
  id,
  name,
  subject_id,
  grade,
  full_image,
  links,
  instructions,
  blobs,
  schedule_id,
  teacher_id,
  goal,
  measurement,
  learning_days_number,
  gradual_unlocking_steps,
}) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWMIX.UPDATE, {
      token: getState().auth.userData.token,
      id,
      name,
      subject_id,
      grade,
      full_image,
      links,
      attachments: blobs,
      instructions,
      schedule_id,
      teacher_id,
      goal,
      measurement,
      learning_days_number,
      gradual_unlocking_steps,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWMIX.UPDATE,
      })
    } else {
      throw json.error
    }
  }
}

export const deleteKnowmix = ({ id }) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWMIX.DELETE, {
      token: getState().auth.userData.token,
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWMIX.DELETE,
      })
    } else {
      throw json.error
    }
  }
}

export const getKnowmix = ({ id }) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWMIX.GET, {
      token: getState().auth.userData.token,
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      dispatch({
        type: KNOWMIX.GET,
        payload: json,
      })

      return json
    } else {
      throw json.error
    }
  }
}

export const startKnowmix = ({ id }) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWMIX.START, {
      token: getState().auth.userData.token,
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWMIX.START,
        payload: {
          product: json,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const finishKnowmix = ({ id }) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWMIX.FINISH, {
      token: getState().auth.userData.token,
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWMIX.FINISH,
        payload: {
          product: json,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const clearCurrentKnowmix = () => {
  return {
    type: KNOWMIX.CLEAR_CURRENT,
  }
}

export const createStep = ({
  knowmix_id,
  name,
  description,
  time,
  links = [],
  products,
  productsDataList,
  standards,
  standardsDataList,
  attachments,
  attachmentsDataList,
  questions,
}) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWMIX.STEPS.CREATE, {
      token: getState().auth.userData.token,
      knowmix_id,
      name,
      description,
      products,
      standards,
      attachments,
      questions,
      time,
      links,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWMIX.STEPS.CREATE,
        payload: {
          step: {
            id: json.id,
            name,
            description,
            time,
            products: productsDataList,
            standards: standardsDataList,
            attachments: attachmentsDataList,
            questions,
            links,
          },
        },
      })
    } else {
      throw json.error
    }
  }
}

export const updateStep = ({
  id,
  name,
  description,
  time,
  links = [],
  standards,
  standardsDataList,
  products,
  productsDataList,
  attachments,
  attachmentsDataList,
  questions,
}) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWMIX.STEPS.UPDATE, {
      token: getState().auth.userData.token,
      id,
      name,
      description,
      standards,
      products,
      attachments,
      questions,
      time,
      links,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWMIX.STEPS.UPDATE,
        payload: {
          step: {
            id,
            name,
            description,
            time,
            products: productsDataList,
            standards: standardsDataList,
            attachments: attachmentsDataList,
            questions,
          },
        },
      })
    } else {
      throw json.error
    }
  }
}

export const deleteStep = ({ id }) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWMIX.STEPS.DELETE, {
      token: getState().auth.userData.token,
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWMIX.STEPS.DELETE,
        payload: {
          id,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const stepsList = ({ id }) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWMIX.STEPS.LIST, {
      token: getState().auth.userData.token,
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWMIX.STEPS.LIST,
        payload: json.steps,
      })
    } else {
      throw json.error
    }
  }
}

export const searchStepProducts = ({
  knowmix_step_id,
  name,
  limit = 30,
  offset = 0,
}) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.LIBRARY_SEARCH, {
      token: getState().auth.userData.token,
      knowmix_step_id,
      name,
      limit,
      offset,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWMIX.STEPS.PRODUCTS.SEARCH,
        payload: json.products,
      })
    } else {
      throw json.error
    }
  }
}

export const searchStepProductsClear = () => {
  return {
    type: KNOWMIX.STEPS.PRODUCTS.CLEAR,
  }
}

export const addProductToStep = ({ knowmix_step_id, product_id }) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.LIBRARY_ADD, {
      token: getState().auth.userData.token,
      knowmix_step_id,
      product_id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWMIX.STEPS.PRODUCTS.ADD,
      })
    } else {
      throw json.error
    }
  }
}

export const deleteProductFromStep = ({ knowmix_step_id, product_id }) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.LIBRARY_DELETE, {
      token: getState().auth.userData.token,
      knowmix_step_id,
      product_id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWMIX.STEPS.PRODUCTS.DELETE,
      })
    } else {
      throw json.error
    }
  }
}

export const clearStepsList = () => {
  return {
    type: KNOWMIX.STEPS.LIST_CLEAR,
  }
}

export const addExaminer = ({ user_id, knowmix_id }) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWMIX.EXAMINERS.ADD, {
      token: getState().auth.userData.token,
      user_id,
      knowmix_id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWMIX.EXAMINERS.ADD,
      })
    } else {
      throw json.error
    }
  }
}

export const deleteExaminer = ({ user_id, knowmix_id }) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWMIX.EXAMINERS.DELETE, {
      token: getState().auth.userData.token,
      user_id,
      knowmix_id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWMIX.EXAMINERS.DELETE,
      })
    } else {
      throw json.error
    }
  }
}
export const createWork = ({ step_id }) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWMIX.WORKS.CREATE, {
      token: getState().auth.userData.token,
      step_id,
    })
    const json = await response.data
    if (response.status === 200) {
      return dispatch({
        type: KNOWMIX.WORKS.CREATE,
      })
    } else {
      throw json.error
    }
  }
}

export const updateWork = ({
  step_id,
  questions = [],
  link,
  attachment_id,
}) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWMIX.WORKS.UPDATE, {
      token: getState().auth.userData.token,
      step_id,
      questions,
      // link: "https://beta.knowstory",
      // attachment_id,
      // attachment_id: "8af06b07-bc65-4382-adc4-c16ba5c179f1",
    })
    const json = await response.data
    if (response.status === 200) {
      return dispatch({
        type: KNOWMIX.WORKS.UPDATE,
      })
    } else {
      throw json.error
    }
  }
}

export const finishWork = ({ step_id }) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWMIX.WORKS.FINISH, {
      token: getState().auth.userData.token,
      step_id,
    })
    const json = await response.data
    if (response.status === 200) {
      return dispatch({
        type: KNOWMIX.WORKS.FINISH,
      })
    } else {
      throw json.error
    }
  }
}

export const searchExaminers = ({ id, name, limit = LIMIT, offset = 0 }) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWMIX.EXAMINERS.SEARCH, {
      token: getState().auth.userData.token,
      id,
      name,
      limit,
      offset,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWMIX.EXAMINERS.SEARCH,
        payload: { list: json.users, offset },
      })
    } else {
      throw json.error
    }
  }
}

export const examinersCount = (n = 0) => {
  return {
    type: KNOWMIX.EXAMINERS.COUNT,
    payload: n,
  }
}

export const clearExaminersList = () => {
  return {
    type: KNOWMIX.EXAMINERS.CLEAR,
  }
}

export const getKnowMixList = ({
  name,
  managed = false,
  offset = 0,
  limit = 50,
  subjects,
  grades,
  school_grades,
  types,
  active = false,
  graded = false,
  finished = false,
  unpublished,
} = {}) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWMIX.LIST, {
      token: getState().auth.userData.token,
      name,
      managed,
      offset,
      limit,
      subjects,
      grades,
      school_grades,
      types,
      active,
      graded,
      finished,
      unpublished,
    })
    const json = await response.data
    if (response.status === 200) {
      return dispatch({
        type: KNOWMIX.LIST,
        payload: {
          list: json.knowmixes,
          counter: json.knowmixes_number,
          is_examiner: json.is_examiner,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const updateKnowMixList = ({
  name,
  managed = false,
  offset = 0,
  limit = 50,
  subjects,
  grades,
  types,
  graded = false,
} = {}) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWMIX.LIST, {
      token: getState().auth.userData.token,
      name,
      managed,
      offset,
      limit,
      subjects,
      grades,
      types,
      graded,
    })
    const json = await response.data
    if (response.status === 200) {
      return dispatch({
        type: KNOWMIX.LIST_UPDATE,
        payload: {
          list: json.knowmixes,
          counter: json.knowmixes_number,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const worksList = ({ checked, name, limit = 20, offset = 0 }) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWMIX.WORKS.LIST, {
      token: getState().auth.userData.token,
      checked,
      name,
      limit,
      offset,
    })
    const json = await response.data
    if (response.status === 200) {
      return dispatch({
        type: KNOWMIX.WORKS.LIST,
        payload: json,
      })
    } else {
      throw json.error
    }
  }
}

export const worksGet = ({ id }) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWMIX.WORKS.GET, {
      token: getState().auth.userData.token,
      id,
    })
    const json = await response.data
    if (response.status === 200) {
      return dispatch({
        type: KNOWMIX.WORKS.GET,
        payload: json,
      })
    } else {
      throw json.error
    }
  }
}

export const worksGrade = ({ id, comment, grade }) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWMIX.WORKS.GRADE, {
      token: getState().auth.userData.token,
      id,
      comment,
      grade,
    })
    const json = await response.data
    if (response.status === 200) {
      return dispatch({
        type: KNOWMIX.WORKS.GRADE,
      })
    } else {
      throw json.error
    }
  }
}

export const knowmixGrade = ({ user_id, knowmix_id, comment, grade }) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWMIX.GRADE, {
      token: getState().auth.userData.token,
      user_id,
      knowmix_id,
      comment,
      grade,
    })
    const json = await response.data
    if (response.status === 200) {
      return dispatch({
        type: KNOWMIX.GRADE,
      })
    } else {
      throw json.error
    }
  }
}

export const getKnowMixProgress = ({ user_id, knowmix_id }) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWMIX.GRADE_LIST, {
      token: getState().auth.userData.token,
      user_id,
      knowmix_id,
    })
    const json = await response.data
    if (response.status === 200) {
      return dispatch({
        type: KNOWMIX.GRADE_LIST,
        payload: json,
      })
    } else {
      throw json.error
    }
  }
}

export const clearKnowMixList = () => {
  return {
    type: KNOWMIX.CLEAR_LIST,
  }
}

export const knowmixMembersList = ({
  group_id,
  knowmix_id,
  grade,
  name,
  offset = 0,
  limit = LIMIT,
}) => {
  return async (dispatch) => {
    const response = await call(SCHOOLS.STUDENTS.LIST, {
      group_id,
      knowmix_id,
      grade,
      name,
      offset,
      limit,
    })

    const json = await response.data.users

    if (response.status === 200) {
      return dispatch({
        type: KNOWMIX.MEMBERS.LIST,
        payload: { list: json, offset, limit },
      })
    } else {
      throw json.error
    }
  }
}

export const knowmixMembersSearchList = ({
  knowmix_id,
  name,
  offset = 0,
  limit = LIMIT,
}) => {
  return async (dispatch) => {
    const response = await call(KNOWMIX.MEMBERS.SEARCH, {
      knowmix_id,
      name,
      offset,
      limit,
    })

    const json = await response.data.users

    if (response.status === 200) {
      return dispatch({
        type: KNOWMIX.MEMBERS.SEARCH,
        payload: { list: json, offset, limit },
      })
    } else {
      throw json.error
    }
  }
}

export const clearKnowmixMembersList = () => {
  return async (dispatch) => {
    return dispatch({
      type: KNOWMIX.MEMBERS.CLEAR,
    })
  }
}

export const clearKnowmixMembersSearchList = () => {
  return async (dispatch) => {
    return dispatch({
      type: KNOWMIX.MEMBERS.CLEAR,
    })
  }
}

export const addKnowmixMember = ({ user_id, knowmix_id }) => {
  return async (dispatch) => {
    const response = await call(KNOWMIX.MEMBERS.ADD, {
      user_id,
      knowmix_id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWMIX.MEMBERS.ADD,
        payload: { id: user_id },
      })
    } else {
      throw json.error
    }
  }
}

export const deleteKnowmixMember = ({ user_id, knowmix_id }) => {
  return async (dispatch) => {
    const response = await call(KNOWMIX.MEMBERS.DELETE, {
      user_id,
      knowmix_id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWMIX.MEMBERS.DELETE,
        payload: { id: user_id },
      })
    } else {
      throw json.error
    }
  }
}
