import React from "react"
import { Dialog, DialogContent } from "@material-ui/core"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import CheckOutlined from "@material-ui/icons/CheckOutlined"
import { generatePath } from "react-router-dom"
import {
  KNOWMAP_INCOMING_KNOWMEET,
  KNOWMAP_INCOMING_MEET,
} from "../../../../../../../library/constants/routes"
import LinkIcon from "@material-ui/icons/Link"
import { PlainButton } from "../../../../../../../components/v2/controls"
import { useAlert } from "../../../../../../../hooks/useAlert"
import { safeFormatDate } from "../../../../../../../library/utils/dateConvert"

const UknowCreatedDialog = ({ uknow, meet, open, handleClose }) => {
  const { successAlert } = useAlert()

  const generateLink = () => {
    let link = document.location.origin

    if (meet) {
      link += generatePath(KNOWMAP_INCOMING_MEET, {
        meetId: meet.id,
      })
    }

    if (uknow) {
      link += generatePath(KNOWMAP_INCOMING_KNOWMEET, {
        knowmeetId: uknow.id,
      })
    }

    return link
  }

  const copyLink = async () => {
    await navigator.clipboard.writeText(generateLink())
    successAlert("Link copied to clipboard")
  }

  return (
    <Dialog
      aria-labelledby="calendar-form-dialog"
      onClose={handleClose}
      open={open}
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title" className="text-center">
        Your {meet ? "Meet" : "Knowmeet"} has been created
      </DialogTitle>
      <DialogContent>
        <div className="f aic fdc full-width">
          <div style={{ maxWidth: 480 }} className={"mb40"}>
            <div className="f full-width mb20">
              <CheckOutlined color={"primary"} className={"mr15"} />
              <p>
                use link below in outside systems as needed but always tell
                guests not to click it until ready to be calendared after
                finishing your instructions
              </p>
            </div>
            {!!meet && (
              <div className="f full-width mb20">
                <CheckOutlined color={"primary"} className={"mr15"} />
                <p>
                  outside invitees get a simple email mentioning your name and
                  are told to use the link to claim their account and then
                  accept the meeting for the amount of time you asked for but no
                  other information
                </p>
              </div>
            )}
            {/*<div className="f full-width mb20">*/}
            {/*  <CheckOutlined color={"primary"} className={"mr15"} />*/}
            {/*  <p>*/}
            {/*    invites will be sent to your included guests upon SAVE below*/}
            {/*  </p>*/}
            {/*</div>*/}
            <div className="f full-width">
              <CheckOutlined color={"primary"} className={"mr15"} />
              <p>
                posted to your account for tracking, link recovery and
                adds/deletes of guests
              </p>
            </div>
          </div>

          <table className="product-details product-details--invenstory-record">
            <tbody>
              <tr className="product-details__row">
                <td className="product-details__label">Copy link</td>
                <td className="product-details__value">
                  <PlainButton onClick={copyLink}>
                    <LinkIcon />
                    <span className={"ml8"}>{generateLink()}</span>
                  </PlainButton>
                </td>
              </tr>
              <tr className="product-details__row">
                <td className="product-details__label">Title</td>
                <td className="product-details__value">
                  {uknow?.name || meet?.name}
                </td>
              </tr>
              <tr className="product-details__row">
                <td className="product-details__label">Target date by</td>
                <td className="product-details__value">
                  {uknow?.target_date
                    ? safeFormatDate(uknow?.target_date, { withTime: true })
                    : safeFormatDate(meet?.target_date, { withTime: true })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UknowCreatedDialog
