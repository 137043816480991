import { colors } from "../../../../library/constants/styles/colors"
import React, { forwardRef } from "react"
import InfoIcon from "@material-ui/icons/Info"
import { Tooltip } from "@material-ui/core"

export const TooltipIcon = forwardRef(function TooltipIcon(props, ref) {
  return (
    <InfoIcon
      {...props}
      ref={ref}
      style={{
        marginRight: 5,
        fontSize: 20,
        color: colors.primary,
        cursor: "pointer",
      }}
    />
  )
})
const SelectOption = ({
  photo,
  name,
  isDisabled,
  disabledMessage,
  withTooltip = false,
  tooltipText = "",
  TooltipIconComponent = TooltipIcon,
  itemHelperText = "",
}) => {
  return (
    <div className={"f aic"}>
      {!!photo && (
        <img
          src={photo}
          style={{
            backgroundColor: colors.black12,
            borderRadius: "50%",
            width: 30,
            height: 30,
            marginRight: 8,
            objectFit: "cover",
            objectPosition: "center",
          }}
          alt={""}
        />
      )}
      <div>
        <div className={"f aic"}>
          {withTooltip && (
            <Tooltip
              title={
                <p style={{ color: "inherit", fontSize: 13 }}>{tooltipText}</p>
              }
              arrow
            >
              <TooltipIconComponent />
            </Tooltip>
          )}
          <span>{name}</span>
        </div>
        {!!itemHelperText && (
          <p className={"font-12 color-black-54"}>{itemHelperText}</p>
        )}
        {isDisabled && disabledMessage && (
          <p className={"font-12 color-black-54 font-weight-500"}>
            {disabledMessage}
          </p>
        )}
      </div>
    </div>
  )
}

export default SelectOption
