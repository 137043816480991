import React from "react"
import { useBookingCreation } from "../../bookMeet/useBookingCreation"
import { useSelector } from "react-redux"
import { call } from "../../../library/networking/API"
import { APPOINTMENTS } from "../../../library/store/actions/types/calendarTypes"
import { meetTypes } from "../../../library/constants/meets"
import { useQuery } from "@tanstack/react-query"
import AlertDialog from "../../../components/ui/alertDialog"
import { useAlert } from "../../../hooks/useAlert"

const SolicitAppointmentsContext = React.createContext(null)
export const useSolicitAppointments = () =>
  React.useContext(SolicitAppointmentsContext)

const SolicitAppointmentsProvider = ({ onClose, children }) => {
  const { errorAlert } = useAlert()
  const { userData } = useSelector(({ auth }) => auth)
  const { getMaxSegmentDuration, getMeetTypes, getSmallestHold } =
    useBookingCreation()
  const [data, setData] = React.useState({
    holds: [],
    type: null,
    date: null,
    time: null,
    title: "",
    location: "",
    details: "",
    conference_link: "",
    email: userData?.email || "",
    first_name: userData?.first_name || "",
    last_name: userData?.last_name || "",
    invitee: null,
    timezone: null,
  })

  const [step, setStep] = React.useState(1)
  const [availableHolds, setAvailableHolds] = React.useState([])
  const [allUserMeetTypes, setAllUserMeetTypes] = React.useState([])
  const [availableTypes, setAvailableTypes] = React.useState([])
  const [friendInvitees, setFriendInvitees] = React.useState([])
  const [outsiderInvitees, setOutsiderInvitees] = React.useState([])

  const {
    data: holdsQueryData,
    isSuccess: isHoldsQuerySuccess,
    error: holdsError,
  } = useQuery({
    queryKey: [APPOINTMENTS.PUBLIC_HOLDS],
    queryFn: async (ctx) => {
      const response = await call(ctx.queryKey[0], { user_id: userData.id })
      return await response.data
    },
    refetchOnWindowFocus: false,
  })

  const {
    data: appointmentTypesQueryData,
    isSuccess: isAppointmentTypesQuerySuccess,
    error: appointmentTypesError,
  } = useQuery({
    queryKey: [APPOINTMENTS.GET_TYPES],
    queryFn: async (ctx) => {
      const response = await call(ctx.queryKey[0], {})
      const types = await response.data.types

      if (types.length) {
        return { types }
      } else {
        return { types: meetTypes }
      }
    },
    refetchOnWindowFocus: false,
  })

  React.useEffect(() => {
    if (holdsError) {
      errorAlert("Unable to fetch holds")
    }

    if (appointmentTypesError) {
      errorAlert("Unable to fetch appointment types")
    }
  }, [holdsError, appointmentTypesError])

  React.useEffect(() => {
    if (isAppointmentTypesQuerySuccess) {
      setAvailableTypes(appointmentTypesQueryData.types)
    }
  }, [isAppointmentTypesQuerySuccess])

  const onHoldChange = (v) => {
    const smallestHold = getSmallestHold(v)
    const types = getMeetTypes(
      smallestHold?.segments,
      appointmentTypesQueryData.types
    )
    setAvailableTypes(types)
    setData((prevState) => ({
      ...prevState,
      holds: v,
      type:
        getMaxSegmentDuration(smallestHold?.segments) >= prevState.type
          ? prevState.type
          : types[0],
    }))
  }

  const [isCloseDialogOpen, setIsCloseDialogOpen] = React.useState(false)

  const onCloseAccepted = () => {
    onClose()
  }

  const onModalClose = (confirmable = true) => {
    if (confirmable) {
      setIsCloseDialogOpen(true)
    } else {
      onCloseAccepted()
    }
  }

  return (
    <SolicitAppointmentsContext.Provider
      value={{
        data,
        setData,
        step,
        setStep,
        availableTypes,
        isAppointmentTypesQuerySuccess,
        holdsQueryData,
        isHoldsQuerySuccess,
        onHoldChange,
        onClose: onModalClose,
        friendInvitees,
        setFriendInvitees,
        outsiderInvitees,
        setOutsiderInvitees,
      }}
    >
      {children}

      {isCloseDialogOpen && (
        <AlertDialog
          open={isCloseDialogOpen}
          handleClose={() => setIsCloseDialogOpen(false)}
          handleAccept={onCloseAccepted}
          title="Cancel meet creation?"
          message="Are you sure you want to cancel meet creation?"
          acceptButtonTitle={"Yes"}
          declineButtonTitle={"No"}
        />
      )}
    </SolicitAppointmentsContext.Provider>
  )
}

export default SolicitAppointmentsProvider
