import PickerSelect from "../select/pickerSelect"
import useEntityPicker from "../../../../hooks/useEntityPicker"
import { useEffect } from "react"
import { KNOWMIX } from "../../../../library/store/actions/types/knowmixTypes"

const StepPicker = ({
  value,
  onChange,
  knowmixId,
  hasError = false,
  errorMessage,
  required = false,
  multiple = false,
  steps,
  emptyListMessage,
  emptyListLink,
  getDisabledValue,
  disabledValueMessage,
}) => {
  const { fetchList, list, dataReceived, isWaitingForOtherFields } =
    useEntityPicker({
      fetchConfig: {
        apiUrl: KNOWMIX.STEPS.LIST,
        params: {
          id: knowmixId,
        },
      },
      key: "steps",
      loadOnMount: false,
    })

  useEffect(() => {
    if (!steps && knowmixId) {
      fetchList()
    }
  }, [knowmixId])

  const entityName = multiple ? "steps" : "step"

  return (
    <PickerSelect
      name={"step"}
      entityName={entityName}
      value={value}
      onChange={onChange}
      required={required}
      hasError={hasError}
      errorMessage={errorMessage}
      disabled={steps ? false : !dataReceived}
      dataReceived={dataReceived}
      multiple={multiple}
      list={steps || list}
      isWaitingForOtherFields={steps ? false : isWaitingForOtherFields}
      isWaitingForOtherFieldsMessage={"First select KnowMix"}
      emptyListMessage={emptyListMessage}
      emptyListLink={emptyListLink}
      getDisabledValue={getDisabledValue}
      disabledValueMessage={disabledValueMessage}
    />
  )
}

export default StepPicker
