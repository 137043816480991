import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress"
import Button from "@material-ui/core/Button/Button"
import React from "react"

const LoadingButton = ({
  onClick,
  isLoading = false,
  isLoadingText = "",
  disabled = false,
  children,
  ...mUIButtonProps
}) => {
  return (
    <Button
      type={"button"}
      variant={"contained"}
      color={"primary"}
      onClick={onClick}
      disabled={isLoading || disabled}
      startIcon={
        isLoading ? <CircularProgress size={18} color={"inherit"} /> : null
      }
      {...mUIButtonProps}
    >
      {isLoading ? isLoadingText || children : children}
    </Button>
  )
}

export default LoadingButton
