import { hours, hoursData } from "../timeConvert"
import { intlFormat } from "date-fns"

export const createDateString = (time, utc = true) => {
  if (utc) {
    return new Date(
      new Date().setUTCHours(
        parseInt(getHours(time), 10),
        parseInt(getMinutes(time), 10),
        0,
        0
      )
    )
  }

  return new Date(
    new Date().setHours(
      parseInt(getHours(time), 10),
      parseInt(getMinutes(time), 10),
      0,
      0
    )
  )
}

export const getLocaleTime = (UTCDate, locale = "en-US") => {
  const date = UTCDate.setUTCSeconds(0, 0)

  return intlFormat(
    date,
    { hour12: true, hour: "2-digit", minute: "2-digit" },
    { locale }
  )?.toLowerCase()
}

export const getLocalePeriod = (UTCStart, UTCEnd) => {
  return `${getLocaleTime(createDateString(UTCStart, false))} — ${getLocaleTime(
    createDateString(UTCEnd, false)
  )}`
}

export const getStandardTime = (UTCDate) => {
  const hh = new Date(UTCDate).getHours()
  const mm = new Date(UTCDate).getMinutes()

  return hh + ":" + mm
  return UTCDate.toISOString().match(/\d{2}:\d{2}/gm)?.[0] || ""
}

export const localTimeToUTC = (localTime) => {
  const d = createDateString(localTime, false)

  return getStandardTime(d)
}

export const timeDifference = (start, end) => {
  const s = parseTimeString(start)
  const e = parseTimeString(end)

  if (s.hh > e.hh) {
    return 24 * 60 - (s.hh * 60 + s.mm) + (e.hh * 60 + e.mm)
  }

  return e.hh * 60 + e.mm - (s.hh * 60 + s.mm)
}

export const getLocalTimezoneLabel = () => {
  const offset = new Date().getTimezoneOffset() / 60
  const absoluteOffset = Math.abs(offset)
  const timezoneName =
    offset < 0 ? `UTC +${absoluteOffset}` : `UTC -${absoluteOffset}`
  const timezoneRegionName = Intl.DateTimeFormat().resolvedOptions().timeZone

  return timezoneRegionName
}

export const getHours = (time) => {
  // console.log('getHours: ', time)
  return time?.substring(0, 2) || "10"
}

export const getMinutes = (time) => {
  return time?.substring(3, 5) || "00"
}

export const parseTimeString = (time) => {
  if (!time || typeof time !== "string" || time.length !== 5)
    return {
      hh: 0,
      mm: 0,
    }
  return {
    hh: parseInt(time.substring(0, 2), 10),
    mm: parseInt(time.substring(3, 5), 10),
  }
}

const parseHoursToDefault = (hh, period) => {
  if (period === "pm") {
    return hoursData.pm.find((item) => item.label === hh).value
  }

  if (hh === "12" && period === "am") {
    return "00"
  }

  return hh
}

const parseHoursToUTC = (hours) => {
  const offset = new Date().getTimezoneOffset() / 60
  // console.log({ offset, hh: parseInt(hours, 10) + offset })

  const utcHours = parseInt(hours, 10) + offset

  return utcHours < 10 ? `0${utcHours}` : String(utcHours)
}

const getUTCTime = (time) => {
  const hours = getHours(time)
  const minutes = getMinutes(time)

  return `${parseHoursToUTC(hours)}:${minutes}`
}

const getTimePeriod = (hh) => {
  return parseInt(hh, 10) < 12 || hh === "00" ? "am" : "pm"
}

const parseHoursToUS = (hh) => {
  const period = getTimePeriod(hh)
  return hoursData[period].find((item) => item.value === hh).label
}

class TimeString {
  static extractValues = (time) => {
    // console.log('extract: ', time)
    if (!time) {
      return {
        hh: "",
        mm: "",
        period: "",
      }
    }

    return {
      hh: parseHoursToUS(getHours(time)),
      mm: getMinutes(time),
      period: getTimePeriod(getHours(time)),
    }
  }

  static getISOTime = (hh, mm, period) => {
    // console.log({ hh, mm, period })
    return `${parseHoursToDefault(hh, period)}:${mm}`
  }

  static getUTCTime = (hh, mm, period) => {
    return getUTCTime(TimeString.getISOTime(hh, mm, period))
  }

  static getUSTime = (time) => {
    const hh = time.substring(0, 2)
    const mm = time.substring(3, 5)

    const period = getTimePeriod(hh)

    return `${parseHoursToUS(hh, period)}:${mm} ${period}`
  }

  static getUSDuration = (timeFrom, timeTo) => {
    return `${TimeString.getUSTime(
      TimeString.UTCToLocal(timeFrom)
    )} — ${TimeString.getUSTime(TimeString.UTCToLocal(timeTo))}`
  }

  static localToUTC = (time) => {
    const _hh = parseInt(getHours(time), 10)
    const _mm = getMinutes(time)
    const UTCOffset = new Date().getTimezoneOffset() / 60

    const diff = _hh + UTCOffset

    if (diff < 0) {
      return `${hours[24 - Math.abs(diff)]}:${_mm}`
    }

    return `${hours[diff]}:${_mm}`
  }

  static UTCToLocal = (time) => {
    const _hh = parseInt(getHours(time), 10)
    const _mm = getMinutes(time)
    const UTCOffset = new Date().getTimezoneOffset() / 60

    let diff

    if (UTCOffset > 0) {
      diff = _hh - UTCOffset
    } else {
      diff = _hh + Math.abs(UTCOffset)
    }

    if (diff >= 24) {
      return `${hours[diff - 24]}:${_mm}`
    }

    return `${hours[diff]}:${_mm}`
  }
}

export default TimeString
