import React from "react"
import { bindActionCreators } from "redux"
import {
  clearCurrentGroup,
  clearGroupAttachmentProducts,
  getGroup,
  getProductsLibrary,
} from "../../../../../library/store/actions/creators/groupsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../../../library/constants/routes"
import VerticalMenu from "../../../../../components/ui/verticalMenu"
import Loader from "../../../../../components/ui/loader"
import GroupMultiTypesHorizontalList from "../groupComponents/groupMultiTypesHorizontalList"
import styled from "@emotion/styled"
import { colors } from "../../../../../library/constants/styles/colors"
import GroupsAttachmentsList from "./groupAttachmentsList"
import { getGroupLogo } from "../../../../../library/utils/getGroupLogo"
import SEO from "../../../../../components/SEO"

const Counter = styled.span`
  color: ${colors.black54};
`

class GroupAttachments extends React.Component {
  async componentDidMount() {
    await this.props.getGroup({ id: parseInt(this.props.match.params.groupId) })

    if (
      this.props.groups.currentGroup &&
      (this.props.groups.currentGroup.deleted ||
        (this.props.groups.currentGroup.closed &&
          !this.props.groups.currentGroup.is_member) ||
        (this.props.groups.currentGroup.closed &&
          this.props.groups.currentGroup.is_member &&
          this.props.groups.currentGroup.tags.includes(1)))
    ) {
      return this.props.history.push(
        generatePath(routes.GROUP, { groupId: this.props.match.params.groupId })
      )
    }

    await this.props.getProductsLibrary({
      group_id: parseInt(this.props.match.params.groupId),
    })
  }

  componentWillUnmount() {
    this.props.clearGroupAttachmentProducts()
  }

  render() {
    const {
      type,
      groups,
      match: { params },
    } = this.props
    const menuItems = [
      {
        path: generatePath(routes.GROUP_ATTACHMENTS, {
          groupId: params.groupId,
        }),
        type: "parent",
        label: "Attachments",
      },
    ]
    if (groups.currentGroup) {
      const { images, videos, blobs, links } = groups.currentGroup
      const { currentGroupAttachmentProducts } = groups
      const counter =
        images.length +
        videos.length +
        blobs.length +
        currentGroupAttachmentProducts.length +
        links.length
      if (links.length) {
        menuItems.push({
          path: generatePath(routes.GROUP_ATTACHMENTS_LINKS, {
            groupId: params.groupId,
          }),
          type: "parent",
          label: "Links",
        })
      }
      if (images.length || videos.length || blobs.length) {
        menuItems.push({
          path: generatePath(routes.GROUP_ATTACHMENTS_FILES, {
            groupId: params.groupId,
          }),
          type: "parent",
          label: "Documents",
        })
      }
      // if (currentGroupAttachmentProducts.length) {
      //   menuItems.push({
      //     path: generatePath(routes.GROUP_ATTACHMENTS_PRODUCTS, {
      //       groupId: params.groupId,
      //     }),
      //     type: 'parent',
      //     label: 'Products',
      //   })
      // }

      return (
        <div className="common-page__content">
          <SEO title={"Group Attachments"} />
          <main className="common-page__main">
            {((_) => {
              switch (type) {
                case "all":
                  return (
                    <>
                      <div className="box__heading">
                        Attachments
                        {/*<Counter>{counter}</Counter>*/}
                      </div>
                      {!!links.length && (
                        <GroupMultiTypesHorizontalList
                          title={"Links"}
                          links={links}
                          id={params.groupId}
                          url={generatePath(routes.GROUP_ATTACHMENTS_LINKS, {
                            groupId: params.groupId,
                          })}
                        />
                      )}
                      {!!(images.length || blobs.length) && (
                        <GroupMultiTypesHorizontalList
                          images={images}
                          blobs={blobs}
                          videos={videos}
                          title={"Documents"}
                          id={params.groupId}
                          url={generatePath(routes.GROUP_ATTACHMENTS_FILES, {
                            groupId: params.groupId,
                          })}
                        />
                      )}
                      {!!currentGroupAttachmentProducts.length && (
                        <GroupMultiTypesHorizontalList
                          title="Products Used"
                          products={currentGroupAttachmentProducts}
                          counter={currentGroupAttachmentProducts.length}
                          url={generatePath(routes.GROUP_ATTACHMENTS_PRODUCTS, {
                            groupId: params.groupId,
                          })}
                        />
                      )}
                    </>
                  )
                case "links":
                  return (
                    <>
                      <div className="box__heading">
                        Links <Counter>{links.length}</Counter>
                      </div>
                      <GroupsAttachmentsList list={links} type="links" />
                    </>
                  )
                case "files":
                  return (
                    <>
                      <div className="box__heading">
                        Documents{" "}
                        <Counter>
                          {[...images, ...videos, ...blobs].length}
                        </Counter>
                      </div>
                      <GroupsAttachmentsList
                        list={[...images, ...videos, ...blobs]}
                        type="documents"
                      />
                    </>
                  )
                case "products":
                  return (
                    <>
                      <div className="box__heading">
                        Products{" "}
                        <Counter>
                          {currentGroupAttachmentProducts.length}
                        </Counter>
                      </div>
                      <GroupsAttachmentsList
                        list={currentGroupAttachmentProducts}
                        type="products"
                        currentGroup={groups.currentGroup}
                      />
                    </>
                  )
                default:
                  return <div>Attachments</div>
              }
            })()}
          </main>
          <aside className="common-page__sidebar">
            <div className="box aside-header">
              <Link
                to={generatePath(routes.GROUP, { groupId: params.groupId })}
                className="box__content aside-header__content"
              >
                <img
                  src={getGroupLogo(this.props.groups.currentGroup)}
                  className="aside-header__img"
                  alt=""
                />
                <div className="aside-header__info">
                  <div className="aside-header__name">
                    {this.props.groups.currentGroup.name}
                  </div>
                  <div className="aside-header__help">back to page</div>
                </div>
              </Link>
            </div>
            <VerticalMenu menuItems={menuItems} />
            {/*<Ads/>*/}
          </aside>
        </div>
      )
    }
    return <Loader />
  }
}

const mapStateToProps = ({ auth, groups }) => ({ auth, groups })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getGroup,
      clearCurrentGroup,
      getProductsLibrary,
      clearGroupAttachmentProducts,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(GroupAttachments))
