import { call, fileCall } from "../networking/API"

export const uploadFiles = async (file, type, onUploadProgress, token) => {
  const formData = new FormData()
  formData.append("file", file)
  formData.append("type", type)

  const response = await fileCall(formData, type, onUploadProgress, token)

  const json = await response.data

  if (response.status === 200) {
    return json
  } else {
    throw json.error
  }
}

export const deleteFile = async (id, token) => {
    const response = await call("files.delete", {
        token,
        id,
    });

    const json = await response.data;

    if (response.status === 200) {
        return json;
    } else {
        throw json.error;
    }
};
