import React from "react"
import Card from "./card"
import { generatePath, useParams } from "react-router-dom"
import {
  GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT,
  GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT_SECTIONS,
} from "../../../../library/constants/routes"
import {
  getArrayByIds,
  renderArrayOfNames,
} from "../../../../library/utils/arrays"
import { calendarGrades } from "../../../../library/constants/educationalDictionary"
import {
  CARD_SIZES,
  getCardSize,
} from "../../../../library/constants/styles/cardSizes"

const SegmentCard = ({ segment, openDeletionDialog, openEditDialog }) => {
  const params = useParams()

  return (
    <Card
      size={getCardSize(6).numValPx}
      name={segment.name}
      link={generatePath(GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT_SECTIONS, {
        ...params,
        segmentId: segment.id,
      })}
      infoComponent={() => (
        <span>
          Grades:{" "}
          {renderArrayOfNames(getArrayByIds(calendarGrades, segment.grades))}
        </span>
      )}
      controls={{
        dropdownMenu: {
          items: [
            {
              type: "button",
              action: openEditDialog,
              label: "Edit segment",
            },
            {
              type: "h-divider",
              id: "hd1",
            },
            {
              type: "button",
              action: openDeletionDialog,
              label: "Delete segment",
            },
          ],
        },
      }}
    />
  )
}

export default SegmentCard
