import React from "react"
import { generatePath } from "react-router-dom"
import { GROUP, USER } from "../../../../../library/constants/routes"
import PlainLink from "../../../../../components/ui/link"
import { useCourse } from "../../../../../hooks/data/courses/courses"
import { getUserName } from "../../../../../library/utils/user/userUtils"
import { Box } from "../../../../../components/tw/layout"
import { getSubjectName } from "../../../../../library/utils/schedule"

const CourseSidebarInfo = () => {
  const { course, canEdit } = useCourse()

  const creatorName = !!course?.creator_user
    ? getUserName(course?.creator_user)
    : course?.creator_group?.name

  const creatorUrl = !!course?.creator_user
    ? generatePath(USER, { userId: course?.creator_user?.id })
    : generatePath(GROUP, { groupId: course?.creator_group?.id })

  const status = () => {
    if (course?.is_creator || canEdit) {
      if (course?.is_finished) {
        return "Released"
      } else {
        return "Not released"
      }
    } else {
      switch (course?.type) {
        case 1:
          return "Not started"

        case 2:
          return "Started"

        case 3:
          return "Finished"

        default:
          return "Not started"
      }
    }
  }

  return (
    <Box className={"p-0"}>
      <div className="p-4 grid grid-flow-row gap-2">
        <div>
          <p className="text-sm text-black54">Creator:</p>
          <div>
            <PlainLink to={creatorUrl} ellipsis>
              {creatorName}
            </PlainLink>
          </div>
        </div>
        {!!course?.subject?.id && (
          <div>
            <p className="text-sm text-black54">Subject:</p>
            <p>{getSubjectName(course?.subject)}</p>
          </div>
        )}
      </div>
      {!!status().length && (
        <>
          <hr className={"border-divider mx-2"} />
          <div className="p-4">
            <p className="text-right font-medium text-lg">{status()}</p>
          </div>
        </>
      )}
    </Box>
  )
}

export default CourseSidebarInfo
