import React from "react"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined"
import { isLast } from "../../../library/utils/arrays"
import UserLink from "../../../components/v2/utils/UserLink"
import LocationIcon from "@material-ui/icons/LocationOnOutlined"
import LinkIcon from "@material-ui/icons/LinkOutlined"
import Button from "@material-ui/core/Button/Button"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import { useSolicitAppointments } from "./SolicitAppointmentsContext"

const SolicitAppointmentStep3 = () => {
  const { data, onClose, friendInvitees, outsiderInvitees } =
    useSolicitAppointments()

  return (
    <>
      <DialogTitle className={"text-center"}>Request was emailed</DialogTitle>
      <DialogContent>
        <p className={"color-black-54 font-16 mb15"}>
          Monitor your Tracking panel for details.
        </p>
        <p className={"mb15 font-18 font-weight-500"}>{data.title}</p>
        {!!(friendInvitees.length || outsiderInvitees.length) && (
          <div className={"f aifs mb10"}>
            <GroupAddOutlinedIcon className={"color-primary"} />
            <div className={"ml10"}>
              <p className={"mb10"}>Other invitees:</p>
              {!!friendInvitees.length && (
                <div className={!!outsiderInvitees.length ? "mb15" : ""}>
                  {friendInvitees.map((invitee, idx) => (
                    <div
                      className={!isLast(friendInvitees, idx) ? "mb5" : ""}
                      key={invitee.id}
                    >
                      <UserLink user={invitee} />
                    </div>
                  ))}
                </div>
              )}

              {outsiderInvitees.map((invitee, idx) => (
                <div
                  key={idx}
                  className={!isLast(outsiderInvitees, idx) ? "mb10" : ""}
                >
                  <p>
                    {invitee.first_name} {invitee.last_name}
                  </p>
                  <p className={"color-black-54 font-13"}>{invitee.email}</p>
                </div>
              ))}
            </div>
          </div>
        )}
        {data.location && (
          <div className={"f aic mb10"}>
            <LocationIcon className={"color-primary"} />
            <p className={"ml10"}>{data.location}</p>
          </div>
        )}
        {data.conference_link && (
          <div className={"f aic mb10"}>
            <LinkIcon className={"color-primary"} />
            <p className={"ml10 line-clamp-1"}>{data.conference_link}</p>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          Ok
        </Button>
      </DialogActions>
    </>
  )
}

export default SolicitAppointmentStep3
