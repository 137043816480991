import React, { Component } from "react"
import GridListItem from "../../../../../components/ui/gridListItem"
import { generatePath, Link, withRouter } from "react-router-dom"
import * as routes from "../../../../../library/constants/routes"
import {
  knowCredActivitiesIds,
  knowCredTypesIds,
} from "../../../../../library/constants/knowCred"
import LinesEllipsis from "react-lines-ellipsis"
import FavouriteBorderedOutlined from "@material-ui/icons/FavoriteBorderOutlined"
import FavouriteOutlined from "@material-ui/icons/FavoriteOutlined"
import getErrorText from "../../../../../library/constants/errorTypes"
import { bindActionCreators } from "redux"
import {
  knowCredLike,
  knowCredUnLike,
} from "../../../../../library/store/actions/creators/knowCredsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import BookmarkedItemDropdown from "../bookmarkedItemDropdown/bookmarkedItemDropdown"
import LockOutlined from "@material-ui/icons/LockOutlined"

class BookmarkedKnowcred extends Component {
  state = {
    isLiked: this.props.knowcred.is_liked,
    likesNumber: this.props.knowcred.likes_number,
    deleted: false,
  }

  deleteBookmark = () => {
    const { knowcred } = this.props
    this.props
      .deleteBookmark({ knowcred_id: knowcred.id })
      .then(this.setState({ deleted: true }))
  }

  processLike = async () => {
    const { knowcred } = this.props

    try {
      if (this.state.isLiked) {
        this.setState((prevState) => {
          return {
            isLiked: false,
            likesNumber: prevState.likesNumber - 1,
          }
        })
        await this.props.knowCredUnLike({ knowcred_id: knowcred.id })
      } else {
        this.setState((prevState) => {
          return {
            isLiked: true,
            likesNumber: prevState.likesNumber + 1,
          }
        })
        await this.props.knowCredLike({ knowcred_id: knowcred.id })
      }
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  render() {
    const { knowcred } = this.props
    const { isLiked, likesNumber, deleted } = this.state

    if (!knowcred || deleted) return null

    const showSubtitleLabel = [3, 4]
    const hideActivity = [0, 1].includes(knowcred.activity)
    const isSubtypeLabel = showSubtitleLabel.includes(knowcred.type)
    const labelClass = isSubtypeLabel ? "knowcred-card__info-label--56" : ""
    const valueClass = isSubtypeLabel ? "knowcred-card__info-value--78" : ""

    const knowcredUrl = generatePath(routes.KNOWCRED_SINGLE, {
      knowCredId: knowcred.id,
    })

    return (
      <GridListItem classes="box">
        <div className="knowcred-card box__content box__content--padding-all">
          <div className="knowcred-card__content">
            <div className="knowcred-card__menu">
              <BookmarkedItemDropdown
                deleteBookmark={this.deleteBookmark}
                bookmarkFoldersList={() =>
                  this.props.bookmarkFoldersList(knowcred.bookmark.id)
                }
                addToFolder={(folders) =>
                  this.props.addToFolder({
                    bookmark_id: knowcred.bookmark.id,
                    folders,
                  })
                }
                bookmarksFoldersListClear={() =>
                  this.props.bookmarksFoldersListClear()
                }
              />
            </div>
            <div className="knowcred-card__image">
              {!!knowcred.background_image && !!knowcred.foreground_image && (
                <Link to={knowcredUrl}>
                  {!knowcred.public && (
                    <div className="knowcred-card__lock-icon">
                      <LockOutlined className="color-primary" />
                    </div>
                  )}
                  <img
                    src={knowcred.background_image}
                    alt="Credicon background"
                    className="knowcred-card__background"
                    style={{ borderColor: knowcred.border_color }}
                  />
                  <img
                    src={knowcred.foreground_image}
                    alt="Credicon foreground"
                    className="knowcred-card__foreground"
                  />
                  {this.props.match.path !== routes.KNOWCRED_VALIDATED &&
                    knowcred.owner.id !== knowcred.creator.id && (
                      <div
                        className="knowcred-card__validation-label"
                        style={{ backgroundColor: knowcred.border_color }}
                      >
                        Valid
                      </div>
                    )}
                </Link>
              )}
            </div>

            <Link to={knowcredUrl} className="knowcred-card__title link">
              {knowcred.title}
            </Link>

            <table className="knowcred-card__info">
              <tbody>
                <tr>
                  <td className={`pb4 knowcred-card__info-label ${labelClass}`}>
                    <span>Type:</span>
                  </td>
                  <td className={`pb4 knowcred-card__info-value ${valueClass}`}>
                    <span>{knowCredTypesIds[knowcred.type]}</span>
                  </td>
                </tr>
                {!hideActivity && (
                  <tr>
                    <td className={`knowcred-card__info-label ${labelClass}`}>
                      <span>
                        {showSubtitleLabel.includes(knowcred.type)
                          ? "Label"
                          : "Activity"}
                        :
                      </span>
                    </td>
                    <td className={`knowcred-card__info-value ${valueClass}`}>
                      <span>{knowCredActivitiesIds[knowcred.activity]}</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <div className="knowcred-card__description">
              <LinesEllipsis
                text={knowcred.description}
                maxLine="5"
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            </div>
          </div>
          <div className="knowcred-card__social-control-wrapper jcsb mt8">
            <div
              className="knowcred-card__social-control"
              onClick={this.processLike}
            >
              {isLiked ? (
                <FavouriteOutlined className="color-primary" />
              ) : (
                <FavouriteBorderedOutlined />
              )}
              {likesNumber > 0 && (
                <span className={isLiked ? "color-primary" : ""}>
                  {likesNumber}
                </span>
              )}
            </div>
          </div>
        </div>
      </GridListItem>
    )
  }
}

const mapStateToProps = ({knowCreds}) => ({knowCreds});
const mapDispatchToProps = dispatch => bindActionCreators({knowCredLike, knowCredUnLike}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withRouter(BookmarkedKnowcred)));
