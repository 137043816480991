import React, { Component } from "react"
import { generatePath, Route, Switch } from "react-router-dom"
import VerticalMenu from "../../../components/ui/verticalMenu/verticalMenu"
import * as routes from "../../../library/constants/routes"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import GroupsList from "./groupsList/groupsList"
import { getGroupsList } from "../../../library/store/actions/creators/groupsCreators"
import { withSnackbar } from "notistack"
import SearchResultsList from "../search/searchResultsList/searchResultsList"
import SEO from "../../../components/SEO"

class Groups extends Component {
  render() {
    const {
      auth: { userData },
    } = this.props

    const hideMyGroupsMenuChildren = ![
      routes.GROUPS,
      routes.GROUPS_MANAGED,
      routes.GROUPS_INTERESTS,
      routes.GROUPS_INTERESTS_MANAGED,
      routes.GROUPS_DISTRICTS,
      routes.GROUPS_DISTRICTS_MANAGED,
      routes.GROUPS_SCHOOLS,
      routes.GROUPS_SCHOOLS_MANAGED,
      routes.GROUPS_COMPANIES,
      routes.GROUPS_COMPANIES_MANAGED,
      routes.GROUPS_EVENTS,
      routes.GROUPS_EVENTS_MANAGED,
      routes.GROUPS_ONE_TEACHER_SCHOOLS,
      routes.GROUPS_ONE_TEACHER_SCHOOLS_MANAGED,
    ].includes(this.props.match.path)

    const hideGroupsMenuChildren = ![
      routes.GROUPS_SEARCH,
      routes.GROUPS_SEARCH_INTERESTS,
      routes.GROUPS_SEARCH_DISTRICTS,
      routes.GROUPS_SEARCH_SCHOOLS,
      routes.GROUPS_SEARCH_COMPANIES,
      routes.GROUPS_SEARCH_EVENTS,
      routes.GROUPS_SEARCH_ONE_TEACHER_SCHOOLS,
    ].includes(this.props.match.path)

    const menuItems = [
      {
        path: generatePath(routes.GROUPS, { userId: userData.id }),
        label: "My groups",
        type: "parent",
      },
      {
        path: generatePath(routes.GROUPS_INTERESTS, { userId: userData.id }),
        label: "Interest groups",
        type: "child",
        hidden: hideMyGroupsMenuChildren,
      },
      {
        path: generatePath(routes.GROUPS_DISTRICTS, { userId: userData.id }),
        label: "Districts",
        type: "child",
        hidden: hideMyGroupsMenuChildren,
      },
      {
        path: generatePath(routes.GROUPS_SCHOOLS, { userId: userData.id }),
        label: "Schools",
        type: "child",
        hidden: hideMyGroupsMenuChildren,
      },
      {
        path: generatePath(routes.GROUPS_ONE_TEACHER_SCHOOLS, {
          userId: userData.id,
        }),
        label: "One Teacher Schools",
        type: "child",
        hidden: hideMyGroupsMenuChildren,
      },
      {
        path: generatePath(routes.GROUPS_COMPANIES, { userId: userData.id }),
        label: "Companies",
        type: "child",
        hidden: hideMyGroupsMenuChildren,
      },
      {
        path: generatePath(routes.GROUPS_EVENTS, { userId: userData.id }),
        label: "Events",
        type: "child",
        hidden: hideMyGroupsMenuChildren,
      },
      {
        path: generatePath(routes.GROUPS_REQUESTS_PENDING, {
          userId: userData.id,
        }),
        label: "Requests to groups",
      },
      {
        path: generatePath(routes.GROUPS_SEARCH, { userId: userData.id }),
        label: "Global group search",
      },
      {
        path: generatePath(routes.GROUPS_SEARCH_INTERESTS, {
          userId: userData.id,
        }),
        label: "Interest groups",
        type: "child",
        hidden: hideGroupsMenuChildren,
        key: routes.GROUPS_SEARCH_INTERESTS,
      },
      {
        path: generatePath(routes.GROUPS_SEARCH_DISTRICTS, {
          userId: userData.id,
        }),
        label: "Districts",
        type: "child",
        hidden: hideGroupsMenuChildren,
        key: routes.GROUPS_SEARCH_DISTRICTS,
      },
      {
        path: generatePath(routes.GROUPS_SEARCH_SCHOOLS, {
          userId: userData.id,
        }),
        label: "Schools",
        type: "child",
        hidden: hideGroupsMenuChildren,
        key: routes.GROUPS_SEARCH_SCHOOLS,
      },
      {
        path: generatePath(routes.GROUPS_SEARCH_ONE_TEACHER_SCHOOLS, {
          userId: userData.id,
        }),
        label: "Schools",
        type: "child",
        hidden: hideGroupsMenuChildren,
        key: routes.GROUPS_SEARCH_SCHOOLS,
      },
      {
        path: generatePath(routes.GROUPS_SEARCH_COMPANIES, {
          userId: userData.id,
        }),
        label: "Companies",
        type: "child",
        hidden: hideGroupsMenuChildren,
        key: routes.GROUPS_SEARCH_COMPANIES,
      },
      {
        path: generatePath(routes.GROUPS_SEARCH_EVENTS, {
          userId: userData.id,
        }),
        label: "Events",
        type: "child",
        hidden: hideGroupsMenuChildren,
        key: routes.GROUPS_SEARCH_EVENTS,
      },
      // {
      //     path: generatePath(routes.GROUPS_FAVORITE, {userId: userData.id}),
      //     label: 'Favorite groups'
      // },
      // {
      //     path: generatePath(routes.GROUPS_RECOMMENDED, {userId: userData.id}),
      //     label: 'Recommended groups'
      // },
    ]

    return (
      <div className="common-page__content">
        <SEO title={"Groups"} />
        <main className="common-page__main">
          <Switch>
            <Route
              exact
              path={routes.GROUPS}
              render={(props) => {
                return <GroupsList {...props} type={"all"} />
              }}
            />
            <Route
              exact
              path={routes.GROUPS_MANAGED}
              render={(props) => {
                return (
                  <GroupsList {...props} type={"managed"} subtype={"all"} />
                )
              }}
            />
            <Route
              exact
              path={routes.GROUPS_INTERESTS}
              render={(props) => {
                return <GroupsList {...props} type={1} />
              }}
            />
            <Route
              exact
              path={routes.GROUPS_INTERESTS_MANAGED}
              render={(props) => {
                return <GroupsList {...props} type={"managed"} subtype={1} />
              }}
            />
            <Route
              exact
              path={routes.GROUPS_DISTRICTS}
              render={(props) => {
                return <GroupsList {...props} type={5} />
              }}
            />
            <Route
              exact
              path={routes.GROUPS_DISTRICTS_MANAGED}
              render={(props) => {
                return <GroupsList {...props} type={"managed"} subtype={5} />
              }}
            />
            <Route
              exact
              path={routes.GROUPS_SCHOOLS}
              render={(props) => {
                return <GroupsList {...props} type={4} />
              }}
            />
            <Route
              exact
              path={routes.GROUPS_SCHOOLS_MANAGED}
              render={(props) => {
                return <GroupsList {...props} type={"managed"} subtype={4} />
              }}
            />
            <Route
              exact
              path={routes.GROUPS_COMPANIES}
              render={(props) => {
                return <GroupsList {...props} type={3} />
              }}
            />
            <Route
              exact
              path={routes.GROUPS_COMPANIES_MANAGED}
              render={(props) => {
                return <GroupsList {...props} type={"managed"} subtype={3} />
              }}
            />
            <Route
              exact
              path={routes.GROUPS_EVENTS}
              render={(props) => {
                return <GroupsList {...props} type={2} />
              }}
            />
            <Route
              exact
              path={routes.GROUPS_EVENTS_MANAGED}
              render={(props) => {
                return <GroupsList {...props} type={"managed"} subtype={2} />
              }}
            />
            <Route
              exact
              path={routes.GROUPS_ONE_TEACHER_SCHOOLS}
              render={(props) => {
                return <GroupsList {...props} type={7} />
              }}
            />
            <Route
              exact
              path={routes.GROUPS_ONE_TEACHER_SCHOOLS_MANAGED}
              render={(props) => {
                return <GroupsList {...props} type={"managed"} subtype={7} />
              }}
            />
            <Route
              exact
              path={routes.GROUPS_SEARCH}
              render={(props) => {
                return <SearchResultsList {...props} type="groups" />
              }}
            />
            <Route
              exact
              path={routes.GROUPS_SEARCH_COMPANIES}
              render={(props) => {
                return <SearchResultsList {...props} type="groups" />
              }}
            />
            <Route
              exact
              path={routes.GROUPS_SEARCH_EVENTS}
              render={(props) => {
                return <SearchResultsList {...props} type="groups" />
              }}
            />
            <Route
              exact
              path={routes.GROUPS_SEARCH_SCHOOLS}
              render={(props) => {
                return <SearchResultsList {...props} type="groups" />
              }}
            />
            <Route
              exact
              path={routes.GROUPS_SEARCH_ONE_TEACHER_SCHOOLS}
              render={(props) => {
                return <SearchResultsList {...props} type="groups" />
              }}
            />
            <Route
              exact
              path={routes.GROUPS_SEARCH_INTERESTS}
              render={(props) => {
                return <SearchResultsList {...props} type="groups" />
              }}
            />
            <Route
              exact
              path={routes.GROUPS_SEARCH_DISTRICTS}
              render={(props) => {
                return <SearchResultsList {...props} type="groups" />
              }}
            />
            <Route
              exact
              path={routes.GROUPS_REQUESTS_PENDING}
              render={(props) => {
                return <GroupsList {...props} type={"requests-pending"} />
              }}
            />
            <Route
              exact
              path={routes.GROUPS_REQUESTS_OUTGOING}
              render={(props) => {
                return <GroupsList {...props} type={"requests-outgoing"} />
              }}
            />
          </Switch>
        </main>
        <aside className="common-page__sidebar">
          <VerticalMenu menuItems={menuItems} />
          {/*<Ads/>*/}
        </aside>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, groups }) => ({ auth, groups })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getGroupsList }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(Groups))
