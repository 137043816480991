import Card from "./card"
import React from "react"
import { generatePath, useParams } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import { format } from "date-fns"

const CalendarCard = ({ calendar, controls }) => {
  const params = useParams()
  const calendarUrl = generatePath(routes.GROUP_SETTINGS_CALENDAR_GRADES, {
    ...params,
    calendarId: calendar.id,
  })

  return (
    <Card
      name={calendar.name}
      link={calendarUrl}
      infoComponent={() => (
        <span className="list-item__description mb0">
          {format(new Date(calendar.date_from), "d MMM yyyy")} -{" "}
          {format(new Date(calendar.date_to), "d MMM yyyy")}
        </span>
      )}
      controls={controls}
    />
  )
}

export default CalendarCard
