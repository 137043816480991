import UploadProgress from "../../../../../components/ui/uploadProgress"
import { useRef, useState } from "react"
import { Button } from "@material-ui/core"
import { uploadFiles } from "../../../../../library/utils/uploadFiles"
import { useSelector } from "react-redux"
import { useAlert } from "../../../../../hooks/useAlert"

const OwnImagePicker = ({ value, onChange, currentLibraryImage }) => {
  const { errorAlert } = useAlert()

  const { token } = useSelector(({ auth }) => auth.userData)

  const [isUploading, setIsUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const inputRef = useRef(null)

  const onUploadProgress = (progressEvent) => {
    let percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    )
    setUploadProgress(percentCompleted)
  }

  const onInputOpen = () => {
    inputRef.current.click()
  }

  const processImageUpload = async (e) => {
    try {
      const image = e.target.files[0]

      setIsUploading(true)
      onChange(URL.createObjectURL(image))

      const { url } = await uploadFiles(image, 2, onUploadProgress, token)
      onChange(url)
      setIsUploading(false)
      setUploadProgress(0)
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <div className="knowcred-picker__uploader mb20">
      <div className="knowcred-picker__custom-image">
        <img src={value || currentLibraryImage} alt="" />
        {isUploading && (
          <UploadProgress fileType="image" progress={uploadProgress} />
        )}
        <input
          type="file"
          id="background-input"
          accept="image/gif, image/png, image/jpeg, image/bmp, image/webp"
          className="hidden"
          onChange={processImageUpload}
          ref={inputRef}
        />
      </div>
      <Button variant="outlined" color="primary" onClick={onInputOpen}>
        {!value ? "Upload image" : "Change image"}
      </Button>
    </div>
  )
}

export default OwnImagePicker
