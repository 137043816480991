import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import getErrorText
  from "../../../../../../../../../../../library/constants/errorTypes";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import {
  getScheduleUnit
} from "../../../../../../../../../../../library/store/actions/creators/calendarCreators";
import {
  FileLoader,
  FormDatePicker
} from "../../../../../../../../../../../components/formComponents";
import TimeDurationPicker
  from "../../../../../../../../../../../components/formComponents/timeDurationPicker";
import StandardPicker
  from "../../../../../../../../../../../components/v2/inputs/pickers/standardPicker";
import Loader from "../../../../../../../../../../../components/ui/loader";
import AddOutlined from "@material-ui/icons/AddOutlined";
import {
  urlValidation
} from "../../../../../../../../../../../library/utils/validators";

// const UnitFormModal = ({
//   edit,
//   activeUnit,
//   period,
//   onSubmit,
//   open,
//   handleClose,
// }) => {
//   const { unit, unitReceived } = useUnit(activeUnit)
//
//   const [values, setValues] = useState({
//     name: unit?.name || "",
//     description: "",
//     subjects: [],
//     grades: [],
//     standards: [],
//     date_from: period?.start || null,
//     date_to: period?.end || null,
//     dateFromError: false,
//     dateToError: false,
//     time: "",
//     dataReceived: !edit,
//   })
//
//   return (
//     <Dialog
//       aria-labelledby="unit-form-dialog"
//       onClose={handleClose}
//       open={open}
//       maxWidth="md"
//     >
//       {/*<ValidatorForm onSubmit={this.processInfoSave}>*/}
//       <DialogTitle id="alert-dialog-title" className="text-center">
//         {edit ? "Edit unit" : "Create unit"}
//       </DialogTitle>
//       <DialogContent>
//         {unitReceived ? (
//           <div className="form form--modal">
//             <div className="form__fieldset">
//               <label htmlFor="name" className="form__label">
//                 Title *
//               </label>
//               <div className="form__fieldbox">
//                 <div className="form__input">{values.name}</div>
//               </div>
//             </div>
//           </div>
//         ) : (
//           <Loader />
//         )}
//       </DialogContent>
//     </Dialog>
//   )
// }

class UnitFormModal extends Component {
  state = {
    name: "",
    description: "",
    subjects: [],
    grades: [],
    standards: [],
    date_from: this.props.period?.start || null,
    date_to: this.props.period?.end || null,
    dateFromError: false,
    dateToError: false,
    time: "",
    link: "",
    attachment: null,
    dataReceived: !this.props.edit,
  }

  async componentDidMount() {
    ValidatorForm.addValidationRule("isLink", (value) => urlValidation(value))
    try {
      if (this.props.edit) {
        await this.props.getScheduleUnit({ id: this.props.activeUnit })

        this.setState({
          name: this.props.calendar.currentUnit.name || "",
          description: this.props.calendar.currentUnit.description || "",
          standards: this.props.calendar.currentUnit.standards || [],
          date_from: this.props.calendar.currentUnit.date_from || null,
          date_to: this.props.calendar.currentUnit.date_to || null,
          time: this.props.calendar.currentUnit.minutes || "",
          attachment: this.props.calendar.currentUnit.blobs[0] || null,
          link: this.props.calendar.currentUnit.link || "",
          dataReceived: true,
        })
      }
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value })
  }

  handleChangeDate = (name, date) => {
    this.setState({ [name]: date })

    switch (name) {
      case "date_from":
        return this.setState({ dateFromError: !date })

      case "date_to":
        return this.setState({ dateToError: !date })

      default:
        return this.setState({ [name]: date })
    }
  }

  handleFileInputOpen = () => {
    document.getElementById("attachments").click()
  }

  validateForm = () => {
    this.setState((prevState) => ({
      dateFromError: !prevState.date_from,
      dateToError: !prevState.date_to,
    }))
  }

  processInfoSave = async () => {
    const {
      name,
      description,
      standards,
      date_from,
      date_to,
      dateFromError,
      dateToError,
      time,
      link,
      attachment,
    } = this.state

    // console.log({ name, description, standards, hours })

    if (dateFromError || dateToError) return

    const subject = this.props.calendar.currentSubject

    try {
      await this.props.onSubmit({
        id: this.props.edit ? this.props.activeUnit : undefined,
        name,
        description,
        subject,
        standards: standards.map((s) => s.id),
        minutes: time || undefined,
        date_from,
        date_to,
        link,
        attachment_id: attachment?.id || "",
      })

      this.props.handleClose()
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  render() {
    const { handleClose, open } = this.props
    const {
      name,
      description,
      time,
      date_from,
      date_to,
      dateFromError,
      dateToError,
      attachment,
      link,
    } = this.state

    return (
      <Dialog
        aria-labelledby="unit-form-dialog"
        onClose={handleClose}
        open={open}
        maxWidth="md"
      >
        <ValidatorForm onSubmit={this.processInfoSave}>
          <DialogTitle id="alert-dialog-title" className="text-center">
            {this.props.edit ? "Edit unit" : "Create unit"}
          </DialogTitle>
          <DialogContent>
            {this.state.dataReceived ? (
              <div className="form form--modal">
                <div className="form__fieldset">
                  <label htmlFor="name" className="form__label">
                    Title *
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="name"
                        name="name"
                        value={name}
                        placeholder="Type unit title"
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        validators={[
                          "required",
                          "minStringLength:3",
                          "maxStringLength:200",
                        ]}
                        errorMessages={[
                          "Field is required",
                          "Min length is 3 characters",
                          "Max length is 200 characters",
                        ]}
                        withRequiredValidator
                        onChange={this.handleChange("name")}
                      />
                    </div>
                  </div>
                </div>

                <StandardPicker
                  value={this.state.standards}
                  onChange={(v) => this.setState({ standards: v })}
                />

                <FormDatePicker
                  dateFrom={{
                    label: "Start date",
                    value: date_from,
                    required: true,
                    error: dateFromError,
                    handleChange: (value) =>
                      this.handleChangeDate("date_from", value),
                  }}
                  dateTo={{
                    label: "End date",
                    value: date_to,
                    required: true,
                    error: dateToError,
                    handleChange: (value) =>
                      this.handleChangeDate("date_to", value),
                  }}
                />

                <TimeDurationPicker
                  label={"Estimated time"}
                  time={time}
                  onChange={(t) => this.setState({ time: t })}
                />

                <div className="form__fieldset">
                  <label htmlFor="description" className="form__label">
                    Description *
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="description"
                        name="description"
                        value={description}
                        placeholder="Type unit description"
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        validators={["required", "maxStringLength:500"]}
                        errorMessages={[
                          "Field is required",
                          "Max length is 500 characters",
                        ]}
                        onChange={this.handleChange("description")}
                      />
                    </div>
                  </div>
                </div>

                <div className="form__fieldset">
                  <label htmlFor="link" className="form__label">
                    Link
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="link"
                        name="link"
                        value={link}
                        placeholder="Type conference link"
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        validators={["isLink", "maxStringLength:250"]}
                        errorMessages={[
                          "Invalid URL",
                          "Max length is 250 characters",
                        ]}
                        onChange={this.handleChange("link")}
                      />
                    </div>
                  </div>
                </div>

                <div className="form__fieldset">
                  <span className="form__label">Attachment</span>
                  <div className="form__fieldbox">
                    <div className="form__attachments">
                      <FileLoader
                        attachmentId={"attachments"}
                        blobs={attachment ? [attachment] : []}
                        onUpload={({ blobs }) =>
                          this.setState({ attachment: blobs[0] })
                        }
                        token={this.props.auth.userData.token}
                        attachmentOnly={true}
                        limit={1}
                      />
                      {!attachment && (
                        <button
                          className="form__add-input"
                          type="button"
                          onClick={this.handleFileInputOpen}
                        >
                          <AddOutlined /> Add attachment
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </DialogContent>
          <DialogActions>
            {/*<Button onClick={() => setShowEventModal(false)} color="primary">*/}
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary" onClick={this.validateForm}>
              Save
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    )
  }
}

const mapStateToProps = ({ auth, groups, calendar }) => ({
  auth,
  groups,
  calendar,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getScheduleUnit }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(UnitFormModal)))
