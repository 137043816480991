import React from "react"
import Dialog from "@material-ui/core/Dialog/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import SubjectPicker from "../../../../../../../components/v2/inputs/pickers/subjectPicker"
import GroupMemberPicker from "../../../../../../../components/v2/inputs/pickers/groupMemberPicker"
import { useIdParam } from "../../../../../../../hooks/useIdParam"
import Button from "@material-ui/core/Button"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import {
  useBlock,
  useBlockMutations,
  useBlockTrackSlot,
  useSlotMutations,
} from "../../../../../../../hooks/data/calendar/schedules"
import { useAlert } from "../../../../../../../hooks/useAlert"
import { getArrayIds } from "../../../../../../../library/utils/arrays"
import { getUserName } from "../../../../../../../library/utils/user/userUtils"
import TextField from "@material-ui/core/TextField/TextField"
import UserPicker from "../../../../../../../components/v2/inputs/pickers/userPicker"
import {
  GROUPS,
  SCHOOLS,
} from "../../../../../../../library/store/actions/types/groupsTypes"

const TeacherScheduleAssignmentFormModal = ({
  open,
  onClose,
  activeCell,
  schoolSchedule,
  blocksQueryKey,
  type,
}) => {
  console.log({ activeCell })
  const { errorAlert } = useAlert()
  const groupId = useIdParam("groupId")
  const currentSlot = activeCell.track.slot

  const [selectedSubject, setSelectedSubject] = React.useState(
    currentSlot?.subject?.id ? currentSlot.subject : null
  )
  const [selectedTeachers, setSelectedTeachers] = React.useState(
    currentSlot?.teachers?.length
      ? currentSlot.teachers.map((t) => ({
          ...t,
          first_name: t.name_first,
          last_name: t.name_last,
        }))
      : []
  )
  const [selectedLearners, setSelectedLearners] = React.useState(
    currentSlot?.students || []
  )

  const { updateSlot } = useSlotMutations(blocksQueryKey, false)

  const onSubmit = async () => {
    try {
      await updateSlot.mutateAsync({
        id: currentSlot.id,
        subject_id: selectedSubject.id,
        teachers: selectedTeachers.map((t) => ({
          user_id: t.id || t.user_id,
          priority: Number(t.priority),
        })),
      })
      onClose()
    } catch (e) {
      console.log(e)
      errorAlert(e)
    }
  }

  console.log(selectedTeachers)

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle className={"text-center"}>
        {!(currentSlot?.teachers?.length && currentSlot?.subject_id)
          ? "Assign"
          : "Reassign"}{" "}
        Block
      </DialogTitle>
      <DialogContent>
        <div className="form form--modal">
          {type === "teacher" && (
            <SubjectPicker
              value={selectedSubject}
              onChange={(e) => setSelectedSubject(e.target.value)}
              calendarId={
                schoolSchedule.has_district_calendar
                  ? schoolSchedule?.calendar_id
                  : undefined
              }
              scheduleId={
                !schoolSchedule.has_district_calendar
                  ? schoolSchedule?.id
                  : undefined
              }
            />
          )}
          <>
            <UserPicker
              multiple
              value={selectedTeachers}
              onChange={(v) =>
                setSelectedTeachers(v.map((t) => ({ ...t, priority: 1 })))
              }
              userLabel={"teacher"}
              requestConfig={{
                fetchConfig: {
                  apiUrl: GROUPS.PARTICIPANTS.LIST,
                  params: {
                    group_id: groupId,
                    tags: [2],
                  },
                },
                key: "users",
              }}
            />
            {!!selectedTeachers.length && (
              <div className="form__fieldset">
                <span className={"form__label"}>Teacher priority level</span>
                <div className="form__fieldbox pt-2.5">
                  {selectedTeachers.map((t, i) => (
                    <div className="form__input mb-2 last:mb-0">
                      <div className={"flex items-baseline gap-2"}>
                        <p className={"flex-1"}>{getUserName(t)}: </p>
                        <div className={"flex-1"}>
                          <TextField
                            type={"number"}
                            inputProps={{ min: 1 }}
                            value={t.priority}
                            onChange={(e) => {
                              setSelectedTeachers((prev) => {
                                return prev.map((teacher, index) => {
                                  return t.id === teacher.id
                                    ? { ...teacher, priority: e.target.value }
                                    : teacher
                                })
                              })
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="primary">
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TeacherScheduleAssignmentFormModal
