import React from "react"
import SharedGroup from "./sharedGroup"
import SharedProduct from "./sharedProduct"
import SharedKnowcred from "./sharedKnowcred"
import SharedStandard from "./sharedStandard"

const SharedPostContent = ({ type }) => {
  const getSharedLayout = () => {
    switch (type) {
      case 2:
        return <SharedGroup />

      case 3:
        return <SharedProduct />

      case 4:
        return <SharedKnowcred />

      case 5:
        return <SharedStandard />

      default:
        return null
    }
  }

  return getSharedLayout()
}

export default SharedPostContent
