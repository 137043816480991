import React, { useEffect, useMemo, useState } from "react"
import SuperSelect from "../select/superSelect"
import { STANDARDS } from "../../../../library/store/actions/types/standardsTypes"
import { standardsFilters } from "../../../../library/constants/filters/standardsFilters"
import { standardsGrades } from "../../../../library/constants/educationalDictionary"
import StaticDataSelect from "../select/staticDataSelect"
import { PlainButton } from "../../controls"
import { Close, FilterListOutlined } from "@material-ui/icons"

const IState = ""
const IGrades = []
const ISubjects = []

const StandardPicker = ({ value = [], onChange, disabled = false }) => {
  const statesList = standardsFilters()

  const [filtersOpen, setFiltersOpen] = useState(false)

  const [state, setState] = useState(IState)
  const [subjects, setSubjects] = useState(ISubjects)
  const [grades, setGrades] = useState(IGrades)
  const [standards, setStandards] = useState(value)

  const subjectsList = state?.subjects || []

  useEffect(() => {
    setSubjects([])
  }, [state])

  const onStandardsChange = (v) => {
    onChange(v)
    setStandards(v)
  }

  const onFiltersReset = () => {
    setState("")
    setGrades([])
    setSubjects([])
  }

  const hasFilters = useMemo(() => {
    return !!state || !!grades.length || !!subjects.length
  }, [state, grades, subjects])

  return (
    <div className="form__fieldset">
      <span className="form__label">Standards</span>
      <div>
        {!disabled && (
          <div style={{ paddingTop: 7 }}>
            {filtersOpen ? (
              <PlainButton
                onClick={() => setFiltersOpen(false)}
                className={"mb10"}
              >
                <Close />
                <span>Hide filters</span>
              </PlainButton>
            ) : (
              <PlainButton
                onClick={() => setFiltersOpen(true)}
                className={"mb10"}
              >
                <FilterListOutlined />
                <span>Show filters</span>
              </PlainButton>
            )}
            {filtersOpen && (
              <div className={"form__fieldbox-paper"}>
                <p>Standards filters:</p>
                <StaticDataSelect
                  fieldName={"state"}
                  entityName={"state"}
                  value={state}
                  onChange={setState}
                  data={statesList}
                  leftLabel={false}
                />
                <StaticDataSelect
                  fieldName={"subjects"}
                  entityName={"subjects"}
                  value={subjects}
                  onChange={setSubjects}
                  data={subjectsList}
                  leftLabel={false}
                  disabled={!state}
                  multiple
                  isWaitingForOtherFields={!state}
                  isWaitingForOtherFieldsMessage={"First select state"}
                />
                <StaticDataSelect
                  fieldName={"grades"}
                  entityName={"grades"}
                  value={grades}
                  onChange={setGrades}
                  data={standardsGrades}
                  leftLabel={false}
                  multiple
                />

                {hasFilters && (
                  <div className={"full-width f jcfe mt10"}>
                    <PlainButton onClick={onFiltersReset}>
                      Reset filters
                    </PlainButton>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        <SuperSelect
          disabled={disabled}
          value={standards}
          onChange={onStandardsChange}
          // onChange={console.log}
          requestConfig={{
            fetchConfig: {
              apiUrl: STANDARDS.SEARCH_LIST,
              params: {
                grades: grades.length ? grades.map((g) => g.id) : undefined,
                state: state?.id || undefined,
                subjects: subjects.length
                  ? subjects.map((s) => s.id)
                  : undefined,
              },
            },
            revalidateParams: true,
            key: "standards",
          }}
          fieldName={"standards"}
          entityName={"standards"}
          required={false}
          multiple={true}
          leftLabel={false}
          getOptionNameFallback={() => "Unnamed standard"}
          withOptionTooltip
          getOptionTooltipText={(option) => option.description || ""}
        />
      </div>
    </div>
  )
}

export default StandardPicker
