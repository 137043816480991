import React from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import { Formik } from "formik"
import * as Yup from "yup"
import Form from "../../../components/v2/layout/forms/form/form"

const validationSchema = Yup.object({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
})

const OutsiderInviteeFormModal = ({ invitee, isOpen, onClose, onSave }) => {
  const initialValues = {
    first_name: invitee?.first_name || "",
    last_name: invitee?.last_name || "",
    email: invitee?.email || "",
  }

  const onSubmit = (values) => {
    console.log("values", values)
    onSave(values)
    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={"sm"}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form isModal>
          <DialogTitle id="alert-dialog-title" className="text-center">
            {!!invitee ? "Edit Non-Member Invitee" : "Add Non-Member Invitee"}
          </DialogTitle>
          <DialogContent>
            <Form.Row label={"First name"} name={"first_name"}>
              <Form.TextField
                name={"first_name"}
                placeholder={"Type invitee first name"}
              />
            </Form.Row>
            <Form.Row label={"Last name"} name={"last_name"}>
              <Form.TextField
                name={"last_name"}
                placeholder={"Type invitee last name"}
              />
            </Form.Row>
            <Form.Row label={"Email"} name={"email"}>
              <Form.TextField
                name={"email"}
                placeholder={"Type invitee email"}
              />
            </Form.Row>
          </DialogContent>
          <DialogActions style={{ alignSelf: "flex-end" }}>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button color="primary" type={"submit"}>
              Save
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  )
}

export default OutsiderInviteeFormModal
