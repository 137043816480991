import React, { Component } from "react"
import List from "../../../../components/ui/list/list"
import Loader from "../../../../components/ui/loader/loader"
import ListItem from "./listItem"
import { bindActionCreators } from "redux"
import {
  acceptFriendRequest,
  cancelFriendRequest,
  clearList,
  declineFriendRequest,
  deleteFriend,
  getFriendsList,
  getFriendsRequestsList,
  sendFriendRequest,
  updateFriendsList,
} from "../../../../library/store/actions/creators/friendsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import * as routes from "../../../../library/constants/routes"
import {
  getPersonFriendsList,
  resetPersonFriendList,
} from "../../../../library/store/actions/creators/personCreators"
import ListSearchHeader from "../../../../components/ui/listSearchHeader/listSearchHeader"
import * as qs from "query-string"
import { LIMIT } from "../../../../library/constants/limits"
import withScroll from "../../../../hocs/withScroll/withScroll"
import getErrorText from "../../../../library/constants/errorTypes"

class FriendsList extends Component {
  state = {
    list: [],
    type:
      parseInt(this.props.match.params.userId) !== this.props.auth.userData.id
        ? "other"
        : "my",
    isFullList: false,
    initialRender: true,
    dataReceived: false,
  }

  async componentDidMount() {
    const {
      match: { params, path },
      auth: { userData },
      location: { search },
    } = this.props
    const { name } = qs.parse(search)
    try {
      switch (path) {
        case routes.FRIENDS:
          if (parseInt(params.userId) !== userData.id) {
            await this.props.getPersonFriendsList({
              name: name || undefined,
              user_id: parseInt(params.userId),
            })
            this.setState({
              list: this.props.person.friendsList,
              isFullList: !name,
              dataReceived: true,
              initialRender: false,
            })
          } else {
            await this.props.getFriendsList({
              name: name || undefined,
            })
            this.setState({
              list: this.props.friends.friendsList,
              isFullList: !name,
              dataReceived: true,
              initialRender: false,
            })
          }
          break

        case routes.FRIENDS_REQUESTS_PENDING:
          await this.props.getFriendsRequestsList({})
          this.setState({
            list: this.props.friends.pendingRequests,
            dataReceived: true,
            initialRender: false,
          })
          break

        case routes.FRIENDS_REQUESTS_OUTGOING:
          await this.props.getFriendsRequestsList({})
          this.setState({
            list: this.props.friends.outgoingRequests,
            dataReceived: true,
            initialRender: false,
          })
          break

        default:
          await this.props.getFriendsList({})
          this.setState({
            list: this.props.friends.friendsList,
            dataReceived: true,
            initialRender: false,
          })
          break
      }
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      match: { params, path },
      auth: { userData },
      location: { search },
    } = this.props
    const { name } = qs.parse(search)

    if (
      prevProps.match.path !== path ||
      qs.parse(prevProps.location.search).name !== name
    ) {
      this.props.clearList()
      this.props.resetPersonFriendList()
    }
    if (params.userId !== prevProps.match.params.userId) {
      this.props.clearList()
      this.props.resetPersonFriendList()
      this.setState({ dataReceived: false })
      try {
        if (parseInt(params.userId) !== userData.id) {
          await this.props.getPersonFriendsList({
            user_id: parseInt(params.userId),
          })
          this.setState({
            list: this.props.person.friendsList,
            type: "other",
            isFullList: !name,
            dataReceived: true,
            initialRender: false,
          })
        } else {
          await this.props.getFriendsList({})
          this.setState({
            list: this.props.friends.friendsList,
            type: "my",
            isFullList: !name,
            dataReceived: true,
            initialRender: false,
          })
        }
      } catch ({ error }) {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    } else if (
      path === routes.FRIENDS &&
      search &&
      search !== prevProps.location.search
    ) {
      this.setState({ dataReceived: false })
      try {
        if (parseInt(params.userId) !== userData.id) {
          await this.props.getPersonFriendsList({
            user_id: parseInt(params.userId),
            name: name || undefined,
          })
          this.setState({
            list: this.props.person.friendsList,
            isFullList: !name,
            dataReceived: true,
            initialRender: false,
          })
        } else {
          await this.props.getFriendsList({ name: name || undefined })
          this.setState({
            list: this.props.friends.friendsList,
            isFullList: !name,
            dataReceived: true,
            initialRender: false,
          })
        }
      } catch ({ error }) {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    }
  }

  componentWillUnmount() {
    this.props.clearList()
    this.props.resetPersonFriendList()
  }

  searchFriends = (name) => {
    const { history } = this.props

    history.push({
      search: `?name=${name}`,
    })
  }
  onScroll = async () => {
    if (!this.state.dataReceived) return
    this.setState({ dataReceived: false })
    const {
      match: { params },
      auth: { userData },
      location: { search },
    } = this.props
    const { name } = qs.parse(search)
    try {
      if (parseInt(params.userId) !== userData.id) {
        await this.props.getPersonFriendsList({
          user_id: parseInt(params.userId),
          offset: this.state.list.length,
          name: name || undefined,
          limit: LIMIT,
        })
        this.setState({
          list: this.props.person.friendsList,
          type: "other",
          isFullList: !name,
          dataReceived: true,
          initialRender: false,
        })
      } else {
        await this.props.getFriendsList({
          offset: this.state.list.length,
          name: name || undefined,
          limit: LIMIT,
        })
        this.setState({
          list: this.props.friends.friendsList,
          type: "my",
          isFullList: !name,
          dataReceived: true,
          initialRender: false,
        })
      }
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  updateList = async () => {
    await this.props.updateFriendsList({
      user_id: parseInt(this.props.match.params.userId),
      offset: 0,
      limit: this.state.list.length,
    })
    this.setState({
      list: this.props.friends.friendsList,
    })
  }

  render() {
    const {
      match: { params },
      auth: { userData },
    } = this.props
    const { list, type, isFullList, initialRender, dataReceived } = this.state

    const noResults =
      parseInt(params.userId) !== userData.id
        ? this.props.person.friendsList.length === 0
        : this.props.friends.friendsList.length === 0

    const counter =
      parseInt(params.userId) !== userData.id
        ? this.props.person.friendsNumber
        : this.props.friends.friendsNumber

    const noResultsLabel =
      this.props.person.personData && parseInt(params.userId) !== userData.id
        ? `${this.props.person.personData.first_name} doesn't have friends yet`
        : "You don't have friends yet"
    return (
      <>
        <ListSearchHeader
          title="All Friends"
          placeholder="Start typing friend's name"
          search={this.searchFriends}
          counter={dataReceived ? counter || "" : ""}
          hasButton={parseInt(params.userId) === userData.id}
          buttonLabel="Find friends"
          buttonAction={() => this.props.history.push(routes.FRIENDS_SEARCH)}
          noResults={dataReceived && noResults && !initialRender}
          noResultsLabel={isFullList && noResultsLabel}
        />
        {!!list.length && (
          <List>
            {list.map((item) => (
              <ListItem
                user={item}
                type={type}
                key={item.id}
                updateList={this.updateList}
              />
            ))}
          </List>
        )}
        {!dataReceived && <Loader />}
      </>
    )
  }
}

const mapStateToProps = ({auth, friends, person}) => ({auth, friends, person});
const mapDispatchToProps = dispatch => bindActionCreators({
    getPersonFriendsList,
    getFriendsList,
    getFriendsRequestsList,
    sendFriendRequest,
    cancelFriendRequest,
    acceptFriendRequest,
    declineFriendRequest,
    deleteFriend,
    clearList,
    resetPersonFriendList,
    updateFriendsList,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withScroll(FriendsList)));
