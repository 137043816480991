import React from "react"
import { useLikesMutations } from "../../../../hooks/data/likes"
import { mutationTypes } from "../../../../library/utils/reactQuery"
import { useAlert } from "../../../../hooks/useAlert"
import { usePostCtx } from "../post/PostContext"
import { useCommentsMutations } from "../../../../hooks/data/feed/comments"

const ReplyContext = React.createContext(null)

export const useReplyCtx = () => React.useContext(ReplyContext)

const ReplyProvider = ({ reply, children }) => {
  const { errorAlert } = useAlert()
  const { post, queryKey } = usePostCtx()

  const [isEdit, setIsEdit] = React.useState(false)
  const [isArchived, setIsArchived] = React.useState(false)

  const { updateComment, deleteComment, restoreComment } = useCommentsMutations(
    { queryKey }
  )

  const onDelete = async () => {
    try {
      await deleteComment.mutateAsync({ id: reply.id })
      setIsArchived(true)
    } catch (e) {
      errorAlert(e)
    }
  }
  const onRestore = async () => {
    try {
      await restoreComment.mutateAsync({ id: reply.id })
      setIsArchived(false)
    } catch (e) {
      errorAlert(e)
    }
  }
  const onEdit = async ({ message }) => {
    try {
      await updateComment.mutateAsync({
        id: reply.id,
        message,
        post_id: post.id,
        comment_id: reply.reply_to,
      })
      setIsEdit(false)
    } catch (e) {
      errorAlert(e)
    }
  }

  const { likes } = useLikesMutations({
    key: queryKey,
    mutationType: mutationTypes.infinite,
  })

  const onLike = async () => {
    if (!reply.is_liked) {
      await likes.create.mutateAsync({
        data: { comment_id: reply.id },
        updater: (p) =>
          p.id === post.id
            ? {
                ...p,
                comments: p.comments.map((c) =>
                  c.id === reply.reply_to
                    ? {
                        ...c,
                        replies: c.replies.map((r) =>
                          r.id === reply.id
                            ? {
                                ...r,
                                is_liked: true,
                                likes_number: r.likes_number + 1,
                              }
                            : r
                        ),
                      }
                    : c
                ),
              }
            : p,
      })
    } else {
      await likes.delete.mutateAsync({
        data: { comment_id: reply.id },
        updater: (p) =>
          p.id === post.id
            ? {
                ...p,
                comments: p.comments.map((c) =>
                  c.id === reply.reply_to
                    ? {
                        ...c,
                        replies: c.replies.map((r) =>
                          r.id === reply.id
                            ? {
                                ...r,
                                is_liked: false,
                                likes_number: r.likes_number - 1,
                              }
                            : r
                        ),
                      }
                    : c
                ),
              }
            : p,
      })
    }
  }

  return (
    <ReplyContext.Provider
      value={{
        reply,
        isArchived,
        isEdit,
        setIsEdit,
        onDelete,
        onRestore,
        onEdit,
        onLike,
        updateComment,
      }}
    >
      {children}
    </ReplyContext.Provider>
  )
}

export default ReplyProvider
