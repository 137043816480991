import { call } from "../../../networking/API"
import { POSTS } from "../types/postsTypes"

export const getPostsList = ({
  type,
  user_id,
  group_id,
  bookmarked,
  folder_id,
  offset,
  limit = 5,
  comments_limit = 100,
  replies_limit = 100,
}) => {
  return async (dispatch, getState) => {
    const response =
      type === "feed"
        ? await call(POSTS.FEED, {
            token: getState().auth.userData.token,
            offset,
            limit,
            comments_limit,
            replies_limit,
          })
        : await call(POSTS.LIST, {
            token: getState().auth.userData.token,
            user_id,
            group_id,
            bookmarked,
            folder_id,
            offset,
            limit,
            comments_limit,
            replies_limit,
          })
    const json = await response.data

    if (response.status === 200) {
      switch (type) {
        case "my":
          return dispatch({
            type: POSTS.MY_LIST,
            payload: json.posts,
          })

        case "feed":
          return dispatch({
            type: POSTS.FEED,
            payload: json.posts,
          })

        default:
          return dispatch({
            type: POSTS.LIST,
            payload: json.posts,
          })
      }
    } else {
      throw json.error
    }
  }
}

export const updatePostsList = ({type, user_id, group_id, bookmarked, offset, limit = 5, comments_limit = 100, replies_limit = 100}) => {
    return async (dispatch, getState) => {
        const response = type === "feed" ?
            (await call(POSTS.FEED, {
                token: getState().auth.userData.token,
                offset,
                limit,
                comments_limit,
                replies_limit,
            }))
            :
            (await call(POSTS.LIST, {
                token: getState().auth.userData.token,
                user_id,
                group_id,
                bookmarked,
                offset,
                limit,
                comments_limit,
                replies_limit,
            }));
        const json = await response.data;

        if (response.status === 200) {
            switch (type) {
                case "my":
                    return dispatch({
                        type: POSTS.MY_LIST_UPDATE,
                        payload: json.posts,
                    });

                case "feed":
                    return dispatch({
                        type: POSTS.FEED_UPDATE,
                        payload: json.posts,
                    });
                default:
                    return dispatch({
                        type: POSTS.LIST_UPDATE,
                        payload: json.posts,
                    });
            }
        } else {
            throw json.error;
        }
    };
};

export const getPost = ({id, comments_limit = 100, replies_limit = 100}) => {
    return async (dispatch, getState) => {
        const response = await call(POSTS.GET, {
            token: getState().auth.userData.token,
            id,
            comments_limit,
            replies_limit,
        });
        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: POSTS.GET,
                payload: json,
            });
        } else {
            throw json.error;
        }
    };
};

export const clearPost = () => {
    return async dispatch => {
        return dispatch({
            type: POSTS.CLEAR,
        });
    };
};

export const clearPostsList = (type = "") => {
    return async dispatch => {
        if (type === "my") {
            return dispatch({
                type: POSTS.MY_CLEAR,
            });
        } else {
            return dispatch({
                type: POSTS.LIST_CLEAR,
            });
        }
    };
};

export const createPost = ({user_id, group_id, message, attachments, personal}) => {
    return async (dispatch, getState) => {
        const response = await call(POSTS.CREATE, {
            token: getState().auth.userData.token,
            user_id,
            group_id,
            message,
            attachments,
            personal,
        });
        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: POSTS.CREATE,
            });
        } else {
            throw json.error;
        }
    };
};

export const updatePost = ({id, message, attachments}) => {
    return async (dispatch, getState) => {
        const response = await call(POSTS.UPDATE, {
            token: getState().auth.userData.token,
            id,
            message,
            attachments,
        });
        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: POSTS.UPDATE,
            });
        } else {
            throw json.error;
        }
    };
};

export const deletePost = ({id}) => {
    return async (dispatch, getState) => {
        const response = await call(POSTS.ARCHIVE, {
            token: getState().auth.userData.token,
            id,
        });
        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: POSTS.ARCHIVE,
            });
        } else {
            throw json.error;
        }
    };
};

export const restorePost = ({id}) => {
    return async (dispatch, getState) => {
        const response = await call(POSTS.RESTORE, {
            token: getState().auth.userData.token,
            id,
        });

        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: POSTS.RESTORE,
            });
        } else {
            throw json.error;
        }
    };
};

export const sharePost = ({post_id, user_id, group_id, product_id, knowcred_id, standard_id}) => {
    return async (dispatch, getState) => {
        const response = await call(POSTS.SHARE, {
            token: getState().auth.userData.token,
            post_id,
            user_id,
            group_id,
            product_id,
            knowcred_id,
            standard_id,
        });
        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: POSTS.SHARE,
            });
        } else {
            throw json.error;
        }
    };
};


export const like = ({type, post_id, comment_id, product_review_id}) => {
    return async (dispatch, getState) => {
        const response = await call(POSTS.LIKES.CREATE, {
            token: getState().auth.userData.token,
            post_id,
            comment_id,
            product_review_id,
        });
        const json = await response.data;

        if (type === "post") {
            if (response.status === 200) {
                return dispatch({
                    type: POSTS.LIKES.CREATE,
                    payload: post_id,
                });
            } else {
                throw json.error;
            }
        }
    };
};

export const unLike = ({type, post_id, comment_id, product_review_id}) => {
    return async (dispatch, getState) => {
        const response = await call(POSTS.LIKES.DELETE, {
            token: getState().auth.userData.token,
            post_id,
            comment_id,
            product_review_id,
        });
        const json = await response.data;

        if (type === "post") {
            if (response.status === 200) {
                return dispatch({
                    type: POSTS.LIKES.DELETE,
                    payload: post_id,
                });
            } else {
                throw json.error;
            }
        }
    };
};

export const pinPost = ({id}) => {
    return async (dispatch, getState) => {
        const response = await call(POSTS.PIN, {
            token: getState().auth.userData.token,
            id,
        });
        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: POSTS.PIN,
            });
        } else {
            throw json.error;
        }
    };
};

export const unpinPost = ({id}) => {
    return async (dispatch, getState) => {
        const response = await call(POSTS.UNPIN, {
            token: getState().auth.userData.token,
            id,
        });
        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: POSTS.UNPIN,
            });
        } else {
            throw json.error;
        }
    };
};

export const createComment = ({post_id, comment_id, message, type, personal = true}) => {
    return async (dispatch, getState) => {
        const userData = getState().auth.userData;
        const myPostsList = JSON.parse(JSON.stringify([...getState().posts.myPostsList]));
        const postsList = JSON.parse(JSON.stringify([...getState().posts.postsList]));
        const feedList = JSON.parse(JSON.stringify([...getState().posts.feedList]));
        const posts = type === "my" ? myPostsList : type === "feed" ? feedList : postsList;
        const post = posts.find(post => post.id === post_id);
        const comment = post.comments.find(comment => comment.id === comment_id);
        const params = {
            token: userData.token,
            comment_id,
            message,
            personal,
        };
        const commentListParams = {
            token: userData.token,
            post_id,
            comment_id,
            offset: 0,
            limit: post.comments_number + 10,
            replies_limit: comment ? comment.replies_number + 10 : 10,
        };
        if (!comment_id) params.post_id = post_id;
        const response = await call(POSTS.COMMENTS.CREATE, params);
        const commentsResponse = await call(POSTS.COMMENTS.LIST, commentListParams);
        if (getState().posts.currentPost) dispatch(getPost({
            id: post_id,
            replies_limit: comment ? comment.replies_number + 10 : 10,
            comments_limit: post.comments_number + 10,
        }));
        const json = await response.data;
        post.comments = await commentsResponse.data.comments;
        if (response.status === 200) {
            return dispatch({
                type: POSTS.COMMENTS.CREATE,
                payload: {
                    myPostsList: [...myPostsList],
                    postsList: [...postsList],
                    feedList: [...feedList],
                },
            });
        } else {
            throw json.error;
        }
    };
};


export const updateComment = ({id, message}) => {
    return async (dispatch, getState) => {
        const response = await call(POSTS.COMMENTS.UPDATE, {
            token: getState().auth.userData.token,
            id,
            message,
        });
        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: POSTS.COMMENTS.UPDATE,
            });
        } else {
            throw json.error;
        }
    };
};

export const deleteComment = ({id}) => {
    return async (dispatch, getState) => {
        const response = await call(POSTS.COMMENTS.DELETE, {
            token: getState().auth.userData.token,
            id,
        });
        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: POSTS.COMMENTS.DELETE,
                payload: id,
            });
        } else {
            throw json.error;
        }
    };
};

export const restoreComment = ({id}) => {
    return async (dispatch, getState) => {
        const response = await call(POSTS.COMMENTS.RESTORE, {
            token: getState().auth.userData.token,
            id,
        });

        return dispatch({
            type: POSTS.COMMENTS.RESTORE,
            payload: id,
        });

        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: POSTS.COMMENTS.RESTORE,
                payload: id,
            });
        } else {
            throw json.error;
        }
    };
};
