import React from "react"
import { Li, Ul } from "../../uknow/uknowInfoPage"
import img1 from "../../../../assets/img/pages/how-to/meets/1.png"

const HowToMeets = () => {
  return (
    <div className={"f aic fdc-xs"}>
      <div style={{ flex: 1 }}>
        <img src={img1} />
      </div>
      <div style={{ flex: 2 }}>
        <p className={"mb15"}>Create Meet</p>
        <Ul>
          <Li>
            Select a cohort number equal to all participants if you do not want
            it to break into multi-meetings. Select a smaller number than your
            total invited users if you are using the auto-cohorting function for
            subsets.
          </Li>
          <Li>
            Selecting a target date alerts invited users of the intended date
            and time. If auto-cohorting, this date will use the target interval
            to establish a new date once the first one passes. You can leave
            target date and interval blank.
          </Li>
          <Li>
            Selecting a target interval is the time length after your target
            date to set a new target date. The target interval will keep issuing
            new target dates as long as the meeting is released. Calculations
            below the interval show the daily searched time in your chosen holds
            or blocks. The system will find time within the first 7 days but not
            before your target date and time. It will reset with each target
            based on your interval.
          </Li>
          <Li>
            Type in physical location or put Virtual if only using a conference
            link which is put in towards the bottom of the form.
          </Li>
          <Li>
            For existing users, start typing the name(s) to find easily and
            select each.
          </Li>
          <Li>
            Adding outside invitees by email will send them the meeting
            invitation. They must create a free account to see their Tracking
            and accept the meeting.
          </Li>
          <Li>
            A Hold is a defined open time you want the meeting to fall into. Use
            multiple holds if needed. Make your Holds under your Profile
            settings. The default without holds is work weekdays Mon.-Fri.
            8am-5pm.
          </Li>
          <Li>
            The Meet Length is how long you want the meeting to be for when it
            happens.
          </Li>
          <Li>
            Add instructions if you would like the invitees to complete
            something before accepting the meeting.
          </Li>
          <Li>
            The Reminder will appear only for you, the meeting creator in your
            Tracking and on the calendar item. Use it to help yourself remember
            what you’re doing in the meeting.
          </Li>
        </Ul>
      </div>
    </div>
  )
}

export default HowToMeets
