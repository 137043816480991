import React from "react"
import {
  PageContentLayout,
  PageMain,
  PageRightSidebar,
} from "../../../../components/v2/layout/layout"
import { useCourse } from "../../../../hooks/data/courses/courses"
import KnowmixPageSkeleton from "../../../../components/v2/layout/skeleton/knowmixPage/KnowmixPageSkeleton"
import NotFound from "../../../../components/notFound"
import KnowmixError from "../knowmixError"
import { generatePath, Redirect } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import { useIdParam } from "../../../../hooks/useIdParam"
import CourseLogo from "./components/CourseLogo"
import CourseSidebarButtons from "./components/CourseSidebarButtons"
import CourseSidebarInfo from "./components/CourseSidebarInfo"
import CourseMainInfo from "./components/CourseMainInfo"
import CourseSteps from "./components/CourseSteps"
import SEO from "../../../../components/SEO"

const CoursePage = () => {
  const knowmixId = useIdParam("knowmixId")
  const { course, canEdit, isAvailable, isSuccess, isLoading, error } =
    useCourse()

  if (!isSuccess && isLoading) {
    return <KnowmixPageSkeleton />
  }

  if ([23000, 20004].includes(error?.error?.code)) {
    return <NotFound entity="Knowmix" />
  }

  if (!isAvailable && canEdit) {
    return <KnowmixError knowmix={course} />
  }

  if (
    !!course?.product &&
    !(course?.is_admin || course?.is_owned || course?.is_product_admin)
  ) {
    return (
      <Redirect
        to={generatePath(routes.PRODUCT, {
          productId: course?.product?.id,
        })}
      />
    )
  }

  return (
    <PageContentLayout>
      <SEO title={course?.name} />
      <PageMain>
        <CourseMainInfo />[
        <CourseSteps />
      </PageMain>
      <PageRightSidebar>
        <CourseLogo />
        <CourseSidebarInfo />
        <CourseSidebarButtons />
      </PageRightSidebar>
    </PageContentLayout>
  )
}

export default CoursePage
