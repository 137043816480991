import React, { useEffect, useState } from "react"
import { navigate } from "react-big-calendar/lib/utils/constants"
import { useDispatch, useSelector } from "react-redux"
import {
  clearScheduleUnitsList,
  createScheduleUnit,
  getScheduleUnitsList,
} from "../../../../../../../../library/store/actions/creators/calendarCreators"
import { useParams } from "react-router-dom"
import { useAlert } from "../../../../../../../../hooks/useAlert"
import { addMonths, differenceInMonths, subMonths } from "date-fns"
import { colors } from "../../../../../../../../library/constants/styles/colors"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import GroupSettingsCalendarProvider from "../../GroupSettingsCalendarContext"
import UnitFormModal from "../../groupSettingsCalendarSubjects/subpages/groupSettingsCalendarUnits/components/unitFormModal"
import KSCalendar from "../../../../../../../../components/v2/calendar/calendar"
import { CalendarEventWrapper } from "../../../../../../calendar/components/eventsCalendar/components/calendarUI"

const UnitsCalendar = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const { errorAlert } = useAlert()

  const [date, setDate] = useState(new Date())
  const [isLoading, setIsLoading] = useState(true)

  const [isFormOpen, setIsFormOpen] = useState(false)
  const [newUnitPeriod, setNewUnitPeriod] = useState(null)

  const { unitsList, unitsListEndReached } = useSelector(({ calendar }) => ({
    unitsList: calendar.unitsList.map((u) => ({
      id: u.id,
      title: u.name,
      start: u.date_from,
      end: u.date_to,
      // allDay: u.is_all_day,
      allDay: false,
      // color: u.color,
      color: colors.primary,
      desc: u.description,
    })),
    unitsListEndReached: calendar.unitsListEndReached,
  }))

  const [activeRange, setActiveRange] = useState({
    date_from: subMonths(new Date(), 4),
    date_to: addMonths(new Date(), 4),
  })

  const getUnits = async () => {
    try {
      setIsLoading(true)
      await dispatch(
        getScheduleUnitsList({
          subject_id: parseInt(params.subjectId, 10),
          date_from: activeRange.date_from,
          date_to: activeRange.date_to,
        })
      )
      // console.log({ list })
      setIsLoading(false)
    } catch (e) {
      errorAlert(e)
    }
  }

  useEffect(() => {
    getUnits()

    return () => dispatch(clearScheduleUnitsList())
  }, [])

  useEffect(() => {
    if (differenceInMonths(date, activeRange.date_from) < 2) {
      setActiveRange((prevRange) => ({
        date_from: subMonths(prevRange.date_from, 4),
        date_to: subMonths(prevRange.date_to, 4),
      }))
    }

    if (differenceInMonths(activeRange.date_to, date) < 2) {
      setActiveRange((prevRange) => ({
        date_from: addMonths(prevRange.date_from, 4),
        date_to: addMonths(prevRange.date_to, 4),
      }))
    }
  }, [date])

  useEffect(() => {
    if (!isLoading) {
      getUnits()
    }
  }, [activeRange])

  console.log(unitsList)

  const onNavigate = (d) => {
    setDate(d)
  }

  const onFormOpen = (period = null) => {
    setNewUnitPeriod(period)
    setIsFormOpen(true)
  }

  const onFormClose = () => {
    setNewUnitPeriod(null)
    setIsFormOpen(false)
  }

  const onCreateUnit = async (data) => {
    try {
      await dispatch(createScheduleUnit(data))
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <div>
      <GroupSettingsCalendarProvider>
        {({ onUnitDialogOpen }) => (
          <KSCalendar
            date={date}
            onNavigate={onNavigate}
            eventsList={unitsList}
            height={700}
            getToolbar={(toolbarProps) => (
              <CalendarToolbar {...toolbarProps} onFormOpen={onFormOpen} />
            )}
            getMonthEvent={(eventProps) => (
              <CalendarEventWrapper {...eventProps} noTime />
            )}
            onSelectEvent={(unit) => onUnitDialogOpen(unit.id)}
            onSelectSlot={({ start, end }) => onFormOpen({ start, end })}
          />
        )}
      </GroupSettingsCalendarProvider>

      {isFormOpen && (
        <UnitFormModal
          open={isFormOpen}
          handleClose={onFormClose}
          onSubmit={onCreateUnit}
          edit={false}
          period={newUnitPeriod}
        />
      )}
    </div>
  )
}

const CalendarToolbar = (props) => {
  console.log(props)

  const navigateToday = () => props.onNavigate(navigate.TODAY, null)
  const navigatePrev = async () => props.onNavigate(navigate.PREVIOUS, null)
  const navigateNext = async () => props.onNavigate(navigate.NEXT, null)

  return (
    <div className="box__heading events-calendar-toolbar">
      <div className={'events-calendar-toolbar__left'}>
        <Button
          variant={'outlined'}
          color={'primary'}
          onClick={navigateToday}
          className={'mr15'}
        >
          Today
        </Button>
        <IconButton aria-label="Previous" size="small" onClick={navigatePrev}>
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton aria-label="Next" size="small" onClick={navigateNext}>
          <KeyboardArrowRight />
        </IconButton>
        <span className={'ml15'}>{props.label}</span>
      </div>
      <div className={'events-calendar-toolbar__right'}>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={props.onFormOpen}
        >
          Create unit
        </Button>
      </div>
    </div>
  )
}

export default UnitsCalendar
