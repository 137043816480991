import React from "react"
import { useSubscriptions, useSubscriptionsQuery } from "./useSubscriptions"
import BoxPage from "../../../../../components/v2/layout/boxPage/boxPage"
import Loader from "../../../../../components/ui/loader"
import { formatDate } from "../../../../../library/utils/dateConvert"
import Button from "@material-ui/core/Button"
import { usePaidPlansQuery } from "../../../../info/pricing/usePlans"
import { PlainButton } from "../../../../../components/v2/controls"
import LinkIcon from "@material-ui/icons/Link"
import { CONTACT_FORM, PRICING } from "../../../../../library/constants/routes"
import { Link } from "react-router-dom"
import GroupSubscriptionInfo from "../../../groups/group/groupSettings/groupSettingsSubscription/groupSubscriptionInfo"
import { colors } from "../../../../../library/constants/styles/colors"
import PlainLink from "../../../../../components/ui/link"

const ProfileSettingsSubscriptions = () => {
  const { subscriptions, isSuccess } = useSubscriptionsQuery()
  const { openCustomerPortal, calcFlatSubscriptionPrice } = useSubscriptions()
  // const { upcomingInvoice, isSuccess: isUpcomingInvoiceSuccess } =
  //   useUpcomingInvoiceQuery({
  //     enabled: false,
  //     // enabled:
  //     //   !!subscriptions?.personal_subscription ||
  //     //   !!subscriptions?.group_subscriptions?.length,
  //   })
  const { paidPlans, isSuccess: isPlansSuccess } = usePaidPlansQuery()

  const plan = paidPlans?.find(
    (p) => p.id === subscriptions?.personal_subscription?.plan_id
  )

  const hasManagedSubscriptions =
    subscriptions?.personal_subscription ||
    !!subscriptions?.group_subscriptions?.length

  const systemSubscription = subscriptions?.system_subscription

  return (
    <BoxPage title={"Subscriptions"}>
      {isSuccess && isPlansSuccess ? (
        <>
          {!!systemSubscription && (
            <>
              <div>
                <p className={"text-center"}>
                  You are using a system subscription to{" "}
                  {systemSubscription.plan_name} plan. <br />
                  To manage it,{" "}
                  <PlainLink to={CONTACT_FORM} inline newTab>
                    contact the support
                  </PlainLink>
                  .
                </p>
              </div>
              <div className="h-divider h-divider--vertical-margin mt20 mb20" />
            </>
          )}
          <div>
            <p className={"mb15"}>Current personal subscription plan: </p>

            {subscriptions.personal_subscription ? (
              <div>
                <div>
                  <p className={"font-16 font-weight-500 mb5"}>
                    {subscriptions.personal_subscription.plan_name}{" "}
                    {
                      calcFlatSubscriptionPrice({
                        unit_amount:
                          plan.prices[
                            subscriptions.personal_subscription.period
                          ].unit_amount,
                        period: subscriptions.personal_subscription.period,
                      }).total
                    }
                  </p>
                  <div
                    className={"pl10"}
                    style={{ borderLeft: `2px solid ${colors.black12}` }}
                  >
                    <p className={"mb5"}>
                      Status:{" "}
                      {subscriptions.personal_subscription.canceled
                        ? "Canceled"
                        : "Active"}
                    </p>
                    <p>
                      Next payment:{" "}
                      {formatDate(
                        subscriptions.personal_subscription.current_period_end
                      )}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <p className={"color-black-54"}>
                You have no personal subscription yet
              </p>
            )}
          </div>
          <div className="h-divider h-divider--vertical-margin mt20 mb20" />
          <div>
            <p className={"mb15"}>Group subscriptions: </p>
            {subscriptions.managed_group_subscriptions?.length ? (
              subscriptions.managed_group_subscriptions.map((s) => (
                <GroupSubscriptionInfo
                  subscription={s}
                  canManage={false}
                  key={s.id}
                />
              ))
            ) : (
              <p className={"color-black-54"}>
                You have no groups subscriptions yet
              </p>
            )}
          </div>
          <div className="h-divider h-divider--vertical-margin mt20 mb20" />

          {hasManagedSubscriptions ? (
            <>
              <div className="f fdc aic">
                <p className={"text-center mb10"}>
                  You can manage your subscriptions, change payment details, see
                  previous payments in the
                </p>
                <PlainButton onClick={openCustomerPortal} inline>
                  <LinkIcon color={"primary"} />
                  <span>Customer portal</span>
                </PlainButton>
              </div>
            </>
          ) : (
            <div className="mt30 f fdc aic">
              <p className={"text-center mb10"}>
                Check out our pricing plans and choose the one that suits you
                best
              </p>
              <Button
                component={Link}
                to={PRICING}
                color={"primary"}
                variant={"contained"}
              >
                Go to pricing
              </Button>
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </BoxPage>
  )
}

export default ProfileSettingsSubscriptions
