import React, { Component } from "react"
import unavailableAvatar from "../../../../assets/img/user_unavailable.svg"
import bgDefault from "../../../../assets/img/bg-default.png"
import { bindActionCreators } from "redux"
import { deleteFriend } from "../../../../library/store/actions/creators/friendsCreators"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withSnackbar } from "notistack"
import DropdownControl from "../../../../components/ui/dropdowns/dropdownControl/dropdownControl"
import MenuList from "@material-ui/core/MenuList/MenuList"
import { withStyles } from "@material-ui/core"
import { colors } from "../../../../library/constants/styles/colors"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import getErrorText from "../../../../library/constants/errorTypes"
import {
  getData,
  updateInfo,
} from "../../../../library/store/actions/creators/authCreators"

const CustomMenuItem = withStyles({
  root: {
    color: colors.primary,
  },
})(MenuItem)

class UserError extends Component {
  state = {
    isFriend: this.props.user.is_friend && !this.props.user.is_blocking,
  }

  deleteFriend = async () => {
    const {
      match: { params },
    } = this.props

    try {
      await this.props.deleteFriend(parseInt(params.userId))
      this.setState({ isFriend: false })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  restoreProfile = async () => {
    const {
      auth: { userData },
    } = this.props

    await this.props.updateInfo({ restore: true })
    await this.props.getData()

    window.location.reload()
  }

  render() {
    const { user } = this.props
    const { isFriend } = this.state
    const fullName = `${user.first_name} ${user.last_name}` || ""

    return (
      <div className="profile-container">
        <div className="profile-container__header box">
          <div className="profile-header user-profile-header">
            <div
              className="profile-header__top"
              style={{ backgrounImage: bgDefault }}
            ></div>
            <div
              className={`profile-header__bottom ${isFriend ? "profile-header__bottom--profile-deleted" : ""}`}
            >
              <div className="main-user-avatar">
                <img
                  src={unavailableAvatar}
                  alt="avatar"
                  className="main-user-avatar__img cursor-auto"
                />
              </div>
              <div className="profile-info aic">
                <span className="profile-info__name mb8">{fullName}</span>
                <span
                  className={`profile-info__secondary font-16 ${isFriend ? "mb15" : ""}`}
                >
                  {!user.is_blocking && user.blocked && "Profile blocked"}
                  {!user.is_blocking && user.deleted && "Profile deleted"}
                </span>
                {isFriend && (
                  <DropdownControl buttonLabel="Friends">
                    <MenuList>
                      <CustomMenuItem onClick={this.deleteFriend}>
                        Remove from friends
                      </CustomMenuItem>
                    </MenuList>
                  </DropdownControl>
                )}
              </div>
            </div>
          </div>
        </div>
        {user.blocked || user.deleted ? (
          <div className="box p30 text-center">
            <p className="color-black-54">This profile has been deleted.</p>

            {parseInt(this.props.match.params.userId) !==
            this.props.auth.userData.id ? (
              <p className="color-black-54">
                Information on this profile is unavailable.
              </p>
            ) : (
              <p className="color-black-54">
                You can{" "}
                <span
                  className="link link--inline"
                  onClick={this.restoreProfile}
                >
                  restore your profile.
                </span>
              </p>
            )}
          </div>
        ) : (
          <div className="box p30 text-center">
            <p className="color-black-54">
              You are not allowed to see this user's content and send messages
            </p>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ auth, person, friends }) => ({
  auth,
  person,
  friends,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ deleteFriend, updateInfo, getData }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(UserError)))
