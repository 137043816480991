import React from "react"
import NotificationIcon from "@material-ui/icons/Notifications"
import { useSelector } from "react-redux"
import { Popover } from "@material-ui/core"
import NotificationsList from "./NotificationsList"

const NotificationsDropdown = () => {
  const { notificationCounter } = useSelector(
    ({ notifications }) => notifications
  )

  const [anchorEl, setAnchorEl] = React.useState(null)

  const onOpen = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const onClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <button
        onClick={onOpen}
        className={"flex items-center justify-center relative"}
      >
        <NotificationIcon className="color-black-38" />
        {/*{!!notificationCounter && (*/}
        <div className="absolute flex items-center justify-center top-[-2px] right-[-3px] w-[14px] h-[14px] p-0.5 rounded-full bg-primary text-[0.5rem] text-white">
          <span>{notificationCounter}</span>
        </div>
        {/*)}*/}
      </button>
      <Popover
        id={"notifications-dropdown"}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <NotificationsList />
      </Popover>
    </div>
  )
}

export default NotificationsDropdown
