export const productContentTypes = [
  { id: 15, name: "KnowMix" },
  // {id: 16, name: "Kourse"},
  { id: 1, name: "App" },
  { id: 2, name: "Assessment/Testing" },
  { id: 3, name: "Learning Object" },
  { id: 4, name: "Courseware" },
  { id: 5, name: "eBook" },
  { id: 6, name: "Learning Game" },
  { id: 7, name: "Service" },
  { id: 8, name: "School Management Software" },
  { id: 9, name: "Technical Skills Software" },
  { id: 10, name: "Software Tool" },
  { id: 11, name: "Video" },
  { id: 12, name: "Other" },
  { id: 13, name: "All" },
  { id: 14, name: "Non-digital" },
]

export const productPlatforms = [
  { id: 1, name: "Android" },
  { id: 2, name: "macOS" },
  { id: 3, name: "iOS" },
  { id: 4, name: "Kindle" },
  { id: 5, name: "Linux" },
  { id: 6, name: "Windows" },
  { id: 7, name: "Web" },
  { id: 8, name: "Other" },
  { id: 9, name: "watchOS" },
]

export const productEnhancements = [
  { id: 1, name: "Administrative Personalization" },
  { id: 2, name: "Analytics" },
  { id: 3, name: "Animations" },
  { id: 4, name: "Audio Enhancement" },
  { id: 5, name: "Characters" },
  { id: 6, name: "Avatars" },
  { id: 7, name: "Annotating" },
  { id: 8, name: "Assembly" },
  { id: 9, name: "Chunking" },
  { id: 10, name: "Clip Embedding" },
  { id: 11, name: "Coding" },
  { id: 12, name: "Collaboration-ware" },
  { id: 13, name: "Data Collection Infrastructure" },
  { id: 14, name: "Device input" },
  { id: 15, name: "Distance Live-Lab" },
  { id: 16, name: "Dynamic Curation" },
  { id: 17, name: "Dynamic Definitions" },
  { id: 18, name: "Enveloping/Pull Mechanisms" },
  { id: 19, name: "Favoriting" },
  { id: 20, name: "Feedback" },
  { id: 21, name: "Formative Assessment" },
  { id: 22, name: "Gambling" },
  { id: 23, name: "Gaming Mastery" },
  { id: 24, name: "Gaming Rewards" },
  { id: 25, name: "Gating" },
  { id: 26, name: "Gesture Controlled Data" },
  { id: 27, name: "Grouping" },
  { id: 28, name: "Inference" },
  { id: 29, name: "Intelligent Learning Engines" },
  { id: 30, name: "Interactive Queries" },
  { id: 31, name: "Live-Chat" },
  { id: 32, name: "Live-Video" },
  { id: 33, name: "Machine Learning" },
  { id: 34, name: "Manipulatives Interfacing" },
  { id: 35, name: "Metrics" },
  { id: 36, name: "Multiple languages" },
  { id: 37, name: "Plagiarism checking" },
  { id: 38, name: "Pre-Assessment" },
  { id: 39, name: "Portability" },
  { id: 40, name: "Practice Microgames" },
  { id: 41, name: "Programming Practice" },
  { id: 42, name: "Project-based" },
  { id: 43, name: "Project Mastery" },
  { id: 44, name: "Projection" },
  { id: 45, name: "Real-time Attention Data/Neuro-determinism" },
  { id: 46, name: "Second-Screen" },
  { id: 47, name: "Sketching" },
  { id: 48, name: "Social Gaming" },
  { id: 49, name: "Social Interaction" },
  { id: 50, name: "Social Experimenters" },
  { id: 51, name: "Spelling Checkers" },
  { id: 52, name: "Standards Alignment/Attainment" },
  { id: 53, name: "Student Personalization" },
  { id: 54, name: "Summative Assessment" },
  { id: 55, name: "Terminology" },
  { id: 56, name: "Touch Enabled" },
  { id: 57, name: "Training" },
  { id: 58, name: "Video Embedding" },
  { id: 59, name: "Visual Advantages" },
  { id: 60, name: "Voice" },
]

export const productIntegrationComplianceTypes = [
  { id: 1, name: "SCORM" },
  { id: 2, name: "LTI" },
]

export const productAquisionTypes = [
  { id: 1, name: "User" },
  { id: 2, name: "Group" },
]

export const productFileTypes = [
  { id: 1, name: "Kindle Format 8 (KF8)" },
  { id: 2, name: "Kindle Mobi" },
  { id: 3, name: "TXT" },
  { id: 4, name: "PDF" },
  { id: 5, name: "MOBI" },
  { id: 6, name: "PRC Natively" },
  { id: 7, name: "AA" },
  { id: 8, name: "AAX" },
  { id: 9, name: "DOC" },
  { id: 10, name: "DOCX" },
  { id: 11, name: "JPEG" },
  { id: 12, name: "GIF" },
  { id: 13, name: "PNG" },
  { id: 14, name: "BMP" },
  { id: 15, name: "non-DRM AAC" },
  { id: 16, name: "MP3" },
  { id: 17, name: "MP4" },
  { id: 18, name: "MIDI" },
  { id: 19, name: "OGG" },
  { id: 20, name: "WAV" },
  { id: 21, name: "VP8" },
  { id: 22, name: "Other" },
]
