import React, { useState } from "react"
import { useAlert } from "../../../../../../../../hooks/useAlert"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import {
  clearScheduleUnitsList,
  createScheduleUnit,
  deleteScheduleUnit,
  getScheduleUnitsList,
  updateScheduleUnit,
} from "../../../../../../../../library/store/actions/creators/calendarCreators"
import ListPage from "../../../../../../../../components/v2/layout/listPage/listPage"
import UnitsListItem from "../../groupSettingsCalendarSubjects/subpages/groupSettingsCalendarUnits/components/unitsListItem"
import GroupSettingsCalendarProvider from "../../GroupSettingsCalendarContext"
import UnitFormModal from "../../groupSettingsCalendarSubjects/subpages/groupSettingsCalendarUnits/components/unitFormModal"
import KnowmixListForUnit from "../../groupSettingsCalendarSubjects/subpages/groupSettingsCalendarUnits/components/unitDialog/knowmixListForUnit"
import { useUnit } from "../../groupSettingsCalendarSubjects/subpages/groupSettingsCalendarUnits/useUnit"

const PacingCalendarUnits = () => {
  const { errorAlert } = useAlert()
  const dispatch = useDispatch()
  const params = useParams()

  const { unitsList, unitsListEndReached } = useSelector(
    ({ calendar }) => calendar
  )
  const isDistrict = useSelector(
    ({ groups }) => groups.currentGroup?.type === 5
  )

  const onCreateUnit = async (data) => {
    try {
      await dispatch(createScheduleUnit(data))
    } catch (e) {
      errorAlert(e)
    }
  }

  const onEditUnit = async (data) => {
    try {
      await dispatch(updateScheduleUnit(data))
    } catch (e) {
      errorAlert(e)
    }
  }

  const onDeleteUnit = async (id) => {
    try {
      await dispatch(deleteScheduleUnit({ id }))
    } catch (e) {
      errorAlert(e)
    }
  }

  const [knowmixListOpen, setKnowmixListOpen] = useState(false)
  const [activeUnit, setActiveUnit] = useState(null)

  const { getUnit } = useUnit()

  const onKnowmixPickerOpen = async (unitId) => {
    const unit = await getUnit(unitId)
    setActiveUnit(unit)
    setKnowmixListOpen(true)
  }

  const onKnowmixPickerClose = () => {
    setKnowmixListOpen(false)
    setActiveUnit(null)
  }

  return (
    <>
      <ListPage
        title={"Units"}
        creationButtonTitle={"Create unit"}
        hasButton={isDistrict}
        noResultsLabel={"This Calendar doesn't have units yet"}
        getList={async ({ name, offset }) =>
          await dispatch(
            getScheduleUnitsList({
              subject_id: parseInt(params.subjectId, 10),
              name,
              offset,
            })
          )
        }
        clearList={() => dispatch(clearScheduleUnitsList())}
        list={unitsList}
        listEndReached={unitsListEndReached}
        getListItemComponent={({
          item,
          openEditDialog,
          openDeletionDialog,
        }) => (
          <GroupSettingsCalendarProvider key={item.id}>
            {({ onUnitDialogOpen }) => (
              <UnitsListItem
                unit={item}
                openDeleteUnit={openDeletionDialog}
                openEditUnit={openEditDialog}
                openUnitDetails={onUnitDialogOpen}
                // isStatic={this.props.groups.currentGroup.type === 4}
                onKnowmixPickerOpen={() => onKnowmixPickerOpen(item.id)}
              />
            )}
          </GroupSettingsCalendarProvider>
        )}
        getFormDialogComponent={({ open, onClose, activeItem }) => {
          return (
            <UnitFormModal
              open={open}
              handleClose={onClose}
              onSubmit={!activeItem ? onCreateUnit : onEditUnit}
              activeUnit={activeItem}
              edit={!!activeItem}
            />
          )
        }}
        getDeletionDialogComponent={{
          entityName: "unit",
          onDelete: onDeleteUnit,
        }}
      />
      {knowmixListOpen && !!activeUnit && (
        <KnowmixListForUnit
          open={knowmixListOpen}
          handleClose={onKnowmixPickerClose}
          unit={activeUnit}
        />
      )}
    </>
  )
}

export default PacingCalendarUnits
