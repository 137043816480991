import React, { Component } from "react"
import Loader from "../../../components/ui/loader"
import * as routes from "../../../library/constants/routes"
import { generatePath, Redirect } from "react-router-dom"
import {
  confirmSignup,
  denySignup,
  getUserFromMeetLink,
  resetPasswordDeny,
  saveAzureAuthCode,
  saveGoogleAuthCode,
  signOut,
  updateEmailConfirm,
  updateEmailDeny,
} from "../../../library/store/actions/creators/authCreators"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withSnackbar } from "notistack"
import * as qs from "query-string"
import getErrorText from "../../../library/constants/errorTypes"

class BlankPage extends Component {
  state = {
    isLoading: true,
    redirectTo: "",
  }

  async componentDidMount() {
    const { path } = this.props.match
    const { id, code } = qs.parse(this.props.location.search)
    const { userId, requestId } = this.props.match.params

    console.log(path)

    try {
      switch (path) {
        case routes.CONFIRM_SIGNUP:
          await this.props.confirmSignup(id)
          this.setState({
            redirectTo: routes.BASE,
            isLoading: false,
          })
          break

        case routes.DENY_SIGNUP:
          await this.props.denySignup(id)
          await this.props.signOut()
          this.setState({
            redirectTo: routes.BASE,
            isLoading: false,
          })
          break

        case routes.DENY_PASSWORD_RESET:
          await this.props.resetPasswordDeny(id)
          this.setState({
            redirectTo: routes.SIGN_IN,
            isLoading: false,
          })
          break

        case routes.CONFIRM_EMAIL_CHANGE:
          await this.props.updateEmailConfirm(id)
          this.setState({
            redirectTo: routes.PROFILE_SETTINGS,
            isLoading: false,
          })
          break

        case routes.DENY_EMAIL_CHANGE:
          await this.props.updateEmailDeny(id)
          this.setState({
            redirectTo: routes.PROFILE_SETTINGS,
            isLoading: false,
          })
          break

        case routes.AZURE_AUTH_SUCCESS:
          if (code) {
            await this.props.saveAzureAuthCode({ code })
            this.setState({
              redirectTo: routes.PROFILE_SETTINGS_INTEGRATIONS,
              isLoading: false,
            })
          }
          break

        case routes.GOOGLE_AUTH_SUCCESS:
          if (code) {
            await this.props.saveGoogleAuthCode({ code })
            this.setState({
              redirectTo: routes.PROFILE_SETTINGS_INTEGRATIONS,
              isLoading: false,
            })
          }
          break

        case routes.BOOK_MEET:
          if (userId) {
            if (this.props.auth.isLoggedIn) {
              this.setState({
                redirectTo: generatePath(routes.BOOK_MEET_AUTHORIZED, {
                  userId,
                }),
                isLoading: false,
              })
            } else {
              this.setState({
                redirectTo: generatePath(routes.BOOK_MEET_UNAUTHORIZED, {
                  userId,
                }),
                isLoading: false,
              })
            }
          } else {
            this.setState({
              redirectTo: "/",
              isLoading: false,
            })
          }
          break

        case routes.INCOMING_SOLICIT_APPOINTMENT:
          console.log("HELLO")
          if (requestId) {
            if (this.props.auth.isLoggedIn) {
              console.log("logged in", "request id ", requestId)
              return this.setState({
                redirectTo: generatePath(
                  routes.INCOMING_SOLICIT_APPOINTMENT_AUTHORIZED,
                  {
                    requestId,
                  }
                ),
                isLoading: false,
              })
            } else {
              console.log("not logged in", "request id ", requestId)
              return this.setState({
                redirectTo: generatePath(
                  routes.INCOMING_SOLICIT_APPOINTMENT_UNAUTHORIZED,
                  {
                    requestId,
                  }
                ),
                isLoading: false,
              })
            }
          } else {
            console.log("no request id")
            return this.setState({
              redirectTo: "/",
              isLoading: false,
            })
          }

        default:
          this.setState({
            redirectTo: routes.BASE,
            isLoading: false,
          })
          break
      }
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  render() {
    const { isLoading, redirectTo } = this.state

    return isLoading ? (
      <div className="pt50">
        <Loader />
      </div>
    ) : (
      <Redirect to={redirectTo} />
    )
  }
}

const mapStateToProps = ({ auth }) => ({ auth })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      confirmSignup,
      denySignup,
      resetPasswordDeny,
      updateEmailConfirm,
      updateEmailDeny,
      signOut,
      saveAzureAuthCode,
      saveGoogleAuthCode,
      getUserFromMeetLink,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(BlankPage))
