import React from "react"
import "./gridListItem.scss"

const GridListItem = (props) => {
  return (
    <div className={`grid-list-item ${props.classes}`}>{props.children}</div>
  )
}

export default GridListItem;
