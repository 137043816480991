import React, { Component } from "react"
import "./moreDropdown.scss"
import MoreHorizOutlined from "@material-ui/icons/MoreHorizOutlined"
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener"

class MoreDropdown extends Component {
  state = {
    active: false,
  }

  toggleDropdown = () => {
    const active = this.state.active
    this.setState({ active: !active })
  }

  handleClickOutside = () => {
    this.setState({ active: false })
  }

  render() {
    const { active } = this.state
    const dropdownDisplay = active ? "more-dropdown-menu--active" : ""

    return (
      <ClickAwayListener onClickAway={this.handleClickOutside}>
        <div className={`more-dropdown-menu ${dropdownDisplay}`}>
          <button
            className="more-dropdown-menu__trigger"
            onClick={this.toggleDropdown}
          >
            <MoreHorizOutlined
              style={{ background: "rgba(255, 255, 255, .4)" }}
            />
          </button>
          {this.props.children}
        </div>
      </ClickAwayListener>
    )
  }
}

export default MoreDropdown;
