import React, { useEffect } from "react"
import useEntityPicker from "../../../../hooks/useEntityPicker"
import { SCHEDULE } from "../../../../library/store/actions/types/calendarTypes"
import PickerSelect from "../select/pickerSelect"

const SchoolCalendarPicker = ({
  value = "",
  onChange,
  groupId,
  hasError = false,
  errorMessage = "Field is required",
  required = true,
  disabled = false,
  emptyListMessage,
  emptyListLink,
}) => {
  const { fetchList, list, dataReceived, isWaitingForOtherFields } =
    useEntityPicker({
      fetchConfig: {
        apiUrl: SCHEDULE.SCHEDULES.LIST,
        params: {
          school_id: groupId,
          offset: 0,
          limit: 100,
        },
      },
      key: "schedules",
      loadOnMount: !!groupId,
    })

  useEffect(() => {
    if (groupId) {
      fetchList()
    }
  }, [groupId])

  return (
    <PickerSelect
      name={"schedule"}
      entityName={"schedule"}
      value={value}
      onChange={onChange}
      required={required}
      hasError={hasError}
      errorMessage={errorMessage}
      disabled={disabled || !dataReceived}
      list={list}
      isWaitingForOtherFields={isWaitingForOtherFields}
      isWaitingForOtherFieldsMessage={"First select school"}
      emptyListMessage={emptyListMessage}
      emptyListLink={emptyListLink}
    />
  )
}

export default SchoolCalendarPicker
