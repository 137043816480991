import { format, isSameDay, isSameYear, isToday, isYesterday } from "date-fns"
import defaultDate, {
  defaultMaxDate,
  defaultNumberDate,
  defaultNumberMaxDate,
} from "../constants/defaultDates"
import { formatInTimeZone, toDate } from "date-fns-tz"

export const getUtcFromZonedDateTime = (isoDateString, newTime, timezone) => {
  let date = new Date(isoDateString)

  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()

  let [hours, minutes] = newTime.split(":").map(Number)

  let isoString = `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}T${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:00`

  return toDate(isoString, { timeZone: timezone })
}

const dateConvert = (previous, current) => {
  const currentDate = new Date(current),
    previousDate = new Date(previous)

  if (isSameDay(currentDate, previousDate)) return

  if (isToday(currentDate)) return "today"

  if (isYesterday(currentDate)) return "yesterday"

  if (
    !isSameYear(previousDate, currentDate) &&
    !isSameYear(new Date(), currentDate)
  ) {
    return format(currentDate, "d MMM yyy")
  }

  if (!isSameYear(new Date(), currentDate)) {
    return format(currentDate, "d MMM yyy")
  }

  return format(currentDate, "d MMM")
}

export const formatDate = (date, withTime = false) => {
  if (!date) return "Invalid date"

  if (date === defaultDate) return ""

  const template = withTime ? "d MMM 'at' h:mm aaa" : "d MMM y"

  try {
    return format(new Date(date), template)
  } catch {
    return "Invalid date"
  }
}

export const safeFormatDate = (
  date,
  { withTime = false, placeholder = "—" } = {}
) => {
  if ([defaultDate, defaultMaxDate].includes(date)) return placeholder

  return formatDate(date, withTime)
}

export const hasSetDates = (dates = []) => {
  return dates.some(
    (date) => !!date && ![defaultDate, defaultMaxDate].includes(date)
  )
}

export const isDefaultDate = (date = "") => {
  return [
    defaultDate,
    defaultMaxDate,
    defaultNumberDate,
    defaultNumberMaxDate,
  ].includes(date)
}

export const prepareDatePickerValue = (date, type = "from") => {
  if (!date) return null

  if (type === "to") {
    return date === defaultMaxDate ? null : date
  }

  return date === defaultDate ? null : date
}

export default dateConvert

export const localizedDateTime = (date, time, initialTz, resultingTz) => {
  const dateTimeStr = getUtcFromZonedDateTime(date, time, initialTz)

  return formatInTimeZone(dateTimeStr, resultingTz, "h:mm aaa")
}
