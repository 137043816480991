import { PRODUCTS } from "../actions/types/productsTypes"
import { LIMIT } from "../../constants/limits"

const initialState = {
  list: [],
  search: null,
  library: null,
  currentProduct: null,
  reviews: null,
  acquisitions: null,
  acquisitionsSearch: null,
  isComplete: false,
  demosList: [],
  demosListEndReached: false,
}

export const productsReducer = (state = initialState, action) => {
  switch (action.type) {
  case PRODUCTS.LIST:
    return {
      ...state,
      list: state.list.length
        ? [...state.list, ...action.payload.products]
        : action.payload.products,
      isComplete: !action.payload.products.length,
    }

  case PRODUCTS.SEARCH:
    return {
      ...state,
      search: state.search
        ? [...state.search, ...action.payload.products]
        : action.payload.products,
      isComplete: !action.payload.products.length,
    }

  case PRODUCTS.CLEAR_SEARCH:
    return {
      ...state,
      search: null,
    }

  case PRODUCTS.GET:
    if (action.payload.type === 'init') {
      return {
        ...state,
        currentProduct: action.payload.product,
        reviews: action.payload.product.reviews,
      }
    } else {
      return {
        ...state,
        currentProduct: action.payload.product,
      }
    }

  case PRODUCTS.LIBRARY.LIST:
    return {
      ...state,
      library: state.library
        ? [...state.library, ...action.payload.products]
        : action.payload.products,
      isComplete: !action.payload.products.length,
    }

  case PRODUCTS.ACQUISITIONS.SEARCH:
    return {
      ...state,
      acquisitionsSearch: state.acquisitionsSearch
        ? [...state.acquisitionsSearch, ...action.payload.users]
        : action.payload.users,
    }

  case PRODUCTS.ACQUISITIONS.CLEAR:
    return {
      ...state,
      acquisitionsSearch: null,
    }

  case PRODUCTS.REVIEWS.LIST:
    return {
      ...state,
      reviews: action.payload.reviews,
    }
  case PRODUCTS.CLEAR:
    return {
      ...initialState,
    }

  case PRODUCTS.DEMO_REQUESTS_LIST:
    return {
      ...state,
      demosList: !action.payload.offset
        ? action.payload.list
        : [...state.demosList, ...action.payload.list],
      demosListEndReached:
        !action.payload.list.length || action.payload.list.length < LIMIT,
    }

  case PRODUCTS.DEMO_REQUESTS_LIST_CLEAR:
    return {
      ...state,
      demosList: [],
      demosListEndReached: false,
    }

  case PRODUCTS.REQUEST_DEMO:
    return {
      ...state,
      demosList: state.demosList.filter(d => d.id !== action.payload.id)
    }

  default:
    return state
  }
}
