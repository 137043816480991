import React, { useEffect, useState } from "react"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import Loader from "../../../../../../../../../../../../../../components/ui/loader"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import Dialog from "@material-ui/core/Dialog/Dialog"
import { useDispatch } from "react-redux"
import { weekDays } from "../../../../../../../../../../../../../../library/utils/timeConvert"
import { useAlert } from "../../../../../../../../../../../../../../hooks/useAlert"
import { call } from "../../../../../../../../../../../../../../library/networking/API"
import { SEGMENTS } from "../../../../../../../../../../../../../../library/store/actions/types/groupsTypes"
import { getLocalePeriod } from "../../../../../../../../../../../../../../library/utils/timeString/timeString"

const BlockDialog = ({ open, onClose, activeBlock }) => {
  const dispatch = useDispatch()
  const { errorAlert } = useAlert()

  const [data, setData] = useState(null)
  const [dataReceived, setDataReceived] = useState(false)

  const getBlock = async () => {
    try {
      setDataReceived(false)
      const response = await call(SEGMENTS.SECTIONS.BLOCKS.GET, {
        id: activeBlock,
      })

      const data = await response.data
      setData(data)
      setDataReceived(true)
    } catch (e) {
      errorAlert(e)
    }
  }

  useEffect(async () => {
    getBlock()
  }, [])

  return (
    <Dialog
      aria-labelledby="calendar-block-dialog"
      onClose={onClose}
      open={open}
    >
      <DialogTitle id="alert-dialog-title" className="text-center">
        Block details
      </DialogTitle>
      <DialogContent>
        {dataReceived ? (
          <div className="f jcc full-width">
            <table className="product-details product-details--invenstory-record">
              <tbody>
                <tr className="product-details__row">
                  <td className="product-details__label">Name</td>
                  <td className="product-details__value">{data.name}</td>
                </tr>

                {!!data.rotations.length && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Rotations</td>
                    <td className="product-details__value">
                      {data.rotations.map((d) => weekDays[d]).join(", ")}
                    </td>
                  </tr>
                )}

                <tr className="product-details__row">
                  <td className="product-details__label">Time</td>
                  <td className="product-details__value">
                    {!data.is_all_day
                      ? getLocalePeriod(data.time_from, data.time_to)
                      : "All day"}
                  </td>
                </tr>

                {!!data.tracks.length && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Tracks</td>
                    <td className="product-details__value">
                      {data.tracks.map((t) => t.name).join(", ")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <Loader />
        )}
      </DialogContent>
      <DialogActions>
        <Button type="submit" color="primary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default BlockDialog
