import React, { useEffect, useState } from "react"
import Dialog from "@material-ui/core/Dialog/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import FormControl from "@material-ui/core/FormControl/FormControl"
import Checkbox from "@material-ui/core/Checkbox/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import Button from "@material-ui/core/Button/Button"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import { ValidatorForm } from "react-material-ui-form-validator"

const TableColumnsSettings = (props) => {
  const [selectedValues, setSelectedValues] = useState([])

  useEffect(() => {
    setInitialState()
  }, [props.selectedColumns])

  const handleCheckboxOption = (id) => (event) => {
    const updateValues = selectedValues.map((item) => {
      return item.id === id ? { ...item, selected: event.target.checked } : item
    })
    setSelectedValues(updateValues)
  }

  const setInitialState = () => {
    setSelectedValues(
      props.columns.map((c) => ({
        id: c.id,
        name: c.name,
        selected: props.selectedColumns.includes(c.id),
        required: c.required || false,
      }))
    )
  }

  const handleClose = () => {
    setInitialState()
    props.handleClose()
  }

  const applySettings = async () => {
    const selectedIds = selectedValues
      .filter((item) => item.selected)
      .map((item) => item.id)
    await props.setColumns(selectedIds)
    handleClose()
  }

  return (
    <Dialog
      aria-labelledby="table-columns-settings"
      onClose={handleClose}
      open={props.open}
    >
      <ValidatorForm onSubmit={applySettings} className="question-form">
        <DialogTitle className="text-center">Customize columns</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            {selectedValues.map(
              (item, index) =>
                !item.required && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(item.selected)}
                        onChange={handleCheckboxOption(item.id)}
                      />
                    }
                    label={item.name}
                    key={item.id}
                  />
                )
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  )
}

export default TableColumnsSettings;
