import { POSTS } from "../actions/types/postsTypes"

const initialState = {
  myPostsLoaded: false,
  myPostsList: [],
  feedList: [],
  isComplete: false,
  postsList: [],
  currentPost: null,
  deletedComments: {},
}

export const postsReducer = (state = initialState, action) => {
    switch (action.type) {
        case POSTS.MY_LIST:
            return {
                ...state,
                myPostsList: [...state.myPostsList, ...action.payload],
                myPostsLoaded: true,
                isComplete: !action.payload.length,
            };

        case POSTS.MY_CLEAR:
            return {
                ...initialState,
                // ...state,
                // myPostsList: [],
                // isComplete: false,
                // myPostsLoaded: false,
            };

        case POSTS.LIST:
            return {
                ...state,
                postsList: [...state.postsList, ...action.payload],
                isComplete: !action.payload.length,
            };

        case POSTS.LIST_CLEAR:
            return {
                ...initialState,
                // ...state,
                // postsList: [],
                // isComplete: false
            };
        case POSTS.FEED:
            return {
                ...state,
                feedList: [...state.feedList, ...action.payload],
            };

        case POSTS.CREATE:
            return {
                ...state,
            };
        case POSTS.LIST_UPDATE:
            return {
                ...state,
                postsList: [...action.payload],
            };
        case POSTS.MY_LIST_UPDATE:
            return {
                ...state,
                myPostsList: [...action.payload],
            };
        case POSTS.FEED_UPDATE:
            return {
                ...state,
                feedList: [...action.payload],
            };
        case POSTS.DELETE:
            return {
                ...state,
            };

        case POSTS.RESTORE:
            return {
                ...state,
            };

        case POSTS.GET:
            return {
                ...state,
                currentPost: action.payload,
            };

        case POSTS.CLEAR:
            return {
                ...state,
                currentPost: null,
            };

        case POSTS.PIN:
            return {
                ...state,
            };

        case POSTS.UNPIN:
            return {
                ...state,
            };

        case POSTS.SHARE:
            return {
                ...state,
            };

        case POSTS.LIKES.CREATE:
            if (state.currentPost) {
                let currentPost = state.currentPost;
                currentPost.is_liked = true;
                currentPost.likes_number = currentPost.likes_number + 1;
                return {
                    ...state,
                    currentPost,
                };
            } else {
                return {
                    ...state,
                };
            }

        case POSTS.LIKES.DELETE:
            if (state.currentPost) {
                let currentPost = state.currentPost;
                currentPost.is_liked = false;
                currentPost.likes_number = currentPost.likes_number - 1;
                return {
                    ...state,
                    currentPost,
                };
            } else {
                return {
                    ...state,
                };
            }

        case POSTS.COMMENTS.CREATE:
            return {
                ...state,
                myPostsList: [...action.payload.myPostsList],
                postsList: [...action.payload.postsList],
                feedList: [...action.payload.feedList],

            };

        case POSTS.COMMENTS.UPDATE:
            return {
                ...state,
            };

        case POSTS.COMMENTS.DELETE:
            return {
                ...state,
                deletedComments: {...state.deletedComments, [action.payload]: {deleted: true}},
            };

        case POSTS.COMMENTS.RESTORE:
            let deletedComments = {...state.deletedComments};
            delete deletedComments[action.payload];
            return {
                ...state,
                deletedComments,
            };

        default:
            return state;
    }
};
