import { INVENSTORY } from "../actions/types/invenstoryTypes"
import { defaultActiveColumns } from "../../constants/invenstory"

const initialState = {
  connectionsList: [],
  recordsList: [],
  activeTableColumns: [],
  currentRecord: null,
  recordsCount: 0,
  view: "table",
}

export const invenstoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case INVENSTORY.CONNECTIONS.LIST:
            return {
                ...state,
                connectionsList: [...state.connectionsList, ...action.payload.connections],
            };

        case INVENSTORY.CONNECTIONS.LIST_UPDATE:
            return {
                ...state,
                connectionsList: action.payload.connections,
            };

        case INVENSTORY.CLEAR:
            return {
                ...state,
                connectionsList: [],
            };

        case INVENSTORY.RECORDS.CREATE:
            return {
                ...state,
            };

        case INVENSTORY.RECORDS.UPDATE:
            return {
                ...state,
            };

        case INVENSTORY.RECORDS.DELETE:
            return {
                ...state,
            };

        case INVENSTORY.RECORDS.GET:
            return {
                ...state,
                currentRecord: action.payload,
            };

        case INVENSTORY.RECORDS.LIST:
            return {
                ...state,
                recordsList: state.view === "table" ? action.payload.records : [...state.recordsList, ...action.payload.records],
                activeTableColumns: action.payload.invenstory_preferences.length ? action.payload.invenstory_preferences : defaultActiveColumns,
                recordsCount: action.payload.records_number,
            };

        case INVENSTORY.RECORDS.CLEAR:
            return {
                ...state,
                currentRecord: null,
            };

        case INVENSTORY.RECORDS.CLEAR_LIST:
            return {
                ...state,
                recordsList: [],
            };

        case INVENSTORY.SWITCH_VIEW:
            return {
                ...state,
                view: action.payload,
            };

        default:
            return {
                ...state,
            };
    }
};
