import React, { Component } from "react"
import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../../../library/constants/routes"
import logoDefault from "../../../../../assets/img/product.svg"
import { getObjectById } from "../../../../../library/utils/arrays"
import { productContentTypes } from "../../../../../library/constants/productDictionary"
import { withSnackbar } from "notistack"
import BookmarkedItemDropdown from "../bookmarkedItemDropdown/bookmarkedItemDropdown"
import styled from "@emotion/styled"

class BookmarkedRecord extends Component {
  state = {
    deleted: false,
  }

  deleteBookmark = () => {
    const { record } = this.props
    this.props
      .deleteBookmark({ record_id: record.id })
      .then(this.setState({ deleted: true }))
  }

  render() {
    const { record, user, group } = this.props
    const { deleted } = this.state

    if (!record || deleted) return null

    const creator = record.creator_user
      ? {
          name: `${record.creator_user.first_name} ${record.creator_user.last_name}`,
          url: generatePath(routes.USER, { userId: record.creator_user.id }),
        }
      : record.creator_group
      ? {
          name: record.creator_group.name,
          url: generatePath(routes.GROUP, { groupId: record.creator_group.id }),
        }
      : user
      ? {
          name: `${user.first_name} ${user.last_name}`,
          url: generatePath(routes.USER, { userId: user.id }),
        }
      : {
          name: group.name,
          url: generatePath(routes.GROUP, { groupId: group.id }),
        }

    return (
      <div className="product-tile">
        <div className="product-tile__menu">
          <BookmarkedItemDropdown
            deleteBookmark={this.deleteBookmark}
            bookmarkFoldersList={() =>
              this.props.bookmarkFoldersList(record.bookmark.id)
            }
            addToFolder={(folders) =>
              this.props.addToFolder({
                bookmark_id: record.bookmark.id,
                folders,
              })
            }
            bookmarksFoldersListClear={() =>
              this.props.bookmarksFoldersListClear()
            }
          />
        </div>
        <Link
          to={generatePath(
            record.creator_user ? routes.INVENSTORY : routes.INVENSTORY_GROUPS
          )}
          className="product-tile__logo"
        >
          <Logo src={record.image || logoDefault} alt="Product image" />
        </Link>
        <Link
          to={generatePath(
            record.creator_user ? routes.INVENSTORY : routes.INVENSTORY_GROUPS
          )}
          className="product-tile__name link"
        >
          {record.name}
        </Link>
        <table className="product-tile__info">
          <tbody>
            <tr>
              <td
                className={`product-tile__info-label ${
                  getObjectById(productContentTypes, record.content_type)
                    ? "pb4"
                    : ""
                }`}
              >
                <span>Creator:</span>
              </td>
              <td
                className={`product-tile__info-value ${
                  getObjectById(productContentTypes, record.content_type)
                    ? "pb4"
                    : ""
                }`}
              >
                <span>
                  <Link to={creator.url} className="link font-weight-500">
                    {creator.name}
                  </Link>
                </span>
              </td>
            </tr>
            <tr>
              <td className="product-tile__info-label">
                <span>
                  {getObjectById(productContentTypes, record.content_type)
                    ? "Content type:"
                    : ""}
                  {!getObjectById(productContentTypes, record.content_type) && (
                    <span style={{ height: "20px" }}></span>
                  )}
                </span>
              </td>
              <td className="product-tile__info-value">
                <span>
                  {getObjectById(productContentTypes, record.content_type)
                    ? getObjectById(productContentTypes, record.content_type)
                        .name
                    : ""}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

const Logo = styled.img`
    object-fit: cover;
    object-position: center;
    width: 135px;
    height: 135px;
`;

export default withSnackbar(BookmarkedRecord);
