import React from "react"
import { useQuery } from "@tanstack/react-query"
import { CALENDARS } from "../../../../../library/store/actions/types/calendarTypes"
import { call } from "../../../../../library/networking/API"
import { addDays, format, isSameDay } from "date-fns"
import { composeEventsList } from "../../../../../library/store/reducers/calendarReducer"
import { groupBy } from "lodash"
import { colors } from "../../../../../library/constants/styles/colors"
import { useCalendar } from "../../../calendar/CalendarContext"
import { PlainButton } from "../../../../../components/v2/controls"
import { KeyboardArrowRight } from "@material-ui/icons"
import Loader from "../../../../../components/ui/loader"
import { useUserCalendar } from "../../../calendar/UserCalendarProvider"
import DropdownControl from "../../../../../components/ui/dropdowns/dropdownControl"
import { useSelector } from "react-redux"
import * as routes from "../../../../../library/constants/routes"
import { Link } from "react-router-dom"
import Button from "@material-ui/core/Button"
import ConnectIcon from "@material-ui/icons/SettingsInputComponent"
import { useCurrentUser } from "../../../../../hooks/data/user/useUser"

const ProfileUpcomingAppointments = () => {
  const { user } = useCurrentUser()
  const { data, isSuccess } = useQuery({
    queryKey: [CALENDARS.EVENTS.LIST],
    queryFn: async () => {
      const response = await call(CALENDARS.EVENTS.LIST, {
        date_from: new Date().toISOString(),
        date_to: addDays(new Date(), 7),
      })

      const json = await response.data

      const eventsList = groupBy(
        composeEventsList(json).sort(
          (a, b) => new Date(a.date_from) - new Date(b.date_from)
        ),
        (v) => {
          const date = new Date(v.date_from)
          const year = date.getFullYear()
          const month = date.getMonth() + 1
          const day = date.getDate()

          return `${year}-${month}-${day}`
        }
      )

      return eventsList || []
    },
    refetchOnWindowFocus: false,
    refetchInterval: 5 * 60 * 1000,
  })

  const {
    onEventDialogOpen,
    onEventFormModalOpen,
    onSolicitAppointmentFormModalOpen,
    onMeetFormModalOpen,
  } = useCalendar()
  const { onCalendarOpen } = useUserCalendar()

  return (
    <div className={""}>
      <div className="box boxed-list">
        <div className={"p-4 flex flex-col items-stretch gap-2"}>
          {(!user.has_google_integration || !user.has_outlook_integration) && (
            <Button
              variant={"outlined"}
              color={"primary"}
              component={Link}
              to={routes.PROFILE_SETTINGS_INTEGRATIONS}
              fullWidth
              className={"mt-[15px]"}
              size={"small"}
              startIcon={<ConnectIcon />}
            >
              Integrate calendars
            </Button>
          )}

          <DropdownControl
            buttonVariant={"contained"}
            buttonLabel={"Calendar Connect"}
            popperZIndex={102}
            buttonSize={"small"}
            options={[
              {
                label: "Dated Appointment",
                onClick: onEventFormModalOpen,
              },
              {
                label: "Request Undated Appointment",
                onClick: onSolicitAppointmentFormModalOpen,
              },
              user.role !== 1 && {
                label: "AI Cohorting Meet",
                onClick: onMeetFormModalOpen,
              },
            ].filter(Boolean)}
          />
        </div>

        <hr className={"border-divider"} />

        <div className="boxed-list__header">Upcoming appointments</div>
        <div className="boxed-list__list">
          {isSuccess ? (
            Object.keys(data).length ? (
              <div className={"max-h-64 overflow-y-auto relative"}>
                {Object.entries(data).map(([key, value], idx) => (
                  <div
                    key={key}
                    className={idx < Object.keys(data).length - 1 ? "mb-5" : ""}
                  >
                    <div
                      className={
                        "boxed-list-item font-weight-500 mb-1 z-1 bg-white sticky top-0"
                      }
                    >
                      {format(new Date(key), "EEEE, MMMM d, yyyy")}
                    </div>
                    <div>
                      {value.map((event, i) => {
                        const isOneDayEvent = isSameDay(
                          new Date(event.date_to),
                          new Date()
                        )
                        return (
                          <div
                            key={event.id}
                            className={i < value.length - 1 ? "mb-1" : ""}
                          >
                            <div className={"px-4 text-sm"}>
                              <div className="f aic">
                                <div
                                  style={{
                                    width: 8,
                                    height: 8,
                                    backgroundColor:
                                      event.color || colors.primary,
                                    marginRight: 5,
                                    borderRadius: "50%",
                                  }}
                                />
                                <PlainButton
                                  compact
                                  onClick={() =>
                                    onEventDialogOpen(
                                      event.id,
                                      event.entity_type
                                    )
                                  }
                                >
                                  {event.name || event.title}
                                </PlainButton>
                              </div>
                              {!event.is_all_day ? (
                                <p className={"ml-4 color-black-54 text-xs"}>
                                  {format(
                                    new Date(event.date_from),
                                    isOneDayEvent
                                      ? `h:mm aaa`
                                      : "eee, MMM d 'at' h:mm aaa"
                                  )}{" "}
                                  —{" "}
                                  {format(
                                    new Date(event.date_to),
                                    isOneDayEvent
                                      ? `h:mm aaa`
                                      : "eee, MMM d 'at' h:mm aaa"
                                  )}
                                </p>
                              ) : (
                                <p className={"ml-4 color-black-54"}>All day</p>
                              )}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <span className={"px-4 color-black-54"}>
                You are free for the next 7 days
              </span>
            )
          ) : (
            <Loader dense />
          )}
          <div className={"boxed-list__footer"}>
            <PlainButton onClick={onCalendarOpen}>
              Show all in calendar <KeyboardArrowRight />
            </PlainButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileUpcomingAppointments
