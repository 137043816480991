import SelectValue from "../../utils/forms/SelectValue"
import ErrorMessage from "../../utils/forms/errorMessage"
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core"
import EmptyListError from "../../utils/forms/emptyListError"
import { colors } from "../../../../library/constants/styles/colors"
import { capitalize } from "lodash"

const PickerSelect = ({
  name,
  entityName,
  hasError = false,
  errorMessage = "Field is required",
  list = [],
  value = "",
  required = false,
  disabled = false,
  onChange,
  dataReceived = true,
  multiple = false,
  isWaitingForOtherFields = false,
  isWaitingForOtherFieldsMessage = "",
  emptyListMessage,
  emptyListLink,
  getValueName,
  getDisabledValue,
  disabledValueMessage,
  getItemPhoto,
  leftLabel = true,
  getValueHelperText,
  getOptionHelperText,
}) => {
  const fieldBox = (
    <div className="form__fieldbox">
      <div className="form__input form__input--select">
        {dataReceived && !list.length && !!emptyListMessage ? (
          <EmptyListError message={emptyListMessage} link={emptyListLink} />
        ) : (
          <FormControl error={hasError} fullWidth>
            {!leftLabel && (
              <InputLabel id={`${name}-label`}>
                {capitalize(entityName)}
              </InputLabel>
            )}
            <Select
              error={hasError}
              id={name}
              labelId={!leftLabel ? `${name}-label` : undefined}
              name={name}
              fullWidth
              displayEmpty={!!leftLabel}
              multiple={multiple}
              disabled={disabled}
              value={value}
              onChange={onChange}
              renderValue={(v) => (
                <SelectValue
                  entityName={entityName}
                  value={v}
                  isMulti={multiple}
                  getValueName={getValueName}
                  getValueHelperText={getValueHelperText}
                />
              )}
            >
              {!multiple && (
                <MenuItem disabled={required} value={""}>
                  Select {entityName}
                </MenuItem>
              )}
              {list.map((item) => {
                const itemName =
                  typeof getValueName === "function"
                    ? getValueName(item)
                    : item.name

                const isItemDisabled = getDisabledValue?.(item) || false
                const itemHelperText = getOptionHelperText?.(item) || ""

                return (
                  <MenuItem
                    value={item}
                    key={item.id}
                    disabled={isItemDisabled}
                  >
                    <div>
                      <div className={"f aic"}>
                        {!!getItemPhoto?.(item) && (
                          <img
                            src={getItemPhoto(item)}
                            style={{
                              backgroundColor: colors.black12,
                              borderRadius: "50%",
                              width: 30,
                              height: 30,
                              marginRight: 8,
                            }}
                            alt={""}
                          />
                        )}
                        <p>{itemName}</p>
                      </div>
                      {!!itemHelperText && (
                        <p className={"font-12 color-black-54"}>
                          {itemHelperText}
                        </p>
                      )}
                      {isItemDisabled && disabledValueMessage && (
                        <p className={"font-12 color-black-54 font-weight-500"}>
                          {disabledValueMessage}
                        </p>
                      )}
                    </div>
                  </MenuItem>
                )
              })}
            </Select>
            <ErrorMessage error={hasError} message={errorMessage} />
            {isWaitingForOtherFields && (
              <FormHelperText className={"color-black-38"}>
                {isWaitingForOtherFieldsMessage}
              </FormHelperText>
            )}
          </FormControl>
        )}
      </div>
    </div>
  )

  return leftLabel ? (
    <div className="form__fieldset">
      <label htmlFor={name} className={"form__label"}>
        {entityName} {required && "*"}
      </label>
      {fieldBox}
    </div>
  ) : (
    fieldBox
  )
}

export default PickerSelect
