import React from "react"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import Dialog from "@material-ui/core/Dialog/Dialog"
import getErrorText from "../../../../../library/constants/errorTypes"
import { bindActionCreators } from "redux"
import {
  deleteFolder,
  foldersList,
} from "../../../../../library/store/actions/creators/bookmarksCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { withRouter } from "react-router-dom"
import * as routes from "../../../../../library/constants/routes"

const DeleteFolder = ({ open, onClose, folder, ...rest }) => {
  const deleteFolder = async () => {
    try {
      await rest.deleteFolder({ id: folder.id })
      await rest.foldersList()
      await onClose()
      if (
        rest.match.params.folderId &&
        Number(rest.match.params.folderId) === folder.id
      ) {
        rest.history.push(routes.BOOKMARKS)
      }
      rest.enqueueSnackbar("Folder deleted", { variant: "success" })
    } catch ({ error }) {
      rest.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Delete folder from bookmarks?
      </DialogTitle>
      <DialogContent>
        <p className="color-black-54 font-16">
          Are you sure you want to delete {folder.name}? This folder will be
          removed from all of your bookmarks, but the bookmarks themselves will
          remain.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Decline
        </Button>
        <Button onClick={deleteFolder} color="primary" autoFocus>
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = ({auth, bookmarks}) => ({auth, bookmarks});
const mapDispatchToProps = dispatch => bindActionCreators({deleteFolder, foldersList}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withRouter(DeleteFolder)));
