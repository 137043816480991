import FormControl from "@material-ui/core/FormControl/FormControl"
import Select from "@material-ui/core/Select/Select"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import FormHelperText from "@material-ui/core/FormHelperText"
import React from "react"

const IntPicker = ({
  min = 1,
  max = 100,
  onChange,
  value = "",
  required = false,
  hasError = false,
  errorMessage = "",
  entityName = "",
  optionEntityName = "",
  inputName = "int_picker",
}) => {
  return (
    <div className="form__fieldset">
      <label className="form__label">
        {entityName} {required && "*"}
      </label>
      <div className="form__fieldbox">
        <div className="form__input form__input--select">
          <FormControl error={hasError} fullWidth>
            <Select
              id={inputName}
              name={inputName}
              fullWidth
              displayEmpty
              value={value}
              onChange={onChange}
            >
              {Array.from(Array(max)).map((option, index) => (
                <MenuItem key={index + 1} value={index + 1}>
                  {index + 1} {optionEntityName}
                </MenuItem>
              ))}
            </Select>
            {hasError && (
              <FormHelperText className="error-message">
                {errorMessage}
              </FormHelperText>
            )}
          </FormControl>
        </div>
      </div>
    </div>
  )
}

export default IntPicker
