import React from "react"
import "./searchResultsGlobal.scss"
import * as routes from "../../../../../library/constants/routes"
import HorizontalList from "../../../../../components/ui/horizontalList/horizontalList"
import { withRouter } from "react-router-dom"

const SearchResultsGlobal = ({ results, location }) => {
  const {
    users,
    groups,
    products,
    users_number,
    groups_number,
    products_number,
  } = results

  return (
    <div className="search-results-global">
      {users.length > 0 && (
        <HorizontalList
          entity="users"
          itemType="rounded"
          rounded
          list={users}
          title="People"
          link={routes.SEARCH_USERS + location.search}
          counter={users_number}
        />
      )}
      {groups.length > 0 && (
        <HorizontalList
          entity="groups"
          itemType="rounded"
          rounded
          list={groups}
          title="Groups"
          link={routes.SEARCH_GROUPS + location.search}
          counter={groups_number}
        />
      )}
      {/*{products.length > 0 && (*/}
      {/*  <HorizontalList*/}
      {/*    entity="products"*/}
      {/*    list={products}*/}
      {/*    title="Products"*/}
      {/*    link={routes.SEARCH_PRODUCTS + location.search}*/}
      {/*    counter={products_number}*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  )
}

export default withRouter(SearchResultsGlobal)
