import React from "react"
import styled from "@emotion/styled"
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress"

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ dense }) => (dense ? "0" : "15px")};
`

const Loader = ({ size, dense = false }) => {
  return (
    <Container dense={dense}>
      <CircularProgress size={size || 32} />
    </Container>
  )
}

export default Loader
