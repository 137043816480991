import React from "react"
import styled from "@emotion/styled"
import { colors } from "../../../library/constants/styles/colors"
import { devices } from "../../../library/constants/styles/responsive"
import appScreenshot from "../../../assets/img/app-screenshot.png"
import android from "../../../assets/img/pages/promote/android.png"
import ios from "../../../assets/img/pages/promote/ios.png"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  @media ${devices.xs} {
    flex-direction: column;
  }
`

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 610px;

  @media ${devices.xs} {
    width: 100%;
    margin-top: 50px;
  }
`

const RightContent = styled.div`
  flex: 1;
`

const Title = styled.h1`
  margin-bottom: 125px;
  font-size: 34px;
  font-weight: 500;
  color: ${colors.primary};
  text-transform: uppercase;
  line-height: normal;

  @media ${devices.xs} {
    width: 75%;
    margin-bottom: 25px;
    text-align: center;
    line-height: 1.4;
    font-size: 24px;
  }
`

const ButtonsContainer = styled.div`
  display: flex;

  @media ${devices.xs} {
    flex-direction: column;
    margin-bottom: 10px;
  }
`

const ImagesContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media ${devices.xs} {
    justify-content: center;
  }
`

const ImageBig = styled.img`
  transform: translate(calc(100% - 210px));

  @media ${devices.xs} {
    transform: none;
  }
`

const ImageSmall = styled.img`
  height: 335px;
  z-index: -1;

  @media ${devices.xs} {
    display: none;
  }
`

export const MobileMarketButtons = () => {
  const isMobile = useMediaQuery("(max-width:599px)")

  return (
    <ButtonsContainer>
      {window.navigator.userAgent.indexOf("Android") !== -1 && isMobile && (
        <a
          href={"https://play.google.com/store/apps/details?id=com.ks_app"}
          target={"_blank"}
          rel="noreferrer"
          className={"mb15"}
        >
          <img
            src={android}
            alt={"Google Play Market"}
            style={{ maxWidth: 215 }}
          />
        </a>
      )}

      {window.navigator.userAgent.indexOf("iPhone") !== -1 && isMobile && (
        <a
          href={"https://apps.apple.com/ua/app/knowstory/id1537940521?l=uk"}
          target={"_blank"}
          rel="noreferrer"
          className={"mb15"}
        >
          <img src={ios} alt={"Apple App Store"} style={{ maxWidth: 215 }} />
        </a>
      )}

      {!isMobile && (
        <>
          <a
            href={"https://play.google.com/store/apps/details?id=com.ks_app"}
            target={"_blank"}
            rel="noreferrer"
            className={"mr15notXs mb15xs"}
          >
            <img
              src={android}
              alt={"Google Play Market"}
              style={{ maxWidth: 215 }}
            />
          </a>
          <a
            href={"https://apps.apple.com/ua/app/knowstory/id1537940521"}
            target={"_blank"}
            rel="noreferrer"
          >
            <img src={ios} alt={"Apple App Store"} style={{ maxWidth: 215 }} />
          </a>
        </>
      )}
    </ButtonsContainer>
  )
}

const AppPromote = () => {
  return (
    <Container>
      <LeftContent>
        <Title>Download KnowStory App</Title>
        <MobileMarketButtons />
      </LeftContent>
      <RightContent>
        <ImagesContainer>
          <ImageBig src={appScreenshot} alt="App" />
          <ImageSmall src={appScreenshot} alt="App" />
        </ImagesContainer>
      </RightContent>
    </Container>
  )
}

export default AppPromote
