import { useIdParam } from "../../useIdParam"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { KNOWMIX } from "../../../library/store/actions/types/knowmixTypes"
import { call } from "../../../library/networking/API"

export const useCourse = (id = null, options = {}) => {
  const courseId = useIdParam("knowmixId")
  const __id = id || courseId

  const queryKey = [KNOWMIX.GET, { id: __id }]

  const { data: course, ...query } = useQuery(queryKey, {
    queryFn: async (ctx) => {
      const res = await call(queryKey[0], {
        id: __id,
      })

      return await res.data
    },
    staleTime: 60 * 1000,
    refetchOnWindowFocus: false,
    ...options,
  })

  return {
    course,
    canEdit:
      course?.is_admin ||
      course?.is_teacher ||
      course?.is_creator ||
      course?.is_product_admin,
    isAvailable: !course?.deleted && !course?.blocked,
    queryKey,
    ...query,
  }
}

export const useCourseMutations = ({ queryKey }) => {
  const queryClient = useQueryClient()

  const createCourse = useMutation({
    mutationFn: async ({ name, subject_id, group_id, schedule_id }) => {
      const resp = await call(KNOWMIX.CREATE, {
        name,
        subject_id,
        group_id,
        schedule_id,
      })
      const data = await resp.data
      return data.id
    },
  })

  const updateCourse = useMutation({
    mutationFn: async ({
      id,
      name,
      subject_id,
      grade,
      full_image,
      links,
      instructions,
      attachments,
      schedule_id,
      teacher_id,
      goal,
      measurement,
      learning_days_number,
      gradual_unlocking_steps,
    }) => {
      const resp = await call(KNOWMIX.UPDATE, {
        id,
        name,
        subject_id,
        grade,
        full_image,
        links,
        instructions,
        attachments,
        schedule_id,
        teacher_id,
        goal,
        measurement,
        learning_days_number,
        gradual_unlocking_steps,
      })

      return await resp.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey })
    },
  })

  const deleteCourse = useMutation({
    mutationFn: async ({ id }) => {
      const resp = await call(KNOWMIX.DELETE, {
        id,
      })
      const data = await resp.data
      return data.id
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey })
    },
  })

  const releaseCourse = useMutation({
    mutationFn: async ({ id }) => {
      const resp = await call(KNOWMIX.FINISH, {
        id,
      })
      return resp.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey })
    },
  })

  const startCourse = useMutation({
    mutationFn: async ({ id }) => {
      const res = await call(KNOWMIX.START, { id })
      return await res.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey })
    },
  })

  return {
    createCourse,
    updateCourse,
    deleteCourse,
    releaseCourse,
    startCourse,
  }
}
