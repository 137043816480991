import React from "react"
import {
  MAX_DATE,
  MIN_DATE,
} from "../../../../../../library/constants/defaultDates"
import * as Yup from "yup"
import { Formik } from "formik"
import Form from "../../../../../../components/v2/layout/forms/form/form"

const validationSchema = Yup.object({
  country: Yup.string().max(50),
  city: Yup.string().max(50),
  name: Yup.string().required().max(150),
  department: Yup.string().max(150),
  date_from: Yup.date().min(MIN_DATE).nullable(),
  date_to: Yup.date().max(MAX_DATE).nullable(),
})

const EducationForm = ({ education, onSubmit, onDelete }) => {
  const initialValues = {
    id: education.id || '',
    type: education.type,
    country: education.country || '',
    city: education.city || '',
    name: education.name || '',
    department: education.department || '',
    date_from:
      education.date_from === null ? null : new Date(education.date_from),
    date_to: education.date_to === null ? null : new Date(education.date_to),
  }

  const typesWithDepartments = [4, 5, 6]

  const departmentLabel = () => {
    switch (education.type) {
      case 4:
        return 'Major'

      case 5:
        return 'Training'

      case 6:
        return 'Certification'

      default:
        return ''
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(fk) => (
        <Form onDelete={onDelete}>
          <Form.Row name={'country'} label={'Country'}>
            <Form.TextField
              name={'country'}
              placeholder={'Type your country'}
            />
          </Form.Row>
          <Form.Row name={'city'} label={'City'}>
            <Form.TextField name={'city'} placeholder={'Type your city'} />
          </Form.Row>
          <Form.Row
            name={'name'}
            label={education.type === 4 ? 'College/University *' : 'School *'}
          >
            <Form.TextField
              name={'name'}
              placeholder={'Type your institution'}
            />
          </Form.Row>
          {typesWithDepartments.includes(education.type) && (
            <Form.Row name={'department'} label={departmentLabel()}>
              <Form.TextField
                name={'department'}
                placeholder={`Type your ${
                  education.type === 6 ? 'certification' : 'department'
                }`}
              />
            </Form.Row>
          )}
          <Form.Row name={'date_from'} label={'Start date'}>
            <Form.DatePicker
              name={'date_from'}
              placeholder={'Select start date'}
              maxDate={fk.values.date_to}
              views={['year', 'month']}
              format={'MMMM yyyy'}
            />
          </Form.Row>
          <Form.Row name={'date_to'} label={'Finish date'}>
            <Form.DatePicker
              name={'date_to'}
              placeholder={'Select finish date'}
              minDate={fk.values.date_from}
              views={['year', 'month']}
              format={'MMMM yyyy'}
            />
          </Form.Row>
        </Form>
      )}
    </Formik>
  )
}

export default EducationForm
