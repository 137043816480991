export const imageAspectRatios = {
  square: 1 / 1,
  page_banner: 4 / 1,
  user_page_header: 16 / 3,
  group_page_header: 14 / 3,
}

export const emptyImage = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  cropped_image: "",
  original_image: "",
}

export const imageLimitations = {
  [imageAspectRatios.square]: {
    size: {
      bytes: 512000,
      userReadable: "500KB",
    },
    dimensions: {
      width: 320,
      height: 320,
    },
  },
  [imageAspectRatios.page_banner]: {
    size: {
      bytes: 1048576,
      userReadable: "1MB",
    },
    dimensions: {
      width: 1368,
      height: 342,
    },
  },
}
