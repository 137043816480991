import React from "react"

import "./userProfile.scss"
import { generatePath, Route } from "react-router-dom"
import UserProfileHeader from "./userProfileHeader/userProfileHeader"
import PostModal from "../../feed/postModal/postModal"
import * as routes from "../../../../library/constants/routes"
import { BOOKMARKS_PEOPLE } from "../../../../library/constants/routes"
import NotFound from "../../../../components/notFound/notFound"
import UserError from "../userError/userError"
import BookMeetModal from "../../../bookMeet/bookMeetModal"
import BookMeetProvider from "../../../bookMeet/bookMeetContext"
import BoxedList from "../../../../components/ui/boxedList"
import ProfileUpcomingAppointments from "./userProfileCalendar/ProfileUpcomingAppointments"
import CalendarProvider from "../../calendar/CalendarContext"
import ProfilePageSkeleton from "../../../../components/v2/layout/skeleton/profilePage/profilePageSkeleton"
import { useUser } from "../../../../hooks/data/user/useUser"
import { useIdParam } from "../../../../hooks/useIdParam"
import {
  PageContentLayout,
  PageMain,
  PageRightSidebar,
} from "../../../../components/v2/layout/layout"
import UserProfileCreds from "./userProfileKnowcred"
import BookmarksProvider from "../../../../hooks/modals/useBookmarksModal"
import { mutationTypes } from "../../../../library/utils/reactQuery"
import ProfileHowToBanner from "./userProfileHeader/components/ProfileHowToBanner"
import ComplaintsProvider from "../../../../hooks/modals/useComplaintsModal"
import UserFeed from "./userProfileFeed/userFeed"
import SEO from "../../../../components/SEO"
import { getUserName } from "../../../../library/utils/user/userUtils"
import { useSelector } from "react-redux"
import ProfileSchedule from "./userProfileCalendar/ProfileSchedule"

const UserProfile = () => {
  const { isLoggedIn } = useSelector(({ auth }) => auth)
  const userId = useIdParam("userId")
  const { user, isCurrentUser, isSuccess, isLoading, isFetching, error, key } =
    useUser(userId)

  const [isContentVisible, setIsContentVisible] = React.useState(true)

  React.useEffect(() => {
    if (isSuccess && user.is_blocking) {
      setIsContentVisible(false)
    }
  }, [])

  if (error?.error?.code === 20100) return <NotFound entity="User" />

  if (!isSuccess && isLoading) return <ProfilePageSkeleton />

  if (user.blocked || user.deleted || user.is_blocked) {
    return <UserError user={user} />
  }

  return (
    <div className="grid auto-rows-max gap-4">
      <PageContentLayout>
        <SEO title={getUserName(user)} />
        <PageMain>
          <BookmarksProvider
            keyToUpdate={key}
            params={{ user_id: user.id }}
            pathToBookmarks={BOOKMARKS_PEOPLE}
            mutationType={mutationTypes.record}
          >
            <ComplaintsProvider
              entityUpdater={(u) => ({ ...u, is_reported: true })}
              keyToUpdate={key}
              params={{ user_id: user.id }}
            >
              <UserProfileHeader isContentVisible={isContentVisible} />
            </ComplaintsProvider>
          </BookmarksProvider>
          {isCurrentUser && <ProfileHowToBanner />}
          {isLoggedIn && <UserFeed />}
        </PageMain>
        <PageRightSidebar>
          {isCurrentUser && (
            <>
              <CalendarProvider contextType={"upcoming-appointments"}>
                <ProfileUpcomingAppointments />
              </CalendarProvider>
              {[1, 2].includes(user.role) && <ProfileSchedule />}
            </>
          )}
          {!!user.friends_number && (
            <BoxedList
              title="Friends"
              counter={user.friends_number}
              list={user.friends}
              entity="users"
              linkShowAll={generatePath(routes.FRIENDS, {
                userId: user.id,
              })}
            />
          )}
          <UserProfileCreds />
        </PageRightSidebar>
      </PageContentLayout>

      <Route
        path={routes.USER_POST}
        render={(props) => (
          <PostModal
            {...props}
            userInfo={user}
            type={isCurrentUser ? "my-profile" : "other-profile"}
          />
        )}
      />

      <Route
        path={routes.BOOK_MEET_AUTHORIZED}
        render={(props) => (
          <BookMeetProvider>
            <BookMeetModal {...props} />
          </BookMeetProvider>
        )}
      />
    </div>
  )
}

export default UserProfile

// class UserProfile extends Component {
//   state = {
//     type: "other-profile",
//     userInfo: undefined,
//     isPostModal: false,
//     dataReceived: false,
//     error404: false,
//     userBlocked: false,
//     userDeleted: false,
//     currentUserDeleted: false,
//     currentUserBlocked: false,
//     currentUserBlacklisted: false,
//     isContentVisible: true,
//     userBlacklisted: false,
//     showBlacklistModal: false,
//   }
//
//   async componentDidMount() {
//     const { userId } = this.props.match.params
//     const { userData } = this.props.auth
//     const id = parseInt(userId)
//
//     try {
//       if (userData.id !== id) {
//         await this.props.getPerson(userData.token, id)
//         this.setState({
//           type: "other-profile",
//           userInfo: this.props.person.personData,
//           userDeleted: this.props.person.personData.deleted,
//           userBlocked: this.props.person.personData.blocked,
//           userBlacklisted: this.props.person.personData.is_blocked,
//           isContentVisible: !this.props.person.personData.is_blocked,
//           currentUserBlacklisted: this.props.person.personData.is_blocking,
//           dataReceived: true,
//         })
//       } else {
//         await this.props.getData(userData.token, userData.id)
//         this.setState({
//           type: "my-profile",
//           userInfo: this.props.auth.userData,
//           currentUserBlocked: this.props.auth.userData.blocked,
//           userBlocked: this.props.auth.userData.deleted,
//           isContentVisible: true,
//           dataReceived: true,
//         })
//       }
//     } catch ({ error }) {
//       if (error.code === 20100) {
//         return this.setState({ error404: true })
//       }
//
//       if (error.code === 20005) {
//         return this.setState({ currentUserDeleted: true })
//       }
//
//       this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
//     }
//   }
//
//   async componentDidUpdate(prevProps) {
//     if (this.props.match.params.userId !== prevProps.match.params.userId) {
//       this.setState({ dataReceived: false })
//       const {
//         match: {
//           params: { userId },
//         },
//         auth: { userData },
//       } = this.props
//       const id = parseInt(userId)
//
//       try {
//         if (userData.id !== id) {
//           await this.props.getPerson(userData.token, id)
//           this.setState({
//             type: "other-profile",
//             userInfo: this.props.person.personData,
//             userDeleted: this.props.person.personData.deleted,
//             userBlocked: this.props.person.personData.blocked,
//             userBlacklisted: this.props.person.personData.is_blocked,
//             isContentVisible: !this.props.person.personData.is_blocked,
//             currentUserBlacklisted: this.props.person.personData.is_blocking,
//             error404: false,
//             dataReceived: true,
//           })
//         } else {
//           await this.props.getData(userData.token, userData.id)
//           this.setState({
//             type: "my-profile",
//             userInfo: this.props.auth.userData,
//             userDeleted: this.props.auth.userData.deleted,
//             currentUserBlocked: this.props.auth.userData.blocked,
//             isContentVisible: true,
//             error404: false,
//             dataReceived: true,
//           })
//         }
//       } catch ({ error }) {
//         if (error.code === 20100) {
//           return this.setState({ error404: true })
//         }
//
//         if (error.code === 20005) {
//           return this.setState({ currentUserDeleted: true })
//         }
//
//         this.props.enqueueSnackbar(getErrorText(error.code), {
//           variant: "error",
//         })
//       }
//     }
//   }
//
//   componentWillUnmount() {
//     if (this.state.type === "other-profile") {
//       this.props.resetPerson()
//       this.props.clearPostsList("person")
//     } else {
//       this.props.clearPostsList("my")
//     }
//   }
//
//   onBlacklistModalOpen = () => {
//     this.setState({ showBlacklistModal: true })
//   }
//
//   onBlacklistModalClose = () => this.setState({ showBlacklistModal: false })
//
//   addToBlacklist = async () => {
//     const { id, first_name, last_name } = this.state.userInfo
//
//     try {
//       console.log(id, first_name, last_name)
//       await this.props.addUserToBlacklist({
//         user: {
//           id,
//           first_name,
//           last_name,
//         },
//       })
//
//       this.setState({ isContentVisible: false, userBlacklisted: true })
//     } catch ({ error }) {
//       this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
//     }
//   }
//
//   removeFromBlacklist = async () => {
//     try {
//       await this.props.removeUserFromBlacklist({
//         user_id: this.state.userInfo.id,
//       })
//       // console.log('await this.props.removeUserFromBlacklist({user_id: this.state.userInfo.id});');
//
//       this.setState({ isContentVisible: true, userBlacklisted: false })
//     } catch ({ error }) {
//       this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
//     }
//   }
//
//   showUserContent = () => this.setState({ isContentVisible: true })
//   hideUserContent = () => this.setState({ isContentVisible: false })
//
//   render() {
//     const { userInfo, userDeleted, userBlocked, error404, dataReceived } =
//       this.state
//
//     if (error404) {
//       return <NotFound entity="User" />
//     }
//
//     if (userBlocked || userDeleted || this.state.currentUserBlacklisted) {
//       return <UserError user={userInfo} />
//     }
//
//     if (dataReceived) {
//       const linkToProducts =
//         this.state.type === "my-profile"
//           ? routes.MARKETPLACE_MY_PRODUCTS
//           : generatePath(routes.USER_PRODUCTS, { userId: userInfo.id })
//       return (
//         <>
//           <div className="common-page__content">
//             <main className="common-page__main">
//               <UserProfileHeader
//                 type={this.state.type}
//                 userInfo={userInfo}
//                 onBlacklistModalOpen={this.onBlacklistModalOpen}
//                 isContentVisible={this.state.isContentVisible}
//                 removeFromBlacklist={async () => {
//                   await this.removeFromBlacklist()
//                   this.setState({ userBlacklisted: false })
//                 }}
//                 showUserContent={this.showUserContent}
//                 hideUserContent={this.hideUserContent}
//                 isBlacklisted={this.state.userBlacklisted}
//               />
//
//               {this.state.isContentVisible ? (
//                 <UserProfileContent
//                   type={this.state.type}
//                   userInfo={userInfo}
//                   {...this.props}
//                 />
//               ) : (
//                 <Stub text={`${userInfo.first_name} is blacklisted`} />
//               )}
//             </main>
//             <aside className="common-page__sidebar">
//               {this.state.type === "my-profile" && (
//                 <CalendarProvider>
//                   <ProfileUpcomingAppointments />
//                 </CalendarProvider>
//               )}
//               {userInfo.friends_number > 0 && (
//                 <BoxedList
//                   title="Friends"
//                   counter={userInfo.friends_number}
//                   list={userInfo.friends}
//                   entity="users"
//                   linkShowAll={generatePath(routes.FRIENDS, {
//                     userId: this.state.userInfo.id,
//                   })}
//                 />
//               )}
//               {userInfo.products_number > 0 && (
//                 <BoxedList
//                   title="Products"
//                   counter={userInfo.products_number}
//                   list={userInfo.products}
//                   entity="products"
//                   linkShowAll={linkToProducts}
//                 />
//               )}
//             </aside>
//           </div>
//
//           <Route
//             path={routes.USER_POST}
//             render={(props) => (
//               <PostModal
//                 {...props}
//                 userInfo={userInfo}
//                 type={this.state.type}
//               />
//             )}
//           />
//
//           <Route
//             path={routes.BOOK_MEET_AUTHORIZED}
//             render={(props) => (
//               <BookMeetProvider>
//                 <BookMeetModal {...props} />
//               </BookMeetProvider>
//             )}
//           />
//
//           {this.state.showBlacklistModal && (
//             <BlacklistModal
//               open={this.state.showBlacklistModal}
//               user={userInfo}
//               handleClose={this.onBlacklistModalClose}
//               onAddToBlacklist={this.addToBlacklist}
//             />
//           )}
//         </>
//       )
//     } else {
//       return <Loader />
//     }
//   }
// }
//
// const mapStateToProps = ({ auth, person, friends, posts }) => ({
//   auth,
//   person,
//   friends,
//   posts,
// })
// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators(
//     {
//       getData,
//       getPerson,
//       resetPerson,
//       clearPostsList,
//       sendFriendRequest,
//       cancelFriendRequest,
//       acceptFriendRequest,
//       declineFriendRequest,
//       deleteFriend,
//       addUserToBlacklist,
//       removeUserFromBlacklist,
//     },
//     dispatch
//   )
//
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withRouter(withSnackbar(UserProfile)))
