import { views } from "react-big-calendar/lib/utils/constants"

export const calendarViewsArray = [
  { type: views.MONTH, name: "Month" },
  { type: views.WEEK, name: "Week" },
  { type: views.DAY, name: "Day" },
  { type: views.AGENDA, name: "Agenda" },
]

export const calendarEventsColors = [
    {
        hex: "#fbb040",
        // rgba: 'rgba(251, 176, 64, 0.5)',
        rgba: "rgb(253, 216, 168)",
    },
    {
        hex: "#5874b4",
        // rgba: 'rgba(88, 116, 180, 0.5)',
        rgba: "rgb(176, 188, 218)",
    },
    {
        hex: "#8d5193",
        // rgba: 'rgba(141, 81, 147, 0.5)',
        rgba: "rgb(199, 172, 202)",
    },
    {
        hex: "#b45231",
        // rgba: 'rgba(180, 82, 49, 0.5)',
        rgba: "rgb(217, 173, 161)",
    },
    {
        hex: "#49853f",
        // rgba: 'rgba(73, 133, 63, 0.5)',
        rgba: "rgb(169, 196, 165)",
    },
    {
        hex: "#258394",
        // rgba: 'rgba(37, 131, 148, 0.5)',
        rgba: "rgb(153, 195, 203)",
    },
];
