import React, { Component } from "react"
import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../../../library/constants/routes"
import ListSearchHeader from "../../../../../components/ui/listSearchHeader/listSearchHeader"
import List from "../../../../../components/ui/list/list"
import ListItemGroup from "../../../../../components/ui/listItem/listItemGroup"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import {
  clearGroupsList,
  deleteGroup,
  getGroup,
  getGroupRelatedList,
  getOutgoingInvitesList,
  getPendingInvitesList,
  groupRequestsCancel,
  groupRequestsDecline,
  joinGroup,
  leaveGroup,
} from "../../../../../library/store/actions/creators/groupsCreators"
import Loader from "../../../../../components/ui/loader/loader"
import * as qs from "query-string"
import withScroll from "../../../../../hocs/withScroll/withScroll"
import { LIMIT } from "../../../../../library/constants/limits"
import getErrorText from "../../../../../library/constants/errorTypes"
import { getGroupLogo } from "../../../../../library/utils/getGroupLogo"
import SEO from "../../../../../components/SEO"

class DistrictConnectedSchools extends Component {
  state = {
    query: "",
    dataReceived: false,
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }

  async componentDidMount() {
    const id = parseInt(this.props.match.params.groupId)
    const {
      location: { search },
    } = this.props
    const { q: name } = qs.parse(search)
    try {
      await this.props.getGroup({ id })
      if (
        this.props.groups.currentGroup &&
        (this.props.groups.currentGroup.deleted ||
          (this.props.groups.currentGroup.closed &&
            !this.props.groups.currentGroup.is_member) ||
          (this.props.groups.currentGroup.closed &&
            this.props.groups.currentGroup.is_member &&
            this.props.groups.currentGroup.tags.includes(1)))
      ) {
        return this.props.history.push(
          generatePath(routes.GROUP, { groupId: id })
        )
      }
      await this.getList(name)
      await this.setStateAsync({ dataReceived: true })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  searchGroups = async (name) => {
    const { history } = this.props
    this.props.clearGroupsList()
    history.push({
      search: `?q=${name}`,
    })
    await this.getList(name)
  }

  getList = async (name, offset = 0) => {
    const id = parseInt(this.props.match.params.groupId)
    this.setState({ dataReceived: false })
    await this.props.getGroupRelatedList({
      group_id: id,
      name: name || undefined,
      offset,
      limit: LIMIT,
      type: 4,
    })
    this.setState({ dataReceived: true })
  }

  onScroll = async () => {
    if (!this.state.dataReceived) return
    const {
      location: { search },
    } = this.props
    const { q: name } = qs.parse(search)
    await this.getList(name, this.props.groups.relatedList.length)
  }

  componentWillUnmount() {
    this.props.clearGroupsList()
  }

  render() {
    const {
      groups: { relatedList },
    } = this.props

    return (
      <div className="common-page__content">
        <SEO title={"Schools"} />
        <main className="common-page__main">
          <ListSearchHeader
            title="Schools"
            placeholder="Search by schools"
            search={(name) => this.searchGroups(name)}
            noResults={
              this.state.dataReceived && relatedList && !relatedList.length
            }
          />
          <List>
            {relatedList &&
              relatedList.map((item) => (
                <ListItemGroup
                  key={item.id}
                  group={item}
                  type="related"
                  leaveGroup={(id) => this.props.leaveGroup({ id })}
                  joinGroup={(id) => this.props.joinGroup({ id })}
                  groupRequestsDecline={(id) =>
                    this.props.groupRequestsDecline({ id })
                  }
                  groupRequestsCancel={(group_id) =>
                    this.props.groupRequestsCancel({ group_id })
                  }
                  deleteGroup={(id) => this.props.deleteGroup({ id })}
                />
              ))}
          </List>
          {!this.state.dataReceived && <Loader />}
        </main>

        {this.props.groups.currentGroup && (
          <aside className="common-page__sidebar">
            <div className="box aside-header">
              <Link
                to={generatePath(routes.GROUP, {
                  groupId: this.props.match.params.groupId,
                })}
                className="box__content aside-header__content"
              >
                <img
                  src={getGroupLogo(this.props.groups.currentGroup)}
                  className="aside-header__img"
                  alt=""
                />
                <div className="aside-header__info">
                  <div className="aside-header__name">
                    {this.props.groups.currentGroup.name}
                  </div>
                  <div className="aside-header__help">back to page</div>
                </div>
              </Link>
            </div>
            {/*<Ads/>*/}
          </aside>
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ groups }) => ({ groups })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getGroup,
      getGroupRelatedList,
      joinGroup,
      leaveGroup,
      deleteGroup,
      groupRequestsDecline,
      groupRequestsCancel,
      getPendingInvitesList,
      getOutgoingInvitesList,
      clearGroupsList,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withScroll(DistrictConnectedSchools)))
