import useEntityPicker from "../../../../hooks/useEntityPicker"
import { CALENDARS } from "../../../../library/store/actions/types/calendarTypes"
import PickerSelect from "../select/pickerSelect"
import { useEffect } from "react"
import { getLocalePeriod } from "../../../../library/utils/timeString/timeString"

const HoldPicker = ({
  value = "",
  onChange,
  groupId,
  // userId,
  hasError = false,
  errorMessage = "Field is required",
  required = true,
  emptyListMessage,
  emptyListLink,
}) => {
  const { fetchList, isWaitingForOtherFields, list, dataReceived } =
    useEntityPicker({
      fetchConfig: {
        apiUrl: CALENDARS.HOLDS.LIST,
        params: {
          // user_id: userId,
          school_id: groupId,
          offset: 0,
          limit: 100,
        },
      },
      key: "holds",
      loadOnMount: false,
    })

  useEffect(() => {
    if (groupId) {
      fetchList()
    }
  }, [groupId])

  return (
    <PickerSelect
      name={"hold"}
      entityName={"hold"}
      value={value}
      onChange={onChange}
      dataReceived={dataReceived}
      required={required}
      hasError={hasError}
      errorMessage={errorMessage}
      disabled={!dataReceived}
      list={list}
      isWaitingForOtherFields={isWaitingForOtherFields}
      isWaitingForOtherFieldsMessage={"First select school"}
      emptyListMessage={emptyListMessage}
      emptyListLink={emptyListLink}
      getValueHelperText={(hold) =>
        !hold.is_all_day
          ? getLocalePeriod(hold.time_from, hold.time_to)
          : "All day"
      }
      getOptionHelperText={(hold) =>
        !hold.is_all_day
          ? getLocalePeriod(hold.time_from, hold.time_to)
          : "All day"
      }
    />
  )
}

export default HoldPicker
