import { useDispatch } from "react-redux"
import { useAlert } from "../useAlert"
import React, { createContext, useContext, useState } from "react"
import {
  assignHoldToMeet,
  createMeet,
  deleteMeet,
  updateMeet,
} from "../../library/store/actions/creators/uknowCreators"
import AlertDialog from "../../components/ui/alertDialog"
import MeetFormModal from "../../screens/main/calendar/components/meetFormModal/MeetFormModal"
import MeetDialog from "../../screens/main/knowmap/subpages/meets/MeetDialog"
import AssignHoldToMeetDialog from "../../screens/main/knowmap/subpages/meets/AssignHoldToMeetDialog"

export const MeetModalsContext = createContext()
export const useMeetModals = () => useContext(MeetModalsContext)

const initialModalState = {
  type: null,
  open: false,
  activeId: null,
}

const MeetModalsProvider = ({ updateList, children }) => {
  const dispatch = useDispatch()
  const { errorAlert, successAlert } = useAlert()

  const [infoState, setInfoState] = useState(initialModalState)
  const [formState, setFormState] = useState(initialModalState)
  const [deletionState, setDeletionState] = useState(initialModalState)
  const [assignHoldState, setAssignHoldState] = useState(initialModalState)

  const onInfoOpen = (activeId, type) => {
    console.log({ activeId, type })
    setInfoState({
      type,
      activeId,
      open: true,
    })
  }
  const onFormOpen = (activeId, type) => {
    setFormState({
      type,
      activeId,
      open: true,
    })
  }
  const onDeletionOpen = (activeId, type) => {
    setDeletionState({
      type,
      activeId,
      open: true,
    })
  }

  const onAssignHoldOpen = (activeId, type) => {
    setAssignHoldState({
      type,
      activeId,
      open: true,
    })
  }

  const onInfoClose = () => setInfoState(initialModalState)
  const onFormClose = () => setFormState(initialModalState)
  const onDeletionClose = () => setDeletionState(initialModalState)
  const onAssignHoldClose = () => setAssignHoldState(initialModalState)

  const onSave = async (values) => {
    try {
      if (!formState.activeId) {
        await dispatch(createMeet(values))
      } else {
        await dispatch(
          updateMeet({
            id: formState.activeId,
            ...values,
          })
        )
      }

      if (updateList) await updateList()

      setFormState(initialModalState)
    } catch (e) {
      errorAlert(e)
    }
  }

  const onDelete = async () => {
    try {
      await dispatch(deleteMeet({ id: deletionState.activeId }))

      if (updateList) await updateList()

      setDeletionState(initialModalState)
    } catch (e) {
      errorAlert(e)
    }
  }

  const onAssignHold = async (hold_id) => {
    try {
      await dispatch(
        assignHoldToMeet({ id: assignHoldState.activeId, hold_id })
      )

      if (updateList) await updateList()

      setAssignHoldState(initialModalState)
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <MeetModalsContext.Provider
      value={{
        onFormOpen,
        onDeletionOpen,
        onInfoOpen,
        onAssignHoldOpen,
      }}
    >
      {/*<MeetModalsContext.Consumer>*/}
      {children}
      {/*</MeetModalsContext.Consumer>*/}

      {infoState.open && (
        <MeetDialog
          open={infoState.open}
          handleClose={onInfoClose}
          activeMeet={infoState.activeId}
          type={infoState.type}
          isConfirmable={infoState.type === "confirmable"}
        />
      )}

      {formState.open && (
        <MeetFormModal
          activeMeet={formState.activeId}
          handleClose={onFormClose}
          open={formState.open}
          afterSubmit={updateList}
          isCopying={formState.type === "copy"}
        />
      )}

      {deletionState.open && (
        <AlertDialog
          open={deletionState.open}
          handleClose={onDeletionClose}
          handleAccept={onDelete}
          title="Delete this Meet?"
          message="If you delete this Meet you will not be able to restore it"
        />
      )}

      {assignHoldState.open && (
        <AssignHoldToMeetDialog
          open={assignHoldState.open}
          onClose={onAssignHoldClose}
          onSubmit={onAssignHold}
        />
      )}
    </MeetModalsContext.Provider>
  )
}

export default MeetModalsProvider
