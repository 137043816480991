import React from "react"
import { withRouter } from "react-router-dom"
import styled from "@emotion/styled"
import { devices } from "../../../library/constants/styles/responsive"
import { Li, Ul } from "../uknow/uknowInfoPage"
import { key } from "../../../library/utils/generateKey"

export const ImageContainer = styled.div`
  width: ${(props) => (props.wide ? "100%" : "50%")};

  @media ${devices.xs} {
    width: 100%;
  }
`

export const ImagesGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media ${devices.xs} {
    flex-direction: column;
  }

  img {
    max-width: 430px;
  }
`

const HowToArticle = ({ article }) => {
  return (
    <div className="box">
      <div className="box__heading">{article.title}</div>
      <div className="box__content">
        {article.component
          ? article.component
          : article.content.map((item, index) => {
              const itemMargin =
                article.content.length > index + 1 ? "mb15" : "mb0"
              if (item.type === "text") {
                return (
                  <pre key={item.type + index} className={itemMargin}>
                    {item.value}
                  </pre>
                )
              }

              if (["image", "wide-image"].includes(item.type)) {
                return (
                  <ImageContainer
                    wide={item.type === "wide-image"}
                    className={itemMargin}
                    key={item.image}
                  >
                    <img src={item.image} alt="" />
                  </ImageContainer>
                )
              }

              if (item.type === "images") {
                return (
                  <ImagesGroup className={itemMargin} key={item.type + index}>
                    {item.images.map((img, idx) => (
                      <div key={img}>
                        <img src={img} alt={item.title} />
                      </div>
                    ))}
                  </ImagesGroup>
                )
              }

              if (item.type === "list") {
                return (
                  <Ul className={itemMargin} key={item.type + index}>
                    {item.values?.map((v) => (
                      <Li key={key()}>{v}</Li>
                    ))}
                  </Ul>
                )
              }

              return null
            })}
      </div>
    </div>
  )
}

export default withRouter(HowToArticle)
