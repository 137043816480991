import React from "react"
import InfoLayout from "../infoLayout"
import { Section, SectionHeading } from "./accountsInfoMain"
import { Button } from "@material-ui/core"
import { Link } from "react-router-dom"
import { ACCOUNTS_INFO } from "../../../library/constants/routes"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import { Li, Ul } from "../uknow/uknowInfoPage"
import { MobileMarketButtons } from "../appPromote/appPromote"
import img1 from "../../../assets/img/pages/info/learner/1.png"
import img2 from "../../../assets/img/pages/info/learner/2.png"
import img3 from "../../../assets/img/pages/info/learner/3.png"
import img4 from "../../../assets/img/pages/info/learner/4.png"

const AccountsInfoLearner = () => {
  return (
    <InfoLayout>
      <div className={"flexgrid"}>
        <Section pt={"30px"}>
          <div className="container">
            <div className="full-width">
              <Button
                component={Link}
                to={ACCOUNTS_INFO}
                color={"primary"}
                aria-label="Back"
                startIcon={<KeyboardArrowLeft />}
                className={"mb30"}
              >
                Back to accounts overview
              </Button>

              <SectionHeading>Members & Learners</SectionHeading>

              <p className="mb30 text-center">
                Here is where you use intelligent calendaring. Other features
                include posting, messaging, bookmarks, and create a record of
                your learning for life to share with others, employers, and
                schools.
              </p>
              <div className="row full-width">
                <div className="col-md-5 col-md-offset-1">
                  <Ul>
                    <Li>
                      Set intelligent meetings that sync calendars and find time
                      between you and your invitees.
                    </Li>
                    <Li>Create your own study schedule</Li>
                    <Li>Give and receive Creds (badges)</Li>
                    <Li>Create events</Li>
                  </Ul>
                </div>
                <div className="col-md-5 f jcfe">
                  <Ul>
                    <Li>Associate to a School or Tutor</Li>
                    <Li>Find learning resources</Li>
                    <Li>Receive learning plans and resource mixes</Li>
                    <Li>Sync your calendar for accepting live meets</Li>
                    <Li>
                      Enter your favorite quote, your interests, <br /> your
                      favorite books, movies and learning subjects
                    </Li>
                  </Ul>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section isDark>
          <div className="container">
            <div className="full-width">
              <div className="row full-width mb60">
                <div className="col-md-4 full-width-xs col-md-offset-1 f jcc">
                  <img src={img1} alt={"Student with laptop"} />
                </div>
                <div className="col-md-6 order-0-xs pt30 text-center">
                  <SectionHeading>Just for You</SectionHeading>
                  <p>
                    Build your learning story. <br />
                    Here is where you build a learning profile and find more
                    things to learn, join your school, or participate in online
                    events.
                  </p>
                </div>
              </div>
              <div className="row full-width mb60">
                <div className="col-md-6 pt30 order-0-xs col-md-offset-1 text-center">
                  <SectionHeading>Worthy Friends</SectionHeading>
                  <p>
                    Find friends with the same interest in learning. Unlike
                    other social media, the focus here is on building knowledge.
                    Keep it that way and you’ll be able to share what you saved
                    with colleges and employers later.
                  </p>
                </div>
                <div className="col-md-4 full-width-xs f jcc">
                  <img src={img2} alt={"Group of students talking"} />
                </div>
              </div>
              <div className="row full-width mb60">
                <div className="col-md-4 full-width-xs col-md-offset-1 f jcc">
                  <img src={img3} alt={"Subjects"} />
                </div>
                <div className="col-md-6 order-0-xs pt30 text-center">
                  <SectionHeading>Smart is Goals</SectionHeading>
                  <p>
                    Post your learning.
                    <br />
                    Talk about what you learned, your latest big achievement,
                    what’s interesting, what’s weird, what inspires you that
                    could inspire other learners. Smart is so goals.
                  </p>
                </div>
              </div>
              <div className="row full-width">
                <div className="col-md-6 order-0-xs pt30 col-md-offset-1 text-center">
                  <SectionHeading>Give Cred</SectionHeading>
                  <p>
                    Give social or extra-curricular Creds
                    <br />
                    When another learner helps you, make them a Cred to show
                    your appreciation.
                  </p>
                </div>
                <div className="col-md-4 full-width-xs f jcc">
                  <img src={img4} alt={"KnowCreds"} />
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section>
          <div className="container">
            <div className="full-width f jcc">
              <MobileMarketButtons />
            </div>
          </div>
        </Section>
      </div>
    </InfoLayout>
  )
}

export default AccountsInfoLearner
