import React from "react"
import { ClickAwayListener, Grow, Popper } from "@material-ui/core"
import MoreHorizOutlined from "@material-ui/icons/MoreHorizOutlined"
import VerticalMenu from "../../../ui/verticalMenu"

const Menu = ({ items = [] }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl)
  const id = open ? "dropdown-menu" : undefined

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div>
        <button
          aria-describedby={id}
          type="button"
          className="more-dropdown-menu more-dropdown-menu__trigger"
          onClick={handleClick}
        >
          <MoreHorizOutlined />
        </button>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          transition
          placement={"bottom-end"}
        >
          {({ TransitionProps }) => (
            <Grow in={open} {...TransitionProps} timeout={200}>
              <div style={{ minWidth: 170, backgroundColor: "#fff" }}>
                <VerticalMenu menuItems={items} />
              </div>
            </Grow>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  )
}

export default Menu
