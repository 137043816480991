import React, { useEffect } from "react"
import PlainLink from "../components/ui/link"
import { PlainButton } from "../components/v2/controls"
import { Tooltip } from "@material-ui/core"
import UserLink from "../components/v2/utils/UserLink"
import { useQuery } from "@tanstack/react-query"
import { useHistory, useLocation } from "react-router-dom"
import { useSearchQuery } from "./useSearchQuery"
import { useAlert } from "./useAlert"
import * as qs from "query-string"
import { clearTeacherKnowmapKnowmeets } from "../library/store/actions/creators/knowmapCreators"

const useTableContent = () => {
  const composeValue = (value) => {
    if (!value) return <span className={"text-black54"}>—</span>

    if (typeof value === "string" || typeof value === "number") {
      return <p className={"line-clamp-3"}>{value}</p>
    }

    if (value.type === "component") return value.component()

    if (value.type === "button") {
      return <PlainButton onClick={value.onClick}>{value.label}</PlainButton>
    }

    if (value.type === "external-link") {
      return (
        <Tooltip title={value.path} arrow>
          <div>
            <PlainLink native to={value.path} className={"!line-clamp-1"}>
              {value.path}
            </PlainLink>
          </div>
        </Tooltip>
      )
    }

    if (value.type === "phone") {
      return (
        <Tooltip title={value.number} arrow>
          <div>
            <PlainLink
              native
              phone
              to={value.number}
              className={"!line-clamp-1"}
            >
              {value.number}
            </PlainLink>
          </div>
        </Tooltip>
      )
    }

    if (value.type === "link") {
      return (
        <PlainLink to={value.path} newTab className={"!line-clamp-1"}>
          {value.label}
        </PlainLink>
      )
    }

    if (value.type === "links-list") {
      return value.links.map((link) => (
        <div className={"mb5"} key={link.label()}>
          <PlainLink to={link.path} newTab className={"!line-clamp-1"}>
            {link.label()}
          </PlainLink>
        </div>
      ))
    }

    if (value.type === "people") {
      const renderLimit = value.limit || 1
      return (
        <div>
          {value.list.length ? (
            <div>
              {value.list.slice(0, renderLimit).map((person, i) => (
                <div key={i}>
                  {person.id ? (
                    <UserLink
                      user={{
                        id: person.id,
                        first_name: person.name_first,
                        last_name: person.name_last,
                      }}
                    />
                  ) : (
                    <p>{person.name_first + " " + person.name_last}</p>
                  )}
                </div>
              ))}
              {value.list.length > renderLimit && (
                <p className={"text-black54"}>
                  +{value.list.length - renderLimit} more
                </p>
              )}
            </div>
          ) : (
            <span className={"text-black54"}>—</span>
          )}
        </div>
      )
    }

    return ""
  }

  const composeActions = (action) => {
    if (action.type === "link") {
      return (
        <PlainLink to={action.path} newTab>
          {action.label()}
        </PlainLink>
      )
    }

    if (action.type === "button") {
      return (
        <PlainButton onClick={action.onClick} compact>
          {action.label()}
        </PlainButton>
      )
    }

    if (action.component) return action.component()

    return null
  }

  return { composeValue, composeActions }
}

export const useTableLayout = () => {
  const { composeValue, composeActions } = useTableContent()

  return {
    composeValue,
    composeActions,
  }
}

export const useTable = ({
  isSearchable = false,
  getData,
  clearData,
  availableOffsets,
  total,
} = {}) => {
  const { errorAlert } = useAlert()
  const location = useLocation()
  const searchQuery = useSearchQuery()

  const rowsPerPageOptions = [10, 15, 20, 25, 30]

  const [name, setName] = React.useState("")

  const [dataReceived, setDataReceived] = React.useState(false)
  const [changingPage, setChangingPage] = React.useState(false)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageOptions[2])
  const [currentOffset, setCurrentOffset] = React.useState(0)

  const fetchData = async (offset = 0, shouldPageChange = false) => {
    try {
      if (shouldPageChange) setChangingPage(true)

      await getData({
        name: isSearchable ? searchQuery.fields?.name || undefined : undefined,
        offset,
        limit: rowsPerPage,
      })

      setCurrentOffset(offset)

      setDataReceived(true)

      if (shouldPageChange) setChangingPage(false)
    } catch (e) {
      errorAlert(e)
    }
  }

  const onSearch = (e) => {
    searchQuery.compose({ name: name || undefined })
  }

  React.useEffect(() => {
    if (typeof searchQuery.fields.name === "string") {
      setName(searchQuery.fields.name)
    }

    fetchData()

    return clearData
  }, [])

  React.useEffect(() => {
    const search = setTimeout(() => {
      onSearch()
    }, 500)

    return () => clearTimeout(search)
  }, [name])

  React.useEffect(() => {
    if (dataReceived) {
      fetchData()
      setPage(0)
    }
  }, [location.search])

  React.useEffect(() => {
    if (dataReceived && !changingPage) {
      fetchData(availableOffsets[page], true)
    }
  }, [page])

  React.useEffect(() => {
    if (dataReceived && !changingPage) {
      fetchData()
      setPage(0)
    }
  }, [rowsPerPage])

  const { composeValue, composeActions } = useTableContent()

  return {
    composeValue,
    composeActions,
    dataReceived,
    search: {
      value: name,
      setValue: setName,
    },
    pagination: {
      count: total || 100,
      page,
      onPageChange: (e, value) => setPage(value),
      rowsPerPage,
      rowsPerPageOptions: rowsPerPageOptions,
      onRowsPerPageChange: (e) => setRowsPerPage(parseInt(e.target.value, 10)),
    },
    refetch: () => fetchData(currentOffset),
  }
}
