import React from "react"
import { useCommentCtx } from "../CommentContext"

const RestorePost = () => {
  const { onRestore } = useCommentCtx()

  return (
    <div className="p15">
      <div className="f jcc aic">
        <div>
          <span>Comment deleted. </span>
          <button type={"button"} className="post__restore" onClick={onRestore}>
            Undo.
          </button>
        </div>
      </div>
    </div>
  )
}

export default RestorePost
