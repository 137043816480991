import React, { Component } from "react"
import userAvatarDefault from "../../../../assets/img/user_avatar.svg"
import { generatePath, Link, withRouter } from "react-router-dom"
import Button from "@material-ui/core/Button/Button"
import DropdownControl from "../../../../components/ui/dropdowns/dropdownControl/dropdownControl"
import MenuList from "@material-ui/core/MenuList/MenuList"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import { bindActionCreators } from "redux"
import {
  acceptFriendRequest,
  cancelFriendRequest,
  declineFriendRequest,
  deleteFriend,
  sendFriendRequest,
} from "../../../../library/store/actions/creators/friendsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { withStyles } from "@material-ui/core"
import { colors } from "../../../../library/constants/styles/colors"
import getErrorText from "../../../../library/constants/errorTypes"
import LinesEllipsis from "react-lines-ellipsis"
import * as routes from "../../../../library/constants/routes"
import { userRolesIds } from "../../../../library/constants/userRoles"
import {
  composeUserHighlightedEducation,
  composeUserHighlightedWork,
} from "../../../../library/utils/getUserHighlightedInfo"
import { getUserLocationString } from "../../../../library/utils/getUserLocationString"

const CustomMenuItem = withStyles({
  root: {
    color: colors.primary,
  },
})(MenuItem)

class SearchResultUser extends Component {
    state = {
        isFriend: this.props.user.user_relation.is_friend,
        isRequested: this.props.user.user_relation.is_requested,
        isRequesting: this.props.user.user_relation.is_requesting,
        noRelations: !this.props.user.user_relation.is_friend
            && !this.props.user.user_relation.is_requested
            && !this.props.user.user_relation.is_requesting,
    };

    sendFriendRequest = async () => {
        const {user: {id}} = this.props;

        try {
            await this.props.sendFriendRequest(id);
            this.setState({
                isRequested: true,
                noRelations: false,
            });
        } catch ({error}) {
            this.props.enqueueSnackbar(getErrorText(error.code), {variant: "error"});
        }
    };

    cancelFriendRequest = async () => {
        const {user: {id}} = this.props;

        try {
            await this.props.cancelFriendRequest(id);
            this.setState({
                isRequested: false,
                noRelations: true,
            });
        } catch ({error}) {
            this.props.enqueueSnackbar(getErrorText(error.code), {variant: "error"});
        }
    };

    acceptFriendRequest = async () => {
        const {user: {id}} = this.props;

        try {
            await this.props.acceptFriendRequest(id);
            this.setState({
                isRequesting: false,
                isFriend: true,
            });
        } catch ({error}) {
            this.props.enqueueSnackbar(getErrorText(error.code), {variant: "error"});
        }
    };

    declineFriendRequest = async () => {
        const {user: {id}} = this.props;

        try {
            await this.props.declineFriendRequest(id);
            this.setState({
                isRequesting: false,
                noRelations: true,
            });
        } catch ({error}) {
            this.props.enqueueSnackbar(getErrorText(error.code), {variant: "error"});
        }
    };

    deleteFriend = async () => {
        const {user: {id}} = this.props;

        try {
            await this.props.deleteFriend(id);
            this.setState({
                isFriend: false,
                noRelations: true,
            });
        } catch ({error}) {
            this.props.enqueueSnackbar(getErrorText(error.code), {variant: "error"});
        }
    };

    render() {
        const {isFriend, isRequested, isRequesting, noRelations} = this.state;
        const {type, controlType} = this.props;
        const {id, first_name, last_name, photo, role, city, country, ...rest} = this.props.user;

        const photoUrl = photo !== "" ? photo : userAvatarDefault;
        const fullName = `${first_name} ${last_name}`;
        const profileUrl = generatePath(routes.USER, {userId: id});

        const dropdownControl = () => {
            const buttonLabel = isFriend ? "Added"
                : isRequesting ? "Confirm request"
                    : isRequested ? "Request sent"
                        : "";

            return (
                noRelations ? (
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={this.sendFriendRequest}
                    >
                        Add
                    </Button>
                ) : (
                    <DropdownControl buttonLabel={buttonLabel}>
                        <MenuList>
                            {isFriend && (
                                <CustomMenuItem onClick={this.deleteFriend}>
                                    Remove from friends
                                </CustomMenuItem>
                            )}
                            {isRequested && (
                                <CustomMenuItem onClick={this.cancelFriendRequest}>
                                    Cancel request
                                </CustomMenuItem>
                            )}
                            {isRequesting && (
                                <>
                                    <CustomMenuItem onClick={this.acceptFriendRequest}>
                                        Confirm request
                                    </CustomMenuItem>
                                    <CustomMenuItem onClick={this.declineFriendRequest}>
                                        Decline request
                                    </CustomMenuItem>
                                </>
                            )}
                        </MenuList>
                    </DropdownControl>
                )
            );
        };

        const controlsAlign = controlType === "OUTGOING_FRIEND_REQUEST" || type === "search" || controlType === "DEFAULT" ? "jcc" : "";
        // const addressInfoBlock = (city ? city : "") + (country ? `, ${country}` : "");
        return (
            <div className="box list-item">
                <div className="f aic full-width">
                    <Link to={profileUrl} className="list-item__img">
                        <img src={photoUrl} alt={fullName}/>
                    </Link>
                    <div className="list-item__info">
                        <span className="font-12 color-black-54">{userRolesIds[role].name}</span>
                        <Link to={profileUrl} className="link list-item__name"><LinesEllipsis
                            text={fullName}
                            maxLine="1"
                            ellipsis="..."
                            trimRight
                            basedOn="letters"
                        /></Link>
                        {/*<span className="list-item__description">{userRolesIds[role].name}</span>*/}

                        {!!rest.education && composeUserHighlightedEducation(rest.education)}

                        {!!rest.work && composeUserHighlightedWork(rest.work)}
                        <span className="list-item__info-block">
                            {getUserLocationString(this.props.user) && <LinesEllipsis
                                text={getUserLocationString(this.props.user)}
                                maxLine="1"
                                ellipsis="..."
                                trimRight
                                basedOn="letters"
                            />}
                        </span>
                    </div>
                </div>
                {this.props.auth.userData.id !== id && (
                    <div className={`list-item__control-panel ${controlsAlign}`}>
                        {type === "search" ? dropdownControl() : null}
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({auth, search, friends}) => ({auth, search, friends});
const mapDispatchToProps = dispatch => bindActionCreators({
    sendFriendRequest,
    cancelFriendRequest,
    acceptFriendRequest,
    declineFriendRequest,
    deleteFriend,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withSnackbar(SearchResultUser)));
