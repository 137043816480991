import React from "react"
import HorizontalList from "../../../../../components/ui/horizontalList/horizontalList"
import * as routes from "../../../../../library/constants/routes"
import { KeyboardArrowRight } from "@material-ui/icons"
import PlainLink from "../../../../../components/ui/link/plainLink"
import { generatePath, Link, withRouter } from "react-router-dom"

const AllBookmarksList = ({ list, type, match }) => {
  const getArrayByEntity = (array, entity) => {
    return (
      !!array &&
      array
        .map((item) => item[entity])
        .filter((item) => !item.deleted && !item.blocked)
    )
  }

  const users = getArrayByEntity(list.users, "user")
  const groups = getArrayByEntity(list.groups, "group")
  const products = getArrayByEntity(list.products, "product")
  const knowcreds = getArrayByEntity(list.knowcreds, "knowcred")
  const records = getArrayByEntity(list.records, "record")
  const standards = getArrayByEntity(list.standards, "standard")

  return (
    <>
      {users && users.length > 0 && (
        <HorizontalList
          entity="users"
          itemType="rounded"
          rounded
          list={users}
          title="People"
          link={
            type === "bookmarks"
              ? routes.BOOKMARKS_PEOPLE
              : generatePath(routes.FOLDER_PEOPLE, {
                  folderId: match.params.folderId,
                })
          }
          counter={list.users_number}
        />
      )}

      {groups && groups.length > 0 && (
        <HorizontalList
          entity="groups"
          itemType="rounded"
          rounded
          list={groups}
          title="Groups"
          link={
            type === "bookmarks"
              ? routes.BOOKMARKS_GROUPS
              : generatePath(routes.FOLDER_GROUPS, {
                  folderId: match.params.folderId,
                })
          }
          counter={list.groups_number}
        />
      )}

      {knowcreds && knowcreds.length > 0 && (
        <HorizontalList
          entity="knowcred"
          list={knowcreds}
          title="Creds"
          link={
            type === "bookmarks"
              ? routes.BOOKMARKS_KNOWCRED
              : generatePath(routes.FOLDER_KNOWCRED, {
                  folderId: match.params.folderId,
                })
          }
          counter={list.knowcreds_number}
        />
      )}

      {/*{products && products.length > 0 && (*/}
      {/*  <HorizontalList*/}
      {/*    entity="products"*/}
      {/*    list={products}*/}
      {/*    title="Products"*/}
      {/*    link={*/}
      {/*      type === "bookmarks"*/}
      {/*        ? routes.BOOKMARKS_PRODUCTS*/}
      {/*        : generatePath(routes.FOLDER_PRODUCTS, {*/}
      {/*            folderId: match.params.folderId,*/}
      {/*          })*/}
      {/*    }*/}
      {/*    counter={list.products_number}*/}
      {/*  />*/}
      {/*)}*/}

      {/*{records && records.length > 0 && (*/}
      {/*  <HorizontalList*/}
      {/*    entity="records"*/}
      {/*    list={records}*/}
      {/*    title="Records"*/}
      {/*    link={*/}
      {/*      type === "bookmarks"*/}
      {/*        ? routes.BOOKMARKS_RECORDS*/}
      {/*        : generatePath(routes.FOLDER_RECORDS, {*/}
      {/*            folderId: match.params.folderId,*/}
      {/*          })*/}
      {/*    }*/}
      {/*    counter={list.records_number}*/}
      {/*  />*/}
      {/*)}*/}

      {standards && standards.length > 0 && (
        <div className="box">
          <div className="box__content">
            <div className="f aic jcsb">
              <Link
                to={
                  type === "bookmarks"
                    ? routes.BOOKMARKS_STANDARDS
                    : generatePath(routes.FOLDER_STANDARDS, {
                        folderId: match.params.folderId,
                      })
                }
              >
                <span>
                  Standards{" "}
                  <span className="color-black-54">
                    {list.standards_number}
                  </span>
                </span>
              </Link>
              <PlainLink
                to={
                  type === "bookmarks"
                    ? routes.BOOKMARKS_STANDARDS
                    : generatePath(routes.FOLDER_STANDARDS, {
                        folderId: match.params.folderId,
                      })
                }
              >
                Show all <KeyboardArrowRight />
              </PlainLink>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default withRouter(AllBookmarksList);
