import React from "react"
import ListSearchHeader from "../../../../components/ui/listSearchHeader/listSearchHeader"
import * as routes from "../../../../library/constants/routes"
import { generatePath, NavLink } from "react-router-dom"
import VerticalMenu from "../../../../components/ui/verticalMenu"
import ProductsListFilter from "../../marketplace/productsList/productsListFilter/productsListFilter"
import Loader from "../../../../components/ui/loader"
import { connect } from "react-redux"
import withScroll from "../../../../hocs/withScroll/withScroll"
import {
  clearKnowMixList,
  deleteKnowmix,
  getKnowMixList,
  updateKnowMixList,
  worksList,
} from "../../../../library/store/actions/creators/knowmixCreators"
import { bindActionCreators } from "redux"
import * as qs from "query-string"
import * as _ from "lodash"
import KnowMixSquareCard from "../knowmixCard/knowMixSquareCard"
import KnowMixLineCard from "../knowmixCard/knowMixLineCard"
import "./knowmix-list.scss"
import { LIMIT } from "../../../../library/constants/limits"
import getErrorText from "../../../../library/constants/errorTypes"

class KnowmixList extends React.Component {
  state = {
    list: [],
    dataReceived: false,
    dialogOpen: false,
    complaintsModal: null,
  }

  searchParams = {}

  async componentDidMount() {
    const {
      location: { search },
    } = this.props
    const { q: query, grades, subjects } = qs.parse(search)
    this.searchParams = {
      name: query || undefined,
      subjects: subjects
        ? _.map(_.split(subjects, ","), _.parseInt)
        : undefined,
      school_grades: grades
        ? _.map(_.split(grades, ","), _.parseInt)
        : undefined,
    }
    this.getKnowMixes()
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.match.path !== this.props.match.path ||
      prevProps.location.search !== this.props.location.search
    ) {
      this.props.clearKnowMixList()
      this.setState({
        list: [],
      })
      const {
        location: { search },
      } = this.props
      const { q: query, grades, subjects } = qs.parse(search)
      this.searchParams = {
        name: query || undefined,
        subjects: subjects
          ? _.map(_.split(subjects, ","), _.parseInt)
          : undefined,
        school_grades: grades
          ? _.map(_.split(grades, ","), _.parseInt)
          : undefined,
      }
      await this.getKnowMixes()
    }
  }

  componentWillUnmount() {
    this.props.clearKnowMixList()
  }

  getMenuItems = () => {
    const {
      knowmix: { isExaminer },
    } = this.props
    const hideMainListMenuChildren = [
      routes.KNOWMIX_LIST_MY_GRADES,
      routes.KNOWMIX_LIST_MY_GRADES_STARTED,
      routes.KNOWMIX_LIST_MY_GRADES_FINISHED,
      routes.SUBMITTED_WORKS,
      routes.SUBMITTED_WORKS_EXAMINED,
      routes.SUBMITTED_WORKS_STEPS,
      routes.SUBMITTED_WORKS_STEPS_EXAMINED,
      routes.SUBMITTED_WORKS_KNOWMIX,
      routes.SUBMITTED_WORKS_KNOWMIX_EXAMINED,
    ].includes(this.props.match.path)
    const hideMyGradesListMenuChildren = [
      routes.KNOWMIX_LIST,
      routes.KNOWMIX_LIST_MANAGED,
      routes.KNOWMIX_LIST_STARTED,
      routes.KNOWMIX_LIST_STARTED_MANAGED,
      routes.KNOWMIX_LIST_NOT_STARTED,
      routes.KNOWMIX_LIST_NOT_STARTED_MANAGED,
      routes.KNOWMIX_LIST_FINISHED,
      routes.KNOWMIX_LIST_FINISHED_MANAGED,
      routes.SUBMITTED_WORKS,
      routes.SUBMITTED_WORKS_EXAMINED,
      routes.SUBMITTED_WORKS_STEPS,
      routes.SUBMITTED_WORKS_STEPS_EXAMINED,
      routes.SUBMITTED_WORKS_KNOWMIX,
      routes.SUBMITTED_WORKS_KNOWMIX_EXAMINED,
    ].includes(this.props.match.path)
    const hideSubmittedWorksListMenuChildren = [
      routes.KNOWMIX_LIST,
      routes.KNOWMIX_LIST_MANAGED,
      routes.KNOWMIX_LIST_STARTED,
      routes.KNOWMIX_LIST_STARTED_MANAGED,
      routes.KNOWMIX_LIST_NOT_STARTED,
      routes.KNOWMIX_LIST_NOT_STARTED_MANAGED,
      routes.KNOWMIX_LIST_FINISHED,
      routes.KNOWMIX_LIST_FINISHED_MANAGED,
      routes.KNOWMIX_LIST_MY_GRADES,
      routes.KNOWMIX_LIST_MY_GRADES_STARTED,
      routes.KNOWMIX_LIST_MY_GRADES_FINISHED,
    ].includes(this.props.match.path)
    const menuItems = [
      {
        path: generatePath(
          this.props.type === "managed"
            ? routes.KNOWMIX_LIST_MANAGED
            : routes.KNOWMIX_LIST
        ),
        type: "parent",
        label: "My course",
      },
      {
        path: generatePath(
          this.props.type === "managed"
            ? routes.KNOWMIX_LIST_NOT_STARTED_MANAGED
            : routes.KNOWMIX_LIST_NOT_STARTED
        ),
        type: "child",
        label: "Not started",
        hidden: hideMainListMenuChildren,
      },
      {
        path: generatePath(
          this.props.type === "managed"
            ? routes.KNOWMIX_LIST_STARTED_MANAGED
            : routes.KNOWMIX_LIST_STARTED
        ),
        type: "child",
        label: "Started",
        key: "started_main",
        hidden: hideMainListMenuChildren,
      },
      {
        path: generatePath(
          this.props.type === "managed"
            ? routes.KNOWMIX_LIST_FINISHED_MANAGED
            : routes.KNOWMIX_LIST_FINISHED
        ),
        type: "child",
        label: "Finished",
        key: "finished_main",
        hidden: hideMainListMenuChildren,
      },
      {
        path: generatePath(routes.KNOWMIX_LIST_MY_GRADES),
        type: "parent",
        label: "My grades",
      },
      {
        path: generatePath(routes.KNOWMIX_LIST_MY_GRADES_STARTED),
        type: "child",
        label: "Started course",
        key: "started_my",
        hidden: hideMyGradesListMenuChildren,
      },
      {
        path: generatePath(routes.KNOWMIX_LIST_MY_GRADES_FINISHED),
        type: "child",
        label: "Finished course",
        key: "finished_my",
        hidden: hideMyGradesListMenuChildren,
      },
      isExaminer && {
        path: generatePath(
          this.props.type === "examined"
            ? routes.SUBMITTED_WORKS_STEPS_EXAMINED
            : routes.SUBMITTED_WORKS_STEPS
        ),
        type: "parent",
        label: "Submitted works",
        markAsActive: false,
      },
      isExaminer && {
        path: generatePath(
          this.props.type === "examined"
            ? routes.SUBMITTED_WORKS_STEPS_EXAMINED
            : routes.SUBMITTED_WORKS_STEPS
        ),
        type: "child",
        label: "Steps",
        hidden: hideSubmittedWorksListMenuChildren,
      },
      isExaminer && {
        path: generatePath(
          this.props.type === "examined"
            ? routes.SUBMITTED_WORKS_KNOWMIX_EXAMINED
            : routes.SUBMITTED_WORKS_KNOWMIX
        ),
        type: "child",
        label: "Full course",
        hidden: hideSubmittedWorksListMenuChildren,
      },
    ]
    return menuItems
  }

  composeTabs = () => {
    const {
      match: { path },
      type,
    } = this.props
    switch (path) {
      case routes.KNOWMIX_LIST:
      case routes.KNOWMIX_LIST_MANAGED:
        return {
          allUrl: routes.KNOWMIX_LIST,
          managedUrl: routes.KNOWMIX_LIST_MANAGED,
          emptyLabel: "You don`t have courses yet",
          hasButton: true,
        }
      case routes.KNOWMIX_LIST_STARTED:
      case routes.KNOWMIX_LIST_STARTED_MANAGED:
        return {
          allUrl: routes.KNOWMIX_LIST_STARTED,
          managedUrl: routes.KNOWMIX_LIST_STARTED_MANAGED,
          emptyLabel: "You don`t have started courses yet",
          hasButton: true,
        }
      case routes.KNOWMIX_LIST_NOT_STARTED:
      case routes.KNOWMIX_LIST_NOT_STARTED_MANAGED:
        return {
          allUrl: routes.KNOWMIX_LIST_NOT_STARTED,
          managedUrl: routes.KNOWMIX_LIST_NOT_STARTED_MANAGED,
          emptyLabel: "You don`t have not started courses yet",
          hasButton: true,
        }
      case routes.KNOWMIX_LIST_FINISHED:
      case routes.KNOWMIX_LIST_FINISHED_MANAGED:
        return {
          allUrl: routes.KNOWMIX_LIST_FINISHED,
          managedUrl: routes.KNOWMIX_LIST_FINISHED_MANAGED,
          emptyLabel: "You don`t have finished courses yet",
          hasButton: true,
        }
      case routes.KNOWMIX_LIST_MY_GRADES:
      case routes.KNOWMIX_LIST_MY_GRADES_STARTED:
      case routes.KNOWMIX_LIST_MY_GRADES_FINISHED:
        return {
          emptyLabel: "You don`t have grades yet",
        }
      case routes.SUBMITTED_WORKS:
      case routes.SUBMITTED_WORKS_EXAMINED:
        return {
          pendingUrl: routes.SUBMITTED_WORKS,
          examinedUrl: routes.SUBMITTED_WORKS_EXAMINED,
          emptyLabel: `You don\`t have ${type} works yet`,
        }
      case routes.SUBMITTED_WORKS_STEPS:
      case routes.SUBMITTED_WORKS_STEPS_EXAMINED:
        return {
          pendingUrl: routes.SUBMITTED_WORKS_STEPS,
          examinedUrl: routes.SUBMITTED_WORKS_STEPS_EXAMINED,
          emptyLabel: `You don\`t have ${type} steps yet`,
        }
      case routes.SUBMITTED_WORKS_KNOWMIX:
      case routes.SUBMITTED_WORKS_KNOWMIX_EXAMINED:
        return {
          pendingUrl: routes.SUBMITTED_WORKS_KNOWMIX,
          examinedUrl: routes.SUBMITTED_WORKS_KNOWMIX_EXAMINED,
          emptyLabel: `You don\`t have ${type} full courses yet`,
        }
      default:
        return {}
    }
  }

  getLabelLayout = (allUrl, managedUrl, pendingUrl, examinedUrl) => {
    const {
      match: { path },
      type,
    } = this.props
    switch (path) {
      case routes.KNOWMIX_LIST:
      case routes.KNOWMIX_LIST_MANAGED:
      case routes.KNOWMIX_LIST_STARTED:
      case routes.KNOWMIX_LIST_STARTED_MANAGED:
      case routes.KNOWMIX_LIST_NOT_STARTED:
      case routes.KNOWMIX_LIST_NOT_STARTED_MANAGED:
      case routes.KNOWMIX_LIST_FINISHED:
      case routes.KNOWMIX_LIST_FINISHED_MANAGED:
        return (
          <ul className="h-tabs__nav-list">
            <li
              className={`h-tabs__item ${
                type === "all" ? "h-tabs__item--active" : ""
              }`}
            >
              <NavLink
                exact
                activeClassName="h-tabs__item--active"
                to={allUrl}
                className="h-tabs__link"
              >
                All courses
              </NavLink>
            </li>
            <li
              className={`h-tabs__item ${
                type === "managed" ? "h-tabs__item--active" : ""
              }`}
            >
              <NavLink
                exact
                activeClassName="h-tabs__item--active"
                to={managedUrl}
                className="h-tabs__link"
              >
                Managed courses
              </NavLink>
            </li>
          </ul>
        )
      case routes.KNOWMIX_LIST_MY_GRADES:
      case routes.KNOWMIX_LIST_MY_GRADES_STARTED:
      case routes.KNOWMIX_LIST_MY_GRADES_FINISHED:
        return "My grades"
      case routes.SUBMITTED_WORKS:
      case routes.SUBMITTED_WORKS_EXAMINED:
      case routes.SUBMITTED_WORKS_STEPS:
      case routes.SUBMITTED_WORKS_STEPS_EXAMINED:
      case routes.SUBMITTED_WORKS_KNOWMIX:
      case routes.SUBMITTED_WORKS_KNOWMIX_EXAMINED:
        return (
          <ul className="f">
            <li
              className={`h-tabs__item ${
                type === "pending" ? "h-tabs__item--active" : ""
              }`}
            >
              <NavLink
                exact
                activeClassName="h-tabs__item--active"
                to={pendingUrl}
                className="h-tabs__link"
              >
                Pending
              </NavLink>
            </li>
            <li
              className={`h-tabs__item ${
                type === "examined" ? "h-tabs__item--active" : ""
              }`}
            >
              <NavLink
                exact
                activeClassName="h-tabs__item--active"
                to={examinedUrl}
                className="h-tabs__link"
              >
                Examined
              </NavLink>
            </li>
          </ul>
        )
      default:
        return ""
    }
  }

  openDialog = () => {
    this.setState({ dialogOpen: true })
  }

  closeDialog = () => {
    this.setState({ dialogOpen: false })
  }

  processSearch = (name) => {
    const {
      location: { search },
      history,
    } = this.props
    const { subjects, grades } = qs.parse(search)

    this.searchParams = {
      q: name || undefined,
      subjects: subjects
        ? _.map(_.split(subjects, ","), _.parseInt)
        : undefined,
      grades: grades ? _.map(_.split(grades, ","), _.parseInt) : undefined,
    }

    const result = qs.stringify(this.searchParams, { arrayFormat: "comma" })

    history.push({
      search: `?${result}`,
    })
  }
  onScroll = async () => {
    if (!this.state.dataReceived) return
    this.getKnowMixes({ offset: this.state.list.length, limit: LIMIT })
  }

  getKnowMixes = async ({ limit, offset } = {}) => {
    const {
      match: { path },
      type,
      tabType,
      entity,
    } = this.props
    this.setState({ dataReceived: false })
    switch (path) {
      case routes.KNOWMIX_LIST:
      case routes.KNOWMIX_LIST_MANAGED:
      case routes.KNOWMIX_LIST_STARTED:
      case routes.KNOWMIX_LIST_STARTED_MANAGED:
      case routes.KNOWMIX_LIST_NOT_STARTED:
      case routes.KNOWMIX_LIST_NOT_STARTED_MANAGED:
      case routes.KNOWMIX_LIST_FINISHED:
      case routes.KNOWMIX_LIST_FINISHED_MANAGED:
        await this.props.getKnowMixList({
          ...this.searchParams,
          managed: type === "managed",
          active: type === "managed",
          limit,
          offset,
          types: tabType || undefined,
          finished:
            path === routes.KNOWMIX_LIST_FINISHED ||
            path === routes.KNOWMIX_LIST_FINISHED_MANAGED,
        })
        this.setState({
          list: this.props.knowmix.list,
          dataReceived: true,
        })
        break
      case routes.KNOWMIX_LIST_MY_GRADES:
      case routes.KNOWMIX_LIST_MY_GRADES_STARTED:
      case routes.KNOWMIX_LIST_MY_GRADES_FINISHED:
        await this.props.getKnowMixList({
          ...this.searchParams,
          managed: false,
          limit,
          offset,
          types: tabType || undefined,
          graded: true,
          finished: path === routes.KNOWMIX_LIST_MY_GRADES_FINISHED,
        })
        this.setState({
          list: this.props.knowmix.list,
          dataReceived: true,
        })
        break
      case routes.SUBMITTED_WORKS:
      case routes.SUBMITTED_WORKS_EXAMINED:
      case routes.SUBMITTED_WORKS_STEPS:
      case routes.SUBMITTED_WORKS_STEPS_EXAMINED:
      case routes.SUBMITTED_WORKS_KNOWMIX:
      case routes.SUBMITTED_WORKS_KNOWMIX_EXAMINED:
        await this.props.worksList({
          ...this.searchParams,
          offset,
          limit,
          entity: entity === "step" ? 1 : 2,
          checked: type !== "pending",
        })
        this.setState({
          list:
            this.props.entity === "step"
              ? this.props.knowmix.works
              : this.props.knowmix.worksKnowmixes,
          dataReceived: true,
        })
    }
  }

  createKnowcred = ({ user, knowmix }) => {
    this.props.history.push(
      generatePath(routes.KNOWMIX_CREATE_KNOWCRED, { knowmixId: 2 }),
      {
        user,
        knowmix,
      }
    )
  }

  deleteKnowmix = async ({ id }) => {
    const limit = this.state.list.length
    try {
      await this.props.deleteKnowmix({ id })
      this.props.clearKnowMixList()
      await this.getKnowMixes({ limit })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }
  showComplaintsModal = (id) => {
    this.setState({ complaintsModal: id })
  }
  hideComplaintsModal = () => {
    this.setState({ complaintsModal: null })
  }

  updateKnowmixes = async () => {
    const limit = this.state.list.length
    try {
      this.props.clearKnowMixList()
      await this.getKnowMixes({ limit })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  render() {
    const {
      match: { path },
      location: { search },
    } = this.props
    const { q: query, grades, subjects } = qs.parse(search)
    const { gridType, entity, type, isMyGrades } = this.props
    const {
      allUrl,
      managedUrl,
      pendingUrl,
      examinedUrl,
      emptyLabel,
      hasButton,
    } = this.composeTabs()
    const { dataReceived, list, dialogOpen } = this.state
    const types = {
      1: "Not started",
      2: "Started",
      3: "Finished",
    }
    const myTypes = {
      1: "Public",
      2: "Not public",
    }

    return (
      <div className="common-page__content common-page__content--static">
        <main className="common-page__main">
          <ListSearchHeader
            // hasButton={hasButton}
            // buttonAction={this.openDialog}
            // buttonLabel="Create knowmix"
            placeholder="Start typing course name"
            search={this.processSearch}
            title={this.getLabelLayout(
              allUrl,
              managedUrl,
              pendingUrl,
              examinedUrl
            )}
            noResults={dataReceived && !list.length}
            noResultsLabel={
              !(query || grades || subjects) ? emptyLabel : "Results not found"
            }
          />
          <div className={`cards-container cards-container--${gridType}`}>
            {list.map((knowmix) => {
              // if (!knowmix.creator) {
              //     knowmix.creator = {
              //         ...this.props.auth.userData,
              //     };
              // }
              switch (gridType) {
                case "square":
                  // const isCreator = knowmix.creator.id === this.props.auth.userData.id;
                  const isCreator = true
                  return (
                    <KnowMixSquareCard
                      knowmix={knowmix}
                      key={knowmix.id}
                      hasMenu={true}
                      updateData={this.updateKnowmixes}
                      bottomLabel={
                        isCreator
                          ? knowmix.product &&
                            !knowmix.product.deleted &&
                            !knowmix.product.blocked
                            ? myTypes[1]
                            : myTypes[2]
                          : types[knowmix.type]
                      }
                      linkUrl={
                        isMyGrades
                          ? generatePath(routes.KNOWMIX_GRADES, {
                              knowmixId: knowmix.id,
                            })
                          : generatePath(routes.KNOWMIX, {
                              knowmixId: knowmix.id,
                            })
                      }
                      menuItems={[
                        !isCreator && {
                          type: "button",
                          action: () => this.showComplaintsModal(knowmix.id),
                          label: "Report",
                          disabled: knowmix.is_reported,
                        },
                        isCreator && {
                          path: generatePath(routes.KNOWMIX_SETTINGS, {
                            knowmixId: knowmix.id,
                          }),
                          label: "Edit course",
                        },
                        isCreator && {
                          type: "h-divider",
                          id: "hd1",
                        },
                        isCreator && {
                          type: "button",
                          action: () => this.deleteKnowmix({ id: knowmix.id }),
                          label: "Delete course",
                        },
                      ]}
                      complaintsModal={this.state.complaintsModal}
                      hideComplaintsModal={this.hideComplaintsModal}
                      userId={this.props.auth.userData.id}
                    />
                  )
                case "inline":
                  return (
                    <KnowMixLineCard
                      knowmix={entity === "step" ? knowmix.knowmix : knowmix}
                      step={entity === "step" ? knowmix : null}
                      type={entity}
                      subType={type}
                      knowMixLink={
                        entity === "step"
                          ? path === routes.SUBMITTED_WORKS_STEPS_EXAMINED
                            ? generatePath(routes.KNOWMIX_GRADES_CHECK, {
                                knowmixId: knowmix.knowmix
                                  ? knowmix.knowmix.id
                                  : 1,
                                learnerId: knowmix.learner
                                  ? knowmix.learner.id
                                  : 1,
                              })
                            : generatePath(routes.KNOWMIX_CHECK, {
                                knowmixId: knowmix.knowmix
                                  ? knowmix.knowmix.id
                                  : 1,
                                checkId: knowmix.id,
                              })
                          : generatePath(routes.KNOWMIX_GRADES_CHECK, {
                              knowmixId: knowmix.id,
                              learnerId: knowmix.learner
                                ? knowmix.learner.id
                                : 1,
                            })
                      }
                      linkUrl={
                        entity === "step"
                          ? generatePath(routes.KNOWMIX_CHECK, {
                              knowmixId: knowmix.knowmix
                                ? knowmix.knowmix.id
                                : 1,
                              checkId: knowmix.id,
                            })
                          : knowmix.knowcred
                            ? generatePath(routes.KNOWCRED_SINGLE, {
                                knowCredId: knowmix.knowcred
                                  ? knowmix.knowcred.id
                                  : 1,
                              })
                            : generatePath(routes.KNOWMIX_GRADES_CHECK, {
                                knowmixId: knowmix.id,
                                learnerId: knowmix.learner
                                  ? knowmix.learner.id
                                  : 1,
                              })
                      }
                      hasButton={type === "pending" || entity === "work"}
                      buttonType={
                        type === "pending"
                          ? "link"
                          : knowmix.knowcred
                            ? "link"
                            : "button"
                      }
                      emptyStyleButton={!!knowmix.knowcred}
                      buttonHandler={(e) =>
                        this.createKnowcred({
                          user: knowmix.learner,
                          knowmix: type === "step" ? knowmix.knowmix : knowmix,
                        })
                      }
                      buttonText={
                        type === "pending"
                          ? entity === "step"
                            ? "Examine Step"
                            : "Examine Work"
                          : knowmix.knowcred
                            ? "Created Cred"
                            : "Create Cred"
                      }
                      key={
                        knowmix.id +
                        `${
                          knowmix.knowmix && knowmix.knowmix.learner
                            ? knowmix.knowmix.learner.id
                            : knowmix.learner
                              ? knowmix.learner.id
                              : 1
                        }`
                      }
                    />
                  )
              }
            })}
          </div>
          {!dataReceived && <Loader />}
        </main>
        <aside className="common-page__sidebar">
          <VerticalMenu menuItems={this.getMenuItems()} />
          {((_) => {
            switch (this.props.match.path) {
              case routes.KNOWMIX_LIST:
              case routes.KNOWMIX_LIST_MANAGED:
              case routes.KNOWMIX_LIST_STARTED:
              case routes.KNOWMIX_LIST_STARTED_MANAGED:
              case routes.KNOWMIX_LIST_NOT_STARTED:
              case routes.KNOWMIX_LIST_NOT_STARTED_MANAGED:
              case routes.KNOWMIX_LIST_FINISHED:
              case routes.KNOWMIX_LIST_FINISHED_MANAGED:
                return (
                  <ProductsListFilter
                    calendarGrades
                    hiddenFields={[
                      "states",
                      "standardSubjects",
                      "contentTypes",
                      "standardsGrades",
                    ]}
                  />
                )
              default:
                return null
            }
          })()}

          {/*<Ads/>*/}
        </aside>
        {/*{dialogOpen && <CourseCreationFormModal isOpen={dialogOpen} onClose={this.closeDialog} />}*/}
      </div>
    )
  }
}

const mapState = ({ auth, knowmix }) => ({ auth, knowmix })
const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      getKnowMixList,
      updateKnowMixList,
      clearKnowMixList,
      worksList,
      deleteKnowmix,
    },
    dispatch
  )

export default connect(mapState, mapDispatch)(withScroll(KnowmixList))
