import React from "react"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import { useSolicitAppointments } from "./SolicitAppointmentsContext"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button"
import * as Yup from "yup"
import { phoneRegEx } from "../../../library/utils/validators"
import { call } from "../../../library/networking/API"
import { APPOINTMENTS } from "../../../library/store/actions/types/calendarTypes"
import { pick } from "lodash"
import ContactLinkPicker, {
  linkProviderNames,
} from "../../../components/v2/inputs/pickers/ContactLinkPicker"
import { Formik } from "formik"
import Form from "../../../components/v2/layout/forms/form/form"
import UserPicker from "../../../components/v2/inputs/pickers/userPicker"
import { FRIENDS } from "../../../library/networking/apiEndpoints"
import { PlainButton } from "../../../components/v2/controls"
import { useSelector } from "react-redux"
import OutsiderInviteeFormModal from "../../bookMeet/component/OutsiderInviteeFormModal"
import { useAlert } from "../../../hooks/useAlert"

const validationSchema = Yup.object({
  title: Yup.string().max(200).required(),
  location: Yup.string().max(200),
  details: Yup.string().max(2000),
  conference_link: Yup.string().url(),
  email: Yup.string().email().required(),
  first_name: Yup.string().max(200).required(),
  last_name: Yup.string().max(200).required(),
  phone: Yup.string().max(50).matches(phoneRegEx, "Invalid phone format"),
  position: Yup.string().max(200),
  institution: Yup.string().max(200),
})

const SolicitAppointmentStep2 = () => {
  const { errorAlert } = useAlert()
  const { isLoggedIn, userData } = useSelector(({ auth }) => auth)
  const {
    data,
    setData,
    setStep,
    user,
    selectedTimezone,
    onClose,
    setSavedLinkType,
    savedLinkType,
    friendInvitees,
    setFriendInvitees,
    outsiderInvitees,
    setOutsiderInvitees,
  } = useSolicitAppointments()

  const [activeOutsiderInvitee, setActiveOutsiderInvitee] = React.useState({
    data: null,
    idx: null,
  })
  const [isOutsiderInviteeFormOpen, setIsOutsiderInviteeFormOpen] =
    React.useState(false)

  const initialValues = {
    title: data.title || "",
    location: data.location || "",
    details: data.details || "",
    conference_link: data.conference_link || "",
    email: data.email || "",
    first_name: data.first_name || "",
    last_name: data.last_name || "",
    phone: data.phone || "",
    outsider_invitees: data.outsider_invitees || [],
    position: data.position || "",
    institution: data.institution || "",
  }

  const onSubmit = async (values) => {
    const {
      title,
      location,
      details,
      conference_link,
      email,
      first_name,
      last_name,
      phone,
      position,
      institution,
    } = values

    const body = {
      creator_id: userData?.id || 0,
      hold_ids: data.holds.map((h) => h.id),
      duration: data?.type,
      title,
      location,
      details,
      conference_link,
      creator_email: email,
      creator_name_first: first_name,
      creator_name_last: last_name,
      phone,
      invitees: [
        ...friendInvitees.map((u) => ({ id: u.id, hold_ids: [] })),
        ...outsiderInvitees.map((i) => ({
          name_first: i.first_name,
          name_last: i.last_name,
          email: i.email,
          id: 0,
          hold_ids: [],
        })),
      ],
      position,
      institution,
    }
    setData((prevState) => ({ ...prevState, ...values }))

    try {
      const response = await call(APPOINTMENTS.SOLICIT.CREATE, body)

      if (response.status === 200) {
        setStep(3)
      }
    } catch (e) {
      errorAlert("Server Error. Unable to create appointment.")
    }
  }

  const inviteeContactLinks = pick(user, Object.keys(linkProviderNames))
  const inviteeHasLinks = Object.values(inviteeContactLinks).some(Boolean)

  const onSaveOutsiderInvitee = (value) => {
    if (activeOutsiderInvitee.idx !== null) {
      setOutsiderInvitees((prevState) => {
        const newState = [...prevState]
        newState[activeOutsiderInvitee.idx] = value
        return newState
      })
      setActiveOutsiderInvitee({ data: null, idx: null })
    } else {
      setOutsiderInvitees((prevState) => [...prevState, value])
    }
  }

  const onDeleteOutsiderInvitee = (idx) => {
    setOutsiderInvitees((prevState) => {
      const newState = [...prevState]
      newState.splice(idx, 1)
      return newState
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur={true}
      validationSchema={validationSchema}
      onBlur={(values) => setData({ ...data, values })}
    >
      {(fk) => {
        return (
          <>
            <DialogTitle className={"text-center"}>
              AI Appointment Details
            </DialogTitle>
            <DialogContent className={"pb15"}>
              <Form isModal>
                <Form.GroupTitle>Invitation</Form.GroupTitle>
                <UserPicker
                  multiple
                  value={friendInvitees}
                  onChange={setFriendInvitees}
                  requestConfig={{
                    fetchConfig: {
                      apiUrl: FRIENDS.LIST,
                      params: {
                        user_id: userData?.id,
                      },
                    },
                    key: "users",
                  }}
                  fieldName={"users"}
                  userLabel={"Invited friends"}
                />
                <Form.Row label={"Invited Non-Members"}>
                  <div className="form__fieldbox">
                    <div className="form__input form__input--select">
                      {outsiderInvitees.map((u, index) => (
                        <div key={index} className={"mb15 f aifs"}>
                          <div className="f1">
                            <p>
                              {u.first_name} {u.last_name}
                            </p>
                            <p className={"color-black-54 font-13"}>
                              {u.email}
                            </p>
                          </div>
                          <div className="f jcfe f1">
                            <PlainButton
                              onClick={() => {
                                setActiveOutsiderInvitee({
                                  data: u,
                                  idx: index,
                                })
                                setIsOutsiderInviteeFormOpen(true)
                              }}
                              actionType={"edit"}
                              className={"mr10"}
                            />
                            <PlainButton
                              onClick={() => onDeleteOutsiderInvitee(index)}
                              actionType={"delete"}
                            />
                          </div>
                        </div>
                      ))}
                      <PlainButton
                        onClick={() => setIsOutsiderInviteeFormOpen(true)}
                        actionType={"add"}
                      />
                      {isOutsiderInviteeFormOpen && (
                        <OutsiderInviteeFormModal
                          invitee={activeOutsiderInvitee.data}
                          onSave={onSaveOutsiderInvitee}
                          isOpen={isOutsiderInviteeFormOpen}
                          onClose={() => setIsOutsiderInviteeFormOpen(false)}
                        />
                      )}
                    </div>
                  </div>
                </Form.Row>
                <Form.Row label={"Title"} name={"title"}>
                  <Form.TextField
                    name={"title"}
                    placeholder={"Type meet title"}
                  />
                </Form.Row>
                <Form.Row label={"Message"} name={"details"}>
                  <Form.TextField
                    name={"details"}
                    placeholder={"Type invitation message"}
                    multiline
                  />
                </Form.Row>

                <Form.GroupTitle>From</Form.GroupTitle>

                <Form.Row label={"First name"} name={"first_name"}>
                  <Form.TextField
                    name={"first_name"}
                    placeholder={"Type your first name"}
                  />
                </Form.Row>
                <Form.Row label={"Last name"} name={"last_name"}>
                  <Form.TextField
                    name={"last_name"}
                    placeholder={"Type your last name"}
                  />
                </Form.Row>
                <Form.Row label={"Position"} name={"position"}>
                  <Form.TextField
                    name={"position"}
                    placeholder={"Type your position"}
                  />
                </Form.Row>
                <Form.Row label={"Institution"} name={"institution"}>
                  <Form.TextField
                    name={"institution"}
                    placeholder={"Type your institution"}
                  />
                </Form.Row>
                <Form.Row label={"Phone"} name={"phone"}>
                  <Form.TextField
                    name={"phone"}
                    placeholder={"Type your phone"}
                  />
                </Form.Row>
                <Form.Row label={"Email"} name={"email"}>
                  <Form.TextField
                    name={"email"}
                    placeholder={"Type your email"}
                  />
                </Form.Row>
                <ContactLinkPicker
                  value={fk.values.conference_link}
                  onChange={(v) => {
                    fk.setFieldValue("conference_link", v)
                  }}
                  error={fk.errors.conference_link}
                />
                <Form.Row
                  label={"Physical Location (optional)"}
                  name={"location"}
                >
                  <Form.TextField
                    name={"location"}
                    placeholder={"Type meet physical location"}
                  />
                </Form.Row>
              </Form>
            </DialogContent>
            <DialogActions>
              <div className="f jcsb full-width">
                <Button
                  onClick={() => {
                    setData((prevState) => ({ ...prevState, ...fk.values }))
                    setStep(1)
                  }}
                  color="primary"
                >
                  Back
                </Button>
                <div className="f">
                  <Button onClick={onClose} color="primary">
                    Cancel
                  </Button>
                  <Button color="primary" onClick={() => fk.handleSubmit()}>
                    Next
                  </Button>
                </div>
              </div>
            </DialogActions>
          </>
        )
      }}
    </Formik>
  )
}

export default SolicitAppointmentStep2
