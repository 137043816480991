import React from "react"
import styled from "@emotion/styled"

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 15px;
`

const List = (props) => {
    return (
        <Container>
            {props.children}
        </Container>
    );
};

export default List;
