import React from "react"
import Tooltip from "@material-ui/core/Tooltip"
import { withStyles } from "@material-ui/core/styles"

const styles = {
  tooltip: {
    backgroundColor: "#fff",
    opacity: 1,
    color: "#49853f",
    padding: 0,
  },
}

const CustomToolTip = (props) => {
    const {content, children, open, close, isOpen, onBlur} = props;
    return (
        <Tooltip
            disableFocusListener
            disableHoverListener
            disableTouchListener
            placement={props.placement || "bottom-start"}
            classes={props.classes}
            interactive
            title={content}
            onOpen={open}
            onClose={close}
            open={isOpen}
            onBlur={onBlur}
        >
            {children}
        </Tooltip>
    );
};

export default withStyles(styles)(CustomToolTip);
