import { store } from "../configureStore"
import axios from "axios"
import Snackbar from "../../../components/snackbarUtilsConfig"
import { getError } from "../../constants/errorTypes"

const apiUrl = `https://${process.env.REACT_APP_API}`

const asyncDispatch = async (action) => {
  store.dispatch(action)
}

export const axiosBaseQuery =
  () =>
  async ({ url, data, actionType }) => {
    try {
      const result = await axios({
        url: apiUrl + url,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getState().auth.userData.token}`,
        },
        method: 'POST',
        data,
      })

      if (actionType) {
        await asyncDispatch({ type: actionType, payload: result.data })
      }

      return { data: result.data }
    } catch ({ response: errResponse }) {
      if (errResponse?.data?.error?.code === 20003) {
        localStorage.clear()
        window.location.href = '/'
      }

      if (errResponse?.data?.error) {
        Snackbar.error(getError(errResponse?.data))
      }

      return {
        error: { status: errResponse?.status, data: errResponse?.data },
      }
    }
  }
