import { useDispatch } from "react-redux"
import { generatePath, useHistory, useParams } from "react-router-dom"
import { useAlert } from "../../hooks/useAlert"
import { useEffect, useState } from "react"
import { getGroup } from "../../library/store/actions/creators/groupsCreators"
import * as routes from "../../library/constants/routes"
import Loader from "../../components/ui/loader"

const withGroupSettingsGuard = (Page) => {
  return (props) => {
    const dispatch = useDispatch()
    const params = useParams()
    const history = useHistory()
    const { errorAlert } = useAlert()
    const [dataReceived, setDataReceived] = useState(false)

    const getData = async () => {
      try {
        const group = await dispatch(
          getGroup({
            id: parseInt(params.groupId, 10),
          })
        )

        if (group.deleted || group.blocked || !group.is_admin) {
          console.log("------REDIRECT-------")
          return history.replace(
            generatePath(routes.GROUP, { groupId: params.groupId })
          )
        }

        console.log(group)

        setDataReceived(true)
      } catch (error) {
        errorAlert(error)
      }
    }

    useEffect(() => {
      getData()
    }, [])

    return dataReceived ? <Page {...props} /> : <Loader />
  }
}

export default withGroupSettingsGuard
