import FormControl from "@material-ui/core/FormControl/FormControl"
import FormGroup from "@material-ui/core/FormGroup/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import Radio from "@material-ui/core/Radio/Radio"

const TrueFalseQuestion = ({ question, index }) => {
  return (
    <>
      <div className="form__fieldset">
        <span className="form__label">Question {index + 1}</span>
        <div className="form__fieldbox">
          <div className="form__masked-value">{question.title}</div>
        </div>
      </div>

      <div className="form__fieldset">
        <span className="form__label">Options and right answer</span>
        <div className="form__fieldbox">
          <div className="form__input">
            <FormControl component="fieldset">
              <FormGroup>
                {question.options.map((item, index) => (
                  <FormControlLabel
                    disabled
                    control={
                      <Radio checked={question.answer_options[0] === index} />
                    }
                    label={item}
                    key={item + index}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </div>
        </div>
      </div>
    </>
  )
}

export default TrueFalseQuestion
