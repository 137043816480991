import React, { useEffect, useState } from "react"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import { generatePath, useParams, useRouteMatch } from "react-router-dom"
import * as routes from "../../../../../../../library/constants/routes"
import {
  KNOWMAP_INCOMING_KNOWMEET,
  KNOWMIX,
} from "../../../../../../../library/constants/routes"
import returnExternalUrl from "../../../../../../../library/utils/returnExternalUrl"
import InsertDriveFile from "@material-ui/icons/InsertDriveFile"
import { formatBytes } from "../../../../../../../library/utils/fileSizeConverter"
import Loader from "../../../../../../../components/ui/loader"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import Dialog from "@material-ui/core/Dialog/Dialog"
import { useDispatch, useSelector } from "react-redux"
import {
  classUknowsCurrentClear,
  getClassUknow,
  getKnowmixStepUknow,
  getUserUknow,
  knowmixStepUknowsCurrentClear,
  uknowsCurrentClear,
} from "../../../../../../../library/store/actions/creators/uknowCreators"
import { useAlert } from "../../../../../../../hooks/useAlert"
import PlainLink from "../../../../../../../components/ui/link"
import { safeFormatDate } from "../../../../../../../library/utils/dateConvert"
import { parseBool } from "../../../../../../../library/utils/parseBool"
import { minutesToString } from "../../../../../../../library/utils/timeConvert"

const UknowDialog = ({
  type = "user",
  activeUknow,
  open,
  handleClose,
  openDoneConfirmation,
}) => {
  const params = useParams()
  const { path } = useRouteMatch()
  const dispatch = useDispatch()
  const { errorAlert } = useAlert()
  const { currentStepUknow, currentClassUknow, currentUserUknow } = useSelector(
    ({ uknow }) => uknow
  )

  const { successAlert } = useAlert()

  const knowmeetId =
    path === KNOWMAP_INCOMING_KNOWMEET
      ? parseInt(params.knowmeetId)
      : activeUknow

  const uknow =
    type === "step"
      ? currentStepUknow
      : type === "class"
        ? currentClassUknow
        : currentUserUknow

  const link =
    document.location.origin +
    generatePath(KNOWMAP_INCOMING_KNOWMEET, {
      knowmeetId,
    })

  const copyLink = async () => {
    await navigator.clipboard.writeText(link)
    successAlert("Link copied to clipboard")
  }

  const [dataReceived, setDataReceived] = useState(false)

  const getUknow = async () => {
    try {
      if (type === "step") {
        await dispatch(getKnowmixStepUknow({ id: knowmeetId }))
      } else if (type === "class") {
        await dispatch(getClassUknow({ id: knowmeetId }))
      } else {
        await dispatch(getUserUknow({ id: knowmeetId }))
      }

      setDataReceived(true)
    } catch (e) {
      if (e.error.code === 20004) {
        errorAlert("You don't have access to this Knowmeet")
      } else {
        errorAlert(e)
      }

      return handleClose(true)
    }
  }

  useEffect(() => {
    getUknow()

    return () => {
      if (type === "step") {
        dispatch(knowmixStepUknowsCurrentClear())
      } else if (type === "class") {
        dispatch(classUknowsCurrentClear())
      } else {
        dispatch(uknowsCurrentClear())
      }
    }
  }, [])

  return (
    <Dialog
      aria-labelledby="create-record-dialog"
      onClose={handleClose}
      open={open}
    >
      <DialogTitle id="alert-dialog-title" className="text-center">
        Meet details
      </DialogTitle>
      <DialogContent>
        {dataReceived ? (
          <div className="f jcc full-width">
            <table className="product-details product-details--invenstory-record">
              <tbody>
                <tr className="product-details__row">
                  <td className="product-details__label">Name</td>
                  <td className="product-details__value">{uknow.name}</td>
                </tr>

                {!!uknow?.knowmix && (
                  <tr className="product-details__row">
                    <td className="product-details__label">KnowMix</td>
                    <td className="product-details__value">
                      <PlainLink
                        to={generatePath(KNOWMIX, {
                          knowmixId: uknow.knowmix.id,
                        })}
                        newTab
                      >
                        {uknow.knowmix.name}
                      </PlainLink>
                    </td>
                  </tr>
                )}

                {!!uknow?.step && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Step</td>
                    <td className="product-details__value">
                      {uknow.step.name}
                    </td>
                  </tr>
                )}

                {!!uknow?.class && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Class</td>
                    <td className="product-details__value">
                      {uknow.class.name}
                    </td>
                  </tr>
                )}

                <tr className="product-details__row">
                  <td className="product-details__label">Cohort size</td>
                  <td className="product-details__value">
                    {uknow.cohort_size}
                  </td>
                </tr>

                <tr className="product-details__row">
                  <td className="product-details__label">Start date</td>
                  <td className="product-details__value">
                    {safeFormatDate(uknow.date_from, { withTime: true })}
                  </td>
                </tr>

                {/*<tr className="product-details__row">*/}
                {/*  <td className="product-details__label">Finish date</td>*/}
                {/*  <td className="product-details__value">*/}
                {/*    {safeFormatDate(uknow.date_to)}*/}
                {/*  </td>*/}
                {/*</tr>*/}

                {!!uknow?.target_date && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Target date</td>
                    <td className="product-details__value">
                      {safeFormatDate(uknow.target_date)}
                    </td>
                  </tr>
                )}

                <tr className="product-details__row">
                  <td className="product-details__label">Auto cancel</td>
                  <td className="product-details__value">
                    {parseBool(uknow?.auto_cancel)}
                  </td>
                </tr>

                <tr className="product-details__row">
                  <td className="product-details__label">Duration</td>
                  <td className="product-details__value">
                    {minutesToString(uknow?.duration)}
                  </td>
                </tr>

                {!!uknow.description && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Description</td>
                    <td className="product-details__value">
                      {uknow.description}
                    </td>
                  </tr>
                )}

                {!!uknow?.space && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Space</td>
                    <td className="product-details__value">
                      {uknow.space.name}
                    </td>
                  </tr>
                )}

                {!!uknow.instructions && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Instructions</td>
                    <td className="product-details__value">
                      {uknow.instructions}
                    </td>
                  </tr>
                )}

                {!!uknow.subject && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Subject</td>
                    <td className="product-details__value">{uknow.subject}</td>
                  </tr>
                )}

                {!!uknow.reminder && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Reminder</td>
                    <td className="product-details__value">{uknow.reminder}</td>
                  </tr>
                )}

                {!!uknow?.users?.length && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Participants</td>
                    <td className="product-details__value">
                      <div>
                        {uknow.users.map((user) => (
                          <div className="mb5" key={user.id}>
                            <PlainLink
                              to={generatePath(routes.USER, {
                                userId: user.id,
                              })}
                            >
                              {user.first_name} {user.last_name}
                            </PlainLink>
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                )}

                {!!uknow?.completed?.length && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Participants</td>
                    <td className="product-details__value">
                      <div>
                        {uknow.completed.map((user) => (
                          <div className="mb5" key={user.id}>
                            <PlainLink
                              to={generatePath(routes.USER, {
                                userId: user.id,
                              })}
                            >
                              {user.first_name} {user.last_name}
                            </PlainLink>
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                )}

                {!!uknow?.not_completed?.length && (
                  <tr className="product-details__row">
                    <td className="product-details__label">
                      Potential participants
                    </td>
                    <td className="product-details__value">
                      <div>
                        {uknow.not_completed.map((user) => (
                          <div className="mb5" key={user.id}>
                            <PlainLink
                              to={generatePath(routes.USER, {
                                userId: user.id,
                              })}
                            >
                              {user.first_name} {user.last_name}
                            </PlainLink>
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                )}

                {!!uknow?.links?.length && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Links</td>
                    <td className="product-details__value">
                      <ul className="text-list">
                        {uknow.links.map((link) => (
                          <li key={link}>
                            <a
                              href={`${returnExternalUrl(link)}`}
                              className="link"
                            >
                              {link}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                )}

                {!!uknow?.blobs?.length && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Attachments</td>
                    <td className="product-details__value">
                      <div className="post__files">
                        {uknow.blobs.map(({ id, name, size, url }) => {
                          return (
                            <a
                              href={url}
                              target="_blank"
                              className="post__file"
                              key={id}
                            >
                              <InsertDriveFile
                                className="post__file-icon"
                                style={{ fontSize: "30px" }}
                              />
                              <div className="post__file-info">
                                <span className="link link--inline">
                                  {name}
                                </span>
                                <span className="post__file-size">
                                  {formatBytes(size)}
                                </span>
                              </div>
                            </a>
                          )
                        })}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <Loader />
        )}
      </DialogContent>
      <DialogActions>
        <div className="f jcsb full-width">
          <div>
            {dataReceived && (
              <>
                {type === "confirmable" && !uknow?.is_completed && (
                  <Button color="primary" onClick={openDoneConfirmation}>
                    Mark as done
                  </Button>
                )}

                {type === "step" && !uknow.step && (
                  <Button color="primary" onClick={copyLink}>
                    Copy link
                  </Button>
                )}
              </>
            )}
          </div>
          <Button color="primary" onClick={handleClose}>
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default UknowDialog
