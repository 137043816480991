import { BOOKMARKS } from "../actions/types/bookmarksTypes"

const initialState = {
  newestBookmark: null,
  currentBookmarkFolders: [],
  list: [],
  isComplete: false,
  folders: [],
  currentFolder: null,
}

export const bookmarksReducer = (state = initialState, action) => {
    switch (action.type) {
        case BOOKMARKS.LIST:
            return {
                ...state,
                list: state.list.length ? [...state.list, ...action.payload] : action.payload,
            };

        case BOOKMARKS.CREATE:
            return {
                ...state,
                newestBookmark: action.payload.id,
            };

        case BOOKMARKS.GET_FOLDERS:
            return {
                ...state,
                currentBookmarkFolders: action.payload,
            };

        case BOOKMARKS.CLEAR_FOLDERS:
            return {
                ...state,
                currentBookmarkFolders: [],
            };

        case BOOKMARKS.CLEAR:
            return {
                ...state,
                list: [],
            };

        case BOOKMARKS.FOLDERS.GET:
            return {
                ...state,
                currentFolder: action.payload,
            };

        case BOOKMARKS.FOLDERS.CREATE:
            return {
                ...state,
            };

        case BOOKMARKS.FOLDERS.UPDATE:
            return {
                ...state,
            };

        case BOOKMARKS.FOLDERS.DELETE:
            return {
                ...state,
            };

        case BOOKMARKS.FOLDERS.BIND:
            return {
                ...state,
            };

        case BOOKMARKS.FOLDERS.UNBIND:
            return {
                ...state,
            };

        case BOOKMARKS.FOLDERS.LIST:
            return {
                ...state,
                folders: action.payload,
            };

        default:
            return state;
    }
};
