import React from "react"
import "./notFound.scss"
import styled from "@emotion/styled"
import { colors } from "../../library/constants/styles/colors"
import * as routes from "../../library/constants/routes"
import PlainLink from "../ui/link/plainLink"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.isGlobal ? "center" : "flex-start")};
  align-items: center;
  padding-top: 20%;
`

const Message = styled.h1`
  color: ${colors.black54};
  font-weight: 400;
  text-align: center;
  font-size: 64px;
  margin-bottom: ${(props) => (props.isGlobal ? "30px" : 0)};
`

const NotFound = ({ entity }) => {
  return (
    <Container isGlobal={!entity}>
      <Message isGlobal={!entity}>Page not found</Message>
      {/*{!entity && (*/}
      <PlainLink to={routes.HOME} fontSize={20} bold="true">
        Back to home
      </PlainLink>
      {/*)}*/}
    </Container>
  )
}

export default NotFound
