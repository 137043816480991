import { useMutation, useQueryClient } from "@tanstack/react-query"
import { call } from "../../library/networking/API"
import {
  mutateInfinite,
  mutateRecord,
  mutationTypes,
} from "../../library/utils/reactQuery"
import { useAlert } from "../useAlert"
import { COMPLAINTS } from "../../library/networking/apiEndpoints"

export const useComplaintsMutation = ({ key, mutationType }) => {
  const queryClient = useQueryClient()

  const { successAlert } = useAlert()

  const complain = useMutation({
    mutationFn: async ({
      data: {
        type,
        user_id,
        group_id,
        post_id,
        comment_id,
        message_id,
        product_id,
        knowcred_id,
        knowmix_id,
      },
    }) => {
      await call(COMPLAINTS.CREATE, {
        type,
        user_id,
        group_id,
        post_id,
        comment_id,
        message_id,
        product_id,
        knowcred_id,
        knowmix_id,
      })
    },
    onSuccess: async (data, variables) => {
      const { updater } = variables
      if (mutationType === mutationTypes.record) {
        await mutateRecord({
          queryClient,
          key,
          recordUpdater: updater,
        })
      }

      if (mutationType === mutationTypes.infinite) {
        await mutateInfinite({
          queryClient,
          key,
          itemUpdater: updater,
        })
      }

      return successAlert(
        "Your report has been sent and will be reviewed as soon as possible"
      )
    },
  })

  return {
    complain,
  }
}
