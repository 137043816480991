import FavouriteOutlined from "@material-ui/icons/FavoriteOutlined"
import FavouriteBorderedOutlined from "@material-ui/icons/FavoriteBorderOutlined"
import ReplyOutlined from "@material-ui/icons/ReplyOutlined"
import postDateConvert from "../../../../../library/utils/postDateConvert"
import React from "react"
import { useReplyCtx } from "../ReplyContext"
import { useCommentCtx } from "../../comments/CommentContext"
import { PlainButton } from "../../../controls"

const ReplyFooter = () => {
  const { onReplyFormOpen } = useCommentCtx()
  const { reply, onLike } = useReplyCtx()

  return (
    <div className="post-reply__footer">
      <div className="post-reply__social-control" onClick={onLike}>
        {reply.is_liked ? (
          <FavouriteOutlined className="color-primary" />
        ) : (
          <FavouriteBorderedOutlined />
        )}
        {!!reply.likes_number && <span>{reply.likes_number}</span>}
      </div>
      <PlainButton onClick={onReplyFormOpen}>
        <ReplyOutlined />
        Reply
      </PlainButton>
      <div className="post-reply__date">{postDateConvert(reply.created)}</div>
    </div>
  )
}

export default ReplyFooter
