import React from "react"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import Button from "@material-ui/core/Button"

const KnowMixGradeForm = (props) => {
  const { grade, comment, changeGrade, changeComment, submit, isStep } = props
  return (
    <div className="box">
      <div className="box__content">
        <p className="box__subheading">Examine {isStep ? "step" : "KnowMix"}</p>
        <ValidatorForm className="form" onSubmit={submit}>
          <div className="form__fieldset">
            <label htmlFor={`field_grade`} className="form__label">
              Grade *
            </label>
            <div className="form__fieldbox">
              <div className="form__input">
                <TextValidator
                  name="grade"
                  id={`field_grade`}
                  margin="normal"
                  fullWidth
                  validators={["required", "maxStringLength:75"]}
                  errorMessages={[
                    "Field is required",
                    "Max length is 75 characters",
                  ]}
                  placeholder={`Type your grade for ${
                    isStep ? "step" : "KnowMix"
                  }`}
                  onChange={(e) => changeGrade(e)}
                  value={grade}
                />
              </div>
            </div>
          </div>
          <div className="form__fieldset">
            <label htmlFor={`field_comment`} className="form__label">
              Comment
            </label>
            <div className="form__fieldbox">
              <div className="form__input">
                <TextValidator
                  name={`field_comment`}
                  id={`field_comment`}
                  margin="normal"
                  fullWidth
                  placeholder={`Type your comment for ${
                    isStep ? "step" : "KnowMix"
                  }`}
                  multiline
                  onChange={(e) => changeComment(e)}
                  value={comment}
                  validators={["maxStringLength:2000"]}
                  errorMessages={["Max length is 2000 characters"]}
                />
              </div>
            </div>
          </div>

          <div className="form__divider"></div>

          <div className="full-width f jcfe mt5">
            <Button color="primary" variant="contained" type="submit">
              Save
            </Button>
          </div>
        </ValidatorForm>
      </div>
    </div>
  )
}

export default KnowMixGradeForm;
