import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../../../../library/constants/routes"
import { getGroupLogo } from "../../../../../../library/utils/getGroupLogo"
import React from "react"
import { usePostCtx } from "../../PostContext"

const SharedGroup = () => {
  const { post } = usePostCtx()

  const group = post.shared_group

  const groupUrl = generatePath(routes.GROUP, { groupId: group.id })

  return (
    <div className="f aic full-width">
      <Link to={groupUrl} className="list-item__img">
        <img src={getGroupLogo(group)} alt="group logo" />
      </Link>
      <div className="list-item__info">
        <Link to={groupUrl} className="link">
          <div className="list-item__name">{group.name}</div>
        </Link>
        {!!group.description && (
          <div className="break-word">
            {group.description.length > 120
              ? group.description.slice(0, 120) + "..."
              : group.description}
          </div>
        )}
      </div>
    </div>
  )
}

export default SharedGroup
