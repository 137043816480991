import React from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  clearGroupSpacesList,
  getGroupSpacesList,
  groupSpacesCreate,
  groupSpacesDelete,
  groupSpacesUpdate,
} from "../../../../../../library/store/actions/creators/groupsCreators"
import SpaceFormModal from "./components/spaceFormModal/spaceFormModal"
import SpaceInfoDialog from "./components/spaceInfoDialog/spaceInfoDialog"
import ListPage from "../../../../../../components/v2/layout/listPage/listPage"
import { useParams } from "react-router-dom"
import SpaceCard from "../../../../../../components/v2/layout/cards/spaceCard"
import useCreator from "../../../../../../hooks/useCreator"

const GroupSettingsSpaces = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const creator = useCreator()

  const { spacesList, spacesListEndReached } = useSelector(
    ({ groups }) => groups
  )

  const onCreateSpace = async (data) => await creator(groupSpacesCreate(data))
  const onEditSpace = async (data) => await creator(groupSpacesUpdate(data))
  const onDeleteSpace = async (id) => await creator(groupSpacesDelete({ id }))

  return (
    <ListPage
      title={"Spaces"}
      creationButtonTitle={"Create Space"}
      noResultsLabel={"You don't have Spaces in this school yet"}
      getList={async ({ name, offset }) =>
        await dispatch(
          getGroupSpacesList({
            school_id: parseInt(params.groupId, 10),
            name,
            offset,
          })
        )
      }
      clearList={() => dispatch(clearGroupSpacesList())}
      list={spacesList}
      listEndReached={spacesListEndReached}
      getListItemComponent={({
        item,
        openEditDialog,
        openDeletionDialog,
        openInfoDialog,
      }) => (
        <SpaceCard
          space={item}
          openDeleteSpace={openDeletionDialog}
          openEditSpace={openEditDialog}
          openSpaceDetails={openInfoDialog}
          key={item.id}
        />
      )}
      getFormDialogComponent={({ open, onClose, activeItem }) => {
        return (
          <SpaceFormModal
            open={open}
            handleClose={onClose}
            onSubmit={!activeItem ? onCreateSpace : onEditSpace}
            activeSpace={activeItem}
            edit={!!activeItem}
          />
        )
      }}
      getDeletionDialogComponent={{
        entityName: "space",
        onDelete: onDeleteSpace,
      }}
      getInfoDialogComponent={({ open, onClose, activeItem }) => (
        <SpaceInfoDialog
          open={open}
          onClose={onClose}
          activeSpace={activeItem}
        />
      )}
    />
  )
}

export default GroupSettingsSpaces
