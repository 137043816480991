import { format } from "date-fns"
import Card from "./card"
import React from "react"
import PlainLink from "../../../ui/link"
import { generatePath } from "react-router-dom"
import { KNOWMIX } from "../../../../library/constants/routes"
import { isDefaultDate } from "../../../../library/utils/dateConvert"

const LearnerKnowmixKnowmeetCard = ({
  knowmeet,
  openKnowmeetDetails,
  openDoneConfirmation,
}) => {
  return (
    <Card
      name={knowmeet.name}
      onNameClick={openKnowmeetDetails}
      infoComponent={
        !!knowmeet.step ||
        !isDefaultDate(knowmeet.target_date) ||
        knowmeet.is_completed
          ? () => (
              <>
                {!!knowmeet.step && (
                  <p className={""}>
                    Course step:{" "}
                    <PlainLink
                      inline
                      newTab
                      to={{
                        pathname: generatePath(KNOWMIX, {
                          knowmixId: knowmeet.step.knowmix_id,
                        }),
                        search: `?stepId=${knowmeet.step.id}`,
                      }}
                    >
                      {knowmeet.step.name}
                    </PlainLink>
                  </p>
                )}
                {!isDefaultDate(knowmeet.target_date) && (
                  <p>
                    Target date:{" "}
                    {format(new Date(knowmeet.target_date), "d MMM yyyy")}
                  </p>
                )}

                {knowmeet.is_completed && <p>Instructions completed</p>}
              </>
            )
          : undefined
      }
      controls={{
        button: !knowmeet.is_completed && {
          label: "Accept",
          action: openDoneConfirmation,
        },
      }}
    />
  )
}

export default LearnerKnowmixKnowmeetCard
