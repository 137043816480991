import React, { Component } from "react"
import "./productsListFilter.scss"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp"
import {
  calendarGrades,
  grades,
  standardsGrades,
  subjects,
} from "../../../../../library/constants/educationalDictionary"
import { productContentTypes } from "../../../../../library/constants/productDictionary"
import FormGroup from "@material-ui/core/FormGroup/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox/Checkbox"
import FormControl from "@material-ui/core/FormControl/FormControl"
import * as _ from "lodash"
import * as qs from "query-string"
import { withRouter } from "react-router-dom"
import { arrayHasItem } from "../../../../../library/utils/arrays"
import { statesAndThames } from "../../../../../library/constants/statesAndThames"
import composeQuery from "../../../../../library/utils/composeQuery"

class ProductsListFilter extends Component {
  state = {
    allFilterValues: {
      grades: [],
      subjects: [],
      contentTypes: [],
      states: [],
      standardSubjects: [],
      standardsGrades: [],
      groups: [],
    },
  }
  refs = []

  componentDidMount() {
    this.setSelectedFilters()
  }

  setSelectedFilters = () => {
    const {
      location: { search },
    } = this.props
    const searchParsed = qs.parse(search, { arrayFormat: "comma" })
    if (search) {
      this.setState({
        allFilterValues: {
          grades: searchParsed.grades
            ? _.map(_.split(searchParsed.grades, ","), _.parseInt)
            : [],
          subjects: searchParsed.subjects
            ? _.map(_.split(searchParsed.subjects, ","), _.parseInt)
            : [],
          contentTypes: searchParsed.contentTypes
            ? _.map(_.split(searchParsed.contentTypes, ","), _.parseInt)
            : [],
          states: searchParsed.states
            ? _.map(_.split(searchParsed.states, ","), _.parseInt)
            : [],
          standardSubjects: searchParsed.standardSubjects
            ? _.map(_.split(searchParsed.standardSubjects, ","), _.parseInt)
            : [],
          standardsGrades: searchParsed.standardsGrades
            ? _.map(_.split(searchParsed.standardsGrades, ","), _.parseInt)
            : [],
          groups: searchParsed.groups
            ? _.map(_.split(searchParsed.groups, ","), _.parseInt)
            : [],
        },
      })
    }
  }

  setFilterValues = (values) => {
    this.setState({
      allFilterValues: values,
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.match.path !== prevProps.match.path) {
      this.refreshFilters()
    } else if (this.props.location.search !== prevProps.location.search) {
      this.setSelectedFilters()
    }
  }

  processFilter = (category, values = []) => {
    switch (category) {
      case "grades":
        this.setState(
          (prevState) => {
            if (values !== prevState.allFilterValues.grades) {
              return {
                allFilterValues: {
                  grades: values,
                  groups: [...prevState.allFilterValues.groups],
                  subjects: [...prevState.allFilterValues.subjects],
                  contentTypes: [...prevState.allFilterValues.contentTypes],
                  standardSubjects: [
                    ...prevState.allFilterValues.standardSubjects,
                  ],
                  states: [...prevState.allFilterValues.states],
                  standardsGrades: [
                    ...prevState.allFilterValues.standardsGrades,
                  ],
                },
              }
            }
          },
          () => {
            this.props.setFilterValues
              ? this.props.setFilterValues(this.state.allFilterValues)
              : this.composeQueryString()
          }
        )

        break

      case "subjects":
        this.setState(
          (prevState) => {
            if (values !== prevState.allFilterValues.subjects) {
              return {
                allFilterValues: {
                  grades: [...prevState.allFilterValues.grades],
                  groups: [...prevState.allFilterValues.groups],
                  subjects: values,
                  contentTypes: [...prevState.allFilterValues.contentTypes],
                  standardSubjects: [
                    ...prevState.allFilterValues.standardSubjects,
                  ],
                  states: [...prevState.allFilterValues.states],
                  standardsGrades: [
                    ...prevState.allFilterValues.standardsGrades,
                  ],
                },
              }
            }
          },
          () => {
            this.props.setFilterValues
              ? this.props.setFilterValues(this.state.allFilterValues)
              : this.composeQueryString()
          }
        )
        break

      case "contentTypes":
        this.setState(
          (prevState) => {
            if (values !== prevState.allFilterValues.contentType) {
              return {
                allFilterValues: {
                  grades: [...prevState.allFilterValues.grades],
                  groups: [...prevState.allFilterValues.groups],
                  subjects: [...prevState.allFilterValues.subjects],
                  contentTypes: values,
                  standardSubjects: [
                    ...prevState.allFilterValues.standardSubjects,
                  ],
                  states: [...prevState.allFilterValues.states],
                  standardsGrades: [
                    ...prevState.allFilterValues.standardsGrades,
                  ],
                },
              }
            }
          },
          () => {
            this.props.setFilterValues
              ? this.props.setFilterValues(this.state.allFilterValues)
              : this.composeQueryString()
          }
        )
        break

      case "states":
        this.setState(
          (prevState) => {
            if (values !== prevState.allFilterValues.states) {
              return {
                allFilterValues: {
                  grades: [...prevState.allFilterValues.grades],
                  groups: [...prevState.allFilterValues.groups],
                  subjects: [...prevState.allFilterValues.subjects],
                  contentTypes: [...prevState.allFilterValues.contentTypes],
                  standardSubjects: [],
                  states: values,
                  standardsGrades: [
                    ...prevState.allFilterValues.standardsGrades,
                  ],
                },
              }
            }
          },
          () => {
            this.props.setFilterValues
              ? this.props.setFilterValues(this.state.allFilterValues)
              : this.composeQueryString()
          }
        )
        break

      case "standardSubjects":
        this.setState(
          (prevState) => {
            if (values !== prevState.allFilterValues.states) {
              return {
                allFilterValues: {
                  grades: [...prevState.allFilterValues.grades],
                  groups: [...prevState.allFilterValues.groups],
                  subjects: [...prevState.allFilterValues.subjects],
                  contentTypes: [...prevState.allFilterValues.contentTypes],
                  standardSubjects: values,
                  states: [...prevState.allFilterValues.states],
                  standardsGrades: [
                    ...prevState.allFilterValues.standardsGrades,
                  ],
                },
              }
            }
          },
          () => {
            this.props.setFilterValues
              ? this.props.setFilterValues(this.state.allFilterValues)
              : this.composeQueryString()
          }
        )
        break

      case "standardsGrades":
        this.setState(
          (prevState) => {
            if (values !== prevState.allFilterValues.standardsGrades) {
              return {
                allFilterValues: {
                  grades: [...prevState.allFilterValues.grades],
                  groups: [...prevState.allFilterValues.groups],
                  subjects: [...prevState.allFilterValues.subjects],
                  contentTypes: [...prevState.allFilterValues.contentTypes],
                  standardSubjects: [
                    ...prevState.allFilterValues.standardSubjects,
                  ],
                  states: [...prevState.allFilterValues.states],
                  standardsGrades: values,
                },
              }
            }
          },
          () => {
            this.props.setFilterValues
              ? this.props.setFilterValues(this.state.allFilterValues)
              : this.composeQueryString()
          }
        )
        break

      case "groups":
        this.setState(
          (prevState) => {
            if (values !== prevState.allFilterValues.groups) {
              return {
                allFilterValues: {
                  groups: values,
                  grades: [...prevState.allFilterValues.grades],
                  subjects: [...prevState.allFilterValues.subjects],
                  contentTypes: [...prevState.allFilterValues.contentTypes],
                  standardSubjects: [
                    ...prevState.allFilterValues.standardSubjects,
                  ],
                  states: [...prevState.allFilterValues.states],
                  standardsGrades: [
                    ...prevState.allFilterValues.standardsGrades,
                  ],
                },
              }
            }
          },
          () => {
            this.props.setFilterValues
              ? this.props.setFilterValues(this.state.allFilterValues)
              : this.composeQueryString()
          }
        )

        break

      default:
        break
    }
  }

  refreshFilters = () => {
    this.setState({
      grades: [],
      subjects: [],
      contentTypes: [],
      states: [],
      standardsGrades: [],
      groups: [],
    })
  }

  // composeQueryString = () => {
  //     const {allFilterValues} = this.state;
  //     const {location: {search}} = this.props;
  //     const {q: name} = qs.parse(search);
  //     const result = qs.stringify({
  //         q: name || undefined,
  //         subjects: allFilterValues.subjects,
  //         grades: allFilterValues.grades,
  //         contentTypes: allFilterValues.contentTypes,
  //         standardSubjects: allFilterValues.standardSubjects,
  //         states: allFilterValues.states,
  //         standardsGrades: allFilterValues.standardsGrades,
  //     }, {arrayFormat: "comma"});
  //     this.props.history.push({
  //         pathname: this.props.pathname ? this.props.pathname : this.props.match.path,
  //         search: `?${result}`,
  //     });
  // };

  composeQueryString = () => {
    const { allFilterValues } = this.state
    const {
      location: { search },
      history,
    } = this.props
    const { q: name } = qs.parse(search)

    const fields = {
      q: name || undefined,
      subjects: allFilterValues.subjects,
      grades: allFilterValues.grades,
      contentTypes: allFilterValues.contentTypes,
      standardSubjects: allFilterValues.standardSubjects,
      states: allFilterValues.states,
      standardsGrades: allFilterValues.standardsGrades,
      groups: allFilterValues.groups,
    }

    composeQuery({
      fields,
      history,
      path: this.props.pathname ? this.props.pathname : this.props.match.path,
    })
  }

  render() {
    const hiddenFields = this.props.hiddenFields || []
    const states = statesAndThames
    const { allFilterValues } = this.state
    const standardSubjects = states
      .filter((s) => {
        return allFilterValues.states.includes(s.id)
      })
      .map((s) => s.subjects)
      .flat()
    const fields = [
      { title: "Subjects", value: "standardSubjects", list: standardSubjects },
      { title: "States", value: "states", list: states },
      { title: "Subjects", value: "subjects", list: subjects },
      {
        title: "Grades",
        value: "grades",
        list: this.props.calendarGrades ? calendarGrades : grades,
      },
      {
        title: "Content type",
        value: "contentTypes",
        list: productContentTypes,
      },
      {
        title: "Standards grades",
        value: "standardsGrades",
        list: standardsGrades,
      },
      !!this.props?.groups?.length && {
        title: "Groups",
        value: "groups",
        list: this.props.groups,
      },
    ].filter(Boolean)
    const shownFields = [
      ...fields.filter((field) => !hiddenFields.includes(field.value)),
    ]
    return (
      <div className="box products-list-filter">
        {shownFields.map((field, index) => {
          if (index !== shownFields.length - 1) {
            return (
              <div key={field.title}>
                <FilterValues
                  title={field.title}
                  controlType="checkbox"
                  list={field.list}
                  filterTrigger={(values) =>
                    this.processFilter(field.value, values)
                  }
                  filteredValue={field.value}
                  match={this.props.match}
                  location={this.props.location}
                />
                <div className="h-divider"></div>
              </div>
            )
          }
          return (
            <FilterValues
              key={field.title}
              title={field.title}
              controlType="checkbox"
              list={field.list}
              filterTrigger={(values) =>
                this.processFilter(field.value, values)
              }
              filteredValue={field.value}
              match={this.props.match}
              location={this.props.location}
              open={this.props.open}
            />
          )
        })}
        {/*<Button onClick={this.resetFilter}>reset</Button>*/}
      </div>
    )
  }
}

class FilterValues extends Component {
  state = {
    open: this.props.open || false,
  }

  componentDidMount() {
    this.getInitState()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.match.path !== prevProps.match.path) {
      this.setState({ open: false })
      this.getInitState()
    } else if (this.props.location.search !== prevProps.location.search) {
      this.getInitState()
    }
  }

  getInitState = () => {
    const {
      list,
      controlType,
      filteredValue,
      location: { search },
    } = this.props
    const {
      grades,
      subjects,
      contentTypes,
      states,
      standardSubjects,
      standardsGrades,
      groups,
    } = qs.parse(search)

    const gradesArray = !!grades ? grades.split(",") : []
    const subjectsArray = !!subjects ? subjects.split(",") : []
    const contentTypesArray = !!contentTypes ? contentTypes.split(",") : []
    const statesArray = !!states ? states.split(",") : []
    const standardSubjectsArray = !!standardSubjects
      ? standardSubjects.split(",")
      : []
    const standardsGradesArray = !!standardsGrades
      ? standardsGrades.split(",")
      : []
    const groupsArray = !!groups ? groups.split(",") : []

    if (controlType === "checkbox") {
      switch (filteredValue) {
        case "subjects":
          list.map((item) => {
            const itemName = String(item.id)
            this.setState({
              [itemName]: arrayHasItem(subjectsArray, String(item.id)),
            })
          })
          if (subjects) {
            this.setState({ open: true })
          }
          break

        case "grades":
          list.map((item) => {
            const itemName = String(item.id)
            this.setState({
              [itemName]: arrayHasItem(gradesArray, String(item.id)),
            })
          })
          if (grades) {
            this.setState({ open: true })
          }
          break

        case "contentTypes":
          list.map((item) => {
            const itemName = String(item.id)
            this.setState({
              [itemName]: arrayHasItem(contentTypesArray, String(item.id)),
            })
          })
          if (contentTypes) {
            this.setState({ open: true })
          }
          break

        case "standardSubjects":
          list.map((item) => {
            const itemName = String(item.id)
            this.setState({
              [itemName]: arrayHasItem(standardSubjectsArray, String(item.id)),
            })
          })
          if (contentTypes) {
            this.setState({ open: true })
          }
          break

        case "states":
          list.map((item) => {
            const itemName = String(item.id)
            this.setState({
              [itemName]: arrayHasItem(statesArray, String(item.id)),
            })
          })
          if (states) {
            this.setState({ open: true })
          }
          break

        case "standardsGrades":
          list.map((item) => {
            const itemName = String(item.id)
            this.setState({
              [itemName]: arrayHasItem(standardsGradesArray, String(item.id)),
            })
          })
          if (states) {
            this.setState({ open: true })
          }
          break

        case "groups":
          list.map((item) => {
            const itemName = String(item.id)
            this.setState({
              [itemName]: arrayHasItem(groupsArray, String(item.id)),
            })
          })

          this.setState({ open: true })

          break

        default:
          return
      }
    }
  }

  composeFilterValues = () => {
    const obj = this.state

    return _.map(
      _.keys(obj).filter((key) => key !== "open" && obj[key] === true),
      _.parseInt
    )
  }

  handleCheckboxChange = (name) => (event) => {
    if (this.props.filteredValue !== "states") {
      this.setState(
        {
          [name]: event.target.checked,
        },
        () => {
          this.props.filterTrigger(this.composeFilterValues())
        }
      )
    } else {
      const s = { ...this.state }
      Object.keys(s).map((k) => {
        if (k !== "open") s[k] = false
      })
      this.setState(
        {
          ...s,
          [name]: event.target.checked,
        },
        () => {
          this.props.filterTrigger(this.composeFilterValues())
        }
      )
    }
  }

  toggleVisibility = () => {
    this.setState({ open: !this.state.open })
  }

  composeList = () => {
    const { controlType, list } = this.props
    switch (controlType) {
      case "checkbox":
        return (
          <FormControl component="fieldset" className="">
            <FormGroup>
              {list.map((item) => {
                let itemName = `${item.id}`
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state[itemName]}
                        onChange={this.handleCheckboxChange(itemName)}
                        value={String(item.id)}
                      />
                    }
                    label={
                      <span
                        className="break-word"
                        style={{
                          maxWidth: "175px",
                          display: "block",
                        }}
                      >
                        {item.name}
                      </span>
                    }
                    key={item.id}
                  />
                )
              })}
            </FormGroup>
          </FormControl>
        )

      default:
        return null
    }
  }

  render() {
    const { open } = this.state
    const { title } = this.props

    return (
      <div className="products-list-filter__category">
        <div
          className="products-list-filter__title"
          onClick={this.toggleVisibility}
        >
          <span>{title}</span>
          {!open ? (
            <KeyboardArrowDown className="color-black-38" />
          ) : (
            <KeyboardArrowUp className="color-black-38" />
          )}
        </div>
        {open && (
          <div className="products-list-filter__controls">
            {this.composeList()}
          </div>
        )}
      </div>
    )
  }
}

export default withRouter(ProductsListFilter)
