import React, { Component } from "react"
import Dialog from "@material-ui/core/Dialog/Dialog"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import {
  productContentTypes,
  productFileTypes,
  productPlatforms,
} from "../../../../library/constants/productDictionary"
import {
  grades as gradesList,
  subjects as subjectsList,
} from "../../../../library/constants/educationalDictionary"
import { withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { getArrayByIds } from "../../../../library/utils/arrays"
import getErrorText from "../../../../library/constants/errorTypes"
import { fileCall } from "../../../../library/networking/API"
import Loader from "../../../../components/ui/loader"
import {
  clearCurrentRecord,
  createInvenstoryRecord,
  getInvenstoryRecord,
  updateInvenstoryRecord,
} from "../../../../library/store/actions/creators/invenstoryCreators"
import { urlValidation } from "../../../../library/utils/validators"
import FileProcessor from "../../../../components/formComponents/fileLoader/fileProcessor"
import FormRadioGroup from "../../../../components/formComponents/formRadioGroup"
import GroupPicker from "../../../../components/v2/inputs/pickers/groupPicker"
import StaticDataSelect from "../../../../components/v2/inputs/select/staticDataSelect"
import StandardPicker from "../../../../components/v2/inputs/pickers/standardPicker"

class CreateEditInvenstoryRecord extends Component {
  state = {
    name: "",
    createFrom: "1",
    group: "",
    groupError: false,
    groupId: "",
    groupIdError: false,
    contentType: "",
    contentTypeError: false,
    subjects: [],
    grades: [],
    url: "",
    version: "",
    platforms: [],
    fileTypes: [],
    standards: [],
    dataReceived: !this.props.edit,
    image: "",
    openAvatarUpdate: false,
  }

  async componentDidMount() {
    ValidatorForm.addValidationRule("isLink", (value) => urlValidation(value))
    try {
      if (this.props.edit && this.props.recordId) {
        await this.props.getInvenstoryRecord({ id: this.props.recordId })
        this.setState({
          name: this.props.invenstory.currentRecord.name,
          contentType: this.props.invenstory.currentRecord.content_type,
          subjects: getArrayByIds(
            subjectsList,
            this.props.invenstory.currentRecord.subjects
          ),
          grades: getArrayByIds(
            gradesList,
            this.props.invenstory.currentRecord.grades
          ),
          url: this.props.invenstory.currentRecord.url,
          version: this.props.invenstory.currentRecord.version,
          platforms: getArrayByIds(
            productPlatforms,
            this.props.invenstory.currentRecord.platforms
          ),
          fileTypes: getArrayByIds(
            productFileTypes,
            this.props.invenstory.currentRecord.file_types
          ),
          standards: this.props.invenstory.currentRecord.standards,
          chosenStandards: this.props.invenstory.currentRecord.standards.map(
            (s) => {
              return { label: s.name, value: s.id }
            }
          ),
          dataReceived: true,
          image: {
            url: this.props.invenstory.currentRecord.image,
            isUpload: true,
          },
        })
      }
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  componentWillUnmount() {
    this.props.clearCurrentRecord()
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    })
  }

  handleChangeRequired = (name, event) => {
    switch (name) {
      case "contentType":
        this.setState({
          contentTypeError: event.target.value.length === 0,
          contentType: event.target.value,
        })
        break

      default:
        this.setState({
          [name]: event.target.value,
        })
        break
    }
  }

  handleClose = async () => {
    this.props.onClose()
  }

  validateForm = () => {
    const { contentType } = this.state
    if (contentType === "") {
      this.setState({ contentTypeError: true })
    } else {
      this.setState({ contentTypeError: false })
    }
  }

  processRecordCreate = async () => {
    const {
      name,
      fileTypes,
      grades,
      platforms,
      subjects,
      url,
      version,
      createFrom,
      group,
      groupError,
      contentType,
      contentTypeError,
      image,
      standards,
    } = this.state

    if (
      ((!group || groupError) && createFrom === "2") ||
      !contentType ||
      contentTypeError
    ) {
      if (!group && createFrom === "2") {
        this.setState({ groupError: true })
      }

      if (!contentType) {
        this.setState({ contentTypeError: true })
      }

      return
    }

    const data = {
      name,
      standards: standards.map((s) => s.id),
      group_id: group?.id || undefined,
      file_types: fileTypes.map((f) => f.id),
      grades: grades.map((g) => g.id),
      platforms: platforms.map((p) => p.id),
      subjects: subjects.map((s) => s.id),
      url,
      version,
      content_type: contentType.id,
      image: image.url,
    }

    try {
      if (this.props.edit) {
        await this.props.updateInvenstoryRecord({
          ...data,
          id: this.props.recordId,
        })
      } else {
        await this.props.createInvenstoryRecord(data)
      }

      await this.props.updateData()
      await this.handleClose()
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  handleClickOpen = () => {
    document.getElementById("img-input").click()
  }

  clearFileInput = () => {
    this.imgUploader.value = ""
  }

  processFile = (e) => {
    const file = e.target.files[0]
    this.setState(
      {
        image: {},
      },
      () => {
        this.setState({
          image: {
            file: file,
            url: URL.createObjectURL(file),
          },
        })
      }
    )
  }

  uploadFile = async (type, file, onUploadProgress) => {
    const formData = new FormData()
    console.log(file, type)
    const token = this.props.auth.userData.token
    formData.append("file", file)
    formData.append("type", type)

    const response = await fileCall(formData, type, onUploadProgress, token)

    const json = await response.data

    if (response.status === 200) {
      const { url } = json
      this.setState({
        image: {
          ...this.state.image,
          url,
        },
      })
    } else {
      this.props.enqueueSnackbar(getErrorText(json.error.code), {
        variant: "error",
      })
    }
  }

  deleteFile = () => {
    this.setState({
      image: {},
    })
    this.clearFileInput()
  }

  render() {
    const {
      name,
      createFrom,
      group,
      groupError,
      contentType,
      contentTypeError,
      subjects,
      grades,
      url,
      version,
      standards,
      platforms,
      fileTypes,
      dataReceived,
    } = this.state

    console.log(this.state)
    return (
      <Dialog
        aria-labelledby="create-record-dialog"
        onClose={this.handleClose}
        open={this.props.open}
        maxWidth="md"
      >
        <ValidatorForm onSubmit={this.processRecordCreate}>
          <DialogTitle id="alert-dialog-title" className="text-center">
            {this.props.edit ? "Edit record" : "New record"}
          </DialogTitle>
          <DialogContent>
            {dataReceived ? (
              <div className="form form--modal">
                <div className="form__fieldset">
                  <label htmlFor="name" className="form__label">
                    Record name *
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="name"
                        name="name"
                        value={name}
                        placeholder="Type record name"
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        validators={[
                          "required",
                          "minStringLength:3",
                          "maxStringLength:200",
                        ]}
                        errorMessages={[
                          "Field is required",
                          "Min length is 3 characters",
                          "Max length is 200 characters",
                        ]}
                        withRequiredValidator
                        onChange={this.handleChange("name")}
                      />
                    </div>
                  </div>
                </div>

                <div className="form__fieldset">
                  <label className="form__label grid-form__label">Image </label>
                  <div className="form__fieldbox">
                    <div className="form__attachments">
                      <div
                        style={{
                          maxWidth: "30%",
                          marginLeft: "7%",
                        }}
                      >
                        {this.state.image.url && (
                          <FileProcessor
                            fileToProgress={{
                              file: this.state.image.file,
                              type: 2,
                              isUpload: this.state.image.isUpload,
                            }}
                            file={this.state.image}
                            type={"image"}
                            uploadFile={this.uploadFile}
                            deleteAttachment={this.deleteFile}
                          />
                        )}
                      </div>

                      <input
                        type="file"
                        accept="image/gif, image/png, image/jpeg, image/bmp, image/webp"
                        className="hidden"
                        id={"img-input"}
                        ref={(ref) => (this.imgUploader = ref)}
                        onChange={(e) => this.processFile(e, "image")}
                      />
                      <Button
                        color="primary"
                        variant="outlined"
                        htmlFor="image"
                        onClick={() => this.handleClickOpen()}
                      >
                        {!this.state.image.url ? "Upload" : "Change"} image
                      </Button>
                    </div>
                  </div>
                </div>

                {!this.props.edit && (
                  <>
                    <FormRadioGroup
                      label={"Create from"}
                      onChange={(v) => this.setState({ createFrom: v })}
                      options={[
                        { label: "Yourself", value: "1" },
                        { label: "Group", value: "2" },
                      ]}
                      name="createFrom"
                      value={createFrom}
                    />

                    {createFrom === "2" && (
                      <GroupPicker
                        value={group}
                        hasError={groupError}
                        required
                        onChange={(g) =>
                          this.setState({
                            group: g,
                            groupError: !g,
                          })
                        }
                        getRequestConfig={(config) => {
                          return {
                            ...config,
                            fetchConfig: {
                              ...config.fetchConfig,
                              params: {
                                ...config.fetchConfig.params,
                                types: [4, 5],
                                managed: true,
                              },
                            },
                          }
                        }}
                      />
                    )}
                  </>
                )}

                <StaticDataSelect
                  required
                  fieldName={"contentType"}
                  entityName={"content type"}
                  data={productContentTypes}
                  value={contentType}
                  hasError={contentTypeError}
                  onChange={(v) =>
                    this.setState({ contentType: v, contentTypeError: !v })
                  }
                />

                <StandardPicker
                  value={standards}
                  onChange={(v) => this.setState({ standards: v })}
                />

                <StaticDataSelect
                  multiple
                  fieldName={"subjects"}
                  entityName={"subjects"}
                  data={subjectsList}
                  value={subjects}
                  onChange={(v) => this.setState({ subjects: v })}
                />

                <StaticDataSelect
                  multiple
                  fieldName={"grades"}
                  entityName={"grades"}
                  data={gradesList}
                  value={grades}
                  onChange={(v) => this.setState({ grades: v })}
                />

                <div className="form__fieldset">
                  <label htmlFor="url" className="form__label">
                    URL
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="url"
                        name="url"
                        value={url}
                        placeholder="Type URL"
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        validators={["isLink", "maxStringLength:250"]}
                        errorMessages={[
                          "Invalid URL",
                          "Max length is 250 characters",
                        ]}
                        onChange={this.handleChange("url")}
                      />
                    </div>
                  </div>
                </div>

                <StaticDataSelect
                  multiple
                  fieldName={"fileTypes"}
                  entityName={"file types"}
                  data={productFileTypes}
                  value={fileTypes}
                  onChange={(v) => this.setState({ fileTypes: v })}
                />

                <StaticDataSelect
                  multiple
                  fieldName={"platforms"}
                  entityName={"platforms"}
                  data={productPlatforms}
                  value={platforms}
                  onChange={(v) => this.setState({ platforms: v })}
                />

                <div className="form__fieldset">
                  <label htmlFor="version" className="form__label">
                    Version
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="version"
                        name="version"
                        value={version}
                        placeholder="Type version"
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        validators={["maxStringLength:50"]}
                        errorMessages={["Max length is 50 characters"]}
                        onChange={this.handleChange("version")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary" onClick={this.validateForm}>
              Save
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    )
  }
}

const mapStateToProps = ({ auth, groups, invenstory }) => ({
  auth,
  groups,
  invenstory,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getInvenstoryRecord,
      createInvenstoryRecord,
      updateInvenstoryRecord,
      clearCurrentRecord,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(CreateEditInvenstoryRecord)))
