const TextAnswerQuestion = ({ question, index }) => {
  return (
    <>
      <div className="form__fieldset">
        <span className="form__label">Question {index + 1}</span>
        <div className="form__fieldbox">
          <div className="form__masked-value">{question.title}</div>
        </div>
      </div>

      <div className="form__fieldset">
        <span className="form__label">Right answer</span>
        <div className="form__fieldbox">
          <div className="form__masked-value">{question.answer_text}</div>
        </div>
      </div>
    </>
  )
}

export default TextAnswerQuestion
