import React from "react"
import { TextValidator } from "react-material-ui-form-validator"
import DeleteForeverOutlined from "@material-ui/icons/DeleteForeverOutlined"
import AddOutlined from "@material-ui/icons/AddOutlined"

const FillBlankQuestionConstructor = (props) => {
  const canAddPart = (type) => {
    if (props.parts.length === 0) return true

    if (type === 2) {
      return (
        props.parts[props.parts.length - 1].type === 1 &&
        props.parts.filter((item) => item.type === 2).length < 2
      )
    }

    if (type === 1) {
      return (
        props.parts[props.parts.length - 1].type === 2 &&
        props.parts.filter((item) => item.type === 1).length < 1
      )
    }
  }

  return (
    <>
      <div className="form__fieldset">
        <label className="form__label">Question constructor *</label>
        <div className="form__fieldbox">
          <div className="form__input-group" style={{ width: 330 }}>
            {props.parts.map((item, index) => {
              if (item.type === 2) {
                return (
                  <div
                    className="form__controllable-input"
                    key={"text-" + index}
                  >
                    <div className="form__input">
                      <TextValidator
                        name={`text-part-${index + String(new Date())}`}
                        value={item.text}
                        placeholder={"Type text part"}
                        label={"Text part"}
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        multiline
                        validators={["required", "maxStringLength:250"]}
                        errorMessages={[
                          "Field is required",
                          "Max length is 250 characters",
                        ]}
                        onChange={(event) => {
                          props.handlePartChange(index, event.target.value)
                        }}
                      />
                    </div>
                    <button
                      className="form__manage-input"
                      type="button"
                      onClick={() => props.deletePart(index)}
                    >
                      <DeleteForeverOutlined /> Delete
                    </button>
                  </div>
                )
              } else {
                return (
                  <div
                    className="form__controllable-input"
                    key={"blank-" + index}
                  >
                    <div className="form__input">
                      <TextValidator
                        name={`blank-part-${index + String(new Date())}`}
                        value={item.text}
                        placeholder={"Type blank part answer"}
                        label={"Blank part"}
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        multiline
                        validators={["required", "maxStringLength:250"]}
                        errorMessages={[
                          "Field is required",
                          "Max length is 250 characters",
                        ]}
                        onChange={(event) => {
                          props.handlePartChange(index, event.target.value)
                        }}
                      />
                    </div>
                    <button
                      className="form__manage-input"
                      type="button"
                      onClick={() => props.deletePart(index)}
                    >
                      <DeleteForeverOutlined /> Delete
                    </button>
                  </div>
                )
              }
            })}
          </div>
          {canAddPart(1) && (
            <button
              className="form__add-input"
              type="button"
              onClick={() => props.addPart(1)}
            >
              <AddOutlined /> Add blank part
            </button>
          )}
          {canAddPart(2) && (
            <button
              className="form__add-input"
              type="button"
              onClick={() => props.addPart(2)}
            >
              <AddOutlined /> Add text part
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export default FillBlankQuestionConstructor;
