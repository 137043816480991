import { useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { nestAPI } from "../../../library/networking/API"
import { useSelector } from "react-redux"

export const useHowTo = ({
  categories = false,
  articles = false,
  article = false,
}) => {
  const { categoryId, articleId } = useParams()
  const { userData } = useSelector((state) => state.auth)

  const categoriesQuery = useQuery({
    queryKey: ["how-to-categories"],
    queryFn: async () => {
      const response = await fetch(
        nestAPI + "article-categories/how_to_category",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userData.token,
          },
        }
      )
      return await response.json()
    },
    enabled: categories,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })

  const articlesQuery = useQuery({
    queryKey: ["how-to-category", { categoryId }],
    queryFn: async () => {
      const response = await fetch(
        nestAPI +
          `articles/how_to${categoryId !== "all" ? "?term=" + categoryId : ""}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userData.token,
          },
        }
      )
      return await response.json()
    },
    enabled: articles,
    refetchOnWindowFocus: false,
    staleTime: 10 * 60 * 1000,
  })

  const articleQuery = useQuery({
    queryKey: ["how-to-article", { articleId }],
    queryFn: async () => {
      const response = await fetch(nestAPI + "articles/how_to/" + articleId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userData.token,
        },
      })
      return await response.json()
    },
    enabled: article,
    refetchOnWindowFocus: false,
    staleTime: 10 * 60 * 1000,
  })

  return {
    categoriesQuery,
    articlesQuery,
    articleQuery,
  }
}
