import React, { useEffect, useState } from "react"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import Dialog from "@material-ui/core/Dialog/Dialog"
import Button from "@material-ui/core/Button/Button"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Select from "@material-ui/core/Select/Select"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText"
import FormControl from "@material-ui/core/FormControl/FormControl"
import FormGroup from "@material-ui/core/FormGroup/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox/Checkbox"
import { AddOutlined } from "@material-ui/icons"
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup"
import Radio from "@material-ui/core/Radio/Radio"
import { withStyles } from "@material-ui/core"
import DeleteForeverOutlined from "@material-ui/icons/DeleteForeverOutlined"
import { withSnackbar } from "notistack"
import QuestionsInfoModal from "./QuestionInfo"
import InfoIcon from "@material-ui/icons/Info"
import MatchingQuestionConstructor from "./MatchingQuestionConstructor"
import FillBlankQuestionConstructor from "./FillBlankQuestionConstructor"
import { questionTypes } from "../../../../../../library/constants/knowmix"
import { getObjectById } from "../../../../../../library/utils/arrays"
import { colors } from "../../../../../../library/constants/styles/colors"

const CustomDialog = withStyles((theme) => ({
  paper: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "820px",
    },
  },
}))(Dialog)

const QuestionForm = (props) => {
  const [values, setValues] = useState({
    title: "",
    type: 1,
    optionsObjects: [],
    options: [],
    answer_options: [],
    answer_text: "",
  })

  const [errors, setErrors] = useState({
    typeError: false,
  })

  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)

  useEffect(() => {
    if (props.question) {
      switch (props.question.type) {
        case 1:
        case 2:
          const optionsObjects = props.question.options.map((item, index) => {
            return {
              title: item,
              checked: props.question.answer_options.includes(index),
            }
          })

          setValues({
            ...values,
            title: props.question.title,
            type: props.question.type,
            optionsObjects,
            options: props.question.options,
            answer_options: props.question.answer_options,
          })
          break

        case 3:
          setValues({
            ...values,
            title: props.question.title,
            type: props.question.type,
            answer_text: props.question.answer_text,
          })
          break

        case 4:
          setValues({
            ...values,
            title: props.question.title,
            type: props.question.type,
          })
          setQuestionParts(props.question.parts)
          break

        case 5:
          setValues({
            ...values,
            title: props.question.title,
            type: props.question.type,
          })

          setMatchingQuestions(props.question.questions)
          setMatchingAnswers(props.question.answers)
          break

        default:
          return
      }
    }
  }, [props.question])

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value })
  }

  const handleChangeRequired = (name, event) => {
    setValues({ ...values, [name]: event.target.value })

    switch (name) {
      case "type":
        setErrors({ ...errors, typeError: event.target.value.length === 0 })
        break

      default:
        return
    }
  }

  const handleClose = async () => {
    props.handleClose()
  }

  const handleRadioOption = (index, type) => (event) => {
    const optionsObjects = values.optionsObjects

    if (type === "radio") {
      setValues({ ...values, answer_options: [Number(event.target.value)] })
    } else {
      optionsObjects[index].title = event.target.value
      setValues({ ...values, optionsObjects })
    }
  }

  const handleCheckboxOption = (index, type) => (event) => {
    const optionsObjects = values.optionsObjects

    if (type === "checkbox") {
      optionsObjects[index].checked = event.target.checked
    } else {
      optionsObjects[index].title = event.target.value
    }

    setValues({ ...values, optionsObjects })
  }

  const addAnswerOption = () => {
    const optionsObjects = [
      ...values.optionsObjects,
      { title: "", checked: false },
    ]
    setValues({ ...values, optionsObjects })
  }

  const deleteOption = (index) => {
    const optionsUpdated = values.optionsObjects.filter((o, i) => i !== index)
    setValues({ ...values, optionsObjects: optionsUpdated })
  }

  const valuesValidate = (arr) => {
    let err = false
    arr.map((item) => {
      if (arr.filter((el) => el === item).length > 1) err = true
    })
    return err
  }

  const [matchingQuestions, setMatchingQuestions] = useState([])
  const [matchingAnswers, setMatchingAnswers] = useState([])

  const handleChangeMatching = (event, index) => {
    setMatchingQuestions((prevQuestions) => {
      return prevQuestions.map((item, i) =>
        i === index ? { ...item, right_answer: event.target.value } : item
      )
    })
  }

  const handleQuestionChange = (index, val) => {
    setMatchingQuestions((prevQuestions) => {
      return prevQuestions.map((item, i) =>
        i === index ? { ...item, value: val } : item
      )
    })
  }

  const addQuestion = () => {
    setMatchingQuestions((prevQuestions) => {
      return [...prevQuestions, { value: "", right_answer: "" }]
    })
  }

  const deleteQuestion = (index) => {
    setMatchingQuestions((prevQuestions) =>
      prevQuestions.filter((item, i) => i !== index)
    )
  }

  const handleAnswerChange = (index, val) => {
    setMatchingAnswers((prevAnswers) => {
      return prevAnswers.map((item, i) =>
        i === index ? { ...item, value: val } : item
      )
    })
  }

  const addAnswer = () => {
    setMatchingAnswers((prevAnswers) => {
      return [...prevAnswers, { value: "" }]
    })
  }

  const deleteAnswer = (index) => {
    setMatchingAnswers((prevQuestions) =>
      prevQuestions.filter((item, i) => i !== index)
    )
  }

  const [questionParts, setQuestionParts] = useState([])

  const addQuestionPart = (type) => {
    setQuestionParts((prevState) => {
      return [...prevState, { type, text: "" }]
    })
  }

  const deleteQuestionPart = (index) => {
    setQuestionParts((prevState) => prevState.filter((item, i) => index !== i))
  }

  const handleQuestionPartChange = (index, text) => {
    setQuestionParts((prevState) => {
      return prevState.map((item, i) =>
        i === index ? { ...item, text } : item
      )
    })
  }

  const saveQuestion = async () => {
    const { title, type, answer_text } = values
    let options = []

    switch (type) {
      case 1:
        options = values.optionsObjects.map((item) => item.title)
        if (valuesValidate(options))
          return props.enqueueSnackbar("Answers must be unique", {
            variant: "error",
          })
        await props.saveQuestion({
          id: props.question ? props.question.id : undefined,
          title,
          type,
          options,
          answer_options: values.answer_options,
        })
        break

      case 2:
        options = values.optionsObjects.map((item) => item.title)
        if (valuesValidate(options))
          return props.enqueueSnackbar("Answers must be unique", {
            variant: "error",
          })
        let answer_options = values.optionsObjects
          .map((item, index) => (item.checked ? index : false))
          .filter((item) => parseInt(item) === item)
        await props.saveQuestion({
          id: props.question ? props.question.id : undefined,
          title,
          type,
          options,
          answer_options,
        })
        break

      case 3:
        await props.saveQuestion({
          id: props.question ? props.question.id : undefined,
          title,
          type,
          answer_text: answer_text ? answer_text : "Any",
        })
        break

      case 4:
        await props.saveQuestion({
          id: props.question ? props.question.id : undefined,
          title,
          type,
          parts: questionParts,
        })
        break

      case 5:
        await props.saveQuestion({
          id: props.question ? props.question.id : undefined,
          title,
          type,
          questions: matchingQuestions,
          answers: matchingAnswers,
        })
        break

      default:
        return
    }

    await handleClose()
  }

  const composeAnswersTemplate = () => {
    switch (values.type) {
      case 1:
        return (
          <div className="form__fieldset">
            <label htmlFor="questions" className="form__label">
              Options and right answer *
            </label>
            <div className="form__fieldbox">
              <div className="form__input-group">
                {!!values.optionsObjects.length && (
                  <FormControl component="fieldset" fullWidth>
                    <RadioGroup
                      aria-label="option"
                      name="option"
                      value={String(values.answer_options[0])}
                      onChange={handleRadioOption(undefined, "radio")}
                    >
                      {values.optionsObjects.map((item, index) => (
                        <div className="form__controllable-input" key={index}>
                          <FormControlLabel
                            key={index}
                            value={String(index)}
                            control={
                              <Radio color="primary" disableRipple={true} />
                            }
                            className="form__radio-text-input mr35"
                            label={
                              <div className="form__input">
                                <TextValidator
                                  id="answer_text"
                                  name={`answer_text_${index + String(new Date())}`}
                                  value={item.title}
                                  placeholder="Type option"
                                  margin="normal"
                                  fullWidth
                                  multiline
                                  validators={[
                                    "required",
                                    "maxStringLength:500",
                                  ]}
                                  errorMessages={[
                                    "Field is required",
                                    "Max length is 500 characters",
                                  ]}
                                  withRequiredValidator
                                  onChange={handleRadioOption(index, "text")}
                                />
                              </div>
                            }
                            labelPlacement="end"
                          />
                          <button
                            type="button"
                            className="form__manage-input"
                            onClick={() => deleteOption(index)}
                          >
                            <DeleteForeverOutlined /> Delete
                          </button>
                        </div>
                      ))}
                    </RadioGroup>
                  </FormControl>
                )}
                {values.optionsObjects.length < 6 && (
                  <button
                    className="form__add-input"
                    type="button"
                    onClick={addAnswerOption}
                  >
                    <AddOutlined />
                    Add option
                  </button>
                )}
              </div>
            </div>
          </div>
        )

      case 2:
        return (
          <div className="form__fieldset">
            <label htmlFor="questions" className="form__label">
              Options and right answer *
            </label>
            <div className="form__fieldbox">
              <div className="form__input-group">
                {!!values.optionsObjects.length && (
                  <FormControl component="fieldset" fullWidth>
                    <FormGroup fullWidth>
                      {values.optionsObjects.map((item, index) => (
                        <div className="form__controllable-input" key={index}>
                          <FormControlLabel
                            className="form__radio-text-input mr35"
                            control={
                              <Checkbox
                                checked={Boolean(
                                  values.optionsObjects[index].checked
                                )}
                                onChange={handleCheckboxOption(
                                  index,
                                  "checkbox"
                                )}
                              />
                            }
                            label={
                              <div className="form__input">
                                <TextValidator
                                  id="answer_text"
                                  name={`answer_text_${index + String(new Date())}`}
                                  value={item.title}
                                  placeholder="Type option"
                                  className="edit-form__input edit-form__input--question"
                                  margin="normal"
                                  fullWidth
                                  multiline
                                  validators={[
                                    "required",
                                    "maxStringLength:500",
                                  ]}
                                  errorMessages={[
                                    "Field is required",
                                    "Max length is 500 characters",
                                  ]}
                                  withRequiredValidator
                                  onChange={handleCheckboxOption(index, "text")}
                                />
                              </div>
                            }
                            key={index}
                          />
                          <button
                            type="button"
                            className="form__manage-input"
                            onClick={() => deleteOption(index)}
                          >
                            <DeleteForeverOutlined /> Delete
                          </button>
                        </div>
                      ))}
                    </FormGroup>
                  </FormControl>
                )}
                {values.optionsObjects.length < 6 && (
                  <button
                    className="form__add-input"
                    type="button"
                    onClick={addAnswerOption}
                  >
                    <AddOutlined />
                    Add option
                  </button>
                )}
              </div>
            </div>
          </div>
        )

      case 3:
        return (
          <div className="form__fieldset">
            <label htmlFor="answer_text" className="form__label">
              Right answer
            </label>
            <div className="form__fieldbox">
              <div className="form__input">
                <TextValidator
                  id="answer_text"
                  name="answer_text"
                  value={values.answer_text}
                  placeholder="Type right answer"
                  margin="normal"
                  fullWidth
                  multiline
                  validators={["maxStringLength:5000"]}
                  errorMessages={["Max length is 5000 characters"]}
                  withRequiredValidator
                  onChange={handleChange("answer_text")}
                />
              </div>
            </div>
          </div>
        )

      case 4:
        return (
          <FillBlankQuestionConstructor
            parts={questionParts}
            addPart={addQuestionPart}
            deletePart={deleteQuestionPart}
            handlePartChange={handleQuestionPartChange}
          />
        )

      case 5:
        return (
          <MatchingQuestionConstructor
            questions={matchingQuestions}
            handleQuestionChange={handleQuestionChange}
            deleteQuestion={deleteQuestion}
            addQuestion={addQuestion}
            answers={matchingAnswers}
            handleAnswerChange={handleAnswerChange}
            deleteAnswer={deleteAnswer}
            addAnswer={addAnswer}
            handleChangeMatching={handleChangeMatching}
          />
        )

      default:
        return null
    }
  }

  const disableSubmit = () => {
    switch (values.type) {
      case 1:
        return values.optionsObjects.length < 2 || !values.answer_options.length

      case 2:
        return (
          values.optionsObjects.length < 2 ||
          !values.optionsObjects.filter((a) => a.checked).length
        )

      case 3:
        return !values.title

      case 4:
        return (
          !!questionParts.find((item) => !item.text && item.type === 2) ||
          !questionParts.find((item) => item.type === 2) ||
          !questionParts.find((item) => item.type === 1)
        )

      case 5:
        return (
          !matchingQuestions.length ||
          !!matchingQuestions.find((q) => !Number.isInteger(q.right_answer))
        )

      default:
        return true
    }
  }

  return (
    <CustomDialog
      aria-labelledby="create-product-dialog"
      onClose={handleClose}
      open={props.open}
    >
      <ValidatorForm onSubmit={saveQuestion} className="question-form">
        <DialogTitle id="alert-dialog-title" className="text-center">
          {!props.question ? "New question" : "Edit question"}
        </DialogTitle>
        <DialogContent>
          <div className="f jcc">
            <div className="form">
              <div className="form__fieldset">
                <label htmlFor="type" className="form__label f aic">
                  <InfoIcon
                    style={{
                      marginRight: 5,
                      fontSize: 20,
                      color: colors.primary,
                      cursor: "pointer",
                    }}
                    onClick={() => setIsInfoModalOpen(true)}
                  />
                  Question type *
                </label>
                <div className="form__fieldbox">
                  <div className="form__input form__input--select">
                    <FormControl error={errors.typeError} fullWidth>
                      <Select
                        id="type"
                        name="type"
                        fullWidth
                        displayEmpty
                        value={values.type}
                        onChange={(event) =>
                          handleChangeRequired("type", event)
                        }
                        renderValue={(value) => {
                          if (value.length === 0) {
                            return (
                              <span className="color-black-38">
                                Select question type
                              </span>
                            )
                          }

                          return getObjectById(questionTypes, value).name
                        }}
                      >
                        {questionTypes.map((item) => (
                          <MenuItem value={item.id} key={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.typeError && (
                        <FormHelperText>Field is required</FormHelperText>
                      )}
                    </FormControl>
                  </div>
                </div>
              </div>

              <div className="form__fieldset">
                <label htmlFor="name" className="form__label">
                  {[4, 5].includes(values.type)
                    ? "Question instructions *"
                    : "Question *"}
                </label>
                <div className="form__fieldbox">
                  <div className="form__input">
                    <TextValidator
                      id="title"
                      name="title"
                      value={values.title}
                      placeholder={
                        [4, 5].includes(values.type)
                          ? "Type question instructions"
                          : "Type your question"
                      }
                      className="edit-form__input"
                      margin="normal"
                      fullWidth
                      multiline
                      validators={["required", "maxStringLength:500"]}
                      errorMessages={[
                        "Field is required",
                        "Max length is 500 characters",
                      ]}
                      withRequiredValidator
                      onChange={handleChange("title")}
                    />
                  </div>
                </div>
              </div>

              {composeAnswersTemplate()}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary" disabled={disableSubmit()}>
            Save
          </Button>
        </DialogActions>
      </ValidatorForm>

      {isInfoModalOpen && (
        <QuestionsInfoModal
          open={isInfoModalOpen}
          onClose={() => setIsInfoModalOpen(false)}
        />
      )}
    </CustomDialog>
  )
}

export default withSnackbar(QuestionForm)
