import React from "react"
import styled from "@emotion/styled"
import { usePostCtx } from "../PostContext"
import { Link } from "react-router-dom"
import unavailableAvatar from "../../../../../assets/img/user_unavailable.svg"
import { useSelector } from "react-redux"
import avatarDefault from "../../../../../assets/img/user_avatar.svg"
import UserAvatar from "../../../../userAvatar/userAvatar"
import { getGroupLogo } from "../../../../../library/utils/getGroupLogo"
import { useCurrentUser } from "../../../../../hooks/data/user/useUser"

const Avatar = styled.img`
  width: ${(props) => (props.isRepost ? "45px" : "60px")};
  height: ${(props) => (props.isRepost ? "45px" : "60px")};
  margin-right: 10px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  background-color: #fff;
`

const PostAuthorAvatar = ({ authorUrl, isRepost = false }) => {
  const { post } = usePostCtx()
  const { currenUserId } = useCurrentUser()

  const getUserPhotoUrl = () => {
    if (post.creator_user.deleted) {
      return unavailableAvatar
    }

    if (!post.creator_user.photo) {
      return avatarDefault
    }

    return post.creator_user.photo
  }

  const getOriginUserPhotoUrl = () => {
    if (!post.origin_user.photo) {
      return avatarDefault
    }

    return post.origin_user.photo
  }

  const getPhotoUrl = () => {
    if (isRepost && post.origin_user) {
      return getOriginUserPhotoUrl()
    }

    if (isRepost && post.origin_group) {
      return getGroupLogo(post.origin_group)
    }

    if (!isRepost && post.creator_user) {
      return getUserPhotoUrl()
    }

    if (!isRepost && post.creator_group) {
      return getGroupLogo(post.creator_group)
    }
  }

  return (
    <Link to={authorUrl}>
      {!isRepost &&
      post.creator_user &&
      post.creator_user.id === currenUserId ? (
        <UserAvatar classes="post__author-avatar" />
      ) : (
        <Avatar src={getPhotoUrl()} isRepost={isRepost} />
      )}
    </Link>
  )
}

export default PostAuthorAvatar
