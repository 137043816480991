import React from "react"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import { MenuItem, Select } from "@material-ui/core"
import PlainLink from "../../../../../../components/ui/link"
import * as routes from "../../../../../../library/constants/routes"
import { useGroupSubscriptionsQuery } from "../../../../user/userProfileSettings/profileSettingsSubscriptions/useSubscriptions"
import { useIdParam } from "../../../../../../hooks/useIdParam"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { nestAPI } from "../../../../../../library/networking/API"
import { useSelector } from "react-redux"
import { useAlert } from "../../../../../../hooks/useAlert"
import Button from "@material-ui/core/Button"

const SeatsNumberPicker = ({ open, onClose }) => {
  const { errorAlert, successAlert } = useAlert()
  const queryClient = useQueryClient()

  const { token } = useSelector(({ auth }) => auth.userData)
  const groupId = useIdParam("groupId")
  const {
    subscriptions: { subscription },
    isSuccess,
  } = useGroupSubscriptionsQuery(groupId)
  const [selectedSeatsNumber, setSelectedSeatsNumber] = React.useState(
    subscription.quantity
  )

  const { data: seatOptions, isSuccess: isSeatOptionsSuccess } = useQuery({
    queryKey: ["GROUP_SUBSCRIPTION_SEAT_OPTIONS", { id: groupId }],
    queryFn: async (ctx) => {
      const response = await fetch(
        nestAPI + `groups/${groupId}/subscriptionQuantityOptions`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )

      if (!response.ok) throw new Error("Cant fetch subscription options")

      const json = await response.json()

      return json?.available_options || []
    },
    staleTime: Infinity,
  })

  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const onSubmit = async () => {
    try {
      setIsSubmitting(true)
      const response = await fetch(
        nestAPI + `groups/${groupId}/subscription/update-seats`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            quantity: selectedSeatsNumber,
          }),
        }
      )

      const data = await response.json()
      await queryClient.invalidateQueries([
        "GROUP_SUBSCRIPTIONS",
        { id: groupId },
      ])

      setIsSubmitting(false)

      successAlert("Subscription updated")

      onClose()
    } catch (e) {
      errorAlert("Unable to update subscription")
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle className={"text-center"}>
        Choose group for subscription
      </DialogTitle>
      <DialogContent>
        <div className="f jcc">
          <div className="form form--modal">
            <div className="form__fieldset">
              <label htmlFor={"seatsNumber"} className={"form__label"}>
                Number of seats
              </label>
              <div className="form__fieldbox">
                <div className="form__input form__input--select">
                  {isSeatOptionsSuccess && (
                    <Select
                      id={"seatsNumber"}
                      name={"selectedSeatsNumber"}
                      fullWidth
                      onChange={(e) => setSelectedSeatsNumber(e.target.value)}
                      value={selectedSeatsNumber}
                      displayEmpty
                    >
                      <MenuItem value={""} disabled>
                        <span className={"color-black-38"}>
                          Select seats number
                        </span>
                      </MenuItem>
                      {seatOptions.map((o) => {
                        return (
                          <MenuItem
                            value={o}
                            key={o}
                            disabled={o === subscription.quantity}
                          >
                            {o} seats{" "}
                            {o === subscription.quantity && "(current)"}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  )}
                  <p className={"mt10 color-black-54 font-13"}>
                    Need more seats? Just{" "}
                    <PlainLink to={routes.CONTACT_FORM} bold inline>
                      Contact us
                    </PlainLink>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color={"primary"}>
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          color={"primary"}
          disabled={
            !selectedSeatsNumber ||
            setSelectedSeatsNumber === subscription.quantity ||
            isSubmitting
          }
        >
          {!isSubmitting ? "Submit" : "Submitting..."}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SeatsNumberPicker
