export const GROUPS = {
  LIST: "groups.list",
  LIST_UPDATE: "groups.list.update",
  SEARCH: "groups.search",
  GET: "groups.get",
  CLEAR_CURRENT: "groups.clear_current",
  CLEAR: "groups.clear",
  CREATE: "groups.create",
  UPDATE: "groups.update",
  DELETE: "groups.delete",
  JOIN: "groups.join",
  LEAVE: "groups.leave",
  INVITES_LIST: "groups.invites.list",
  REQUESTS_LIST: "groups.requests.list",
  KNOWMIX_LIST: "groups.knowmixes.list",
  KNOWMIX_LIST_CLEAR: "groups.knowmixes.list_clear",
  PARTICIPANTS: {
    LIST: "groups.participants.list",
    REMOVE: "groups.participants.remove",
    CHANGE_ROLE: "groups.participants.changeRole",
    CHANGE_TAGS: "groups.participants.changeTags",
    REQUESTS: {
      ACCEPT: "groups.participants.requests.accept",
      DECLINE: "groups.participants.requests.decline",
      CANCEL: "groups.participants.requests.cancel",
      LIST: "groups.participants.requests.list",
    },
    INVITES: {
      SEARCH: "groups.participants.invites.search",
      CREATE: "groups.participants.invites.create",
      DECLINE: "groups.participants.invites.decline",
      CANCEL: "groups.participants.invites.cancel",
      LIST: "groups.participants.invites.list",
      ROSTER: "groups.participants.invites.batch",
    },
  },
  CONNECTIONS: {
    LIST: "groups.connections.list",
    SEARCH: "groups.connections.search",
    ADD: "groups.connections.add",
    DELETE: "groups.connections.delete",
    CLEAR: "groups.connections.list_clear",
  },
  LIBRARY_ADD: "groups.products.add",
  LIBRARY_DELETE: "groups.products.delete",
  LIBRARY_LIST: "groups.products.list",
  LIBRARY_SEARCH: "groups.products.search",
  LIBRARY_UPDATE: "groups.products.update",
  LIBRARY_CLEAR: "groups.products.clear",

  SPACES: {
    LIST: "spaces.list",
    CLEAR_LIST: "spaces.clear_list",
    GET: "spaces.get",
    CLEAR_CURRENT: "spaces.clear_current",
    CREATE: "spaces.create",
    UPDATE: "spaces.update",
    DELETE: "spaces.delete",
  },
}

export const SCHOOLS = {
  STUDENTS: {
    ADD: "schools.students.add",
    DELETE: "schools.students.delete",
    LIST: "schools.students.list",
    SEARCH: "schools.students.search",
    LIST_CLEAR: "schools.students.list_clear",
    SEARCH_CLEAR: "schools.students.search_clear",
    TRANSFER: "schools.students.transfer",
  },
}

export const SEGMENTS = {
  LIST: "segments.list",
  GET: "segments.get",
  CREATE: "segments.create",
  UPDATE: "segments.update",
  DELETE: "segments.delete",
  CLEAR_LIST: "segments.list.clear",
  CLEAR_CURRENT: "segments.current.clear",

  SECTIONS: {
    LIST: "sections.list",
    GET: "sections.get",
    CREATE: "sections.create",
    UPDATE: "sections.update",
    DELETE: "sections.delete",
    CLEAR_LIST: "sections.list.clear",
    CLEAR_CURRENT: "sections.current.clear",
    BLOCKS: {
      LIST: "schedule_blocks.list",
      GET: "schedule_blocks.get",
      CREATE: "schedule_blocks.create",
      UPDATE: "schedule_blocks.update",
      DELETE: "schedule_blocks.delete",
    },
    TRACKS: {
      LIST: "schedule_tracks.list",
      GET: "schedule_tracks.get",
      CREATE: "schedule_tracks.create",
      UPDATE: "schedule_tracks.update",
      DELETE: "schedule_tracks.delete",
    },
    SLOTS: {
      GET: "schedule_block_tracks.get",
      UPDATE: "schedule_block_tracks.update",
    },
  },
}
