import React from "react"
import styled from "@emotion/styled"
import { colors } from "../../../../../library/constants/styles/colors"
import * as routes from "../../../../../library/constants/routes"
import pinIcon from "../../../../../assets/img/icons/icon-pin.svg"
import MoreDropdown from "../../../../ui/dropdowns/moreDropdown/moreDropdown"
import VerticalMenu from "../../../../ui/verticalMenu"
import { useParams, useRouteMatch } from "react-router-dom"
import { usePostCtx } from "../PostContext"
import { compact } from "lodash"
import { useSelector } from "react-redux"
import { useCurrentUser, useUser } from "../../../../../hooks/data/user/useUser"
import { useIdParam } from "../../../../../hooks/useIdParam"
import { useBookmarksModal } from "../../../../../hooks/modals/useBookmarksModal"
import { useComplaintsModal } from "../../../../../hooks/modals/useComplaintsModal"
import Menu from "../../../controls/menu/menu"
import { usePostsMutations } from "../../../../../hooks/data/feed/posts"
import { useAlert } from "../../../../../hooks/useAlert"
import { useGroup } from "../../../../../hooks/data/groups/groups"

const Container = styled.div`
  display: flex;
  align-items: center;
`

const PinIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  color: ${colors.black54};
`

const PostControls = ({ isEditMode }) => {
  const { errorAlert } = useAlert()
  const match = useRouteMatch()

  const userIdParam = useIdParam("userId")

  const { group } = useGroup()

  const {
    post,
    isBookmarksPage,
    pageOwnerGroup,
    queryKey,
    setIsEdit,
    setIsArchived,
  } = usePostCtx()

  const { id: currentUserId } = useCurrentUser(queryKey)

  const { archivePost, pinPost, unpinPost } = usePostsMutations({ queryKey })
  const { createBookmark, deleteBookmark } = useBookmarksModal()
  const { onComplainModalOpen } = useComplaintsModal()

  const onCreateBookmark = async () => {
    await createBookmark({
      params: {
        post_id: post.id,
      },
      updater: (p, bId) =>
        p.id === post.id
          ? {
              ...p,
              bookmark: { id: bId },
            }
          : p,
    })
  }

  const onDeleteBookmark = async () => {
    await deleteBookmark({
      params: {
        post_id: post.id,
      },
      updater: (p) =>
        p.id === post.id
          ? {
              ...p,
              bookmark: undefined,
            }
          : p,
    })
  }

  const onPinPost = async () => {
    await pinPost.mutateAsync({ id: post.id })
  }

  const onUnpinPost = async () => {
    await unpinPost.mutateAsync({ id: post.id })
  }

  const onDeletePost = async () => {
    try {
      await archivePost.mutateAsync({ id: post.id })
      setIsArchived(true)
    } catch (e) {
      errorAlert(e)
    }
  }

  const dropdownMenuItems = [
    post.can_edit &&
      !post.share_type &&
      !post.origin_post && {
        type: "button",
        action: () => setIsEdit(true),
        label: "Edit post",
      },
    ((post.can_delete && userIdParam === currentUserId) ||
      group?.is_admin ||
      group?.is_creator) && {
      type: "button",
      action: !post.pinned ? onPinPost : onUnpinPost,
      label: !post.pinned ? "Pin" : "Unpin",
    },
    !post.can_edit && {
      type: "button",
      action: onComplainModalOpen,
      label: "Report",
      disabled: post.is_reported,
    },
    !post.bookmark
      ? {
          type: "button",
          action: onCreateBookmark,
          label: "Add to bookmarks",
        }
      : {
          type: "button",
          action: onDeleteBookmark,
          label: "Remove from bookmarks",
        },
    post.can_delete && {
      type: "h-divider",
      id: "hd1",
    },
    post.can_delete && {
      type: "button",
      action: onDeletePost,
      label: "Delete post",
    },
  ].filter(Boolean)

  return (
    <Container>
      {post.pinned &&
        !isBookmarksPage &&
        ![routes.NEWS, routes.NEWS_POST].includes(match.path) && (
          <PinIndicatorContainer>
            <img src={pinIcon} alt="Pinned" className="mr5" />
            <span className="d-none-xs">Post pinned</span>
          </PinIndicatorContainer>
        )}
      {!isEditMode &&
        (!isBookmarksPage ? (
          <Menu items={dropdownMenuItems} />
        ) : (
          <Menu items={dropdownMenuItems} />
          // <BookmarkedItemDropdown
          //     deleteBookmark={this.deleteBookmark}
          //     bookmarkFoldersList={() => this.props.bookmarkFoldersList(post.bookmark.id)}
          //     addToFolder={(folders) => this.props.bindFolder({
          //         bookmark_id: post.bookmark.id,
          //         folders,
          //     })}
          //     bookmarksFoldersListClear={this.props.bookmarksFoldersListClear}
          // />
        ))}
    </Container>
  )
}

export default PostControls
