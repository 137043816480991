import React from "react"
import ListPageHeader from "../listPage/listPageHeader/listPageHeader"
import { useListPage } from "../../../../hooks/useListPage"
import Loader from "../../../ui/loader"
import AlertDialog from "../../../ui/alertDialog"
import withErrorBoundary from "../../../../hocs/guards/withErrorBoundary"
import { getCardSize } from "../../../../library/constants/styles/cardSizes"

const SuperListPage = ({
  queryConfig = {
    key: [null],
    fetchParams: {},
    listKey: null,
    url: null,
  },
  Header,
  getHeaderComponent,
  headerProps = {
    title: "Page",
    button: {
      label: "Button",
      action: null,
      show: true,
    },
    search: true,
  },
  noResultsLabel = "Result not found",
  listItemHeight = getCardSize(8).numValPx,
  getListItemComponent,
  getDeletionDialogComponent,
  getInfoDialogComponent,
  getFormDialogComponent,
}) => {
  const { isSearching, allItems, query, virtualizer, searchParams } =
    useListPage({ queryConfig, listItemHeight })

  const [formDialogState, setFormDialogState] = React.useState({
    open: false,
    activeId: null,
  })
  const [infoDialogState, setInfoDialogState] = React.useState({
    open: false,
    activeId: null,
  })
  const [deletionDialogState, setDeletionDialogState] = React.useState({
    open: false,
    activeId: null,
  })

  const openFormDialog = (id = null) => {
    console.log("openFormDialog", id)
    setFormDialogState({
      activeId: id,
      open: true,
    })
  }

  const closeFormDialog = () => {
    setFormDialogState({
      activeId: null,
      open: false,
    })
  }

  const openInfoDialog = (id) => {
    setInfoDialogState({
      activeId: id,
      open: true,
    })
  }

  const closeInfoDialog = () => {
    setInfoDialogState({
      activeId: null,
      open: false,
    })
  }

  const openDeletionDialog = (id) => {
    setDeletionDialogState({
      activeId: id,
      open: true,
    })
  }

  const closeDeletionDialog = () => {
    setDeletionDialogState({
      activeId: null,
      open: false,
    })
  }

  const composeDeletionDialog = () => {
    if (!getDeletionDialogComponent) return null

    const hasDeletionConfig = !!(
      getDeletionDialogComponent?.entityName &&
      getDeletionDialogComponent?.onDelete
    )

    const hasDeletionComponent = !!getDeletionDialogComponent.component

    if (hasDeletionConfig) {
      return (
        <AlertDialog
          open={deletionDialogState.open}
          handleClose={closeDeletionDialog}
          handleAccept={async () => {
            await getDeletionDialogComponent.onDelete(
              deletionDialogState.activeId
            )
            closeDeletionDialog()
          }}
          title={`Delete this ${getDeletionDialogComponent.entityName}?`}
          message={`If you delete this ${getDeletionDialogComponent.entityName} you will not be able to restore it`}
        />
      )
    }

    if (hasDeletionComponent) {
      return getDeletionDialogComponent.component({
        open: deletionDialogState.open,
        onClose: closeDeletionDialog,
        activeItem: deletionDialogState.activeId,
      })
    }

    return null
  }

  return (
    <>
      {Header ? (
        <Header />
      ) : typeof getHeaderComponent === "function" ? (
        getHeaderComponent({ openFormDialog })
      ) : (
        <ListPageHeader
          title={headerProps.title}
          buttonTitle={headerProps.button?.label}
          buttonAction={headerProps.button?.action || (() => openFormDialog())}
          hasButton={headerProps.button?.show}
          noResults={query.isSuccess && !allItems.length}
          noResultsLabel={searchParams.name ? "" : noResultsLabel}
          hasSearch={headerProps.search}
          // filter={{
          //   ...filter,
          //   onChange: this.onFilterChange,
          //   value: this.state.filterId,
          // }}
        />
      )}

      {query.isInitialLoading || isSearching ? (
        <Loader />
      ) : query.isError ? (
        <div className={"text-center"}>
          <p className="color-black-54 mb10">Something went wrong</p>
          <p style={{ fontSize: 30, lineHeight: "30px" }}>&#128533;</p>
        </div>
      ) : allItems.length ? (
        <div
          style={{
            height: `${virtualizer.getTotalSize()}px`,
            width: "100%",
            position: "relative",
          }}
        >
          {virtualizer.getVirtualItems().map((virtualItem) => {
            const isLoader = virtualItem.index > allItems.length - 1
            const item = allItems[virtualItem.index]

            return (
              <div
                key={virtualItem.key}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: `${virtualItem.size}px`,
                  transform: `translateY(${virtualItem.start}px)`,
                }}
                // data-index={virtualItem.index}
                // ref={virtualizer.measureElement}
              >
                {isLoader ? (
                  query.hasNextPage ? (
                    <Loader />
                  ) : (
                    <p className={"color-black-54"}>No more items</p>
                  )
                ) : (
                  getListItemComponent?.({
                    item,
                    index: virtualItem.index,
                    openDeletionDialog: () => openDeletionDialog(item.id),
                    closeDeletionDialog: closeDeletionDialog,
                    openInfoDialog: () => openInfoDialog(item.id),
                    closeInfoDialog: closeInfoDialog,
                    openEditDialog: () => openFormDialog(item.id),
                    closeEditDialog: closeFormDialog,
                    // filterId: this.state.filterId || undefined,
                    key: item.id,
                  }) ?? null
                )}
              </div>
            )
          })}
        </div>
      ) : (
        <p className={"text-black54 text-center"}>{noResultsLabel}</p>
      )}

      {deletionDialogState.open && composeDeletionDialog()}

      {infoDialogState.open &&
        !!getInfoDialogComponent &&
        getInfoDialogComponent({
          open: infoDialogState.open,
          onClose: closeInfoDialog,
          activeItem: infoDialogState.activeId,
        })}

      {formDialogState.open &&
        !!getFormDialogComponent &&
        getFormDialogComponent({
          open: formDialogState.open,
          onClose: closeFormDialog,
          activeItem: formDialogState.activeId,
          // filterId: this.state.filterId || undefined,
        })}
    </>
  )
}

export default withErrorBoundary(SuperListPage)
