import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withSnackbar } from "notistack"
import { bindActionCreators } from "redux"
import { formatBytes } from "../../../../../library/utils/fileSizeConverter"
import InsertDriveFile from "@material-ui/icons/InsertDriveFile"

const ProductAttachments = ({ products }) => {
  const attachments = [
    ...products.currentProduct.blobs,
    ...products.currentProduct.images,
    ...products.currentProduct.videos,
  ]

  if (attachments.length > 0) {
    return (
      <div className="box product-testimonials">
        <div className="box__content">
          <p className="box__subheading">Product attachments</p>
          <div className="post__files">
            {attachments.map(({ id, name, size, url }) => {
              return (
                <a href={url} target="_blank" className="post__file" key={id}>
                  <InsertDriveFile
                    className="post__file-icon"
                    style={{ fontSize: "30px" }}
                  />
                  <div className="post__file-info">
                    <span className="link link--inline">{name}</span>
                    <span className="post__file-size">{formatBytes(size)}</span>
                  </div>
                </a>
              )
            })}
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

const mapStateToProps = ({ auth, products }) => ({ auth, products })
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(ProductAttachments)))
