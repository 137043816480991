import React, { Component } from "react"
import "./postsList.scss"
import { getData } from "../../../../library/store/actions/creators/authCreators"
import { getPerson } from "../../../../library/store/actions/creators/personCreators"
import {
  clearPostsList,
  getPostsList,
  updatePostsList,
} from "../../../../library/store/actions/creators/postsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import Post from "../post"
import Loader from "../../../../components/ui/loader/loader"
import withScroll from "../../../../hocs/withScroll/withScroll"
import { LIMIT } from "../../../../library/constants/limits"
import Stub from "../../../../components/stub"
import getErrorText from "../../../../library/constants/errorTypes"
import { bookmarksListClear } from "../../../../library/store/actions/creators/bookmarksCreators"

class PostsList extends Component {
  state = {
    activeTab: "all",
    type: this.props.type,
    posts: [],
    firstPost: null,
    userInfo: this.props.userInfo,
    groupInfo: this.props.groupInfo,
    dataReceived: false,
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }

  loadPosts = async (limit) => {
    const { id } = this.props.auth.userData
    const { type, posts } = this.state
    let userId

    await this.setStateAsync({ dataReceived: false })

    try {
      switch (type) {
        case "my-profile":
          userId = this.props.match.params.userId
          await this.props.getPostsList({
            type: "my",
            user_id: id,
            offset: posts.length,
            limit: limit,
          })
          await this.setStateAsync({
            posts: this.props.posts.myPostsList,
            dataReceived: true,
          })
          break

        case "feed":
          await this.props.getPostsList({
            type: "feed",
            offset: posts.length,
            limit: limit,
          })
          await this.setStateAsync({
            posts: this.props.posts.feedList,
            dataReceived: true,
          })
          break

        case "other-profile":
          userId = this.props.match.params.userId
          await this.props.getPostsList({
            type: "person",
            user_id: parseInt(userId),
            offset: posts.length,
            limit: limit,
          })
          await this.setStateAsync({
            posts: this.props.posts.postsList,
            dataReceived: true,
          })
          break

        case "group":
          await this.setStateAsync({
            posts: this.props.posts.postsList,
            dataReceived: true,
          })
          break

        case "bookmarks":
          await this.props.getPostsList({
            bookmarked: true,
            folder_id:
              this.props.subtype && this.props.subtype === "folder"
                ? parseInt(this.props.match.params.folderId)
                : undefined,
            offset: posts.length,
            limit: limit,
          })
          await this.setStateAsync({
            posts: this.props.posts.postsList,
            dataReceived: true,
          })
          break

        default:
          return
      }
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  componentDidMount() {
    this.loadPosts()
  }

  onScroll = () => {
    if (
      !this.props.posts.isComplete &&
      this.state.dataReceived &&
      this.state.posts.length
    ) {
      this.loadPosts(LIMIT)
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const { type } = this.state
    switch (type) {
      case "my-profile":
        if (this.props.posts.myPostsList !== prevProps.posts.myPostsList) {
          await this.setState({ dataReceived: false })

          await this.setState({
            posts: this.props.posts.myPostsList,
            firstPost: this.props.posts.myPostsList[0],
            dataReceived: true,
          })
        }
        break

      case "feed":
        if (this.props.posts.feedList !== prevProps.posts.feedList) {
          await this.setState({ dataReceived: false })

          await this.setState({
            posts: this.props.posts.feedList,
            firstPost: this.props.posts.feedList[0],
            dataReceived: true,
          })
        }
        break

      default:
        if (this.props.posts.postsList !== prevProps.posts.postsList) {
          await this.setState({ dataReceived: false })

          await this.setState({
            posts: this.props.posts.postsList,
            firstPost: this.props.posts.postsList[0],
            dataReceived: true,
          })
        }
    }
  }

  updateList = async () => {
    const { type } = this.props
    const { id } = this.props.auth.userData
    const { userId, groupId, folderId } = this.props.match.params

    try {
      switch (type) {
        case "my-profile":
          await this.props.updatePostsList({
            type: "my",
            user_id: id,
            offset: 0,
            limit: this.state.posts.length,
          })
          break

        case "other-profile":
          await this.props.updatePostsList({
            type: "person",
            user_id: parseInt(userId),
            offset: 0,
            limit: this.state.posts.length,
          })
          break

        case "group":
          await this.props.updatePostsList({
            type: "group",
            group_id: parseInt(groupId),
            offset: 0,
            limit: this.state.posts.length,
          })
          break

        case "feed":
          await this.props.updatePostsList({
            type: "feed",
            offset: 0,
            limit: this.state.posts.length,
          })
          break

        case "bookmarks":
          await this.props.updatePostsList({
            type: "bookmarks",
            bookmarked: true,
            folder_id:
              this.props.subtype && this.props.subtype === "folder"
                ? parseInt(folderId)
                : undefined,
            offset: 0,
            limit: this.state.posts.length,
          })
          break

        default:
          return
      }
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  componentWillUnmount() {
    if (this.state.type !== "my-profile") {
      this.props.clearPostsList()
    }
  }

  setStateLoading = () => {
    this.setState({
      posts: [],
      dataReceived: false,
    })
  }
  setStateLoaded = () => {
    setTimeout(
      this.setState({
        dataReceived: true,
      }),
      200
    )
  }

  render() {
    const {
      userInfo,
      groupInfo,
      posts,
      type,
      activeTab,
      dataReceived,
      firstPost,
    } = this.state
    const { createdByGroup } = this.props

    return (
      <>
        <div className="posts-list">
          <div
            className={`box posts-list__header ${
              type !== "bookmarks" ? "pb10" : ""
            }`}
          >
            {type !== "bookmarks" ? (
              <nav className="h-tabs h-tabs--ghost jcfs">
                <ul className="h-tabs__nav-list h-tabs__nav-list--secondary">
                  {posts.length === 0 && dataReceived ? (
                    <li className="h-tabs__item h-tabs__item--active">
                      <span className="h-tabs__link">No posts yet</span>
                    </li>
                  ) : (
                    <>
                      {type !== "feed" && (
                        <li
                          className={`h-tabs__item ${
                            activeTab === "all" ? "h-tabs__item--active" : ""
                          }`}
                          onClick={() => this.setState({ activeTab: "all" })}
                        >
                          <span className={`h-tabs__link`}>All posts</span>
                        </li>
                      )}
                      {/*<li className={`h-tabs__item ${activeTab === 'owner' ? 'h-tabs__item--active' : ''}`}*/}
                      {/*    onClick={() => this.setState({activeTab: 'owner'})}>*/}
                      {/*    <span className={`h-tabs__link`}>*/}
                      {/*        {type !== 'group' ? (*/}
                      {/*            type === 'my-profile' ? 'My posts' : `${userInfo.first_name}'s posts`*/}
                      {/*        ) : `Group's posts`}*/}
                      {/*    </span>*/}
                      {/*</li>*/}
                    </>
                  )}
                </ul>
              </nav>
            ) : (
              !this.props.hideHeader && (
                <>
                  <div className="box__heading">
                    <h1>Posts</h1>
                  </div>
                  {posts.length === 0 && dataReceived && (
                    <div className="box__content">
                      <p className="color-black-54 text-center">
                        You don't have bookmarks yet
                      </p>
                    </div>
                  )}
                </>
              )
            )}

            {type !== "bookmarks" && (
              <div className="h-divider--without_margin"></div>
            )}
            {firstPost && posts.length === 0 && dataReceived && (
              <Stub
                text={
                  type === "group"
                    ? "The group’s wall is empty."
                    : type === "bookmarks"
                    ? "No posts bookmarked"
                    : `${userInfo.first_name}'s wall is empty.`
                }
              />
            )}
          </div>
          {posts.map((post, index) => (
            <div className={index === 0 ? "box" : "box mt15"} key={post.id}>
              <Post
                setStateLoading={this.setStateLoading}
                setStateLoaded={this.setStateLoaded}
                post={post}
                userInfo={userInfo}
                groupInfo={groupInfo}
                type={type}
                updateData={(type) => this.updateList(type)}
                createdByGroup={createdByGroup}
              />
            </div>
          ))}
        </div>
        {((_) => {
          if (!dataReceived) {
            return <Loader />
          }
        })()}
      </>
    )
  }
}

const mapStateToProps = ({auth, person, posts, bookmarks}) => ({auth, person, posts, bookmarks});
const mapDispatchToProps = dispatch => bindActionCreators({
    getData,
    getPerson,
    getPostsList,
    clearPostsList,
    updatePostsList,
    bookmarksListClear,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withSnackbar(withScroll(PostsList))));
