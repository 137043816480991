import { createApi } from "@reduxjs/toolkit/query/react"
import { CALENDARS, SCHEDULE } from "../../actions/types/calendarTypes"
import { axiosBaseQuery } from "../baseQuery"

export const calendarsAPI = createApi({
  reducerPath: "calendarsAPI",
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getCalendar: build.query({
      query: (id) => ({
        url: "calendars.get",
        data: { id },
        actionType: CALENDARS.GET,
      }),
    }),
    getSchedule: build.query({
      query: (id) => ({
        url: "schedules.get",
        data: { id },
        actionType: SCHEDULE.SCHEDULES.GET,
      }),
    }),
    getCalendarBlock: build.query({
      query: (id) => ({
        url: "blocks.get",
        data: { id },
      }),
    }),
    getCalendarSegment: build.query({
      query: (id) => ({
        url: "segments.get",
        data: { id },
      }),
    }),
  }),
})

export const {
  useGetCalendarQuery,
  useLazyGetCalendarQuery,
  useGetCalendarBlockQuery,
  useGetCalendarSegmentQuery,
  useGetScheduleQuery,
  useLazyGetScheduleQuery,
} = calendarsAPI
