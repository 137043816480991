import React, { useEffect, useState } from "react"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import { Dialog, DialogContent } from "@material-ui/core"
import Button from "@material-ui/core/Button/Button"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import { useParams } from "react-router-dom"
import { useAlert } from "../../../../../../../../hooks/useAlert"
import { useGetSpaceQuery } from "../../../../../../../../library/store/api/calendar/spacesAPI"
import Loader from "../../../../../../../../components/ui/loader"

const SpaceFormModal = ({ handleClose, edit, open, onSubmit, activeSpace }) => {
  const { groupId: schoolId } = useParams()
  const { errorAlert } = useAlert()

  const { data, isLoading, isSuccess } = useGetSpaceQuery(activeSpace, {
    skip: !edit,
  })

  const [values, setValues] = useState({
    name: "",
    description: "",
  })

  useEffect(() => {
    if (isSuccess) {
      setValues({
        name: data.name,
        description: data.description,
      })
    }
  }, [isSuccess])

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value })
  }

  const processInfoSave = async () => {
    console.log(values)
    try {
      await onSubmit({
        school_id: !edit ? parseInt(schoolId, 10) : undefined,
        id: edit ? activeSpace : undefined,
        ...values,
      })
      handleClose()
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <Dialog
      aria-labelledby="subject-form-dialog"
      onClose={handleClose}
      open={open}
      maxWidth="md"
    >
      <ValidatorForm onSubmit={processInfoSave}>
        <DialogTitle id="alert-dialog-title" className="text-center">
          {edit ? "Edit Space" : "Create Space"}
        </DialogTitle>
        {isLoading && <Loader />}
        {(!edit || isSuccess) && (
          <>
            <DialogContent>
              <div className="form form--modal">
                <div className="form__fieldset">
                  <label htmlFor="name" className="form__label">
                    Title *
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="name"
                        name="name"
                        value={values.name}
                        placeholder="Type Space title"
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        validators={[
                          "required",
                          "minStringLength:3",
                          "maxStringLength:200",
                        ]}
                        errorMessages={[
                          "Field is required",
                          "Min length is 3 characters",
                          "Max length is 200 characters",
                        ]}
                        withRequiredValidator
                        onChange={handleChange("name")}
                      />
                    </div>
                  </div>
                </div>
                <div className="form__fieldset">
                  <label htmlFor="description" className="form__label">
                    Description *
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="description"
                        name="description"
                        value={values.description}
                        multiline
                        placeholder="Type description"
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        validators={["required", "maxStringLength:500"]}
                        errorMessages={[
                          "Field is required",
                          "Max length is 500 characters",
                        ]}
                        withRequiredValidator
                        onChange={handleChange("description")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Save
              </Button>
            </DialogActions>
          </>
        )}
      </ValidatorForm>
    </Dialog>
  )
}

export default SpaceFormModal
