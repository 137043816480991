import React, { Component } from "react"
import { TimePicker } from "@material-ui/pickers"
import FormControl from "@material-ui/core/FormControl/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"

class FormTimePicker extends Component {
  state = {
    timeFrom: this.props.timeFrom.value || null,
    timeTo: this.props?.timeTo?.value || null,
    timeFromError: this.props.timeFrom.error || false,
    timeToError: this.props?.timeTo?.error || false,
  }

  handleDateChange = (date, name) => {
    const parsedDate = date ? new Date(date).toISOString() : null
    this.setState({ [name]: parsedDate })

    if (name === "timeFrom") {
      // this.setState({dateFromError: !parsedDate});
      this.props.timeFrom.handleChange(parsedDate)
    } else {
      // this.setState({dateToError: !parsedDate});
      this.props.timeTo.handleChange(parsedDate)
    }
  }

  render() {
    const { timeFrom, timeTo } = this.props
    return (
      <>
        <div className="form__fieldset">
          <label htmlFor="start-time" className="form__label">
            {timeFrom.label || "Start time"} {timeFrom.required ? " *" : ""}
          </label>
          <div className="form__fieldbox">
            <div className="form__input">
              <FormControl error={timeFrom.error} fullWidth>
                <TimePicker
                  autoOk
                  id="start-time"
                  value={this.state.timeFrom}
                  emptyLabel="Select start time"
                  invalidLabel="Select start time"
                  allowKeyboardControl={false}
                  fullWidth
                  clearable
                  format="h:mm a"
                  margin="normal"
                  error={timeFrom.error}
                  maxDate={
                    this.state.timeTo
                      ? new Date(this.state.timeTo)
                      : new Date(2099, 11, 31, 23, 59, 59)
                  }
                  onChange={(date) => this.handleDateChange(date, "timeFrom")}
                />
                {timeFrom.error && (
                  <FormHelperText className="error-message">
                    Field is required
                  </FormHelperText>
                )}
              </FormControl>
            </div>
          </div>
        </div>
        {!!timeTo && (
          <div className="form__fieldset">
            <label htmlFor="finish-date" className="form__label">
              {timeTo.label || "End date"} {timeTo.required ? " *" : ""}
            </label>
            <div className="form__fieldbox">
              <div className="form__input">
                <FormControl error={timeTo.error} fullWidth>
                  <TimePicker
                    id="finish-time"
                    value={this.state.timeTo}
                    emptyLabel="Select end time"
                    invalidLabel="Select end time"
                    allowKeyboardControl={false}
                    fullWidth
                    clearable
                    format="h:mm a"
                    error={timeTo.error}
                    minDate={
                      this.state.timeFrom
                        ? new Date(this.state.timeFrom)
                        : new Date(1910, 1, 1, 23, 59, 59)
                    }
                    margin="normal"
                    onChange={(date) => this.handleDateChange(date, "timeTo")}
                  />
                  {timeTo.error && (
                    <FormHelperText className="error-message">
                      Field is required
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

export default FormTimePicker
