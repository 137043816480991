import React, { useState } from "react"
import { Box } from "../../../../../components/tw/layout"
import CheckOutlined from "@material-ui/icons/CheckOutlined"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp"
import {
  useCourse,
  useCourseMutations,
} from "../../../../../hooks/data/courses/courses"
import { useToggle } from "../../../../../hooks/useToggle"
import { Collapse } from "@material-ui/core"
import Button from "@material-ui/core/Button/Button"
import returnExternalUrl from "../../../../../library/utils/returnExternalUrl"
import InsertDriveFile from "@material-ui/icons/InsertDriveFile"
import { formatBytes } from "../../../../../library/utils/fileSizeConverter"
import PlainLink from "../../../../../components/ui/link"
import { useCourseStepsMutations } from "../../../../../hooks/data/courses/steps"
import { useAlert } from "../../../../../hooks/useAlert"
import { generatePath, useHistory } from "react-router-dom"
import * as routes from "../../../../../library/constants/routes"
import { useIdParam } from "../../../../../hooks/useIdParam"

const CourseStep = ({ step, isInitiallyOpen, isUnlocked, stepIdx }) => {
  const { errorAlert } = useAlert()
  const knowmixId = useIdParam("knowmixId")
  const history = useHistory()

  const { course, canEdit, queryKey } = useCourse()

  const [isStepOpen, toggleStep] = useToggle(isInitiallyOpen)
  const [isQuizOpen, toggleQuiz] = useToggle(false)

  const { startCourse } = useCourseMutations({ queryKey })
  const { expressFinishCourseStepQuiz } = useCourseStepsMutations({ queryKey })

  const onStartCourse = async () => {
    try {
      if (course?.type === 1) {
        await startCourse.mutateAsync({ id: course?.id })
      }
      history.push(
        generatePath(routes.KNOWMIX_WALKTHROUGH, {
          knowmixId,
          stepId: stepIdx,
        })
      )
    } catch (e) {
      errorAlert(e)
    }
  }

  const onFinishStep = async () => {
    if (step.type === 3) {
      return history.push(
        generatePath(routes.KNOWMIX_WALKTHROUGH, {
          knowmixId,
          stepId: stepIdx,
        })
      )
    }

    try {
      await expressFinishCourseStepQuiz.mutateAsync(step.id)
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <Box className={"p-0"}>
      <div
        role={"button"}
        onClick={toggleStep}
        className="grid grid-cols-[1fr,_max-content] gap-8 items-center p-4 cursor-pointer hover:bg-primary15"
      >
        <p className={"line-clamp-1 text-lg"}>
          Step {stepIdx + 1} - {step.name}
        </p>
        <div>
          {step.type === 3 && !!step.grade && (
            <CheckOutlined style={{ marginRight: "1rem" }} color={"primary"} />
          )}
          {!canEdit && (
            <span>
              {step.type === 3 && "Completed"}

              {step.has_questions ? (
                <>
                  {step.type === 2 && "Not completed"}
                  {step.type === 1 && "Not started"}
                </>
              ) : (
                <>{[1, 2].includes(step.type) && "Not completed"}</>
              )}
            </span>
          )}
          {!isStepOpen ? (
            <KeyboardArrowDown className="text-black38" />
          ) : (
            <KeyboardArrowUp className="text-black38" />
          )}
        </div>
      </div>
      <Collapse in={isStepOpen}>
        <div className={"grid gap-4 p-4"}>
          <div>
            <p className={"mb-2 text-black54"}>Step name:</p>
            <span>{step.name}</span>
          </div>
          {!!step.description && (
            <div>
              <p className={"mb-2 text-black54"}>Instructions:</p>
              <span>{step.description}</span>
            </div>
          )}
          {!!step.links && step.links.length > 0 && (
            <div>
              <p className={"mb-2 text-black54"}>Links:</p>
              <ul className="text-list">
                {step.links.map((link, index) => (
                  <li key={link + index}>
                    <PlainLink
                      newTab
                      to={`${returnExternalUrl(link)}`}
                      className="link"
                    >
                      {link}
                    </PlainLink>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {!!step.blobs && step.blobs.length > 0 && (
            <div>
              <p className={"mb-2 text-black54"}>Attachments:</p>
              <div className="post__files">
                {step.blobs.map(({ id, name, size, url }) => {
                  return (
                    <a
                      href={url}
                      target="_blank"
                      className="post__file"
                      key={id}
                    >
                      <InsertDriveFile
                        className="post__file-icon"
                        style={{ fontSize: "30px" }}
                      />
                      <div className="post__file-info">
                        <span className="link link--inline">{name}</span>
                        <span className="post__file-size">
                          {formatBytes(size)}
                        </span>
                      </div>
                    </a>
                  )
                })}
              </div>
            </div>
          )}
          {isUnlocked && !canEdit && (
            <div>
              {step.has_questions ? (
                <Button
                  onClick={onStartCourse}
                  color="primary"
                  variant="outlined"
                  disabled={!course.is_finished}
                >
                  {step.type === 1 || step.type === 2
                    ? "Answer Question"
                    : "Review work"}
                </Button>
              ) : (
                <Button
                  onClick={onFinishStep}
                  color="primary"
                  variant="outlined"
                  disabled={!course.is_finished}
                >
                  {step.type !== 3 ? "Finish step" : "Review work"}
                </Button>
              )}
            </div>
          )}
        </div>
      </Collapse>
    </Box>
  )
}

export default CourseStep
