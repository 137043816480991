import React, { Component } from "react"
import ListSearchHeader from "../../../../components/ui/listSearchHeader/listSearchHeader"
import { generatePath, Link } from "react-router-dom"
import userAvatarDefault from "../../../../assets/img/user_avatar.svg"
import Button from "@material-ui/core/Button"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import * as routes from "../../../../library/constants/routes"
import {
  clearSearchList,
  usersSearch,
} from "../../../../library/store/actions/creators/searchCreators"
import Loader from "../../../../components/ui/loader"
import withScroll from "../../../../hocs/withScroll/withScroll"
import { LIMIT } from "../../../../library/constants/limits"
import getErrorText from "../../../../library/constants/errorTypes"
import LinesEllipsis from "react-lines-ellipsis"
import * as qs from "query-string"
import { getUserLocationString } from "../../../../library/utils/getUserLocationString"

class ConversationStart extends Component {
  state = {
    name: "",
    initialRender: true,
    dataReceived: false,
  }

  async componentDidMount() {
    const { search } = this.props.location
    const { q: query } = qs.parse(search)
    try {
      this.props.clearSearchList()
      await this.props.usersSearch({ name: !!query ? query : undefined })
      this.setState({
        initialRender: false,
        dataReceived: true,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location !== this.props.location) {
      await this.searchUsers()
    }
  }

  setQuery = (name) => {
    const { history } = this.props
    this.props.clearSearchList()
    history.push({
      search: `?q=${name}`,
    })
  }

  searchUsers = async () => {
    this.setState({ dataReceived: false })
    const { search } = this.props.location
    const { q: query } = qs.parse(search)

    try {
      await this.props.usersSearch({ name: !!query ? query : undefined })
      this.setState({ dataReceived: true })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  onScroll = async () => {
    if (!this.state.dataReceived) return
    const { search } = this.props.location
    const { q: query } = qs.parse(search)
    this.setState({ dataReceived: false })

    try {
      await this.props.usersSearch({
        name: !!query ? query : undefined,
        offset: this.props.search.users.users.length,
        limit: LIMIT,
      })
      this.setState({ dataReceived: true })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  render() {
    const { initialRender, dataReceived } = this.state
    return (
      <>
        <ListSearchHeader
          title="People"
          placeholder="Start typing any name"
          search={(name) => this.setQuery(name)}
          noResults={
            this.props.search.users.users &&
            !this.props.search.users.users.length &&
            !initialRender &&
            dataReceived
          }
        />
        {((_) => {
          if (this.props.search.users.users) {
            const {
              users: { users },
            } = this.props.search
            return users.map((item) => (
              <ListItem
                user={item}
                key={item.id}
                history={this.props.history}
              />
            ))
          }
        })()}
        {((_) => {
          if (!dataReceived) return <Loader />
        })()}
      </>
    )
  }
}

const ListItem = (props) => {
  const { id, first_name, last_name, photo, role, city, country, ...rest } =
    props.user
  const photoUrl = photo !== "" ? photo : userAvatarDefault
  const fullName = `${first_name} ${last_name}`
  const profileUrl = generatePath("/users/:userId", { userId: id })
  // const addressInfoBlock = (city ? city : "") + (country ? `, ${country}` : "");
  return (
    <div className="box list-item">
      <div className="f aic full-width">
        <Link to={profileUrl} className="list-item__img">
          <img src={photoUrl} alt={fullName} />
        </Link>
        <div className="list-item__info">
          <Link to={profileUrl} className="link list-item__name">
            <LinesEllipsis
              text={fullName}
              maxLine="1"
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
          </Link>
          {/*<span className="list-item__description">{userRolesIds[role].name}</span>*/}

          {!!rest.education && (
            <span>
              {rest.education && rest.education.name ? rest.education.name : ""}
              {rest.education &&
              rest.education.name &&
              rest.education.department
                ? ", "
                : ""}
              {rest.education && rest.education.department
                ? rest.education.department
                : ""}
            </span>
          )}

          {!!rest.work && (
            <span>
              {rest.work && rest.work.name ? rest.work.name : ""}
              {rest.work && rest.work.name && rest.work.role ? ", " : ""}
              {rest.work && rest.work.role ? rest.work.role : ""}
            </span>
          )}
          <span className="list-item__info-block">
            {getUserLocationString(props.user) && (
              <LinesEllipsis
                text={getUserLocationString(props.user)}
                maxLine="1"
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            )}
          </span>
        </div>
      </div>
      <div className="list-item__control-panel">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            props.history.push({
              pathname: routes.CONVERSATION,
              search: `?userId=${id}`,
            })
          }}
        >
          Write message
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = ({ search }) => ({ search })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ usersSearch, clearSearchList }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withScroll(ConversationStart)))
