import React from "react"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import Dialog from "@material-ui/core/Dialog"
import Button from "@material-ui/core/Button/Button"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import { useAlert } from "../../../../../../../../../../../../../../hooks/useAlert"
import {
  useBlockMutations,
  useTrack,
  useTrackMutations,
} from "../../../../../../../../../../../../../../hooks/data/calendar/schedules"
import Form from "../../../../../../../../../../../../../../components/v2/layout/forms/form/form"
import { Formik } from "formik"
import * as Yup from "yup"
import { useIdParam } from "../../../../../../../../../../../../../../hooks/useIdParam"

const validationSchema = Yup.object({
  name: Yup.string().required(),
})

const TrackFormModal = ({ onClose, open, activeTrack, queryKey }) => {
  const section_id = useIdParam("sectionId")
  const { errorAlert } = useAlert()

  const { data: currentTrack, isSuccess } = useTrack(activeTrack, {
    enabled: !!activeTrack,
  })

  const { createTrack, updateTrack } = useTrackMutations(queryKey)

  const initialValues = {
    name: currentTrack?.name || "",
  }

  const onSubmit = async (data) => {
    try {
      if (!!activeTrack) {
        await updateTrack.mutateAsync({ id: activeTrack, ...data })
      } else {
        await createTrack.mutateAsync({ title: data.name, section_id })
      }
      onClose()
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <Dialog
      aria-labelledby="subject-form-dialog"
      onClose={onClose}
      open={open}
      maxWidth="md"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={onSubmit}
      >
        <Form isModal>
          <DialogTitle id="alert-dialog-title" className="text-center">
            {!!activeTrack ? "Edit Track" : "Create Track"}
          </DialogTitle>
          <DialogContent>
            <Form.Row label={"Name"} name={"name"}>
              <Form.TextField name={"name"} placeholder={"Type track name"} />
            </Form.Row>
          </DialogContent>
          <DialogActions style={{ alignSelf: "flex-end" }}>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button color="primary" type={"submit"}>
              Save
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  )
}

export default TrackFormModal
