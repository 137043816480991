import React from "react"
import img1 from "../../../../assets/img/pages/how-to/appointments/1.png"

const HowToAppointments = () => {
  return (
    <div>
      <p className={"mb15"}>
        An appointment is a regular meeting which does not auto-cohort into
        multi-meetings.
      </p>
      <div className={"f aic fdc-xs"}>
        <div style={{ flex: 1 }}>
          <img src={img1} />
        </div>
        <div style={{ flex: 2 }} />
      </div>
    </div>
  )
}

export default HowToAppointments
