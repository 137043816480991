import React from "react"
import { SectionHeading } from "../uknow/uknowInfoPage"
import PlainLink from "../../../components/ui/link"
import { SOLUTIONS_COMPANIES } from "../../../library/constants/routes"
import img1 from "../../../assets/img/pages/solutions/products/1.png"
import img2 from "../../../assets/img/pages/solutions/products/2.png"
import img3 from "../../../assets/img/pages/solutions/products/3.png"
import img4 from "../../../assets/img/pages/solutions/products/4.png"
import img5 from "../../../assets/img/pages/solutions/products/5.png"
import img6 from "../../../assets/img/pages/solutions/products/6.png"
import img7 from "../../../assets/img/pages/solutions/products/7.png"
import img8 from "../../../assets/img/pages/solutions/products/8.png"

const SolutionsProductsPage = () => {
  return (
    <>
      <section className={"bg-white w-full py-8"}>
        <div className="container">
          <SectionHeading>Featured Products</SectionHeading>
          <img src={img1} alt="Teachers" className={"rounded-2xl mb-8"} />
          <SectionHeading>
            <span className={"italic"}>Create Your Listing</span>
            <br />
            Make Sure Shoppers Can Find You
          </SectionHeading>
          <p className={"text-center"}>
            After your company administrator for your company group makes their
            own profile, they make a company group. From the group they can make
            company products, each having their own product profile page in the
            featured products marketplace. This marketplace will be undergoing
            continuous upgrades so that you can opt to sell your product through
            Knowstory directly or keep it so that interested buyers contact you
            through the site. Descriptors and how you can “boost” and “blast”
            your product are under development.
          </p>
        </div>
      </section>
      <section className={"bg-grey7 w-full py-8"}>
        <div className="container">
          <div className="grid grid-cols-3 gap-8">
            <div className="col-span-1">
              <img src={img2} />
            </div>
            <div className="col-span-2">
              <img src={img3} className={"mb-8"} />
              <div className={"px-8 space-y-6"}>
                <h3 className={"font-medium text-xl mb-4"}>
                  What’s Different?
                </h3>
                <p>
                  <b>“Contact to Purchase” Button.</b> Members clicking this
                  button send their information to your group administrator to
                  contact them.
                </p>
                <p>
                  <b>Schedule Appointment.</b> Like other intelligent calendars,
                  any Knowstory user has a bookings link they can share for
                  others to schedule on their calendar and integration through
                  web-hook to Microsoft or Google calendars. The difference is
                  that Knowstory is also a social hub so that users can build
                  human networks for easier time coordination. Your featured
                  products can each have a Schedule Appointment function for
                  buyers to book with members of your group.
                </p>
                <p>
                  <b>Request Appointments.</b> Unlike other intelligent
                  calendars, Knowstory users can send requests for amounts of
                  time with no assigned date and time to members or non-members.
                  Co-members who are affiliated friends would just accept or
                  decline, and the AI would coordinate and set the appointment.
                  Non-members would be directed to select as if from a bookings
                  page or create an account and integrate.
                </p>
                <p>
                  <b>Holds for Scheduling Appointments.</b> A hold is an open
                  time across any multiple of days in a week that is bracketed
                  by a start and end time. It may have inside itself minus times
                  such as lunch breaks so that the AI will know not to set
                  meetings over that time as it is not open. Any member may make
                  a hold and assign it to outgoing request appointments, offer
                  labeled holds to members and non-members booking on their
                  calendar, and use holds in auto-cohorting Meets.
                </p>
                <p>
                  <b>Training.</b> If your company group offers training it
                  could affiliate a second group under it. The second group
                  would be a School created from some employee establishing an
                  “Educator” Account and being the admin of this second Group so
                  that your company can offer training and use all the same
                  features as a regular School.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={"bg-white w-full py-8"}>
        <div className="container">
          <div className="grid grid-cols-12 gap-8 items-center mb-8">
            <div className={"col-start-2 col-span-5"}>
              <img src={img4} />
            </div>
            <div className={"col-span-5"}>
              <img src={img5} />
            </div>
          </div>
        </div>
      </section>
      <section className={"bg-grey7 w-full py-8"}>
        <div className="container">
          <div className="grid grid-cols-12 gap-8 items-center">
            <div className={"col-start-2 col-span-10 space-y-6"}>
              <p>
                <b>Create Product Pages & Ad Tiles.</b> Creating your company
                product page and ad tile is FREE. Just first create your group
                and then your product tiles. It is best to create one product
                tile for <i>each product</i>.
              </p>
              <p>
                <b>Maximize Effectiveness by Posting Regularly.</b> Regular
                posting helps show your company is paying attention to Knowstory
                users. Mr. Know Story may also boost your post by listing it in
                our newsletter to all members.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={"bg-white w-full py-8"}>
        <div className="container">
          <div className="grid grid-cols-12 gap-8 items-center">
            <div className={"col-start-2 col-span-4"}>
              <img src={img6} />
            </div>
            <div className={"col-span-6"}>
              <img src={img7} />
            </div>
          </div>
        </div>
      </section>
      <section className={"bg-grey7 w-full py-8"}>
        <div className="container">
          <div className="grid grid-cols-12 gap-8 items-center">
            <div className={"col-start-2 col-span-10 space-y-6"}>
              <p>
                <b>A Chance to Give Specifics.</b> The product forms give you a
                chance to give a lot of specifics.
              </p>
              <p>
                <b>Set Pricing.</b> Under Market Specifics you can name your
                pricing approach – or if your product is Free or Freemium, leave
                it that way.
              </p>
              <p>
                <b>Option for Group Only.</b> If you have a closed company
                group, or school, you can mark your products to appear in your
                Group’s sidebar and a mini marketplace page all your own.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={"bg-white w-full py-8"}>
        <div className="container">
          <div className="grid grid-cols-12 gap-8 items-center">
            <div className={"col-start-3 col-span-8 flex justify-center"}>
              <img src={img8} />
            </div>
          </div>
        </div>
      </section>
      <section className={"bg-grey7 w-full py-8"}>
        <div className="container">
          <div className="grid grid-cols-12 gap-8 items-center">
            <div className={"col-start-2 col-span-10 space-y-6"}>
              <p>
                <b>Check your consumer-facing page.</b> After you make your
                pages, make sure to check them – and share them with your
                friends so that they rate your product.
              </p>
              <p>
                <b>Use Features.</b> To get folks visiting, make sure you made
                your pages offer Schedule Appointment with members of your
                group.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={"bg-white w-full py-8"}>
        <div className="container">
          <div className="grid grid-cols-12 gap-8 items-center">
            <div className={"col-start-2 col-span-10 space-y-6"}>
              <PlainLink to={SOLUTIONS_COMPANIES} bold>
                Also see Company Group Solutions
              </PlainLink>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SolutionsProductsPage
