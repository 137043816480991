import React from "react"
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress"
import styled from "@emotion/styled"

const Container = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
`

const UploadProgress = ({ fileType, progress }) => {
  const overlaidFileTypes = ['image', 'video']

  return overlaidFileTypes.includes(fileType) ? (
    <Container>
      <CircularProgress
        variant="determinate"
        value={progress}
        size={30}
        color="inherit"
      />
    </Container>
  ) : (
    <CircularProgress variant="determinate" value={progress} size={30} />
  )
}

export default UploadProgress
