import React from "react"
import logoDefault from "../../../../assets/img/knowmix_unavailable.svg"
import getErrorText from "../../../../library/constants/errorTypes"
import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"

const KnowmixError = ({ knowmix }) => {
  const restoreKnowmix = async () => {
    try {
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  return (
    <div className="common-page__content">
      <main className="common-page__main">
        <div className="box">
          <div className="box__heading">{knowmix.name}</div>
          <div className="box__content text-center">
            {knowmix.deleted && "Course deleted"}
            {knowmix.blocked && "Course blocked"}
          </div>
        </div>
        <div className="box p30 text-center">
          {knowmix.deleted && (
            <p className="color-black-54">This Course has been deleted.</p>
          )}
          {knowmix.blocked && (
            <p className="color-black-54">This Course has been blocked.</p>
          )}
          {knowmix.is_creator ? (
            <p className="color-black-54">
              You can{" "}
              <Link
                to={generatePath(routes.CONTACT_FORM)}
                className="link link--inline"
                onClick={restoreKnowmix}
              >
                restore your Course.
              </Link>
            </p>
          ) : (
            <p className="color-black-54">
              Information on this Course is unavailable.
            </p>
          )}
        </div>
      </main>
      <aside className="common-page__sidebar">
        <div className="box">
          <div className="box__content">
            <div className="product-logo">
              <img
                src={knowmix.logo || logoDefault}
                alt="Logo"
                className="product-logo__img"
              />
            </div>
          </div>
        </div>
      </aside>
    </div>
  )
}

export default KnowmixError
