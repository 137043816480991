export class Group {
  data = null

  constructor(data) {
    this.data = data
  }

  get fullAddress() {
    const { country, state, city, zip_code, address } = this.data

    return [address, city, state, country, zip_code].filter(Boolean).join(", ")
  }
}

export const getGroupAddress = (group) => {
  const { country, state, city, zip_code, address } = group

  return [address, city, state, country, zip_code].filter(Boolean).join(", ")
}
