import { useEffect, useState } from "react"
import { call } from "../../../../../../../../../library/networking/API"
import { SCHEDULE } from "../../../../../../../../../library/store/actions/types/calendarTypes"
import { useAlert } from "../../../../../../../../../hooks/useAlert"

export const useUnit = (id) => {
  const { errorAlert } = useAlert()
  const [dataReceived, setDataReceived] = useState(false)
  const [unit, setUnit] = useState(null)

  const getUnit = async (_id = id) => {
    try {
      const response = await call(SCHEDULE.UNITS.GET, { id: _id })

      const data = await response.data

      setUnit(data)
      setDataReceived(true)

      return data
    } catch (e) {
      errorAlert(e)
    }
  }

  useEffect(() => {
    if (id) {
      getUnit(id)
    }
  }, [])

  return {
    unit,
    getUnit,
    unitReceived: dataReceived,
  }
}
