import React from "react"
import SuperListPage from "../../../../../components/v2/layout/superListPage/superListPage"
import { getGroupLogo } from "../../../../../library/utils/getGroupLogo"
import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../../../library/constants/routes"
import UserFriendshipCard from "../../../../../components/v2/layout/cards/userFriendshipCard"
import { GROUPS } from "../../../../../library/store/actions/types/groupsTypes"
import { useGroup } from "../../../../../hooks/data/groups/useGroup"
import { useIdParam } from "../../../../../hooks/useIdParam"
import SEO from "../../../../../components/SEO"

const GroupMembers = () => {
  const id = useIdParam("groupId")
  const { group, isSuccess } = useGroup()

  return (
    <div className="common-page__content">
      <SEO title={"Group Members"} />
      <main className="common-page__main">
        <SuperListPage
          queryConfig={{
            key: [GROUPS.PARTICIPANTS.LIST],
            fetchParams: {
              group_id: id,
            },
            listKey: "users",
          }}
          headerProps={{
            title: "Members",
          }}
          getListItemComponent={({ item }) => (
            <UserFriendshipCard
              user={item}
              mutatedQueryKey={[GROUPS.PARTICIPANTS.LIST]}
            />
          )}
        />
      </main>

      <aside className="common-page__sidebar">
        {isSuccess && (
          <div className="box aside-header">
            <div className="box__content aside-header__content">
              <img
                src={getGroupLogo(group)}
                className="aside-header__img"
                alt={"Group logo"}
              />
              <div className="aside-header__info">
                <Link
                  to={generatePath(routes.GROUP, {
                    groupId: id,
                  })}
                  className="aside-header__name"
                >
                  {group.name}
                </Link>
                <div className="aside-header__help">back to page</div>
              </div>
            </div>
          </div>
        )}
      </aside>
    </div>
  )
}

export default GroupMembers
