import React from "react"
import {
  useCourse,
  useCourseMutations,
} from "../../../../../hooks/data/courses/courses"
import { generatePath, Link, useHistory } from "react-router-dom"
import * as routes from "../../../../../library/constants/routes"
import SettingsOutlined from "@material-ui/icons/SettingsOutlined"
import Button from "@material-ui/core/Button/Button"
import { Box } from "../../../../../components/tw/layout"
import PlainLink from "../../../../../components/ui/link"
import EntityOperationsBox from "../../../../../components/v2/controls/entityOperationsBox/entityOperationsBox"
import { useIdParam } from "../../../../../hooks/useIdParam"
import { useAlert } from "../../../../../hooks/useAlert"

const CourseSidebarButtons = () => {
  const history = useHistory()
  const { errorAlert } = useAlert()
  const knowmixId = useIdParam("knowmixId")
  const { course, canEdit, isAvailable, queryKey } = useCourse()
  const { startCourse } = useCourseMutations({ queryKey })

  const canContactTeacher = !!course?.teacher && course?.is_owned
  const canSeeGrades =
    [2, 3].includes(course?.type) && !!course?.steps?.find((s) => s.grade)

  const onStartCourse = async () => {
    try {
      await startCourse.mutateAsync({ id: course?.id })

      if (course?.steps?.length) {
        history.push({
          pathname: generatePath(routes.KNOWMIX, { knowmixId }),
          search: `?stepId=${course?.steps[0]?.id}`,
        })
      }
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <>
      {(canEdit || course?.is_product_admin || course?.is_creator) && (
        <div className="box">
          <div className="box__content">
            <Link
              to={generatePath(routes.KNOWMIX_SETTINGS, {
                knowmixId,
              })}
              className="link"
            >
              <SettingsOutlined className="color-black-38 mr8" /> Manage
            </Link>
          </div>
        </div>
      )}

      {!canEdit &&
        course?.type === 1 &&
        !!course?.steps?.length &&
        course?.is_finished && (
          <Button
            variant="contained"
            color="primary"
            onClick={onStartCourse}
            fullWidth
          >
            Start course
          </Button>
        )}

      {!canEdit && (canContactTeacher || canSeeGrades) && (
        <Box>
          <div className="grid grid-flow-row gap-2">
            {canContactTeacher && (
              <PlainLink
                to={{
                  pathname: routes.CONVERSATION,
                  search: `?userId=${course?.teacher?.id}`,
                }}
              >
                Contact teacher
              </PlainLink>
            )}
            {canSeeGrades && (
              <PlainLink
                to={generatePath(routes.KNOWMIX_GRADES, {
                  knowmixId,
                })}
              >
                My grades
              </PlainLink>
            )}
          </div>
        </Box>
      )}
      <EntityOperationsBox
        enableShares={false}
        enableBookmarks={false}
        enableReports={true}
        canReport={!course?.is_creator}
        isReported={course?.is_reported}
        operationParams={{ knowmix_id: course?.id }}
      />
    </>
  )
}

export default CourseSidebarButtons
