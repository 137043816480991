import { useQuery } from "@tanstack/react-query"
import { SEGMENTS } from "../../../../../../library/store/actions/types/groupsTypes"
import { call } from "../../../../../../library/networking/API"
import React from "react"
import { useIdParam } from "../../../../../../hooks/useIdParam"
import { groupBy } from "lodash"
import { weekDays } from "../../../../../../library/utils/timeConvert"
import { getObjectById } from "../../../../../../library/utils/arrays"
import { rotationTypes } from "../../../../../../library/constants/groupTypes"

export const useScheduleAssignment = ({ type }) => {
  const [filters, setFilters] = React.useState({
    schedule: "",
    grade: "",
    segment: "",
    section: "",
    learner: "",
  })
  const [filterErrors, setFilterErrors] = React.useState({
    schedule: false,
    grade: false,
    segment: false,
    section: false,
    learner: false,
  })

  const onFilterChange = (name) => (event) => {
    setFilters({
      ...filters,
      [name]: event.target.value,
    })
    setFilterErrors({
      ...filterErrors,
      [name]: !event.target.value,
    })
  }

  const onFilterChangeValue = (name) => (v) => {
    setFilters({
      ...filters,
      [name]: v,
    })
    setFilterErrors({
      ...filterErrors,
      [name]: !v,
    })
  }

  const [activeDay, setActiveDay] = React.useState(
    new Date().getDay().toString()
  )

  const blocksQueryKey = [
    SEGMENTS.SECTIONS.BLOCKS.LIST,
    {
      section_id: filters.section?.id,
      offset: 0,
      limit: 100,
      rotation: Number(activeDay),
    },
    "schedule-assignment-table",
  ]

  const {
    data: blocks,
    refetch: refetchBlocks,
    isSuccess: isBlocksSuccess,
  } = useQuery({
    queryKey: blocksQueryKey,
    queryFn: async (ctx) => {
      const response = await call(ctx.queryKey[0], ctx.queryKey[1])
      return response.data.blocks
    },
    refetchOnWindowFocus: false,
    staleTime: 60000,
    enabled: !!filters.section?.id,
  })

  const {
    data: tracks,
    refetch: refetchTracks,
    isSuccess: isTracksSuccess,
  } = useQuery({
    queryKey: [
      SEGMENTS.SECTIONS.TRACKS.LIST,
      { section_id: filters.section?.id, offset: 0, limit: 100 },
      "schedule-assignment-table",
    ],
    queryFn: async (ctx) => {
      const response = await call(ctx.queryKey[0], ctx.queryKey[1])
      return response.data.tracks
    },
    refetchOnWindowFocus: false,
    staleTime: 60000,
    enabled: !!filters.section?.id,
  })

  React.useEffect(() => {
    if (filters.section?.id) {
      refetchBlocks()
      refetchTracks()
    }
  }, [filters.section])

  React.useEffect(() => {
    if (isBlocksSuccess) {
      refetchBlocks()
    }
  }, [activeDay])

  const columns = React.useMemo(() => {
    return isBlocksSuccess && isTracksSuccess
      ? [
          { id: "tracks", label: "", width: 100 },
          ...blocks.map((b) => ({
            id: b.id,
            label: b.name,
            time: `${b.time_from} - ${b.time_to}`,
          })),
        ]
      : []
  }, [blocks, tracks])

  return {
    filters,
    onFilterChange,
    onFilterChangeValue,
    filterErrors,
    blocksQuery: { blocks, isBlocksSuccess, refetchBlocks, blocksQueryKey },
    tracksQuery: { tracks, isTracksSuccess, refetchTracks },
    sectionRotations: filters.section?.rotations,
    columns,
    activeDay,
    setActiveDay,
  }
}
