import React from "react"
import { useSelector } from "react-redux"
import { generatePath, Redirect, useParams } from "react-router-dom"
import { INCOMING_SOLICIT_APPOINTMENT_AUTHORIZED } from "../../../library/constants/routes"
import IncomingSolicitAppointmentFormModal from "../../main/solicitAppointments/IncomingSolicitAppointmentFormModal"

const IncomingSolicitAppointmentUnauthorized = () => {
  const { isLoggedIn } = useSelector((state) => state.auth)
  const { requestId } = useParams()

  console.log(requestId)

  return <IncomingSolicitAppointmentFormModal />
}

export default IncomingSolicitAppointmentUnauthorized
