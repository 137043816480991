import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import {
  setInviterGroup,
  setKnowcred,
  setMeetCreation,
  setNewFriend,
} from "../../../library/store/actions/creators/authCreators"
import "./sign-up.scss"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox/Checkbox"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import { Link, withRouter } from "react-router-dom"
import * as routes from "../../../library/constants/routes"
import Button from "@material-ui/core/Button"
import { withoutSpaces } from "../../../library/utils/validators"
import * as qs from "query-string"

class SignUp extends Component {
  state = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    agree: false,
    isOldEnough: false,
    knowcredId: null,
    newFriendId: null,
    meetHash: null,
    inviterGroupId: null,
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked })
  }

  processSignUp = (event) => {
    const { first_name, last_name, email, password } = this.state
    const { history } = this.props

    event.preventDefault()
    this.props.setKnowcred(this.state.knowcredId)
    this.props.setNewFriend(this.state.newFriendId)
    this.props.setMeetCreation(this.state.meetHash)
    this.props.setInviterGroup(this.state.inviterGroupId)
    history.push({
      pathname: routes.COMPLETE_SIGNUP,
      state: {
        first_name,
        last_name,
        email,
        password,
      },
    })
  }

  componentDidMount() {
    ValidatorForm.addValidationRule("withoutSpaces", (value) =>
      withoutSpaces(value)
    )
    this.setState({ ...this.props.location.state })

    const { newFriendId, knowcred, meetHash, inviterGroupId } = qs.parse(
      this.props.location.search
    )

    if (newFriendId) {
      this.setState({ newFriendId: parseInt(newFriendId) })
    }

    if (knowcred) {
      this.setState({ knowcredId: parseInt(knowcred, 10) })
    }

    if (meetHash) {
      this.setState({ meetHash })
    }

    if (inviterGroupId) {
      this.setState({ inviterGroupId: parseInt(inviterGroupId, 10) })
    }
  }

  render() {
    const { first_name, last_name, email, password, agree, isOldEnough } =
      this.state

    const TermsMixedLabel = () => {
      return (
        <div>
          I have read and agree to{" "}
          <Link
            to={routes.TERMS_AND_CONDITIONS}
            target="_blank"
            className="link"
          >
            the Terms and Conditions
          </Link>
        </div>
      )
    }

    const disabled = !agree || !isOldEnough ? "disabled" : ""

    return (
      <div className="sign-up-form hero-form">
        <div className="sign-up-form__header">
          <h2 className="sign-up-form__heading">Sign Up</h2>
        </div>
        <ValidatorForm
          className="sign-up-form__form"
          onSubmit={this.processSignUp}
        >
          <div className="sign-up-form__field-wrapper">
            <TextValidator
              name="first_name"
              value={first_name}
              label="First name *"
              className="m-text-field"
              margin="none"
              fullWidth
              helperText="&nbsp;"
              validators={["required", "maxStringLength:75"]}
              errorMessages={[
                "Field is required",
                "Max length is 75 characters",
              ]}
              onChange={(first_name) =>
                this.setState({ first_name: first_name.target.value })
              }
            />
          </div>
          <div className="sign-up-form__field-wrapper">
            <TextValidator
              name="last_name"
              value={last_name}
              label="Last name *"
              className="m-text-field"
              margin="none"
              fullWidth
              helperText="&nbsp;"
              validators={["required", "maxStringLength:75"]}
              errorMessages={[
                "Field is required",
                "Max length is 75 characters",
              ]}
              onChange={(last_name) =>
                this.setState({ last_name: last_name.target.value })
              }
            />
          </div>
          <div className="sign-up-form__field-wrapper">
            <TextValidator
              type="text"
              name="email"
              value={email}
              label="Email *"
              className="m-text-field"
              margin="none"
              fullWidth
              helperText="&nbsp;"
              validators={["required", "isEmail", "maxStringLength:100"]}
              errorMessages={[
                "Field is required",
                "Email is not valid",
                "Max length is 100 characters",
              ]}
              onChange={(email) => this.setState({ email: email.target.value })}
            />
          </div>
          <div className="sign-up-form__field-wrapper">
            <TextValidator
              type="password"
              name="password"
              value={password}
              label="Password *"
              className="m-text-field"
              margin="none"
              fullWidth
              helperText="&nbsp;"
              validators={[
                "withoutSpaces",
                "required",
                "minStringLength:8",
                "maxStringLength:32",
              ]}
              errorMessages={[
                "You can`t use space symbol",
                "Field is required",
                "Min length is 8 characters",
                "Max field length is 32 characters",
              ]}
              onChange={(password) =>
                this.setState({ password: password.target.value })
              }
            />
          </div>

          <div className="full-width mb20">
            <div className="checkbox m-checkbox">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isOldEnough}
                    value={"isOldEnough"}
                    disableRipple
                    onChange={this.handleChange("isOldEnough")}
                  />
                }
                label="I am at least 13 years of age or have my parents approval to use KnowStory"
              />
            </div>

            <div className="checkbox m-checkbox">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={agree}
                    value={"agree"}
                    disableRipple
                    onChange={this.handleChange("agree")}
                  />
                }
                label={<TermsMixedLabel />}
              />
            </div>
          </div>

          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={!isOldEnough || !agree}
          >
            Sign Up
          </Button>
        </ValidatorForm>
      </div>
    )
  }
}

const mapState = ({ auth }) => ({ auth })
const mapDispatch = (dispatch) =>
  bindActionCreators(
    { setKnowcred, setNewFriend, setMeetCreation, setInviterGroup },
    dispatch
  )
export default connect(mapState, mapDispatch)(withRouter(SignUp))
