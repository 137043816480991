import React, { Component } from "react"
import KnowCredListHeader from "../knowCredListHeader"
import * as routes from "../../../../library/constants/routes"
import GridList from "../../../../components/ui/grid"
import KnowCredCard from "../knowCredCard"
import { bindActionCreators } from "redux"
import { getFriendsList } from "../../../../library/store/actions/creators/friendsCreators"
import {
  knowCredClear,
  knowCredsList,
  knowCredsListClear,
  sharedKnowCredsList,
} from "../../../../library/store/actions/creators/knowCredsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import * as qs from "query-string"
import Loader from "../../../../components/ui/loader"
import withScroll from "../../../../hocs/withScroll/withScroll"
import { LIMIT } from "../../../../library/constants/limits"
import { generatePath } from "react-router-dom"
import getErrorText from "../../../../library/constants/errorTypes"
import { compact } from "lodash"
import composeQuery from "../../../../library/utils/composeQuery"

const validatedRoutes = [
  routes.KNOWCRED_VALIDATED,
  routes.KNOWCRED_SOCIAL_VALIDATED,
  routes.KNOWCRED_EXTRA_VALIDATED,
  routes.KNOWCRED_GRADES_VALIDATED,
  routes.KNOWCRED_CERTIFICATES_VALIDATED,
  routes.KNOWCRED_DIPLOMAS_VALIDATED,
]

const grantedRoutes = [
  routes.KNOWCRED_GRANTED,
  routes.KNOWCRED_SOCIAL_GRANTED,
  routes.KNOWCRED_EXTRA_GRANTED,
  routes.KNOWCRED_GRADES_GRANTED,
  routes.KNOWCRED_CERTIFICATES_GRANTED,
  routes.KNOWCRED_DIPLOMAS_GRANTED,
]

const awardedRoutes = [
  routes.KNOWCRED_AWARDED,
  routes.KNOWCRED_EXTRA_AWARDED,
  routes.KNOWCRED_GRADES_AWARDED,
  routes.KNOWCRED_CERTIFICATES_AWARDED,
  routes.KNOWCRED_DIPLOMAS_AWARDED,
]

const validRoutes = [
  routes.KNOWCRED_VALID,
  routes.KNOWCRED_EXTRA_VALID,
  routes.KNOWCRED_GRADES_VALID,
  routes.KNOWCRED_CERTIFICATES_VALID,
  routes.KNOWCRED_DIPLOMAS_VALID,
]

class KnowCredList extends Component {
  state = {
    list: [],
    query: "",
    knowCredsNumber: "",
    validatedKnowCredsNumber: "",
    type: "my-profile",
    dataReceived: false,
    initialRender: true,
    focusInput: true,
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }

  async componentDidMount() {
    const {
      auth: { userData },
      match: { params, path },
    } = this.props

    const list_type =
      parseInt(params.userId) === userData.id || validatedRoutes.includes(path)
        ? "my"
        : "other"

    const offset = () => {
      return parseInt(params.userId) === userData.id ||
        validatedRoutes.includes(path)
        ? this.props.knowCreds.myKnowCredsList.length
        : this.props.knowCreds.knowCredsList.length
    }

    try {
      await this.getList({
        list_type,
        user_id: parseInt(params.userId),
        offset: offset(),
        limit: LIMIT,
      })
      this.setState({
        type: list_type === "my" ? "my-profile" : "other-profile",
        list:
          parseInt(params.userId) === userData.id ||
          validatedRoutes.includes(path)
            ? this.props.knowCreds.myKnowCredsList
            : this.props.knowCreds.knowCredsList,
        knowCredsNumber: this.props.knowCreds.knowCredsNumber,
        validatedKnowCredsNumber: this.props.knowCreds.validatedKnowCredsNumber,
        dataReceived: true,
        initialRender: false,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      auth: { userData },
      location: { search },
      match: { path, params },
    } = this.props

    const list_type =
      parseInt(params.userId) === userData.id || validatedRoutes.includes(path)
        ? "my"
        : "other"

    const offset = () => {
      return parseInt(params.userId) === userData.id ||
        validatedRoutes.includes(path)
        ? this.props.knowCreds.myKnowCredsList.length
        : this.props.knowCreds.knowCredsList.length
    }

    try {
      if (
        path !== prevProps.match.path ||
        params.userId !== prevProps.match.params.userId ||
        search !== prevProps.location.search
      ) {
        this.setState({ list: [], dataReceived: false, initialRender: true })
        await this.props.knowCredsListClear()
        await this.getList({
          list_type,
          user_id: parseInt(params.userId),
          offset: offset(),
          limit: LIMIT,
        })
        this.setState({
          type: list_type === "my" ? "my-profile" : "other-profile",
          list:
            parseInt(params.userId) === userData.id ||
            validatedRoutes.includes(path)
              ? this.props.knowCreds.myKnowCredsList
              : this.props.knowCreds.knowCredsList,
          knowCredsNumber: this.props.knowCreds.knowCredsNumber,
          validatedKnowCredsNumber:
            this.props.knowCreds.validatedKnowCredsNumber,
          dataReceived: true,
          initialRender: false,
        })
      }
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  async componentWillUnmount() {
    await this.props.knowCredsListClear()
    await this.props.knowCredClear()
  }

  getList = async ({ list_type, user_id, offset, limit }) => {
    const {
      match: { path },
      auth: { userData },
      location: { search },
    } = this.props
    const { name: title, order } = qs.parse(search)

    const type = () => {
      switch (path) {
        case routes.KNOWCRED_SOCIAL:
        case routes.KNOWCRED_SOCIAL_VALIDATED:
        case routes.KNOWCRED_SOCIAL_GRANTED:
          return 1

        case routes.KNOWCRED_EXTRA:
        case routes.KNOWCRED_EXTRA_VALIDATED:
        case routes.KNOWCRED_EXTRA_GRANTED:
        case routes.KNOWCRED_EXTRA_AWARDED:
        case routes.KNOWCRED_EXTRA_VALID:
          return 2

        case routes.KNOWCRED_GRADES:
        case routes.KNOWCRED_GRADES_VALIDATED:
        case routes.KNOWCRED_GRADES_GRANTED:
        case routes.KNOWCRED_GRADES_AWARDED:
        case routes.KNOWCRED_GRADES_VALID:
          return 3

        case routes.KNOWCRED_CERTIFICATES:
        case routes.KNOWCRED_CERTIFICATES_VALIDATED:
        case routes.KNOWCRED_CERTIFICATES_GRANTED:
        case routes.KNOWCRED_CERTIFICATES_AWARDED:
        case routes.KNOWCRED_CERTIFICATES_VALID:
          return 4

        case routes.KNOWCRED_DIPLOMAS:
        case routes.KNOWCRED_DIPLOMAS_VALIDATED:
        case routes.KNOWCRED_DIPLOMAS_GRANTED:
        case routes.KNOWCRED_DIPLOMAS_AWARDED:
        case routes.KNOWCRED_DIPLOMAS_VALID:
          return 5

        default:
          return undefined
      }
    }

    const status = () => {
      switch (path) {
        case routes.KNOWCRED_GRANTED:
        case routes.KNOWCRED_SOCIAL_GRANTED:
        case routes.KNOWCRED_EXTRA_GRANTED:
        case routes.KNOWCRED_GRADES_GRANTED:
        case routes.KNOWCRED_CERTIFICATES_GRANTED:
        case routes.KNOWCRED_DIPLOMAS_GRANTED:
          return 1

        case routes.KNOWCRED_AWARDED:
        case routes.KNOWCRED_EXTRA_AWARDED:
        case routes.KNOWCRED_GRADES_AWARDED:
        case routes.KNOWCRED_CERTIFICATES_AWARDED:
        case routes.KNOWCRED_DIPLOMAS_AWARDED:
          return 2

        case routes.KNOWCRED_VALID:
        case routes.KNOWCRED_EXTRA_VALID:
        case routes.KNOWCRED_GRADES_VALID:
        case routes.KNOWCRED_CERTIFICATES_VALID:
        case routes.KNOWCRED_DIPLOMAS_VALID:
          return 3

        case routes.KNOWCRED_VALIDATED:
        case routes.KNOWCRED_EXTRA_VALIDATED:
        case routes.KNOWCRED_GRADES_VALIDATED:
        case routes.KNOWCRED_CERTIFICATES_VALIDATED:
        case routes.KNOWCRED_DIPLOMAS_VALIDATED:
          return 4

        default:
          return 0
      }
    }

    if (validatedRoutes.includes(path)) {
      await this.props.knowCredsList({
        type: type(),
        status_type: status(),
        title: title || undefined,
        list_type,
        user_id: userData.id,
        offset,
        limit,
        order: order ? order.toUpperCase() : undefined,
      })
    } else {
      await this.props.knowCredsList({
        type: type(),
        status_type: status(),
        title: title || undefined,
        list_type,
        user_id,
        offset,
        limit,
        order: order ? order.toUpperCase() : undefined,
      })
    }
  }

  composeSearchQuery = (name) => {
    const { history, location } = this.props
    const { order } = qs.parse(location.search)

    composeQuery({
      fields: { name: name || undefined, order },
      history,
      path: location.pathname,
    })
  }

  composeSortQuery = (order) => {
    const { history, location } = this.props
    const { name } = qs.parse(location.search)

    composeQuery({
      fields: { name, order: order || undefined },
      history,
      path: location.pathname,
    })
  }

  onScroll = async () => {
    const {
      auth: { userData },
      match: { params, path },
    } = this.props

    if (!this.state.dataReceived) return

    this.setState({ dataReceived: false })

    const list_type =
      parseInt(params.userId) === userData.id || validatedRoutes.includes(path)
        ? "my"
        : "other"

    const offset = () => {
      return parseInt(params.userId) === userData.id ||
        validatedRoutes.includes(path)
        ? this.props.knowCreds.myKnowCredsList.length
        : this.props.knowCreds.knowCredsList.length
    }

    try {
      await this.getList({
        list_type,
        user_id: parseInt(params.userId),
        offset: offset(),
        limit: LIMIT,
      })
      this.setState({
        type: list_type === "my" ? "my-profile" : "other-profile",
        list:
          parseInt(params.userId) === userData.id ||
          validatedRoutes.includes(path)
            ? this.props.knowCreds.myKnowCredsList
            : this.props.knowCreds.knowCredsList,
        knowCredsNumber: this.props.knowCreds.knowCredsNumber,
        validatedKnowCredsNumber: this.props.knowCreds.validatedKnowCredsNumber,
        dataReceived: true,
        initialRender: false,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  render() {
    const {
      match: { params, path },
      auth: { userData },
    } = this.props
    const { list, dataReceived, initialRender } = this.state

    const composePath = () => {
      switch (path) {
        case routes.KNOWCRED:
        case routes.KNOWCRED_VALIDATED:
        case routes.KNOWCRED_GRANTED:
        case routes.KNOWCRED_AWARDED:
        case routes.KNOWCRED_VALID:
          return {
            all: routes.KNOWCRED,
            validated: routes.KNOWCRED_VALIDATED,
            granted: routes.KNOWCRED_GRANTED,
            awarded: routes.KNOWCRED_AWARDED,
            valid: routes.KNOWCRED_VALID,
          }

        case routes.KNOWCRED_SOCIAL:
        case routes.KNOWCRED_SOCIAL_VALIDATED:
        case routes.KNOWCRED_SOCIAL_GRANTED:
          return {
            all: routes.KNOWCRED_SOCIAL,
            validated: routes.KNOWCRED_SOCIAL_VALIDATED,
            granted: routes.KNOWCRED_SOCIAL_GRANTED,
          }

        case routes.KNOWCRED_EXTRA:
        case routes.KNOWCRED_EXTRA_VALIDATED:
        case routes.KNOWCRED_EXTRA_GRANTED:
        case routes.KNOWCRED_EXTRA_AWARDED:
        case routes.KNOWCRED_EXTRA_VALID:
          return {
            all: routes.KNOWCRED_EXTRA,
            validated: routes.KNOWCRED_EXTRA_VALIDATED,
            granted: routes.KNOWCRED_EXTRA_GRANTED,
            awarded: routes.KNOWCRED_EXTRA_AWARDED,
            valid: routes.KNOWCRED_EXTRA_VALID,
          }

        case routes.KNOWCRED_GRADES:
        case routes.KNOWCRED_GRADES_VALIDATED:
        case routes.KNOWCRED_GRADES_GRANTED:
        case routes.KNOWCRED_GRADES_AWARDED:
        case routes.KNOWCRED_GRADES_VALID:
          return {
            all: routes.KNOWCRED_GRADES,
            validated: routes.KNOWCRED_GRADES_VALIDATED,
            granted: routes.KNOWCRED_GRADES_GRANTED,
            awarded: routes.KNOWCRED_GRADES_AWARDED,
            valid: routes.KNOWCRED_GRADES_VALID,
          }

        case routes.KNOWCRED_CERTIFICATES:
        case routes.KNOWCRED_CERTIFICATES_VALIDATED:
        case routes.KNOWCRED_CERTIFICATES_GRANTED:
        case routes.KNOWCRED_CERTIFICATES_AWARDED:
        case routes.KNOWCRED_CERTIFICATES_VALID:
          return {
            all: routes.KNOWCRED_CERTIFICATES,
            validated: routes.KNOWCRED_CERTIFICATES_VALIDATED,
            granted: routes.KNOWCRED_CERTIFICATES_GRANTED,
            awarded: routes.KNOWCRED_CERTIFICATES_AWARDED,
            valid: routes.KNOWCRED_CERTIFICATES_VALID,
          }

        case routes.KNOWCRED_DIPLOMAS:
        case routes.KNOWCRED_DIPLOMAS_VALIDATED:
        case routes.KNOWCRED_DIPLOMAS_GRANTED:
        case routes.KNOWCRED_DIPLOMAS_AWARDED:
        case routes.KNOWCRED_DIPLOMAS_VALID:
          return {
            all: routes.KNOWCRED_DIPLOMAS,
            validated: routes.KNOWCRED_DIPLOMAS_VALIDATED,
            granted: routes.KNOWCRED_DIPLOMAS_GRANTED,
            awarded: routes.KNOWCRED_DIPLOMAS_AWARDED,
            valid: routes.KNOWCRED_DIPLOMAS_VALID,
          }
      }
    }

    const generateUserId = validatedRoutes.includes(path)
      ? userData.id
      : params.userId

    const menuItems = compact([
      {
        label: "All",
        path: generatePath(composePath().all, { userId: generateUserId }),
        // counter: this.state.knowCredsNumber
        counter: "",
      },
      {
        label: "Granted",
        path: generatePath(composePath().granted, { userId: generateUserId }),
        // counter: this.state.validatedKnowCredsNumber
        counter: "",
      },
      !!composePath().awarded && {
        label: "Awarded",
        path: generatePath(composePath().awarded, { userId: generateUserId }),
        // counter: this.state.validatedKnowCredsNumber
        counter: "",
      },
      !!composePath().valid && {
        label: "Valid",
        path: generatePath(composePath().valid, { userId: generateUserId }),
        // counter: this.state.validatedKnowCredsNumber
        counter: "",
      },
      (validatedRoutes.includes(path) ||
        parseInt(params.userId) === userData.id) && {
        label: "Shared",
        path: composePath().validated,
        counter: "",
      },
    ])

    const composeNoResultsLabel = () => {
      if (validatedRoutes.includes(path)) {
        return "You don't have shared Creds yet"
      }

      if (parseInt(params.userId) === userData.id) {
        if (grantedRoutes.includes(path)) {
          return "You don't have granted Creds yet"
        } else if (awardedRoutes.includes(path)) {
          return "You don't have awarded Creds yet"
        } else if (validRoutes.includes(path)) {
          return "You don't have valid Creds yet"
        } else {
          return "You don't have Creds yet"
        }
      }

      if (this.props.person.personData) {
        if (grantedRoutes.includes(path)) {
          return `${this.props.person.personData.first_name} doesn't have granted Creds yet`
        } else if (awardedRoutes.includes(path)) {
          return `${this.props.person.personData.first_name} doesn't have awarded Creds yet`
        } else if (validRoutes.includes(path)) {
          return `${this.props.person.personData.first_name} doesn't have valid Creds yet`
        } else {
          return `${this.props.person.personData.first_name} doesn't have Creds yet`
        }
      }
    }

    const tabs = [
      {
        label: "All",
        path: generatePath(composePath().all, { userId: generateUserId }),
        // counter: this.state.knowCredsNumber
        counter: "",
      },
      {
        label: "Granted",
        path: generatePath(composePath().granted, { userId: generateUserId }),
        // counter: this.state.validatedKnowCredsNumber
        counter: "",
      },
      !!composePath().awarded && {
        label: "Awarded",
        path: generatePath(composePath().awarded, { userId: generateUserId }),
        // counter: this.state.validatedKnowCredsNumber
        counter: "",
      },
      !!composePath().valid && {
        label: "Valid",
        path: generatePath(composePath().valid, { userId: generateUserId }),
        // counter: this.state.validatedKnowCredsNumber
        counter: "",
      },
      (validatedRoutes.includes(path) ||
        parseInt(params.userId) === userData.id) && {
        label: "Shared",
        path: composePath().validated,
        counter: "",
      },
    ].filter(Boolean)

    return (
      <>
        {/*<ListPageTabsHeader*/}
        {/*  tabs={tabs}*/}
        {/*  button={{*/}
        {/*    title: 'Create KnowCred',*/}
        {/*    component: Link,*/}
        {/*    props: { to: routes.KNOWCRED_CREATE },*/}
        {/*  }}*/}
        {/*  noResults={!list.length && !initialRender}*/}
        {/*  noResultsLabel={composeNoResultsLabel()}*/}
        {/*/>*/}
        <KnowCredListHeader
          menuItems={menuItems}
          search={this.composeSearchQuery}
          sort={this.composeSortQuery}
          noResults={!list.length && !initialRender}
          noResultsLabel={composeNoResultsLabel()}
        />
        <GridList>
          {list.map((knowCred) => (
            <KnowCredCard knowCred={knowCred} key={knowCred.id} />
          ))}
        </GridList>
        {!dataReceived && <Loader />}
      </>
    )
  }
}

const mapStateToProps = ({ auth, person, knowCreds }) => ({
  auth,
  person,
  knowCreds,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getFriendsList,
      knowCredsList,
      sharedKnowCredsList,
      knowCredsListClear,
      knowCredClear,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withScroll(KnowCredList)))
