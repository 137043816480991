import React from "react"
import PlainLink from "../link/plainLink"
import * as routes from "../../../library/constants/routes"

const LinksList = ({ array, searchIn, searchParam }) => {
  const url = () => {
    switch (searchIn) {
      case "marketplace":
        return routes.MARKETPLACE + `?${searchParam}=`

      default:
        return ""
    }
  }
  return (
    <div className="f aic fww">
      {array.map((item, i) => {
        return (
          <pre className="f" key={item.id}>
            <PlainLink to={`${url() + item.id}`}>{item.name}</PlainLink>
            {i < array.length - 1 && ", "}
          </pre>
        )
      })}
    </div>
  )
}

export default LinksList;
