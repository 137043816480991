import React, { Component } from "react"
import { generatePath, Link } from "react-router-dom"
import userAvatarDefault from "../../../../../assets/img/user_avatar.svg"
import * as routes from "../../../../../library/constants/routes"
import BookmarkedItemDropdown from "../bookmarkedItemDropdown/bookmarkedItemDropdown"
import { userRolesIds } from "../../../../../library/constants/userRoles"

class BookmarkedUser extends Component {
  state = {
    deleted: false,
  }

  deleteBookmark = () => {
    const { user } = this.props
    this.props
      .deleteBookmark({ user_id: user.id })
      .then(this.setState({ deleted: true }))
  }

  render() {
    const { user } = this.props
    const { deleted } = this.state

    if (!user || deleted) return null

    const photoUrl = user.photo !== "" ? user.photo : userAvatarDefault
    const fullName = `${user.first_name} ${user.last_name}`
    const profileUrl = generatePath(routes.USER, { userId: user.id })

    return (
      <div className="box list-item">
        <div className="f aic full-width">
          <Link to={profileUrl} className="list-item__img">
            <img src={photoUrl} alt={fullName} />
          </Link>
          <div className="list-item__info">
            <span className="color-black-38 font-13">
              {userRolesIds[user.role].name}
            </span>
            <Link to={profileUrl} className="link list-item__name">
              {fullName}
            </Link>
            {/*<span className="list-item__description">{!!user.role && userRolesIds[user.role].name}</span>*/}

            {!!user.education && (
              <span>
                {user.education && user.education.name
                  ? user.education.name
                  : ""}
                {user.education &&
                user.education.name &&
                user.education.department
                  ? ", "
                  : ""}
                {user.education && user.education.department
                  ? user.education.department
                  : ""}
              </span>
            )}

            {!!user.work && (
              <span>
                {user.work && user.work.name ? user.work.name : ""}
                {user.work && user.work.name && user.work.role ? ", " : ""}
                {user.work && user.work.role ? user.work.role : ""}
              </span>
            )}

            {/*{user.role === 1 ? (*/}
            {/*    <span>*/}
            {/*        {user.education && user.education.name ? user.education.name : ''}*/}
            {/*        {user.education && user.education.name && user.education.department ? ', ' : ''}*/}
            {/*        {user.education && user.education.department ? user.education.department : ''}*/}
            {/*    </span>*/}
            {/*) : (*/}
            {/*    <span>*/}
            {/*        {user.work && user.work.name ? user.work.name : ''}*/}
            {/*        {user.work && user.work.name && user.work.role ? ', ' : ''}*/}
            {/*        {user.work && user.work.role ? user.work.role : ''}*/}
            {/*    </span>*/}
            {/*)}*/}
            <span>
              {user.city ? user.city : ""}
              {user.city && user.country ? ", " : ""}
              {user.country ? user.country : ""}
            </span>
          </div>
        </div>

        <div className="list-item__control-panel">
          <BookmarkedItemDropdown
            deleteBookmark={this.deleteBookmark}
            bookmarkFoldersList={() =>
              this.props.bookmarkFoldersList(user.bookmark.id)
            }
            addToFolder={(folders) =>
              this.props.addToFolder({ bookmark_id: user.bookmark.id, folders })
            }
            bookmarksFoldersListClear={() =>
              this.props.bookmarksFoldersListClear()
            }
          />
        </div>
      </div>
    )
  }
}

export default BookmarkedUser;
