import { NOTIFICATION } from "../types/notificationTypes"
import { call } from "../../../networking/API"

export const getNotificationList = ({ limit = 20, offset = 0 }) => {
  return async (dispatch, getState) => {
    const { token } = getState().auth.userData
    const response = await call(NOTIFICATION.LIST, { token, limit, offset })
    if (response.status === 200) {
      const notifications = response.data.notifications
      dispatch({
        type: NOTIFICATION.LIST,
        payload: notifications,
      })
    } else {
      throw response.error
    }
  }
}

export const getNotificationSmallList = ({ limit = 5, offset = 0 }) => {
  return async (dispatch, getState) => {
    const { token } = getState().auth.userData
    const response = await call(NOTIFICATION.LIST, { token, limit, offset })
    if (response.status === 200) {
      const notifications = response.data.notifications
      dispatch({
        type: NOTIFICATION.SMALL_LIST,
        payload: notifications,
      })
    } else {
      throw response.error
    }
  }
}

export const getNotificationCount = () => {
  return async (dispatch, getState) => {
    const { token } = getState().auth.userData
    const response = await call(NOTIFICATION.GET_NOTIFICATION_COUNT, { token })
    if (response.status === 200) {
      const { number } = response.data
      dispatch({
        type: NOTIFICATION.GET_NOTIFICATION_COUNT,
        payload: number,
      })
    } else {
      throw response.error
    }
  }
}

export const getChatsCount = () => {
  return async (dispatch, getState) => {
    const { token } = getState().auth.userData
    const response = await call(NOTIFICATION.GET_CHATS_COUNT, { token })
    if (response.status === 200) {
      const { number } = response.data
      dispatch({
        type: NOTIFICATION.GET_CHATS_COUNT,
        payload: number,
      })
    } else {
      throw response.error
    }
  }
}

export const incrementNotificationCount = (type, inc, chat) => {
  return (dispatch) => {
    const storage = localStorage.getItem("activeChats")
    let isActiveChat
    if (storage && chat) {
      const activeChats = JSON.parse(storage)
      if (activeChats.find((c) => c === chat.id)) {
        isActiveChat = true
      }
    }
    switch (type) {
      case 1:
        return dispatch({
          type: NOTIFICATION.INCREMENT_NOTIFICATION_COUNT,
          payload: inc,
        })
      case 2:
        return dispatch({
          type: NOTIFICATION.INCREMENT_CHATS_COUNT,
          payload: isActiveChat ? 0 : inc,
        })
      default:
        return
    }
  }
}

export const decrementChatsCount = () => {
  return {
    type: NOTIFICATION.DECREMENT_CHATS_COUNT,
  }
}

export const clearChatsCount = () => {
  return {
    type: NOTIFICATION.CLEAR_CHATS_COUNT,
  }
}

export const clearNotificationCount = () => {
  return {
    type: NOTIFICATION.CLEAR_NOTIFICATIONS_COUNT,
  }
}

export const clearUnreadNotificationCount = () => {
  return {
    type: NOTIFICATION.CLEAR_UNREAD_COUNT,
  }
}

export const updateNotificationList = ({ limit = 20, offset = 0 }) => {
  return async (dispatch, getState) => {
    const { token } = getState().auth.userData
    const response = await call(NOTIFICATION.LIST, { token, limit, offset })
    if (response.status === 200) {
      const notifications = response.data.notifications
      dispatch({
        type: NOTIFICATION.UPDATE,
        payload: notifications,
      })
    } else {
      throw response.error
    }
  }
}

export const deleteNotification = ({ id }) => {
  return async (dispatch, getState) => {
    const { token } = getState().auth.userData
    const response = await call(NOTIFICATION.DELETE_NOTIFICATION, { token, id })
    if (response.status === 200) {
      dispatch({
        type: NOTIFICATION.DELETE_NOTIFICATION,
      })
    } else {
      throw response.error
    }
  }
}

export const clearNotificationList = () => {
  return (dispatch) => {
    dispatch({
      type: NOTIFICATION.CLEAR_LIST,
    })
  }
}

export const clearNotificationSmallList = () => {
  return (dispatch) => {
    dispatch({
      type: NOTIFICATION.CLEAR_SMALL_LIST,
    })
  }
}

export const decrementUnreadCount = (n = 0) => {
  return {
    type: NOTIFICATION.DECREMENT_UNREAD_COUNT,
    payload: n,
  }
}
