import React from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import HoldsPicker from "../../../../../components/v2/inputs/pickers/HoldsPicker"
import { USER } from "../../../../../library/store/actions/types/authTypes"

const AssignHoldToMeetDialog = ({ onClose, open, onSubmit }) => {
  const [hold, setHold] = React.useState(null)
  return (
    <Dialog
      aria-labelledby="calendar-form-dialog"
      onClose={onClose}
      open={open}
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title" className="text-center">
        Assign Hold to Meet
      </DialogTitle>
      <DialogContent>
        <div className="f jcc">
          <div className="form form--modal">
            <HoldsPicker
              required
              value={hold}
              onChange={setHold}
              requestConfig={{
                fetchConfig: {
                  apiUrl: USER.HOLDS.LIST,
                },
                key: "list",
              }}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          onClick={() => onSubmit(hold.id)}
          disabled={!hold}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AssignHoldToMeetDialog
