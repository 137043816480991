import React, { useEffect, useState } from "react"
import getErrorText from "../../../../../../../../../library/constants/errorTypes"
import { bindActionCreators } from "redux"
import {
  clearCurrentScheduleSubject,
  getCalendar,
  getScheduleSubject,
} from "../../../../../../../../../library/store/actions/creators/calendarCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { withRouter } from "react-router-dom"
import Dialog from "@material-ui/core/Dialog/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import Loader from "../../../../../../../../../components/ui/loader"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import { getObjectById } from "../../../../../../../../../library/utils/arrays"
import { grades as gradesList } from "../../../../../../../../../library/constants/educationalDictionary"

const SubjectDialog = (props) => {
  const {
    calendar: { currentSubject },
  } = props

  const [dataReceived, setDataReceived] = useState(false)
  useEffect(() => {
    const getSubject = async () => {
      try {
        await props.getScheduleSubject({ id: props.activeSubject })
        setDataReceived(true)
      } catch ({ error }) {
        props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
      }
    }

    getSubject()

    // return props.clearCurrentScheduleSubject;
  }, [])

  return (
    <Dialog
      aria-labelledby="create-record-dialog"
      onClose={props.onClose}
      open={props.open}
    >
      <DialogTitle id="alert-dialog-title" className="text-center">
        Subject details
      </DialogTitle>
      <DialogContent>
        {dataReceived ? (
          <div className="f jcc full-width">
            <table className="product-details product-details--invenstory-record">
              <tbody>
                <tr className="product-details__row">
                  <td className="product-details__label">Name</td>
                  <td className="product-details__value">
                    {currentSubject.name}
                  </td>
                </tr>

                {!!currentSubject.description.length && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Description</td>
                    <td className="product-details__value">
                      {currentSubject.description}
                    </td>
                  </tr>
                )}

                {!!currentSubject?.calendar && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Calendar</td>
                    <td className="product-details__value">
                      {currentSubject.calendar.name}
                    </td>
                  </tr>
                )}

                <tr className="product-details__row">
                  <td className="product-details__label">Grade</td>
                  <td className="product-details__value">
                    {getObjectById(gradesList, currentSubject.grade).name}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <Loader />
        )}
      </DialogContent>
      <DialogActions>
        <Button type="submit" color="primary" onClick={props.onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = ({ auth, groups, calendar }) => ({
  auth,
  groups,
  calendar,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCalendar,
      getScheduleSubject,
      clearCurrentScheduleSubject,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(SubjectDialog)))
