import Loader from "../../../ui/loader"
import Accordion from "../../../ui/accordion/accordion"
import ErrorBoundary from "../../ErrorBoundary"
import { PlainButton } from "../../controls"
import PlainLink from "../../../ui/link"
import { key } from "../../../../library/utils/generateKey"

const SidebarEntityInfo = ({
  entityName = "",
  records = [],
  isLoading = false,
}) => {
  return (
    <Accordion title={`${entityName} info`} defaultOpen inSidebar>
      {!isLoading ? (
        <ErrorBoundary message={"Unable to display info"}>
          <Info records={records} />
        </ErrorBoundary>
      ) : (
        <Loader />
      )}
    </Accordion>
  )
}

const Info = ({ records }) => {
  const composeValues = (record) => {
    if (record.value) return <p key={record.value}>{record.value}</p>

    if (record.values) {
      return record.values.map((v, i) => <p key={`${v}-${i}`}>{v}</p>)
    }

    if (record.type === 'button') {
      return (
        <PlainButton onClick={record.onClick} key={record.buttonText}>
          {record.buttonText}
        </PlainButton>
      )
    }

    if (record.type === 'link') {
      return (
        <PlainLink to={record.path} newTab key={record.linkText}>
          {record.linkText}
        </PlainLink>
      )
    }

    return ''
  }

  return records.map((r, i) => {
    return (
      <div className={records.length !== i + 1 ? 'mb8' : ''} key={key()}>
        <p className={'color-black-54'}>{r.label}:</p>
        {composeValues(r)}
        {r.dividerAfter && (
          <div className={'h-divider h-divider--vertical-margin'} />
        )}
      </div>
    )
  })
}

export default SidebarEntityInfo
