import React from "react"
import { SectionHeading } from "../uknow/uknowInfoPage"
import PlainLink from "../../../components/ui/link"
import { SOLUTIONS_PRODUCTS } from "../../../library/constants/routes"
import img1 from "../../../assets/img/pages/solutions/companies/1.jpg"
import img2 from "../../../assets/img/pages/solutions/companies/2.png"
import img3 from "../../../assets/img/pages/solutions/companies/3.png"
import img4 from "../../../assets/img/pages/solutions/companies/4.png"

const SolutionsCompaniesPage = () => {
  return (
    <>
      <section className={"bg-white w-full py-8"}>
        <div className="container">
          <SectionHeading>Company Groups</SectionHeading>
          <img src={img1} alt="Page Banner" className={"rounded-2xl mb-8"} />
          <SectionHeading>
            <span className={"italic"}>Found Your Group</span>
            <br />
            Attach Products to Your Group for the Featured Products Marketplace
          </SectionHeading>
          <p className={"text-center mb-4"}>
            After your company administrator for your company group makes their
            own profile, they make a company group. From the group they can make
            company products, each having their own product profile page in the
            featured products marketplace. This marketplace will be undergoing
            continuous upgrades so that you can opt to sell your product through
            Knowstory directly or keep it so that interested buyers contact you
            through the site.
          </p>
          <div className="grid grid-cols-2 items-center">
            <img src={img2} />
            <img src={img3} />
          </div>
        </div>
      </section>
      <section className={"bg-grey7 w-full py-8"}>
        <div className="container">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-start-2 col-span-10">
              <h3 className={"font-medium text-xl mb-4"}>What’s Different?</h3>
              <p className={"mb-4"}>
                <b>Bookings for Groups.</b> Like other intelligent calendars,
                any Knowstory user has a bookings link they can share for others
                to schedule on their calendar and integration through web-hook
                to Microsoft or Google calendars. The difference is that
                Knowstory is also a social hub so that users can build human
                networks for easier time coordination. Plus, your group can have
                a Schedule Appointment function for buyers to book with members
                of your group.
              </p>
              <p className={"mb-4"}>
                <b>Request Appointments.</b> Unlike other intelligent calendars,
                Knowstory users can send requests for amounts of time with no
                assigned date and time. Co-members who are affiliated friends
                would just accept or decline, and the AI would coordinate and
                set the appointment. Non-members would be directed to select as
                if from a bookings page or create an account and integrate.
              </p>
              <p className={"mb-4"}>
                <b>Holds for Scheduling Appointments.</b> A hold is an open time
                across any multiple of days in a week that is bracketed by a
                start and end time. It may have inside itself minus times such
                as lunch breaks so that the AI will know not to set meetings
                over that time as it is not open. Any member may make a hold and
                assign it to outgoing request appointments, offer labeled holds
                to members and non-members booking on their calendar, and use
                holds in auto-cohorting Meets.
              </p>
              <p>
                <b>Training.</b> If your company group offers training it could
                affiliate a second group under it. The second group would be a
                School created from some employee establishing an “Educator”
                Account and being the admin of this second Group so that your
                company can offer training and use all the same features as a
                regular School.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={"bg-white w-full py-8"}>
        <div className="container">
          <img src={img4} alt={"Holds"} />
          <PlainLink to={SOLUTIONS_PRODUCTS} bold>
            Also see Featured Products Solutions
          </PlainLink>
        </div>
      </section>
    </>
  )
}

export default SolutionsCompaniesPage
