import { useSelector } from "react-redux"
import { GROUPS } from "../../../../library/store/actions/types/groupsTypes"
import { getGroupLogo } from "../../../../library/utils/getGroupLogo"
import SuperSelect from "../select/superSelect"

const GroupPicker = ({
  value,
  onChange,
  hasError = false,
  errorMessage,
  required = false,
  multiple = false,
  requestParams = {},
  requestConfig = {},
  getRequestConfig,
  emptyListMessage,
  fieldName = "groups",
  entityName = "group",
  disabled = false,
}) => {
  const { id } = useSelector(({ auth }) => auth.userData)
  const defaultRequestConfig = {
    fetchConfig: {
      apiUrl: GROUPS.LIST,
      params: {
        user_id: id,
        types: undefined,
        active: true,
        managed: true,
        paid: false,
        type: undefined,
      },
    },
    key: "groups",
  }

  const composeConfig = () => {
    if (typeof getRequestConfig === "function")
      return getRequestConfig(defaultRequestConfig)

    if (
      requestConfig?.fetchConfig?.apiUrl &&
      requestConfig?.fetchConfig?.params &&
      requestConfig?.key
    ) {
      return requestConfig
    }

    return defaultRequestConfig
  }

  return (
    <SuperSelect
      value={value}
      onChange={onChange}
      requestConfig={composeConfig()}
      fieldName={fieldName}
      entityName={entityName}
      required={required}
      multiple={multiple}
      getItemPhoto={getGroupLogo}
      hasError={hasError}
      errorMessage={errorMessage}
      emptyListMessage={emptyListMessage}
      disabled={disabled}
    />
  )
}

export default GroupPicker
