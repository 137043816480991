import React from "react"
import { TextLineSkeleton } from "./textSkeleton"
import { AvatarSkeleton } from "./avatarSkeleton"
import { isLast } from "../../../../../library/utils/arrays"

const SidebarListSkeleton = ({ itemsNumber = 5 }) => {
  const items = new Array(itemsNumber).fill("")
  return (
    <div className="box boxed-list p15">
      <div className="mb15">
        <TextLineSkeleton width={"30%"} />
      </div>
      <div className="">
        {items.map((_, index) => (
          <div key={index}>
            <div className="f aifs">
              <AvatarSkeleton size={"50px"} mr={"8px"} />
              <div className="">
                <TextLineSkeleton width={"100px"} height={"13px"} />
                {/*<TextLineSkeleton width={"30%"} />*/}
              </div>
            </div>
            {!isLast(items, index) && <div className="h-divider" />}
          </div>
        ))}
      </div>
      {items.length >= 5 && (
        <div className={"mt15"}>
          <TextLineSkeleton width={"50%"} />
        </div>
      )}
    </div>
  )
}

export default SidebarListSkeleton
