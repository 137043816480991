import React, { Component } from "react"
import "./repliesList.scss"
import Reply from "../reply/reply"
import { bindActionCreators } from "redux"
import {
  createComment,
  getPostsList,
} from "../../../../library/store/actions/creators/postsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"

class RepliesList extends Component {
  state = {
    type: this.props.type,
    replies: this.props.replies,
    showReplyField: false,
    newReplyText: "",
    newReplyAttachments: [],
  }

  handleChange = (event) => {
    this.setState({ newReplyText: event.target.value })
  }

  showReplyField = () => {
    this.setState({ showReplyField: true })
  }

  addReply = async (reply_id, user_id, user_first_name, user_last_name) => {
    this.props.addReply(reply_id, user_id, user_first_name, user_last_name)
  }

  componentDidUpdate(prevProps) {
    if (this.props.replies !== prevProps.replies) {
      this.setState({ replies: this.props.replies })
    }
  }

  updateData = async () => {
    this.props.updateData(this.state.type)
  }

  render() {
    const { postId, commentId } = this.props
    const { replies } = this.state

    return (
      <div>
        {replies.map((reply) => (
          <Reply
            reply={reply}
            addReply={(reply_id, user_id, user_first_name, user_last_name) =>
              this.addReply(reply_id, user_id, user_first_name, user_last_name)
            }
            updateData={() => this.updateData()}
            postId={postId}
            commentId={commentId}
            key={reply.id}
          />
        ))}
      </div>
    )
  }
}

const mapStateToProps = ({auth, person, posts}) => ({auth, person, posts});
const mapDispatchToProps = dispatch => bindActionCreators({createComment, getPostsList}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(RepliesList));
