import PostText from "../../post/components/postText"
import React from "react"
import { useReplyCtx } from "../ReplyContext"

const ReplyContent = () => {
  const { reply } = useReplyCtx()

  return (
    <div className="post-comment__content lg:max-w-[75%]">
      <PostText message={reply.message} showPlayer={false} limit={150} />
    </div>
  )
}

export default ReplyContent
