import React from "react"
import styled from "@emotion/styled"
import { colors } from "../../../../../library/constants/styles/colors"
import { keyframes } from "@emotion/react"

const blink = keyframes`
  0% {
    background-position: -150% 0;
  }

  50% {
    background-position: 150% 0;
  }
  
  100% {
    background-position: -150% 0;
  }
`

const Container = styled.div`
  padding: 15px;
  border-radius: 2px;
  background-color: ${colors.white};
  box-shadow: 0 0 2px 0 rgba(210, 210, 210, 0.5);
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

const Avatar = styled.div`
  width: 60px;
  height: 60px;
  margin-right: 10px;
  border-radius: 50%;
  background: linear-gradient(to right, ${colors.black7} 0%, ${colors.black4} 50%, ${colors.black7} 100%);
  background-size: 150% 150%;
  animation: ${blink} 2s ease-in-out infinite;
`;

const Text = styled.div`
  height: ${props => props.height || "16px"};
  width: ${props => props.width || "100%"};
  background: linear-gradient(to right, ${colors.black7} 0%, ${colors.black4} 50%, ${colors.black7} 100%);
  background-size: 150% 150%;
  border-radius: 2px;
  animation: ${blink} 2s ease-in-out infinite;
  
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const PostSkeleton = () => {
    return (
        <Container>
            <Header>
                <Avatar />
                <div>
                    <Text width={"200px"} />
                    <Text width={"80px"} />
                </div>
            </Header>
            <div>
                <Text width={"100%"} />
                <Text width={"50%"} />
                <Text width={"70%"} />
            </div>
        </Container>
    );
};

export default PostSkeleton;
