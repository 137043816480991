import React from "react"
import styled from "@emotion/styled"
import { colors } from "../../../library/constants/styles/colors"

const Cell = styled.td`
  display: flex;
  align-items: center;
  flex: ${(props) => (props.fluid ? 1 : "auto")};
  padding: 10px 5px;
  min-width: 115px;
  max-width: ${(props) => (props.fluid ? "auto" : "115px")};
  height: 44px;
  background-color: ${(props) => (props.th ? colors.grey7 : colors.white)};
  transition: 0.2s all;

  &:first-of-type {
    position: sticky;
    left: 0;
    max-width: ${(props) => (props.fluid ? "auto" : "115px")};
    border-left: 2px solid transparent;
  }
`

const CellContent = styled.div`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const TableCell = ({ children, ...rest }) => {
  return (
    <Cell
      th={rest.th}
      fluid={rest.fluid}
      onClick={!rest.th ? () => rest.openRecord(rest.recordId) : () => {}}
    >
      <CellContent>{children}</CellContent>
    </Cell>
  )
}

export default TableCell
