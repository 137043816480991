import React from "react"
import BookMeetProvider from "../../bookMeet/bookMeetContext"
import BookMeetModal from "../../bookMeet/bookMeetModal"

const CreateMeetAsGuest = () => {
  return (
    <BookMeetProvider>
      <BookMeetModal />
    </BookMeetProvider>
  )
}

export default CreateMeetAsGuest
