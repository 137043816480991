import PlainLink from "../../../ui/link"

const EmptyListError = ({ message = "", link = { to: "", label: "" } }) => {
  return (
    <div>
      <p className={"error-message mb10"}>{message}</p>
      {!!link.to && !!link.label && (
        <PlainLink to={link.to} newTab>
          {link.label}
        </PlainLink>
      )}
    </div>
  )
}

export default EmptyListError
