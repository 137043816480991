import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { CALENDARS } from "../../../library/store/actions/types/calendarTypes"
import { useAlert } from "../../useAlert"
import { call } from "../../../library/networking/API"
import { USER } from "../../../library/store/actions/types/authTypes"

export const useHold = (id, options = {}, user = false) => {
  const { data: hold, ...query } = useQuery({
    queryKey: [!user ? CALENDARS.HOLDS.GET : USER.HOLDS.GET, { id }],
    ...options,
  })

  return {
    hold: !user ? hold : hold?.hold,
    ...query,
  }
}

export const useHoldManage = ({
  queryKey = [CALENDARS.HOLDS.LIST],
  user = false,
} = {}) => {
  const queryClient = useQueryClient()
  const { errorAlert } = useAlert()

  const createHold = useMutation(
    async ({
      name,
      school_id,
      time_from,
      time_to,
      is_all_day,
      days_of_week,
      breaks = [],
      user_time_zone,
      // group_id = 0,
      // segment_id = 0,
    }) => {
      try {
        await call(!user ? CALENDARS.HOLDS.CREATE : USER.HOLDS.CREATE, {
          name,
          school_id,
          time_from,
          time_to,
          is_all_day,
          days_of_week,
          breaks,
          user_time_zone: !user ? undefined : user_time_zone,
          // group_id,
          // segment_id,
        })
      } catch (e) {
        errorAlert(e)
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    }
  )

  const editHold = useMutation(
    async ({
      id,
      name,
      time_from,
      time_to,
      is_all_day,
      days_of_week,
      breaks = [],
      user_time_zone,
      // group_id = 0,
      // segment_id = 0,
    }) => {
      try {
        await call(!user ? CALENDARS.HOLDS.UPDATE : USER.HOLDS.UPDATE, {
          id,
          name,
          time_from,
          time_to,
          is_all_day,
          days_of_week,
          breaks,
          user_time_zone: !user ? undefined : user_time_zone,
          // group_id,
          // segment_id,
        })
      } catch (e) {
        errorAlert(e)
      }
    },
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries({ queryKey })
        queryClient.invalidateQueries({
          queryKey: [!user ? CALENDARS.HOLDS.GET : USER.HOLDS.GET, { id }],
        })
      },
    }
  )

  const deleteHold = useMutation(
    async (id) => {
      try {
        await call(!user ? CALENDARS.HOLDS.DELETE : USER.HOLDS.DELETE, { id })
      } catch (e) {
        errorAlert(e)
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    }
  )

  return {
    createHold,
    editHold,
    deleteHold,
  }
}
