import { twc } from "react-twc"

export const PageLayout = ({ children }) => {
  return (
    <div className="mt-4 mb-[150px] grid grid-cols-layout-lvl-1 gap-4">
      {children}
    </div>
  )
}

export const PageContentLayout = ({ fullWidth = false, children }) => {
  return (
    // <div className={"container mx-auto"}>
    // <div className={`mx-auto ${fullWidth ? "w-full" : "w-page"}`}>
    <div className={`mb-[150px] grid gap-4 grid-cols-layout-lvl-2-full`}>
      {children}
    </div>
    // </div>
    // </div>
  )
}

export const PageMain = ({ fullWidth = false, children }) => (
  <main className={`mx-auto grid auto-rows-max gap-4 w-full`}>{children}</main>
)

export const PageRightSidebar = ({ children }) => (
  <aside className="grid auto-rows-max gap-4">{children}</aside>
)

export const Layout = twc.div`
  grid grid-cols-[1fr] gap-4 mt-4 mb-32
`
