import React from "react"
import Loader from "../../../../components/ui/loader"
import { generatePath, Link, useParams } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import DOMPurify from "dompurify"
import BoxPage, {
  BoxHeading,
} from "../../../../components/v2/layout/boxPage/boxPage"
import {
  PageContentLayout,
  PageMain,
  PageRightSidebar,
} from "../../../../components/v2/layout/layout"
import HelpOutlineIcon from "@material-ui/icons/HelpOutline"
import { useHowTo } from "../useHowTo"

const HowToCategoryPage = () => {
  const { categoryId } = useParams()

  const { categoriesQuery, articlesQuery } = useHowTo({
    categories: true,
    articles: true,
  })

  const category =
    categoryId !== "all"
      ? categoriesQuery?.data?.categories?.find(
          (category) => category.id === Number(categoryId)
        )
      : { name: "All articles", count: categoriesQuery.data?.all_posts_count }

  return articlesQuery.isSuccess ? (
    <PageContentLayout>
      <PageMain>
        <BoxHeading>
          {category?.name} ({category?.count})
        </BoxHeading>
        <div className={"grid grid-cols-4 gap-4"}>
          {articlesQuery?.data?.posts?.map((article) => (
            <Link
              to={generatePath(routes.HOW_TO_ARTICLE, {
                categoryId,
                articleId: article.id,
              })}
              className={
                "grid grid-rows-subgrid row-span-2 gap-2 rounded-sm shadow-card p-3 bg-white"
              }
            >
              <h3
                className={"text-primary font-medium text-lg mb-4 line-clamp-2"}
              >
                {article.title}
              </h3>
              {!!article.excerpt && (
                <p
                  className={"line-clamp-3"}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(article.excerpt),
                  }}
                />
              )}
            </Link>
          ))}
        </div>
      </PageMain>
      <PageRightSidebar>
        {categoriesQuery.isSuccess && (
          <div className="box aside-header">
            <Link
              to={routes.HOW_TO}
              className="box__content aside-header__content"
            >
              <div className="flex items-center justify-center mr-4">
                <HelpOutlineIcon
                  style={{ fontSize: "2.5rem" }}
                  color={"primary"}
                />
              </div>
              <div className="aside-header__info">
                <div className="aside-header__name">All categories</div>
                <div className="aside-header__help">back to page</div>
              </div>
            </Link>
          </div>
        )}
      </PageRightSidebar>
    </PageContentLayout>
  ) : (
    <Loader />
  )
}

export default HowToCategoryPage
