import React from "react"
import { calendarGrades } from "../../../../../../../library/constants/educationalDictionary"
import PlainLink from "../../../../../../../components/ui/link"
import * as routes from "../../../../../../../library/constants/routes"
import { generatePath, useParams } from "react-router-dom"

const GroupSettingsCalendarGrades = () => {
  const params = useParams()
  return (
    <>
      <div className="box">
        <div className="box__heading">Grades</div>
      </div>

      {calendarGrades.map((grade) => {
        return (
          <div className={"box list-item"} key={grade.id}>
            <div className="f aic full-width">
              <div className="list-item__info">
                <PlainLink
                  to={generatePath(routes.GROUP_SETTINGS_CALENDAR_GRADE, {
                    groupId: params.groupId,
                    calendarId: params.calendarId,
                    gradeId: grade.id,
                  })}
                >
                  <span className="list-item__name">{grade.name}</span>
                </PlainLink>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default GroupSettingsCalendarGrades
