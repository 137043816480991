export const notificationTypes = {
  1: "Incoming group invitation",
  2: "Incoming group membership request",
  3: "Group membership approval",
  4: "New message",
  5: "New friend request",
  6: "Outgoing friend request approval",
  7: "New Cred",
  // 8: "Product purchase request",
}
