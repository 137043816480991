import React from "react"
import { POSTS } from "../../../../../../library/store/actions/types/postsTypes"
import PostCreate from "../../../../../../components/v2/feed/post/components/postCreate"
import { useGroup } from "../../../../../../hooks/data/groups/groups"
import GroupFeedList from "./GroupFeedList"
import { GROUP_POST } from "../../../../../../library/constants/routes"
import PostModal from "../../../../feed/postModal/postModal"
import { Route } from "react-router-dom"

const GroupFeed = () => {
  const { group, isUserFollower } = useGroup()
  const postsListQueryKey = [POSTS.LIST, { groupId: group.id }]

  return (
    <div className={"lg:w-[75%] lg:mx-auto"}>
      {group.is_member && !isUserFollower && (
        <PostCreate postsListQueryKey={postsListQueryKey} />
      )}
      <GroupFeedList postsListQueryKey={postsListQueryKey} />

      <Route
        path={GROUP_POST}
        render={(props) => (
          <PostModal {...props} groupInfo={group} type="group" />
        )}
      />
    </div>
  )
}

export default GroupFeed
