import { call } from "../../../networking/API"
import { SEARCH } from "../types/searchTypes"
import { BOOKMARKS } from "../types/bookmarksTypes"

export const globalSearch = ({
  name,
  users_limit = 9,
  groups_limit = 9,
  products_limit = 5,
}) => {
  return async (dispatch, getState) => {
    const response = await call(SEARCH.GLOBAL, {
      token: getState().auth.userData.token,
      name,
      users_limit,
      groups_limit,
      products_limit,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SEARCH.GLOBAL,
        payload: json,
      })
    } else {
      throw json.error
    }
  }
}

export const usersSearch = ({name, is_related_to, bookmarked, folder_id, offset = 0, limit = 50}) => {
    return async (dispatch, getState) => {
        const response = await call(SEARCH.USERS, {
            token: getState().auth.userData.token,
            name,
            is_related_to,
            bookmarked,
            folder_id,
            offset,
            limit,
        });
        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: bookmarked ? BOOKMARKS.LIST : SEARCH.USERS,
                payload: json,
            });
        } else {
            throw json.error;
        }
    };
};

export const groupsSearch = ({name, type, bookmarked, folder_id, offset = 0, limit = 50}) => {
    return async (dispatch, getState) => {
        const response = await call(SEARCH.GROUPS, {
            token: getState().auth.userData.token,
            name,
            type,
            bookmarked,
            folder_id,
            offset,
            limit,
        });
        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: bookmarked ? BOOKMARKS.LIST : SEARCH.GROUPS,
                payload: json,
            });
        } else {
            throw json.error;
        }
    };
};

export const productsSearch = ({name, bookmarked, folder_id, offset = 0, limit = 50}) => {
    return async (dispatch, getState) => {
        const response = await call(SEARCH.PRODUCTS, {
            token: getState().auth.userData.token,
            name,
            bookmarked,
            folder_id,
            offset,
            limit,
        });

        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: bookmarked ? BOOKMARKS.LIST : SEARCH.PRODUCTS,
                payload: json,
            });
        } else {
            throw json.error;
        }
    };
};

export const clearSearchList = () => {
    return {
        type: SEARCH.CLEAR,
    };
};
