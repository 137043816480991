import { call } from "../../../networking/API"
import { GROUPS, SCHOOLS, SEGMENTS } from "../types/groupsTypes"
import { LIMIT } from "../../../constants/limits"

export const getGroupsList = ({
  user_id,
  type,
  types,
  name,
  managed = false,
  paid = false,
  offset = 0,
  limit = 50,
  active = false,
  tags = [],
}) => {
  return async (dispatch, getState) => {
    const options = {
      token: getState().auth.userData.token,
      user_id: user_id || getState().auth.userData.id,
      type: parseInt(type) || undefined,
      types,
      managed,
      offset,
      limit,
      active,
      paid,
      tags,
    }
    if (name && name !== "undefined") {
      options["name"] = name
    }
    const response = await call(GROUPS.LIST, options)
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.LIST,
        payload: {
          type,
          groups: json.groups,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const updateGroupsList = ({
  user_id,
  type,
  name,
  managed = false,
  offset = 0,
  limit = 50,
  active = false,
  tags = [],
}) => {
  return async (dispatch, getState) => {
    const options = {
      token: getState().auth.userData.token,
      user_id: user_id || getState().auth.userData.id,
      type: parseInt(type) || undefined,
      managed,
      offset,
      limit,
      active,
      tags,
    }
    if (name && name !== "undefined") {
      options["name"] = name
    }
    const response = await call(GROUPS.LIST, options)
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.LIST_UPDATE,
        payload: {
          type,
          groups: json.groups,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const searchGroups = ({ type, name, offset = 0, limit = 50 }) => {
  return async (dispatch, getState) => {
    const options = {
      token: getState().auth.userData.token,
      type: parseInt(type) || undefined,
      offset,
      limit,
    }
    if (name && name !== "undefined") {
      options["name"] = name
    }
    const response = await call(GROUPS.SEARCH, options)
    const json = await response.data
    if (response.status === 200) {
      return dispatch({
        type: GROUPS.LIST,
        payload: {
          type: "search",
          groups: json.groups,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const searchRelatedGroups = ({
  name,
  group_id,
  offset = 0,
  limit = 50,
}) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.CONNECTIONS.SEARCH, {
      token: getState().auth.userData.token,
      name,
      group_id,
      offset,
      limit,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.CONNECTIONS.LIST,
        payload: { list: json.groups, offset },
      })
    } else {
      throw json.error
    }
  }
}

export const clearGroupsList = () => {
  return {
    type: GROUPS.CLEAR,
  }
}

export const getGroup = ({
  id,
  participants_limit = 5,
  connections_limit = 5,
  products_limit = 5,
}) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.GET, {
      id,
      participants_limit,
      connections_limit,
      products_limit,
    })
    const json = await response.data

    if (response.status === 200) {
      dispatch({
        type: GROUPS.GET,
        payload: json,
      })

      return json
    } else {
      throw json.error
    }
  }
}

export const clearCurrentGroup = () => {
  return async (dispatch) => {
    return dispatch({
      type: GROUPS.CLEAR_CURRENT,
    })
  }
}

export const createGroup = ({
  name,
  description,
  type,
  website,
  country,
  city,
  state,
  address,
  zip_code,
  phone,
  start_date,
  end_date,
  closed = false,
  attachments = [],
  district_id,
  event_link,
}) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.CREATE, {
      token: getState().auth.userData.token,
      name,
      description,
      type,
      website,
      country,
      city,
      state,
      address,
      zip_code,
      phone,
      start_date,
      end_date,
      closed,
      district_id,
      attachments,
      event_link,
    })
    const json = await response.data

    if (response.status === 200) {
      dispatch({
        type: GROUPS.CREATE,
      })
      return json.id
    } else {
      throw json.error
    }
  }
}

export const updateGroup = ({
  id,
  name,
  description,
  website,
  country,
  city,
  state,
  address,
  zip_code,
  phone,
  start_date,
  end_date,
  full_image,
  full_header,
  closed,
  attachments = [],
  links,
  district_id,
  event_link,
}) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.UPDATE, {
      token: getState().auth.userData.token,
      id,
      name,
      description,
      website,
      country,
      city,
      state,
      address,
      zip_code,
      phone,
      start_date,
      end_date,
      full_image,
      full_header,
      closed,
      attachments,
      links,
      district_id,
      event_link,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.UPDATE,
      })
    } else {
      throw json.error
    }
  }
}

export const deleteGroup = ({ id }) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.DELETE, {
      token: getState().auth.userData.token,
      id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.DELETE,
      })
    } else {
      throw json.error
    }
  }
}

export const joinGroup = ({ id }) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.JOIN, {
      token: getState().auth.userData.token,
      id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.JOIN,
      })
    } else {
      throw json.error
    }
  }
}

export const leaveGroup = ({ id }) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.LEAVE, {
      token: getState().auth.userData.token,
      id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.LEAVE,
      })
    } else {
      throw json.error
    }
  }
}

export const groupChangeRole = ({ group_id, user_id, role }) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.PARTICIPANTS.CHANGE_ROLE, {
      token: getState().auth.userData.token,
      group_id,
      user_id,
      role,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.PARTICIPANTS.CHANGE_ROLE,
      })
    } else {
      throw json.error
    }
  }
}

export const groupMemberRemove = ({ group_id, user_id }) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.PARTICIPANTS.REMOVE, {
      token: getState().auth.userData.token,
      group_id,
      user_id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.PARTICIPANTS.REMOVE,
      })
    } else {
      throw json.error
    }
  }
}

export const getGroupMembersList = ({
  group_id,
  name,
  role,
  tags = [],
  offset = 0,
  limit = 50,
}) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.PARTICIPANTS.LIST, {
      token: getState().auth.userData.token,
      group_id,
      name,
      role,
      tags,
      offset,
      limit,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.PARTICIPANTS.LIST,
        payload: json,
      })
    } else {
      throw json.error
    }
  }
}

export const groupRequestsAccept = ({ group_id, user_id }) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.PARTICIPANTS.REQUESTS.ACCEPT, {
      token: getState().auth.userData.token,
      group_id,
      user_id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.PARTICIPANTS.REQUESTS.ACCEPT,
      })
    } else {
      throw json.error
    }
  }
}

export const groupRequestsDecline = ({ group_id, user_id }) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.PARTICIPANTS.REQUESTS.DECLINE, {
      token: getState().auth.userData.token,
      group_id,
      user_id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.PARTICIPANTS.REQUESTS.DECLINE,
      })
    } else {
      throw json.error
    }
  }
}

export const groupRequestsCancel = ({ group_id }) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.PARTICIPANTS.REQUESTS.CANCEL, {
      token: getState().auth.userData.token,
      group_id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.PARTICIPANTS.REQUESTS.CANCEL,
      })
    } else {
      throw json.error
    }
  }
}

export const groupRequestsList = ({
  group_id,
  name,
  offset = 0,
  limit = 50,
}) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.PARTICIPANTS.REQUESTS.LIST, {
      token: getState().auth.userData.token,
      group_id,
      name,
      offset,
      limit,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.PARTICIPANTS.REQUESTS.LIST,
        payload: json.users,
      })
    } else {
      throw json.error
    }
  }
}

export const groupAddConnection = ({ group_id, related_group_id }) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.CONNECTIONS.ADD, {
      token: getState().auth.userData.token,
      group_id,
      related_group_id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.CONNECTIONS.ADD,
      })
    } else {
      throw json.error
    }
  }
}

export const groupDeleteConnection = ({ group_id, related_group_id }) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.CONNECTIONS.DELETE, {
      token: getState().auth.userData.token,
      group_id,
      related_group_id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.CONNECTIONS.DELETE,
      })
    } else {
      throw json.error
    }
  }
}

export const getGroupRelatedList = ({
  group_id,
  name,
  offset = 0,
  limit = 50,
  type,
}) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.CONNECTIONS.LIST, {
      group_id,
      name,
      offset,
      limit,
      type,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.CONNECTIONS.LIST,
        payload: { list: json.groups, offset },
      })
    } else {
      throw json.error
    }
  }
}

export const clearGroupRelatedList = () => {
  return async (dispatch) => {
    return dispatch({
      type: GROUPS.CONNECTIONS.CLEAR,
    })
  }
}

export const groupInviteCreate = ({ group_id, user_id }) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.PARTICIPANTS.INVITES.CREATE, {
      token: getState().auth.userData.token,
      group_id,
      user_id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.PARTICIPANTS.INVITES.CREATE,
      })
    } else {
      throw json.error
    }
  }
}

export const groupInviteDecline = ({ group_id }) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.PARTICIPANTS.INVITES.DECLINE, {
      token: getState().auth.userData.token,
      group_id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.PARTICIPANTS.INVITES.DECLINE,
      })
    } else {
      throw json.error
    }
  }
}

export const groupInviteCancel = ({ group_id, user_id }) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.PARTICIPANTS.INVITES.CANCEL, {
      token: getState().auth.userData.token,
      group_id,
      user_id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.PARTICIPANTS.INVITES.CANCEL,
      })
    } else {
      throw json.error
    }
  }
}

export const groupInviteRoster = ({ group_id, subgroup_id, roster_id }) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.PARTICIPANTS.INVITES.ROSTER, {
      group_id,
      subgroup_id,
      file_id: roster_id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.PARTICIPANTS.INVITES.ROSTER,
      })
    } else {
      throw json.error
    }
  }
}

export const getMembersInvitesList = ({
  group_id,
  offset = 0,
  limit = 100,
}) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.PARTICIPANTS.INVITES.LIST, {
      token: getState().auth.userData.token,
      group_id,
      offset,
      limit,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.PARTICIPANTS.INVITES.LIST,
        payload: json.users,
      })
    } else {
      throw json.error
    }
  }
}

export const getPendingInvitesList = ({
  type,
  name,
  offset = 0,
  limit = 50,
}) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.INVITES_LIST, {
      token: getState().auth.userData.token,
      offset,
      name,
      limit,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.LIST,
        payload: {
          type,
          groups: json.groups,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const getOutgoingInvitesList = ({
  type,
  name,
  offset = 0,
  limit = 50,
}) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.REQUESTS_LIST, {
      token: getState().auth.userData.token,
      offset,
      name,
      limit,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.LIST,
        payload: {
          type,
          groups: json.groups,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const groupInvitesSearch = ({
  name,
  group_id,
  offset = 0,
  limit = 50,
}) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.PARTICIPANTS.INVITES.SEARCH, {
      token: getState().auth.userData.token,
      name,
      group_id,
      offset,
      limit,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.PARTICIPANTS.INVITES.SEARCH,
        payload: json.users,
      })
    } else {
      throw json.error
    }
  }
}

export const addProductToLibrary = ({ group_id, product_id }) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.LIBRARY_ADD, {
      token: getState().auth.userData.token,
      group_id,
      product_id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.LIBRARY_ADD,
      })
    } else {
      throw json.error
    }
  }
}

export const removeProductFromLibrary = ({ group_id, product_id }) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.LIBRARY_DELETE, {
      token: getState().auth.userData.token,
      group_id,
      product_id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.LIBRARY_DELETE,
      })
    } else {
      throw json.error
    }
  }
}

export const getProductsLibrary = ({
  group_id,
  name,
  limit = 30,
  offset = 0,
}) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.LIBRARY_LIST, {
      token: getState().auth.userData.token,
      group_id,
      name,
      limit,
      offset,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.LIBRARY_LIST,
        payload: json.products,
      })
    } else {
      throw json.error
    }
  }
}

export const productsLibrarySearch = ({
  group_id,
  name,
  limit = 30,
  offset = 0,
}) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.LIBRARY_SEARCH, {
      token: getState().auth.userData.token,
      group_id,
      name,
      limit,
      offset,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.LIBRARY_SEARCH,
        payload: json.products,
      })
    } else {
      throw json.error
    }
  }
}

export const updateGroupAttachmentsProducts = ({
  group_id,
  name,
  limit = 30,
  offset = 0,
}) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.LIBRARY_SEARCH, {
      token: getState().auth.userData.token,
      group_id,
      name,
      limit,
      offset,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.LIBRARY_UPDATE,
        payload: json.products,
      })
    } else {
      throw json.error
    }
  }
}

export const clearGroupAttachmentProducts = () => {
  return {
    type: GROUPS.LIBRARY_CLEAR,
  }
}

export const groupInvitesSearchClear = () => {
  return async (dispatch) => {
    return dispatch({
      type: GROUPS.PARTICIPANTS.INVITES.SEARCH,
      payload: null,
    })
  }
}

export const getSchoolGradeStudentsList = ({
  group_id,
  grade,
  name,
  offset = 0,
  limit = LIMIT,
}) => {
  return async (dispatch, getState) => {
    const response = await call(SCHOOLS.STUDENTS.LIST, {
      token: getState().auth.userData.token,
      group_id,
      grade,
      name,
      offset,
      limit,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SCHOOLS.STUDENTS.LIST,
        payload: { list: json.users },
      })
    } else {
      throw json.error
    }
  }
}

export const searchSchoolGradeStudents = ({
  group_id,
  name,
  offset = 0,
  limit = LIMIT,
}) => {
  return async (dispatch, getState) => {
    const response = await call(SCHOOLS.STUDENTS.SEARCH, {
      token: getState().auth.userData.token,
      group_id,
      name,
      offset,
      limit,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SCHOOLS.STUDENTS.SEARCH,
        payload: { list: json.users },
      })
    } else {
      throw json.error
    }
  }
}

export const addStudentToSchoolGrade = ({ group_id, user_id, grade }) => {
  return async (dispatch, getState) => {
    const response = await call(SCHOOLS.STUDENTS.ADD, {
      token: getState().auth.userData.token,
      group_id,
      user_id,
      grade,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SCHOOLS.STUDENTS.ADD,
        payload: { user_id, grade },
      })
    } else {
      throw json.error
    }
  }
}

export const deleteStudentFromSchoolGrade = ({
  group_id,
  user_id,
  removeFromList = true,
}) => {
  return async (dispatch, getState) => {
    const response = await call(SCHOOLS.STUDENTS.DELETE, {
      token: getState().auth.userData.token,
      group_id,
      user_id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SCHOOLS.STUDENTS.DELETE,
        payload: { user_id, removeFromList },
      })
    } else {
      throw json.error
    }
  }
}

export const transferStudentToSchoolGrade = ({
  group_id,
  user_id,
  new_grade,
}) => {
  return async (dispatch, getState) => {
    const removeStudentResponse = await call(SCHOOLS.STUDENTS.DELETE, {
      group_id,
      user_id,
    })
    const removeStudentResponseJson = await removeStudentResponse.data

    if (removeStudentResponse.status === 200) {
      const addStudentResponse = await call(SCHOOLS.STUDENTS.ADD, {
        group_id,
        user_id,
        grade: new_grade,
      })
      const addStudentResponseJson = await addStudentResponse.data

      if (addStudentResponse.status === 200) {
        return dispatch({
          type: SCHOOLS.STUDENTS.TRANSFER,
          payload: { user_id, new_grade },
        })
      } else {
        throw addStudentResponseJson.error
      }
    } else {
      throw removeStudentResponseJson.error
    }
  }
}

export const clearSchoolGradeStudentsList = () => {
  return {
    type: SCHOOLS.STUDENTS.LIST_CLEAR,
  }
}

export const clearSchoolGradeStudentsSearchList = () => {
  return {
    type: SCHOOLS.STUDENTS.SEARCH_CLEAR,
  }
}

export const getSchoolCalendarSegmentsList = ({
  schedule_id,
  name,
  offset = 0,
  limit = LIMIT,
}) => {
  return async (dispatch) => {
    const response = await call(SEGMENTS.LIST, {
      schedule_id,
      name,
      offset,
      limit,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SEGMENTS.LIST,
        payload: { list: json.segments, offset },
      })
    } else {
      throw json.error
    }
  }
}

export const clearSchoolCalendarSegmentsList = () => {
  return async (dispatch) => {
    return dispatch({
      type: SEGMENTS.CLEAR_LIST,
    })
  }
}

export const getSchoolCalendarSegment = ({ id }) => {
  return async (dispatch) => {
    const response = await call(SEGMENTS.GET, {
      id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SEGMENTS.GET,
        payload: json,
      })
    } else {
      throw json.error
    }
  }
}

export const clearSchoolCalendarSegment = () => {
  return async (dispatch) => {
    return dispatch({
      type: SEGMENTS.CLEAR_CURRENT,
    })
  }
}

export const createSchoolCalendarSegment = ({
  schedule_id,
  name,
  grades,
  date_from,
  date_to,
  section_duration = 1,
}) => {
  return async (dispatch) => {
    const response = await call(SEGMENTS.CREATE, {
      schedule_id,
      name,
      grades,
      date_from,
      date_to,
      section_duration,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SEGMENTS.CREATE,
        payload: {
          id: json.id,
          schedule_id,
          name,
          grades,
          date_from,
          date_to,
          section_duration,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const updateSchoolCalendarSegment = ({
  id,
  name,
  grades,
  date_from,
  date_to,
  section_duration = 1,
}) => {
  return async (dispatch) => {
    const response = await call(SEGMENTS.UPDATE, {
      id,
      name,
      grades,
      date_from,
      date_to,
      section_duration,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SEGMENTS.UPDATE,
        payload: {
          id,
          name,
          grades,
          date_from,
          date_to,
          section_duration,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const deleteSchoolCalendarSegment = ({ id }) => {
  return async (dispatch) => {
    const response = await call(SEGMENTS.DELETE, {
      id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SEGMENTS.DELETE,
        payload: { id },
      })
    } else {
      throw json.error
    }
  }
}

export const getSchoolCalendarSegmentSectionsList = ({
  segment_id,
  name,
  offset,
  limit = LIMIT,
}) => {
  return async (dispatch) => {
    const response = await call(SEGMENTS.SECTIONS.LIST, {
      segment_id,
      name,
      offset,
      limit,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SEGMENTS.SECTIONS.LIST,
        payload: { list: json.sections, offset },
      })
    } else {
      throw json.error
    }
  }
}

export const clearSchoolCalendarSegmentSectionsList = () => {
  return async (dispatch) => {
    return dispatch({
      type: SEGMENTS.SECTIONS.CLEAR_LIST,
    })
  }
}

export const getSchoolCalendarSegmentSection = ({ id }) => {
  return async (dispatch) => {
    const response = await call(SEGMENTS.SECTIONS.GET, {
      id,
    })
    const json = await response.data

    if (response.status === 200) {
      dispatch({
        type: SEGMENTS.SECTIONS.GET,
        payload: json,
      })

      return json
    } else {
      throw json.error
    }
  }
}

export const getBlocksList = ({
  section_id,
  name,
  offset = 0,
  limit = LIMIT,
}) => {
  return async (dispatch, getState) => {
    const response = await call(SEGMENTS.SECTIONS.BLOCKS.LIST, {
      section_id,
      name,
      offset,
      limit,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SEGMENTS.SECTIONS.BLOCKS.LIST,
        payload: { list: json.blocks, offset },
      })
    } else {
      throw json.error
    }
  }
}

export const clearBlocksList = () => {
  return async (dispatch) => {
    return dispatch({
      type: SEGMENTS.SECTIONS.BLOCKS.CLEAR_LIST,
    })
  }
}

export const getBlock = ({ id }) => {
  return async (dispatch) => {
    const response = await call(SEGMENTS.SECTIONS.BLOCKS.GET, {
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SEGMENTS.SECTIONS.BLOCKS.GET,
        payload: json,
      })
    } else {
      throw json.error
    }
  }
}

export const clearCurrentBlock = () => {
  return async (dispatch) => {
    return dispatch({
      type: SEGMENTS.SECTIONS.BLOCKS.CLEAR_CURRENT_BLOCK,
    })
  }
}

export const createBlock = ({
  name,
  time_from,
  time_to,
  is_all_day,
  section_id,
  rotations,
}) => {
  return async (dispatch) => {
    const response = await call(SEGMENTS.SECTIONS.BLOCKS.CREATE, {
      name,
      time_from,
      time_to,
      is_all_day,
      section_id,
      rotations,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SEGMENTS.SECTIONS.BLOCKS.CREATE,
        payload: {
          id: json.id,
          name,
          time_from,
          time_to,
          is_all_day,
          section_id,
          rotations,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const updateBlock = ({
  id,
  name,
  time_from,
  time_to,
  is_all_day,
  rotations,
}) => {
  return async (dispatch) => {
    const response = await call(SEGMENTS.SECTIONS.BLOCKS.UPDATE, {
      id,
      name,
      time_from,
      time_to,
      is_all_day,
      rotations,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SEGMENTS.SECTIONS.BLOCKS.UPDATE,
        payload: {
          id,
          name,
          time_from,
          time_to,
          is_all_day,
          rotations,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const deleteBlock = ({ id }) => {
  return async (dispatch) => {
    const response = await call(SEGMENTS.SECTIONS.BLOCKS.DELETE, {
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SEGMENTS.SECTIONS.BLOCKS.DELETE,
        payload: { id },
      })
    } else {
      throw json.error
    }
  }
}

export const clearSchoolCalendarSegmentSection = () => {
  return async (dispatch) => {
    return dispatch({
      type: SEGMENTS.SECTIONS.CLEAR_CURRENT,
    })
  }
}

export const createSchoolCalendarSegmentSection = ({
  segment_id,
  name,
  rotations,
}) => {
  return async (dispatch) => {
    const response = await call(SEGMENTS.SECTIONS.CREATE, {
      segment_id,
      name,
      rotations,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SEGMENTS.SECTIONS.CREATE,
        payload: {
          id: json.id,
          segment_id,
          name,
          rotations,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const updateSchoolCalendarSegmentSection = ({ id, name, rotations }) => {
  return async (dispatch) => {
    const response = await call(SEGMENTS.SECTIONS.UPDATE, {
      id,
      name,
      rotations,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SEGMENTS.SECTIONS.UPDATE,
        payload: {
          id,
          name,
          rotations,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const deleteSchoolCalendarSegmentSection = ({ id }) => {
  return async (dispatch) => {
    const response = await call(SEGMENTS.SECTIONS.DELETE, {
      id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SEGMENTS.SECTIONS.DELETE,
        payload: { id },
      })
    } else {
      throw json.error
    }
  }
}

export const changeGroupMemberTags = ({ group_id, user_id, tags = [] }) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.PARTICIPANTS.CHANGE_TAGS, {
      token: getState().auth.userData.token,
      group_id,
      user_id,
      tags,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.PARTICIPANTS.CHANGE_TAGS,
        payload: { user_id, tags },
      })
    } else {
      throw json.error
    }
  }
}

export const getGroupSpacesList = ({
  name,
  school_id,
  offset = 0,
  limit = LIMIT,
}) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.SPACES.LIST, {
      token: getState().auth.userData.token,
      name,
      school_id,
      offset,
      limit,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.SPACES.LIST,
        payload: { list: json.spaces, offset },
      })
    } else {
      throw json.error
    }
  }
}

export const clearGroupSpacesList = () => {
  return async (dispatch) => {
    return dispatch({
      type: GROUPS.SPACES.CLEAR_LIST,
    })
  }
}

export const groupSpacesGet = ({ id }) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.SPACES.GET, {
      token: getState().auth.userData.token,
      id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.SPACES.GET,
        payload: json,
      })
    } else {
      throw json.error
    }
  }
}

export const clearCurrentGroupSpace = () => {
  return async (dispatch) => {
    return dispatch({
      type: GROUPS.SPACES.CLEAR_CURRENT,
    })
  }
}

export const groupSpacesCreate = ({ school_id, name, description }) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.SPACES.CREATE, {
      token: getState().auth.userData.token,
      school_id,
      name,
      description,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.SPACES.CREATE,
        payload: { id: json.id, school_id, name, description },
      })
    } else {
      throw json.error
    }
  }
}

export const groupSpacesUpdate = ({ id, name, description }) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.SPACES.UPDATE, {
      token: getState().auth.userData.token,
      id,
      name,
      description,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.SPACES.UPDATE,
        payload: { id, name, description },
      })
    } else {
      throw json.error
    }
  }
}

export const groupSpacesDelete = ({ id }) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.SPACES.DELETE, {
      token: getState().auth.userData.token,
      id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: GROUPS.SPACES.DELETE,
        payload: { id },
      })
    } else {
      throw json.error
    }
  }
}

export const getGroupKnowmixList = ({
  group_id,
  name,
  offset = 0,
  limit = 50,
  subjects,
  school_grades,
  finished = false,
} = {}) => {
  return async (dispatch, getState) => {
    const response = await call(GROUPS.KNOWMIX_LIST, {
      group_id,
      name,
      offset,
      limit,
      subjects,
      school_grades,
      finished,
    })
    const json = await response.data
    if (response.status === 200) {
      return dispatch({
        type: GROUPS.KNOWMIX_LIST,
        payload: {
          list: json.knowmixes,
          counter: json.knowmixes_number,
          offset,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const clearGroupKnowmixList = () => {
  return async (dispatch) => {
    return dispatch({
      type: GROUPS.KNOWMIX_LIST_CLEAR,
    })
  }
}
