import React from "react"
import Loader from "../../../../../../components/ui/loader"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import withScroll from "../../../../../../hocs/withScroll/withScroll"
import { withSnackbar } from "notistack"
import getErrorText from "../../../../../../library/constants/errorTypes"

import {
  acceptInvite,
  cancelInvite,
  clearInvenstory,
  createInvite,
  declineInvite,
  getConnectionsList,
  removeConnection,
  restrictConnection,
  updateConnectionsList,
} from "../../../../../../library/store/actions/creators/invenstoryCreators"

import {
  clearGroupsList,
  getGroupMembersList,
  getGroupsList,
  searchGroups,
} from "../../../../../../library/store/actions/creators/groupsCreators"
import { LIMIT } from "../../../../../../library/constants/limits"
import * as routes from "../../../../../../library/constants/routes"
import ListSearchHeader from "../../../../../../components/ui/listSearchHeader/listSearchHeader"
import { generatePath, NavLink } from "react-router-dom"
import * as qs from "query-string"
import InvenstoryUserCard from "../../../../invenstory/invenstoryCards/invenstoryUserCard"
import InvenstoryGroupCard from "../../../../invenstory/invenstoryCards/invenstoryGroupCard"

class GroupsSettingsInvenstory extends React.Component {
  state = {
    list: [],
    dataReceived: false,
  }

  searchParams = {}

  async componentDidMount() {
    const {
      location: { search },
    } = this.props
    const { q: query } = qs.parse(search)
    this.searchParams = {
      name: query || undefined,
    }
    await this.getList()
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.match.path !== this.props.match.path ||
      prevProps.location.search !== this.props.location.search
    ) {
      this.setState({
        list: [],
      })
      this.props.clearInvenstory()
      this.props.clearGroupsList()
      const {
        location: { search },
      } = this.props
      const { q: query } = qs.parse(search)
      this.searchParams = {
        name: query || undefined,
      }
      await this.getList()
    }
  }

  componentWillUnmount() {
    this.props.clearInvenstory()
    this.props.clearGroupsList()
  }

  combineEntity = (entity, connection) => {
    if (connection) {
      switch (connection.type) {
        case 1:
          return {
            ...entity,
            isConnected: false,
            isPending: true,
          }
        case 2:
          return {
            ...entity,
            isConnected: true,
            isPending: false,
          }
      }
    }
    return {
      ...entity,
      isConnected: false,
      isPending: false,
    }
  }

  getList = async ({ offset, limit, isUpdate } = {}) => {
    this.setState({ dataReceived: false })
    const {
        subTub,
        groups: { currentGroup },
      } = this.props,
      group_id = parseInt(this.props.match.params.groupId),
      { name } = this.searchParams
    try {
      !isUpdate
        ? await this.props.getConnectionsList({
            name,
            group_id,
            type: currentGroup.type === 5 ? 2 : subTub,
            limit: limit || LIMIT,
            offset,
          })
        : await this.props.updateConnectionsList({
            name,
            group_id,
            type: currentGroup.type === 5 ? 2 : subTub,
            limit: limit || LIMIT,
            offset,
          })
      switch (currentGroup.type) {
        case 4:
          await this.props.getGroupMembersList({
            group_id,
            name,
            limit: limit || LIMIT,
            offset,
          })
          const schoolMembersList = [...this.props.groups.membersList]
          const schoolConnectionsList = [
            ...this.props.invenstory.connectionsList,
          ]
          switch (subTub) {
            case 1:
              return this.setState({
                dataReceived: true,
                list: schoolConnectionsList.map((c) =>
                  c.type === 1
                    ? {
                        type: c.type,
                        ...c.group,
                        isConnected: false,
                        isPending: true,
                      }
                    : {
                        type: c.type,
                        ...c.group,
                        isConnected: true,
                        isPending: false,
                      }
                ),
              })
            case 2:
              const members = schoolMembersList.map((m) => {
                const connection = schoolConnectionsList.find(
                  (c) => c.user.id === m.id
                )
                return this.combineEntity(m, connection)
              })
              return this.setState({
                dataReceived: true,
                list: [...members],
              })
          }
          break
        case 5:
          await this.props.searchGroups({
            name,
            limit: limit || LIMIT,
            offset,
            type: 4,
          })
          const districtGroupsList = this.props.groups.list.search
          const districtConnectionsList = this.props.invenstory.connectionsList
          const groupsConnectionsList = districtGroupsList.map((g) => {
            const connection = districtConnectionsList.find(
              (dg) => dg.group.id === g.id
            )
            return this.combineEntity(g, connection)
          })
          return this.setState({
            dataReceived: true,
            list: groupsConnectionsList,
          })
      }
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  onScroll = async () => {
    if (!this.state.dataReceived) return
    await this.getList({ offset: this.state.list.length })
  }

  sendRequest = async ({ user_id, group_id }) => {
    if (!this.state.dataReceived) return
    this.setState({ dataReceived: false })
    const list = [...this.state.list]
    const {
      groups: { currentGroup },
    } = this.props
    try {
      await this.props.createInvite({
        associated_group_id: group_id,
        associated_user_id: user_id,
        group_id: currentGroup.id,
      })
      this.props.clearInvenstory()
      this.props.clearGroupsList()
      await this.getList({ offset: 0, limit: list.length, isUpdate: true })
      this.setState({ dataReceived: true })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  acceptRequest = async ({ group_id }) => {
    if (!this.state.dataReceived) return
    this.setState({ dataReceived: false })
    const list = [...this.state.list]
    const {
      groups: { currentGroup },
    } = this.props
    try {
      await this.props.acceptInvite({
        associated_group_id: group_id,
        group_id: currentGroup.id,
      })
      this.props.clearInvenstory()
      this.props.clearGroupsList()
      await this.getList({ offset: 0, limit: list.length, isUpdate: true })
      this.setState({ dataReceived: true })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  declineRequest = async ({ group_id }) => {
    if (!this.state.dataReceived) return
    this.setState({ dataReceived: false })
    const list = [...this.state.list]
    const {
      groups: { currentGroup },
    } = this.props
    try {
      await this.props.declineInvite({
        associated_group_id: group_id,
        group_id: currentGroup.id,
      })
      this.props.clearInvenstory()
      this.props.clearGroupsList()
      await this.getList({ offset: 0, limit: list.length, isUpdate: true })
      this.setState({ dataReceived: true })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  getDropDownMenuItems = (entity, isGroup) => {
    if (entity.isPending) {
      return [
        {
          type: "button",
          action: () => {
            this.declineRequest({ group_id: entity.id })
          },
          label: "Decline request",
        },
      ]
    }
    return [
      {
        type: "button",
        action: () => {
          isGroup
            ? this.removeConnection({ group_id: entity.id })
            : this.removeConnection({ user_id: entity.id })
        },
        label: "Remove from InvenStory",
      },
    ]
  }

  cancelRequest = async ({ user_id, group_id }) => {
    if (!this.state.dataReceived) return
    this.setState({ dataReceived: false })
    const list = [...this.state.list]
    const {
      groups: { currentGroup },
    } = this.props
    try {
      await this.props.cancelInvite({
        associated_group_id: group_id,
        associated_user_id: user_id,
        group_id: currentGroup.id,
      })
      this.props.clearInvenstory()
      this.props.clearGroupsList()
      await this.getList({ offset: 0, limit: list.length, isUpdate: true })
      this.setState({ dataReceived: true })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  removeConnection = async ({ user_id, group_id }) => {
    if (!this.state.dataReceived) return
    this.setState({ dataReceived: false })
    const list = [...this.state.list]
    const {
      groups: { currentGroup },
    } = this.props
    try {
      await this.props.removeConnection({
        associated_group_id: group_id,
        associated_user_id: user_id,
        group_id: currentGroup.id,
      })
      this.props.clearInvenstory()
      this.props.clearGroupsList()
      await this.getList({ offset: 0, limit: list.length, isUpdate: true })
      this.setState({ dataReceived: true })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  restrictConnection = async ({ group_id }) => {
    if (!this.state.dataReceived) return
    this.setState({ dataReceived: false })
    const list = [...this.state.list]
    const {
      groups: { currentGroup },
    } = this.props
    try {
      await this.props.restrictConnection({
        associated_group_id: group_id,
        group_id: currentGroup.id,
      })
      this.props.clearInvenstory()
      this.props.clearGroupsList()
      await this.getList({ offset: 0, limit: list.length, isUpdate: true })
      this.setState({ dataReceived: true })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  composeTabs = () => {
    const {
      groups: { currentGroup },
    } = this.props
    switch (currentGroup.type) {
      case 4:
        return {
          pendingUrl: generatePath(routes.GROUP_SETTINGS_INVENSTORY, {
            groupId: currentGroup.id,
          }),
          outgoingUrl: generatePath(routes.GROUP_SETTINGS_INVENSTORY_OUTGOING, {
            groupId: currentGroup.id,
          }),
        }
      default:
        return {}
    }
  }
  processSearch = (name) => {
    const { history } = this.props

    this.searchParams = {
      q: name || undefined,
    }

    const result = qs.stringify(this.searchParams, { arrayFormat: "comma" })

    history.push({
      search: `?${result}`,
    })
  }
  getLabelLayout = (pendingUrl, outgoingUrl) => {
    const {
      groups: { currentGroup },
      subTub,
    } = this.props
    switch (currentGroup.type) {
      case 4:
        return (
          <ul className="h-tabs__nav-list">
            <li
              className={`h-tabs__item ${
                subTub === 1 ? "h-tabs__item--active" : ""
              }`}
            >
              <NavLink
                exact
                activeClassName="h-tabs__item--active"
                to={pendingUrl}
                className="h-tabs__link"
              >
                Pending
              </NavLink>
            </li>
            <li
              className={`h-tabs__item ${
                subTub === 2 ? "h-tabs__item--active" : ""
              }`}
            >
              <NavLink
                exact
                activeClassName="h-tabs__item--active"
                to={outgoingUrl}
                className="h-tabs__link"
              >
                Outgoing
              </NavLink>
            </li>
          </ul>
        )
      case 5:
        return "Requests"
      default:
        return null
    }
  }

  render() {
    const { list, dataReceived } = this.state
    const {
      groups: { currentGroup },
      subTub,
    } = this.props
    const { pendingUrl, outgoingUrl } = this.composeTabs()
    const renderList = list.filter((e) => !e.deleted && !e.blocked)
    const { name } = this.searchParams
    return (
      <div className="common-page__content">
        <main className="common-page__main">
          <ListSearchHeader
            placeholder={`Start typing ${
              currentGroup.type === 5
                ? "school"
                : subTub === 1
                ? "district"
                : "teacher"
            } name`}
            search={this.processSearch}
            title={this.getLabelLayout(pendingUrl, outgoingUrl)}
            noResults={dataReceived && !list.length}
            noResultsLabel={
              name
                ? "Result not found"
                : `You don\`t have ${
                    subTub === 1 ? "pending" : "outgoing"
                  } requests yet`
            }
          />
          {renderList.map((entity, i) => {
            switch (currentGroup.type) {
              case 4:
                if (subTub === 1) {
                  return (
                    <InvenstoryGroupCard
                      key={i}
                      group={!entity.deleted ? entity : undefined}
                      dropdownMenuItems={this.getDropDownMenuItems(
                        entity,
                        true
                      )}
                      hasMenu={entity.isPending}
                      hasButton
                      dataReceived={dataReceived}
                      disabled={dataReceived}
                      buttonType={
                        !dataReceived
                          ? "disabled"
                          : entity.isPending
                          ? "contained"
                          : "outlined"
                      }
                      buttonText={
                        entity.isConnected ? "Close access" : "Open access"
                      }
                      buttonHandler={
                        entity.isConnected
                          ? (_) =>
                              this.restrictConnection({ group_id: entity.id })
                          : (_) => this.acceptRequest({ group_id: entity.id })
                      }
                    />
                  )
                } else {
                  return (
                    <InvenstoryUserCard
                      key={i}
                      user={entity}
                      dataReceived={dataReceived}
                      dropdownMenuItems={this.getDropDownMenuItems(
                        entity,
                        false
                      )}
                      hasMenu={entity.isConnected}
                      hasButton={!entity.isConnected}
                      buttonType={
                        !dataReceived
                          ? "disabled"
                          : entity.isPending
                          ? "outlined"
                          : "contained"
                      }
                      buttonText={
                        entity.isPending ? "Cancel request" : "Send request"
                      }
                      buttonHandler={
                        entity.isConnected
                          ? (_) => this.removeConnection({ user_id: entity.id })
                          : entity.isPending
                          ? (_) => this.cancelRequest({ user_id: entity.id })
                          : (_) => this.sendRequest({ user_id: entity.id })
                      }
                    />
                  )
                }
              case 5:
                return (
                  <InvenstoryGroupCard
                    key={i}
                    group={!entity.deleted ? entity : undefined}
                    dataReceived={dataReceived}
                    dropdownMenuItems={this.getDropDownMenuItems(entity, true)}
                    hasMenu={entity.isConnected}
                    hasButton={!entity.isConnected}
                    buttonType={
                      !dataReceived
                        ? "disabled"
                        : entity.isPending
                        ? "outlined"
                        : "contained"
                    }
                    buttonText={
                      entity.isPending ? "Cancel request" : "Send request"
                    }
                    buttonHandler={
                      entity.isConnected
                        ? (_) => this.removeConnection({ group_id: entity.id })
                        : entity.isPending
                        ? (_) => this.cancelRequest({ group_id: entity.id })
                        : (_) => this.sendRequest({ group_id: entity.id })
                    }
                  />
                )
            }
          })}
          {!dataReceived && <Loader />}
        </main>
      </div>
    )
  }
}

const mapState = ({ auth, groups, invenstory }) => ({
  auth,
  groups,
  invenstory,
})
const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      cancelInvite,
      clearInvenstory,
      createInvite,
      declineInvite,
      getConnectionsList,
      removeConnection,
      getGroupsList,
      searchGroups,
      getGroupMembersList,
      clearGroupsList,
      updateConnectionsList,
      restrictConnection,
      acceptInvite,
    },
    dispatch
  )

export default connect(
  mapState,
  mapDispatch
)(withScroll(withSnackbar(GroupsSettingsInvenstory)))
