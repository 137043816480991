import React from "react"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import Dialog from "@material-ui/core/Dialog/Dialog"
import { withRouter } from "react-router-dom"
import Loader from "../../../../../../../../components/ui/loader"
import Button from "@material-ui/core/Button/Button"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import { format } from "date-fns"
import { useGetCalendarQuery } from "../../../../../../../../library/store/api/calendar/calendarsAPI"

const CalendarDialog = (props) => {
  const { data, isFetching, isSuccess, error } = useGetCalendarQuery(
    props.activeCalendar
  )

  console.log(error)

  return (
    <Dialog
      aria-labelledby="create-record-dialog"
      onClose={props.onClose}
      open={props.open}
    >
      <DialogTitle id="alert-dialog-title" className="text-center">
        Calendar details
      </DialogTitle>
      <DialogContent>
        {isFetching && <Loader />}
        {isSuccess && (
          <div className="f jcc full-width">
            <table className="product-details product-details--invenstory-record">
              <tbody>
                <tr className="product-details__row">
                  <td className="product-details__label">Name</td>
                  <td className="product-details__value">{data.name}</td>
                </tr>
                {data.date_from && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Date from</td>
                    <td className="product-details__value">
                      {format(new Date(data.date_from), "d MMM yyyy")}
                    </td>
                  </tr>
                )}
                {data.date_to && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Date to</td>
                    <td className="product-details__value">
                      {format(new Date(data.date_to), "d MMM yyyy")}
                    </td>
                  </tr>
                )}
                {data.spring_break_date_from && (
                  <tr className="product-details__row">
                    <td className="product-details__label">
                      Spring break start
                    </td>
                    <td className="product-details__value">
                      {format(
                        new Date(data.spring_break_date_from),
                        "d MMM yyyy"
                      )}
                    </td>
                  </tr>
                )}
                {data.spring_break_date_to && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Spring break end</td>
                    <td className="product-details__value">
                      {format(
                        new Date(data.spring_break_date_to),
                        "d MMM yyyy"
                      )}
                    </td>
                  </tr>
                )}
                {data.fall_break_date_from && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Fall break start</td>
                    <td className="product-details__value">
                      {format(
                        new Date(data.fall_break_date_from),
                        "d MMM yyyy"
                      )}
                    </td>
                  </tr>
                )}
                {data.fall_break_date_to && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Fall break end</td>
                    <td className="product-details__value">
                      {format(new Date(data.fall_break_date_to), "d MMM yyyy")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button type="submit" color="primary" onClick={props.onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withRouter(CalendarDialog)
