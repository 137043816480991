import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { managedMeetsList } from "../../../../../library/store/actions/creators/uknowCreators"
import MeetModalsProvider, {
  useMeetModals,
} from "../../../../../hooks/entities/useMeetsModals"
import DeleteForeverOutlined from "@material-ui/icons/DeleteForeverOutlined"
import { useAlert } from "../../../../../hooks/useAlert"
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined"
import DataTable from "../../../../../components/v2/dataTable/components"
import BoxPage from "../../../../../components/v2/layout/boxPage/boxPage"

const columns = [
  { label: "Name", role: "name" },
  { label: "Invitees number", width: 80 },
  { label: "Target/Set Date", width: 100 },
  { label: "Location", width: 120 },
  { label: "Virtual link", width: 130 },
  { label: "Reminder", width: 250 },
  { label: "Manage", width: 130, role: "actions" },
]

const MeetsTable = ({ getRecords }) => {
  const dispatch = useDispatch()
  const { managedMeets } = useSelector(({ uknow }) => uknow)
  const { onFormOpen, onInfoOpen, onDeletionOpen } = useMeetModals()

  return (
    <BoxPage title={"Managed Meets"}>
      <DataTable
        columns={columns}
        data={managedMeets.list}
        getData={async ({ offset, limit, name }) => {
          await dispatch(managedMeetsList({ name, offset, limit }))
        }}
        availableOffsets={managedMeets.availableOffsets}
        isSearchable
        actionsPinned
        isFirstColumnPinned
        customColumnsWidth
        onDetailsOpen={(record) => onInfoOpen(record.id)}
        onEditOpen={(record) => onFormOpen(record.id)}
        notFoundText={"You don't have managed Meets yet"}
        actions={(record) => [
          {
            type: "button",
            onClick: () => onFormOpen(record.id, "copy"),
            label: () => (
              <>
                <FileCopyOutlinedIcon />
                <span>Duplicate</span>
              </>
            ),
          },
          {
            type: "button",
            onClick: () => onDeletionOpen(record.id),
            label: () => (
              <>
                <DeleteForeverOutlined />
                <span>Delete</span>
              </>
            ),
          },
        ]}
      />
    </BoxPage>
  )
}

const ManagedMeets = () => {
  const dispatch = useDispatch()
  const { errorAlert } = useAlert()

  const getRecords = async ({ name, offset, limit } = {}) => {
    try {
      await dispatch(managedMeetsList({ name, offset, limit }))
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <MeetModalsProvider updateList={getRecords}>
      <MeetsTable />
    </MeetModalsProvider>
  )
}

export default ManagedMeets
