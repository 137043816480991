import React from "react"
import ComplaintsModal from "../../components/complaints/complaintsModal"
import CreateComplaintModal from "../../components/complaints/createComplaintModal"

const ComplaintsContext = React.createContext(null)
export const useComplaintsModal = () => React.useContext(ComplaintsContext)

const ComplaintsProvider = ({
  children,
  params = {},
  entityUpdater,
  keyToUpdate,
  mutationType,
  enabled = true,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const onOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)

  return (
    <ComplaintsContext.Provider
      value={{
        onComplainModalOpen: onOpen,
        onComplainModalClose: onClose,
      }}
    >
      {children}

      {enabled && isOpen && (
        <CreateComplaintModal
          open={isOpen}
          onClose={onClose}
          updater={entityUpdater}
          mutationKey={keyToUpdate}
          mutationType={mutationType}
          {...params}
        />
      )}
    </ComplaintsContext.Provider>
  )
}

export default ComplaintsProvider
