import { useSelector } from "react-redux"

export const usePermissionChecker = (
  allowedPlans = [],
  allowGrantedSubscription
) => {
  const { subscriptions } = useSelector(({ auth }) => auth)

  if (subscriptions?.system_roles?.includes(1) || !allowedPlans.length)
    return true

  if (allowGrantedSubscription) {
    return !!subscriptions?.granted_group_subscription
  }

  return (
    allowedPlans.includes(
      Number(subscriptions?.personal_subscription?.plan_id)
    ) ||
    allowedPlans.includes(Number(subscriptions?.system_subscription?.plan_id))
  )
}
