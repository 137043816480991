import React from "react"
import { Route, Switch } from "react-router-dom"
import * as routes from "../../../library/constants/routes"
import KnowmixList from "./knowmixList"
import "./knowmix.scss"
import SEO from "../../../components/SEO"

const Knowmix = () => {
  return (
    <div>
      <SEO title={"Courses"} />
      <Switch>
        <Route
          exact
          path={routes.KNOWMIX_LIST}
          render={(props) => (
            <KnowmixList {...props} type={"all"} gridType={"square"} />
          )}
        />
        <Route
          exact
          path={routes.KNOWMIX_LIST_MANAGED}
          render={(props) => (
            <KnowmixList {...props} type={"managed"} gridType={"square"} />
          )}
        />
        <Route
          exact
          path={routes.KNOWMIX_LIST_FINISHED}
          render={(props) => (
            <KnowmixList
              {...props}
              type={"all"}
              gridType={"square"}
              tabType={[3]}
            />
          )}
        />
        <Route
          exact
          path={routes.KNOWMIX_LIST_FINISHED_MANAGED}
          render={(props) => (
            <KnowmixList
              {...props}
              type={"managed"}
              gridType={"square"}
              tabType={[3]}
            />
          )}
        />
        <Route
          exact
          path={routes.KNOWMIX_LIST_NOT_STARTED}
          render={(props) => (
            <KnowmixList
              {...props}
              type={"all"}
              gridType={"square"}
              tabType={[1]}
            />
          )}
        />
        <Route
          exact
          path={routes.KNOWMIX_LIST_NOT_STARTED_MANAGED}
          render={(props) => (
            <KnowmixList
              {...props}
              type={"managed"}
              gridType={"square"}
              tabType={[1]}
            />
          )}
        />
        <Route
          exact
          path={routes.KNOWMIX_LIST_STARTED}
          render={(props) => (
            <KnowmixList
              {...props}
              type={"all"}
              gridType={"square"}
              tabType={[2]}
            />
          )}
        />
        <Route
          exact
          path={routes.KNOWMIX_LIST_STARTED_MANAGED}
          render={(props) => (
            <KnowmixList
              {...props}
              type={"managed"}
              gridType={"square"}
              tabType={[2]}
            />
          )}
        />
        <Route
          exact
          path={routes.KNOWMIX_LIST_MY_GRADES}
          render={(props) => (
            <KnowmixList
              {...props}
              gridType={"square"}
              isMyGrades
              tabType={[2, 3]}
            />
          )}
        />
        <Route
          exact
          path={routes.KNOWMIX_LIST_MY_GRADES_STARTED}
          render={(props) => (
            <KnowmixList
              {...props}
              gridType={"square"}
              tabType={[2]}
              isMyGrades
            />
          )}
        />
        <Route
          exact
          path={routes.KNOWMIX_LIST_MY_GRADES_FINISHED}
          render={(props) => (
            <KnowmixList
              {...props}
              gridType={"square"}
              tabType={[3]}
              isMyGrades
            />
          )}
        />
        <Route
          exact
          path={routes.SUBMITTED_WORKS}
          render={(props) => (
            <KnowmixList {...props} type={"pending"} gridType={"inline"} />
          )}
        />
        <Route
          exact
          path={routes.SUBMITTED_WORKS_EXAMINED}
          render={(props) => (
            <KnowmixList {...props} type={"examined"} gridType={"inline"} />
          )}
        />
        <Route
          exact
          path={routes.SUBMITTED_WORKS_STEPS}
          render={(props) => (
            <KnowmixList
              {...props}
              type={"pending"}
              gridType={"inline"}
              entity={"step"}
            />
          )}
        />
        <Route
          exact
          path={routes.SUBMITTED_WORKS_STEPS_EXAMINED}
          render={(props) => (
            <KnowmixList
              {...props}
              type={"examined"}
              gridType={"inline"}
              entity={"step"}
            />
          )}
        />
        <Route
          exact
          path={routes.SUBMITTED_WORKS_KNOWMIX}
          render={(props) => (
            <KnowmixList
              {...props}
              type={"pending"}
              gridType={"inline"}
              entity={"work"}
            />
          )}
        />
        <Route
          exact
          path={routes.SUBMITTED_WORKS_KNOWMIX_EXAMINED}
          render={(props) => (
            <KnowmixList
              {...props}
              type={"examined"}
              gridType={"inline"}
              entity={"work"}
            />
          )}
        />
      </Switch>
    </div>
  )
}

export default Knowmix
