import React from "react"
import Dialog from "@material-ui/core/Dialog/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import Loader from "../../../../../../components/ui/loader"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import { useCalendar } from "../../../CalendarContext"
import styled from "@emotion/styled"
import InsertDriveFile from "@material-ui/icons/InsertDriveFile"
import { formatBytes } from "../../../../../../library/utils/fileSizeConverter"
import UserLink from "../../../../../../components/v2/utils/UserLink"
import { safeFormatDate } from "../../../../../../library/utils/dateConvert"
import { CALENDARS } from "../../../../../../library/store/actions/types/calendarTypes"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { call } from "../../../../../../library/networking/API"
import { useAlert } from "../../../../../../hooks/useAlert"
import { ErrorMessage } from "formik"
import { useIdParam } from "../../../../../../hooks/useIdParam"
import { getCalendarEventsList } from "../../../../../../library/store/actions/creators/calendarCreators"
import useCreator from "../../../../../../hooks/useCreator"

const ColorIndicator = styled.div`
  width: 18px;
  height: 18px;
  background-color: ${(props) => props.color || "transparent"};
  border-radius: 2px;
`

const AppointmentDialog = ({
  open,
  onClose,
  activeAppointment,
  contextType,
  activeRange,
}) => {
  const { errorAlert, successAlert } = useAlert()
  const queryClient = useQueryClient()
  const groupId = useIdParam("groupId")

  const { data, isSuccess, ...rest } = useQuery({
    queryKey: [CALENDARS.APPOINTMENTS.GET, { id: activeAppointment }],
    queryFn: async () => {
      const response = await call(CALENDARS.APPOINTMENTS.GET, {
        id: activeAppointment,
      })
      return await response.data
    },
    refetchOnWindowFocus: false,
  })

  const { onEventFormModalOpen } = useCalendar()

  const creator = useCreator()

  const onDelete = async () => {
    try {
      await call(CALENDARS.APPOINTMENTS.DELETE, {
        id: activeAppointment,
      })

      if (contextType === "calendar") {
        await creator(
          getCalendarEventsList({
            group_id: groupId || undefined,
            ...activeRange,
          })
        )
      } else {
        await queryClient.invalidateQueries([CALENDARS.EVENTS.LIST])
      }
      successAlert("Appointment deleted")
      onClose()
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <Dialog aria-labelledby="event-dialog" onClose={onClose} open={open}>
      <DialogTitle id="alert-dialog-title" className="text-center">
        Appointment details
      </DialogTitle>
      <DialogContent>
        {isSuccess && (
          <div className="f jcc full-width">
            <table className="product-details product-details--invenstory-record">
              <tbody>
                <tr className="product-details__row">
                  <td className="product-details__label">Title</td>
                  <td className="product-details__value">{data.title}</td>
                </tr>

                <tr className="product-details__row">
                  <td className="product-details__label">Creator</td>
                  <td className="product-details__value">
                    <UserLink user={data?.creator} />
                  </td>
                </tr>

                {!!data.details && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Message</td>
                    <td className="product-details__value">{data.details}</td>
                  </tr>
                )}

                {!data.is_all_day ? (
                  <>
                    {!!data.date_from && (
                      <tr className="product-details__row">
                        <td className="product-details__label">Start</td>
                        <td className="product-details__value">
                          {safeFormatDate(new Date(data.date_from), {
                            withTime: true,
                          })}
                        </td>
                      </tr>
                    )}

                    {!!data.date_to && (
                      <tr className="product-details__row">
                        <td className="product-details__label">Finish</td>
                        <td className="product-details__value">
                          {safeFormatDate(new Date(data.date_to), {
                            withTime: true,
                          })}
                        </td>
                      </tr>
                    )}
                  </>
                ) : (
                  <>
                    {!!data.date_from && (
                      <tr className="product-details__row">
                        <td className="product-details__label">Date</td>
                        <td className="product-details__value">
                          {safeFormatDate(new Date(data.date_from))}
                        </td>
                      </tr>
                    )}
                  </>
                )}

                {!!data.conference_link && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Conference link</td>
                    <td className="product-details__value">
                      <a href={data.conference_link} className={"link"}>
                        {data.conference_link}
                      </a>
                    </td>
                  </tr>
                )}

                {!!data.attachments?.blobs?.length && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Attachments</td>
                    <td className="product-details__value">
                      <div className="post__files">
                        {data.attachments?.blobs.map(
                          ({ id, name, size, url }) => {
                            return (
                              <a
                                href={url}
                                target="_blank"
                                className="post__file"
                                key={id}
                              >
                                <InsertDriveFile
                                  className="post__file-icon"
                                  style={{ fontSize: "30px" }}
                                />
                                <div className="post__file-info">
                                  <span className="link link--inline">
                                    {name}
                                  </span>
                                  <span className="post__file-size">
                                    {formatBytes(size)}
                                  </span>
                                </div>
                              </a>
                            )
                          }
                        )}
                      </div>
                    </td>
                  </tr>
                )}

                {!!data.participants.length && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Participants</td>
                    <td className="product-details__value">
                      <div>
                        {data.participants.map((user) => (
                          <div className="mb5" key={user.id}>
                            <UserLink user={user} />
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                )}

                {!!data.color && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Color</td>
                    <td className="product-details__value">
                      <ColorIndicator color={data.color} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
        {rest.isLoading && <Loader />}
        {rest.isError && <p>{rest.error}</p>}
      </DialogContent>
      <DialogActions>
        <div className="full-width f jcsb">
          <div className="f">
            {!!data?.b_creator && (
              <>
                <Button color="primary" onClick={onEventFormModalOpen}>
                  Edit
                </Button>
                <Button color="primary" onClick={onDelete}>
                  Delete
                </Button>
              </>
            )}
          </div>
          <Button color="primary" onClick={onClose}>
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default AppointmentDialog
