import React, { useState } from "react"
import MoreDropdown from "../../../../../../../../components/ui/dropdowns/moreDropdown/moreDropdown"
import VerticalMenu from "../../../../../../../../components/ui/verticalMenu/verticalMenu"
import { compact } from "lodash"
import PlainLink from "../../../../../../../../components/ui/link"
import { generatePath, withRouter } from "react-router-dom"
import * as routes from "../../../../../../../../library/constants/routes"
import Button from "@material-ui/core/Button"
import { format } from "date-fns"

const CalendarsListItem = (props) => {
  const [isUsed, setIsUsed] = useState(props.calendar.is_used)

  const useCalendar = async () => {
    props.createSchedule(props.calendar.id)
    setIsUsed(true)
  }

  const dropdownMenuItems =
    props.calendarType === "district"
      ? compact([
          !props.calendar.completed && {
            type: "button",
            action: () => props.openCompleteCalendar(props.calendar.id),
            label: "Complete Calendar",
          },
          {
            type: "button",
            action: () => props.openEditCalendar(props.calendar.id),
            label: "Edit Calendar",
          },
          !props.calendar.completed && {
            type: "h-divider",
            id: "hd1",
          },
          !props.calendar.completed && {
            type: "button",
            action: () => props.openDeleteCalendar(props.calendar.id),
            label: "Delete Calendar",
          },
        ])
      : [
          {
            type: "button",
            action: () => props.openDeleteCalendar(props.calendar.id),
            label: "Delete Calendar",
          },
        ]

  return (
    <div className={"box list-item"}>
      <div className="f aic full-width">
        <div className="list-item__info">
          <div className="f">
            {((props.calendarType === "school" && props.schoolView) ||
              (props.calendarType === "district" && !props.schoolView)) && (
              <PlainLink
                to={
                  props.calendarType === "district"
                    ? generatePath(
                        routes.GROUP_SETTINGS_DISTRICT_CALENDAR_SUBJECTS,
                        {
                          groupId: props.match.params.groupId,
                          calendarId: props.calendar.id,
                        }
                      )
                    : // : generatePath(routes.GROUP_SETTINGS_CALENDAR_KOURSES, {
                      generatePath(routes.GROUP_SETTINGS_CALENDAR_GRADES, {
                        groupId: props.match.params.groupId,
                        calendarId: props.calendar.id,
                      })
                }
                bold="true"
              >
                {props.calendar.name}
              </PlainLink>
            )}

            {(props.publicView ||
              (props.calendarType === "district" && props.schoolView)) && (
              <button
                className={"link font-weight-500"}
                onClick={() => props.openCalendarDetails(props.calendar.id)}
              >
                {props.calendar.name}
              </button>
            )}

            {!props.schoolView && !props.publicView && (
              <pre className={"color-black-54"}>
                {props.calendar.completed && " (completed)"}
                {props.calendar.deleted && " (deleted)"}
              </pre>
            )}
          </div>

          <span className="list-item__description mb0">
            {format(new Date(props.calendar.date_from), "d MMM yyyy")} -{" "}
            {format(new Date(props.calendar.date_to), "d MMM yyyy")}
          </span>
        </div>
      </div>
      {!props.publicView && (
        <div className={`list-item__control-panel`}>
          {!props.schoolView ? (
            props.calendarType === "district" &&
            !props.calendar.deleted && (
              <MoreDropdown>
                <VerticalMenu
                  menuItems={dropdownMenuItems}
                  classes={"more-dropdown-menu__list"}
                />
              </MoreDropdown>
            )
          ) : props.calendarType === "district" &&
            props.calendar.completed &&
            !isUsed ? (
            <Button variant="contained" color="primary" onClick={useCalendar}>
              Assign to school
            </Button>
          ) : (
            props.calendarType === "school" && (
              <MoreDropdown>
                <VerticalMenu
                  menuItems={dropdownMenuItems}
                  classes={"more-dropdown-menu__list"}
                />
              </MoreDropdown>
            )
          )}
        </div>
      )}
    </div>
  )
}

export default withRouter(CalendarsListItem)
