// export const columns = [
//     {id: 1, name: 'Name', required: true},
//     {id: 2, name: 'Standards'},
//     {id: 3, name: 'Added by'},
//     {id: 4, name: 'Content type'},
//     {id: 5, name: 'Subjects'},
//     {id: 6, name: 'Grades'},
//     {id: 7, name: 'URL'},
//     {id: 8, name: 'File types'},
//     {id: 9, name: 'Platforms'},
//     {id: 10, name: 'Version'}
// ];

export const columns = [
    {id: 1, name: "Name", required: true},
    {id: 2, name: "Added by"},
    {id: 3, name: "Content type"},
    {id: 4, name: "Subjects"},
    {id: 5, name: "Grades"},
    {id: 6, name: "URL"},
    {id: 7, name: "File types"},
    {id: 8, name: "Platforms"},
    {id: 9, name: "Version"},
];

export const defaultActiveColumns = columns.map(c => c.id);
