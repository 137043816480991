import { USER } from "../actions/types/authTypes"
import { LIMIT } from "../../constants/limits"
import { APPOINTMENTS } from "../actions/types/calendarTypes"

const initialState = {
  knowcredId: null,
  newFriendId: null,
  userData: null,
  isLoggedIn: false,
  blacklist: [],
  blacklistEndReached: false,
  blockedUsersList: [],
  blockedUsersListEndReached: false,
  meetHash: null,
  subscriptions: {
    bg_check: false,
    personal_subscription: null,
    group_subscriptions: [],
  },
  appointmentTypes: [],
  inviterGroupId: null,
}

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER.SIGNED_IN:
    case USER.SIGNED_UP:
      localStorage.setItem("isLoggedIn", "true")
      return {
        ...state,
        userData: action.payload,
        isLoggedIn: true,
      }

    case USER.SIGNED_OUT:
    case USER.SIGN_UP_CONFIRMED:
    case USER.ACCOUNT_DELETED:
      localStorage.setItem("isLoggedIn", "false")
      return initialState

    case USER.DATA_RECEIVED:
    case USER.FRIENDS_RECEIVED:
      return {
        ...state,
        userData: { ...state.userData, ...action.payload },
      }

    case USER.CURRENT_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.payload,
      }

    case USER.GOOGLE_CODE_REVOKE:
      return {
        ...state,
        userData: { ...state.userData, has_google_integration: false },
      }

    case USER.AZURE_CODE_REVOKE:
      return {
        ...state,
        userData: { ...state.userData, has_outlook_integration: false },
      }

    case USER.HAS_KNOWKRED:
      return {
        ...state,
        knowcredId: action.payload,
      }

    case USER.HAS_FRIEND:
      return {
        ...state,
        newFriendId: action.payload,
      }

    case USER.SAVE_MEET_HASH:
      return {
        ...state,
        meetHash: action.payload,
      }

    case USER.HAS_GROUP:
      return {
        ...state,
        inviterGroupId: action.payload,
      }

    case USER.BLACKLIST.GET:
      return {
        ...state,
        blacklist:
          action.payload.offset === 0
            ? action.payload.list
            : [...state.blacklist, ...action.payload.list],
        blockedUsersList:
          action.payload.offset === 0
            ? action.payload.list
            : [...state.blockedUsersList, ...action.payload.list],
        blacklistEndReached:
          !action.payload.list.length || action.payload.list.length < LIMIT,
        blockedUsersListEndReached:
          !action.payload.list.length || action.payload.list.length < LIMIT,
      }

    case USER.BLACKLIST.CLEAR:
      return {
        ...state,
        blacklist: [],
        blockedUsersList: [],
        blacklistEndReached: false,
        blockedUsersListEndReached: false,
      }

    case USER.BLACKLIST.REMOVE_USER:
      return {
        ...state,
        blacklist: state.blacklist.filter(
          (user) => user.id !== action.payload.user_id
        ),
        blockedUsersList: state.blockedUsersList.filter(
          (user) => user.id !== action.payload.user_id
        ),
      }

    case APPOINTMENTS.GET_TYPES:
    case APPOINTMENTS.UPDATE_TYPES:
      return {
        ...state,
        appointmentTypes: action.payload,
      }

    default:
      return state
  }
}
