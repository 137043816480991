import React, { Component } from "react"
import "./productSettingsMain.scss"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import Radio from "@material-ui/core/Radio/Radio"
import FormControl from "@material-ui/core/FormControl/FormControl"
import Select from "@material-ui/core/Select/Select"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import { productContentTypes } from "../../../../../library/constants/productDictionary"
import {
  grades as gradesList,
  standardsGrades as standardsGradesList,
  subjects as subjectsList,
} from "../../../../../library/constants/educationalDictionary"
import Button from "@material-ui/core/Button/Button"
import {
  deleteProduct,
  getProduct,
  updateProduct,
} from "../../../../../library/store/actions/creators/productsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { bindActionCreators } from "redux"
import {
  clearGroupsList,
  getGroupsList,
} from "../../../../../library/store/actions/creators/groupsCreators"
import { generatePath, Redirect } from "react-router-dom"
import * as routes from "../../../../../library/constants/routes"
import AlertDialog from "../../../../../components/ui/alertDialog/alertDialog"
import { getObjectById } from "../../../../../library/utils/arrays"
import FormHelperText from "@material-ui/core/FormHelperText"
import Loader from "../../../../../components/ui/loader"
import getErrorText from "../../../../../library/constants/errorTypes"
import MultiSelect from "../../../../../components/ui/multiSelect"
import { call } from "../../../../../library/networking/API"
import { STANDARDS } from "../../../../../library/store/actions/types/standardsTypes"
import { KNOWMIX } from "../../../../../library/store/actions/types/knowmixTypes"
import { statesAndThames } from "../../../../../library/constants/statesAndThames"
import Checkbox from "@material-ui/core/Checkbox/Checkbox"

class ProductSettingsMain extends Component {
  state = {
    name: this.props.products.currentProduct.name,
    createFrom: this.props.products.currentProduct.creator_user ? "1" : "2",
    groupId: this.props.products.currentProduct.creator_group
      ? this.props.products.currentProduct.creator_group.id
      : "",
    groupIdError: false,
    paid: this.props.products.currentProduct.paid ? "true" : "false",
    description: this.props.products.currentProduct.description,
    contentType: this.props.products.currentProduct.content_type,
    contentTypeError: false,
    subjects: this.props.products.currentProduct.subjects,
    grades: this.props.products.currentProduct.grades,
    publisher: this.props.products.currentProduct.publisher,
    author: this.props.products.currentProduct.author,
    groupsList: [],
    openProductDelete: false,
    openCreatorChange: false,
    productDeleted: false,
    standards: [],
    chosenStandards: this.props.products.currentProduct.standards || [],
    standardsName: "",
    standardsReceived: true,
    knowmixes: [],
    chosenKnowMix: this.props.products.currentProduct.knowmix
      ? {
          label: this.props.products.currentProduct.knowmix.name,
          value: this.props.products.currentProduct.knowmix.id,
        }
      : null,
    knowmixName: "",
    knowmixError: false,
    knowmixesReceived: false,
    chosenState: null,
    chosenStandardSubjects: [],
    standardsGrades: [],
    email: this.props.products.currentProduct.contact_email || "",
    demo: this.props.products.currentProduct.has_demo || false,
  }

  async componentDidMount() {
    const {
      auth: { userData },
      products: { currentProduct },
    } = this.props

    if (currentProduct.creator_user) {
      try {
        await this.props.getGroupsList({
          user_id: userData.id,
          type: "all",
          managed: true,
          active: true,
        })
        await this.getStandards({})
        await this.getKnowMixes({})
        this.setState({
          groupsList: this.props.groups.list.all,
          dataReceived: true,
        })
      } catch ({ error }) {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    } else {
      await this.getStandards({})
      await this.getKnowMixes({})
      this.setState({
        groupsList: [currentProduct.creator_group],
        dataReceived: true,
      })
    }
  }

  componentWillUnmount() {
    this.props.clearGroupsList()
  }

  handleChange = (name) => (event) => {
    this.setState(
      {
        [name]: event.target.value,
      },
      () => {
        if (
          name === "chosenState" ||
          name === "chosenStandardSubjects" ||
          name === "standardsGrades"
        ) {
          this.setState(
            {
              standards: [],
              chosenStandardSubjects:
                name === "chosenState" ? [] : this.state.chosenStandardSubjects,
            },
            this.searchStandards
          )
        }
      }
    )
  }

  handleChangeRequired = (name, event) => {
    switch (name) {
      case "groupId":
        this.setState({
          groupIdError: event.target.value.length === 0,
          groupId: event.target.value,
        })
        break

      case "contentType":
        this.setState({
          contentTypeError: event.target.value.length === 0,
          contentType: event.target.value,
        })
        break

      default:
        this.setState({
          [name]: event.target.value,
        })
        break
    }
  }

  handleClickOpen = (name) => {
    this.setState({ [name]: true })
  }

  handleClose = (name) => {
    this.setState({ [name]: false })
  }

  onSubmitUpdatedCreator = () => {
    const { groupId, groupIdError } = this.state
    if (groupId === "" || groupIdError) {
      if (groupId === "") {
        this.setState({ groupIdError: true })
      }

      return
    }

    this.handleClickOpen("openCreatorChange")
  }

  onCreatorChangeDecline = () => {
    this.setState({
      createFrom: "1",
      groupId: "",
      openCreatorChange: false,
    })
  }

  processInfoSave = async () => {
    const {
      products: { currentProduct },
    } = this.props
    const {
      name,
      paid,
      description,
      createFrom,
      groupId,
      groupIdError,
      author,
      contentType,
      contentTypeError,
      grades,
      publisher,
      subjects,
      chosenStandards,
      chosenKnowMix,
      email,
      demo,
    } = this.state
    const standards = chosenStandards.map((s) =>
      typeof s === "number" ? s : s.id
    )
    if (
      ((groupId === "" || groupIdError) && createFrom === "2") ||
      contentTypeError
    ) {
      if (groupId === "") {
        this.setState({ groupIdError: true })
      }

      return
    }
    if (contentType === 15 && !chosenKnowMix) {
      return this.setState({
        knowmixError: true,
      })
    }

    try {
      this.setState({ groupIdError: false, openCreatorChange: false })
      await this.props.updateProduct({
        id: currentProduct.id,
        name,
        paid: paid === "true",
        description,
        group_id: currentProduct.creator_user
          ? createFrom === "2"
            ? groupId
            : undefined
          : undefined,
        author,
        content_type: contentType,
        grades,
        publisher,
        subjects,
        standards,
        knowmix_id:
          contentType === 15 && chosenKnowMix ? chosenKnowMix.value : undefined,
        contact_email: email,
        has_demo: demo,
      })
      await this.props.enqueueSnackbar("Information updated", {
        variant: "success",
      })
      await this.props.getProduct({ id: currentProduct.id })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  processDeleteProduct = async () => {
    const {
      match: { params },
    } = this.props
    try {
      await this.props.deleteProduct({ id: parseInt(params.productId) })
      this.setState({ productDeleted: true })
      this.props.enqueueSnackbar("Product deleted", { variant: "success" })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  onScroll = async () => {
    if (!this.state.standardsReceived) return
    const {
      standardsName,
      standardsGrades,
      chosenState,
      chosenStandardSubjects,
      standards,
    } = this.state
    await this.getStandards({
      standardsName,
      grades: standardsGrades.length ? standardsGrades : undefined,
      chosenState: chosenState || undefined,
      chosenStandardSubjects,
      offset: standards.length,
    })
  }
  setStandardsName = (standardsName) => {
    this.setState(
      {
        standardsName,
        standardsReceived: false,
        standards: [],
      },
      this.searchStandards
    )
  }
  setChosenStandards = (chosenStandards) => {
    this.setState({
      chosenStandards: chosenStandards.map((s) => s.value),
    })
  }
  searchStandards = async () => {
    const {
      standardsName,
      standardsGrades,
      chosenState,
      chosenStandardSubjects,
    } = this.state
    await this.getStandards({
      standardsName,
      grades: standardsGrades.length ? standardsGrades : undefined,
      chosenState: chosenState || undefined,
      chosenStandardSubjects,
    })
  }
  getStandards = async ({
    standardsName,
    grades = [],
    chosenState,
    chosenStandardSubjects,
    limit = 20,
    offset = 0,
  }) => {
    this.setState({ standardsReceived: false })
    const { standards } = this.state
    try {
      const response = await call(STANDARDS.SEARCH_LIST, {
        token: this.props.auth.userData.token,
        name: standardsName || undefined,
        grades: grades.length ? grades : undefined,
        limit,
        offset,
        state: chosenState,
        subjects:
          chosenStandardSubjects && chosenStandardSubjects.length
            ? chosenStandardSubjects
            : undefined,
      })
      this.setState({
        standardsReceived: true,
        standards: [...standards, ...response.data.standards],
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  scrollKnowmixes = async () => {
    if (!this.state.knowmixesReceived) return
    const { knowmixName, knowmixes } = this.state
    await this.getKnowMixes({
      knowmixName,
      offset: knowmixes.length,
    })
  }

  setKnowMix = (knowmix) => {
    this.setState({
      chosenKnowMix: knowmix,
      knowmixError: !knowmix,
    })
  }
  setKnowMixName = (knowmixName) => {
    this.setState(
      {
        knowmixName,
        knowmixesReceived: false,
        knowmixes: [],
      },
      this.searchKnowmixes
    )
  }

  searchKnowmixes = async () => {
    const { knowmixName } = this.state
    await this.getKnowMixes({ knowmixName })
  }

  getKnowMixes = async ({ knowmixName, limit = 20, offset = 0 }) => {
    this.setState({ knowmixesReceived: false })
    const { knowmixes } = this.state
    try {
      const response = await call(KNOWMIX.LIST, {
        token: this.props.auth.userData.token,
        name: knowmixName || undefined,
        managed: true,
        offset,
        limit,
        active: true,
        graded: false,
        finished: true,
        unpublished: true,
      })
      this.setState({
        knowmixesReceived: true,
        knowmixes: [...knowmixes, ...response.data.knowmixes],
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }
  processFormValidate = () => {
    const { contentType, chosenKnowMix } = this.state
    if (contentType === 15 && !chosenKnowMix) {
      return this.setState({
        knowmixError: true,
      })
    } else {
      return this.setState({
        knowmixError: false,
      })
    }
  }

  render() {
    const {
      products: { currentProduct },
    } = this.props

    const {
      name,
      createFrom,
      groupId,
      groupIdError,
      paid,
      description,
      contentType,
      contentTypeError,
      subjects,
      grades,
      publisher,
      author,
      groupsList,
      openProductDelete,
      openCreatorChange,
      productDeleted,
      dataReceived,
      standards,
      standardsReceived,
      chosenKnowMix,
      knowmixError,
      knowmixes,
      chosenStandardSubjects,
      chosenState,
      standardsGrades,
      chosenStandards,
      email,
    } = this.state
    const renderKnowmixes = knowmixes.filter((k) => !k.is_public)

    const disabledRadio = !!this.props.products.currentProduct.creator_group

    const states = statesAndThames
    const standardSubjects = states
      .filter((s) => {
        return s.id === chosenState
      })
      .map((s) => s.subjects)
      .flat()

    if (productDeleted) {
      return (
        <Redirect
          to={generatePath(routes.PRODUCT, { productId: currentProduct.id })}
        />
      )
    }

    return dataReceived ? (
      <div className="box">
        <div className="box__heading">Main information</div>
        <div className="box__content">
          <ValidatorForm
            className="form"
            onSubmit={
              currentProduct.creator_user
                ? createFrom === "2"
                  ? this.onSubmitUpdatedCreator
                  : this.processInfoSave
                : this.processInfoSave
            }
          >
            <div className="form__fieldset">
              <label htmlFor="name" className="form__label">
                Product name *
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <TextValidator
                    id="name"
                    name="name"
                    value={name}
                    placeholder="Type product name"
                    className="edit-form__input"
                    margin="normal"
                    fullWidth
                    validators={["required", "maxStringLength:200"]}
                    errorMessages={[
                      "Field is required",
                      "Max length is 200 characters",
                    ]}
                    withRequiredValidator
                    onChange={this.handleChange("name")}
                  />
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor="create-from" className="form__label">
                Create from *
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <FormControl component="fieldset" id="create-from">
                    <RadioGroup
                      aria-label="create-from"
                      name="create-from"
                      value={createFrom}
                      onChange={this.handleChange("createFrom")}
                    >
                      {/*<FormControlLabel*/}
                      {/*    value="1"*/}
                      {/*    control={<Radio color="primary"/>}*/}
                      {/*    disabled={disabledRadio}*/}
                      {/*    label="Yourself"*/}
                      {/*    labelPlacement="end"*/}
                      {/*/>*/}
                      <FormControlLabel
                        value="2"
                        control={<Radio color="primary" />}
                        disabled={disabledRadio}
                        label="Group"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div>

            {createFrom === "2" && (
              <div className="form__fieldset">
                <label htmlFor="group-id" className="form__label">
                  Group *
                </label>
                <div className="form__fieldbox">
                  <div className="form__input form__input--select">
                    <FormControl
                      error={groupIdError}
                      disabled={!!currentProduct.creator_group}
                      fullWidth
                    >
                      <Select
                        id="group-id"
                        name="groupId"
                        fullWidth
                        disabled={!!currentProduct.creator_group}
                        displayEmpty
                        value={groupId}
                        onChange={(event) =>
                          this.handleChangeRequired("groupId", event)
                        }
                        renderValue={(value) => {
                          if (value.length === 0) {
                            return (
                              <span className="color-black-38">
                                Select group
                              </span>
                            )
                          }

                          return getObjectById(groupsList, value).name
                        }}
                      >
                        {groupsList.map((item) => (
                          <MenuItem value={item.id} key={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {groupIdError && (
                        <FormHelperText>Field is required</FormHelperText>
                      )}
                      {/*{!!currentProduct.creator_group && (*/}
                      {/*    <FormHelperText>Group cannot be changed</FormHelperText>*/}
                      {/*)}*/}
                    </FormControl>
                  </div>
                </div>
              </div>
            )}

            <div className="form__fieldset">
              <label htmlFor="paid" className="form__label">
                Status *
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <FormControl component="fieldset" id="paid">
                    <RadioGroup
                      aria-label="paid"
                      name="paid"
                      value={String(paid)}
                      onChange={this.handleChange("paid")}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio color="primary" />}
                        label="Paid"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio color="primary" />}
                        label="Free"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div>

            {paid === "true" && (
              <div className="form__fieldset">
                <span className="form__label" />
                <div className="form__fieldbox">
                  <div className="form__input">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.demo}
                          onChange={(e) =>
                            this.setState({ demo: e.target.checked })
                          }
                          value={"demo"}
                        />
                      }
                      label={"Demo available"}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="form__fieldset">
              <label htmlFor="description" className="form__label">
                Description *
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <TextValidator
                    id="description"
                    name="description"
                    value={description}
                    placeholder="Type product description"
                    className="edit-form__input"
                    margin="normal"
                    fullWidth
                    multiline
                    validators={["required", "maxStringLength:5000"]}
                    errorMessages={[
                      "Field is required",
                      "Max length is 5000 characters",
                    ]}
                    withRequiredValidator
                    onChange={this.handleChange("description")}
                  />
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor="contentType" className="form__label">
                Content type *
              </label>
              <div className="form__fieldbox">
                <div className="form__input form__input--select">
                  <FormControl error={contentTypeError} fullWidth>
                    <Select
                      id="content-type"
                      name="contentType"
                      fullWidth
                      disabled={!!this.props.products.currentProduct.knowmix}
                      displayEmpty
                      value={contentType}
                      onChange={(event) =>
                        this.handleChangeRequired("contentType", event)
                      }
                      renderValue={(value) => {
                        if (value.length === 0) {
                          return (
                            <span className="color-black-38">
                              Select content type
                            </span>
                          )
                        }

                        return getObjectById(productContentTypes, value).name
                      }}
                    >
                      {/*<MenuItem value="">*/}
                      {/*<span className="color-black-38">*/}
                      {/*None*/}
                      {/*</span>*/}
                      {/*</MenuItem>*/}
                      {productContentTypes.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {contentTypeError && (
                      <FormHelperText>Field is required</FormHelperText>
                    )}
                  </FormControl>
                </div>
              </div>
            </div>

            {contentType === 15 && (
              <div className="form__fieldset">
                <label htmlFor="knowmix" className="form__label">
                  KnowMix *
                </label>
                <div className="form__fieldbox">
                  <div className="form__input form__input--select">
                    <FormControl error={knowmixError} fullWidth>
                      <MultiSelect
                        name="knowmix"
                        list={renderKnowmixes}
                        placeholder="Select KnowMix"
                        disabled={!!this.props.products.currentProduct.knowmix}
                        onScroll={this.scrollKnowmixes}
                        onChange={this.setKnowMix}
                        onInputChange={this.setKnowMixName}
                        emptyArrayMessage={
                          this.state.knowmixesReceived ? (
                            <span>No results</span>
                          ) : (
                            <Loader />
                          )
                        }
                        defaultValue={chosenKnowMix}
                      />
                      {knowmixError && (
                        <FormHelperText>
                          <span className="error-message">
                            Field is required
                          </span>
                        </FormHelperText>
                      )}
                    </FormControl>
                  </div>
                </div>
              </div>
            )}

            <div className="form__fieldset">
              <label htmlFor="subject" className="form__label">
                Subjects
              </label>
              <div className="form__fieldbox">
                <div className="form__input form__input--select">
                  <Select
                    id="subject"
                    name="subject"
                    fullWidth
                    multiple
                    displayEmpty
                    value={subjects}
                    onChange={this.handleChange("subjects")}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <span className="color-black-38">
                            Select subjects
                          </span>
                        )
                      }

                      let selectedNames = selected.map((item) => {
                        return getObjectById(subjectsList, item).name
                      })

                      return selectedNames.join(", ")
                    }}
                  >
                    {subjectsList.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor="grade" className="form__label">
                Grades
              </label>
              <div className="form__fieldbox">
                <div className="form__input form__input--select">
                  <Select
                    id="grade"
                    name="grade"
                    fullWidth
                    multiple
                    displayEmpty
                    value={grades}
                    onChange={this.handleChange("grades")}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <span className="color-black-38">Select grades</span>
                        )
                      }

                      let selectedNames = selected.map((item) => {
                        return getObjectById(gradesList, item).name
                      })

                      return selectedNames.join(", ")
                    }}
                  >
                    {gradesList.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor="grade" className="form__label">
                Standards grades{" "}
              </label>
              <div className="form__fieldbox">
                <div className="form__input form__input--select">
                  <Select
                    id="standardsGrades"
                    name="standardsGrades"
                    fullWidth
                    multiple
                    displayEmpty
                    value={standardsGrades}
                    onChange={this.handleChange("standardsGrades")}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <span className="color-black-38">
                            Select grades for standards
                          </span>
                        )
                      }

                      let selectedNames = selected.map((item) => {
                        return getObjectById(standardsGradesList, item).name
                      })

                      return selectedNames.join(", ")
                    }}
                  >
                    {standardsGradesList.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor="contentType" className="form__label">
                Standards state{" "}
              </label>
              <div className="form__fieldbox">
                <div className="form__input form__input--select">
                  <FormControl fullWidth>
                    <Select
                      id="state"
                      name="state"
                      fullWidth
                      displayEmpty
                      value={chosenState}
                      onChange={this.handleChange("chosenState")}
                      renderValue={(value) => {
                        if (!value || value.length === 0) {
                          return (
                            <span className="color-black-38">
                              Select state for standards
                            </span>
                          )
                        }

                        return getObjectById(states, value).name
                      }}
                    >
                      {states.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>

            {chosenState && (
              <div className="form__fieldset">
                <label htmlFor="grade" className="form__label">
                  Standards subjects{" "}
                </label>
                <div className="form__fieldbox">
                  <div className="form__input form__input--select">
                    <Select
                      id="standardsSubjects"
                      name="standardsSubjects"
                      fullWidth
                      multiple
                      displayEmpty
                      disabled={!chosenState}
                      value={chosenStandardSubjects}
                      onChange={this.handleChange("chosenStandardSubjects")}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                            <span className="color-black-38">
                              Select subjects for standards
                            </span>
                          )
                        }

                        let selectedNames = selected.map((item) => {
                          return getObjectById(standardSubjects, item).name
                        })

                        return selectedNames.join(", ")
                      }}
                    >
                      {standardSubjects.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            )}

            {(!!chosenStandards.length ||
              (chosenState && !!chosenStandardSubjects.length)) && (
              <div className="form__fieldset">
                <label htmlFor="standards" className="form__label">
                  Standards
                </label>
                <div className="form__fieldbox">
                  <div className="form__input form__input--select">
                    <MultiSelect
                      name="standards"
                      isMulti
                      list={standards}
                      defaultValue={currentProduct.standards.map((s) => ({
                        value: s.id,
                        label: s.name,
                      }))}
                      placeholder="Select standards"
                      onScroll={this.onScroll}
                      onChange={this.setChosenStandards}
                      onInputChange={this.setStandardsName}
                      emptyArrayMessage={
                        standardsReceived ? <span>No results</span> : <Loader />
                      }
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="form__fieldset">
              <label htmlFor="email" className="form__label">
                Contact email
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <TextValidator
                    type="text"
                    name="email"
                    value={email}
                    placeholder="Type contact email"
                    className="edit-form__input"
                    margin="normal"
                    fullWidth
                    validators={["isEmail"]}
                    errorMessages={["Email is not valid"]}
                    onChange={this.handleChange("email")}
                  />
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor="publisher" className="form__label">
                Publisher
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <TextValidator
                    id="publisher"
                    name="publisher"
                    value={publisher}
                    placeholder="Type publisher name"
                    className="edit-form__input"
                    margin="normal"
                    fullWidth
                    validators={["maxStringLength:200"]}
                    errorMessages={["Max length is 200 characters"]}
                    onChange={this.handleChange("publisher")}
                  />
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor="author" className="form__label">
                Author
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <TextValidator
                    id="author"
                    name="author"
                    value={author}
                    placeholder="Type author name"
                    className="edit-form__input"
                    margin="normal"
                    fullWidth
                    validators={["maxStringLength:200"]}
                    errorMessages={["Max length is 200 characters"]}
                    onChange={this.handleChange("author")}
                  />
                </div>
              </div>
            </div>

            <Button
              color="primary"
              variant="contained"
              type="submit"
              className="form__submit mb15"
              onClick={this.processFormValidate}
            >
              Save
            </Button>

            <div className="form__divider"></div>

            <div className="f jcc aic color-black-54">
              <div>
                You can{" "}
                <span
                  className="color-primary pointer"
                  onClick={() => this.handleClickOpen("openProductDelete")}
                >
                  delete this product.
                </span>
              </div>
            </div>
          </ValidatorForm>
        </div>

        {openProductDelete && (
          <AlertDialog
            open={openProductDelete}
            handleClose={() => this.handleClose("openProductDelete")}
            handleAccept={this.processDeleteProduct}
            title="Delete product?"
            message="Deleting this product deletes all descriptions, links and information."
          />
        )}

        {openCreatorChange && (
          <AlertDialog
            open={openCreatorChange}
            handleClose={this.onCreatorChangeDecline}
            handleAccept={this.processInfoSave}
            title="Reassign product to group?"
            message="This action cannot be undone"
          />
        )}
      </div>
    ) : (
      <Loader />
    )
  }
}

const mapStateToProps = ({ auth, products, groups }) => ({
  auth,
  products,
  groups,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getGroupsList,
      getProduct,
      updateProduct,
      deleteProduct,
      clearGroupsList,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ProductSettingsMain))
