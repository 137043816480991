import React from "react"
import { SectionHeading } from "../uknow/uknowInfoPage"
import img1 from "../../../assets/img/pages/solutions/schools-and-districts/1.png"
import img2 from "../../../assets/img/pages/solutions/schools-and-districts/2.jpg"
import img3 from "../../../assets/img/pages/solutions/schools-and-districts/3.jpg"
import img4 from "../../../assets/img/pages/solutions/schools-and-districts/4.png"
import img5 from "../../../assets/img/pages/solutions/schools-and-districts/5.jpg"

const SolutionsSchoolsPage = () => {
  return (
    <>
      <section className={"bg-white w-full py-8"}>
        <div className="container">
          <SectionHeading>Schools & Districts</SectionHeading>
          <img
            src={img1}
            alt="Schools & Districts"
            className={"rounded-2xl mb-8"}
          />
          <div className={"grid grid-cols-12"}>
            <div className={"col-start-2 col-span-10"}>
              <SectionHeading>
                Transform to True Pace-Based & Aligned Learning
                <br />
                <span className={"fot-xl leading-normal"}>
                  Give Learners the Personalization They Need
                </span>
              </SectionHeading>
              <p>
                Schools and districts can finally manage the logistics of
                personalized learning and pace-based cohorting without
                sacrificing live teaching moments. True pace-based learning
                means each individual student can be on widely disparate points
                of the curriculum yet still have live classes appropriate to
                them - with cohorts of other students at the same points.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={"bg-grey7 w-full py-8"}>
        <div className="container">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-start-2 col-span-10 space-y-4">
              <h3 className={"font-medium text-xl"}>What’s Different?</h3>
              <p>
                <b>Bookings.</b> Like other intelligent calendars, any Knowstory
                user has a bookings link they can share for others to schedule
                on their calendar and integration through web-hook to Microsoft
                or Google calendars. The difference is that Knowstory is also a
                social hub so that users can build human networks for easier
                time coordination.
              </p>
              <p>
                <b>Request Appointments.</b> Unlike other intelligent calendars,
                Knowstory users can send requests for amounts of time with no
                assigned date and time. Co-members would just accept or decline,
                and the Al would calendar coordinate and set the appointment.
                Non-members would be directed to select as if from a bookings
                page or create an account and integrate.
              </p>
              <p>
                <b>Holds.</b> A hold is an open time across any multiple of days
                in a week that is bracketed by a start and end time. It may have
                inside itself minus times such as lunch breaks so that the Al
                will know not to set meetings over that time as it is not open.
                Any member may make a hold and assign it to outgoing request
                appointments, offer labeled holds to members and non-members
                booking on their calendar, and use holds in auto-cohorting
                Meets.
              </p>
              <p>
                <b>Auto-cohorting.</b> Each live class moment made in the unique
                calendar event called a Meet requires enrollment. Enrollment is
                different than acceptance because until the required cohort
                number set in the Meet accept - the actual date and time are
                still waiting to calendar. The design of the Al in Knowstory is
                for cohort or individual learning that still intersects with
                live teaching moments that are calendared on-demand - only as
                the pace of the learner achieves each point that triggers the
                enrollment in the next class or check-in. These can be triggered
                by email "acceptance," or inside Knowstory when a student clicks
                "finish" to a study step or completes a quiz. Teachers can also
                manage each student's enrollment.
              </p>
              <div className="pl-8 space-y-4">
                <p>
                  <b>Class Meets</b> set to all calendars with no human
                  intervention. The coordinated time selected by the Al will
                  automatically set to all the cohort calendars, send
                  notifications, and allow unique "push forward" for any one
                  cohort member, or accept and decline as needed. Each meeting
                  allows the teacher or other administrator to mark attendance.
                </p>
                <p>
                  <b>Single Class.</b> Teachers can break their single class
                  roster into cohorts for core learning, project-based learning
                  and cross-curricular learning.
                </p>
                <p>
                  <b>Multiple Units.</b> Teachers can "collapse" all units of
                  all subjects and grades taught into one "hold" of open time
                  during their days of availability each week. The Al will set
                  cohorts in any course assigned to that Teacher as students
                  accrue into cohort groups for each meeting needed.
                </p>
                <p>
                  <b>Class Meets can be tethered into sequence.</b> A course
                  frame can put multiple Meets in an order so that an entire
                  course can have every participant self-pacing and cohorts
                  altering depending on any one person's speed of enrollment
                  into the next Meet. A "push-forward" feature allows students
                  to join the next cohort, but not cancel out entirely. This is
                  handy for situations where they decided they needed restudy
                  time, were unpredictably ill or had a parent set a conflicting
                  appointment outside of school.
                </p>
              </div>
              <p>
                <b>Curriculum independent.</b> Unlike personalized learning
                within content apps, Knowstory is content agnostic. It does not
                "know" when a student has attained mastery, only that they
                completed an assignment or quiz indicating they were ready to
                move on to the next step. Teacher oversight keeps them moving.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={"bg-white w-full py-8"}>
        <div className="container">
          <img src={img2} alt="Calendaring" />
        </div>
      </section>

      <section className={"bg-grey7 w-full py-8"}>
        <div className="container">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-start-2 col-span-10 space-y-4">
              <p>
                <b>New Bi-Level Master Calendaring.</b> This means you set a new
                master schedule, and each individual gets a regular schedule of
                when to teach, students when to study what, but the actual class
                togetherness is dynamically calendared. In addition, teachers
                and students can create open-time holds for when those classes
                calendar.
              </p>
              <div className="pl-8 space-y-4">
                <p>
                  <b>Individual Schedules are Adjustable.</b> A student's
                  schedule may recommend amounts of time on each subject
                  hour-by-hour independently of all other students and teachers.
                  If they are fast through core subjects, they could "save up"
                  time to proceed to electives. Teachers could adjust a
                  student's schedule for more or less time on any one subject.
                  Total learning time is calculated as study time plus class
                  time.
                </p>
                <p>
                  <b>Al Allows Courses to Have No Enrollment Deadline.</b> Once
                  a course of sequenced auto-cohorting multi-meetings is
                  released, the course can be always open for new enrollment.
                  Every Meet will continue to issue targets indefinitely to
                  students until they complete that part of a course. The
                  original roster of students could move forward through each
                  class meeting and study step between class meetings towards
                  completion. New students could be added at any time, catching
                  up to tailing cohorts of the original roster or not. The Al
                  will notice if there are not enough students to meet a cohort
                  value and trigger the next class for fewer or just one
                  student. This means teachers could see class times pop up on
                  calendars non-sequentially and outside of normal pacing.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={"bg-white w-full py-8"}>
        <div className="container">
          <img src={img3} alt="Cohorting" />
        </div>
      </section>

      <section className={"bg-grey7 w-full py-8"}>
        <div className="container">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-start-2 col-span-10 space-y-4">
              <h3 className={"font-medium text-xl"}>What’s Different?</h3>
              <p>
                <b>Whole School Flex.</b> Schools use the bi-level schedules to
                change the story of how they let everyone have flexible time.
                This includes fully online schools. Big and small schools and
                districts managing a breadth of ages and learning levels can
                ensure live teaching is calendared appropriate to each learner.
              </p>
              <div className="pl-8 space-y-4">
                <p>
                  <b>Physically On-campus or Remote Students.</b> Teacher's
                  calendars manage regardless of where the student is - as long
                  as that student is added to the roster of the course their
                  calendar will populate as the class meetings set. Schools and
                  districts can reward students with remote days.
                </p>
                <p>
                  <b>Existing or Expanded Flextime.</b> Schools can use their
                  already partitioned master schedules with flextime to offer
                  students individually paced learning within that time - that
                  intersects with live teaching more efficiently. This is ideal
                  for electives and remedial learning.
                </p>
                <p>
                  <b>Space Use Flexibility.</b> Knowstory's Al calendaring
                  allows schools to operate with the "learning center" concept
                  of study lounges, or homerooms with adult supervision, and
                  classroom meeting spaces. Classrooms become on-demand meeting
                  space.
                  <br />
                  Corners or partitioned space within the homerooms can be set
                  up for visiting teachers or video calls with remote teachers.
                  <br />
                  Space planning to use all classrooms as meeting spaces only
                  would shift a school to Full Hi-Flex.
                </p>
              </div>
              <p>
                <b>Sector Hi-Flex.</b> When districts or even whole States adopt
                Al calendaring:
              </p>
              <div className="pl-8 space-y-4">
                <p>
                  <b>Easy Substitutions with Gig Teachers or Tutors.</b> Any
                  course can be easily assigned to any teacher anywhere. Gig
                  teachers can also offer their own courses which can accrue
                  students to points and automatically schedule the next class,
                  and the next, and so forth.
                </p>
                <p>
                  <b>Shared Teaching Network.</b> Converting all schools to Full
                  Flex allows a shared teaching where a single school has the
                  teacher live and in-person holding cohort meetings with some
                  students physically there at the same times the course
                  auto-cohorts in other students who are remote in another
                  school.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={"bg-white w-full py-8"}>
        <div className="container">
          <img src={img4} alt="School Flex" />
        </div>
      </section>

      <section className={"bg-grey7 w-full py-8"}>
        <div className="container">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-start-2 col-span-10">
              <p>
                <b>Streamlined Model Built for Learners.</b> Knowstory enables
                is not just about using the Al, it is the anchoring idea that
                will help schools and districts reorganize with hardware,
                networks, software and curriculum design to deliver a highly
                responsive learner-facing enter- prise. This competitive move
                helps attract and retain students.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className={"bg-white w-full py-8"}>
        <div className="container">
          <img src={img5} alt="Flkex Learning Logistics Schema" />
        </div>
      </section>
    </>
  )
}

export default SolutionsSchoolsPage
