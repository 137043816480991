import { letters } from "../questionForm/MatchingQuestionConstructor"

const MatchingQuestion = ({ question, index }) => {
  return (
    <>
      <div className="form__fieldset">
        <span className="form__label">Question {index + 1}</span>
        <div className="form__fieldbox">
          <div className="form__masked-value">{question.title}</div>
        </div>
      </div>

      <div className="form__fieldset">
        <span className="form__label">Statements</span>
        <div className="form__fieldbox">
          {question.questions.map((q, qIdx) => (
            <div className="form__masked-value" key={"question-" + qIdx}>
              {qIdx + 1}. {q.value}
            </div>
          ))}
        </div>
      </div>

      <div className="form__fieldset">
        <span className="form__label">Answers</span>
        <div className="form__fieldbox">
          {question.answers.map((a, aIdx) => (
            <div className="form__masked-value" key={"answer-" + aIdx}>
              {letters[aIdx]}. {a.value}
            </div>
          ))}
        </div>
      </div>

      <div className="form__fieldset">
        <span className="form__label">Right answers</span>
        <div className="form__fieldbox">
          {question.questions.map((q, qIdx) => (
            <div className="form__masked-value" key={"results-" + qIdx}>
              {qIdx + 1}. {letters[q.right_answer]}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default MatchingQuestion
