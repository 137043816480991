import React from "react"
import InfoLayout from "../info/infoLayout"
import logo from "../../assets/img/logo.svg"
import { Link } from "react-router-dom"
import Button from "@material-ui/core/Button/Button"
import * as routes from "../../library/constants/routes"
import {
  ACCOUNTS_INFO_AGENT,
  ACCOUNTS_INFO_EDUCATOR,
  ACCOUNTS_INFO_LEARNER,
} from "../../library/constants/routes"
import PlainLink from "../../components/ui/link"
import { Pointed, SectionHeading } from "../info/accounts/accountsInfoMain"
import { Ul, Li } from "../../components/v2/typography/twComponents"
import districtIcon from "../../assets/img/group-icons/district-icon.svg"
import schoolIcon from "../../assets/img/group-icons/school-icon.svg"
import oneTeacherSchoolIcon from "../../assets/img/group-icons/tutor-icon.svg"
import companyIcon from "../../assets/img/group-icons/company-icon.svg"
import interestsIcon from "../../assets/img/group-icons/regular-group-icon.svg"
import eventIcon from "../../assets/img/group-icons/event-group-icon.svg"
import section1_img1 from "../../assets/img/pages/home-new/section1/1.svg"
import section1_img2 from "../../assets/img/pages/home-new/section1/2.svg"
import section1_img3 from "../../assets/img/pages/home-new/section1/3.svg"
import section1_img4 from "../../assets/img/pages/home-new/section1/4.svg"
import section2_img1 from "../../assets/img/pages/home-new/section2/1.png"
import section2_img2 from "../../assets/img/pages/home-new/section2/2.png"
import section2_img3 from "../../assets/img/pages/home-new/section2/3.png"
import section2_img4 from "../../assets/img/pages/home-new/section2/4.png"
import section2_img5 from "../../assets/img/pages/home-new/section2/5.png"
import section4_img1 from "../../assets/img/pages/home-new/section4/1.png"
import section4_img2 from "../../assets/img/pages/home-new/section4/2.png"
import section4_img3 from "../../assets/img/pages/home-new/section4/3.png"
import section4_img4 from "../../assets/img/pages/home-new/section4/4.png"
import section4_img5 from "../../assets/img/pages/home-new/section4/5.png"
import section5_img1 from "../../assets/img/pages/home-new/section5/1.png"
import section5_img2 from "../../assets/img/pages/home-new/section5/2.png"
import section5_img3 from "../../assets/img/pages/home-new/section5/3.png"
import section5_img4 from "../../assets/img/pages/home-new/section5/4.png"
import section6_img1 from "../../assets/img/pages/home-new/section6/1.png"
import section6_img2 from "../../assets/img/pages/home-new/section6/2.png"
import section7_img1 from "../../assets/img/pages/home-new/section7/1.png"
import section7_img2 from "../../assets/img/pages/home-new/section7/2.png"
import section7_img3 from "../../assets/img/pages/home-new/section7/3.png"
import section8_img1 from "../../assets/img/pages/home-new/section8/1.png"
import section8_img2 from "../../assets/img/pages/home-new/section8/2.png"
import section8_img3 from "../../assets/img/pages/home-new/section8/3.png"
import section8_img4 from "../../assets/img/pages/home-new/section8/4.png"

const LandingPage = () => {
  return (
    <InfoLayout>
      <section id={"education-ai"} className={"py-16 bg-white"}>
        <div className="container">
          <SectionHeading>
            Education’s AI Calendaring Social Ecosystem
          </SectionHeading>
          <div className="grid md:grid-cols-4 gap-x-10 gap-y-4">
            <div className={"grid grid-rows-subgrid row-span-3"}>
              <div className="flex justify-center items-center h-auto md:h-52">
                <img src={section1_img1} alt="1" />
              </div>
              <p className="text-center">
                Create auto-cohorting meetings which set themselves upon the
                first cohort filling, then keep setting for additional meetings
                until all invitees are complete.
              </p>
              <Ul>
                <Li>
                  Customize learning pace without sacrificing live teaching.
                </Li>
                <Li>
                  Build courses in which each study step can be followed by an
                  auto-cohorting Meet.
                </Li>
                <Li>
                  Add instructions to complete before accepting, links,
                  attachments, reminders, and targets.
                </Li>
              </Ul>
            </div>
            <div className={"grid grid-rows-subgrid row-span-3"}>
              <div className="flex justify-center items-center h-auto md:h-52">
                <img src={section1_img2} alt="2" />
              </div>
              <p className="text-center">
                Send undated meeting requests for an amount of time – let the AI
                do the rest.
              </p>
              <Ul>
                <Li>Instigate togetherness with just an email address.</Li>
              </Ul>
            </div>
            <div className={"grid grid-rows-subgrid row-span-3"}>
              <div className="flex justify-center items-center h-auto md:h-52">
                <img src={section1_img3} alt="3" />
              </div>
              <p className="text-center">
                Eliminate back-and-forth by giving out your calendar link for
                bookings.
              </p>
              <Ul>
                <Li>
                  Guests using your link choose from your amounts of time
                  settings and can use your Holds.
                </Li>
              </Ul>
            </div>
            <div className={"grid grid-rows-subgrid row-span-3"}>
              <div className="flex justify-center items-center h-auto md:h-52">
                <img src={section1_img4} alt="4" />
              </div>
              <p className="text-center">
                Bracket and label your open time for specific function with
                Holds for meetings to set within.
              </p>
              <Ul>
                <Li>
                  Example: label a Hold from 3:00-5:00 p.m. every weekday as
                  “Parent Conference Time.”
                </Li>
                <Li>
                  Example: label a Hold “Math Time” or “Training Calls” for
                  9:00-2:00 p.m. minus lunch and breaks so any meet set around
                  those.
                </Li>
              </Ul>
            </div>
          </div>
        </div>
      </section>
      <section id={"member-types"} className={"py-16 bg-grey7"}>
        <div className="container">
          <SectionHeading>Member Types</SectionHeading>
          <div className="md:grid grid-cols-3 gap-6">
            <div className="flex flex-col items-center mb-12 md:mb-0">
              <Pointed as={"h4"} className={"text-lg font-medium mb-4"}>
                Learners & Members
              </Pointed>
              <Button
                component={Link}
                to={ACCOUNTS_INFO_LEARNER}
                color={"primary"}
                variant={"contained"}
              >
                More info
              </Button>
              <div
                style={{ height: 300 }}
                className={"flex items-end mt-3 md:mt-8"}
              >
                <img src={section8_img1} alt="Learners & Members" />
              </div>
            </div>
            <div className="flex flex-col items-center mb-12 md:mb-0">
              <Pointed as={"h4"} className={"text-lg font-medium mb-4"}>
                Educators
              </Pointed>
              <Button
                component={Link}
                to={ACCOUNTS_INFO_EDUCATOR}
                color={"primary"}
                variant={"contained"}
              >
                More info
              </Button>
              <div className={"flex flex-col gap-4 mt-3 md:mt-8"}>
                <img
                  src={section8_img2}
                  alt="Educators"
                  style={{ maxHeight: 300 }}
                />
                <img
                  src={section8_img4}
                  alt="Educators"
                  style={{ maxHeight: 190 }}
                />
              </div>
            </div>
            <div className="flex flex-col items-center">
              <Pointed as={"h4"} className={"text-lg font-medium mb-4"}>
                Company Representatives
              </Pointed>
              <Button
                component={Link}
                to={ACCOUNTS_INFO_AGENT}
                color={"primary"}
                variant={"contained"}
              >
                More info
              </Button>
              <div
                style={{ height: 300 }}
                className={"flex items-end  mt-3 md:mt-8"}
              >
                <img src={section8_img3} alt="Educators" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id={"create-groups"} className={"py-16 bg-white"}>
        <div className="container">
          <SectionHeading>Create Groups</SectionHeading>
          <div className="flex flex-col md:flex-row justify-between space-y-8 space-x-4 mb-8">
            <div className="flex flex-col items-center justify-end flex-1">
              <img src={districtIcon} alt="District" className={"w-56 mb-4"} />
              <h4 className={"text-center font-medium text-lg"}>District</h4>
            </div>
            <div className="flex flex-col items-center justify-end flex-1">
              <img src={schoolIcon} alt="School" className={"w-56 mb-4"} />
              <h4 className={"text-center font-medium text-lg"}>School</h4>
            </div>
            <div className="flex flex-col items-center justify-end flex-1">
              <img
                src={oneTeacherSchoolIcon}
                alt="One teacher school"
                className={"w-56 mb-4"}
              />
              <h4 className={"text-center font-medium text-lg"}>
                One teacher school
              </h4>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between space-y-8 space-x-4 ">
            <div className="flex flex-col items-center justify-end flex-1">
              <img src={companyIcon} alt="Company" className={"w-56 mb-4"} />
              <h4 className={"text-center font-medium text-lg"}>Company</h4>
            </div>
            <div className="flex flex-col items-center justify-end flex-1">
              <img
                src={interestsIcon}
                alt="Interest group"
                className={"w-56 mb-4"}
              />
              <h4 className={"text-center font-medium text-lg"}>
                Interest Group
              </h4>
            </div>
            <div className="flex flex-col items-center justify-end flex-1">
              <img src={eventIcon} alt="Event" className={"w-56 mb-4"} />
              <h4 className={"text-center font-medium text-lg"}>Event</h4>
            </div>
          </div>
        </div>
      </section>
      <section id={"featured-edtech"} className={"py-16 bg-grey7"}>
        <div className="container">
          <SectionHeading>Featured Edtech</SectionHeading>
          <div className="grid md:grid-cols-3 gap-x-5 gap-y-8">
            <div className={"flex flex-col items-center space-y-4"}>
              <Pointed as={"h4"}>Social Profiles</Pointed>
              <img src={section2_img1} alt={""} className={""} />
            </div>
            <div
              className={
                "md:col-start-2 md:row-span-2 flex flex-col items-center space-y-4"
              }
            >
              <Pointed as={"h4"}>Featured Products</Pointed>
              <img src={section2_img2} alt={""} className={""} />
            </div>
            <div className={"flex flex-col items-center space-y-4"}>
              <Pointed as={"h4"}>Academic Standards</Pointed>
              <img src={section2_img3} alt={""} className={""} />
            </div>
            <div className={"flex flex-col items-center space-y-4"}>
              <Pointed as={"h4"}>Bookmarking</Pointed>
              <img src={section2_img4} alt={""} className={""} />
            </div>
            <div className={"flex flex-col items-center space-y-4"}>
              <Pointed as={"h4"}>Meeting Tracking</Pointed>
              <img src={section2_img5} alt={""} className={""} />
            </div>
          </div>
        </div>
      </section>
      <section id={"featured-schools"} className={"py-16 bg-white"}>
        <div className="container">
          <SectionHeading>Forthcoming Featured School</SectionHeading>
          <div className="grid md:grid-cols-4 gap-8">
            {[section5_img1, section5_img2, section5_img3, section5_img4].map(
              (image, index) => (
                <div key={index}>
                  <img src={image} alt="" className={"mb-4"} />
                  <div className="px-2 flex flex-col gap-2">
                    <Button
                      color={"primary"}
                      variant={"outlined"}
                      onClick={() => {}}
                    >
                      SEE MORE
                    </Button>
                    <Button
                      color={"primary"}
                      variant={"contained"}
                      onClick={() => {}}
                    >
                      CREATE APPOINTMENT
                    </Button>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </section>
      <section id={"courses"} className={"py-16 bg-grey7"}>
        <div className="container">
          <SectionHeading>Create & Take Courses</SectionHeading>
          <div className="grid md:grid-cols-3 gap-x-5 gap-y-8">
            <div className={"flex flex-col items-center space-y-4"}>
              <Pointed as={"h4"}>Group Management</Pointed>
              <img src={section4_img1} alt={""} className={""} />
            </div>
            <div
              className={
                "md:col-start-2 md:row-span-2 flex flex-col items-center space-y-4"
              }
            >
              <Pointed as={"h4"}>Courses</Pointed>
              <img src={section4_img2} alt={""} className={""} />
            </div>
            <div className={"flex flex-col items-center space-y-4"}>
              <Pointed as={"h4"}>Create Credential Badges</Pointed>
              <img src={section4_img3} alt={""} className={""} />
            </div>
            <div className={"flex flex-col items-center space-y-4"}>
              <Pointed as={"h4"}>Create Master Schedules</Pointed>
              <img src={section4_img4} alt={""} className={""} />
            </div>
            <div className={"flex flex-col items-center space-y-4"}>
              <Pointed as={"h4"}>Create Pacing Calendars</Pointed>
              <img src={section4_img5} alt={""} className={""} />
            </div>
          </div>
        </div>
      </section>
      <section id={"featured-teachers"} className={"py-16 bg-white"}>
        <div className="container">
          <SectionHeading>
            Forthcoming Featured Teachers & Tutors
          </SectionHeading>
          <div className="grid md:grid-cols-4 gap-8">
            {[section6_img1, section6_img2, section6_img1, section6_img2].map(
              (image, index) => (
                <div key={index}>
                  <img src={image} alt="" className={"mb-4"} />
                  <div className="px-2 flex flex-col gap-2">
                    <Button
                      color={"primary"}
                      variant={"outlined"}
                      onClick={() => {}}
                    >
                      SEE MORE
                    </Button>
                    <Button
                      color={"primary"}
                      variant={"contained"}
                      onClick={() => {}}
                    >
                      CREATE APPOINTMENT
                    </Button>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </section>
      <section id={"knowplan"} className={"py-16 bg-grey7"}>
        <div className="container">
          <SectionHeading>Knowplan</SectionHeading>
          <div className="md:flex justify-between mb-4">
            <div>
              <p className={"font-medium text-primary"}>Coming Soon</p>
              <p className={"mb-4"}>Knowledge Wizard:</p>
              <Ul>
                <Li>Input your interest, age, location, and/or subject.</Li>
                <Li>
                  See recommended schools, courses, knowledge products from the
                  marketplace.
                </Li>
                <Li>Select items into your plan.​</Li>
              </Ul>
            </div>
            <div className={"mb-8 md:mb-0"}>
              <img src={section7_img1} alt={""} style={{ width: 500 }} />
            </div>
          </div>
          <div className="md:flex justify-between mb-4">
            <div className={"mb-8 md:mb-0"}>
              <img src={section7_img2} alt={""} style={{ width: 500 }} />
            </div>
            <div className={"mb-8 md:mb-0"}>
              <img src={section7_img3} alt={""} style={{ width: 500 }} />
            </div>
          </div>
        </div>
      </section>
    </InfoLayout>
  )
}

export default LandingPage
