import React, { useState } from "react"
import { NavLink, withRouter } from "react-router-dom"
import styled from "@emotion/styled"
import { colors } from "../../../../library/constants/styles/colors"
import * as routes from "../../../../library/constants/routes"
import Button from "@material-ui/core/Button"
import CreateEditInvenstoryRecord from "../createEditInvenstoryRecord"
import { devices } from "../../../../library/constants/styles/responsive"

const Container = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  height: 60px;
  width: 100%;
  background-color: ${colors.primary15};
  border-radius: ${(props) => (props.bottomRadius ? "2px" : "2px 2px 0 0")};

  @media ${devices.xs} {
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 15px;
    height: auto;
  }
`

const Menu = styled.ul`
display: flex;
margin: 0;
padding: 0;
font-size: 18px;
color: ${colors.black54};
list-style: none;
flex-wrap: wrap;

@media ${devices.xs} {
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}
`;

const MenuItem = styled.li`
&:not(:last-child) {
  margin-right: 15px;
  
  @media ${devices.xs} {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

`;

const MenuLink = styled(NavLink)`
padding: 5px 5px 2px;
border-bottom: 2px solid transparent;
color: ${colors.black54};
transition: .2s all;

&:hover {
  border-color: ${colors.primary};
}

&.active {
  color: ${colors.black87};
  border-color: ${colors.primary};
}
`;

const InvenstoryHeader = (props) => {
    const [createDialogOpen, setCreateDialogOpen] = useState(false);

    const handleClose = () => {
        setCreateDialogOpen(false);
    };

    const handleClickOpen = () => {
        setCreateDialogOpen(true);
    };

    const menuItems = [
        {path: routes.INVENSTORY, label: "My InvenStory"},
        {path: routes.INVENSTORY_ACCESS, label: "Access requests"},
    ];

    if (!props.hideGroupsInvenstory) {
        menuItems.splice(1, 0, {path: routes.INVENSTORY_GROUPS, label: "Group’s InvenStory"});
    }

    return (
        <Container bottomRadius={props.bottomRadius}>
            <Menu>
                {menuItems.map(({path, label}) => (
                    <MenuItem key={label}>
                        <MenuLink exact to={path}>{label}</MenuLink>
                    </MenuItem>
                ))}
            </Menu>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>Create Record</Button>
            {createDialogOpen && (
                <CreateEditInvenstoryRecord
                    open={createDialogOpen}
                    onClose={handleClose}
                    updateData={props.updateData}
                />
            )}
        </Container>
    );
};

export default withRouter(InvenstoryHeader);
