import React, { useEffect, useState } from "react"
import { BoxContainer, BoxContent, BoxHeading } from "../../boxPage/boxPage"
import { Button } from "@material-ui/core"
import TextField from "@material-ui/core/TextField/TextField"
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment"
import SearchOutlined from "@material-ui/icons/SearchOutlined"
import { useHistory } from "react-router-dom"
import { useLocation } from "react-router-dom/cjs/react-router-dom"
import * as qs from "query-string"
import Select from "@material-ui/core/Select/Select"
import { getObjectById } from "../../../../../library/utils/arrays"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"

const ListPageHeader = ({
  title,
  hasButton = true,
  buttonAction,
  buttonTitle,
  noResults = false,
  noResultsLabel = "No results",
  filter = {
    list: [],
    ready: false,
  },
  hasSearch = true,
}) => {
  const history = useHistory()
  const location = useLocation()

  const [q, setQ] = useState("")

  useEffect(() => {
    const { q: param } = qs.parse(location.search)

    if (typeof param === "string") {
      setQ(param)
    }
  }, [])

  const handleChange = (e) => {
    setQ(e.target.value)
  }

  React.useEffect(() => {
    const search = setTimeout(() => {
      processSearch()
    }, 500)

    return () => clearTimeout(search)
  }, [q])

  const processSearch = () => {
    // e.preventDefault()

    history.push({
      search: q.trim().length ? `?q=${q.trim()}` : undefined,
    })
  }

  const showButton = hasButton && !!buttonAction && !!buttonTitle

  const emptyLabel = filter.emptyLabel || "Select option"

  return (
    <BoxContainer>
      <BoxHeading withButton={showButton}>
        <h2>{title}</h2>
        {showButton && (
          <Button
            color={"primary"}
            variant={"contained"}
            onClick={buttonAction}
          >
            {buttonTitle}
          </Button>
        )}
      </BoxHeading>
      {(hasSearch || (filter.ready && !!filter.list.length)) && (
        <BoxContent>
          {/*<form onSubmit={processSearch}>*/}
          <TextField
            className="search-header__input full-width"
            id="search-header-input"
            placeholder={"Start typing name"}
            margin="none"
            autoComplete="off"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined style={{ color: "#9b9a9b" }} />
                </InputAdornment>
              ),
            }}
            value={q}
            onChange={handleChange}
          />
          {/*</form>*/}
          {filter.ready && !!filter.list.length && (
            <div className="form mt15">
              <div className="form__fieldset mb0">
                <label htmlFor="filter" className="form__label">
                  {filter.label}
                </label>
                <div className="form__fieldbox">
                  <div className="form__input form__input--select">
                    <Select
                      id="filter"
                      name="filter"
                      fullWidth
                      displayEmpty
                      value={filter.value}
                      defaultValue={filter.required ? filter.list[0].id : ""}
                      onChange={filter.onChange}
                      renderValue={(value) => {
                        if (value.length === 0) {
                          return (
                            <span className="color-black-38">{emptyLabel}</span>
                          )
                        }

                        return getObjectById(filter.list, value)
                          ? getObjectById(filter.list, value).name
                          : ""
                      }}
                    >
                      {!filter.required && (
                        <MenuItem value={""}>
                          <span className={"color-black-38"}>{emptyLabel}</span>
                        </MenuItem>
                      )}

                      {filter.list.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            </div>
          )}
          {noResults && (
            <p className={"text-center color-black-54 pt15"}>
              {noResultsLabel || "Result not found"}
            </p>
          )}
        </BoxContent>
      )}
    </BoxContainer>
  )
}

export default ListPageHeader
