import React, { Component } from "react"
import Dialog from "@material-ui/core/Dialog/Dialog"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import FormControl from "@material-ui/core/FormControl/FormControl"
import { productContentTypes } from "../../../../library/constants/productDictionary"
import {
  grades as gradesList,
  subjects as subjectsList,
} from "../../../../library/constants/educationalDictionary"
import { generatePath, withRouter } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { createProduct } from "../../../../library/store/actions/creators/productsCreators"
import {
  clearGroupsList,
  getGroupsList,
} from "../../../../library/store/actions/creators/groupsCreators"
import FormHelperText from "@material-ui/core/FormHelperText"
import getErrorText from "../../../../library/constants/errorTypes"
import MultiSelect from "../../../../components/ui/multiSelect"
import { call } from "../../../../library/networking/API"
import { KNOWMIX } from "../../../../library/store/actions/types/knowmixTypes"
import Loader from "../../../../components/ui/loader"
import StandardPicker from "../../../../components/v2/inputs/pickers/standardPicker"
import FormRadioGroup from "../../../../components/formComponents/formRadioGroup"
import GroupPicker from "../../../../components/v2/inputs/pickers/groupPicker"
import StaticDataSelect from "../../../../components/v2/inputs/select/staticDataSelect"

class ProductCreate extends Component {
  state = {
    name: "",
    createFrom: "2",
    groupsList: [],
    knowmixes: [],
    chosenKnowMix: null,
    groupId: "",
    groupIdError: false,
    description: "",
    contentType: "",
    contentTypeError: false,
    subjects: [],
    grades: [],
    publisher: "",
    author: "",
    standards: [],
    knowmixName: "",
    knowmixError: false,
    dataReceived: false,
    groupName: "",
    groupsReceived: true,
    chosenGroup: "",
    disableGroups: false,
    email: "",
    accessType: "marketplace",
    recommendedNextKourse: null,

    group: this.props.groups.currentGroup,
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }

  async componentDidMount() {
    try {
      await this.getKnowMixes({})

      if (!!this.props.history.location.state) {
        const { name, id, contentType, group, subjects, grades, standards } =
          this.props.history.location.state

        this.setState({
          groupId: group ? group.id : "",
          chosenGroup: group ? { value: group.id, label: group.name } : "",
          disableGroups: !!group,
          contentType: contentType || "",
          chosenKnowMix: id && name ? { value: id, label: name } : "",
          subjects: subjects || [],
          grades: grades || [],
          chosenStandards: standards || [],
        })
      }
      this.setState({ dataReceived: true })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { fields } = this.props
    if (fields && prevProps.fields !== fields) {
      const { name, content_type, grades, standards, subjects } = fields
      this.setState({
        name,
        contentType: content_type,
        subjects,
        grades,
        standards: standards || [],
      })
    }
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    })
  }

  handleChangeRequired = (name, event) => {
    switch (name) {
      case "contentType":
        this.setState({
          contentTypeError: !event.target.value,
          contentType: event.target.value,
        })
        break

      default:
        this.setState({
          [name]: event.target.value,
        })
        break
    }
  }

  handleClose = () => {
    this.props.onClose()
  }

  processFormValidate = () => {
    const { createFrom, contentType, chosenKnowMix, chosenGroup } = this.state
    if (contentType.id === 15 && !chosenKnowMix) {
      this.setState({
        knowmixError: true,
      })
    } else {
      this.setState({
        knowmixError: false,
      })
    }
    if (createFrom === "2") {
      if (!chosenGroup) {
        this.setState({ groupIdError: true })
      } else {
        this.setState({ groupIdError: false })
      }
    }
    if (contentType === "") {
      this.setState({ contentTypeError: true })
    } else {
      this.setState({ contentTypeError: false })
    }
  }

  processProductCreate = async () => {
    const {
      name,
      description,
      createFrom,
      contentType,
      contentTypeError,
      subjects,
      grades,
      publisher,
      author,
      standards,
      chosenKnowMix,
      email,
      group,
      groupError,
    } = this.state

    if (
      ((!group || groupError) && createFrom === "2") ||
      contentType === "" ||
      !contentType ||
      contentTypeError
    ) {
      if (!group && createFrom === "2") {
        this.setState({ groupError: true })
      }
      if (contentType === "" || !contentType) {
        this.setState({ contentTypeError: true })
      }

      return
    }
    if (contentType.id === 15 && !chosenKnowMix) {
      return this.setState({
        knowmixError: true,
      })
    }
    try {
      const productId = await this.props.createProduct({
        name,
        description,
        group_id: group.id,
        content_type: contentType.id,
        subjects: subjects.map((s) => s.id),
        grades: grades.map((g) => g.id),
        publisher,
        author,
        standards: standards.map((s) => s.id),
        knowmix_id:
          contentType.id === 15 && chosenKnowMix
            ? chosenKnowMix.value
            : undefined,
        contact_email: email,
      })
      this.props.afterProductCreate
        ? await this.props.afterProductCreate()
        : await this.props.history.push({
            pathname: generatePath(routes.PRODUCT, { productId }),
          })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  scrollKnowmixes = async () => {
    if (!this.state.standardsReceived) return
    const { knowmixName, knowmixes } = this.state
    await this.getKnowMixes({
      knowmixName,
      offset: knowmixes.length,
    })
  }

  setKnowMix = (knowmix) => {
    this.setState({
      chosenKnowMix: knowmix,
      knowmixError: !knowmix,
    })
  }
  setKnowMixName = (knowmixName) => {
    this.setState(
      {
        knowmixName,
        standardsReceived: false,
        knowmixes: [],
      },
      this.searchKnowmixes
    )
  }

  searchKnowmixes = async () => {
    const { knowmixName } = this.state
    await this.getKnowMixes({ knowmixName })
  }

  getKnowMixes = async ({ knowmixName, limit = 20, offset = 0 }) => {
    this.setState({ standardsReceived: false })
    const { knowmixes } = this.state
    try {
      const response = await call(KNOWMIX.LIST, {
        token: this.props.auth.userData.token,
        name: knowmixName || undefined,
        managed: true,
        offset,
        limit,
        active: true,
        graded: false,
        finished: true,
        unpublished: true,
      })
      this.setState({
        standardsReceived: true,
        knowmixes: [...knowmixes, ...response.data.knowmixes],
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  render() {
    const {
      name,
      createFrom,
      description,
      contentType,
      contentTypeError,
      subjects,
      grades,
      publisher,
      author,
      standards,
      knowmixes,
      chosenKnowMix,
      knowmixError,
      email,
      accessType,
    } = this.state

    const renderKnowmixes = knowmixes.filter((k) => !k.is_public)
    return (
      <Dialog
        aria-labelledby="create-product-dialog"
        onClose={this.handleClose}
        open={this.props.open}
        maxWidth="md"
      >
        <ValidatorForm onSubmit={this.processProductCreate}>
          <DialogTitle id="alert-dialog-title" className="text-center">
            New product (ad tile only)
          </DialogTitle>
          {this.state.dataReceived ? (
            <DialogContent>
              <div className="form form--modal">
                <div className="form__fieldset">
                  <label htmlFor="name" className="form__label">
                    Product name *
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="name"
                        name="name"
                        value={name}
                        placeholder="Type product name"
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        validators={[
                          "required",
                          "minStringLength:3",
                          "maxStringLength:200",
                        ]}
                        errorMessages={[
                          "Field is required",
                          "Min length is 3 characters",
                          "Max length is 200 characters",
                        ]}
                        withRequiredValidator
                        onChange={this.handleChange("name")}
                      />
                    </div>
                  </div>
                </div>

                <FormRadioGroup
                  label={"Create from"}
                  onChange={(v) => this.setState({ createFrom: v })}
                  options={[{ label: "Group", value: "2" }]}
                  name="createFrom"
                  value={createFrom}
                />

                {createFrom === "2" && (
                  <>
                    <GroupPicker
                      value={this.state.group}
                      onChange={(v) => this.setState({ group: v })}
                      disabled
                    />

                    <FormRadioGroup
                      label={"Available for"}
                      onChange={(v) => this.setState({ accessType: v })}
                      options={[
                        { label: "Marketplace (public)", value: "marketplace" },
                        { label: "Group only", value: "group" },
                      ]}
                      name="accessType"
                      value={accessType}
                    />
                  </>
                )}

                <div className="form__fieldset">
                  <label htmlFor="description" className="form__label">
                    Description *
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="description"
                        name="description"
                        value={description}
                        placeholder="Type product description"
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        multiline
                        validators={["required", "maxStringLength:5000"]}
                        errorMessages={[
                          "Field is required",
                          "Max length is 5000 characters",
                        ]}
                        withRequiredValidator
                        onChange={this.handleChange("description")}
                      />
                    </div>
                  </div>
                </div>

                <StaticDataSelect
                  required
                  fieldName={"contentType"}
                  entityName={"content type"}
                  data={productContentTypes}
                  value={contentType}
                  hasError={contentTypeError}
                  onChange={(v) =>
                    this.setState({ contentType: v, contentTypeError: !v })
                  }
                />

                {contentType?.id === 15 && (
                  <div className="form__fieldset">
                    <label className="form__label">KnowMix *</label>
                    <div className="form__fieldbox">
                      <div className="form__input form__input--select">
                        <FormControl error={knowmixError} fullWidth>
                          <MultiSelect
                            list={renderKnowmixes}
                            placeholder="Select KnowMix"
                            onScroll={this.scrollKnowmixes}
                            onChange={this.setKnowMix}
                            onInputChange={this.setKnowMixName}
                            emptyArrayMessage={
                              this.state.standardsReceived ? (
                                <span>No results</span>
                              ) : (
                                <Loader />
                              )
                            }
                            defaultValue={chosenKnowMix}
                          />
                          {knowmixError && (
                            <FormHelperText>
                              <span className="error-message">
                                Field is required
                              </span>
                            </FormHelperText>
                          )}
                        </FormControl>
                      </div>
                    </div>
                  </div>
                )}

                <StaticDataSelect
                  multiple
                  fieldName={"subjects"}
                  entityName={"subjects"}
                  data={subjectsList}
                  value={subjects}
                  onChange={(v) => this.setState({ subjects: v })}
                />

                <StaticDataSelect
                  multiple
                  fieldName={"grades"}
                  entityName={"grades"}
                  data={gradesList}
                  value={grades}
                  onChange={(v) => this.setState({ grades: v })}
                />

                <StandardPicker
                  value={this.state.standards}
                  onChange={(v) => this.setState({ standards: v })}
                />

                <div className="form__fieldset">
                  <label htmlFor="email" className="form__label">
                    Contact email
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="email"
                        name="email"
                        value={email}
                        placeholder="Type contact email"
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        validators={["isEmail"]}
                        errorMessages={["Email is not valid"]}
                        onChange={this.handleChange("email")}
                      />
                    </div>
                  </div>
                </div>

                <div className="form__fieldset">
                  <label htmlFor="publisher" className="form__label">
                    Publisher
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="publisher"
                        name="publisher"
                        value={publisher}
                        placeholder="Type publisher name"
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        validators={["maxStringLength:200"]}
                        errorMessages={["Max length is 200 characters"]}
                        onChange={this.handleChange("publisher")}
                      />
                    </div>
                  </div>
                </div>

                <div className="form__fieldset">
                  <label htmlFor="author" className="form__label">
                    Author
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="author"
                        name="author"
                        value={author}
                        placeholder="Type author name"
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        validators={["maxStringLength:200"]}
                        errorMessages={["Max length is 200 characters"]}
                        onChange={this.handleChange("author")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </DialogContent>
          ) : (
            <Loader />
          )}
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              onClick={this.processFormValidate}
            >
              Save
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    )
  }
}

const mapStateToProps = ({ auth, groups, products }) => ({
  auth,
  groups,
  products,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { getGroupsList, createProduct, clearGroupsList },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(ProductCreate)))
