import React, { Component } from "react"
import ListItemGroup from "../../../../components/ui/listItem/listItemGroup"
import {
  clearGroupsList,
  deleteGroup,
  getGroupsList,
  getOutgoingInvitesList,
  getPendingInvitesList,
  groupInviteDecline,
  groupRequestsCancel,
  groupRequestsDecline,
  joinGroup,
  leaveGroup,
  searchGroups,
  updateGroupsList,
} from "../../../../library/store/actions/creators/groupsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { bindActionCreators } from "redux"
import * as qs from "query-string"
import GroupListHeader from "../groupsListHeader/groupsListHeader"
import List from "../../../../components/ui/list"
import Loader from "../../../../components/ui/loader"
import * as routes from "../../../../library/constants/routes"
import ListSearchHeader from "../../../../components/ui/listSearchHeader/listSearchHeader"
import { LIMIT } from "../../../../library/constants/limits"
import withScroll from "../../../../hocs/withScroll/withScroll"

class GroupsList extends Component {
  state = {
    isLoading: false,
  }

  componentDidMount() {
    const { search } = this.props.location
    const { q: query } = qs.parse(search)
    const { type } = this.props

    if (search) {
      this.searchGroups(query)
    } else if (type === "requests-pending") {
      this.getRequests("requests-pending")
    } else if (type === "requests-outgoing") {
      this.getRequests("requests-outgoing")
    } else {
      this.getGroupsList()
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const { search } = this.props.location
    const { q: query } = qs.parse(search)
    if (
      prevProps.match.path !== this.props.match.path ||
      prevProps.type !== this.props.type ||
      prevProps.subtype !== this.props.subtype
    ) {
      this.props.clearGroupsList()
      if (search) {
        return await this.getGroupsList(query, 0, 50)
      }
    }

    if (
      prevProps.match.path === routes.GROUPS_SEARCH &&
      this.props.match.path === routes.GROUPS
    ) {
      return this.getGroupsList(undefined, 0, LIMIT)
    }
    if (
      prevProps.match.path !== this.props.match.path &&
      this.props.match.path === routes.GROUPS_SEARCH &&
      !this.props.groups.list["search"]
    ) {
      const { q: query } = qs.parse(this.props.location.search)
      return this.searchGroups(query, 0, LIMIT)
    } else if (
      prevProps.match.path !== this.props.match.path &&
      this.props.match.path !== routes.GROUPS_SEARCH &&
      this.props.match.path !== routes.GROUPS_REQUESTS_PENDING &&
      this.props.match.path !== routes.GROUPS_REQUESTS_OUTGOING
    ) {
      return this.getGroupsList(undefined, 0, LIMIT)
    } else if (
      prevProps.match.path !== this.props.match.path &&
      this.props.match.path === routes.GROUPS_REQUESTS_PENDING
    ) {
      return this.getRequests("requests-pending", undefined, 0, LIMIT)
    } else if (
      prevProps.match.path !== this.props.match.path &&
      this.props.match.path === routes.GROUPS_REQUESTS_OUTGOING
    ) {
      return this.getRequests("requests-outgoing", undefined, 0, LIMIT)
    }
  }

  getGroupsList = async (name, offset, limit) => {
    this.setState({ isLoading: true })
    await this.props.getGroupsList({
      type: this.props.subtype ? this.props.subtype : this.props.type,
      name,
      managed: this.props.type === "managed" || false,
      offset,
      limit,
    })
    this.setState({ isLoading: false })
  }

  updateGroupsList = async () => {
    this.setState({ isLoading: true })
    const { type, subtype } = this.props
    const list = this.props.groups.list
    const limit = list[type]
      ? list[type].length
      : list[subtype]
        ? list[subtype].length
        : list.list.search
          ? list.list.search.length
          : 0
    const { search } = this.props.location
    const { q: query } = qs.parse(search)
    await this.props.updateGroupsList({
      type: this.props.subtype ? this.props.subtype : this.props.type,
      name: query || undefined,
      managed: this.props.type === "managed" || false,
      offset: 0,
      limit,
    })
    this.setState({ isLoading: false })
  }

  getRequests = async (typeOf, name, offset, limit) => {
    this.setState({ isLoading: true })
    if (typeOf === "requests-pending") {
      await this.props.getPendingInvitesList({
        type: this.props.type,
        name: name || undefined,
        offset,
        limit,
      })
    } else {
      await this.props.getOutgoingInvitesList({
        type: this.props.type,
        name: name || undefined,
        offset,
        limit,
      })
    }
    this.setState({ isLoading: false })
  }
  updateRequestList = async () => {
    this.props.clearGroupsList()
    const { search } = this.props.location
    const { q: query } = qs.parse(search)
    if (this.props.match.path === routes.GROUPS_REQUESTS_PENDING) {
      return this.getRequests("requests-pending", query || undefined, 0, LIMIT)
    } else if (this.props.match.path === routes.GROUPS_REQUESTS_OUTGOING) {
      return this.getRequests("requests-outgoing", query || undefined, 0, LIMIT)
    }
  }
  searchGroups = async (name, offset, limit, isScroll) => {
    const { history, location } = this.props
    const { type, subtype } = this.props
    if (
      (`?q=${name}` !== location.search && this.props.groups.list["search"]) ||
      !isScroll
    )
      this.props.clearGroupsList()
    history.push({
      search: `?q=${name}`,
    })
    this.setState({ isLoading: true })
    switch (type) {
      case "all":
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 7:
        await this.props.getGroupsList({ type, name, offset, limit })
        break
      case "managed":
        await this.props.getGroupsList({
          type: subtype,
          name,
          offset,
          limit,
          managed: true,
        })
        break
      case "requests-pending":
      case "requests-outgoing":
        await this.getRequests(type, name, offset, limit)
        break

      default:
        await this.props.searchGroups({ name, offset, limit })
    }
    this.setState({ isLoading: false })
  }

  onScroll = () => {
    if (this.state.isLoading) return
    const { search } = this.props.location
    const { q: query } = qs.parse(search)
    const { type, subtype } = this.props
    const list = this.props.groups.list
    const offset = list[type]
      ? list[type].length
      : list[subtype]
        ? list[subtype].length
        : list.list.search
          ? list.list.search.length
          : 0
    if (query || query === "") {
      this.searchGroups(query, offset, LIMIT, true)
    } else if (type === "requests-pending") {
      this.getRequests("requests-pending", undefined, offset, LIMIT)
    } else if (type === "requests-outgoing") {
      this.getRequests("requests-outgoing", undefined, offset, LIMIT)
    } else {
      this.getGroupsList(query, offset, LIMIT)
    }
  }

  componentWillUnmount() {
    this.props.clearGroupsList()
  }

  render() {
    let {
      type,
      subtype,
      groups: { list },
    } = this.props
    const { search } = this.props.location
    const { q: query } = qs.parse(search)
    const noResults =
      (!subtype
        ? !list[type] || !list[type].length
        : !list[subtype] || !list[subtype].length) && !this.state.isLoading
    const noSearchResults =
      (!subtype
        ? !list[type] || !list[type].length
        : !list[subtype] || !list[subtype].length) &&
      !this.state.isLoading &&
      query

    return (
      <>
        {type === "search" ? (
          <ListSearchHeader
            title="Global group search"
            placeholder="Start typing group name"
            search={this.searchGroups}
            noResults={noResults}
          />
        ) : (
          <GroupListHeader
            search={this.searchGroups}
            type={type}
            noResults={noResults}
            noSearchResults={noSearchResults}
          />
        )}

        {!subtype ? (
          list[type] ? (
            <>
              <List>
                {list[type].map((item) => (
                  <ListItemGroup
                    key={item.id}
                    type={type}
                    group={item}
                    leaveGroup={(id) => this.props.leaveGroup({ id })}
                    joinGroup={(id) => this.props.joinGroup({ id })}
                    groupRequestsDecline={(group_id) =>
                      this.props.groupInviteDecline({ group_id })
                    }
                    groupRequestsCancel={(group_id) =>
                      this.props.groupRequestsCancel({ group_id })
                    }
                    deleteGroup={(id) => this.props.deleteGroup({ id })}
                    updateGroupsList={this.updateGroupsList}
                    updateRequestList={this.updateRequestList}
                  />
                ))}
              </List>
            </>
          ) : (
            <></>
          )
        ) : list[subtype] ? (
          <>
            <List>
              {list[subtype].map((item) => (
                <ListItemGroup
                  key={item.id}
                  type={type}
                  group={item}
                  leaveGroup={(id) => this.props.leaveGroup({ id })}
                  joinGroup={(id) => this.props.joinGroup({ id })}
                  groupRequestsDecline={(id) =>
                    this.props.groupRequestsDecline({ id })
                  }
                  groupRequestsCancel={(group_id) =>
                    this.props.groupRequestsCancel({ group_id })
                  }
                  deleteGroup={(id) => this.props.deleteGroup({ id })}
                  updateGroupsList={this.updateGroupsList}
                  updateRequestList={this.updateRequestList}
                />
              ))}
            </List>
          </>
        ) : (
          <></>
        )}
        {(() => {
          if (this.state.isLoading) return <Loader />
        })()}
      </>
    )
  }
}

const mapStateToProps = ({ groups }) => ({ groups })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getGroupsList,
      searchGroups,
      joinGroup,
      leaveGroup,
      deleteGroup,
      groupRequestsDecline,
      groupInviteDecline,
      groupRequestsCancel,
      getPendingInvitesList,
      getOutgoingInvitesList,
      clearGroupsList,
      updateGroupsList,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withScroll(GroupsList)))
