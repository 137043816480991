import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  clearStandards,
  searchStandards,
} from "../../../library/store/actions/creators/standardsCreators"
import Loader from "../../../components/ui/loader"
import * as qs from "query-string"
import * as _ from "lodash"
import getErrorText from "../../../library/constants/errorTypes"
import withScroll from "../../../hocs/withScroll/withScroll"
import { withRouter } from "react-router-dom"
import { withSnackbar } from "notistack"
import StandardCard from "./standardCard"
import ListSearchHeader from "../../../components/ui/listSearchHeader/listSearchHeader"
import ProductsListFilter from "../marketplace/productsList/productsListFilter/productsListFilter"
import { LIMIT } from "../../../library/constants/limits"
import Grid from "../../../components/ui/grid"
import SEO from "../../../components/SEO"

class Standards extends React.Component {
  state = {
    list: [],
    dataReceived: false,
  }
  searchParams = {}

  async componentDidMount() {
    const {
      location: { search },
    } = this.props
    const {
      q: query,
      standardsGrades,
      standardSubjects,
      states,
    } = qs.parse(search)
    this.searchParams = {
      name: query || undefined,
      subjects: standardSubjects
        ? _.map(_.split(standardSubjects, ","), _.parseInt)
        : undefined,
      standardsGrades: standardsGrades
        ? _.map(_.split(standardsGrades, ","), _.parseInt)
        : undefined,
      state: states ? _.map(_.split(states, ","), _.parseInt)[0] : undefined,
    }
    try {
      await this.props.searchStandards({ ...this.searchParams })
      this.setState({
        list: this.props.standards.standardsList,
        dataReceived: true,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      location: { search },
    } = this.props
    const {
      q: query,
      standardSubjects,
      standardsGrades,
      states,
    } = qs.parse(search)

    this.searchParams = {
      name: query || undefined,
      subjects: standardSubjects
        ? _.map(_.split(standardSubjects, ","), _.parseInt)
        : undefined,
      grades: standardsGrades
        ? _.map(_.split(standardsGrades, ","), _.parseInt)
        : undefined,
      state: states ? _.map(_.split(states, ","), _.parseInt)[0] : undefined,
    }

    if (search && search !== prevProps.location.search) {
      this.props.clearStandards()
      try {
        this.setState({ dataReceived: false, list: [] })
        await this.props.searchStandards({ ...this.searchParams })
        this.setState({
          list: this.props.standards.standardsList,
          dataReceived: true,
        })
      } catch ({ error }) {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    }
  }

  componentWillUnmount() {
    this.props.clearStandards()
  }

  processSearch = async (name) => {
    const {
      location: { search },
      history,
    } = this.props
    const { standardSubjects, standardsGrades, states } = qs.parse(search)

    this.searchParams = {
      q: name || undefined,
      subjects: standardSubjects
        ? _.map(_.split(standardSubjects, ","), _.parseInt)
        : undefined,
      grades: standardsGrades
        ? _.map(_.split(standardsGrades, ","), _.parseInt)
        : undefined,
      state: states ? _.map(_.split(states, ","), _.parseInt)[0] : undefined,
    }

    const result = qs.stringify(this.searchParams, { arrayFormat: "comma" })

    history.push({
      search: `?${result}`,
    })
  }

  onScroll = async () => {
    if (!this.state.dataReceived) return
    this.setState({
      dataReceived: false,
    })
    try {
      await this.props.searchStandards({
        ...this.searchParams,
        offset: this.state.list.length,
        limit: LIMIT,
      })
      this.setState({
        list: this.props.standards.standardsList,
        dataReceived: true,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  render() {
    const { dataReceived, list } = this.state
    return (
      <div className="common-page__content common-page__content--static">
        <SEO title={"Standards"} />
        <main className="common-page__main">
          <ListSearchHeader
            title="Standards"
            placeholder="Start typing standard name"
            search={this.processSearch}
            noResults={!list.length && dataReceived}
            counter={this.props.standards.standardsCount}
          />
          <Grid>
            {list.map((standard) => {
              return <StandardCard key={standard.id} standard={standard} />
            })}
          </Grid>
          {!dataReceived && <Loader />}
        </main>
        <aside className="common-page__sidebar">
          <ProductsListFilter
            hiddenFields={[
              "contentTypes",
              "subjects",
              "grades",
              !this.searchParams.state && "standardSubjects",
            ]}
          />
        </aside>
      </div>
    )
  }
}

const mapState = ({ standards }) => ({ standards })
const mapDispatch = (dispatch) =>
  bindActionCreators({ searchStandards, clearStandards }, dispatch)

export default connect(
  mapState,
  mapDispatch
)(withRouter(withScroll(withSnackbar(Standards))))
