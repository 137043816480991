const FillBlankQuestion = ({ question, index }) => {
  return (
    <>
      <div className="form__fieldset">
        <span className="form__label">Question {index + 1}</span>
        <div className="form__fieldbox">
          <div className="form__masked-value">{question.title}</div>
        </div>
      </div>

      {question.parts.map((part, pIdx) => (
        <div className="form__fieldset" key={'part-' + pIdx}>
          <span className="form__label">
            {part.type === 1 ? 'Blank part answer' : 'Text part'}
          </span>
          <div className="form__fieldbox">
            <div className="form__masked-value">{part.text}</div>
          </div>
        </div>
      ))}
    </>
  )
}

export default FillBlankQuestion
