import { generatePath, Link } from "react-router-dom"
import {
  getUserName,
  getUserPhoto,
} from "../../../../../library/utils/user/userUtils"
import { PlainButton } from "../../../controls"
import EditOutlined from "@material-ui/icons/EditOutlined"
import DeleteForeverOutlined from "@material-ui/icons/DeleteForeverOutlined"
import React from "react"
import { useCommentCtx } from "../CommentContext"
import * as routes from "../../../../../library/constants/routes"
import { getGroupLogo } from "../../../../../library/utils/getGroupLogo"

const CommentHeader = () => {
  const { comment, onDelete, isEdit, setIsEdit } = useCommentCtx()

  const profileUrl = comment.user
    ? generatePath(routes.USER, { userId: comment.user.id })
    : generatePath(routes.GROUP, { groupId: comment.group.id })

  const creatorAvatar = comment.user
    ? getUserPhoto(comment.user)
    : getGroupLogo(comment.group)
  return (
    <div className="post-comment__header">
      <Link to={profileUrl}>
        <img
          src={creatorAvatar}
          alt="Author Name"
          className="post-comment__author-avatar post-comment__author-avatar--img"
        />
      </Link>
      <div className="f fdc jcc fg1">
        <Link to={profileUrl} className="asfs link">
          {comment.user ? (
            <span className="post-comment__author-name">
              {getUserName(comment.user)}
            </span>
          ) : (
            <span className="post-comment__author-name">
              {comment.group.name}
            </span>
          )}
        </Link>
      </div>

      {!isEdit && (comment.can_edit || comment.can_delete) && (
        <div className="post-comment__manage">
          {comment.can_edit && (
            <PlainButton onClick={() => setIsEdit(true)}>
              <EditOutlined />
              Edit
            </PlainButton>
          )}
          {comment.can_delete && (
            <PlainButton onClick={onDelete}>
              <DeleteForeverOutlined />
              Delete
            </PlainButton>
          )}
        </div>
      )}
    </div>
  )
}

export default CommentHeader
