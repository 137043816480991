import React from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core"
import { complaintsTypes } from "../../library/constants/complaintsTypes"
import { useComplaintsMutation } from "../../hooks/data/complaints"
import { mutationTypes } from "../../library/utils/reactQuery"
import FormRadioGroup from "../formComponents/formRadioGroup"

const CreateComplaintModal = ({
  open,
  onClose,
  callback,
  updater,
  mutationKey,
  mutationType,
  ...params
}) => {
  const [type, setType] = React.useState("1")

  const { complain } = useComplaintsMutation({
    key: mutationKey,
    mutationType: mutationType,
  })

  const proceedComplaintsCreate = async () => {
    await complain.mutateAsync({
      data: { ...params, type: Number(type) },
      updater,
    })
    if (typeof callback === "function") await callback()

    onClose()
  }

  return (
    <Dialog
      aria-labelledby="create-product-dialog"
      onClose={onClose}
      open={open}
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title" className="text-center">
        What's going on?
      </DialogTitle>
      <DialogContent>
        <div className="f jcc">
          <div className="form form--modal">
            <FormRadioGroup
              onChange={setType}
              label={"Reason *"}
              name={"type"}
              value={type}
              options={Object.keys(complaintsTypes).map((v) => ({
                label: complaintsTypes[v],
                value: v.toString(),
              }))}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button type="submit" color="primary" onClick={proceedComplaintsCreate}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateComplaintModal
