export const MESSAGES = {
    CREATE: "messages.create",
    UPDATE: "messages.update",
    LIST: "messages.list",
    SEARCH: "messages.search",
    CLEAR: "messages.clear",
    CHATS: {
        LIST: "messages.chats.list",
        UPDATE: "messages.chats.update",
        READ: "messages.chats.markAsRead",
        CLEAR: "messages.chats.clear",
    },
};
