import { formatInTimeZone, getTimezoneOffset } from "date-fns-tz"
import enUS from "date-fns/locale/en-US"

export const useTimezone = () => {
  const currentTz = Intl.DateTimeFormat().resolvedOptions().timeZone

  const ianaTzList = Intl.supportedValuesOf("timeZone")

  const getTzName = (tz) => {
    const tzName = formatInTimeZone(new Date(), tz, "(zzz) zzzz", {
      locale: enUS,
    })
    const location = tz
      .split("/")
      .slice(1)
      .map((p) => p.replaceAll("_", " "))
      .reverse()
      .join(", ")

    return tzName + " - " + location
  }

  const tzList = ianaTzList
    .map((tz) => ({
      id: tz,
      name: getTzName(tz),
      offset: getTimezoneOffset(tz),
    }))
    .sort((a, b) => a.offset - b.offset)

  return {
    currentTz,
    tzList,
    getTzName,
  }
}
