import React from "react"

const Grid = ({ children, columnsNumber = 4 }) => {
  return (
    <div
      className={"grid gap-3"}
      style={{ gridTemplateColumns: `repeat(${columnsNumber}, 1fr)` }}
    >
      {children}
    </div>
  )
}

export default Grid
