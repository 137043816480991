import React from "react"
import { Route, Switch } from "react-router-dom"
import * as routes from "../../../library/constants/routes"
import HowToCategoriesPage from "./howToCategories/HowToCategoriesPage"
import HowToCategoryPage from "./howToCategories/HowToCategoryPage"
import HowToArticlePage from "./howToArticle/HowToArticlePage"
import BoxPage from "../../../components/v2/layout/boxPage/boxPage"

const HowTo = (props) => {
  // return <BoxPage title={"How To"}>Content</BoxPage>
  return (
    <Switch>
      <Route exact path={routes.HOW_TO} component={HowToCategoriesPage} />
      <Route
        exact
        path={routes.HOW_TO_CATEGORY}
        component={HowToCategoryPage}
      />
      <Route exact path={routes.HOW_TO_ARTICLE} component={HowToArticlePage} />
    </Switch>
  )
}

export default HowTo
