import React, { useEffect, useState } from "react"
import { KNOWCREDS } from "../../../../../library/store/actions/types/knowCredsTypes"
import { call } from "../../../../../library/networking/API"
import { KeyboardArrowRight } from "@material-ui/icons"
import ImageSourcePicker from "./imageSourcePicker"
import OwnImagePicker from "./ownImagePicker"
import withErrorBoundary from "../../../../../hocs/guards/withErrorBoundary"

const ForegroundsPicker = ({
  onChangeLibraryImage,
  libraryImage,
  savedLibraryImage,
  onChangeOwnImage,
  ownImage,
  onImageSourceChange,
  type,
  activity,
}) => {
  const [isMounted, setIsMounted] = useState(false)
  const [list, setList] = useState([])
  const [visibleList, setVisibleList] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [imageSource, setImageSource] = useState("library")

  const getList = async () => {
    const response = await call(KNOWCREDS.FOREGROUNDS_LIST, {
      offset: 0,
      limit: 100,
      type,
    })

    const images = await response.data.foregrounds

    if (!isMounted && !!savedLibraryImage) {
      const isLibraryImage = !!images.find(
        (img) => img.url === savedLibraryImage
      )

      if (!isLibraryImage) {
        onChangeOwnImage(savedLibraryImage)
        onChangeLibraryImage(images[0].url)
        setImageSource("own")
      }
    } else {
      onChangeLibraryImage(images[0].url)
    }

    const imagesByActivity =
      activity > 1 ? images.filter((img) => img.activity === activity) : images

    setList(imagesByActivity)
    setVisibleList(imagesByActivity.slice(0, 18))
    setHasMore(imagesByActivity.length > 18)

    setIsMounted(true)
  }

  useEffect(() => {
    onImageSourceChange(imageSource)
  }, [imageSource])

  useEffect(() => {
    getList()
  }, [type])

  useEffect(() => {
    console.log(activity)

    if (activity > 1 && isMounted) {
      const newList = list.filter((img) => img.activity === activity)

      setVisibleList(newList.slice(0, 18))
      setHasMore(newList.length > 18)
      onChangeLibraryImage(newList[0].url)
    }
  }, [activity])

  const onShowAll = () => {
    if (activity > 1) {
      setVisibleList(list.filter((img) => img.activity === activity))
    } else {
      setVisibleList(list)
    }

    setHasMore(false)
  }

  return (
    <>
      <ImageSourcePicker
        label={"Add Foreground *"}
        value={imageSource}
        onChange={setImageSource}
        name={"fgSource"}
      />
      {imageSource === "library" ? (
        <>
          <div className="knowcred-picker__images">
            {visibleList.map(({ url }) => (
              <div className="knowcred-picker__image-wrapper" key={url}>
                <input
                  type="radio"
                  id={url}
                  name="fg"
                  className="knowcred-picker__input hidden"
                  value={url}
                  checked={libraryImage === url}
                  onChange={(e) => onChangeLibraryImage(e.target.value)}
                />
                <label htmlFor={url} className="knowcred-picker__input-label">
                  <img src={url} alt="" className="knowcred-picker__image" />
                </label>
              </div>
            ))}
          </div>
          {hasMore && (
            <button
              className=" btn btn--plain mb20"
              type={"button"}
              onClick={onShowAll}
            >
              Show All
              <KeyboardArrowRight />
            </button>
          )}
        </>
      ) : (
        <OwnImagePicker
          value={ownImage}
          onChange={onChangeOwnImage}
          currentLibraryImage={libraryImage}
        />
      )}
    </>
  )
}

export default withErrorBoundary(ForegroundsPicker)
