import React, { Component } from "react"
import FormControl from "@material-ui/core/FormControl/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import { DatePicker, DateTimePicker } from "@material-ui/pickers"

class FormDatePicker extends Component {
  state = {
    date_from: this.props.dateFrom ? this.props.dateFrom.value : null,
    date_to: this.props.dateTo ? this.props.dateTo.value : null,
    dateFromError: this.props.dateFrom ? this.props.dateFrom.error : false,
    dateToError: this.props.dateTo ? this.props.dateTo.error : false,
  }

  handleDateChange = (date, name) => {
    const parsedDate = date ? date.toISOString() : null
    this.setState({ [name]: parsedDate })

    if (name === "date_from") {
      // this.setState({dateFromError: !parsedDate});
      this.props.dateFrom.handleChange(parsedDate)
    } else {
      // this.setState({dateToError: !parsedDate});
      this.props.dateTo.handleChange(parsedDate)
    }
  }

  render() {
    const { date_from, date_to, dateError } = this.state
    const { withTimePicker, dateFrom, dateTo } = this.props
    return withTimePicker ? (
      <>
        {!!dateFrom && (
          <div className="form__fieldset">
            <label htmlFor="start-date" className="form__label">
              {dateFrom.label || "Start date"} {dateFrom.required ? " *" : ""}
            </label>
            <div className="form__fieldbox">
              <div className="form__input">
                <FormControl error={this.props.dateFrom.error} fullWidth>
                  <DateTimePicker
                    id="start-date"
                    value={date_from}
                    emptyLabel="Select date"
                    invalidLabel="Select date"
                    allowKeyboardControl={false}
                    fullWidth
                    clearable
                    format="d MMMM 'at' h:mm aaa"
                    margin="normal"
                    error={this.props.dateFrom.error}
                    minDate={this.props.dateFrom.minDate}
                    maxDate={
                      this.props.dateFrom.maxDate ||
                      (date_to
                        ? new Date(date_to)
                        : new Date(2099, 11, 31, 23, 59, 59))
                    }
                    onChange={(date) =>
                      this.handleDateChange(date, "date_from")
                    }
                  />
                  {this.props.dateFrom.error && (
                    <FormHelperText className="error-message">
                      Field is required
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
            </div>
          </div>
        )}

        {!!dateTo && (
          <div className="form__fieldset">
            <label htmlFor="finish-date" className="form__label">
              {dateTo.label || "End date"} {dateTo.required ? " *" : ""}
            </label>
            <div className="form__fieldbox">
              <div className="form__input">
                <FormControl error={this.props.dateTo.error} fullWidth>
                  <DateTimePicker
                    id="finish-date"
                    value={date_to}
                    emptyLabel="Select date"
                    invalidLabel="Select date"
                    allowKeyboardControl={false}
                    fullWidth
                    clearable
                    format="d MMMM 'at' h:mm aaa"
                    error={this.props.dateTo.error}
                    minDate={
                      date_from
                        ? new Date(date_from)
                        : this.props.dateFrom.minDate
                        ? this.props.dateFrom.minDate
                        : new Date(1910, 1, 1)
                    }
                    margin="normal"
                    onChange={(date) => this.handleDateChange(date, "date_to")}
                  />
                  {this.props.dateTo.error && (
                    <FormHelperText className="error-message">
                      Field is required
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
            </div>
          </div>
        )}
      </>
    ) : (
      <>
        {!!dateFrom && (
          <div className="form__fieldset">
            <label htmlFor="start-date" className="form__label">
              {dateFrom.label || "Start date"} {dateFrom.required ? " *" : ""}
            </label>
            <div className="form__fieldbox">
              <div className="form__input">
                <FormControl error={this.props.dateFrom.error} fullWidth>
                  <DatePicker
                    id="start-date"
                    value={date_from}
                    emptyLabel="Select date"
                    invalidLabel="Select date"
                    allowKeyboardControl={false}
                    fullWidth
                    clearable
                    format="d MMMM yyyy"
                    margin="normal"
                    error={this.props.dateFrom.error}
                    minDate={this.props.dateFrom.minDate}
                    maxDate={
                      this.props.dateFrom.maxDate ||
                      (date_to ? new Date(date_to) : new Date(2099, 11, 31))
                    }
                    onChange={(date) =>
                      this.handleDateChange(date, "date_from")
                    }
                  />
                  {this.props.dateFrom.error && (
                    <FormHelperText className="error-message">
                      Field is required
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
            </div>
          </div>
        )}

        {!!dateTo && (
          <div className="form__fieldset">
            <label htmlFor="finish-date" className="form__label">
              {dateTo.label || "End date"} {dateTo.required ? " *" : ""}
            </label>
            <div className="form__fieldbox">
              <div className="form__input">
                <FormControl error={this.props.dateTo.error} fullWidth>
                  <DatePicker
                    id="finish-date"
                    value={date_to}
                    emptyLabel="Select date"
                    invalidLabel="Select date"
                    allowKeyboardControl={false}
                    fullWidth
                    clearable
                    format="d MMMM yyyy"
                    error={this.props.dateTo.error}
                    minDate={
                      date_from
                        ? new Date(date_from)
                        : this.props.dateTo.minDate
                        ? this.props.dateTo.minDate
                        : new Date(1910, 1, 1)
                    }
                    margin="normal"
                    onChange={(date) => this.handleDateChange(date, "date_to")}
                  />
                  {this.props.dateTo.error && (
                    <FormHelperText className="error-message">
                      Field is required
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

export default FormDatePicker
