import React from "react"
import { useSelector } from "react-redux"
import AlertDialog from "../../components/ui/alertDialog"
import { generatePath, useHistory } from "react-router-dom"
import { USER } from "../../library/constants/routes"
import { call } from "../../library/networking/API"
import { APPOINTMENTS } from "../../library/store/actions/types/calendarTypes"
import { meetTypes } from "../../library/constants/meets"
import { useQuery } from "@tanstack/react-query"
import { useIdParam } from "../../hooks/useIdParam"
import { pick } from "lodash"
import { linkProviderNames } from "../../components/v2/inputs/pickers/ContactLinkPicker"
import returnExternalUrl from "../../library/utils/returnExternalUrl"
import { useBookingCreation } from "./useBookingCreation"
import { useAlert } from "../../hooks/useAlert"

export const BookMeetContext = React.createContext()

export const useBookMeet = () => {
  return React.useContext(BookMeetContext)
}

const BookMeetProvider = ({ children }) => {
  const { getMaxSegmentDuration, getMeetTypes, getSmallestHold } =
    useBookingCreation()
  const history = useHistory()
  const { errorAlert } = useAlert()
  const inviteeId = useIdParam("userId")
  const { isLoggedIn, userData } = useSelector(({ auth }) => auth)
  const [isCloseDialogOpen, setIsCloseDialogOpen] = React.useState(false)
  const [data, setData] = React.useState({
    holds: [],
    type: null,
    date: null,
    time: null,
    title: "",
    location: "",
    details: "",
    conference_link: "",
    email: userData?.email || "",
    first_name: userData?.first_name || "",
    last_name: userData?.last_name || "",
  })

  const [friendInvitees, setFriendInvitees] = React.useState([])
  const [outsiderInvitees, setOutsiderInvitees] = React.useState([])

  const {
    data: user,
    isSuccess: userSuccess,
    error: userError,
  } = useQuery({
    queryKey: [APPOINTMENTS.USER_INFO, { id: inviteeId }],
    queryFn: async () => {
      try {
        const response = await call(APPOINTMENTS.USER_INFO, { id: inviteeId })
        const data = await response.data
        return {
          ...data.user,
          google: returnExternalUrl(data.user.google),
          zoom: returnExternalUrl(data.user.zoom),
          teams: returnExternalUrl(data.user.teams),
          webex: returnExternalUrl(data.user.webex),
          outlook: returnExternalUrl(data.user.outlook),
        }
      } catch (e) {
        errorAlert(e)
      }
    },
  })

  const [step, setStep] = React.useState(!isLoggedIn ? 1 : 2)

  const [availableHolds, setAvailableHolds] = React.useState([])
  const [availableTypes, setAvailableTypes] = React.useState([])

  const [availableSlots, setAvailableSlots] = React.useState([])
  const [selectedSlot, setSelectedSlot] = React.useState(null)

  const [selectedTimezone, setSelectedTimezone] = React.useState(null)

  const [allUserMeetTypes, setAllUserMeetTypes] = React.useState([])

  const {
    data: holdsQueryData,
    isSuccess: isHoldsQuerySuccess,
    error: holdsError,
  } = useQuery({
    queryKey: [APPOINTMENTS.PUBLIC_HOLDS],
    queryFn: async (ctx) => {
      const response = await call(ctx.queryKey[0], {
        user_id: inviteeId,
        offset: 0,
        limit: 100,
      })
      return await response.data
    },
    refetchOnWindowFocus: false,
  })

  const {
    data: appointmentTypesQueryData,
    isSuccess: isAppointmentTypesQuerySuccess,
    error: appointmentTypesError,
  } = useQuery({
    queryKey: [APPOINTMENTS.GET_TYPES],
    queryFn: async (ctx) => {
      const response = await call(ctx.queryKey[0], { id: inviteeId })
      const types = await response.data.types

      if (types.length) {
        return { types }
      } else {
        return { types: meetTypes }
      }
    },
    refetchOnWindowFocus: false,
  })

  React.useEffect(() => {
    if (isAppointmentTypesQuerySuccess) {
      setAvailableTypes(appointmentTypesQueryData.types)
    }
  }, [isAppointmentTypesQuerySuccess])

  React.useEffect(() => {
    if (holdsError) {
      errorAlert("Unable to fetch holds")
    }

    if (appointmentTypesError) {
      errorAlert("Unable to fetch appointment types")
    }
  }, [holdsError, appointmentTypesError])

  const onHoldChange = (v) => {
    setData((prevState) => ({
      ...prevState,
      holds: v,
    }))

    if (v.length) {
      const smallestHold = getSmallestHold(v)
      const types = getMeetTypes(
        smallestHold.segments,
        appointmentTypesQueryData.types
      )
      setAvailableTypes(types)
      setData((prevState) => ({
        ...prevState,
        holds: v,
        type:
          getMaxSegmentDuration(smallestHold?.segments) >= prevState.type
            ? prevState.type
            : types[0],
      }))
    } else {
      setAvailableTypes(appointmentTypesQueryData.types)
      setData((prevState) => ({
        ...prevState,
        type: appointmentTypesQueryData.types[0],
      }))
    }
  }

  const onCloseAccepted = () => {
    if (isLoggedIn) {
      history.replace(generatePath(USER, { userId: inviteeId }))
    } else {
      history.replace("/")
    }
  }

  const onClose = (confirmable = true) => {
    if (confirmable) {
      setIsCloseDialogOpen(true)
    } else {
      onCloseAccepted()
    }
  }

  const inviteeContactLinks = pick(user, Object.keys(linkProviderNames))
  const inviteeHasLinks = Object.values(inviteeContactLinks).some(Boolean)

  const [savedLinkType, setSavedLinkType] = React.useState("saved")

  // React.useEffect(() => {
  //   console.log({
  //     holds: data.holds,
  //     smallestHold: data.holds
  //       .map((h) => ({
  //         id: h.id,
  //         duration: h.segments
  //           .map((s) => +s.duration)
  //           .reduce((a, b) => a + b, 0),
  //       }))
  //       .sort((a, b) => a.duration - b.duration)[0],
  //   })
  // }, [data.holds])

  return (
    userSuccess && (
      <BookMeetContext.Provider
        value={{
          user,
          data,
          setData,
          availableSlots,
          setAvailableSlots,
          selectedSlot,
          setSelectedSlot,
          availableHolds,
          availableTypes,
          onHoldChange,
          step,
          setStep,
          onClose,
          selectedTimezone,
          setSelectedTimezone,
          savedLinkType,
          setSavedLinkType,
          friendInvitees,
          setFriendInvitees,
          outsiderInvitees,
          setOutsiderInvitees,
        }}
      >
        {children}
        {isCloseDialogOpen && (
          <AlertDialog
            open={isCloseDialogOpen}
            handleClose={() => setIsCloseDialogOpen(false)}
            handleAccept={onCloseAccepted}
            title="Cancel meet creation?"
            message="Are you sure you want to cancel meet creation?"
            acceptButtonTitle={"Yes"}
            declineButtonTitle={"No"}
          />
        )}
      </BookMeetContext.Provider>
    )
  )
}

export default BookMeetProvider
