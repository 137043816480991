import React from "react"
import styled from "@emotion/styled"
import { mq } from "../../../../../theme/globalStyle"
import { colors } from "../../../../../library/constants/styles/colors"
import TextInput from "../../../inputs/textInput/textInput"
import Button from "@material-ui/core/Button"
import { Form as FormikForm } from "formik"
import Select from "../../../inputs/select/select"
import InputsArray from "../../../inputs/inputsArray/inputsArray"
import Checkbox from "../../../inputs/checkbox/checkbox"
import { PlainButton } from "../../../controls"
import DatePicker from "../../../inputs/datePicker/datePicker"
import isPropValid from "@emotion/is-prop-valid"
import { AddOutlined } from "@material-ui/icons"
import FileLoader from "../../../../formComponents/fileLoader"

const Container = styled(FormikForm, {
  shouldForwardProp: (prop) => isPropValid(prop),
})`
  display: flex;
  position: relative;
  flex-direction: column;
`

const RowContainer = styled.div`
  display: flex;
  margin-bottom: 15px;

  ${mq.xs} {
    flex-direction: column;
  }
`

const FieldLabel = styled.label`
  align-self: flex-start;
  width: 150px;
  padding-top: 10px;
  color: ${colors.black54};
  line-height: 20px;
`

const FieldsContainer = styled.div`
  flex: 1;
`

export const FieldWrapper = styled.div`
  width: 330px;
  margin-bottom: ${(props) => (props.isSelect ? "6px" : 0)};
  margin-top: ${(props) => (props.isSelect ? "6px" : 0)};

  ${mq.xs} {
    width: ${(props) => (props.isModal ? "200px" : "100%")};
  }
`

const MultiInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Submit = styled(Button)`
  align-self: center;
  min-width: 135px !important;
`

export const Divider = styled.hr`
  height: 1px;
  margin-bottom: ${(props) => (props.noMargin ? 0 : "15px")};
  background-color: rgba(210, 210, 210, 0.5);
  border: 0;
`

const DeleteForm = styled(PlainButton)`
  position: absolute;
  top: 7px;
  right: 0;

  ${mq.xs} {
    top: 0;
  }
`

const Form = ({ isModal = false, withBottomDivider, onDelete, children }) => {
  return (
    <Container style={{ alignItems: isModal ? "center" : "stretch" }}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { isModal })
        }

        return child
      })}
      {onDelete && <DeleteForm onClick={onDelete} actionType={"delete"} />}
      {!isModal && (
        <Submit color="primary" variant="contained" type="submit">
          Save
        </Submit>
      )}
      {withBottomDivider && <Divider style={{ marginTop: 15 }} />}
    </Container>
  )
}

Form.Row = ({ name, label = "", isModal = false, children }) => {
  return (
    <RowContainer>
      <FieldLabel htmlFor={name}>{label}</FieldLabel>
      <FieldsContainer>
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, { isModal })
          }

          return child
        })}
      </FieldsContainer>
    </RowContainer>
  )
}

Form.FieldWrapper = ({ isModal, children }) => (
  <FieldWrapper isModal={isModal}>{children}</FieldWrapper>
)
Form.Divider = ({ noMargin = false }) => <Divider noMargin={noMargin} />
Form.TextField = (props) => (
  <FieldWrapper isModal={props.isModal}>
    <TextInput {...props} />
  </FieldWrapper>
)
Form.Select = (props) => (
  <FieldWrapper isModal={props.isModal} isSelect>
    <Select {...props} />
  </FieldWrapper>
)
Form.InputsArray = (props) => (
  <MultiInputsContainer>
    <InputsArray {...props} />
  </MultiInputsContainer>
)
Form.Checkbox = (props) => (
  <FieldWrapper isModal={props.isModal}>
    <Checkbox {...props} />
  </FieldWrapper>
)
Form.DatePicker = (props) => (
  <FieldWrapper isModal={props.isModal}>
    <DatePicker {...props} />
  </FieldWrapper>
)
Form.GroupTitle = (props) => (
  <RowContainer>
    <h3 className={"self-start font-medium text-base w-auto md:w-form"}>
      {props.children}
    </h3>
  </RowContainer>
)
Form.Attachments = (props) => (
  <FieldWrapper isModal={props.isModal}>
    <div className="form__attachments">
      <FileLoader
        attachmentId={"attachments"}
        blobs={props.attachments}
        onUpload={props.setAttachments}
        token={props.token}
        attachmentOnly={true}
        limit={props.limit || 10}
      />
      {props.attachments.length < (props.limit || 10) && !props.disabled && (
        <button
          className="form__add-input"
          type="button"
          onClick={() => document.getElementById("attachments").click()}
        >
          <AddOutlined /> Add attachment
        </button>
      )}
    </div>
  </FieldWrapper>
)

export default Form
