import React from "react"
import { Li, Ul } from "../../uknow/uknowInfoPage"
import img1 from "../../../../assets/img/pages/how-to/knowmeets/1.png"

const HowToKnowmeets = () => {
  return (
    <div className={"f aic fdc-xs"}>
      <div style={{ flex: 1 }}>
        <img src={img1} />
      </div>
      <div style={{ flex: 2 }}>
        <p className={"mb15"}>Create Course Meet</p>
        <Ul>
          <Li>
            Add to a whole course as an override of meetings attached to steps,
            or add to a course step.
          </Li>
          <Li>
            Select a cohort number equal to all participants if you do not want
            it to break into multi-meetings. Select a smaller number than your
            total invited users if you are using the auto-cohorting function for
            subsets.
          </Li>
          <Li>
            The Meet Length is how long you want the meeting to be for when it
            happens.
          </Li>
          <Li>
            Selecting a target date alerts invited users of the intended date
            and time. If auto-cohorting, this date will use the target interval
            to establish a new date once the first one passes. You can leave
            target date and interval blank.
          </Li>
          <Li>
            Selecting a target interval is the time length after your target
            date to set a new target date. The target interval will keep issuing
            new target dates as long as the meeting is released. Calculations
            below the interval show the daily searched time in your chosen holds
            or blocks. The system will find time within the first 7 days but not
            before your target date and time. It will reset with each target
            based on your interval. ​
          </Li>
          <Li>
            A Hold is a defined open time you want the meeting to fall into. Use
            multiple holds if needed. Holds in Course Meets are made by your
            Group Admin. Alternately use Blocks or multiple blocks from your
            master schedule instead of holds. The default without holds or
            blocks is work weekdays Mon.-Fri. 8am-5pm.
          </Li>
          <Li>Use spaces already put in by the Group Admin.</Li>
          <Li>
            Add instructions if you would like the invitees to complete
            something before accepting the meeting.
          </Li>
          <Li>
            The Reminder will appear only for you, the meeting creator in your
            Tracking and on the calendar item. Use it to help yourself remember
            what you’re doing in the meeting.
          </Li>
          <Li>Subject is an optional text field.</Li>
          <Li>
            You will add users after you save the Course Meet and then Release
            it.
          </Li>
        </Ul>
      </div>
    </div>
  )
}

export default HowToKnowmeets
