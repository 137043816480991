import React, { useState } from "react"
import { useAlert } from "../../../../../hooks/useAlert"
import { useDispatch, useSelector } from "react-redux"
import CheckOutlined from "@material-ui/icons/CheckOutlined"
import CloseOutlined from "@material-ui/icons/CloseOutlined"
import {
  acceptAppointmentBooking,
  declineAppointmentBooking,
  forwardAppointmentBooking,
  getIncomingAppointmentBookings,
  removeAppointmentBooking,
} from "../../../../../library/store/actions/creators/knowmapCreators"
import AppointmentBookingDialog from "./appointmentBookingDialog"
import UpdateIcon from "@material-ui/icons/UpdateOutlined"
import withErrorBoundary from "../../../../../hocs/guards/withErrorBoundary"
import HideIcon from "@material-ui/icons/VisibilityOffOutlined"
import BoxPage from "../../../../../components/v2/layout/boxPage/boxPage"
import DataTable from "../../../../../components/v2/dataTable/components"
import { generatePath, Route, useParams } from "react-router-dom"
import { INCOMING_SOLICIT_APPOINTMENT_AUTHORIZED } from "../../../../../library/constants/routes"
import IncomingSolicitAppointmentFormModal from "../../../solicitAppointments/IncomingSolicitAppointmentFormModal"

const columns = [
  { label: "Name", role: "name" },
  { label: "Inviter", width: 120 },
  { label: "Invitees number", width: 80 },
  { label: "Starts at", width: 100 },
  { label: "Duration", width: 80 },
  { label: "Link/Phone", width: 130 },
  { label: "Details", width: 250 },
  { label: "Manage", width: 130, role: "actions" },
]

const IncomingBookings = () => {
  const { requestId } = useParams()
  const { successAlert, errorAlert } = useAlert()

  console.log(requestId)

  const dispatch = useDispatch()
  const { incomingBookings } = useSelector(({ knowmap }) => knowmap)

  const [isDetailsOpen, setIsDetailsOpen] = useState(false)
  const [selectedRecordId, setSelectedRecordId] = useState(null)
  const [bookingType, setBookingType] = React.useState(null)

  const onAccept = async ({ id, type }) => {
    try {
      await dispatch(acceptAppointmentBooking({ id, type }))
      successAlert("Booking accepted")
    } catch (e) {
      errorAlert(e)
    }
  }

  const onDecline = async ({ id, type }) => {
    try {
      await dispatch(declineAppointmentBooking({ id, type }))
      successAlert("Booking declined")
    } catch (e) {
      errorAlert(e)
    }
  }

  const onRemove = async ({ id, type }) => {
    try {
      await dispatch(removeAppointmentBooking({ id, type }))
      successAlert("Record hidden")
    } catch (e) {
      errorAlert(e)
    }
  }

  const onInfoOpen = (id, _type) => {
    setSelectedRecordId(id)
    setBookingType(_type)
    setIsDetailsOpen(true)
  }

  const onInfoClose = () => {
    setIsDetailsOpen(false)
    setBookingType(null)
    setSelectedRecordId(null)
  }

  const onForward = async ({ id }) => {
    try {
      await dispatch(forwardAppointmentBooking({ id }))
      successAlert("You've been moved to the next cohort")
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <BoxPage title={"Incoming Appointment Bookings"}>
      <DataTable
        columns={columns}
        data={incomingBookings.list}
        isFirstColumnPinned
        actionsPinned
        customColumnsWidth
        onDetailsOpen={(record) => onInfoOpen(record.raw.id, record.raw._type)}
        isSearchable={false}
        actions={(record, refetch) => {
          return [
            !record.accepted &&
              !record.declined && {
                type: "button",
                label: () => (
                  <>
                    <CheckOutlined />
                    <span>Accept</span>
                  </>
                ),
                onClick: () =>
                  onAccept({
                    id: record.id,
                    type: record._type,
                  }),
              },
            !record.accepted &&
              !record.declined && {
                type: "button",
                label: () => (
                  <>
                    <CloseOutlined />
                    <span>Decline</span>
                  </>
                ),
                onClick: () =>
                  onDecline({
                    id: record.id,
                    type: record._type,
                  }),
              },
            !record.accepted &&
              !record.declined && {
                type: "button",
                label: () => (
                  <>
                    <UpdateIcon />
                    <span>Push forward</span>
                  </>
                ),
                onClick: () =>
                  onForward({
                    id: record.id,
                    type: record._type,
                  }),
              },
            {
              type: "button",
              label: () => (
                <>
                  <HideIcon />
                  <span>Hide</span>
                </>
              ),
              onClick: async () => {
                await onRemove({
                  id: record.id,
                  type: record._type,
                })
                await refetch()
              },
            },
          ].filter(Boolean)
        }}
        getData={async ({ name, offset, limit }) => {
          await dispatch(
            getIncomingAppointmentBookings({ name, offset, limit })
          )
        }}
        availableOffsets={incomingBookings.availableOffsets}
        notFoundMessage={"You don't have incoming bookings yet"}
      />

      {isDetailsOpen && (
        <AppointmentBookingDialog
          isConfirmable={true}
          onClose={onInfoClose}
          open={isDetailsOpen}
          activeBookingId={selectedRecordId}
          bookingType={bookingType}
        />
      )}
    </BoxPage>
  )
}

export default withErrorBoundary(IncomingBookings)
