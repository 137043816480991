import React, { Component } from "react"
import "./knowCredSingle.scss"
import { Button } from "@material-ui/core"
import { generatePath, Link, Redirect } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import {
  clearCred,
  knowCredClear,
  knowCredDelete,
  knowCredGet,
  knowCredLike,
  knowCredUnLike,
} from "../../../../library/store/actions/creators/knowCredsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { bindActionCreators } from "redux"
import {
  knowCredActivitiesIds,
  knowcredStatusTypes,
  knowCredTypesIds,
} from "../../../../library/constants/knowCred"
import FavouriteBorderedOutlined from "@material-ui/icons/FavoriteBorderOutlined"
import FavouriteOutlined from "@material-ui/icons/FavoriteOutlined"
import Loader from "../../../../components/ui/loader"
import returnExternalUrl from "../../../../library/utils/returnExternalUrl"
import ExpandableText from "../../../../components/expandableText/expandableText"
import getErrorText from "../../../../library/constants/errorTypes"
import LockOutlined from "@material-ui/icons/LockOutlined"
import SEO from "../../../../components/SEO"

class KnowCredSingle extends Component {
  state = {
    likesNumber: "",
    isLiked: false,
    dataReceived: false,
    redirect: false,
  }

  async componentDidMount() {
    const id = parseInt(this.props.match.params.knowCredId)

    try {
      await this.props.knowCredGet({ id })

      if (
        !this.props.knowCreds.currentKnowCred.public &&
        this.props.knowCreds.currentKnowCred.creator.id !==
          this.props.auth.userData.id &&
        this.props.knowCreds.currentKnowCred.owner.id !==
          this.props.auth.userData.id
      ) {
        this.props.enqueueSnackbar("Access denied", { variant: "error" })
        return this.setState({ redirect: true })
      }

      this.props.showError(false)
      this.setState({
        likesNumber: this.props.knowCreds.currentKnowCred.likes_number,
        isLiked: this.props.knowCreds.currentKnowCred.is_liked,
        dataReceived: true,
      })
    } catch ({ error }) {
      if (error.code === 21000) {
        this.props.showError(true)
      } else {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    }
  }

  processLike = async () => {
    const {
      auth: { userData },
      knowCreds: { currentKnowCred },
    } = this.props

    try {
      if (this.state.isLiked) {
        this.setState((prevState) => {
          return {
            isLiked: false,
            likesNumber: prevState.likesNumber - 1,
          }
        })
        await this.props.knowCredUnLike({
          token: userData.token,
          knowcred_id: currentKnowCred.id,
        })
      } else {
        this.setState((prevState) => {
          return {
            isLiked: true,
            likesNumber: prevState.likesNumber + 1,
          }
        })
        await this.props.knowCredLike({
          token: userData.token,
          knowcred_id: currentKnowCred.id,
        })
      }
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  processDeleteKnowCred = async () => {
    const {
      knowCreds: { currentKnowCred },
    } = this.props

    try {
      await this.props.knowCredDelete({ id: currentKnowCred.id })
      await this.props.enqueueSnackbar("KnowCred successfully deleted", {
        variant: "success",
      })
      this.setState({ redirect: true })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  async componentWillUnmount() {
    await this.props.clearCred()
  }

  render() {
    const { dataReceived, redirect, isLiked, likesNumber } = this.state
    const { currentKnowCred } = this.props.knowCreds

    if (redirect) {
      return (
        <Redirect
          to={generatePath(routes.KNOWCRED, {
            userId: this.props.auth.userData.id,
          })}
        />
      )
    }

    if (dataReceived) {
      const linkToEdit = generatePath(routes.KNOWCRED_EDIT, {
        knowCredId: currentKnowCred.id,
      })
      const linkToCreator = generatePath(routes.USER, {
        userId: currentKnowCred.creator.id,
      })
      const linkToOwner = currentKnowCred.owner
        ? generatePath(routes.USER, { userId: currentKnowCred.owner.id })
        : "/"
      const creatorName = `${currentKnowCred.creator.first_name} ${currentKnowCred.creator.last_name}`
      const ownerName = currentKnowCred.owner
        ? `${currentKnowCred.owner.first_name} ${currentKnowCred.owner.last_name}`
        : currentKnowCred.email

      return (
        <div className="box knowcred">
          <SEO title={"Cred"} />
          <div className="box__heading">
            <h1 className="knowcred__title">{currentKnowCred.title}</h1>
          </div>
          <div className="box__content">
            <div className="knowcred__image">
              {!currentKnowCred.public && (
                <div className="knowcred__lock-icon">
                  <LockOutlined className="color-primary" />
                </div>
              )}
              <img
                src={currentKnowCred.background_image}
                className="knowcred__background"
                style={{ border: `5px solid ${currentKnowCred.border_color}` }}
                alt="Background"
              />
              <img
                src={currentKnowCred.foreground_image}
                className="knowcred__foreground"
                alt="Foreground"
              />
              {currentKnowCred.status_type !== 0 && (
                <div
                  className="knowcred-card__validation-label"
                  style={{ backgroundColor: currentKnowCred.border_color }}
                >
                  {knowcredStatusTypes[currentKnowCred.status_type]}
                </div>
              )}
            </div>

            <div className="knowcred__social-controls">
              <div
                className="knowcred__social-control"
                onClick={this.processLike}
              >
                {isLiked ? (
                  <FavouriteOutlined className="color-primary" />
                ) : (
                  <FavouriteBorderedOutlined />
                )}
                {likesNumber > 0 && (
                  <span className={isLiked ? "color-primary" : ""}>
                    {likesNumber}
                  </span>
                )}
              </div>
            </div>

            <table border="0" className="knowcred__info">
              <tbody>
                <tr className="knowcred__info-row">
                  <td className="knowcred__info-label">Type:</td>
                  <td>{knowCredTypesIds[currentKnowCred.type]}</td>
                </tr>
                {![1, 5].includes(currentKnowCred.type) && (
                  <tr className="knowcred__info-row">
                    <td className="knowcred__info-label">Activity:</td>
                    <td>{knowCredActivitiesIds[currentKnowCred.activity]}</td>
                  </tr>
                )}
                {!!currentKnowCred.location && (
                  <tr className="knowcred__info-row">
                    <td className="knowcred__info-label">Location:</td>
                    <td>{currentKnowCred.location}</td>
                  </tr>
                )}
                {((currentKnowCred.owner &&
                  this.props.auth.userData.id !== currentKnowCred.owner.id &&
                  currentKnowCred.owner.id !== currentKnowCred.creator.id) ||
                  !currentKnowCred.owner) && (
                  <tr className="knowcred__info-row">
                    <td className="knowcred__info-label">Owner:</td>
                    <td>
                      {currentKnowCred.owner ? (
                        <Link to={linkToOwner} className="link">
                          {ownerName}
                        </Link>
                      ) : (
                        ownerName
                      )}
                    </td>
                  </tr>
                )}
                {this.props.auth.userData.id !== currentKnowCred.creator.id && (
                  <tr className="knowcred__info-row">
                    <td className="knowcred__info-label">Creator:</td>
                    <td>
                      <Link to={linkToCreator} className="link">
                        {creatorName}
                      </Link>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <div className="knowcred__description">
              <ExpandableText text={currentKnowCred.description} limit={500} />
            </div>

            {!!currentKnowCred.links.length && (
              <>
                <div className="font-16 mb8">Proofs</div>
                <div className="knowcred__links">
                  {currentKnowCred.links.map((link, index) => (
                    <div key={link + index}>
                      <a href={`${returnExternalUrl(link)}`} className="link">
                        {link}
                      </a>
                    </div>
                  ))}
                </div>
              </>
            )}

            {!currentKnowCred.public && (
              <p className={"font-weight-500 mb8"}>
                This KnowCred is private and is visible for you only.
              </p>
            )}

            <div className="mt15 f jcfe">
              {(currentKnowCred.can_delete || currentKnowCred.can_edit) && (
                <div className="btn-group">
                  {currentKnowCred.can_delete && (
                    <Button
                      variant="outlined"
                      onClick={this.processDeleteKnowCred}
                    >
                      Delete
                    </Button>
                  )}
                  {currentKnowCred.can_edit && (
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={linkToEdit}
                    >
                      Edit
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )
    } else {
      return <Loader />
    }
  }
}

const mapStateToProps = ({ auth, friends, knowCreds }) => ({
  auth,
  friends,
  knowCreds,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearCred,
      knowCredGet,
      knowCredLike,
      knowCredUnLike,
      knowCredDelete,
      knowCredClear,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(KnowCredSingle))
