import React from "react"
import "./productsCategoriesCarousel.scss"
import Slider from "react-slick"
import "../../../../../assets/libraries/slick/slick.css"
import "../../../../../assets/libraries/slick/slick-theme.css"
import * as educationalDictionary from "../../../../../library/constants/educationalDictionary"
import { Link } from "react-router-dom"
import * as routes from "../../../../../library/constants/routes"
import { chunk } from "lodash"

const NextArrow = (props) => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      onClick={onClick}
      style={{ ...style, zIndex: "auto" }}
    />
  )
}

const ProductsCategoriesCarousel = () => {
  const composePageItems = () => {
    return chunk(educationalDictionary.subjects, 5)
  }

  const categoryPath = (id) => {
    return `${routes.MARKETPLACE}?subjects=${id}`
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: false,
    swipe: false,
    nextArrow: <NextArrow />,
    arrows: true,
  }

  const [width, setWidth] = React.useState(0)

  React.useEffect(() => {
    const wrapperEl = document.getElementById("slider-wrapper")
    if (wrapperEl) {
      setWidth(wrapperEl.offsetWidth)
    }
  }, [])

  return (
    <div id={"slider-wrapper"} className={"w-full"}>
      {!!width && (
        <div className="pb-6 d-ie-none d-none-xs" style={{ maxWidth: width }}>
          <Slider {...settings}>
            {composePageItems().map((item, index) => (
              <div className="no-outline" key={index}>
                <div className="grid grid-cols-5 gap-3 pb-4">
                  {item.map((subject) => {
                    return (
                      <Link
                        to={categoryPath(subject.id)}
                        className="grid grid-rows-subgrid gap-2 rounded-sm p-4 bg-white shadow-card items-center"
                        style={{ gridRow: "span 2" }}
                        role="button"
                        key={subject.id}
                      >
                        <img
                          src={subject.image}
                          alt={subject.name}
                          className="w-full aspect-square object-cover object-center overflow-hidden rounded"
                        />
                        <div className={"h-12 w-full text-center"}>
                          <span className="text-primary font-medium">
                            {subject.name}
                          </span>
                        </div>
                      </Link>
                    )
                  })}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      )}
    </div>
  )
}

export default ProductsCategoriesCarousel
