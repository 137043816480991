import React, { Component } from "react"
import { GROUPS } from "../../../../../../../library/store/actions/types/groupsTypes"
import Dialog from "@material-ui/core/Dialog"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { generatePath, withRouter } from "react-router-dom"
import { FormDatePicker } from "../../../../../../../components/formComponents"
import {
  createKnowmixStepUknow,
  getKnowmixStepUknow,
  updateKnowmixStepUknow,
} from "../../../../../../../library/store/actions/creators/uknowCreators"
import IntPicker from "../../../../../../../components/v2/inputs/pickers/intPicker"
import KnowmixPicker from "../../../../../../../components/v2/inputs/pickers/knowmixPicker"
import HoldPicker from "../../../../../../../components/v2/inputs/pickers/holdPicker"
import {
  CALENDAR_HOLDS,
  GROUP_SETTINGS_KNOWMIX,
  KNOWMIX_SETTINGS_STEPS,
} from "../../../../../../../library/constants/routes"
import SpacePicker from "../../../../../../../components/v2/inputs/pickers/spacePicker"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import StepPicker from "../../../../../../../components/v2/inputs/pickers/stepPicker"
import { urlValidation } from "../../../../../../../library/utils/validators"
import FormControl from "@material-ui/core/FormControl/FormControl"
import { Radio, RadioGroup } from "@material-ui/core"
import FormHelperText from "@material-ui/core/FormHelperText"
import { colors } from "../../../../../../../library/constants/styles/colors"
import getErrorText from "../../../../../../../library/constants/errorTypes"
import { safeGetEntity } from "../../../../../../../library/utils/entities"
import * as qs from "query-string"
import GroupPicker from "../../../../../../../components/v2/inputs/pickers/groupPicker"
import { addMonths } from "date-fns"
import DurationInput from "../../../../../../../components/v2/inputs/durationInput/durationInput"
import { timeDifference } from "../../../../../../../library/utils/timeString/timeString"
import FormCheckbox from "../../../../../../../components/formComponents/formCheckbox"

class FullStepKnowmeetFormModal extends Component {
  state = {
    name: "",
    instructions: "",
    date_from: null,
    // date_to: null,
    cohort_size: 1,
    target_date: null,
    hold: "",
    holdError: false,
    holdsReceived: false,
    school: "",
    schoolError: false,
    knowmix: "",
    knowmixError: false,
    step: "",
    stepError: false,
    space: "",
    subject: "",
    reminder: "",
    link: "",
    auto_cancel: false,
    schedule: "",
    scheduleError: false,
    segment: "",
    segmentError: false,
    section: "",
    sectionError: false,
    block: "",
    blockError: false,
    holdOrBlock: "hold",
    knowmixOrStep: "knowmix",
    time: 15,
    maxTime: 1440,
    allow_over_cohort: false,
    require_full_cohort: false,
    target_interval: 10,

    dataReceive: !this.props.activeKnowmeet,
  }

  async componentDidMount() {
    console.log(this.props.activeKnowmeet)
    ValidatorForm.addValidationRule("isLink", (value) => urlValidation(value))

    if (this.props.activeKnowmeet) {
      try {
        const data = await this.props.getKnowmixStepUknow({
          id: this.props.activeKnowmeet,
        })

        this.setState({
          name: data.name || "",
          instructions: data.instructions || "",
          date_from: data.date_from || null,
          // date_to: null,
          cohort_size: data.cohort_size || 1,
          target_date: data.target_date || null,
          hold: safeGetEntity(data.hold),
          school: safeGetEntity(data.school),
          knowmix: safeGetEntity(data.knowmix),
          step: safeGetEntity(data.step),
          space: safeGetEntity(data.space),
          subject: data.subject || "",
          reminder: data.reminder || "",
          link: data.links?.[0] || "",
          auto_cancel: data.auto_cancel || false,
          schedule: safeGetEntity(data?.knowmix?.schedule),
          segment: safeGetEntity(data?.block?.segment),
          section: safeGetEntity(data?.block?.section),
          block: safeGetEntity(data?.block),
          holdOrBlock: safeGetEntity(data?.hold) ? "hold" : "block",
          knowmixOrStep: safeGetEntity(data?.step) ? "step" : "knowmix",
          time: data.duration || 15,
          allow_over_cohort: data.allow_over_cohort || false,
          require_full_cohort: data.require_full_cohort || false,
          target_interval: data.target_interval || 10,
          dataReceived: true,
        })
      } catch ({ error }) {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    }
  }

  handleChange = (name) => (event) => {
    this.setState(
      {
        [name]: event.target.value,
      },
      () => {
        if (name === "knowmixOrStep" && event.target.value === "knowmix") {
          this.setState({ name: "" })
        }

        if (name === "holdOrBlock") {
          this.setState({
            time: 15,
            maxTime: "",
            block: "",
            hold: "",
          })
        }
      }
    )
  }

  handleChangeRequired = (name) => (event) => {
    if (name === "step") {
      this.setState({
        name: event.target.value?.name || "",
      })
    }

    if (name === "block" || name === "hold") {
      const schedulePeriod = event.target.value

      const schedulePeriodDuration = !schedulePeriod.is_all_day
        ? timeDifference(schedulePeriod.time_from, schedulePeriod.time_to)
        : 1440

      // console.log({
      //   schedulePeriod,
      //   startTime,
      //   endTime,
      //   schedulePeriodDuration,
      //   timeDifference: timeDifference(
      //     schedulePeriod.time_from,
      //     schedulePeriod.time_to
      //   ),
      // })

      // console.log(schedulePeriodDuration);

      this.setState({ maxTime: schedulePeriodDuration })
    }

    return this.setState({
      [name]: event.target.value,
      [name + "Error"]: !event.target.value,
    })
  }

  handleChangeDate = (name, date) => {
    this.setState({ [name]: date })
  }

  handleFileInputOpen = () => {
    document.getElementById("attachments").click()
  }

  processFormValidate = () => {
    this.setState((state) => ({
      schoolError: !state.school,
      knowmixError: !state.knowmix,
      stepError: state.knowmixOrStep === "step" && !state.step,
      holdError: state.holdOrBlock === "hold" && !state.hold,
      segmentError: state.holdOrBlock === "block" && !state.segment,
      sectionError: state.holdOrBlock === "block" && !state.section,
      blockError: state.holdOrBlock === "block" && !state.block,
    }))
  }

  processKnowmeetCreate = async () => {
    const { s, k } = qs.parse(this.props.location.search)

    const {
      name,
      instructions,
      cohort_size,
      // date_to,
      date_from,
      target_date,
      step,
      stepError,
      hold,
      knowmix,
      space,
      knowmixError,
      holdError,
      segment,
      segmentError,
      section,
      sectionError,
      block,
      blockError,
      reminder,
      subject,
      auto_cancel,
      link,
      holdOrBlock,
      knowmixOrStep,
      time,
      allow_over_cohort,
      require_full_cohort,
      target_interval,
    } = this.state

    if (
      knowmixError ||
      stepError ||
      holdError ||
      segmentError ||
      sectionError ||
      blockError
    )
      return

    const data = {
      knowmix_id: knowmix.id,
      step_id: knowmixOrStep === "step" ? step.id : undefined,
      name,
      instructions: instructions || undefined,
      cohort_size,
      // date_from: date_from || undefined,
      // date_to: date_to || undefined,
      target_date: target_date || undefined,
      hold_id: holdOrBlock === "hold" ? hold?.id : undefined,
      space_id: space?.id,
      subject: subject || undefined,
      links: link ? [link] : [],
      duration: time || 15,
      reminder: reminder || undefined,
      allow_over_cohort,
      require_full_cohort,
      block_id: holdOrBlock === "block" ? block?.id : undefined,
      target_interval: target_interval | 10,
    }

    if (!this.props.activeKnowmeet) {
      const newKnowmeet = await this.props.createKnowmixStepUknow(data)

      if (knowmixOrStep === "knowmix") {
        this.props.openKnowmeetCreatedDialog(newKnowmeet)
      }
    } else {
      await this.props.updateKnowmixStepUknow({
        ...data,
        id: this.props.activeKnowmeet,
        knowmix_id: undefined,
        step_id: undefined,
      })
    }

    if (this.props.afterSubmit) await this.props.afterSubmit()

    this.props.handleClose()
  }

  render() {
    const { s, k } = qs.parse(this.props.location.search)

    console.log(this.props)
    const { handleClose, open } = this.props
    const {
      name,
      instructions,
      cohort_size,
      date_from,
      // date_to,
      target_date,
      school,
      schoolError,
      knowmix,
      knowmixError,
      step,
      stepError,
      hold,
      holdError,
      space,
      subject,
      reminder,
      link,
      auto_cancel,
      schedule,
      scheduleError,
      segment,
      segmentError,
      section,
      sectionError,
      block,
      blockError,
      holdOrBlock,
      time,
      knowmixOrStep,
      allow_over_cohort,
      require_full_cohort,
      target_interval,
    } = this.state

    return (
      <>
        <Dialog
          aria-labelledby="event-form-dialog"
          onClose={handleClose}
          open={open}
          maxWidth="md"
        >
          <ValidatorForm onSubmit={this.processKnowmeetCreate}>
            <DialogTitle id="alert-dialog-name" className="text-center">
              {!this.props.activeKnowmeet ? "Create" : "Edit"} Course Frame Meet
            </DialogTitle>
            <DialogContent>
              <div className="f jcc">
                <div className="form form--modal">
                  {!this.props.activeKnowmeet && (
                    <div className="form__fieldset">
                      <label htmlFor="knowmixOrStep" className="form__label">
                        Create Meet for
                      </label>
                      <div className="form__fieldbox">
                        <div className="form__input">
                          <FormControl component="fieldset" id="knowmixOrStep">
                            <RadioGroup
                              aria-label="knowmixOrStep"
                              name="knowmixOrStep"
                              value={knowmixOrStep}
                              onChange={this.handleChange("knowmixOrStep")}
                            >
                              <FormControlLabel
                                value="knowmix"
                                control={<Radio color="primary" />}
                                label="Course Frame"
                                labelPlacement="end"
                              />
                              <FormControlLabel
                                value="step"
                                control={<Radio color="primary" />}
                                label="Course Frame step"
                                labelPlacement="end"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  )}

                  {/*{!this.props.activeKnowmeet && (*/}
                  {/*  <SchoolPicker*/}
                  {/*    value={school}*/}
                  {/*    hasError={schoolError}*/}
                  {/*    required*/}
                  {/*    onChange={this.handleChangeRequired("school")}*/}
                  {/*    userId={this.props.auth.userData.id}*/}
                  {/*  />*/}
                  {/*)}*/}
                  {!this.props.activeKnowmeet && (
                    <GroupPicker
                      value={school}
                      hasError={schoolError}
                      required
                      onChange={(option) => {
                        this.setState({
                          school: option,
                          schoolError: !option,
                        })
                      }}
                      requestParams={{
                        types: [4, 7],
                        managed: true,
                      }}
                      fieldName="schools"
                      entityName="school"
                      // emptyListMessage={"You are not an admin of any group"}
                    />
                  )}

                  {/*{!this.props.activeKnowmeet && (*/}
                  <KnowmixPicker
                    apiUrl={GROUPS.KNOWMIX_LIST}
                    groupId={school?.id || parseInt(s)}
                    required
                    value={knowmix}
                    onChange={this.handleChangeRequired("knowmix")}
                    hasError={knowmixError}
                    emptyListMessage={
                      "You don't have courses for this school. Create one and then come back here."
                    }
                    emptyListLink={{
                      to: school
                        ? generatePath(GROUP_SETTINGS_KNOWMIX, {
                            groupId: school.id || parseInt(s) || "",
                          })
                        : "",
                      label: "Go to course steps page",
                    }}
                  />
                  {/*)}*/}

                  {!this.props.activeKnowmeet && knowmixOrStep === "step" && (
                    <StepPicker
                      value={step}
                      hasError={stepError}
                      onChange={this.handleChangeRequired("step")}
                      required
                      knowmixId={knowmix.id || parseInt(k)}
                      emptyListMessage={
                        "You don't have steps for this course. Create one and then come back here."
                      }
                      emptyListLink={{
                        to: knowmix
                          ? generatePath(KNOWMIX_SETTINGS_STEPS, {
                              knowmixId: knowmix.id || parseInt(k),
                            })
                          : "",
                        label: "Go to course steps page",
                      }}
                      getDisabledValue={(v) => !!v?.has_knowmeet}
                      disabledValueMessage={
                        "This step already has Meet created"
                      }
                    />
                  )}

                  <div className="form__fieldset">
                    <label htmlFor="name" className="form__label">
                      Name *
                    </label>
                    <div className="form__fieldbox">
                      <div className="form__input">
                        <TextValidator
                          id="name"
                          name="name"
                          value={name}
                          placeholder="Type Meet name"
                          className="edit-form__input"
                          margin="normal"
                          fullWidth
                          validators={[
                            "required",
                            "minStringLength:3",
                            "maxStringLength:200",
                          ]}
                          errorMessages={[
                            "Field is required",
                            "Min length is 3 characters",
                            "Max length is 200 characters",
                          ]}
                          withRequiredValidator
                          onChange={this.handleChange("name")}
                          disabled={knowmixOrStep === "step"}
                        />
                        {knowmixOrStep === "step" && (
                          <FormHelperText color={colors.black38}>
                            The name of the Meet will be the same as the name of
                            the chosen step
                          </FormHelperText>
                        )}
                      </div>
                    </div>
                  </div>

                  <IntPicker
                    entityName={"Cohort size"}
                    inputName={"cohort_size"}
                    value={cohort_size}
                    onChange={this.handleChange("cohort_size")}
                    required
                  />

                  {/*{((holdOrBlock === "hold" && !!hold) ||*/}
                  {/*  (holdOrBlock === "block" && !!block)) && (*/}
                  <DurationInput
                    name={"duration"}
                    label={"Meet length"}
                    time={time}
                    onChange={(t) => this.setState({ time: t })}
                    minTime={5}
                    maxTime={this.state.maxTime || undefined}
                    periodEntity={holdOrBlock === "block" ? block : hold}
                  />
                  {/*)}*/}

                  <FormDatePicker
                    withTimePicker
                    dateFrom={{
                      label: "Target date",
                      value: target_date,
                      handleChange: (value) =>
                        this.handleChangeDate("target_date", value),
                      minDate: new Date(),
                      maxDate: addMonths(new Date(), 2),
                    }}
                  />

                  <DurationInput
                    name={"target_interval"}
                    label={"Target interval"}
                    time={target_interval}
                    onChange={(t) =>
                      this.setState({
                        target_interval: t,
                      })
                    }
                    minTime={10}
                  />

                  <FormCheckbox
                    label={
                      "Require full cohort, rescheduling for all if a participant who accepted in that cohort pushes it forward"
                    }
                    onChange={(e) =>
                      this.setState({ require_full_cohort: e.target.checked })
                    }
                    value={require_full_cohort}
                  />

                  <FormCheckbox
                    label={
                      "If a full cohort sets a Meet and then more participants accept, add them if it is within an 15 minutes prior"
                    }
                    onChange={(e) =>
                      this.setState({ allow_over_cohort: e.target.checked })
                    }
                    value={allow_over_cohort}
                  />

                  {/*<div className="form__fieldset">*/}
                  {/*  <span className="form__label"></span>*/}
                  {/*  <div className="form__fieldbox">*/}
                  {/*    <div className="form__input">*/}
                  {/*      <FormControlLabel*/}
                  {/*        control={*/}
                  {/*          <Checkbox*/}
                  {/*            checked={auto_cancel}*/}
                  {/*            value={"auto_cancel"}*/}
                  {/*            disableRipple*/}
                  {/*            onChange={(e) =>*/}
                  {/*              this.setState({ auto_cancel: e.target.checked })*/}
                  {/*            }*/}
                  {/*          />*/}
                  {/*        }*/}
                  {/*        label="Auto cancel"*/}
                  {/*      />*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  {/*<div className="form__fieldset">*/}
                  {/*  <label htmlFor="holdOrBlock" className="form__label">*/}
                  {/*    Hold or block*/}
                  {/*  </label>*/}
                  {/*  <div className="form__fieldbox">*/}
                  {/*    <div className="form__input">*/}
                  {/*      <FormControl component="fieldset" id="holdOrBlock">*/}
                  {/*        <RadioGroup*/}
                  {/*          aria-label="holdOrBlock"*/}
                  {/*          name="holdOrBlock"*/}
                  {/*          value={holdOrBlock}*/}
                  {/*          onChange={this.handleChange("holdOrBlock")}*/}
                  {/*        >*/}
                  {/*          <FormControlLabel*/}
                  {/*            value="hold"*/}
                  {/*            control={<Radio color="primary" />}*/}
                  {/*            label="Hold"*/}
                  {/*            labelPlacement="end"*/}
                  {/*          />*/}
                  {/*          <FormControlLabel*/}
                  {/*            value="block"*/}
                  {/*            control={<Radio color="primary" />}*/}
                  {/*            label="Block"*/}
                  {/*            labelPlacement="end"*/}
                  {/*          />*/}
                  {/*        </RadioGroup>*/}
                  {/*      </FormControl>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  <HoldPicker
                    groupId={school?.id || parseInt(s) || undefined}
                    value={hold}
                    hasError={holdError}
                    onChange={this.handleChangeRequired("hold")}
                    emptyListMessage={
                      "You don't have holds for this school. Create one and then come back here."
                    }
                    emptyListLink={{
                      to: generatePath(CALENDAR_HOLDS, {
                        groupId: school.id || this.props.schoolId,
                      }),
                      label: "Go to holds page",
                    }}
                  />

                  {/*{holdOrBlock === "hold" ? (*/}
                  {/*  <HoldPicker*/}
                  {/*    groupId={school?.id || parseInt(s) || undefined}*/}
                  {/*    value={hold}*/}
                  {/*    hasError={holdError}*/}
                  {/*    onChange={this.handleChangeRequired("hold")}*/}
                  {/*    emptyListMessage={*/}
                  {/*      "You don't have holds for this school. Create one and then come back here."*/}
                  {/*    }*/}
                  {/*    emptyListLink={{*/}
                  {/*      to: generatePath(CALENDAR_HOLDS, {*/}
                  {/*        groupId: school.id || this.props.schoolId,*/}
                  {/*      }),*/}
                  {/*      label: "Go to holds page",*/}
                  {/*    }}*/}
                  {/*  />*/}
                  {/*) : (*/}
                  {/*  <>*/}
                  {/*    <SegmentPicker*/}
                  {/*      onChange={this.handleChangeRequired("segment")}*/}
                  {/*      hasError={segmentError}*/}
                  {/*      value={segment}*/}
                  {/*      scheduleId={*/}
                  {/*        this.props.scheduleId || knowmix?.schedule?.id*/}
                  {/*      }*/}
                  {/*      isWaitingForOtherFieldsMessage={"First select KnowMix"}*/}
                  {/*    />*/}
                  {/*    <SectionPicker*/}
                  {/*      onChange={this.handleChangeRequired("section")}*/}
                  {/*      hasError={sectionError}*/}
                  {/*      value={section}*/}
                  {/*      segmentId={segment?.id}*/}
                  {/*    />*/}
                  {/*    <BlockPicker*/}
                  {/*      onChange={this.handleChangeRequired("block")}*/}
                  {/*      hasError={blockError}*/}
                  {/*      value={block}*/}
                  {/*      sectionId={section?.id}*/}
                  {/*    />*/}
                  {/*  </>*/}
                  {/*)}*/}

                  <SpacePicker
                    value={space}
                    onChange={this.handleChange("space")}
                    groupId={school?.id || parseInt(s)}
                  />

                  <div className="form__fieldset">
                    <label htmlFor="instructions" className="form__label">
                      Instructions
                    </label>
                    <div className="form__fieldbox">
                      <div className="form__input">
                        <TextValidator
                          id="instructions"
                          name="instructions"
                          value={instructions}
                          placeholder="Type Meet instructions"
                          className="edit-form__input"
                          margin="normal"
                          fullWidth
                          validators={["maxStringLength:5000"]}
                          errorMessages={["Max length is 5000 characters"]}
                          onChange={this.handleChange("instructions")}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form__fieldset">
                    <label htmlFor="reminder" className="form__label">
                      Reminder
                    </label>
                    <div className="form__fieldbox">
                      <div className="form__input">
                        <TextValidator
                          id="reminder"
                          name="reminder"
                          value={reminder}
                          placeholder="Type Meet reminder"
                          className="edit-form__input"
                          margin="normal"
                          fullWidth
                          multiline
                          validators={["maxStringLength:5000"]}
                          errorMessages={["Max length is 5000 characters"]}
                          onChange={this.handleChange("reminder")}
                        />
                      </div>
                    </div>
                  </div>

                  {knowmixOrStep === "knowmix" && (
                    <div className="form__fieldset">
                      <label htmlFor="subject" className="form__label">
                        Subject
                      </label>
                      <div className="form__fieldbox">
                        <div className="form__input">
                          <TextValidator
                            id="subject"
                            name="subject"
                            value={subject}
                            placeholder="Type Meet subject"
                            className="edit-form__input"
                            margin="normal"
                            fullWidth
                            validators={["maxStringLength:200"]}
                            errorMessages={["Max length is 200 characters"]}
                            onChange={this.handleChange("subject")}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="form__fieldset">
                    <label htmlFor="link" className="form__label">
                      Conference link
                    </label>
                    <div className="form__fieldbox">
                      <div className="form__input">
                        <TextValidator
                          id="link"
                          name="link"
                          value={link}
                          placeholder="Type conference link"
                          className="edit-form__input"
                          margin="normal"
                          fullWidth
                          validators={["isLink", "maxStringLength:250"]}
                          errorMessages={[
                            "Invalid URL",
                            "Max length is 250 characters",
                          ]}
                          onChange={this.handleChange("link")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color={"primary"}>
                Cancel
              </Button>
              <Button
                type={"submit"}
                color={"primary"}
                onClick={this.processFormValidate}
              >
                Save
              </Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>
      </>
    )
  }
}

const mapStateToProps = ({ auth, groups, uknow }) => ({ auth, groups, uknow })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { getKnowmixStepUknow, updateKnowmixStepUknow, createKnowmixStepUknow },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(FullStepKnowmeetFormModal)))
