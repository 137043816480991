import React, { Component } from "react"
import {
  clearPostsList,
  deletePost,
  getPost,
  getPostsList,
  like,
  pinPost,
  restorePost,
  sharePost,
  unLike,
  unpinPost,
  updatePost,
} from "../../../../library/store/actions/creators/postsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { bindActionCreators } from "redux"
import avatarDefault from "../../../../assets/img/user_avatar.svg"
import { generatePath, Link, withRouter } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import UserAvatar from "../../../../components/userAvatar/userAvatar"
import pinIcon from "../../../../assets/img/icons/icon-pin.svg"
import MoreDropdown from "../../../../components/ui/dropdowns/moreDropdown/moreDropdown"
import VerticalMenu from "../../../../components/ui/verticalMenu"
import ChatBubbleOutlineOutlined from "@material-ui/icons/ChatBubbleOutlineOutlined"
import FavouriteBorderedOutlined from "@material-ui/icons/FavoriteBorderOutlined"
import FavouriteOutlined from "@material-ui/icons/FavoriteOutlined"
import ReplyOutlined from "@material-ui/icons/ReplyOutlined"
import AttachFileOutlined from "@material-ui/icons/AttachFileOutlined"
import PhotoOutlined from "@material-ui/icons/PhotoOutlined"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import InsertDriveFile from "@material-ui/icons/InsertDriveFile"
import { formatBytes } from "../../../../library/utils/fileSizeConverter"
import Button from "@material-ui/core/es/Button"
import CommentsList from "../commentsList/commentsList"
import ImageModal from "../../../../components/ui/modals/imageModal"
import { LIMIT } from "../../../../library/constants/limits"
import FileLoader from "../../../../components/formComponents/fileLoader"
import postDateConvert from "../../../../library/utils/postDateConvert"
import { getGroupLogo } from "../../../../library/utils/getGroupLogo"
import getErrorText from "../../../../library/constants/errorTypes"
import LinesEllipsis from "react-lines-ellipsis"
import logoDefault from "../../../../assets/img/product.svg"
import StarRating from "../../../../components/ui/starRating"
import { productContentTypes } from "../../../../library/constants/productDictionary"
import "./post.scss"
import {
  knowCredActivitiesIds,
  knowCredTypesIds,
} from "../../../../library/constants/knowCred"
import { standardsGrades } from "../../../../library/constants/educationalDictionary"
import {
  bindFolder,
  bookmarkFoldersList,
  bookmarksFoldersListClear,
  createBookmark,
  deleteBookmark,
  foldersList,
} from "../../../../library/store/actions/creators/bookmarksCreators"
import BookmarkedItemDropdown from "../../bookmarks/bookmarksList/bookmarkedItemDropdown/bookmarkedItemDropdown"
import unavailableAvatar from "../../../../assets/img/user_unavailable.svg"
import AddToFolderModal from "../../bookmarks/folders/addToFolder/addToFolder"
import ComplaintsModal from "../../../../components/complaints/complaintsModal"
import { statesAndThames } from "../../../../library/constants/statesAndThames"
import TextWithExternalURL from "../../../../components/externalURL/externalURL"
import LockOutlined from "@material-ui/icons/LockOutlined"

const _ = require("lodash")

const bookmarksRoutes = [
  routes.BOOKMARKS,
  routes.BOOKMARKS_POSTS,
  routes.FOLDER,
  routes.FOLDER_POSTS,
]

class Post extends Component {
  state = {
    type: this.props.type,

    textHidden: this.props.post.message.length > 500,
    showLess: false,
    messageFull: this.props.post.message,
    message:
      this.props.post.message.length > 500
        ? this.props.post.message.slice(0, 500).concat("...")
        : this.props.post.message,
    blobs: this.props.post.blobs,
    images: this.props.post.images,
    videos: this.props.post.videos,
    isLiked: this.props.post.is_liked,
    likesNumber: this.props.post.likes_number,
    canEdit: this.props.post.can_edit,
    canDelete: this.props.post.can_delete,
    isShared: !!this.props.post.origin_user,
    sharesNumber: this.props.post.shares_number,
    commentsNumber: this.props.post.comments_number,
    isPinned: this.props.post.pinned,
    isMine: this.props.post.creator_user
      ? (!!this.props.post.creator_user && this.props.post.can_delete) ||
        this.props.post.creator_user.id === this.props.auth.userData.id ||
        this.props.auth.userData.id === parseInt(this.props.match.params.userId)
      : this.props.auth.userData.id ===
        parseInt(this.props.match.params.userId),
    restore: false,

    updatePost: false,
    updatedMessage: "",
    updatedBlobs: _.cloneDeep(this.props.post.blobs),
    updatedImages: _.cloneDeep(this.props.post.images),
    updatedVideos: _.cloneDeep(this.props.post.videos),

    updatedAttachments: [],
    filesCount:
      this.props.post.blobs.length +
      this.props.post.images.length +
      this.props.post.videos.length,
    attachmentsThreshold: false,

    comments: this.props.post.comments.slice(0, 3),
    hasMoreComments: this.props.post.comments.length > 3,
    showAllComments: false,
    showCommentsField: false,

    openImageDialog: false,
    activeImage: "",
    is_shared: this.props.post.is_shared,
    is_commented: this.props.post.is_commented,

    bookmarked: !!this.props.post.bookmark,
    openAddToFolderDialog: false,
    hidden: false,
    complaintsModal: false,
    isReported: !!this.props.post.is_reported,
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }

  handleClickOpen = (type, url) => {
    switch (type) {
      case "addToFolder":
        this.setState({
          openAddToFolderDialog: true,
        })
        break

      case "image":
        this.setState({
          openImageDialog: true,
          activeImage: url,
        })
        break

      default:
        return
    }
  }

  handleClose = (type) => {
    switch (type) {
      case "addToFolder":
        this.setState({ openAddToFolderDialog: false })
        break

      case "image":
        this.setState({ openImageDialog: false })
        break

      default:
        return
    }
  }

  showFullText = () => {
    this.setState({
      textHidden: false,
      showLess: true,
      message: this.props.post.message,
    })
  }

  showLessText = () => {
    this.setState({
      textHidden: true,
      showLess: false,
      message: this.props.post.message.slice(0, 500).concat("..."),
    })
  }

  handleChange = (event) => {
    this.setState({
      updatedMessage: event.target.value,
    })
  }

  processLike = async (post_id) => {
    const { isLiked, likesNumber } = this.state

    try {
      if (!isLiked) {
        await this.setStateAsync({
          isLiked: true,
          likesNumber: likesNumber + 1,
        })
        await this.props.like({ type: "post", post_id: post_id })
      } else {
        await this.setStateAsync({
          isLiked: false,
          likesNumber: likesNumber - 1,
        })
        await this.props.unLike({ type: "post", post_id: post_id })
      }
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
      if (!isLiked) {
        await this.setStateAsync({ isLiked: false })
      } else {
        await this.setStateAsync({ isLiked: true })
      }
    }
  }

  processPin = async (id) => {
    const { id: userId } = this.props.auth.userData
    const { isPinned, type } = this.state
    const params = {
      type,
      offset: 0,
      limit: LIMIT,
    }
    type === "group"
      ? (params.group_id = this.props.groupInfo.id)
      : (params.user_id = userId)
    try {
      if (!isPinned) {
        await this.props.pinPost({ id })
        await this.setStateAsync({ isPinned: true })
        await this.props.updateData()
      } else {
        await this.props.unpinPost({ id })
        await this.setStateAsync({ isPinned: false })
        await this.props.updateData()
      }
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
      if (!isPinned) {
        await this.setStateAsync({ isPinned: false })
      } else {
        await this.setStateAsync({ isPinned: true })
      }
    }
  }

  addToBookmarks = async () => {
    const {
      post: { id },
    } = this.props

    try {
      await this.props.createBookmark({ post_id: id })
      await this.props.foldersList()
      this.setState({ bookmarked: true })
      await this.props.enqueueSnackbar("Saved to bookmarks", {
        autoHideDuration: 5000,
        action: (key) => {
          return (
            <>
              <Button
                color="primary"
                onClick={() => {
                  this.props.closeSnackbar(key)
                  this.props.history.push(routes.BOOKMARKS_POSTS)
                }}
              >
                View
              </Button>
              {this.props.bookmarks.folders.length > 0 && (
                <Button
                  color="primary"
                  onClick={() => this.handleClickOpen("addToFolder")}
                >
                  Add to folder
                </Button>
              )}
            </>
          )
        },
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  deleteBookmark = async () => {
    const { post } = this.props

    try {
      await this.props.deleteBookmark({ post_id: post.id })
      this.setState({
        bookmarked: false,
        hidden: bookmarksRoutes.includes(this.props.match.path),
      })
      this.props.enqueueSnackbar("Bookmark removed", { variant: "success" })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  processShare = async (id) => {
    const { sharesNumber } = this.state

    try {
      await this.props.sharePost({ post_id: id })
      await this.setStateAsync({ sharesNumber: sharesNumber + 1 })
      await this.props.updateData()
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  processDelete = async (id) => {
    try {
      await this.props.deletePost({ id })
      await this.setStateAsync({ restore: true })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  processRestore = async (id) => {
    try {
      await this.props.restorePost({ id })
      await this.setStateAsync({ restore: false })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  openEditMode = () => {
    this.setState({
      updatePost: true,
      updatedMessage: this.props.post.message,
      updatedAttachments: [],
    })
  }

  closeEditMode = async () => {
    this.setState({
      updatePost: false,
      updatedMessage: "",
      updatedAttachments: [],
      blobs: this.props.post.blobs,
      images: this.props.post.images,
      videos: this.props.post.videos,
    })
  }
  setAttachments = ({ videos, blobs, images }) => {
    this.setStateAsync({
      videos,
      blobs,
      images,
    })
  }

  processEdit = async (id) => {
    const { updatedMessage, blobs, images, videos } = this.state

    const attachments = [...blobs, ...images, ...videos].map(({ id }) => id)
    if (!updatedMessage.replace(/\s/g, "") && !attachments.length) return
    try {
      await this.props.updatePost({
        id,
        message: updatedMessage,
        attachments: [...attachments],
      })
      await this.props.updateData()
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  showAllComments = () => {
    this.setState({
      comments: this.props.post.comments,
      showAllComments: true,
    })
  }

  openCommentField = () => {
    this.setState({ showCommentsField: true })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.post !== prevProps.post) {
      this.setState({
        type: this.props.type,

        textHidden: this.props.post.message.length > 500,
        showLess: false,
        messageFull: this.props.post.message,
        message:
          this.props.post.message.length > 500
            ? this.props.post.message.slice(0, 500).concat("...")
            : this.props.post.message,
        blobs: this.props.post.blobs,
        images: this.props.post.images,
        videos: this.props.post.videos,
        isLiked: this.props.post.is_liked,
        likesNumber: this.props.post.likes_number,
        canEdit: this.props.post.can_edit,
        canDelete: this.props.post.can_delete,
        isShared: !!this.props.post.origin_user,
        sharesNumber: this.props.post.shares_number,
        commentsNumber: this.props.post.comments_number,
        isPinned: this.props.post.pinned,
        isMine: this.props.post.creator_user
          ? this.props.post.creator_user.id === this.props.auth.userData.id ||
            this.props.auth.userData.id ===
              Number(this.props.match.params.userId)
          : this.props.auth.userData.id ===
            Number(this.props.match.params.userId),
        restore: false,

        updatePost: false,
        updatedMessage: "",
        updatedBlobs: _.cloneDeep(this.props.post.blobs),
        updatedImages: _.cloneDeep(this.props.post.images),
        updatedVideos: _.cloneDeep(this.props.post.videos),

        updatedAttachments: [],
        filesCount:
          this.props.post.blobs.length +
          this.props.post.images.length +
          this.props.post.videos.length,
        attachmentsThreshold: false,

        comments: this.state.showAllComments
          ? this.props.post.comments
          : this.props.post.comments.slice(0, 3),
        hasMoreComments: this.props.post.comments.length > 3,
        showCommentsField: false,

        open: false,
        activeImage: "",
        is_shared: this.props.post.is_shared,
        is_commented: this.props.post.is_commented,
      })
    }
  }

  updateData = () => {
    this.props.updateData()
  }

  getPostDate = () => {
    const { post } = this.props
    const date = post.created
    return postDateConvert(date)
  }

  composeContentType = (product) => {
    const { content_type } = product
    return productContentTypes.filter((item) => {
      return item.id === content_type
    })[0]
  }

  getSharedLayout = (post) => {
    switch (parseInt(post.share_type)) {
      case 2:
        const sharedGroup = post.shared_group
        const groupUrl = generatePath(routes.GROUP, { groupId: sharedGroup.id })
        return (
          <div className="f aic full-width">
            <Link to={groupUrl} className="list-item__img">
              <img src={getGroupLogo(sharedGroup)} alt="group logo" />
            </Link>
            <div className="list-item__info">
              <Link to={groupUrl} className="link">
                <div className="list-item__name">{sharedGroup.name}</div>
              </Link>
              {sharedGroup.description !== "Unknown" && (
                <div className="break-word">
                  {sharedGroup.description.length > 120
                    ? sharedGroup.description.slice(0, 120) + "..."
                    : sharedGroup.description}
                </div>
              )}
            </div>
          </div>
        )
      case 3:
        // return <span>Content not available</span>
        const product = post.shared_product
        const creator = product.creator_user
          ? {
              name: `${product.creator_user.first_name} ${product.creator_user.last_name}`,
              url: generatePath(routes.USER, {
                userId: product.creator_user.id,
              }),
            }
          : product.creator_group
            ? {
                name: product.creator_group.name,
                url: generatePath(routes.GROUP, {
                  groupId: product.creator_group.id,
                }),
              }
            : {}
        this.composeContentType(product)
        return (
          <div className="shared_content">
            <Link
              to={generatePath(routes.PRODUCT, { productId: product.id })}
              className="product_link"
            >
              <img src={product.image || logoDefault} alt="Product image" />
            </Link>
            <div className="product-tile">
              <Link
                to={generatePath(routes.PRODUCT, { productId: product.id })}
                className="product-tile__name link"
              >
                {product.name}
              </Link>
              <table className="product-tile__info">
                <tbody>
                  <tr>
                    <td
                      className={`product-tile__info-label ${this.composeContentType(product) ? "pb4" : ""}`}
                    >
                      <span>Creator:</span>
                    </td>
                    <td
                      className={`product-tile__info-value ${this.composeContentType(product) ? "pb4" : ""}`}
                    >
                      <span>
                        <Link
                          to={creator.url || ""}
                          className="link font-weight-500"
                        >
                          {creator.name}
                        </Link>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="product-tile__info-label">
                      <span>
                        {this.composeContentType(product)
                          ? "Content type:"
                          : ""}
                        {!this.composeContentType(product) && (
                          <span style={{ height: "20px" }}></span>
                        )}
                      </span>
                    </td>
                    <td className="product-tile__info-value">
                      <span>
                        {this.composeContentType(product)
                          ? this.composeContentType(product).name
                          : ""}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>

              <StarRating rating={product.rating} />

              <div className="h-divider h-divider--vertical-margin"></div>
              {product.paid && (
                <>
                  {!!product.user_price && (
                    <div className="product-tile__price">
                      <span className="product-tile__price-label">
                        Price per user:
                      </span>
                      <span className="product-tile__price-value">
                        ${product.user_price}
                      </span>
                    </div>
                  )}
                  {!!product.group_price && (
                    <div className="product-tile__price">
                      <span className="product-tile__price-label">
                        Price per school:
                      </span>
                      <span className="product-tile__price-value">
                        ${product.group_price}
                      </span>
                    </div>
                  )}
                  {!!product.others_price && (
                    <div className="product-tile__price">
                      <span className="product-tile__price-label">
                        Other price:
                      </span>
                      <span className="product-tile__price-value">
                        ${product.others_price}
                      </span>
                    </div>
                  )}
                </>
              )}
              {product.paid &&
                product.user_price === 0 &&
                product.group_price === 0 &&
                product.others_price === 0 && (
                  <div className="product-tile__price free">
                    <span className="product-tile__price-value">Paid</span>
                  </div>
                )}
              {!product.paid && (
                <div className="product-tile__price free">
                  <span className="product-tile__price-value">Free</span>
                </div>
              )}
            </div>
          </div>
        )
      case 4:
        const knowCred = post.shared_knowcred
        const knowCredUrl = generatePath(routes.KNOWCRED_SINGLE, {
          knowCredId: knowCred.id,
        })
        const showSubtitleLabel = [3, 4]
        const hideActivity = [0, 1].includes(knowCred.activity)
        const isSubtypeLabel = showSubtitleLabel.includes(knowCred.type)
        const labelClass = isSubtypeLabel ? "knowcred-card__info-label--56" : ""
        const valueClass = isSubtypeLabel ? "knowcred-card__info-value--78" : ""
        const knowCredOwner = (
          <Link
            className="link"
            to={generatePath(routes.USER, { userId: knowCred.user.id })}
          >{`${knowCred.user.first_name} ${knowCred.user.last_name}`}</Link>
        )
        const knowCredCreator = (
          <Link
            className="link"
            to={generatePath(routes.USER, { userId: knowCred.creator.id })}
          >{`${knowCred.creator.first_name} ${knowCred.creator.last_name}`}</Link>
        )
        return (
          <div className="shared_content">
            <div className="knowcred-card__image">
              {!knowCred.public && (
                <div className="knowcred-card__lock-icon">
                  <LockOutlined className="color-primary" />
                </div>
              )}
              <Link to={knowCredUrl}>
                <img
                  src={knowCred.background_image}
                  alt="Credicon background"
                  className="knowcred-card__background"
                  style={{ borderColor: knowCred.border_color }}
                />
                <img
                  src={knowCred.foreground_image}
                  alt="Credicon foreground"
                  className="knowcred-card__foreground"
                />
              </Link>
            </div>
            <div className="knowcred-card__content">
              <h4>
                <Link to={knowCredUrl} className="link">
                  {knowCred.title}
                </Link>
              </h4>

              <table
                className="knowcred-card__info"
                style={{ display: "table" }}
              >
                <tbody>
                  <tr>
                    <td
                      className={`pb4 knowcred-card__info-label ${labelClass}`}
                    >
                      <span>Type:</span>
                    </td>
                    <td
                      className={`pb4 knowcred-card__info-value ${valueClass}`}
                    >
                      <span>{knowCredTypesIds[knowCred.type]}</span>
                    </td>
                  </tr>
                  {!hideActivity && (
                    <tr>
                      <td
                        className={`pb4 knowcred-card__info-label ${labelClass}`}
                      >
                        <span>
                          {showSubtitleLabel.includes(knowCred.type)
                            ? "Label"
                            : "Activity"}
                          :
                        </span>
                      </td>
                      <td
                        className={`pb4 knowcred-card__info-value ${valueClass}`}
                      >
                        <span>{knowCredActivitiesIds[knowCred.activity]}</span>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td
                      className={`pb4 knowcred-card__info-label ${labelClass}`}
                    >
                      <span>Owner:</span>
                    </td>
                    <td
                      className={`pb4 knowcred-card__info-value ${valueClass}`}
                    >
                      <span>{knowCredOwner}</span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={`pb4 knowcred-card__info-label ${labelClass}`}
                    >
                      <span>Creator:</span>
                    </td>
                    <td
                      className={`pb4 knowcred-card__info-value ${valueClass}`}
                    >
                      <span>{knowCredCreator}</span>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="knowcred-card__description">
                {knowCred.description.length > 80
                  ? knowCred.description.slice(0, 80) + "..."
                  : knowCred.description}
              </div>
            </div>
          </div>
        )
      case 5:
        const standard = post.shared_standard
        const state = statesAndThames[standard.state - 1]
        const subjects = state["subjects"]
        return (
          <div className="shared_content">
            <div className="product-tile">
              <Link
                className="product-tile__name link"
                to={generatePath(routes.STANDARD, { standardId: standard.id })}
              >
                {standard.name}
              </Link>
              <table className="product-tile__info">
                <tbody>
                  <tr>
                    <td className={`product-tile__info-label pb4`}>
                      <span>State:</span>
                    </td>
                    <td className={`product-tile__info-value pb4`}>
                      <span>{state.name}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="product-tile__info-label">
                      <span>Subject:</span>
                    </td>
                    <td className="product-tile__info-value">
                      <span>
                        {subjects.find((s) => s.id === standard.subject).name}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="product-tile__info-label">
                      <span>Grades:</span>
                    </td>
                    <td className="product-tile__info-value">
                      <span>
                        {standard.grades
                          .map(
                            (g) =>
                              standardsGrades.find((gr) => gr.id === g).name
                          )
                          .join(", ")}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <LinesEllipsis
                text={standard.description}
                maxLine="3"
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            </div>
          </div>
        )
      default:
        return <></>
    }
  }

  showComplaintsModal = () => {
    this.setState({ complaintsModal: true })
  }
  hideComplaintsModal = () => {
    this.setState({ complaintsModal: false })
  }

  render() {
    const {
      post,
      userInfo,
      groupInfo,
      auth: { userData },
      createdByGroup,
    } = this.props
    const {
      type,
      textHidden,
      showLess,
      message,
      blobs,
      images,
      videos,
      isLiked,
      likesNumber,
      isPinned,
      commentsNumber,
      isMine,
      canEdit,
      canDelete,
      sharesNumber,
      restore,
      updatedMessage,
      updatePost,
      comments,
      hasMoreComments,
      showAllComments,
      is_shared,
      is_commented,
      bookmarked,
    } = this.state

    if (this.state.hidden) return null

    const canSubmit = !!(
      updatedMessage.replace(/\s/g, "") !== "" ||
      blobs.length > 0 ||
      images.length > 0 ||
      videos.length > 0
    )

    const showAllBtn = !showAllComments && hasMoreComments

    const postClass =
      post.origin_user || post.origin_group ? "post--repost" : ""
    const locationClass =
      this.props.postLocation === "modal" ? "post--modal" : ""

    const postUrl =
      this.props.type === "group"
        ? generatePath(routes.GROUP_POST, {
            groupId: groupInfo.id,
            postId: post.id,
          })
        : generatePath(routes.USER_POST, {
            userId: userInfo.id,
            postId: post.id,
          })

    const creatorName = post.creator_user
      ? `${post.creator_user.first_name} ${post.creator_user.last_name}`
      : post.creator_group
        ? `${post.creator_group.name}`
        : `${userInfo.first_name} ${userInfo.last_name}`

    const groupLogo = () => {
      if (!post.creator_group) return null
      if (!post.creator_group.logo) {
        return getGroupLogo(post.creator_group)
      } else {
        return post.creator_group.logo
      }
    }

    const creatorPhoto = post.creator_user
      ? post.creator_user && post.creator_user.photo !== ""
        ? post.creator_user.photo
        : avatarDefault
      : groupLogo()

    const creatorUrl = post.creator_user
      ? generatePath(routes.USER, { userId: post.creator_user.id })
      : post.creator_group
        ? generatePath(routes.GROUP, { groupId: post.creator_group.id })
        : generatePath(routes.USER, { userId: userInfo.id })

    const originCreatorName = post.origin_user
      ? `${post.origin_user.first_name} ${post.origin_user.last_name}`
      : post.origin_group
        ? `${post.origin_group.name}`
        : ""

    const originCreatorPhoto = post.origin_user
      ? post.origin_user.photo && post.origin_user.photo !== ""
        ? post.origin_user.photo
        : avatarDefault
      : post.origin_group && getGroupLogo(post.origin_group)

    const originCreatorUrl = post.origin_user
      ? generatePath(routes.USER, { userId: post.origin_user.id })
      : post.origin_group
        ? generatePath(routes.GROUP, { groupId: post.origin_group.id })
        : ""

    const dropdownMenuItems = [
      canEdit &&
        !post.share_type &&
        !post.origin_post && {
          type: "button",
          action: this.openEditMode,
          label: "Edit post",
        },
      ((canDelete && type === "my-profile") ||
        (groupInfo && (groupInfo.is_admin || groupInfo.is_creator))) && {
        type: "button",
        action: () => this.processPin(post.id),
        label: !isPinned ? "Pin" : "Unpin",
      },
      !canEdit && {
        type: "button",
        action: this.showComplaintsModal,
        label: "Report",
        disabled: this.state.isReported,
      },
      !bookmarked
        ? {
            type: "button",
            action: this.addToBookmarks,
            label: "Add to bookmarks",
          }
        : {
            type: "button",
            action: this.deleteBookmark,
            label: "Remove from bookmarks",
          },
      canDelete && {
        type: "h-divider",
        id: "hd1",
      },
      canDelete && {
        type: "button",
        action: () => this.processDelete(post.id),
        label: "Delete post",
      },
    ]

    if (restore) {
      return (
        <div className="post">
          <div className="f jcc aic">
            <div>
              Post deleted.{" "}
              <span
                className="post__restore"
                onClick={() => this.processRestore(post.id)}
              >
                Undo.
              </span>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className={`post ${postClass} ${locationClass}`}>
          <ComplaintsModal
            open={this.state.complaintsModal}
            post_id={post.id}
            onClose={this.hideComplaintsModal}
            callback={() => {
              this.setState({ isReported: true })
              this.updateData()
            }}
          />
          <div className="post__header">
            <Link to={creatorUrl}>
              {post.creator_user && post.creator_user.deleted ? (
                <img
                  src={unavailableAvatar}
                  className="post__author-avatar"
                  alt="Author Name"
                />
              ) : post.creator_user && post.creator_user.id === userData.id ? (
                <UserAvatar className="post__author-avatar" />
              ) : creatorPhoto ? (
                <img
                  src={creatorPhoto}
                  alt="Author Name"
                  className="post__author-avatar"
                />
              ) : (
                <img
                  src={userInfo.photo || avatarDefault}
                  alt="Author Name"
                  className="post__author-avatar"
                />
              )}
            </Link>
            <div className="f fdc jcc fg1">
              <Link to={creatorUrl} className="asfs link">
                <span className="post__author-name">{creatorName}</span>
              </Link>
              <Link to={postUrl} className="post__date">
                {this.getPostDate()}
              </Link>
            </div>

            <div className="f aic">
              {isPinned && !bookmarksRoutes.includes(this.props.match.path) && (
                <div className="post__pin-indicator">
                  <img src={pinIcon} alt="" className="mr5" />{" "}
                  <span className="d-none-xs">Post pinned</span>
                </div>
              )}
              {!updatePost ? (
                <div className="post__menu">
                  {!bookmarksRoutes.includes(this.props.match.path) ? (
                    <MoreDropdown>
                      <VerticalMenu
                        menuItems={dropdownMenuItems}
                        classes={"more-dropdown-menu__list"}
                      />
                    </MoreDropdown>
                  ) : (
                    <BookmarkedItemDropdown
                      deleteBookmark={this.deleteBookmark}
                      bookmarkFoldersList={() =>
                        this.props.bookmarkFoldersList(post.bookmark.id)
                      }
                      addToFolder={(folders) =>
                        this.props.bindFolder({
                          bookmark_id: post.bookmark.id,
                          folders,
                        })
                      }
                      bookmarksFoldersListClear={
                        this.props.bookmarksFoldersListClear
                      }
                    />
                  )}
                </div>
              ) : null}
            </div>
          </div>
          <div className="post__content">
            {post.origin_user || post.origin_group ? (
              <div className="post__header post__header--repost">
                <Link to={originCreatorUrl}>
                  <img
                    src={originCreatorPhoto}
                    alt="Author Name"
                    className="post__author-avatar"
                  />
                </Link>
                <div className="f fdc jcc fg1">
                  <Link to={originCreatorUrl} className="asfs link">
                    <span className="post__author-name f aic">
                      <ReplyOutlined className="flip-h mr5" />{" "}
                      {originCreatorName}
                    </span>
                  </Link>
                  <div className="post__date">{this.getPostDate()}</div>
                </div>
              </div>
            ) : null}
            {!updatePost ? (
              <>
                {message.length ? (
                  <TextWithExternalURL text={message} />
                ) : // <Linkify tagName={"pre"} options={{className: "link"}}>
                //     {message}
                // </Linkify>
                null}
                {textHidden ? (
                  <span
                    className="post__show-more link"
                    onClick={() => this.showFullText()}
                  >
                    Show more...
                  </span>
                ) : null}
                {showLess ? (
                  <span
                    className="post__show-more link"
                    onClick={() => this.showLessText()}
                  >
                    Show less
                  </span>
                ) : null}
                {post.share_type ? this.getSharedLayout(post) : null}
                {images.length > 0 ? (
                  <div
                    className={`post__images ${
                      images.length === 1 ? "post__images--single" : ""
                    }`}
                  >
                    {images.map(({ id, url }) => {
                      return (
                        <img
                          src={url}
                          className="post__img"
                          alt="Image"
                          onClick={() => this.handleClickOpen("image", url)}
                          key={id}
                        />
                      )
                    })}
                  </div>
                ) : null}

                {blobs.length > 0 ? (
                  <div className="post__files">
                    {blobs.map(({ id, name, size, url }) => {
                      return (
                        <a
                          href={url}
                          target="_blank"
                          className="post__file"
                          key={id}
                        >
                          <InsertDriveFile
                            className="post__file-icon"
                            style={{ fontSize: "30px" }}
                          />
                          <div className="post__file-info">
                            <span className="link link--inline">{name}</span>
                            <span className="post__file-size">
                              {formatBytes(size)}
                            </span>
                          </div>
                        </a>
                      )
                    })}
                  </div>
                ) : null}

                {videos.length > 0 ? (
                  <div
                    className={`post__videos${
                      videos.length === 1 ? "--single" : ""
                    }`}
                  >
                    {videos.map(({ id, name, url }) => {
                      return (
                        <div key={id}>
                          <div className="post__video">
                            <video className="post__video-player" controls>
                              <source src={url} />
                            </video>
                            <span className="post__video-name">{name}</span>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                ) : null}
              </>
            ) : (
              <ValidatorForm onSubmit={() => this.processEdit(post.id)}>
                <div className="post__update">
                  <div className="post__update-input">
                    <TextValidator
                      placeholder="What’s happening?"
                      multiline
                      autoFocus
                      fullWidth
                      name="post"
                      value={updatedMessage}
                      validators={["maxStringLength:5000"]}
                      errorMessages={["Max length is 5000 characters"]}
                      onChange={this.handleChange}
                      margin="none"
                    />
                    <div className="post-create__add-attachment">
                      <label htmlFor={`image-${post.id}`}>
                        <PhotoOutlined className="post-create__btn-icon" />
                      </label>
                    </div>
                    <div className="post-create__add-attachment">
                      <label htmlFor={`attachment-${post.id}`}>
                        <AttachFileOutlined className="post-create__btn-icon" />
                      </label>
                    </div>
                  </div>
                  <div className="f jcfs aifs full-width mb10">
                    <div className="post-create__images">
                      <FileLoader
                        imageId={`image-${post.id}`}
                        attachmentId={`attachment-${post.id}`}
                        videos={videos}
                        images={images}
                        blobs={blobs}
                        onUpload={this.setAttachments}
                        token={userData.token}
                      />
                    </div>
                  </div>
                  <div className="post__update-controls">
                    <div className="btn-group">
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => this.closeEditMode()}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={!canSubmit}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </ValidatorForm>
            )}
          </div>
          <div className="post__footer">
            <div
              className="post__social-control"
              onClick={() => this.processLike(post.id)}
            >
              {isLiked ? (
                <FavouriteOutlined className="color-primary" />
              ) : (
                <FavouriteBorderedOutlined />
              )}
              {likesNumber > 0 ? <span>{likesNumber}</span> : null}
            </div>
            <div
              className="post__social-control"
              onClick={() => this.openCommentField()}
            >
              {is_commented ? (
                <ChatBubbleOutlineOutlined className="color-primary" />
              ) : (
                <ChatBubbleOutlineOutlined />
              )}
              {commentsNumber > 0 ? <span>{commentsNumber}</span> : null}
            </div>
            {!isMine &&
              !(post.origin_user && post.origin_user.id === userData.id) && (
                <div
                  className="post__social-control"
                  onClick={() => this.processShare(post.id, userData.id)}
                >
                  {is_shared ? (
                    <ReplyOutlined className="color-primary flip-h" />
                  ) : (
                    <ReplyOutlined className="flip-h" />
                  )}
                  {sharesNumber > 0 ? <span>{sharesNumber}</span> : null}
                </div>
              )}
          </div>
          <div className="h-divider h-divider--vertical-margin"></div>
          <CommentsList
            comments={comments}
            showAll={showAllBtn}
            showAllComments={() => this.showAllComments()}
            type={this.props.type}
            postId={post.id}
            updateData={(type) => this.updateData(type)}
            createdByGroup={createdByGroup}
            groupInfo={groupInfo}
          />

          <ImageModal
            url={this.state.activeImage}
            open={this.state.openImageDialog}
            handleClose={() => this.handleClose("image")}
          />

          {this.state.openAddToFolderDialog && (
            <AddToFolderModal
              open={this.state.openAddToFolderDialog}
              handleClose={() => this.handleClose("addToFolder")}
            />
          )}
        </div>
      )
    }
  }
}

const mapStateToProps = ({ auth, person, posts, bookmarks }) => ({
  auth,
  person,
  posts,
  bookmarks,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPostsList,
      like,
      unLike,
      createBookmark,
      sharePost,
      pinPost,
      unpinPost,
      getPost,
      deletePost,
      restorePost,
      updatePost,
      clearPostsList,
      deleteBookmark,
      bookmarkFoldersList,
      bookmarksFoldersListClear,
      bindFolder,
      foldersList,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(withRouter(Post))))
