import React, { useState } from "react"
import { useAlert } from "../../../../../hooks/useAlert"
import { useDispatch, useSelector } from "react-redux"
import {
  cancelAppointmentBooking,
  getOutgoingAppointmentBookings,
} from "../../../../../library/store/actions/creators/knowmapCreators"
import AppointmentBookingDialog from "./appointmentBookingDialog"
import DataTable from "../../../../../components/v2/dataTable/components"
import CloseOutlined from "@material-ui/icons/CloseOutlined"
import BoxPage from "../../../../../components/v2/layout/boxPage/boxPage"
import withErrorBoundary from "../../../../../hocs/guards/withErrorBoundary"

const columns = [
  { label: "Name", role: "name" },
  { label: "Invitees number", width: 80 },
  { label: "Invitees", width: 120 },
  { label: "Starts at", width: 100 },
  { label: "Duration", width: 80 },
  { label: "Link/Phone", width: 130 },
  { label: "Details", width: 250 },
  { label: "Manage", width: 110, role: "actions" },
]

const OutgoingBookings = () => {
  const { successAlert, errorAlert } = useAlert()

  const dispatch = useDispatch()
  const { outgoingBookings } = useSelector(({ knowmap }) => knowmap)

  const [isDetailsOpen, setIsDetailsOpen] = useState(false)
  const [selectedRecordId, setSelectedRecordId] = useState(null)
  const [bookingType, setBookingType] = React.useState(null)

  const onCancel = async ({ id }) => {
    try {
      await dispatch(cancelAppointmentBooking({ id }))
      successAlert("Booking canceled")
    } catch (e) {
      errorAlert(e)
    }
  }

  const onInfoOpen = (id, _type) => {
    setSelectedRecordId(id)
    setBookingType(_type)
    setIsDetailsOpen(true)
  }

  const onInfoClose = () => {
    setIsDetailsOpen(false)
    setBookingType(null)
    setSelectedRecordId(null)
  }

  return (
    <BoxPage title={"Outgoing Appointment Bookings"}>
      <DataTable
        columns={columns}
        data={outgoingBookings.list}
        isFirstColumnPinned
        actionsPinned
        customColumnsWidth
        onDetailsOpen={(record) => onInfoOpen(record.raw.id, record.raw._type)}
        isSearchable={false}
        actions={(record) =>
          [
            !record.accepted &&
              !record.declined && {
                type: "button",
                label: () => (
                  <>
                    <CloseOutlined />
                    <span>Cancel</span>
                  </>
                ),
                onClick: () =>
                  onCancel({
                    id: record.id,
                  }),
              },
          ].filter(Boolean)
        }
        getData={async ({ name, offset, limit }) => {
          await dispatch(
            getOutgoingAppointmentBookings({
              name: name || undefined,
              offset,
              limit,
            })
          )
        }}
        availableOffsets={outgoingBookings.availableOffsets}
        notFoundText={"You don't have outgoing bookings yet"}
      />

      {isDetailsOpen && (
        <AppointmentBookingDialog
          onClose={onInfoClose}
          open={isDetailsOpen}
          activeBookingId={selectedRecordId}
          bookingType={bookingType}
        />
      )}
    </BoxPage>
  )
}

export default withErrorBoundary(OutgoingBookings)
