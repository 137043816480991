import React from "react"
import styled from "@emotion/styled"
import { colors } from "../../../library/constants/styles/colors"
import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../library/constants/routes"
import avatarDefault from "../../../assets/img/user_avatar.svg"
import groupLogoDefault from "../../../assets/img/group-logo-default.png"
import productLogoDefault from "../../../assets/img/product.svg"
import { KeyboardArrowRight } from "@material-ui/icons"
import PlainLink from "../link/plainLink"
import { getGroupLogo } from "../../../library/utils/getGroupLogo"

const Container = styled.div`
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 2px;
  background-color: ${colors.white};
  box-shadow: 0 0 2px 0 rgba(210, 210, 210, 0.5);

  @supports (display: grid) {
    margin-bottom: 0;
  }
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const Title = styled.div``

const Counter = styled.span`
  color: ${colors.black54};
`

const List = styled.div`
  display: flex;
  padding: 8px 0;

  @supports (display: grid) {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(
      ${(props) => (props.itemType === "rounded" ? 9 : 5)},
      1fr
    );
  }
`

const ListItem = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${(props) => (props.itemType === "rounded" ? "66px" : "127px")};
  margin-right: 10px;

  @supports (display: grid) {
    width: auto;
    margin-right: 0;
  }
`

const Image = styled.img`
  width: ${(props) => (props.itemType === "rounded" ? "63px" : "127px")};
  height: ${(props) => (props.itemType === "rounded" ? "63px" : "127px")};
  margin-bottom: 8px;
  object-fit: cover;
  object-position: center;
  border-radius: ${(props) => (props.itemType === "rounded" ? "50%" : "0")};
`

const Credicon = styled.div`
  position: relative;
  margin-bottom: 8px;
`

const CrediconBackground = styled.img`
  width: 127px;
  height: 127px;
  object-fit: cover;
  object-position: center;
  border-width: 5px;
  border-style: solid;
  border-color: ${(props) => props.borderColor};
`

const CrediconForeground = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 30px);
  max-height: calc(100% - 30px);
`

const Name = styled.div`
  width: ${(props) => (props.itemType === "rounded" ? "63px" : "127px")};
  font-size: ${(props) => (props.itemType === "rounded" ? "13px" : "1rem")};
  color: ${colors.primary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const LinkContainer = styled.div`
  display: flex;
  width: 100%;
`

const HorizontalList = ({
  entity,
  itemType = "default",
  list,
  title,
  counter,
  link,
}) => {
  return (
    <Container>
      <TitleContainer>
        {/*<Link to={link}><Title>{title} {!!counter && <Counter>{counter}</Counter>}</Title></Link>*/}
        <Link to={link}>
          <Title>{title}</Title>
        </Link>
        <div className="d-block-xs">
          <PlainLink to={link}>
            Show all <KeyboardArrowRight />
          </PlainLink>
        </div>
      </TitleContainer>
      <div className="d-none-xs">
        <List itemType={itemType}>
          {list.map((item) => {
            const name =
              entity === "users"
                ? `${item.first_name} ${item.last_name}`
                : item.name || item.title

            const image = () => {
              switch (entity) {
                case "users":
                  return item.photo || avatarDefault

                case "groups":
                  return getGroupLogo(item)

                case "products":
                  return item.image || productLogoDefault

                case "records":
                  return item.image || productLogoDefault

                default:
                  return groupLogoDefault
              }
            }

            const url = () => {
              switch (entity) {
                case "users":
                  return generatePath(routes.USER, { userId: item.id })

                case "groups":
                  return generatePath(routes.GROUP, { groupId: item.id })

                case "products":
                  return generatePath(routes.PRODUCT, { productId: item.id })

                case "knowcred":
                  return generatePath(routes.KNOWCRED_SINGLE, {
                    knowCredId: item.id,
                  })

                case "records":
                  return generatePath(
                    item.user_id ? routes.INVENSTORY : routes.INVENSTORY_GROUPS
                  )

                default:
                  return "#"
              }
            }
            return (
              <ListItem to={url()} itemType={itemType} key={item.id}>
                {entity !== "knowcred" ? (
                  <Image src={image()} itemType={itemType} />
                ) : (
                  <Credicon>
                    <CrediconBackground
                      src={item.background_image}
                      borderColor={item.border_color}
                    />
                    <CrediconForeground src={item.foreground_image} />
                  </Credicon>
                )}
                <Name itemType={itemType}>{name}</Name>
              </ListItem>
            )
          })}
        </List>
      </div>
      <div className="d-none-xs">
        <LinkContainer>
          {(itemType === "rounded" ? counter > 9 : counter > 5) && (
            <PlainLink to={link}>
              Show all <KeyboardArrowRight />
            </PlainLink>
          )}
        </LinkContainer>
      </div>
    </Container>
  )
}

export default HorizontalList
