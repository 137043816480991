import React from "react"
import BoxPage from "../../../../../components/v2/layout/boxPage/boxPage"
import { useIdParam } from "../../../../../hooks/useIdParam"
import { useCourse } from "../../../../../hooks/data/courses/courses"
import { getSubjectName } from "../../../../../library/utils/educational"
import { minutesToString } from "../../../../../library/utils/timeConvert"
import { generatePath } from "react-router-dom"
import * as routes from "../../../../../library/constants/routes"
import { getUserName } from "../../../../../library/utils/user/userUtils"
import InfoTable from "../../../../../components/v2/utils/infoTable"
import { meanBy, sumBy } from "lodash"
import { GROUP, USER } from "../../../../../library/constants/routes"
import UserLink from "../../../../../components/v2/utils/UserLink"
import GroupLink from "../../../../../components/v2/utils/GroupLink"

const CourseMainInfo = () => {
  const knowmixId = useIdParam("knowmixId")
  const { course, canEdit, isAvailable } = useCourse()

  const creatorName = !!course?.creator_user
    ? getUserName(course?.creator_user)
    : course?.creator_group?.name

  const creatorUrl = !!course?.creator_user
    ? generatePath(USER, { userId: course?.creator_user?.id })
    : generatePath(GROUP, { groupId: course?.creator_group?.id })

  const averageStepsTime = Math.round(
    meanBy(course?.steps, (step) => step.time)
  )
  const totalStepsTime = sumBy(course?.steps, (step) => step.time)

  const creatorLinkComponent = !!course?.creator_user ? (
    <UserLink user={course?.creator_user} />
  ) : !!course?.creator_group ? (
    <GroupLink group={course?.creator_group} />
  ) : null

  return (
    <BoxPage title={course?.name}>
      <InfoTable
        data={[
          {
            label: "Name",
            text: course?.name,
          },
          {
            label: "Creator",
            component: creatorLinkComponent,
          },
          {
            label: "Subject",
            text: getSubjectName(course?.subject),
          },
          {
            label: "Info",
            text: course?.instructions,
          },
          {
            label: "Goal",
            text: course?.goal,
          },
          {
            label: "Estimated total days on this course",
            text: course?.learning_days_number,
          },
          canEdit && {
            label: "Measurement",
            text: course?.measurement,
          },
          !!course?.links?.length && {
            label: "Links",
            linkTitleKey: "title",
            links: course?.links,
            newTab: true,
            external: true,
          },
          !!course?.blobs?.length && {
            label: "Attachments",
            files: course?.blobs,
          },
          !!course?.steps.length && {
            label: "Number of steps",
            text: course?.steps.length,
          },
          !!course?.steps.length &&
            !!averageStepsTime && {
              label: "Average time to complete each step",
              text: minutesToString(averageStepsTime),
            },
          !!course?.steps.length &&
            !!totalStepsTime && {
              label: "Total study time",
              text: minutesToString(totalStepsTime),
            },
          !!course?.teacher && {
            label: "Teacher",
            component: <UserLink user={course?.teacher} />,
          },
        ]}
      />
    </BoxPage>
  )
}

export default CourseMainInfo
