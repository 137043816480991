import React, { useEffect, useState } from "react"
import { useCourse } from "../../../../../hooks/data/courses/courses"
import { Box } from "../../../../../components/tw/layout"
import CourseStep from "./CourseStep"
import { useLocation } from "react-router-dom"
import * as qs from "query-string"

const CourseSteps = () => {
  const { course, canEdit } = useCourse()

  const location = useLocation()
  const [activeStep, setActiveStep] = useState(
    course?.steps?.length ? course?.steps[0].id : null
  )
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const { stepId } = qs.parse(location.search)
    if (stepId) setActiveStep(parseInt(stepId, 10))
    setIsLoading(false)
  }, [])

  return (
    !isLoading && (
      <Box>
        <h3 className={"mb-4"}>Course program</h3>
        {course?.steps?.length ? (
          course?.steps?.map((step, i) => {
            const isUnlocked =
              i === 0 ||
              (course?.steps[i - 1]?.type === 3 &&
                !!course?.steps[i - 1]?.grade)
            return (
              <CourseStep
                key={step.id}
                step={step}
                isInitiallyOpen={step.id === activeStep}
                isUnlocked={isUnlocked}
                stepIdx={i}
              />
            )
          })
        ) : (
          <p className="text-black54 text-center">
            Course doesn't have program yet
          </p>
        )}
      </Box>
    )
  )
}

export default CourseSteps
