import React, { useEffect, useState } from "react"
import Loader from "../../../../../components/ui/loader/loader"
import { generatePath, Link, Redirect, withRouter } from "react-router-dom"
import * as routes from "../../../../../library/constants/routes"
import logoDefault from "../../../../../assets/img/product.svg"
import { bindActionCreators } from "redux"
import {
  clearCurrentKnowmix,
  getKnowmix,
} from "../../../../../library/store/actions/creators/knowmixCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import getErrorText from "../../../../../library/constants/errorTypes"
import styled from "@emotion/styled"
import { colors } from "../../../../../library/constants/styles/colors"
import CheckOutlined from "@material-ui/icons/CheckOutlined"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp"
import PlainLink from "../../../../../components/ui/link/plainLink"
import * as qs from "query-string"
import SEO from "../../../../../components/SEO"

const Container = styled.div`
  padding: 15px;
  border-radius: 2px;
  background-color: ${colors.white};
  box-shadow: 0 0 2px 0 ${colors.black12};
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`

const Status = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.black54};

  span {
    margin-right: 15px;
  }

  svg:first-of-type {
    margin-right: 15px;
    color: ${colors.primary};
  }
`

const Name = styled.span`
  max-width: 480px;
`

const Content = styled.div`
  padding-top: 15px;
`

const KnowmixGrades = (props) => {
  const [dataReceived, setDataReceived] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [activeStep, setActiveStep] = useState(null)

  useEffect(() => {
    getKnowmix()

    return () => props.clearCurrentKnowmix()
  }, [])

  const getKnowmix = async () => {
    const id = Number(props.match.params.knowmixId)

    const { stepId } = qs.parse(props.location.search)

    try {
      await props.getKnowmix({ id })
      if (stepId) setActiveStep(parseInt(stepId, 10))
      setDataReceived(true)
    } catch ({ error }) {
      if (error.code === 23000) {
        setRedirect(true)
      } else {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    }
  }

  if (
    redirect ||
    (dataReceived &&
      (props.knowmix.currentKnowmix.blocked ||
        props.knowmix.currentKnowmix.deleted) &&
      !props.knowmix.currentKnowmix)
  ) {
    return (
      <Redirect
        to={generatePath(routes.KNOWMIX, {
          knowmixId: props.match.params.knowmixId,
        })}
      />
    )
  }

  return (
    <div className="common-page__content">
      <SEO title={"Course Grades"} />
      <main className="common-page__main">
        {dataReceived ? (
          <>
            <div className="box">
              <div className="box__heading">
                <h1>{props.knowmix.currentKnowmix.name}</h1>
              </div>
              <div className="box__content">
                <p className="box__subheading">Grades for steps</p>
                {props.knowmix.currentKnowmix.steps.map((step, index) => {
                  return (
                    <Step
                      step={step}
                      stepNumber={index + 1}
                      isActive={step.id === activeStep}
                      key={step.id}
                    />
                  )
                })}
              </div>
            </div>

            <div className="box">
              <div className="box__content">
                <p className="box__subheading">Grades for Course</p>

                {!!props.knowmix.currentKnowmix.grade ? (
                  <table className="product-details product-details--kx">
                    <tbody>
                      {!!props.knowmix.currentKnowmix.grade && (
                        <tr className="product-details__row">
                          <td className="product-details__label">Grade:</td>
                          <td className="product-details__value">
                            {props.knowmix.currentKnowmix.grade}
                          </td>
                        </tr>
                      )}
                      {!!props.knowmix.currentKnowmix.comment && (
                        <tr className="product-details__row">
                          <td className="product-details__label">Comment:</td>
                          <td className="product-details__value">
                            {props.knowmix.currentKnowmix.comment}
                          </td>
                        </tr>
                      )}
                      {!!props.knowmix.currentKnowmix.examiner && (
                        <tr className="product-details__row">
                          <td className="product-details__label">Examiner:</td>
                          <td className="product-details__value">
                            <PlainLink
                              to={generatePath(routes.USER, {
                                userId:
                                  props.knowmix.currentKnowmix.examiner.id,
                              })}
                            >
                              {`${props.knowmix.currentKnowmix.examiner.first_name} ${props.knowmix.currentKnowmix.examiner.last_name}`}
                            </PlainLink>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                ) : (
                  <p className={"color-black-54"}>
                    This KnowMix is not graded by teacher yet
                  </p>
                )}
              </div>
            </div>
            {!!props.knowmix.currentKnowmix.knowcred &&
              !!props.knowmix.currentKnowmix.knowcred.name && (
                <div className="box">
                  <div className="box__content">
                    <p className="box__subheading">KnowCred for KnowMix</p>

                    <table className="product-details product-details--kx">
                      <tbody>
                        {!!props.knowmix.currentKnowmix.knowcred.name && (
                          <tr className="product-details__row">
                            <td className="product-details__label">
                              KnowCred:
                            </td>
                            <td className="product-details__value">
                              <PlainLink
                                to={generatePath(routes.KNOWCRED_SINGLE, {
                                  knowCredId:
                                    props.knowmix.currentKnowmix.knowcred.id,
                                })}
                              >
                                {props.knowmix.currentKnowmix.knowcred.name}
                              </PlainLink>
                            </td>
                          </tr>
                        )}
                        {/*{!!props.knowmix.currentKnowmix.knowcred.creator && (*/}
                        {/*<tr className="product-details__row">*/}
                        {/*<td className="product-details__label">Examiner:</td>*/}
                        {/*<td className="product-details__value">*/}
                        {/*<PlainLink to={generatePath(routes.USER, {userId: props.knowmix.currentKnowmix.knowcred.creator.id})}>*/}
                        {/*{`${props.knowmix.currentKnowmix.knowcred.creator.first_name} ${props.knowmix.currentKnowmix.knowcred.creator.last_name}`}*/}
                        {/*</PlainLink>*/}
                        {/*</td>*/}
                        {/*</tr>*/}
                        {/*)}*/}
                        {!!props.knowmix.currentKnowmix.examiner && (
                          <tr className="product-details__row">
                            <td className="product-details__label">
                              Examiner:
                            </td>
                            <td className="product-details__value">
                              <PlainLink
                                to={generatePath(routes.USER, {
                                  userId:
                                    props.knowmix.currentKnowmix.examiner.id,
                                })}
                              >
                                {`${props.knowmix.currentKnowmix.examiner.first_name} ${props.knowmix.currentKnowmix.examiner.last_name}`}
                              </PlainLink>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
          </>
        ) : (
          <Loader />
        )}
      </main>
      <aside className="common-page__sidebar">
        {dataReceived && (
          <div className="box aside-header">
            <Link
              to={generatePath(routes.KNOWMIX, {
                knowmixId: props.knowmix.currentKnowmix.id,
              })}
              className="box__content aside-header__content"
            >
              <img
                src={props.knowmix.currentKnowmix.photo || logoDefault}
                className="aside-header__img"
                alt=""
              />
              <div className="aside-header__info">
                <div className="aside-header__name">
                  {props.knowmix.currentKnowmix.name}
                </div>
                <div className="aside-header__help">back to KnowMix</div>
              </div>
            </Link>
          </div>
        )}
      </aside>
    </div>
  )
}

const Step = ({ step, stepNumber, isActive = false }) => {
  const [open, setOpen] = useState(isActive)

  const toggle = () => {
    setOpen(!open)
  }

  return (
    <Container>
      <Header onClick={toggle}>
        <Name>
          Step {stepNumber} - {step.name}
        </Name>
        <Status>
          {step.type === 3 && !!step.grade && <CheckOutlined />}

          {!step.grade && (
            <span>
              {step.type === 3 && "Completed"}
              {step.type === 2 && "Not completed"}
              {step.type === 1 && "Not started"}
            </span>
          )}
          {!open ? (
            <KeyboardArrowDown className="color-black-38" />
          ) : (
            <KeyboardArrowUp className="color-black-38" />
          )}
        </Status>
      </Header>
      {open && (
        <Content>
          <table className="product-details product-details--kx mb15">
            <tbody>
              <tr className="product-details__row">
                <td className="product-details__label">Name:</td>
                <td className="product-details__value">{step.name}</td>
              </tr>
              {step.type === 3 && !!step.grade && (
                <>
                  {!!step.grade && (
                    <tr className="product-details__row">
                      <td className="product-details__label">Grade:</td>
                      <td className="product-details__value">{step.grade}</td>
                    </tr>
                  )}
                  {!!step.comment && (
                    <tr className="product-details__row">
                      <td className="product-details__label">Comment:</td>
                      <td className="product-details__value">{step.comment}</td>
                    </tr>
                  )}
                  {!!step.examiner && (
                    <tr className="product-details__row">
                      <td className="product-details__label">Examiner:</td>
                      <td className="product-details__value">
                        <PlainLink
                          to={generatePath(routes.USER, {
                            userId: step.examiner.id,
                          })}
                        >
                          {`${step.examiner.first_name} ${step.examiner.last_name}`}
                        </PlainLink>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>

          {/*{step.type !== 3 || !step.grade && (*/}
          <span className="color-black-54">
            {step.type === 3 && !step.grade && "Step has not been graded yet"}
            {step.type === 2 && "Step has not been completed yet"}
            {step.type === 1 && "Step has not been started yet"}
          </span>
          {/*)}*/}
        </Content>
      )}
    </Container>
  )
}

const mapStateToProps = ({ auth, knowmix, standards }) => ({
  auth,
  knowmix,
  standards,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getKnowmix,
      clearCurrentKnowmix,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(KnowmixGrades)))
