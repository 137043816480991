import React from "react"
import ListPageHeader from "../listPage/listPageHeader/listPageHeader"
import Loader from "../../../ui/loader"
import * as qs from "query-string"
import AlertDialog from "../../../ui/alertDialog"
import withErrorBoundary from "../../../../hocs/guards/withErrorBoundary"
import { useInfiniteQuery } from "@tanstack/react-query"
import { call } from "../../../../library/networking/API"
import { LIMIT } from "../../../../library/constants/limits"
import { useInView } from "react-intersection-observer"
import GridList from "../../../ui/grid"
import { useLocation } from "react-router-dom"
import { clsx } from "clsx"

const GridListPage = ({
  queryConfig = {
    key: [null],
    fetchParams: {},
    dataKey: null,
    url: null,
    limit: LIMIT,
    options: {},
    searchParams: [],
    searchByNameParam: "name",
  },
  Header,
  headerProps = {
    title: "Page",
    button: {
      label: "Button",
      action: null,
      show: true,
    },
  },
  noResultsLabel = "Result not found",
  getListItemComponent,
  getDeletionDialogComponent,
  getInfoDialogComponent,
  getFormDialogComponent,
  columns = 4,
  gridClassName,
}) => {
  const { search } = useLocation()
  const filtersParsed = qs.parse(search, {
    parseBooleans: true,
    arrayFormat: "comma",
    parseNumbers: true,
  })

  const searchParams = {
    [queryConfig?.searchByNameParam]:
      filtersParsed.name || filtersParsed.q || undefined,
    ...queryConfig?.searchParams?.reduce((prev, item) => {
      return {
        ...prev,
        [item.param]: filtersParsed[item.param]
          ? item.transformer(filtersParsed[item.param])
          : undefined,
      }
    }, {}),
  }

  const [isSearching, setIsSearching] = React.useState(false)

  const query = useInfiniteQuery({
    queryKey: queryConfig.key,
    queryFn: async (ctx) => {
      const response = await call(ctx.queryKey[0], {
        offset: ctx.pageParam || 0,
        limit: queryConfig.limit,
        ...searchParams,
        ...queryConfig.fetchParams,
      })

      return response.data?.[queryConfig.dataKey] || []
    },
    getNextPageParam: (prevPage, allPages) => {
      if (prevPage.length === queryConfig.limit)
        return allPages.length * queryConfig.limit
    },
    ...queryConfig.options,
  })

  const onScroll = () => {
    if (query.hasNextPage && !query.isFetchingNextPage) {
      query.fetchNextPage()
    }
  }

  const { ref } = useInView({
    threshold: 0.8,
    onChange: (inView) => {
      if (inView) onScroll()
    },
  })

  React.useEffect(() => {
    if (query.status === "success") {
      ;(async () => {
        setIsSearching(true)
        await query.refetch({})
        setIsSearching(false)
      })()
    }
  }, [search])

  const allItems = query.data ? query.data.pages.flat() : []

  const [formDialogState, setFormDialogState] = React.useState({
    open: false,
    activeId: null,
  })
  const [infoDialogState, setInfoDialogState] = React.useState({
    open: false,
    activeId: null,
  })
  const [deletionDialogState, setDeletionDialogState] = React.useState({
    open: false,
    activeId: null,
  })

  const openFormDialog = (id = null) => {
    setFormDialogState({
      activeId: id,
      open: true,
    })
  }

  const closeFormDialog = () => {
    setFormDialogState({
      activeId: null,
      open: false,
    })
  }

  const openInfoDialog = (id) => {
    setInfoDialogState({
      activeId: id,
      open: true,
    })
  }

  const closeInfoDialog = () => {
    setInfoDialogState({
      activeId: null,
      open: false,
    })
  }

  const openDeletionDialog = (id) => {
    setDeletionDialogState({
      activeId: id,
      open: true,
    })
  }

  const closeDeletionDialog = () => {
    setDeletionDialogState({
      activeId: null,
      open: false,
    })
  }

  const composeDeletionDialog = () => {
    if (!getDeletionDialogComponent) return null

    const hasDeletionConfig = !!(
      getDeletionDialogComponent?.entityName &&
      getDeletionDialogComponent?.onDelete
    )

    const hasDeletionComponent = !!getDeletionDialogComponent.component

    if (hasDeletionConfig) {
      return (
        <AlertDialog
          open={deletionDialogState.open}
          handleClose={closeDeletionDialog}
          handleAccept={async () => {
            await getDeletionDialogComponent.onDelete(
              deletionDialogState.activeId
            )
            closeDeletionDialog()
          }}
          title={`Delete this ${getDeletionDialogComponent.entityName}?`}
          message={`If you delete this ${getDeletionDialogComponent.entityName} you will not be able to restore it`}
        />
      )
    }

    if (hasDeletionComponent) {
      return getDeletionDialogComponent.component({
        open: deletionDialogState.open,
        onClose: closeDeletionDialog,
        activeItem: deletionDialogState.activeId,
      })
    }

    return null
  }

  return (
    <>
      {Header ? (
        <Header />
      ) : (
        <ListPageHeader
          title={headerProps.title}
          buttonTitle={headerProps.button?.label}
          buttonAction={headerProps.button?.action || (() => openFormDialog())}
          hasButton={headerProps.button?.show}
          noResults={query.isSuccess && !query.hasNextPage && !allItems.length}
          noResultsLabel={
            searchParams[queryConfig?.searchByNameParam] ? "" : noResultsLabel
          }
          // filter={{
          //   ...filter,
          //   onChange: this.onFilterChange,
          //   value: this.state.filterId,
          // }}
        />
      )}

      {query.isInitialLoading || isSearching ? (
        <Loader />
      ) : query.isError ? (
        <div className={"text-center"}>
          <p className="color-black-54 mb10">Something went wrong</p>
          <p style={{ fontSize: 30, lineHeight: "30px" }}>&#128533;</p>
        </div>
      ) : (
        <div className={clsx("grid gap-4", gridClassName)}>
          {allItems.map((item, index) => {
            return getListItemComponent?.({
              item,
              openDeletionDialog: () => openDeletionDialog(item.id),
              closeDeletionDialog: closeDeletionDialog,
              openInfoDialog: () => openInfoDialog(item.id),
              closeInfoDialog: closeInfoDialog,
              openEditDialog: () => openFormDialog(item.id),
              closeEditDialog: closeFormDialog,
              // filterId: this.state.filterId || undefined,
              key: item.id,
            })
          })}
          <div style={{ gridColumn: "1/-1" }} ref={ref}>
            {query.isFetchingNextPage && <Loader />}
            {/*{!query.hasNextPage && !allItems.length && (*/}
            {/*  <p className={"color-black-54"}>Your feed is empty</p>*/}
            {/*)}*/}
          </div>
        </div>
      )}

      {deletionDialogState.open && composeDeletionDialog()}

      {infoDialogState.open &&
        !!getInfoDialogComponent &&
        getInfoDialogComponent({
          open: infoDialogState.open,
          onClose: closeInfoDialog,
          activeItem: infoDialogState.activeId,
        })}

      {formDialogState.open &&
        !!getFormDialogComponent &&
        getFormDialogComponent({
          open: formDialogState.open,
          onClose: closeFormDialog,
          activeItem: formDialogState.activeId,
          // filterId: this.state.filterId || undefined,
        })}
    </>
  )
}

export default withErrorBoundary(GridListPage)
