import React from "react"
import "./interlocutorListItem.scss"
import * as routes from "../../../../../library/constants/routes"
import { Link } from "react-router-dom"
import avatarDefault from "../../../../../assets/img/user_avatar.svg"

const InterlocutorListItem = ({ user }) => {
  const avatar = user.photo ? user.photo : avatarDefault
  const fullName = `${user.first_name} ${user.last_name}`

  return (
    <Link
      to={{
        pathname: routes.CONVERSATION,
        search: `?userId=${user.id}`,
      }}
      className="box interlocutor-list-item"
    >
      <div className="interlocutor-list-item__img">
        <img src={avatar} alt="User" />
      </div>
      <div className="interlocutor-list-item__name">
        <span className="list-item__name">
          {fullName.length > 50 ? fullName.slice(0, 50) + "..." : fullName}
        </span>
      </div>
    </Link>
  )
}

export default InterlocutorListItem
