import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { productReviewsList } from "../../../../../../library/store/actions/creators/productsCreators"
import ProductReview from "../productReview/productReview"
import { withRouter } from "react-router-dom"

class ProductReviewsList extends Component {
  state = {
    list: this.props.products.reviews,
    dataReceived: false,
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.products.currentProduct.reviews !==
      prevProps.products.currentProduct.reviews
    ) {
      this.setState({
        list: this.props.products.currentProduct.reviews,
        dataReceived: true,
      })
    }
  }

  render() {
    const { list } = this.state
    return (
      <div>
        {this.props.products.currentProduct.reviews.map((review, i) => (
          <ProductReview
            review={review}
            isLast={i + 1 === list.length}
            key={review.id}
          />
        ))}
      </div>
    )
  }
}

const mapStateToProps = ({ auth, products }) => ({ auth, products })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ productReviewsList }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(ProductReviewsList)))
