import React, { Component } from "react"
import ListSearchHeader from "../../../../../../../components/ui/listSearchHeader/listSearchHeader"
import Loader from "../../../../../../../components/ui/loader/loader"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import {
  addProductToLibrary,
  clearGroupAttachmentProducts,
  getProductsLibrary,
  productsLibrarySearch,
  removeProductFromLibrary,
  updateGroup,
  updateGroupAttachmentsProducts,
} from "../../../../../../../library/store/actions/creators/groupsCreators"
import * as qs from "query-string"
import { LIMIT } from "../../../../../../../library/constants/limits"
import withScroll from "../../../../../../../hocs/withScroll/withScroll"
import { generatePath, Link, withRouter } from "react-router-dom"
import { productContentTypes } from "../../../../../../../library/constants/productDictionary"
import * as routes from "../../../../../../../library/constants/routes"
import logoDefault from "../../../../../../../assets/img/product.svg"
import StarRating from "../../../../../../../components/ui/starRating"
import DropdownControl from "../../../../../../../components/ui/dropdowns/dropdownControl"
import MenuList from "@material-ui/core/MenuList"
import { withStyles } from "@material-ui/core"
import { colors } from "../../../../../../../library/constants/styles/colors"
import MenuItem from "@material-ui/core/MenuItem"
import Button from "@material-ui/core/Button"
import getErrorText from "../../../../../../../library/constants/errorTypes"

const CustomMenuItem = withStyles({
  root: {
    color: colors.primary,
  },
})(MenuItem)

class GroupsSettingsAttachmentsProducts extends Component {
  state = {
    list: [],
    dataReceived: false,
  }

  async componentDidMount() {
    const {
      location: { search },
    } = this.props
    const { q: query } = qs.parse(search)
    try {
      await this.props.productsLibrarySearch({
        name: query || undefined,
        group_id: this.props.groups.currentGroup.id,
      })
      await this.props.getProductsLibrary({
        group_id: this.props.groups.currentGroup.id,
      })
      this.setState({
        list: this.props.groups.attachmentProductList,
        dataReceived: true,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  composeContentType = (product) => {
    const { content_type } = product
    return productContentTypes.find((item) => {
      return item.id === content_type
    })
  }

  searchProducts = async (name) => {
    const { history } = this.props

    history.push({
      search: `?q=${name}`,
    })
    this.setState({ dataReceived: false, list: [] })
    this.setState({ dataReceived: false, list: [] })
    await this.props.updateGroupAttachmentsProducts({
      name: name || undefined,
      group_id: this.props.groups.currentGroup.id,
    })
    this.setState({
      dataReceived: true,
      list: this.props.groups.attachmentProductList,
    })
  }

  onScroll = async () => {
    if (!this.state.dataReceived) return
    const {
      match: { params },
      location: { search },
    } = this.props
    const { q: query } = qs.parse(search)
    this.setState({ dataReceived: false })
    await this.props.productsLibrarySearch({
      name: query || undefined,
      group_id: this.props.groups.currentGroup.id,
      offset: this.state.list.length,
      limit: LIMIT,
    })
    this.setState({
      dataReceived: true,
      list: this.props.groups.attachmentProductList,
    })
  }

  componentWillUnmount() {
    this.props.clearGroupAttachmentProducts()
  }

  addToLibrary = async (product_id) => {
    const { groups } = this.props
    const group_id = groups.currentGroup.id
    const {
      location: { search },
    } = this.props
    const { q: query } = qs.parse(search)
    try {
      await this.props.addProductToLibrary({ product_id, group_id })
      await this.props.updateGroupAttachmentsProducts({
        group_id,
        name: query || undefined,
        limit: this.state.list.length,
      })
      await this.props.getProductsLibrary({
        group_id: this.props.groups.currentGroup.id,
      })
      this.setState({ list: this.props.groups.attachmentProductList })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }
  deleteProductFromLibrary = async (product_id) => {
    const { groups } = this.props
    const group_id = groups.currentGroup.id
    const {
      location: { search },
    } = this.props
    const { q: query } = qs.parse(search)
    try {
      await this.props.removeProductFromLibrary({ product_id, group_id })
      await this.props.updateGroupAttachmentsProducts({
        group_id,
        name: query || undefined,
        limit: this.state.list.length,
      })
      await this.props.getProductsLibrary({
        group_id: this.props.groups.currentGroup.id,
      })
      this.setState({ list: this.props.groups.attachmentProductList })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  render() {
    const { list, dataReceived } = this.state
    const {
      groups: { currentGroupAttachmentProducts },
    } = this.props
    return (
      <>
        <ListSearchHeader
          title="Products Used"
          placeholder="Start typing product name"
          search={this.searchProducts}
          noResults={dataReceived && !list.length}
        />

        <div className="products-list">
          {list.map((item) => {
            const creator = item.creator_user
              ? {
                  name: `${item.creator_user.first_name} ${item.creator_user.last_name}`,
                  url: generatePath(routes.USER, {
                    userId: item.creator_user.id,
                  }),
                }
              : item.creator_group
                ? {
                    name: item.creator_group.name,
                    url: generatePath(routes.GROUP, {
                      groupId: item.creator_group.id,
                    }),
                  }
                : {}
            const inCase = item.is_added
            // : user ? {
            //     name: `${user.first_name} ${user.last_name}`,
            //     url: generatePath(routes.USER, {userId: user.id})
            // } : {
            //     name: group.name,
            //     url: generatePath(routes.GROUP, {groupId: group.id})
            // };
            const dropdownControl = (itemId) => {
              const buttonLabel = () => {
                if (inCase) {
                  return "ADDED TO GROUP"
                } else {
                  return null
                }
              }

              return (
                <DropdownControl buttonLabel={buttonLabel()}>
                  <MenuList>
                    {inCase && (
                      <CustomMenuItem
                        onClick={() => this.deleteProductFromLibrary(itemId)}
                      >
                        Remove from group
                      </CustomMenuItem>
                    )}
                  </MenuList>
                </DropdownControl>
              )
            }
            return (
              <div className="product-tile" key={item.id}>
                <div className="product-tile__menu"></div>
                <Link
                  to={generatePath(routes.PRODUCT, { productId: item.id })}
                  className="product-tile__logo"
                >
                  <img src={item.image || logoDefault} alt="Product image" />
                </Link>
                <Link
                  to={generatePath(routes.PRODUCT, { productId: item.id })}
                  className="product-tile__name link"
                >
                  {item.name}
                </Link>
                <table className="product-tile__info">
                  <tbody>
                    <tr>
                      <td
                        className={`product-tile__info-label ${this.composeContentType(item) ? "pb4" : ""}`}
                      >
                        <span>Creator:</span>
                      </td>
                      <td
                        className={`product-tile__info-value ${this.composeContentType(item) ? "pb4" : ""}`}
                      >
                        <span>
                          <Link
                            to={creator.url}
                            className="link font-weight-500"
                          >
                            {creator.name}
                          </Link>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="product-tile__info-label">
                        <span>
                          {this.composeContentType(item) ? "Content type:" : ""}
                          {!this.composeContentType(item) && (
                            <span style={{ height: "20px" }}></span>
                          )}
                        </span>
                      </td>
                      <td className="product-tile__info-value">
                        <span>
                          {this.composeContentType(item)
                            ? this.composeContentType(item).name
                            : ""}
                        </span>
                      </td>
                    </tr>
                    {/*)}*/}
                  </tbody>
                </table>

                <StarRating rating={item.rating} />

                <div className="h-divider h-divider--vertical-margin"></div>
                {!inCase ? (
                  currentGroupAttachmentProducts.length < 30 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => this.addToLibrary(item.id)}
                    >
                      Add to group
                    </Button>
                  ) : null
                ) : (
                  dropdownControl(item.id)
                )}
              </div>
            )
          })}
        </div>

        {!dataReceived && <Loader />}
      </>
    )
  }
}

const mapStateToProps = ({ auth, groups }) => ({ auth, groups })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateGroup,
      productsLibrarySearch,
      clearGroupAttachmentProducts,
      updateGroupAttachmentsProducts,
      addProductToLibrary,
      removeProductFromLibrary,
      getProductsLibrary,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(withScroll(GroupsSettingsAttachmentsProducts))))
