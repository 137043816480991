import React from "react"
import { generatePath, Link, useParams } from "react-router-dom"
import postDateConvert from "../../../../../library/utils/postDateConvert"
import styled from "@emotion/styled"
import * as routes from "../../../../../library/constants/routes"
import { usePostCtx } from "../PostContext"
import ReplyOutlined from "@material-ui/icons/ReplyOutlined"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`

const PostMeta = ({ authorUrl, authorName, isRepost = false }) => {
  const params = useParams()
  const { post } = usePostCtx()

  const getPostUrl = () => {
    if (params.groupId) {
      return generatePath(routes.GROUP_POST, {
        groupId: params.groupId,
        postId: post.id,
      })
    }

    if (params.userId) {
      return generatePath(routes.USER_POST, {
        userId: params.userId,
        postId: post.id,
      })
    }

    return generatePath(routes.NEWS_POST, { postId: post.id })
  }

  return (
    <Container>
      <Link to={authorUrl} className="asfs link font-weight-500">
        {isRepost && <ReplyOutlined className="flip-h mr5" />}
        {authorName}
      </Link>
      <Link to={getPostUrl()} className="post__date">
        {postDateConvert(post.created)}
      </Link>
    </Container>
  )
}

export default PostMeta
