import React from "react"
import { Box } from "../../../../../components/tw/layout"
import { useQuery } from "@tanstack/react-query"
import { useCurrentUser } from "../../../../../hooks/data/user/useUser"
import { call } from "../../../../../library/networking/API"
import Loader from "../../../../../components/ui/loader"
import { PlainButton } from "../../../../../components/v2/controls"
import { KeyboardArrowRight } from "@material-ui/icons"

const ProfileSchedule = () => {
  const { user } = useCurrentUser()

  const { data, isSuccess } = useQuery({
    queryKey: [
      user.role === 1
        ? "schedule_assignments.list_student"
        : "schedule_assignments.list_teacher",
    ],
    queryFn: async (ctx) => {
      const res = await call(ctx.queryKey[0], {})
      return res.data
    },
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000,
  })

  console.log(data)

  const onScheduleOpen = () => {}

  return (
    <div className="box boxed-list">
      <div className="boxed-list__header">Your schedule</div>
      <div className="boxed-list__list">
        {isSuccess ? (
          <div className={"max-h-64 overflow-y-auto relative"}>
            items will be rendered here
          </div>
        ) : (
          <Loader dense />
        )}
      </div>
      <div className={"boxed-list__footer"}>
        <PlainButton onClick={onScheduleOpen}>
          Show full schedule <KeyboardArrowRight />
        </PlainButton>
      </div>
    </div>
  )
}

export default ProfileSchedule
