export const POSTS = {
    MY_LIST: "posts.my_list",
    MY_CLEAR: "posts.my_clear",
    LIST: "posts.list",
    FEED: "posts.feed",
    FEED_UPDATE: "posts.feed_update",
    LIST_CLEAR: "posts.list_clear",
    CREATE: "posts.create",
    UPDATE: "posts.update",
    DELETE: "posts.delete",
    ARCHIVE: "posts.archive",
    RESTORE: "posts.restore",
    GET: "posts.get",
    CLEAR: "posts.clear",
    SHARE: "posts.share",
    PIN: "posts.pin",
    UNPIN: "posts.unpin",
    LIST_UPDATE: "posts.list.update",
    MY_LIST_UPDATE: "posts.mylist.update",
    LIKES: {
        CREATE: "likes.create",
        DELETE: "likes.delete",
    },
    COMMENTS: {
        LIST: "comments.list",
        CREATE: "comments.create",
        UPDATE: "comments.update",
        DELETE: "comments.archive",
        RESTORE: "comments.restore",
    },
};
