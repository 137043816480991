import React from "react"
import {
  DataTableRow,
  DataTableCell,
  MIN_TABLE_CELL_WIDTH,
} from "../../../../../../../components/v2/dataTable/components"
import { useElementWidth } from "../../../../../../../hooks/useElementWidth"
import Loader from "../../../../../../../components/ui/loader"
import Stub from "../../../../../../../components/stub"
import { Tooltip } from "@material-ui/core"
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined"
import EditOutlinedIcon from "@material-ui/icons/EditOutlined"
import { divide } from "lodash"
import { clsx } from "clsx"

const ScheduleAssignmentTable = ({
  columns,
  rows,
  data,
  onCellClick,
  getCellContent,
}) => {
  const tableRef = React.useRef(null)

  const [gridCols, setGridCols] = React.useState(
    `repeat(${columns.length}, 1fr)`
  )

  const containerId = "table-container"

  const containerWidth = useElementWidth(containerId)

  React.useEffect(() => {
    const colFixedWidthSum = columns.reduce(
      (prev, cur) => prev + (cur.width || MIN_TABLE_CELL_WIDTH),
      0
    )

    const isFluid = colFixedWidthSum <= containerWidth

    const calculateGridCols = () => {
      return columns.reduce((prev, column) => {
        if (!isFluid) {
          return prev + ` ${column.width || MIN_TABLE_CELL_WIDTH}px`
        } else {
          return prev + ` ${column.width ? column.width + "px" : "1fr"}`
        }
      }, "")
    }

    setGridCols(calculateGridCols())
  }, [containerWidth, columns])

  const composeValue = (cell, idx) => {
    if (idx === 0 && cell) {
      return <span>{cell}</span>
    }

    if (!cell?.enabled) {
      return (
        <p className={"text-xs text-black54 text-center"}>
          Not <br /> available
        </p>
      )
    }

    if (typeof getCellContent === "function") {
      return getCellContent(cell, idx)
    }

    return <p className={"text-center"}>—</p>
  }

  console.log(rows)

  return (
    <div id={containerId}>
      {!columns.length && <Stub text={"Blocks not found"} />}

      {!!containerWidth && (
        <div
          role={"table"}
          className={"pb-4 overflow-x-auto grid"}
          ref={tableRef}
          style={{
            gridTemplateColumns: gridCols,
            width: containerWidth,
          }}
        >
          {!!columns.length && (
            <>
              <DataTableRow
                $isHeader
                style={{
                  gridColumn: `span ${columns.length}`,
                }}
              >
                {columns.map((column, index) => (
                  <DataTableCell
                    key={index}
                    $isFirstColumnPinned={true}
                    $isLastColumnPinned={false}
                    $isScrolled={false}
                    $isHeaderCell
                    className={"bg-grey7"}
                  >
                    <div className={"w-full text-center"}>
                      <h4 className={"text-black87 text-base leading-none"}>
                        {column.label}
                      </h4>
                      <span className={"text-black54 text-xs"}>
                        {column.time}
                      </span>
                    </div>
                    {/*<div>{column.label}</div>*/}
                  </DataTableCell>
                ))}
              </DataTableRow>
              {rows.map((row, rowIdx) => (
                <DataTableRow
                  key={rowIdx}
                  style={{
                    gridColumn: `span ${columns.length}`,
                  }}
                >
                  {row.map((cell, cellIdx) => {
                    const cellContent = composeValue(cell, cellIdx)

                    return (
                      <DataTableCell
                        key={cellIdx}
                        $isFirstColumnPinned={true}
                        $isScrolled={false}
                        className={clsx(
                          cellIdx === 0 && "bg-white !text-base leading-none",
                          cellIdx > 0 &&
                            (cell.enabled
                              ? "bg-white hover:bg-primary15"
                              : "bg-gray-100")
                        )}
                      >
                        {cellIdx > 0 && cell.enabled ? (
                          <button
                            className={clsx(
                              "w-full h-full",
                              cell.enabled ? "cursor-pointer" : "cursor-default"
                            )}
                            disabled={!cell.enabled}
                            onClick={() =>
                              onCellClick({ block: cell, track: cell.track })
                            }
                          >
                            {cellContent}
                          </button>
                        ) : (
                          <div className={"w-full"}>{cellContent}</div>
                        )}
                      </DataTableCell>
                    )
                  })}
                </DataTableRow>
              ))}
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default ScheduleAssignmentTable
