import React from "react"
import { useUserRelations } from "../../../../hooks/data/user/useUserRelations"
import UserCard from "./userCard"
import { useSelector } from "react-redux"
import { getCardSize } from "../../../../library/constants/styles/cardSizes"

const UserFriendshipCard = ({ user, mutatedQueryKey }) => {
  const currentUserId = useSelector(({ auth }) => auth.userData.id)
  const isCurrentUser = user.id === currentUserId

  const {
    mutatingIds,
    sendRequest,
    acceptRequest,
    cancelRequest,
    declineRequest,
    deleteFriend,
  } = useUserRelations({
    queryKey: mutatedQueryKey,
    flatRelationKeys: true,
  })

  const relations = user.user_relation || {
    is_friend: user.is_friend,
    is_requested: user.is_requested,
    is_requesting: user.is_requesting,
  }

  const isUpdating = sendRequest.isLoading && mutatingIds.includes(user.id)
  const noRelations = !(
    relations.is_friend ||
    relations.is_requested ||
    relations.is_requesting
  )

  return (
    <UserCard
      user={user}
      controls={
        !isCurrentUser && {
          button: noRelations
            ? {
                label: !isUpdating ? "Add" : "Adding...",
                action: () => sendRequest.mutate(user.id),
                isLoading: isUpdating,
              }
            : undefined,
          dropdownButton: !noRelations
            ? {
                buttonLabel: relations.is_friend
                  ? "Added"
                  : relations.is_requesting
                    ? "Confirm request"
                    : relations.is_requested
                      ? "Request sent"
                      : "",
                items: [
                  relations.is_friend && {
                    label: "Remove from friends",
                    action: () => deleteFriend.mutate(user.id),
                  },
                  relations.is_requested && {
                    label: "Cancel request",
                    action: () => cancelRequest.mutate(user.id),
                  },
                  relations.is_requesting && {
                    label: "Confirm request",
                    action: () => acceptRequest.mutate(user.id),
                  },
                  relations.is_requesting && {
                    label: "Decline request",
                    action: () => declineRequest.mutate(user.id),
                  },
                ].filter(Boolean),
              }
            : undefined,
        }
      }
    />
  )
}

export default UserFriendshipCard
