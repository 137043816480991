export const USERS = {
    SIGNIN: "users.signin",
    SIGNUP: "users.signup",
    CONFIRM_SIGNUP: "users.confirmSignup",
    DENY_SIGNUP: "users.denySignup",
    SIGNOUT: "users.signout",
    DELETE: "users.delete",
    GET: "users.get",
    REQUEST_PASSWORD_RESET: "users.requestPasswordReset",
    CONFIRM_PASSWORD_RESET: "users.confirmPasswordReset",
    DENY_PASSWORD_RESET: "users.denyPasswordReset",
    UPDATE_INFO: "users.update",
    UPDATE_EMAIL: "users.updateEmail",
    CONFIRM_EMAIL_UPDATE: "users.confirmEmailUpdate",
    DENY_EMAIL_UPDATE: "users.denyEmailUpdate",
    UPDATE_PASSWORD: "users.updatePassword",
    UPDATE_ROLE: "users.updateRole",
    EDUCATION_ADD: "users.education.add",
    EDUCATION_UPDATE: "users.education.update",
    EDUCATION_DELETE: "users.education.delete",
    EDUCATION_HIGHLIGHT: "users.education.highlight",
    EDUCATION_UNHIGHLIGHT: "users.education.unhighlight",
    WORK_ADD: "users.work.add",
    WORK_UPDATE: "users.work.update",
    WORK_DELETE: "users.work.delete",
    WORK_HIGHLIGHT: "users.work.highlight",
    WORK_UNHIGHLIGHT: "users.work.unhighlight",
    SEARCH: "users.search",
    CONTACT: "feedback.send",
};

export const FRIENDS = {
    DELETE: "friends.delete",
    LIST: "friends.list",
    REQUESTS_SEND: "friends.requests.send",
    REQUESTS_CANCEL: "friends.requests.cancel",
    REQUESTS_DECLINE: "friends.requests.decline",
    REQUESTS_ACCEPT: "friends.requests.accept",
    REQUESTS_LIST: "friends.requests.list",

};

export const COMPLAINTS = {
    CREATE: "complaints.create",
};

export const BLACKLIST = {
    ADD_USER: "users.block",
    REMOVE_USER: "users.unblock",
}
