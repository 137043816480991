import React, { Component } from "react"
import List from "../../../../../../components/ui/list"
import ListItemGroup from "../../../../../../components/ui/listItem/listItemGroup"
import Loader from "../../../../../../components/ui/loader"
import { withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import {
  clearGroupsList,
  getGroupRelatedList,
  groupAddConnection,
  groupDeleteConnection,
  searchRelatedGroups,
} from "../../../../../../library/store/actions/creators/groupsCreators"
import { LIMIT } from "../../../../../../library/constants/limits"
import * as qs from "query-string"
import withScroll from "../../../../../../hocs/withScroll/withScroll"
import ListSearchHeader from "../../../../../../components/ui/listSearchHeader/listSearchHeader"

class GroupSettingsRelatedGroups extends Component {
  state = {
    dataReceived: true,
  }

  async componentDidMount() {
    const groupId = parseInt(this.props.match.params.groupId)
    const { search } = this.props.location
    const { q: name } = qs.parse(search)
    this.setState({ dataReceived: false })
    await this.props.searchRelatedGroups({
      name: name || undefined,
      group_id: groupId,
      is_connected_to: groupId,
    })
    this.setState({ dataReceived: true })
  }

  async componentWillUnmount() {
    await this.props.clearGroupsList()
  }

  searchGroups = async (name) => {
    const { history } = this.props
    const groupId = parseInt(this.props.match.params.groupId)
    this.props.clearGroupsList()
    history.push({
      search: `?q=${name}`,
    })
    this.setState({ dataReceived: false })
    await this.props.searchRelatedGroups({
      name: name || undefined,
      group_id: groupId,
      is_connected_to: groupId,
    })
    this.setState({ dataReceived: true })
  }

  onScroll = async () => {
    if (!this.state.dataReceived) return
    this.setState({ dataReceived: false })
    const { relatedList } = this.props.groups
    const { search } = this.props.location
    const { q: name } = qs.parse(search)
    const groupId = parseInt(this.props.match.params.groupId)
    await this.props.searchRelatedGroups({
      name: name || undefined,
      group_id: groupId,
      is_connected_to: groupId,
      offset: relatedList.length,
      limit: LIMIT,
    })
    this.setState({ dataReceived: true })
  }

  render() {
    const currentGroupId = parseInt(this.props.match.params.groupId)
    const { relatedList } = this.props.groups
    return (
      <>
        <ListSearchHeader
          title="Related groups"
          placeholder="Start typing group name"
          search={(name) => this.searchGroups(name)}
          noResults={
            this.state.dataReceived && relatedList && !relatedList.length
          }
        />
        <>
          <List>
            {relatedList &&
              relatedList.map((item) => (
                <ListItemGroup
                  key={item.id}
                  group={item}
                  type="settings-related"
                  currentGroupId={currentGroupId}
                  groupAddConnection={({ group_id, related_group_id }) =>
                    this.props.groupAddConnection({
                      group_id,
                      related_group_id,
                    })
                  }
                  groupDeleteConnection={({ group_id, related_group_id }) =>
                    this.props.groupDeleteConnection({
                      group_id,
                      related_group_id,
                    })
                  }
                />
              ))}
          </List>
        </>
        {!this.state.dataReceived && <Loader />}
      </>
    )
  }
}

const mapStateToProps = ({groups}) => ({groups});
const mapDispatchToProps = dispatch => bindActionCreators({
    getGroupRelatedList,
    searchRelatedGroups,
    groupAddConnection,
    groupDeleteConnection,
    clearGroupsList,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withSnackbar(withScroll(GroupSettingsRelatedGroups))));

