import React, { useState } from "react"
import Dialog from "@material-ui/core/Dialog/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import { ValidatorForm } from "react-material-ui-form-validator"
import { DateTimePicker } from "@material-ui/pickers"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl/FormControl"
import { useDispatch } from "react-redux"
import { useAlert } from "../../../../../hooks/useAlert"
import { requestDemo } from "../../../../../library/store/actions/creators/productsCreators"
import { useParams } from "react-router-dom"

const ScheduleDemoDialog = (props) => {
  const { productId } = useParams()
  const dispatch = useDispatch()
  const { errorAlert, successAlert } = useAlert()

  const [dateTime, setDateTime] = useState(null)
  const [dateTimeError, setDateTimeError] = useState(false)

  const onSubmit = async () => {
    if (!dateTime) return setDateTimeError(true)

    try {
      dispatch(
        requestDemo({ product_id: parseInt(productId, 10), date: dateTime })
      )
      console.log(dateTime)
      successAlert("Demo requested")
      props.handleClose()
    } catch (error) {
      errorAlert(error)
    }
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <ValidatorForm onSubmit={onSubmit}>
        <DialogTitle id="alert-dialog-title" className="text-center">
          Schedule demo date
        </DialogTitle>
        <DialogContent>
          <div className="f jcc">
            <div className="form form--modal">
              <div className="form__fieldset">
                <label htmlFor="date-time" className="form__label">
                  Date and time *
                </label>
                <div className="form__fieldbox">
                  <div className="form__input">
                    <FormControl error={dateTimeError} fullWidth>
                      <DateTimePicker
                        id="date-time"
                        value={dateTime}
                        emptyLabel="Select date and time"
                        invalidLabel="Select date and time"
                        allowKeyboardControl={false}
                        fullWidth
                        clearable
                        format="d MMMM 'at' h:mm aaa"
                        margin="normal"
                        error={dateTimeError}
                        disablePast
                        maxDate={new Date(2099, 11, 31, 23, 59, 59)}
                        onChange={(date) => {
                          setDateTime(date.toISOString())
                        }}
                      />
                      {dateTimeError && (
                        <FormHelperText className="error-message">
                          Field is required
                        </FormHelperText>
                      )}
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary" autoFocus>
            Close
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  )
}

export default ScheduleDemoDialog
