import React from "react"
import styled from "@emotion/styled"
import { colors } from "../../../../../library/constants/styles/colors"
import { TextLineSkeleton } from "./textSkeleton"
import { blinkAnimation } from "../../../../../library/constants/styles/animation"

const Container = styled.div`
  border-radius: 2px;
  background-color: ${colors.white};
  box-shadow: 0 0 2px 0 rgba(210, 210, 210, 0.5);
  padding: 15px;
`

const Tile = styled.div`
  height: 127px;
  width: 127px;
  background: linear-gradient(
    to right,
    ${colors.black7} 0%,
    ${colors.black4} 50%,
    ${colors.black7} 100%
  );
  background-size: 150% 150%;
  animation: ${blinkAnimation} 2s ease-in-out infinite;

  &:not(:last-of-type) {
    margin-right: 10px;
  }
`

const HorizontalListSkeleton = () => {
  return (
    <Container>
      <div className={"mb-2 flex w-full items-center justify-between"}>
        <div>
          <TextLineSkeleton width={"100px"} />
        </div>
        <div>
          <TextLineSkeleton width={"100px"} />
        </div>
      </div>
      <div className={"mb-2 flex w-full items-center"}>
        <Tile />
        <Tile />
        <Tile />
        <Tile />
        <Tile />
      </div>
      <TextLineSkeleton width={"100px"} />
    </Container>
  )
}

export default HorizontalListSkeleton
