import React from "react"
import { useQuery } from "@tanstack/react-query"
import { nestAPI } from "../../../library/networking/API"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import StaticDataSelect from "../../../components/v2/inputs/select/staticDataSelect"
import { useSelector } from "react-redux"
import Loader from "../../../components/ui/loader"
import { MenuItem, Select } from "@material-ui/core"
import PlainLink from "../../../components/ui/link"
import * as routes from "../../../library/constants/routes"
import Button from "@material-ui/core/Button/Button"
import { useSubscriptions } from "../../main/user/userProfileSettings/profileSettingsSubscriptions/useSubscriptions"
import qs from "qs"
import { generatePath } from "react-router-dom"
import FormRadioGroup from "../../../components/formComponents/formRadioGroup"
import { getGroupLogo } from "../../../library/utils/getGroupLogo"

const GroupSeatsSelectionFormModal = ({ open, onClose, plan }) => {
  const { userData } = useSelector(({ auth }) => auth)
  const {
    openCheckoutPage,
    calcTieredSubscriptionPrice,
    calcFlatSubscriptionPrice,
  } = useSubscriptions()

  const { data, isSuccess } = useQuery({
    queryKey: ["SUBSCRIPTION_CANDIDATES"],
    queryFn: async () => {
      const q = qs.stringify(
        { types: plan.eligible_group_types },
        { arrayFormat: "comma" }
      )
      const response = await fetch(
        nestAPI + `groups/subscriptions/candidates?${q}`,
        {
          headers: {
            Authorization: "Bearer " + userData.token,
          },
        }
      )
      const json = await response.json()

      return json
    },
  })

  const [period, setPeriod] = React.useState("month")
  const [selectedGroup, setSelectedGroup] = React.useState(null)
  const [selectedSeatsNumber, setSelectedSeatsNumber] = React.useState("")

  const onSelectedGroupChange = (group) => {
    setSelectedGroup(group)
    setSelectedSeatsNumber(group.available_seats_options[0])
  }

  const onSubmit = async () => {
    await openCheckoutPage({
      plan_id: plan.id,
      group_id: +selectedGroup.id,
      quantity: selectedSeatsNumber || 1,
      period: period,
      price_id: plan.prices[period].id,
      success_url:
        window.location.origin +
        generatePath(routes.GROUP, { groupId: selectedGroup.id }),
    })
  }

  const isPriceTiered = !!plan.prices.month.tiers

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle className={"text-center"}>
        Choose group for subscription
      </DialogTitle>
      <DialogContent>
        <div className="f jcc">
          {isSuccess ? (
            <div className="form form--modal">
              <StaticDataSelect
                data={data}
                onChange={onSelectedGroupChange}
                value={selectedGroup}
                fieldName={"Group"}
                entityName={"Group"}
                getOptionHelperText={
                  isPriceTiered
                    ? (item) =>
                        `Users to pay for: ${item.full_fledged_users_number}`
                    : undefined
                }
                getItemPhoto={getGroupLogo}
              />
              <FormRadioGroup
                label={"Subscription schedule"}
                value={period}
                onChange={setPeriod}
                options={[
                  { value: "month", label: "Monthly" },
                  { value: "year", label: "Yearly" },
                ]}
              />

              {selectedGroup && !!selectedGroup.available_seats_options && (
                <div className="form__fieldset">
                  <label htmlFor={"seatsNumber"} className={"form__label"}>
                    Number of seats
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input form__input--select">
                      <Select
                        id={"seatsNumber"}
                        name={"selectedSeatsNumber"}
                        fullWidth
                        onChange={(e) => setSelectedSeatsNumber(e.target.value)}
                        value={selectedSeatsNumber}
                        displayEmpty
                      >
                        <MenuItem value={""} disabled>
                          <span className={"color-black-38"}>
                            Select seats number
                          </span>
                        </MenuItem>
                        {selectedGroup.available_seats_options.map((o) => {
                          return (
                            <MenuItem value={o} key={o}>
                              {o} seats
                            </MenuItem>
                          )
                        })}
                      </Select>
                      <p className={"mt10 color-black-54 font-13"}>
                        Need more seats? Just{" "}
                        <PlainLink to={routes.CONTACT_FORM} bold inline>
                          Contact us
                        </PlainLink>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {!!selectedGroup &&
                (selectedGroup.available_seats_options
                  ? !!selectedSeatsNumber
                  : true) && (
                  <div className="form__fieldset">
                    <span className={"form__label"} />
                    <div className="form__fieldbox">
                      <div className="form__input form__input--select">
                        <p className={"font-weight-500 font-18"}>
                          Total:{" "}
                          {isPriceTiered
                            ? !!selectedSeatsNumber &&
                              calcTieredSubscriptionPrice({
                                tiers: plan.prices[period].tiers,
                                quantity: selectedSeatsNumber,
                                period,
                              }).total
                            : calcFlatSubscriptionPrice({
                                unit_amount: plan.prices[period].unit_amount,
                                period,
                              }).total}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          ) : (
            <Loader />
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          onClick={onSubmit}
          disabled={!(selectedGroup || selectedSeatsNumber)}
        >
          Subscribe group
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default GroupSeatsSelectionFormModal
