import React from "react"
import { useAlert } from "../../../../../hooks/useAlert"
import {
  useFoldersList,
  useFoldersMutations,
} from "../../../../../hooks/data/bookmarks"
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core"
import Loader from "../../../../../components/ui/loader"

const FoldersPicker = ({ open, onClose, bookmarkId }) => {
  const { errorAlert } = useAlert()

  const { data: foldersData, isSuccess } = useFoldersList()
  const { addToFolder } = useFoldersMutations()

  const [selected, setSelected] = React.useState([])

  const handleCheckboxChange = (id) => (event) => {
    setSelected((prevState) => {
      if (event.target.checked) {
        return [...prevState, id]
      } else {
        return prevState.filter((f) => f.id !== id)
      }
    })
  }

  const onSubmit = async () => {
    try {
      await addToFolder.mutateAsync({
        bookmark_id: bookmarkId,
        folders: selected,
      })
      onClose()
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="dialog-title">
      <DialogTitle id="dialog-title" className="text-center">
        Bookmarks folders
      </DialogTitle>
      <DialogContent>
        {isSuccess ? (
          <FormControl component="fieldset">
            <FormGroup>
              {foldersData?.folders?.map((item) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selected.includes(item.id)}
                      onChange={handleCheckboxChange(item.id)}
                      value={String(item.id)}
                    />
                  }
                  label={item.name}
                  key={item.id}
                />
              ))}
            </FormGroup>
          </FormControl>
        ) : (
          <Loader />
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={onSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FoldersPicker
