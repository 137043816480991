import React from "react"
import BoxPage from "../../../../../../components/v2/layout/boxPage/boxPage"

const SchoolScheduleLearnerSubjectTime = () => {
  return (
    <BoxPage title={"Learner subject time"}>
      <div className={"mb20"}></div>
      <div>table</div>
    </BoxPage>
  )
}

export default SchoolScheduleLearnerSubjectTime
