import React from "react"
import "./search.scss"
import { bindActionCreators } from "redux"
import {
  globalSearch,
  groupsSearch,
  productsSearch,
  usersSearch,
} from "../../../library/store/actions/creators/searchCreators"
import { Route, Switch, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import VerticalMenu from "../../../components/ui/verticalMenu/verticalMenu"
import SearchResultsList from "./searchResultsList/searchResultsList"
import { withSnackbar } from "notistack"
import * as routes from "../../../library/constants/routes"
import * as qs from "query-string"
import SEO from "../../../components/SEO"

const Search = (props) => {
  const hideGroupsMenuChildren = ![
    routes.SEARCH_GROUPS,
    routes.SEARCH_GROUPS_INTERESTS,
    routes.SEARCH_GROUPS_DISTRICTS,
    routes.SEARCH_GROUPS_SCHOOLS,
    routes.SEARCH_GROUPS_COMPANIES,
    routes.SEARCH_GROUPS_EVENTS,
  ].includes(props.match.path)

  const composeQueryString = () => {
    const { name } = qs.parse(props.location.search)

    const query = qs.stringify({ name: name || undefined })
    return query ? `?${query}` : ""
  }

  const menuItems = [
    {
      path: routes.SEARCH + composeQueryString(),
      label: "All",
    },
    {
      path: routes.SEARCH_USERS + composeQueryString(),
      label: "People",
    },
    {
      path: routes.SEARCH_GROUPS + composeQueryString(),
      label: "Groups",
    },
    {
      path: routes.SEARCH_GROUPS_INTERESTS + composeQueryString(),
      label: "Interest groups",
      type: "child",
      hidden: hideGroupsMenuChildren,
    },
    {
      path: routes.SEARCH_GROUPS_DISTRICTS + composeQueryString(),
      label: "Districts",
      type: "child",
      hidden: hideGroupsMenuChildren,
    },
    {
      path: routes.SEARCH_GROUPS_SCHOOLS + composeQueryString(),
      label: "Schools",
      type: "child",
      hidden: hideGroupsMenuChildren,
    },
    {
      path: routes.SEARCH_GROUPS_COMPANIES + composeQueryString(),
      label: "Companies",
      type: "child",
      hidden: hideGroupsMenuChildren,
    },
    {
      path: routes.SEARCH_GROUPS_EVENTS + composeQueryString(),
      label: "Events",
      type: "child",
      hidden: hideGroupsMenuChildren,
    },
    // {
    //   path: routes.SEARCH_PRODUCTS + composeQueryString(),
    //   label: "Products",
    // },
  ]

  return (
    <div className="common-page__content">
      <SEO title={"Search"} />
      <main className="common-page__main">
        <Switch>
          <Route
            exact
            path={routes.SEARCH}
            render={(props) => <SearchResultsList {...props} type="all" />}
          />
          <Route
            exact
            path={routes.SEARCH_USERS}
            render={(props) => <SearchResultsList {...props} type="users" />}
          />
          <Route
            exact
            path={routes.SEARCH_GROUPS}
            render={(props) => <SearchResultsList {...props} type="groups" />}
          />
          <Route
            exact
            path={routes.SEARCH_GROUPS_INTERESTS}
            render={(props) => <SearchResultsList {...props} type="groups" />}
          />
          <Route
            exact
            path={routes.SEARCH_GROUPS_DISTRICTS}
            render={(props) => <SearchResultsList {...props} type="groups" />}
          />
          <Route
            exact
            path={routes.SEARCH_GROUPS_SCHOOLS}
            render={(props) => <SearchResultsList {...props} type="groups" />}
          />
          <Route
            exact
            path={routes.SEARCH_GROUPS_COMPANIES}
            render={(props) => <SearchResultsList {...props} type="groups" />}
          />
          <Route
            exact
            path={routes.SEARCH_GROUPS_EVENTS}
            render={(props) => <SearchResultsList {...props} type="groups" />}
          />
          <Route
            exact
            path={routes.SEARCH_PRODUCTS}
            render={(props) => <SearchResultsList {...props} type="products" />}
          />
        </Switch>
      </main>
      <aside className="common-page__sidebar">
        <VerticalMenu menuItems={menuItems} ignoreQuery />
        {/*{props.match.path === routes.SEARCH_USERS && (*/}
        {/*    <UsersFilter />*/}
        {/*)}*/}
      </aside>
    </div>
  )
}

const mapStateToProps = ({ auth, search }) => ({ auth, search })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      globalSearch,
      usersSearch,
      groupsSearch,
      productsSearch,
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Search))
)
