import React from "react"
import * as Yup from "yup"
import { useSelector } from "react-redux"
import { useAlert } from "../../../../../hooks/useAlert"
import BoxPage from "../../../../../components/v2/layout/boxPage/boxPage"
import { Formik } from "formik"
import Form from "../../../../../components/v2/layout/forms/form/form"
import { baseUrl } from "../../../../../library/networking/API"
import { generatePath } from "react-router-dom"
import { BOOK_MEET } from "../../../../../library/constants/routes"
import { useContactLinks, useContactLinksMutation } from "./useContactLinks"
import Loader from "../../../../../components/ui/loader"
import returnExternalUrl from "../../../../../library/utils/returnExternalUrl"

const validationSchema = Yup.object({
  appointment_link: Yup.string().url(),
  zoom_link: Yup.string().url(),
  webex_link: Yup.string().url(),
  teams_link: Yup.string().url(),
  outlook_link: Yup.string().url(),
  google_link: Yup.string().url(),
})

const ProfileSettingsAppointmentLinks = () => {
  const { links, ...query } = useContactLinks()
  const updateLinks = useContactLinksMutation()
  const { userData } = useSelector(({ auth }) => auth)
  const { successAlert, errorAlert } = useAlert()

  const initialValues = {
    appointment_link:
      baseUrl + generatePath(BOOK_MEET, { userId: userData.id }),
    zoom_link: links?.zoom || "",
    webex_link: links?.webex || "",
    teams_link: links?.teams || "",
    outlook_link: links?.outlook || "",
    google_link: links?.google || "",
  }

  const onSubmit = async (values) => {
    const { zoom_link, webex_link, teams_link, outlook_link, google_link } =
      values

    try {
      await updateLinks.mutateAsync({
        zoom: returnExternalUrl(zoom_link),
        webex: returnExternalUrl(webex_link),
        teams: returnExternalUrl(teams_link),
        outlook: returnExternalUrl(outlook_link),
        google: returnExternalUrl(google_link),
      })
      successAlert()
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <BoxPage title={"Appointment links"}>
      {query.isSuccess ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          <Form>
            <Form.Row label={"Appointment link"} name={"appointment_link"}>
              <Form.TextField
                name={"appointment_link"}
                placeholder={"Add appointment link"}
                readOnly
                withCopyButton
              />
            </Form.Row>
            <Form.Row label={"Zoom link"} name={"zoom_link"}>
              <Form.TextField
                name={"zoom_link"}
                placeholder={"Add Zoom link"}
              />
            </Form.Row>
            <Form.Row label={"Webex link"} name={"webex_link"}>
              <Form.TextField
                name={"webex_link"}
                placeholder={"Add Webex link"}
              />
            </Form.Row>
            <Form.Row label={"Teams link"} name={"teams_link"}>
              <Form.TextField
                name={"teams_link"}
                placeholder={"Add Teams link"}
              />
            </Form.Row>
            <Form.Row label={"Outlook link"} name={"outlook_link"}>
              <Form.TextField
                name={"outlook_link"}
                placeholder={"Add Outlook link"}
              />
            </Form.Row>
            <Form.Row label={"Google link"} name={"google_link"}>
              <Form.TextField
                name={"google_link"}
                placeholder={"Add Google link"}
              />
            </Form.Row>
          </Form>
        </Formik>
      ) : (
        <Loader />
      )}
    </BoxPage>
  )
}

export default ProfileSettingsAppointmentLinks
