import React from "react"
import ProfileHeaderSkeleton from "./profileHeaderSkeleton"
import ProfileInfoSkeleton from "./profileInfoSkeleton"
import SidebarListSkeleton from "../components/sidebarListSkeleton"
import { PageContentLayout, PageMain, PageRightSidebar } from "../../layout"

const ProfilePageSkeleton = () => {
  return (
    <div className="grid auto-rows-max gap-4">
      <PageContentLayout>
        <PageMain>
          <ProfileHeaderSkeleton />
          <ProfileInfoSkeleton />
        </PageMain>
        <PageRightSidebar>
          <SidebarListSkeleton itemsNumber={5} />
          <SidebarListSkeleton itemsNumber={3} />
          <SidebarListSkeleton itemsNumber={3} />
        </PageRightSidebar>
      </PageContentLayout>
    </div>
  )
}

export default ProfilePageSkeleton
