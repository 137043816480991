import React, { Component } from "react"
import "./productSettingsAttachments.scss"
import Button from "@material-ui/core/Button"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withSnackbar } from "notistack"
import {
  getProduct,
  updateProduct,
} from "../../../../../library/store/actions/creators/productsCreators"
import { bindActionCreators } from "redux"
import FileLoader from "../../../../../components/formComponents/fileLoader"
import getErrorText from "../../../../../library/constants/errorTypes"

class ProductSettingsAttachments extends Component {
  state = {
    blobs: this.props.products.currentProduct.blobs || [],
    images: this.props.products.currentProduct.photos || [],
    videos: this.props.products.currentProduct.videos || [],
  }

  processInfoSave = async () => {
    const {
      products: {
        currentProduct: { id },
      },
    } = this.props

    try {
      await this.props.updateProduct({
        id,
        attachments: this.state.blobs.map((f) => f.id),
        photos: this.state.images.map((f) => f),
      })
      await this.props.enqueueSnackbar("Information updated", {
        variant: "success",
      })
      await this.props.getProduct({ id })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }
  handleFileInputOpen = (id) => {
    document.getElementById(id).click()
  }
  setPhotos = ({ images }) => {
    this.setState({ images })
  }
  setBlobs = ({ blobs }) => {
    this.setState({ blobs })
  }

  render() {
    const {
      auth: { userData },
    } = this.props
    const { blobs, images, videos } = this.state
    return (
      <div className="box">
        <div className="box__heading">Product attachments</div>
        <div className="box__content">
          <div className="form">
            <div className="form__fieldset">
              <span className="form__label">Gallery images</span>
              <div className="form__fieldbox">
                <div className="form__attachments">
                  <FileLoader
                    imageId={"photos"}
                    images={images}
                    photosOnly={true}
                    onUpload={this.setPhotos}
                    token={userData.token}
                    byUrl={true}
                  />
                  {images.length <= 10 && (
                    <Button
                      color="primary"
                      variant="outlined"
                      htmlFor="image"
                      className={`${!!images.length ? "mt10" : ""}`}
                      onClick={() => this.handleFileInputOpen("photos")}
                    >
                      Upload image
                    </Button>
                  )}
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <span className="form__label">Attachments</span>
              <div className="form__fieldbox">
                <div className="form__attachments">
                  <FileLoader
                    attachmentId={"blobs"}
                    videos={videos}
                    blobs={blobs}
                    onUpload={this.setBlobs}
                    token={userData.token}
                    attachmentOnly={true}
                  />
                  <Button
                    color="primary"
                    variant="outlined"
                    className={`${!!blobs.length ? "mt10" : ""}`}
                    onClick={() => this.handleFileInputOpen("blobs")}
                  >
                    Upload
                  </Button>
                </div>
              </div>
            </div>

            <Button
              color="primary"
              variant="contained"
              onClick={this.processInfoSave}
              className="form__submit"
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, products }) => ({ auth, products })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getProduct, updateProduct }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(ProductSettingsAttachments)))
