import React, { useState } from "react"
import { ValidatorForm } from "react-material-ui-form-validator"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import { FormDatePicker } from "../../../../../../components/formComponents"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import Dialog from "@material-ui/core/Dialog"

const AcquisitionModal = ({ handleClose, open, openAccess }) => {
  const [startDate, setStartDate] = useState(new Date().toISOString())
  const [endDate, setEndDate] = useState(null)

  const processAcquisitionChange = () => {
    openAccess({ date_from: startDate, date_to: endDate })
    // console.log(startDate, endDate);
  }

  return (
    <Dialog
      aria-labelledby="event-form-dialog"
      onClose={handleClose}
      open={open}
      maxWidth="md"
    >
      <ValidatorForm onSubmit={processAcquisitionChange}>
        <DialogTitle id="alert-dialog-title" className="text-center">
          Open access to product
        </DialogTitle>
        <DialogContent>
          <div className="f jcc">
            <div className="form form--modal">
              <FormDatePicker
                dateTo={{
                  label: "End date",
                  value: endDate,
                  handleChange: setEndDate,
                  minDate: new Date().setDate(new Date().getDate() + 1),
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  )
}

export default AcquisitionModal
