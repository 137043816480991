import { useAlert } from "./useAlert"

export const useTry = () => {
  const { errorAlert } = useAlert()

  const safeCall = async (callback, onError = null) => {
    try {
      if (typeof callback === "function") {
        await callback()
      }
    } catch (e) {
      if (typeof onError === "function") {
        onError(e)
      } else {
        errorAlert(e)
      }
    }
  }

  return safeCall
}
