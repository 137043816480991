import React from "react"
import Linkify from "linkifyjs/react"
import getVideoId from "get-video-id"

const linkify = require("linkifyjs")

const TextWithExternalURL = ({text, showPlayer = true, videoHeight = 400}) => {
    const textLinks = linkify.find(text);

    const video = textLinks.find(link => {
        const videoInfo = getVideoId(link.href);
        return (videoInfo.service === "youtube" || videoInfo.service === "vimeo") && videoInfo.id !== null;
    });

    return (
        <>
            <Linkify tagName={"pre"} options={{className: "link link--inline"}}>
                {text}
            </Linkify>

            {showPlayer && !!video && (
                <>
                    {getVideoId(video.href).service === "youtube" ? (
                        <iframe
                            src={`https://www.youtube.com/embed/${getVideoId(video.href).id}`}
                            width="100%"
                            height={videoHeight}
                            frameBorder="0"
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            className={"mt15"}
                        />

                    ) : (
                        <iframe
                            src={`https://player.vimeo.com/video/${getVideoId(video.href).id}`}
                            width="100%"
                            height={videoHeight}
                            frameBorder="0"
                            allow="fullscreen; picture-in-picture"
                            allowFullScreen
                            className={"mt15"}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default TextWithExternalURL;
