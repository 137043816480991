import React from "react"
import styled from "@emotion/styled"
import TableCell from "../tableCell"
import { colors } from "../../../library/constants/styles/colors"
import ArrowDropDown from "@material-ui/icons/ArrowDropDown"
import ArrowDropUp from "@material-ui/icons/ArrowDropUp"

const Container = styled.thead`
  display: block;
  width: ${(props) =>
    props.fluid ? "100%" : props.cellsNumber * 115 + 48 + "px"};
  background-color: ${colors.grey7};
  border-top: 1px solid ${colors.black12};
  border-bottom: 1px solid ${colors.black12};
`

const ContainerRow = styled.tr`
display: flex;
align-items: center;
width: 100%;
`;

const CellContent = styled.button`
display: flex;
align-items: center;
color: ${colors.black54};
border: 0;
box-shadow: none;
background-color: transparent;
white-space: nowrap;
transition: .2s all;

:focus {
  outline: none;
  color: ${colors.secondary};
  -webkit-text-fill-color: ${colors.secondary};
}

svg {
  margin-left: 4px;
}
`;

const TableHead = (props) => {
    const nextOrder = () => {
        switch (props.sortOrder) {
            case "asc":
                return "desc";

            case "desc":
                return null;

            default:
                return "asc";
        }
    };

    return (
        !!props.columns.length && <Container cellsNumber={props.columns.length} fluid={props.fluid}>
            <ContainerRow>
                {props.columns.map(({id, name}) => (
                    <TableCell th fluid={props.fluid} key={id}>
                        <CellContent onClick={() => props.sortBy(id, nextOrder())}>
                            {name}
                            {(props.sortedBy !== id || (props.sortedBy === id && !props.sortOrder)) && (
                                <ArrowDropDown className="color-black-38"/>
                            )}
                            {(props.sortedBy === id && props.sortOrder === "asc") && (
                                <ArrowDropUp className="color-primary"/>
                            )}
                            {(props.sortedBy === id && props.sortOrder === "desc") && (
                                <ArrowDropDown className="color-primary"/>
                            )}</CellContent>
                    </TableCell>
                ))}
            </ContainerRow>
        </Container>
    );
};

export default TableHead;
