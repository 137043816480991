import { useSnackbar } from "notistack"
import { getError } from "../library/constants/errorTypes"

export const useAlert = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const successAlert = (text = "Information updated") => {
    return enqueueSnackbar(text, { variant: "success" })
  }

  const errorAlert = (error) => {
    if (process.env.NODE_ENV !== "production") {
      console.error("--DEV ERROR--: ", { error })
    }

    if (error?.error?.code) {
      return enqueueSnackbar(getError(error), { variant: "error" })
    }

    if (typeof error === "string") {
      return enqueueSnackbar(error, { variant: "error" })
    }
  }

  return {
    successAlert,
    errorAlert,
    toast: enqueueSnackbar,
    closeToast: closeSnackbar,
  }
}
