import { useDispatch, useSelector } from "react-redux"
import {
  clearSchoolCalendarSegmentsList,
  deleteSchoolCalendarSegment,
  getSchoolCalendarSegmentsList,
} from "../../../../../../../library/store/actions/creators/groupsCreators"
import SegmentsListItem from "./components/segmentsListItem/segmentsListItem"
import { useParams } from "react-router-dom"
import SegmentFormModal from "./components/segmentFormModal/segmentFormModal"
import ListPage from "../../../../../../../components/v2/layout/listPage/listPage"
import useCreator from "../../../../../../../hooks/useCreator"
import SuperListPage from "../../../../../../../components/v2/layout/superListPage/superListPage"
import { SEGMENTS } from "../../../../../../../library/store/actions/types/groupsTypes"
import { useSegmentMutations } from "../../../../../../../hooks/data/calendar/schedules"
import SegmentCard from "../../../../../../../components/v2/layout/cards/segmentCard"
import { useIdParam } from "../../../../../../../hooks/useIdParam"
import {
  CARD_SIZES,
  getCardSize,
} from "../../../../../../../library/constants/styles/cardSizes"

const GroupSettingsCalendarClassSegments = () => {
  const schedule_id = useIdParam("calendarId")

  const queryKey = [SEGMENTS.LIST, { schedule_id }]

  const { deleteSegment } = useSegmentMutations()

  return (
    <SuperListPage
      headerProps={{
        title: "Segments",
        button: { label: "Create Segment" },
      }}
      noResultsLabel={"This Calendar doesn't have segments yet"}
      listItemHeight={getCardSize(6).numValPx}
      queryConfig={{
        key: queryKey,
        fetchParams: {
          schedule_id,
        },
        listKey: "segments",
      }}
      getListItemComponent={({ item, openEditDialog, openDeletionDialog }) => (
        <SegmentCard
          segment={item}
          openDeletionDialog={openDeletionDialog}
          openEditDialog={openEditDialog}
        />
      )}
      getFormDialogComponent={({ open, onClose, activeItem }) => {
        return (
          <SegmentFormModal
            activeSegment={activeItem}
            edit={!!activeItem}
            onClose={onClose}
            open={open}
            queryKey={queryKey}
          />
        )
      }}
      getDeletionDialogComponent={{
        entityName: "segment",
        onDelete: (id) => deleteSegment.mutateAsync({ id }),
      }}
    />
  )
}

export default GroupSettingsCalendarClassSegments
