import React from "react"
import LockOutlined from "@material-ui/icons/LockOutlined"

const CredMini = ({ cred }) => {
  return (
    <div
      className={
        "relative aspect-square w-full border-[3px] bg-center bg-cover bg-no-repeat flex items-center justify-center p-4 snap-start"
      }
      style={{
        borderColor: cred.border_color,
        backgroundImage: `url(${cred.background_image})`,
      }}
    >
      <img src={cred.foreground_image} alt={"icon"} className={"max-h-full"} />
      {!cred.public && (
        <div
          className={
            "absolute top-0 left-0 z-100 bg-white rounded-full w-6 h-6 flex items-center justify-center shadow-[0_2px_4px_rgba(0,_0,_0,_.2)]"
          }
        >
          <LockOutlined className="color-primary !text-lg" />
        </div>
      )}
    </div>
  )
}

export default CredMini
