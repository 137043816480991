import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { axiosBaseQuery } from "../baseQuery"

export const spacesAPI = createApi({
  reducerPath: "spacesAPI",
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getSpace: build.query({
      query: (id) => ({
        url: "spaces.get",
        data: { id },
      }),
    }),
  }),
})

export const { useGetSpaceQuery, useLazyGetSpaceQuery } = spacesAPI
