import React, { useEffect, useRef, useState } from "react"
import ListModal, {
  ListModalContentContainer,
} from "../../../../../../../../../../../components/ui/modals/listModal/listModal"
import { generatePath, Link, useHistory, useParams } from "react-router-dom"
import * as routes from "../../../../../../../../../../../library/constants/routes"
import logoDefault from "../../../../../../../../../../../assets/img/product.svg"
import Button from "@material-ui/core/Button/Button"
import { useDispatch, useSelector } from "react-redux"
import { useAlert } from "../../../../../../../../../../../hooks/useAlert"
import { getGroupKnowmixList } from "../../../../../../../../../../../library/store/actions/creators/groupsCreators"
import withScroll from "../../../../../../../../../../../hocs/withScroll/withScroll"
import { getSubjectName } from "../../../../../../../../../../../library/utils/educational"

const KnowmixListForUnit = (props) => {
  const params = useParams()
  const dispatch = useDispatch()
  const { errorAlert } = useAlert()

  const scrollComponent = useRef(null)

  const { knowmixList, knowmixListEndReached } = useSelector(
    ({ groups }) => groups
  )

  const [dataReceived, setDataReceived] = useState(false)
  const [initialRender, setInitialRender] = useState(true)
  const [name, setName] = useState("")

  const getList = async (offset = 0, limit = 15) => {
    try {
      await dispatch(
        getGroupKnowmixList({
          name: name || undefined,
          group_id: parseInt(params.groupId, 10),
          offset,
          limit,
        })
      )
      setDataReceived(true)
      setInitialRender(false)
    } catch (e) {
      errorAlert(e)
    }
  }

  useEffect(() => {
    getList()
    localStorage.setItem("unit", JSON.stringify(props.unit))
  }, [])

  const handleChange = (event) => {
    setName(event.target.value)
  }

  const processSearch = (event) => {
    event.preventDefault()

    getList()
  }

  const onScroll = async () => {
    if (!dataReceived || knowmixListEndReached) return
    await this.getList(this.state.list.length)
  }

  return (
    <ListModal
      title={"Course"}
      list={knowmixList}
      name={name}
      handleNameChange={handleChange}
      placeholder="Type product name"
      onSearch={processSearch}
      onScroll={onScroll}
      dataReceived={dataReceived}
      initialRender={initialRender}
      ListContent={
        <ListModalContentContainer id="products-list" ref={scrollComponent}>
          <div className={"cards-container cards-container--square"}>
            {knowmixList.map((item) => (
              <KnowmixListItem knowmix={item} unit={props.unit} key={item.id} />
            ))}
          </div>
        </ListModalContentContainer>
      }
      open={props.open}
      handleClose={props.handleClose}
    />
  )
}

const KnowmixListItem = ({ knowmix, unit }) => {
  const history = useHistory()

  const linkUrl = generatePath(routes.KNOWMIX, {
    knowmixId: knowmix.id,
  })

  const knowmixLogo = knowmix.photo || logoDefault

  return (
    <div className="knowmix-card knowmix-card--square">
      <Link to={linkUrl} className="img-container">
        <img src={knowmixLogo} alt="" />
      </Link>
      <Link to={linkUrl} className="link font-weight-500">
        {knowmix.name}
      </Link>
      <table className="knowmix-card--square__info">
        <tbody>
          <tr>
            <td className="knowmix-card--square__info-label pb4">
              <span>Subject:</span>
            </td>
            <td className="knowmix-card--square__info-value pb4">
              <span>{getSubjectName(knowmix.subject)}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="h-divider h-divider--vertical-margin"></div>
      <div className="text-center">
        {!knowmix.is_finished ? (
          <Button
            component={Link}
            variant="contained"
            color="primary"
            fullWidth
            to={{
              pathname: generatePath(routes.KNOWMIX_SETTINGS_STEPS, {
                knowmixId: knowmix.id,
              }),
              search: `?unitId=${unit.id}`,
            }}
            target={"_blank"}
          >
            Add
          </Button>
        ) : (
          <span className="knowmix-card--square__status-value">Released</span>
        )}
      </div>
    </div>
  )
}

export default withScroll(KnowmixListForUnit)
