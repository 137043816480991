import { call } from "../../../networking/API"
import * as endpoints from "../../../networking/apiEndpoints"
import { PERSON } from "../types/personTypes"
import { FRIENDS } from "../types/friendsTypes"

export const getPerson = (token, id, friends_limit = 5, products_limit = 5) => {
  return async (dispatch) => {
    const response = await call(endpoints.USERS.GET, {
      token,
      id,
      friends_limit,
      products_limit,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: PERSON.DATA_RECEIVED,
        payload: json,
      })
    } else {
      throw json.error
    }
  }
}

export const getPersonFriendsList = ({user_id, name, offset = 0, limit = 50}) => {
    return async (dispatch, getState) => {
        const response = await call(FRIENDS.LIST, {
            token: getState().auth.userData.token,
            user_id,
            name,
            offset,
            limit,
        });
        const json = await response.data;

        if (response.status === 200) {
            return dispatch({
                type: PERSON.FRIENDS_RECEIVED,
                payload: json,
            });
        } else {
            throw json.error;
        }
    };
};

export const resetPerson = () => {
    return async dispatch => {
        return dispatch({
            type: PERSON.DATA_RESET,
        });
    };
};


export const resetPersonFriendList = () => {
    return {
        type: PERSON.FRIENDS_RESET,
    };
};
