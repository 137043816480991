import React, { Component } from "react"
import * as routes from "../../../../library/constants/routes"
import FriendsHeader from "../friendsHeader/friendsHeader"
import List from "../../../../components/ui/list/list"
import ListItem from "../friendsList/listItem"
import Loader from "../../../../components/ui/loader/loader"
import { bindActionCreators } from "redux"
import { getPersonFriendsList } from "../../../../library/store/actions/creators/personCreators"
import {
  acceptFriendRequest,
  cancelFriendRequest,
  clearList,
  declineFriendRequest,
  deleteFriend,
  getFriendsList,
  getFriendsRequestsList,
  sendFriendRequest,
  updateFriendsRequestsList,
} from "../../../../library/store/actions/creators/friendsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import * as qs from "query-string"
import { LIMIT } from "../../../../library/constants/limits"
import withScroll from "../../../../hocs/withScroll/withScroll"
import getErrorText from "../../../../library/constants/errorTypes"

class FriendsRequests extends Component {
  state = {
    list: [],
    type:
      this.props.match.path === routes.FRIENDS_REQUESTS_PENDING
        ? "pending"
        : "outgoing",
    isFullList: false,
    initialRender: true,
    dataReceived: false,
  }

  async componentDidMount() {
    const {
      match: { path },
      location: { search },
    } = this.props
    const { name } = qs.parse(search)
    try {
      switch (path) {
        case routes.FRIENDS_REQUESTS_PENDING:
          await this.initRequestsList()
          this.setState({
            list: this.props.friends.pendingRequests,
            isFullList: !name,
            dataReceived: true,
            initialRender: false,
          })
          break

        case routes.FRIENDS_REQUESTS_OUTGOING:
          await this.initRequestsList()
          this.setState({
            list: this.props.friends.outgoingRequests,
            isFullList: !name,
            dataReceived: true,
            initialRender: false,
          })
          break

        default:
          this.setState({
            list: [],
          })
          break
      }
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      match: { path },
      location: { search },
    } = this.props
    const { name } = qs.parse(search)

    if (path !== prevProps.match.path) {
      await this.props.clearList()
      this.setState({ dataReceived: false, list: [] })
      try {
        switch (path) {
          case routes.FRIENDS_REQUESTS_PENDING:
            await this.props.getFriendsRequestsList({ type: 1 })
            this.setState({
              list: this.props.friends.pendingRequests,
              isFullList: !name,
              type: "pending",
              dataReceived: true,
              initialRender: false,
            })
            break

          case routes.FRIENDS_REQUESTS_OUTGOING:
            await this.props.getFriendsRequestsList({ type: 2 })
            this.setState({
              list: this.props.friends.outgoingRequests,
              isFullList: !name,
              type: "outgoing",
              dataReceived: true,
              initialRender: false,
            })
            break

          default:
            this.setState({
              list: [],
            })
            break
        }
      } catch ({ error }) {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    } else if (search && search !== prevProps.location.search) {
      this.setState({ dataReceived: false, list: [] })
      this.props.clearList()
      try {
        if (path === routes.FRIENDS_REQUESTS_PENDING) {
          await this.props.getFriendsRequestsList({
            type: 1,
            name: name || undefined,
          })
          this.setState({
            list: this.props.friends.pendingRequests,
            isFullList: !name,
            dataReceived: true,
            initialRender: false,
          })
        } else {
          await this.props.getFriendsRequestsList({
            type: 2,
            name: name || undefined,
          })
          this.setState({
            list: this.props.friends.outgoingRequests,
            isFullList: !name,
            dataReceived: true,
            initialRender: false,
          })
        }
      } catch ({ error }) {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    }
  }

  componentWillUnmount() {
    this.props.clearList()
  }

  onScroll = async () => {
    if (!this.state.dataReceived) return
    this.setState({ dataReceived: false })
    const {
      match: { path },
      location: { search },
    } = this.props
    const { name } = qs.parse(search)

    if (!name) {
      try {
        switch (path) {
          case routes.FRIENDS_REQUESTS_PENDING:
            await this.props.getFriendsRequestsList({
              type: 1,
              offset: this.state.list.length,
              limit: LIMIT,
            })
            await this.setState({
              list: this.props.friends.pendingRequests,
              dataReceived: true,
            })
            break

          case routes.FRIENDS_REQUESTS_OUTGOING:
            await this.props.getFriendsRequestsList({
              type: 2,
              offset: this.state.list.length,
              limit: LIMIT,
            })
            await this.setState({
              list: this.props.friends.outgoingRequests,
              dataReceived: true,
            })
            break

          default:
            this.setState({
              list: [],
            })
            break
        }
      } catch ({ error }) {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    } else {
      this.setState({ dataReceived: false })
      try {
        if (path === routes.FRIENDS_REQUESTS_PENDING) {
          await this.props.getFriendsRequestsList({
            type: 1,
            name: name || undefined,
            offset: this.state.list.length,
            limit: LIMIT,
          })
          await this.setState({
            list: this.props.friends.pendingRequests,
            dataReceived: true,
          })
        } else {
          await this.props.getFriendsRequestsList({
            type: 2,
            name: name || undefined,
            offset: this.state.list.length,
            limit: LIMIT,
          })
          this.setState({
            list: this.props.friends.outgoingRequests,
            dataReceived: true,
          })
        }
      } catch ({ error }) {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    }
  }

  initRequestsList = async () => {
    const {
      location: { search },
      match: { path },
    } = this.props
    const { name } = qs.parse(search)
    try {
      await this.props.getFriendsRequestsList({
        name:
          path === routes.FRIENDS_REQUESTS_PENDING
            ? name || undefined
            : undefined,
        type: 1,
      })
      await this.props.getFriendsRequestsList({
        name:
          path === routes.FRIENDS_REQUESTS_OUTGOING
            ? name || undefined
            : undefined,
        type: 2,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  searchRequests = (query) => {
    const { history } = this.props

    history.push({
      search: `?name=${query}`,
    })
  }

  updateList = async () => {
    const {
      match: { path },
      location: { search },
    } = this.props
    const { name } = qs.parse(search)
    if (path === routes.FRIENDS_REQUESTS_PENDING) {
      await this.props.updateFriendsRequestsList({
        type: 1,
        name: name || undefined,
        offset: 0,
        limit: this.state.list.length,
      })
      await this.setState({
        list: this.props.friends.pendingRequests,
        dataReceived: true,
      })
    } else {
      await this.props.updateFriendsRequestsList({
        type: 2,
        name: name || undefined,
        offset: 0,
        limit: this.state.list.length,
      })
      this.setState({
        list: this.props.friends.outgoingRequests,
        dataReceived: true,
      })
    }
  }

  render() {
    const {
      match: { path },
    } = this.props
    const { list, type, isFullList, dataReceived, initialRender } = this.state

    const noResults =
      path === routes.FRIENDS_REQUESTS_PENDING
        ? this.props.friends.pendingRequestsNumber === 0
        : this.props.friends.outgoingRequestsNumber === 0

    const noResultsLabel =
      path === routes.FRIENDS_REQUESTS_PENDING
        ? "You don't have pending requests yet"
        : "You don't have outgoing requests yet"

    const menuItems = [
      {
        label: "Pending",
        path: routes.FRIENDS_REQUESTS_PENDING,
        counter: this.props.friends.pendingRequestsNumber,
      },
      {
        label: "Outgoing",
        path: routes.FRIENDS_REQUESTS_OUTGOING,
        counter: this.props.friends.outgoingRequestsNumber,
      },
    ]

    return (
      <>
        <FriendsHeader
          counter={this.props.friends.friendsList.length}
          menuItems={menuItems}
          activeTab="Pending"
          search={this.searchRequests}
          noResults={!initialRender && noResults}
          noResultsLabel={isFullList && noResultsLabel}
        />
        <List>
          {list.map((item) => (
            <ListItem
              user={item}
              type={type}
              key={item.id}
              updateList={this.updateList}
            />
          ))}
        </List>
        {!dataReceived && <Loader />}
      </>
    )
  }
}

const mapStateToProps = ({auth, friends, person}) => ({auth, friends, person});
const mapDispatchToProps = dispatch => bindActionCreators({
    getPersonFriendsList,
    getFriendsList,
    getFriendsRequestsList,
    sendFriendRequest,
    cancelFriendRequest,
    acceptFriendRequest,
    declineFriendRequest,
    deleteFriend,
    clearList,
    updateFriendsRequestsList,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withScroll(FriendsRequests)));
