import React, { useState } from "react"
import Button from "@material-ui/core/Button"
import CheckOutlined from "@material-ui/icons/CheckOutlined"
import IconButton from "@material-ui/core/IconButton"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import { useMediaQuery } from "@material-ui/core"
import PlanCard from "./PlanCard"
import { usePaidPlansQuery } from "./usePlans"
import Loader from "../../../components/ui/loader"
import { planFeatures } from "./planFeatures"

const freePlan = {
  title: "FREE for anyone simplifying meeting calendaring and schedules",
  features: [
    "Create meetings without dates and times that calendars themselves; outside invitees can use your link to pick a time, inside users can use calendar intelligence to coordinate the best time for you and your invitees",
    "Social posting",
    "Create auto-cohorting multi-meetings that coordinate and calendar themselves with pre-instructions, auto-retargeting, and reminders. Learners cannot create Meets",
    "News feed",
    "Multi-meets tracking",
    "Create social and extra-credit Cred",
    "Create regular events for fellow users",
    "Sync with Microsoft Outlook or Google calendars",
    "Cannot create open time Holds for automatically slotting Meets in, Meets will use any open time from 8am-5pm in your time zone",
    "Input links video conferencing meets",
    "Personal profile page, finding friends, joining groups",
  ],
  comingSoonFeatures: [],
}

const PricingPage = () => {
  const { paidPlans, isSuccess, isLoading, isError } = usePaidPlansQuery()

  console.log({ paidPlans, isSuccess, isLoading, isError })

  const isMobile = useMediaQuery("(max-width:599px)")
  const step = 1

  const [offset, setOffset] = useState(0)

  const navigatePrev = () => {
    if (offset === 0) return
    setOffset((prevState) => prevState - step)
  }

  const navigateNext = () => {
    if (offset >= paidPlans.length) return
    setOffset((prevState) => prevState + step)
  }

  const navigation = isMobile ? (
    <>
      <span className={"mr-4 text-base"}>see more</span>
      <IconButton
        aria-label="Previous"
        onClick={navigatePrev}
        disabled={offset === 0}
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        aria-label="Next"
        onClick={navigateNext}
        disabled={offset + step >= paidPlans?.length}
      >
        <KeyboardArrowRight />
      </IconButton>
    </>
  ) : (
    <>
      <div>
        {offset !== 0 && (
          <Button
            color={"primary"}
            aria-label="Previous"
            onClick={navigatePrev}
            startIcon={<KeyboardArrowLeft />}
          >
            See previous levels
          </Button>
        )}
      </div>
      <div>
        {offset + 4 < paidPlans?.length && (
          <Button
            color={"primary"}
            aria-label="Next"
            onClick={navigateNext}
            endIcon={<KeyboardArrowRight />}
          >
            See next levels
          </Button>
        )}
      </div>
    </>
  )

  return isSuccess ? (
    <div
      className={
        "flex flex-col justify-between w-full min-h-[calc(100vh-50px)] bg-white"
      }
    >
      <div>
        <div className={"flex items-center py-4 min-h-14 bg-grey7"}>
          <div className="container">
            <div className="flexgrid full-width">
              <div className={"px-4 md:px-0"}>
                <div
                  className={
                    "flex flex-col py-8 px-2 rounded-3xl leading-normal border border-[#979797]"
                  }
                >
                  <h2
                    className={
                      "text-2xl leading-none font-medium text-center mb-3"
                    }
                  >
                    FREE for everyone!
                  </h2>
                  <div className={"grid grid-cols-1 md:grid-cols-2 gap-4 mt-8"}>
                    {planFeatures.free?.map((f) => (
                      <div className={"flex"} key={f}>
                        <CheckOutlined color={"primary"} className={"mr-2"} />
                        <span>{f}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className={`w-full flex items-center justify-end pt-4 pb-8`}>
                {navigation}
              </div>
              <div className={"grid md:grid-cols-4 gap-4 px-4 md:px-0"}>
                {paidPlans
                  .slice(offset, isMobile ? offset + 1 : offset + 4)
                  .map((p, i) => (
                    <PlanCard plan={p} key={i} />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  )
}

export default PricingPage
