import React from "react"
import Button from "@material-ui/core/Button/Button"
import MenuList from "@material-ui/core/MenuList/MenuList"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import * as routes from "../../../../../../library/constants/routes"
import { generatePath, useHistory } from "react-router-dom"
import DropdownControl from "../../../../../../components/ui/dropdowns/dropdownControl/dropdownControl"
import { withStyles } from "@material-ui/core"
import { colors } from "../../../../../../library/constants/styles/colors"
import VerticalMenu from "../../../../../../components/ui/verticalMenu"
import MoreDropdownBtn from "../../../../../../components/ui/dropdowns/moreDropdownBtn"
import { useUser } from "../../../../../../hooks/data/user/useUser"
import { useIdParam } from "../../../../../../hooks/useIdParam"
import { useUserRelations } from "../../../../../../hooks/data/user/useUserRelations"
import { useBookmarksModal } from "../../../../../../hooks/modals/useBookmarksModal"
import BlacklistModal from "../../../../../../components/complaints/blacklistModal"
import { useComplaintsModal } from "../../../../../../hooks/modals/useComplaintsModal"

const CustomMenuItem = withStyles({
  root: {
    color: colors.primary,
  },
})(MenuItem)

const UserProfileControls = ({ isContentVisible, toggleContentVisibility }) => {
  const history = useHistory()
  const userId = useIdParam("userId")
  const { user, isCurrentUser, key } = useUser(userId)

  const noRelations =
    !user.is_friend && !user.is_requested && !user.is_requesting

  const {
    sendRequest,
    acceptRequest,
    cancelRequest,
    declineRequest,
    deleteFriend,
    unblockUser,
  } = useUserRelations({ queryKey: key, isSingleEntity: true })

  const { onComplainModalOpen } = useComplaintsModal()

  const { createBookmark, deleteBookmark } = useBookmarksModal()

  const onSendFriendRequest = async () => {
    await sendRequest.mutateAsync(user.id)
  }
  const onDeleteFriend = async () => {
    await deleteFriend.mutateAsync(user.id)
  }
  const cancelFriendRequest = async () => {
    await cancelRequest.mutateAsync(user.id)
  }
  const onAcceptFriendRequest = async () => {
    await acceptRequest.mutateAsync(user.id)
  }
  const onDeclineFriendRequest = async () => {
    await declineRequest.mutateAsync(user.id)
  }
  const onAddToBookmarks = async () => {
    await createBookmark({
      params: {
        user_id: user.id,
      },
      updater: (u, bId) => {
        console.log({ ...u, bookmark: { id: bId } })
        return { ...u, bookmark: { id: bId } }
      },
    })
  }
  const onDeleteBookmark = async () => {
    await deleteBookmark({
      params: { user_id: user.id },
      updater: (u) => ({ ...u, bookmark: undefined }),
    })
  }
  const onShowBlacklistModal = async () => {}
  const onRemoveFromBlacklist = async () => {
    await unblockUser.mutateAsync(user.id)
  }

  const [isBlacklistModalOpen, setIsBlacklistModalOpen] = React.useState(false)

  const dropdownControl = () => {
    const buttonLabel = user.is_friend
      ? "Added"
      : user.is_requesting
        ? "Confirm request"
        : user.is_requested
          ? "Request sent"
          : ""

    return noRelations ? (
      <Button color="primary" variant="contained" onClick={onSendFriendRequest}>
        Add
      </Button>
    ) : (
      <DropdownControl buttonLabel={buttonLabel}>
        <MenuList>
          {user.is_friend && (
            <CustomMenuItem onClick={onDeleteFriend}>
              Remove from friends
            </CustomMenuItem>
          )}
          {user.is_requested && (
            <CustomMenuItem onClick={cancelFriendRequest}>
              Cancel request
            </CustomMenuItem>
          )}
          {user.is_requesting && (
            <>
              <CustomMenuItem onClick={onAcceptFriendRequest}>
                Confirm request
              </CustomMenuItem>
              <CustomMenuItem onClick={onDeclineFriendRequest}>
                Decline request
              </CustomMenuItem>
            </>
          )}
        </MenuList>
      </DropdownControl>
    )
  }
  const dropdownMenu = () => {
    return (
      <MoreDropdownBtn>
        <VerticalMenu
          menuItems={dropdownMenuItems}
          classes={"more-dropdown-menu__list"}
        />
      </MoreDropdownBtn>
    )
  }

  const dropdownMenuItems = [
    !user.bookmark
      ? {
          type: "button",
          action: onAddToBookmarks,
          label: "Add to bookmarks",
        }
      : {
          type: "button",
          action: onDeleteBookmark,
          label: "Remove from bookmarks",
        },
    !isCurrentUser && {
      type: "button",
      action: onComplainModalOpen,
      label: !user.is_reported ? "Report" : "Reported",
      disabled: user.is_reported,
    },
    !isCurrentUser && {
      type: "button",
      action: onShowBlacklistModal,
      label: "Add to blacklist",
    },
  ]

  return !user.is_blocked ? (
    <div className={`btn-group`}>
      {/*<ComplaintsModal*/}
      {/*  open={this.state.complaintsModal}*/}
      {/*  user_id={this.props.userInfo.id}*/}
      {/*  onClose={this.hideComplaintsModal}*/}
      {/*  callback={() => this.setState({ isReported: true })}*/}
      {/*/>*/}
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          history.push(
            generatePath(routes.BOOK_MEET_AUTHORIZED, {
              userId: user.id,
              hash: user.id,
            })
          )
        }}
      >
        Create Appointment
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          history.push({
            pathname: routes.CONVERSATION,
            search: `?userId=${userId}`,
          })
        }}
      >
        Write message
      </Button>

      <div>{dropdownControl()}</div>
      <div style={{ marginLeft: "10px" }}>{dropdownMenu()}</div>
      {/*{this.state.openBookmarksModal && (*/}
      {/*  <AddToFolderModal*/}
      {/*    open={this.state.openBookmarksModal}*/}
      {/*    handleClose={this.handleClose}*/}
      {/*  />*/}
      {/*)}*/}

      {isBlacklistModalOpen && (
        <BlacklistModal
          open={isBlacklistModalOpen}
          onClose={() => setIsBlacklistModalOpen(false)}
        />
      )}
    </div>
  ) : (
    <div className={"btn-group mt20"}>
      <Button
        variant="contained"
        color="primary"
        onClick={onRemoveFromBlacklist}
      >
        Remove from blacklist
      </Button>

      <Button
        variant="outlined"
        color="primary"
        onClick={toggleContentVisibility}
      >
        {!isContentVisible ? "Show profile content" : "Hide profile content"}
      </Button>
    </div>
  )
}

export default UserProfileControls

// class UserProfileControls extends Component {
//   state = {
//     isFriend: !!(this.props.userInfo && this.props.userInfo.is_friend),
//     isRequested: !!(this.props.userInfo && this.props.userInfo.is_requested),
//     isRequesting: !!(this.props.userInfo && this.props.userInfo.is_requesting),
//     noRelations: !!(
//       this.props.userInfo &&
//       !this.props.userInfo.is_friend &&
//       !this.props.userInfo.is_requested &&
//       !this.props.userInfo.is_requesting
//     ),
//     isReported: !!(this.props.userInfo && this.props.userInfo.is_reported),
//     bookmarked: !!this.props.userInfo.bookmark,
//     complaintsModal: false,
//     openBookmarksModal: false,
//   }
//
//   setStateAsync(state) {
//     return new Promise((resolve) => {
//       this.setState(state, resolve)
//     })
//   }
//
//   addToBookmarks = async () => {
//     const {
//       userInfo: { id },
//     } = this.props
//
//     try {
//       await this.props.createBookmark({ user_id: id })
//       await this.props.foldersList()
//       this.setState({ bookmarked: true })
//       await this.props.enqueueSnackbar("Saved to bookmarks", {
//         autoHideDuration: 5000,
//         action: (key) => {
//           return (
//             <>
//               <Button
//                 color="primary"
//                 onClick={() => {
//                   this.props.closeSnackbar(key)
//                   this.props.history.push(routes.BOOKMARKS_PEOPLE)
//                 }}
//               >
//                 View
//               </Button>
//               {this.props.bookmarks.folders.length > 0 && (
//                 <Button
//                   color="primary"
//                   onClick={() => this.handleClickOpen("addToFolder")}
//                 >
//                   Add to folder
//                 </Button>
//               )}
//             </>
//           )
//         },
//       })
//     } catch ({ error }) {
//       this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
//     }
//   }
//
//   deleteBookmark = async () => {
//     const {
//       userInfo: { id },
//     } = this.props
//
//     try {
//       await this.props.deleteBookmark({ user_id: id })
//       this.setState({ bookmarked: false })
//     } catch ({ error }) {
//       this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
//     }
//   }
//   sendFriendRequest = async () => {
//     const {
//       userInfo: { id },
//     } = this.props
//
//     try {
//       await this.props.sendFriendRequest(id)
//       this.setState({
//         isRequested: true,
//         noRelations: false,
//       })
//     } catch ({ error }) {
//       this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
//     }
//   }
//
//   acceptFriendRequest = async () => {
//     const {
//       userInfo: { id },
//     } = this.props
//
//     try {
//       await this.props.acceptFriendRequest(id)
//       this.setState({
//         isRequesting: false,
//         isFriend: true,
//       })
//     } catch ({ error }) {
//       this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
//     }
//   }
//
//   cancelFriendRequest = async () => {
//     const {
//       userInfo: { id },
//     } = this.props
//
//     try {
//       await this.props.cancelFriendRequest(id)
//       this.setState({
//         isRequested: false,
//         noRelations: true,
//       })
//     } catch ({ error }) {
//       this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
//     }
//   }
//
//   declineFriendRequest = async () => {
//     const {
//       userInfo: { id },
//     } = this.props
//
//     try {
//       await this.props.declineFriendRequest(id)
//       this.setState({
//         isRequesting: false,
//         noRelations: true,
//       })
//     } catch ({ error }) {
//       this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
//     }
//   }
//
//   deleteFriend = async () => {
//     const {
//       userInfo: { id },
//     } = this.props
//
//     try {
//       await this.props.deleteFriend(id)
//       this.setState({
//         isFriend: false,
//         noRelations: true,
//       })
//     } catch ({ error }) {
//       this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
//     }
//   }
//
//   showComplaintsModal = () => {
//     this.setState({ complaintsModal: true })
//   }
//
//   hideComplaintsModal = () => {
//     this.setState({ complaintsModal: false })
//   }
//
//   handleClickOpen = () => {
//     this.setState({ openBookmarksModal: true })
//   }
//
//   handleClose = () => {
//     this.setState({ openBookmarksModal: false })
//   }
//
//   render() {
//     const { userInfo } = this.props
//     const { isFriend, isRequested, isRequesting, noRelations } = this.state
//
//     const dropdownControl = () => {
//       const buttonLabel = isFriend
//         ? "Added"
//         : isRequesting
//           ? "Confirm request"
//           : isRequested
//             ? "Request sent"
//             : ""
//
//       return noRelations ? (
//         <Button
//           color="primary"
//           variant="contained"
//           onClick={this.sendFriendRequest}
//         >
//           Add
//         </Button>
//       ) : (
//         <DropdownControl buttonLabel={buttonLabel}>
//           <MenuList>
//             {isFriend && (
//               <CustomMenuItem onClick={this.deleteFriend}>
//                 Remove from friends
//               </CustomMenuItem>
//             )}
//             {isRequested && (
//               <CustomMenuItem onClick={this.cancelFriendRequest}>
//                 Cancel request
//               </CustomMenuItem>
//             )}
//             {isRequesting && (
//               <>
//                 <CustomMenuItem onClick={this.acceptFriendRequest}>
//                   Confirm request
//                 </CustomMenuItem>
//                 <CustomMenuItem onClick={this.declineFriendRequest}>
//                   Decline request
//                 </CustomMenuItem>
//               </>
//             )}
//           </MenuList>
//         </DropdownControl>
//       )
//     }
//     const dropdownMenu = () => {
//       return (
//         <MoreDropdownBtn>
//           <VerticalMenu
//             menuItems={dropdownMenuItems}
//             classes={"more-dropdown-menu__list"}
//           />
//         </MoreDropdownBtn>
//       )
//     }
//     const dropdownMenuItems = [
//       !this.state.bookmarked
//         ? {
//             type: "button",
//             action: this.addToBookmarks,
//             label: "Add to bookmarks",
//           }
//         : {
//             type: "button",
//             action: this.deleteBookmark,
//             label: "Remove from bookmarks",
//           },
//       this.props.auth.userData.id !== this.props.userInfo.id && {
//         type: "button",
//         action: this.showComplaintsModal,
//         label: "Report",
//         disabled: this.state.isReported,
//       },
//       this.props.auth.userData.id !== this.props.userInfo.id && {
//         type: "button",
//         action: this.props.onBlacklistModalOpen,
//         label: "Add to blacklist",
//       },
//     ]
//     return !this.props.isBlacklisted ? (
//       <div
//         className={`btn-group ${
//           this.props.userInfo.highlighted !== 0 ? "mt20" : ""
//         }`}
//       >
//         <ComplaintsModal
//           open={this.state.complaintsModal}
//           user_id={this.props.userInfo.id}
//           onClose={this.hideComplaintsModal}
//           callback={() => this.setState({ isReported: true })}
//         />
//         <Button
//           variant="outlined"
//           color="primary"
//           onClick={() => {
//             this.props.history.push(
//               generatePath(routes.BOOK_MEET_AUTHORIZED, {
//                 userId: userInfo.id,
//                 hash: userInfo.id,
//               })
//             )
//           }}
//         >
//           Create Appointment
//         </Button>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={() => {
//             this.props.history.push({
//               pathname: routes.CONVERSATION,
//               search: `?userId=${userInfo.id}`,
//             })
//           }}
//         >
//           Write message
//         </Button>
//
//         <div>{dropdownControl()}</div>
//         <div style={{ marginLeft: "10px" }}>{dropdownMenu()}</div>
//         {this.state.openBookmarksModal && (
//           <AddToFolderModal
//             open={this.state.openBookmarksModal}
//             handleClose={this.handleClose}
//           />
//         )}
//       </div>
//     ) : (
//       <div className={"btn-group mt20"}>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={this.props.removeFromBlacklist}
//         >
//           Remove from blacklist
//         </Button>
//         {!this.props.isContentVisible ? (
//           <Button
//             variant="outlined"
//             color="primary"
//             onClick={this.props.showUserContent}
//           >
//             Show profile content
//           </Button>
//         ) : (
//           <Button
//             variant="outlined"
//             color="primary"
//             onClick={this.props.hideUserContent}
//           >
//             Hide profile content
//           </Button>
//         )}
//       </div>
//     )
//   }
// }
//
// const mapStateToProps = ({ auth, search, friends, bookmarks }) => ({
//   auth,
//   search,
//   friends,
//   bookmarks,
// })
// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators(
//     {
//       sendFriendRequest,
//       cancelFriendRequest,
//       acceptFriendRequest,
//       declineFriendRequest,
//       deleteFriend,
//       createBookmark,
//       deleteBookmark,
//       foldersList,
//     },
//     dispatch
//   )
//
// export default withRouter(
//   connect(
//     mapStateToProps,
//     mapDispatchToProps
//   )(withSnackbar(UserProfileControls))
// )
