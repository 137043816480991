import React from "react"
import { usePostCtx } from "../PostContext"
import { generatePath } from "react-router-dom"
import * as routes from "../../../../../library/constants/routes"
import styled from "@emotion/styled"
import { useSelector } from "react-redux"
import { PostAuthorAvatar, PostControls, PostMeta } from "../components"
import { useCurrentUser } from "../../../../../hooks/data/user/useUser"
import { getUserName } from "../../../../../library/utils/user/userUtils"

const Container = styled.div`
  display: flex;
  margin-bottom: ${(props) => (props.isRepost ? "10px" : 0)};

  @supports (display: grid) {
    display: grid;
    grid-template-columns: max-content 1fr max-content;
  }
`

const PostHeader = ({ isEditMode, isRepost = false }) => {
  const { currentUser } = useCurrentUser()
  const { post, pageOwnerUser } = usePostCtx()

  const getAuthorUrl = () => {
    if (post.creator_user) {
      return generatePath(routes.USER, { userId: post.creator_user.id })
    }

    if (post.creator_group) {
      return generatePath(routes.GROUP, { groupId: post.creator_group.id })
    }

    return generatePath(routes.USER, { userId: currentUser.id })
  }

  const getOriginAuthorUrl = () => {
    if (post.origin_user) {
      return generatePath(routes.USER, { userId: post.origin_user.id })
    }

    if (post.origin_group) {
      return generatePath(routes.GROUP, { groupId: post.origin_group.id })
    }

    return ""
  }

  const getAuthorName = () => {
    if (post.creator_user) return getUserName(post.creator_user)
    if (post.creator_group) return post.creator_group.name

    return getUserName(pageOwnerUser)
  }

  const getOriginAuthorName = () => {
    if (post.origin_user) return getUserName(post.origin_user)
    if (post.origin_group) return post.origin_group.name

    return ""
  }

  const authorUrl = isRepost ? getOriginAuthorUrl() : getAuthorUrl()
  const authorName = isRepost ? getOriginAuthorName() : getAuthorName()

  return (
    <Container isRepost={isRepost}>
      <PostAuthorAvatar authorUrl={authorUrl} isRepost={isRepost} />
      <PostMeta
        authorUrl={authorUrl}
        authorName={authorName}
        isRepost={isRepost}
      />
      {!isRepost && <PostControls isEditMode={isEditMode} />}
    </Container>
  )
}

export default PostHeader
