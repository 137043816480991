import React, { Component } from "react"
import "./friendsHeader.scss"
import { NavLink, withRouter } from "react-router-dom"
import TextField from "@material-ui/core/TextField/TextField"
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment"
import SearchOutlined from "@material-ui/icons/SearchOutlined"
import * as qs from "query-string"

class FriendsHeader extends Component {
  state = {
    activeTab: this.props.activeTab ? this.props.activeTab : "all",
    query: "",
  }

  componentDidMount() {
    const { name, q } = qs.parse(this.props.location.search)
    this.setState({ query: name ? name : q && q !== "undefined" ? q : "" })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.match.path !== prevProps.match.path) {
      this.setState({
        query: "",
      })
    }
  }

  handleChange = (event) => {
    this.setState({ query: event.target.value })
  }

  processSearch = (event) => {
    event.preventDefault()
    const { query } = this.state

    this.props.search(query.trim())
  }

  render() {
    const { query } = this.state
    const { match, noResults, noResultsLabel } = this.props

    const iconStyle = { color: "#9b9a9b" }

    return (
      <div className="box friends-header">
        <nav className="h-tabs jcfs">
          <ul className="h-tabs__nav-list">
            {this.props.menuItems.map(({ label, path, counter }) => (
              <li
                className={`h-tabs__item ${
                  match.path === path ? "h-tabs__item--active" : ""
                }`}
                key={label}
              >
                <NavLink
                  exact
                  to={path}
                  className="h-tabs__link"
                  activeClassName={"h-tabs__item--active"}
                >
                  {/*{label} {counter}*/}
                  {label}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
        <div className="box__content">
          <form onSubmit={this.processSearch}>
            <TextField
              className="search-header__input full-width"
              id="search-header-input"
              placeholder="Type any name, title, word"
              margin="none"
              autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined style={iconStyle} />
                  </InputAdornment>
                ),
              }}
              value={query}
              onChange={(e) => this.handleChange(e)}
            />
          </form>
          {noResults && (
            <div className="aic color-black-54 f jcc pt15">
              {noResultsLabel || "Result not found"}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(FriendsHeader);
