// returns an array of objects from another object (arg: arr)
// whose ids correspond to the specified values of other array (arg: ids)
export const getArrayByIds = (arr, ids) => {
  let result = []

  ids.forEach((id) => {
    arr.forEach((item) => {
      if (item.id === id) {
        result.push(item)
      }
    })
  })

  return result
}

// returns a string of item names separated with comma from array of objects (arg: arr)
export const renderArrayOfNames = (arr) => {
  return arr
    .map((item, i) => {
      return i < arr.length - 1 ? `${item.name}, ` : item.name
    })
    .join("")
}

// returns first object from array (arg: arr) with matching id value
export const getObjectById = (arr, id) => {
  return arr.find((item) => item.id === id)
}

// check if array (arg: arr) has item (arg: item)
export const arrayHasItem = (arr, item) => {
  return arr.indexOf(item) !== -1
}

// changes array (arg: arr) item with new one (arg: item)
export const editArrayRecord = (arr, item) =>
  arr.map((r) => (r.id !== item.id ? r : item))

// checks if element is last in array (arg: arr) by its index (arg: idx)
export const isLast = (arr, idx) => arr.length === idx + 1

//returns an array of Ids from array of objects (arg: arr)
export const getArrayIds = (arr) => arr.map((item) => item.id)
