import React from "react"
import "./userProfileKnowCred.scss"
import { generatePath, Link } from "react-router-dom"
import AddOutlined from "@material-ui/icons/AddOutlined"
import * as routes from "../../../../../library/constants/routes"
import { KeyboardArrowRight } from "@material-ui/icons"
import { useSelector } from "react-redux"
import { useQuery } from "@tanstack/react-query"
import { call } from "../../../../../library/networking/API"
import { KNOWCREDS } from "../../../../../library/store/actions/types/knowCredsTypes"
import PlainLink from "../../../../../components/ui/link"
import { useIdParam } from "../../../../../hooks/useIdParam"
import HorizontalScroll from "../../../../../components/ui/HorizontalScroll"
import CredMini from "./components/credMini"
import { useUser } from "../../../../../hooks/data/user/useUser"

const UserProfileCreds = () => {
  const userId = useIdParam("userId")

  const { user, isCurrentUser } = useUser(userId)

  const { data, isSuccess } = useQuery({
    queryKey: ["profile-creds", { userId }],
    queryFn: async () => {
      const response = await call(KNOWCREDS.LIST, {
        user_id: userId,
        status_type: 0,
        offset: 0,
        limit: 4,
      })

      return response.data
    },
    refetchOnWindowFocus: false,
    staleTime: 60 * 1000,
  })

  const canCreateCred = user.is_friend || isCurrentUser

  const allCredsList = generatePath(routes.KNOWCRED, {
    userId,
  })

  const knowCredCreateLink = isCurrentUser
    ? routes.KNOWCRED_CREATE
    : {
        pathname: routes.KNOWCRED_CREATE,
        search: `?for=${user.id}`,
      }

  return (
    isSuccess &&
    !!data.knowcreds.length && (
      <div className={"box p-4 -order-1 md:order-none"}>
        <div className="flex items-center justify-between w-full mb-3">
          <Link to={allCredsList}>Creds</Link>
          {canCreateCred && (
            <PlainLink to={knowCredCreateLink} className={"!text-sm"}>
              <AddOutlined /> Add Cred
            </PlainLink>
          )}
        </div>
        <div className="grid grid-flow-row grid-cols-2 gap-2 justify-start">
          {data.knowcreds.map((k, i) => (
            <Link
              to={generatePath(routes.KNOWCRED_SINGLE, {
                knowCredId: k.id,
              })}
              key={k.id}
            >
              <CredMini cred={k} />
            </Link>
          ))}
        </div>
        <div className="w-full hidden md:block mt-3">
          <PlainLink to={allCredsList}>
            Show all <KeyboardArrowRight />
          </PlainLink>
        </div>
      </div>
    )
  )
}

export default UserProfileCreds

// class UserProfileKnowCred extends Component {
//   state = {
//     list: [],
//     counter: this.props.userInfo.knowcreds_number,
//     dataReceived: false,
//   }
//
//   setStateAsync(state) {
//     return new Promise((resolve) => {
//       this.setState(state, resolve)
//     })
//   }
//
//   async componentDidMount() {
//     const {
//       auth: { userData },
//     } = this.props
//     const { id } = this.props.userInfo
//
//     try {
//       if (this.props.type === "my-profile") {
//         await this.props.lastKnowCredsList({
//           list_type: "my",
//           token: userData.token,
//           user_id: userData.id,
//         })
//         await this.setStateAsync({
//           list: this.props.knowCreds.myLastKnowCredsList,
//           dataReceived: true,
//         })
//       } else {
//         await this.props.lastKnowCredsList({
//           list_type: "person",
//           token: userData.token,
//           user_id: id,
//         })
//         await this.setStateAsync({
//           list: this.props.knowCreds.lastKnowCredsList,
//           dataReceived: true,
//         })
//       }
//     } catch ({ error }) {
//       this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
//     }
//   }
//
//   render() {
//     const { counter, dataReceived, list } = this.state
//     const { userInfo, type } = this.props
//
//     const knowCredCreateLink =
//       type === "my-profile"
//         ? routes.KNOWCRED_CREATE
//         : {
//             pathname: routes.KNOWCRED_CREATE,
//             search: `?for=${userInfo.id}`,
//           }
//
//     if (!dataReceived) {
//       return null
//     } else {
//       if (!list.length) return null
//
//       return (
//         <div className="box user-profile-knowcred">
//           <div className="f aic jcsb full-width">
//             {/*<Link to={generatePath(routes.KNOWCRED, {userId: this.props.userInfo.id})} >KnowCred <span className="color-black-54">{counter}</span></Link>*/}
//             <Link
//               to={generatePath(routes.KNOWCRED, {
//                 userId: this.props.userInfo.id,
//               })}
//             >
//               Creds
//             </Link>
//             {userInfo.is_friend || this.props.type === "my-profile" ? (
//               <Link to={knowCredCreateLink} className="btn btn--plain">
//                 <AddOutlined /> Add Cred
//               </Link>
//             ) : null}
//           </div>
//           <div className="user-profile-knowcred__list">
//             {list.map((knowCred) => (
//               <Link
//                 to={generatePath(routes.KNOWCRED_SINGLE, {
//                   knowCredId: knowCred.id,
//                 })}
//                 className="user-profile-knowcred__list-item"
//                 key={knowCred.id}
//               >
//                 {!knowCred.public && (
//                   <div className="knowcred-card__lock-icon">
//                     <LockOutlined className="color-primary" />
//                   </div>
//                 )}
//                 <img
//                   src={knowCred.background_image}
//                   className="user-profile-knowcred__background"
//                   style={{ borderColor: knowCred.border_color }}
//                 />
//                 <img
//                   src={knowCred.foreground_image}
//                   className="user-profile-knowcred__foreground"
//                 />
//               </Link>
//             ))}
//           </div>
//           <div className="full-width d-none-xs">
//             <Link
//               to={generatePath(routes.KNOWCRED, {
//                 userId: this.props.userInfo.id,
//               })}
//               className="btn btn--plain"
//             >
//               Show all <KeyboardArrowRight />
//             </Link>
//           </div>
//         </div>
//       )
//     }
//   }
// }
//
// const mapStateToProps = ({ auth, person, knowCreds }) => ({
//   auth,
//   person,
//   knowCreds,
// })
// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators({ lastKnowCredsList }, dispatch)
//
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withSnackbar(UserProfileKnowCred))
