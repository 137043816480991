import React, { Component } from "react"
import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import Loader from "../../../../components/ui/loader"
import ListSearchHeader from "../../../../components/ui/listSearchHeader/listSearchHeader"
import ProductsListItem from "../../marketplace/productsList/productsListItem/productsListItem"
import avatarDefault from "../../../../assets/img/user_avatar.svg"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { getPerson } from "../../../../library/store/actions/creators/personCreators"
import {
  clearProductsList,
  getProductsList,
} from "../../../../library/store/actions/creators/productsCreators"
import * as qs from "query-string"
import withScroll from "../../../../hocs/withScroll/withScroll"
import { LIMIT } from "../../../../library/constants/limits"
import getErrorText from "../../../../library/constants/errorTypes"
import * as _ from "lodash"
import ProductsListFilter from "../../marketplace/productsList/productsListFilter/productsListFilter"
import SEO from "../../../../components/SEO"

class UserProducts extends Component {
  state = {
    list: [],
    dataReceived: false,
  }

  searchParams = {}

  async componentDidMount() {
    const {
      auth: { userData },
      match: { params },
      location: { search },
    } = this.props
    const { q: query, grades, subjects, contentTypes } = qs.parse(search)
    this.searchParams = {
      name: query || undefined,
      subjects: subjects
        ? _.map(_.split(subjects, ","), _.parseInt)
        : undefined,
      grades: grades ? _.map(_.split(grades, ","), _.parseInt) : undefined,
      content_types: contentTypes
        ? _.map(_.split(contentTypes, ","), _.parseInt)
        : undefined,
    }
    try {
      await this.props.getPerson(userData.token, parseInt(params.userId))
      await this.props.getProductsList({
        user_id: parseInt(params.userId),
        ...this.searchParams,
      })
      this.setState({
        list: this.props.products.list,
        dataReceived: true,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      match: { params },
      location: { search },
    } = this.props
    const { q: query, grades, subjects, contentTypes } = qs.parse(search)
    this.searchParams = {
      name: query || undefined,
      subjects: subjects
        ? _.map(_.split(subjects, ","), _.parseInt)
        : undefined,
      grades: grades ? _.map(_.split(grades, ","), _.parseInt) : undefined,
      content_types: contentTypes
        ? _.map(_.split(contentTypes, ","), _.parseInt)
        : undefined,
    }

    if (search && search !== prevProps.location.search) {
      try {
        await this.props.clearProductsList()
        await this.props.getProductsList({
          user_id: parseInt(params.userId),
          ...this.searchParams,
        })
        this.setState({ list: this.props.products.list })
      } catch ({ error }) {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    }
  }

  searchProducts = (name) => {
    const { history } = this.props
    history.push({
      search: `?q=${name}`,
    })
  }

  componentWillUnmount() {
    this.props.clearProductsList()
  }

  onScroll = async () => {
    const {
      match: { params },
    } = this.props

    if (!this.state.dataReceived) return

    this.setState({ dataReceived: false })

    try {
      await this.props.getProductsList({
        user_id: parseInt(params.userId),
        offset: this.state.list.length,
        limit: LIMIT,
        ...this.searchParams,
      })
      this.setState({ list: this.props.products.list, dataReceived: true })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  render() {
    const { list, dataReceived } = this.state
    const {
      person: { personData },
      match: { params },
    } = this.props

    return (
      <div className="common-page__content">
        <SEO title={"Products"} />
        <main className="common-page__main">
          <ListSearchHeader
            title={
              personData
                ? `${personData.first_name + "`s"} products`
                : "products"
            }
            placeholder="Start typing product name"
            counter={list.length}
            search={this.searchProducts}
            noResults={dataReceived && !list.length}
            noResultsLabel={
              personData
                ? `${personData.first_name} doesn't have products yet`
                : ""
            }
          />
          <div className="products-list">
            {list.map((item) => (
              <ProductsListItem
                product={item}
                user={personData}
                manageable={false}
                key={item.id}
              />
            ))}
          </div>
          {!dataReceived && <Loader />}
        </main>
        {personData && (
          <aside className="common-page__sidebar">
            <div className="box aside-header">
              <Link
                to={generatePath(routes.USER, { userId: params.userId })}
                className="box__content aside-header__content"
              >
                <img
                  src={personData.photo || avatarDefault}
                  className="aside-header__img"
                  alt=""
                />
                <div className="aside-header__info">
                  <div className="aside-header__name">
                    {`${personData.first_name} ${personData.last_name}`}
                  </div>
                  <div className="aside-header__help">back to page</div>
                </div>
              </Link>
            </div>
            <ProductsListFilter
              hiddenFields={["states", "standardSubjects"]}
              pathname={generatePath(routes.USER_PRODUCTS, {
                userId: personData.id,
              })}
            />
          </aside>
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ auth, person, products }) => ({
  auth,
  person,
  products,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { getPerson, getProductsList, clearProductsList },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withScroll(UserProducts)))
