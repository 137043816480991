import React, { useEffect, useState } from "react"
import { isLast } from "../../../library/utils/arrays"
import { PlainButton } from "../../v2/controls"
import { Divider } from "../../v2/layout/forms/form/form"
import TimePeriodPicker from "../TimePeriodPicker"
import { localTimeToUTC } from "../../../library/utils/timeString/timeString"

const getDefaultPeriod = (num) => ({
  from: localTimeToUTC("10:00"),
  to: localTimeToUTC("11:00"),
  label: `Break ${num}`,
})

const prepareBreaksForInit = (breaks) => {
  return breaks.map(([from, to], i) => ({ from, to, label: `Break ${i + 1}` }))
}

const prepareBreaksForSave = (breaks) => {
  return breaks.map(({ from, to }) => [from, to])
}

const MultipleTimePeriodsPicker = ({ values = [], onChange }) => {
  const [periods, setPeriods] = useState(
    values.length ? prepareBreaksForInit(values) : []
  )

  const setPeriod = (index, fieldName, time) => {
    setPeriods((prevState) => {
      return prevState.map((item, i) =>
        i === index
          ? {
              ...item,
              [fieldName]: time,
            }
          : item
      )
    })
  }

  const onAddPeriod = () => {
    setPeriods((prevState) => [
      ...prevState,
      getDefaultPeriod(prevState.length + 1),
    ])
  }

  const onDeletePeriod = (i) => {
    const newPeriods = periods.filter((item, index) => i !== index)
    setPeriods(newPeriods)
  }

  useEffect(() => {
    const data = prepareBreaksForSave(periods)
    onChange(data)
  }, [periods])

  const canAdd = periods.length < 10

  return (
    <div>
      <div className="form__fieldset">
        <span className={"form__label"}>Breaks</span>
        <div className="form__fieldbox">
          <div className="form__input form__input--select">
            {periods.map((period, i) => (
              <div className={!isLast(periods, i) ? "mb20" : "mt5"} key={i}>
                <div className="f aic jcsb mb10">
                  <p>{period.label || "Time period"}:</p>
                  <PlainButton
                    actionType={"delete"}
                    onClick={() => onDeletePeriod(i)}
                    actionText={"Delete break"}
                  />
                </div>

                <TimePeriodPicker
                  startTime={period.from}
                  endTime={period.to}
                  compact
                  label={period.label}
                  onChange={({ start, end }) => {
                    setPeriod(i, "from", start)
                    setPeriod(i, "to", end)
                  }}
                />
                <Divider className={"mt15"} />
              </div>
            ))}
            {canAdd && (
              <PlainButton
                actionType={"add"}
                onClick={onAddPeriod}
                actionText={"Add break"}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MultipleTimePeriodsPicker
