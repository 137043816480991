import * as qs from "query-string"

const composeQuery = ({ fields, history, path }) => {
  const result = qs.stringify(fields, { arrayFormat: "comma" })
  history.push({
    pathname: path,
    search: `?${result}`,
  })
}

export default composeQuery;
