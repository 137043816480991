import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { call } from "../../library/networking/API"
import {
  mutateInfinite,
  mutateRecord,
  mutationTypes,
} from "../../library/utils/reactQuery"
import { BOOKMARKS } from "../../library/store/actions/types/bookmarksTypes"

export const useFoldersList = (options = {}) => {
  const { data, ...foldersQuery } = useQuery({
    queryKey: [BOOKMARKS.FOLDERS.LIST, {}],
    ...options,
  })

  return {
    data,
    ...foldersQuery,
  }
}

export const useFolder = ({ options = {} }) => {
  const { data: folder, ...foldersQuery } = useQuery({
    queryKey: [BOOKMARKS.FOLDERS.GET],
  })

  return {
    folder,
    ...foldersQuery,
  }
}

export const useFoldersMutations = () => {
  const queryClient = useQueryClient()

  const createFolder = useMutation({
    mutationFn: async ({ name }) => {
      await call(BOOKMARKS.FOLDERS.CREATE, { name })
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [BOOKMARKS.FOLDERS.LIST],
      }),
  })

  const updateFolder = useMutation({
    mutationFn: async ({ id, name }) => {
      await call(BOOKMARKS.FOLDERS.UPDATE, { id, name })
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [BOOKMARKS.FOLDERS.LIST],
      }),
  })

  const deleteFolder = useMutation({
    mutationFn: async ({ id, name }) => {
      await call(BOOKMARKS.FOLDERS.DELETE, { id })
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [BOOKMARKS.FOLDERS.LIST],
      }),
  })

  const addToFolder = useMutation({
    mutationFn: async ({ bookmark_id, folders }) => {
      await call(BOOKMARKS.FOLDERS.BIND, {
        bookmark_id,
        folders,
      })
    },
  })

  const removeFromFolder = useMutation({
    mutationFn: async ({ bookmark_id, folder_id }) => {
      await call(BOOKMARKS.FOLDERS.UNBIND, {
        bookmark_id,
        folder_id,
      })
    },
  })

  return {
    createFolder,
    updateFolder,
    deleteFolder,
    addToFolder,
    removeFromFolder,
  }
}

export const useBookmarksMutations = ({ queryKey, mutationType }) => {
  const queryClient = useQueryClient()

  const createBookmark = useMutation({
    mutationFn: async ({
      params: {
        user_id,
        group_id,
        product_id,
        post_id,
        knowcred_id,
        standard_id,
        record_id,
      },
    }) => {
      const response = await call(BOOKMARKS.CREATE, {
        user_id,
        group_id,
        product_id,
        post_id,
        knowcred_id,
        standard_id,
        record_id,
      })

      return response.data
    },
    onSuccess: async (data, { updater }) => {
      if (mutationType === mutationTypes.record) {
        await mutateRecord({
          queryClient,
          key: queryKey,
          recordUpdater: (r) => updater(r, data.id),
        })
      }

      if (mutationType === mutationTypes.infinite) {
        await mutateInfinite({
          queryClient,
          key: queryKey,
          itemUpdater: (r) => updater(r, data.id),
        })
      }
    },
  })

  const deleteBookmark = useMutation({
    mutationFn: async ({
      params: {
        user_id,
        group_id,
        product_id,
        post_id,
        knowcred_id,
        standard_id,
        record_id,
      },
    }) => {
      await call(BOOKMARKS.DELETE, {
        user_id,
        group_id,
        product_id,
        post_id,
        knowcred_id,
        standard_id,
        record_id,
      })
    },
    onSuccess: async (data, { updater }) => {
      if (mutationType === mutationTypes.record) {
        await mutateRecord({
          queryClient,
          key: queryKey,
          recordUpdater: updater,
        })
      }

      if (mutationType === mutationTypes.infinite) {
        await mutateInfinite({
          queryClient,
          key: queryKey,
          itemUpdater: (r) => updater(r),
        })
      }
    },
  })

  return { createBookmark, deleteBookmark }
}
