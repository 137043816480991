import React, { Component } from "react"
import { generatePath, Link, Route, Switch } from "react-router-dom"
import * as routes from "../../../../../../../../../library/constants/routes"
import Loader from "../../../../../../../../../components/ui/loader"
import VerticalMenu from "../../../../../../../../../components/ui/verticalMenu"
import { bindActionCreators } from "redux"
import {
  clearCurrentGroup,
  getGroup,
} from "../../../../../../../../../library/store/actions/creators/groupsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import {
  clearCurrentCalendar,
  clearCurrentScheduleSubject,
  getCalendar,
  getScheduleSubject,
} from "../../../../../../../../../library/store/actions/creators/calendarCreators"
import GroupSettingsCalendarUnits from "../groupSettingsCalendarUnits/groupSettingsCalendarUnits"
import GroupSettingsCalendarSubjectInfo from "../groupSettingsCalendarSubjectInfo/groupSettingsCalndarSubjectInfo"
import { getGroupLogo } from "../../../../../../../../../library/utils/getGroupLogo"
import SEO from "../../../../../../../../../components/SEO"

class GroupSettingsCalendarSubject extends Component {
  state = {
    dataReceived: false,
  }

  async componentDidMount() {
    const id = parseInt(this.props.match.params.groupId)

    try {
      await this.props.getGroup({ id })
      if (
        this.props.groups.currentGroup &&
        this.props.groups.currentGroup.deleted
      ) {
        return this.props.history.push(
          generatePath(routes.GROUP, { groupId: id })
        )
      }
      await this.props.getCalendar({
        id: parseInt(this.props.match.params.calendarId),
      })
      await this.props.getScheduleSubject({
        id: parseInt(this.props.match.params.subjectId),
      })
      this.setState({ dataReceived: true })
    } catch ({ description }) {
      this.props.enqueueSnackbar(description, { variant: "error" })
    }
  }

  componentWillUnmount() {
    // this.props.clearCurrentGroup();
    // this.props.clearCurrentCalendar();
    this.props.clearCurrentScheduleSubject()
  }

  render() {
    const {
      match: { params },
    } = this.props
    const menuItems = [
      {
        path: generatePath(
          this.props.groups.currentGroup !== null &&
            this.props.groups.currentGroup.type === 5
            ? routes.GROUP_SETTINGS_DISTRICT_CALENDAR_SUBJECT
            : routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SUBJECT,
          {
            groupId: params.groupId,
            calendarId: params.calendarId,
            subjectId: params.subjectId,
          }
        ),
        type: "parent",
        label: "Subject info",
      },
      {
        path: generatePath(
          this.props.groups.currentGroup !== null &&
            this.props.groups.currentGroup.type === 5
            ? routes.GROUP_SETTINGS_DISTRICT_CALENDAR_UNITS
            : routes.GROUP_SETTINGS_SCHOOL_CALENDAR_UNITS,
          {
            groupId: params.groupId,
            calendarId: params.calendarId,
            subjectId: params.subjectId,
          }
        ),
        type: "parent",
        label: "Units",
      },
    ]

    return (
      <>
        <div className="common-page__content">
          <SEO title={"Subject | Calendar | Group Settings"} />
          <main className="common-page__main">
            {this.state.dataReceived ? (
              <Switch>
                <Route
                  exact
                  path={routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SUBJECT}
                  component={GroupSettingsCalendarSubjectInfo}
                />
                <Route
                  exact
                  path={routes.GROUP_SETTINGS_DISTRICT_CALENDAR_SUBJECT}
                  component={GroupSettingsCalendarSubjectInfo}
                />
                <Route
                  exact
                  path={routes.GROUP_SETTINGS_SCHOOL_CALENDAR_UNITS}
                  component={GroupSettingsCalendarUnits}
                />
                <Route
                  exact
                  path={routes.GROUP_SETTINGS_DISTRICT_CALENDAR_UNITS}
                  component={GroupSettingsCalendarUnits}
                />
              </Switch>
            ) : (
              <Loader />
            )}
          </main>
          <aside className="common-page__sidebar">
            {this.state.dataReceived && (
              <div className="box aside-header">
                <Link
                  to={generatePath(
                    this.props.groups.currentGroup.type === 5
                      ? routes.GROUP_SETTINGS_DISTRICT_CALENDAR_SUBJECTS
                      : routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SUBJECTS,
                    { groupId: params.groupId, calendarId: params.calendarId }
                  )}
                  className="box__content aside-header__content"
                >
                  <img
                    src={getGroupLogo(this.props.groups.currentGroup)}
                    className="aside-header__img"
                    alt=""
                  />
                  <div className="aside-header__info">
                    <div className="aside-header__name">
                      {this.props.groups.currentGroup.name}
                    </div>
                    <div className="aside-header__help">back to subjects</div>
                  </div>
                </Link>
              </div>
            )}
            <VerticalMenu menuItems={menuItems} />
          </aside>
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ auth, groups }) => ({ auth, groups })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getGroup,
      clearCurrentGroup,
      getScheduleSubject,
      clearCurrentScheduleSubject,
      getCalendar,
      clearCurrentCalendar,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(GroupSettingsCalendarSubject))
