import React from "react"
import FormControl from "@material-ui/core/FormControl/FormControl"
import { InputLabel } from "@material-ui/core"
import Select from "@material-ui/core/Select/Select"
import MenuItem from "@material-ui/core/MenuItem"
import CheckOutlined from "@material-ui/icons/CheckOutlined"
import PlainLink from "../../../components/ui/link"
import * as routes from "../../../library/constants/routes"
import { SIGN_UP } from "../../../library/constants/routes"
import Button from "@material-ui/core/Button"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { usePaidPlans, useTieredPriceCalc } from "./usePlans"
import { useSubscriptions } from "../../main/user/userProfileSettings/profileSettingsSubscriptions/useSubscriptions"
import { planFeatures } from "./planFeatures"
import { useToggle } from "../../../hooks/useToggle"
import GroupSeatsSelectionFormModal from "./GroupSeatsSelectionFormModal"
import SubscriptionPeriodFormModal from "./SubscriptionPeriodFormModal"

const PlanCard = ({ plan }) => {
  const { userData } = useSelector(({ auth }) => auth)
  const { isLoggedIn, subscriptions } = useSelector(({ auth }) => auth)
  const [isGroupPickerOpen, toggleGroupPicker] = useToggle(false)
  const [isPeriodPickerOpen, togglePeriodPicker] = useToggle(false)

  const {
    openCheckoutPage,
    applySubscriptionPlan,
    openCustomerPortal,
    calcTieredSubscriptionPrice,
    calcFlatSubscriptionPrice,
  } = useSubscriptions()

  const monthPrice = plan.prices.month
  const yearPrice = plan.prices.year

  const { onSeatsNumberChange, selectedTier, selectedSeatsNumber, totalPrice } =
    useTieredPriceCalc({
      options: plan.seats_options,
      tiers: monthPrice.tiers,
    })

  const { isUserEligible } = usePaidPlans(plan)

  const subscription = subscriptions?.personal_subscription
  const isSubscribed = subscription?.plan_id === plan.id
  const isSubscribedToOtherPlan = !!subscription && !isSubscribed

  const renderSubscriptionButton = () => {
    if (!isLoggedIn) {
      return (
        <Button
          component={Link}
          to={SIGN_UP}
          variant={"contained"}
          color={"primary"}
          fullWidth
        >
          Sign Up
        </Button>
      )
    }

    if (isSubscribed) {
      return (
        <Button
          variant={"contained"}
          color={"primary"}
          fullWidth
          onClick={openCustomerPortal}
          // disabled={!isUserEligible}
        >
          Manage subscription
        </Button>
      )
    }

    if (!isUserEligible) {
      return (
        <>
          <Button
            variant={"contained"}
            color={"primary"}
            fullWidth
            onClick={() => {}}
            disabled
          >
            Subscribe
          </Button>
          {!isUserEligible && (
            <p className={"text-center text-black54 text-xs leading-none mt-1"}>
              Unavailable for your account type
            </p>
          )}
        </>
      )
    }

    if (plan.id === 3) {
      if (!subscriptions.bg_check) {
        return (
          <>
            <Button
              component={Link}
              to={routes.PROFILE_SETTINGS_VERIFICATION}
              variant={"contained"}
              color={"primary"}
              fullWidth
              disabled={!isUserEligible}
            >
              Get verified
            </Button>
          </>
        )
      }
    }

    if (plan.eligible_group_types) {
      return (
        <>
          <Button
            variant={"contained"}
            color={"primary"}
            fullWidth
            onClick={toggleGroupPicker}
          >
            Subscribe group
          </Button>
        </>
      )
    }

    return (
      <>
        <Button
          variant={"contained"}
          color={"primary"}
          fullWidth
          onClick={togglePeriodPicker}
        >
          Subscribe
        </Button>
      </>
    )
  }

  return (
    <div
      className={
        "flex flex-col py-8 px-2 rounded-3xl leading-normal border border-[#979797]"
      }
    >
      <div className={"flex-1 mb-8"}>
        <h2 className={"text-2xl leading-none font-medium text-center mb-3"}>
          {plan.name}
        </h2>
        <h3 className={"font-medium leading-none text-center mb-8"}>
          {plan.target_audience}
        </h3>
        <div className={"mb-8 min-h-48 text-center leading-none"}>
          {monthPrice?.tiers?.length && !!selectedTier ? (
            <div className={"flex flex-col items-center"}>
              <p className={"font-medium text-xl leading-none"}>
                {
                  calcTieredSubscriptionPrice({
                    tiers: plan.prices.month.tiers,
                    period: "month",
                    quantity: selectedSeatsNumber,
                  }).perSeat
                }
              </p>
              <p className={"font-medium"}>
                {
                  calcTieredSubscriptionPrice({
                    tiers: plan.prices.month.tiers,
                    period: "month",
                    quantity: selectedSeatsNumber,
                  }).total
                }
              </p>
              <p className={"my-1"}>or</p>
              <p className={"font-medium text-xl leading-none"}>
                {
                  calcTieredSubscriptionPrice({
                    tiers: plan.prices.year.tiers,
                    period: "year",
                    quantity: selectedSeatsNumber,
                  }).perSeat
                }
              </p>
              <p className={"font-medium"}>
                {
                  calcTieredSubscriptionPrice({
                    tiers: plan.prices.year.tiers,
                    period: "year",
                    quantity: selectedSeatsNumber,
                  }).total
                }
              </p>
              <div className={"mt-8"}>
                <FormControl>
                  <InputLabel id={"seats-number"}>Number of seats</InputLabel>
                  <Select
                    labelId={"seats-number"}
                    name="seats"
                    value={selectedSeatsNumber}
                    onChange={onSeatsNumberChange}
                  >
                    {plan?.seats_options?.map((o) => (
                      <MenuItem value={o} key={o}>
                        {o}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <p className={"mt-2 text-black54 text-[13px] text-center"}>
                  Need more seats? Just{" "}
                  <PlainLink to={routes.CONTACT_FORM} bold inline>
                    Contact us
                  </PlainLink>
                  .
                </p>
              </div>
            </div>
          ) : (
            <>
              <p className={"font-weight-500 font-20"}>
                {
                  calcFlatSubscriptionPrice({
                    period: "month",
                    unit_amount: plan.prices.month.unit_amount,
                  }).total
                }
              </p>
              <p className={"mt5 mb5"}>or</p>
              <p className={"font-weight-500 font-20"}>
                {
                  calcFlatSubscriptionPrice({
                    period: "year",
                    unit_amount: plan.prices.year.unit_amount,
                  }).total
                }
              </p>
            </>
          )}
        </div>
        <div className={"mt-4 mb-8 min-h-40"}>
          <p className={"text-center mb-1 last:mb-0"}>{plan.description}</p>
          <p className={"text-black54 text-center mb-1 last:mb-0"}>
            {plan.verification_warning}
          </p>
        </div>

        <div className="mb-8 px-2">{renderSubscriptionButton()}</div>

        <p className={"mb-4 font-medium text-lg"}>
          {plan.base_plan_relation_message}
        </p>
        <div className={"mb-8"}>
          {planFeatures[plan.id]?.map((f, i) => (
            <div className={"mb-3 flex"} key={i}>
              <CheckOutlined color={"primary"} className={"mr-2"} />
              <span>{f}</span>
            </div>
          ))}
        </div>
      </div>
      <div>
        <p className={"mb-4 font-medium text-lg"}>See more</p>
        <PlainLink to={routes.CONTACT_FORM}>Customer support</PlainLink>
      </div>

      {isGroupPickerOpen && (
        <GroupSeatsSelectionFormModal
          open={isGroupPickerOpen}
          onClose={toggleGroupPicker}
          plan={plan}
        />
      )}

      {isPeriodPickerOpen && (
        <SubscriptionPeriodFormModal
          open={isPeriodPickerOpen}
          onClose={togglePeriodPicker}
          plan={plan}
          currentSubscription={subscription}
        />
      )}
    </div>
  )
}

export default PlanCard
