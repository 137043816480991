import React from "react"
import TextWithExternalURL from "../../../../externalURL/externalURL"
import { PlainButton } from "../../../controls"
import { useExpandableText } from "../../../../../hooks/useExpandableText"

const PostText = ({ message, showPlayer = true, limit = 500 }) => {
  const { text, canExpand, canCollapse, collapse, expand } = useExpandableText({
    message,
    limit,
  })

  return (
    <>
      <TextWithExternalURL
        text={text}
        fullText={message}
        showPlayer={showPlayer}
      />

      {canExpand && <PlainButton onClick={expand}>Show more...</PlainButton>}

      {canCollapse && <PlainButton onClick={collapse}>Show less</PlainButton>}
    </>
  )
}

export default PostText
