import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import Loader from "../../../../../../components/ui/loader"
import { ValidatorForm } from "react-material-ui-form-validator"
import { Button } from "@material-ui/core"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"

class GroupSettingsComments extends Component {
  state = {
    enableComments: true,
    dataReceived: true,
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked })
  }

  processInfoSave = () => {
    console.log(this.state)
  }

  render() {
    const { enableComments, dataReceived } = this.state

    return dataReceived ? (
      <div className="box">
        <div className="box__heading">Comments</div>
        <ValidatorForm
          className="edit-form"
          onSubmit={this.processInfoSave}
          instantValidate={false}
        >
          <div className="edit-form__fieldset edit-form__fieldset--grid">
            <FormControlLabel
              control={
                <Checkbox
                  checked={enableComments}
                  onChange={this.handleChange("enableComments")}
                  value="enableComments"
                  color="primary"
                />
              }
              label="Enable comments"
            />
          </div>
          <div className="h-divider"></div>
          <div className="btn-group jcc">
            <Button color="primary" variant="contained" type="submit">
              Save
            </Button>
          </div>
        </ValidatorForm>
      </div>
    ) : (
      <Loader />
    )
  }
}

const mapStateToProps = ({auth}) => ({auth});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(GroupSettingsComments));
