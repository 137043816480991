import React from "react"
import BoxPage from "../../../../../../components/v2/layout/boxPage/boxPage"

const TeacherTrackingCoursesStudentProgress = () => {
  return (
    <BoxPage title={"Student Progress"}>
      <p className="text-center text-black54">Coming soon.</p>
    </BoxPage>
  )
}

export default TeacherTrackingCoursesStudentProgress
