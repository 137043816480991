import React from "react"
import {
  useGroup,
  useGroupMutations,
} from "../../../../../../hooks/data/groups/groups"
import EntityPhotoPicker from "../../../../../../components/EntityPhotoPicker/EntityPhotoPicker"
import bgDefault from "../../../../../../assets/img/bg-default.png"
import { imageAspectRatios } from "../../../../../../library/constants/images"
import { Tooltip } from "@material-ui/core"
import premiumIcon from "../../../../../../assets/img/icons/check-decagram-outline.svg"
import { GROUP_TYPES } from "../../../../../../library/constants/groupTypes"
import ExpandableText from "../../../../../../components/expandableText"
import returnExternalUrl from "../../../../../../library/utils/returnExternalUrl"
import LanguageOutlined from "@material-ui/icons/LanguageOutlined"
import LocalPhoneOutlined from "@material-ui/icons/LocalPhoneOutlined"
import AccessTime from "@material-ui/icons/AccessTime"
import PlaceOutlined from "@material-ui/icons/PlaceOutlined"
import LinkIcon from "@material-ui/icons/Link"
import { getGroupAddress } from "../../../../../../library/utils/groups"
import defaultDate, {
  defaultMaxDate,
} from "../../../../../../library/constants/defaultDates"
import { format } from "date-fns"
import PlainLink from "../../../../../../components/ui/link"
import GroupHeaderControls from "./GroupHeaderControls"
import { useSelector } from "react-redux"

const GroupPageHeader = () => {
  const { isLoggedIn } = useSelector(({ auth }) => auth)

  const { group, groupHeader, canEdit, isEvent, queryKey } = useGroup()
  const { updateGroup } = useGroupMutations({ queryKey })

  const onHeaderUpdate = async (full_header) => {
    await updateGroup.mutateAsync({ id: group.id, full_header })
  }

  const address = getGroupAddress(group)

  const composeEventDates = () => {
    const { start_date, end_date } = group
    let date = ""

    date +=
      !!start_date && start_date !== defaultDate
        ? format(new Date(start_date), "d MMMM 'at' h:mm aaa")
        : ""
    date += !!(
      end_date &&
      end_date !== start_date &&
      end_date !== defaultMaxDate
    )
      ? ` — ${format(new Date(end_date), "d MMMM 'at' h:mm aaa")}`
      : ""

    return date
  }

  const eventDates = composeEventDates()

  return (
    <div className={"rounded-sm shadow-box bg-white overflow-hidden"}>
      <div
        className="aspect-page-banner p-4 bg-gray-200 relative bg-no-repeat bg-cover bg-center"
        style={{ backgroundImage: `url(${group?.header})` }}
      >
        {canEdit && (
          <EntityPhotoPicker
            photo={groupHeader}
            defaultPhoto={bgDefault}
            onSave={onHeaderUpdate}
            buttonClassName={"right-4 top-4"}
            buttonLabel={"Edit background"}
            aspectRatio={imageAspectRatios.page_banner}
          />
        )}
      </div>
      <div className="p-4">
        <div className="mb-4">
          <div className="w-full flex flex-col items-center mb-4">
            <div className={"flex items-center gap-1 mb-2"}>
              {group?.paid && (
                <Tooltip title="Paid group" arrow>
                  <img src={premiumIcon} alt="Paid group" />
                </Tooltip>
              )}
              <span className="flex items-center py-1 px-2 rounded-full bg-primary5 text-primary text-lg font-medium leading-none">
                {GROUP_TYPES[group?.type]?.title}
              </span>
            </div>

            <h1 className="font-medium text-2xl mb-2">{group?.name}</h1>
            <p className="font-light text-center">
              {`This is ${group?.closed ? "a closed" : "an open"} group`}
            </p>
          </div>
          <div className="text-justify mb-4">
            <ExpandableText text={group?.description} limit={500} />
          </div>
          <div className={"flex flex-col gap-2 items-start"}>
            {!!group?.website && (
              <PlainLink to={`${returnExternalUrl(group.website)}`} native>
                <LanguageOutlined className="text-black38 mr-2" />
                <span>{group.website}</span>
              </PlainLink>
            )}

            {!!group?.phone && (
              <PlainLink to={group.phone} native phone>
                <LocalPhoneOutlined className="text-black38 mr-2" />
                <span>{group.phone}</span>
              </PlainLink>
            )}

            {isEvent && !!eventDates && (
              <div className="flex items-center">
                <AccessTime className="text-black38 mr-2" />
                <span>{composeEventDates()}</span>
              </div>
            )}

            {!!address && (
              <div className="color-primary flex items-center justify-start">
                <PlaceOutlined className="text-black38 mr-2" />
                <span>{address}</span>
              </div>
            )}

            {isEvent && !!group?.event_link && (
              <PlainLink to={`${returnExternalUrl(group.event_link)}`} native>
                <LinkIcon className="text-black38 mr-2" />
                <span>{group.event_link}</span>
              </PlainLink>
            )}
          </div>
        </div>
        <GroupHeaderControls />
        {group?.tags?.includes(1) && (
          <p className={"mt-4 text-black54"}>
            You are a follower. Some functions are not available for you.
          </p>
        )}
      </div>
    </div>
  )
}

export default GroupPageHeader
