import { useQuery } from "@tanstack/react-query"
import { USERS } from "../../../library/networking/apiEndpoints"
import { call } from "../../../library/networking/API"
import { useSelector } from "react-redux"
import {
  getUserName,
  getUserPhoto,
} from "../../../library/utils/user/userUtils"

export const useUser = (id, options = {}) => {
  const auth = useSelector(({ auth }) => auth)

  const key = [USERS.GET, id]

  const { data: user, ...query } = useQuery(key, {
    queryFn: async () => {
      const res = await call(USERS.GET, {
        id,
        friends_limit: 5,
        products_limit: 5,
      })

      return await res.data
    },
    refetchOnWindowFocus: false,
    staleTime: 60 * 1000,
    ...options,
  })

  return {
    key,
    user,
    fullName: getUserName(user),
    avatar: getUserPhoto(user),
    id: user?.id,
    isCurrentUser: user?.id === auth?.userData?.id,
    ...query,
  }
}

export const useCurrentUser = (options = {}) => {
  const { userData } = useSelector(({ auth }) => auth)

  const { data: user, ...query } = useQuery({
    queryKey: ["currentUser"],
    queryFn: async () => {
      const res = await call(USERS.GET, {
        id: userData.id,
        friends_limit: 1,
        products_limit: 1,
      })

      return await res.data
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    ...options,
  })

  return {
    user,
    fullName: getUserName(user),
    avatar: getUserPhoto(user),
    id: user?.id,
    queryKey: ["currentUser"],
    ...query,
  }
}
