import PostText from "../../post/components/postText"
import React from "react"
import { useCommentCtx } from "../CommentContext"

const CommentContent = () => {
  const { comment } = useCommentCtx()

  return (
    <div className="post-comment__content lg:max-w-[75%]">
      <PostText message={comment.message} showPlayer={false} limit={300} />
    </div>
  )
}

export default CommentContent
