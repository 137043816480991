import { CALENDARS, SCHEDULE } from "../actions/types/calendarTypes"
import { StateComposer } from "../../utils/reducerUtils/reducerUtils"
import { getUserName } from "../../utils/user/userUtils"

export const composeEventsList = (eventsSet) => {
  return Object.entries(eventsSet)
    .map(([key, value]) => {
      return value.map((v) => ({ ...v, entity_type: key }))
    })
    .flat(1)
}

const initialState = {
  list: [],
  calendarsListEndReached: false,
  schoolSchedule: null,

  ...StateComposer.initStateArrays([
    "eventsList",
    "schedulesList",
    "schedulesCalendarsList",
    "unitsList",
    "subjectsList",
    "subjectMembersList",
    "blocksList",
    "holdsList",
  ]),
  ...StateComposer.initStateCurrentItems([
    "currentCalendar",
    "currentSchedule",
    "currentUnit",
    "currentSubject",
    "currentClass",
    "currentLesson",
    "currentBlock",
    "currentEvent",
    "currentClassBlock",
    "currentHold",
  ]),
}

export const calendarReducer = (state = initialState, action) => {
  const composer = new StateComposer(state, action)

  switch (action.type) {
    case CALENDARS.CREATE:
      return composer.array("list").addItem()

    case CALENDARS.UPDATE:
      return composer.array("list").updateItem()

    case CALENDARS.DELETE:
      return composer.array("list").deleteItem()

    case CALENDARS.LIST:
      return composer.composeConfigurableFields({
        listKey: "list",
        listEndReachedKey: "calendarsListEndReached",
      })

    case CALENDARS.GET:
      return composer.record("currentCalendar").get()

    case CALENDARS.COMPLETE:
      return {
        ...state,
        list: state.list.map((item) =>
          item.id === action.payload.id ? { ...item, completed: true } : item
        ),
      }

    case CALENDARS.CLEAR_CURRENT_CALENDAR:
      return composer.record("currentCalendar").clear()

    case CALENDARS.CLEAR_LIST:
      return composer.resetConfigurableFields({
        listKey: "list",
        listEndReachedKey: "calendarsListEndReached",
      })

    case CALENDARS.EVENTS.GET:
      return composer.record("currentEvent").get()

    case CALENDARS.EVENTS.DELETE:
      return composer.array("eventsList").deleteItem()

    case CALENDARS.EVENTS.LIST:
      const allEntities = composeEventsList(action.payload)

      return {
        ...state,
        eventsList: allEntities.map((e, i) => ({
          id: e.id,
          title: e.name || e.title,
          start: e.date_from,
          end: e.date_to,
          allDay: e.is_all_day,
          color: e.color,
          desc: e.description || e.details,
          reminder: e.reminder,
          is_holiday: e.is_holiday,
          space: e?.space?.name || "",
          entity_type: e.entity_type,
        })),
      }

    case CALENDARS.EVENTS.CLEAR_LIST:
      return composer.array("eventsList").clear()

    case SCHEDULE.SCHEDULES.DELETE:
      return composer.array("schedulesList").deleteItem()

    case SCHEDULE.SCHEDULES.PRIVATE_SCHOOL_CREATE:
      return composer.array("schedulesList").addItem()

    case SCHEDULE.SCHEDULES.PRIVATE_SCHOOL_DELETE:
      return composer.array("schedulesList").deleteItem()

    case SCHEDULE.SCHEDULES.LIST:
      return composer.array("schedulesList").get()

    case SCHEDULE.SCHEDULES.GET:
      return composer.array("currentSchedule").get()

    case SCHEDULE.SCHEDULES.CREATE:
      return composer.array("schedulesList").addItem()

    case SCHEDULE.SCHEDULES.UPDATE:
      return composer.array("schedulesList").updateItem()

    case SCHEDULE.SCHEDULES.CLEAR_CURRENT_SCHEDULE:
      return composer.record("currentSchedule").clear()

    case SCHEDULE.SCHEDULES.CLEAR_LIST:
      return composer.array("schedulesList").clear()

    case SCHEDULE.SCHEDULES.CALENDARS.LIST:
      return composer.array("schedulesCalendarsList").get()

    case SCHEDULE.SCHEDULES.CALENDARS.CLEAR_LIST:
      return composer.array("schedulesCalendarsList").clear()

    case SCHEDULE.UNITS.CREATE:
      return composer.array("unitsList").addItem()

    case SCHEDULE.UNITS.UPDATE:
      return composer.array("unitsList").updateItem()

    case SCHEDULE.UNITS.DELETE:
      return composer.array("unitsList").deleteItem()

    case SCHEDULE.UNITS.GET:
      return composer.record("currentUnit").get()

    case SCHEDULE.UNITS.LIST:
      return composer.array("unitsList").get()

    case SCHEDULE.UNITS.CLEAR_CURRENT_UNIT:
      return composer.record("currentUnit").clear()

    case SCHEDULE.UNITS.CLEAR_LIST:
      return composer.array("unitsList").clear()

    case SCHEDULE.SUBJECTS.CREATE:
      return composer.array("subjectsList").addItem()

    case SCHEDULE.SUBJECTS.UPDATE:
      return composer.array("subjectsList").updateItem()

    case SCHEDULE.SUBJECTS.DELETE:
      return composer.array("subjectsList").deleteItem()

    case SCHEDULE.SUBJECTS.GET:
      return composer.record("currentSubject").get()

    case SCHEDULE.SUBJECTS.LIST:
      return composer.array("subjectsList").get()

    case SCHEDULE.SUBJECTS.CLEAR_CURRENT_SUBJECT:
      return composer.record("currentSubject").clear()

    case SCHEDULE.SUBJECTS.CLEAR_LIST:
      return composer.array("subjectsList").clear()

    case CALENDARS.BLOCKS.LIST:
      return composer.array("blocksList").get()

    case CALENDARS.BLOCKS.CLEAR_LIST:
      return composer.array("blocksList").clear()

    case CALENDARS.BLOCKS.GET:
      return composer.record("currentBlock").get()

    case CALENDARS.BLOCKS.CLEAR_CURRENT_BLOCK:
      return composer.record("currentBlock").clear()

    case CALENDARS.BLOCKS.CREATE:
      return composer.array("blocksList").addItem()

    case CALENDARS.BLOCKS.UPDATE:
      return composer.array("blocksList").updateItem()

    case CALENDARS.BLOCKS.DELETE:
      return composer.array("blocksList").deleteItem()

    case CALENDARS.HOLDS.LIST:
      return composer.array("holdsList").get()

    case CALENDARS.HOLDS.CLEAR_LIST:
      return composer.array("holdsList").clear()

    case CALENDARS.HOLDS.GET:
      return composer.record("currentHold").get()

    case CALENDARS.HOLDS.CLEAR_CURRENT_BLOCK:
      return composer.record("currentHold").clear()

    case CALENDARS.HOLDS.CREATE:
      return composer.array("holdsList").addItem()

    case CALENDARS.HOLDS.UPDATE:
      return composer.array("holdsList").updateItem()

    case CALENDARS.HOLDS.DELETE:
      return composer.array("holdsList").deleteItem()

    case SCHEDULE.SCHEDULES.GET_FOR_MANAGE:
      const records = action.payload.blocks

      const timePeriods = records.map((r) => parseInt(r.time_from))
      console.log(timePeriods)
      return {
        ...state,
        schoolSchedule: {
          rows: [
            {
              label: "Block",
              values: records.map((_, i) => i + 1),
            },
            {
              label: "Time",
              values: records.map((r, i) => `${r.time_from} — ${r.time_to}`),
            },
            // {
            //   label: 'Time type',
            //   values: [],
            // },
            {
              label: "Kourse",
              values: records.map((r, i) => r?.lesson?.class?.name ?? ""),
            },
            {
              label: "Space",
              values: records.map((r) => r?.lesson?.space?.name),
            },
            {
              label: "Teacher",
              values: records.map((r) =>
                r?.lesson?.teacher ? getUserName(r.lesson.teacher) : ""
              ),
            },
            {
              label: "Klass",
              values: records.map((r) => r?.lesson?.name),
            },
            // {
            //   label: 'Max',
            //   values: [],
            // },
          ],
        },
      }

    default:
      return state
  }
}
