import React from "react"
import { useCurrentUser } from "../../../../hooks/data/user/useUser"
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import SendOutlined from "@material-ui/icons/SendOutlined"
import { useGroup } from "../../../../hooks/data/groups/groups"
import { usePostCtx } from "../post/PostContext"
import styled from "@emotion/styled"
import { colors } from "../../../../library/constants/styles/colors"
import { devices } from "../../../../library/constants/styles/responsive"
import { Form, Formik } from "formik"
import * as yup from "yup"
import { useAlert } from "../../../../hooks/useAlert"
import { TextField } from "@material-ui/core"
import {
  useCommentsList,
  useCommentsMutations,
} from "../../../../hooks/data/feed/comments"
import Loader from "../../../ui/loader"
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress"

const DropdownContainer = styled.div`
  position: relative;
  margin-right: 10px;
`

const DropdownBtn = styled.button`
  display: flex;
  color: ${colors.black38};
`

const DropdownContentWrapper = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  left: -10px;
  z-index: 1;
`

const DropdownContent = styled.div`
  position: relative;
  min-width: 195px;
  padding: 15px;
  background-color: ${colors.white};
  box-shadow: 0 0 2px 0 rgba(210, 210, 210, 0.5);
  border-radius: 2px;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -6px;
    left: 14px;
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
    border-radius: 1px;
    background-color: ${colors.white};
    box-shadow: 0 0 2px 0 rgba(210, 210, 210, 0.5);

    @media ${devices.xs} {
      right: 12px;
    }
  }
`

const validationSchema = yup.object({
  message: yup.string().max(5000).required(),
})

const CommentCreate = () => {
  const { errorAlert } = useAlert()

  const { post, queryKey } = usePostCtx()
  const { group, groupLogo } = useGroup()
  const { avatar, fullName } = useCurrentUser()

  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false)
  const [shouldCreateFromGroup, setShouldCreateFromGroup] =
    React.useState(false)

  const initialValues = {
    message: "",
  }

  const { createComment } = useCommentsMutations({
    queryKey,
  })

  const { refetch: refetchComments } = useCommentsList({
    postId: post.id,
    queryKey,
    options: { enabled: false },
  })

  const onSubmit = async ({ message }, { resetForm }) => {
    try {
      await createComment.mutateAsync({
        post_id: post.id,
        message,
        personal: !shouldCreateFromGroup,
      })
      await refetchComments()
      resetForm()
    } catch (e) {
      errorAlert(e)
    }
  }

  const creatorPhoto = !!group && shouldCreateFromGroup ? groupLogo : avatar
  const creatorName = !!group && shouldCreateFromGroup ? group.name : fullName

  return (
    <div className="post-comment-field">
      <img
        src={creatorPhoto}
        alt={creatorName}
        className="post-comment-field__avatar"
      />

      {group?.is_admin ||
        (group?.is_creator && (
          <ClickAwayListener onClickAway={() => setIsDropdownOpen(false)}>
            <DropdownContainer>
              <DropdownBtn
                type="button"
                onClick={() => setIsDropdownOpen((prevState) => !prevState)}
              >
                {isDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </DropdownBtn>
              {isDropdownOpen && (
                <DropdownContentWrapper>
                  <DropdownContent>
                    <FormControlLabel
                      control={
                        <Checkbox
                          autoFocus
                          checked={shouldCreateFromGroup}
                          onChange={(e) =>
                            setShouldCreateFromGroup(e.target.checked)
                          }
                          value={"Comment as group"}
                        />
                      }
                      label="Comment as group"
                    />
                  </DropdownContent>
                </DropdownContentWrapper>
              )}
            </DropdownContainer>
          </ClickAwayListener>
        ))}

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(fk) => (
          <Form className={"f full-width"}>
            <TextField
              name={"message"}
              placeholder="Add your comment…"
              multiline
              value={fk.values.message}
              onChange={fk.handleChange}
              fullWidth
              margin="none"
              variant="outlined"
              className={"flex-1"}
            />
            <button
              type={"submit"}
              className="post-comment-field__send"
              disabled={createComment.isLoading}
            >
              {!createComment.isLoading ? (
                <SendOutlined />
              ) : (
                <CircularProgress size={24} />
              )}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CommentCreate
