import React from "react"
import { useSolicitAppointments } from "./SolicitAppointmentsContext"
import Dialog from "@material-ui/core/Dialog/Dialog"
import SolicitAppointmentStep1 from "./SolicitAppointmentStep1"
import SolicitAppointmentStep2 from "./SolicitAppointmentStep2"
import SolicitAppointmentStep3 from "./SolicitAppointmentStep3"

const SolicitAppointmentFormModal = ({ open }) => {
  const { step, onClose } = useSolicitAppointments()

  const renderStep = () => {
    switch (step) {
      case 1:
        return <SolicitAppointmentStep1 />
      case 2:
        return <SolicitAppointmentStep2 />
      case 3:
        return <SolicitAppointmentStep3 />
      default:
        return null
    }
  }
  return (
    <Dialog
      open={true}
      onClose={() => onClose(step < 3)}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth={"sm"}
      fullWidth
    >
      {renderStep()}
    </Dialog>
  )
}

export default SolicitAppointmentFormModal
