import React from "react"
import BoxPage from "../../../../../components/v2/layout/boxPage/boxPage"
import { KNOWMIX } from "../../../../../library/store/actions/types/knowmixTypes"
import SuperListPage from "../../../../../components/v2/layout/superListPage/superListPage"
import { useIdParam } from "../../../../../hooks/useIdParam"
import { useCourse } from "../../../../../hooks/data/courses/courses"
import { safeGetEntity } from "../../../../../library/utils/entities"
import { getObjectById } from "../../../../../library/utils/arrays"
import { calendarGrades } from "../../../../../library/constants/educationalDictionary"
import UserCard from "../../../../../components/v2/layout/cards/userCard"
import { useCourseMembersMutations } from "../../../../../hooks/data/courses/members"
import CourseMemberCard from "./CourseMemberCard"
import { SCHOOLS } from "../../../../../library/store/actions/types/groupsTypes"

const CourseSettingsMembers = () => {
  const knowmixId = useIdParam("knowmixId")

  const { course } = useCourse()

  const listQueryKey = [
    SCHOOLS.STUDENTS.LIST,
    { knowmix_id: Number(knowmixId), group_id: course?.creator_group?.id },
  ]

  return (
    <SuperListPage
      queryConfig={{
        key: listQueryKey,
        fetchParams: {
          knowmix_id: Number(knowmixId),
          group_id: course?.creator_group?.id,
        },
        listKey: "users",
      }}
      headerProps={{
        title: "Members",
        search: true,
      }}
      noResultsLabel={`Chosen schedule doesn't have students for ${
        safeGetEntity(course?.subject)
          ? getObjectById(calendarGrades, course?.subject?.grade)?.name
          : "this"
      } grade`}
      getListItemComponent={({ item }) => (
        <CourseMemberCard user={item} mutatedQueryKey={listQueryKey} />
      )}
    />
  )
}

export default CourseSettingsMembers
