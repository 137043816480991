import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { ValidatorForm } from "react-material-ui-form-validator"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import Dialog from "@material-ui/core/Dialog"
import { useAlert } from "../../../../../../../hooks/useAlert"
import FileLoader from "../../../../../../../components/formComponents/fileLoader"
import AddOutlined from "@material-ui/icons/AddOutlined"
import { groupInviteRoster } from "../../../../../../../library/store/actions/creators/groupsCreators"

const UsersRosterModal = ({
  open,
  handleClose,
  title = "Upload members roster",
  groupId,
  kourseId,
  subgroupId,
}) => {
  const dispatch = useDispatch()
  const params = useParams()
  const { errorAlert, successAlert } = useAlert()

  const { userData } = useSelector(({ auth }) => auth)

  const [file, setFile] = useState(null)

  const handleFileInputOpen = () => {
    document.getElementById("file").click()
  }

  const setAttachments = ({ blobs }) => {
    setFile(blobs[0])
  }

  const processSubmit = async () => {
    if (file) {
      try {
        if (groupId) {
          await dispatch(
            groupInviteRoster({
              roster_id: file.id,
              group_id: groupId,
              subgroup_id: subgroupId,
            })
          )
        }

        // if (kourseId) {
        //   await dispatch(
        //     classInviteRoster({
        //       roster_id: file.id,
        //       class_id: kourseId,
        //     })
        //   )
        // }

        await successAlert("Roster uploaded successfully")

        handleClose()
      } catch (e) {
        errorAlert(e)
      }
    }
  }

  return (
    <Dialog
      aria-labelledby="upload-roster-dialog"
      onClose={handleClose}
      open={open}
      maxWidth="md"
    >
      <ValidatorForm onSubmit={processSubmit}>
        <DialogTitle id="alert-dialog-title" className="text-center">
          {title}
        </DialogTitle>
        <DialogContent>
          <div className="form form--modal">
            <div className="form__fieldset">
              <label htmlFor="name" className="form__label">
                Roster *
              </label>
              <div className="form__fieldbox">
                <div className="form__attachments">
                  <FileLoader
                    attachmentId={"file"}
                    blobs={file ? [file] : []}
                    onUpload={setAttachments}
                    token={userData.token}
                    attachmentOnly={true}
                    limit={1}
                    accept={".csv"}
                  />
                  {!file && (
                    <button
                      className="form__add-input mb5"
                      type="button"
                      onClick={handleFileInputOpen}
                    >
                      <AddOutlined /> Pick .csv file
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="full-width f jcsb">
            <Button
              component={"a"}
              href="data:text/csv;charset=utf-8,email1@test.test
email2@test.test
email3@test.test"
              // target={'_blank'}
              color="primary"
              download="roster.csv"
            >
              Get roster template
            </Button>

            <div className="f">
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Submit
              </Button>
            </div>
          </div>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  )
}

export default UsersRosterModal
