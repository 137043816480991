import React, { PureComponent } from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import styled from "@emotion/styled"
import Button from "@material-ui/core/Button/Button"
import ReactCrop from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"
import img from "../../assets/img/userBg.jpg"
import getErrorText from "../../library/constants/errorTypes"
import { withSnackbar } from "notistack"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import Loader from "../ui/loader"
import { uploadFiles } from "../../library/utils/uploadFiles"
import {
  emptyImage,
  imageAspectRatios,
  imageLimitations,
} from "../../library/constants/images"

class ImagePickerModal extends PureComponent {
  state = {
    originImage: this.props.originImage,
    imageDeleted: false,
    pickerActive: false,
    src: null,
    fullImage: null,
    fullImageUrl: null,
    croppedImage: null,
    croppedImageUrl: null,
    crop: {
      aspect: this.props.aspectRatio,
      unit: "%",
      width:
        this.props.dimensions && this.props.dimensions.width
          ? this.props.dimensions.width
          : 100,
      height:
        this.props.dimensions && this.props.dimensions.height
          ? this.props.dimensions.height
          : 100,
      x:
        this.props.dimensions && this.props.dimensions.x
          ? this.props.dimensions.x
          : 0,
      y:
        this.props.dimensions && this.props.dimensions.y
          ? this.props.dimensions.y
          : 0,
    },
    isUploading: false,
    uploadProgress: "",
    fileTooLarge: false,
  }

  onUploadProgress = (progressEvent) => {
    let percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    )
    this.setState({ uploadProgress: percentCompleted })
  }

  onClose = () => {
    this.setState({
      originImage: this.props.originImage,
      pickerActive: false,
      crop: {
        aspect: this.props.aspectRatio,
        unit: "%",
        width:
          this.props.dimensions && this.props.dimensions.width
            ? this.props.dimensions.width
            : 100,
        height:
          this.props.dimensions && this.props.dimensions.height
            ? this.props.dimensions.height
            : 100,
        x:
          this.props.dimensions && this.props.dimensions.x
            ? this.props.dimensions.x
            : 0,
        y:
          this.props.dimensions && this.props.dimensions.y
            ? this.props.dimensions.y
            : 0,
      },
      isUploading: false,
      uploadProgress: "",
    })
    this.props.onClose()
  }

  openFileInput = () => document.getElementById("image-input").click()

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      const fileData = e.target.files[0]

      if (fileData.size > imageLimitations[this.props.aspectRatio].size.bytes) {
        return this.setState({ fileTooLarge: true })
      } else {
        this.setState({ fileTooLarge: false })
      }

      reader.addEventListener("load", () =>
        this.setState({
          src: reader.result,
          imageDeleted: false,
          crop: {
            aspect: this.props.aspectRatio,
            unit: "%",
            width: 100,
            x: 0,
            y: 0,
          },
          fullImage: fileData,
          pickerActive: true,
        })
      )
      reader.readAsDataURL(fileData)
    }
  }

  onImageLoaded = (image) => {
    this.imageRef = image
  }

  onCropComplete = (crop) => {
    this.makeClientCrop(crop)
  }

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop, percentCrop })
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImage = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      )
      this.setState({
        croppedImageUrl: croppedImage.fileUrl,
        croppedImage: croppedImage.file,
      })
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas")
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext("2d")

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    return new Promise((resolve, reject) => {
      let formData = new FormData()
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty")
          return
        }

        blob.name = fileName
        window.URL.revokeObjectURL(this.fileUrl)
        this.fileUrl = window.URL.createObjectURL(blob)
        formData.append("image_name", blob)
        resolve({ file: blob, fileUrl: this.fileUrl })
      }, "image/jpeg")
    })
  }

  processDelete = async () => {
    this.setState({
      isUploading: false,
      originImage: this.props.defaultImage,
      imageDeleted: true,
      pickerActive: false,
    })
  }

  processUpload = async () => {
    const { fullImage, croppedImage, crop, imageDeleted } = this.state
    try {
      if (!imageDeleted) {
        this.setState({ isUploading: true })
        const full_image = await uploadFiles(
          fullImage,
          2,
          this.onUploadProgress,
          this.props.auth.userData.token
        )
        const cropped_image = await uploadFiles(
          croppedImage,
          2,
          this.onUploadProgress,
          this.props.auth.userData.token
        )
        const photo = {
          x: Math.floor(crop.x),
          y: Math.floor(crop.y),
          width: Math.floor(crop.width),
          height: Math.floor(crop.height),
          original_image: full_image.url,
          cropped_image: cropped_image.url,
        }
        await this.props.onSave(photo)
      } else {
        await this.props.onSave(emptyImage)
      }

      this.setState(
        {
          isUploading: false,
          originImage: this.state.croppedImageUrl,
          pickerActive: false,
        },
        this.props.onClose
      )
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  render() {
    const { src, originImage, pickerActive, crop, isUploading } = this.state

    const imageConfig = {
      size: imageLimitations[this.props.aspectRatio].size.userReadable,
      wight: imageLimitations[this.props.aspectRatio].dimensions.width + "px",
      height: imageLimitations[this.props.aspectRatio].dimensions.height + "px",
    }

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="update-photo-dialog"
        aria-describedby="update-photo-dialog"
        maxWidth={"md"}
        scroll={"body"}
      >
        <DialogContent>
          <input
            type="file"
            accept="image/png, image/jpeg"
            id="image-input"
            className="hidden"
            onChange={this.onSelectFile}
          />
          <ImageContainer>
            {!pickerActive && (
              <img src={originImage} alt="Your current photo" />
            )}
            {!!src && pickerActive && (
              <ReactCrop
                src={src}
                crop={crop}
                onChange={this.onCropChange}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                keepSelection
                circularCrop={this.props.isCircle}
              />
            )}
            {isUploading && (
              <ImageOverlay>
                <Loader />
              </ImageOverlay>
            )}
          </ImageContainer>
          {this.state.fileTooLarge && (
            <p className={"mt-4 text-lg text-danger"}>
              File is too large, maximum size is {imageConfig.size}
            </p>
          )}
          <p className={"mt-4 text-base text-black54"}>
            <span className={"font-medium text-black87"}>Note:</span> file size
            should not exceed {imageConfig.size}.{" "}
            {this.props.aspectRatio !== imageAspectRatios.square &&
              `Preferred image dimensions are
          ${imageConfig.wight} width and ${imageConfig.height} height.`}
          </p>
        </DialogContent>
        <DialogActions>
          <ActionsContainer>
            <div>
              <Button color="primary" onClick={this.openFileInput}>
                Choose photo
              </Button>
              <Button color="primary" onClick={this.processDelete}>
                Delete photo
              </Button>
            </div>

            <div>
              <Button color="primary" onClick={this.props.onClose}>
                Close
              </Button>
              <Button color="primary" onClick={this.processUpload}>
                Save
              </Button>
            </div>
          </ActionsContainer>
        </DialogActions>
      </Dialog>
    )
  }
}

const ImageContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const ImageOverlay = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  svg {
    cursor: pointer;
  }
`

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const mapStateToProps = ({ auth }) => ({ auth })
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ImagePickerModal))
