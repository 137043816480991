import React from "react"
import InfoLayout from "../infoLayout"
import { Li, Section, SectionHeading, Ul } from "./accountsInfoMain"
import { Button } from "@material-ui/core"
import { Link } from "react-router-dom"
import { ACCOUNTS_INFO, CONTACT_FORM } from "../../../library/constants/routes"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import { InfoFooter } from "./accountsInfoEducator"
import { Text } from "../uknow/uknowInfoPage"
import computerImg from "../../../assets/img/pages/info/main/Computer-Graphic.png"
import feature1Img from "../../../assets/img/pages/info/agent/feature1.jpg"
import feature2Img from "../../../assets/img/pages/info/agent/feature2.jpg"
import feature3Img from "../../../assets/img/pages/info/agent/feature3.jpg"
import feature4Img from "../../../assets/img/pages/info/agent/feature4.jpg"
import handshakeImg from "../../../assets/img/pages/info/agent/handshake.png"

const AccountsInfoAgent = () => {
  return (
    <InfoLayout>
      <div className={"flexgrid"}>
        <Section pt={"30px"}>
          <div className="container">
            <div className="full-width">
              <Button
                component={Link}
                to={ACCOUNTS_INFO}
                color={"primary"}
                aria-label="Back"
                startIcon={<KeyboardArrowLeft />}
                className={"mb30"}
              >
                Back to accounts overview
              </Button>

              <SectionHeading>Agents</SectionHeading>
              <div className="row mb30">
                <div className="col-md-10 col-md-offset-1">
                  <p className="text-center">
                    Here is where you market all your education products for
                    free. Receive and act on Demo requests auto-calendared for
                    your staff. Create a company representative account, then
                    create your Company Group.
                  </p>
                </div>
              </div>

              <div className="row mb60">
                <div className="col-md-6 col-md-offset-3">
                  <img src={computerImg} className={""} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-10 col-md-offset-1">
                  <Text className="mb30">
                    Automate Demo Setting | Get Reports on Wish-List Adds of
                    your Product | Coming Soon – Resell your product and license
                    directly to Educators, Schools and Districts with tracking –
                    Plus, boosting products into recommendations during any
                    users Course creation
                  </Text>
                  <Ul>
                    <Li>Create and add documents to your Group Profile</Li>
                    <Li>Manage membership and followers</Li>
                    <Li>
                      Create Product Tiles (advertising tiles) for the
                      Marketplace Free
                    </Li>
                    <Li>
                      Use Records in Invenstory to create descriptions of the
                      sub-parts of your product addressing specific Standards
                    </Li>
                    <Li>Create holds for Demos</Li>
                    <Li>Create events</Li>
                    <Li>Use social media and messaging</Li>
                    <Li>Use bookmarks and folders</Li>
                    <Li>Post news and stories</Li>
                  </Ul>
                </div>
              </div>
            </div>
          </div>
        </Section>

        <Section isDark>
          <div className="container">
            <div className="full-width">
              <div className="row mb40">
                <div className="col-md-4 col-md-offset-1 f jcc">
                  <img src={feature1Img} alt={""} />
                </div>
                <div className="col-md-5 col-md-offset-1 pt30 text-center">
                  <SectionHeading>Be recommended</SectionHeading>
                  <p>
                    Get recommended right into parts of lesson plans. <br />{" "}
                    Creating your Company Group and each Product Tile is free.
                    When you tag the products with records of which parts cover
                    what Standards or topics – we recommend them as educators
                    plan.
                  </p>
                </div>
              </div>
              <div className="row mb40">
                <div className="col-md-5 col-md-offset-1 pt30 text-center">
                  <SectionHeading>Reach Edtech Buyers</SectionHeading>
                  <p>
                    Boost or Blast for Awareness Purchase product boosts and
                    post blasts to reach shoppers for ed-tech. Select by
                    unaffiliated educators, school and district staff, K-12
                    learners, and adult learners.
                  </p>
                </div>
                <div className="col-md-4 col-md-offset-1 f jcc">
                  <img src={feature2Img} alt={""} />
                </div>
              </div>
              <div className="row mb40">
                <div className="col-md-4 col-md-offset-1 f jcc">
                  <img src={feature3Img} alt={""} />
                </div>
                <div className="col-md-5 col-md-offset-1 pt30 text-center">
                  <SectionHeading>Automate your demo requests</SectionHeading>
                  <p>
                    Set up Automated Demo Calendaring Set up which staff will
                    take which times to schedule a demo that will automatically
                    be set to coincide with the shopper’s calendar.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-5 col-md-offset-1 pt30 text-center">
                  <SectionHeading>
                    Reach Homeschoolers and Schools
                  </SectionHeading>
                  <p>
                    Sell to consumers and institutions in one place The
                    Marketplace can be used freely to put in your products with
                    no automated sales, just ad pages with links. Premium
                    accounts, sell directly with license and subscription
                    tracking or single sales you fulfill.
                  </p>
                </div>
                <div className="col-md-4 col-md-offset-1 f jcc">
                  <img src={feature4Img} alt={""} />
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section>
          <div className="container">
            <div className="full-width">
              <SectionHeading>How it Works</SectionHeading>
              <div className="row">
                <div className="col-md-10 col-md-offset-1">
                  <p className="mb15 text-center">
                    One of your company representatives creates a personal
                    profile and then creates a Company Group. As the Admin of
                    the Group, they can approve other staff and your sales
                    manager. They use the sub-tabs in the Group management to
                    complete the set-up and start adding Products.
                  </p>
                  <p className="mb15 text-center">
                    Each Product will have its own page and ad-tile in the
                    marketplace.
                  </p>
                  <p className="mb15 text-center">
                    Under Invenstory, the group admin can create unlimited
                    Records that are descriptors of the parts of major products
                    like Courseware modules and ebook chapters which each have
                    their own address to various academic Standards. Each Record
                    adds standards and then can be added as a sub-part of a
                    product so that shoppers and teachers creating lesson
                    Knowmixes can see the parts in their planning in the
                    recommendations.
                  </p>
                  <p className="mb30 text-center">
                    Paid Company group accounts can set up each product to have
                    a “Schedule Demo” button for visitors. Your admin sets up
                    “Holds” for each individual on your sales team and then
                    assigns those staff to those Holds for calendaring Demos.
                    Synced calendars will look for time in the buyer’s and your
                    staff’s calendars and schedule the meet for the soonest
                    available hour.
                  </p>
                  <p className={"mb15"}>
                    Free to all companies are these functions:
                  </p>
                  <Ul className={"mb30"}>
                    <Li>Company representative profiles</Li>
                    <Li>Company Groups</Li>
                    <Li>Posting and messaging</Li>
                    <Li>Product creation</Li>
                    <Li>Invenstory for Records of sub-parts of products</Li>
                    <Li>
                      Buyer inquiry notifications and emails to your group
                      administrator
                    </Li>
                  </Ul>

                  <p className={"mb15"}>Paid forthcoming functions include:</p>
                  <Ul>
                    <Li>Automated demo set-up</Li>
                    <Li>Advanced product sales and remittances</Li>
                    <Li>Boosts in auto-recommendations to teacher planning</Li>
                    <Li>Post blasts</Li>
                    <Li>
                      Stats on followers, product visits, sales and remittances
                    </Li>
                  </Ul>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section isDark>
          <div className="container">
            <div className="full-width">
              <SectionHeading>Help Wanted</SectionHeading>
              <div className="row aic">
                <div className="col-md-5 col-md-offset-1">
                  <div className={"mb60"}>
                    <SectionHeading>Invest Now</SectionHeading>
                    <p className={"mb15"}>
                      Knowstory has already received investment by Learning
                      Counsel News Media & Research for over $2 Million in
                      software development and has existing users with a full
                      pipeline of interested schools and districts for premium
                      accounts currently being worked.
                    </p>
                    <Button
                      component={Link}
                      to={CONTACT_FORM}
                      color={"primary"}
                      variant={"contained"}
                    >
                      Contact Us to Invest or Partner
                    </Button>
                  </div>
                  <div className={"mb60"}>
                    <SectionHeading>
                      Sponsor a School or District Scholarship
                    </SectionHeading>
                    <p className={"mb15"}>
                      When you sponsor a school or district, we will match your
                      donation to the services and subscriptions needed for a
                      first-year pilot from already interested schools as a
                      scholarship.
                    </p>
                    <p className={"mb15"}>
                      Your company announced as scholarship sponsor to the
                      receiving school or district with a certificate and
                      introduction.
                    </p>
                    <Button
                      component={Link}
                      to={CONTACT_FORM}
                      color={"primary"}
                      variant={"contained"}
                    >
                      Contact Us to Sponsor
                    </Button>
                  </div>
                </div>
                <div className="col-md-4 col-md-offset-1 f jcc">
                  <img src={handshakeImg} alt={"Sponsor"} />
                </div>
              </div>
            </div>
          </div>
        </Section>
        <InfoFooter isFirstDark={false} />
      </div>
    </InfoLayout>
  )
}

export default AccountsInfoAgent
