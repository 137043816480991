import React, { Component } from "react"
import MoreDropdown from "../../../../components/ui/dropdowns/moreDropdown/moreDropdown"
import VerticalMenu from "../../../../components/ui/verticalMenu"
import { generatePath, Link, withRouter } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import { standardsGrades as grades } from "../../../../library/constants/educationalDictionary"
import { statesAndThames } from "../../../../library/constants/statesAndThames"
import { bindActionCreators } from "redux"
import {
  createBookmark,
  deleteBookmark,
  foldersList,
} from "../../../../library/store/actions/creators/bookmarksCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import Button from "@material-ui/core/Button/Button"
import getErrorText from "../../../../library/constants/errorTypes"
import AddToFolderModal from "../../bookmarks/folders/addToFolder/addToFolder"

import LineSlice from "../../../../components/ui/lineSlice"
import PlainLink from "../../../../components/ui/link"

class StandardCard extends Component {
  state = {
    openAddToFolderDialog: false,
    bookmarked: !!this.props.standard.bookmark,
  }

  handleClickOpen = () => {
    this.setState({ openAddToFolderDialog: true })
  }

  handleClose = () => {
    this.setState({ openAddToFolderDialog: false })
  }

  addToBookmarks = async () => {
    const {
      standard: { id },
    } = this.props

    try {
      await this.props.createBookmark({ standard_id: id })
      await this.props.foldersList()
      this.setState({ bookmarked: true })
      await this.props.enqueueSnackbar("Saved to bookmarks", {
        autoHideDuration: 5000,
        action: (key) => {
          return (
            <>
              <Button
                color="primary"
                onClick={() => {
                  this.props.closeSnackbar(key)
                  this.props.history.push(routes.BOOKMARKS_STANDARDS)
                }}
              >
                View
              </Button>
              {this.props.bookmarks.folders.length > 0 && (
                <Button color="primary" onClick={this.handleClickOpen}>
                  Add to folder
                </Button>
              )}
            </>
          )
        },
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  deleteBookmark = async () => {
    const { standard } = this.props

    try {
      await this.props.deleteBookmark({ standard_id: standard.id })
      this.setState({ bookmarked: false })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  render() {
    const { standard } = this.props
    const { bookmarked } = this.state

    const dropdownMenuItems = [
      !bookmarked
        ? {
            type: "button",
            action: this.addToBookmarks,
            label: "Add to bookmarks",
          }
        : {
            type: "button",
            action: this.deleteBookmark,
            label: "Remove from bookmarks",
          },
    ]

    const state = statesAndThames[standard.state - 1]
    const subjects = state["subjects"]

    if (!standard) return null

    return (
      <div
        className="relative grid grid-rows-subgrid row-span-3 gap-2 rounded-[2px] shadow-card bg-white relative p-3"
        // style={{ gridRow: `span 3` }}
      >
        <div className="absolute right-2 top-2 self-end text-black38">
          <MoreDropdown>
            <VerticalMenu
              menuItems={dropdownMenuItems}
              classes={"more-dropdown-menu__list"}
            />
          </MoreDropdown>
        </div>
        <PlainLink
          to={generatePath(routes.STANDARD, { standardId: standard.id })}
          bold
          className={"!line-clamp-2 w-[calc(100%_-_1.75rem)]"}
        >
          {standard.name}
        </PlainLink>
        <div>
          <div className={"mb-1"}>
            <p className={`text-black54 text-xs mb-0.5`}>State:</p>
            <p>{state.name}</p>
          </div>
          <div className={"mb-1"}>
            <p className={"text-black54 text-xs mb-0.5"}>Subject:</p>
            <p>{subjects.find((s) => s.id === standard.subject).name}</p>
          </div>
          {!!standard.grades.length && (
            <div>
              <p className="text-black54 text-xs mb-0.5">
                <span>Grades:</span>
              </p>
              <p>
                {standard.grades
                  .map((g) => grades.find((gr) => gr.id === g).name)
                  .join(", ")}
              </p>
            </div>
          )}
        </div>
        <p className={"line-clamp-3"}>{standard.description}</p>

        {this.state.openAddToFolderDialog && (
          <AddToFolderModal
            open={this.state.openAddToFolderDialog}
            handleClose={this.handleClose}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ auth, bookmarks }) => ({ auth, bookmarks })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ createBookmark, deleteBookmark, foldersList }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(StandardCard)))
