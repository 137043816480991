import React from "react"
import FileLoader from "../../../../../../../components/formComponents/fileLoader"
import Button from "@material-ui/core/Button"
import { withSnackbar } from "notistack"
import getErrorText from "../../../../../../../library/constants/errorTypes"

class GroupSettingsAttachmentsFiles extends React.Component {
  state = {
    blobs: this.props.groups.currentGroup.blobs || [],
    images: this.props.groups.currentGroup.images || [],
    videos: this.props.groups.currentGroup.videos || [],
  }

  handleFileInputOpen = (id) => {
    document.getElementById(id).click()
  }
  setPhotos = ({ images }) => {
    this.setState({ images })
  }
  setBlobs = ({ blobs }) => {
    this.setState({ blobs })
  }
  processInfoSave = async () => {
    const { images, blobs } = this.state
    const {
      id,
      name,
      description,
      website,
      country,
      city,
      state,
      address,
      zip_code,
      phone,
      start_date,
      end_date,
      district_id,
      logo,
      header,
      closed,
    } = this.props.groups.currentGroup
    const attachments = [...images.map((i) => i.id), ...blobs.map((b) => b.id)]
    try {
      await this.props.updateGroup({
        id,
        name,
        description,
        website,
        country,
        city,
        state,
        address,
        zip_code,
        phone,
        start_date,
        end_date,
        district_id,
        logo,
        header,
        closed,
        attachments,
      })
      await this.props.enqueueSnackbar("Information updated", {
        variant: "success",
      })
      await this.props.getGroup({ id })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  render() {
    const {
      auth: { userData },
    } = this.props
    const { blobs, images, videos } = this.state
    return (
      <div className="box">
        <div className="box__heading">Documents</div>
        <div className="box__content">
          <div className="form">
            <div className="form__fieldset">
              <span className="form__label">Images</span>
              <div className="form__fieldbox">
                <div className="form__attachments">
                  <FileLoader
                    imageId={"photos"}
                    images={images}
                    photosOnly={true}
                    onUpload={this.setPhotos}
                    token={userData.token}
                    limit={50}
                  />
                  {images.length <= 50 && (
                    <Button
                      color="primary"
                      variant="outlined"
                      htmlFor="image"
                      className={`${!!images.length ? "mt10" : ""}`}
                      onClick={() => this.handleFileInputOpen("photos")}
                    >
                      Upload image
                    </Button>
                  )}
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <span className="form__label">Attachments</span>
              <div className="form__fieldbox">
                <div className="form__attachments">
                  <FileLoader
                    attachmentId={"blobs"}
                    videos={videos}
                    blobs={blobs}
                    onUpload={this.setBlobs}
                    token={userData.token}
                    attachmentOnly={true}
                    limit={50}
                  />
                  <Button
                    color="primary"
                    variant="outlined"
                    className={`${!!blobs.length ? "mt10" : ""}`}
                    onClick={() => this.handleFileInputOpen("blobs")}
                  >
                    Upload
                  </Button>
                </div>
              </div>
            </div>

            <Button
              color="primary"
              variant="contained"
              onClick={this.processInfoSave}
              className="form__submit"
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    )
  }
}


export default withSnackbar(GroupSettingsAttachmentsFiles);
