import React from "react"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import { Dialog, DialogContent } from "@material-ui/core"
import Button from "@material-ui/core/Button/Button"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import { questionTypes } from "../../../../../../library/constants/knowmix"

const QuestionsInfoModal = (props) => {
  return (
    <Dialog
      aria-labelledby="create-record-dialog"
      onClose={props.onClose}
      open={props.open}
    >
      <DialogTitle id="alert-dialog-title" className="text-center">
        Questions info
      </DialogTitle>
      <DialogContent>
        {questionTypes.map((item, index) => (
          <div
            className={index < questionTypes.length - 1 ? "mb15" : ""}
            key={item.id}
          >
            <p>
              <span className={"font-weight-500"}>{item.name}</span> -{" "}
              {item.description}
            </p>
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button type="submit" color="primary" onClick={props.onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default QuestionsInfoModal
