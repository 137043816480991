import React from "react"
import "./productDetails.scss"
import { generatePath, Link } from "react-router-dom"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import * as routes from "../../../../../library/constants/routes"
import {
  grades as gradesList,
  subjects as subjectsList,
} from "../../../../../library/constants/educationalDictionary"
import {
  productContentTypes,
  productEnhancements,
  productFileTypes,
  productIntegrationComplianceTypes,
  productPlatforms,
} from "../../../../../library/constants/productDictionary"
import defaultDate from "../../../../../library/constants/defaultDates"
import {
  getArrayByIds,
  getObjectById,
  renderArrayOfNames,
} from "../../../../../library/utils/arrays"
import returnExternalUrl from "../../../../../library/utils/returnExternalUrl"
import LinksList from "../../../../../components/ui/linksList/linksList"
import { minutesToString } from "../../../../../library/utils/timeConvert"
import { format } from "date-fns"

const ProductDetails = (props) => {
  const {
    name,
    author,
    average_time,
    concepts_number,
    content_type,
    creator_user,
    grades,
    creator_group,
    date_from,
    date_to,
    enhancements,
    file_types,
    integration,
    lesson_time,
    platforms,
    potential_time,
    publisher,
    standards,
    subjects,
    tagline,
    target_market,
    topic,
    url,
    version,
    knowmix,
  } = props.products.currentProduct

  const creator = creator_user
    ? {
        name: `${creator_user.first_name} ${creator_user.last_name}`,
        url: generatePath(routes.USER, { userId: creator_user.id }),
      }
    : {
        name: creator_group.name,
        url: generatePath(routes.GROUP, { groupId: creator_group.id }),
      }

  const isPluralNoun = (word, number) => {
    return number > 1 || number === 0 ? `${word}s` : word
  }

  return (
    <div className="box">
      <div className="box__content">
        <p className="box__subheading">Product details</p>
        <table className="product-details">
          <tbody>
            <tr className="product-details__row">
              <td className="product-details__label">Name:</td>
              <td className="product-details__value">{name}</td>
            </tr>

            <tr className="product-details__row">
              <td className="product-details__label">Creator:</td>
              <td className="product-details__value">
                <Link to={creator.url} className="link">
                  {creator.name}
                </Link>
              </td>
            </tr>
            {subjects.length > 0 && (
              <tr className="product-details__row">
                <td className="product-details__label">Subject:</td>
                <td className="product-details__value">
                  <LinksList
                    array={getArrayByIds(subjectsList, subjects)}
                    searchIn="marketplace"
                    searchParam="subjects"
                  />
                </td>
              </tr>
            )}
            {grades.length > 0 && (
              <tr className="product-details__row">
                <td className="product-details__label">Grades:</td>
                <td className="product-details__value">
                  <LinksList
                    array={getArrayByIds(gradesList, grades)}
                    searchIn="marketplace"
                    searchParam="grades"
                  />
                </td>
              </tr>
            )}
            {standards.length > 0 && (
              <tr className="product-details__row">
                <td className="product-details__label">Standards:</td>
                <td className="product-details__value">
                  <ul className="text-list">
                    {standards.map((s) => (
                      <li key={s.id}>{s.name}</li>
                    ))}
                  </ul>
                </td>
              </tr>
            )}
            {!!(publisher && publisher !== "") && (
              <tr className="product-details__row">
                <td className="product-details__label">Publisher:</td>
                <td className="product-details__value">{publisher}</td>
              </tr>
            )}
            {!!(author && author !== "") && (
              <tr className="product-details__row">
                <td className="product-details__label">Author:</td>
                <td className="product-details__value">{author}</td>
              </tr>
            )}
            {content_type === 15 && !!(knowmix && knowmix.steps !== 0) && (
              <tr className="product-details__row">
                <td className="product-details__label">Number of steps:</td>
                <td className="product-details__value">{knowmix.steps}</td>
              </tr>
            )}
            {content_type !== 15 &&
              !!(concepts_number && concepts_number !== 0) && (
                <tr className="product-details__row">
                  <td className="product-details__label">
                    Number of concepts:
                  </td>
                  <td className="product-details__value">
                    {concepts_number} {isPluralNoun("hour", concepts_number)}
                  </td>
                </tr>
              )}
            {content_type === 15 &&
              !!(knowmix && knowmix.average_time !== 0) && (
                <tr className="product-details__row">
                  <td className="product-details__label">Average step time:</td>
                  <td className="product-details__value">
                    {minutesToString(knowmix.average_time)}
                  </td>
                </tr>
              )}
            {content_type !== 15 && !!(average_time && average_time !== 0) && (
              <tr className="product-details__row">
                <td className="product-details__label">
                  Average time of each:
                </td>
                <td className="product-details__value">
                  {average_time} {isPluralNoun("hour", average_time)}
                </td>
              </tr>
            )}
            {content_type !== 15 && !!(lesson_time && lesson_time !== 0) && (
              <tr className="product-details__row">
                <td className="product-details__label">
                  Lesson time potential:
                </td>
                <td className="product-details__value">
                  {lesson_time} {isPluralNoun("hour", lesson_time)}
                </td>
              </tr>
            )}
            {content_type === 15 && !!(knowmix && knowmix.time !== 0) && (
              <tr className="product-details__row">
                <td className="product-details__label">Total steps time:</td>
                <td className="product-details__value">
                  {minutesToString(knowmix.time)}
                </td>
              </tr>
            )}
            {content_type !== 15 &&
              !!(potential_time && potential_time !== 0) && (
                <tr className="product-details__row">
                  <td className="product-details__label">
                    Estimated lesson time used:
                  </td>
                  <td className="product-details__value">
                    {potential_time} {isPluralNoun("hour", potential_time)}
                  </td>
                </tr>
              )}
            {!!(topic && topic !== "") && (
              <tr className="product-details__row">
                <td className="product-details__label">Topic:</td>
                <td className="product-details__value">{topic}</td>
              </tr>
            )}
            {!!(content_type && content_type !== "") && (
              <tr className="product-details__row">
                <td className="product-details__label">Content type:</td>
                <td className="product-details__value">
                  <LinksList
                    array={[getObjectById(productContentTypes, content_type)]}
                    searchIn="marketplace"
                    searchParam="contentTypes"
                  />
                </td>
              </tr>
            )}
            {/*{!!(content_type && content_type === 15 && knowmix) && (*/}
            {/*    <tr className="product-details__row">*/}
            {/*        <td className="product-details__label">Link to product:</td>*/}
            {/*        <td className="product-details__value">*/}
            {/*            <Link to={generatePath(routes.KNOWMIX, {knowmixId: knowmix.id})} className="link" >{knowmix.name}</Link>*/}
            {/*        </td>*/}
            {/*    </tr>*/}
            {/*)}*/}
            {file_types.length > 0 && (
              <tr className="product-details__row">
                <td className="product-details__label">File type:</td>
                <td className="product-details__value">
                  {renderArrayOfNames(
                    getArrayByIds(productFileTypes, file_types)
                  )}
                </td>
              </tr>
            )}
            {platforms.length > 0 && (
              <tr className="product-details__row">
                <td className="product-details__label">Platform:</td>
                <td className="product-details__value">
                  {renderArrayOfNames(
                    getArrayByIds(productPlatforms, platforms)
                  )}
                </td>
              </tr>
            )}
            {!!(integration && integration !== "") && (
              <tr className="product-details__row">
                <td className="product-details__label">Integration:</td>
                <td className="product-details__value">
                  {
                    getObjectById(
                      productIntegrationComplianceTypes,
                      integration
                    ).name
                  }
                </td>
              </tr>
            )}
            {enhancements.length > 0 && (
              <tr className="product-details__row">
                <td className="product-details__label">Enhancements:</td>
                <td className="product-details__value">
                  {renderArrayOfNames(
                    getArrayByIds(productEnhancements, enhancements)
                  )}
                </td>
              </tr>
            )}
            {/*{teks_standards.length > 0 && (*/}
            {/*<tr className="product-details__row">*/}
            {/*<td className="product-details__label">TEKS standards:</td>*/}
            {/*<td className="product-details__value">*/}
            {/*110.10*/}
            {/*110.11*/}
            {/*120.33*/}
            {/*</td>*/}
            {/*</tr>*/}
            {/*)}*/}

            {!!(target_market && target_market !== "") && (
              <tr className="product-details__row">
                <td className="product-details__label">Target market:</td>
                <td className="product-details__value">{target_market}</td>
              </tr>
            )}
            {!!(tagline && tagline !== "") && (
              <tr className="product-details__row">
                <td className="product-details__label">Product tagline:</td>
                <td className="product-details__value">{tagline}</td>
              </tr>
            )}
            {!!(url && url !== "") && (
              <tr className="product-details__row">
                <td className="product-details__label">Link to product:</td>
                <td className="product-details__value">
                  <a href={`${returnExternalUrl(url)}`} className="link">
                    {url}
                  </a>
                </td>
              </tr>
            )}
            {!!(version && version !== "") && (
              <tr className="product-details__row">
                <td className="product-details__label">Version:</td>
                <td className="product-details__value">{version}</td>
              </tr>
            )}
            {date_from !== defaultDate && (
              <tr className="product-details__row">
                <td className="product-details__label">Date from:</td>
                <td className="product-details__value">
                  {format(new Date(date_from), "MMM d, yyyy")}
                </td>
              </tr>
            )}
            {date_to !== defaultDate && (
              <tr className="product-details__row">
                <td className="product-details__label">Date to:</td>
                <td className="product-details__value">
                  {format(new Date(date_to), "MMM d, yyyy")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth, products }) => ({ auth, products })
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ProductDetails))
