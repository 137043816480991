import * as Sentry from "@sentry/browser"

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://8a253b4cbe2a4633b7f20047a7f16212@sentry.io/1456614",
    integrations: [
      new Sentry.Integrations.Breadcrumbs({
        console: false,
      }),
    ],
  })
}
