import React, { useState } from "react"
import { withSnackbar } from "notistack"
import { withRouter } from "react-router-dom"
import { Dialog, DialogActions, DialogContent } from "@material-ui/core"
import FormControl from "@material-ui/core/FormControl/FormControl"
import Checkbox from "@material-ui/core/Checkbox/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import getErrorText from "../../../../../../../../library/constants/errorTypes"
import Button from "@material-ui/core/Button/Button"
import { groupMemberTags } from "../../../../../../../../library/constants/userRoles"

const tags = (user) => {
  // if (user.group_role === 2) {
  //   return groupMemberTags
  //     .filter((tag) => tag.id !== 1)
  //     .map((tag) => ({ ...tag, selected: user.tags.includes(tag.id) }))
  // }

  return groupMemberTags.map((tag) => ({
    ...tag,
    selected: user.tags.includes(tag.id),
  }))
}

const MemberTagsModal = ({
  user,
  changeUserTags,
  open,
  handleClose,
  match,
  enqueueSnackbar,
}) => {
  const [selectedTags, setSelectedTags] = useState(tags(user))
  const [followerOnly, setFollowerOnly] = useState(
    user.tags.length === 1 && user.tags[0] === 1
    // false
  )

  const handleChange = (event) => {
    event.persist()

    if (parseInt(event.target.value) === 1) {
      setFollowerOnly(event.target.checked)

      return setSelectedTags((prevState) => {
        return prevState.map((tag) => ({
          ...tag,
          selected:
            parseInt(event.target.value) === tag.id
              ? event.target.checked
              : event.target.checked
              ? false
              : tag.selected,
        }))
      })
    }

    setSelectedTags((prevState) => {
      return prevState.map((tag) =>
        tag.id === parseInt(event.target.value)
          ? {
              ...tag,
              selected: event.target.checked,
            }
          : tag
      )
    })
  }

  const processTagsSave = async () => {
    try {
      await changeUserTags({
        user_id: user.id,
        group_id: parseInt(match.params.groupId, 10),
        tags: selectedTags.filter((tag) => tag.selected).map((tag) => tag.id),
      })
      handleClose()
    } catch (error) {
      enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  return (
    <Dialog
      aria-labelledby="member-occupation-dialog"
      onClose={handleClose}
      open={open}
      maxWidth="md"
    >
      <DialogContent>
        <div className="group-create">
          <div className="text-center f fdc aic">
            <h3 className="group-create__title">Member occupation</h3>
            <p className={"color-black-54"}>
              Member can either be only a follower or all of other options.
              <br />
              If "Follower" option is checked, you must uncheck it and check any
              other option you need
            </p>
          </div>
          <FormControl component="fieldset" id="group-member-tags">
            {selectedTags.map((tag) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={tag.selected}
                      onChange={handleChange}
                      value={tag.id}
                      disabled={tag.id !== 1 && followerOnly}
                    />
                  }
                  label={
                    <span
                      className="break-word"
                      style={{ maxWidth: "175px", display: "block" }}
                    >
                      {tag.name}
                    </span>
                  }
                  key={tag.id}
                />
              )
            })}
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="text" color="primary">
          Cancel
        </Button>
        <Button
          type="submit"
          variant="text"
          color="primary"
          onClick={processTagsSave}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withRouter(withSnackbar(MemberTagsModal))
