import { GROUPS, SCHOOLS, SEGMENTS } from "../actions/types/groupsTypes"
import { StateComposer } from "../../utils/reducerUtils/reducerUtils"

const initialState = {
  list: {},
  groupsSearch: null,
  membersList: null,
  membersNumber: null,
  requestsList: null,
  membersInvitesList: null,
  invitesList: null,
  invitesSearch: null,
  currentGroupAttachmentProducts: [],
  attachmentProductList: [],

  ...StateComposer.initStateArrays([
    "relatedList",
    "schoolGradeMembersList",
    "schoolGradeMembersSearchList",
    "segmentsList",
    "sectionsList",
    "sectionRotationBlocksList",
    "spacesList",
    "knowmixList",
    "sectionBlocksList",
  ]),

  ...StateComposer.initStateCurrentItems([
    "currentGroup",
    "currentSegment",
    "currentSpace",
    "currentSectionBlock",
  ]),
}

export const groupsReducer = (state = initialState, action) => {
  const composer = new StateComposer(state, action)

  switch (action.type) {
    case GROUPS.LIST: {
      const { type, groups } = action.payload
      return {
        ...state,
        list: {
          ...state.list,
          [type]: state.list[type] ? [...state.list[type], ...groups] : groups,
        },
      }
    }
    case GROUPS.LIST_UPDATE: {
      const { type, groups } = action.payload
      return {
        ...state,
        list: { ...state.list, [type]: groups },
      }
    }
    case GROUPS.SEARCH: {
      return {
        ...state,
        groupsSearch: action.payload,
      }
    }
    case GROUPS.GET: {
      return {
        ...state,
        currentGroup: action.payload,
      }
    }
    case GROUPS.CLEAR_CURRENT: {
      return {
        ...state,
        currentGroup: null,
      }
    }

    case GROUPS.PARTICIPANTS.CHANGE_TAGS: {
      return {
        ...state,
        membersList: state.membersList.map((member) => {
          return member.id === action.payload.user_id
            ? { ...member, tags: action.payload.tags }
            : member
        }),
      }
    }

    case GROUPS.PARTICIPANTS.LIST: {
      return {
        ...state,
        membersList: state.membersList
          ? [...state.membersList, ...action.payload.users]
          : action.payload.users,
        membersNumber: action.payload.participants_number,
      }
    }

    case GROUPS.PARTICIPANTS.REQUESTS.LIST: {
      return {
        ...state,
        requestsList: state.requestsList
          ? [...state.requestsList, ...action.payload]
          : action.payload,
      }
    }

    case GROUPS.CONNECTIONS.LIST: {
      return composer.array("relatedList").get()
    }

    case GROUPS.CONNECTIONS.CLEAR: {
      return composer.array("relatedList").clear()
    }

    case GROUPS.PARTICIPANTS.INVITES.LIST: {
      return {
        ...state,
        membersInvitesList: action.payload,
      }
    }
    case GROUPS.INVITES_LIST: {
      return {
        ...state,
        invitesList: state.invitesList
          ? [...state.invitesList, ...action.payload]
          : action.payload,
      }
    }
    case GROUPS.PARTICIPANTS.INVITES.SEARCH: {
      return {
        ...state,
        invitesSearch: state.invitesSearch
          ? [...state.invitesSearch, ...action.payload]
          : action.payload,
      }
    }
    case GROUPS.CLEAR:
      return {
        ...state,
        list: {},
        groupsSearch: [],
        membersList: [],
        membersNumber: 0,
        requestsList: [],
        relatedList: [],
        membersInvitesList: [],
        invitesList: [],
        invitesSearch: [],
      }
    case GROUPS.LIBRARY_LIST:
      return {
        ...state,
        currentGroupAttachmentProducts: action.payload,
      }
    case GROUPS.LIBRARY_SEARCH:
      return {
        ...state,
        attachmentProductList: [
          ...state.attachmentProductList,
          ...action.payload,
        ],
      }
    case GROUPS.LIBRARY_UPDATE:
      return {
        ...state,
        attachmentProductList: action.payload,
      }
    case GROUPS.LIBRARY_CLEAR:
      return {
        ...state,
        currentGroupAttachmentProducts: [],
        attachmentProductList: [],
      }

    case GROUPS.KNOWMIX_LIST:
      return composer.array("knowmixList").get()

    case GROUPS.KNOWMIX_LIST_CLEAR:
      return composer.array("knowmixList").clear()

    case SCHOOLS.STUDENTS.LIST:
      return composer.array("schoolGradeMembersList").get()

    case SCHOOLS.STUDENTS.SEARCH:
      return composer.array("schoolGradeMembersSearchList").get()

    case SCHOOLS.STUDENTS.LIST_CLEAR:
      return composer.array("schoolGradeMembersList").clear()

    case SCHOOLS.STUDENTS.SEARCH_CLEAR:
      return composer.array("schoolGradeMembersSearchList").clear()

    case SCHOOLS.STUDENTS.ADD:
      return {
        ...state,
        schoolGradeMembersSearchList: state.schoolGradeMembersSearchList.map(
          (user) =>
            user.id === action.payload.user_id
              ? {
                  ...user,
                  grade: action.payload.grade,
                }
              : user
        ),
      }
    case SCHOOLS.STUDENTS.DELETE:
      return {
        ...state,
        schoolGradeMembersSearchList: !action.payload.removeFromList
          ? state.schoolGradeMembersSearchList.map((user) =>
              user.id === action.payload.user_id
                ? {
                    ...user,
                    grade: undefined,
                  }
                : user
            )
          : state.schoolGradeMembersSearchList,
        schoolGradeMembersList: action.payload.removeFromList
          ? state.schoolGradeMembersList.filter(
              (user) => user.id !== action.payload.user_id
            )
          : state.schoolGradeMembersList,
      }

    case SCHOOLS.STUDENTS.TRANSFER:
      return {
        ...state,
        schoolGradeMembersSearchList: state.schoolGradeMembersSearchList.map(
          (user) =>
            user.id === action.payload.user_id
              ? {
                  ...user,
                  grade: action.payload.new_grade,
                }
              : user
        ),
      }

    case GROUPS.SPACES.LIST:
      return composer.array("spacesList").get()

    case GROUPS.SPACES.CLEAR_LIST:
      return composer.array("spacesList").clear()

    case GROUPS.SPACES.CREATE:
      return composer.array("spacesList").addItem()

    case GROUPS.SPACES.UPDATE:
      return composer.array("spacesList").updateItem()

    case GROUPS.SPACES.DELETE:
      return composer.array("spacesList").deleteItem()

    case GROUPS.SPACES.GET:
      return composer.record("currentSpace").get()

    case GROUPS.SPACES.CLEAR_CURRENT:
      return composer.record("currentSpace").clear()

    case SEGMENTS.LIST:
      return composer.array("segmentsList").get()

    case SEGMENTS.CLEAR_LIST:
      return composer.array("segmentsList").clear()

    case SEGMENTS.CREATE:
      return composer.array("segmentsList").addItem()

    case SEGMENTS.UPDATE:
      return composer.array("segmentsList").updateItem()

    case SEGMENTS.DELETE:
      return composer.array("segmentsList").deleteItem()

    case SEGMENTS.GET:
      return composer.record("currentSegment").get()

    case SEGMENTS.CLEAR_CURRENT:
      return composer.record("currentSegment").clear()

    case SEGMENTS.SECTIONS.LIST:
      return composer.array("sectionsList").get()

    case SEGMENTS.SECTIONS.CLEAR_LIST:
      return composer.array("sectionsList").clear()

    case SEGMENTS.SECTIONS.CREATE:
      return composer.array("sectionsList").addItem()

    case SEGMENTS.SECTIONS.UPDATE:
      return composer.array("sectionsList").updateItem()

    case SEGMENTS.SECTIONS.DELETE:
      return composer.array("sectionsList").deleteItem()

    case SEGMENTS.SECTIONS.GET:
      return composer.record("currentSection").get()

    case SEGMENTS.SECTIONS.CLEAR_CURRENT:
      return composer.record("currentSection").clear()

    case SEGMENTS.SECTIONS.BLOCKS.LIST:
      return composer.array("sectionBlocksList").get()

    case SEGMENTS.SECTIONS.BLOCKS.CLEAR_LIST:
      return composer.array("sectionBlocksList").clear()

    case SEGMENTS.SECTIONS.BLOCKS.CREATE:
      return composer.array("sectionBlocksList").addItem()

    case SEGMENTS.SECTIONS.BLOCKS.UPDATE:
      return composer.array("sectionBlocksList").updateItem()

    case SEGMENTS.SECTIONS.BLOCKS.DELETE:
      return composer.array("sectionBlocksList").deleteItem()

    case SEGMENTS.SECTIONS.BLOCKS.GET:
      return composer.record("currentSectionBlock").get()

    case SEGMENTS.SECTIONS.BLOCKS.CLEAR_CURRENT:
      return composer.record("currentSectionBlock").clear()

    default:
      return state
  }
}
