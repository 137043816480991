import { LOADING } from "../actions/types/loadingTypes"

const initialState = {
  isLoading: false,
  error: false,
}

export const loadingReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING.STARTED:
            return {
                ...state,
                isLoading: true,
            };
        case LOADING.ENDED:
            return {
                ...state,
                isLoading: false,
            };
        case LOADING.ERROR.SHOW:
            return {
                ...state,
                // isLoading: false,
                // error: true
            };
        case LOADING.ERROR.HIDE:
            return {
                ...state,
                error: false,
            };
        default:
            return state;
    }
};
