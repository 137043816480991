import React, { Component } from "react"
import UnitDialog from "./groupSettingsCalendarSubjects/subpages/groupSettingsCalendarUnits/components/unitDialog"
import SubjectDialog from "./groupSettingsCalendarSubjects/components/subjectDialog"
import CalendarDialog from "./components/calendarDialog/calendarDialog"
import UknowDialog from "../../../../knowmix/knowmixSingle/knowmixSettings/knowmixSettingsKnowmeets/uknowDialog/uknowDialog"
import AlertDialog from "../../../../../../components/ui/alertDialog"

const GroupSettingsCalendarContext = React.createContext()

class GroupSettingsCalendarProvider extends Component {
  state = {
    showCalendarDialog: false,
    showSubjectDialog: false,
    showUnitDialog: false,
    activeCalendar: undefined,
    activeSubject: undefined,
    activeUnit: undefined,
    activeClass: undefined,
    activeLesson: undefined,
    isPrivateSchool: false,

    knowmeet: {
      active: null,
      infoOpen: false,
      formOpen: false,
      deleteOpen: false,
      type: null,
    },
  }

  onCalendarDialogOpen = (calendar_id) => {
    this.setState({
      activeCalendar: calendar_id,
      showCalendarDialog: true,
    })
  }

  onCalendarDialogClose = () =>
    this.setState({ showCalendarDialog: false, activeCalendar: undefined })

  onSubjectDialogOpen = (subject_id) => {
    this.setState({
      activeSubject: subject_id,
      showSubjectDialog: true,
    })
  }

  onSubjectDialogClose = () =>
    this.setState({ showSubjectDialog: false, activeSubject: undefined })

  onUnitDialogOpen = (unit_id) => {
    this.setState({
      activeUnit: unit_id,
      showUnitDialog: true,
    })
  }

  onUnitDialogClose = () =>
    this.setState({ showUnitDialog: false, activeUnit: undefined })

  onKnowmeetDialogOpen = (knowmeet_id, dialogType, knowmeetType) => {
    this.setState((prevState) => ({
      ...prevState,
      knowmeet: {
        ...prevState.knowmeet,
        active: knowmeet_id,
        [dialogType + "Open"]: true,
        type: knowmeetType,
      },
    }))
  }

  onKnowmeetDialogClose = (dialogType) => {
    this.setState((prevState) => ({
      ...prevState,
      knowmeet: {
        ...prevState.knowmeet,
        active: null,
        [dialogType + "Open"]: false,
        type: null,
      },
    }))
  }

  render() {
    const {
      showCalendarDialog,
      showSubjectDialog,
      showUnitDialog,
      showKnowmeetDialog,
      activeCalendar,
      activeSubject,
      activeUnit,
      activeKnowmeet,
      knowmeet,
    } = this.state

    return (
      <GroupSettingsCalendarContext.Provider
        value={{
          onCalendarDialogOpen: (calendar_id) =>
            this.onCalendarDialogOpen(calendar_id),
          onSubjectDialogOpen: (subject_id) =>
            this.onSubjectDialogOpen(subject_id),
          onUnitDialogOpen: (unit_id) => this.onUnitDialogOpen(unit_id),
          onKnowmeetDialogOpen: (knowmeet_id, dialogType, knowmeetType) =>
            this.onKnowmeetDialogOpen(knowmeet_id, dialogType, knowmeetType),
        }}
      >
        <GroupSettingsCalendarContext.Consumer>
          {this.props.children}
        </GroupSettingsCalendarContext.Consumer>

        {showCalendarDialog && (
          <CalendarDialog
            open={showCalendarDialog}
            onClose={this.onCalendarDialogClose}
            activeCalendar={activeCalendar}
          />
        )}

        {showSubjectDialog && (
          <SubjectDialog
            open={showSubjectDialog}
            onClose={this.onSubjectDialogClose}
            activeSubject={activeSubject}
            onCalendarDialogOpen={this.onCalendarDialogOpen}
          />
        )}

        {showUnitDialog && (
          <UnitDialog
            open={showUnitDialog}
            onClose={this.onUnitDialogClose}
            activeUnit={activeUnit}
            onCalendarDialogOpen={this.onCalendarDialogOpen}
            onSubjectDialogOpen={this.onSubjectDialogOpen}
          />
        )}

        {knowmeet.infoOpen && (
          <UknowDialog
            open={knowmeet.infoOpen}
            handleClose={() => this.onKnowmeetDialogClose("info")}
            activeUknow={knowmeet.active}
            type={knowmeet.type}
          />
        )}

        {knowmeet.deleteOpen && (
          <AlertDialog
            open={showKnowmeetDialog}
            onClose={this.onKnowmeetDialogClose}
            activeKnowmeet={activeKnowmeet}
            type={knowmeet.type}
          />
        )}
      </GroupSettingsCalendarContext.Provider>
    )
  }
}

export default GroupSettingsCalendarProvider
