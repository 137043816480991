import useEntityPicker from "../../../../hooks/useEntityPicker"
import { useEffect } from "react"
import PickerSelect from "../select/pickerSelect"
import { SEGMENTS } from "../../../../library/store/actions/types/groupsTypes"

const SectionPicker = ({
  value,
  onChange,
  segmentId,
  hasError = false,
  errorMessage,
  required = true,
  multiple = false,
  emptyListMessage,
  emptyListLink,
  disabled = false,
}) => {
  const { fetchList, list, dataReceived, isWaitingForOtherFields } =
    useEntityPicker({
      fetchConfig: {
        apiUrl: SEGMENTS.SECTIONS.LIST,
        params: {
          segment_id: segmentId,
          offset: 0,
          limit: 100,
        },
      },
      key: "sections",
      loadOnMount: !!segmentId,
    })

  useEffect(() => {
    if (segmentId) {
      fetchList()
    }
  }, [segmentId])

  const entityName = multiple ? "sections" : "section"

  return (
    <PickerSelect
      name={"section"}
      entityName={entityName}
      value={value}
      onChange={onChange}
      required={required}
      hasError={hasError}
      errorMessage={errorMessage}
      disabled={disabled || !dataReceived}
      multiple={multiple}
      list={list}
      isWaitingForOtherFields={isWaitingForOtherFields}
      isWaitingForOtherFieldsMessage={"First select segment"}
      emptyListMessage={emptyListMessage}
      emptyListLink={emptyListLink}
    />
  )
}

export default SectionPicker
