import logoPlaceholder from "../../assets/img/group-logo-default.png"
import logoDeleted from "../../assets/img/group_unavailable.svg"
import { GROUP_TYPES } from "../constants/groupTypes"

export const getGroupLogo = (group) => {
  if (!group) return null
  if (group.deleted) return logoDeleted
  if (group.logo) return group.logo

  return GROUP_TYPES?.[group.type]?.defaultLogo || logoPlaceholder
}
