import React from "react"

const Stub = ({ text }) => {
  return (
    <div className="pl30 pr30 pb20 pt20 f aic jcc color-black-54 full-width">
      {text}
    </div>
  )
}

export default Stub
