import React from "react"
import styled from "@emotion/styled"
import { colors } from "../../../../../library/constants/styles/colors"
import { TextLineSkeleton } from "../components/textSkeleton"

const Container = styled.div`
  border-radius: 2px;
  background-color: ${colors.white};
  box-shadow: 0 0 2px 0 rgba(210, 210, 210, 0.5);
  padding-bottom: 15px;
`

const ProfileInfoSkeleton = () => {
  return (
    <Container>
      <div className="f aifs">
        <div style={{ width: 120 }} className={"pt15 pl15"}>
          <TextLineSkeleton height={"14px"} />
          <TextLineSkeleton height={"14px"} />
          <TextLineSkeleton height={"14px"} />
        </div>
        <div className="v-divider" />
        <div className={"full-width pt15 pr15"}>
          <TextLineSkeleton height={"14px"} width={"20%"} />
          <TextLineSkeleton height={"14px"} width={"40%"} />
          <TextLineSkeleton height={"14px"} width={"20%"} />
          <TextLineSkeleton height={"14px"} width={"50%"} />
          <TextLineSkeleton height={"14px"} width={"30%"} />
          <TextLineSkeleton height={"14px"} width={"30%"} />
        </div>
      </div>
      <div className="h-divider" />
      <div className="full-width f jcc">
        <div className="f jcc aic fdc ml25 mr25">
          <TextLineSkeleton
            height={"20px"}
            width={"25px"}
            style={{ marginBottom: 15 }}
          />
          <TextLineSkeleton height={"14px"} width={"70px"} />
        </div>
        <div className="f jcc aic fdc ml25 mr25">
          <TextLineSkeleton
            height={"20px"}
            width={"25px"}
            style={{ marginBottom: 15 }}
          />
          <TextLineSkeleton height={"14px"} width={"70px"} />
        </div>
        <div className="f jcc aic fdc ml25 mr25">
          <TextLineSkeleton
            height={"20px"}
            width={"25px"}
            style={{ marginBottom: 15 }}
          />
          <TextLineSkeleton height={"14px"} width={"70px"} />
        </div>
        <div className="f jcc aic fdc ml25 mr25">
          <TextLineSkeleton
            height={"20px"}
            width={"25px"}
            style={{ marginBottom: 15 }}
          />
          <TextLineSkeleton height={"14px"} width={"70px"} />
        </div>
      </div>
    </Container>
  )
}

export default ProfileInfoSkeleton
