import {
  Cell,
  CellContent,
  MobileContainer,
  Row,
  ScrollContainer,
  StyledTable,
  TBody,
} from "../../../../../../../components/v2/table/table"

const data = {
  columnsNumber: 20,
  rows: [
    {
      label: "Block",
      values: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      ],
    },
    {
      label: "Time",
      values: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      ],
    },
    // {
    //   label: 'Time type',
    //   values: [
    //     1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    //   ],
    // },
    {
      label: "Kourse",
      values: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      ],
    },
    {
      label: "Space",
      values: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      ],
    },
    {
      label: "Teacher",
      values: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      ],
    },
    {
      label: "Klass",
      values: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      ],
    },
    // {
    //   label: 'Max',
    //   values: [
    //     1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    //   ],
    // },
  ],
}

const fluid = data.columnsNumber + 1 <= 5

const ScheduleTable = ({ timePeriod, isLast = false }) => {
  return (
    <div className={!isLast ? 'mb15' : ''}>
      <p className={'mb10'}>{timePeriod}</p>

      <MobileContainer>
        <ScrollContainer>
          <StyledTable>
            <TBody fluid={fluid} columnsNumber={data.columnsNumber + 1}>
              {data.rows.map((r, i) => {
                return (
                  <Row
                    noHead
                    fluid={fluid}
                    columnsNumber={data.columnsNumber + 1}
                    key={r.label}
                  >
                    <Cell fluid={fluid} isFirstColumnSticky={!fluid}>
                      <CellContent fluid={fluid}>{r.label}</CellContent>
                    </Cell>
                    {r.values.map((v, i) => {
                      return (
                        <Cell
                          fluid={fluid}
                          key={`${r.label}-${i}-${v}`}
                          isFirstColumnSticky={!fluid}
                        >
                          <CellContent fluid={fluid}>{v}</CellContent>
                        </Cell>
                      )
                    })}
                  </Row>
                )
              })}
            </TBody>
          </StyledTable>
        </ScrollContainer>
      </MobileContainer>
    </div>
  )
}

export default ScheduleTable
