import React from "react"
import PlainLink from "../../ui/link"
import { User } from "../../../library/utils/user/userUtils"
import UserInfoTooltip from "./UserInfoTooltip"
import { generatePath } from "react-router-dom"
import { GROUP } from "../../../library/constants/routes"
import GroupInfoTooltip from "./GroupInfoTooltip"

const GroupLink = ({
  group,
  interactive = true,
  newTab = true,
  linkClassName = "",
}) => {
  const path = generatePath(GROUP, { groupId: group.id })

  const link = (
    <PlainLink to={path} className={linkClassName} newTab={newTab} inline>
      {group.name}
    </PlainLink>
  )

  return interactive ? (
    <GroupInfoTooltip group={group}>{link}</GroupInfoTooltip>
  ) : (
    link
  )
}

export default GroupLink
