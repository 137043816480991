import React, { useState } from "react"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import { call } from "../../../../library/networking/API"
import { FRIENDS } from "../../../../library/store/actions/types/friendsTypes"
import { useAlert } from "../../../../hooks/useAlert"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core"

const InviteFriendByEmailModal = ({ handleClose, open }) => {
  const { errorAlert, successAlert } = useAlert()
  const [email, setEmail] = useState("")

  const processSubmit = async () => {
    try {
      await call(FRIENDS.INVITE_BY_EMAIL, { email })

      successAlert("Invitation sent")
      handleClose()
    } catch (error) {
      errorAlert(error)
    }
  }

  return (
    <Dialog
      aria-labelledby="event-form-dialog"
      onClose={handleClose}
      open={open}
      maxWidth="md"
    >
      <ValidatorForm onSubmit={processSubmit}>
        <DialogTitle id="alert-dialog-title" className="text-center">
          Invite friend by email
        </DialogTitle>
        <DialogContent>
          <div className="f jcc">
            <div className="form form--modal">
              <div className="form__fieldset">
                <label htmlFor="email" className="form__label">
                  Email *
                </label>
                <div className="form__fieldbox">
                  <div className="form__input">
                    <TextValidator
                      id="email"
                      name="email"
                      value={email}
                      placeholder="Type friend's email"
                      className="edit-form__input"
                      margin="normal"
                      fullWidth
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        "Field is required",
                        "Email is not valid",
                      ]}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  )
}

export default InviteFriendByEmailModal
