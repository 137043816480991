import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { APPOINTMENTS } from "../../../../../library/store/actions/types/calendarTypes"
import { useAlert } from "../../../../../hooks/useAlert"
import { call } from "../../../../../library/networking/API"

export const useBooking = (id, bookingType, options = {}) => {
  const queryUrl =
    bookingType === "book_appointments"
      ? APPOINTMENTS.GET
      : APPOINTMENTS.SOLICIT.GET
  const query = useQuery({
    queryKey: [queryUrl, { id }],
    ...options,
  })

  return {
    booking: query.data,
    ...query,
  }
}

export const useBookingMutations = (queryKey) => {
  const queryClient = useQueryClient()
  const { errorAlert } = useAlert()

  const accept = useMutation(
    async ({ id, bookingType }) => {
      try {
        await call(
          bookingType === "book_appointments"
            ? APPOINTMENTS.ACCEPT
            : APPOINTMENTS.SOLICIT.ACCEPT,
          { id }
        )
      } catch (e) {
        errorAlert(e)
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(queryKey),
    }
  )

  const decline = useMutation(
    async ({ id, bookingType }) => {
      try {
        await call(
          bookingType === "book_appointments"
            ? APPOINTMENTS.DECLINE
            : APPOINTMENTS.SOLICIT.DECLINE,
          { id }
        )
      } catch (e) {
        errorAlert(e)
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(queryKey),
    }
  )

  const cancel = useMutation(
    async ({ id, bookingType }) => {
      try {
        await call(
          bookingType === "book_appointments"
            ? APPOINTMENTS.CANCEL
            : APPOINTMENTS.SOLICIT.CANCEL,
          { id }
        )
      } catch (e) {
        errorAlert(e)
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(queryKey),
    }
  )

  const forward = useMutation(
    async ({ id, bookingType }) => {
      try {
        await call(
          bookingType === "book_appointments"
            ? APPOINTMENTS.FORWARD
            : APPOINTMENTS.SOLICIT.FORWARD,
          { id }
        )
      } catch (e) {
        errorAlert(e)
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(queryKey),
    }
  )

  return {
    accept,
    decline,
    cancel,
    forward,
  }
}
