import { LIMIT } from "../../constants/limits"

export default class TypedStateArray {
  constructor(state, action, initialState) {
    this.state = state
    this.action = action
    this.initialState = initialState
  }

  static initStateArrayByType = (key, types = []) => {
    return {
      [key]: types.reduce(
        (prev, cur) => ({
          ...prev,
          [cur]: {
            list: [],
            endReached: false,
          },
        }),
        {}
      ),
    }
  }

  composeFields = ({
    key,
    type = this.action.payload.type,
    payloadListKey = "list",
    limit = LIMIT,
  }) => ({
    ...this.state,
    [key]: {
      ...this.state[key],
      [type]: {
        list: this.composeList({ stateKey: key, payloadListKey }),
        endReached: this.composeEndReached({
          payloadListKey,
          limit,
        }),
      },
    },
  })

  resetFields = ({ key, type = this.action.payload.type }) => {
    if (!type) {
      return {
        ...this.state,
        [key]: this.initialState[key],
      }
    } else {
      return {
        ...this.state,
        [key]: {
          ...this.state[key],
          [type]: {
            list: [],
            endReached: false,
          },
        },
      }
    }
  }

  composeList = ({
    payloadListKey = "list",
    stateKey,
    type = this.action.payload.type,
  }) => {
    const payloadList = this.action.payload[payloadListKey]

    return this.action.payload.offset === 0
      ? payloadList
      : [...this.state[stateKey][type].list, ...payloadList]
  }

  composeEndReached = ({ payloadListKey = "list", limit = LIMIT } = {}) => {
    const payloadList = this.action.payload[payloadListKey]

    return payloadList.length < limit
  }

  addArrayRecord = ({ key, type = this.action.payload.type }) => ({
    ...this.state,
    [key]: {
      ...this.state[key],
      [type]: {
        list: [...this.state[key][type].list, this.action.payload],
      },
    },
  })

  updateArrayRecord = ({
    key,
    type = this.action.payload.type,
    idKey = "id",
  }) => ({
    ...this.state,
    [key]: {
      ...this.state[key],
      [type]: {
        list: this.state[key][type].list.map((record) => {
          return record[idKey] !== this.action.payload[idKey]
            ? record
            : this.action.payload
        }),
      },
    },
  })

  deleteArrayRecord = ({
    key,
    type = this.action.payload.type,
    idKey = "id",
  }) => ({
    ...this.state,
    [key]: {
      ...this.state[key],
      [type]: {
        list: this.state[key][type].filter(
          (r) => r[idKey] !== this.action.payload[idKey]
        ),
      },
    },
  })

  entities = (key) => ({
    get: () => this.composeFields({ key }),
    clear: () => this.resetFields({ key }),
    addItem: () => this.addArrayRecord({ key }),
    updateItem: () => this.updateArrayRecord({ key }),
    deleteItem: () => this.deleteArrayRecord({ key }),
  })
}
