import React, { Component } from "react"
import { withStyles } from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog/Dialog"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import TextField from "@material-ui/core/TextField/TextField"
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment"
import SearchOutlined from "@material-ui/icons/SearchOutlined"
import Loader from "../../loader"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import withScroll from "../../../../hocs/withScroll/withScroll"
import styled from "@emotion/styled"

const CustomDialog = withStyles((theme) => ({
  paper: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "752px",
    },
  },
}))(Dialog)

const CustomDialogContent = withStyles({
  root: {
    padding: "16px 0 16px 24px",
    overflow: "hidden",
  },
})(DialogContent)

export const ListModalContentContainer = styled.div`
  padding-right: 24px;
  max-height: 620px;
  overflow-y: auto;

  .product-tile {
    width: 215px;

    @supports (display: grid) {
      width: 224px;
    }
  }
`

class ListModal extends Component {
  render() {
    const {
      title,
      list,
      name,
      handleNameChange,
      placeholder = "Type name",
      onSearch,
      dataReceived,
      initialRender,
      ListContent,
      open,
      handleClose,
      onExit = null,
    } = this.props
    return (
      <CustomDialog
        open={open}
        onClose={handleClose}
        onExit={onExit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        scroll="paper"
      >
        <DialogTitle id="alert-dialog-title">
          <p className="mb10 text-center">{title}</p>
          <form className="full-width font-weight-400" onSubmit={onSearch}>
            <TextField
              className="search-header__input full-width"
              placeholder={placeholder}
              margin="none"
              autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined style={{ color: "#9b9a9b" }} />
                  </InputAdornment>
                ),
              }}
              value={name}
              onChange={handleNameChange}
            />
          </form>
        </DialogTitle>
        <CustomDialogContent>
          {!!list.length
            ? ListContent
            : !initialRender && (
                <p className="color-black-54 text-center">Result not found</p>
              )}
          {!dataReceived && <Loader />}
        </CustomDialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </CustomDialog>
    )
  }
}

export default withScroll(ListModal)
