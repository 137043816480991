//Styled
import React from "react"
import StarOutlined from "@material-ui/icons/StarOutlined"
import StarBorderOutlined from "@material-ui/icons/StarBorderOutlined"
import styled from "@emotion/styled/macro"
import { colors } from "../../../library/constants/styles/colors"

const CustomStarOutlined = styled(StarOutlined)`
  &:not(:last-child) {
    margin-right: 2px;
  }
`

const CustomStarBorderOutlined = styled(StarBorderOutlined)`
  &:not(:last-child) {
    margin-right: 2px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.orange};
  
  ${CustomStarOutlined},
  ${CustomStarBorderOutlined} {
    font-size: ${props => props.starSize}px;
    cursor: ${props => props.clickable ? "pointer" : "auto"}
  }
`;

const StarRating = ({rating = 0, maxRating = 5, starSize = 24, ...rest}) => {
    const currentRating = Math.round(rating);
    const starsFilled = Array(currentRating).fill("");
    const starsEmpty = Array(maxRating - currentRating).fill("");

    return (
        <Container starSize={starSize} clickable={!!rest.rate}>
            {starsFilled.map((star, i) => (
                <CustomStarOutlined
                    onClick={rest.rate ? () => rest.rate(i + 1) : null}
                    key={i}
                />
            ))}
            {starsEmpty.map((star, i) => (
                <CustomStarBorderOutlined
                    onClick={rest.rate ? () => rest.rate(currentRating + i + 1) : null}
                    key={i}
                />
            ))}
        </Container>
    );
};

export default StarRating;
