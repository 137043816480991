import { createTheme } from "@material-ui/core/styles"
import createBreakpoints from "@material-ui/core/styles/createBreakpoints"

export const breakpoints = createBreakpoints({})

export const appTheme = {
  props: {
    MuiRadio: {
      disableRipple: true,
      color: "primary",
    },
    MuiCheckbox: {
      disableRipple: true,
      color: "primary",
    },
    // MuiButton: {
    //   color: 'primary',
    //   variant: 'contained',
    // },
    MuiTablePagination: {
      component: "div",
      backIconButtonProps: { size: "small", color: "primary" },
      nextIconButtonProps: { size: "small", color: "primary" },
      labelRowsPerPage: "Records per page",
    },
  },

  palette: {
    primary: {
      main: "#49853f",
    },
    secondary: {
      main: "#49853f",
    },
    // secondary: {
    //   main: "#f44336",
    // },
  },

  typography: {
    useNextVariants: true,
  },

  overrides: {
    MuiOutlinedInput: {
      multiline: {
        padding: "8px",
      },
    },

    MuiButtonBase: {
      root: {
        fontSize: "1rem",
      },
    },

    MuiButton: {
      root: {
        borderRadius: "2px",
      },
    },

    MuiCheckbox: {
      root: {
        "&:checked": {
          color: "#49853f",
        },
      },
    },

    MuiIconButton: {
      root: {
        fontSize: "1rem",
        // padding: "5px 10px 5px 0",
      },
      label: {
        fontSize: "1rem",
      },
    },

    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
        marginRight: 0,
      },
    },

    MuiFormControl: {
      marginNormal: {
        marginTop: "6px",
        marginBottom: "6px",
      },
      marginDense: {
        marginTop: "6px",
        marginBottom: "6px",
      },
    },

    MuiInput: {
      underline: {
        borderColor: "#49853f",

        "&::before": {
          borderColor: "rgba(0, 0, 0, 0.12)",
        },

        "&:hover:not(&disabled):not(&focused):not(&error):before": {
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        },

        "&::after": {
          borderColor: "#49853f",
        },
      },
    },

    MuiInputBase: {
      root: {
        fontSize: "1rem",
      },
      input: {
        padding: "6px 0 3px",
      },
    },

    MuiInputLabel: {
      root: {
        fontSize: "1rem",
      },
    },

    MuiFormLabel: {
      root: {
        color: "rgba(0, 0, 0, 0.38)",
        "&$focused": {
          color: "#49853f",
        },
      },
    },

    MuiFormHelperText: {
      root: {
        fontSize: "11px",
      },
    },

    MuiStepIcon: {
      text: {
        fontSize: "11px",
      },
    },

    MuiTypography: {
      body1: {
        fontSize: "1rem !important",
      },
      caption: {
        fontSize: "1rem",
      },
    },

    MuiSelect: {
      root: {
        [breakpoints.up("sm")]: {
          maxWidth: "330px",
        },
        maxWidth: "200px",
      },
      selectMenu: {
        color: "rgba(0, 0, 0, .87)",
      },
    },

    MuiList: {
      root: {
        maxHeight: "300px",
      },
    },

    MuiMenuItem: {
      root: {
        fontSize: "1rem",
      },
    },

    MuiListItem: {
      button: {
        "&:hover": {
          backgroundColor: "#fafafa",
        },
      },
    },

    MuiListSubheader: {
      sticky: {
        backgroundColor: "#fafafa",
      },
    },

    MuiDialog: {
      paper: {
        [breakpoints.up("sm")]: {
          minWidth: "640px",
        },
      },
    },

    MuiPaper: {
      rounded: {
        borderRadius: "2px",
      },
    },
  },
}

const theme = createTheme(appTheme)

export default theme
