import React from "react"
import Loader from "../../ui/loader"
import PlainLink from "../../ui/link"
import { HtmlTooltip } from "../../../screens/main/calendar/components/eventsCalendar/components/calendarUI"
import { useQuery } from "@tanstack/react-query"
import { USERS } from "../../../library/networking/apiEndpoints"
import { User } from "../../../library/utils/user/userUtils"
import styled from "@emotion/styled"
import { GROUPS } from "../../../library/store/actions/types/groupsTypes"
import { generatePath } from "react-router-dom"
import { GROUP } from "../../../library/constants/routes"
import { getGroupLogo } from "../../../library/utils/getGroupLogo"
import { GROUP_TYPES } from "../../../library/constants/groupTypes"
import LockIcon from "@material-ui/icons/LockOutlined"
import { colors } from "../../../library/constants/styles/colors"

const Avatar = styled.img`
  width: 45px;
  height: 45px;
  margin-right: 10px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  background-color: #fff;
`

const GroupInfoTooltip = ({ group, children }) => {
  const { data, isLoading, isSuccess, isError, refetch } = useQuery({
    queryKey: [
      GROUPS.GET,
      {
        id: group.id,
        participants_limit: 1,
        connections_limit: 1,
        products_limit: 1,
      },
      "group-data-preview",
    ],
    staleTime: 60000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    retry: 1,
    enabled: false,
  })

  const [shouldFetch, setShouldFetch] = React.useState(false)

  React.useEffect(() => {
    if (shouldFetch) {
      refetch()
    }
  }, [shouldFetch])

  const path = generatePath(GROUP, { groupId: group.id })

  return (
    <HtmlTooltip
      onOpen={() => setShouldFetch(true)}
      onClose={() => setShouldFetch(false)}
      interactive
      placement={"bottom-start"}
      title={
        <>
          <div style={{ minWidth: 100 }}>
            {isLoading ? (
              <Loader />
            ) : (
              <div className={"flex items-center"}>
                <Avatar src={getGroupLogo(data)} />
                <div>
                  <div>
                    <p className={"text-black54"}>
                      {data.closed && (
                        <LockIcon
                          style={{
                            color: colors.primary,
                            fontSize: "1rem",
                            marginRight: "0.25rem",
                          }}
                        />
                      )}
                      {GROUP_TYPES[data?.type]?.title}
                    </p>
                    <PlainLink newTab bold to={path} inline>
                      {group.name}
                    </PlainLink>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      }
    >
      <div className={"inline-block"}>{children}</div>
    </HtmlTooltip>
  )
}

export default GroupInfoTooltip
