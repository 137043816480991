import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { generatePath, useParams } from "react-router-dom"
import {
  clearGroupKnowmixList,
  getGroupKnowmixList,
} from "../../../../../../library/store/actions/creators/groupsCreators"
import * as routes from "../../../../../../library/constants/routes"
import * as _ from "lodash"
import ListPage from "../../../../../../components/v2/layout/listPage/listPage"
import KnowMixSquareCard from "../../../../knowmix/knowmixCard/knowMixSquareCard"
import CourseCreationFormModal from "../../../../knowmix/CourseCreationFormModal/CourseCreationFormModal"

const GroupSettingsKnowmix = () => {
  const dispatch = useDispatch()
  const params = useParams()

  const { knowmixList, knowmixListEndReached } = useSelector(
    ({ groups }) => groups
  )

  return (
    <ListPage
      title={"Courses"}
      creationButtonTitle={"Create Course"}
      noResultsLabel={"This group doesn't have created Courses yet"}
      getList={async ({ name, offset, searchParams: { grades } }) =>
        await dispatch(
          getGroupKnowmixList({
            name: name || undefined,
            group_id: parseInt(params.groupId, 10),
            school_grades: grades
              ? _.map(_.split(grades, ","), _.parseInt)
              : undefined,
            offset,
          })
        )
      }
      ListContainer={({ children }) => (
        <div className={`cards-container cards-container--square`}>
          {children}
        </div>
      )}
      clearList={() => dispatch(clearGroupKnowmixList())}
      list={knowmixList}
      listEndReached={knowmixListEndReached}
      getListItemComponent={({ item }) => (
        <KnowMixSquareCard
          knowmix={item}
          hasMenu={true}
          menuItems={[
            {
              path: generatePath(routes.KNOWMIX_SETTINGS, {
                knowmixId: item.id,
              }),
              label: "Edit KnowMix",
            },
          ]}
          linkUrl={generatePath(routes.KNOWMIX, {
            knowmixId: item.id,
          })}
          key={item.id}
        />
      )}
      getFormDialogComponent={({ open, onClose }) => {
        return <CourseCreationFormModal isOpen={open} onClose={onClose} />
      }}
    />
  )
}

export default GroupSettingsKnowmix
