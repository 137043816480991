import React from "react"
import { SectionHeading } from "../uknow/uknowInfoPage"
import PlainLink from "../../../components/ui/link"
import { CONTACT_FORM } from "../../../library/constants/routes"
import img1 from "../../../assets/img/pages/solutions/educators/1.jpg"
import img2 from "../../../assets/img/pages/solutions/educators/2.png"
import img3_1 from "../../../assets/img/pages/solutions/teacher-intro/2.jpg"
import img3_2 from "../../../assets/img/pages/solutions/teacher-intro/3.jpg"
import img3_3 from "../../../assets/img/pages/solutions/teacher-intro/4.jpg"
import img4 from "../../../assets/img/pages/solutions/educators/4.png"
import img5 from "../../../assets/img/pages/solutions/educators/5.png"
import img6 from "../../../assets/img/pages/solutions/educators/6.png"
import img7 from "../../../assets/img/pages/solutions/educators/7.png"
import img8 from "../../../assets/img/pages/solutions/educators/8.png"
import img9 from "../../../assets/img/pages/solutions/educators/9.png"
import img10 from "../../../assets/img/pages/solutions/educators/10.png"
import img11 from "../../../assets/img/pages/solutions/educators/11.jpg"
import img12 from "../../../assets/img/pages/solutions/educators/12.png"
import img13 from "../../../assets/img/pages/solutions/educators/13.png"
import img14 from "../../../assets/img/pages/solutions/educators/14.png"
import img15 from "../../../assets/img/pages/solutions/educators/15.png"

const SolutionsEducatorsPage = () => {
  return (
    <>
      <section className={"bg-white w-full py-8"}>
        <div className="container">
          <SectionHeading>Educators</SectionHeading>
          <img
            src={img1}
            alt="Features for Educators"
            className={"rounded-2xl mb-8"}
          />
          <div className="grid grid-cols-12 gap-4">
            <div className="col-start-2 col-span-10">
              <SectionHeading>
                <span className={"italic"}>Magically Create more Time…</span>
                <br />
                More Time for You, More Learning for Them
              </SectionHeading>
              <p className={"mb-4 text-center"}>
                It’s time to own your teaching time with easy cohorting that
                gives you more time for individual instruction, so every student
                achieves. You just create a new kind of class meeting that uses
                AI to split your whole group into smaller cohorts. As long as
                your students have email, and you check your own calendar,
                you’ll know when to teach to which group.{" "}
              </p>
              <p className={"mb-4 text-center font-medium"}>
                {" "}
                Perfect Pace-Based or Project-Based Learning and Everyday
                Lessons{" "}
              </p>
              <p className={"mb-4 text-center"}>
                The fastest set-up to try Knowstory is listed with detailed
                images below – including the initial steps of opening your
                account and syncing your calendar. Keep this page open and start
                your account on another tab – follow the instructions and build
                your first auto-cohorting lesson or project. Learn from the How
                To section to later build all your courses into sequences of
                meetings that each break themselves into cohorts so you can have
                fully pace-based instruction.
              </p>
              <p className={"mb-6 text-center"}>
                <b>Once you have your account set up</b> – making auto-cohorting
                lessons is as simple as creating one class Meet which becomes
                multiple meetings setting to the calendar independently with
                cohorts dynamically formed from the pace of students. Based on
                rate-of-enrollment into each “class” meet, and your labeled open
                time for the class, every cohort can happen within your same
                class period or spread over several hours, days or weeks. Your
                tracking panels will tell you who’s running behind or ahead.
              </p>
              <img src={img2} alt="Meets" className={"mx-auto w-3/4"} />
            </div>
          </div>
        </div>
      </section>
      <section className={"py-8 bg-grey7"}>
        <div className="container">
          <SectionHeading>
            Important Note: split your formal vs. informal teaching & study time
          </SectionHeading>
          <p className={"text-center mx-auto w-5/6 mb-4"}>
            To make more time for you and more learning for all your students,
            auto-cohorting should be used just for your{" "}
            <i>formal instruction</i>.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 md:grid-rows-subgrid md:row-span-2 gap-4">
            <div>
              <img
                src={img3_1}
                alt={""}
                className={"max-h-72 w-full mb-4 object-center object-cover"}
              />
              <div className={"mb-4"}>
                <h3 className={"text-2xl font-medium"}>Formal Instruction</h3>
                <p className={"italic"}>Set a Meeting</p>
              </div>
              <div>
                <p className={"mb-4"}>
                  <b>Formal</b> instruction is time spent delivering verbally to
                  a group. It is usually lecture, presentation, hands-on project
                  time such as a lab, or discussion-style. It's anytime you are
                  verbalizing knowledge and giving guidance.
                </p>
                <p>
                  <i>Difference from 5-Day Week of 45-minute Classes:</i>
                  <br />
                  Now that the whole group is divided into cohorts, to keep your
                  calendaring optimum, * the lessons each week of formal
                  instruction time should be up to two :20 or :30-minute
                  meetings a week to accommodate multiple cohorts, one
                  :45-minute meeting, and the other two days check-ins only for
                  students. Every week of a course should be planned so that
                  cohorts may pace independently and rearrange members as some
                  pace faster or slower at any given time.
                </p>
              </div>
            </div>

            <div>
              <img
                src={img3_2}
                alt={""}
                className={"max-h-72 w-full mb-4 object-center object-cover"}
              />
              <div className={"mb-4"}>
                <h3 className={"text-2xl font-medium"}>Study Time</h3>
                <p className={"italic"}>Issue Resources & Assignments</p>
              </div>
              <div>
                <p className={"mb-4"}>
                  <b>Study time</b> is when students are doing the work assigned
                  with any resources independently. They could be working singly
                  or in pairs quietly, from anywhere. This time would optimally
                  be spent in a space separately from the teacher with another
                  adult having oversight and calling in the teacher as needed -
                  or the teacher roams doing informal instruction but can
                  proceed at any time to manage another Meeting.
                </p>
                <div>
                  <p>
                    When teachers are not doing formal or informal instruction,
                    they can:
                  </p>
                  <ol className="list-decimal ml-0 pl-8">
                    <li>Plan and issue additional personalizations</li>
                    <li>
                      Adjust individual student time spent on which subjects
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div>
              <img
                src={img3_3}
                alt={""}
                className={"max-h-72 w-full mb-4 object-center object-cover"}
              />
              <div className={"mb-4"}>
                <h3 className={"text-2xl font-medium"}>Informal Instruction</h3>
                <p className={"italic"}>Roaming Time & Check-ins</p>
              </div>
              <div>
                <p className={"mb-4"}>
                  <span className={"font-medium"}>
                    Roaming to do individual instruction, subject understanding
                    check-ins and motivation.
                  </span>{" "}
                  No calendaring is necessary for check-ins and can be done by
                  watching tracking of students across a course - paying
                  attention to individual students as needed for instruction or
                  motivation by roaming to them. This time toggles between the
                  spaces for formal instruction meetings, and purely study time
                  spent in homerooms or remotely with other adult oversight or a
                  rotation of teachers.
                </p>
                <p>
                  Informal instruction for remote students can use video
                  conferencing and messaging. Homerooms where students are
                  studying should have "quiet zones" for study and corners for
                  teachers to meet with students informally.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={"py-8 bg-white"}>
        <div className="container">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-start-2 col-span-10">
              <SectionHeading>Get Started Free!</SectionHeading>
              <p className={"mb-4"}>
                Get Started at Knowstory free or sign up for a Teacher Premium
                account for any schoolteacher or gig independent teacher to make
                a One-Teacher-School Group. The Group account will grant all
                your students similar advanced calendaring features while their
                personal profile is affiliated with your group. In your One
                Teacher School Group you can make course frames to sequence your
                auto-cohorting meetings.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-8 bg-grey7">
        <div className="container">
          <SectionHeading>
            Account Set-up & First Auto-Cohorting Class Meet
          </SectionHeading>
          <div className="grid grid-cols-2 gap-16 mb-8">
            <div>
              <h3 className={"text-xl font-medium mb-8"}>
                1. Create Educator Account & Profile
              </h3>
              <p>
                When registering on the site, you need to select the role of
                educator.
              </p>
            </div>
            <img src={img4} />
          </div>
          <div className="grid grid-cols-2 gap-16 items-center mb-8">
            <img src={img5} />
            <p>
              After you have registered on the site, you need to fill out a
              profile. You should go to “Edit your profile.”{" "}
            </p>
          </div>
          <div className="grid grid-cols-2 gap-16 items-center mb-8">
            <p>
              Now fill out your profile by clicking the icon of your initials
              and “Edit your profile.”
            </p>
            <img src={img6} />
          </div>
          <div className="grid grid-cols-2 gap-16 items-center mb-8">
            <img src={img7} />
            <p>
              Here you can change or fill in the main information, your
              contacts, education, work and personal information.
            </p>
          </div>
          <div className="grid grid-cols-2 gap-16 items-center mb-8">
            <p>
              Also under the icon of your initials on the top right in settings
              you can:
            </p>
            <img src={img8} />
          </div>
          <ul>
            <li className={"mb-4"}>
              Change password, email, and your role. In some instances, the Role
              is locked, and you will need to{" "}
              <PlainLink to={CONTACT_FORM} newTab inline>
                contact us
              </PlainLink>{" "}
              to change it.
            </li>
            <li className={"mb-4"}>
              In <b>Holds</b>, you can add your personal holds which are a way
              for you to bracket <i>open time</i> but make it just your class
              periods or time you will work as a gig teacher or tutor, or
              separate Holds for each subject or course. A Hold is special
              because inside of it you can also have <i>minus times</i> out of
              it such as a lunch time or a break time.
            </li>
            <li className={"mb-4"}>
              You can <b>block</b> unwanted users. Under <b>verification</b> is
              where you go to request being verified if you want to add students
              to a Course.
            </li>
            <li className={"mb-4"}>
              Under <b>integrate calendar</b> you can integrate Google and
              Outlook calendar.
            </li>
            <li className={"mb-4"}>
              You can add <b>appointment links</b>and <b>types</b> you will
              allow in bookings by others.
            </li>
            <li>
              Under subscriptions is where you will see your personal
              subscription or group subscriptions and a button to go to pricing.
            </li>
          </ul>
        </div>
      </section>
      <section className="py-8 bg-white">
        <div className="container">
          <div className="grid grid-cols-2 gap-16 mb-8">
            <div>
              <h3 className={"text-xl font-medium mb-8"}>
                2. Go to Calendar Connect & Make An AI Cohorting Meet
              </h3>
              <p>
                On your main page is a button labeled “Calendar Connect” – click
                on it to see <b>“AI Cohorting Meet.”</b>
              </p>
            </div>
            <img src={img9} />
          </div>
          <div className="grid grid-cols-2 gap-16 items-center mb-8">
            <img src={img10} />
            <p>
              Select <b>“Meet with no Course Frame”</b> and hit Next.
            </p>
          </div>
          <div className="mb-8">
            <p>Fill out this form – notes are below about each part.</p>
            <img src={img11} />
          </div>
          <div className="grid grid-cols-2 gap-16 items-center mb-8">
            <p>
              After you hit “Save” you will see a pop-up telling you “Your Meet
              has been created.”
            </p>
            <img src={img12} />
          </div>
        </div>
      </section>
      <section className="py-8 bg-grey7">
        <div className="container">
          <div className="grid grid-cols-2 gap-16 mb-8">
            <div>
              <h3 className={"text-xl font-medium mb-8"}>
                3. Go to your Tracking Tab and look under Meets “Managed”
              </h3>
            </div>
            <img src={img13} />
          </div>
          <div className="grid grid-cols-2 gap-16 items-center mb-8">
            <img src={img14} />
            <div className={"flex flex-col justify-between"}>
              <div>
                <p className={"mb-2"}>
                  Notice it shows you have invited 9 attendees. It shows “T” for
                  target date because no participants have accepted yet and
                  filled a cohort. Once a cohort accepts it will show “S” for
                  the first meeting set and add you as a participant.
                </p>
                <p>
                  You can click on the (i) symbol to see the meeting details and
                  who has accepted so far:
                </p>
              </div>
              <p>
                Notice how the 3 who have accepted now have a “Push Forward”
                symbol next to their names and the exact time they accepted. The
                first cohort meeting is now set for the day just those three
                students who accepted.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-16 items-center">
            <p>
              <b>
                Over on your participant’s screens will be a Tracking panel for
                Incoming Meets
              </b>{" "}
              like the below screen shot. If they are not a member they will
              receive a similar email. They can “Accept” which will enroll them
              in the next cohort. They can also decline. You will be able to see
              if they decline in your own tracking. Class Meets in full courses
              under paid accounts in an educator group do not allow participants
              to decline, only accept or push forward. Once a participant
              accepts here, they will see the option of pushing forward. They
              also have the option of assigning a “Hold” of open bracketed time
              themselves for calculations.
            </p>
            <img src={img15} />
          </div>
        </div>
      </section>
      <section className="py-8 bg-grey7">
        <div className="container">
          <h3 className={"text-xl font-medium mb-8"}>
            4. Go to meetings as they calendar and take attendance.
          </h3>
          <p>
            Now just watch for meetings to pop onto your calendar as your
            participants accept. You can create additional auto-cohorting
            meetings and issue those as well for the same project – just
            numbering them. You have the option of creating a mini-course with a
            paid account within a One Teacher School group or School group that
            will allow you to tether auto-cohorting meetings together and issue
            the course only to those you invite – or offer it as a paid course
            online.{" "}
          </p>
        </div>
      </section>
    </>
  )
}

export default SolutionsEducatorsPage
