import { useUser } from "../../../../../hooks/data/user/useUser"
import { usePostsList } from "../../../../../hooks/data/feed/posts"
import { useInView } from "react-intersection-observer"
import PostsListSkeleton from "../../../../../components/v2/layout/skeleton/feed/postsListSkeleton"
import PostProvider from "../../../../../components/v2/feed/post/PostContext"
import ErrorBoundary from "../../../../../components/v2/ErrorBoundary"
import Post from "../../../../../components/v2/feed/post/post"
import Loader from "../../../../../components/ui/loader"
import React from "react"
import { TextLineSkeleton } from "../../../../../components/v2/layout/skeleton/components/textSkeleton"
import { useIdParam } from "../../../../../hooks/useIdParam"

const UserFeedList = ({ postsListQueryKey }) => {
  const userId = useIdParam("userId")
  const { user } = useUser(userId)

  const query = usePostsList({
    queryKey: postsListQueryKey,
    userId: user?.id,
  })

  const onScroll = () => {
    if (query.hasNextPage && !query.isFetchingNextPage) {
      query.fetchNextPage()
    }
  }

  const { ref } = useInView({
    threshold: 0.8,
    onChange: (inView) => {
      if (inView) onScroll()
    },
  })

  const allItems = query.data ? query.data.pages.flat() : []

  return (
    <>
      <div className={"box posts-list__header pb10"}>
        <nav className="h-tabs h-tabs--ghost jcfs">
          <ul className="h-tabs__nav-list h-tabs__nav-list--secondary">
            {query.isInitialLoading ? (
              <li>
                <TextLineSkeleton width={"70px"} />
              </li>
            ) : (
              <li className="h-tabs__item h-tabs__item--active">
                <span className="h-tabs__link">
                  {!query.hasNextPage && !allItems.length
                    ? "No posts yet"
                    : "All posts"}
                </span>
              </li>
            )}
          </ul>
        </nav>
        <div className="h-divider--without_margin" />
      </div>
      {query.isInitialLoading ? (
        <PostsListSkeleton postsNumber={10} />
      ) : query.isError ? (
        <div className={"text-center"}>
          <p className="color-black-54 mb10">Something went wrong</p>
          <p style={{ fontSize: 30, lineHeight: "30px" }}>&#128533;</p>
        </div>
      ) : (
        <div style={{ display: "grid", gap: 15 }}>
          {allItems.map((item) => (
            <PostProvider
              key={item.id}
              post={item}
              pageOwnerUser={user}
              queryKey={postsListQueryKey}
            >
              <ErrorBoundary message={"Unable to load post"}>
                <Post />
              </ErrorBoundary>
            </PostProvider>
          ))}
          <div ref={ref}>{query.isFetchingNextPage && <Loader />}</div>
        </div>
      )}
    </>
  )
}

export default UserFeedList
