import React, { Component } from "react"
import { GROUP_TYPES } from "../../../../library/constants/groupTypes"
import { generatePath, Link, withRouter } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import DropdownControl from "../../../../components/ui/dropdowns/dropdownControl/dropdownControl"
import MenuList from "@material-ui/core/MenuList/MenuList"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import Button from "@material-ui/core/Button/Button"
import { bindActionCreators } from "redux"
import {
  deleteGroup,
  groupInviteDecline,
  groupRequestsCancel,
  groupRequestsDecline,
  joinGroup,
  leaveGroup,
} from "../../../../library/store/actions/creators/groupsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Dialog from "@material-ui/core/Dialog/Dialog"
import { withStyles } from "@material-ui/core"
import { colors } from "../../../../library/constants/styles/colors"
import { getGroupLogo } from "../../../../library/utils/getGroupLogo"
import getErrorText from "../../../../library/constants/errorTypes"
import LineSlice from "../../../../components/ui/lineSlice"

const CustomMenuItem = withStyles({
  root: {
    color: colors.primary,
  },
})(MenuItem)

class SearchResultGroup extends Component {
    state = {
        isMember: this.props.group.is_member,
        isRequesting: this.props.group.is_requesting,
        isCreator: this.props.group.is_creator || undefined,
        noRelations: !!(!this.props.group.is_requesting && !this.props.group.is_member && !this.props.group.is_admin),
        participantsNumber: this.props.group.participants_number,
        isGroupDeleted: false,
        openGroupDelete: false,
    };

    joinGroup = async () => {
        const {group: {id, closed, is_invited, participants_number}} = this.props;

        try {
            await this.props.joinGroup({id});

            if (closed) {
                is_invited ? this.setState({isMember: true}) : this.setState({isRequesting: true});
                this.setState({noRelations: false});
            } else {
                this.setState({
                    noRelations: false,
                    isMember: true,
                    participantsNumber: participants_number + 1,
                });
            }
        } catch ({error}) {
            this.props.enqueueSnackbar(getErrorText(error.code), {variant: "error"});
        }
    };

    leaveGroup = async () => {
        const {group: {id, participants_number}} = this.props;

        try {
            await this.props.leaveGroup({id});

            this.setState({
                isMember: false,
                noRelations: true,
                participantsNumber: participants_number,
            });
        } catch ({error}) {
            this.props.enqueueSnackbar(getErrorText(error.code), {variant: "error"});
        }
    };

    groupRequestDecline = async () => {
        const {group: {id}} = this.props;

        try {
            await this.props.groupInviteDecline({group_id: id});
        } catch ({error}) {
            this.props.enqueueSnackbar(getErrorText(error.code), {variant: "error"});
        }
    };

    groupRequestCancel = async () => {
        const {group: {id}} = this.props;

        try {
            await this.props.groupRequestsCancel({group_id: id});
            this.setState({
                isRequesting: false,
                noRelations: true,
            });
        } catch ({error}) {
            this.props.enqueueSnackbar(getErrorText(error.code), {variant: "error"});
        }
    };

    initiateGroupDelete = () => {
        this.setState({openGroupDelete: true});
    };

    handleCloseGroupDelete = () => {
        this.setState({openGroupDelete: false});
    };

    processDeleteGroup = async () => {
        const {id} = this.props.group;

        try {
            await this.props.deleteGroup({id});
            this.setState({isGroupDeleted: true});
            this.props.enqueueSnackbar("Group successfully deleted", {variant: "success"});
        } catch ({error}) {
            this.props.enqueueSnackbar(getErrorText(error.code), {variant: "error"});
        }
    };

    render() {
        const {id, name, description, type, closed} = this.props.group;

        const {isMember, isRequesting, noRelations, participantsNumber, isGroupDeleted} = this.state;

        if (isGroupDeleted) return null;

        const photoUrl = getGroupLogo(this.props.group);
        const groupStats = `${GROUP_TYPES[type].title} (${closed ? "closed" : "open"} group), ${participantsNumber} members`;
        const groupUrl = generatePath(routes.GROUP, {groupId: id});


        const dropdownControl = () => {
            const buttonLabel = () => {
                if (isMember) {
                    return "Member";
                } else if (isRequesting) {
                    return "Joining";
                } else {
                    return "";
                }
            };
            return (
                noRelations ? (
                    <Button color="primary" variant="contained" onClick={this.joinGroup}>
                        {type !== 2 ? "Join group" : "Join event"}
                    </Button>
                ) : (
                    <DropdownControl buttonLabel={buttonLabel()}>
                        <MenuList>
                            {this.props.group.is_creator && (
                                <CustomMenuItem
                                    disabled={type === 5}
                                    onClick={type !== 5 ? this.initiateGroupDelete : false}
                                >
                                    Delete group
                                </CustomMenuItem>
                            )}
                            {(isMember && !this.props.group.is_creator) && (
                                <CustomMenuItem onClick={this.leaveGroup}>
                                    Leave the group
                                </CustomMenuItem>
                            )}
                            {isRequesting && (
                                <CustomMenuItem onClick={this.groupRequestCancel}>
                                    Cancel request
                                </CustomMenuItem>
                            )}
                        </MenuList>
                    </DropdownControl>
                )
            );
        };

        return (
            <div className="box list-item">
                <div className="f aic full-width">
                    <Link to={groupUrl} className="list-item__img">
                        <img src={photoUrl} alt={name}/>
                    </Link>
                    <div className="list-item__info">
                        <Link to={groupUrl} className="link list-item__name">{name}</Link>
                        <span className="list-item__description">{groupStats}</span>
                        {description !== "Unknown" && (
                            <div className="break-word">
                                <LineSlice
                                    text={description}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="list-item__control-panel">
                    {dropdownControl()}
                </div>

                <Dialog
                    open={this.state.openGroupDelete}
                    onClose={this.handleCloseGroupDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Delete group from site?</DialogTitle>
                    <DialogContent>
                        <p className="color-black-54 font-16">
                            If you delete group you will not be able to view information on the site. You can restore
                            the group only through the support site.
                        </p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseGroupDelete} color="primary">
                            Decline
                        </Button>
                        <Button onClick={this.processDeleteGroup} color="primary" autoFocus>
                            Accept
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = ({auth, search}) => ({auth, search});
const mapDispatchToProps = dispatch => bindActionCreators({
    joinGroup,
    leaveGroup,
    deleteGroup,
    groupRequestsDecline,
    groupInviteDecline,
    groupRequestsCancel,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withSnackbar(SearchResultGroup)));
