import React from "react"
import { useIdParam } from "../../../../../../hooks/useIdParam"
import { useSelector } from "react-redux"
import {
  useGroupSubscriptionsQuery,
  useSubscriptions,
} from "../../../../user/userProfileSettings/profileSettingsSubscriptions/useSubscriptions"
import Loader from "../../../../../../components/ui/loader"
import Button from "@material-ui/core/Button"
import { generatePath, Link, Redirect } from "react-router-dom"
import {
  CONTACT_FORM,
  GROUP_SETTINGS,
  PRICING,
} from "../../../../../../library/constants/routes"
import { PlainButton } from "../../../../../../components/v2/controls"
import LinkIcon from "@material-ui/icons/Link"
import GroupSubscriptionInfo from "./groupSubscriptionInfo"
import PlainLink from "../../../../../../components/ui/link"

const GroupSettingsSubscription = () => {
  const groupId = useIdParam("groupId")
  const { currentGroup } = useSelector(({ groups }) => groups)
  const userData = useSelector(({ auth }) => auth.userData)

  const { subscriptions, isSuccess } = useGroupSubscriptionsQuery(
    currentGroup.id
  )

  const { openCustomerPortal } = useSubscriptions()

  if (currentGroup.creator.id !== userData.id) {
    return <Redirect to={generatePath(GROUP_SETTINGS, { groupId })} />
  }

  const subscription = subscriptions?.subscription
  const systemSubscription = subscriptions?.system_subscription

  return (
    <div className="box">
      <div className="box__heading">Subscription</div>
      <div className="box__content">
        {isSuccess ? (
          <>
            {!!systemSubscription && (
              <div className="flex flex-col items-center mb-8">
                <p className={"text-center mb-2"}>
                  This group is using a system subscription to{" "}
                  {systemSubscription.plan_name} plan. <br />
                  To manage it,{" "}
                  <PlainLink to={CONTACT_FORM} inline newTab>
                    contact the support
                  </PlainLink>
                  .
                </p>
              </div>
            )}

            {!!subscription ? (
              <>
                <GroupSubscriptionInfo
                  subscription={subscription}
                  canManage={[4, 5].includes(currentGroup.type)}
                />
                <div className="mt-8 flex flex-col items-center">
                  <p className={"text-center mb-2"}>
                    You can manage your subscriptions, change payment details,
                    see previous payments in the
                  </p>
                  <PlainButton onClick={openCustomerPortal} inline>
                    <LinkIcon color={"primary"} />
                    <span>Customer portal</span>
                  </PlainButton>
                </div>
              </>
            ) : (
              <div className="flex flex-col items-center">
                <p className={"text-center mb-2"}>
                  Check out our pricing plans and choose the one that suits your
                  group best
                </p>
                <Button
                  component={Link}
                  to={PRICING}
                  color={"primary"}
                  variant={"contained"}
                >
                  Go to pricing
                </Button>
              </div>
            )}
          </>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  )
}

export default GroupSettingsSubscription
