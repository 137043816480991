import React from "react"
import {
  getData,
  updateInfo,
} from "../../../../../library/store/actions/creators/authCreators"
import { useDispatch, useSelector } from "react-redux"
import { countries, states } from "../../../../../library/constants/states"
import BoxPage from "../../../../../components/v2/layout/boxPage/boxPage"
import * as Yup from "yup"
import Form from "../../../../../components/v2/layout/forms/form/form"
import { Formik } from "formik"
import { useAlert } from "../../../../../hooks/useAlert"

const validationSchema = Yup.object({
  city: Yup.string().max(50),
  backup_email: Yup.string().max(100).email(),
  // phones: Yup.array().of(
  //   Yup.string().max(50).matches(phoneRegEx, 'Invalid phone format')
  // ),
})

const ProfileEditContacts = () => {
  const { userData } = useSelector(({ auth }) => auth)
  const dispatch = useDispatch()
  const { successAlert, errorAlert } = useAlert()

  const initialValues = {
    country: userData.country,
    state: userData.state,
    city: userData.city,
    backup_email: userData.backup_email,
    // phones: userData.phones || [],
  }

  const onSubmit = async (values) => {
    try {
      await dispatch(
        updateInfo({
          ...values,
          // phones: values.phones.filter((item) => !!item.length),
        })
      )
      await dispatch(getData())
      successAlert()
    } catch (error) {
      errorAlert(error)
    }
  }

  return (
    <BoxPage title="Contacts">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(fk) => (
          <Form>
            <Form.Row name={'country'} label={'Country'}>
              <Form.Select
                name={'country'}
                placeholder={'Select your country'}
                // disabled
                options={[
                  { label: 'Select country' },
                  ...countries.map((c) => ({ value: c, label: c })),
                ]}
              />
            </Form.Row>
            {fk.values.country === 'USA' && (
              <Form.Row name={'state'} label={'State'}>
                <Form.Select
                  name={'state'}
                  placeholder={'Select your state'}
                  options={[
                    { label: 'Select state' },
                    ...states.map((state) => ({ value: state, label: state })),
                  ]}
                />
              </Form.Row>
            )}
            <Form.Row name={'city'} label={'City'}>
              <Form.TextField name={'city'} placeholder={'Type your city'} />
            </Form.Row>
            <Form.Divider />
            <Form.Row name={'backup_email'} label={'Additional email'}>
              <Form.TextField
                name={'backup_email'}
                placeholder={'Type your additional email'}
              />
            </Form.Row>
            {/*<Form.Row name={'phones'} label={'Contact phone'}>*/}
            {/*  <Form.InputsArray*/}
            {/*    name={'phones'}*/}
            {/*    placeholder={'Type your contact phone'}*/}
            {/*  />*/}
            {/*</Form.Row>*/}
          </Form>
        )}
      </Formik>
    </BoxPage>
  )
}

export default ProfileEditContacts
