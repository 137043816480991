import React, { Component } from "react"
import "./product.scss"
import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import SettingsOutlined from "@material-ui/icons/SettingsOutlined"
import ProductHeader from "./productHeader/productHeader"
import ProductDetails from "./productDetails/productDetails"
import ProductAttachments from "./productAttachments/productAttachments"
import ProductTestimonials from "./productTestimonials/productTestimonials"
import { withSnackbar } from "notistack"
import ProductInfo from "./productInfo/productInfo"
import ProductPhotosSlider from "./productPhotosSlider/productPhotosSlider"
import { bindActionCreators } from "redux"
import {
  addProductToLibrary,
  getProduct,
  purchaseProduct,
} from "../../../../library/store/actions/creators/productsCreators"
import { connect } from "react-redux"
import Loader from "../../../../components/ui/loader/loader"
import ProductReviews from "./productReviews/productReviews"
import Button from "@material-ui/core/Button"
import NotFound from "../../../../components/notFound/notFound"
import returnExternalUrl from "../../../../library/utils/returnExternalUrl"
import getErrorText from "../../../../library/constants/errorTypes"
import ProductError from "../productError/productError"
import ReplyOutlined from "@material-ui/icons/ReplyOutlined"
import { sharePost } from "../../../../library/store/actions/creators/postsCreators"
import StarOutlined from "@material-ui/icons/StarOutlined"
import StarBorderOutlined from "@material-ui/icons/StarBorderOutlined"
import {
  createBookmark,
  deleteBookmark,
  foldersList,
} from "../../../../library/store/actions/creators/bookmarksCreators"
import {
  createInvenstoryRecord,
  deleteInvenstoryRecord,
} from "../../../../library/store/actions/creators/invenstoryCreators"
import AddToFolderModal from "../../bookmarks/folders/addToFolder/addToFolder"
import ComplaintsModal from "../../../../components/complaints/complaintsModal"
import ErrorOutline from "@material-ui/icons/ErrorOutline"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle"
import PublicIcon from "@material-ui/icons/Public"
import SettingsApplicationsOutlinedIcon from "@material-ui/icons/SettingsApplicationsOutlined"
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined"
import PurchaseDialog from "./purchaseModal"
import { defaultMaxDate } from "../../../../library/constants/defaultDates"
import { format } from "date-fns"
import ScheduleDemoDialog from "./scheduleDemoModal"
import SEO from "../../../../components/SEO"

class Product extends Component {
  state = {
    bookmarked: false,
    openAddToFolderDialog: false,
    dataReceived: false,
    error404: false,
    deleted: false,
    blocked: false,
    complaintsModal: false,
    isReported: false,
    purchaseDialogOpen: false,
    scheduleDemoDialogOpen: false,
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props

    try {
      await this.props.getProduct({
        type: "init",
        id: parseInt(params.productId),
      })
      await this.setState({
        acquisition: this.props.products.currentProduct.acquisition,
        bookmarked: !!this.props.products.currentProduct.bookmark,
        deleted:
          this.props.products.currentProduct.deleted &&
          !this.props.products.currentProduct.acquisition,
        blocked:
          this.props.products.currentProduct.blocked &&
          !this.props.products.currentProduct.acquisition,
        isReported: !!this.props.products.currentProduct.is_reported,
        dataReceived: true,
      })
    } catch ({ error }) {
      if (error.code === 30000) {
        this.setState({ error404: true })
      } else {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.products.currentProduct !==
        prevProps.products.currentProduct &&
      this.props.products.currentProduct
    ) {
      this.setState({
        acquisition: this.props.products.currentProduct.acquisition,
      })
    }
  }

  handleClickOpen = (type) => {
    switch (type) {
      case "addToFolder":
        this.setState({ openAddToFolderDialog: true })
        break

      default:
        return
    }
  }

  handleClose = (type) => {
    switch (type) {
      case "addToFolder":
        this.setState({ openAddToFolderDialog: false })
        break

      default:
        return
    }
  }

  addToLibrary = async () => {
    const {
      products: {
        currentProduct: { id },
      },
    } = this.props
    try {
      await this.props.addProductToLibrary({ product_id: id })
      await this.props.getProduct({ id })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  processShare = async () => {
    const { products } = this.props
    try {
      await this.props.sharePost({ product_id: products.currentProduct.id })
      await this.props.getProduct({ id: products.currentProduct.id })
      this.props.enqueueSnackbar("Product has been shared", {
        variant: "success",
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  addToBookmarks = async () => {
    const {
      products: {
        currentProduct: { id },
      },
    } = this.props

    try {
      await this.props.createBookmark({ product_id: id })
      await this.props.foldersList()
      this.setState({ bookmarked: true })
      await this.props.enqueueSnackbar("Saved to bookmarks", {
        autoHideDuration: 5000,
        action: (key) => {
          return (
            <>
              <Button
                color="primary"
                onClick={() => {
                  this.props.closeSnackbar(key)
                  this.props.history.push(routes.BOOKMARKS_PRODUCTS)
                }}
              >
                View
              </Button>
              {this.props.bookmarks.folders.length > 0 && (
                <Button
                  color="primary"
                  onClick={() => this.handleClickOpen("addToFolder")}
                >
                  Add to folder
                </Button>
              )}
            </>
          )
        },
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  deleteBookmark = async () => {
    const {
      products: {
        currentProduct: { id },
      },
    } = this.props

    this.props
      .deleteBookmark({ product_id: id })
      .then(this.setState({ bookmarked: false }))
  }

  addToInvenstory = async () => {
    const {
      name,
      subjects,
      content_type,
      grades,
      platforms,
      file_types,
      standards,
      url,
      version,
      id,
    } = this.props.products.currentProduct
    try {
      await this.props.createInvenstoryRecord({
        name,
        subjects,
        content_type,
        grades,
        platforms,
        file_types,
        url,
        version,
        product_id: id,
        standards: standards.map((s) => s.id),
      })
      await this.props.getProduct({ type: "init", id })
      this.props.enqueueSnackbar("Product added to your InvenStory", {
        variant: "success",
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  removeFromInvenstory = async () => {
    const { record, id } = this.props.products.currentProduct
    try {
      await this.props.deleteInvenstoryRecord({ id: record.id })
      await this.props.getProduct({ type: "init", id })
      this.props.enqueueSnackbar("Product removed from your InvenStory", {
        variant: "success",
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  showComplaintsModal = () => {
    this.setState({ complaintsModal: true })
  }

  hideComplaintsModal = () => {
    this.setState({ complaintsModal: false })
  }

  showPurchaseDialog = async () => {
    try {
      await this.props.purchaseProduct({
        product_id: this.props.products.currentProduct.id,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
    this.setState({ purchaseDialogOpen: true })
  }

  closePurchaseDialog = () => {
    this.setState({ purchaseDialogOpen: false })
  }

  showScheduleDemoDialog = async () => {
    this.setState({ scheduleDemoDialogOpen: true })
  }

  closeScheduleDemoDialog = () => {
    this.setState({ scheduleDemoDialogOpen: false })
  }

  render() {
    const {
      acquisition,
      bookmarked,
      dataReceived,
      error404,
      deleted,
      blocked,
    } = this.state
    const { currentProduct } = this.props.products
    if (error404) {
      return <NotFound entity="Product" />
    }

    if (deleted || blocked) {
      return <ProductError product={this.props.products.currentProduct} />
    }

    if (dataReceived) {
      const {
        can_delete,
        can_edit,
        creator_user,
        creator_group,
        url,
        paid,
        info_reviews,
        id,
        has_demo,
        scheduled_demo,
      } = this.props.products.currentProduct

      const creator = creator_user
        ? {
            link: generatePath(routes.USER, { userId: creator_user.id }),
            name: creator_user.first_name + " " + creator_user.last_name,
            email: creator_user.email,
          }
        : {
            link: generatePath(routes.USER, {
              userId: creator_group.creator.id,
            }),
            name:
              creator_group.creator.first_name +
              " " +
              creator_group.creator.last_name,
            email: creator_group.creator.email,
          }

      return (
        <div className="common-page__content common-page__content--static">
          <SEO title={currentProduct.name} />
          <ComplaintsModal
            open={this.state.complaintsModal}
            product_id={id}
            onClose={this.hideComplaintsModal}
            callback={() => this.setState({ isReported: true })}
          />
          <main className="common-page__main">
            <ProductHeader />
            {(!!acquisition || can_edit || can_delete) && (
              <ProductAttachments />
            )}
            <ProductDetails />
            {info_reviews.length > 0 && <ProductTestimonials />}
            <ProductReviews />
          </main>
          <aside className="common-page__sidebar">
            <div className="box">
              <div className="box__content">
                <ProductPhotosSlider />
              </div>
            </div>

            <ProductInfo />

            {can_edit && (
              <div className="box">
                <div className="box__content">
                  <Link
                    to={generatePath(routes.PRODUCT_SETTINGS, {
                      productId: this.props.match.params.productId,
                    })}
                    className="link"
                  >
                    <SettingsOutlined className="color-black-38 mr8" /> Manage
                  </Link>
                </div>
              </div>
            )}
            {(!!acquisition || can_edit) &&
              currentProduct.content_type === 15 &&
              currentProduct.knowmix && (
                <div className="box">
                  <div className="box__content">
                    {!!acquisition &&
                      currentProduct.content_type === 15 &&
                      currentProduct.knowmix && (
                        <Link
                          className={`link ${can_edit && "mb15"}`}
                          to={generatePath(routes.KNOWMIX, {
                            knowmixId: currentProduct.knowmix.id,
                          })}
                        >
                          <VisibilityOutlinedIcon className="mr8 color-black-38" />
                          Go to KnowMix
                        </Link>
                      )}
                    {can_edit &&
                      currentProduct.content_type === 15 &&
                      currentProduct.knowmix && (
                        <Link
                          className="link"
                          to={generatePath(routes.KNOWMIX_SETTINGS, {
                            knowmixId: currentProduct.knowmix.id,
                          })}
                        >
                          <SettingsApplicationsOutlinedIcon className="mr8 color-black-38" />{" "}
                          Manage KnowMix
                        </Link>
                      )}
                  </div>
                </div>
              )}
            <div className="box">
              <div className="box__content">
                {!!url && (
                  <a className="link mb15" href={`${returnExternalUrl(url)}`}>
                    <PublicIcon className="mr8 color-black-38" /> Visit product
                    website
                  </a>
                )}
                {!!acquisition &&
                  (!currentProduct.record ? (
                    <div className="link mb15" onClick={this.addToInvenstory}>
                      <AddCircleOutlineIcon className="mr8 color-black-38" />{" "}
                      Add to my InvenStory
                    </div>
                  ) : (
                    <div
                      className="link mb15"
                      onClick={this.removeFromInvenstory}
                    >
                      <RemoveCircleIcon className="mr8 color-primary" /> Remove
                      from my InvenStory
                    </div>
                  ))}
                <div className="link mb15" onClick={this.processShare}>
                  <ReplyOutlined
                    className={`flip-h mr8 ${
                      this.props.products.currentProduct.is_shared
                        ? "color-primary"
                        : "color-black-38"
                    }`}
                  />{" "}
                  Share with friends
                </div>
                {!bookmarked ? (
                  <div
                    className={`link ${!can_edit && "mb15"}`}
                    onClick={this.addToBookmarks}
                  >
                    <StarBorderOutlined className="mr8 color-black-38" /> Add to
                    bookmarks
                  </div>
                ) : (
                  <div
                    className={`link ${!can_edit && "mb15"}`}
                    onClick={this.deleteBookmark}
                  >
                    <StarOutlined className="mr8 color-primary" /> Remove from
                    bookmarks
                  </div>
                )}
                {!can_edit && (
                  <div
                    className={`link ${
                      this.state.isReported ? "link--disabled" : ""
                    }`}
                    onClick={() =>
                      !this.state.isReported && this.showComplaintsModal()
                    }
                  >
                    <ErrorOutline className="color-black-38 mr8" /> Report
                  </div>
                )}
              </div>
            </div>

            <div className="full-width">
              {!acquisition &&
                !this.props.products.currentProduct.deleted &&
                !this.props.products.currentProduct.blocked &&
                (paid ? (
                  <>
                    {/*<Button*/}
                    {/*    component={Link}*/}
                    {/*    to={creator}*/}
                    {/*    color="primary"*/}
                    {/*    className={can_edit && currentProduct.content_type === 15 && currentProduct.knowmix ? "mb5" : "mb15"}*/}
                    {/*    fullWidth*/}
                    {/*    variant="contained"*/}
                    {/*>*/}
                    {/*    Contact to Purchase*/}
                    {/*</Button>*/}
                    <Button
                      color="primary"
                      className={
                        can_edit &&
                        currentProduct.content_type === 15 &&
                        currentProduct.knowmix
                          ? "mb5"
                          : "mb15"
                      }
                      fullWidth
                      variant="contained"
                      onClick={this.showPurchaseDialog}
                    >
                      Contact to Purchase
                    </Button>
                    {has_demo && !scheduled_demo && (
                      <Button
                        color="primary"
                        className={
                          can_edit &&
                          currentProduct.content_type === 15 &&
                          currentProduct.knowmix
                            ? "mb5"
                            : "mb15"
                        }
                        fullWidth
                        variant="outlined"
                        onClick={this.showScheduleDemoDialog}
                      >
                        Schedule Demo
                      </Button>
                    )}
                    {scheduled_demo && (
                      <span className={"color-black-54"}>
                        Demo already scheduled
                      </span>
                    )}
                    {can_edit &&
                      currentProduct.content_type === 15 &&
                      currentProduct.knowmix && (
                        <p className="color-black-54 mb15">
                          Add product to your product list to start the KnowMix
                        </p>
                      )}
                  </>
                ) : (
                  <>
                    <Button
                      color="primary"
                      variant="contained"
                      className={
                        can_edit &&
                        currentProduct.content_type === 15 &&
                        currentProduct.knowmix
                          ? "mb5"
                          : "mb15"
                      }
                      fullWidth
                      onClick={this.addToLibrary}
                    >
                      Add to My Product List
                    </Button>
                    {can_edit &&
                      currentProduct.content_type === 15 &&
                      currentProduct.knowmix && (
                        <p className="color-black-54 mb15">
                          Add product to your product list to start the KnowMix
                        </p>
                      )}
                  </>
                ))}
              {acquisition && acquisition.date_to !== defaultMaxDate && (
                <span className={"color-black-38"}>
                  Available till:{" "}
                  {format(new Date(acquisition.date_to), "d MMM yyyy")}
                </span>
              )}
            </div>
          </aside>
          {this.state.openAddToFolderDialog && (
            <AddToFolderModal
              open={this.state.openAddToFolderDialog}
              handleClose={() => this.handleClose("addToFolder")}
            />
          )}
          {this.state.purchaseDialogOpen && (
            <PurchaseDialog
              open={this.state.purchaseDialogOpen}
              handleClose={this.closePurchaseDialog}
              productName={currentProduct.name}
              productCreator={creator}
              contactEmail={currentProduct.contact_email}
            />
          )}
          {this.state.scheduleDemoDialogOpen && (
            <ScheduleDemoDialog
              open={this.state.scheduleDemoDialogOpen}
              handleClose={this.closeScheduleDemoDialog}
            />
          )}
        </div>
      )
    } else {
      return <Loader />
    }
  }
}

const mapStateToProps = ({ auth, products, bookmarks }) => ({
  auth,
  products,
  bookmarks,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getProduct,
      addProductToLibrary,
      sharePost,
      createBookmark,
      deleteBookmark,
      foldersList,
      createInvenstoryRecord,
      deleteInvenstoryRecord,
      purchaseProduct,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(Product))
