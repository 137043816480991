import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import React from "react"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import { Box, Dialog, DialogContent } from '@material-ui/core'

const InfoModal = ({ open, onClose, title, leftButton, children }) => {
  return (
    <Dialog
      aria-labelledby="info-dialog-title"
      onClose={onClose}
      open={open}
    >
      <DialogTitle id="info-dialog-title" className="text-center">
        {title}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <div className="f jcsb full-width">
          <Box>
            {!!leftButton && (
              <Button
                color='primary'
                onClick={leftButton.action}
              >
                {leftButton.label}
              </Button>
            )}
          </Box>

          <Button color="primary" onClick={onClose}>
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default InfoModal
