import React from "react"
import { useIdParam } from "../../../../../../../hooks/useIdParam"
import BoxPage from "../../../../../../../components/v2/layout/boxPage/boxPage"
import { useToggle } from "../../../../../../../hooks/useToggle"
import Button from "@material-ui/core/Button"
import GroupCreatorTransferModal from "./GroupCreatorTransferModal"
import { useSelector } from "react-redux"
import { generatePath, Redirect } from "react-router-dom"
import { GROUP_SETTINGS } from "../../../../../../../library/constants/routes"

const GroupSettingsSuperAdmins = () => {
  const currentGroup = useSelector(({ groups }) => groups.currentGroup)
  const userData = useSelector(({ auth }) => auth.userData)
  const groupId = useIdParam("groupId")
  const [isTransferringModalOpen, toggleTransferringModalOpen] = useToggle()

  if (
    currentGroup.creator.id !== userData.id ||
    ![3, 4, 5].includes(currentGroup.type)
  ) {
    return <Redirect to={generatePath(GROUP_SETTINGS, { groupId })} />
  }

  return (
    <BoxPage title={"Transfer Super Admin Rights"}>
      <div className={"text-center"}>
        <p className={"mb20"}>
          The account creator is the super admin until this right is
          transferred. The super admin can be only one person who manages paid
          subscriptions. Once transferred the next renewal will need to be
          manually done. With transfer, the account creator can leave the group
          if needed.
        </p>
        <Button
          onClick={toggleTransferringModalOpen}
          color={"primary"}
          variant={"contained"}
        >
          Transfer Super Admin Rights
        </Button>

        {isTransferringModalOpen && (
          <GroupCreatorTransferModal
            open={isTransferringModalOpen}
            onClose={toggleTransferringModalOpen}
          />
        )}
      </div>
    </BoxPage>
  )
}

export default GroupSettingsSuperAdmins
