import { useDispatch, useSelector } from "react-redux"
import { useAlert } from "../../../../../hooks/useAlert"
import {
  clearLeanerKnowmapKnowmixKnowmeets,
  getLeanerKnowmapKnowmixKnowmeets,
} from "../../../../../library/store/actions/creators/knowmapCreators"
import UknowDialog from "../../../knowmix/knowmixSingle/knowmixSettings/knowmixSettingsKnowmeets/uknowDialog/uknowDialog"
import ListPage from "../../../../../components/v2/layout/listPage/listPage"
import React, { useState } from "react"
import LearnerKnowmixKnowmeetCard from "../../../../../components/v2/layout/cards/learnerKnowmixKnowmeetCard"
import withErrorBoundary from "../../../../../hocs/guards/withErrorBoundary"
import { markKnowmixKnowmeetAsDone } from "../../../../../library/store/actions/creators/uknowCreators"
import AlertDialog from "../../../../../components/ui/alertDialog"
import { Route, useHistory, useParams, useRouteMatch } from "react-router-dom"
import {
  KNOWMAP_INCOMING_KNOWMEET,
  KNOWMAP_UKNOW_KNOWMIX,
} from "../../../../../library/constants/routes"

const KnowmapLearnerKnowmixKnowmeets = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { path } = useRouteMatch()
  const params = useParams()

  const { errorAlert, successAlert } = useAlert()

  const { learnerKnowmixKnowmeet, learnerKnowmixKnowmeetEndReached } =
    useSelector(({ knowmap }) => knowmap)

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const [activeKnowmeet, setActiveKnowmeet] = useState(null)

  const initCompletionConfirmation = (id) => {
    setIsConfirmationOpen(true)
    setActiveKnowmeet(id)
  }

  const closeCompletionConfirmation = () => {
    setIsConfirmationOpen(false)
    setActiveKnowmeet(null)
  }

  const onMarkAsDone = async () => {
    try {
      await dispatch(markKnowmixKnowmeetAsDone({ id: activeKnowmeet }))
      successAlert("Completion confirmed")
      setIsConfirmationOpen(false)
      setActiveKnowmeet(null)

      if (path === KNOWMAP_INCOMING_KNOWMEET) {
        history.replace(KNOWMAP_UKNOW_KNOWMIX)
      }
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <>
      <ListPage
        title={"Courses Managed Meets"}
        noResultsLabel={"You don't have Courses Managed Meets yet"}
        getList={async ({ name, offset }) =>
          await dispatch(
            getLeanerKnowmapKnowmixKnowmeets({
              name,
              offset,
            })
          )
        }
        clearList={() => dispatch(clearLeanerKnowmapKnowmixKnowmeets())}
        list={learnerKnowmixKnowmeet}
        listEndReached={learnerKnowmixKnowmeetEndReached}
        getListItemComponent={({ item, openInfoDialog }) => (
          <LearnerKnowmixKnowmeetCard
            knowmeet={item}
            openKnowmeetDetails={openInfoDialog}
            openDoneConfirmation={() => initCompletionConfirmation(item.id)}
            key={item.id}
          />
        )}
        getInfoDialogComponent={({ open, onClose, activeItem }) => {
          return (
            <UknowDialog
              open={open}
              handleClose={onClose}
              activeUknow={activeItem}
              type={"confirmable"}
              openDoneConfirmation={() =>
                initCompletionConfirmation(activeItem)
              }
            />
          )
        }}
      />
      {isConfirmationOpen && (
        <AlertDialog
          open={isConfirmationOpen}
          handleClose={closeCompletionConfirmation}
          handleAccept={onMarkAsDone}
          title={"Accept Meet?"}
          message={"Do you accept this action? You won't be able to cancel it."}
        />
      )}

      <Route
        path={KNOWMAP_INCOMING_KNOWMEET}
        render={() => (
          <UknowDialog
            open
            handleClose={(replace = false) => {
              replace
                ? history.replace(KNOWMAP_UKNOW_KNOWMIX)
                : history.push(KNOWMAP_UKNOW_KNOWMIX)
            }}
            type={"confirmable"}
            openDoneConfirmation={() =>
              initCompletionConfirmation(parseInt(params.knowmeetId, 10))
            }
          />
        )}
      />
    </>
  )
}

export default withErrorBoundary(KnowmapLearnerKnowmixKnowmeets)
