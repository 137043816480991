import React from "react"
import { addMinutes, format } from "date-fns"
import TimezoneLabel from "../../timezones/timezoneLabel"

const formatDate = (date) => format(new Date(date), "eeee, MMMM do")
const formatTime = (date) => format(new Date(date), "h:mm aaa")

const MeetDateTimeFromUtc = ({ date, duration, activeTimezone }) => {
  console.log(date, duration, activeTimezone)
  return (
    <div className={"mb10"}>
      <p className={"font-weight-500"}>{formatDate(date)}</p>
      <p className={"font-weight-500"}>
        {formatTime(date)} - {formatTime(addMinutes(new Date(date), duration))}
      </p>
      <TimezoneLabel tz={activeTimezone} className={"color-black-54"} />
    </div>
  )
}

export default MeetDateTimeFromUtc
