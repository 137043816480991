import React from "react"
import { weekDaysArray } from "../../../../library/utils/timeConvert"
import Card from "./card"
import { getLocalePeriod } from "../../../../library/utils/timeString/timeString"
import {
  CARD_SIZES,
  getCardSize,
} from "../../../../library/constants/styles/cardSizes"

const BlockCard = ({
  block,
  openBlockDetails,
  openDeleteBlock,
  openEditBlock,
}) => {
  const dropdownMenuItems = [
    {
      type: "button",
      action: openEditBlock,
      label: "Edit block",
    },
    {
      type: "h-divider",
      id: "hd1",
    },
    {
      type: "button",
      action: openDeleteBlock,
      label: "Delete block",
    },
  ]

  const renderedDays = block.rotations.map((d) => weekDaysArray[d]).join(", ")

  const blockInfo = () => (
    <>
      {!!block?.rotations && <p>Day: {renderedDays}</p>}

      <p>
        Time:{" "}
        {!block.is_all_day
          ? getLocalePeriod(block.time_from, block.time_to)
          : "All day"}
      </p>

      <p>
        Tracks:{" "}
        {block.tracks
          .toSorted((a, b) => a.cardinal - b.cardinal)
          .map((t) => t.name)
          .join(", ")}
      </p>
    </>
  )

  return (
    <Card
      size={getCardSize(9).numValPx}
      name={block.title}
      onNameClick={openBlockDetails}
      // info={!!group.description && group.description !== 'Unknown' && group.description}
      infoComponent={blockInfo}
      controls={{
        dropdownMenu: {
          items: dropdownMenuItems,
        },
      }}
    />
  )
}

export default BlockCard
