import React, { Component } from "react"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import Button from "@material-ui/core/Button/Button"
import {
  getProduct,
  updateProduct,
} from "../../../../../library/store/actions/creators/productsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { bindActionCreators } from "redux"
import getErrorText from "../../../../../library/constants/errorTypes"
import { minutesToString } from "../../../../../library/utils/timeConvert"

class ProductSettingsUsageDetails extends Component {
  state = {
    conceptsNumber:
      (this.props.products.currentProduct.knowmix
        ? this.props.products.currentProduct.knowmix.steps
        : this.props.products.currentProduct.concepts_number) || "",
    averageTime:
      (this.props.products.currentProduct.knowmix
        ? this.props.products.currentProduct.knowmix.average_time
        : this.props.products.currentProduct.average_time) || "",
    potentialTime:
      (this.props.products.currentProduct.knowmix
        ? this.props.products.currentProduct.knowmix.time
        : this.props.products.currentProduct.potential_time) || "",
    lessonTime: this.props.products.currentProduct.lesson_time || "",
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    })
  }

  processInfoSave = async () => {
    const {
      products: {
        currentProduct: { id },
      },
    } = this.props
    const { conceptsNumber, averageTime, lessonTime, potentialTime } =
      this.state

    try {
      await this.props.updateProduct({
        id,
        concepts_number: parseInt(conceptsNumber) || 0,
        average_time: parseInt(averageTime) || 0,
        lesson_time: parseInt(lessonTime) || 0,
        potential_time: parseInt(potentialTime) || 0,
      })
      await this.props.enqueueSnackbar("Information updated", {
        variant: "success",
      })
      await this.props.getProduct({ id })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  render() {
    const { conceptsNumber, averageTime, potentialTime } = this.state
    const {
      products: {
        currentProduct: { content_type },
      },
    } = this.props

    return (
      <div className="box">
        <div className="box__heading">Usage details</div>
        <div className="box__content">
          <ValidatorForm className="form" onSubmit={this.processInfoSave}>
            <div className="form__fieldset">
              <label htmlFor="concepts-number" className="form__label">
                {content_type !== 15 ? "Num. concepts" : "Num. steps"}
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <TextValidator
                    id="concepts-number"
                    name="conceptsNumber"
                    value={conceptsNumber}
                    placeholder={`Type ${
                      content_type !== 15
                        ? "number of concepts"
                        : "number of steps"
                    }`}
                    className="edit-form__input"
                    margin="normal"
                    fullWidth
                    validators={[
                      "isNumber",
                      "minNumber:0",
                      "maxNumber:2147483647",
                    ]}
                    errorMessages={[
                      "Numbers only",
                      "Incorrect format",
                      "Max value is 2147483647",
                    ]}
                    onChange={this.handleChange("conceptsNumber")}
                    disabled={content_type === 15}
                  />
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor="average-time" className="form__label">
                {content_type !== 15 ? "Average time" : "Average step time"}
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  {content_type !== 15 ? (
                    <TextValidator
                      id="average-time"
                      name="averageTime"
                      value={averageTime}
                      placeholder={`Type avg. time of each`}
                      className="edit-form__input"
                      margin="normal"
                      fullWidth
                      validators={[
                        "isNumber",
                        "minNumber:0",
                        "maxNumber:2147483647",
                      ]}
                      errorMessages={[
                        "Numbers only",
                        "Incorrect format",
                        "Max value is 2147483647",
                      ]}
                      onChange={this.handleChange("averageTime")}
                    />
                  ) : (
                    <TextValidator
                      id="average-time"
                      name="averageTime"
                      value={minutesToString(averageTime)}
                      placeholder={`Type avg. step time`}
                      className="edit-form__input"
                      margin="normal"
                      fullWidth
                      onChange={this.handleChange("averageTime")}
                      disabled
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor="potential-time" className="form__label">
                {content_type !== 15 ? "Potential time" : "Total steps time"}
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  {content_type !== 15 ? (
                    <TextValidator
                      id="potential-time"
                      name="potentialTime"
                      value={potentialTime}
                      placeholder={`Type lesson time potential`}
                      className="edit-form__input"
                      margin="normal"
                      fullWidth
                      validators={[
                        "isNumber",
                        "minNumber:0",
                        "maxNumber:2147483647",
                      ]}
                      errorMessages={[
                        "Numbers only",
                        "Incorrect format",
                        "Max value is 2147483647",
                      ]}
                      onChange={this.handleChange("potentialTime")}
                    />
                  ) : (
                    <TextValidator
                      id="potential-time"
                      name="potentialTime"
                      value={minutesToString(potentialTime)}
                      placeholder={`Type total steps time`}
                      className="edit-form__input"
                      margin="normal"
                      fullWidth
                      onChange={this.handleChange("potentialTime")}
                      disabled
                    />
                  )}
                </div>
              </div>
            </div>

            <Button
              color="primary"
              variant="contained"
              className="form__submit"
              type="submit"
            >
              Save
            </Button>
          </ValidatorForm>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, products }) => ({ auth, products })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getProduct, updateProduct }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ProductSettingsUsageDetails))
