import React from "react"
import Card from "./card"
import {
  CARD_SIZES,
  getCardSize,
} from "../../../../library/constants/styles/cardSizes"

const TrackCard = ({
  track,
  openTrackDetails,
  openDeleteTrack,
  openEditTrack,
}) => {
  const dropdownMenuItems = [
    // {
    //   type: "button",
    //   action: openEditTrack,
    //   label: "Edit track",
    // },
    // {
    //   type: "h-divider",
    //   id: "hd1",
    // },
    {
      type: "button",
      action: openDeleteTrack,
      label: "Delete track",
    },
  ]
  return (
    <Card
      size={getCardSize(4).numValPx}
      name={track.name}
      // onNameClick={openTrackDetails}
      controls={{
        dropdownMenu: {
          items: dropdownMenuItems,
        },
      }}
    />
  )
}

export default TrackCard
