import React from "react"
import Loader from "../../../../components/ui/loader"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import withScroll from "../../../../hocs/withScroll/withScroll"
import { withSnackbar } from "notistack"
import getErrorText from "../../../../library/constants/errorTypes"

import {
  acceptInvite,
  cancelInvite,
  clearInvenstory,
  createInvite,
  declineInvite,
  getConnectionsList,
  removeConnection,
  restrictConnection,
} from "../../../../library/store/actions/creators/invenstoryCreators"

import { LIMIT } from "../../../../library/constants/limits"
import * as qs from "query-string"
import InvenstoryGroupCard from "../invenstoryCards/invenstoryGroupCard"
import List from "../../../../components/ui/list"

class InvenstoryAccessRequests extends React.Component {
  state = {
    list: [],
    dataReceived: false,
  }

  searchParams = {}

  async componentDidMount() {
    const {
      location: { search },
    } = this.props
    const { q: query } = qs.parse(search)
    this.searchParams = {
      name: query || undefined,
    }
    await this.getList()
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.match.path !== this.props.match.path ||
      prevProps.location.search !== this.props.location.search
    ) {
      this.setState({
        list: [],
      })
      this.props.clearInvenstory()
      const {
        location: { search },
      } = this.props
      const { q: query } = qs.parse(search)
      this.searchParams = {
        name: query || undefined,
      }
      await this.getList()
    }
    if (this.state.dataReceived && !this.state.list.length) {
      !this.props.noResults && this.props.setNoResults(true)
    } else if (this.state.dataReceived && !!this.state.list.length) {
      this.props.noResults && this.props.setNoResults(false)
    }
  }

  componentWillUnmount() {
    this.props.clearInvenstory()
  }

  getList = async (offset) => {
    this.setState({ dataReceived: false })
    const { name } = this.searchParams
    try {
      await this.props.getConnectionsList({
        name,
        type: 1,
        limit: LIMIT,
        offset,
      })
      const userConnectionsList = [...this.props.invenstory.connectionsList]
      return this.setState({
        dataReceived: true,
        list: userConnectionsList.map((c) =>
          c.type === 1
            ? {
                type: c.type,
                ...c.group,
                isConnected: false,
                isPending: true,
              }
            : {
                type: c.type,
                ...c.group,
                isConnected: true,
                isPending: false,
              }
        ),
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  onScroll = async () => {
    if (!this.state.dataReceived) return
    await this.getList(this.state.list.length)
  }

  acceptRequest = async ({ group_id }) => {
    const list = [...this.state.list]
    try {
      await this.props.acceptInvite({
        associated_group_id: group_id,
      })
      const entity = list.find((e) => e.id === group_id)
      entity.isConnected = true
      entity.isPending = false
      this.setState({
        list,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  declineRequest = async ({ group_id }) => {
    const list = [...this.state.list]
    try {
      await this.props.declineInvite({
        associated_group_id: group_id,
      })
      const newList = list.filter((e) => e.id !== group_id)
      this.setState({
        list: newList,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  removeConnection = async ({ user_id, group_id }) => {
    const list = [...this.state.list]
    try {
      await this.props.removeConnection({
        associated_group_id: group_id,
        associated_user_id: user_id,
      })
      const entity = list.find((e) => e.id === user_id || e.id === group_id)
      entity.isConnected = false
      this.setState({
        list,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  restrictConnection = async ({ group_id }) => {
    const list = [...this.state.list]
    try {
      await this.props.restrictConnection({
        associated_group_id: group_id,
      })
      const newList = list.filter((e) => e.id !== group_id)
      this.setState({
        list: newList,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }
  getDropDownMenuItems = (entity) => {
    if (entity.isPending) {
      return [
        {
          type: "button",
          action: () => {
            this.declineRequest({ group_id: entity.id })
          },
          label: "Decline request",
        },
      ]
    }
    return [
      {
        type: "button",
        action: () => {
          this.removeConnection({ group_id: entity.id })
        },
        label: "Remove from InvenStory",
      },
    ]
  }

  processSearch = (name) => {
    const { history } = this.props

    this.searchParams = {
      q: name || undefined,
    }

    const result = qs.stringify(this.searchParams, { arrayFormat: "comma" })

    history.push({
      search: `?${result}`,
    })
  }

  render() {
    const { list, dataReceived } = this.state
    return (
      <List>
        {list.map((entity, i) => {
          return (
            <InvenstoryGroupCard
              key={i}
              group={!entity.deleted ? entity : undefined}
              dropdownMenuItems={this.getDropDownMenuItems(entity)}
              hasMenu={entity.isPending}
              hasButton
              buttonType={!entity.isConnected ? "contained" : "outlined"}
              buttonText={entity.isConnected ? "Close access" : "Open access"}
              buttonHandler={
                !entity.isConnected
                  ? (_) => this.acceptRequest({ group_id: entity.id })
                  : (_) => this.restrictConnection({ group_id: entity.id })
              }
            />
          )
        })}
        {!dataReceived && <Loader />}
      </List>
    )
  }
}

const mapState = ({ auth, invenstory }) => ({ auth, invenstory })
const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      cancelInvite,
      clearInvenstory,
      createInvite,
      declineInvite,
      getConnectionsList,
      removeConnection,
      restrictConnection,
      acceptInvite,
    },
    dispatch
  )
export default connect(
  mapState,
  mapDispatch
)(withScroll(withSnackbar(InvenstoryAccessRequests)))
