import { Tooltip, withStyles } from "@material-ui/core"
import { colors } from "../../../../../../library/constants/styles/colors"
import Button from "@material-ui/core/Button"
import { navigate, views } from "react-big-calendar/lib/utils/constants"
import { useCalendar } from "../../../CalendarContext"
import React, { Children, useEffect, useState } from "react"
import IconButton from "@material-ui/core/IconButton"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import styled from "@emotion/styled"
import { calendarViewsArray } from "../../../../../../library/constants/calendar"
import { withRouter } from "react-router-dom"
import { addDays, format, isPast, isToday } from "date-fns"
import DropdownControl from "../../../../../../components/ui/dropdowns/dropdownControl"
import { safeFormatDate } from "../../../../../../library/utils/dateConvert"
import CalendarFilters from "./calendarFilters"
import { useSelector } from "react-redux"
import CloseIcon from "@material-ui/icons/Close"
import { clsx } from "clsx"

const ViewsListToggleButton = withStyles({
  root: {
    color: colors.black54,
  },
})(Button)

const DateHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const EventContainer = styled.div`
  padding: 0 4px;
  line-height: 1.38;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: ${(props) => props.color || colors.black12};
  color: ${colors.white};
  font-size: 14px;
  border-radius: 2px;
  overflow: hidden;
  cursor: pointer;
`

const AgendaEventContainer = styled.div`
  color: ${(props) => props.color || colors.black54};
  cursor: pointer;
`

const CalendarToolbar = (props) => {
  const {
    userData: { role },
  } = useSelector(({ auth }) => auth)

  const period = props.view === "agenda" && {
    start: format(new Date(props.label.split(" ")[0]), "d MMM yyyy"),
    end: format(new Date(props.label.split(" ")[2]), "d MMM yyyy"),
  }

  const {
    onEventFormModalOpen,
    canChangeView,
    onKourseKnowmeetFormModalOpen,
    onStepKnowmeetFormModalOpen,
    onMeetFormModalOpen,
    groupsReceived,
    groups,
    onSolicitAppointmentFormModalOpen,
  } = useCalendar()

  const navigateToday = () => props.onNavigate(navigate.TODAY, null)
  const navigateTomorrow = () =>
    props.onNavigate(navigate.DATE, addDays(new Date(), 1))
  const navigatePrev = async () => props.onNavigate(navigate.PREVIOUS, null)
  const navigateNext = async () => props.onNavigate(navigate.NEXT, null)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [currentView, setCurrentView] = useState({
    type: views.MONTH,
    name: "Month",
  })

  useEffect(() => {
    setCurrentView(calendarViewsArray.find((v) => v.type === props.view))
  }, [props.view])

  const handleOpenViewsDropdown = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (event, view) => {
    setCurrentView(view)
    props.onView(view.type)
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className="box__heading events-calendar-toolbar">
      <div className={"events-calendar-toolbar__left"}>
        {!props.noFilters && groupsReceived && !!groups.length && (
          <CalendarFilters groups={groups} />
        )}
        <Button
          variant={"outlined"}
          color={"primary"}
          onClick={navigateToday}
          className={"mr15"}
        >
          Today
        </Button>
        {currentView.type === views.DAY && (
          <Button
            variant={"outlined"}
            color={"primary"}
            onClick={navigateTomorrow}
            className={"mr15"}
          >
            Tomorrow
          </Button>
        )}
        <IconButton aria-label="Previous" size="small" onClick={navigatePrev}>
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton aria-label="Next" size="small" onClick={navigateNext}>
          <KeyboardArrowRight />
        </IconButton>
        <span className={"ml15"}>
          {period ? `${period.start} — ${period.end}` : props.label}
        </span>
      </div>
      <div className={"events-calendar-toolbar__right"}>
        <div className={"mr15"}>
          {/*{canChangeView ? (*/}
          <ViewsListToggleButton
            aria-controls="calendar-views-menu"
            aria-haspopup="true"
            onClick={handleOpenViewsDropdown}
          >
            {currentView.name}{" "}
            {!Boolean(anchorEl) ? (
              <KeyboardArrowDown className={"ml5"} />
            ) : (
              <KeyboardArrowUp className={"ml5"} />
            )}
          </ViewsListToggleButton>
          {/*) : (*/}
          {/*  <Button*/}
          {/*    component={Link}*/}
          {/*    to={routes.CALENDAR}*/}
          {/*    variant={"outlined"}*/}
          {/*    color={"primary"}*/}
          {/*  >*/}
          {/*    Open Calendar*/}
          {/*  </Button>*/}
          {/*)}*/}
          <Menu
            id="calendar-views-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {calendarViewsArray.map((v) => (
              <MenuItem
                key={v.type}
                selected={currentView.name === v.name}
                onClick={(event) => handleMenuItemClick(event, v)}
              >
                {v.name}
              </MenuItem>
            ))}
          </Menu>
        </div>
        <DropdownControl
          popperZIndex={1300}
          buttonLabel={"Add to Calendar"}
          options={[
            {
              label: "Dated Appointment",
              onClick: onEventFormModalOpen,
            },
            {
              label: "Request Appointment",
              onClick: onSolicitAppointmentFormModalOpen,
            },
            role !== 1 && {
              label: "AI Cohorting Meets",
              onClick: onMeetFormModalOpen,
            },
          ].filter(Boolean)}
        />
        {props.isFullScreen && typeof props.onClose === "function" && (
          <IconButton
            aria-label="close"
            color="primary"
            onClick={props.onClose}
            className={"ml50"}
          >
            <CloseIcon />
          </IconButton>
        )}
      </div>
    </div>
  )
}

export const CalendarDayWrapper = (props) => {
  return React.cloneElement(Children.only(props.children), {
    style: {
      ...props.children.style,
      backgroundColor: isPast(props.value)
        ? colors.grey7
        : isToday(props.value)
          ? colors.primary15
          : "#fff",
      borderTop: `2px solid ${
        isToday(props.value) ? colors.primary : "transparent"
      }`,
    },
  })
}

export const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: colors.white,
    color: colors.black87,
    minWidth: 120,
    maxWidth: 300,
    fontSize: "0.875em",
    boxShadow: "0 1px 6px 0 rgba(0, 0, 0, .21)",
    fontWeight: 400,
    padding: 10,
    margin: "8px 0",
  },
}))(Tooltip)

export const CalendarEventWrapper = (props) => {
  return (
    <HtmlTooltip
      title={
        <>
          <div className="f aic mb5">
            <div
              className={"w-2.5 h-2.5 mr-1 rounded-full"}
              style={{ backgroundColor: props.event.color || colors.primary }}
            />
            <p className={"text-base font-medium"}>{props.event.title}</p>
          </div>
          {!(props.event.allDay || props.noTime) && (
            <p>
              {safeFormatDate(new Date(props.event.start), { withTime: true })}{" "}
              — {safeFormatDate(new Date(props.event.end), { withTime: true })}
            </p>
          )}

          {!!props.event.desc && (
            <div>
              {/*<p className={"color-black-54 mt10"}>Appointment description:</p>*/}
              <p className={"text-sm mt-2 line-clamp-3"}>{props.event.desc}</p>
            </div>
          )}

          {!!props.event.reminder && (
            <div>
              <p className={"text-black54 mt-2"}>KnowMeet reminder:</p>
              <p className={"text-sm line-clamp-3"}>{props.event.reminder}</p>
            </div>
          )}

          {!!props.event.space && (
            <div>
              <p className={"text-black54 mt-2"}>Space:</p>
              <p className={"text-sm"}>{props.event.space}</p>
            </div>
          )}
        </>
      }
    >
      <EventContainer
        color={
          props.event.entity_type === "book_appointments"
            ? colors.primary5
            : props.event.color || colors.primary
        }
        onClick={() => props.onSelect(props.event, props.event.entity_type)}
      >
        {!(props.event.allDay || props.noTime || props.event.is_holiday) && (
          <span className={"font-10"}>
            {props.event.entity_type === "book_appointments" && "~"}
            {format(new Date(props.event.start), "h:mm aaa")}{" "}
          </span>
        )}
        {props.event.title}
      </EventContainer>
    </HtmlTooltip>
  )
}

export const CalendarWeekEventWrapper = (props) => {
  const { onEventDialogOpen } = useCalendar()
  return React.cloneElement(Children.only(props.children), {
    onClick: () => onEventDialogOpen(props.event.id, props.event.entity_type),
    style: {
      ...props.children.style,
      height: props?.style?.height + "%",
      top: props?.style?.top + "%",
      width: props?.style?.width + "%",
      xOffset: props?.style?.xOffset + "%",
      color: colors.white,
      backgroundColor: props.event.color || colors.black12,
      padding: "4px",
      lineHeight: 1.38,
      textOverflow: "ellipsis",
      fontSize: "14px",
      borderRadius: "2px",
      overflow: "hidden",
      cursor: "pointer",
      border: 0,
    },
  })
}

export const CalendarDayEventWrapper = (props) => {
  const { onEventDialogOpen } = useCalendar()

  return React.cloneElement(Children.only(props.children), {
    onClick: () => onEventDialogOpen(props.event.id, props.event.entity_type),
    style: {
      ...props.children.props.style,
      color: colors.white,
      backgroundColor: props.event.color || colors.black12,
      padding: "4px",
      lineHeight: 1.38,
      textOverflow: "ellipsis",
      fontSize: "14px",
      borderRadius: "2px",
      overflow: "hidden",
      cursor: "pointer",
      border: 0,
    },
  })
}

export const AgendaEvent = (props) => {
  const { onEventDialogOpen } = useCalendar()

  return (
    <AgendaEventContainer
      color={props.event.color}
      onClick={() => onEventDialogOpen(props.event.id, props.event.entity_type)}
    >
      {props.event.title}
    </AgendaEventContainer>
  )
}

// export const CalendarDayEventWrapper = (props) => {
//   return React.cloneElement(Children.only(props.children), {
//     style: {
//       ...props.children.style,
//       //     backgroundColor: props.event.color || colors.black12,
//       // borderTop: `2px solid ${isToday ? colors.primary : 'transparent'}`,
//     },
//   })
//
//   // const styles = props.style ? {
//   //     top: `${props.style.top}%` || undefined,
//   //     left: `${props.style.left}%` || undefined,
//   //     height: `${props.style.height}%` || undefined,
//   //     width: `${props.style.width}%` || undefined,
//   // } : {};
//   //
//   // return (
//   //     <EventContainer color={props.event.color} style={styles}>
//   //         {props.label}
//   //     </EventContainer>
//   // );
// }

export default withRouter(CalendarToolbar)
