import React from "react"
import PlainLink from "../../ui/link"
import { User } from "../../../library/utils/user/userUtils"
import UserInfoTooltip from "./UserInfoTooltip"

const UserLink = ({
  user,
  interactive = true,
  newTab = true,
  linkClassName = "",
}) => {
  const userData = new User(user)

  const path = userData.path
  const userName = userData.name
  const link = (
    <PlainLink to={path} className={linkClassName} newTab={newTab}>
      {userName}
    </PlainLink>
  )

  return interactive ? (
    <UserInfoTooltip user={user}>{link}</UserInfoTooltip>
  ) : (
    link
  )
}

export default UserLink
