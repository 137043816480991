export const USER = {
  SIGNED_IN: "USER_SIGNED_IN",
  SIGNED_UP: "USER_SIGNED_UP",
  SIGN_UP_DENIED: "USER_SIGN_UP_DENIED",
  SIGN_UP_CONFIRMED: "USER_SIGN_UP_CONFIRMED",
  SIGNED_OUT: "USER_SIGNED_OUT",
  DATA_RECEIVED: "USER_DATA_RECEIVED",
  ACCOUNT_DELETED: "USER_ACCOUNT_DELETED",
  ACCOUNT_RESTORED: "USER_ACCOUNT_RESTORED",
  PASSWORD_RESET: "USER_PASSWORD_RESET",
  EMAIL_UPDATE: "USER_PASSWORD_RESET",
  INFO_UPDATED: "USER_INFO_UPDATED",
  EDUCATION_UPDATED: "USER_EDUCATION_UPDATED",
  WORK_UPDATED: "USER_WORK_UPDATED",
  FRIENDS_RECEIVED: "USER_FRIENDS_RECEIVED",
  HAS_KNOWKRED: "has_knowkred",
  GOOGLE_CODE_SAVE: "integrations.oauth_google",
  GOOGLE_CODE_REVOKE: "integrations_google.revoke",
  AZURE_CODE_SAVE: "integrations.oauth_outlook",
  AZURE_CODE_REVOKE: "integrations_outlook.revoke",
  UPDATE_PERSONAL_LINKS: "users.personal_links.update",
  UPDATE_MEET_TYPES: "users.meet_types.update",
  GET_FROM_MEET_LINK: "users.get_from_meet_link",
  SAVE_MEET_HASH: "save_meet_hash",
  CONTACT_LINKS_UPDATE: "users.update_contact_links",
  CONTACT_LINKS_GET: "users.get_contact_links",
  CURRENT_SUBSCRIPTIONS: "subscriptions",
  HAS_GROUP: "has_group",

  BLACKLIST: {
    GET: "users.listBlocked",
    CLEAR: "users.blacklist.clear",
    ADD_USER: "users.block",
    REMOVE_USER: "users.unblock",
  },

  HAS_FRIEND: "has_friend",

  HOLDS: {
    LIST: "public_holds.list",
    CLEAR_LIST: "public_holds.clear_list",
    GET: "public_holds.get",
    CLEAR_CURRENT_BLOCK: "public_holds.clear_current_hold",
    CREATE: "public_holds.create",
    UPDATE: "public_holds.update",
    DELETE: "public_holds.delete",
    PUBLIC_LIST: "appointments.get_user_holds",
  },
}
