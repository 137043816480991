import { useEffect, useState } from "react"

export const useElementWidth = (elId) => {
  const [width, setWidth] = useState(0)
  useEffect(() => {
    const element = document.getElementById(elId)
    if (element) {
      setWidth(element.offsetWidth)
    }
  }, [elId])
  return width
}
