import React from "react"
import styled from "@emotion/styled"
import { colors } from "../../library/constants/styles/colors"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { ThemeProvider } from "@material-ui/styles"
import { createTheme } from "@material-ui/core/styles"
import { appTheme } from "../../library/m-ui-theme"
import DateFnsUtils from "@date-io/date-fns"
import { format, isPast, isSameDay, isSameMonth, isSameYear } from "date-fns"
import Button from "@material-ui/core/Button"
import { ChevronLeftOutlined } from "@material-ui/icons"
import { useBookMeet } from "./bookMeetContext"
import SelectedMeetData from "./selectedMeetData"
import { useAlert } from "../../hooks/useAlert"
import { call } from "../../library/networking/API"
import { APPOINTMENTS } from "../../library/store/actions/types/calendarTypes"
import { localizedDateTime } from "../../library/utils/dateConvert"
import TimezonePicker from "../../components/timezones/TimezonePicker"
import { useQuery } from "@tanstack/react-query"

const Container = styled.div`
  display: grid;
  grid-template-columns: 25% 45% 30%;
  width: 100%;

  & > div {
    padding: 15px;
    &:not(:last-of-type) {
      border-right: 1px solid ${colors.black12};
    }
  }
`

const TimeButtonsContainer = styled.div`
  max-height: 390px;
  overflow-y: auto;
`

export const appointmentCalendarTheme = createTheme({
  ...appTheme,
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        display: "none",
      },
    },
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        minWidth: "100%",
        maxWidth: "100%",
        width: "100%",
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        minWidth: "100%",
        maxWidth: "100%",
        width: "100%",
        minHeight: "auto",
      },
    },
    MuiPickersDay: {
      day: {
        margin: "2px",
      },
    },
  },
})

class WeekDays extends DateFnsUtils {
  getWeekdays() {
    return ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"]
  }
}

const areEqualDates = (d1, d2) =>
  isSameDay(d1, d2) && isSameMonth(d1, d2) && isSameYear(d1, d2)

const BookMeetStep3 = () => {
  const { errorAlert } = useAlert()

  const {
    data,
    availableSlots,
    setAvailableSlots,
    setData,
    setStep,
    user,
    selectedTimezone,
    setSelectedTimezone,
    onClose,
  } = useBookMeet()

  const [isInitialLoad, setIsInitialLoad] = React.useState(true)
  const [dataReceived, setDataReceived] = React.useState(false)

  const [date, setDate] = React.useState(
    data.date ? new Date(data.date.date) : new Date()
  )
  const [selectedDateRecord, setSelectedDateRecord] = React.useState(data.date)
  const [selectedTimeRecord, setSelectedTimeRecord] = React.useState(data.time)

  const { data: slotsData, isSuccess: slotsDataSuccess } = useQuery({
    queryKey: [
      APPOINTMENTS.AVAILABLE_SLOTS,
      {
        hold_ids: data.holds.length ? data.holds?.map((h) => h.id) : undefined,
        slot: data.type,
        user_id: !data.holds.length ? user.id : undefined,
      },
    ],
    queryFn: async (ctx) => {
      const response = await call(ctx.queryKey[0], ctx.queryKey[1])

      return (await response.data?.list) ?? []
    },
    staleTime: Infinity,
    // enabled: !!data.hold?.id,
  })

  React.useEffect(() => {
    if (slotsDataSuccess && slotsData) {
      setAvailableSlots(slotsData || [])

      if (isInitialLoad && !data.date) {
        setSelectedDateRecord(slotsData?.[0])
        setData((prevState) => ({ ...prevState, date: slotsData?.[0] }))
        setIsInitialLoad(false)
      }
    }
  }, [slotsDataSuccess])

  const onDateChange = (d) => {
    const dateRecord = availableSlots.find((fd) =>
      areEqualDates(d, new Date(fd.date))
    )

    if (!dateRecord) return

    setDate(d)
    setSelectedDateRecord(dateRecord)
    setSelectedTimeRecord(null)

    setData((prevState) => ({
      ...prevState,
      date: dateRecord,
    }))
  }

  const onTimeChange = (t) => {
    setSelectedTimeRecord(t)
    setData((prevState) => ({
      ...prevState,
      time: t,
    }))
  }

  const availableDates = availableSlots.map((d) => new Date(d.date))

  const isInAvailableDays = (day) =>
    availableDates.some((d) => areEqualDates(d, day))

  // React.useEffect(() => {
  //   if (data.hold?.id) {
  //     getSlots()
  //   }
  // }, [])

  const onSubmit = () => {}

  return (
    <Container>
      <div className={"f fdc aifs jcsb"}>
        <SelectedMeetData />
        <Button
          variant={"text"}
          color={"primary"}
          startIcon={<ChevronLeftOutlined />}
          onClick={() => setStep(2)}
        >
          Back
        </Button>
      </div>
      <div>
        <DialogTitle className={"text-center pt0"}>
          Select a Date & Time
        </DialogTitle>
        <div className={"f jcc"}>
          {/*{slotsDataSuccess && (*/}
          <div>
            <ThemeProvider theme={appointmentCalendarTheme}>
              <MuiPickersUtilsProvider utils={WeekDays}>
                <DatePicker
                  autoOk
                  variant="static"
                  openTo="date"
                  value={date}
                  onChange={onDateChange}
                  disablePast
                  shouldDisableDate={(day) => {
                    return !isInAvailableDays(day)
                  }}
                  renderDay={(
                    day,
                    selectedDay,
                    isInCurrentMonth,
                    dayComponent
                  ) => {
                    const isHighlighted = !isPast(day) && isInAvailableDays(day)

                    return isHighlighted
                      ? React.cloneElement(dayComponent, {
                          style: { border: `1px solid ${colors.primary}` },
                        })
                      : dayComponent
                  }}
                />
              </MuiPickersUtilsProvider>
            </ThemeProvider>
            <div className={"mt30"}>
              <TimezonePicker
                value={selectedTimezone}
                onChange={setSelectedTimezone}
              />
            </div>
          </div>
          {/*)}*/}
        </div>
      </div>
      <div className={"f fdc"}>
        <div className={"fg1"}>
          {slotsDataSuccess && !!selectedTimezone && (
            <>
              <DialogTitle className={"text-center pt0"}>
                {format(date, "eee, MMM do")}
              </DialogTitle>
              <TimeButtonsContainer>
                {selectedDateRecord?.slots?.length ? (
                  selectedDateRecord?.slots?.map((t) => (
                    <div className={"f mb10 pl20 pr20"} key={t.start}>
                      <Button
                        variant={"outlined"}
                        color={"primary"}
                        className={"fg1"}
                        onClick={() => onTimeChange(t)}
                      >
                        {localizedDateTime(
                          data.date.date,
                          t.start,
                          "America/Chicago",
                          selectedTimezone.id
                        )}
                      </Button>
                      {selectedTimeRecord?.start === t?.start && (
                        <Button
                          variant={"contained"}
                          color={"primary"}
                          className={"fg1 ml5"}
                          onClick={() => setStep(4)}
                        >
                          Next
                        </Button>
                      )}
                    </div>
                  ))
                ) : (
                  <p className={"color-black-54 text-center"}>
                    No more time slots available.
                    <br />
                    Please, select another date.
                  </p>
                )}
              </TimeButtonsContainer>
            </>
          )}
        </div>

        <Button
          variant={"text"}
          color={"primary"}
          onClick={onClose}
          style={{ alignSelf: "flex-end" }}
        >
          Cancel
        </Button>
      </div>
    </Container>
  )
}

export default BookMeetStep3
