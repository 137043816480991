import React, { useEffect, useState } from "react"
import styled from "@emotion/styled/macro"
import { colors } from "../../../library/constants/styles/colors"
import { values } from "lodash"
import TableCell from "../tableCell"
import DeleteForeverOutlined from "@material-ui/icons/DeleteForeverOutlined"
import EditOutlined from "@material-ui/icons/EditOutlined"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import StarBorderOutlined from "@material-ui/icons/StarBorderOutlined"
import StarOutlined from "@material-ui/icons/StarOutlined"
import { devices } from "../../../library/constants/styles/responsive"
import { Tooltip } from "@material-ui/core"

const Controls = styled.td`
  display: flex;
  align-items: center;
  position: ${(props) => (props.fluid ? "absolute" : "sticky")};
  right: 0;
  color: ${colors.primary};
  background-color: ${colors.grey7};
  opacity: 0;
  visibility: hidden;
  transition: 0.2s all;
  transform: translateY(-50%);

  @supports (display: grid) {
    transform: none;
  }

  @media ${devices.m} {
    opacity: 1;
    visibility: visible;
  }

  svg {
    cursor: pointer;

    :not(:last-child) {
      margin-right: 8px;
    }
  }
`

const Container = styled.tr`
  display: flex;
  position: relative;
  align-items: center;
  width: ${(props) =>
    props.fluid ? "100%" : props.cellsNumber * 115 + 48 + "px"};
  border-bottom: 1px solid ${colors.black12};
  cursor: pointer;
  transition: 1s all;

  &:hover {
    td {
      background-color: ${colors.grey7};

      &:first-of-type {
        border-color: ${colors.primary};
      }
    }

    ${Controls} {
      opacity: 1;
      visibility: visible;
    }
  }
`

const TableRow = (props) => {
  const [bookmarked, setBookmarked] = useState(props.bookmarked)

  useEffect(() => {
    setBookmarked(!!props.record.bookmark)
  }, [props.record.bookmark])
  const addToBookmarks = async () => {
    try {
      await props.createBookmark(props.recordId)
      setBookmarked(true)
    } catch (e) {}
  }
  const removeFromBookmarks = async () => {
    try {
      await props.deleteBookmark(props.recordId)
      setBookmarked(false)
    } catch (e) {}
  }
  return (
    <Container cellsNumber={values(props.data).length} fluid={props.fluid}>
      {values(props.data).map((item, index) => {
        return (
          <TableCell
            recordId={props.recordId}
            openRecord={props.openRecord}
            fluid={props.fluid}
            key={item + index}
          >
            {item}
          </TableCell>
        )
      })}

      {props.canEdit && (
        <Controls fluid={props.fluid}>
          <Tooltip title="Edit" arrow>
            <EditOutlined onClick={() => props.editRecord(props.recordId)} />
          </Tooltip>
          <Tooltip title="Create product ad tile" arrow>
            <AddCircleOutlineIcon
              onClick={() => props.createProduct(props.recordId)}
            />
          </Tooltip>
          <Tooltip title="Delete" arrow>
            <DeleteForeverOutlined
              onClick={() => props.deleteRecord(props.recordId)}
            />
          </Tooltip>
          <Tooltip
            title={!bookmarked ? "Add to bookmarks" : "Remove from bookmarks"}
            arrow
          >
            {!bookmarked ? (
              <StarBorderOutlined onClick={addToBookmarks} />
            ) : (
              <StarOutlined
                onClick={removeFromBookmarks}
                className="mr8 color-primary"
              />
            )}
          </Tooltip>
        </Controls>
      )}
    </Container>
  )
}

export default TableRow
