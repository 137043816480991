import styled from "@emotion/styled"
import { colors } from "../../../../../library/constants/styles/colors"
import { blinkAnimation } from "../../../../../library/constants/styles/animation"

export const TextLineSkeleton = styled.div`
  height: ${(props) => props.height || "1rem"};
  width: ${(props) => props.width || "100%"};
  background: linear-gradient(
    to right,
    ${colors.black7} 0%,
    ${colors.black4} 50%,
    ${colors.black7} 100%
  );
  background-size: 150% 150%;
  border-radius: 2px;
  animation: ${blinkAnimation} 2s ease-in-out infinite;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`
