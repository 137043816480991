export const KNOWMIX = {
  CREATE: 'knowmixes.create',
  UPDATE: 'knowmixes.update',
  DELETE: 'knowmixes.delete',
  GET: 'knowmixes.get',
  START: 'knowmixes.start',
  FINISH: 'knowmixes.finish',
  LIST: 'knowmixes.list',
  GROUP_LIST: 'knowmixes.group_list',
  LIST_UPDATE: 'knowmixes.list.update',
  CLEAR_LIST: 'knowmixes.clearList',
  CLEAR_CURRENT: 'knowmixes.clearCurrent',
  GRADE: 'knowmixes.grade',
  GRADE_LIST: 'knowmixes.grades.list',

  STEPS: {
    CREATE: 'knowmixes.steps.create',
    UPDATE: 'knowmixes.steps.update',
    DELETE: 'knowmixes.steps.delete',
    LIST: 'knowmixes.steps.list',
    LIST_CLEAR: 'knowmixes.steps.clear',

    PRODUCTS: {
      SEARCH: 'knowmixes.steps.products.search',
      LIST: 'knowmixes.steps.products.list',
      ADD: 'knowmixes.steps.products.add',
      DELETE: 'knowmixes.steps.products.delete',
      CLEAR: 'knowmixes.steps.products.clear',
    },
  },

  EXAMINERS: {
    ADD: 'knowmixes.examiners.add',
    DELETE: 'knowmixes.examiners.delete',
    SEARCH: 'knowmixes.examiners.search',
    CLEAR: 'knowmixes.examiners.clear',
    COUNT: 'knowmixes.examiners.count',
  },

  GRADES: {
    LIST: 'knowmixes.grades.list',
    GET: 'knowmixes.grades.get',
    CREATE: 'knowmixes.grades.create',
  },

  WORKS: {
    CREATE: 'knowmixes.works.create',
    GET: 'knowmixes.works.get',
    UPDATE: 'knowmixes.works.update',
    FINISH: 'knowmixes.works.finish',
    GRADE: 'knowmixes.works.grade',
    LIST: 'knowmixes.works.list',
  },

  MEMBERS: {
    ADD: 'knowmixes.members.add',
    DELETE: 'knowmixes.members.delete',
    SEARCH: 'knowmixes.members.search',
    LIST: 'knowmixes.members.list',
    CLEAR: 'knowmixes.members.clear',
    SEARCH_CLEAR: 'knowmixes.members.search_clear',
  },
}
