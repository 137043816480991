import React from "react"
import styled from "@emotion/styled"
import { FieldArray, useField } from "formik"
import { mq } from "../../../../theme/globalStyle"
import TextInput from "../textInput/textInput"
import { PlainButton } from "../../controls"
import { FieldWrapper } from "../../layout/forms/form/form"

const ControllableInputContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${mq.xs} {
    flex-direction: column;
    align-items: flex-end;
  }
`

const InputsContainer = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`

const AddField = styled(PlainButton)`
  align-self: flex-start;
  margin-top: 7px;
`

const InputsArray = ({
  limit = 10,
  placeholder = "",
  subFields = [],
  ...rest
}) => {
  const [field] = useField({ multiple: true, ...rest })

  return (
    <FieldArray name={field.name}>
      {({ push, remove }) => (
        <>
          <InputsContainer>
            {field.value.map((v, i) => (
              <ControllableInputContainer key={`${field.name}.${i}`}>
                {subFields?.length ? (
                  <div>
                    {subFields.map((sf, sfIdx) => {
                      return (
                        <FieldWrapper>
                          <TextInput
                            name={`${field.name}.${i}.${sf.name}`}
                            placeholder={sf?.placeholder || ""}
                          />
                        </FieldWrapper>
                      )
                    })}
                  </div>
                ) : (
                  <FieldWrapper>
                    <TextInput
                      name={`${field.name}.${i}`}
                      placeholder={placeholder}
                    />
                  </FieldWrapper>
                )}

                <PlainButton onClick={() => remove(i)} actionType={"delete"} />
              </ControllableInputContainer>
            ))}
          </InputsContainer>

          {field.value.length < limit && (
            <AddField
              onClick={() => {
                if (subFields?.length) {
                  const newSubFields = {}
                  subFields.forEach((sf) => {
                    newSubFields[sf.name] = ""
                  })
                  push(newSubFields)
                } else {
                  push("")
                }
              }}
              actionType={"add"}
            />
          )}
        </>
      )}
    </FieldArray>
  )
}

export default InputsArray
