import { SEARCH } from "../actions/types/searchTypes"

const initialState = {
  global: {},
  users: {},
  groups: {},
  products: {},
}

export const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH.GLOBAL:
            return {
                ...state,
                global: action.payload,
            };

        case SEARCH.USERS:
            return {
                ...state,
                users: {
                    users_number: action.payload.users_number,
                    users: state.users.users ? [...state.users.users, ...action.payload.users] : action.payload.users,
                },
            };

        case SEARCH.GROUPS:
            return {
                ...state,
                groups: {
                    groups_number: action.payload.groups_number,
                    groups: state.groups.groups ? [...state.groups.groups, ...action.payload.groups] : action.payload.groups,
                },
            };

        case SEARCH.PRODUCTS:
            return {
                ...state,
                products: {
                    products_number: action.payload.products_number,
                    products: state.products.products ? [...state.products.products, ...action.payload.products] : action.payload.products,
                },
            };

        case SEARCH.CLEAR:
            return {
                ...initialState,
            };

        default:
            return state;
    }
};
