import React from "react"
import {
  FormControl,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core"
import { linkProviderNames } from "../../../components/v2/inputs/pickers/ContactLinkPicker"
import FormHelperText from "@material-ui/core/FormHelperText"
import { colors } from "../../../library/constants/styles/colors"

const OuterInviterContactLinkPicker = ({
  value,
  onChange,
  inviteeLinks = {},
  leftLabel = true,
  error = "",
}) => {
  const allLinks = [inviteeLinks]

  const renderList = () => {
    const items = []
    allLinks.forEach((linksSet, i) => {
      if (allLinks.length > 1) {
        items.push({
          type: "title",
          value: i === 0 ? "Their links" : "Your links",
        })
      }
      Object.entries(linksSet)
        .filter(([k, v]) => !!v)
        .forEach(([k, v]) => {
          items.push({ type: "link", value: { k, v } })
        })
    })

    return items.map((item, i) => {
      if (item.type === "title") {
        return (
          <MenuItem value={""} disabled key={i}>
            {item.value}
          </MenuItem>
        )
      } else {
        return (
          <MenuItem value={item.value.v} key={item.value.k + "_" + i}>
            <div>
              <p>{linkProviderNames[item.value.k]}</p>
              <p
                className={
                  "color-black-54 font-13 overflow-x-hidden overflow-ellipsis max-w-[300px]"
                }
              >
                {item.value.v}
              </p>
            </div>
          </MenuItem>
        )
      }
    })
  }

  const inviteeHasLinks = allLinks[0]
    ? Object.values(allLinks[0]).some(Boolean)
    : false

  const fieldBox = (
    <div className="form__fieldbox">
      <div className="form__input form__input--select">
        {inviteeHasLinks ? (
          <FormControl fullWidth error={!!error}>
            <Select
              id={"savedLink"}
              name={"savedLink"}
              fullWidth
              onChange={(e) => onChange(e.target.value)}
              value={value}
              // labelId={"savedLinkLabel"}
              displayEmpty
            >
              <MenuItem value={""} disabled className={"color-black-54"}>
                Select link
              </MenuItem>
              {renderList()}
            </Select>
            {!!error && (
              <FormHelperText className={"error-message"}>
                {error}
              </FormHelperText>
            )}
          </FormControl>
        ) : (
          <TextField
            onChange={(e) => onChange(e.target.value)}
            value={value}
            fullWidth
            placeholder="Type conference link"
            error={!!error}
            helperText={
              !!error && (
                <FormHelperText style={{ color: colors.error }}>
                  {error}
                </FormHelperText>
              )
            }
          />
        )}
      </div>
    </div>
  )

  return leftLabel ? (
    <div className="form__fieldset">
      <label htmlFor={"conferenceLink"} className={"form__label"}>
        Conference Link
      </label>
      {fieldBox}
    </div>
  ) : (
    fieldBox
  )
}

export default OuterInviterContactLinkPicker
