import React from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  clearBlacklist,
  getBlacklist,
  removeUserFromBlacklist,
} from "../../../../../library/store/actions/creators/authCreators"
import ListPage from "../../../../../components/v2/layout/listPage/listPage"
import { useAlert } from "../../../../../hooks/useAlert"
import UserCard from "../../../../../components/v2/layout/cards/userCard"

const ProfileSettingsBlacklist = () => {
  const dispatch = useDispatch()
  const { errorAlert } = useAlert()

  const { blockedUsersList, blockedUsersListEndReached } = useSelector(
    ({ auth }) => auth
  )

  const onRemoveUserFromBlacklist = async (user_id) => {
    try {
      await dispatch(removeUserFromBlacklist({ user_id }))
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <ListPage
      title={"Blocked users"}
      noResultsLabel={"You don't have blocked users"}
      getList={async ({ name, offset }) =>
        await dispatch(getBlacklist({ name, offset }))
      }
      clearList={() => dispatch(clearBlacklist())}
      list={blockedUsersList}
      listEndReached={blockedUsersListEndReached}
      getListItemComponent={({ item }) => (
        <UserCard
          user={item}
          controls={{
            button: {
              label: "Unblock",
              action: () => onRemoveUserFromBlacklist(item.id),
            },
          }}
          key={item.id}
        />
      )}
    />
  )
}

export default ProfileSettingsBlacklist
