import React from "react"
import "./userAvatar.scss"
import { Avatar } from "@material-ui/core"
import { getUserName } from "../../library/utils/user/userUtils"
import makeStyles from "@material-ui/core/styles/makeStyles"

const useStyles = makeStyles((theme) => ({
  xs: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: "0.875rem",
  },
  sm: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    fontSize: "1rem",
  },
  md: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    fontSize: "1.5rem",
  },
  lg: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    fontSize: "2rem",
  },
  xl: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    fontSize: "2.5rem",
  },
}))

const UserAvatar = ({
  first_name = "",
  last_name = "",
  photo = "",
  user = null,
  size = "md",
  ...avatarProps
}) => {
  const avatarStyles = useStyles()
  const fullName = React.useMemo(() => {
    if (user) return

    if (first_name && last_name) {
      return first_name + " " + last_name
    }

    return ""
  }, [first_name, last_name, user])

  const smallName = React.useMemo(() => {
    if (user) return user.first_name[0] + user.last_name[0]

    if (first_name && last_name) return first_name[0] + last_name[0]

    return ""
  }, [first_name, last_name, user])

  return (
    <Avatar
      alt={fullName}
      src={photo}
      size={size}
      className={avatarStyles[size]}
      {...avatarProps}
    >
      {smallName}
    </Avatar>
  )
}

export default UserAvatar
