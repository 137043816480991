import React from "react"
import LineSlice from "../../../../../../../../../../../components/ui/lineSlice"
import VerticalMenu from "../../../../../../../../../../../components/ui/verticalMenu"
import MoreDropdown from "../../../../../../../../../../../components/ui/dropdowns/moreDropdown/moreDropdown"

const UnitsListItem = ({
  unit,
  openEditUnit,
  openDeleteUnit,
  openUnitDetails,
  onKnowmixPickerOpen,
  isStatic = false,
}) => {
  const dropdownMenuItems = [
    {
      type: "button",
      action: onKnowmixPickerOpen,
      label: "Use in course step",
    },
    {
      type: "button",
      action: () => openEditUnit(unit.id),
      label: "Edit unit",
    },
    {
      type: "h-divider",
      id: "hd1",
    },
    {
      type: "button",
      action: () => openDeleteUnit(unit.id),
      label: "Delete unit",
    },
  ]

  return (
    <div className={"box list-item"}>
      <div className="f aic full-width">
        <div className="list-item__info">
          <button onClick={() => openUnitDetails(unit.id)} className="link">
            <span className="list-item__name">{unit.name}</span>
          </button>
          <span className="list-item__description">
            {/*{`${unit.hours} hours, ${unit.subject.name}`}*/}
            {unit.subject.name}
          </span>

          {unit.description.length && (
            <div className="break-word color-black-87">
              <LineSlice text={unit.description} />
            </div>
          )}
        </div>
      </div>
      {!isStatic && (
        <div className={`list-item__control-panel`}>
          <MoreDropdown>
            <VerticalMenu
              menuItems={dropdownMenuItems}
              classes={"more-dropdown-menu__list"}
            />
          </MoreDropdown>
        </div>
      )}
    </div>
  )
}

export default UnitsListItem
