import React from "react"
import { useElementWidth } from "../../hooks/useElementWidth"

const HorizontalScroll = ({ id = "horizontal-scroll", children }) => {
  const width = useElementWidth(id)

  console.log({ width })

  return (
    <div id={id}>
      {!!width && (
        <div className="overflow-x-auto snap-x" style={{ width }}>
          <div className="pb-4">{children}</div>
        </div>
      )}
    </div>
  )
}

export default HorizontalScroll
