import { call } from "../../../networking/API"
import { KNOWMAP } from "../types/knowmapTypes"
import { LIMIT } from "../../../constants/limits"
import { APPOINTMENTS } from "../types/calendarTypes"

export const getTeacherKnowmapKnowmeets = ({
  offset = 0,
  limit = 10,
  name,
}) => {
  return async (dispatch) => {
    const response = await call(KNOWMAP.KNOWMEET.TEACHER_LIST, {
      offset,
      limit,
      name,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWMAP.KNOWMEET.TEACHER_LIST,
        payload: { list: json.uknows, offset, limit, total: json.uknows_count },
      })
    } else {
      throw json.error
    }
  }
}

export const clearTeacherKnowmapKnowmeets = () => {
  return async (dispatch) => {
    return dispatch({
      type: KNOWMAP.KNOWMEET.TEACHER_LIST_CLEAR,
    })
  }
}

export const getTeacherKnowmapKnowmixKnowmeets = ({
  knowmix_id,
  offset = 0,
  limit = 10,
  name,
}) => {
  return async (dispatch) => {
    const response = await call(KNOWMAP.KNOWMEET.TEACHER_KNOWMIX_LIST, {
      knowmix_id,
      offset,
      limit,
      name,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWMAP.KNOWMEET.TEACHER_KNOWMIX_LIST,
        payload: { list: json.uknows, offset, limit, total: json.uknows_count },
      })
    } else {
      throw json.error
    }
  }
}

export const clearTeacherKnowmapKnowmixKnowmeets = () => {
  return async (dispatch) => {
    return dispatch({
      type: KNOWMAP.KNOWMEET.TEACHER_KNOWMIX_LIST_CLEAR,
    })
  }
}

export const getTeacherKnowmapKnowmixSteps = ({
  school_id,
  knowmix_id,
  offset = 0,
  limit = 10,
}) => {
  return async (dispatch) => {
    const response = await call(KNOWMAP.KNOWMIX.TEACHER_WORKS_LIST, {
      knowmix_id,
      school_id,
      offset,
      limit,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWMAP.KNOWMIX.TEACHER_WORKS_LIST,
        payload: { list: json.works, total: json.works_number, offset, limit },
      })
    } else {
      throw json.error
    }
  }
}

export const clearTeacherKnowmapKnowmixSteps = () => {
  return async (dispatch) => {
    return dispatch({
      type: KNOWMAP.KNOWMIX.TEACHER_WORKS_LIST_CLEAR,
    })
  }
}

export const getTeacherKnowmapKnowmixes = ({
  school_id,
  knowmix_id,
  offset = 0,
  limit = 10,
}) => {
  return async (dispatch) => {
    const response = await call(KNOWMAP.KNOWMIX.TEACHER_LIST, {
      knowmix_id,
      school_id,
      offset,
      limit,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWMAP.KNOWMIX.TEACHER_LIST,
        payload: {
          list: json.knowmixes,
          total: json.knowmixes_number,
          offset,
          limit,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const clearTeacherKnowmapKnowmixes = () => {
  return async (dispatch) => {
    return dispatch({
      type: KNOWMAP.KNOWMIX.TEACHER_LIST_CLEAR,
    })
  }
}

export const getTeacherKnowmapKnowcreds = ({
  title,
  type,
  offset = 0,
  limit = LIMIT,
}) => {
  return async (dispatch) => {
    const response = await call(KNOWMAP.KNOWCRED.TEACHER_LIST, {
      type,
      title,
      offset,
      limit,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWMAP.KNOWCRED.TEACHER_LIST,
        payload: { list: json.knowcreds, offset },
      })
    } else {
      throw json.error
    }
  }
}

export const clearTeacherKnowmapKnowcreds = () => {
  return async (dispatch) => {
    return dispatch({
      type: KNOWMAP.KNOWCRED.TEACHER_LIST_CLEAR,
    })
  }
}

export const getLearnerKnowmapKnowmixSteps = ({
  // name,
  offset = 0,
  limit = 10,
}) => {
  return async (dispatch) => {
    const response = await call(KNOWMAP.KNOWMIX.LEARNER_WORKS_LIST, {
      // name,
      offset,
      limit,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWMAP.KNOWMIX.LEARNER_WORKS_LIST,
        payload: { list: json.works, total: json.works_number, offset, limit },
      })
    } else {
      throw json.error
    }
  }
}

export const clearLearnerKnowmapKnowmixSteps = () => {
  return async (dispatch) => {
    return dispatch({
      type: KNOWMAP.KNOWMIX.LEARNER_WORKS_LIST_CLEAR,
    })
  }
}

export const getLearnerKnowmapKnowmixes = ({
  // name,
  offset = 0,
  limit = 10,
}) => {
  return async (dispatch) => {
    const response = await call(KNOWMAP.KNOWMIX.LEARNER_LIST, {
      // name,
      offset,
      limit,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWMAP.KNOWMIX.LEARNER_LIST,
        payload: {
          list: json.knowmixes,
          total: json.knowmixes_number,
          offset,
          limit,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const clearLearnerKnowmapKnowmixes = () => {
  return async (dispatch) => {
    return dispatch({
      type: KNOWMAP.KNOWMIX.LEARNER_LIST_CLEAR,
    })
  }
}

export const getLearnerKnowmapKnowcreds = ({
  title,
  // status_type,
  type,
  offset = 0,
  limit = LIMIT,
}) => {
  return async (dispatch, getState) => {
    const response = await call(KNOWMAP.KNOWCRED.LEARNER_LIST, {
      // user_id: getState().auth.userData.id,
      // status_type,
      type,
      title,
      offset,
      limit,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWMAP.KNOWCRED.LEARNER_LIST,
        payload: { list: json.knowcreds, offset },
      })
    } else {
      throw json.error
    }
  }
}

export const clearLearnerKnowmapKnowcreds = (status_type) => {
  return async (dispatch) => {
    return dispatch({
      type: KNOWMAP.KNOWCRED.LEARNER_LIST_CLEAR,
      // payload: { type: status_type },
    })
  }
}

export const getLeanerKnowmapKnowmeets = ({
  name,
  offset = 0,
  limit = LIMIT,
}) => {
  return async (dispatch) => {
    const response = await call(KNOWMAP.KNOWMEET.LEARNER_LIST, {
      name,
      offset,
      limit: 100,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWMAP.KNOWMEET.LEARNER_LIST,
        payload: {
          offset,
          list: json.uknows,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const clearLeanerKnowmapKnowmeets = () => {
  return async (dispatch) => {
    return dispatch({
      type: KNOWMAP.KNOWMEET.LEARNER_LIST_CLEAR,
    })
  }
}

export const getLeanerKnowmapKnowmixKnowmeets = ({
  // knowmix_id,
  name,
  offset = 0,
  limit = LIMIT,
}) => {
  return async (dispatch) => {
    const response = await call(KNOWMAP.KNOWMEET.LEARNER_KNOWMIX_LIST, {
      // knowmix_id,
      name,
      offset,
      limit,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: KNOWMAP.KNOWMEET.LEARNER_KNOWMIX_LIST,
        payload: {
          offset,
          list: json.uknows,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const clearLeanerKnowmapKnowmixKnowmeets = () => {
  return async (dispatch) => {
    return dispatch({
      type: KNOWMAP.KNOWMEET.LEARNER_KNOWMIX_LIST_CLEAR,
    })
  }
}

export const getIncomingAppointmentBookings = ({
  name,
  offset = 0,
  limit = LIMIT,
}) => {
  return async (dispatch) => {
    const response = await call(KNOWMAP.BOOKINGS.INCOMING_LIST, {
      name,
      offset,
      limit,
      b_creator: false,
    })

    const json = await response.data

    const generateList = (payload) => {
      return [
        ...payload.book_appointments.map((b) => ({
          ...b,
          _type: "book_appointments",
          view_id: "b__" + b.id,
        })),
        ...payload.solicit_appointments.map((b) => ({
          ...b,
          _type: "solicit_appointments",
          view_id: "s__" + b.id,
        })),
      ]
        .sort((a, b) => new Date(b.created) - new Date(a.created))
        .flat()
    }

    if (response.status === 200) {
      return dispatch({
        type: KNOWMAP.BOOKINGS.INCOMING_LIST,
        payload: {
          offset,
          list: generateList(json) || [],
        },
      })
    } else {
      throw json.error
    }
  }
}

export const clearIncomingAppointmentBookings = () => {
  return async (dispatch) => {
    return dispatch({
      type: KNOWMAP.BOOKINGS.INCOMING_LIST_CLEAR,
    })
  }
}

export const getOutgoingAppointmentBookings = ({
  name,
  offset = 0,
  limit = LIMIT,
}) => {
  return async (dispatch) => {
    const response = await call(KNOWMAP.BOOKINGS.INCOMING_LIST, {
      name,
      offset,
      limit,
      b_creator: true,
    })

    const json = await response.data

    const generateList = (payload) => {
      return [
        ...payload.book_appointments.map((b) => ({
          ...b,
          _type: "book_appointments",
          view_id: "b__" + b.id,
        })),
        ...payload.solicit_appointments.map((b) => ({
          ...b,
          _type: "solicit_appointments",
          view_id: "s__" + b.id,
        })),
      ]
        .sort((a, b) => new Date(b.created) - new Date(a.created))
        .flat()
    }

    if (response.status === 200) {
      return dispatch({
        type: KNOWMAP.BOOKINGS.OUTGOING_LIST,
        payload: {
          offset,
          list: generateList(json) || [],
        },
      })
    } else {
      throw json.error
    }
  }
}

export const acceptAppointmentBooking = ({ id, type }) => {
  return async (dispatch) => {
    const response = await call(
      type === "book_appointments"
        ? APPOINTMENTS.ACCEPT
        : APPOINTMENTS.SOLICIT.ACCEPT,
      {
        id,
      }
    )

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: APPOINTMENTS.ACCEPT,
        payload: {
          id,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const declineAppointmentBooking = ({ id, type }) => {
  return async (dispatch) => {
    const response = await call(
      type === "book_appointments"
        ? APPOINTMENTS.DECLINE
        : APPOINTMENTS.SOLICIT.DECLINE,
      {
        id,
      }
    )

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: APPOINTMENTS.DECLINE,
        payload: {
          id,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const cancelAppointmentBooking = ({ id }) => {
  return async (dispatch) => {
    const response = await call(APPOINTMENTS.CANCEL, {
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: APPOINTMENTS.CANCEL,
        payload: {
          id,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const forwardAppointmentBooking = ({ id }) => {
  return async (dispatch) => {
    const response = await call(APPOINTMENTS.FORWARD, {
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: APPOINTMENTS.FORWARD,
        payload: {
          id,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const removeAppointmentBooking = ({ id, type }) => {
  return async (dispatch) => {
    const response = await call(
      type === "book_appointments"
        ? APPOINTMENTS.REMOVE
        : APPOINTMENTS.SOLICIT.REMOVE,
      {
        id,
      }
    )

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: APPOINTMENTS.REMOVE,
        payload: {
          id,
        },
      })
    } else {
      throw json.error
    }
  }
}
