import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import FormControl from "@material-ui/core/FormControl"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import FormGroup from "@material-ui/core/FormGroup"
import Checkbox from "@material-ui/core/Checkbox"
import getErrorText from "../../../../../library/constants/errorTypes"
import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../../../library/constants/routes"
import logoDefault from "../../../../../assets/img/product.svg"
import PlainLink from "../../../../../components/ui/link"
import { getObjectById } from "../../../../../library/utils/arrays"
import { calendarGrades } from "../../../../../library/constants/educationalDictionary"
import {
  getKnowmix,
  worksGet,
  worksGrade,
} from "../../../../../library/store/actions/creators/knowmixCreators"
import { withSnackbar } from "notistack"
import Loader from "../../../../../components/ui/loader"
import KnowMixGradeForm from "../knowMixGradeForm"
import styled from "@emotion/styled"
import * as qs from "query-string"
import ChosenStandardsList from "../../../standards/chosenStandardsList/chosenStandardsList"
import { letters } from "../../courseSettings/CourseSettingsSteps/questionForm/MatchingQuestionConstructor"
import SEO from "../../../../../components/SEO"

const Td = styled.td`
  vertical-align: baseline;
`

class KnowMixCheck extends React.Component {
  state = {
    dataReceived: false,
    grade: "",
    comment: "",
    currentWork: null,
    currentKnowmix: null,
  }

  componentDidMount() {
    this.getData()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.match.params.knowmixId !== this.props.match.params.knowmixId ||
      prevProps.match.params.checkId !== this.props.match.params.checkId
    ) {
      this.setState({ dataReceived: false })
      this.getData()
    }
  }

  getData = async () => {
    const {
      match: {
        params: { knowmixId, checkId },
      },
    } = this.props
    try {
      await this.props.getKnowmix({ id: parseInt(knowmixId) })
      await this.props.worksGet({ id: parseInt(checkId) })
      const { currentWork, currentKnowmix } = this.props.knowmix
      this.setState({
        dataReceived: true,
        currentWork,
        currentKnowmix,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }
  changeGrade = (e) => {
    this.setState({
      grade: e.target.value,
    })
  }
  changeComment = (e) => {
    this.setState({
      comment: e.target.value,
    })
  }

  getQuestionLayout = (
    {
      title,
      type,
      answer_text,
      user_answer_text,
      options = [],
      answer_options = [],
      user_answer_options,
      user_answer_text_options,
      questions,
      answers,
      parts,
    },
    index
  ) => {
    console.log(user_answer_options)
    switch (type) {
      case 1:
        return (
          <>
            <div className="form__fieldset">
              <label htmlFor={`field_${index}`} className="form__label">
                Question {index + 1}
              </label>
              <div className="form__masked-value">{title}</div>
            </div>
            <div className="form__fieldset">
              <label htmlFor={`field_${index}`} className="form__label">
                Answer
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <FormControl component="fieldset" id="group-type">
                    <RadioGroup aria-label="group-type" name="groupType">
                      {options.map((v, i) => {
                        return (
                          <FormControlLabel
                            key={v}
                            disabled
                            checked={user_answer_options.includes(i)}
                            control={<Radio color="primary" />}
                            label={v}
                            labelPlacement="end"
                          />
                        )
                      })}
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="form__fieldset">
              <label htmlFor={`field_${index}`} className="form__label">
                Right answer
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <FormControl component="fieldset" id="group-type">
                    <RadioGroup aria-label="group-type" name="groupType">
                      {options.map((v, i) => {
                        return (
                          <FormControlLabel
                            key={v}
                            disabled
                            checked={answer_options.includes(i)}
                            control={<Radio color="primary" />}
                            label={v}
                            labelPlacement="end"
                          />
                        )
                      })}
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div>
          </>
        )
      case 2:
        return (
          <>
            <div className="form__fieldset">
              <label htmlFor={`field_${index}`} className="form__label">
                Question {index + 1}
              </label>
              <div className="form__masked-value">{title}</div>
            </div>
            <div className="form__fieldset">
              <label htmlFor={`field_${index}`} className="form__label">
                Answer
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <FormControl component="fieldset">
                    <FormGroup>
                      {options.map((v, i) => {
                        return (
                          <FormControlLabel
                            disabled
                            key={v}
                            control={
                              <Checkbox
                                checked={user_answer_options.includes(i)}
                              />
                            }
                            label={v}
                          />
                        )
                      })}
                    </FormGroup>
                  </FormControl>
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor={`field_${index}`} className="form__label">
                Right answer
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <FormControl component="fieldset" className="">
                    <FormGroup>
                      {options.map((v, i) => {
                        return (
                          <FormControlLabel
                            disabled
                            key={v}
                            control={
                              <Checkbox checked={answer_options.includes(i)} />
                            }
                            label={v}
                          />
                        )
                      })}
                    </FormGroup>
                  </FormControl>
                </div>
              </div>
            </div>
          </>
        )
      case 3:
        return (
          <>
            <div className="form__fieldset">
              <label htmlFor={`field_${index}`} className="form__label">
                Question {index + 1}
              </label>
              <div className="form__masked-value">
                <pre>{title}</pre>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor={`field_${index}`} className="form__label">
                Answer
              </label>
              <div className="form__fieldbox">
                <div className="form__masked-value">
                  <pre>{user_answer_text}</pre>
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor={`field_${index}`} className="form__label">
                Right answer
              </label>
              <div className="form__fieldbox">
                <div className="form__masked-value">
                  <pre>{answer_text}</pre>
                </div>
              </div>
            </div>
          </>
        )

      case 4:
        return (
          <>
            <div className="form__fieldset">
              <span className="form__label">Question {index + 1}</span>
              <div className="form__fieldbox">
                <div className="form__masked-value">{title}</div>
              </div>
            </div>

            {parts.map((part, partIdx) => {
              return (
                <>
                  <div className="form__fieldset">
                    <span className="form__label">
                      {part.type === 1 ? "Right answer" : ""}
                    </span>
                    <div className="form__fieldbox">
                      <div className="form__masked-value">{part.text}</div>
                    </div>
                  </div>
                  {part.type === 1 && (
                    <div className="form__fieldset">
                      <span className="form__label">User's answer</span>
                      <div className="form__fieldbox">
                        <div className="form__masked-value">
                          {user_answer_text_options[0]}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )
            })}
          </>
        )

      case 5:
        return (
          <>
            <div className="form__fieldset">
              <span className="form__label">Question {index + 1}</span>
              <div className="form__fieldbox">
                <div className="form__masked-value">{title}</div>
              </div>
            </div>

            <div className="form__fieldset">
              <span className="form__label">Statements</span>
              <div className="form__fieldbox">
                {questions.map((q, qIdx) => (
                  <div className="form__masked-value">
                    {qIdx + 1}. {q.value}
                  </div>
                ))}
              </div>
            </div>

            <div className="form__fieldset">
              <span className="form__label">Answers</span>
              <div className="form__fieldbox">
                {answers.map((a, aIdx) => (
                  <div className="form__masked-value">
                    {letters[aIdx]}. {a.value}
                  </div>
                ))}
              </div>
            </div>

            <div className="form__fieldset">
              <span className="form__label">Right answers</span>
              <div className="form__fieldbox">
                {questions.map((q, qIdx) => (
                  <div className="form__masked-value">
                    {qIdx + 1}. {letters[q.right_answer]}
                  </div>
                ))}
              </div>
            </div>

            <div className="form__fieldset">
              <span className="form__label">User answers</span>
              <div className="form__fieldbox">
                {user_answer_options.map((a, aIdx) => (
                  <div className="form__masked-value">
                    {aIdx + 1}. {letters[a]}
                  </div>
                ))}
              </div>
            </div>
          </>
        )

      default:
        return <></>
    }
  }
  grade = async () => {
    const {
      match: {
        params: { checkId },
      },
    } = this.props
    this.setState({ dataReceived: false })
    const { comment, grade } = this.state
    try {
      await this.props.worksGrade({
        id: parseInt(checkId),
        comment: comment || undefined,
        grade,
      })
    } catch ({ error }) {
      if (error.code === 20004) {
        this.props.enqueueSnackbar("Step already graded", { variant: "error" })
      } else {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
      return this.setState({ dataReceived: true })
    }

    const { redirectToKnowmap } = qs.parse(this.props.location.search)

    this.props.history.push(
      !!redirectToKnowmap
        ? routes.KNOWMAP_KNOWMIX_STEPS
        : routes.SUBMITTED_WORKS_STEPS_EXAMINED
    )
  }

  render() {
    const { dataReceived, grade, comment, currentWork, currentKnowmix } =
      this.state
    if (dataReceived) {
      const { learner } = currentWork

      const creatorName = currentKnowmix.creator_user
        ? `${currentKnowmix.creator_user?.first_name} ${currentKnowmix.creator_user?.last_name}`
        : currentKnowmix.creator_group?.name

      const creatorUrl = currentKnowmix.creator_user
        ? generatePath(routes.USER, { userId: currentKnowmix.creator_user?.id })
        : generatePath(routes.GROUP, {
            groupId: currentKnowmix.creator_group?.id,
          })

      const learnerName =
        !!learner && `${learner.first_name} ${learner.last_name}`

      const currentStep = currentKnowmix.steps.find(
        (s) => s.id === currentWork.step.id
      )

      return (
        <div className="common-page__content common-page__content--static">
          <SEO title={"Course Step Check"} />
          <main className="common-page__main">
            <div className="box">
              {dataReceived && (
                <>
                  <div className="box__heading">
                    <h1>
                      Step{" "}
                      {!!currentKnowmix &&
                        `${
                          currentKnowmix.steps.findIndex(
                            (s) => s.id === currentWork.step.id
                          ) + 1
                        } - ${currentWork.step.name}`}
                    </h1>
                  </div>
                  <div className="box__content">
                    <table className="product-main-info__table product-main-info__table--kx-check">
                      <tbody>
                        <tr>
                          <Td className="product-main-info__table-label pb8">
                            Step name:
                          </Td>
                          <td className="product-main-info pb8">
                            {currentWork.step.name}
                          </td>
                        </tr>
                        <tr>
                          <Td className="product-main-info__table-label pb8">
                            Course:
                          </Td>
                          <td className="product-main-info pb8">
                            <PlainLink
                              to={
                                !!currentKnowmix &&
                                generatePath(routes.KNOWMIX, {
                                  knowmixId: currentKnowmix.id,
                                })
                              }
                            >
                              {currentKnowmix.name}
                            </PlainLink>
                          </td>
                        </tr>
                        <tr>
                          <Td className="product-main-info__table-label pb8">
                            Creator:
                          </Td>
                          <td className="product-main-info pb8">
                            <PlainLink to={creatorUrl}>{creatorName}</PlainLink>
                          </td>
                        </tr>

                        {!!currentKnowmix.subject && (
                          <tr>
                            <Td className="product-main-info__table-label pb8">
                              Subject:
                            </Td>
                            <td className="product-main-info pb8">
                              {`${
                                getObjectById(
                                  calendarGrades,
                                  currentKnowmix.subject.grade
                                ).name
                              } grade, ${currentKnowmix.subject.name}`}
                            </td>
                          </tr>
                        )}

                        <tr>
                          <Td className="product-main-info__table-label pb8">
                            Standards:
                          </Td>
                          <td className="product-main-info pb8">
                            <ChosenStandardsList
                              standards={currentStep?.standards || []}
                            />
                          </td>
                        </tr>

                        <tr>
                          <Td className="product-main-info__table-label pb8">
                            Instructions:
                          </Td>
                          <td className="product-main-info pb8">
                            {currentStep?.description || "—"}
                          </td>
                        </tr>

                        {!!currentKnowmix.measurement &&
                          (currentKnowmix.is_teacher ||
                            currentKnowmix.is_admin) && (
                            <tr>
                              <Td className="product-main-info__table-label pb8">
                                Measurement:
                              </Td>
                              <td className="product-main-info pb8">
                                {currentKnowmix.measurement}
                              </td>
                            </tr>
                          )}

                        <tr>
                          <Td className="product-main-info__table-label">
                            Learner:
                          </Td>
                          <td className="product-main-info">
                            <PlainLink
                              to={
                                !!currentWork &&
                                generatePath(routes.USER, {
                                  userId: currentWork.learner.id,
                                })
                              }
                            >
                              {learnerName}
                            </PlainLink>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
            {dataReceived && (
              <div className="box">
                <div className="box__content">
                  <div className="form">
                    {!currentWork.questions.length ? (
                      <div className="text-secondary">
                        This step has no questions.
                      </div>
                    ) : (
                      currentWork.questions.map((q, i) => {
                        return (
                          <div className="question-container" key={i}>
                            {this.getQuestionLayout(q, i)}
                            {currentWork.questions.length > i + 1 && (
                              <div className="form__divider"></div>
                            )}
                          </div>
                        )
                      })
                    )}
                  </div>
                </div>
              </div>
            )}

            {dataReceived && (
              <KnowMixGradeForm
                grade={grade}
                comment={comment}
                submit={this.grade}
                changeGrade={this.changeGrade}
                changeComment={this.changeComment}
                isStep
              />
            )}
          </main>
          <aside className="common-page__sidebar">
            {dataReceived && (
              <div className="box aside-header">
                <Link
                  to={generatePath(routes.SUBMITTED_WORKS_STEPS)}
                  className="box__content aside-header__content"
                >
                  <img
                    src={this.props.knowmix.currentKnowmix.photo || logoDefault}
                    className="aside-header__img"
                    alt=""
                  />
                  <div className="aside-header__info">
                    <div className="aside-header__name">
                      {this.props.knowmix.currentWork.step.name}
                    </div>
                    <div className="aside-header__help">back</div>
                  </div>
                </Link>
              </div>
            )}
          </aside>
        </div>
      )
    }
    return <Loader />
  }
}

const mapState = ({ knowmix }) => ({ knowmix })

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      worksGet,
      worksGrade,
      getKnowmix,
    },
    dispatch
  )

export default connect(mapState, mapDispatch)(withSnackbar(KnowMixCheck))
