import React from "react"
import * as Yup from "yup"
import { Formik } from "formik"
import Form from "../../../../../../components/v2/layout/forms/form/form"
import { useSelector } from "react-redux"
import {
  MAX_DATE,
  MIN_DATE,
} from "../../../../../../library/constants/defaultDates"

const validationSchema = Yup.object({
  name: Yup.string().required().max(50),
  role: Yup.string(50),
  description: Yup.string(1000),
  country: Yup.string(75),
  city: Yup.string(75),
  date_from: Yup.date().min(MIN_DATE).nullable(),
  date_to: Yup.date().max(MAX_DATE).nullable(),
})

const WorkForm = ({ work, onSubmit, onDelete }) => {
  const { userData } = useSelector(({ auth }) => auth)

  const initialValues = {
    id: work.id || '',
    name: work.name || '',
    role: work.role || '',
    country: work.country || '',
    city: work.city || '',
    description: work.description || '',
    date_from: work.date_from || null,
    date_to: work.date_to || null,
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(fk) => (
        <Form onDelete={onDelete}>
          <Form.Row name={'country'} label={'Country'}>
            <Form.TextField
              name={'country'}
              placeholder={'Type your country'}
            />
          </Form.Row>
          <Form.Row name={'city'} label={'City'}>
            <Form.TextField name={'city'} placeholder={'Type your city'} />
          </Form.Row>
          <Form.Row
            name={'name'}
            label={`${
              [1, 2].includes(userData.role) ? 'Institution' : 'Company'
            } name *`}
          >
            <Form.TextField
              name={'name'}
              placeholder={`Type your ${
                [1, 2].includes(userData.role) ? 'institution' : 'company'
              }`}
            />
          </Form.Row>
          <Form.Row name={'role'} label={'Position'}>
            <Form.TextField name={'role'} placeholder={'Type your position'} />
          </Form.Row>
          <Form.Row name={'date_from'} label={'Start date'}>
            <Form.DatePicker
              name={'date_from'}
              placeholder={'Select start date'}
              maxDate={fk.values.date_to}
              views={['year', 'month']}
              format={'MMMM yyyy'}
            />
          </Form.Row>
          <Form.Row name={'date_to'} label={'Finish date'}>
            <Form.DatePicker
              name={'date_to'}
              placeholder={'Select finish date'}
              minDate={fk.values.date_from}
              views={['year', 'month']}
              format={'MMMM yyyy'}
            />
          </Form.Row>
          <Form.Row name={'description'} label={'About'}>
            <Form.TextField
              multiline
              name={'description'}
              placeholder={'Type something about your job'}
            />
          </Form.Row>
        </Form>
      )}
    </Formik>
  )
}

export default WorkForm
