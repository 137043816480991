import { usePostCtx } from "../post/PostContext"
import { useCommentCtx } from "../comments/CommentContext"
import ReplyProvider from "./ReplyContext"
import { useCommentsList } from "../../../../hooks/data/feed/comments"
import Reply from "./reply"
import { PlainButton } from "../../controls"
import KeyboardArrowDownOutlined from "@material-ui/icons/KeyboardArrowDownOutlined"
import Loader from "../../../ui/loader"
import React from "react"
import CommentCreate from "../comments/commentCreate"
import ReplyCreate from "./replyCreate"

const RepliesList = () => {
  const { post, queryKey } = usePostCtx()
  const { comment, isReplying, setIsReplying } = useCommentCtx()

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
    isFetchedAfterMount,
  } = useCommentsList({
    postId: post.id,
    commentId: comment.id,
    queryKey,
    options: {
      enabled: false,
    },
  })

  const onShowMore = async () => {
    await fetchNextPage()
  }

  return (
    <div>
      {comment.replies.map((reply) => (
        <ReplyProvider reply={reply} key={reply.id}>
          <Reply />
        </ReplyProvider>
      ))}

      {((!isFetchedAfterMount && comment.replies_number > 3) || hasNextPage) &&
        !isFetchingNextPage && (
          <div className="full-width f jcc mt10">
            <PlainButton onClick={onShowMore}>
              <span>Show more replies</span>
              <KeyboardArrowDownOutlined />
            </PlainButton>
          </div>
        )}

      {isFetchingNextPage && <Loader />}

      {isReplying && <ReplyCreate />}
    </div>
  )
}

export default RepliesList
