import React, { Component } from "react"
import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../../../library/constants/routes"
import {
  grades,
  subjects,
} from "../../../../../library/constants/educationalDictionary"
import BookmarkedItemDropdown from "../bookmarkedItemDropdown/bookmarkedItemDropdown"
import LineSlice from "../../../../../components/ui/lineSlice"

class BookmarkedStandard extends Component {
  state = {
    deleted: false,
  }

  deleteBookmark = () => {
    const { standard } = this.props
    this.props
      .deleteBookmark({ standard_id: standard.id })
      .then(this.setState({ deleted: true }))
  }

  render() {
    const { standard } = this.props
    const { deleted } = this.state

    if (!standard || deleted) return null

    return (
      <div className="product-tile">
        <div className="product-tile__menu">
          <BookmarkedItemDropdown
            deleteBookmark={this.deleteBookmark}
            bookmarkFoldersList={() =>
              this.props.bookmarkFoldersList(standard.bookmark.id)
            }
            addToFolder={(folders) =>
              this.props.addToFolder({
                bookmark_id: standard.bookmark.id,
                folders,
              })
            }
            bookmarksFoldersListClear={() =>
              this.props.bookmarksFoldersListClear()
            }
          />
        </div>
        <Link
          to={generatePath(routes.STANDARD, { standardId: standard.id })}
          className="product-tile__name link"
        >
          {standard.name.length > 20
            ? standard.name.slice(0, 20) + "..."
            : standard.name}
        </Link>
        <table className="product-tile__info">
          <tbody>
            <tr>
              <td className={`product-tile__info-label pb4`}>
                <span>State:</span>
              </td>
              <td className={`product-tile__info-value pb4`}>
                <span>{standard.state}</span>
              </td>
            </tr>
            {!!standard.subject && (
              <tr>
                <td className="product-tile__info-label">
                  <span>Subject:</span>
                </td>
                <td className="product-tile__info-value">
                  <span>
                    {subjects.find((s) => s.id === standard.subject).name}
                  </span>
                </td>
              </tr>
            )}
            {!!standard.grades && (
              <tr>
                <td className="product-tile__info-label">
                  <span>Grades:</span>
                </td>
                <td className="product-tile__info-value">
                  <span>
                    {standard.grades
                      .map((g) => grades.find((gr) => gr.id === g).name)
                      .join(", ")}
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {!!standard.description && (
          <LineSlice text={standard.description} linesCount={3} />
        )}
      </div>
    )
  }
}

export default BookmarkedStandard;
