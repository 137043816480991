import InsertDriveFile from "@material-ui/icons/InsertDriveFile"
import { formatBytes } from "../../../../../library/utils/fileSizeConverter"
import React from "react"

const PostFiles = ({ files }) => {
  return (
    <div className="post__files">
      {files.map(({ id, name, size, url }) => {
        return (
          <a href={url} target="_blank" className="post__file" key={id}>
            <InsertDriveFile
              className="post__file-icon"
              style={{ fontSize: "30px" }}
            />
            <div className="post__file-info">
              <span className="link link--inline">{name}</span>
              <span className="post__file-size">{formatBytes(size)}</span>
            </div>
          </a>
        )
      })}
    </div>
  )
}

export default PostFiles
