import React from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  getData,
  updateInfo,
} from "../../../../../library/store/actions/creators/authCreators"
import { MIN_DATE_ISO } from "../../../../../library/constants/defaultDates"
import BoxPage from "../../../../../components/v2/layout/boxPage/boxPage"
import { Formik } from "formik"
import * as Yup from "yup"
import Form from "../../../../../components/v2/layout/forms/form/form"
import { useAlert } from "../../../../../hooks/useAlert"

const validationSchema = Yup.object({
  birthdate: Yup.date().nullable(),
  interests: Yup.string().max(500),
  favorite_subjects: Yup.string().max(500),
  favorite_films: Yup.string().max(500),
  favorite_books: Yup.string().max(500),
  favorite_games: Yup.string().max(500),
})

const ProfileEditPersonal = () => {
  const { userData } = useSelector(({ auth }) => auth)
  const dispatch = useDispatch()
  const { successAlert, errorAlert } = useAlert()

  const initialValues = {
    birthdate: userData.birthdate,
    interests: userData.interests,
    favorite_subjects: userData.favorite_subjects,
    favorite_films: userData.favorite_films,
    favorite_books: userData.favorite_books,
    favorite_games: userData.favorite_games,
  }

  const onSubmit = async (values) => {
    console.log(values)
    try {
      await dispatch(
        updateInfo({
          ...values,
          birthdate:
            values.birthdate === null
              ? MIN_DATE_ISO
              : new Date(values.birthdate).toISOString(),
        }),
      )
      await dispatch(getData())
      successAlert()
    } catch (error) {
      errorAlert(error)
    }
  }

  return (
    <BoxPage title='Personal'>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <Form.Row name={'birthdate'} label={'Date of birth'}>
            <Form.DatePicker
              name={'birthdate'}
              placeholder={'Select date of birth'}
              disableFuture
            />
          </Form.Row>
          <Form.Row name={'interests'} label={'Interests'}>
            <Form.TextField
              name={'interests'}
              placeholder={'Type your interests'}
              multiline
            />
          </Form.Row>
          <Form.Row name={'favorite_subjects'} label={'Favorite subjects'}>
            <Form.TextField
              name={'favorite_subjects'}
              placeholder={'Type your favorite learning subjects'}
              multiline
            />
          </Form.Row>
          <Form.Row name={'favorite_films'} label={'Favorite films'}>
            <Form.TextField
              name={'favorite_films'}
              placeholder={'Type your favorite films'}
              multiline
            />
          </Form.Row>
          <Form.Row name={'favorite_books'} label={'Favorite books'}>
            <Form.TextField
              name={'favorite_books'}
              placeholder={'Type your favorite books'}
              multiline
            />
          </Form.Row>
          <Form.Row name={'favorite_games'} label={'Favorite games'}>
            <Form.TextField
              name={'favorite_games'}
              placeholder={'Type your favorite games'}
              multiline
            />
          </Form.Row>
          {/*//TODO: file input*/}
          {/*<Form.Row name={'attachments'} label={'Attachments'}>*/}
          {/*  <FileManager />*/}
          {/*</Form.Row>*/}
        </Form>
      </Formik>
    </BoxPage>
  )
}

export default ProfileEditPersonal
