import { StateComposer } from "../../utils/reducerUtils/reducerUtils"
import { KNOWMAP } from "../actions/types/knowmapTypes"
import TypedStateArray from "../../utils/reducerUtils/typedStateArray"
import { format } from "date-fns"
import { generatePath } from "react-router-dom"
import * as routes from "../../constants/routes"
import defaultDate from "../../constants/defaultDates"
import { safeFormatDate } from "../../utils/dateConvert"
import { UKNOW } from "../actions/types/uknowTypes"
import { getUserName } from "../../utils/user/userUtils"
import { APPOINTMENTS } from "../actions/types/calendarTypes"

const initialState = {
  ...StateComposer.initStateArrays([
    // 'teacherKnowmeet',
    "teacherKnowmixSteps",
    "teacherKnowmix",
    "teacherKnowcred",
    "learnerKnowmeet",
    "learnerKnowmixSteps",
    "learnerKnowmix",
    "learnerKnowcred",
    "learnerKnowmixKnowmeet",
  ]),
  ...StateComposer.initStateCurrentItems(["currentKnowmeet"]),
  // ...TypedStateArray.initStateArrayByType('learnerKnowcred', [2, 3]),

  teacherKnowmeet: {
    list: [],
    total: 0,
    availableOffsets: [0],
  },
  teacherKnowmixKnowmeet: {
    list: [],
    total: 0,
    availableOffsets: [0],
  },
  teacherKnowmix: {
    list: [],
    total: 0,
    availableOffsets: [0],
  },
  learnerKnowmix: {
    list: [],
    total: 0,
    availableOffsets: [0],
  },
  incomingBookings: {
    list: [],
    total: 0,
    availableOffsets: [0],
  },
  outgoingBookings: {
    list: [],
    total: 0,
    availableOffsets: [0],
  },
}

const generateOffsets = (limit, total) => {
  const arr1 = Array(total).fill(0)
  const arr2 = arr1.map((_, i) => i)

  return arr2.filter((item) => item === 0 || item % limit === 0)
}

export const knowmapReducer = (state = initialState, action) => {
  const composer = new StateComposer(state, action)
  const composerByType = new TypedStateArray(state, action, initialState)

  switch (action.type) {
    case KNOWMAP.KNOWMEET.GET:
      return composer.record("currentKnowmeet").get()

    case KNOWMAP.KNOWMEET.CLEAR_CURRENT:
      return composer.record("currentKnowmeet").clear()

    case KNOWMAP.KNOWMEET.TEACHER_LIST:
      return {
        ...state,
        teacherKnowmeet: {
          total: action.payload.total,
          list: action.payload.list.map((record, index) => ({
            id: record.id,
            raw: record,
            info: [
              // index + 1,
              record.name || "",
              record.date_from
                ? format(new Date(record.date_from), "d MMM yyy")
                : "",
              record.block?.name ?? "",
              record.class?.name ?? "",

              record.target_date
                ? format(new Date(record.target_date), "d MMM yyy")
                : "",
              // `${record.clicked || ''}/${record.awaiting || ''}`,
              {
                type: "links-list",
                links: record.users.map((user) => ({
                  path: generatePath(routes.USER, {
                    userId: user.id,
                  }),
                  label: user.first_name + " " + user.last_name,
                })),
              },
            ],
          })),
          availableOffsets: generateOffsets(
            action.payload.limit,
            action.payload.total
          ),
        },
      }

    case KNOWMAP.KNOWMEET.TEACHER_LIST_CLEAR:
      return {
        ...state,
        teacherKnowmeet: {
          list: [],
          total: 0,
          availableOffsets: [0],
        },
      }

    case KNOWMAP.KNOWMEET.TEACHER_KNOWMIX_LIST:
      return {
        ...state,
        teacherKnowmixKnowmeet: {
          total: action.payload.total,
          list: action.payload.list.map((record, index) => ({
            id: record.id,
            raw: record,
            info: [
              // index + 1,
              record.name || "",
              // record.date_from ? safeFormatDate(record.date_from) : "",
              record.target_date
                ? record.target_date !== defaultDate
                  ? safeFormatDate(record.target_date)
                  : "—"
                : "",
              `${record.cohort_size}/${record.current_cohort_size}` || "",
              record.current_completed_size || "",
              record.step
                ? {
                    type: "link",
                    path: {
                      pathname: generatePath(routes.KNOWMIX, {
                        knowmixId: record.knowmix.id,
                      }),
                      search: `?stepId=${record.step.id}`,
                    },
                    label: record.step.name || "",
                  }
                : "",
            ],
          })),
          availableOffsets: generateOffsets(
            action.payload.limit,
            action.payload.total
          ),
        },
      }

    case KNOWMAP.KNOWMEET.TEACHER_KNOWMIX_LIST_CLEAR:
      return {
        ...state,
        teacherKnowmixKnowmeet: {
          list: [],
          total: 0,
          availableOffsets: [0],
        },
      }

    case KNOWMAP.KNOWMEET.LEARNER_LIST:
      return composer.array("learnerKnowmeet").get()

    case KNOWMAP.KNOWMEET.MARK_AS_DONE:
      return {
        ...state,
        currentKnowmeet: null,
        learnerKnowmeetList: state.learnerKnowmeetList.map((u) =>
          u.id === action.payload.id ? { ...u, completed: true } : u
        ),
      }

    case KNOWMAP.KNOWMEET.LEARNER_LIST_CLEAR:
      return composer.array("learnerKnowmeet").clear()

    case KNOWMAP.KNOWMEET.LEARNER_KNOWMIX_LIST:
      return composer.array("learnerKnowmixKnowmeet").get()

    case KNOWMAP.KNOWMEET.LEARNER_KNOWMIX_LIST_CLEAR:
      return composer.array("learnerKnowmixKnowmeet").clear()

    case KNOWMAP.KNOWMIX.TEACHER_WORKS_LIST:
      return {
        ...state,
        teacherKnowmixSteps: {
          total: action.payload.total,
          list: action.payload.list.map((record, index) => ({
            id: record.id,
            raw: record,
            info: [
              {
                type: "link",
                path: generatePath(routes.USER, {
                  userId: record.learner.id,
                }),
                label: getUserName(record.learner),
              },
              {
                type: "link",
                path: {
                  pathname: generatePath(routes.KNOWMIX, {
                    knowmixId: record.knowmix.id,
                  }),
                  search: `?stepId=${record.step.id}`,
                },
                label: record.step.name || "",
              },
              record.grade ? "Completed" : "To Teacher",
              record.grade || "—",
            ],
          })),
          availableOffsets: generateOffsets(
            action.payload.limit,
            action.payload.total
          ),
        },
      }

    case KNOWMAP.KNOWMIX.TEACHER_WORKS_LIST_CLEAR:
      return {
        ...state,
        teacherKnowmixSteps: {
          list: [],
          total: 0,
          availableOffsets: [0],
        },
      }

    case KNOWMAP.KNOWMIX.TEACHER_LIST:
      return {
        ...state,
        teacherKnowmix: {
          total: action.payload.total,
          list: action.payload.list.map((record, index) => ({
            id: record.id,
            raw: record,
            info: [
              {
                type: "link",
                path: generatePath(routes.USER, {
                  userId: record.learner.id,
                }),
                label: getUserName(record.learner),
              },
              {
                type: "link",
                path: {
                  pathname: generatePath(routes.KNOWMIX, {
                    knowmixId: record.id,
                  }),
                },
                label: record.name || "",
              },
              record.grade ? "Completed" : "To Teacher",
              record.grade || "—",
            ],
          })),
          availableOffsets: generateOffsets(
            action.payload.limit,
            action.payload.total
          ),
        },
      }

    case KNOWMAP.KNOWMIX.TEACHER_LIST_CLEAR:
      return {
        ...state,
        teacherKnowmix: {
          list: [],
          total: 0,
          availableOffsets: [0],
        },
      }

    case KNOWMAP.KNOWMIX.LEARNER_WORKS_LIST:
      return {
        ...state,
        learnerKnowmixSteps: {
          total: action.payload.total,
          list: action.payload.list.map((record, index) => ({
            id: record.id,
            raw: record,
            info: [
              {
                type: "link",
                path: {
                  pathname: generatePath(routes.KNOWMIX, {
                    knowmixId: record.knowmix.id,
                  }),
                  search: `?stepId=${record.step.id}`,
                },
                label: record.step.name || "",
              },
              {
                type: "link",
                path: generatePath(routes.KNOWMIX, {
                  knowmixId: record.knowmix.id,
                }),
                label: record.knowmix.name || "",
              },
              record.examiner
                ? {
                    type: "link",
                    path: generatePath(routes.USER, {
                      userId: record.examiner.id,
                    }),
                    label: getUserName(record.examiner),
                  }
                : "",
              record.grade ? "Completed" : "To teacher",
              record.grade || "—",
            ],
          })),
          availableOffsets: generateOffsets(
            action.payload.limit,
            action.payload.total
          ),
        },
      }

    case KNOWMAP.KNOWMIX.LEARNER_WORKS_LIST_CLEAR:
      return {
        ...state,
        learnerKnowmixSteps: {
          list: [],
          total: 0,
          availableOffsets: [0],
        },
      }

    case KNOWMAP.KNOWMIX.LEARNER_LIST:
      return {
        ...state,
        learnerKnowmix: {
          total: action.payload.total,
          list: action.payload.list.map((record, index) => ({
            id: record.id,
            raw: record,
            info: [
              {
                type: "link",
                path: generatePath(routes.KNOWMIX, {
                  knowmixId: record.id,
                }),
                label: record.name || "",
              },
              record.teacher
                ? {
                    type: "link",
                    path: generatePath(routes.USER, {
                      userId: record.teacher.id,
                    }),
                    label: getUserName(record.teacher),
                  }
                : "",
              record.grade ? "Completed" : "To teacher",
              record.grade || "—",
            ],
          })),
          availableOffsets: generateOffsets(
            action.payload.limit,
            action.payload.total
          ),
        },
      }

    case KNOWMAP.KNOWMIX.LEARNER_LIST_CLEAR:
      return {
        ...state,
        learnerKnowmix: {
          list: [],
          total: 0,
          availableOffsets: [0],
        },
      }

    case KNOWMAP.KNOWCRED.TEACHER_LIST:
      return composer.array("teacherKnowcred").get()

    case KNOWMAP.KNOWCRED.TEACHER_LIST_CLEAR:
      return composer.array("teacherKnowcred").clear()

    case KNOWMAP.KNOWCRED.LEARNER_LIST:
      return composer.array("learnerKnowcred").get()
    // return composerByType.entities('learnerKnowcred').get()

    case KNOWMAP.KNOWCRED.LEARNER_LIST_CLEAR:
      return composer.array("learnerKnowcred").clear()
    // return composerByType.entities('learnerKnowcred').clear()

    case UKNOW.KNOWMIX_MARK_AS_DONE:
      return {
        ...state,
        learnerKnowmixKnowmeet: state.learnerKnowmixKnowmeet.map((u) =>
          u.id === action.payload.id ? { ...u, is_completed: true } : u
        ),
      }

    case KNOWMAP.BOOKINGS.INCOMING_LIST:
      return {
        ...state,
        incomingBookings: {
          total: action.payload.total,
          list: action.payload.list.map((record, index) => ({
            id: record.id,
            raw: record,
            info: [
              record.title || "—",
              record.creator?.id
                ? {
                    type: "link",
                    path: generatePath(routes.USER, {
                      userId: record.creator_id?.id,
                    }),
                    label:
                      record?.creator?.name_first +
                      " " +
                      record?.creator?.name_last,
                    // label: getUserName(record.user),
                  }
                : record?.creator?.name_first +
                  " " +
                  record?.creator?.name_last,
              record.invitees.length,
              record._type === "book_appointments"
                ? record.date_from
                  ? safeFormatDate(record.date_from, {
                      withTime: true,
                    })
                  : "—"
                : "To be calculated",
              record.duration + " min",
              // record.location || "—",
              record.conference_link || record.phone || "—",
              record.details || "—",
            ],
          })),
          availableOffsets: generateOffsets(
            action.payload.limit,
            action.payload.total
          ),
        },
      }

    case KNOWMAP.BOOKINGS.OUTGOING_LIST:
      return {
        ...state,
        outgoingBookings: {
          total: action.payload.total,
          list: action.payload.list.map((record, index) => ({
            id: record.id,
            raw: record,
            info: [
              record.title || null,
              record.invitees.length,
              {
                type: "people",
                list: record.invitees,
                limit: 2,
                // path: generatePath(routes.USER, {
                //   userId: record.invitee_id || 0,
                // }),
                // label: record.name_first + " " + record.name_last,
                // // label: getUserName(record.user),
              },
              record._type === "book_appointments"
                ? record.date_from
                  ? safeFormatDate(record.date_from, {
                      withTime: true,
                    })
                  : null
                : "TBA",
              record.duration + " min",
              // record.location || "—",
              record.conference_link
                ? { type: "external-link", path: record.conference_link }
                : record.phone
                  ? { type: "phone", number: record.phone }
                  : null,
              record.details || null,
            ],
          })),
          availableOffsets: generateOffsets(
            action.payload.limit,
            action.payload.total
          ),
        },
      }

    case KNOWMAP.BOOKINGS.INCOMING_LIST_CLEAR:
      return {
        ...state,
        incomingBookings: initialState.incomingBookings,
      }

    case KNOWMAP.BOOKINGS.OUTGOING_LIST_CLEAR:
      return {
        ...state,
        outgoingBookings: initialState.outgoingBookings,
      }

    case APPOINTMENTS.ACCEPT:
      return {
        ...state,
        incomingBookings: {
          ...state.incomingBookings,
          list: state.incomingBookings.list.map((item) =>
            item.id === action.payload.id ? { ...item, accepted: true } : item
          ),
        },
      }

    case APPOINTMENTS.DECLINE:
      return {
        ...state,
        incomingBookings: {
          ...state.incomingBookings,
          list: state.incomingBookings.list.map((item) =>
            item.id === action.payload.id ? { ...item, declined: true } : item
          ),
        },
      }

    case APPOINTMENTS.REMOVE:
      return {
        ...state,
        incomingBookings: {
          ...state.incomingBookings,
          list: state.incomingBookings.list.filter(
            (item) => item.id !== action.payload.id
          ),
        },
      }

    case APPOINTMENTS.CANCEL:
      return {
        ...state,
        outgoingBookings: {
          ...state.outgoingBookings,
          list: state.outgoingBookings.list.map((item) =>
            item.id === action.payload.id ? { ...item, canceled: true } : item
          ),
        },
      }

    default:
      return state
  }
}
