import React from "react"
import styled from "@emotion/styled"
import { colors } from "../../../library/constants/styles/colors"
import { devices } from "../../../library/constants/styles/responsive"
import headerImg from "../../../assets/img/pages/info/main/header-calendar.png"
import computerImg from "../../../assets/img/pages/info/main/Computer-Graphic.png"
import learnerImg from "../../../assets/img/pages/info/main/Learners-Kid-Graphic.png"
import memberImg from "../../../assets/img/pages/info/main/Members-Graphic.png"
import educatorImg from "../../../assets/img/pages/info/main/Educators-Top-Graphic.png"
import agentImg from "../../../assets/img/pages/info/main/Agents-Graphic.png"
import { mq } from "../../../theme/globalStyle"
import { Button } from "@material-ui/core"
import { Link } from "react-router-dom"
import {
  ACCOUNTS_INFO_AGENT,
  ACCOUNTS_INFO_EDUCATOR,
  ACCOUNTS_INFO_LEARNER,
  ACCOUNTS_INFO_ORGANIZATION,
} from "../../../library/constants/routes"
import InfoLayout from "../infoLayout"

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: ${({ pt = "60px" }) => pt};
  padding-bottom: ${({ pb = "60px" }) => pb};
  padding-inline: 0;
  background-color: ${({ isDark }) => (isDark ? colors.grey7 : colors.white)};

  @media ${devices.xs} {
    padding-left: 10px;
    padding-right: 10px;
  }
`

export const SectionHeading = styled.h2`
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 15px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  text-align: center;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 70px;
    height: 4px;
    transform: translateX(-50%);
    background-color: ${colors.secondary};
  }
`

const Text = styled.p`
  max-width: 970px;
  text-align: center;
`

const Img = styled.img`
  max-width: calc(970px * 0.8);

  @media ${devices.xs} {
    max-width: 100%;
  }
`

const ImgSection = styled.section`
  width: 100%;
  height: 630px;
  padding-top: 170px;
  background-image: url(${headerImg});
  background-size: cover;
  background-position: center;
  text-align: center;
  font-size: 34px;
  color: ${colors.white};
  text-transform: uppercase;
  line-height: normal;
  font-weight: 500;

  @media ${devices.xs} {
    height: 200px;
    font-size: 28px;
    padding-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const ListContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: ${(props) => props.width || "calc(970px * 0.8)"};
  margin-top: 30px;

  @media ${devices.xs} {
    flex-direction: column;
    width: 100%;
  }
`

export const Ul = styled.ul`
  display: ${(props) => (props.inline ? "flex" : "block")};
  justify-content: space-between;
  width: ${(props) => (props.inline ? "100%" : "auto")};
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-align: left;

  @media ${devices.xs} {
    display: block;
    width: auto;
    margin-bottom: 15px;
  }
`

export const Li = styled.li`
  position: relative;
  padding-left: 16px;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 6px;
    left: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${colors.primary};
  }
`

export const Pointed = styled.div`
  position: relative;
  padding-left: 16px;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${colors.primary};
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;

  ${mq.xs} {
    flex-direction: column;
    align-items: center;
  }

  & > *:first-of-type {
    margin-right: 15px;
    margin-bottom: 0;

    ${mq.xs} {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 970px;
  position: relative;

  ${mq.xs} {
    flex-direction: column;
    width: 100%;
  }

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  & > *:first-of-type {
    padding-right: 45px;
    padding-bottom: 0;

    ${mq.xs} {
      padding-right: 0;
      padding-bottom: 30px;
    }
  }

  & > *:last-of-type {
    padding-left: 45px;
    padding-bottom: 0;

    ${mq.xs} {
      padding-left: 0;
      padding-bottom: 30px;
    }
  }
`

const Col = styled.div`
  display: flex;
  align-items: center;
  width: ${(props) => (props.img ? "40%" : "60%")};
  text-align: center;
  order: initial;

  ${mq.xs} {
    width: ${(props) => (props.img ? "75%" : "100%")};
    order: ${(props) => (props.img ? "-1" : "initial")};
  }
`
const AccountsInfoMain = () => {
  return (
    <InfoLayout>
      <div className={"flexgrid"}>
        <ImgSection>
          <p>Free basic accounts</p>
        </ImgSection>

        <Section>
          <div className="mb60 container">
            <div className="flexgrid full-width">
              <div className="row">
                <div className="col-md-4 full-width-xs col-md-offset-1 mb30xs">
                  <div className="f jcc">
                    <img src={computerImg} style={{ maxHeight: 210 }} />
                  </div>
                </div>
                <div className="col-md-5 col-md-offset-1">
                  <div className="f aic full-height text-center">
                    <div>
                      <SectionHeading>
                        School Intelligent Calendaring
                      </SectionHeading>
                      <Ul>
                        <Li>
                          Create meetings with special features for groups.
                        </Li>
                        <Li>Personalization by pace of learning.</Li>
                        <Li>Create unique schedules.</Li>
                        <Li>
                          Other features include regular meeting scheduling,
                          direct messaging, social posting, featured knowledge
                          products from any tech company or course provider,
                          social badge-giving and school credentialing,
                          bookmarking, academic standards library, and schedules
                          which block time for specific uses and are flexible
                          down to settings for individuals.
                        </Li>
                      </Ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb60 container">
            <div className="full-width f jcse fdc-xs" style={{ gap: 15 }}>
              <div className="f fdc aic f-1">
                <Button
                  component={Link}
                  to={ACCOUNTS_INFO_LEARNER}
                  color={"primary"}
                  variant={"contained"}
                  className={"mb30"}
                >
                  Member
                </Button>
                <div
                  style={{ height: 180 }}
                  className={"f aic mb15xs order-0-xs"}
                >
                  <img
                    src={memberImg}
                    alt="Member plan"
                    style={{ maxHeight: 150 }}
                  />
                </div>
              </div>
              <div className="f fdc aic f-1">
                <Button
                  component={Link}
                  to={ACCOUNTS_INFO_LEARNER}
                  color={"primary"}
                  variant={"contained"}
                  className={"mb30"}
                >
                  Learner
                </Button>
                <img
                  src={learnerImg}
                  alt="Learner plan"
                  style={{ maxHeight: 180 }}
                  className={"mb15xs order-0-xs"}
                />
              </div>
              <div className="f fdc aic f-1">
                <Button
                  component={Link}
                  to={ACCOUNTS_INFO_EDUCATOR}
                  color={"primary"}
                  variant={"contained"}
                  className={"mb30"}
                >
                  Educator
                </Button>
                <img
                  src={educatorImg}
                  alt="Educator plan"
                  style={{ maxHeight: 180 }}
                  className={"mb15xs order-0-xs"}
                />
              </div>
              <div className="f fdc aic f-1">
                <Button
                  component={Link}
                  to={ACCOUNTS_INFO_AGENT}
                  color={"primary"}
                  variant={"contained"}
                  className={"mb30"}
                >
                  Company Representative
                </Button>
                <img
                  src={agentImg}
                  alt="Company Representative plan"
                  style={{ maxHeight: 180 }}
                  className={"mb15xs order-0-xs"}
                />
              </div>
            </div>
          </div>
          <div className="container">
            <ListContainer width={"100%"}>
              <div className="f aic fdc mb30xs">
                <Pointed as={"span"} className={"font-18 mb8"}>
                  Member
                </Pointed>
                <Button
                  component={Link}
                  to={ACCOUNTS_INFO_LEARNER}
                  color={"primary"}
                  variant={"contained"}
                >
                  More info
                </Button>
              </div>

              <div className="f aic fdc mb30xs">
                <Pointed as={"span"} className={"font-18 mb8"}>
                  Learner
                </Pointed>
                <Button
                  component={Link}
                  to={ACCOUNTS_INFO_LEARNER}
                  color={"primary"}
                  variant={"contained"}
                >
                  More info
                </Button>
              </div>

              <div className="f aic fdc mb30xs">
                <Pointed as={"span"} className={"font-18 mb8"}>
                  Educator
                </Pointed>
                <Button
                  component={Link}
                  to={ACCOUNTS_INFO_EDUCATOR}
                  color={"primary"}
                  variant={"contained"}
                >
                  More info
                </Button>
              </div>

              <div className="f aic fdc mb30xs">
                <Pointed as={"span"} className={"font-18 mb8"}>
                  Schools & Districts
                </Pointed>
                <Button
                  component={Link}
                  to={ACCOUNTS_INFO_ORGANIZATION}
                  color={"primary"}
                  variant={"contained"}
                >
                  More info
                </Button>
              </div>

              <div className="f aic fdc">
                <Pointed as={"span"} className={"font-18 mb8"}>
                  Company representative
                </Pointed>
                <Button
                  component={Link}
                  to={ACCOUNTS_INFO_AGENT}
                  color={"primary"}
                  variant={"contained"}
                >
                  More info
                </Button>
              </div>
            </ListContainer>
          </div>
        </Section>
      </div>
    </InfoLayout>
  )
}

export default AccountsInfoMain
