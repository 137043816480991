import React from "react"
import MoreDropdown from "../../../../../../../../../components/ui/dropdowns/moreDropdown/moreDropdown"
import VerticalMenu from "../../../../../../../../../components/ui/verticalMenu"
import { getObjectById } from "../../../../../../../../../library/utils/arrays"
import { calendarGrades } from "../../../../../../../../../library/constants/educationalDictionary"
import { withRouter } from "react-router-dom"
import { PlainButton } from "../../../../../../../../../components/v2/controls"

const SubjectsListItem = ({
  subjectInfo,
  openDeleteSubject,
  openEditSubject,
  groupType,
  isStatic,
  match,
  openSubjectDetails,
}) => {
  const dropdownMenuItems = [
    {
      type: "button",
      action: openEditSubject,
      label: "Edit subject",
    },
    {
      type: "h-divider",
      id: "hd1",
    },
    {
      type: "button",
      action: openDeleteSubject,
      label: "Delete subject",
    },
  ]

  return (
    <div className={"box list-item"}>
      <div className="f aic full-width">
        <div className="list-item__info">
          {/*<PlainLink to={generatePath(*/}
          {/*  groupType === 5 ? routes.GROUP_SETTINGS_DISTRICT_CALENDAR_SUBJECT : routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SUBJECT,*/}
          {/*  {*/}
          {/*    groupId: match.params.groupId,*/}
          {/*    calendarId: match.params.calendarId,*/}
          {/*    subjectId: subjectInfo.id,*/}
          {/*  })}>*/}
          {/*  <span className='list-item__name'>{subjectInfo.name}</span>*/}
          {/*</PlainLink>*/}

          <PlainButton onClick={openSubjectDetails}>
            <span className="list-item__name">{subjectInfo.name}</span>
          </PlainButton>

          <div>
            <span>
              {getObjectById(calendarGrades, subjectInfo.grade).name}{" "}
              {subjectInfo.grade !== 1 && "grade"}
            </span>
          </div>

          {/*{!!subjectInfo.description && (*/}
          {/*  <div className="break-word">*/}
          {/*    <LineSlice text={subjectInfo.description} linesCount={2} />*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      </div>
      {!isStatic && (
        <div className={`list-item__control-panel`}>
          <MoreDropdown>
            <VerticalMenu
              menuItems={dropdownMenuItems}
              classes={"more-dropdown-menu__list"}
            />
          </MoreDropdown>
        </div>
      )}
    </div>
  )
}

export default withRouter(SubjectsListItem)
