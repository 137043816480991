import React, { Component } from "react"
import { generatePath, Link } from "react-router-dom"
import LinesEllipsis from "react-lines-ellipsis"
import * as routes from "../../../../../library/constants/routes"
import { getGroupLogo } from "../../../../../library/utils/getGroupLogo"
import { GROUP_TYPES } from "../../../../../library/constants/groupTypes"
import BookmarkedItemDropdown from "../bookmarkedItemDropdown/bookmarkedItemDropdown"

class BookmarkedGroup extends Component {
  state = {
    deleted: false,
  }

  deleteBookmark = () => {
    const { group } = this.props
    this.props
      .deleteBookmark({ group_id: group.id })
      .then(this.setState({ deleted: true }))
  }

  render() {
    const { group } = this.props
    const { deleted } = this.state

    if (!group || deleted) return null

    const photoUrl = getGroupLogo(group)
    const groupStats =
      !!group.type &&
      `${GROUP_TYPES[group.type].title} (${
        group.closed ? "closed" : "open"
      } group), ${group.participants_number} members`
    const groupUrl = generatePath(routes.GROUP, { groupId: group.id })

    return (
      <div className="box list-item">
        <div className="f aic full-width">
          <Link to={groupUrl} className="list-item__img">
            <img src={photoUrl} alt={group.name} />
          </Link>
          <div className="list-item__info">
            <Link to={groupUrl} className="link list-item__name">
              {group.name}
            </Link>
            <span className="list-item__description">{groupStats}</span>
            {group.description !== "Unknown" && (
              <div className="break-word">
                <LinesEllipsis
                  text={group.description}
                  maxLine="1"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
              </div>
            )}
          </div>
        </div>
        <div className="list-item__control-panel">
          <BookmarkedItemDropdown
            deleteBookmark={this.deleteBookmark}
            bookmarkFoldersList={() =>
              this.props.bookmarkFoldersList(group.bookmark.id)
            }
            addToFolder={(folders) =>
              this.props.addToFolder({
                bookmark_id: group.bookmark.id,
                folders,
              })
            }
            bookmarksFoldersListClear={() =>
              this.props.bookmarksFoldersListClear()
            }
          />
        </div>
      </div>
    )
  }
}

export default BookmarkedGroup;
