import React from "react"
import { useBooking, useBookingMutations } from "./useBooking"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Loader from "../../../../../components/ui/loader"
import UserLink from "../../../../../components/v2/utils/UserLink"
import MeetDateTimeFromUtc from "../../../../../components/v2/utils/MeetDateTimeFromUtc"
import Button from "@material-ui/core/Button/Button"
import { APPOINTMENTS } from "../../../../../library/store/actions/types/calendarTypes"
import { isLast } from "../../../../../library/utils/arrays"

const AppointmentBookingDialog = ({
  activeBookingId,
  open,
  onClose,
  bookingType,
}) => {
  const { booking, isSuccess } = useBooking(activeBookingId, bookingType)

  const { accept, decline, cancel, forward } = useBookingMutations([
    APPOINTMENTS.GET,
    { id: activeBookingId },
  ])

  return (
    <Dialog
      aria-labelledby="create-record-dialog"
      onClose={onClose}
      open={open}
    >
      <DialogTitle id="alert-dialog-title" className="text-center">
        Appointment booking details
      </DialogTitle>
      <DialogContent>
        {isSuccess ? (
          <div className="f jcc full-width">
            <table className="product-details product-details--invenstory-record">
              <tbody>
                <tr className="product-details__row">
                  <td className="product-details__label">Name</td>
                  <td className="product-details__value">{booking.title}</td>
                </tr>

                {bookingType === "book_appointments" &&
                  (booking.b_creator ? (
                    <tr className="product-details__row">
                      <td className="product-details__label">Invitee</td>
                      <td className="product-details__value">
                        <div>
                          <div className={"mb10"}>
                            {booking.invitee_id ? (
                              <UserLink
                                user={{
                                  id: booking.invitee_id,
                                  first_name: booking.invitee_name_first,
                                  last_name: booking.invitee_name_last,
                                }}
                              />
                            ) : (
                              <span>
                                {booking.invitee_name_first}{" "}
                                {booking.invitee_name_last}
                              </span>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr className="product-details__row">
                      <td className="product-details__label">Inviter</td>
                      <td className="product-details__value">
                        <div>
                          <div className={"mb10"}>
                            {booking.creator_id ? (
                              <UserLink
                                user={{
                                  id: booking.creator_id,
                                  first_name: booking.name_first,
                                  last_name: booking.name_last,
                                }}
                              />
                            ) : (
                              <span>
                                {booking.name_first} {booking.name_last}
                              </span>
                            )}
                          </div>
                          <div className={"mb10"}>
                            <span>Email: </span>
                            <a
                              href={`mailto:${booking.email}`}
                              className={"link link--inline"}
                            >
                              {booking.email}
                            </a>
                          </div>
                          <div>
                            <span>Phone: </span>
                            <a
                              href={`tel:${booking.phone}`}
                              className={"link link--inline"}
                            >
                              {booking.phone}
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}

                {!!booking.invitees?.length && (
                  <tr className="product-details__row">
                    <td className="product-details__label">
                      {bookingType === "book_appointments"
                        ? "Other invitees"
                        : "Invitees"}
                    </td>
                    <td className="product-details__value">
                      <div>
                        {booking.invitees.map((invitee, idx) => {
                          const className = !isLast(booking.invitees, idx)
                            ? "mb10"
                            : ""
                          if (!invitee.id) {
                            return (
                              <div key={idx} className={className}>
                                <p>
                                  {invitee.name_first} {invitee.name_last}
                                </p>
                                <p className={"color-black-54 font-13"}>
                                  {invitee.email}
                                </p>
                              </div>
                            )
                          }

                          return (
                            <div key={idx} className={className}>
                              <UserLink
                                user={{
                                  id: invitee.id,
                                  first_name: invitee.name_first,
                                  last_name: invitee.name_last,
                                }}
                              />
                            </div>
                          )
                        })}
                      </div>
                    </td>
                  </tr>
                )}

                <tr className="product-details__row">
                  <td className="product-details__label">Date and time</td>
                  <td className="product-details__value">
                    {bookingType === "book_appointments" ? (
                      <div>
                        <div className={"mb10"}>
                          <p>For you:</p>
                          <MeetDateTimeFromUtc
                            date={booking.date_from}
                            duration={Number(booking.duration)}
                            activeTimezone={
                              Intl.DateTimeFormat().resolvedOptions().timeZone
                            }
                          />
                        </div>
                        <div>
                          <p>For {booking?.creator?.name_first}:</p>
                          <MeetDateTimeFromUtc
                            date={booking.date_from}
                            duration={Number(booking.duration)}
                            activeTimezone={booking.time_zone}
                          />
                        </div>
                      </div>
                    ) : (
                      "To be calculated"
                    )}
                  </td>
                </tr>

                <tr className="product-details__row">
                  <td className="product-details__label">Appointment type</td>
                  <td className="product-details__value">
                    {booking.duration} min
                  </td>
                </tr>

                {!!booking.conference_link && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Conference link</td>
                    <td className="product-details__value">
                      <a href={booking.conference_link} className={"link"}>
                        {booking.conference_link}
                      </a>
                    </td>
                  </tr>
                )}

                {!!booking.location && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Location</td>
                    <td className="product-details__value">
                      {booking.location}
                    </td>
                  </tr>
                )}

                {!!booking.details && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Message</td>
                    <td className="product-details__value">
                      {booking.details}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <Loader />
        )}
      </DialogContent>
      <DialogActions>
        <div className="f jcsb full-width">
          <div>
            {isSuccess &&
              (booking.b_creator ? (
                <Button
                  color="primary"
                  onClick={() =>
                    cancel.mutateAsync({ id: booking.id, bookingType })
                  }
                >
                  Cancel
                </Button>
              ) : (
                <>
                  {!(booking?.is_accepted || booking?.is_declined) && (
                    <>
                      <Button
                        color="primary"
                        onClick={() =>
                          forward.mutateAsync({ id: booking.id, bookingType })
                        }
                      >
                        Push forward
                      </Button>
                      <Button
                        color="primary"
                        onClick={() =>
                          accept.mutateAsync({ id: booking.id, bookingType })
                        }
                      >
                        Accept
                      </Button>
                      <Button
                        color="primary"
                        onClick={() =>
                          decline.mutateAsync({ id: booking.id, bookingType })
                        }
                      >
                        Decline
                      </Button>
                    </>
                  )}
                </>
              ))}
          </div>
          <Button color="primary" onClick={onClose}>
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default AppointmentBookingDialog
