import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import TableSearch from "../tableControls/tableSearch/tableSearch"
import Button from "@material-ui/core/Button"
import TableColumnsSettings from "../tableColumnsSettings/tableColumnsSettings"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import { ValidatorForm } from "react-material-ui-form-validator"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import Select from "@material-ui/core/Select"
import { getObjectById } from "../../../library/utils/arrays"
import {
  grades as gradesList,
  subjects as subjectsList,
} from "../../../library/constants/educationalDictionary"
import MenuItem from "@material-ui/core/MenuItem"
import ViewComfyOutlinedIcon from "@material-ui/icons/ViewComfyOutlined"
import ViewListOutlinedIcon from "@material-ui/icons/ViewListOutlined"
import { devices } from "../../../library/constants/styles/responsive"
import { Tooltip, withStyles } from "@material-ui/core"
import MultiSelect from "../../ui/multiSelect"
import Loader from "../../ui/loader"

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 5px;

  @media ${devices.xs} {
    flex-direction: column;
    align-items: center;

    .search-form {
      margin-bottom: 15px;
      width: 100%;
      max-width: 100%;
    }
  }
`

const Label = styled.label`
    display: flex;
    align-items: center;
`;

const TableHeader = (props) => {
    const [openColumnsSettings, setOpenColumnsSettings] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const handleClickOpen = (type) => {
        switch (type) {
            case "settings":
                setOpenColumnsSettings(true);
                break;

            case "filter":
                setOpenFilter(true);
                break;

            default:
                return;
        }
    };

    const handleClose = async (type) => {
        switch (type) {
            case "settings":
                setOpenColumnsSettings(false);
                break;

            case "filter":
                setOpenFilter(false);
                break;

            default:
                return;
        }
    };

    return (
        <Container>
            <TableSearch
                processTableSearch={props.processTableSearch}
                tableSearchQuery={props.tableSearchQuery}
            />

            <div className="btn-group fdc-xs">
                <div className="btn-group fdc-xs">
                    <Tooltip title={"Switch to Tile view"} arrow>
                        <ViewComfyOutlinedIcon style={{fontSize: "35px"}}
                                               className={props.view === "tile" ? "color-primary" : "color-black-38"}
                                               onClick={() => {
                                                   props.switchView("tile");
                                               }}/>
                    </Tooltip>
                    <Tooltip title={"Switch to Table view"} arrow>
                        <ViewListOutlinedIcon style={{fontSize: "35px"}}
                                              className={props.view === "table" ? "color-primary" : "color-black-38"}
                                              onClick={() => props.switchView("table")}/>
                    </Tooltip>
                </div>
                <Button variant="outlined" color="primary" onClick={() => handleClickOpen("settings")}>Customize
                    columns</Button>
                <Button variant="outlined" color="primary" onClick={() => handleClickOpen("filter")}>Filter
                    columns</Button>
            </div>

            <TableColumnsSettings
                open={openColumnsSettings}
                handleClose={() => handleClose("settings")}
                setColumns={props.setColumns}
                columns={props.columns}
                selectedColumns={props.selectedColumns}
            />
            <FilterModal
                setFilterValues={props.setFilterValues}
                open={openFilter}
                onClose={_ => handleClose("filter")}
                standards={props.standards}
                standardsDataReceived={props.standardsDataReceived}
                standardsName={props.standardsName}
                onStandardsScroll={props.onStandardsScroll}
                setStandardsName={props.setStandardsName}
            />
        </Container>
    );
};

const CustomDialog = withStyles({
    paper: {
        overflowY: "visible",
    },
})(Dialog);

const CustomDialogContent = withStyles({
    root: {
        overflowY: "visible",
    },
})(DialogContent);


const FilterModal = (props) => {
    const [filterValues, setFilterValues] = useState({
        grades: [],
        subjects: [],
        standards: [],
    });

    const [tempFilter, setTempFilter] = useState({
        grades: [],
        subjects: [],
        standards: [],
    });

    const setGrades = e => {
        const newFilter = {...tempFilter, grades: e.target.value};
        setTempFilter(newFilter);
    };

    const setSubjects = e => {
        const newFilter = {...tempFilter, subjects: e.target.value};
        setTempFilter(newFilter);
    };

    const setStandards = standards => {
        const newFilter = {...tempFilter, standards};
        setTempFilter(newFilter);
    };

    const handleClose = () => {
        setTempFilter(filterValues);
        props.onClose();
    };

    const processSave = () => {
        setFilterValues(tempFilter);
    };

    useEffect(() => {
        props.setFilterValues(filterValues);
        props.onClose();
    }, [filterValues]);

    return (
        <CustomDialog
            aria-labelledby="table-columns-settings"
            onClose={props.onClose}
            open={props.open}
            style={{
                height: "100%",
            }}
        >

            <DialogTitle style={{textAlign: "center"}}>Filter columns</DialogTitle>
            <ValidatorForm onSubmit={processSave}>
                <CustomDialogContent>
                    <div className="form form--modal">
                        <div className="form__fieldset">
                            <Label htmlFor="subject" className="form__label">Subjects</Label>
                            <div className="form__fieldbox">
                                <div className="form__input form__input--select">
                                    <Select
                                        id="subject"
                                        name="subject"
                                        fullWidth
                                        multiple
                                        displayEmpty
                                        value={tempFilter.subjects}
                                        onChange={setSubjects}
                                        renderValue={selected => {
                                            if (selected.length === 0) {
                                                return (
                                                    <span className="color-black-38">
                                                    Select subjects
                                                </span>
                                                );
                                            }

                                            let selectedNames = selected.map(item => {
                                                return getObjectById(subjectsList, item).name;
                                            });

                                            return selectedNames.join(", ");
                                        }}
                                    >
                                        {subjectsList.map(item => (
                                            <MenuItem value={item.id} key={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="form__fieldset">
                            <Label htmlFor="grade" className="form__label">Grades</Label>
                            <div className="form__fieldbox">
                                <div className="form__input form__input--select">
                                    <Select
                                        id="grade"
                                        name="grade"
                                        fullWidth
                                        multiple
                                        displayEmpty
                                        value={tempFilter.grades}
                                        onChange={setGrades}
                                        renderValue={selected => {
                                            if (selected.length === 0) {
                                                return (
                                                    <span className="color-black-38">
                                                Select grades
                                            </span>
                                                );
                                            }

                                            let selectedNames = selected.map(item => {
                                                return getObjectById(gradesList, item).name;
                                            });

                                            return selectedNames.join(", ");
                                        }}
                                    >
                                        {gradesList.map(item => (
                                            <MenuItem value={item.id} key={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </div>

                        <div className="form__fieldset">
                            <Label className="form__label">
                                Standards
                            </Label>
                            <div className="form__fieldbox">
                                <div className="form__input form__input--select">
                                    <MultiSelect
                                        isMulti
                                        list={props.standards}
                                        placeholder="Select standards"
                                        defaultValue={tempFilter.standards}
                                        onScroll={props.onStandardsScroll}
                                        onChange={setStandards}
                                        onInputChange={props.setStandardsName}
                                        emptyArrayMessage={props.standardsDataReceived ? <span>No results</span> :
                                            <Loader/>}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </CustomDialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button type="submit" color="primary">
                        Save
                    </Button>
                </DialogActions>
            </ValidatorForm>
        </CustomDialog>
    );
};


export default TableHeader;
