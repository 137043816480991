import React from "react"
import { useGroup } from "../../../../../hooks/data/groups/groups"
import GroupPageSkeleton from "../../../../../components/v2/layout/skeleton/groupPage/groupPageSkeleton"
import NotFound from "../../../../../components/notFound"
import GroupError from "../../groupError/groupError"
import {
  PageContentLayout,
  PageMain,
  PageRightSidebar,
} from "../../../../../components/v2/layout/layout"
import SEO from "../../../../../components/SEO"
import GroupPageHeader from "./components/GroupPageHeader"
import { Box } from "../../../../../components/tw/layout"
import GroupLogo from "./components/GroupLogo"
import GroupSidebarControls from "./components/GroupSidebarControls"
import { Route } from "react-router-dom"
import {
  GROUP_POST,
  GROUP_PUBLIC_CALENDAR,
} from "../../../../../library/constants/routes"
import PostModal from "../../../feed/postModal/postModal"
import CalendarProvider from "../../../calendar/CalendarContext"
import PublicCalendar from "../publicCalendar/publicCalendar"
import GroupSidebarInfo from "./components/GroupSidebarInfo"
import GroupAttachments from "./components/GroupAttachments"
import GroupFeed from "./components/GroupFeed"
import { useSelector } from "react-redux"

const GroupPage = () => {
  const { isLoggedIn } = useSelector(({ auth }) => auth)

  const {
    group,
    isSuccess,
    isLoading,
    error,
    isAvailable,
    canEdit,
    isEducational,
  } = useGroup()

  if (!isSuccess && isLoading) {
    return <GroupPageSkeleton />
  }

  if (error?.error?.code === 23000) {
    return <NotFound entity={"Group"} />
  }

  if (!isAvailable) {
    return <GroupError group={group} />
  }

  const showAttachments =
    isLoggedIn &&
    group.is_member &&
    !!(
      group.images.length ||
      group.videos.length ||
      group.blobs.length ||
      group.links.length ||
      group.products_number
    )

  return (
    <PageContentLayout>
      <SEO title={group.name} description={group.description} />
      <PageMain>
        <GroupPageHeader />
        {showAttachments && <GroupAttachments />}

        {isLoggedIn && <GroupFeed />}
      </PageMain>
      <PageRightSidebar>
        <GroupLogo />
        {isLoggedIn && <GroupSidebarControls />}
        <GroupSidebarInfo />
      </PageRightSidebar>

      <Route
        path={GROUP_PUBLIC_CALENDAR}
        render={(props) => (
          <CalendarProvider>
            <PublicCalendar
              {...props}
              hasAccessToCalendar={group.is_member && isEducational}
            />
          </CalendarProvider>
        )}
      />
    </PageContentLayout>
  )
}

export default GroupPage
