import React, { Component } from "react"
import Loader from "../../../../../components/ui/loader/loader"
import { bindActionCreators } from "redux"
import { foldersList } from "../../../../../library/store/actions/creators/bookmarksCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import getErrorText from "../../../../../library/constants/errorTypes"
import { generatePath, withRouter } from "react-router-dom"
import * as routes from "../../../../../library/constants/routes"
import VerticalMenu from "../../../../../components/ui/verticalMenu/verticalMenu"
import VerticalMenuCollapsible from "../../../../../components/ui/VerticalMenuCollapsible/verticalMenuCollapsible"
import Button from "@material-ui/core/Button/Button"
import CreateEditFolder from "../createEditFolder/createEditFolder"
import DeleteForeverOutlined from "@material-ui/icons/DeleteForeverOutlined"
import EditOutlined from "@material-ui/icons/EditOutlined"
import DeleteFolder from "../deleteFolder/deleteFolder"
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText"
import { flatten } from "lodash"

class FoldersList extends Component {
  state = {
    open: false,
    createEditDialogOpen: false,
    editDialogOpen: false,
    deleteDialogOpen: false,
    activeDialogType: "",
    selectedFolder: null,
    dataReceived: false,
  }

  async componentDidMount() {
    try {
      await this.props.foldersList()
      this.setState({ dataReceived: true })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.bookmarks.currentFolder !==
        prevProps.bookmarks.currentFolder ||
      this.props.match.params !== prevProps.match.params ||
      this.props.match.path !== prevProps.match.path
    ) {
      this.setState({ dataReceived: false })
      this.composeList()
      this.setState({ dataReceived: true })
    }
  }

  openDialog = (type, folder) => {
    switch (type) {
      case "create":
        this.setState({
          createEditDialogOpen: true,
          activeDialogType: "create",
        })
        break

      case "edit":
        this.setState({
          createEditDialogOpen: true,
          activeDialogType: "edit",
          selectedFolder: folder,
        })
        break

      case "delete":
        this.setState({
          deleteDialogOpen: true,
          activeDialogType: "delete",
          selectedFolder: folder,
        })
        break

      default:
        return
    }
  }

  handleClose = (type) => {
    switch (type) {
      case "create":
        this.setState({
          createEditDialogOpen: false,
          activeDialogType: "",
          selectedFolder: null,
        })
        break

      case "edit":
        this.setState({
          createEditDialogOpen: false,
          activeDialogType: "",
          selectedFolder: null,
        })
        break

      case "delete":
        this.setState({
          deleteDialogOpen: false,
          activeDialogType: "",
          selectedFolder: null,
        })
        break

      default:
        return
    }
  }

  composeControls = (folder) => {
    return (
      <div className="menu__item-controls">
        <EditOutlined onClick={() => this.openDialog("edit", folder)} />
        <DeleteForeverOutlined
          onClick={() => this.openDialog("delete", folder)}
        />
      </div>
    )
  }

  composeList = () => {
    const {
      bookmarks: { folders, currentFolder },
      match: { params },
    } = this.props

    return flatten(
      folders.map((item) => {
        return [
          {
            label: item.name,
            path: generatePath(routes.FOLDER, { folderId: item.id }),
            type: "child",
            controls: this.composeControls(item),
          },
          this.props.showList &&
            !!currentFolder &&
            currentFolder.users_number !== 0 && {
              label: "People",
              type: "child2",
              path: generatePath(routes.FOLDER_PEOPLE, { folderId: item.id }),
              key: generatePath(routes.FOLDER_PEOPLE, { folderId: item.id }),
              hidden: parseInt(params.folderId) !== item.id,
            },
          this.props.showList &&
            !!currentFolder &&
            currentFolder.groups_number !== 0 && {
              label: "Groups",
              type: "child2",
              path: generatePath(routes.FOLDER_GROUPS, { folderId: item.id }),
              key: generatePath(routes.FOLDER_GROUPS, { folderId: item.id }),
              hidden: parseInt(params.folderId) !== item.id,
            },
          this.props.showList &&
            !!currentFolder &&
            currentFolder.posts_number !== 0 && {
              label: "Posts",
              type: "child2",
              path: generatePath(routes.FOLDER_POSTS, { folderId: item.id }),
              key: generatePath(routes.FOLDER_POSTS, { folderId: item.id }),
              hidden: parseInt(params.folderId) !== item.id,
            },
          this.props.showList &&
            !!currentFolder &&
            currentFolder.knowcreds_number !== 0 && {
              label: "Creds",
              type: "child2",
              path: generatePath(routes.FOLDER_KNOWCRED, { folderId: item.id }),
              key: generatePath(routes.FOLDER_KNOWCRED, { folderId: item.id }),
              hidden: parseInt(params.folderId) !== item.id,
            },
          // this.props.showList &&
          //   !!currentFolder &&
          //   currentFolder.products_number !== 0 && {
          //     label: "Products",
          //     type: "child2",
          //     path: generatePath(routes.FOLDER_PRODUCTS, { folderId: item.id }),
          //     key: generatePath(routes.FOLDER_PRODUCTS, { folderId: item.id }),
          //     hidden: parseInt(params.folderId) !== item.id,
          //   },
          // this.props.showList &&
          //   !!currentFolder &&
          //   currentFolder.records_number !== 0 && {
          //     label: "Records",
          //     type: "child2",
          //     path: generatePath(routes.FOLDER_RECORDS, { folderId: item.id }),
          //     key: generatePath(routes.FOLDER_RECORDS, { folderId: item.id }),
          //     hidden: parseInt(params.folderId) !== item.id,
          //   },
          this.props.showList &&
            !!currentFolder &&
            currentFolder.standards_number !== 0 && {
              label: "Standards",
              type: "child2",
              path: generatePath(routes.FOLDER_STANDARDS, {
                folderId: item.id,
              }),
              key: generatePath(routes.FOLDER_STANDARDS, { folderId: item.id }),
              hidden: parseInt(params.folderId) !== item.id,
            },
        ]
      })
    )
  }

  composeMenuItems = () => {
    return <VerticalMenu menuItems={this.composeList()} collapsible />
  }

  composeMenuFooter = () => {
    return (
      <div className={`f fdc jcc`}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => this.openDialog("create")}
          disabled={this.props.bookmarks.folders.length >= 15}
        >
          Create folder
        </Button>
        {this.props.bookmarks.folders.length >= 15 && (
          <div className="flex-centered-x">
            <FormHelperText>You can have maximum 15 folders</FormHelperText>
          </div>
        )}
      </div>
    )
  }

  render() {
    const {
      createEditDialogOpen,
      deleteDialogOpen,
      selectedFolder,
      activeDialogType,
      dataReceived,
    } = this.state

    return dataReceived ? (
      <>
        <VerticalMenuCollapsible
          open={true}
          title="Folders"
          body={this.composeMenuItems()}
          footer={this.composeMenuFooter()}
        />
        {createEditDialogOpen && (
          <CreateEditFolder
            open={createEditDialogOpen}
            onClose={(type) => this.handleClose(type)}
            folder={selectedFolder}
            type={activeDialogType}
          />
        )}
        {deleteDialogOpen && (
          <DeleteFolder
            open={deleteDialogOpen}
            onClose={() => this.handleClose("delete")}
            folder={selectedFolder}
          />
        )}
      </>
    ) : (
      <Loader />
    )
  }
}

const mapStateToProps = ({ auth, bookmarks }) => ({ auth, bookmarks })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ foldersList }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(FoldersList)))
