import React from "react"
import { Box } from "../../../../../../components/tw/layout"
import { useGroup } from "../../../../../../hooks/data/groups/groups"
import Button from "@material-ui/core/Button"
import { useToggle } from "../../../../../../hooks/useToggle"
import SaveEventToCalendar from "../../SaveEventToCalendar"
import { generatePath, Link, useParams } from "react-router-dom"
import SettingsOutlined from "@material-ui/icons/SettingsOutlined"
import * as routes from "../../../../../../library/constants/routes"
import HowToRegIcon from "@material-ui/icons/HowToReg"
import PlainLink from "../../../../../../components/ui/link"
import {
  BOOKMARKS_GROUPS,
  CONTACT_FORM,
  GROUP_PUBLIC_CALENDAR,
  GROUP_SETTINGS,
  GROUP_SETTINGS_KNOWMIX,
} from "../../../../../../library/constants/routes"
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined"
import EntityOperationsBox from "../../../../../../components/v2/controls/entityOperationsBox/entityOperationsBox"

const GroupSidebarControls = () => {
  const params = useParams()
  const {
    group,
    canEdit,
    isSchool,
    isEvent,
    isGovEducational,
    isEducational,
    isDistrict,
    isAnySchool,
    queryKey,
  } = useGroup()

  const [saveEventToCalendarOpen, toggleSaveEventToCalendar] = useToggle(false)

  const settingsRoute = isAnySchool ? GROUP_SETTINGS_KNOWMIX : GROUP_SETTINGS

  return (
    <>
      {(group.is_member || canEdit) && isEvent && (
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={toggleSaveEventToCalendar}
        >
          Save to Calendar
        </Button>
      )}
      {saveEventToCalendarOpen && (
        <SaveEventToCalendar
          open={saveEventToCalendarOpen}
          onClose={toggleSaveEventToCalendar}
          group={group}
        />
      )}

      <Box>
        {canEdit ? (
          <PlainLink to={generatePath(settingsRoute, params)}>
            <SettingsOutlined className="text-black38 mr-2" /> Manage
          </PlainLink>
        ) : (
          <PlainLink to={CONTACT_FORM}>
            <HowToRegIcon className="text-black38 mr-2" /> Claim admin rights
          </PlainLink>
        )}
      </Box>
      {group.is_member && isEducational && (
        <Box>
          <PlainLink to={generatePath(GROUP_PUBLIC_CALENDAR, params)}>
            <DateRangeOutlinedIcon className="text-black38 mr-2" /> Show group
            calendar
          </PlainLink>
        </Box>
      )}
      <EntityOperationsBox
        queryKey={queryKey}
        enableShares={true}
        isShared={group.is_shared}
        revalidateAfterShare={true}
        enableBookmarks={true}
        isBookmarked={!!group.bookmark}
        pathToBookmarks={BOOKMARKS_GROUPS}
        enableReports={true}
        canReport={!canEdit}
        isReported={group?.is_reported}
        operationParams={{ group_id: group?.id }}
      />
    </>
  )
}

export default GroupSidebarControls
