import React from "react"
import PostSkeleton from "./postSkeleton"

const PostsListSkeleton = ({ postsNumber = 5 }) => {
  return (
    <>
      {new Array(postsNumber).fill(1).map((p, i) => (
        <PostSkeleton key={i} />
      ))}
    </>
  )
}

export default PostsListSkeleton;
