import React, { useEffect, useState } from "react"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import Dialog from "@material-ui/core/Dialog"
import Button from "@material-ui/core/Button/Button"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import { useParams, withRouter } from "react-router-dom"
import { withSnackbar } from "notistack"
import { bindActionCreators } from "redux"
import {
  clearCurrentCalendar,
  createCalendar,
  createFullSchedule,
  createPrivateSchoolSchedule,
  getCalendar,
  updateCalendar,
  updateFullSchedule,
  updatePrivateSchoolSchedule,
} from "../../../../../../library/store/actions/creators/calendarCreators"
import { connect, useDispatch, useSelector } from "react-redux"
import Loader from "../../../../../../components/ui/loader"
import * as Yup from "yup"
import { Formik } from "formik"
import Form from "../../../../../../components/v2/layout/forms/form/form"
import { useAlert } from "../../../../../../hooks/useAlert"
import { call } from "../../../../../../library/networking/API"
import {
  CALENDARS,
  SCHEDULE,
} from "../../../../../../library/store/actions/types/calendarTypes"

const validationSchema = Yup.object({
  name: Yup.string().min(3).max(200).required(),
  date_from: Yup.date().nullable().required(),
  date_to: Yup.date().nullable().required(),
})

export const getSchoolYears = (yearsNumber) => {
  // const lastYear =
  //   new Date().getMonth() < 6
  //     ? new Date().getFullYear() - 1
  //     : new Date().getFullYear()

  const lastYear = new Date().getFullYear() - 1

  return Array(yearsNumber)
    .fill("")
    .map((_, i) => lastYear + i)
    .map((y) => ({ label: `${y} — ${y + 1}`, value: y }))
}

const CalendarFormModal = ({
  open,
  onClose,
  edit,
  activeCalendar,
  groupType,
}) => {
  const dispatch = useDispatch()
  const params = useParams()
  const calendar = useSelector(({ calendar }) => calendar)
  const { errorAlert } = useAlert()
  const [isDataReady, setIsDataReady] = useState(!edit)

  const [initialValues, setInitialValues] = useState({
    name: "",
    date_from: null,
    date_to: null,
  })

  const getData = async () => {
    let response, data

    try {
      switch (groupType) {
        case 4:
        case 7:
          response = await call(SCHEDULE.SCHEDULES.GET, { id: activeCalendar })
          break

        default:
          response = await call(CALENDARS.GET, { id: activeCalendar })
          break
      }

      data = await response.data

      setInitialValues({
        name: data.name,
        date_from: new Date(data.date_from),
        date_to: new Date(data.date_to),
      })
      setIsDataReady(true)
    } catch (error) {
      errorAlert(error)
    }
  }

  useEffect(async () => {
    if (edit) {
      await getData()
    }
  }, [])

  const onSubmit = async (values) => {
    const { name, date_from, date_to } = values

    const data = {
      id: edit ? activeCalendar : undefined,
      name,
      date_from: date_from.toISOString(),
      date_to: date_to.toISOString(),
    }

    const districtCalendarData = {
      district_id: !edit ? parseInt(params.groupId, 10) : undefined,
      ...data,
    }

    const schoolScheduleData = {
      school_id: !edit ? parseInt(params.groupId, 10) : undefined,
      ...data,
    }

    try {
      if (activeCalendar) {
        switch (groupType) {
          case 4:
            await dispatch(updateFullSchedule(schoolScheduleData))
            console.log(schoolScheduleData)
            break

          case 5:
            await dispatch(updateCalendar(districtCalendarData))
            console.log(districtCalendarData)
            break

          case 7:
            await dispatch(updatePrivateSchoolSchedule(schoolScheduleData))
            console.log(schoolScheduleData)
            break

          default:
            break
        }
      } else {
        switch (groupType) {
          case 4:
            await dispatch(createFullSchedule(schoolScheduleData))
            console.log(schoolScheduleData)
            break

          case 5:
            await dispatch(createCalendar(districtCalendarData))
            console.log(districtCalendarData)
            break

          case 7:
            await dispatch(createPrivateSchoolSchedule(schoolScheduleData))
            console.log(schoolScheduleData)
            break

          default:
            break
        }
      }

      // console.log(data)
      onClose()
    } catch (error) {
      errorAlert(error)
    }
  }

  return (
    <Dialog
      aria-labelledby="calendar-form-dialog"
      onClose={onClose}
      open={open}
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title" className="text-center">
        {edit ? "Edit Calendar" : "New Calendar"}
      </DialogTitle>

      {isDataReady ? (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(fk) => (
            <>
              <DialogContent>
                <Form isModal>
                  <Form.Row name={"name"} label={"Name *"}>
                    <Form.TextField
                      name={"name"}
                      placeholder={"Type calendar title"}
                    />
                  </Form.Row>
                  {/*<Form.Row name={'school_year'} label={'School year *'}>*/}
                  {/*  <Form.Select*/}
                  {/*    name={'school_year'}*/}
                  {/*    placeholder={'Select school year'}*/}
                  {/*    options={[...getSchoolYears(5)]}*/}
                  {/*  />*/}
                  {/*</Form.Row>*/}
                  <Form.Row name={"date_from"} label={"Start date *"}>
                    <Form.DatePicker
                      name={"date_from"}
                      placeholder={"Select start date"}
                      maxDate={fk.values.date_to}
                    />
                  </Form.Row>
                  <Form.Row name={"date_to"} label={"End date *"}>
                    <Form.DatePicker
                      name={"date_to"}
                      placeholder={"Select end date"}
                      minDate={fk.values.date_form}
                    />
                  </Form.Row>
                  {/*<Form.Row*/}
                  {/*  name={'spring_break_date_from'}*/}
                  {/*  label={'Spring break start *'}*/}
                  {/*>*/}
                  {/*  <Form.DatePicker*/}
                  {/*    name={'spring_break_date_from'}*/}
                  {/*    placeholder={'Select spring break start date'}*/}
                  {/*    maxDate={fk.values.spring_break_date_to}*/}
                  {/*  />*/}
                  {/*</Form.Row>*/}
                  {/*<Form.Row*/}
                  {/*  name={'spring_break_date_to'}*/}
                  {/*  label={'Spring break end *'}*/}
                  {/*>*/}
                  {/*  <Form.DatePicker*/}
                  {/*    name={'spring_break_date_to'}*/}
                  {/*    placeholder={'Select spring break end date'}*/}
                  {/*    minDate={fk.values.spring_break_date_from}*/}
                  {/*  />*/}
                  {/*</Form.Row>*/}
                  {/*<Form.Row*/}
                  {/*  name={'fall_break_date_from'}*/}
                  {/*  label={'Fall break start *'}*/}
                  {/*>*/}
                  {/*  <Form.DatePicker*/}
                  {/*    name={'fall_break_date_from'}*/}
                  {/*    placeholder={'Select fall break start date'}*/}
                  {/*    maxDate={fk.values.fall_break_date_to}*/}
                  {/*  />*/}
                  {/*</Form.Row>*/}
                  {/*<Form.Row*/}
                  {/*  name={'fall_break_date_to'}*/}
                  {/*  label={'Fall break end *'}*/}
                  {/*>*/}
                  {/*  <Form.DatePicker*/}
                  {/*    name={'fall_break_date_to'}*/}
                  {/*    placeholder={'Select fall break end date'}*/}
                  {/*    minDate={fk.values.fall_break_date_from}*/}
                  {/*  />*/}
                  {/*</Form.Row>*/}
                </Form>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={fk.handleSubmit} color="primary">
                  Save
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      ) : (
        <Loader />
      )}
    </Dialog>
  )
}

const mapStateToProps = ({ auth, groups, calendar }) => ({
  auth,
  groups,
  calendar,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getCalendar, clearCurrentCalendar }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(CalendarFormModal)))
