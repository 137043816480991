import React from "react"
import StaticDataSelect from "../v2/inputs/select/staticDataSelect"
import { useTimezone } from "../../hooks/useTimezone"

const TimezonePicker = ({ value, onChange }) => {
  const { currentTz, tzList } = useTimezone()

  const [selectedValue, setSelectedValue] = React.useState(value)

  React.useEffect(() => {
    if (!value) {
      const suggestedTz = tzList.find((tz) => tz.id === currentTz)
      setSelectedValue(suggestedTz)
      onChange(suggestedTz)
    }
  }, [])

  const handleChange = (tz) => {
    setSelectedValue(tz)
    onChange(tz)
  }

  return (
    <StaticDataSelect
      data={tzList}
      value={selectedValue}
      onChange={handleChange}
      leftLabel={false}
      entityName={"Timezone"}
      fieldName={"timezone"}
    />
  )
}

export default TimezonePicker
