import { KNOWMIX } from "../actions/types/knowmixTypes"
import { LIMIT } from "../../constants/limits"
import { StateComposer } from "../../utils/reducerUtils/reducerUtils"

const initialState = {
  list: [],
  currentKnowmix: null,
  stepsList: [],
  currentWork: null,
  currentGrade: null,
  stepProductsSearch: [],
  productsSearchComplete: false,
  examinersList: [],
  examinersListEndReached: false,
  works: [],
  worksKnowmixes: [],
  worksCount: null,
  worksKnowmixesCount: null,
  gradesStepsList: [],
  isExaminer: false,
  examiners_number: null,

  ...StateComposer.initStateArrays(["membersList", "membersListSearch"]),
}

export const knowmixReducer = (state = initialState, action) => {
  const composer = new StateComposer(state, action)

  switch (action.type) {
    case KNOWMIX.GET:
      return {
        ...state,
        currentKnowmix: action.payload,
      }

    case KNOWMIX.STEPS.UPDATE:
      return {
        ...state,
        stepsList: state.stepsList.map((item) => {
          return item.id === action.payload.step.id ? action.payload.step : item
        }),
      }

    case KNOWMIX.STEPS.PRODUCTS.SEARCH:
      return {
        ...state,
        // stepProductsSearch: action.payload
        stepProductsSearch: state.stepProductsSearch.length
          ? [...state.stepProductsSearch, ...action.payload]
          : action.payload,
        productsSearchComplete: !action.payload.length,
      }

    case KNOWMIX.STEPS.PRODUCTS.CLEAR:
      return {
        ...state,
        stepProductsSearch: [],
        productsSearchComplete: false,
      }

    case KNOWMIX.STEPS.PRODUCTS.LIST:
      return {
        ...state,
        stepsList: state.stepsList.map((item) => {
          return item.id === action.payload.stepId
            ? { ...item, products: action.payload.products }
            : item
        }),
      }

    case KNOWMIX.STEPS.DELETE:
      return {
        ...state,
        stepsList: state.stepsList.filter(
          (item) => item.id !== action.payload.id
        ),
      }

    case KNOWMIX.STEPS.LIST:
      return {
        ...state,
        stepsList: action.payload,
      }

    case KNOWMIX.STEPS.LIST_CLEAR:
      return {
        ...state,
        stepsList: [],
      }

    case KNOWMIX.EXAMINERS.SEARCH:
      return {
        ...state,
        examinersList:
          action.payload.offset === 0
            ? action.payload.list
            : [...state.examinersList, ...action.payload.list],
        examinersListEndReached:
          !action.payload.list.length || action.payload.list.length < LIMIT,
      }

    case KNOWMIX.EXAMINERS.COUNT:
      return {
        ...state,
        examiners_number: state.examiners_number + action.payload,
      }

    case KNOWMIX.EXAMINERS.CLEAR:
      return {
        ...state,
        examinersList: [],
        examinersListEndReached: false,
      }

    case KNOWMIX.CLEAR_CURRENT:
      return {
        ...state,
        currentKnowmix: null,
        currentWork: null,
      }
    case KNOWMIX.LIST:
      return {
        ...state,
        list: [...state.list, ...action.payload.list],
        isExaminer: action.payload.is_examiner,
      }
    case KNOWMIX.GROUP_LIST:
      return {
        ...state,
        groupList: [...state.groupList, ...action.payload.list],
        groupListEndReached:
          !action.payload.list.length || action.payload.list.length < LIMIT,
      }
    case KNOWMIX.LIST_UPDATE:
      return {
        ...state,
        list: action.payload.list,
      }
    case KNOWMIX.CLEAR_LIST:
      return {
        ...state,
        list: [],
        works: [],
        worksKnowmixes: [],
      }
    case KNOWMIX.WORKS.GET:
      return {
        ...state,
        currentWork: action.payload,
      }
    case KNOWMIX.WORKS.LIST:
      return {
        ...state,
        works: action.payload.works
          ? [...state.works, ...action.payload.works]
          : state.works,
        worksKnowmixes: action.payload.knowmixes
          ? [...state.worksKnowmixes, ...action.payload.knowmixes]
          : state.worksKnowmixes,
        worksCount: action.payload.works_number,
        worksKnowmixesCount: action.payload.knowmixes_number,
        isExaminer: action.payload.is_examiner,
      }

    case KNOWMIX.GRADE_LIST:
      return {
        ...state,
        gradesStepsList: action.payload,
      }

    case KNOWMIX.MEMBERS.LIST:
      return composer.array('membersList').get()

    case KNOWMIX.MEMBERS.SEARCH:
      return composer.array('membersListSearch').get()

    case KNOWMIX.MEMBERS.CLEAR:
      return composer.array('membersList').clear()

    case KNOWMIX.MEMBERS.SEARCH_CLEAR:
      return composer.array('membersList').clear()

    case KNOWMIX.MEMBERS.ADD:
      return {
        ...state,
        membersList: state.membersList.map((u) =>
          u.id === action.payload.id
            ? {
                ...u,
                knowmix: { ...u.knowmix, is_member: true },
              }
            : u
        ),
      }

    case KNOWMIX.MEMBERS.DELETE:
      return {
        ...state,
        membersList: state.membersList.map((u) =>
          u.id === action.payload.id
            ? {
                ...u,
                knowmix: { ...u.knowmix, is_member: false },
              }
            : u
        ),
      }

    default:
      return state
  }
}
