import ErrorBoundary from "../../components/v2/ErrorBoundary"

const withErrorBoundary = (Component, errorMessage) => {
  return (props) => (
    <ErrorBoundary message={errorMessage}>
      <Component {...props} />
    </ErrorBoundary>
  )
}

export default withErrorBoundary
