import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../../../../library/constants/routes"
import LockOutlined from "@material-ui/icons/LockOutlined"
import {
  knowCredActivitiesIds,
  knowCredTypesIds,
} from "../../../../../../library/constants/knowCred"
import React from "react"
import { usePostCtx } from "../../PostContext"
import { getUserName } from "../../../../../../library/utils/user/userUtils"
import PlainLink from "../../../../../ui/link"

const SharedKnowcred = () => {
  const { post } = usePostCtx()

  const knowCred = post.shared_knowcred

  const knowCredUrl = generatePath(routes.KNOWCRED_SINGLE, {
    knowCredId: knowCred.id,
  })

  const showSubtitleLabel = [3, 4]
  const hideActivity = [0, 1].includes(knowCred.activity)

  const isSubtypeLabel = showSubtitleLabel.includes(knowCred.type)

  const labelClass = isSubtypeLabel ? "knowcred-card__info-label--56" : ""
  const valueClass = isSubtypeLabel ? "knowcred-card__info-value--78" : ""

  return (
    <div className="shared_content">
      <div className="knowcred-card__image">
        {!knowCred.public && (
          <div className="knowcred-card__lock-icon">
            <LockOutlined className="color-primary" />
          </div>
        )}
        <Link to={knowCredUrl}>
          <img
            src={knowCred.background_image}
            alt="Credicon background"
            className="knowcred-card__background"
            style={{ borderColor: knowCred.border_color }}
          />
          <img
            src={knowCred.foreground_image}
            alt="Credicon foreground"
            className="knowcred-card__foreground"
          />
        </Link>
      </div>
      <div className="knowcred-card__content">
        <h4>
          <Link to={knowCredUrl} className="link">
            {knowCred.title}
          </Link>
        </h4>

        <table className="knowcred-card__info" style={{ display: "table" }}>
          <tbody>
            <tr>
              <td className={`pb4 knowcred-card__info-label ${labelClass}`}>
                Type:
              </td>
              <td className={`pb4 knowcred-card__info-value ${valueClass}`}>
                {knowCredTypesIds[knowCred.type]}
              </td>
            </tr>
            {!hideActivity && (
              <tr>
                <td>
                  {showSubtitleLabel.includes(knowCred.type)
                    ? "Label"
                    : "Activity"}
                  :
                </td>
                <td className={`pb4 knowcred-card__info-value ${valueClass}`}>
                  {knowCredActivitiesIds[knowCred.activity]}
                </td>
              </tr>
            )}
            <tr>
              <td className={`pb4 knowcred-card__info-label ${labelClass}`}>
                Owner:
              </td>
              <td className={`pb4 knowcred-card__info-value ${valueClass}`}>
                <PlainLink
                  to={generatePath(routes.USER, { userId: knowCred.user.id })}
                >
                  {getUserName(knowCred.user)}
                </PlainLink>
              </td>
            </tr>
            <tr>
              <td className={`pb4 knowcred-card__info-label ${labelClass}`}>
                Creator:
              </td>
              <td className={`pb4 knowcred-card__info-value ${valueClass}`}>
                <PlainLink
                  to={generatePath(routes.USER, {
                    userId: knowCred.creator.id,
                  })}
                >
                  {getUserName(knowCred.creator)}
                </PlainLink>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="knowcred-card__description">
          {knowCred.description.length > 80
            ? knowCred.description.slice(0, 80) + "..."
            : knowCred.description}
        </div>
      </div>
    </div>
  )
}

export default SharedKnowcred
