import { PERSON } from "../actions/types/personTypes"

const initialState = {
  personData: null,
  friendsList: [],
  friendsNumber: null,
  loading: true,
}

export const personReducer = (state = initialState, action) => {
    switch (action.type) {
        case PERSON.DATA_RECEIVED:
            return {
                ...state,
                personData: {...state.personData, ...action.payload},
                loading: false,
            };

        case PERSON.FRIENDS_RECEIVED:
            return {
                ...state,
                //friendsList: action.payload.users,
                friendsNumber: action.payload.friends_number,
                friendsList: state.friendsList ? [...state.friendsList, ...action.payload.users] : action.payload.users,
                loading: false,
            };

        case PERSON.DATA_RESET:
            return initialState;

        case PERSON.FRIENDS_RESET:
            return {
                ...state,
                friendsList: [],
            };
        default:
            return state;
    }
};
