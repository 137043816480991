import React from "react"
import { generatePath, useParams } from "react-router-dom"
import { useGroup } from "../../../../../../hooks/data/groups/groups"
import BoxedList from "../../../../../../components/ui/boxedList"
import * as routes from "../../../../../../library/constants/routes"
import { safeGetEntity } from "../../../../../../library/utils/entities"
import { useSelector } from "react-redux"

const GroupSidebarInfo = () => {
  const params = useParams()
  const { isLoggedIn } = useSelector(({ auth }) => auth)

  const {
    group,
    canEdit,
    isSchool,
    isEvent,
    isGovEducational,
    isEducational,
    isDistrict,
    isAnySchool,
    queryKey,
    isUserFollower,
  } = useGroup()

  const isFullFledgedMember = group?.is_member && !isUserFollower

  return (
    <>
      {(!group?.closed || isFullFledgedMember) &&
        isEvent &&
        !!safeGetEntity(group?.creator) && (
          <BoxedList
            title="Organizer"
            counter={""}
            list={[group?.creator]}
            entity="users"
          />
        )}

      {(!group.closed || isFullFledgedMember) &&
        isSchool &&
        !!safeGetEntity(group?.district) && (
          <BoxedList
            title="District"
            counter={""}
            list={[{ ...group?.district, type: 5 }]}
            entity="groups"
          />
        )}

      {(!group?.closed || group?.is_member) &&
        isSchool &&
        !!group?.teachers_number && (
          <BoxedList
            title="Teachers"
            counter={group?.teachers_number}
            list={group?.teachers?.slice(0, 5)}
            entity="users"
            linkShowAll={generatePath(routes.GROUP_TEACHERS, params)}
          />
        )}

      {(!group?.closed || isFullFledgedMember) &&
        isDistrict &&
        !!group?.schools?.length && (
          <BoxedList
            title="Schools"
            counter={group?.schools?.length}
            list={group?.schools?.map((s) => ({ ...s, type: 4 }))}
            groupType={4}
            entity="groups"
            linkShowAll={generatePath(routes.GROUP_RELATED_SCHOOLS, params)}
          />
        )}

      {(!group.closed || group?.is_member) &&
        isSchool &&
        !!group?.schedules_number && (
          <BoxedList
            title="Calendars"
            counter={group?.schedules_number}
            list={group?.schedules?.slice(0, 5)}
            entity="Calendars"
            linkShowAll={generatePath(routes.GROUP_CALENDARS, params)}
          />
        )}

      {(!group?.closed || group?.is_member) &&
        isDistrict &&
        !!group?.calendars_number && (
          <BoxedList
            title="Calendars"
            counter={group?.calendars_number}
            list={group?.calendars?.slice(0, 5)}
            entity="Calendars"
            linkShowAll={generatePath(routes.GROUP_CALENDARS, params)}
          />
        )}

      {(!group?.closed || isFullFledgedMember) &&
        !!group?.participants_number && (
          <BoxedList
            title="Members"
            counter={group?.participants_number}
            list={group?.participants.slice(0, 5)}
            entity="users"
            linkShowAll={generatePath(routes.GROUP_MEMBERS, params)}
          />
        )}

      {(!group?.closed || isFullFledgedMember) &&
        !!group?.connections?.length && (
          <BoxedList
            title="Related groups"
            counter={group?.connections_number}
            list={group?.connections?.slice(0, 5)}
            entity="groups"
            linkShowAll={generatePath(routes.GROUP_RELATED_GROUPS, params)}
          />
        )}

      {(!group?.closed || isFullFledgedMember) && !!group?.products_number && (
        <BoxedList
          title="Products"
          counter={group?.products_number}
          list={group?.products}
          entity="products"
          linkShowAll={generatePath(routes.GROUP_PRODUCTS, params)}
        />
      )}
    </>
  )
}

export default GroupSidebarInfo
