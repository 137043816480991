import React, { useEffect, useState } from "react"
import { Button, Dialog, IconButton, Slide } from "@material-ui/core"
import { navigate } from "react-big-calendar/lib/utils/constants"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import { useDispatch, useSelector } from "react-redux"
import { generatePath, useHistory, useParams } from "react-router-dom"
import { useAlert } from "../../../../../hooks/useAlert"
import {
  clearCalendarEvents,
  getCalendarEventsList,
} from "../../../../../library/store/actions/creators/calendarCreators"
import CloseIcon from "@material-ui/icons/Close"
import * as routes from "../../../../../library/constants/routes"
import { GROUP } from "../../../../../library/constants/routes"
import KSCalendar from "../../../../../components/v2/calendar/calendar"
import { useCalendar } from "../../../calendar/CalendarContext"
import Loader from "../../../../../components/ui/loader"

export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PublicCalendar = ({ hasAccessToCalendar }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const { errorAlert } = useAlert()

  const groupName = useSelector(({ groups: { currentGroup } }) => {
    const charLimit = 50
    const renderName = currentGroup?.name.slice(0, charLimit + 1)
    return currentGroup?.name.length > charLimit
      ? renderName + "..."
      : renderName
  })

  const {
    globalDate,
    setGlobalDate,
    activeRange,
    onEventFormModalOpen,
    onEventDialogOpen,
  } = useCalendar()

  const [date, setDate] = useState(globalDate?.date || new Date())
  const [isLoading, setIsLoading] = useState(true)

  const { eventsList } = useSelector(({ calendar }) => calendar)

  useEffect(async () => {
    if (!hasAccessToCalendar) {
      history.replace(generatePath(GROUP, params))
    }

    try {
      await dispatch(clearCalendarEvents())
      await dispatch(
        getCalendarEventsList({
          group_id: parseInt(params.groupId),
          ...activeRange,
        })
      )
      setIsLoading(false)
    } catch (e) {
      errorAlert(e)
    }
  }, [])

  useEffect(() => {
    return () => dispatch(clearCalendarEvents())
  }, [])

  useEffect(async () => {
    if (!isLoading) {
      await dispatch(
        getCalendarEventsList({
          group_id: parseInt(params.groupId),
          ...activeRange,
        })
      )
    }
  }, [activeRange])

  const onNavigate = (d) => {
    setDate(d)
    setGlobalDate({ date: d })
  }

  const onClose = () => {
    history.push(generatePath(routes.GROUP, { ...params }))
  }

  return (
    <Dialog
      fullScreen
      open={true}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      {!isLoading ? (
        <KSCalendar
          date={date}
          onNavigate={onNavigate}
          eventsList={eventsList}
          style={{ minHeight: 800, height: "100vh" }}
          getToolbar={(toolbarProps) => (
            <CalendarToolbar
              {...toolbarProps}
              groupName={groupName}
              onFormOpen={onEventFormModalOpen}
              onClose={onClose}
            />
          )}
          onSelectEvent={(event) =>
            onEventDialogOpen(event.id, event.entity_type)
          }
          onSelectSlot={({ start, end }) =>
            onEventFormModalOpen({
              start,
              end,
            })
          }
        />
      ) : (
        <Loader />
      )}
    </Dialog>
  )
}

const CalendarToolbar = (props) => {
  const navigateToday = () => props.onNavigate(navigate.TODAY, null)
  const navigatePrev = async () => props.onNavigate(navigate.PREVIOUS, null)
  const navigateNext = async () => props.onNavigate(navigate.NEXT, null)

  return (
    <div className="box__heading events-calendar-toolbar">
      <div className={"events-calendar-toolbar__left"}>
        <Button
          variant={"outlined"}
          color={"primary"}
          onClick={navigateToday}
          className={"mr15"}
        >
          Today
        </Button>
        <IconButton aria-label="Previous" size="small" onClick={navigatePrev}>
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton aria-label="Next" size="small" onClick={navigateNext}>
          <KeyboardArrowRight />
        </IconButton>
        <span className={"ml15"}>{props.label}</span>
      </div>
      <div className={"text-center"}>
        <p className={"ml15 mr15"}>{props.groupName}</p>
        <p className={"color-black-54 font-12"}>Public events Calendar</p>
      </div>
      <div className={"events-calendar-toolbar__right"}>
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={props.onFormOpen}
        >
          Create appointment
        </Button>

        <IconButton
          aria-label="close"
          color="primary"
          onClick={props.onClose}
          className={"ml50"}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  )
}

export default PublicCalendar
