import styled from "@emotion/styled/macro"
import { colors } from "../../../library/constants/styles/colors"
import { devices } from "../../../library/constants/styles/responsive"
import { Tooltip } from "@material-ui/core"
import React, { useState } from "react"
import KeyboardArrowDownOutlined from "@material-ui/icons/KeyboardArrowDownOutlined"
import { KeyboardArrowUpOutlined } from "@material-ui/icons"
import { key } from "../../../library/utils/generateKey"
import { mq } from "../../../theme/globalStyle"
import PlainLink from "../../ui/link"
import { PlainButton } from "../controls"
import HorizontalScroll from "../../ui/HorizontalScroll"

const Container = styled.div`
  padding: 0 10px;
  box-shadow: 0 0 2px 0 ${colors.black12};
  background-color: ${colors.white};
  border-radius: 2px;
`

export const StyledTable = styled.table`
  display: block;
`

export const THead = styled.thead`
  display: block;
  width: ${(props) =>
    props.fluid ? "100%" : props.columnsNumber * 130 + "px"};
  background-color: ${colors.grey7};
  border-top: 1px solid ${colors.black12};
  border-bottom: 1px solid ${colors.black12};
`

export const THeadCellContent = styled.button`
  display: flex;
  align-items: center;
  color: ${colors.black54};
  border: 0;
  box-shadow: none;
  background-color: transparent;
  white-space: nowrap;
  transition: 0.2s all;

  svg {
    margin-left: 4px;
  }
`

export const THeadRow = styled.tr`
  display: flex;
  align-items: center;
  width: 100%;
`

const ExpandableRowControls = styled.td`
  position: ${(props) => (props.fluid ? "absolute" : "sticky")};
  display: flex;
  align-items: center;
  right: 5px;
  color: ${colors.primary};
  //background-color: ${colors.grey7};
  opacity: 0;
  visibility: hidden;
  transition: 0.2s all;
  transform: translateY(-50%);

  @supports (display: grid) {
    transform: none;
  }

  ${mq.m} {
    opacity: 1;
    visibility: visible;
  }

  svg {
    cursor: pointer;

    :not(:last-child) {
      margin-right: 8px;
    }
  }
`

const Controls = styled.td`
  display: flex;
  align-items: center;
  position: ${(props) => (props.fluid ? "absolute" : "sticky")};
  right: 5px;
  //padding: 10px 5px;
  //min-width: 130px;
  //max-width: 130px;
  color: ${colors.primary};
  //background-color: ${colors.grey7};
  opacity: 0;
  visibility: hidden;
  transition: 0.2s all;
  transform: translateY(-50%);
  height: 44px;

  @supports (display: grid) {
    transform: none;
  }

  ${mq.m} {
    opacity: 1;
    visibility: visible;
  }

  svg {
    cursor: pointer;

    :not(:last-child) {
      margin-right: 8px;
    }
  }
`

export const TBody = styled.tbody`
  display: flex;
  flex-direction: column;
  width: ${(props) =>
    props.fluid ? "100%" : props.columnsNumber * 130 + 48 + "px"};
  margin-bottom: ${(props) => (props.fluid ? 0 : "20px")};
`

export const Row = styled.tr`
  display: flex;
  position: relative;
  align-items: center;
  width: ${(props) =>
    props.fluid ? "100%" : props.columnsNumber * 130 + "px"};
  border-bottom: 1px solid ${colors.black12};
  cursor: pointer;
  transition: 1s all;

  &:first-of-type {
    border-top: ${(props) =>
      props.noHead ? `1px solid ${colors.black12}` : 0};
  }

  &:hover {
    background-color: ${(props) =>
      props.noHoverBg ? "transparent" : colors.grey7};

    td {
      background-color: ${(props) =>
        props.noHoverBg ? "transparent" : colors.grey7};

      &:first-of-type {
        border-left-color: ${colors.primary};
      }
    }

    ${Controls} {
      opacity: 1;
      visibility: visible;
      background-color: ${colors.grey7};
    }
  }

  td {
    background-color: ${colors.white};

    &:first-of-type {
      color: ${(props) => (props.noHead ? colors.black54 : "auto")};
      border-right: ${(props) =>
        props.noHead ? `1px solid ${colors.black12}` : "auto"};
    }
  }
`

export const Cell = styled.td`
  display: flex;
  align-items: center;
  flex: ${(props) => (props.fluid ? 1 : "auto")};
  padding: 10px 5px;
  min-width: ${(props) => props.width || "130px"};
  //max-width: ${(props) => (props.fluid ? "auto" : props.width || "130px")};
  height: ${(props) =>
    props.autoHeight ? "auto" : props.fullHeight ? "100%" : "44px"};
  //background-color: ${(props) => (props.th ? colors.grey7 : colors.white)};
  transition: 0.2s all;

  &:first-of-type {
    position: ${(props) => (props.isFirstColumnSticky ? "sticky" : "relative")};
    left: ${(props) => (props.isFirstColumnSticky ? 0 : "auto")};
    // max-width: ${(props) => (props.fluid ? "auto" : "130px")};
    // min-width: ${(props) => (props.fluid ? "auto" : "130px")};
    border-left: 2px solid transparent;
  }
`

export const CellContent = styled.div`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const ScrollContainer = styled.div`
  display: block;
  width: 684px;
  overflow-x: auto;
  overflow-y: hidden;
`

export const MobileContainer = styled.div`
  @media ${devices.xs} {
    width: calc(100vw - 40px);
    overflow: auto;
  }
`

export const recordsPerPageOptions = [10, 15, 20, 25, 30]

const Table = ({
  columns,
  data,
  fluid,
  expandableRows = false,
  onDetailsOpen,
  detailsEntityKey,
  numberedRecords = false,
  actions,
}) => {
  return (
    <>
      <MobileContainer>
        <HorizontalScroll>
          <StyledTable>
            <THead fluid={fluid} columnsNumber={columns.length}>
              <THeadRow>
                {columns.map((column, i) => (
                  <Cell
                    th
                    fluid={fluid}
                    numbered={numberedRecords}
                    width={column.width}
                    key={column.label}
                  >
                    <THeadCellContent>{column.label}</THeadCellContent>
                  </Cell>
                ))}
              </THeadRow>
            </THead>
            <TBody fluid={fluid} columnsNumber={columns.length}>
              {expandableRows
                ? data.map((record, recordIdx) => {
                    // console.log(record)
                    return (
                      <ExpandableRow
                        record={record}
                        expandable={!!record.nested}
                        fluid={fluid}
                        columnsNumber={columns.length}
                        numbered={numberedRecords}
                        onDetailsOpen={
                          onDetailsOpen
                            ? () => onDetailsOpen(record)
                            : undefined
                        }
                        columns={columns}
                        key={record?.raw?.view_id || record.id}
                      />
                    )
                  })
                : data.map((record, recordIdx) => {
                    // console.log(record)
                    console.log(record)
                    return (
                      <EditableRow
                        record={record}
                        fluid={fluid}
                        columnsNumber={columns.length}
                        numbered={numberedRecords}
                        onDetailsOpen={
                          onDetailsOpen
                            ? () => onDetailsOpen(record)
                            : undefined
                        }
                        actions={actions}
                        columns={columns}
                        key={record?.raw?.view_id || record.id}
                      />
                    )
                  })}
            </TBody>
          </StyledTable>
        </HorizontalScroll>
      </MobileContainer>
    </>
  )
}

const EditableRow = ({
  record,
  fluid,
  columnsNumber,
  numbered = false,
  onDetailsOpen,
  actions,
  columns,
}) => {
  const composeValue = (value) => {
    if (typeof value === "string" || typeof value === "number") return value

    if (value.type === "link") {
      return (
        <PlainLink to={value.path} newTab ellipsis>
          {value.label}
        </PlainLink>
      )
    }

    if (value.type === "links-list") {
      return value.links.map((link) => (
        <div className={"mb5"} key={link.label()}>
          <PlainLink to={link.path} newTab ellipsis>
            {link.label()}
          </PlainLink>
        </div>
      ))
    }

    return ""
  }

  const composeActions = (action) => {
    if (action.type === "link") {
      return (
        <PlainLink to={action.path} newTab>
          {action.label()}
        </PlainLink>
      )
    }

    if (action.type === "button") {
      return (
        <PlainButton onClick={action.onClick}>{action.label()}</PlainButton>
      )
    }

    if (action.component) return action.component()

    return null
  }
  return (
    <>
      <Row
        key={record?.raw?.view_id || record.id}
        fluid={fluid}
        columnsNumber={columnsNumber}
      >
        {record.info.map((value, cellIdx) => (
          <Cell
            fluid={fluid}
            numbered={numbered}
            onClick={onDetailsOpen}
            width={columns[cellIdx].width}
            key={key()}
          >
            <CellContent fluid={fluid}>{composeValue(value)}</CellContent>
          </Cell>
        ))}
        <Controls fluid={fluid}>
          {actions?.(record.raw).map((action) => {
            // console.log(action)
            return <div key={action.label}>{composeActions(action)}</div>
          })}
        </Controls>
      </Row>
    </>
  )
}

const ExpandableRow = ({
  record,
  expandable = false,
  fluid,
  columnsNumber,
  numbered = false,
  columns,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <>
      <Row
        key={record?.raw?.view_id || record.id}
        fluid={fluid}
        columnsNumber={columnsNumber}
      >
        {record.info.map((value, cellIdx) => (
          <Cell
            numbered={numbered}
            fluid={fluid}
            key={key()}
            width={columns[cellIdx].width}
          >
            {value}
          </Cell>
        ))}
        <ExpandableRowControls fluid={fluid}>
          {expandable && (
            <Tooltip title={!isExpanded ? "Show steps" : "Hide steps"} arrow>
              {!isExpanded ? (
                <KeyboardArrowDownOutlined
                  onClick={() => setIsExpanded(true)}
                />
              ) : (
                <KeyboardArrowUpOutlined onClick={() => setIsExpanded(false)} />
              )}
            </Tooltip>
          )}
        </ExpandableRowControls>
      </Row>
      {expandable &&
        isExpanded &&
        record?.nested?.map((nested) => (
          <Row key={nested.id}>
            {nested.info.map((r, rIdx) => (
              <Cell numbered={numbered} key={key()} width={columns[rIdx].width}>
                {r}
              </Cell>
            ))}
          </Row>
        ))}
    </>
  )
}

export default Table
