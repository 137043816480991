import React from "react"
import { usePostsMutations } from "../../../../../hooks/data/feed/posts"
import { usePostCtx } from "../PostContext"
import { useAlert } from "../../../../../hooks/useAlert"

const RestorePost = () => {
  const { errorAlert } = useAlert()
  const { post, queryKey, setIsArchived } = usePostCtx()
  const { restorePost } = usePostsMutations({ queryKey })

  const onRestorePost = async () => {
    try {
      await restorePost.mutateAsync({ id: post.id })
      setIsArchived(false)
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <div className="p15">
      <div className="f jcc aic">
        <div>
          <span>Post deleted. </span>
          <button
            type={"button"}
            className="post__restore"
            onClick={onRestorePost}
          >
            Undo.
          </button>
        </div>
      </div>
    </div>
  )
}

export default RestorePost
