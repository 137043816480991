const SelectValue = ({
  entityName = "value",
  value = "",
  isMulti = false,
  getValueName,
  getValueHelperText,
}) => {
  if (!value || (isMulti && !value.length)) {
    return <span className={"color-black-38"}>Select {entityName}</span>
  }

  const safePickName = (v) =>
    typeof getValueName === "function" ? getValueName(v) : v.name

  const hasHelperText =
    typeof getValueHelperText === "function" &&
    !!getValueHelperText(value).length
  const singleValue = () =>
    hasHelperText ? (
      <div>
        <p>{safePickName(value)}</p>
        <p className={"color-black-54 font-12"}>{getValueHelperText(value)}</p>
      </div>
    ) : (
      safePickName(value)
    )

  const multipleValue = () => value.map((item) => safePickName(item)).join(", ")

  return !isMulti ? singleValue() : multipleValue() || ""
}

export default SelectValue
