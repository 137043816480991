import React from "react"
import { generatePath } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import Card from "./card"
import {
  composeUserHighlightedEducation,
  composeUserHighlightedWork,
} from "../../../../library/utils/getUserHighlightedInfo"
import unavailableAvatar from "../../../../assets/img/user_unavailable.svg"
import userAvatarDefault from "../../../../assets/img/user_avatar.svg"
import { getCardSize } from "../../../../library/constants/styles/cardSizes"

const UserCard = ({ user, infoComponent, controls }) => {
  const userUrl = generatePath(routes.USER, { userId: user.id })

  const photoUrl = user.deleted
    ? unavailableAvatar
    : user.photo !== ""
      ? user.photo
      : userAvatarDefault

  const highlightedInfo = () => (
    <>
      {!!user.education && composeUserHighlightedEducation(user.education)}

      {!!user.work && composeUserHighlightedWork(user.work)}
    </>
  )

  return (
    <Card
      size={getCardSize(8).numValPx}
      name={user.first_name + " " + user.last_name}
      photo={photoUrl}
      link={userUrl}
      infoComponent={infoComponent || highlightedInfo}
      controls={controls}
    />
  )
}

export default UserCard
