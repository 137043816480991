import React, { Component } from "react"
import Dialog from "@material-ui/core/Dialog"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import { bindActionCreators } from "redux"
import {
  clearCurrentScheduleSubject,
  getScheduleSubject,
} from "../../../../../../../../../library/store/actions/creators/calendarCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { withRouter } from "react-router-dom"
import getErrorText from "../../../../../../../../../library/constants/errorTypes"
import { calendarGrades } from "../../../../../../../../../library/constants/educationalDictionary"
import { getObjectById } from "../../../../../../../../../library/utils/arrays"
import GradePicker from "../../../../../../../../../components/v2/inputs/pickers/gradePicker"
import { forEach } from "lodash"

class SubjectFormModal extends Component {
  state = {
    name: "",
    description: "",
    grade: this.props.edit ? "" : [],
    gradeError: false,
  }

  async componentDidMount() {
    if (this.props.edit) {
      try {
        await this.props.getScheduleSubject({ id: this.props.activeSubject })
        this.setState({
          name: this.props.calendar.currentSubject.name || "",
          description: this.props.calendar.currentSubject.description || "",
          grade:
            getObjectById(
              calendarGrades,
              this.props.calendar.currentSubject.grade
            ) || "",
        })
      } catch ({ error }) {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    }
  }

  componentWillUnmount() {
    this.props.clearCurrentScheduleSubject()
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    })
  }

  handleChangeRequired = (name) => (event) => {
    switch (name) {
      case "grade":
        this.setState({
          gradeError: !event.target.value,
          grade: event.target.value,
        })
        break

      default:
        this.setState({
          [name]: event.target.value,
        })
        break
    }
  }

  processFormValidate = () => {
    const { grade } = this.state
    this.setState({ gradeError: !grade })
  }

  processInfoSave = async () => {
    const { name, description, grade, gradeError } = this.state

    if (!grade || gradeError) {
      return this.setState({ gradeError: true })
    }

    if (Array.isArray(grade)) {
      console.log(grade)
      let submissions = []
      for (const g of grade) {
        // submissions.push(async () => {
        await this.props.onSubmit({
          id: this.props.edit ? this.props.activeSubject : undefined,
          [this.props.isSchool ? "schedule_id" : "calendar_id"]: parseInt(
            this.props.match.params.calendarId
          ),
          name,
          description: description || undefined,
          grade: g.id,
        })
        // })
      }

      // await Promise.all(submissions)
    } else {
      await this.props.onSubmit({
        id: this.props.edit ? this.props.activeSubject : undefined,
        [this.props.isSchool ? "schedule_id" : "calendar_id"]: parseInt(
          this.props.match.params.calendarId
        ),
        name,
        description: description || undefined,
        grade: grade.id,
      })
    }

    this.props.handleClose()
  }

  render() {
    const { handleClose, open } = this.props
    const { name, description, grade, gradeError } = this.state

    return (
      <Dialog
        aria-labelledby="subject-form-dialog"
        onClose={handleClose}
        open={open}
        maxWidth="md"
      >
        <ValidatorForm onSubmit={this.processInfoSave}>
          <DialogTitle id="alert-dialog-title" className="text-center">
            {this.props.edit ? "Edit subject" : "Create subject"}
          </DialogTitle>
          <DialogContent>
            <div className="form form--modal">
              <div className="form__fieldset">
                <label htmlFor="name" className="form__label">
                  Title *
                </label>
                <div className="form__fieldbox">
                  <div className="form__input">
                    <TextValidator
                      id="name"
                      name="name"
                      value={name}
                      placeholder="Type subject title"
                      className="edit-form__input"
                      margin="normal"
                      fullWidth
                      validators={[
                        "required",
                        "minStringLength:3",
                        "maxStringLength:200",
                      ]}
                      errorMessages={[
                        "Field is required",
                        "Min length is 3 characters",
                        "Max length is 200 characters",
                      ]}
                      withRequiredValidator
                      onChange={this.handleChange("name")}
                    />
                  </div>
                </div>
              </div>
              <GradePicker
                multiple={!this.props.edit}
                grades={calendarGrades}
                value={grade}
                hasError={gradeError}
                onChange={this.handleChangeRequired("grade")}
                required
              />
              {/*<div className="form__fieldset">*/}
              {/*  <label htmlFor="description" className="form__label">*/}
              {/*    Description*/}
              {/*  </label>*/}
              {/*  <div className="form__fieldbox">*/}
              {/*    <div className="form__input">*/}
              {/*      <TextValidator*/}
              {/*        id="description"*/}
              {/*        name="description"*/}
              {/*        value={description}*/}
              {/*        placeholder="Type description"*/}
              {/*        className="edit-form__input"*/}
              {/*        margin="normal"*/}
              {/*        fullWidth*/}
              {/*        validators={['maxStringLength:500']}*/}
              {/*        errorMessages={['Max length is 500 characters']}*/}
              {/*        onChange={this.handleChange('description')}*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    )
  }
}

const mapStateToProps = ({ auth, groups, calendar }) => ({
  auth,
  groups,
  calendar,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getScheduleSubject,
      clearCurrentScheduleSubject,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(SubjectFormModal)))
