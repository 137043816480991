import React from "react"
import { devices } from "../../../../library/constants/styles/responsive"
import styled from "@emotion/styled"
import { ImagesGroup } from "../howToArticle"
import img1 from "../../../../assets/img/pages/how-to/meets-vs-knowmeets-vs-appointments/1.png"
import img2 from "../../../../assets/img/pages/how-to/meets-vs-knowmeets-vs-appointments/2.png"
import img3 from "../../../../assets/img/pages/how-to/meets-vs-knowmeets-vs-appointments/3.png"

const ImageContainer = styled.div`
  width: ${(props) => (props.wide ? "100%" : "50%")};

  @media ${devices.xs} {
    width: 100%;
  }
`

const CustomImagesGroup = styled(ImagesGroup)`
  align-items: flex-start;

  img {
    max-width: 290px;
  }
`

const MeetTypesOverview = () => {
  return (
    <>
      <pre className={"mb15"}>
        Both Meets and Course Meets will pick the date and time for the meeting
        maker and the invitees and offer auto-cohorting which break an
        invitation into multiple meetings based on the acceptance rate of
        invitees. The difference between a Meet and Course Meet is that a Meet
        can only use your personal Holds times and is not attached to a Course
        which can sequence multiple meetings called Course Meets. It cannot use
        Holds so you can control when you make and accept meetings within
        windows of time unless you have a paid account.
        <br />
        A Course Meet can also used the advanced master scheduler for schools
        which have Blocks in addition to Holds. Both types of meets do not need
        a date and time but will automatically coordinate a time. They may have
        a target which would automatically trigger to set for however many
        invitees have accepted at that target date and time regardless of
        meeting the cohort size, then offer a new target to remaining invitees
        for another meeting. If a cohort is met, the system will set at the
        target or as soon after as possible, or sooner if there is no target.
        <br />
        An appointment is a regular meeting which does not auto-cohort into
        multi-meetings.
      </pre>
      <CustomImagesGroup>
        <img src={img2} />
        <img src={img1} />
        <img src={img3} />
      </CustomImagesGroup>
    </>
  )
}

export default MeetTypesOverview
