import PagePermissionDenied from "./PagePermissonDenied"
import { usePermissionChecker } from "./usePermissionChecker"

export const withPagePermissions =
  ({ pageTitle = "", allowedPlans = [], allowGrantedSubscription = false }) =>
  (Component) => {
    const WithPagePermissions = (props) => {
      const isAllowed = usePermissionChecker(
        allowedPlans,
        allowGrantedSubscription
      )

      if (allowedPlans.length && !isAllowed) {
        return (
          <PagePermissionDenied allowedPlans={allowedPlans} title={pageTitle} />
        )
      }

      return <Component {...props} />
    }

    return WithPagePermissions
  }
