export const FRIENDS = {
  LIST: "friends.list",
  LIST_UPDATE: "friends.list.update",
  SEARCH: "users.search",
  CLEAR: "friend.clear",
  REQUESTS: {
    REFRESHING: "friends.requests.refreshing",
    LIST: "friends.requests.list",
    PENDING: "friends.requests.pending",
    OUTGOING: "friends.requests.outgoing",
    UPDATE_PENDING: "friends.requests.pending.update",
    UPDATE_OUTGOING: "friends.requests.outgoing.update",
    SEND: "friends.requests.send",
    CANCEL: "friends.requests.cancel",
    ACCEPT: "friends.requests.accept",
    DECLINE: "friends.requests.decline",
  },
  DELETE: "friends.delete",
  INVITE_BY_EMAIL: "users.invite",
}
