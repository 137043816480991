import React from "react"
import CalendarFormModal from "../../../../../calendar/components/modals/calendarFormModal"
import {
  clearSchedulesList,
  createPrivateSchoolSchedule,
  deleteSchedule,
  getSchedulesList,
  updatePrivateSchoolSchedule,
} from "../../../../../../../library/store/actions/creators/calendarCreators"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import ListPage from "../../../../../../../components/v2/layout/listPage/listPage"
import CalendarCard from "../../../../../../../components/v2/layout/cards/calendarCard"
import useCreator from "../../../../../../../hooks/useCreator"

const PrivateSchoolCalendars = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const creator = useCreator()

  const { schedulesList, schedulesListEndReached } = useSelector(
    ({ calendar }) => calendar
  )

  const onCreateCalendar = async (data) =>
    await creator(createPrivateSchoolSchedule(data))
  const onEditCalendar = async (data) =>
    await creator(updatePrivateSchoolSchedule(data))
  const onDeleteCalendar = async (id) =>
    await creator(
      deleteSchedule({
        id,
        isPrivateSchool: true,
      })
    )

  return (
    <ListPage
      title={"Calendars"}
      creationButtonTitle={"Create Calendar"}
      noResultsLabel={"This school doesn't have Calendars yet"}
      getList={async ({ name, offset }) =>
        await dispatch(
          getSchedulesList({
            school_id: parseInt(params.groupId),
            name,
            offset,
          })
        )
      }
      clearList={() => dispatch(clearSchedulesList())}
      list={schedulesList}
      listEndReached={schedulesListEndReached}
      getListItemComponent={({ item, openEditDialog, openDeletionDialog }) => (
        <CalendarCard
          calendar={item}
          controls={{
            dropdownMenu: {
              items: [
                {
                  type: "button",
                  action: openEditDialog,
                  label: "Edit Calendar",
                },
                {
                  type: "h-divider",
                  id: "hd1",
                },
                {
                  type: "button",
                  action: openDeletionDialog,
                  label: "Delete Calendar",
                },
              ],
            },
          }}
          key={item.id}
        />
      )}
      getFormDialogComponent={({ open, onClose, activeItem }) => {
        return (
          <CalendarFormModal
            open={open}
            onClose={onClose}
            onSubmit={!activeItem ? onCreateCalendar : onEditCalendar}
            activeCalendar={activeItem}
            edit={!!activeItem}
            isPrivateSchool
            groupType={4}
          />
        )
      }}
      getDeletionDialogComponent={{
        entityName: "Calendar",
        onDelete: onDeleteCalendar,
      }}
    />
  )
}

export default PrivateSchoolCalendars
