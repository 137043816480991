import { withStyles } from "@material-ui/core"
import { colors } from "../../../library/constants/styles/colors"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import React, { useState } from "react"
import { getObjectById } from "../../../library/utils/arrays"
import { productContentTypes } from "../../../library/constants/productDictionary"
import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../library/constants/routes"
import logoDefault from "../../../assets/img/product.svg"
import StarRating from "../../ui/starRating"
import DropdownControl from "../../ui/dropdowns/dropdownControl"
import MenuList from "@material-ui/core/MenuList/MenuList"
import Button from "@material-ui/core/Button/Button"

const CustomMenuItem = withStyles({
  root: {
    color: colors.primary,
  },
})(MenuItem)

const ProductsPickerListItem = ({ product, ...rest }) => {
  const [isAdded, setIsAdded] = useState(
    rest.relatedProducts.includes(product.id)
  )
  console.log(rest.relatedProducts.includes(product))

  const addProduct = async () => {
    await rest.addProduct(product)
    setIsAdded(true)
  }

  const deleteProduct = async () => {
    await rest.deleteProduct(product.id)
    setIsAdded(false)
  }

  const composeContentType = () => {
    return getObjectById(productContentTypes, product.content_type)
  }

  const creator = product.creator_user
    ? {
        name: `${product.creator_user.first_name} ${product.creator_user.last_name}`,
        url: generatePath(routes.USER, { userId: product.creator_user.id }),
      }
    : {
        name: product.creator_group.name,
        url: generatePath(routes.GROUP, { groupId: product.creator_group.id }),
      }

  return (
    <div className="product-tile">
      <Link
        to={generatePath(routes.PRODUCT, { productId: product.id })}
        className="product-tile__logo"
      >
        <img src={product.image || logoDefault} alt="Product image" />
      </Link>
      <Link
        to={generatePath(routes.PRODUCT, { productId: product.id })}
        className="product-tile__name link"
      >
        {product.name}
      </Link>
      <table className="product-tile__info">
        <tbody>
          <tr>
            <td
              className={`product-tile__info-label ${
                composeContentType() ? 'pb4' : ''
              }`}
            >
              <span>Creator:</span>
            </td>
            <td
              className={`product-tile__info-value ${
                composeContentType() ? 'pb4' : ''
              }`}
            >
              <span>
                <Link to={creator.url} className="link font-weight-500">
                  {creator.name}
                </Link>
              </span>
            </td>
          </tr>
          <tr>
            <td className="product-tile__info-label">
              <span>
                {composeContentType() ? 'Content type:' : ''}
                {!composeContentType() && (
                  <span style={{ height: '20px' }}></span>
                )}
              </span>
            </td>
            <td className="product-tile__info-value">
              <span>
                {composeContentType() ? composeContentType().name : ''}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <StarRating rating={product.rating} />
      <div className="h-divider h-divider--vertical-margin"></div>
      {isAdded ? (
        <div className="f jcc">
          <DropdownControl buttonLabel="Added">
            <MenuList>
              <CustomMenuItem onClick={deleteProduct}>Remove</CustomMenuItem>
            </MenuList>
          </DropdownControl>
        </div>
      ) : (
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={addProduct}
          disabled={!rest.canAdd}
        >
          Add
        </Button>
      )}
    </div>
  )
}

export default ProductsPickerListItem
