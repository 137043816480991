import styled from "@emotion/styled"
import { colors } from "../../../library/constants/styles/colors"
import PlainLink from "../../ui/link"
import { isLast } from "../../../library/utils/arrays"
import FileItem from "../../formComponents/fileLoader/fileItem"
import { clsx } from "clsx"

const Container = styled.table``

const Row = styled.tr`
  vertical-align: top;
`

const Label = styled.td`
  max-width: 180px;
  min-width: 110px;

  padding-bottom: ${(props) => (!props.isLast ? "15px" : 0)};
  color: ${colors.black54};
`

const Value = styled.td`
  padding-bottom: ${(props) => (!props.isLast ? "15px" : 0)};
  padding-left: 30px;
`

const InfoTable = ({ data = [], dense = false }) => {
  const composeValue = (record) => {
    if (record.text) return record.text

    if (record.textList) return ""

    if (record.link) {
      const link = record.link
      return (
        <PlainLink
          native={record.link.external || false}
          to={link.to}
          newTab={link.newTab || false}
          inline
        >
          {link.text}
        </PlainLink>
      )
    }

    if (record.links) {
      return record.links.map((link, i) => {
        const url = typeof link === "string" ? link : link.url
        return (
          <PlainLink
            native={record.external || false}
            to={url}
            newTab={record.newTab || false}
            style={{ marginBottom: !isLast(record.links, i) ? 5 : 0 }}
            key={url + i}
          >
            {record.linkTitleKey ? link[record.linkTitleKey] : url}
          </PlainLink>
        )
      })
    }

    if (record.files) {
      return record.files.map((file, i) => (
        <FileItem
          name={file.name}
          size={file.size}
          url={file.url}
          key={file.id}
          containerProps={{
            style: { marginBottom: !isLast(record.files, i) ? 5 : 0 },
          }}
        />
      ))
    }

    if (record.component) return record.component

    return "—"
  }

  const records = data?.filter(Boolean)

  return data.length ? (
    <div
      className={clsx(
        "grid grid-cols-[minmax(110px,_max-content),_1fr]",
        dense ? "gap-x-4 gap-y-1" : "gap-x-8 gap-y-2"
      )}
    >
      {records.map((record, i) => {
        return (
          <div
            className={"grid grid-cols-subgrid col-span-2 items-end"}
            key={record.label}
          >
            <span className={"text-black54"}>{record.label}:</span>
            <div>{composeValue(record)}</div>
          </div>
        )
      })}
    </div>
  ) : null
}

export default InfoTable
