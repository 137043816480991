import React, { Component } from "react"
import "./productInfo.scss"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../../../library/constants/routes"
import { productContentTypes } from "../../../../../library/constants/productDictionary"
import StarRating from "../../../../../components/ui/starRating/starRating"
import { Tooltip } from "@material-ui/core"
import InfoIcon from "@material-ui/icons/Info"
import { colors } from "../../../../../library/constants/styles/colors"

class ProductInfo extends Component {
  composeContentType = () => {
    const { content_type } = this.props.products.currentProduct

    return productContentTypes.filter((item) => {
      return item.id === content_type
    })[0]
  }

  render() {
    const {
      creator_user,
      creator_group,
      content_type,
      rating,
      reviews_number,
      paid,
      price,
      group_price,
      others_price,
      pricing_levels,
    } = this.props.products.currentProduct
    const creator = creator_user
      ? {
          name: `${creator_user.first_name} ${creator_user.last_name}`,
          url: generatePath(routes.USER, { userId: creator_user.id }),
        }
      : {
          name: creator_group.name,
          url: generatePath(routes.GROUP, { groupId: creator_group.id }),
        }
    // const hasPrice = (!!price && price > 0)
    //     || (!!group_price && group_price > 0)
    //     || (!!others_price && others_price > 0);

    const hasPrice =
      (!!price && price > 0) || (!!pricing_levels && !!pricing_levels.length)

    return (
      <div className="box product-main-info">
        <div className="box__content">
          <table className="product-main-info__table mb8">
            <tbody>
              <tr>
                <td className="product-main-info__table-label pb8">Creator:</td>
                <td className="product-main-info__table-value pb8">
                  <Link to={creator.url} className="link link--inline">
                    {creator.name}
                  </Link>
                </td>
              </tr>
              {!!content_type && (
                <tr>
                  <td className="product-main-info__table-label">
                    Content type:
                  </td>
                  <td className="product-main-info__table-value">
                    {this.composeContentType().name}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="f aic">
            <StarRating rating={rating} starSize={20} />
            <span className="ml8">{reviews_number}</span>
          </div>
        </div>
        {hasPrice && paid && (
          <>
            <div className="h-divider h-divider--horizontal-margin"></div>
            <div className="box__content">
              <div className="product-price">
                {price > 0 && (
                  <div className="product-price__row">
                    <span className="product-price__label">Minimal price:</span>
                    <span className="product-price__value">
                      ${price.toFixed(2)}
                    </span>
                  </div>
                )}
                {!!pricing_levels &&
                  !!pricing_levels.length &&
                  pricing_levels.map((level, index) => (
                    <div className="product-tile__price" key={index}>
                      <span className="product-tile__price-label">
                        Price {index + 1}:
                      </span>
                      <div className="product-tile__price-value f aic">
                        <span>${level.price.toFixed(2)}</span>
                        <Tooltip title={level.description} arrow>
                          <InfoIcon
                            style={{
                              marginLeft: 5,
                              fontSize: 20,
                              color: colors.primary,
                              cursor: "pointer",
                            }}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ auth, products }) => ({ auth, products })
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ProductInfo))
