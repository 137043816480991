import React from "react"
import BoxPage from "../../../../../../components/v2/layout/boxPage/boxPage"
import SuperDataTable from "../../../../../../components/v2/dataTable/SuperDataTable"
import { KNOWMIX } from "../../../../../../library/store/actions/types/knowmixTypes"
import GroupLink from "../../../../../../components/v2/utils/GroupLink"
import UserLink from "../../../../../../components/v2/utils/UserLink"
import { getObjectById } from "../../../../../../library/utils/arrays"
import { calendarGrades } from "../../../../../../library/constants/educationalDictionary"
import { knowmixTypes } from "../../../../../../library/constants/knowmix"
import { generatePath } from "react-router-dom"
import * as routes from "../../../../../../library/constants/routes"
import { KeyboardArrowRightOutlined } from "@material-ui/icons"
import FormControl from "@material-ui/core/FormControl/FormControl"
import { InputLabel } from "@material-ui/core"
import Select from "@material-ui/core/Select/Select"
import MenuItem from "@material-ui/core/MenuItem"

const TeacherTrackingCoursesManaged = () => {
  const [type, setType] = React.useState("")

  return (
    <BoxPage title={"Managed Courses"}>
      <SuperDataTable
        columns={[
          { label: "Name", role: "name" },
          { label: "Created by" },
          { label: "Subject" },
          { label: "", role: "actions" },
        ]}
        queryConfig={{
          key: [
            {
              type: "teacher-courses-tracking",
              url: KNOWMIX.LIST,
              fetchParams: {
                managed: true,
                active: true,
                finished: false,
                graded: false,
                types: type ? [Number(type)] : undefined,
              },
            },
          ],
          listKey: "knowmixes",
          counterKey: "knowmixes_number",
          composeRowData: (record) => [
            record.name || "",
            {
              type: "component",
              component: record.creator_group
                ? () => <GroupLink group={record.creator_group} />
                : () => <UserLink user={record.creator_user} />,
            },
            {
              type: "component",
              component: () => {
                return (
                  <p>
                    {record?.subject?.id ? (
                      <>
                        <span className={""}>{record?.subject?.name}</span>
                        <br />
                        <span className={"text-xs"}>
                          Grade:{" "}
                          {
                            getObjectById(calendarGrades, record.subject.grade)
                              .name
                          }
                        </span>
                      </>
                    ) : (
                      <span className={"color-black-54"}>—</span>
                    )}
                  </p>
                )
              },
            },
          ],
        }}
        customColumnsWidth
        isSearchable
        actions={(record) => [
          {
            type: "link",
            path: generatePath(routes.KNOWMIX, { knowmixId: record.id }),
            label: () => (
              <>
                Open Course <KeyboardArrowRightOutlined />
              </>
            ),
          },
        ]}
      />
    </BoxPage>
  )
}

export default TeacherTrackingCoursesManaged
