import React, { useState } from "react"

const cutText = (text, charsLimit) =>
  text.length > charsLimit ? text.slice(0, charsLimit).concat("...") : text

export const useExpandableText = ({ message, limit }) => {
  const [text, setText] = useState({
    value: "",
    canExpand: false,
    canCollapse: false,
  })

  React.useEffect(() => {
    setText({
      value: cutText(message, limit),
      canExpand: message.length > limit,
      canCollapse: false,
    })
  }, [message])

  const expandText = () => {
    setText({
      canExpand: false,
      canCollapse: true,
      value: message,
    })
  }

  const collapseText = () => {
    setText({
      canExpand: true,
      canCollapse: false,
      value: cutText(message, limit),
    })
  }

  return {
    text: text.value,
    canExpand: text.canExpand,
    canCollapse: text.canCollapse,
    expand: expandText,
    collapse: collapseText,
  }
}
