import React from "react"
import { TextValidator } from "react-material-ui-form-validator"
import Select from "@material-ui/core/Select/Select"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import DeleteForeverOutlined from "@material-ui/icons/DeleteForeverOutlined"
import AddOutlined from "@material-ui/icons/AddOutlined"

export const letters = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
]

const MatchingQuestionConstructor = (props) => {
    return (
        <>
            <div className="form__fieldset">
                <label className="form__label">Statements *</label>
                <div className="form__fieldbox">
                    <div className="form__input-group" style={{width: 330}}>
                        {props.questions.map((item, index) => (
                            <div className="form__controllable-input" key={`statement-${index}`}>
                                <div className="form__input">
                                    <div className="f aib">
                                        <span className={"mr5"}>{index + 1}. </span>
                                        <TextValidator
                                            name={`statement-${index + String(new Date())}`}
                                            value={item.value}
                                            placeholder={"Type statement"}
                                            className="edit-form__input"
                                            margin="normal"
                                            fullWidth
                                            multiline
                                            validators={["required", "maxStringLength:250"]}
                                            errorMessages={["Field is required", "Max length is 250 characters"]}
                                            onChange={(item) => {
                                                props.handleQuestionChange(index, item.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <button
                                    className="form__manage-input"
                                    type="button"
                                    onClick={() => props.deleteQuestion(index)}
                                >
                                    <DeleteForeverOutlined/> Delete
                                </button>
                            </div>
                        ))}
                    </div>
                    {props.questions.length < 10 && (
                        <button
                            className="form__add-input"
                            type="button"
                            onClick={props.addQuestion}
                        >
                            <AddOutlined/> Add statement
                        </button>
                    )}
                </div>
            </div>

            <div className="form__fieldset">
                <label className="form__label">Matchers *</label>
                <div className="form__fieldbox">
                    <div className="form__input-group" style={{width: 330}}>
                        {props.answers.map((item, index) => (
                            <div className="form__controllable-input" key={`answer-${index}`}>
                                <div className="form__input">
                                    <div className="f aib">
                                        <span className={"mr5"}>{letters[index]}. </span>
                                        <TextValidator
                                            name={`answer-${index + String(new Date())}`}
                                            value={item.value}
                                            placeholder={"Type matcher"}
                                            className="edit-form__input"
                                            margin="normal"
                                            fullWidth
                                            multiline
                                            validators={["required", "maxStringLength:250"]}
                                            errorMessages={["Field is required", "Max length is 250 characters"]}
                                            onChange={(item) => {
                                                props.handleAnswerChange(index, item.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <button
                                    className="form__manage-input"
                                    type="button"
                                    onClick={() => props.deleteAnswer(index)}
                                >
                                    <DeleteForeverOutlined/> Delete
                                </button>
                            </div>
                        ))}
                    </div>
                    {props.answers.length < 15 && (
                        <button
                            className="form__add-input"
                            type="button"
                            onClick={props.addAnswer}
                        >
                            <AddOutlined/> Add matcher
                        </button>
                    )}
                </div>
            </div>

            {!!props.questions.length && !!props.answers.length && (
                <div className="form__fieldset">
                    <label htmlFor="answer" className="form__label f aic">
                        Right answers *
                    </label>
                    <div className="form__fieldbox">
                        {props.questions.map((item, index) => (
                            <div className="f aib mb20" key={index}>
                                <span className={'mr5'}>{index + 1}: </span>
                                    <Select
                                        id={"right-answer-" + index}
                                        name={"rightAnswer_" + index}
                                        fullWidth
                                        displayEmpty
                                        value={item.right_answer}
                                        renderValue={value => {
                                            if (!Number.isInteger(value)) {
                                                return (
                                                    <span className="color-black-38">
                                                        Select answer
                                                    </span>
                                                );
                                            }

                                            return letters[value];
                                        }}
                                        onChange={e => props.handleChangeMatching(e, index)}
                                    >
                                        <MenuItem value={''}>
                                            <span className={"color-black-38"}>Select answer</span>
                                        </MenuItem>
                                        {props.answers.map((item, index) => (
                                            <MenuItem
                                                value={index}
                                                key={letters[index]}
                                                disabled={props.questions.map(q => q.right_answer).includes(index)}
                                            >
                                                {letters[index]}
                                            </MenuItem>
                                        ))}
                                    </Select>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default MatchingQuestionConstructor;
