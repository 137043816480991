import React from "react"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import { Button } from "@material-ui/core"
import { AddOutlined, DeleteForeverOutlined } from "@material-ui/icons"
import getErrorText from "../../../../../../../library/constants/errorTypes"
import { withSnackbar } from "notistack"
import { urlValidation } from "../../../../../../../library/utils/validators"

class GroupSettingsAttachmentsLinks extends React.Component {
  state = {
    links: [
      {
        name: "",
        url: "",
      },
    ],
  }

  componentDidMount() {
    const { links } = this.props.groups.currentGroup

    if (links && links.length) {
      this.setState({ links })
    }

    ValidatorForm.addValidationRule("isLink", (value) => urlValidation(value))
  }

  handleChange = (name, index) => (event) => {
    const { links } = this.state
    links[index][name] = event.target.value
    this.setState({ links })
  }

  addLink = () => {
    const { links } = this.state
    links.push({
      name: "",
      url: "",
    })
    this.setState({ links })
  }

  deleteLink = (index) => {
    const { links } = this.state
    links.splice(index, 1)
    this.setState({ links })
  }

  saveChanges = async () => {
    const { updateGroup, groups } = this.props
    const { links } = this.state
    const { id, videos, images, blobs } = groups.currentGroup
    const attachments = [
      ...images.map((i) => i.id),
      ...videos.map((v) => v.id),
      ...blobs.map((b) => b.id),
    ]
    try {
      await updateGroup({
        id,
        attachments,
        links: links.map((link) => {
          return { ...link, created: undefined }
        }),
      })
      await this.props.enqueueSnackbar("Information updated", {
        variant: "success",
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  render() {
    const { links } = this.state
    return (
      <div className="box">
        <div className="box__heading">Links</div>
        <div className="box__content">
          <ValidatorForm className="form" onSubmit={this.saveChanges}>
            {links.map(({ name, url }, index) => (
              <div key={index}>
                <div className="form__fieldset">
                  <label htmlFor="name" className="form__label">
                    Link name *
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__controllable-input">
                      <div className="form__input">
                        <TextValidator
                          id="name"
                          name="name"
                          value={name}
                          placeholder={`Type link name`}
                          margin="normal"
                          fullWidth
                          validators={[
                            "required",
                            "minStringLength:3",
                            "maxStringLength:100",
                          ]}
                          errorMessages={[
                            "Field is required",
                            "Min length is 3 characters",
                            "Max length is 100 characters",
                          ]}
                          withRequiredValidator
                          onChange={this.handleChange("name", index)}
                        />
                      </div>
                      <button
                        className="form__manage-input"
                        type="button"
                        onClick={() => this.deleteLink(index)}
                      >
                        <DeleteForeverOutlined /> Delete
                      </button>
                    </div>
                  </div>
                </div>

                <div className="form__fieldset">
                  <label htmlFor="link" className="form__label">
                    Link *
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="link"
                        name="link"
                        value={url}
                        placeholder={`Type link`}
                        margin="normal"
                        fullWidth
                        multiline
                        validators={["required", "isLink"]}
                        errorMessages={["Field is required", "Invalid url"]}
                        withRequiredValidator
                        onChange={this.handleChange("url", index)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <Button
              color="primary"
              variant="contained"
              type="submit"
              className="form__submit mb15"
            >
              Save
            </Button>
            <div className="form__divider"></div>

            {!(links.length >= 30) && (
              <div className="f jcc">
                <button
                  type="button"
                  className="btn btn--plain"
                  onClick={this.addLink}
                >
                  <AddOutlined /> Add
                </button>
              </div>
            )}
          </ValidatorForm>
        </div>
      </div>
    )
  }
}


export default withSnackbar(GroupSettingsAttachmentsLinks);
