export const KNOWMAP = {
  KNOWMEET: {
    TEACHER_LIST: "knowmap.uknows.list",
    LEARNER_LIST: "knowmap.learner.uknows.list",
    TEACHER_LIST_CLEAR: "knowmap.uknows.list_clear",
    LEARNER_LIST_CLEAR: "knowmap.learner_uknow.list_clear",
    GET: "knowmap.uknow.get",
    CLEAR_CURRENT: "knowmap.uknow.clear_current",
    UPDATE: "knowmap.uknows.update",
    DELETE: "knowmap.uknows.delete",
    MARK_AS_DONE: "classes.uknows.library.complete",
    TEACHER_KNOWMIX_LIST: "knowmap.knowmix.uknows.list",
    TEACHER_KNOWMIX_LIST_CLEAR: "knowmap.knowmix.uknows.list_clear",
    LEARNER_KNOWMIX_LIST: "knowmap.learner.knowmix.uknows.list",
    LEARNER_KNOWMIX_LIST_CLEAR: "knowmap.learner.knowmix.uknows.list_clear",
  },
  KNOWMIX: {
    TEACHER_LIST: "knowmap.knowmixes.list",
    TEACHER_WORKS_LIST: "knowmap.knowmixes.works.list",
    LEARNER_LIST: "knowmap.learner.knowmixes.list",
    LEARNER_WORKS_LIST: "knowmap.learner.knowmixes.works.list",
    TEACHER_LIST_CLEAR: "knowmap.teacher_knowmix.list_clear",
    TEACHER_WORKS_LIST_CLEAR: "knowmap.teacher_knowmix.works.list_clear",
    LEARNER_LIST_CLEAR: "knowmap.learner_knowmix.list_clear",
    LEARNER_WORKS_LIST_CLEAR: "knowmap.learner_knowmix.works.list_clear",
  },
  KNOWCRED: {
    TEACHER_LIST: "knowmap.knowcreds.list",
    LEARNER_LIST: "knowmap.learner.knowcreds.list",
    TEACHER_LIST_CLEAR: "knowmap.teacher_knowcred.list_clear",
    LEARNER_LIST_CLEAR: "knowmap.learner_knowcred.list_clear",
  },
  BOOKINGS: {
    INCOMING_LIST: "book_appointments.list",
    INCOMING_LIST_CLEAR: "book_appointments.list_clear",
    OUTGOING_LIST: "book_appointments.list_creator",
    OUTGOING_LIST_CLEAR: "book_appointments.list_creator_clear",
  },
}
