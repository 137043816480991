import React from "react"
import { useCommentCtx } from "./CommentContext"
import CommentUpdate from "./commentUpdate"
import CommentHeader from "./components/commentHeader"
import CommentFooter from "./components/commentFooter"
import CommentContent from "./components/commentContent"
import CommentRestore from "./components/commentRestore"
import RepliesList from "../reply/repliesList"

const Comment = ({ index }) => {
  const { isEdit, isArchived } = useCommentCtx()

  if (isArchived) return <CommentRestore />

  return (
    <div className="post-comment">
      {!!index && <div className="h-divider h-divider--comment" />}
      <div className="post-comment__body">
        <CommentHeader />
        <div className="post-comment__offset-wrapper">
          {!isEdit ? <CommentContent /> : <CommentUpdate />}
          <CommentFooter />
        </div>
      </div>

      <RepliesList />
    </div>
  )
}

export default Comment
