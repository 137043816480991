import { useReplyCtx } from "./ReplyContext"
import React from "react"
import ReplyHeader from "./components/replyHeader"
import ReplyContent from "./components/replyContent"
import ReplyUpdate from "./replyUpdate"
import ReplyFooter from "./components/replyFooter"
import ReplyRestore from "./components/replyRestore"

const Reply = () => {
  const { isEdit, isArchived } = useReplyCtx()

  if (isArchived) return <ReplyRestore />

  return (
    <div className="post-reply">
      <div className="h-divider h-divider--reply" />
      <ReplyHeader />

      <div className="post-reply__offset-wrapper">
        {!isEdit ? <ReplyContent /> : <ReplyUpdate />}
        <ReplyFooter />
      </div>
    </div>
  )
}

export default Reply
