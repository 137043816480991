import useEntityPicker from "../../../../hooks/useEntityPicker"
import PickerSelect from "../select/pickerSelect"
import { GROUPS } from "../../../../library/store/actions/types/groupsTypes"
import { useEffect } from "react"
import { getGroupLogo } from "../../../../library/utils/getGroupLogo"
import SuperSelect from "../select/superSelect"

const SpacePicker = ({
  groupId,
  value,
  onChange,
  hasError = false,
  errorMessage,
  required = false,
  multiple = false,
  requestParams = {},
  // requestConfig = {},
  getRequestConfig,
  emptyListMessage,
  fieldName = "spaces",
  entityName = "space",
  disabled = false,
  isWaitingForOtherFields = false,
}) => {
  const requestConfig = {
    fetchConfig: {
      apiUrl: GROUPS.SPACES.LIST,
      params: {
        school_id: groupId,
        offset: 0,
        limit: 100,
      },
    },
    key: "spaces",
    loadOnMount: !!groupId,
  }

  return (
    <SuperSelect
      value={value}
      onChange={onChange}
      requestConfig={requestConfig}
      fieldName={fieldName}
      entityName={entityName}
      required={required}
      multiple={multiple}
      hasError={hasError}
      errorMessage={errorMessage}
      emptyListMessage={emptyListMessage}
      disabled={disabled}
      isWaitingForOtherFields={isWaitingForOtherFields}
      isWaitingForOtherFieldsMessage={"First select school"}
    />
  )
}

export default SpacePicker
