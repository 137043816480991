export const withoutSpaces = str => !str.includes(' ')

export const passwordValidation = (password, confirmPassword) => {
  return (!!password.lenght && !!confirmPassword.length) && password === confirmPassword
}

export const urlValidation = (value) => {
  if (value.length > 0) {
    return /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/.test(value)
  } else {
    return true
  }
}

export const phoneValidation = (value) => {
  if (value.length > 0) {
    return /^[0-9\+]{1,}[0-9\-]{3,15}$/.test(value)
  } else {
    return true
  }
}

export const phoneRegEx = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
