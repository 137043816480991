import React, { useState } from "react"
import { usePostCtx } from "./PostContext"
import styled from "@emotion/styled"
import { colors } from "../../../../library/constants/styles/colors"
import { RestorePost } from "./components"
import { PostContent, PostFooter, PostHeader } from "./layout"
import BookmarksProvider from "../../../../hooks/modals/useBookmarksModal"
import {
  BOOKMARKS_PEOPLE,
  BOOKMARKS_POSTS,
} from "../../../../library/constants/routes"
import { mutationTypes } from "../../../../library/utils/reactQuery"
import ComplaintsProvider from "../../../../hooks/modals/useComplaintsModal"
import PostUpdate from "./components/postUpdate"
import CommentsList from "../comments/commentsList"

const Container = styled.div`
  padding: 15px;
  border-radius: 2px;
  background-color: ${colors.white};
  box-shadow: 0 0 2px 0 rgba(210, 210, 210, 0.5);
`

const Post = ({ index }) => {
  const { post, queryKey, isArchived, isEdit } = usePostCtx()

  if (isArchived) return <RestorePost />

  const isRepost = !!(post.origin_user || post.origin_group)

  return (
    <BookmarksProvider
      keyToUpdate={queryKey}
      params={{ post_id: post.id }}
      pathToBookmarks={BOOKMARKS_POSTS}
      mutationType={mutationTypes.infinite}
    >
      <ComplaintsProvider
        entityUpdater={(p) => ({ ...p, is_reported: true })}
        keyToUpdate={queryKey}
        params={{ post_id: post.id }}
        mutationType={mutationTypes.infinite}
      >
        <Container>
          <PostHeader />
          {!isEdit ? <PostContent isRepost={isRepost} /> : <PostUpdate />}
          <PostFooter />
          <div className="h-divider h-divider--vertical-margin" />
          <CommentsList />
        </Container>
      </ComplaintsProvider>
    </BookmarksProvider>
  )
}

export default Post
