export const BOOKMARKS = {
    LIST: "bookmarks.list",
    SEARCH: "bookmarks.search",
    GET: "bookmarks.get",
    GET_FOLDERS: "bookmarks.listFolders",
    CLEAR_FOLDERS: "bookmarks.clearFolders",
    CREATE: "bookmarks.create",
    DELETE: "bookmarks.delete",
    CLEAR: "bookmarks.clear",

    FOLDERS: {
        GET: "bookmarks.folders.get",
        CREATE: "bookmarks.folders.create",
        UPDATE: "bookmarks.folders.update",
        DELETE: "bookmarks.folders.delete",
        LIST: "bookmarks.folders.list",
        BIND: "bookmarks.folders.bind",
        UNBIND: "bookmarks.folders.unbind",
    },
};
