import useEntityPicker from "../../../../hooks/useEntityPicker"
import { useEffect } from "react"
import PickerSelect from "../select/pickerSelect"

const KnowmixPicker = ({
  value,
  onChange,
  groupId,
  hasError = false,
  errorMessage,
  required = false,
  multiple = false,
  apiUrl = "",
  emptyListMessage,
  emptyListLink,
}) => {
  const { fetchList, isWaitingForOtherFields, list, dataReceived } =
    useEntityPicker({
      fetchConfig: {
        apiUrl,
        params: {
          group_id: groupId,
          finished: false,
          offset: 0,
          limit: 100,
        },
      },
      key: "knowmixes",
      loadOnMount: false,
    })

  useEffect(() => {
    if (groupId) {
      fetchList()
    }
  }, [groupId])

  return (
    <PickerSelect
      name={"course"}
      entityName={"Course"}
      value={value}
      onChange={onChange}
      dataReceived={dataReceived}
      required={required}
      hasError={hasError}
      errorMessage={errorMessage}
      disabled={!dataReceived}
      list={list}
      isWaitingForOtherFields={isWaitingForOtherFields}
      isWaitingForOtherFieldsMessage={"First select school"}
      emptyListMessage={emptyListMessage}
      emptyListLink={emptyListLink}
    />
  )
}

export default KnowmixPicker
