import { MEETS, UKNOW } from "../actions/types/uknowTypes"
import { LIMIT } from "../../constants/limits"
import { editArrayRecord } from "../../utils/arrays"
import { generatePath } from "react-router-dom"
import * as routes from "../../constants/routes"
import { getUserName } from "../../utils/user/userUtils"
import { safeFormatDate } from "../../utils/dateConvert"

const initialState = {
  stepList: [],
  stepListEndReached: false,
  currentStepUknow: null,
  classList: [],
  classListEndReached: false,
  currentClassUknow: null,
  userUknowsList: [],
  userUknowsListEndReached: false,
  currentUserUknow: null,
  managedMeets: {
    list: [],
    total: 0,
    availableOffsets: [0],
  },
  incomingMeets: {
    list: [],
    total: 0,
    availableOffsets: [0],
  },
  currentMeet: null,
}

const generateOffsets = (limit, total) => {
  const arr1 = Array(total).fill(0)
  const arr2 = arr1.map((_, i) => i)

  return arr2.filter((item) => item === 0 || item % limit === 0)
}

export const uknowReducer = (state = initialState, action) => {
  switch (action.type) {
    case MEETS.CREATE:
    case MEETS.UPDATE:
    case MEETS.DELETE:
      return {
        ...state,
      }

    case MEETS.ACCEPT:
      return {
        ...state,
        incomingMeets: {
          ...state.incomingMeets,
          list: state.incomingMeets.list.map((m) =>
            m.public_meeting_id === action.payload.public_meeting_id
              ? { ...m, accepted: true }
              : m
          ),
        },
      }

    case MEETS.DECLINE:
      return {
        ...state,
        incomingMeets: {
          ...state.incomingMeets,
          list: state.incomingMeets.list.map((m) =>
            m.public_meeting_id === action.payload.public_meeting_id
              ? { ...m, declined: true }
              : m
          ),
        },
      }

    case MEETS.GET:
      return {
        ...state,
        currentMeet: action.payload,
      }

    case MEETS.CLEAR_CURRENT:
      return {
        ...state,
        currentMeet: null,
      }

    case MEETS.CLEAR_CREATOR_LIST:
      return {
        ...state,
        managedMeets: {
          list: [],
          total: 0,
          availableOffsets: [0],
        },
      }

    case MEETS.CREATOR_LIST:
      return {
        ...state,
        managedMeets: {
          total: action.payload.total,
          list: action.payload.list.map((record, index) => ({
            id: record.id,
            raw: record,
            info: [
              record.name || "—",
              record.participants.length,
              !!(record.cohort_current_datetime && record.cohort_current)
                ? `S ${safeFormatDate(record.cohort_current_datetime, {
                    withTime: true,
                  })}`
                : record.target_datetime
                  ? `T ${safeFormatDate(record.target_datetime, {
                      withTime: true,
                    })}`
                  : "—",
              record.location || "—",
              record.conference_link
                ? { type: "external-link", path: record.conference_link }
                : "—",
              record.reminder || "—",
            ],
          })),
          availableOffsets: generateOffsets(
            action.payload.limit,
            action.payload.total
          ),
        },
      }

    case MEETS.CLEAR_LIST:
      return {
        ...state,
        incomingMeets: {
          list: [],
          total: 0,
          availableOffsets: [0],
        },
      }

    case MEETS.LIST:
      return {
        ...state,
        incomingMeets: {
          total: action.payload.total,
          list: action.payload.list.map((record, index) => ({
            id: record.id,
            raw: record,
            info: [
              (record.declined ? "DECLINED " + record.name : record.name) ||
                "—",
              !!(record.cohort_current_datetime && record.cohort_current)
                ? `S ${safeFormatDate(record.cohort_current_datetime, {
                    withTime: true,
                  })}`
                : record.target_datetime
                  ? `T ${safeFormatDate(record.target_datetime, {
                      withTime: true,
                    })}`
                  : "—",
              {
                type: "link",
                path: generatePath(routes.USER, { userId: record.user.id }),
                label: getUserName(record.user),
              },
              record.instructions || "—",
              record.location || "—",
              record.conference_link
                ? { type: "external-link", path: record.conference_link }
                : "—",
            ],
          })),
          availableOffsets: generateOffsets(
            action.payload.limit,
            action.payload.total
          ),
        },
      }

    case UKNOW.STEP_LIST:
      return {
        ...state,
        stepList:
          action.payload.offset === 0
            ? action.payload.list
            : [...state.stepList, ...action.payload.list],
        stepListEndReached:
          !action.payload.list.length || action.payload.list.length < LIMIT,
      }

    case UKNOW.STEP_LIST_CLEAR:
      return {
        ...state,
        stepList: [],
        stepListEndReached: false,
      }

    case UKNOW.STEP_CREATE:
      return {
        ...state,
        stepList: action.payload.updateListAfterCreate
          ? [action.payload.knowmeet, ...state.stepList]
          : state.stepList,
      }

    case UKNOW.STEP_UPDATE:
      return {
        ...state,
        stepList: editArrayRecord(state.stepList, action.payload),
      }

    case UKNOW.STEP_DELETE:
      return {
        ...state,
        stepList: state.stepList.filter((u) => u.id !== action.payload.id),
      }

    case UKNOW.STEP_GET:
      return {
        ...state,
        currentStepUknow: action.payload,
      }

    case UKNOW.STEP_CLEAR_CURRENT:
      return {
        ...state,
        currentStepUknow: null,
      }

    case UKNOW.CLASS_LIST:
      return {
        ...state,
        classList:
          action.payload.offset === 0
            ? action.payload.list
            : [...state.classList, ...action.payload.list],
        classListEndReached:
          !action.payload.list.length || action.payload.list.length < LIMIT,
      }

    case UKNOW.CLASS_LIST_CLEAR:
      return {
        ...state,
        classList: [],
        classListEndReached: false,
      }

    case UKNOW.CLASS_CREATE:
      return {
        ...state,
        classList: [action.payload, ...state.classList],
      }

    case UKNOW.CLASS_UPDATE:
      return {
        ...state,
        classList: editArrayRecord(state.classList, action.payload),
      }

    case UKNOW.CLASS_DELETE:
      return {
        ...state,
        classList: state.classList.filter((u) => u.id !== action.payload.id),
      }

    case UKNOW.CLASS_GET:
      return {
        ...state,
        currentClassUknow: action.payload,
      }

    case UKNOW.CLASS_CLEAR_CURRENT:
      return {
        ...state,
        currentClassUknow: null,
      }

    case UKNOW.LIST:
      return {
        ...state,
        userUknowsList:
          action.payload.offset === 0
            ? action.payload.list
            : [...state.userUknowsList, ...action.payload.list],
        userUknowsListEndReached:
          !action.payload.list.length || action.payload.list.length < LIMIT,
      }

    case UKNOW.LIST_CLEAR:
      return {
        ...state,
        userUknowsList: [],
        userUknowsListEndReached: false,
      }

    case UKNOW.GET:
      return {
        ...state,
        currentUserUknow: action.payload,
      }

    case UKNOW.CLEAR_CURRENT:
      return {
        ...state,
        currentUserUknow: null,
      }

    case UKNOW.MARK_CURRENT_AS_DONE:
      return {
        ...state,
        currentUserUknow: !!state.currentUserUknow
          ? {
              ...state.currentUserUknow,
              is_completed: true,
            }
          : state.currentUserUknow,
      }

    default:
      return state
  }
}
