import React, { Component } from "react"
import "./knowCredCard.scss"
import { generatePath, Link, withRouter } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import FavouriteBorderedOutlined from "@material-ui/icons/FavoriteBorderOutlined"
import FavouriteOutlined from "@material-ui/icons/FavoriteOutlined"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import {
  knowCredLike,
  knowCredUnLike,
} from "../../../../library/store/actions/creators/knowCredsCreators"
import {
  knowCredActivitiesIds,
  knowcredStatusTypes,
  knowCredTypesIds,
} from "../../../../library/constants/knowCred"
import getErrorText from "../../../../library/constants/errorTypes"
import MoreDropdown from "../../../../components/ui/dropdowns/moreDropdown/moreDropdown"
import VerticalMenu from "../../../../components/ui/verticalMenu"
import Button from "@material-ui/core/Button/Button"
import {
  createBookmark,
  deleteBookmark,
  foldersList,
} from "../../../../library/store/actions/creators/bookmarksCreators"
import { sharePost } from "../../../../library/store/actions/creators/postsCreators"
import AddToFolderModal from "../../bookmarks/folders/addToFolder/addToFolder"
import LineSlice from "../../../../components/ui/lineSlice"
import ComplaintsModal from "../../../../components/complaints/complaintsModal"
import LockOutlined from "@material-ui/icons/LockOutlined"
import VerticalCard from "../../../../components/v2/layout/cards/verticalCard"

class KnowCredCard extends Component {
  state = {
    isLiked: this.props.knowCred.is_liked,
    likesNumber: this.props.knowCred.likes_number,
    bookmarked: !!this.props.knowCred.bookmark,
    openAddToFolderDialog: false,
    complaintsModal: false,
    isReported: !!this.props.knowCred.is_reported,
  }

  handleClickOpen = () => {
    this.setState({ openAddToFolderDialog: true })
  }

  handleClose = () => {
    this.setState({ openAddToFolderDialog: false })
  }

  addToBookmarks = async () => {
    const {
      knowCred: { id },
    } = this.props

    try {
      await this.props.createBookmark({ knowcred_id: id })
      await this.props.foldersList()
      this.setState({ bookmarked: true })
      await this.props.enqueueSnackbar("Saved to bookmarks", {
        autoHideDuration: 5000,
        action: (key) => {
          return (
            <>
              <Button
                color="primary"
                onClick={() => {
                  this.props.closeSnackbar(key)
                  this.props.history.push(routes.BOOKMARKS_KNOWCRED)
                }}
              >
                View
              </Button>
              {this.props.bookmarks.folders.length > 0 && (
                <Button color="primary" onClick={this.handleClickOpen}>
                  Add to folder
                </Button>
              )}
            </>
          )
        },
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  deleteBookmark = async () => {
    const {
      knowCred: { id },
    } = this.props

    try {
      await this.props.deleteBookmark({ knowcred_id: id })
      this.setState({ bookmarked: false })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  processLike = async () => {
    const {
      auth: { userData },
      knowCred,
    } = this.props

    try {
      if (this.state.isLiked) {
        this.setState((prevState) => {
          return {
            isLiked: false,
            likesNumber: prevState.likesNumber - 1,
          }
        })
        await this.props.knowCredUnLike({
          token: userData.token,
          knowcred_id: knowCred.id,
        })
      } else {
        this.setState((prevState) => {
          return {
            isLiked: true,
            likesNumber: prevState.likesNumber + 1,
          }
        })
        await this.props.knowCredLike({
          token: userData.token,
          knowcred_id: knowCred.id,
        })
      }
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  processShare = async () => {
    const { knowCred } = this.props
    try {
      await this.props.sharePost({ knowcred_id: knowCred.id })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }
  showComplaintsModal = () => {
    this.setState({ complaintsModal: true })
  }
  hideComplaintsModal = () => {
    this.setState({ complaintsModal: false })
  }

  render() {
    const { knowCred } = this.props
    const { isLiked, likesNumber, bookmarked } = this.state

    const showSubtitleLabel = [3, 4]
    const hideActivity = [0, 1].includes(knowCred.activity)
    const isSubtypeLabel = showSubtitleLabel.includes(knowCred.type)
    const labelClass = isSubtypeLabel ? "knowcred-card__info-label--56" : ""
    const valueClass = isSubtypeLabel ? "knowcred-card__info-value--78" : ""

    const knowCredUrl = generatePath(routes.KNOWCRED_SINGLE, {
      knowCredId: knowCred.id,
    })

    const dropdownMenuItems = [
      !knowCred.can_edit && {
        type: "button",
        action: this.showComplaintsModal,
        label: "Report",
        disabled: this.state.isReported,
      },
      !bookmarked
        ? {
            type: "button",
            action: this.addToBookmarks,
            label: "Add to bookmarks",
          }
        : {
            type: "button",
            action: this.deleteBookmark,
            label: "Remove from bookmarks",
          },
    ]

    return (
      // <GridListItem classes="box">
      //     {this.state.complaintsModal && (
      //         <ComplaintsModal open={this.state.complaintsModal} knowcred_id={knowCred.id}
      //                          onClose={this.hideComplaintsModal}
      //                          callback={() => this.setState({isReported: true})}/>
      //     )}
      <VerticalCard>
        {this.state.complaintsModal && (
          <ComplaintsModal
            open={this.state.complaintsModal}
            knowcred_id={knowCred.id}
            onClose={this.hideComplaintsModal}
            callback={() => this.setState({ isReported: true })}
          />
        )}
        <div className="knowcred-card">
          <div className="knowcred-card__content">
            {!knowCred.public && (
              <div className="knowcred-card__lock-icon">
                <LockOutlined className="color-primary" />
              </div>
            )}
            <div className="knowcred-card__menu">
              <MoreDropdown>
                <VerticalMenu
                  menuItems={dropdownMenuItems}
                  classes={"more-dropdown-menu__list"}
                />
              </MoreDropdown>
            </div>
            <div className="knowcred-card__image">
              <Link to={knowCredUrl}>
                <img
                  src={knowCred.background_image}
                  alt="Credicon background"
                  className="knowcred-card__background"
                  style={{ borderColor: knowCred.border_color }}
                />
                <img
                  src={knowCred.foreground_image}
                  alt="Credicon foreground"
                  className="knowcred-card__foreground"
                />
                {knowCred.status_type !== 0 && (
                  <div
                    className="knowcred-card__validation-label"
                    style={{ backgroundColor: knowCred.border_color }}
                  >
                    {knowcredStatusTypes[knowCred.status_type]}
                  </div>
                )}
              </Link>
            </div>

            <Link to={knowCredUrl} className="knowcred-card__title link">
              {knowCred.title}
            </Link>

            <table className="knowcred-card__info">
              <tbody>
                <tr>
                  <td className={`pb4 knowcred-card__info-label ${labelClass}`}>
                    <span>Type:</span>
                  </td>
                  <td className={`pb4 knowcred-card__info-value ${valueClass}`}>
                    <span>{knowCredTypesIds[knowCred.type]}</span>
                  </td>
                </tr>
                {!hideActivity && (
                  <tr>
                    <td className={`knowcred-card__info-label ${labelClass}`}>
                      <span>
                        {showSubtitleLabel.includes(knowCred.type)
                          ? "Label"
                          : "Activity"}
                        :
                      </span>
                    </td>
                    <td className={`knowcred-card__info-value ${valueClass}`}>
                      <span>{knowCredActivitiesIds[knowCred.activity]}</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <div className="knowcred-card__description">
              <LineSlice text={knowCred.description} linesCount={2} />
            </div>
          </div>
          <div className="knowcred-card__social-control-wrapper jcsb mt8">
            <div
              className="knowcred-card__social-control"
              onClick={this.processLike}
            >
              {isLiked ? (
                <FavouriteOutlined className="color-primary" />
              ) : (
                <FavouriteBorderedOutlined />
              )}
              {likesNumber > 0 && (
                <span className={isLiked ? "color-primary" : ""}>
                  {likesNumber}
                </span>
              )}
            </div>
            {/*<div className="knowcred-card__social-control" onClick={this.processShare}>*/}
            {/*    <ReplyOutlined className={`flip-h mr8 ${knowCred.is_shared ? 'color-primary' : 'color-black-38'}`}/>*/}
            {/*</div>*/}
            {/*<div className="knowcred-card__social-control" onClick={() => this.processLike()}>*/}
            {/*{isLiked ? <FavouriteOutlined/> :*/}
            {/*<FavouriteBorderedOutlined/>}*/}
            {/*{likesCount > 0 && <span className="ml8">{likesCount}</span>}*/}
            {/*</div>*/}
          </div>
        </div>
        {this.state.openAddToFolderDialog && (
          <AddToFolderModal
            open={this.state.openAddToFolderDialog}
            handleClose={this.handleClose}
          />
        )}
      </VerticalCard>

      // </GridListItem>
    )
  }
}

const mapStateToProps = ({ auth, knowCreds, bookmarks }) => ({
  auth,
  knowCreds,
  bookmarks,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      knowCredLike,
      knowCredUnLike,
      createBookmark,
      deleteBookmark,
      sharePost,
      foldersList,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(KnowCredCard)))
