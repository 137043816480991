import React from "react"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import FormRadioGroup from "../../../components/formComponents/formRadioGroup"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import Dialog from "@material-ui/core/Dialog"
import { useSelector } from "react-redux"
import { useSubscriptions } from "../../main/user/userProfileSettings/profileSettingsSubscriptions/useSubscriptions"
import { useAlert } from "../../../hooks/useAlert"

const SubscriptionPeriodFormModal = ({
  open,
  onClose,
  plan,
  currentSubscription,
}) => {
  const { successAlert } = useAlert()
  const { userData } = useSelector(({ auth }) => auth)
  const { openCheckoutPage, applySubscriptionPlan, calcFlatSubscriptionPrice } =
    useSubscriptions()

  const [period, setPeriod] = React.useState("month")

  const [isLoading, setIsLoading] = React.useState(false)

  const onSubmit = async () => {
    setIsLoading(true)
    if (currentSubscription) {
      await applySubscriptionPlan({
        subscription_id: currentSubscription.id,
        plan_id: plan.id,
        price_id: plan.prices[period].id,
      })
      successAlert("Subscription updated")
      onClose()
    } else {
      await openCheckoutPage({
        plan_id: plan.id,
        price_id: plan.prices[period].id,
      })
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle className={"text-center"}>
        Choose subscription period
      </DialogTitle>
      <DialogContent>
        <div className="f jcc">
          <div className="form form--modal">
            <FormRadioGroup
              label={"Subscription schedule"}
              value={period}
              onChange={setPeriod}
              options={[
                { value: "month", label: "Monthly" },
                { value: "year", label: "Yearly" },
              ]}
            />

            <div className="form__fieldset">
              <span className={"form__label"} />
              <div className="form__fieldbox">
                <div className="form__input form__input--select">
                  <p className={"font-weight-500 font-18"}>
                    Total:{" "}
                    {
                      calcFlatSubscriptionPrice({
                        unit_amount: plan.prices[period].unit_amount,
                        period,
                      }).total
                    }
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          onClick={onSubmit}
          disabled={isLoading}
        >
          {!isLoading ? "Subscribe" : "Loading..."}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SubscriptionPeriodFormModal
