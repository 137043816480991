import { call } from "../../../networking/API"
import { APPOINTMENTS, CALENDARS, SCHEDULE } from "../types/calendarTypes"
import { LIMIT } from "../../../constants/limits"
import { MEETS } from "../types/uknowTypes"

export const getCalendarsList = ({
  name,
  district_id,
  not_deleted_only,
  not_completed_only,
  offset = 0,
  limit = LIMIT,
}) => {
  return async (dispatch, getState) => {
    const response = await call(CALENDARS.LIST, {
      token: getState().auth.userData.token,
      name,
      district_id,
      not_deleted_only,
      not_completed_only,
      offset,
      limit,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: CALENDARS.LIST,
        payload: { list: json.calendars, offset },
      })
    } else {
      throw json.error
    }
  }
}

export const createCalendar = ({
  district_id,
  name,
  date_from,
  date_to,
  // spring_break_date_from,
  // fall_break_date_from,
  // spring_break_date_to,
  // fall_break_date_to,
}) => {
  return async (dispatch, getState) => {
    const response = await call(CALENDARS.CREATE, {
      token: getState().auth.userData.token,
      district_id,
      name,
      date_from,
      date_to,
      // spring_break_date_from,
      // spring_break_date_to,
      // fall_break_date_from,
      // fall_break_date_to,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: CALENDARS.CREATE,
        payload: {
          id: json.id,
          district_id,
          name,
          date_from,
          date_to,
          // spring_break_date_from,
          // fall_break_date_from,
          // spring_break_date_to,
          // fall_break_date_to,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const updateCalendar = ({
  id,
  name,
  date_from,
  date_to,
  // spring_break_date_from,
  // fall_break_date_from,
  // spring_break_date_to,
  // fall_break_date_to,
}) => {
  return async (dispatch, getState) => {
    const response = await call(CALENDARS.UPDATE, {
      token: getState().auth.userData.token,
      id,
      name,
      date_from,
      date_to,
      // spring_break_date_from,
      // spring_break_date_to,
      // fall_break_date_from,
      // fall_break_date_to,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: CALENDARS.UPDATE,
        payload: {
          id,
          name,
          date_from,
          date_to,
          // spring_break_date_from,
          // fall_break_date_from,
          // spring_break_date_to,
          // fall_break_date_to,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const getCalendar = ({ id }) => {
  return async (dispatch, getState) => {
    const response = await call(CALENDARS.GET, {
      token: getState().auth.userData.token,
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      dispatch({
        type: CALENDARS.GET,
        payload: json,
      })
      return json
    } else {
      throw json.error
    }
  }
}

export const completeCalendar = ({ id }) => {
  return async (dispatch, getState) => {
    const response = await call(CALENDARS.COMPLETE, {
      token: getState().auth.userData.token,
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: CALENDARS.COMPLETE,
        payload: { id },
      })
    } else {
      throw json.error
    }
  }
}

export const clearCurrentCalendar = () => {
  return async (dispatch) => {
    return dispatch({
      type: CALENDARS.CLEAR_CURRENT_CALENDAR,
    })
  }
}

export const clearCalendarsList = () => {
  return async (dispatch) => {
    return dispatch({
      type: CALENDARS.CLEAR_LIST,
    })
  }
}

export const deleteCalendar = ({ id }) => {
  return async (dispatch, getState) => {
    const response = await call(CALENDARS.DELETE, {
      token: getState().auth.userData.token,
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: CALENDARS.DELETE,
        payload: { id },
      })
    } else {
      throw json.error
    }
  }
}

export const getSchedulesList = ({
  name,
  school_id,
  offset = 0,
  limit = LIMIT,
}) => {
  return async (dispatch, getState) => {
    const response = await call(SCHEDULE.SCHEDULES.LIST, {
      token: getState().auth.userData.token,
      name,
      school_id,
      offset,
      limit,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SCHEDULE.SCHEDULES.LIST,
        payload: { list: json.schedules, offset },
      })
    } else {
      throw json.error
    }
  }
}

export const createSchedule = ({ school_id, calendar_id }) => {
  return async (dispatch, getState) => {
    const response = await call(SCHEDULE.SCHEDULES.CREATE, {
      school_id,
      calendar_id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SCHEDULE.SCHEDULES.CREATE,
      })
    } else {
      throw json.error
    }
  }
}

export const createFullSchedule = ({ school_id, name, date_from, date_to }) => {
  return async (dispatch) => {
    const response = await call(SCHEDULE.SCHEDULES.CREATE, {
      school_id,
      name,
      date_from,
      date_to,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SCHEDULE.SCHEDULES.CREATE,
        payload: {
          id: json.id,
          school_id,
          name,
          date_from,
          date_to,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const updateFullSchedule = ({ id, name, date_from, date_to }) => {
  return async (dispatch) => {
    const response = await call(SCHEDULE.SCHEDULES.UPDATE, {
      id,
      name,
      date_from,
      date_to,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SCHEDULE.SCHEDULES.UPDATE,
        payload: {
          id,
          name,
          date_from,
          date_to,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const createPrivateSchoolSchedule = ({
  school_id,
  name,
  date_from,
  date_to,
  // spring_break_date_from,
  // fall_break_date_from,
  // spring_break_date_to,
  // fall_break_date_to,
}) => {
  return async (dispatch) => {
    const response = await call(SCHEDULE.SCHEDULES.CREATE, {
      school_id,
      name,
      date_from,
      date_to,
      // spring_break_date_from,
      // fall_break_date_from,
      // spring_break_date_to,
      // fall_break_date_to,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SCHEDULE.SCHEDULES.PRIVATE_SCHOOL_CREATE,
        payload: {
          id: json.id,
          school_id,
          name,
          date_from,
          date_to,
          // spring_break_date_from,
          // fall_break_date_from,
          // spring_break_date_to,
          // fall_break_date_to,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const updatePrivateSchoolSchedule = ({
  id,
  name,
  date_from,
  date_to,
  // spring_break_date_from,
  // fall_break_date_from,
  // spring_break_date_to,
  // fall_break_date_to,
}) => {
  return async (dispatch) => {
    const response = await call(SCHEDULE.SCHEDULES.UPDATE, {
      id,
      name,
      date_from,
      date_to,
      // spring_break_date_from,
      // fall_break_date_from,
      // spring_break_date_to,
      // fall_break_date_to,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SCHEDULE.SCHEDULES.UPDATE,
        payload: {
          id,
          name,
          date_from,
          date_to,
          // spring_break_date_from,
          // fall_break_date_from,
          // spring_break_date_to,
          // fall_break_date_to,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const getSchedule = ({ id, school_id }) => {
  return async (dispatch, getState) => {
    const response = await call(SCHEDULE.SCHEDULES.GET, {
      token: getState().auth.userData.token,
      id,
      // school_id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: CALENDARS.GET,
        payload: json,
      })
    } else {
      throw json.error
    }
  }
}

export const clearCurrentSchedule = () => {
  return async (dispatch) => {
    return dispatch({
      type: SCHEDULE.SCHEDULES.CLEAR_CURRENT_SCHEDULE,
    })
  }
}

export const clearSchedulesList = () => {
  return async (dispatch) => {
    return dispatch({
      type: SCHEDULE.SCHEDULES.CLEAR_LIST,
    })
  }
}

export const getSchedulesCalendarsList = ({
  name,
  school_id,
  offset = 0,
  limit = LIMIT,
}) => {
  return async (dispatch, getState) => {
    const response = await call(SCHEDULE.SCHEDULES.CALENDARS.LIST, {
      token: getState().auth.userData.token,
      name,
      school_id,
      offset,
      limit,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SCHEDULE.SCHEDULES.CALENDARS.LIST,
        payload: { list: json.calendars, offset },
      })
    } else {
      throw json.error
    }
  }
}

export const clearSchedulesCalendarsList = () => {
  return async (dispatch) => {
    return dispatch({
      type: SCHEDULE.SCHEDULES.CALENDARS.CLEAR_LIST,
    })
  }
}

export const deleteSchedule = ({ id, isPrivateSchool = false }) => {
  return async (dispatch) => {
    const response = await call(SCHEDULE.SCHEDULES.DELETE, {
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: !isPrivateSchool
          ? SCHEDULE.SCHEDULES.DELETE
          : SCHEDULE.SCHEDULES.PRIVATE_SCHOOL_DELETE,
        payload: { id },
      })
    } else {
      throw json.error
    }
  }
}

export const getScheduleUnitsList = ({
  name,
  subject_id,
  date_from,
  date_to,
  offset = 0,
  limit = LIMIT,
}) => {
  return async (dispatch, getState) => {
    const response = await call(SCHEDULE.UNITS.LIST, {
      token: getState().auth.userData.token,
      subject_id,
      date_from,
      date_to,
      name,
      offset: !(date_from && date_to) ? offset : undefined,
      limit: !(date_from && date_to) ? limit : 100,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SCHEDULE.UNITS.LIST,
        payload: { list: json.units, offset },
      })
    } else {
      throw json.error
    }
  }
}

export const createScheduleUnit = ({
  name,
  subject,
  standards = [],
  description,
  date_from,
  date_to,
  minutes,
  link,
  attachment_id,
}) => {
  return async (dispatch, getState) => {
    const response = await call(SCHEDULE.UNITS.CREATE, {
      name,
      subject_id: subject.id,
      standards,
      description,
      date_from,
      date_to,
      minutes,
      link,
      attachment_id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SCHEDULE.UNITS.CREATE,
        payload: {
          id: json.id,
          name,
          subject,
          standards,
          date_from,
          date_to,
          minutes,
          description,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const updateScheduleUnit = ({
  id,
  name,
  subject,
  grade,
  standards = [],
  description,
  date_from,
  date_to,
  minutes,
  link,
  attachment_id,
}) => {
  return async (dispatch, getState) => {
    const response = await call(SCHEDULE.UNITS.UPDATE, {
      token: getState().auth.userData.token,
      id,
      name,
      grade,
      standards,
      description,
      date_from,
      date_to,
      minutes,
      link,
      attachment_id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SCHEDULE.UNITS.UPDATE,
        payload: {
          id,
          name,
          subject,
          grade,
          standards,
          date_from,
          date_to,
          minutes,
          description,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const getScheduleUnit = ({ id }) => {
  return async (dispatch, getState) => {
    const response = await call(SCHEDULE.UNITS.GET, {
      token: getState().auth.userData.token,
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SCHEDULE.UNITS.GET,
        payload: json,
      })
    } else {
      throw json.error
    }
  }
}

export const deleteScheduleUnit = ({ id }) => {
  return async (dispatch, getState) => {
    const response = await call(SCHEDULE.UNITS.DELETE, {
      token: getState().auth.userData.token,
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SCHEDULE.UNITS.DELETE,
        payload: { id },
      })
    } else {
      throw json.error
    }
  }
}

export const clearScheduleUnitsList = () => {
  return async (dispatch) => {
    return dispatch({
      type: SCHEDULE.UNITS.CLEAR_LIST,
    })
  }
}

export const getScheduleSubjectsList = ({
  name,
  calendar_id,
  schedule_id,
  offset = 0,
  limit = LIMIT,
}) => {
  return async (dispatch, getState) => {
    const response = await call(SCHEDULE.SUBJECTS.LIST, {
      token: getState().auth.userData.token,
      name,
      calendar_id,
      schedule_id,
      offset,
      limit,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SCHEDULE.SUBJECTS.LIST,
        payload: { list: json.subjects, offset },
      })
    } else {
      throw json.error
    }
  }
}

export const createScheduleSubject = ({
  calendar_id,
  schedule_id,
  name,
  grade,
  // description,
}) => {
  return async (dispatch) => {
    const response = await call(SCHEDULE.SUBJECTS.CREATE, {
      calendar_id,
      schedule_id,
      name,
      grade,
      // description,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SCHEDULE.SUBJECTS.CREATE,
        payload: {
          id: json.id,
          calendar_id,
          name,
          grade,
          // description,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const updateScheduleSubject = ({
  id,
  name,
  grade,
  // description,
}) => {
  return async (dispatch, getState) => {
    const response = await call(SCHEDULE.SUBJECTS.UPDATE, {
      token: getState().auth.userData.token,
      id,
      name,
      grade,
      // description,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SCHEDULE.SUBJECTS.UPDATE,
        payload: {
          id,
          name,
          grade,
          // description,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const getScheduleSubject = ({ id }) => {
  return async (dispatch, getState) => {
    const response = await call(SCHEDULE.SUBJECTS.GET, {
      token: getState().auth.userData.token,
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SCHEDULE.SUBJECTS.GET,
        payload: json,
      })
    } else {
      throw json.error
    }
  }
}

export const deleteScheduleSubject = ({ id }) => {
  return async (dispatch, getState) => {
    const response = await call(SCHEDULE.SUBJECTS.DELETE, {
      token: getState().auth.userData.token,
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SCHEDULE.SUBJECTS.DELETE,
        payload: { id },
      })
    } else {
      throw json.error
    }
  }
}

export const clearCurrentScheduleSubject = () => {
  return async (dispatch) => {
    return dispatch({
      type: SCHEDULE.SUBJECTS.CLEAR_CURRENT_SUBJECT,
    })
  }
}

export const clearScheduleSubjectsList = () => {
  return async (dispatch) => {
    return dispatch({
      type: SCHEDULE.SUBJECTS.CLEAR_LIST,
    })
  }
}

export const getHoldsList = ({
  name,
  school_id,
  offset = 0,
  limit = LIMIT,
}) => {
  return async (dispatch, getState) => {
    const response = await call(CALENDARS.HOLDS.LIST, {
      // user_id: getState().auth.userData.id,
      name,
      school_id,
      offset,
      limit,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: CALENDARS.HOLDS.LIST,
        payload: { list: json.holds, offset },
      })
    } else {
      throw json.error
    }
  }
}

export const clearHoldsList = () => {
  return async (dispatch) => {
    return dispatch({
      type: CALENDARS.HOLDS.CLEAR_LIST,
    })
  }
}

export const createHold = ({
  name,
  school_id,
  time_from,
  time_to,
  is_all_day,
  class_id,
  days_of_week,
  space_id,
  breaks = [],
}) => {
  return async (dispatch) => {
    const response = await call(CALENDARS.HOLDS.CREATE, {
      name,
      school_id,
      time_from,
      time_to,
      is_all_day,
      class_id,
      days_of_week,
      // space_id,
      breaks,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: CALENDARS.HOLDS.CREATE,
        payload: {
          id: json.id,
          name,
          time_from,
          time_to,
          is_all_day,
          class_id,
          days_of_week,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const updateHold = ({
  id,
  name,
  time_from,
  time_to,
  is_all_day,
  days_of_week,
  space_id,
  breaks = [],
}) => {
  return async (dispatch) => {
    const response = await call(CALENDARS.HOLDS.UPDATE, {
      id,
      name,
      time_from,
      time_to,
      is_all_day,
      days_of_week,
      space_id,
      breaks,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: CALENDARS.HOLDS.UPDATE,
        payload: {
          id,
          name,
          time_from,
          time_to,
          is_all_day,
          days_of_week,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const deleteHold = ({ id }) => {
  return async (dispatch) => {
    const response = await call(CALENDARS.HOLDS.DELETE, {
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: CALENDARS.HOLDS.DELETE,
        payload: { id },
      })
    } else {
      throw json.error
    }
  }
}

export const getCalendarEventsList = ({
  date_from,
  date_to,
  group_id,
  group_ids,
}) => {
  return async (dispatch, getState) => {
    const response = await call(CALENDARS.EVENTS.LIST, {
      date_from,
      date_to,
      group_id,
      group_ids,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: CALENDARS.EVENTS.LIST,
        payload: json,
      })
    } else {
      throw json.error
    }
  }
}

export const getCalendarEvent = ({ id, entityType = "events" }) => {
  const getEndpoint = () => {
    switch (entityType) {
      case "events":
        return CALENDARS.EVENTS.GET
      case "appointments":
        return CALENDARS.APPOINTMENTS.GET
      case "public_meetings":
        return MEETS.GET
      case "book_appointments":
        return APPOINTMENTS.GET
      default:
        return CALENDARS.EVENTS.GET
    }
  }
  return async (dispatch, getState) => {
    const response = await call(getEndpoint(), {
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: CALENDARS.EVENTS.GET,
        payload: json,
      })
    } else {
      throw json.error
    }
  }
}

export const clearCurrentCalendarEvent = () => {
  return async (dispatch) => {
    return dispatch({
      type: CALENDARS.EVENTS.CLEAR_CURRENT_EVENT,
    })
  }
}

export const clearCalendarEvents = () => {
  return async (dispatch) => {
    return dispatch({
      type: CALENDARS.EVENTS.CLEAR_LIST,
    })
  }
}

export const createCalendarEvent = ({
  title,
  description,
  date_from,
  date_to,
  is_all_day,
  links,
  invitees,
  attachments,
  color,
  group_id,
  is_holiday = false,
  location,
  conference_link,
}) => {
  return async (dispatch, getState) => {
    const response = await call(CALENDARS.APPOINTMENTS.CREATE, {
      title,
      description,
      date_from,
      date_to,
      is_all_day,
      links,
      invitees,
      attachments,
      color,
      group_id,
      is_holiday,
      location,
      conference_link,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: CALENDARS.EVENTS.CREATE,
        payload: {
          id: json.id,
          title,
          description,
          date_from,
          date_to,
          is_all_day,
          links,
          invitees,
          attachments,
          color,
          location,
          conference_link,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const updateCalendarEvent = ({
  id,
  title,
  description,
  date_from,
  date_to,
  is_all_day,
  links,
  participants,
  attachments,
  color,
  is_holiday = false,
}) => {
  return async (dispatch, getState) => {
    const response = await call(CALENDARS.APPOINTMENTS.UPDATE, {
      id,
      title,
      description,
      date_from,
      date_to,
      is_all_day,
      links,
      participant_ids: participants,
      attachments,
      color,
      is_holiday,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: CALENDARS.EVENTS.UPDATE,
        payload: {
          id,
          title,
          description,
          date_from,
          date_to,
          is_all_day,
          links,
          participants,
          attachments,
          color,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const deleteCalendarEvent = ({ id }) => {
  return async (dispatch, getState) => {
    const response = await call(CALENDARS.EVENTS.DELETE, {
      token: getState().auth.userData.token,
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: CALENDARS.EVENTS.DELETE,
        payload: { id },
      })
    } else {
      throw json.error
    }
  }
}

export const getScheduleForManage = ({ section_id, offset }) => {
  return async (dispatch) => {
    const response = await call(SCHEDULE.SCHEDULES.GET_FOR_MANAGE, {
      section_id,
      offset,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: SCHEDULE.SCHEDULES.GET_FOR_MANAGE,
        payload: json,
      })
    } else {
      throw json.error
    }
  }
}
