import React from "react"
import { generatePath, Link, useParams } from "react-router-dom"
import Loader from "../../../../components/ui/loader"
import BoxPage from "../../../../components/v2/layout/boxPage/boxPage"
import DOMPurify from "dompurify"
import { twc } from "react-twc"
import {
  PageContentLayout,
  PageMain,
  PageRightSidebar,
} from "../../../../components/v2/layout/layout"
import VerticalMenu from "../../../../components/ui/verticalMenu"
import * as routes from "../../../../library/constants/routes"
import HelpOutlineIcon from "@material-ui/icons/HelpOutline"
import { useHowTo } from "../useHowTo"

export const RenderedArticle = twc.article`prose prose-lg prose-a:text-primary prose-li:marker:text-primary prose-blockquote:border-primary50`

const HowToArticlePage = () => {
  const { categoryId } = useParams()

  const { categoriesQuery, articlesQuery, articleQuery } = useHowTo({
    categories: true,
    articles: true,
    article: true,
  })

  const menuItems = articlesQuery?.data?.posts?.map((article) => ({
    id: article.id,
    label: article.title,
    path: generatePath(routes.HOW_TO_ARTICLE, {
      categoryId,
      articleId: article.id,
    }),
  }))

  const currentCategory =
    categoryId !== "all"
      ? categoriesQuery?.data?.categories?.find(
          (category) => category.id === Number(categoryId)
        )
      : { name: "All articles", count: categoriesQuery.data?.all_posts_count }

  return (
    <PageContentLayout>
      <PageMain>
        {articleQuery.isSuccess ? (
          <BoxPage title={articleQuery.data.title}>
            <RenderedArticle
              className={"mx-auto"}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(articleQuery.data.content),
              }}
            />
          </BoxPage>
        ) : (
          <Loader />
        )}
      </PageMain>
      <PageRightSidebar>
        {categoriesQuery.isSuccess && (
          <div className="box aside-header">
            <Link
              to={generatePath(routes.HOW_TO_CATEGORY, { categoryId })}
              className="box__content aside-header__content"
            >
              <div className="flex items-center justify-center mr-4">
                <HelpOutlineIcon
                  style={{ fontSize: "2.5rem" }}
                  color={"primary"}
                />
              </div>
              <div className="aside-header__info">
                <div className="aside-header__name">{currentCategory.name}</div>
                <div className="aside-header__help">back to page</div>
              </div>
            </Link>
          </div>
        )}
        {articlesQuery.isSuccess && (
          <div>
            <VerticalMenu
              menuItems={menuItems}
              title={`Other in ${categoriesQuery.isSuccess ? currentCategory.name : "category"}`}
            />
          </div>
        )}
      </PageRightSidebar>
    </PageContentLayout>
  )
}

export default HowToArticlePage
