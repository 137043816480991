import React from "react"
import { SectionHeading } from "../uknow/uknowInfoPage"
import img1 from "../../../assets/img/pages/solutions/auto-cohorting/1.jpg"
import img2 from "../../../assets/img/pages/solutions/auto-cohorting/2.png"
import img3 from "../../../assets/img/pages/solutions/auto-cohorting/3.png"
import img4 from "../../../assets/img/pages/solutions/auto-cohorting/4.gif"
import img5 from "../../../assets/img/pages/solutions/auto-cohorting/5.png"
import img6 from "../../../assets/img/pages/solutions/auto-cohorting/6.png"
import img7 from "../../../assets/img/pages/solutions/auto-cohorting/7.png"

const SolutionsAutoCohortingPage = () => {
  return (
    <>
      <section className={"bg-white w-full py-8"}>
        <div className="container">
          <SectionHeading>Auto-Cohorting AI Calendaring</SectionHeading>
          <img src={img1} alt="Feet Up" className={"rounded-2xl mb-8"} />
          <div className="grid grid-cols-12 gap-4">
            <div className="col-start-2 col-span-10">
              <SectionHeading>
                <span className={"italic"}>It's Spatial-Temporal AI</span>
                <br />
                Several Divergent Calendaring Values
              </SectionHeading>
            </div>
          </div>
        </div>
      </section>
      <section className={"bg-grey7 w-full py-8"}>
        <div className="container">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-start-2 col-span-10">
              <p className={"mb-4"}>
                <b>Spatial-temporal means space and time.</b> In the case of
                Knowstory, it is a consumer-facing AI to manage time and space
                in a new way for humans.
              </p>
              <p className={"mb-4"}>
                <b>
                  While Knowstory has many of the same values as regular
                  intelligent calendaring, it also has these:
                </b>
              </p>
              <p className={"mb-4"}>
                <b>
                  Built to Break Whole Groups into Small Groups called Cohorts.
                </b>{" "}
                A long-held dream of educational institutions has been to have
                some help in the logistics of managing smaller groups or
                individuals for their unique pace of learning—and leave the
                manufacturing line structure behind. This has been called
                “Uberization” of learning.
              </p>
              <p className={"ml-8"}>
                <b>
                  Allows one class or group meeting to break into multiple
                  smaller group meetings that each set at different times.
                </b>{" "}
                A “cohort value” entered on a “Meets” form that is far more
                sophisticated than Microsoft Outlook or Google events, which
                functions to dissect a large group into smaller ones.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={"bg-white w-full py-8"}>
        <div className="container">
          <div className="grid grid-cols-3 gap-8">
            <div className="col-span-2">
              <img src={img2} />
            </div>
            <div className="col-span-1">
              <img src={img3} />
            </div>
          </div>
        </div>
      </section>
      <section className={"bg-grey7 w-full py-8"}>
        <div className="container">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-start-2 col-span-10">
              <p className={"mb-4"}>
                <b>
                  Built using sophisticated algorithms found only in Knowstory.
                </b>{" "}
                You get to determine your class Meet parameters, setting a
                number of different values before releasing the Meet to start
                doing its work. It will continue to work setting targets for new
                cohorts indefinitely until you "unrelease" it.
              </p>
              <p className={"mb-4"}>
                <b>Built to Allow Human Differences.</b> Since one Meet is
                actual a multiple-meeting, every invited participant can accept
                the meeting, enrolling in a forthcoming cohort, whenever. When
                receiving the invitation, they will already have a target date,
                but that's not the firm meeting date and time. It's just a
                target. Targets are used to trigger the Al to set a meeting
                whenever the tailing final few cannot "fill" a cohort value
                originally put into the Meet's parameters. That's so they don't
                wait indefinitely.
              </p>
              <p className={"mb-4"}>
                <b>Built with Fine Time Management.</b> Any Meet has parameters
                for better management of the creator's and participant time. It
                allows the creator to require full cohorts only, conserving
                calendar time, which the Al will override only if it cannot fill
                a cohort due to too few remaining participants. If a full cohort
                does cause a meeting to set firmly on the calendar, but before
                that actual date and time one of the participants pushes it
                forward and takes themselves out of the cohort due to some
                conflict, the creator's setting could cause the Al to rescind
                all participants and again await a full cohort before re-setting
                at another time. The creator can also set the meeting to take
                the original set meeting minus one or more of its original
                participants so that they do not wait. A default setting of the
                Al searching all participant calendars for a common time will
                not look beyond seven days from the moment the last person in a
                cohort triggers the Al to search - but creators can alter this
                value higher or lower. No meeting will be set up inside ten
                minutes from the moment the last person in a cohort triggers the
                Al to search so that all the cohort's participants have a chance
                to see the meet has set and get together.
                <br /> This delay of ten minutes can also be altered for
                instances when only a ten-minute delay, were it true that all
                participants had that time open, can instead always delay longer
                to give participants time to walk across long campuses or plan
                for the following day or week.
              </p>
              <p>
                <b>Common Time Al.</b> If the Al finds no common time for one or
                more participants in a cohort with the maker, it will
                automatically push those participants out of the cohort and
                calendar according to other settings. The system will also
                notify both the creator and those participants that no common
                time could be found and that they should adjust parameters such
                as the time Hold they have made available for the Al to seek.
                Creators such as teachers notified of these instances can reach
                out to individuals to ensure that common time is created either
                on their side or the participants - although advanced master
                schedules will usually have already ensured this in schools.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={"bg-white w-full py-8"}>
        <div className="container">
          <SectionHeading>
            Auto-Cohorting of Class Meetings using a Value of 8 Per Cohort
          </SectionHeading>
          <img
            src={img4}
            alt="Auto-Cohoritng visaulization"
            className={"mx-auto"}
          />
        </div>
      </section>
      <section className={"bg-grey7 w-full py-8"}>
        <div className="container">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-start-2 col-span-10">
              <p className={"mb-4"}>
                <b>Coordinated time calculations.</b> The Al is watching as a
                cohort fills, and when it does it coordinates all calendars
                automatically to find a time for everyone in that cohort.
              </p>
              <p className={"mb-4"}>
                <b>Meets set to all calendars with no human intervention.</b>{" "}
                The time selected by the Al will automatically set to all the
                cohort calendars, send notifications, and allow unique "push
                forward" for any one cohort member, accept and decline as
                needed. Each meeting allows the creator to mark attendance.
              </p>
              <p>
                <b>Meets can be tethered into sequence.</b> A course frame can
                put multiple veers in an order so that an entire course can have
                every participant self-pacing and cohorts altering depending on
                any one person's speed of enrollment into the next Meet. A
                "push-forward" feature allows students to join the next cohort,
                but not cancel out entirely. This is handy for situations where
                they decided they needed restudy time, were unpredictably ill or
                had a parent set a conflicting appointment outside of school.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={"bg-white w-full py-8"}>
        <div className="container">
          <img src={img5} alt="Course Meets" />
        </div>
      </section>
      <section className={"bg-grey7 w-full py-8"}>
        <div className="container">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-start-2 col-span-10">
              <p className={"mb-4"}>
                <b>Bookings.</b> Like other intelligent calendars, any Knowstory
                user has a bookings link they can share for others to schedule
                on their calendar and integration through web-hook to Microsoft
                or Google calendars. What's different is that bookings pages are
                on a user's profile readily accessible to anyone they have
                Friended. They just need to look up that person in the site and
                will see a link to book an appointment.
              </p>
              <p>
                <b>Request Appointments.</b> Unlike other intelligent calendars,
                Knowstory users can send requests for amounts of time with no
                assigned date and time. Co-members would just accept or decline,
                and the Al would calendar coordinate and set the appointment.
                Non-mem-bers would be directed to select as if from a bookings
                page or create an account and integrate.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={"bg-white w-full py-8"}>
        <div className="container">
          <img src={img6} alt="Mr. Knowstory" />
        </div>
      </section>
      <section className={"bg-grey7 w-full py-8"}>
        <div className="container">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-start-2 col-span-10">
              <p className={"mb-4"}>
                <b>Holds.</b> A hold is an open time across any multiple of days
                in a week that is bracketed by a start and end time. It may have
                inside itself minus times such as lunch breaks so that the AI
                will know not to set meetings over that time as it is not open.
                Any member may make a hold and assign it to outgoing request
                appointments, offer labeled holds to members and non-members
                booking on their calendar, and use holds in auto-cohorting
                Meets.
              </p>
              <p>
                <b>Timers.</b> Every event booking, appointment request,
                auto-cohorting meet and course step runs timers which allow
                adjudications in the tracking panels.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={"bg-white w-full py-8"}>
        <div className="container">
          <img src={img7} alt="Holds" />
        </div>
      </section>
    </>
  )
}

export default SolutionsAutoCohortingPage
