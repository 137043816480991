import React from "react"
import styled from "@emotion/styled"
import { colors } from "../../../../library/constants/styles/colors"
import InsertDriveFile from "@material-ui/icons/InsertDriveFile"
import { formatBytes } from "../../../../library/utils/fileSizeConverter"

const Container = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
    font-size: 30px;
    color: ${colors.black54};
  }
`

const FileInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const FileName = styled.span`
  display: block;
  font-weight: 500;
  color: ${colors.primary};
  max-width: 250px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const FileSize = styled.span`
  color: ${colors.black54};
`

const FileItem = ({ name, size, url, containerProps = {} }) => {
  return (
    <Container
      as={url ? "a" : undefined}
      href={url ? url : undefined}
      // target={url ? "_blank" : undefined}

      {...containerProps}
    >
      <InsertDriveFile />

      <FileInfo>
        <FileName>{name}</FileName>
        <FileSize>{formatBytes(size)}</FileSize>
      </FileInfo>
    </Container>
  )
}

export default FileItem
