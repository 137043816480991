import React, { useEffect, useState } from "react"
import { useAlert } from "../../../../../../../hooks/useAlert"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import Loader from "../../../../../../../components/ui/loader"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
} from "@material-ui/core"
import { weekDaysArray } from "../../../../../../../library/utils/timeConvert"
import MultipleTimePeriodsPicker from "../../../../../../../components/formComponents/inlineTimePicker/multipleTimePeriodsPicker"
import { useParams } from "react-router-dom"
import { useHold } from "../../../../../../../hooks/data/calendar/useHold"
import TimePeriodPicker from "../../../../../../../components/formComponents/TimePeriodPicker"
import { getLocalTimezoneLabel } from "../../../../../../../library/utils/timeString/timeString"

const CalendarHoldFormModal = ({
  handleClose,
  edit,
  open,
  onSubmit,
  activeHold,
  schoolsList = [],
  user = false,
}) => {
  const { hold } = useHold(
    activeHold,
    {
      enabled: !!activeHold,
    },
    user
  )

  const { errorAlert } = useAlert()
  const params = useParams()

  const [dataReceived, setDataReceived] = useState(!edit)
  const [values, setValues] = useState({
    name: "",
    days_of_week: [new Date().getDay()],
    school: "",
    is_all_day: false,
    hasBreak: false,
    breaks: [],
    user_time_zone: getLocalTimezoneLabel(),
  })

  const [timeFrom, setTimeFrom] = useState("08:00")
  const [timeTo, setTimeTo] = useState("18:00")

  const [errors, setErrors] = useState({
    school: false,
    days: false,
  })

  useEffect(() => {
    if (edit && !dataReceived && !!hold) {
      try {
        setValues({
          ...values,
          name: hold?.name,
          days_of_week: hold?.days_of_week,
          school: schoolsList.find((s) => s.id === hold?.school_id) || "",
          is_all_day: hold?.is_all_day,
          breaks: hold?.breaks || [],
          // user_time_zone: hold.user_time_zone,
        })
        setTimeFrom(hold?.time_from)
        setTimeTo(hold?.time_to)

        setDataReceived(true)
      } catch (e) {
        errorAlert(e)
      }
    }
  }, [hold])

  const handleChangeRequired = (name) => (event) => {
    switch (name) {
      case "school":
        setErrors({
          ...errors,
          school: !event.target.value,
        })
        return setValues({
          ...values,
          school: event.target.value,
        })

      case "days_of_week":
        setErrors((prevState) => ({
          ...errors,
          days: !event.target.value.length,
        }))
        return setValues((prevState) => ({
          ...values,
          days_of_week: event.target.value,
        }))

      default:
        return
    }
  }

  const handleChange = (name) => (event) => {
    if (["is_all_day", "hasBreak"].includes(name)) {
      return setValues({ ...values, [name]: event.target.checked })
    }

    setValues({ ...values, [name]: event.target.value })
  }

  const processFormValidate = () => {}

  const processInfoSave = async () => {
    const school_id = params.groupId
      ? parseInt(params.groupId)
      : values.school.id

    const data = {
      id: edit ? activeHold : undefined,
      school_id: !edit ? school_id : undefined,
      name: values.name,
      is_all_day: values.is_all_day,
      days_of_week: values.days_of_week.slice().sort((a, b) => a - b),
      time_from: values.is_all_day ? undefined : timeFrom,
      time_to: values.is_all_day ? undefined : timeTo,
      breaks: values.breaks,
      user_time_zone: values.user_time_zone,
    }

    try {
      await onSubmit(data)
      handleClose()
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <Dialog
      aria-labelledby="subject-form-dialog"
      onClose={handleClose}
      open={open}
      maxWidth="md"
    >
      <ValidatorForm onSubmit={processInfoSave}>
        <DialogTitle id="alert-dialog-title" className="text-center">
          {edit ? "Edit Hold" : "Create Hold"}
        </DialogTitle>

        {dataReceived ? (
          <>
            <p className={"mb30 text-center"}>
              Time will be saved using your local timezone
            </p>
            <DialogContent>
              <div className="form form--modal">
                <div className="form__fieldset">
                  <label htmlFor="name" className="form__label">
                    Title *
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="name"
                        name="name"
                        value={values.name}
                        placeholder="Type hold title"
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        validators={[
                          "required",
                          "minStringLength:3",
                          "maxStringLength:200",
                        ]}
                        errorMessages={[
                          "Field is required",
                          "Min length is 3 characters",
                          "Max length is 200 characters",
                        ]}
                        withRequiredValidator
                        onChange={handleChange("name")}
                      />
                    </div>
                  </div>
                </div>

                <div className="form__fieldset">
                  <label htmlFor="day" className="form__label">
                    Day *
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input form__input--select">
                      <FormControl error={errors.days} fullWidth>
                        <Select
                          id="day"
                          name="day"
                          fullWidth
                          displayEmpty
                          multiple
                          value={values.days_of_week}
                          onChange={handleChangeRequired("days_of_week")}
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return (
                                <span className="color-black-38">
                                  Select day
                                </span>
                              )
                            }

                            return selected
                              .map((d) => weekDaysArray[Number(d)])
                              .join(", ")
                          }}
                        >
                          {weekDaysArray.map((item, i) => (
                            <MenuItem value={i} key={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.days && (
                          <FormHelperText className="error-message">
                            Field is required
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  </div>
                </div>

                <div className="form__fieldset">
                  <span className="form__label" />
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <FormControl component="fieldset" id="is_all_day">
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={"is_all_day"}
                              checked={values.is_all_day}
                              onChange={handleChange("is_all_day")}
                            />
                          }
                          label={"All day"}
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>

                {!values.is_all_day && (
                  <>
                    <TimePeriodPicker
                      label={"Duration"}
                      required
                      startTime={timeFrom}
                      endTime={timeTo}
                      onChange={({ start, end }) => {
                        console.log({ start, end })
                        setTimeFrom(start)
                        setTimeTo(end)
                      }}
                    />

                    <MultipleTimePeriodsPicker
                      values={values.breaks}
                      onChange={(breaks = []) =>
                        setValues({
                          ...values,
                          breaks,
                        })
                      }
                    />
                  </>
                )}

                {!!user && (
                  <div className="form__fieldset">
                    <span className="form__label">Timezone</span>
                    <div className="form__fieldbox">
                      <div className="form__input pt10">
                        {values.user_time_zone}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                onClick={processFormValidate}
              >
                Save
              </Button>
            </DialogActions>
          </>
        ) : (
          <Loader />
        )}
      </ValidatorForm>
    </Dialog>
  )
}

export default CalendarHoldFormModal
