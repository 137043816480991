import { useQuery } from "@tanstack/react-query"
import { nestAPI } from "../../../library/networking/API"
import { useSelector } from "react-redux"
import React from "react"

export const usePaidPlansQuery = (options = {}) => {
  // React.useEffect(() => {
  //   try {
  //     const getAppointments = async () => {
  //       const res = await call("appointments.list", {})
  //       const data = await res.data
  //       console.log(data)
  //     }
  //
  //     getAppointments()
  //   } catch (e) {
  //     console.log(e)
  //   }
  // })

  const { data, ...query } = useQuery({
    queryKey: ["ALL_PAID_PLANS"],
    queryFn: async () => {
      const response = await fetch(nestAPI + "paid-plans")
      const json = await response.json()

      let list = []

      if (response.ok) {
        list = json.plans
      }

      return list
    },
    staleTime: Infinity,
    ...options,
  })

  return {
    paidPlans: data,
    ...query,
  }
}

export const usePaidPlans = (plan) => {
  const { userData, isLoggedIn, subscriptions } = useSelector(
    ({ auth }) => auth
  )

  const isUserEligible =
    !plan.eligible_user_roles ||
    plan.eligible_user_roles.includes(userData?.role)

  return {
    isUserEligible,
  }
}

export const useTieredPriceCalc = ({ options = [], tiers = [], quantity }) => {
  const tierNumericLimits = tiers
    .map((t) => t.up_to)
    .filter(Boolean)
    .sort((a, b) => a - b)

  const [selectedTier, setSelectedTier] = React.useState(tiers?.[0] ?? null)
  const [selectedSeatsNumber, setSelectedSeatsNumber] = React.useState(
    quantity || options?.[0] || 0
  )
  const [totalPrice, setTotalPrice] = React.useState(0)

  React.useEffect(() => {
    if (quantity) {
      console.log(quantity)
      onSeatsNumberChange({ target: { value: quantity } })
    }
  }, [])

  React.useEffect(() => {
    if (options.length) setSelectedSeatsNumber(options[0])
    if (tiers.length) setSelectedTier(tiers[0])
  }, [tiers, options])

  React.useEffect(() => {
    if (!!selectedTier && !!selectedSeatsNumber) {
      setTotalPrice((selectedTier.unit_amount / 100) * selectedSeatsNumber)
    }
  }, [selectedTier, selectedSeatsNumber])

  const onSeatsNumberChange = (e) => {
    const newSeatsNumber = Number(e.target.value)
    let newTier = null

    for (let i = 0; i < tierNumericLimits.length; i++) {
      if (newSeatsNumber > Math.max(...tierNumericLimits)) {
        newTier = tiers.find((t) => !t.up_to)
        break
      } else if (newSeatsNumber <= tierNumericLimits[i]) {
        newTier = tiers.find((t) => t.up_to === tierNumericLimits[i])
        break
      }
    }

    setSelectedSeatsNumber(newSeatsNumber)
    setSelectedTier(newTier)
  }

  return {
    onSeatsNumberChange,
    selectedTier,
    selectedSeatsNumber,
    totalPrice,
  }
}
