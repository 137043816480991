import React, { useEffect, useRef, useState } from "react"
import styled from "@emotion/styled"

const HiddenContainer = styled.div`
  display: none;
  position: absolute;
  height: auto;
  width: 100%;
  white-space: nowrap;
`

const Container = styled.div`
    width: 100%
`;

const TextContainer = styled.div`
    width: 100%;
`;

const LineSlice = ({text, linesCount = 1}) => {
    const [renderText, setRenderText] = useState("");
    const temp = useRef(), child = useRef();
    useEffect(() => {
        const node = child.current.parentNode;
        const nodeWidth = node.clientWidth;
        const textWidth = temp.current.clientWidth;
        const charWidth = textWidth / text.length + 6;
        const lineWidth = nodeWidth / charWidth;
        const lines = text.length / lineWidth;
        const renderText = lines > linesCount ? text.slice(0, lineWidth * linesCount - 3).trim() + "..." : text;
        setRenderText(renderText);
    }, []);
    return (
        <Container>
            <TextContainer ref={child}>
                {renderText}
                <HiddenContainer ref={temp}>{text}</HiddenContainer>
            </TextContainer>
        </Container>
    );
};

export default LineSlice;
