import { format, formatDistanceToNow } from "date-fns"

const postDateConvert = (date) => {
  const now = new Date().valueOf(),
    thisYear = new Date().getFullYear(),
    postDate = new Date(date).valueOf(),
    postYear = new Date(date).getFullYear()

  if (now - postDate < 6000) return "just now"
  if (now - postDate < 3600000)
    return formatDistanceToNow(new Date(date), { addSuffix: true })
  if (now - postDate < 8640000) return format(new Date(date), "h:mm aaa")
  if (thisYear === postYear)
    return format(new Date(date), "d MMM 'at' h:mm aaa")
  return format(new Date(date), "d MMM yyyy")
}

export default postDateConvert;
