import React from "react"
import { useQuery } from "@tanstack/react-query"
import { CALENDARS } from "../../../../../../library/store/actions/types/calendarTypes"
import { call } from "../../../../../../library/networking/API"
import Dialog from "@material-ui/core/Dialog/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import { safeFormatDate } from "../../../../../../library/utils/dateConvert"
import Loader from "../../../../../../components/ui/loader"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"

const ExternalAppointmentsDialog = ({
  open,
  onClose,
  type,
  activeAppointment,
}) => {
  const apiUrl =
    type === "outlook_events"
      ? CALENDARS.APPOINTMENTS.OUTLOOK.GET
      : CALENDARS.APPOINTMENTS.GOOGLE.GET

  const { data, isSuccess, ...rest } = useQuery({
    queryKey: [apiUrl, { id: activeAppointment }],
    queryFn: async () => {
      const response = await call(apiUrl, {
        id: activeAppointment,
      })
      return await response.data
    },
    refetchOnWindowFocus: false,
  })

  return (
    <Dialog aria-labelledby="event-dialog" onClose={onClose} open={open}>
      <DialogTitle id="alert-dialog-title" className="text-center">
        Appointment details
      </DialogTitle>
      <DialogContent>
        {isSuccess && (
          <div className="f jcc full-width">
            <table className="product-details product-details--invenstory-record">
              <tbody>
                <tr className="product-details__row">
                  <td className="product-details__label">Title</td>
                  <td className="product-details__value">{data.title || data.summary || data.subject}</td>
                </tr>

                <tr className="product-details__row">
                  <td className="product-details__label">Source</td>
                  <td className="product-details__value">
                    {type === "outlook_events" ? "Outlook" : "Google"}
                  </td>
                </tr>

                {!!data.location && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Location</td>
                    <td className="product-details__value">{data.location}</td>
                  </tr>
                )}

                {!!data.date_from && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Start</td>
                    <td className="product-details__value">
                      {safeFormatDate(new Date(data.date_from), {
                        withTime: true,
                      })}
                    </td>
                  </tr>
                )}

                {!!data.date_to && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Finish</td>
                    <td className="product-details__value">
                      {safeFormatDate(new Date(data.date_to), {
                        withTime: true,
                      })}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
        {rest.isLoading && <Loader />}
        {rest.isError && <p>{rest.error}</p>}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ExternalAppointmentsDialog
