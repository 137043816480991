import img1_1 from "../../../assets/img/pages/how-to/beginners/1.png"
import img1_2 from "../../../assets/img/pages/how-to/beginners/2.png"
import img1_3 from "../../../assets/img/pages/how-to/beginners/3.png"
import img1_4 from "../../../assets/img/pages/how-to/beginners/4.png"
import img1_5 from "../../../assets/img/pages/how-to/beginners/5.png"
import img1_6 from "../../../assets/img/pages/how-to/beginners/6.png"
import img1_7 from "../../../assets/img/pages/how-to/beginners/7.png"
import img1_8 from "../../../assets/img/pages/how-to/beginners/8.png"
import img1_9 from "../../../assets/img/pages/how-to/beginners/9.png"
import img1_10 from "../../../assets/img/pages/how-to/beginners/10.png"

import img2_1 from "../../../assets/img/pages/how-to/find-friends-and-add-them/1.png"
import img2_2 from "../../../assets/img/pages/how-to/find-friends-and-add-them/2.png"
import img2_3 from "../../../assets/img/pages/how-to/find-friends-and-add-them/3.png"
import img2_4 from "../../../assets/img/pages/how-to/find-friends-and-add-them/4.png"

import img3_1 from "../../../assets/img/pages/how-to/post-things/1.png"
import img3_2 from "../../../assets/img/pages/how-to/post-things/2.png"

import img4_1 from "../../../assets/img/pages/how-to/join-groups/1.png"

import img6_1 from "../../../assets/img/pages/how-to/bookmarks-&-folders/1.png"
import img6_2 from "../../../assets/img/pages/how-to/bookmarks-&-folders/2.png"
import img6_3 from "../../../assets/img/pages/how-to/bookmarks-&-folders/3.png"
import img6_4 from "../../../assets/img/pages/how-to/bookmarks-&-folders/4.png"
import img6_5 from "../../../assets/img/pages/how-to/bookmarks-&-folders/5.png"
import img6_6 from "../../../assets/img/pages/how-to/bookmarks-&-folders/6.png"
import img6_7 from "../../../assets/img/pages/how-to/bookmarks-&-folders/7.png"
import img6_8 from "../../../assets/img/pages/how-to/bookmarks-&-folders/8.png"
import img6_9 from "../../../assets/img/pages/how-to/bookmarks-&-folders/9.png"
import img6_10 from "../../../assets/img/pages/how-to/bookmarks-&-folders/10.png"
import img6_11 from "../../../assets/img/pages/how-to/bookmarks-&-folders/11.png"
import img6_12 from "../../../assets/img/pages/how-to/bookmarks-&-folders/12.png"
import img6_13 from "../../../assets/img/pages/how-to/bookmarks-&-folders/13.png"
import img6_14 from "../../../assets/img/pages/how-to/bookmarks-&-folders/14.png"
import img6_15 from "../../../assets/img/pages/how-to/bookmarks-&-folders/15.png"
import img6_16 from "../../../assets/img/pages/how-to/bookmarks-&-folders/16.png"
import img6_17 from "../../../assets/img/pages/how-to/bookmarks-&-folders/17.png"
import img6_18 from "../../../assets/img/pages/how-to/bookmarks-&-folders/18.png"

import img7_1 from "../../../assets/img/pages/how-to/claim-a-group-already-present/1.png"
import img7_2 from "../../../assets/img/pages/how-to/claim-a-group-already-present/2.png"
import img7_3 from "../../../assets/img/pages/how-to/claim-a-group-already-present/3.png"

import img8_1 from "../../../assets/img/pages/how-to/create-a-channel/1.png"
import img8_2 from "../../../assets/img/pages/how-to/create-a-channel/2.png"
import img8_3 from "../../../assets/img/pages/how-to/create-a-channel/3.png"

import img10_1 from "../../../assets/img/pages/how-to/events-posting/1.png"
import img10_2 from "../../../assets/img/pages/how-to/events-posting/2.png"
import img10_3 from "../../../assets/img/pages/how-to/events-posting/3.png"
import img10_4 from "../../../assets/img/pages/how-to/events-posting/4.png"

import img11_1 from "../../../assets/img/pages/how-to/groups-creation/1.png"
import img11_2 from "../../../assets/img/pages/how-to/groups-creation/2.png"
import img11_3 from "../../../assets/img/pages/how-to/groups-creation/3.png"

import img12_1 from "../../../assets/img/pages/how-to/cred-creation/1.png"
import img12_2 from "../../../assets/img/pages/how-to/cred-creation/2.png"
import img12_3 from "../../../assets/img/pages/how-to/cred-creation/3.png"
import img12_4 from "../../../assets/img/pages/how-to/cred-creation/4.png"
import img12_5 from "../../../assets/img/pages/how-to/cred-creation/5.png"
import img12_6 from "../../../assets/img/pages/how-to/cred-creation/6.png"
import img12_7 from "../../../assets/img/pages/how-to/cred-creation/7.png"
import img12_8 from "../../../assets/img/pages/how-to/cred-creation/8.png"
import img12_9 from "../../../assets/img/pages/how-to/cred-creation/9.png"
import img12_10 from "../../../assets/img/pages/how-to/cred-creation/10.png"
import img12_11 from "../../../assets/img/pages/how-to/cred-creation/11.png"

import img13_1 from "../../../assets/img/pages/how-to/course-creation/1.png"
import img13_2 from "../../../assets/img/pages/how-to/course-creation/2.png"
import img13_3 from "../../../assets/img/pages/how-to/course-creation/3.png"

import img16_1 from "../../../assets/img/pages/how-to/standards-use/1.png"

import img19_1 from "../../../assets/img/pages/how-to/calendar/1.png"
import img19_2 from "../../../assets/img/pages/how-to/calendar/2.png"
import img19_3 from "../../../assets/img/pages/how-to/calendar/3.png"
import img19_4 from "../../../assets/img/pages/how-to/calendar/4.png"
import img19_5 from "../../../assets/img/pages/how-to/calendar/5.png"
import img19_6 from "../../../assets/img/pages/how-to/calendar/6.png"
import img19_7 from "../../../assets/img/pages/how-to/calendar/7.png"

import MeetTypesOverview from "./meetTypes/MeetTypesOverview"
import HowToMeets from "./meetTypes/HowToMeets"
import HowToKnowmeets from "./meetTypes/HowToKnowmeets"
import HowToAppointments from "./meetTypes/HowToAppointments"

export const howToArticles = [
  {
    title: "Meets vs. Course Meets vs. Appointments",
    label: "Meets/Course Meets/Appointments",
    slug: "meets-vs-course-meets-vs-appointments",
    component: <MeetTypesOverview />,
  },
  {
    title: "Meets",
    slug: "meets",
    isChild: true,
    component: <HowToMeets />,
  },
  {
    title: "Course Meets",
    slug: "course-meets",
    isChild: true,
    component: <HowToKnowmeets />,
  },
  {
    title: "Appointments",
    slug: "appointments",
    isChild: true,
    component: <HowToAppointments />,
  },
  {
    title: "Beginners",
    slug: "beginners",
    content: [
      {
        type: "text",
        value:
          "First, fill out your profile. On your profile page will be a circle that you can hover over with your cursor and click on to put your picture in.",
      },
      {
        type: "images",
        images: [img1_1, img1_2],
      },
      {
        type: "text",
        value:
          "You can also edit the background image by hovering over the camera icon at the top right.",
      },
      {
        type: "image",
        image: img1_3,
      },
      {
        type: "text",
        value:
          "Under your name on the upper right is a drop-down men that goes to “Edit my Profile” and “Settings”.",
      },
      {
        type: "image",
        image: img1_4,
      },
      {
        type: "text",
        value:
          "Under the “Main” page for your Profile is your Favorite quote, First name and Last Name and options for showing on your profile your Main work and Main education. You need to go to the Work and Education subtab on the right and fill those out before you can chose to “show” them on your main page profile. Hit “Save” when you’re done.",
      },
      {
        type: "image",
        image: img1_5,
      },
      {
        type: "text",
        value:
          "Under Contact, put in your Country, State and City if it is not already there from sign-up. Contact phone is optional but important in case we need to use it to help you recover your log-in by sending you a text code. Hit “Save” when you are done.",
      },
      {
        type: "image",
        image: img1_6,
      },
      {
        type: "text",
        value:
          "Under “Education” subtab there are light green subtabs for each level of education to fill out. Notice how you can add another location at the bottom under “Save.” Fill out as much as you can and hit “Save” on each subtab.",
      },
      {
        type: "image",
        image: img1_7,
      },
      {
        type: "text",
        value:
          "Under “Work” subtab there is a place to put in what your work history has been. Notice how you can add another institution and position workplace at the bottom under “Save.” Fill out as much as you can and hit “Save” on each one.",
      },
      {
        type: "image",
        image: img1_8,
      },
      {
        type: "text",
        value:
          "Under “Personal” subtab here is a place for your birthday, interests, favorite subjects (use commas to separate), favorite films, favorite books, and favorite games. Fill out as much as you can and hit “Save” at the bottom.",
      },
      {
        type: "image",
        image: img1_9,
      },
      {
        type: "text",
        value:
          "Now go back to the “Main” subtab on the right and hit “show work” or “show education.” The site will show one or the other on your main profile.\n" +
          "Users visiting your profile can see via the subtabs your Education, Work, and Personal favorites.",
      },
      {
        type: "image",
        image: img1_10,
      },
    ],
  },
  {
    title: "Find friends and add them",
    slug: "find-friends-and-add-them",
    content: [
      {
        type: "text",
        value:
          "Use a profile page to go to Friends.\n" +
          "On the right will be a menu with three options: My Friends which shows your current list of friends (note all new users are friends with the Founders of KnowStory first), Friend Requests where you can allow people to be your friend and Find Friends.",
      },
      {
        type: "image",
        image: img2_1,
      },
      {
        type: "image",
        image: img2_2,
      },
      {
        type: "text",
        value:
          "Find friends and hit “Add” to send them a request to be your friend.\n" +
          "You can also invite friends by adding their email. When they join they will automatically be in your My Friends list.",
      },
      {
        type: "image",
        image: img2_3,
      },
      {
        type: "image",
        image: img2_4,
      },
      {
        type: "text",
        value:
          "The whole Friends section will be getting upgraded with new features to make it easier to find people in your geographic area, by favorites, by title, by profile type (Learner, Educator or Agent) and also recommendations of who to follow that makes frequent great posts, and more. For now, we just have to start making friends with other learners if we are a Learner profile, with other educators if we are an Educator and if an Agent, with other agents and Educators",
      },
    ],
  },
  {
    title: "Post things",
    slug: "post-things",
    content: [
      {
        type: "text",
        value:
          "On your My Page and in the mobile Apps you can post what’s happening and add photos and links. When you post a link to a video it will automatically grab an image. Use this button to put in an image:",
      },
      {
        type: "image",
        image: img3_1,
      },
      {
        type: "text",
        value:
          "Otherwise use the You can also attach documents using the little clip.",
      },
      {
        type: "image",
        image: img3_2,
      },
    ],
  },
  {
    title: "Join groups",
    slug: "join-groups",
    content: [
      {
        type: "text",
        value:
          "Go to Groups and Global Group Search to join groups you are interested in. You can also search for your District or School. Many of the schools and districts do not have anyone running their group yet – but when you send a request to join, we will reach out and let them know you are seeking to join and to Claim their group. This may take a few days. If you are a member of that school or district as a leader you can go directly to the group page and hit “Claim admin rights” so we can check you out to make sure you are who you say you are before giving you the right to run your group.\n" +
          "You can also make your own Interest Group and get others to join it.",
      },
      {
        type: "image",
        image: img4_1,
      },
    ],
  },
  {
    title: "Bookmarks & Folders",
    slug: "bookmarks-&-folders",
    content: [
      {
        type: "text",
        value:
          "Bookmarks is where you keep things for later reference quickly. On your bookmarks tab, see far right sub-tabs for People, Groups, Posts, Creds and Standards. Underneath that list is the Folders function. Let’s work through these in order: People, Groups, Posts, Cred, Standards, and lastly Folders.",
      },
      {
        type: "image",
        image: img6_1,
      },
      {
        type: "text",
        value: "People",
      },
      {
        type: "text",
        value:
          "When you visit any profile, you can not only request for them to be a Friend, but once they’ve accepted that you can visit their profile and Add them to Bookmarks.",
      },
      {
        type: "images",
        images: [img6_2, img6_3],
      },
      {
        type: "text",
        value:
          "Once you’ve bookmarked them, they will appear in the list under People in your Bookmarks.",
      },
      {
        type: "image",
        image: img6_4,
      },
      {
        type: "text",
        value:
          "Once a person is in your bookmarks you can do things like add them to a new Folder, possibly a roster of all your friends in a subject or learners in a class.",
      },
      {
        type: "image",
        image: img6_5,
      },
      {
        type: "text",
        value: "Groups",
      },
      {
        type: "text",
        value:
          "In Bookmark the subtab to the right for Groups will show you all your bookmarked Groups. Groups can be removed from bookmarks here and also added to Folders as needed.",
      },
      {
        type: "images",
        images: [img6_6, img6_7],
      },
      {
        type: "text",
        value:
          "You would add to this list of Groups in your bookmarks while you are looking at Groups or joining them as a member under Groups.",
      },
      {
        type: "image",
        image: img6_8,
      },
      {
        type: "text",
        value: "Posts",
      },
      {
        type: "text",
        value:
          "In Bookmarks, the Posts subtab is where you can save your own or other’s posts for reference later, so they don’t get lost in your feed. You can also save them to Folders in collections you might use for things like research on a topic and more.\n" +
          "When viewing a post, use the “…” menu on the post to view a submenu. Use “Add to bookmarks” to put it into your bookmarks.",
      },
      {
        type: "image",
        image: img6_9,
      },
      {
        type: "text",
        value: "In your bookmarks, view Posts you’ve pinned.",
      },
      {
        type: "image",
        image: img6_10,
      },
      {
        type: "text",
        value: "Cred",
      },
      {
        type: "text",
        value:
          "See section on “Cred Creation” for more information.\n" +
          "To Bookmark a Cred, go to the individual Cred under your Creds tab and click on the “…” symbol. It will show you an “Add to Bookmarks” button to click. This adds that Cred to your Bookmarks.",
      },
      {
        type: "images",
        images: [img6_11, img6_12],
      },
      {
        type: "text",
        value: "Standards",
      },
      {
        type: "text",
        value:
          "To bookmark an academic standard, you will go to the Standards subtab and choose a State and then the Subject and Grade for the Standards. Then use the “…” symbol on each Standard tile to open a submenu with “Add to bookmarks.” Click on that to add to your bookmarks.",
      },
      {
        type: "image",
        image: img6_13,
      },
      {
        type: "text",
        value:
          "Another way to add a Standard to bookmarks is to open the Standard tile to its full page and then hit “Add to bookmarks” on the right-hand navigation menu.",
      },
      {
        type: "image",
        image: img6_14,
      },
      {
        type: "text",
        value: "Folders",
      },
      {
        type: "text",
        value:
          "Folders are found under your bookmarks right menu. You can use the “Create Folder” button to make and name new folders to hold collections of things including anything you’ve bookmarked.",
      },
      {
        type: "image",
        image: img6_15,
      },
      {
        type: "text",
        value:
          "Any of the other things you put into bookmarks can also be organized into folders including People if you want to create a roster folder.",
      },
      {
        type: "images",
        images: [img6_16, img6_17],
      },
      {
        type: "images",
        images: [img6_18],
      },
    ],
  },
  {
    title: "Claim a group already present",
    slug: "claim-a-group-already-present",
    content: [
      {
        type: "text",
        value:
          "KnowStory has a full database of every school and district in the United States pre-loaded with groups already made waiting for someone to claim them as a group leader. If you can’t find yours it may be mis-named in the Federal data sets we used. We can always create a new School, District for you if you request that.",
      },
      {
        type: "image",
        image: img7_1,
      },
      {
        type: "text",
        value:
          "All schools and districts are preset to “closed group” until they are claimed with a request posted to that school or district page:",
      },
      {
        type: "image",
        image: img7_2,
      },
      {
        type: "text",
        value:
          "Companies will also carry a “Claim admin rights” option on their page in case there is a dispute as to who owns the rights to post and gather members for that brand.",
      },
      {
        type: "image",
        image: img7_3,
      },
    ],
  },
  {
    title: "Create a channel",
    slug: "create-a-channel",
    content: [
      {
        type: "text",
        value:
          "Companies who wish to create a Channel that would appear on the main News page as a highlighted group to join need to first create their company Group. Go to Groups on the top navigation and then use the button “Create Group.” It is wise before you do this to see if you already have a Group created so you do not create a duplicate. If for some reason you do have one that you cannot control with admin rights, then use the “Claim admin rights” so that we can look into transferring you the authority over the page.",
      },
      {
        type: "image",
        image: img8_1,
      },
      {
        type: "text",
        value:
          "When you use the Create Group button for a Company you will see this form first to fill out. If you make your Group a “closed” group, then you will get notified each time someone wants to join and need to accept or reject them. Normally news Channels are Open groups.",
      },
      {
        type: "image",
        image: img8_2,
      },
      {
        type: "text",
        value:
          "Once you have created your Group and started posting news in it, you may request a Channel through our “Contact Us” form. We will ask you a few questions about your posting frequency and how the Channel will need to be named as a link.",
      },
      {
        type: "image",
        image: img8_3,
      },
    ],
  },
  {
    title: "Events posting",
    slug: "events-posting",
    content: [
      {
        type: "text",
        value:
          "Events can be posted by any user under the Groups tab. Subtabs for “My events” and “My managed events” appear on a right-hand navigation for Events. Use the “Create Group” button to build a new event.",
      },
      {
        type: "images",
        images: [img10_1, img10_2],
      },
      {
        type: "image",
        image: img10_3,
      },
      {
        type: "text",
        value:
          "After creating your event with the form, you can manage and edit the full page, add photos and post news about the event. The Manage tab will show who has joined your group for discussing your event.",
      },
      {
        type: "image",
        image: img10_4,
      },
    ],
  },
  {
    title: "Groups creation",
    slug: "groups-creation",
    content: [
      {
        type: "text",
        value:
          "On your Profile page you should see on the left side the Groups subtab. Click on that to go to Groups. In the middle at the top should be a Create Group button. Click on that and then select which type of group you are making. It is wise to pre-check by search if your group already exists such as a company, school or district. If it does already exist, then you will want to go to that Group and use the button to “Claim admin rights” to run that group if no one else has or there is an imposter running it.",
      },
      {
        type: "images",
        images: [img11_1, img11_2],
      },
      {
        type: "text",
        value:
          "Each type of group will have a form like the one below to fill out. Once you enter this basic information and hit save you will see the full web page that can be further edited.",
      },
      {
        type: "image",
        image: img11_3,
      },
    ],
  },
  {
    title: "Cred creation",
    slug: "cred-creation",
    content: [
      {
        type: "text",
        value:
          "Cred is a social badging system. When you create a Cred for someone else, they receive it as “Granted” to them. If they are not in KnowStory, you just put their email in and KnowStory invites them and sends them notification they have a Cred waiting. If you make it for yourself, it has no status as granted, awarded or valid. It still appears on your page to show off your accomplishment that you are saying you did.\n" +
          "If someone with authority, like a teacher or a company representative gives you a Cred, it will say “Awarded” on it.\n" +
          "Only credentialed administrators of a school, district or State may give Valid Creds for degrees and diplomas. We are working on allowing all users to make their own Creds for grades, certificates, degrees, and diplomas that can be sent to the institution who gave them that credential in order to get it validated. A key to accomplishing this is getting a ton of those institutions to take over administering their own pages so we can check them out to see they are really the right official to give validation. This could take a while, but we are working on it!",
      },
      {
        type: "text",
        value: "Public Creds",
      },
      {
        type: "text",
        value: "Public Creds will appear on your Profile Page like this:",
      },
      {
        type: "image",
        image: img12_1,
      },
      {
        type: "text",
        value: "Your Creds Tab",
      },
      {
        type: "text",
        value:
          "When you go to your Creds tab, notice there are several subtabs for the types like Granted, Awarded, Valid and also a subtab for keeping track of the ones you have shared to others.",
      },
      {
        type: "image",
        image: img12_2,
      },
      {
        type: "text",
        value: "To make a Cred",
      },
      {
        type: "text",
        value: "Use the “Create Cred” button. You will see this creation page:",
      },
      {
        type: "image",
        image: img12_3,
      },
      {
        type: "text",
        value:
          "You will need to indicate if it is from you or if you are making it from an administrator role of a group you run, like a school, district, company, or interest group. If those Groups are premium members, they will be able to make the higher levels of Creds.",
      },
      {
        type: "image",
        image: img12_4,
      },
      {
        type: "text",
        value:
          "Next you will select who you are making the Cred for. If for yourself, complete the form and your Cred will show up in your Creds tab. If for someone else who is already your friend in KnowStory, a list of those people will come up to choose from like this image below. Click on the name you want.",
      },
      {
        type: "image",
        image: img12_5,
      },
      {
        type: "text",
        value:
          "Note, if the person you want to make Cred for is not in KnowStory already, there is an option to put in their email address. Then KnowStory sends that person an email notification that you have made them a Cred and to please join to see it.\n" +
          "Next you would type the Name of your Cred. We suggest up to 22 characters for that line, but if you need it to go longer that is okay but beyond that number of characters will not show on the Cred tile (badge.)",
      },
      {
        type: "image",
        image: img12_6,
      },
      {
        type: "text",
        value:
          "Next you need to pick which Type of Cred. All users have the ability to make Social or Extra-Curricular Creds.",
      },
      {
        type: "image",
        image: img12_7,
      },
      {
        type: "text",
        value:
          "If your Group is paid for and authorized, you may create Grades/Marks, Certificates and Diplomas and Degrees. Administrators are allowed to create Validated high-level Creds, any authorized Teacher may create Awarded high-level Creds except for Diplomas and Degrees.\n" +
          "When you are picking the Type, you can also pick the credicon from a pre-made set like these ones for Social Cred or upload your own image.",
      },
      {
        type: "image",
        image: img12_8,
      },
      {
        type: "text",
        value:
          "Next you will select the generic location for this Cred, which could be Home, School, Church, Camp, Work District, Association, Group or Other.",
      },
      {
        type: "image",
        image: img12_9,
      },
      {
        type: "text",
        value:
          "Next you need to give your Cred a description of the activities. You have up to 500 words you can put here but only the first few will appear on the small tile. The rest can be viewed on the full page viewing of that Badge.",
      },
      {
        type: "image",
        image: img12_10,
      },
      {
        type: "text",
        value:
          "The final things you do are add any links like another site that shows an original badge with validations or “proof” like a final exam paper or picture you store that can be linked. Then choose a background for your credicon image from the ones given or upload your own. You can also pick a border. Finally, select if you want this kept Private or made Public so others can see it. Hit Save!",
      },
      {
        type: "image",
        image: img12_11,
      },
    ],
  },
  // {
  //   title: "Calendar & Schedule",
  //   slug: "calendar-&-schedule",
  //   content: [
  //     {
  //       type: "text",
  //       value:
  //         "There are two ways to set up to use Knowmeets and Knowmixes with the Calendar. A simple set-up or a full master schedule set-up.",
  //     },
  //     {
  //       type: "image",
  //       image: img18_1,
  //     },
  //     {
  //       type: "text",
  //       value:
  //         "1. Simple Set-Up. One is to Knowmixes which have the option of Knowmeets in any Step.",
  //     },
  //     {
  //       type: "wide-image",
  //       image: img18_2,
  //     },
  //     {
  //       type: "text",
  //       value: "2. Master Schedule Set-Up.",
  //     },
  //     {
  //       type: "wide-image",
  //       image: img18_3,
  //     },
  //     {
  //       type: "text",
  //       value: "Recommendations:",
  //     },
  //     {
  //       type: "list",
  //       values: [
  //         "The labeling of Segments can be for the full year, semi-annual semesters or quarters, whatever break-up of the year by months desired.",
  //         "Carefully label Subjects generally. Individual Kourses and Knowmixes may get more specific in that general subject.",
  //         "Segments set for one week will duplicate over and over every week.",
  //         "Segments can be set for multiple weeks or will alternate.",
  //         "Carefully select how you assign days by in a Block by holding down the control key for multiple days.",
  //         "Carefully check time and a.m. or p.m.",
  //         "Make Breaks their own time block.",
  //         "Consider you Block naming convention so that later assignment of Kourses and Klasses of students to the correct Blocks will be simple. Calling each block of time a “Period” is a good way to go.",
  //         "Try to label Kourses, Knowmixes and Knowmeets to match with their Block by using the same general subject in the name, the grade or level, and the lesson number. In this way your items are easier to find and put in order.",
  //       ],
  //     },
  //     {
  //       type: "text",
  //       value:
  //         "Example:\n" +
  //         "\n" +
  //         "Math Grade 2 Kourse 1\n" +
  //         "\n" +
  //         "    1st Knowmix label: Math Grade 2 Week 1 – Number Recognition\n" +
  //         "\n" +
  //         "        (Step 1 instructions for that lesson)\n" +
  //         "\n" +
  //         "            Knowmeet label: Math Gr.2 Wk 1 – Number Recognition\n" +
  //         "\n" +
  //         "            (Teacher to review numbers 1-10 at meet)\n" +
  //         "\n" +
  //         "        (Step 2 instructions for next part of weeks lesson)\n" +
  //         "\n" +
  //         "            Knowmeet label: Math Gr.2 Wk 1 – Number Recognition\n" +
  //         "\n" +
  //         "            (Teacher to review numbers 11-20 at meet)\n" +
  //         "\n" +
  //         "    2nd Knowmix label: Math Grade 2 Week 2 – Reciting Numbers\n" +
  //         "\n" +
  //         "        (Step 1 instructions for that lesson)\n" +
  //         "\n" +
  //         "             Knowmeet label: Math Gr.2 Wk 2 – Reciting Numbers\n" +
  //         "\n" +
  //         "            (Teacher to review numbers 1-10 forward and backward at meet)\n" +
  //         "\n" +
  //         "        (Step 2 instructions for next part of weeks lesson)\n" +
  //         "\n" +
  //         "            Knowmeet label: Math Gr.2 Wk 2 – Reciting Numbers\n" +
  //         "\n" +
  //         "            (Teacher to review numbers 11-20 forward and backward at meet)",
  //     },
  //   ],
  // },
  {
    title: "Calendar",
    slug: "calendar",
    content: [
      {
        type: "text",
        value:
          "There are two types of calendar: district and school calendars.",
      },
      {
        type: "text",
        value:
          "District calendar.\n" +
          "To create a district calendar, you should go to the district manage and  in the right sidebar, select the menu item calendar.",
      },
      {
        type: "image",
        image: img19_1,
      },
      {
        type: "image",
        image: img19_2,
      },
      {
        type: "text",
        value:
          "You must complete the calendar in order to be able to link it later to the school calendar.",
      },
      {
        type: "image",
        image: img19_3,
      },

      {
        type: "text",
        value:
          "School calendar.\n" +
          "To create a school calendar, you should go to the school manage and  in the right sidebar, select the menu item calendar.",
      },
      {
        type: "image",
        image: img19_4,
      },
      {
        type: "image",
        image: img19_5,
      },
      {
        type: "text",
        value:
          "You can link the district calendar to the school calendar. You should go to district calendars list, choose dictrict calendar and click «Assign to school»",
      },
      {
        type: "image",
        image: img19_6,
      },
      {
        type: "text",
        value:
          "After that, you will see this calendar in the list of school calendars",
      },
      {
        type: "image",
        image: img19_7,
      },
    ],
  },
  {
    title: "Course creation",
    slug: "course-creation",
    content: [
      {
        type: "text",
        value:
          "Find “Manage” to the right of your group profile.\n" +
          "Under Settings find Courses:",
      },
      {
        type: "text",
        value: "Creating a Course in your School group:",
      },
      {
        type: "image",
        image: img13_1,
      },
      {
        type: "image",
        image: img13_2,
      },
      {
        type: "text",
        value: "Under Course subtab",
      },
      {
        type: "text",
        value: "Use the “Create Course” button to launch the form:",
      },
      {
        type: "image",
        image: img13_3,
      },
    ],
  },
  {
    title: "Standards use",
    slug: "standards-use",
    content: [
      {
        type: "text",
        value:
          "The Standards tab on the left-hand navigation bar is populated with the academic standards that are publicly available from States. You can search by State and then Grade and Subject there and open any tile that appears to see the full Standard. You can use the “…” menu on each tile to add to your bookmarks and folders.",
      },
      {
        type: "image",
        image: img16_1,
      },
      {
        type: "text",
        value:
          "When company agents or educators make Courses, they can add Standards into those as well so that learners searching for a Course that would teach them that Standards they can find it.",
      },
    ],
  },
]
