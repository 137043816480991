import React, { useEffect, useState } from "react"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { ThemeProvider } from "@material-ui/styles"
import { createTheme } from "@material-ui/core/styles"
import { appTheme } from "../../../../../library/m-ui-theme"
import { colors } from "../../../../../library/constants/styles/colors"
import { useCalendar } from "../../CalendarContext"
import DateFnsUtils from "@date-io/date-fns"

export const calendarPickerTheme = createTheme({
  ...appTheme,
  overrides: {
    MuiTypography: {
      h4: {
        fontSize: "18px",
      },
      body2: {
        fontSize: 12,
        lineHeight: 1,
      },
    },
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        // minWidth: 176,
        minWidth: 220,
        // maxWidth: 176,
        maxWidth: 220,
        // width: 176,
        width: 220,
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        // minWidth: 176,
        minWidth: 220,
        // maxWidth: 176,
        maxWidth: 220,
        // width: 176,
        width: 220,
        // height: 180,
        minHeight: 195,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        height: "auto",
        padding: "0 0 10px",
        backgroundColor: "#fff",
        minHeight: "auto",
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: colors.black54,
        fontSize: 14,
      },
      toolbarBtnSelected: {
        color: colors.primary,
        fontSize: 16,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        marginTop: 0,
        marginBottom: 10,
      },
      dayLabel: {
        // width: 20,
        width: 25,
      },
      iconButton: {
        padding: 0,
      },
    },
    MuiPickersCalendar: {
      transitionContainer: {
        // height: 115,
        // minHeight: 120,
        minHeight: 130,
      },
    },
    MuiPickersDay: {
      day: {
        width: 25,
        // width: 20,
        height: 25,
        // height: 20,
      },
    },
  },
})

export class WeekDays extends DateFnsUtils {
  getWeekdays() {
    return ["S", "M", "T", "W", "T", "F", "S"]
  }
}

const holidaysOfMonth = [
  { day: 1, name: "New Year" },
  { day: 2, name: "New Year" },
  { day: 7, name: "Orthodox Christmas" },
  { day: 21, name: "Some other long holiday name" },
]

const SmallCalendar = () => {
  const { globalDate, setGlobalDate } = useCalendar()
  const [date, setDate] = useState(globalDate.date)

  useEffect(() => {
    // if (date === globalDate) return;
    if (globalDate.updatedBy === "main" && globalDate.date !== date) {
      setDate(globalDate.date)
    }
  }, [globalDate.date])

  const onDateChange = (d) => {
    setDate(d)
    setGlobalDate({ date: d, updatedBy: "sidebar" })
  }

  const days = holidaysOfMonth.map((day) => day.day)

  return (
    <ThemeProvider theme={calendarPickerTheme}>
      <MuiPickersUtilsProvider utils={WeekDays}>
        <DatePicker
          autoOk
          variant="static"
          openTo="date"
          value={date}
          onChange={onDateChange}
          // renderDay={(day, selectedDay, isInCurrentMonth, dayComponent) => {
          //     const isHighlighted = isInCurrentMonth && days.includes(day.toDate().getDate());
          //
          //     return isHighlighted ? (
          //         React.cloneElement(dayComponent, {style: {backgroundColor: "#d06767", color: "#fff"}})
          //     ): dayComponent;
          // }}
        />
      </MuiPickersUtilsProvider>
      {/*<div className="h-divider h-divider--vertical-margin"></div>*/}
      {/*{holidaysOfMonth.map(holiday => {*/}
      {/*    return <p key={holiday.day}>{holiday.day} - {holiday.name}</p>*/}
      {/*})}*/}
    </ThemeProvider>
  )
}

export default SmallCalendar
