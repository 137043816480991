import { css } from "@emotion/react"
import { colors } from "../library/constants/styles/colors"

const globalStyle = css`
  *,
  *::after,
  *::before {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
  }

  body {
    box-sizing: border-box;
    background-color: ${colors.grey7};
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    color: ${colors.black87};
  }

  a,
  button {
    all: unset;
    box-sizing: border-box;

    :hover {
      cursor: pointer;
    }
  }

  pre {
    font-family: inherit;
    word-break: break-word;
    white-space: pre-wrap;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
`

export const mq = {
  xs: `@media only screen and (max-width: 37.5em)`,
  s: `@media only screen and (max-width: 56.25em)`,
  m: `@media only screen and (max-width: 75em)`,
  xl: `@media only screen and (max-width: 112.5em)`,
}

export default globalStyle
