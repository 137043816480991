import React, { Component } from "react"
import PostCreate from "../../../feed/postCreate"
import PostsList from "../../../feed/postsList"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import {
  clearPostsList,
  getPostsList,
} from "../../../../../library/store/actions/creators/postsCreators"
import { withRouter } from "react-router-dom"
import Loader from "../../../../../components/ui/loader/loader"
import withScroll from "../../../../../hocs/withScroll/withScroll"
import { LIMIT } from "../../../../../library/constants/limits"
import getErrorText from "../../../../../library/constants/errorTypes"

class GroupPosts extends Component {
  state = {
    isClosed: this.props.groups.currentGroup.closed,
    isMember: this.props.groups.currentGroup.is_member,
    isInvited: this.props.groups.currentGroup.is_invited,
    groupInfo: this.props.groups.currentGroup,
    posts: [],
    dataReceived: false,
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props
    const { isClosed, isMember } = this.state
    try {
      if (isMember || !isClosed) {
        await this.props.getPostsList({
          group_id: parseInt(params.groupId),
          offset: 0,
        })
      }
      await this.setStateAsync({
        isClosed: this.props.groups.currentGroup.closed,
        isMember: this.props.groups.currentGroup.is_member,
        dataReceived: true,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const id = parseInt(this.props.match.params.groupId)
    const { isClosed, isMember } = this.state

    if (this.props.match.params.groupId !== prevProps.match.params.groupId) {
      await this.setStateAsync({ dataReceived: false })
      try {
        if (isMember || !isClosed) {
          await this.props.getPostsList({ group_id: id, offset: 0 })
        }
        await this.setState({
          isClosed: this.props.groups.currentGroup.closed,
          isMember: this.props.groups.currentGroup.is_member,
          dataReceived: true,
        })
      } catch ({ error }) {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    } else if (
      this.props.groups.currentGroup.is_member !==
      prevProps.groups.currentGroup.is_member
    ) {
      try {
        if (this.props.groups.currentGroup.is_member) {
          await this.props.clearPostsList()
          await this.props.getPostsList({ group_id: id, offset: 0 })
        }
        await this.setState({
          isClosed: this.props.groups.currentGroup.closed,
          isMember: this.props.groups.currentGroup.is_member,
          dataReceived: true,
        })
      } catch ({ error }) {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    }
  }

  onScroll = async () => {
    if (!this.state.dataReceived) return
    const id = parseInt(this.props.match.params.groupId)
    await this.setState({ dataReceived: false })
    await this.props.getPostsList({
      group_id: id,
      offset: this.props.posts.postsList.length,
      limit: LIMIT,
    })
    await this.setState({ dataReceived: true })
  }

  componentWillUnmount() {
    this.props.clearPostsList()
  }

  render() {
    const { isMember, isClosed, groupInfo, type, dataReceived } = this.state
    const {
      auth: { userData },
    } = this.props
    const id = parseInt(this.props.match.params.groupId)

    const authorGroup = groupInfo.is_admin || groupInfo.is_creator
    return (
      <>
        <div className="posts">
          {isMember && !this.props.groups.currentGroup.tags.includes(1) && (
            <PostCreate
              groupId={id}
              createdByGroup={authorGroup}
              groupInfo={groupInfo}
              type="group"
              isMember={isMember}
            />
          )}
          {(isMember || !isClosed) && (
            <PostsList
              createdByGroup={authorGroup}
              groupId={id}
              groupInfo={groupInfo}
              type="group"
            />
          )}
        </div>
        {!dataReceived && <Loader />}
      </>
    )
  }
}

const mapStateToProps = ({auth, groups, posts}) => ({auth, groups, posts});
const mapDispatchToProps = dispatch => bindActionCreators({getPostsList, clearPostsList}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withSnackbar(withScroll(GroupPosts))));
