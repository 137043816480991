import CalendarLeftSidebar from "./components/calendarLeftSidebar"
import { Route, Switch, useRouteMatch } from "react-router-dom"
import MainCalendar from "./subpages/mainCalendar"
import * as routes from "../../../library/constants/routes"
import VerticalMenu from "../../../components/ui/verticalMenu/verticalMenu"
import CalendarProvider from "./CalendarContext"

const Calendar = () => {
  const { path } = useRouteMatch()

  const menuItems = [
    {
      // path: routes.CALENDAR,
      path: routes.HOME,
      label: "Calendar",
    },
  ].filter(Boolean)

  return (
    <CalendarProvider>
      <div className="common-page common-page--calendar">
        <main className="common-page__main">
          <Switch>
            <Route
              exact
              path={routes.HOME}
              render={(props) => <MainCalendar {...props} noFilters />}
            />
          </Switch>
        </main>
        <aside className="common-page__sidebar">
          <VerticalMenu menuItems={menuItems} />
          {path === routes.HOME && (
            <>
              <CalendarLeftSidebar />
            </>
          )}
        </aside>
      </div>
    </CalendarProvider>
  )
}

export default Calendar
