import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { HOME } from "../../../library/constants/routes"
import React, { useEffect } from "react"
import AccountsInfoMain from "../../info/accounts/accountsInfoMain"
import LandingPage from "../LandingPage"

const AuthHome = () => {
  const { isLoggedIn, userData } = useSelector(({ auth }) => auth)
  const history = useHistory()

  useEffect(() => {
    if (isLoggedIn && userData?.id) {
      history.replace(HOME)
    } else if (isLoggedIn && !userData?.id) {
      localStorage.clear()
      window.location.reload()
    }
  }, [])

  return <LandingPage />
}

export default AuthHome
