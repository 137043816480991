import React, { Component } from "react"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import Dialog from "@material-ui/core/Dialog/Dialog"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import { withSnackbar } from "notistack"
import { withStyles } from "@material-ui/core"
import getErrorText from "../../../../../library/constants/errorTypes"

const CustomDialogTitle = withStyles({
  root: {
    paddingBottom: 0,
  },
})(DialogTitle)

class DeleteUserProfile extends Component {
    state = {
        password: "",
    };

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleClose = async () => {
        this.props.onClose();
    };

    processUserDelete = async () => {
        const {password} = this.state;

        try {
            await this.props.deleteUser(password);
            await this.props.clearChatsCount();
            await this.handleClose();
            await this.props.signOut();
        } catch ({error}) {
            this.props.enqueueSnackbar(getErrorText(error.code), {variant: "error"});
        }
    };

    render() {
        const {password} = this.state;

        return (
            <Dialog
                open={this.props.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <CustomDialogTitle id="alert-dialog-title">Delete page from site?</CustomDialogTitle>
                <ValidatorForm onSubmit={this.processUserDelete}>
                    <DialogContent>
                        <p className="color-black-54 font-16 mb25">
                            If you delete the page you will not be able to view information on the site.
                        </p>

                        <div className="form form--modal">
                            <div className="form__fieldset">
                                <label htmlFor="password" className="form__label">Password *</label>
                                <div className="form__fieldbox">
                                    <div className="form__input">
                                        <TextValidator
                                            id="password"
                                            name="password"
                                            type="password"
                                            value={password}
                                            placeholder="Type your account password"
                                            className="edit-form__input"
                                            margin="normal"
                                            fullWidth
                                            validators={[
                                                "required",
                                            ]}
                                            errorMessages={[
                                                "Field is required",
                                            ]}
                                            onChange={this.handleChange("password")}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Decline
                        </Button>
                        <Button type="submit" color="primary" autoFocus>
                            Accept
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        );
    }
}

export default withSnackbar(DeleteUserProfile);
