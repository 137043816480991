import React from "react"
import { useCurrentUser } from "../../../../hooks/data/user/useUser"
import { useIdParam } from "../../../../hooks/useIdParam"
import { useCommentsMutations } from "../../../../hooks/data/feed/comments"
import { useAlert } from "../../../../hooks/useAlert"
import { usePostCtx } from "../post/PostContext"
import { useLikesMutations } from "../../../../hooks/data/likes"
import { mutationTypes } from "../../../../library/utils/reactQuery"

export const CommentContext = React.createContext(null)

export const useCommentCtx = () => {
  return React.useContext(CommentContext)
}

const CommentProvider = ({ comment, children }) => {
  const { errorAlert } = useAlert()
  const { post, queryKey } = usePostCtx()

  const [isEdit, setIsEdit] = React.useState(false)
  const [isArchived, setIsArchived] = React.useState(false)
  const [isReplying, setIsReplying] = React.useState(false)

  const { updateComment, deleteComment, restoreComment } = useCommentsMutations(
    { queryKey }
  )

  const { likes } = useLikesMutations({
    key: queryKey,
    mutationType: mutationTypes.infinite,
  })

  const onLike = async () => {
    if (!comment.is_liked) {
      await likes.create.mutateAsync({
        data: { comment_id: comment.id },
        updater: (p) =>
          p.id === post.id
            ? {
                ...p,
                comments: p.comments.map((c) =>
                  c.id === comment.id
                    ? {
                        ...c,
                        is_liked: true,
                        likes_number: c.likes_number + 1,
                      }
                    : c
                ),
              }
            : p,
      })
    } else {
      await likes.delete.mutateAsync({
        data: { comment_id: comment.id },
        updater: (p) =>
          p.id === post.id
            ? {
                ...p,
                comments: p.comments.map((c) =>
                  c.id === comment.id
                    ? {
                        ...c,
                        is_liked: false,
                        likes_number: c.likes_number - 1,
                      }
                    : c
                ),
              }
            : p,
      })
    }
  }

  const onDelete = async () => {
    try {
      await deleteComment.mutateAsync({ id: comment.id, post_id: post.id })
      setIsArchived(true)
    } catch (e) {
      errorAlert(e)
    }
  }

  const onRestore = async () => {
    try {
      await restoreComment.mutateAsync({ id: comment.id })
      setIsArchived(false)
    } catch (e) {
      errorAlert(e)
    }
  }

  const onUpdate = async ({ message }) => {
    try {
      await updateComment.mutateAsync({
        id: comment.id,
        message,
        post_id: post.id,
      })
      setIsEdit(false)
    } catch (e) {
      errorAlert(e)
    }
  }

  const replyFormId = `reply-form-${post.id}-${comment.id}`

  const onReplyFormOpen = () => {
    setIsReplying(true)
    setTimeout(() => {
      document
        .getElementById(replyFormId)
        .scrollIntoView({ behavior: "smooth", block: "center" })
    }, [200])
  }

  return (
    <CommentContext.Provider
      value={{
        comment,
        isEdit,
        setIsEdit,
        isArchived,
        setIsArchived,
        isReplying,
        setIsReplying,
        onLike,
        onDelete,
        onUpdate,
        onRestore,
        updateComment,
        replyFormId,
        onReplyFormOpen,
      }}
    >
      {children}
    </CommentContext.Provider>
  )
}

export default CommentProvider
