import { call } from "../../../networking/API"
import { COURSE_MEETS, MEETS, UKNOW } from "../types/uknowTypes"
import { LIMIT } from "../../../constants/limits"

export const createMeet = ({
  name,
  cohort_size,
  target_date,
  duration,
  target_interval,
  other_links = [],
  conference_link,
  participants_ids = [],
  invitee_emails = [],
  attachments = [],
  reminder,
  instructions,
  location,
  hold_ids,
  allow_over_cohort,
  require_full_cohort,
}) => {
  return async (dispatch) => {
    const response = await call(MEETS.CREATE, {
      name,
      cohort_size,
      target_datetime: target_date,
      duration,
      target_interval,
      other_links,
      conference_link,
      participant_ids: participants_ids,
      invitee_emails,
      attachments,
      hold_ids,
      reminder,
      instructions,
      location,
      allow_over_cohort,
      require_full_cohort,
    })

    const json = await response.data

    if (response.status === 200) {
      dispatch({
        type: MEETS.CREATE,
        payload: {
          id: json.id,
          name,
          cohort_size,
          target_date,
          duration,
          target_interval,
          other_links,
          conference_link,
          participants_ids,
          invitee_emails,
          attachments,
          reminder,
          instructions,
          allow_over_cohort,
          require_full_cohort,
        },
      })

      return {
        id: json.id,
        name,
        cohort_size,
        target_date,
        duration,
        target_interval,
        other_links,
        conference_link,
        participants_ids,
        invitee_emails,
        attachments,
        reminder,
        instructions,
      }
    } else {
      throw json.error
    }
  }
}

export const updateMeet = ({
  id,
  name,
  cohort_size,
  target_date,
  duration,
  target_interval,
  other_links = [],
  conference_link,
  participants_ids = [],
  invitee_emails = [],
  attachments = [],
  reminder,
  instructions,
  location,
  hold_ids,
  allow_over_cohort,
  require_full_cohort,
}) => {
  return async (dispatch) => {
    const response = await call(MEETS.UPDATE, {
      id,
      name,
      cohort_size,
      target_datetime: target_date,
      duration,
      target_interval,
      other_links,
      conference_link,
      participant_ids: participants_ids,
      invitee_emails,
      attachments,
      hold_ids,
      reminder,
      instructions,
      location,
      allow_over_cohort,
      require_full_cohort,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: MEETS.UPDATE,
        payload: {
          id,
          name,
          cohort_size,
          target_date,
          duration,
          target_interval,
          other_links,
          conference_link,
          participants_ids,
          invitee_emails,
          // attachments,
          reminder,
          instructions,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const getMeet = ({ id }) => {
  return async (dispatch) => {
    const response = await call(MEETS.GET, {
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      dispatch({
        type: MEETS.GET,
        payload: json.public_meeting,
      })
      return json.public_meeting
    } else {
      throw json.error
    }
  }
}

export const clearMeet = () => {
  return async (dispatch) => {
    dispatch({
      type: MEETS.CLEAR_CURRENT,
    })
  }
}

export const clearManagedMeetsList = () => {
  return async (dispatch) => {
    dispatch({
      type: MEETS.CLEAR_CREATOR_LIST,
    })
  }
}

export const deleteMeet = ({ id }) => {
  return async (dispatch) => {
    const response = await call(MEETS.DELETE, {
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      dispatch({
        type: MEETS.DELETE,
        payload: json,
      })
      return json
    } else {
      throw json.error
    }
  }
}

export const managedMeetsList = ({ offset, limit, name }) => {
  return async (dispatch) => {
    const response = await call(MEETS.CREATOR_LIST, {
      offset,
      limit,
      name,
    })

    const json = await response.data

    if (response.status === 200) {
      dispatch({
        type: MEETS.CREATOR_LIST,
        payload: {
          list: json.list_creator,
          offset: 0,
          limit: 0,
          total: json.meets_count,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const incomingMeetsList = ({ offset, limit, name }) => {
  return async (dispatch) => {
    const response = await call(MEETS.LIST, {
      offset,
      limit,
      name,
    })

    const json = await response.data

    console.log(json)

    if (response.status === 200) {
      dispatch({
        type: MEETS.LIST,
        payload: {
          list: json.list,
          offset: 0,
          limit: 0,
          total: json.meets_count,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const clearIncomingMeetsList = () => {
  return async (dispatch) => {
    dispatch({
      type: MEETS.CLEAR_LIST,
    })
  }
}

export const acceptMeet = ({ id }) => {
  return async (dispatch) => {
    const response = await call(MEETS.ACCEPT, {
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: MEETS.ACCEPT,
        payload: {
          id,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const declineMeet = ({ id }) => {
  return async (dispatch) => {
    const response = await call(MEETS.DECLINE, {
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: MEETS.DECLINE,
        payload: {
          id,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const removeMeet = ({ id }) => {
  return async (dispatch) => {
    const response = await call(MEETS.REMOVE, {
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: MEETS.REMOVE,
        payload: {
          id,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const assignHoldToMeet = ({ id, hold_id }) => {
  return async (dispatch) => {
    const response = await call(MEETS.ASSIGN_HOLD, {
      id,
      hold_id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: MEETS.ASSIGN_HOLD,
        payload: {
          id,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const forwardMeet = ({ id, user_id }) => {
  return async (dispatch) => {
    const response = await call(MEETS.FORWARD, {
      id,
      user_id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: MEETS.FORWARD,
        payload: {
          id,
          user_id,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const markMeetParticipantAttendance = ({
  public_meeting_participant_id,
}) => {
  return async (dispatch) => {
    const response = await call(MEETS.ATTEND, {
      public_meeting_participant_id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: MEETS.ATTEND,
        payload: {
          public_meeting_participant_id,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const createKnowmixStepUknow = ({
  name,
  step_id,
  knowmix_id,
  cohort_size,
  date_from,
  date_to,
  hold_id,
  target_date,
  instructions,
  space_id,
  updateListAfterCreate = true,
  reminder,
  links = [],
  subject,
  block_id,
  auto_cancel = false,
  duration = 5,
  retarget_period,
}) => {
  return async (dispatch) => {
    const response = await call(COURSE_MEETS.CREATE, {
      name,
      step_id,
      knowmix_id,
      cohort_size,
      date_from,
      date_to,
      hold_id,
      target_date,
      instructions,
      space_id,
      reminder,
      links,
      subject,
      block_id,
      auto_cancel,
      duration,
    })

    const json = await response.data

    if (response.status === 200) {
      dispatch({
        type: UKNOW.STEP_CREATE,
        payload: {
          updateListAfterCreate,
          knowmeet: {
            id: json.id,
            name,
            step_id,
            cohort_size,
            date_from,
            date_to,
            duration,
          },
        },
      })

      return {
        id: json.id,
        name,
        step_id,
        cohort_size,
        date_from,
        date_to,
      }
    } else {
      throw json.error
    }
  }
}

export const updateKnowmixStepUknow = ({
  id,
  name,
  cohort_size,
  date_from,
  date_to,
  hold_id,
  target_date,
  instructions,
  space_id,
  reminder,
  links = [],
  subject,
  block_id,
  auto_cancel = false,
  duration,
}) => {
  return async (dispatch) => {
    const response = await call(COURSE_MEETS.UPDATE, {
      id,
      name,
      cohort_size,
      date_from,
      date_to,
      hold_id,
      target_date,
      instructions,
      space_id,
      reminder,
      links,
      subject,
      block_id,
      auto_cancel,
      duration,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: UKNOW.STEP_UPDATE,
        payload: {
          id,
          name,
          cohort_size,
          date_from,
          date_to,
          duration,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const deleteKnowmixStepUknow = ({ id }) => {
  return async (dispatch) => {
    const response = await call(COURSE_MEETS.DELETE, {
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: UKNOW.STEP_DELETE,
        payload: {
          id,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const getKnowmixStepUknow = ({ id }) => {
  return async (dispatch) => {
    const response = await call(COURSE_MEETS.GET, {
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      dispatch({
        type: UKNOW.STEP_GET,
        payload: json,
      })

      return json
    } else {
      throw json.error
    }
  }
}

export const knowmixStepUknowsList = ({
  step_id,
  knowmix_id,
  name,
  offset = 0,
  limit = LIMIT,
}) => {
  return async (dispatch) => {
    const response = await call(COURSE_MEETS.LIST, {
      name,
      step_id,
      knowmix_id,
      offset,
      limit,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: UKNOW.STEP_LIST,
        payload: {
          offset,
          list: json.uknows,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const knowmixStepUknowsListClear = () => {
  return async (dispatch) => {
    return dispatch({
      type: UKNOW.STEP_LIST_CLEAR,
    })
  }
}

export const knowmixStepUknowsCurrentClear = () => {
  return async (dispatch) => {
    return dispatch({
      type: UKNOW.STEP_CLEAR_CURRENT,
    })
  }
}

export const createClassUknow = ({
  name,
  description,
  attachments,
  links,
  cohort_size,
  date_from,
  date_to,
  class_id,
  // block_id,
  target_date,
  instructions,
}) => {
  return async (dispatch) => {
    const response = await call(UKNOW.CLASS_CREATE, {
      name,
      description,
      attachments,
      links,
      cohort_size,
      date_from,
      date_to,
      class_id,
      // block_id,
      target_date,
      instructions,
    })

    const json = await response.data

    if (response.status === 200) {
      dispatch({
        type: UKNOW.CLASS_CREATE,
        payload: {
          id: json.id,
          name,
          description,
          links,
          attachments,
          cohort_size,
          date_from,
          date_to,
        },
      })

      return {
        id: json.id,
        name,
        class_id,
        description,
        cohort_size,
        date_from,
        date_to,
        // time,
        // block: json.block
      }
    } else {
      throw json.error
    }
  }
}

export const updateClassUknow = ({
  id,
  name,
  description,
  attachments,
  links,
  cohort_size,
  date_from,
  date_to,
  block_id,
  target_date,
  instructions,
}) => {
  return async (dispatch) => {
    const response = await call(UKNOW.CLASS_UPDATE, {
      id,
      name,
      description,
      attachments,
      links,
      cohort_size,
      date_from,
      date_to,
      block_id,
      target_date,
      instructions,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: UKNOW.CLASS_UPDATE,
        payload: {
          id,
          name,
          description,
          cohort_size,
          date_from,
          date_to,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const deleteClassUknow = ({ id }) => {
  return async (dispatch) => {
    const response = await call(UKNOW.CLASS_DELETE, {
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: UKNOW.CLASS_DELETE,
        payload: {
          id,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const getClassUknow = ({ id }) => {
  return async (dispatch) => {
    const response = await call(UKNOW.CLASS_GET, {
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      dispatch({
        type: UKNOW.CLASS_GET,
        payload: json,
      })

      return json
    } else {
      throw json.error
    }
  }
}

export const classUknowsList = ({
  class_id,
  name,
  offset = 0,
  limit = LIMIT,
}) => {
  return async (dispatch) => {
    const response = await call(UKNOW.CLASS_LIST, {
      name,
      class_id,
      offset,
      limit,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: UKNOW.CLASS_LIST,
        payload: {
          offset,
          list: json.uknows,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const classUknowsListClear = () => {
  return async (dispatch) => {
    return dispatch({
      type: UKNOW.CLASS_LIST_CLEAR,
    })
  }
}

export const classUknowsCurrentClear = () => {
  return async (dispatch) => {
    return dispatch({
      type: UKNOW.CLASS_CLEAR_CURRENT,
    })
  }
}

export const getUserUknow = ({ id }) => {
  return async (dispatch) => {
    const response = await call(UKNOW.GET, {
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      dispatch({
        type: UKNOW.GET,
        payload: json,
      })

      return json
    } else {
      throw json.error
    }
  }
}

export const userUknowsList = ({ name, offset = 0, limit = LIMIT }) => {
  return async (dispatch) => {
    const response = await call(UKNOW.LIST, {
      name,
      offset,
      limit,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: UKNOW.LIST,
        payload: {
          offset,
          list: json.uknows,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const userUknowsListClear = () => {
  return async (dispatch) => {
    return dispatch({
      type: UKNOW.LIST_CLEAR,
    })
  }
}

export const uknowsCurrentClear = () => {
  return async (dispatch) => {
    return dispatch({
      type: UKNOW.CLEAR_CURRENT,
    })
  }
}

export const markUknowAsDone = ({ id }) => {
  return async (dispatch) => {
    const response = await call(UKNOW.MARK_AS_DONE, {
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      dispatch({
        type: UKNOW.MARK_AS_DONE,
        payload: { id },
      })
    } else {
      throw json.error
    }
  }
}

export const markKnowmixKnowmeetAsDone = ({ id }) => {
  return async (dispatch) => {
    const response = await call(UKNOW.KNOWMIX_MARK_AS_DONE, {
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      dispatch({
        type: UKNOW.KNOWMIX_MARK_AS_DONE,
        payload: { id },
      })
      dispatch({ type: UKNOW.MARK_CURRENT_AS_DONE })
    } else {
      throw json.error
    }
  }
}
