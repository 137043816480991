import React from "react"
import UploadProgress from "../../../components/ui/uploadProgress"
import ClearOutlined from "@material-ui/icons/ClearOutlined"
import styled from "@emotion/styled/macro"
import { colors } from "../../../library/constants/styles/colors"
import FileItem from "./fileItem"
import { devices } from "../../../library/constants/styles/responsive"

const Overlay = styled.div`
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.2s;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
`

const ImgContainer = styled.div`
  position: relative;
  margin: 0 5px 5px 0;
  border-radius: 2px;
  overflow: hidden;
  transition: all 0.2s;

  :hover {
    ${Overlay} {
      visibility: visible;
      opacity: 1;
    }
  }
`

const Img = styled.img`
  width: 104px;
  height: 104px;
  object-fit: cover;
  object-position: center;
  margin: 0;
`

const DeleteImg = styled.button`
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: 0;
  color: #fff;

  svg {
    font-size: 19px;
  }
`

const DeleteFile = styled.button`
  display: none;
  align-self: flex-start;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: 0;
  color: ${colors.primary};

  svg {
    font-size: 19px;
  }

  @media ${devices.xs} {
    display: flex;
  }
`

const FileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  flex: 1;

  :not(:last-child) {
    margin-bottom: 8px;
  }

  :hover {
    ${DeleteFile} {
      display: flex;
    }
  }
`

export default class FileProcessor extends React.PureComponent {
  state = {
    uploadProgress: 0,
    done: true,
  }
  onUploadProgress = (progressEvent) => {
    let percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    )
    this.setState({ uploadProgress: percentCompleted })
    if (percentCompleted === 100) {
      setTimeout(this.setDone, 600)
    }
  }
  setDone = () => {
    this.setState({
      done: true,
    })
  }

  componentDidMount() {
    if (this.props.fileToProgress && !this.props.fileToProgress.isUpload) {
      this.setState({
        done: false,
      })
      this.props.uploadFile(
        this.props.fileToProgress.type,
        this.props.fileToProgress.file,
        this.onUploadProgress
      )
    }
  }

  render() {
    const { fileToProgress, type, file, index, name, size } = this.props
    switch (type) {
      case 'image':
        return (
          <ImgContainer key={file + index}>
            {!this.state.done ? (
              <UploadProgress
                fileType="image"
                progress={this.state.uploadProgress}
              />
            ) : (
              !this.props.disabled && (
                <Overlay>
                  <DeleteImg
                    type="button"
                    onClick={() =>
                      this.props.deleteAttachment(
                        'image',
                        index,
                        fileToProgress.key
                      )
                    }
                  >
                    <ClearOutlined />
                  </DeleteImg>
                </Overlay>
              )
            )}

            <Img src={file.url ? file.url : file} alt="Attachment" />
          </ImgContainer>
        )
      case 'video':
        return (
          <div className="post-create__video-wrapper" key={file + index}>
            {!this.state.done ? (
              <UploadProgress
                fileType="video"
                progress={this.state.uploadProgress}
              />
            ) : (
              !this.props.disabled && (
                <div className="post-create__overlay">
                  <ClearOutlined
                    className="post-create__video-delete"
                    onClick={() =>
                      this.props.deleteAttachment(
                        'video',
                        index,
                        fileToProgress.key
                      )
                    }
                  />
                </div>
              )
            )}
            <video className="post-create__video" controls>
              <source src={file.url ? file.url : file} />
            </video>
          </div>
        )
      case 'blob':
        return (
          <FileContainer key={name + index}>
            <FileItem name={name} size={size} />

            {!this.state.done ? (
              <UploadProgress
                fileType="blob"
                progress={this.state.uploadProgress}
              />
            ) : (
              !this.props.disabled && (
                <DeleteFile
                  type="button"
                  onClick={() =>
                    this.props.deleteAttachment(
                      'blob',
                      index,
                      fileToProgress.key
                    )
                  }
                >
                  <ClearOutlined />
                </DeleteFile>
              )
            )}
          </FileContainer>
        )
    }
  }
}
