import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import PhotoOutlined from "@material-ui/icons/PhotoOutlined"
import AttachFileOutlined from "@material-ui/icons/AttachFileOutlined"
import FileLoader from "../../../../formComponents/fileLoader"
import React from "react"
import getErrorText from "../../../../../library/constants/errorTypes"
import { usePostCtx } from "../PostContext"
import { Button, TextField } from "@material-ui/core"
import { usePostsMutations } from "../../../../../hooks/data/feed/posts"
import { useAlert } from "../../../../../hooks/useAlert"
import { Form, Formik } from "formik"
import * as yup from "yup"
import LoadingButton from "../../../controls/buttons/loadingButton"

const validationSchema = yup.object({
  message: yup.string().max(5000),
})

const PostUpdate = () => {
  const { errorAlert } = useAlert()

  const { post, setIsEdit, queryKey } = usePostCtx()

  const { updatePost } = usePostsMutations({ queryKey })

  // const [message, setMessage] = React.useState(post.message || "")
  const [videos, setVideos] = React.useState(post.videos || [])
  const [blobs, setBlobs] = React.useState(post.blobs || [])
  const [images, setImages] = React.useState(post.images || [])

  const setAttachments = (attachments) => {
    setVideos(attachments.videos)
    setBlobs(attachments.blobs)
    setImages(attachments.images)
  }

  const processEdit = async ({ message }) => {
    const attachments = [...blobs, ...images, ...videos].map(({ id }) => id)

    if (!message.replace(/\s/g, "") && !attachments.length) return

    try {
      await updatePost.mutateAsync({
        id: post.id,
        message,
        attachments,
      })
      setIsEdit(false)
    } catch (e) {
      errorAlert(e)
    }
  }

  const initialValues = {
    message: post.message || "",
  }

  const hasAttachments = !!blobs.length || !!images.length || !!videos.length

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={processEdit}
    >
      {(fk) => (
        <Form>
          <div className="post__update">
            <div className="post__update-input">
              <TextField
                placeholder="What’s happening?"
                multiline
                autoFocus
                fullWidth
                name="message"
                margin="none"
                onChange={fk.handleChange}
                value={fk.values.message}
              />
              <div className="post-create__add-attachment">
                <label htmlFor={`image-${post.id}`}>
                  <PhotoOutlined className="post-create__btn-icon" />
                </label>
              </div>
              <div className="post-create__add-attachment">
                <label htmlFor={`attachment-${post.id}`}>
                  <AttachFileOutlined className="post-create__btn-icon" />
                </label>
              </div>
            </div>
            <div className="f jcfs aifs full-width mb10">
              <div className="post-create__images">
                <FileLoader
                  imageId={`image-${post.id}`}
                  attachmentId={`attachment-${post.id}`}
                  videos={videos}
                  images={images}
                  blobs={blobs}
                  onUpload={setAttachments}
                />
              </div>
            </div>
            <div className="post__update-controls">
              <div className="btn-group">
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => setIsEdit(false)}
                >
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  size="small"
                  disabled={
                    !hasAttachments && !fk.values.message.replace(/\s/g, "")
                  }
                  isLoading={updatePost.isLoading}
                  isLoadingText={"Saving"}
                >
                  Save
                </LoadingButton>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default PostUpdate
