import React from "react"
import styled from "@emotion/styled"
import { shadows } from "../../library/constants/styles/shadows"
import { colors } from "../../library/constants/styles/colors"
import PlainLink from "../ui/link"
import { devices } from "../../library/constants/styles/responsive"
import * as routes from "../../library/constants/routes"
import { withRouter } from "react-router-dom"

const Container = styled.footer`
  @supports (display: grid) {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1200px) 1fr;
    height: 50px;
    width: 100%;

    @media ${devices.xs} {
      height: 100%;
    }
  }

  position: relative;
  display: flex;
  justify-content: center;
  z-index: 1;
  height: 50px;
  background-color: ${colors.white};
  box-shadow: ${shadows.layout};
  font-size: 1rem;
`

const Content = styled.div`
  @supports (display: grid) {
    grid-column: 2 / -2;
    width: 100%;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  width: 1200px;

  @media ${devices.xs} {
    flex-direction: column-reverse;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
  }
`

const CopyrightContainer = styled.div`
  display: flex;
  align-items: baseline;

  @media ${devices.xs} {
    margin-bottom: 10px;
  }
`

const CopyrightLink = styled(PlainLink)`
  margin-right: 4px;
`

const CopyrightText = styled.span`
  color: #808080;
`

const NavContainer = styled.nav``

const Nav = styled.ul`
  display: flex;
  list-style: none;

  @media ${devices.xs} {
    flex-direction: column;
    margin-bottom: 20px;
  }
`

const NavItem = styled.li`
  :not(:last-child) {
    margin-right: 20px;

    @media ${devices.xs} {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  @media ${devices.xs} {
    a {
      display: block;
      text-align: center;

      :not(:last-child) {
      }
    }
  }
`

const Footer = (props) => {
  const menuItems = [
    // { path: routes.ABOUT_US, label: 'About Us' },
    { path: routes.ACCOUNTS_INFO, label: "Info" },
    { path: routes.PRICING, label: "Pricing" },
    { path: routes.TERMS_AND_CONDITIONS, label: "Terms of Use" },
    { path: routes.CONTACT_FORM, label: "Contact Us" },
    // { path: routes.APP_PROMOTE, label: "APP" },
  ]

  if (props.isLoggedIn) {
    return null
  }

  return (
    <Container>
      <Content>
        <CopyrightContainer>
          <CopyrightLink to="/">KnowStory</CopyrightLink>{" "}
          <CopyrightText>© {new Date().getFullYear()}</CopyrightText>
        </CopyrightContainer>
        <NavContainer>
          <Nav>
            {menuItems.map(({ path, label }) => (
              <NavItem key={label}>
                <PlainLink to={path}>{label}</PlainLink>
              </NavItem>
            ))}
          </Nav>
        </NavContainer>
      </Content>
    </Container>
  )
}

export default withRouter(Footer)
