import React, { Component } from "react"
import "./productSettingsReviewerInfo.scss"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import Button from "@material-ui/core/Button/Button"
import AddOutlined from "@material-ui/icons/AddOutlined"
import { bindActionCreators } from "redux"
import {
  getProduct,
  updateProduct,
} from "../../../../../library/store/actions/creators/productsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import DeleteForeverOutlined from "@material-ui/icons/DeleteForeverOutlined"
import getErrorText from "../../../../../library/constants/errorTypes"

const newRecord = { author: "", message: "", status: "new" }

class ProductSettingsReviewerInfo extends Component {
  state = {
    reviewsList:
      this.props.products.currentProduct.info_reviews.length > 0
        ? this.props.products.currentProduct.info_reviews
        : [newRecord],
    reviewsUpdated: this.props.products.currentProduct.info_reviews,
    showEmptyRecord: this.props.products.currentProduct.info_reviews.length > 0,
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }

  addRecord = () => {
    this.setState((prevState) => {
      const reviewsUpdated = [...prevState.reviewsList, newRecord]
      return { reviewsList: reviewsUpdated, showEmptyRecord: false }
    })
  }

  showAddRecord = () => {
    this.setState({ showEmptyRecord: true })
  }

  processInfoSave = async (review, i) => {
    const {
      products: {
        currentProduct: { id },
      },
    } = this.props

    this.setState(
      (prevState) => {
        const reviewsList = prevState.reviewsList.map((item, index) => {
          return i === index ? review : item
        })

        return {
          reviewsList,
        }
      },
      async () => {
        try {
          await this.props.updateProduct({
            id,
            info_reviews: this.state.reviewsList,
          })
          await this.props.enqueueSnackbar("Information updated", {
            variant: "success",
          })
          await this.props.getProduct({ id })
        } catch ({ error }) {
          this.props.enqueueSnackbar(getErrorText(error.code), {
            variant: "error",
          })
        }
      }
    )
  }

  processRecordDelete = async (i) => {
    const {
      products: {
        currentProduct: { id },
      },
    } = this.props

    this.setState(
      (prevState) => {
        const reviewsList = prevState.reviewsList
          .filter((item) => item.author !== "" || item.message !== "")
          .filter((item, index) => i !== index)
        return {
          reviewsList,
        }
      },
      async () => {
        this.showAddRecord()
        try {
          await this.props.updateProduct({
            id,
            info_reviews: this.state.reviewsList,
          })
          this.props.enqueueSnackbar("Review successfully deleted", {
            variant: "success",
          })
        } catch ({ error }) {
          this.props.enqueueSnackbar(error.description, { variant: "error" })
        }
      }
    )
  }

  render() {
    const { reviewsList, showEmptyRecord } = this.state

    return (
      <div className="box product-settings-reviews">
        <div className="box__heading">Editorial reviews</div>
        <div className="box__content">
          {this.state.reviewsList.map((item, index) => (
            <div key={item.id ? item.id : `new-${index}`}>
              <ReviewTemplate
                review={item}
                i={index}
                save={(review) => this.processInfoSave(review, index)}
                deleteRecord={() => this.processRecordDelete(index)}
                showAddRecord={this.showAddRecord}
              />
            </div>
          ))}
          {showEmptyRecord && reviewsList.length < 20 && (
            <div className="btn-group jcc">
              <button className="btn btn--plain" onClick={this.addRecord}>
                <AddOutlined /> Add another reviewer
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

class ReviewTemplate extends Component {
  state = {
    id: this.props.review.id,
    author: this.props.review.author,
    message: this.props.review.message,
    status: this.props.review.status,
    authorSaved: "",
    messageSaved: "",
    canDelete: !!this.props.review.id,
    updated: false,
    deleted: false,
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.review !== prevProps.review) {
      this.setState({
        id: this.props.review.id,
        author: this.props.review.author,
        message: this.props.review.message,
        status: this.props.review.status,
        canDelete: !!this.props.review.id,
      })
    }
  }

  handleChange = (name) => (event) => {
    this.setState(
      {
        [name]: event.target.value,
      },
      () =>
        this.setState({
          updated: this.state.message !== "" && this.state.author !== "",
        })
    )
  }

  processInfoSave = async () => {
    const { id, author, message } = this.state

    this.setState({ authorSaved: author, messageSaved: message })

    this.props.save({ id, author, message }).then(() => {
      this.setState({ updated: false })
      this.props.showAddRecord()
    })
  }

  render() {
    const { author, message } = this.state

    return (
      <ValidatorForm className="form" onSubmit={this.processInfoSave}>
        <div className="form__fieldset">
          <label htmlFor={"name" + this.props.i} className="form__label">
            Reviewer name
          </label>
          <div className="form__fieldbox">
            <div className="form__input">
              <TextValidator
                id={"name" + this.props.i}
                name={"name" + this.props.i}
                value={author}
                placeholder="Type reviewer name"
                className="edit-form__input"
                margin="normal"
                fullWidth
                validators={["required", "maxStringLength:100"]}
                errorMessages={[
                  "Field is required",
                  "Max length is 100 characters",
                ]}
                onChange={this.handleChange("author")}
              />
            </div>
          </div>
        </div>

        <div className="form__fieldset">
          <label htmlFor={"review" + this.props.i} className="form__label">
            Review
          </label>
          <div className="form__fieldbox">
            <div className="form__input">
              <TextValidator
                id={"review" + this.props.i}
                name={"review" + this.props.i}
                value={message}
                placeholder="Type review"
                className="edit-form__input"
                margin="normal"
                fullWidth
                multiline
                validators={["required", "maxStringLength:2000"]}
                errorMessages={[
                  "Field is required",
                  "Max length is 2000 characters",
                ]}
                onChange={this.handleChange("message")}
              />
            </div>
          </div>
        </div>

        <Button
          color="primary"
          variant="contained"
          type="submit"
          className="form__submit mb15"
        >
          Save
        </Button>

        <button
          type="button"
          className="form__manage"
          onClick={this.props.deleteRecord}
        >
          <DeleteForeverOutlined /> Delete
        </button>

        <div className="form__divider"></div>
      </ValidatorForm>
    )
  }
}

const mapStateToProps = ({ auth, products }) => ({ auth, products })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getProduct, updateProduct }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ProductSettingsReviewerInfo))
