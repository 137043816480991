import FormHelperText from "@material-ui/core/FormHelperText"
import React from "react"

const ErrorMessage = ({ error, message }) => {
  return (
    error && (
      <FormHelperText className="error-message">{message}</FormHelperText>
    )
  )
}

export default ErrorMessage
