export const knowcredStatusTypes = {
    0: "",
    1: "Granted",
    2: "Awarded",
    3: "Valid",
};

export const knowCredTypesList = [
    {id: 1, name: "Social", activities: []},
    {
        id: 2, name: "Extra - Curricular",
        activities: [
            {name: "Sports", id: 2},
            {name: "Music", id: 3},
            {name: "Art", id: 4},
        ],
    },
    {
        id: 3, name: "Grades/Marks",
        activities: [
            {name: "Number", id: 5},
            {name: "Letter", id: 6},
            {name: "Check", id: 7},
            {name: "Percent", id: 12},
        ],
    },
    {
        id: 4, name: " Certificates",
        activities: [
            {name: "Trophy", id: 8},
            {name: "Badge", id: 9},
            {name: "Certificate", id: 10},
            {name: "Shield", id: 11},
        ],
    },
    {
        id: 5, name: "Diplomas/Degree",
        activities: [],
    },
];

export const knowCredTypesIds = {
    1: "Social",
    2: "Extra - Curricular",
    3: "Grades/Marks",
    4: "Certificates",
    5: "Diplomas/Degree",
};

export const knowCredActivitiesIds = {
    1: "Default",
    2: "Sports",
    3: "Music",
    4: "Art",
    5: "Number",
    6: "Letter",
    7: "Check",
    8: "Trophy",
    9: "Badge",
    10: "Certificate",
    11: "Shield",
    12: "Percent",
};

export const knowCredLocations = [
    {id: 1, name: "Home"},
    {id: 2, name: "School"},
    {id: 3, name: "Church"},
    {id: 4, name: "Camp"},
    {id: 5, name: "Work"},
    {id: 6, name: "District"},
    {id: 7, name: "Association"},
    {id: 8, name: "Group"},
    {id: 9, name: "Other"},
];

export const knowCredBorderColors = ["#fbb040", "#5874b4", "#8d5193", "#b45231", "#49853f", "#258394"];
