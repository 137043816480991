import React, { Component } from "react"
import "./productsList.scss"
import ListSearchHeader from "../../../../components/ui/listSearchHeader/listSearchHeader"
import ProductCreate from "../productCreate/productCreate"
import * as routes from "../../../../library/constants/routes"
import withScroll from "../../../../hocs/withScroll/withScroll"
import { LIMIT } from "../../../../library/constants/limits"

import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import {
  clearProductsList,
  getProductsList,
  productsLibraryList,
  searchProducts,
} from "../../../../library/store/actions/creators/productsCreators"
import { clearGroupsList } from "../../../../library/store/actions/creators/groupsCreators"
import Loader from "../../../../components/ui/loader/loader"
import * as qs from "query-string"
import * as _ from "lodash"
import getErrorText from "../../../../library/constants/errorTypes"
import { call } from "../../../../library/networking/API"
import { PRODUCTS } from "../../../../library/store/actions/types/productsTypes"
import SuggestionList from "../../../../components/ui/suggestionList"
import Grid from "../../../../components/ui/grid/Grid"
import ProductsListItem from "./productsListItem/productsListItem"
import ProductsCategoriesCarousel from "./productsCategoriesCarousel"

class ProductsList extends Component {
  state = {
    list: [],
    initialRender: true,
    showCarousel: true,
    dataReceived: false,
    open:
      (!!this.props.history.location.state &&
        this.props.history.location.state.open) ||
      false,
    suggestions: [],
    tempInputValue: "",
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }

  searchParams = {}

  async componentDidMount() {
    const {
      match: { path },
      location: { search },
    } = this.props
    const { q: query, grades, subjects, contentTypes } = qs.parse(search)
    this.searchParams = {
      name: query || undefined,
      subjects: subjects
        ? _.map(_.split(subjects, ","), _.parseInt)
        : undefined,
      grades: grades ? _.map(_.split(grades, ","), _.parseInt) : undefined,
      content_types: contentTypes
        ? _.map(_.split(contentTypes, ","), _.parseInt)
        : undefined,
    }
    try {
      switch (path) {
        case routes.MARKETPLACE:
          !this.props.products.search &&
            (await this.props.searchProducts({ ...this.searchParams }))
          this.setState({
            list: this.props.products.search,
            initialRender: false,
            dataReceived: true,
          })
          break

        case routes.MARKETPLACE_MY_PRODUCTS:
          !this.props.products.list.length &&
            (await this.props.getProductsList({ ...this.searchParams }))
          this.setState({
            list: this.props.products.list,
            initialRender: false,
            dataReceived: true,
          })
          break

        case routes.MARKETPLACE_PURCHASE_HISTORY:
          !this.props.products.library &&
            (await this.props.productsLibraryList({ ...this.searchParams }))
          this.setState({
            list: this.props.products.library,
            initialRender: false,
            dataReceived: true,
          })
          break

        default:
          return
      }
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      match: { path },
      location: { search },
    } = this.props
    const { q: query, subjects, grades, contentTypes } = qs.parse(search)

    this.searchParams = {
      name: query || undefined,
      subjects: subjects
        ? _.map(_.split(subjects, ","), _.parseInt)
        : undefined,
      grades: grades ? _.map(_.split(grades, ","), _.parseInt) : undefined,
      content_types: contentTypes
        ? _.map(_.split(contentTypes, ","), _.parseInt)
        : undefined,
    }
    if (
      path !== prevProps.match.path ||
      (search && search !== prevProps.location.search)
    )
      this.props.clearProductsList()
    if (path === routes.MARKETPLACE && path !== prevProps.match.path) {
      this.setState({ dataReceived: false })

      try {
        await this.props.searchProducts({})
        this.setState({ list: this.props.products.search, dataReceived: true })
      } catch ({ error }) {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    } else if (
      path === routes.MARKETPLACE_MY_PRODUCTS &&
      path !== prevProps.match.path
    ) {
      this.setState({ dataReceived: false })

      try {
        await this.props.getProductsList({})
        this.setState({ list: this.props.products.list, dataReceived: true })
      } catch ({ error }) {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    } else if (
      path === routes.MARKETPLACE_PURCHASE_HISTORY &&
      path !== prevProps.match.path
    ) {
      this.setState({ dataReceived: false })

      try {
        await this.props.productsLibraryList({})
        this.setState({ list: this.props.products.library, dataReceived: true })
      } catch ({ error }) {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    } else if (
      path === routes.MARKETPLACE &&
      search &&
      search !== prevProps.location.search
    ) {
      this.setState({ list: [], dataReceived: false })
      this.props.clearProductsList()
      try {
        await this.props.searchProducts(this.searchParams)
        this.setState({
          list: this.props.products.search,
          dataReceived: true,
          showCarousel:
            !this.props.location.search ||
            this.props.location.search === "?q=" ||
            this.props.location.search === "?",
        })
      } catch ({ error }) {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    } else if (
      path === routes.MARKETPLACE_PURCHASE_HISTORY &&
      search &&
      search !== prevProps.location.search
    ) {
      this.setState({ dataReceived: false })

      try {
        await this.props.productsLibraryList(this.searchParams)
        this.setState({
          list: this.props.products.library,
          dataReceived: true,
          showCarousel:
            !this.props.location.search ||
            this.props.location.search === "?q=" ||
            this.props.location.search === "?",
        })
      } catch ({ error }) {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    } else if (
      path === routes.MARKETPLACE_MY_PRODUCTS &&
      search &&
      search !== prevProps.location.search
    ) {
      this.setState({ dataReceived: false })

      try {
        await this.props.getProductsList(this.searchParams)
        this.setState({
          list: this.props.products.list,
          dataReceived: true,
          showCarousel:
            !this.props.location.search ||
            this.props.location.search === "?q=" ||
            this.props.location.search === "?",
        })
      } catch ({ error }) {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    }
  }

  async componentWillUnmount() {
    await this.props.clearGroupsList()
    await this.props.clearProductsList()
  }

  onScroll = async () => {
    if (!this.state.dataReceived || this.props.products.isComplete) return
    this.setState({
      dataReceived: false,
    })
    const {
      match: { path },
    } = this.props
    switch (path) {
      case routes.MARKETPLACE:
        await this.props.searchProducts({
          ...this.searchParams,
          offset: this.state.list.length,
          limit: LIMIT,
        })
        this.setState({ list: this.props.products.search, dataReceived: true })
        break

      case routes.MARKETPLACE_MY_PRODUCTS:
        await this.props.getProductsList({
          ...this.searchParams,
          offset: this.state.list.length,
          limit: LIMIT,
        })
        this.setState({ list: this.props.products.list, dataReceived: true })
        break

      case routes.MARKETPLACE_PURCHASE_HISTORY:
        await this.props.productsLibraryList({
          ...this.searchParams,
          offset: this.state.list.length,
          limit: LIMIT,
        })
        this.setState({ list: this.props.products.library, dataReceived: true })
        break

      default:
        return
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  searchProducts = (name) => {
    const {
      location: { search },
      history,
    } = this.props
    const { subjects, grades, contentTypes } = qs.parse(search)

    this.searchParams = {
      q: name || undefined,
      subjects: subjects
        ? _.map(_.split(subjects, ","), _.parseInt)
        : undefined,
      grades: grades ? _.map(_.split(grades, ","), _.parseInt) : undefined,
      contentTypes: contentTypes
        ? _.map(_.split(contentTypes, ","), _.parseInt)
        : undefined,
    }

    const result = qs.stringify(this.searchParams, { arrayFormat: "comma" })

    history.push({
      search: `?${result}`,
    })
    this.setState({ suggestions: [] })
  }

  composeTitle = () => {
    const {
      match: { path },
    } = this.props
    switch (path) {
      case routes.MARKETPLACE:
        return "Marketplace"

      case routes.MARKETPLACE_PURCHASE_HISTORY:
        return "My products"

      case routes.MARKETPLACE_MY_PRODUCTS:
        return "My managed products"

      default:
        return "Marketplace"
    }
  }

  getSuggestions = async (query) => {
    if (query) {
      try {
        const response = await call(PRODUCTS.SUGGESTIONS.SEARCH, {
          token: this.props.auth.userData.token,
          name: query,
        })
        if (response.status === 200) {
          const { names } = response.data
          await this.setStateAsync({ suggestions: names })
        } else {
          await this.setStateAsync({ suggestions: [] })
        }
      } catch (e) {
        console.error(e)
      }
    } else {
      await this.setStateAsync({ suggestions: [] })
    }
  }

  setQuery = (query) => {
    if (query) {
      const {
        location: { search },
        history,
      } = this.props
      const searchObj = qs.parse(search)
      const result = qs.stringify(
        { ...searchObj, q: query },
        { arrayFormat: "comma" }
      )
      history.push({
        search: `?${result}`,
      })
      this.setState({ suggestions: [], tempInputValue: query }, () =>
        this.setState({ tempInputValue: "" })
      )
    }
  }

  render() {
    const {
      list,
      initialRender,
      showCarousel,
      dataReceived,
      open,
      tempInputValue,
      suggestions,
    } = this.state
    const {
      match: { path },
      products,
      type,
    } = this.props
    return (
      <>
        <ListSearchHeader
          title={this.composeTitle()}
          tempInputValue={tempInputValue}
          placeholder="Start typing product name"
          search={this.searchProducts}
          hasButton={false}
          buttonLabel="Create product or Kourse ad tile"
          buttonAction={this.handleClickOpen}
          noResults={
            dataReceived &&
            list.length === 0 &&
            !initialRender &&
            !suggestions.length
          }
          noResultsLabel={
            type === "purchase"
              ? "You don`t have purchased products yet"
              : undefined
          }
          queryCallback={this.getSuggestions}
          suggestions={
            path === routes.MARKETPLACE ? (
              <SuggestionList list={suggestions} setResult={this.setQuery} />
            ) : null
          }
        />

        {open && (
          <ProductCreate
            open={open}
            fields={this.props.history.location.state}
            onClose={this.handleClose}
          />
        )}

        {path === routes.MARKETPLACE &&
          list.length !== 0 &&
          !initialRender &&
          showCarousel && <ProductsCategoriesCarousel />}

        <Grid>
          {((_) => {
            if (products.list.length || products.library || products.search) {
              return list.map((item) => (
                <ProductsListItem
                  product={item}
                  user={this.props.auth.userData}
                  manageable={true}
                  key={item.id}
                />
                // <ProductCard
                //   product={item}
                //   user={this.props.auth.userData}
                //   manageable={true}
                //   key={item.id}
                // />
              ))
            }
          })()}
        </Grid>

        {(() => {
          if (!dataReceived) return <Loader />
        })()}
      </>
    )
  }
}

const mapStateToProps = ({ auth, products }) => ({ auth, products })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getProductsList,
      searchProducts,
      productsLibraryList,
      clearProductsList,
      clearGroupsList,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withScroll(ProductsList)))
