import { call } from "../../../networking/API"
import { PRODUCTS } from "../types/productsTypes"
import { LIMIT } from "../../../constants/limits"

export const getProductsList = ({
  user_id,
  group_id,
  name,
  content_types,
  subjects,
  grades,
  offset = 0,
  limit = 50,
}) => {
  return async (dispatch, getState) => {
    const response = await call(PRODUCTS.LIST, {
      token: getState().auth.userData.token,
      user_id: group_id
        ? undefined
        : user_id
        ? user_id
        : getState().auth.userData.id,
      group_id,
      name,
      content_types,
      subjects,
      grades,
      offset,
      limit,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: PRODUCTS.LIST,
        payload: {
          products: json.products,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const searchProducts = ({
  name,
  content_types,
  subjects,
  grades,
  offset = 0,
  limit = 50,
}) => {
  return async (dispatch, getState) => {
    const response = await call(PRODUCTS.SEARCH, {
      token: getState().auth.userData.token,
      name,
      content_types,
      subjects,
      grades,
      offset,
      limit,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: PRODUCTS.SEARCH,
        payload: {
          products: json.products,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const clearProductsSearch = () => {
  return {
    type: PRODUCTS.CLEAR_SEARCH,
  }
}

export const createProduct = ({
  group_id,
  name,
  content_type,
  subjects,
  publisher,
  grades,
  author,
  description,
  standards,
  knowmix_id,
  contact_email,
}) => {
  return async (dispatch, getState) => {
    const response = await call(PRODUCTS.CREATE, {
      token: getState().auth.userData.token,
      group_id,
      name,
      content_type,
      subjects,
      publisher,
      grades,
      author,
      description,
      standards,
      knowmix_id,
      contact_email,
    })

    const json = await response.data

    if (response.status === 200) {
      dispatch({
        type: PRODUCTS.CREATE,
      })

      return json.id
    } else {
      throw json.error
    }
  }
}

export const updateProduct = ({
  id,
  group_id,
  name,
  content_type,
  subjects,
  photos,
  attachments,
  grades,
  publisher,
  author,
  full_image,
  concepts_number,
  average_time,
  potential_time,
  lesson_time,
  topic,
  description,
  platforms,
  enhancements,
  integration,
  teks_standards,
  standards,
  target_market,
  tagline,
  url,
  version,
  date_from,
  date_to,
  paid,
  price,
  pricing_levels = [],
  info_reviews,
  file_types,
  knowmix_id,
  contact_email,
  has_demo,
}) => {
  return async (dispatch, getState) => {
    const { images, videos, blobs } = getState().products.currentProduct,
      old_attachments = [
        ...images.map((i) => i.id),
        ...videos.map((v) => v.id),
        ...blobs.map((b) => b.id),
      ]
    const response = await call(PRODUCTS.UPDATE, {
      token: getState().auth.userData.token,
      id,
      group_id,
      name,
      content_type,
      subjects,
      photos,
      attachments: attachments || old_attachments,
      grades,
      publisher,
      author,
      full_image,
      concepts_number,
      average_time,
      potential_time,
      lesson_time,
      topic,
      description,
      platforms,
      enhancements,
      integration,
      teks_standards,
      standards,
      target_market,
      tagline,
      url,
      version,
      date_from,
      date_to,
      paid,
      price,
      pricing_levels,
      info_reviews,
      file_types,
      knowmix_id,
      contact_email,
      has_demo,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: PRODUCTS.UPDATE,
      })
    } else {
      throw json.error
    }
  }
}

export const deleteProduct = ({ id }) => {
  return async (dispatch, getState) => {
    const response = await call(PRODUCTS.DELETE, {
      token: getState().auth.userData.token,
      id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: PRODUCTS.DELETE,
      })
    } else {
      throw json.error
    }
  }
}

export const getProduct = ({ type = '', id, reviews_limit = 20 }) => {
  return async (dispatch, getState) => {
    const response = await call(PRODUCTS.GET, {
      token: getState().auth.userData.token,
      id,
      reviews_limit,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: PRODUCTS.GET,
        payload: {
          product: json,
          type,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const addProductToLibrary = ({ product_id, group_id }) => {
  return async (dispatch, getState) => {
    const response = await call(PRODUCTS.LIBRARY.ADD, {
      token: getState().auth.userData.token,
      product_id,
      group_id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: PRODUCTS.LIBRARY.ADD,
      })
    } else {
      throw json.error
    }
  }
}

export const deleteProductFromLibrary = ({ product_id, group_id }) => {
  return async (dispatch, getState) => {
    const response = await call(PRODUCTS.LIBRARY.DELETE, {
      token: getState().auth.userData.token,
      product_id,
      group_id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: PRODUCTS.LIBRARY.DELETE,
      })
    } else {
      throw json.error
    }
  }
}

export const productsLibraryList = ({
  group_id,
  user_id,
  name,
  content_types,
  subjects,
  grades,
  offset = 0,
  limit = 50,
}) => {
  return async (dispatch, getState) => {
    const response = await call(PRODUCTS.LIBRARY.LIST, {
      token: getState().auth.userData.token,
      group_id,
      user_id,
      name,
      content_types,
      subjects,
      grades,
      offset,
      limit,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: PRODUCTS.LIBRARY.LIST,
        payload: {
          products: json.products,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const productAcquisitionSearch = ({
  product_id,
  name,
  type = 1,
  offset = 0,
  limit = 50,
}) => {
  return async (dispatch, getState) => {
    const response = await call(PRODUCTS.ACQUISITIONS.SEARCH, {
      token: getState().auth.userData.token,
      product_id,
      name,
      type,
      offset,
      limit,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: PRODUCTS.ACQUISITIONS.SEARCH,
        payload: {
          users: json.users,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const productAcquisitionClear = () => {
  return {
    type: PRODUCTS.ACQUISITIONS.CLEAR,
  }
}

export const productAcquisitionAccess = ({
  product_id,
  user_id,
  group_id,
  date_to,
}) => {
  return async (dispatch, getState) => {
    const response = await call(PRODUCTS.ACQUISITIONS.ACCESS, {
      token: getState().auth.userData.token,
      user_id: user_id || getState().auth.userData.id,
      group_id,
      product_id,
      date_to,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: PRODUCTS.ACQUISITIONS.ACCESS,
      })
    } else {
      throw json.error
    }
  }
}

export const createProductReview = ({ product_id, message, rating }) => {
  return async (dispatch, getState) => {
    const response = await call(PRODUCTS.REVIEWS.CREATE, {
      token: getState().auth.userData.token,
      product_id,
      message,
      rating,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: PRODUCTS.REVIEWS.CREATE,
      })
    } else {
      throw json.error
    }
  }
}

export const updateProductReview = ({ product_id, message, rating }) => {
  return async (dispatch, getState) => {
    const response = await call(PRODUCTS.REVIEWS.UPDATE, {
      token: getState().auth.userData.token,
      product_id,
      message,
      rating,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: PRODUCTS.REVIEWS.UPDATE,
      })
    } else {
      throw json.error
    }
  }
}

export const deleteProductReview = ({ product_id }) => {
  return async (dispatch, getState) => {
    const response = await call(PRODUCTS.REVIEWS.DELETE, {
      token: getState().auth.userData.token,
      product_id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: PRODUCTS.REVIEWS.DELETE,
      })
    } else {
      throw json.error
    }
  }
}

export const productReviewsList = ({ product_id, offset = 0, limit = 100 }) => {
  return async (dispatch, getState) => {
    const response = await call(PRODUCTS.REVIEWS.LIST, {
      token: getState().auth.userData.token,
      product_id,
      offset,
      limit,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: PRODUCTS.REVIEWS.LIST,
        payload: {
          reviews: json.reviews,
        },
      })
    } else {
      throw json.error
    }
  }
}

export const clearProductsList = () => {
  return {
    type: PRODUCTS.CLEAR,
  }
}

export const purchaseProduct = ({ product_id }) => {
  return async (dispatch, getState) => {
    const response = await call(PRODUCTS.PURCHASE, {
      token: getState().auth.userData.token,
      product_id,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: PRODUCTS.PURCHASE,
      })
    } else {
      throw json.error
    }
  }
}

export const scheduleDemo = ({ id, date }) => {
  return async (dispatch) => {
    const response = await call(PRODUCTS.SCHEDULE_DEMO, {
      id,
      date,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: PRODUCTS.SCHEDULE_DEMO,
        payload: { id },
      })
    } else {
      throw json.error
    }
  }
}

export const requestDemo = ({ product_id, date }) => {
  return async (dispatch) => {
    const response = await call(PRODUCTS.REQUEST_DEMO, {
      product_id,
      date,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: PRODUCTS.REQUEST_DEMO,
      })
    } else {
      throw json.error
    }
  }
}

export const demoRequestsList = ({ product_id, name, offset = 0 }) => {
  return async (dispatch) => {
    const response = await call(PRODUCTS.DEMO_REQUESTS_LIST, {
      product_id,
      name,
      offset,
      limit: LIMIT,
    })

    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: PRODUCTS.DEMO_REQUESTS_LIST,
        payload: { list: json.demos, offset },
      })
    } else {
      throw json.error
    }
  }
}

export const clearDemoRequestsList = () => {
  return async (dispatch) => {
    return dispatch({
      type: PRODUCTS.DEMO_REQUESTS_LIST_CLEAR,
    })
  }
}
