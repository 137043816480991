import React from "react"
import styled from "@emotion/styled"
import { colors } from "../../../library/constants/styles/colors"

const SuggestionList = (props) => {
  const { list, setResult } = props
  if (!list.length) return null
  return (
    <SuggestionListContainer>
      {list.map((el) => (
        <SuggestionListItem onClick={() => setResult(el)} key={encodeURI(el)}>
          <span style={{ padding: "10px" }}>{el}</span>
        </SuggestionListItem>
      ))}
    </SuggestionListContainer>
  )
}

const SuggestionListContainer = styled.div`
    background: ${colors.white};
    width: 100%;
`;

const SuggestionListItem = styled.div`
    height: 40px;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    :hover {
        background: rgba(0,0,0,0.08);
    }
`;

export default SuggestionList;
