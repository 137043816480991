import { generatePath, Link } from "react-router-dom"
import {
  getUserName,
  getUserPhoto,
} from "../../../../../library/utils/user/userUtils"
import { PlainButton } from "../../../controls"
import EditOutlined from "@material-ui/icons/EditOutlined"
import DeleteForeverOutlined from "@material-ui/icons/DeleteForeverOutlined"
import React from "react"
import * as routes from "../../../../../library/constants/routes"
import { getGroupLogo } from "../../../../../library/utils/getGroupLogo"
import { useReplyCtx } from "../ReplyContext"

const ReplyHeader = () => {
  const { reply, onDelete, isEdit, setIsEdit } = useReplyCtx()

  const profileUrl = reply.user
    ? generatePath(routes.USER, { userId: reply.user.id })
    : generatePath(routes.GROUP, { groupId: reply.group.id })

  const creatorAvatar = reply.user
    ? getUserPhoto(reply.user)
    : getGroupLogo(reply.group)
  return (
    <div className="post-reply__header">
      <Link to={profileUrl}>
        <img
          src={creatorAvatar}
          alt="Author Name"
          className="post-reply__author-avatar post-reply__author-avatar--img"
        />
      </Link>
      <div className="f fdc jcc fg1">
        <Link to={profileUrl} className="asfs link">
          <span className="post-reply__author-name">
            {reply.user ? getUserName(reply.user) : reply.group.name}
          </span>
        </Link>
      </div>

      {!isEdit && (reply.can_edit || reply.can_delete) && (
        <div className="post-reply__manage">
          {reply.can_edit && (
            <PlainButton onClick={() => setIsEdit(true)}>
              <EditOutlined />
              Edit
            </PlainButton>
          )}
          {reply.can_delete && (
            <PlainButton onClick={onDelete}>
              <DeleteForeverOutlined />
              Delete
            </PlainButton>
          )}
        </div>
      )}
    </div>
  )
}

export default ReplyHeader
