import React from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { call } from "../../../library/networking/API"
import { FRIENDS } from "../../../library/store/actions/types/friendsTypes"
import { useAlert } from "../../useAlert"
import { mutateInfinite } from "../../../library/utils/reactQuery"
import { USER } from "../../../library/store/actions/types/authTypes"

export const useUserRelations = ({
  queryKey,
  flatRelationKeys = false,
  isSingleEntity = false,
}) => {
  const queryClient = useQueryClient()
  const { errorAlert } = useAlert()

  const [mutatingIds, setMutatingIds] = React.useState([])

  const sendRequest = useMutation(
    async (user_id) => {
      try {
        setMutatingIds((prevState) => [...prevState, user_id])
        await call(FRIENDS.REQUESTS.SEND, { user_id })
      } catch (e) {
        errorAlert(e)
      }
    },
    {
      onSuccess: async (data, user_id, context) => {
        if (!isSingleEntity) {
          await mutateInfinite({
            queryClient,
            key: queryKey,
            itemUpdater: (u) => {
              return u.id === user_id
                ? {
                    ...u,
                    user_relation: !flatRelationKeys
                      ? {
                          ...u.user_relation,
                          is_requested: true,
                        }
                      : undefined,
                    is_requested: flatRelationKeys ? true : undefined,
                  }
                : u
            },
          })

          setMutatingIds((prevState) =>
            prevState.filter((id) => id !== user_id)
          )
        } else {
          queryClient.setQueryData(queryKey, (prevData) => {
            return {
              ...prevData,
              is_requested: true,
            }
          })
        }
      },
    }
  )

  const cancelRequest = useMutation({
    mutationFn: async (user_id) => {
      try {
        await call(FRIENDS.REQUESTS.CANCEL, { user_id })
      } catch (e) {
        errorAlert(e)
      }
    },
    onSuccess: async (data, user_id, ctx) => {
      if (!isSingleEntity) {
        await mutateInfinite({
          queryClient,
          key: queryKey,
          itemUpdater: (u) => {
            return u.id === user_id
              ? {
                  ...u,
                  user_relation: !flatRelationKeys
                    ? {
                        ...u.user_relation,
                        is_requested: false,
                      }
                    : undefined,
                  is_requested: flatRelationKeys ? false : undefined,
                }
              : u
          },
        })
      } else {
        queryClient.setQueryData(queryKey, (prevData) => {
          return {
            ...prevData,
            is_requested: false,
          }
        })
      }
    },
  })

  const acceptRequest = useMutation({
    mutationFn: async (user_id) => {
      try {
        await call(FRIENDS.REQUESTS.ACCEPT, { user_id })
      } catch (e) {
        errorAlert(e)
      }
    },
    onSuccess: async (data, user_id, ctx) => {
      if (!isSingleEntity) {
        await mutateInfinite({
          queryClient,
          key: queryKey,
          itemUpdater: (u) => {
            return u.id === user_id
              ? {
                  ...u,
                  user_relation: !flatRelationKeys
                    ? {
                        ...u.user_relation,
                        is_requesting: false,
                        is_friend: true,
                      }
                    : undefined,
                  is_requesting: flatRelationKeys ? false : undefined,
                  is_friend: flatRelationKeys ? true : undefined,
                }
              : u
          },
        })
      } else {
        queryClient.setQueryData(queryKey, (prevData) => {
          return {
            ...prevData,
            is_requesting: false,
            is_friend: true,
          }
        })
      }
    },
  })

  const declineRequest = useMutation({
    mutationFn: async (user_id) => {
      try {
        await call(FRIENDS.REQUESTS.DECLINE, { user_id })
      } catch (e) {
        errorAlert(e)
      }
    },
    onSuccess: async (data, user_id, ctx) => {
      if (!isSingleEntity) {
        await mutateInfinite({
          queryClient,
          key: queryKey,
          itemUpdater: (u) => {
            return u.id === user_id
              ? {
                  ...u,
                  user_relation: !flatRelationKeys
                    ? {
                        ...u.user_relation,
                        is_requesting: false,
                      }
                    : undefined,
                  is_requesting: flatRelationKeys ? false : undefined,
                }
              : u
          },
        })
      } else {
        queryClient.setQueryData(queryKey, (prevData) => {
          return {
            ...prevData,
            is_requesting: false,
          }
        })
      }
    },
  })

  const deleteFriend = useMutation({
    mutationFn: async (user_id) => {
      try {
        await call(FRIENDS.DELETE, { user_id })
      } catch (e) {
        errorAlert(e)
      }
    },
    onSuccess: async (data, user_id, ctx) => {
      if (!isSingleEntity) {
        await mutateInfinite({
          queryClient,
          key: queryKey,
          itemUpdater: (u) => {
            return u.id === user_id
              ? {
                  ...u,
                  user_relation: !flatRelationKeys
                    ? {
                        ...u.user_relation,
                        is_friend: false,
                      }
                    : undefined,
                  is_friend: flatRelationKeys ? false : undefined,
                }
              : u
          },
        })
      } else {
        queryClient.setQueryData(queryKey, (prevData) => {
          return {
            ...prevData,
            is_friend: false,
          }
        })
      }
    },
  })

  const blockUser = useMutation({
    mutationFn: async (user_id) => {
      try {
        await call(USER.BLACKLIST.ADD_USER, { user_id })
      } catch (e) {
        errorAlert(e)
      }
    },
    onSuccess: async (data, user_id, ctx) => {
      if (!isSingleEntity) {
        queryClient.setQueryData(queryKey, (prevData) => {
          return {
            ...prevData,
            is_blocked: false,
          }
        })
      }
    },
  })

  const unblockUser = useMutation({
    mutationFn: async (user_id) => {
      try {
        await call(USER.BLACKLIST.REMOVE_USER, { user_id })
      } catch (e) {
        errorAlert(e)
      }
    },
    onSuccess: async (data, user_id, ctx) => {
      if (!isSingleEntity) {
        queryClient.setQueryData(queryKey, (prevData) => {
          return {
            ...prevData,
            is_blocked: false,
          }
        })
      }
    },
  })

  return {
    mutatingIds,
    sendRequest,
    cancelRequest,
    acceptRequest,
    declineRequest,
    deleteFriend,
    blockUser,
    unblockUser,
  }
}
