import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import { urlValidation } from "../../../../../library/utils/validators"
import {
  createMeet,
  getMeet,
  updateMeet,
} from "../../../../../library/store/actions/creators/uknowCreators"
import { prepareDatePickerValue } from "../../../../../library/utils/dateConvert"
import { useAlert } from "../../../../../hooks/useAlert"
import { timeDifference } from "../../../../../library/utils/timeString/timeString"
import { Dialog, DialogContent } from "@material-ui/core"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import IntPicker from "../../../../../components/v2/inputs/pickers/intPicker"
import DurationInput from "../../../../../components/v2/inputs/durationInput/durationInput"
import {
  FormDatePicker,
  MultipleTextField,
} from "../../../../../components/formComponents"
import { addMonths } from "date-fns"
import Loader from "../../../../../components/ui/loader"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import UserPicker from "../../../../../components/v2/inputs/pickers/userPicker"
import { USERS } from "../../../../../library/networking/apiEndpoints"
import FileLoader from "../../../../../components/formComponents/fileLoader"
import AddOutlined from "@material-ui/icons/AddOutlined"
import HoldsPicker from "../../../../../components/v2/inputs/pickers/HoldsPicker"
import { USER } from "../../../../../library/store/actions/types/authTypes"
import FormCheckbox from "../../../../../components/formComponents/formCheckbox"
import ContactLinkPicker from "../../../../../components/v2/inputs/pickers/ContactLinkPicker"

const MeetFormModal = ({
  handleClose,
  open,
  activeMeet,
  openMeetCreatedDialog,
  isCopying = false,
  afterSubmit,
}) => {
  console.log(activeMeet, isCopying)
  const dispatch = useDispatch()
  const { errorAlert } = useAlert()

  const { userData } = useSelector(({ auth }) => auth)

  const [dataReceived, setDataReceived] = React.useState(!activeMeet)
  const [values, setValues] = React.useState({
    name: "",
    cohort_size: 1,
    time: 5,
    target_date: null,
    target_interval: 10,
    hold: "",
    conference_link: "",
    instructions: "",
    reminder: "",
    links: [],
    users: [],
    invitee_emails: [],
    attachments: [],
    location: "",
    holds: [],
    allow_over_cohort: false,
    require_full_cohort: false,
    auto_cancel: false,
  })

  const [errors, setErrors] = useState({
    cohort_size: false,
    target_date: false,
    hold: false,
    holds: false,
    users: false,
  })
  const [isSubmitting, setIsSubmitting] = useState(false)

  const fetchMeet = async () => {
    try {
      const meet = await dispatch(getMeet({ id: activeMeet }))

      setValues({
        name: meet.name || "",
        target_date: prepareDatePickerValue(meet.target_datetime),
        cohort_size: meet.cohort_size || 1,
        hold: meet.hold || "",
        instructions: meet.instructions || "",
        reminder: meet.reminder || "",
        time: meet.duration || 5,
        target_interval: meet.target_interval || "",
        conference_link: meet.conference_link || "",
        links: meet.other_links || [],
        attachments: meet.blobs || [],
        users: meet.participants || [],
        invitee_emails: meet.invitee_emails || [],
        location: meet.location || [],
        holds: meet.holds || [],
        allow_over_cohort: meet.allow_over_cohort || false,
        require_full_cohort: meet.require_full_cohort || false,
      })
      setDataReceived(true)
    } catch (e) {
      errorAlert(e)
    }
  }

  useEffect(() => {
    ValidatorForm.addValidationRule("isLink", (value) => urlValidation(value))

    if (activeMeet) {
      fetchMeet()
    }
  }, [])

  const handleChange = (name, required) => (e) => {
    setValues((prevState) => ({ ...prevState, [name]: e.target.value }))

    if (name === "hold") {
      const schedulePeriod = e.target.value

      const schedulePeriodDuration = !schedulePeriod.is_all_day
        ? timeDifference(schedulePeriod.time_from, schedulePeriod.time_to)
        : 1440

      setValues((prevState) => ({
        ...prevState,
        maxTime: schedulePeriodDuration,
      }))
    }

    if (required) {
      setErrors((prevState) => ({
        ...prevState,
        [name]: e.target.value.length === 0,
      }))
    }
  }

  const handleChangeDate = (name, date) => {
    setValues((prevState) => ({ ...prevState, [name]: date }))
    if (name === "target_date") {
      setErrors((prevState) => ({ ...prevState, target_date: !date }))
    }
  }

  const processFormValidate = () => {
    setErrors({
      cohort_size: !values.cohort_size,
      // hold: !values.hold,
      target_date: !values.target_date,
      users: !values.invitee_emails.length && !values.users.length,
    })
  }

  const processInfoSave = async () => {
    const data = {
      name: values.name,
      cohort_size: values.cohort_size,
      target_date: values.target_date,
      duration: values.time,
      target_interval: values.target_interval,
      other_links: values.links,
      conference_link: values.conference_link,
      participants_ids: values.users.map((u) => u.id),
      invitee_emails: values.invitee_emails,
      attachments: values.attachments.map((a) => a.id),
      // hold_id: values.hold.id,
      // hold_id: 10,
      reminder: values.reminder,
      instructions: values.instructions,
      location: values.location,
      hold_ids: values.holds.map((h) => h.id),
      allow_over_cohort: values.allow_over_cohort,
      require_full_cohort: values.require_full_cohort,
    }

    if (Object.values(errors).filter(Boolean).length) {
      console.log(errors)
      return console.error("Validation error")
    }

    setIsSubmitting(true)

    if (activeMeet && !isCopying) {
      await dispatch(
        updateMeet({
          ...data,
          id: activeMeet,
        })
      )
    } else {
      const newMeet = await dispatch(createMeet(data))

      if (typeof openMeetCreatedDialog === "function") {
        openMeetCreatedDialog(newMeet)
      }
    }

    if (typeof afterSubmit === "function") {
      await afterSubmit()
    }

    setIsSubmitting(false)

    handleClose()
  }

  return (
    <Dialog
      aria-labelledby="calendar-form-dialog"
      onClose={handleClose}
      open={open}
      maxWidth="md"
    >
      <ValidatorForm onSubmit={processInfoSave}>
        <DialogTitle id="alert-dialog-title" className="text-center">
          {!!activeMeet && !isCopying ? "Edit Meet" : "Create Meet"}
        </DialogTitle>
        <DialogContent>
          {!activeMeet && (
            <p className="mb20 text-center">
              Create Auto-Cohorting Meet that sets to all calendars
              <br />
              one cohort at a time until all guests are complete
            </p>
          )}
          {dataReceived ? (
            <div className="f jcc">
              <div className="form form--modal">
                <div className="form__fieldset">
                  <label htmlFor="name" className="form__label">
                    Name *
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="name"
                        name="name"
                        value={values.name}
                        placeholder="Type meet name"
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        validators={[
                          "required",
                          "minStringLength:3",
                          "maxStringLength:200",
                        ]}
                        errorMessages={[
                          "Field is required",
                          "Min length is 3 characters",
                          "Max length is 200 characters",
                        ]}
                        withRequiredValidator
                        onChange={handleChange("name")}
                      />
                    </div>
                  </div>
                </div>

                <IntPicker
                  entityName={"Cohort size"}
                  inputName={"cohort_size"}
                  value={values.cohort_size}
                  onChange={handleChange("cohort_size", true)}
                  hasError={errors.cohort_size}
                  required
                />

                {/*{!!values.holds.length && (*/}
                <DurationInput
                  name={"duration"}
                  label={"Meet length"}
                  time={values.time}
                  onChange={(t) =>
                    setValues((prevState) => ({
                      ...prevState,
                      time: t,
                    }))
                  }
                  minTime={10}
                  // maxTime={values.maxTime || undefined}
                  // periodEntity={values.hold}
                  // isWaitingForOtherFields={!!values.hold}
                  tooltipSubtext={
                    "Daily searched time in your chosen Holds or Blocks. System will find time within first 7 days, resetting with each target based on interval."
                  }
                  showLimits={false}
                />
                {/*)}*/}

                <FormDatePicker
                  withTimePicker
                  dateFrom={{
                    label: "Target date",
                    value: values.target_date,
                    handleChange: (date) =>
                      handleChangeDate("target_date", date),
                    minDate: new Date(),
                    maxDate: addMonths(new Date(), 2),
                    error: errors.target_date,
                  }}
                />

                <DurationInput
                  name={"target interval"}
                  label={"Target interval"}
                  time={values.target_interval}
                  onChange={(t) =>
                    setValues((prevState) => ({
                      ...prevState,
                      target_interval: t,
                    }))
                  }
                  minTime={10}
                />

                <FormCheckbox
                  label={
                    "Require full cohort, rescheduling for all if a participant who accepted in that cohort pushes it forward"
                  }
                  onChange={(e) =>
                    setValues((prevState) => ({
                      ...prevState,
                      require_full_cohort: e.target.checked,
                    }))
                  }
                  value={values.require_full_cohort}
                />

                <FormCheckbox
                  label={
                    "If a full cohort sets a Meet and then more participants accept, add them if it is within an 15 minutes prior"
                  }
                  onChange={(e) =>
                    setValues((prevState) => ({
                      ...prevState,
                      allow_over_cohort: e.target.checked,
                    }))
                  }
                  value={values.allow_over_cohort}
                />

                <div className="form__fieldset">
                  <label htmlFor="name" className="form__label">
                    Location
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="location"
                        name="location"
                        value={values.location}
                        placeholder="Type location"
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        validators={["maxStringLength:200"]}
                        errorMessages={["Max length is 200 characters"]}
                        onChange={handleChange("location")}
                      />
                    </div>
                  </div>
                </div>

                <UserPicker
                  multiple
                  // required={!values.invitee_emails.length}
                  value={values.users}
                  onChange={(users) => {
                    setValues((prevValues) => ({
                      ...prevValues,
                      users: users,
                    }))
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      users: !users.length && !values.invitee_emails.length,
                    }))
                  }}
                  hasError={errors.users}
                  errorMessage={
                    "You have to choose someone from existing users or invite people by email"
                  }
                  requestConfig={{
                    fetchConfig: {
                      apiUrl: USERS.SEARCH,
                      params: {},
                    },
                    key: "users",
                  }}
                  fieldName={"users"}
                  userLabel={"users invited"}
                />

                <MultipleTextField
                  formIsModal
                  values={values.invitee_emails}
                  setValues={(emails) => {
                    setValues((prevValues) => ({
                      ...prevValues,
                      invitee_emails: emails,
                    }))
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      users: !emails.length && !values.users.length,
                    }))
                  }}
                  limit={100}
                  fieldLabel="Invite by emails"
                  fieldPlaceholder="Type email"
                  addButtonLabel="Add email"
                  validators={["isEmail"]}
                  validatorMessages={["Email is not valid"]}
                  hasError={errors.users}
                  errorMessage={
                    "You have to choose someone from existing users or invite people by email"
                  }
                />

                {/*<FormCheckbox*/}
                {/*  label={"Auto cancel"}*/}
                {/*  onChange={(e) =>*/}
                {/*    setValues((prevState) => ({*/}
                {/*      ...prevState,*/}
                {/*      auto_cancel: e.target.checked,*/}
                {/*    }))*/}
                {/*  }*/}
                {/*  value={values.auto_cancel}*/}
                {/*/>*/}

                <HoldsPicker
                  required={false}
                  multiple
                  value={values.holds}
                  onChange={(u) =>
                    setValues((prevValues) => ({
                      ...prevValues,
                      holds: u,
                    }))
                  }
                  // hasError={userError}
                  requestConfig={{
                    fetchConfig: {
                      apiUrl: USER.HOLDS.LIST,
                    },
                    key: "list",
                  }}
                />

                <div className="form__fieldset">
                  <label htmlFor="instructions" className="form__label">
                    Instructions
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="instructions"
                        name="instructions"
                        value={values.instructions}
                        placeholder="Type meet instructions"
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        multiline
                        validators={["maxStringLength:5000"]}
                        errorMessages={["Max length is 5000 characters"]}
                        onChange={handleChange("instructions")}
                      />
                    </div>
                  </div>
                </div>

                <div className="form__fieldset">
                  <label htmlFor="reminder" className="form__label">
                    Reminder
                  </label>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        id="reminder"
                        name="reminder"
                        value={values.reminder}
                        placeholder="Type meet reminder"
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        multiline
                        validators={["maxStringLength:5000"]}
                        errorMessages={["Max length is 5000 characters"]}
                        onChange={handleChange("reminder")}
                      />
                    </div>
                  </div>
                </div>

                <ContactLinkPicker
                  value={values.conference_link}
                  onChange={(v) =>
                    setValues((prevState) => ({
                      ...prevState,
                      conference_link: v,
                    }))
                  }
                />

                <MultipleTextField
                  formIsModal
                  values={values.links}
                  setValues={(links) =>
                    setValues((prevValues) => ({
                      ...prevValues,
                      links: links,
                    }))
                  }
                  limit={10}
                  fieldLabel="Other links"
                  fieldPlaceholder="Type URL"
                  addButtonLabel="Add link"
                  validators={["isLink", "maxStringLength:250"]}
                  validatorMessages={[
                    "Invalid URL",
                    "Max length is 250 characters",
                  ]}
                />

                <div className="form__fieldset">
                  <span className="form__label">Attachments</span>
                  <div className="form__fieldbox">
                    <div className="form__attachments">
                      <FileLoader
                        attachmentId={"attachments"}
                        blobs={values.attachments}
                        onUpload={({ blobs }) =>
                          setValues((prevState) => ({
                            ...prevState,
                            attachments: blobs,
                          }))
                        }
                        token={userData.token}
                        attachmentOnly={true}
                        limit={10}
                      />
                      <button
                        className="form__add-input"
                        type="button"
                        onClick={() => {
                          document.getElementById("attachments").click()
                        }}
                      >
                        <AddOutlined /> Add attachment
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Loader />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            onClick={processFormValidate}
            disabled={isSubmitting}
          >
            {!isSubmitting ? "Save" : "Saving..."}
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  )
}

export default MeetFormModal
