import React, { Component } from "react"
import { generatePath, NavLink, withRouter } from "react-router-dom"
import TextField from "@material-ui/core/TextField/TextField"
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment"
import SearchOutlined from "@material-ui/icons/SearchOutlined"
import * as routes from "../../../../library/constants/routes"
import Button from "@material-ui/core/Button"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import GroupCreate from "../groupCreate/groupCreate"
import * as qs from "query-string"
import ActionWithPermissions from "../../../../components/v2/controls/buttons/ActionWithPermissions"

const requestsTypes = ["requests-pending", "requests-outgoing"]
const managedTypes = [
  routes.GROUPS_MANAGED,
  routes.GROUPS_INTERESTS_MANAGED,
  routes.GROUPS_DISTRICTS_MANAGED,
  routes.GROUPS_SCHOOLS_MANAGED,
  routes.GROUPS_COMPANIES_MANAGED,
  routes.GROUPS_EVENTS_MANAGED,
]

class GroupsListHeader extends Component {
  state = {
    activeTab: !requestsTypes.includes(this.props.type) ? "all" : "pending",
    query: "",
    open: false,
    noResults: this.props.noResults,
  }

  componentDidMount() {
    if (managedTypes.includes(this.props.match.path)) {
      this.setState({ activeTab: "managed" })
    }
    if (this.props.match.path.includes("outgoing")) {
      this.setState({ activeTab: "outgoing" })
    }
    if (this.props.location.search !== "") {
      const { q: query } = qs.parse(this.props.location.search)
      this.setState({ query })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.path !== prevProps.match.path) {
      const { search } = this.props.location
      const { q: query } = qs.parse(search)
      if (query) {
        this.setState({ query: query })
      } else {
        this.setState({ query: "" })
      }

      switch (this.props.match.path) {
        case routes.GROUPS:
        case routes.GROUPS_INTERESTS:
        case routes.GROUPS_DISTRICTS:
        case routes.GROUPS_SCHOOLS:
        case routes.GROUPS_COMPANIES:
        case routes.GROUPS_EVENTS:
          this.setState({ activeTab: "all" })
          break

        case routes.GROUPS_MANAGED:
        case routes.GROUPS_INTERESTS_MANAGED:
        case routes.GROUPS_DISTRICTS_MANAGED:
        case routes.GROUPS_SCHOOLS_MANAGED:
        case routes.GROUPS_COMPANIES_MANAGED:
        case routes.GROUPS_EVENTS_MANAGED:
          this.setState({ activeTab: "managed" })
          break

        case routes.GROUPS_REQUESTS_PENDING:
          this.setState({ activeTab: "pending" })
          break

        case routes.GROUPS_REQUESTS_OUTGOING:
          this.setState({ activeTab: "outgoing" })
          break

        default:
          return
      }
    }
  }

  handleChange = (event) => {
    this.setState({ query: event.target.value })
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  processSearch = (event) => {
    event.preventDefault()

    const { query } = this.state
    const { history } = this.props

    history.push({
      // pathname: generatePath(routes.GROUPS_SEARCH, {userId: this.props.match.params.userId}),
      search: `?q=${query}`,
    })
    this.props.search(query)
  }

  render() {
    const { activeTab, query, open } = this.state
    const {
      auth: { userData },
      type,
      noSearchResults,
    } = this.props

    const iconStyle = { color: "#9b9a9b" }

    const composeTabs = () => {
      switch (this.props.match.path) {
        case routes.GROUPS:
        case routes.GROUPS_MANAGED:
          return {
            allTitle: "My groups",
            allUrl: generatePath(routes.GROUPS, { userId: userData.id }),
            noResultsAllTitle: "You don’t have groups yet",
            managedTitle: "My managed groups",
            managedUrl: routes.GROUPS_MANAGED,
            searchPlaceholder: "Search for groups",
            noResultsManagedTitle: "You don’t have managed groups yet",
          }

        case routes.GROUPS_INTERESTS:
        case routes.GROUPS_INTERESTS_MANAGED:
          return {
            allTitle: "My groups",
            allUrl: generatePath(routes.GROUPS_INTERESTS, {
              userId: userData.id,
            }),
            noResultsAllTitle: "You don’t have groups yet",
            managedTitle: "My managed groups",
            managedUrl: routes.GROUPS_INTERESTS_MANAGED,
            searchPlaceholder: "Search for groups",
            noResultsManagedTitle: "You don’t have managed groups yet",
          }

        case routes.GROUPS_COMPANIES:
        case routes.GROUPS_COMPANIES_MANAGED:
          return {
            allTitle: "My companies",
            allUrl: generatePath(routes.GROUPS_COMPANIES, {
              userId: userData.id,
            }),
            noResultsAllTitle: "You don’t have companies yet",
            managedTitle: "My managed companies",
            managedUrl: routes.GROUPS_COMPANIES_MANAGED,
            searchPlaceholder: "Search for companies",
            noResultsManagedTitle: "You don’t have managed companies yet",
          }

        case routes.GROUPS_DISTRICTS:
        case routes.GROUPS_DISTRICTS_MANAGED:
          return {
            allTitle: "My districts",
            allUrl: generatePath(routes.GROUPS_DISTRICTS, {
              userId: userData.id,
            }),
            noResultsAllTitle: "You don’t have districts yet",
            managedTitle: "My managed districts",
            managedUrl: routes.GROUPS_DISTRICTS_MANAGED,
            searchPlaceholder: "Search for districts",
            noResultsManagedTitle: "You don’t have managed districts yet",
          }

        case routes.GROUPS_SCHOOLS:
        case routes.GROUPS_SCHOOLS_MANAGED:
          return {
            allTitle: "My schools",
            allUrl: generatePath(routes.GROUPS_SCHOOLS, {
              userId: userData.id,
            }),
            noResultsAllTitle: "You don’t have schools yet",
            managedTitle: "My managed schools",
            managedUrl: routes.GROUPS_SCHOOLS_MANAGED,
            searchPlaceholder: "Search for schools",
            noResultsManagedTitle: "You don’t have managed schools yet",
          }

        case routes.GROUPS_EVENTS:
        case routes.GROUPS_EVENTS_MANAGED:
          return {
            allTitle: "My events",
            allUrl: generatePath(routes.GROUPS_EVENTS, { userId: userData.id }),
            noResultsAllTitle: "You don’t have events yet",
            managedTitle: "My managed events",
            managedUrl: routes.GROUPS_EVENTS_MANAGED,
            searchPlaceholder: "Search for events",
            noResultsManagedTitle: "You don’t have managed events yet",
          }

        default:
          return {
            allTitle: "My groups",
            allUrl: generatePath(routes.GROUPS, { userId: userData.id }),
            noResultsAllTitle: "You don’t have groups yet",
            managedTitle: "My managed groups",
            managedUrl: routes.GROUPS_MANAGED,
            searchPlaceholder: "Start typing group name",
            noResultsManagedTitle: "You don’t have managed groups yet",
          }
      }
    }

    const noResultsLabel = () => {
      switch (this.props.match.path) {
        case routes.GROUPS:
          return composeTabs().noResultsAllTitle

        case routes.GROUPS_MANAGED:
          return composeTabs().noResultsManagedTitle

        case routes.GROUPS_INTERESTS:
          return composeTabs().noResultsAllTitle

        case routes.GROUPS_INTERESTS_MANAGED:
          return composeTabs().noResultsManagedTitle

        case routes.GROUPS_COMPANIES:
          return composeTabs().noResultsAllTitle

        case routes.GROUPS_COMPANIES_MANAGED:
          return composeTabs().noResultsManagedTitle

        case routes.GROUPS_DISTRICTS:
          return composeTabs().noResultsAllTitle

        case routes.GROUPS_DISTRICTS_MANAGED:
          return composeTabs().noResultsManagedTitle

        case routes.GROUPS_SCHOOLS:
          return composeTabs().noResultsAllTitle

        case routes.GROUPS_SCHOOLS_MANAGED:
          return composeTabs().noResultsManagedTitle

        case routes.GROUPS_EVENTS:
          return composeTabs().noResultsAllTitle

        case routes.GROUPS_EVENTS_MANAGED:
          return composeTabs().noResultsManagedTitle

        case routes.GROUPS_REQUESTS_PENDING:
          return "You don't have pending requests to groups yet"

        case routes.GROUPS_REQUESTS_OUTGOING:
          return "You don't have outgoing requests to groups yet"

        default:
          return composeTabs().noResultsAllTitle
      }
    }

    return (
      <div className="box search-header">
        <nav
          className={`h-tabs ${!requestsTypes.includes(type) ? "h-tabs--with-button" : ""} f aic jcsb`}
        >
          <ul className="h-tabs__nav-list">
            {!requestsTypes.includes(type) ? (
              <>
                <li
                  className={`h-tabs__item ${activeTab === "all" ? "h-tabs__item--active" : ""}`}
                >
                  <NavLink
                    exact
                    activeClassName="h-tabs__item--active"
                    to={composeTabs().allUrl}
                    className="h-tabs__link"
                  >
                    {composeTabs().allTitle}
                  </NavLink>
                </li>
                <li
                  className={`h-tabs__item ${activeTab === "managed" ? "h-tabs__item--active" : ""}`}
                >
                  <NavLink
                    exact
                    activeClassName="h-tabs__item--active"
                    to={composeTabs().managedUrl}
                    className="h-tabs__link"
                  >
                    {composeTabs().managedTitle}
                  </NavLink>
                </li>
              </>
            ) : (
              <>
                <li
                  className={`h-tabs__item ${activeTab === "pending" ? "h-tabs__item--active" : ""}`}
                >
                  <NavLink
                    exact
                    activeClassName="h-tabs__item--active"
                    to={routes.GROUPS_REQUESTS_PENDING}
                    className="h-tabs__link"
                  >
                    Pending
                  </NavLink>
                </li>
                <li
                  className={`h-tabs__item ${activeTab === "outgoing" ? "h-tabs__item--active" : ""}`}
                >
                  <NavLink
                    exact
                    activeClassName="h-tabs__item--active"
                    to={routes.GROUPS_REQUESTS_OUTGOING}
                    className="h-tabs__link"
                  >
                    Outgoing
                  </NavLink>
                </li>
              </>
            )}
          </ul>
          {!requestsTypes.includes(type) ? (
            <>
              <ActionWithPermissions
                component={Button}
                color="primary"
                variant="contained"
                action={this.handleClickOpen}
              >
                Create Group
              </ActionWithPermissions>
              {open && <GroupCreate open={open} onClose={this.handleClose} />}
            </>
          ) : null}
        </nav>
        <div className="box__content">
          <form onSubmit={this.processSearch}>
            <TextField
              className="search-header__input full-width"
              id="search-header-input"
              placeholder={composeTabs().searchPlaceholder}
              margin="none"
              autoComplete="off"
              autoFocus={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined style={iconStyle} />
                  </InputAdornment>
                ),
              }}
              value={query}
              onChange={(e) => this.handleChange(e)}
            />
          </form>
          {this.props.noResults && (
            <div className="aic color-black-54 f jcc pt15">
              {noSearchResults ? "Result not found" : noResultsLabel()}
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ auth }) => ({ auth })
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(GroupsListHeader)))
