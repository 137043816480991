import React, { useEffect, useState } from "react"
import { useAlert } from "../../../../../../../hooks/useAlert"
import { useDispatch, useSelector } from "react-redux"
import { call } from "../../../../../../../library/networking/API"
import { generatePath, useParams } from "react-router-dom"
import {
  clearScheduleSubjectsList,
  getScheduleSubjectsList,
} from "../../../../../../../library/store/actions/creators/calendarCreators"
import ListPage from "../../../../../../../components/v2/layout/listPage/listPage"
import Loader from "../../../../../../../components/ui/loader"
import { CALENDARS } from "../../../../../../../library/store/actions/types/calendarTypes"
import PlainLink from "../../../../../../../components/ui/link"
import * as routes from "../../../../../../../library/constants/routes"
import { getObjectById } from "../../../../../../../library/utils/arrays"
import { calendarGrades } from "../../../../../../../library/constants/educationalDictionary"
import LineSlice from "../../../../../../../components/ui/lineSlice"

const GroupSettingsPacingCalendars = () => {
  const { errorAlert } = useAlert()
  const dispatch = useDispatch()
  const params = useParams()

  const { subjectsList, subjectsListEndReached } = useSelector(
    ({ calendar }) => calendar
  )

  const [masterCalendarsList, setMasterCalendarsList] = useState([])
  const [masterCalendarsReceived, setMasterCalendarsReceived] = useState(false)

  const getMasterCalendars = async () => {
    try {
      const response = await call(CALENDARS.LIST, {
        district_id: parseInt(params.groupId),
        not_deleted_only: true,
        offset: 0,
        limit: 100,
      })
      const masterCalendarsList = await response.data.calendars

      setMasterCalendarsList(masterCalendarsList)
      setMasterCalendarsReceived(true)
    } catch (error) {
      errorAlert(error)
    }
  }

  useEffect(() => {
    getMasterCalendars()
  }, [])

  return masterCalendarsReceived ? (
    <ListPage
      title={"Pacing Calendars"}
      // creationButtonTitle={'Create Pacing Calendar'}
      // hasButton={currentGroup?.type === 5}
      noResultsLabel={"This Calendar doesn't have Pacing Calendars yet"}
      filter={{
        label: "Master Calendar",
        emptyLabel: "Select Master Calendar",
        list: masterCalendarsList,
        ready: masterCalendarsReceived,
        initialValue: !!masterCalendarsList.length && masterCalendarsList[0].id,
        required: true,
      }}
      getList={async ({ filterId: calendar_id, name, offset }) =>
        await dispatch(
          getScheduleSubjectsList({
            calendar_id,
            name,
            offset,
          })
        )
      }
      clearList={() => dispatch(clearScheduleSubjectsList())}
      list={subjectsList}
      listEndReached={subjectsListEndReached}
      getListItemComponent={({ item, openEditDialog, openDeletionDialog }) => (
        <ListItem subject={item} key={item.id} />
      )}
    />
  ) : (
    <Loader />
  )
}

const ListItem = ({ subject }) => {
  const params = useParams()

  return (
    <div className={"box list-item"}>
      <div className="f aic full-width">
        <div className="list-item__info">
          <PlainLink
            to={generatePath(routes.GROUP_SETTINGS_PACING_CALENDAR, {
              groupId: params.groupId,
              subjectId: subject.id,
            })}
          >
            <span className="list-item__name">{subject.name}</span>
          </PlainLink>

          <div className={"mb15"}>
            Grade: {getObjectById(calendarGrades, subject.grade).name}
          </div>

          {!!subject.description && (
            <div className="break-word">
              <LineSlice text={subject.description} linesCount={2} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default GroupSettingsPacingCalendars
