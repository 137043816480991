import React from "react"
import LoadersWrapper from "./loadersWrapper"
import { withSnackbar } from "notistack"
import { uploadFiles } from "../../../library/utils/uploadFiles"
import getErrorText from "../../../library/constants/errorTypes"

class FileLoader extends React.PureComponent {
  state = {
    uploadedFiles: [],
    inProcess: false,
    filesLine: [],
    videos: this.props.videos ? [...this.props.videos] : [],
    images: this.props.images ? [...this.props.images] : [],
    blobs: this.props.blobs ? [...this.props.blobs] : [],
    videosInfo: [],
    imagesInfo: [],
    blobsInfo: [],
    limit: this.props.limit || 10,
  }

  componentDidMount() {
    const { videos, images, blobs } = this.state
    const modVideos = [...this.setItemsState(videos)],
      modImages = [...this.setItemsState(images)],
      modBlobs = [...this.setItemsState(blobs)]
    this.setState({
      uploadedFiles: [...modBlobs, ...modImages, ...modVideos],
      videosInfo: modVideos,
      imagesInfo: modImages,
      blobsInfo: modBlobs,
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.clearState !== this.props.clearState &&
      this.props.clearState
    ) {
      this.clearState()
    }
    if (
      prevProps.clearState !== this.props.clearState &&
      !this.props.clearState
    ) {
      this.setStateAsync({
        videos: this.props.videos ? [...this.props.videos] : [],
        images: this.props.images ? [...this.props.images] : [],
        blobs: this.props.blobs ? [...this.props.blobs] : [],
      })
    }
  }

  setItemsState = (array) => {
    const arrayCopy = array.slice()
    return arrayCopy.map((i) => {
      if (this.props.byUrl) {
        return {
          isUpload: true,
          isExist: true,
          url: i,
          key: i,
        }
      }
      // i.isUpload = true
      // i.isExist = true
      // i.key = i.url
      return { ...i, isUpload: true, isExist: true, key: i.url }
    })
  }

  setStateAsync = (state) => {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }
  processFiles = async (e, type) => {
    const filesList = this.state.uploadedFiles.slice()
    const files = e.target.files
    const { attachmentOnly } = this.props
    const { limit } = this.state

    if (filesList.length + files.length > limit) {
      this.props.enqueueSnackbar(
        `You can upload not more then ${limit} files`,
        { variant: "error" }
      )
      this.clearFileInputs()
      return false
    }

    if ([...files].find((f) => f.size > 10485760)) {
      this.props.enqueueSnackbar("File is too big", { variant: "error" })
      this.clearFileInputs()
      return false
    }

    await this.setStateAsync({
      inputFilesCount: this.state.inputFilesCount + files.length,
    })

    await this.setStateAsync({
      newPostActive: true,
    })
    ;[...files].map(async (newFile) => {
      const url = URL.createObjectURL(newFile)
      if (filesList.find((f) => f.key === url)) return
      newFile.key = url

      if (attachmentOnly) {
        newFile.key = url
        filesList.push({ type: 1, file: newFile, key: url })
        await this.setStateAsync({
          uploadedFiles: [...filesList],
          blobsInfo: [...this.state.blobsInfo, newFile],
        })
      } else {
        switch (type) {
          case "file":
            switch (newFile.type) {
              case "video/mp4":
                filesList.push({ type: 3, file: newFile, key: url })
                await this.setStateAsync({
                  uploadedFiles: [...filesList],
                  videosInfo: [...this.state.videosInfo, url],
                })
                break

              case "image/jpeg":
                filesList.push({ type: 2, file: newFile, key: url })
                await this.setStateAsync({
                  uploadedFiles: [...filesList],
                  imagesInfo: [...this.state.imagesInfo, url],
                })
                break

              case "image/gif":
                filesList.push({ type: 2, file: newFile, key: url })
                await this.setStateAsync({
                  uploadedFiles: [...filesList],
                  imagesInfo: [...this.state.imagesInfo, url],
                })
                break

              case "image/png":
                filesList.push({ type: 2, file: newFile, key: url })
                await this.setStateAsync({
                  uploadedFiles: [...filesList],
                  imagesInfo: [...this.state.imagesInfo, url],
                })
                break

              default:
                newFile.key = url
                filesList.push({ type: 1, file: newFile, key: url })
                await this.setStateAsync({
                  uploadedFiles: [...filesList],
                  blobsInfo: [...this.state.blobsInfo, newFile],
                })
                break
            }
            break

          case "image":
            filesList.push({ type: 2, file: newFile, key: url })
            await this.setStateAsync({
              uploadedFiles: [...filesList],
              imagesInfo: [...this.state.imagesInfo, url],
            })
            break

          case "video":
            filesList.push({ type: 3, file: newFile, key: url })
            await this.setStateAsync({
              uploadedFiles: [...filesList],
              videosInfo: [...this.state.videosInfo, url],
            })
            break

          default:
            newFile.key = url
            filesList.push({ type: 1, file: newFile, key: url })
            await this.setStateAsync({
              uploadedFiles: [...filesList],
              blobsInfo: [...this.state.blobsInfo, newFile],
            })
            break
        }
      }
    })
  }
  clearState = () => {
    this.setStateAsync({
      uploadedFiles: [],
      videos: [],
      images: [],
      blobs: [],

      videosInfo: [],
      imagesInfo: [],
      blobsInfo: [],
    })
  }
  uploadFile = async (
    type,
    newFile,
    onUploadProgress,
    token = this.props.token
  ) => {
    if (this.state.inProcess) {
      const { filesLine } = this.state
      filesLine.push({ type, newFile, onUploadProgress, token })
      this.setState({
        filesLine,
      })
      return
    }
    this.setState({
      inProcess: true,
    })
    try {
      const response = await uploadFiles(newFile, type, onUploadProgress, token)
      const el = this.props.byUrl ? response.url : { id: response.id }
      switch (type) {
        case 1:
          await this.setStateAsync({
            blobs: [...this.state.blobs, el],
          })
          break

        case 2:
          await this.setStateAsync({
            images: [...this.state.images, el],
          })
          break

        case 3:
          await this.setStateAsync({
            videos: [...this.state.videos, el],
          })
          break

        default:
          return
      }
      this.props.onUpload({
        videos: this.state.videos,
        images: this.state.images,
        blobs: this.state.blobs,
        videosInfo: this.state.videosInfo,
        imagesInfo: this.state.imagesInfo,
        blobsInfo: this.state.blobsInfo,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
    const fileList = [...this.state.uploadedFiles]
    const uf = fileList.find((f) => f.key === newFile.key)
    if (uf) uf.isUpload = true
    this.setState({
      uploadedFiles: [...fileList],
      inProcess: false,
    })
    if (this.state.filesLine.length) {
      const { filesLine } = this.state
      const file = filesLine.splice(0, 1)
      this.setState({
        filesLine,
      })
      this.uploadFile(
        file[0].type,
        file[0].newFile,
        file[0].onUploadProgress,
        file[0].token
      )
    }
    if (this.props.callBack) this.props.callBack()
  }
  clearFileInputs = () => {
    this.imgUploader.value = ""
    this.fileUploader.value = ""
  }
  deleteAttachment = async (type, index, key) => {
    const {
      uploadedFiles,
      blobs,
      images,
      videos,
      blobsInfo,
      imagesInfo,
      videosInfo,
    } = this.state
    const upIndex = uploadedFiles.findIndex((f) => f.key === key)
    uploadedFiles.splice(upIndex, 1)
    this.clearFileInputs()

    switch (type) {
      case "blob":
        await this.setStateAsync({
          blobs: [...blobs.slice(0, index), ...blobs.slice(index + 1)],
          blobsInfo: [
            ...blobsInfo.slice(0, index),
            ...blobsInfo.slice(index + 1),
          ],
          uploadedFiles,
        })
        break

      case "image":
        await this.setStateAsync({
          images: [...images.slice(0, index), ...images.slice(index + 1)],
          imagesInfo: [
            ...imagesInfo.slice(0, index),
            ...imagesInfo.slice(index + 1),
          ],
          uploadedFiles,
        })
        break

      case "video":
        await this.setStateAsync({
          videos: [...videos.slice(0, index), ...videos.slice(index + 1)],
          videosInfo: [
            ...videosInfo.slice(0, index),
            ...videosInfo.slice(index + 1),
          ],
          uploadedFiles,
        })
        break

      default:
        return
    }
    this.props.onUpload({
      videos: this.state.videos,
      images: this.state.images,
      blobs: this.state.blobs,
    })
  }

  render() {
    const { uploadedFiles, imagesInfo, videosInfo, blobsInfo } = this.state
    const { attachmentId, imageId, disabled } = this.props
    return (
      <>
        <LoadersWrapper
          uploadedFiles={uploadedFiles}
          images={imagesInfo}
          videos={videosInfo}
          blobs={blobsInfo}
          uploadFile={this.uploadFile}
          deleteAttachment={this.deleteAttachment}
          disabled={disabled}
        />

        <input
          type="file"
          accept="image/gif, image/png, image/jpeg, image/bmp, image/webp"
          multiple={this.props.limit > 1}
          className="hidden"
          id={imageId || "img-input"}
          ref={(ref) => (this.imgUploader = ref)}
          onChange={(e) => this.processFiles(e, "image")}
        />
        <input
          type="file"
          accept={this.props.accept || undefined}
          id={attachmentId || "attachments-input"}
          className="hidden"
          multiple={this.props.limit > 1}
          ref={(ref) => (this.fileUploader = ref)}
          onChange={(e) => this.processFiles(e, "file")}
        />
      </>
    )
  }
}

export default withSnackbar(FileLoader)
