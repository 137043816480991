import React from "react"
import "./verticalMenu.scss"
import { Link, NavLink, withRouter } from "react-router-dom"
import { compact } from "lodash"

const VerticalMenuTextParent = ({ label }) => {
  return (
    <li className="menu__item">
      <span className="link menu__link">{label}</span>
    </li>
  )
}

const VerticalMenuLink = ({
  type = "parent",
  pathName,
  label,
  markAsActive = true,
  hidden = false,
  controls,
  ignoreQuery,
  match,
  counter,
}) => {
  const linkClass =
    type === "child"
      ? "menu__link--child"
      : type === "child2"
        ? "menu__link--child-2"
        : ""

  const isActive = () => match.url === pathName.split("?")[0]

  return (
    !hidden && (
      <li className="menu__item">
        {markAsActive ? (
          ignoreQuery ? (
            <>
              <NavLink
                exact
                to={pathName}
                isActive={isActive}
                activeClassName="menu__link--active"
                className={`link menu__link ${linkClass}`}
              >
                {label}{" "}
                {!!counter && (
                  <div className="counter">
                    <span>{counter}</span>
                  </div>
                )}
              </NavLink>
              {controls}
            </>
          ) : (
            <>
              <NavLink
                exact
                to={pathName}
                activeClassName="menu__link--active"
                className={`link menu__link ${linkClass}`}
              >
                {label}{" "}
                {!!counter && (
                  <div className="counter">
                    <span>{counter}</span>
                  </div>
                )}
              </NavLink>
              {controls}
            </>
          )
        ) : (
          <Link to={pathName} className={`link menu__link ${linkClass}`}>
            {label}{" "}
            {!!counter && (
              <div className="counter">
                <span>{counter}</span>
              </div>
            )}
            {controls}
          </Link>
        )}
      </li>
    )
  )
}

const VerticalMenuButton = ({ action, label, disabled = false }) => {
  return (
    <li
      className={`menu__item menu__item--button ${
        disabled ? "menu__item--disabled" : ""
      }`}
    >
      <button
        className="btn btn--plain menu__link menu__button"
        onClick={action || (() => {})}
        disabled={disabled}
      >
        {label}
      </button>
    </li>
  )
}

const VerticalMenu = ({
  menuItems = [],
  collapsible,
  classes,
  ignoreQuery = false,
  location,
  match,
  children,
  title,
}) => {
  if (menuItems && compact(menuItems).length > 0) {
    return (
      <nav className={!collapsible ? `box box--menu ${classes}` : undefined}>
        {!!title && (
          <div className={"p-4 pb-0"}>
            <h3 className="menu__title">{title}</h3>
          </div>
        )}
        <ul className={`menu ${!!collapsible && "menu--collapsible"}`}>
          {menuItems &&
            menuItems.map((item) => {
              if (item) {
                switch (item.type) {
                  case undefined:
                    return (
                      <VerticalMenuLink
                        pathName={item.path}
                        label={item.label}
                        markAsActive={item.markAsActive}
                        hidden={item.hidden}
                        ignoreQuery={ignoreQuery}
                        location={location}
                        match={match}
                        key={item.key || item.label}
                        counter={item.counter}
                      />
                    )

                  case "parent":
                    return item.path ? (
                      <VerticalMenuLink
                        pathName={item.path}
                        label={item.label}
                        markAsActive={item.markAsActive}
                        hidden={item.hidden}
                        controls={item.controls}
                        ignoreQuery={ignoreQuery}
                        location={location}
                        match={match}
                        key={item.key || item.label}
                      />
                    ) : (
                      <VerticalMenuTextParent
                        label={item.label}
                        key={item.label}
                      />
                    )

                  case "child":
                    return (
                      <VerticalMenuLink
                        type="child"
                        pathName={item.path}
                        label={item.label}
                        hidden={item.hidden}
                        controls={item.controls}
                        ignoreQuery={ignoreQuery}
                        location={location}
                        match={match}
                        key={item.key || item.label}
                      />
                    )

                  case "child2":
                    return (
                      <VerticalMenuLink
                        type="child2"
                        pathName={item.path}
                        label={item.label}
                        hidden={item.hidden}
                        controls={item.controls}
                        ignoreQuery={ignoreQuery}
                        location={location}
                        match={match}
                        key={item.key || item.label}
                      />
                    )

                  case "button":
                    return (
                      <VerticalMenuButton
                        action={item.action}
                        label={item.label}
                        disabled={item.disabled}
                        key={item.key || item.label}
                      />
                    )

                  case "h-divider":
                    return <div className="h-divider" key={item.id}></div>

                  default:
                    return null
                }
              }
            })}
        </ul>
        {children}
      </nav>
    )
  } else {
    return children || null
  }
}

export default withRouter(VerticalMenu)
