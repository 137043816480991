import { useMutation, useQueryClient } from "@tanstack/react-query"
import { KNOWMIX } from "../../../library/store/actions/types/knowmixTypes"
import { call } from "../../../library/networking/API"
import { useAlert } from "../../useAlert"
import React from "react"
import { mutateInfinite } from "../../../library/utils/reactQuery"

export const useCourseMembersMutations = ({ queryKey }) => {
  const queryClient = useQueryClient()
  const { errorAlert } = useAlert()

  const [mutatingIds, setMutatingIds] = React.useState([])

  const addMember = useMutation({
    mutationFn: async ({ user_id, knowmix_id }) => {
      try {
        setMutatingIds((prevState) => [...prevState, user_id])
        await call(KNOWMIX.MEMBERS.ADD, {
          user_id,
          knowmix_id,
        })
      } catch (e) {
        errorAlert(e)
      }
    },
    onSuccess: async (data, { user_id }) => {
      await mutateInfinite({
        queryClient,
        key: queryKey,
        itemUpdater: (u) => {
          return u.id === user_id
            ? {
                ...u,
                knowmix: { ...u.knowmix, is_member: true },
              }
            : u
        },
      })

      setMutatingIds((prevState) => prevState.filter((id) => id !== user_id))
    },
  })

  const deleteMember = useMutation({
    mutationFn: async ({ user_id, knowmix_id }) => {
      try {
        setMutatingIds((prevState) => [...prevState, user_id])
        await call(KNOWMIX.MEMBERS.DELETE, {
          user_id,
          knowmix_id,
        })
      } catch (e) {
        errorAlert(e)
      }
    },
    onSuccess: async (data, { user_id }) => {
      await mutateInfinite({
        queryClient,
        key: queryKey,
        itemUpdater: (u) => {
          return u.id === user_id
            ? {
                ...u,
                knowmix: { ...u.knowmix, is_member: false },
              }
            : u
        },
      })

      setMutatingIds((prevState) => prevState.filter((id) => id !== user_id))
    },
  })

  return {
    mutatingIds,
    addMember,
    deleteMember,
  }
}
