import FormRadioGroup from "../../../../../components/formComponents/formRadioGroup"

const ImageSourcePicker = ({ onChange, value, label, name }) => {
  return (
    <FormRadioGroup
      label={label}
      value={value}
      onChange={onChange}
      options={[
        { label: "From KnowStory library", value: "library" },
        { label: "Upload your own image", value: "own" },
      ]}
      name={name}
    />
  )
}

export default ImageSourcePicker
