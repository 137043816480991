import React from "react"
import { useCurrentUser } from "../../../../hooks/data/user/useUser"
import { useIdParam } from "../../../../hooks/useIdParam"

export const PostContext = React.createContext(null)

export const usePostCtx = () => {
  return React.useContext(PostContext)
}

const PostProvider = ({
  post,
  pageOwnerUser,
  pageOwnerGroup,
  isBookmarksPage = false,
  queryKey,
  children,
}) => {
  const pageOwnerId = useIdParam("userId")
  const { id: currentUserid } = useCurrentUser()

  const isMine = post.creator_user
    ? post?.creator_user?.can_delete ||
      post.creator_user.id === currentUserid ||
      currentUserid === pageOwnerId
    : currentUserid === pageOwnerId

  const [isEdit, setIsEdit] = React.useState(false)
  const [isArchived, setIsArchived] = React.useState(false)
  const [isCommenting, setIsCommenting] = React.useState(false)
  const [isReplying, setIsReplying] = React.useState(false)

  return (
    <PostContext.Provider
      value={{
        post,
        pageOwnerUser,
        pageOwnerGroup,
        isBookmarksPage,
        queryKey,
        isMine,
        isEdit,
        setIsEdit,
        isCommenting,
        setIsCommenting,
        isArchived,
        setIsArchived,
        isReplying,
        setIsReplying,
      }}
    >
      {/*<PostContext.Consumer>*/}
      {children}
      {/*</PostContext.Consumer>*/}
    </PostContext.Provider>
  )
}

export default PostProvider
