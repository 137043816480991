import React, { Component } from "react"
import * as qs from "query-string"
import getErrorText from "../../../../../library/constants/errorTypes"
import { LIMIT } from "../../../../../library/constants/limits"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { generatePath, Link, withRouter } from "react-router-dom"
import {
  clearDemoRequestsList,
  demoRequestsList,
  scheduleDemo,
} from "../../../../../library/store/actions/creators/productsCreators"
import ListSearchHeader from "../../../../../components/ui/listSearchHeader/listSearchHeader"
import Button from "@material-ui/core/Button/Button"
import * as routes from "../../../../../library/constants/routes"
import { format } from "date-fns"
import userAvatarDefault from "../../../../../assets/img/user_avatar.svg"
import Loader from "../../../../../components/ui/loader"
import DemoRequestFormModal from "./demoRequestFormModal/demoRequestFormModal"

class ProductSettingsDemoRequests extends Component {
  state = {
    offset: 0,
    showFormModal: false,
    dataReceived: false,
    activeDemo: null,
  }

  async componentDidMount() {
    await this.getList()
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      location: { search },
    } = this.props

    if (search && search !== prevProps.location.search) {
      this.props.clearDemoRequestsList()
      await this.getList()
    }
  }

  async componentWillUnmount() {
    await this.props.clearDemoRequestsList()
  }

  getList = async (offset = 0) => {
    const { q } = qs.parse(this.props.location.search)

    try {
      this.setState({ dataReceived: false })
      await this.props.demoRequestsList({
        product_id: parseInt(this.props.match.params.productId),
        name: q || undefined,
        offset,
      })
      this.setState({ dataReceived: true, offset })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  onScroll = async () => {
    const { q: name } = qs.parse(this.props.location.search)

    if (!this.state.dataReceived || this.props.products.demosListEndReached)
      return

    await this.getList({ offset: this.state.offset + LIMIT, name })
  }

  searchDemos = (name) => {
    const { history } = this.props

    history.push({
      search: `?q=${name}`,
    })
  }

  onRequestOpen = (demo) => {
    this.setState({ activeDemo: demo, showFormModal: true })
  }

  onRequestClose = () => {
    this.setState({ activeDemo: null, showFormModal: false })
  }

  render() {
    const { q: query } = qs.parse(this.props.location.search)
    return (
      <>
        <ListSearchHeader
          title="Demo requests"
          placeholder="Search for requests"
          search={this.searchDemos}
          noResults={
            this.state.dataReceived && !this.props.products.demosList.length
          }
          noResultsLabel={
            query ? "" : "There are no demo requests for this product"
          }
        />

        {this.props.products.demosList.map((item) => {
          return (
            <DemoRequestListItem
              key={item.id}
              demo={item}
              onRequestOpen={() => this.onRequestOpen(item)}
            />
          )
        })}

        {!this.state.dataReceived && <Loader />}

        {this.state.showFormModal && (
          <DemoRequestFormModal
            open={this.state.showFormModal}
            onClose={this.onRequestClose}
            request={this.state.activeDemo}
          />
        )}
      </>
    )
  }
}

const DemoRequestListItem = ({ demo, onRequestOpen }) => {
  const fullName = demo.user.first_name + " " + demo.user.last_name

  return (
    <div className={"box list-item"}>
      <div className="f aic full-width">
        <div className="list-item__img">
          <img src={demo.user.photo || userAvatarDefault} alt={fullName} />
        </div>
        <div className="list-item__info">
          <Link
            to={generatePath(routes.USER, { userId: demo.user.id })}
            className="link list-item__name"
          >
            {fullName}
          </Link>
          <span className="list-item__description">
            {format(new Date(demo.date), "d MMM 'at' h:mm aaa")}
          </span>
        </div>
        <div className={`list-item__control-panel`}>
          <Button
            color={"primary"}
            variant={"contained"}
            onClick={onRequestOpen}
          >
            Manage
          </Button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth, groups, products }) => ({
  auth,
  groups,
  products,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      scheduleDemo,
      demoRequestsList,
      clearDemoRequestsList,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(ProductSettingsDemoRequests)))
