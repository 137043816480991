import React from "react"
import HoldsPicker from "../../../components/v2/inputs/pickers/HoldsPicker"
import MeetTypeSwitcher from "./MeetTypeSwitcher"
import { APPOINTMENTS } from "../../../library/store/actions/types/calendarTypes"

const BookingHoldsAndTypePicker = ({
  selectedHolds,
  onHoldsChange,
  types,
  selectedType,
  onTypeChange,
  user,
}) => {
  return (
    <div className="full-width f jcc">
      <div className="form form--modal">
        <HoldsPicker
          reqiured={false}
          multiple
          value={selectedHolds}
          onChange={onHoldsChange}
          requestConfig={{
            fetchConfig: {
              apiUrl: APPOINTMENTS.PUBLIC_HOLDS,
              params: { user_id: user.id },
            },
            key: "list",
          }}
        />
        <MeetTypeSwitcher
          availableTypes={types}
          value={selectedType}
          onChange={onTypeChange}
        />
      </div>
    </div>
  )
}

export default BookingHoldsAndTypePicker
