import { useHistory, useLocation, useRouteMatch } from "react-router-dom"
import React from "react"
import { useInfiniteQuery } from "@tanstack/react-query"
import * as qs from "query-string"
import { call } from "../library/networking/API"
import { LIMIT } from "../library/constants/limits"
import { useWindowVirtualizer } from "@tanstack/react-virtual"

export const useListPage = ({ queryConfig, listItemHeight }) => {
  const history = useHistory()
  const match = useRouteMatch()
  const { search } = useLocation()

  const filtersParsed = qs.parse(search, { parseBooleans: true })

  const [initialFetchParams, setIInitialFetchParams] = React.useState(
    JSON.stringify(queryConfig.fetchParams)
  )

  const [isRefetching, setIsRefetching] = React.useState(false)

  const query = useInfiniteQuery({
    staleTime: 30000,
    queryKey: queryConfig.key,
    queryFn: async (ctx) => {
      const response = await call(queryConfig.url || queryConfig.key[0], {
        ...queryConfig.fetchParams,
        name: filtersParsed.q || undefined,
        offset: ctx.pageParam || 0,
        limit: LIMIT,
      })

      const list = await response.data[queryConfig.listKey]

      return list
    },
    getNextPageParam: (prevPage, allPages) => {
      if (prevPage.length === LIMIT) return allPages.length * LIMIT
    },
  })

  const allItems = query.data ? query.data.pages.flat() : []

  const virtualizer = useWindowVirtualizer({
    count: query.hasNextPage ? allItems.length + 1 : allItems.length,
    estimateSize: () => listItemHeight + 15,
    overscan: 5,
  })

  React.useEffect(() => {
    const [lastItem] = [...virtualizer.getVirtualItems()].reverse()

    if (!lastItem) {
      return
    }

    if (
      lastItem.index >= allItems.length - 1 &&
      query.hasNextPage &&
      !query.isFetchingNextPage
    ) {
      query.fetchNextPage()
    }
  }, [
    query.hasNextPage,
    query.fetchNextPage,
    allItems.length,
    query.isFetchingNextPage,
    virtualizer.getVirtualItems(),
  ])

  // const onSearch = (name) => {
  //   const fields = {
  //     ...filtersParsed,
  //     name: name || undefined,
  //   }
  //
  //   composeQuery({ fields, history, path: match.path })
  // }

  React.useEffect(() => {
    if (query.status === "success") {
      ;(async () => {
        setIsRefetching(true)
        await query.refetch({})
        setIsRefetching(false)
      })()
    }
  }, [search])

  React.useEffect(() => {
    if (
      query.status === "success" &&
      JSON.stringify(queryConfig.fetchParams) !== initialFetchParams
    ) {
      ;(async () => {
        setIsRefetching(true)
        await query.refetch({})
        setIsRefetching(false)
      })()
    }
  }, [queryConfig.fetchParams])

  return {
    isSearching: isRefetching,
    allItems,
    query,
    virtualizer,
    searchParams: {
      name: filtersParsed.q || undefined,
    },
  }
}
