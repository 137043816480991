import { STANDARDS } from "../actions/types/standardsTypes"

const initialState = {
  standardsList: [],
  currentStandard: null,
  standardsCount: null,
}

export const standardsReducer = (state = initialState, action) => {
    switch (action.type) {
        case STANDARDS.SEARCH_LIST:
            return {
                ...state,
                standardsList: [...state.standardsList, ...action.payload.standards],
                standardsCount: action.payload.number,
            };
        case STANDARDS.GET:
            return {
                ...state,
                currentStandard: action.payload,
            };
        case STANDARDS.CLEAR:
            return {
                ...initialState,
            };
        default:
            return {
                ...state,
            };

    }
};
