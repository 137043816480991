import React from "react"
import GroupSettingsCalendarProvider from "../../../GroupSettingsCalendarContext"
import { getObjectById } from "../../../../../../../../../library/utils/arrays"
import { calendarGrades } from "../../../../../../../../../library/constants/educationalDictionary"
import { withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"

const GroupSettingsCalendarSubjectInfo = (props) => {
  return (
    <div className="box">
      <div className="box__heading">Subject info</div>
      <div className="box__content">
        <table className="product-tile__info mb0">
          <tbody>
            <tr>
              <td className="product-tile__info-label">Name:</td>
              <td className="product-tile__info-value">
                {props.calendar.currentSubject.name}
              </td>
            </tr>
            {!!props.calendar.currentSubject.description.length && (
              <tr>
                <td className="product-tile__info-label">Description:</td>
                <td className="product-tile__info-value">
                  {props.calendar.currentSubject.description}
                </td>
              </tr>
            )}

            <tr>
              <td className="product-tile__info-label">Calendar:</td>
              <td className="product-tile__info-value">
                <GroupSettingsCalendarProvider>
                  {({ onCalendarDialogOpen }) => (
                    <button
                      className={"link"}
                      onClick={() =>
                        onCalendarDialogOpen(
                          props.calendar.currentSubject.calendar.id
                        )
                      }
                    >
                      {props.calendar.currentSubject.calendar.name}
                    </button>
                  )}
                </GroupSettingsCalendarProvider>
                {/*{props.calendar.currentSubject.calendar.name}*/}
              </td>
            </tr>

            <tr>
              <td className="product-tile__info-label">Grade:</td>
              <td className="product-tile__info-value">
                {
                  getObjectById(
                    calendarGrades,
                    props.calendar.currentSubject.grade
                  ).name
                }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth, groups, calendar }) => ({
  auth,
  groups,
  calendar,
})
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(GroupSettingsCalendarSubjectInfo)))
