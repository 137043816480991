import React, { Component } from "react"
import "./listSearchHeader.scss"
import { Link, withRouter } from "react-router-dom"
import TextField from "@material-ui/core/TextField/TextField"
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment"
import SearchOutlined from "@material-ui/icons/SearchOutlined"
import Button from "@material-ui/core/Button"
import * as routes from "../../../library/constants/routes"
import * as qs from "query-string"

class ListSearchHeader extends Component {
  state = {
    query: "",
  }

  componentDidMount() {
    const { name, q } = qs.parse(this.props.location.search)
    this.setState({ query: name ? name : q && q !== "undefined" ? q : "" })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { name, q } = qs.parse(this.props.location.search)

    if (
      this.props.location.search !== prevProps.location.search ||
      this.props.match.path !== prevProps.match.path ||
      this.props.tempInputValue
    ) {
      this.setState({ query: name ? name : q && q !== "undefined" ? q : "" })
    }
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }

  handleChange = (event) => {
    this.setState({ query: event.target.value })
    this.props.queryCallback && this.props.queryCallback(event.target.value)
  }

  processSearch = (event) => {
    event.preventDefault()

    const { query } = this.state

    this.props.search(query.trim())
  }

  render() {
    const {
      title,
      noResults,
      noResultsLabel,
      showStartChat,
      hasButton,
      buttonAction,
      buttonLabel,
      placeholder,
      counter,
      noSearch,
    } = this.props
    const { query } = this.state
    const iconStyle = { color: "#9b9a9b" }

    return (
      <div className="box search-header">
        <div className="box__heading">
          <div className="f jcsb aic">
            <div className="f">
              {title}
              {/*{!!counter && <span className="ml8 color-black-54">{counter}</span>}*/}
            </div>
            {showStartChat && showStartChat === true && (
              <Button
                variant="contained"
                color="primary"
                to={routes.CONVERSATIONS_NEW}
                component={Link}
              >
                Start a chat
              </Button>
            )}
            {!!hasButton && hasButton === true && (
              <Button
                variant="contained"
                color="primary"
                onClick={buttonAction}
              >
                {buttonLabel}
              </Button>
            )}
          </div>
        </div>
        <div className="box__content">
          {!noSearch && (
            <form onSubmit={this.processSearch}>
              <TextField
                className="search-header__input full-width"
                id="search-header-input"
                placeholder={placeholder}
                margin="none"
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlined style={iconStyle} />
                    </InputAdornment>
                  ),
                }}
                value={query}
                onChange={this.handleChange}
              />
            </form>
          )}
          {noResults && (
            <div className={`aic color-black-54 f jcc pt15`}>
              {noResultsLabel || "Result not found"}
            </div>
          )}
        </div>
        {!!this.props.suggestions && this.props.suggestions}
      </div>
    )
  }
}

export default withRouter(ListSearchHeader)
