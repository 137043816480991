import React from "react"
import styled from "@emotion/styled"
import { colors } from "../../../../library/constants/styles/colors"
import { devices } from "../../../../library/constants/styles/responsive"

export const BoxContainer = styled.div`
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 0 2px 0 rgba(210, 210, 210, 0.5);
`

export const BoxHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 67px;
  padding: 15px;
  background-color: ${(props) =>
    props.transparent ? "transparent" : colors.primary15};
  color: ${colors.black87};
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;

  h2 {
    display: block;
    width: ${(props) => (!props.withButton ? "674px" : "auto")};
    min-height: 27px;
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-size: 1.25rem;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media ${devices.xs} {
      width: 275px;
    }
  }
`

export const BoxContent = styled.div`
  padding: 15px;

  &:last-child {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  span {
    word-break: break-word;
  }

  .mr8 {
    word-break: normal;
  }
`

const BoxPage = ({ title, children }) => {
  return (
    <BoxContainer>
      <BoxHeading>
        <h2>{title}</h2>
      </BoxHeading>
      <BoxContent>{children}</BoxContent>
    </BoxContainer>
  )
}

export default BoxPage
