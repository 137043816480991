import React, { Component } from "react"

import "./sign-in-full.scss"
import Checkbox from "@material-ui/core/Checkbox/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import { Link, withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"
import { signIn } from "../../../../library/store/actions/creators/authCreators"
import { connect } from "react-redux"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import { withSnackbar } from "notistack"
import getErrorText from "../../../../library/constants/errorTypes"
import Button from "@material-ui/core/Button"

class SignInFull extends Component {
  state = {
    email: "",
    password: "",
    remember: false,
  }

  processSignIn = async () => {
    const { email, password } = this.state

    try {
      await this.props.signIn(email.toLowerCase(), password)
    } catch ({ error }) {
      console.log(";component", error)
      if (error.code === 20002) error.code = 20006
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked })
  }

  render() {
    const { email, password, remember } = this.state

    return (
      <div className="sign-up-form hero-form">
        <div className="sign-up-form__header">
          <h2 className="sign-up-form__heading">Sign In</h2>
        </div>
        <ValidatorForm
          className="sign-up-form__form"
          onSubmit={this.processSignIn}
          instantValidate={false}
        >
          <div className="sign-up-form__field-wrapper">
            <TextValidator
              type="text"
              name="email"
              value={email}
              label="Email *"
              className="m-text-field"
              margin="none"
              fullWidth
              helperText="&nbsp;"
              validators={["required", "isEmail"]}
              errorMessages={["Field is required", "Email is not valid"]}
              onChange={(email) => this.setState({ email: email.target.value })}
            />
          </div>

          <div className="sign-up-form__field-wrapper">
            <TextValidator
              type="password"
              name="password"
              value={password}
              label="Password *"
              className="m-text-field"
              margin="none"
              fullWidth
              helperText="&nbsp;"
              validators={[
                "required",
                // 'minStringLength:8',
                // 'maxStringLength:32'
              ]}
              errorMessages={[
                "Field is required",
                // 'Min length is 8 characters',
                // 'Max length is 32 characters'
              ]}
              onChange={(password) =>
                this.setState({ password: password.target.value })
              }
            />
          </div>

          <div className="f aic jcsb full-width mb20">
            <div className="full-width">
              <div className="checkbox m-checkbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={remember}
                      value={"remember"}
                      disableRipple
                      onChange={this.handleChange("remember")}
                    />
                  }
                  label="Remember me"
                />
              </div>
            </div>
            <Link to="/reset-password" className="link nowrap">
              Forgot password?
            </Link>
          </div>

          <Button color="primary" variant="contained" type="submit">
            Sign In
          </Button>
        </ValidatorForm>
      </div>
    )
  }
}

const mapStateToProps = ({ auth }) => ({ auth })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ signIn }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(SignInFull)))
