import { Tooltip } from "@material-ui/core"
import React from "react"
import { colors } from "../../../../library/constants/styles/colors"
import InfoIcon from "@material-ui/icons/Info"
import { isLast } from "../../../../library/utils/arrays"

const ChosenStandardsList = ({ standards }) => {
  return standards.length ? (
    <div>
      {standards.map((s, i) => (
        <div
          className={`f aic ${!isLast(standards, i) ? "mb5" : ""}`}
          key={s.id}
        >
          {!!s.description && (
            <Tooltip
              title={
                <p style={{ color: "inherit", fontSize: 13 }}>
                  {s.description}
                </p>
              }
              arrow
            >
              <InfoIcon
                style={{
                  marginRight: 5,
                  fontSize: 20,
                  color: colors.primary,
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          )}
          <span>{s.name}</span>
        </div>
      ))}
    </div>
  ) : (
    "—"
  )
}

export default ChosenStandardsList
