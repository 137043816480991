import { KNOWCREDS } from "../actions/types/knowCredsTypes"

const initialState = {
  myKnowCredsList: [],
  createdCred: null,
  myLastKnowCredsList: [],
  sharedKnowCredsList: [],
  knowCredsList: [],
  lastKnowCredsList: [],
  currentKnowCred: {},
  foregroundsList: {
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
  },
  backgroundsList: [],
  knowCredsNumber: "",
  validatedKnowCredsNumber: "",
}

export const knowCredsReducer = (state = initialState, action) => {
    switch (action.type) {
        case KNOWCREDS.CREATE:
            return {
                ...state,
                createdCred: action.payload,
            };

        case KNOWCREDS.EDIT:
            return {
                ...state,
            };

        case KNOWCREDS.UPDATE_PUBLIC_STATUS:
            return {
                ...state,
                currentKnowCred: {...state.currentKnowCred, public: action.payload.public}
            }

        case KNOWCREDS.FOREGROUNDS_LIST:
            return {
                ...state,
                foregroundsList: {...state.foregroundsList, [action.payload.type]: action.payload.list},
            };

        case KNOWCREDS.BACKGROUNDS_LIST:
            return {
                ...state,
                backgroundsList: action.payload,
            };

        case KNOWCREDS.DELETE:
            return {
                ...state,
            };

        case KNOWCREDS.GET:
            return {
                ...state,
                currentKnowCred: action.payload,
            };

        case KNOWCREDS.CLEAR:
            return {
                ...state,
                currentKnowCred: null,
            };

        case KNOWCREDS.CLEAR_CREATED:
            return {
                ...state,
                createdCred: null,
            };

        case KNOWCREDS.MY_LIST:
            return {
                ...state,
                myKnowCredsList: [...state.myKnowCredsList, ...action.payload.knowcreds],
                knowCredsNumber: action.payload.knowcreds_number,
                validatedKnowCredsNumber: action.payload.knowcreds_valid_number,
            };

        case KNOWCREDS.MY_LAST_LIST:
            return {
                ...state,
                myLastKnowCredsList: action.payload,
            };

        case KNOWCREDS.MY_LAST_LIST_CLEAR:
            return {
                ...state,
                myLastKnowCredsList: [],
            };

        case KNOWCREDS.SHARED_LIST:
            return {
                ...state,
                sharedKnowCredsList: [...state.sharedKnowCredsList, ...action.payload.knowcreds],
                knowCredsNumber: action.payload.knowcreds_number,
                validatedKnowCredsNumber: action.payload.knowcreds_valid_number,
            };

        case KNOWCREDS.LIST:
            return {
                ...state,
                knowCredsList: [...state.knowCredsList, ...action.payload.knowcreds],
                knowCredsNumber: action.payload.knowcreds_number,
                validatedKnowCredsNumber: action.payload.knowcreds_valid_number,
            };

        case KNOWCREDS.LAST_LIST:
            return {
                ...state,
                lastKnowCredsList: action.payload,
            };

        case KNOWCREDS.LIST_CLEAR:
            return {
                ...initialState,
                // ...state,
                // knowCredsList: []
            };

        case KNOWCREDS.LAST_LIST_CLEAR:
            return {
                ...state,
                lastKnowCredsList: [],
            };

        default:
            return state;

    }
};
