import React from "react"
import GroupSettingsCalendarProvider from "../../GroupSettingsCalendarContext"
import { getObjectById } from "../../../../../../../../library/utils/arrays"
import { calendarGrades } from "../../../../../../../../library/constants/educationalDictionary"
import { useSelector } from "react-redux"

const PacingCalendarInfo = () => {
  const { currentSubject } = useSelector(({ calendar }) => calendar)
  return (
    <div className="box">
      <div className="box__heading">Subject info</div>
      <div className="box__content">
        <table className="product-tile__info mb0">
          <tbody>
            <tr>
              <td className="product-tile__info-label pb8">Name:</td>
              <td className="product-tile__info-value pb8">
                {currentSubject.name}
              </td>
            </tr>
            {!!currentSubject.description.length && (
              <tr>
                <td className="product-tile__info-label pb8">Description:</td>
                <td className="product-tile__info-value pb8">
                  {currentSubject.description}
                </td>
              </tr>
            )}

            <tr>
              <td className="product-tile__info-label pb8">Master Calendar:</td>
              <td className="product-tile__info-value pb8">
                <GroupSettingsCalendarProvider>
                  {({ onCalendarDialogOpen }) => (
                    <button
                      className={"link"}
                      onClick={() =>
                        onCalendarDialogOpen(currentSubject.calendar.id)
                      }
                    >
                      {currentSubject.calendar.name}
                    </button>
                  )}
                </GroupSettingsCalendarProvider>
                {/*{props.calendar.currentSubject.calendar.name}*/}
              </td>
            </tr>

            <tr>
              <td className="product-tile__info-label">Grade:</td>
              <td className="product-tile__info-value">
                {getObjectById(calendarGrades, currentSubject.grade).name}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default PacingCalendarInfo
