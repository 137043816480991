import React from "react"
import styled from "@emotion/styled"
import { devices } from "../../../library/constants/styles/responsive"

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
  }

  @media ${devices.xs} {
    grid-template-columns: 1fr;
  }
`

const GridList = (props) => {
  return <Container>{props.children}</Container>
}

export default GridList
