import React, { useState } from "react"
import { Autocomplete } from "@material-ui/lab"
import { FormControl, FormHelperText, TextField } from "@material-ui/core"
import Loader from "../../../ui/loader"
import useSelect from "../../../../hooks/useAsyncSelect"
import SelectOption from "../../utils/forms/selectOption"
import EmptyListError from "../../utils/forms/emptyListError"
import ErrorMessage from "../../utils/forms/errorMessage"
import { capitalize } from "lodash"

const SuperSelect = ({
  fieldName,
  value = null,
  onChange,
  required = false,
  multiple = false,
  disabled = false,
  hasError = false,
  errorMessage = "Field is required",
  requestConfig,
  entityName,
  getItemPhoto,
  getValueName,
  getDisabledValue,
  disabledValueMessage,
  emptyListMessage,
  emptyListLink,
  isWaitingForOtherFields = false,
  isWaitingForOtherFieldsMessage = "",
  leftLabel = true,
  getOptionNameFallback,
  withOptionTooltip = false,
  getOptionTooltipText,
  getOptionTooltipIcon,
  getValueHelperText,
  getOptionHelperText,
  renderTags,
  fieldOnly = false,
}) => {
  const {
    data,
    dataReceived,
    fetchList,
    isLoadingMore,
    onScroll,
    processSearch,
    onListBoxClose,
    onSearchChange,
  } = useSelect({ requestConfig, loadOnMount: !isWaitingForOtherFields })
  const [name, setName] = useState("")

  React.useEffect(() => {
    const search = setTimeout(() => {
      processSearch()
    }, 1000)

    return () => clearTimeout(search)
  }, [name])

  const onNameChange = (v = "") => {
    onSearchChange(v)
    setName(v)
  }

  const getOptionName = (option) => {
    if (!option) return ""

    if (typeof getValueName === "function" && getValueName(option).length) {
      return getValueName(option)
    }

    if (option?.name?.length) {
      return option.name
    }

    if (
      typeof getOptionNameFallback === "function" &&
      getOptionNameFallback().length
    ) {
      return getOptionNameFallback()
    }

    return `Unnamed option`
  }

  const optionTooltipText = (option) => {
    return typeof getOptionTooltipText === "function"
      ? getOptionTooltipText(option)
      : ""
  }

  const optionTooltipIcon = () => {
    return typeof getOptionTooltipIcon === "function"
      ? getOptionTooltipIcon()
      : undefined
  }

  const field = () => {
    return (
      <>
        {dataReceived && !data.length && !name && !!emptyListMessage ? (
          <EmptyListError message={emptyListMessage} link={emptyListLink} />
        ) : (
          <FormControl error={hasError} fullWidth>
            <Autocomplete
              id={fieldName}
              disableCloseOnSelect={!!multiple}
              multiple={multiple}
              onOpen={() => fetchList(true)}
              onClose={() => {
                onListBoxClose()
                onNameChange("")
              }}
              options={data}
              value={value}
              onChange={(_e, option) => onChange(option)}
              disabled={isWaitingForOtherFields || disabled}
              // disableClearable
              getOptionDisabled={(option) =>
                getDisabledValue?.(option) || false
              }
              renderOption={(option) => {
                const isItemDisabled = getDisabledValue?.(option) || false
                const photo = getItemPhoto?.(option)
                const itemHelperText = getOptionHelperText?.(option) || ""

                return (
                  <SelectOption
                    name={getOptionName(option)}
                    photo={photo}
                    isDisabled={isItemDisabled}
                    disabledMessage={disabledValueMessage}
                    withTooltip={withOptionTooltip}
                    tooltipText={optionTooltipText(option)}
                    tooltipIcon={optionTooltipIcon()}
                    itemHelperText={itemHelperText}
                  />
                )
              }}
              noOptionsText={
                !dataReceived ? (
                  <div className={"f jcc"}>
                    <Loader size={20} />
                  </div>
                ) : (
                  <p className={"text-center"}>No results</p>
                )
              }
              filterOptions={(options) => options}
              renderInput={(params) => {
                return (
                  <TextField
                    error={hasError}
                    {...params}
                    label={!leftLabel ? capitalize(entityName) : undefined}
                    placeholder={`Select ${entityName}`}
                    onChange={(e) => onNameChange(e.target.value)}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )
              }}
              getOptionSelected={(o, v) => o.id === v.id}
              getOptionLabel={getOptionName}
              ListboxProps={{
                onScroll,
                isLoadingInitial: !dataReceived,
                isLoadingMore: isLoadingMore,
              }}
              ListboxComponent={ListBox}
              renderTags={
                typeof renderTags === "function" ? renderTags : undefined
              }
            />
            <ErrorMessage error={hasError} message={errorMessage} />
            {isWaitingForOtherFields && (
              <FormHelperText className={"color-black-38"}>
                {isWaitingForOtherFieldsMessage}
              </FormHelperText>
            )}
          </FormControl>
        )}
      </>
    )
  }

  if (fieldOnly) return field()

  const fieldBox = () => {
    return (
      <div className="form__fieldbox">
        <div className="form__input form__input--select">{field()}</div>
      </div>
    )
  }

  if (!leftLabel) return fieldBox()

  return (
    <div className="form__fieldset">
      <label htmlFor={fieldName} className={"form__label"}>
        {entityName} {required && "*"}
      </label>
      {fieldBox()}
    </div>
  )
}

const ListBox = React.forwardRef(function ListBox(props, ref) {
  const { children, isLoadingInitial, isLoadingMore, ...rest } = props

  return !isLoadingInitial ? (
    <div ref={ref}>
      <ul {...rest}>
        {children}
        {isLoadingMore && (
          <li>
            <div className={"f jcc"}>
              <Loader size={20} />
            </div>
          </li>
        )}
      </ul>
    </div>
  ) : (
    <div className={"f jcc"}>
      <Loader size={20} />
    </div>
  )
})

export default SuperSelect
