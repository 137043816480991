import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { colors } from "../../library/constants/styles/colors"
import TableHeader from "./tableHeader"
import TableHead from "./tableHead"
import TableFooter from "./tableFooter"
import TableBody from "./tableBody"
import Loader from "../ui/loader"
import makeStyles from "@material-ui/core/styles/makeStyles"
import useTheme from "@material-ui/core/styles/useTheme"
import IconButton from "@material-ui/core/IconButton"
import FirstPageIcon from "@material-ui/icons/FirstPage"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import LastPageIcon from "@material-ui/icons/LastPage"
import { devices } from "../../library/constants/styles/responsive"
import InvenstoryTilesList from "../../screens/main/invenstory/invenstoryTilesList"

const Container = styled.div`
  padding: 0 10px;
  box-shadow: 0 0 2px 0 ${colors.black12};
  background-color: ${colors.white};
  border-radius: 2px;
`

const ScrollContainer = styled.table`
  display: block;
  width: 1150px;
  overflow-x: auto;
  overflow-y: hidden;
`;

const MobileContainer = styled.div`
  @media ${devices.xs} {
    width: calc(100vw - 40px);
    overflow: auto;
  }
`;

const useStyles1 = makeStyles(theme => {
    const marginValue = theme.spacing(2.5);
    return {
        root: {
            flexShrink: 0,
            // margin: `${marginValue}px 0px ${marginValue}px ${marginValue}px`,
            float: "right",
        },
        button: {
            padding: 0,
        },
    };
});

const Pagination = (props) => {
    const {page, count, maxCount, nextPage, prevPage, lastPage, firstPage, countPerPage, startRange} = props;
    const classes = useStyles1();
    const theme = useTheme();
    return (
        <div className={classes.root}>
            <IconButton
                onClick={firstPage}
                disabled={page === 1}
                aria-label="first page"
                className={classes.button}
            >
                {theme.direction === "rtl" ? <LastPageIcon/> : <FirstPageIcon/>}
            </IconButton>
            <IconButton
                onClick={prevPage}
                disabled={page === 1}
                aria-label="previous page"
                className={classes.button}
            >
                {theme.direction === "rtl" ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
            </IconButton>
            <span style={{
                fontSize: "13px",
                color: "rgba(0,0,0,.54)",
            }}>{startRange > 0 ? startRange : 0} - {count} of {maxCount}</span>
            <IconButton
                onClick={nextPage}
                disabled={page >= Math.ceil(maxCount / countPerPage)}
                aria-label="next page"
                className={classes.button}
            >
                {theme.direction === "rtl" ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
            </IconButton>
            <IconButton
                onClick={lastPage}
                disabled={page >= Math.ceil(maxCount / countPerPage)}
                aria-label="last page"
                className={classes.button}
            >
                {theme.direction === "rtl" ? <FirstPageIcon/> : <LastPageIcon/>}
            </IconButton>
        </div>
    );
};


const Table = (props) => {
    const [data, setData] = useState({
        columns: props.columns,
        activeColumns: [],
        listInitial: [],
        list: [],
        sortId: null,
        sortOrder: null,
        received: false,
    });
    const {tableState, tableControls} = props;
    useEffect(() => {

        setData({
            ...data,
            list: data.sortId && data.sortOrder ? sortData(props.data, data.sortId, data.sortOrder) : props.data,
            listInitial: props.data,
            activeColumns: props.activeColumns,
            received: true,
        });
    }, [props.data]);

    const setColumns = async (columnsIds) => {
        const active = props.columns.filter(c => columnsIds.includes(c.id));

        const inactive = props.columns.filter(c => !columnsIds.includes(c.id) && !c.required)
            .map(c => c.id);

        // const listUpdated = props.data.map(item => ({...item, info: omit(item.info, inactive)}));

        // setData({...data, columns: active, list: listUpdated, listInitial: listUpdated});

        await props.saveColumns(columnsIds, active, inactive);
    };

    const reSortData = (id, order) => {
        const newOrder = data.sortId !== id ? "asc" : order;

        setData({...data, list: sortData(data.list, id, newOrder), sortId: id, sortOrder: newOrder});
    };

    const sortData = (list, id, order) => {
        let listToSort = [...list];

        switch (order) {
            case "asc":
                return listToSort.sort((a, b) => {
                    if (a.info[id] === "" || a.info[id] === null) return 1;
                    if (b.info[id] === "" || b.info[id] === null) return -1;
                    if (a.info[id] === b.info[id]) return 0;

                    if (typeof a.info[id] === "string" && typeof b.info[id] === "string") {
                        return a.info[id].toLowerCase() < b.info[id].toLowerCase() ? -1 : 1;
                    } else {
                        return a.info[id] < b.info[id] ? -1 : 1;
                    }

                });

            case "desc":
                return listToSort.sort((a, b) => {
                    if (a.info[id] === "" || a.info[id] === null) return 1;
                    if (b.info[id] === "" || b.info[id] === null) return -1;
                    if (a.info[id] === b.info[id]) return 0;

                    if (typeof a.info[id] === "string" && typeof b.info[id] === "string") {
                        return a.info[id].toLowerCase() < b.info[id].toLowerCase() ? 1 : -1;
                    } else {
                        return a.info[id] < b.info[id] ? 1 : -1;
                    }
                });

            default:
                return data.listInitial;
        }
    };

    return (
        <Container>
            <TableHeader
                setColumns={setColumns}
                columns={props.columns}
                selectedColumns={data.activeColumns.ids || []}
                updateData={props.updateData}
                setFilterValues={props.setFilterValues}
                standards={props.standards}
                standardsDataReceived={props.standardsDataReceived}
                standardsName={props.standardsName}
                onStandardsScroll={props.onStandardsScroll}
                setStandardsName={props.setStandardsName}
                tableSearchQuery={props.tableSearchQuery}
                processTableSearch={props.processTableSearch}
                view={props.view}
                switchView={props.switchView}
            />
            {data.received ? (
                <>
                    <MobileContainer>
                        <ScrollContainer>
                            <TableHead
                                columns={data.activeColumns.columns}
                                sortBy={(id, order) => reSortData(id, order)}
                                sortedBy={data.sortId}
                                sortOrder={data.sortOrder}
                                fluid={data.activeColumns.columns.length <= 10}
                            />
                            {
                                (() => {
                                    switch (props.view) {
                                        case "table":
                                            return (
                                                <TableBody
                                                    data={data.list}
                                                    fluid={data.activeColumns.columns.length <= 10}
                                                    columnsNumber={data.columns.length}
                                                    editRecord={props.editRecord}
                                                    deleteRecord={props.deleteRecord}
                                                    openRecord={props.openRecord}
                                                    createProduct={props.createProduct}
                                                    initialRender={props.initialRender}
                                                    noResultsLabel={props.tableSearchQuery ? "Result not found" : "No records yet"}
                                                    createBookmark={props.createBookmark}
                                                    deleteBookmark={props.deleteBookmark}
                                                />
                                            );
                                        case "tile":
                                            return (
                                                <InvenstoryTilesList
                                                    data={data.list}
                                                    columnsNumber={data.columns.length}
                                                    dataReceived={props.listDataReceived}
                                                    editRecord={props.editRecord}
                                                    deleteRecord={props.deleteRecord}
                                                    openRecord={props.openRecord}
                                                    createProduct={props.createProduct}
                                                    initialRender={props.initialRender}
                                                    noResultsLabel={props.tableSearchQuery ? "Result not found" : "No records yet"}
                                                    createBookmark={props.createBookmark}
                                                    deleteBookmark={props.deleteBookmark}
                                                    onScroll={props.onScroll}
                                                />
                                            );
                                    }
                                })()
                            }
                        </ScrollContainer>
                    </MobileContainer>
                    {props.view === "table" && <TableFooter>
                        <Pagination
                            {...tableState}
                            {...tableControls}
                        />
                    </TableFooter>}
                </>
            ) : <Loader/>}
        </Container>
    );
};

export default Table;
