import React from "react"
import { useQuery } from "@tanstack/react-query"
import { call } from "../library/networking/API"
import { useSearchQuery } from "./useSearchQuery"
import { generateAvailablePages } from "../library/utils/generateAvailablePages"

const rowsPerPageOptions = [10, 15, 20, 25, 30]
const initialPagination = {
  page: 0,
  offset: 0,
  limit: rowsPerPageOptions[2],
}

export const useSuperTable = ({ queryConfig }) => {
  const searchQuery = useSearchQuery()

  const [pagination, setPagination] = React.useState(initialPagination)
  const [name, setName] = React.useState("")

  const queryKey = [
    {
      ...queryConfig.key[0],
      controlledFetchParams: {
        offset: pagination.offset,
        limit: pagination.limit,
        name: searchQuery.fields?.name || undefined,
      },
    },
  ]

  const query = useQuery({
    queryKey,
    queryFn: async (ctx) => {
      try {
        const res = await call(queryKey[0].url, {
          ...queryKey[0].fetchParams,
          ...queryKey[0].controlledFetchParams,
        })

        const resDataCounter = res.data[queryConfig.counterKey]
        const resDataList = res.data[queryConfig.listKey]

        return {
          totalRecords: resDataCounter,
          list: resDataList.map((item) => ({
            id: item.id,
            raw: item,
            info: queryConfig.composeRowData(item),
          })),
          totalPages: Math.ceil(
            resDataCounter / queryKey[0].controlledFetchParams.limit
          ),
          availableOffsets: generateAvailablePages(
            queryKey[0].controlledFetchParams.limit,
            resDataCounter
          ),
        }
      } catch (e) {
        console.error(e)
      }
    },
    keepPreviousData: true,
  })

  const onSearch = (e) => {
    searchQuery.compose({ name: name || undefined })
  }

  React.useEffect(() => {
    if (searchQuery.hasField("name")) {
      setName(searchQuery.fields.name)
    }
  }, [])

  React.useEffect(() => {
    const search = setTimeout(() => {
      onSearch()
    }, 500)

    return () => clearTimeout(search)
  }, [name])

  const onPageChange = (v) => {
    setPagination((prevState) => ({
      ...prevState,
      page: v,
      offset:
        prevState.page > v
          ? prevState.offset - prevState.limit
          : prevState.offset + prevState.limit,
    }))
  }

  const onRowsPerPageChange = (v) => {
    setPagination({
      ...pagination,
      limit: v,
      offset: 0,
      page: 0,
    })
  }

  return {
    query,
    pagination,
    setPagination,
    onPageChange,
    onRowsPerPageChange,
    name,
    setName,
    rowsPerPageOptions,
    search: {
      value: name,
      setValue: setName,
    },
  }
}
