import { LIMIT } from "../../constants/limits"

export class StateComposer {
  constructor(state, action) {
    this.state = state
    this.action = action
  }

  static initStateCurrentItems = (keys) => {
    return keys.reduce((prev, cur) => ({ ...prev, [cur]: null }), {})
  }

  static initStateArrays = (keys) => {
    return keys.reduce(
      (prev, cur) => ({
        ...prev,
        [cur]: [],
        [cur + "EndReached"]: false,
      }),
      {}
    )
  }

  static initStateArrayByType = (key, types = []) => {
    return {
      [key]: types.reduce(
        (prev, cur) => ({
          ...prev,
          [cur]: {
            list: [],
            endReached: false,
          },
        }),
        {}
      ),
    }
  }

  composeConfigurableFields = ({
    listKey,
    listEndReachedKey,
    payloadListKey = "list",
    limit = LIMIT,
  }) => ({
    ...this.state,
    [listKey]: this.composeList({ stateListKey: listKey, payloadListKey }),
    [listEndReachedKey || listKey + "EndReached"]: this.composeListEndReached({
      payloadListKey,
      limit,
    }),
  })

  resetConfigurableFields = ({ listKey, listEndReachedKey }) => ({
    ...this.state,
    [listKey]: [],
    [listEndReachedKey || listKey + "EndReached"]: false,
  })

  composeList = ({ payloadListKey = "list", stateListKey }) => {
    const payloadList = this.action.payload[payloadListKey]

    return this.action.payload.offset === 0
      ? payloadList
      : [...this.state[stateListKey], ...payloadList]
  }

  composeListEndReached = ({ payloadListKey = "list", limit = LIMIT } = {}) => {
    const payloadList = this.action.payload[payloadListKey]

    return !payloadList.length || payloadList.length < limit
  }

  composeFields = (listKey) =>
    this.composeConfigurableFields({
      listKey,
    })

  resetFields = (listKey) => this.resetConfigurableFields({ listKey })

  addArrayRecord = (listKey) => ({
    ...this.state,
    [listKey]: [...this.state[listKey], this.action.payload],
  })

  updateConfigurableArrayRecord = ({ listKey, idKey = "id" }) => ({
    ...this.state,
    [listKey]: this.state[listKey].map((record) => {
      return record[idKey] !== this.action.payload[idKey]
        ? record
        : this.action.payload
    }),
  })

  updateArrayRecord = (listKey) =>
    this.updateConfigurableArrayRecord({ listKey })

  deleteConfigurableArrayRecord = ({ listKey, idKey = "id" }) => ({
    ...this.state,
    [listKey]: this.state[listKey].filter(
      (r) => r[idKey] !== this.action.payload[idKey]
    ),
  })

  deleteArrayRecord = (listKey) =>
    this.deleteConfigurableArrayRecord({ listKey })

  array = (listKey) => ({
    get: () => this.composeFields(listKey),
    clear: () => this.resetFields(listKey),
    addItem: () => this.addArrayRecord(listKey),
    updateItem: () => this.updateArrayRecord(listKey),
    deleteItem: () => this.deleteArrayRecord(listKey),
  })

  getRecord = (recordKey) => ({
    ...this.state,
    [recordKey]: this.action.payload,
  })

  clearRecord = (recordKey) => ({
    ...this.state,
    [recordKey]: null,
  })

  record = (recordKey) => ({
    get: () => this.getRecord(recordKey),
    clear: () => this.clearRecord(recordKey),
  })
}

export class StateComposerDeep {
  constructor(state, action) {
    this.state = state
    this.action = action
  }

  static initStateCurrentItems = (keys) => {
    return keys.reduce((prev, cur) => ({ ...prev, [cur]: null }), {})
  }

  static initStateEntities = (items, withCurrent = false) => {
    return items.reduce((prev, cur) => {
      return {
        ...prev,
        [cur]: {
          list: [],
          hasMore: true,
          current: withCurrent ? null : undefined,
        },
      }
    }, {})
  }

  composeConfigurableFields = ({
    key,
    payloadListKey = 'list',
    limit = LIMIT,
  }) => ({
    ...this.state,
    [key]: {
      list: this.composeList({ stateKey: key, payloadListKey }),
      hasMore: this.composeListEndReached({
        payloadListKey,
        limit,
      }),
    },
  })

  resetConfigurableFields = ({ key }) => ({
    ...this.state,
    [key]: {
      list: [],
      hasMore: true,
    },
  })

  composeList = ({ payloadListKey = 'list', stateKey }) => {
    const payloadList = this.action.payload[payloadListKey]

    return this.action.payload.offset === 0
      ? payloadList
      : [...this.state[stateKey].list, ...payloadList]
  }

  composeListEndReached = ({ payloadListKey = 'list', limit = LIMIT } = {}) => {
    const payloadList = this.action.payload[payloadListKey]

    return payloadList.length >= limit
  }

  composeFields = (key) =>
    this.composeConfigurableFields({
      key,
    })

  resetFields = (key) => this.resetConfigurableFields({ key })

  addArrayRecord = (key) => ({
    ...this.state,
    [key]: {
      ...this.state[key],
      list: [...this.state[key].list, this.action.payload],
    },
  })

  updateConfigurableArrayRecord = ({ key, idKey = 'id' }) => ({
    ...this.state,
    [key]: {
      ...this.state[key],
      list: this.state[key].list.map((record) => {
        return record[idKey] !== this.action.payload[idKey]
          ? record
          : this.action.payload
      }),
    },
  })

  updateArrayRecord = (key) => this.updateConfigurableArrayRecord({ key })

  deleteConfigurableArrayRecord = ({ key, idKey = 'id' }) => ({
    ...this.state,
    [key]: {
      ...this.state[key],
      list: this.state[key].filter(
        (r) => r[idKey] !== this.action.payload[idKey]
      ),
    },
  })

  deleteArrayRecord = (key) => this.deleteConfigurableArrayRecord({ key })

  entities = (key) => ({
    get: () => this.composeFields(key),
    clear: () => this.resetFields(key),
    addItem: () => this.addArrayRecord(key),
    updateItem: () => this.updateArrayRecord(key),
    deleteItem: () => this.deleteArrayRecord(key),
  })

  getRecord = (recordKey) => ({
    ...this.state,
    [recordKey]: this.action.payload,
  })

  clearRecord = (recordKey) => ({
    ...this.state,
    [recordKey]: null,
  })

  record = (recordKey) => ({
    get: () => this.getRecord(recordKey),
    clear: () => this.clearRecord(recordKey),
  })
}
