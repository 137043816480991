const importAll = (r) => {
  let images = {}
  r.keys().map((item) => {
    images[item.replace('./', '')] = r(item)
  })
  return images
}

const images = importAll(
  require.context('../../assets/img/subjects', false, /\.(png|jpe?g|svg)$/)
)

export const subjects = [
  { id: 1, name: 'Language', image: images['1.svg'] },
  { id: 2, name: 'Math', image: images['2.svg'] },
  { id: 3, name: 'Science', image: images['3.svg'] },
  { id: 4, name: 'Social Studies', image: images['4.svg'] },
  { id: 5, name: 'All Areas of Learning', image: images['5.svg'] },
  { id: 6, name: 'Archaeology', image: images['6.svg'] },
  { id: 7, name: 'Architecture', image: images['7.svg'] },
  { id: 8, name: 'Arts', image: images['8.svg'] },
  { id: 9, name: 'Biochemistry', image: images['9.svg'] },
  { id: 10, name: 'Molecular Biology', image: images['10.svg'] },
  { id: 11, name: 'Biology', image: images['11.svg'] },
  { id: 12, name: 'Computer Technology', image: images['12.svg'] },
  { id: 13, name: 'Computer-aided Design', image: images['13.svg'] },
  { id: 14, name: 'Computer-aided Manufacturing', image: images['14.svg'] },
  { id: 15, name: 'Chemistry', image: images['15.svg'] },
  { id: 16, name: 'Civics', image: images['16.svg'] },
  { id: 17, name: 'Computing', image: images['17.svg'] },
  { id: 18, name: 'Earth Sciences', image: images['18.svg'] },
  { id: 19, name: 'Economics', image: images['19.svg'] },
  { id: 20, name: 'Energy', image: images['20.svg'] },
  { id: 21, name: 'English', image: images['21.svg'] },
  { id: 22, name: 'Environment', image: images['22.svg'] },
  { id: 23, name: 'Foreign Language', image: images['23.svg'] },
  { id: 24, name: 'Film', image: images['24.svg'] },
  { id: 25, name: 'Geography', image: images['25.svg'] },
  { id: 26, name: 'Geology', image: images['26.svg'] },
  { id: 27, name: 'Graphics Design', image: images['27.svg'] },
  { id: 28, name: 'Health', image: images['28.svg'] },
  { id: 29, name: 'History', image: images['29.svg'] },
  { id: 30, name: 'Linguistics', image: images['30.svg'] },
  { id: 31, name: 'Reading & Writing', image: images['31.svg'] },
  { id: 32, name: 'Management', image: images['32.svg'] },
  { id: 33, name: 'Algebra', image: images['33.svg'] },
  { id: 34, name: 'Geometry', image: images['34.svg'] },
  { id: 35, name: 'Trigonometry', image: images['35.svg'] },
  { id: 36, name: 'Calculus', image: images['36.svg'] },
  { id: 37, name: 'Physics', image: images['37.svg'] },
  { id: 38, name: 'Quantum Physics', image: images['38.svg'] },
  { id: 39, name: 'Music', image: images['39.svg'] },
  { id: 40, name: 'Philosophy', image: images['40.svg'] },
  { id: 41, name: 'Professional Development', image: images['41.svg'] },
  { id: 42, name: 'Sociology', image: images['42.svg'] },
  { id: 43, name: 'Typing', image: images['43.svg'] },
  { id: 44, name: 'Physical Education', image: images['44.svg'] },
  { id: 45, name: 'Other', image: images['45.svg'] },
]

export const grades = [
  { id: 1, name: 'Preschool' },
  { id: 2, name: 'Kindergarten' },
  { id: 3, name: '1st' },
  { id: 4, name: '2nd' },
  { id: 5, name: '3rd' },
  { id: 6, name: '4th' },
  { id: 7, name: '5th' },
  { id: 8, name: '6th' },
  { id: 9, name: '7th' },
  { id: 10, name: '8th' },
  { id: 11, name: '9th' },
  { id: 12, name: '10th' },
  { id: 13, name: '11th' },
  { id: 14, name: '12th' },
  { id: 15, name: 'Higher Education' },
  { id: 16, name: 'Adult Education' },
  { id: 17, name: 'Homeschool' },
  { id: 18, name: 'Staff' },
]

export const calendarGrades = [
  { id: 17, name: 'All grades' },
  { id: 1, name: 'Pre-K' },
  { id: 2, name: 'Kindergarten' },
  { id: 3, name: '1st' },
  { id: 4, name: '2nd' },
  { id: 5, name: '3rd' },
  { id: 6, name: '4th' },
  { id: 7, name: '5th' },
  { id: 8, name: '6th' },
  { id: 9, name: '7th' },
  { id: 10, name: '8th' },
  { id: 11, name: '9th' },
  { id: 12, name: '10th' },
  { id: 13, name: '11th' },
  { id: 14, name: '12th' },
  { id: 15, name: 'Higher Education' },
  { id: 16, name: 'Adult Education' },
  { id: 18, name: 'All ages' },
  { id: 19, name: 'Age 4' },
  { id: 20, name: 'Age 5' },
  { id: 21, name: 'Age 6' },
  { id: 22, name: 'Age 7' },
  { id: 23, name: 'Age 8' },
  { id: 24, name: 'Age 9' },
  { id: 25, name: 'Age 10' },
  { id: 26, name: 'Age 11' },
  { id: 27, name: 'Age 12' },
  { id: 28, name: 'Age 13' },
  { id: 29, name: 'Age 14' },
  { id: 30, name: 'Age 15' },
  { id: 31, name: 'Age 16' },
  { id: 32, name: 'Age 17' },
  { id: 33, name: 'Age 18' },
]

export const standardsGrades = [
  { id: 13, name: 'Kindergarten' },
  { id: 1, name: '1st' },
  { id: 2, name: '2nd' },
  { id: 3, name: '3rd' },
  { id: 4, name: '4th' },
  { id: 5, name: '5th' },
  { id: 6, name: '6th' },
  { id: 7, name: '7th' },
  { id: 8, name: '8th' },
  { id: 9, name: '9th' },
  { id: 10, name: '10th' },
  { id: 11, name: '11th' },
  { id: 12, name: '12th' },
]

export const states = [
  { id: 1, name: 'Alabama' },
  { id: 2, name: 'Alaska' },
  { id: 3, name: 'Arizona' },
  { id: 4, name: 'Arkansas' },
  { id: 5, name: 'California' },
  { id: 6, name: 'Colorado' },
  { id: 7, name: 'Connecticut' },
  { id: 8, name: 'Delaware' },
  { id: 9, name: 'Florida' },
  { id: 10, name: 'Georgia' },
  { id: 11, name: 'Hawaii' },
  { id: 12, name: 'Idaho' },
  { id: 13, name: 'Illinois' },
  { id: 14, name: 'Indiana' },
  { id: 15, name: 'Iowa' },
  { id: 16, name: 'Kansas' },
  { id: 17, name: 'Kentucky' },
  { id: 18, name: 'Louisiana' },
  { id: 19, name: 'Maine' },
  { id: 20, name: 'Maryland' },
  { id: 21, name: 'Massachusetts' },
  { id: 22, name: 'Michigan' },
  { id: 23, name: 'Minnesota' },
  { id: 24, name: 'Mississippi' },
  { id: 25, name: 'Missouri' },
  { id: 26, name: 'Montana' },
  { id: 27, name: 'Nebraska' },
  { id: 28, name: 'Nevada' },
  { id: 29, name: 'New Hampshire' },
  { id: 30, name: 'New Jersey' },
  { id: 31, name: 'New Mexico' },
  { id: 32, name: 'New York' },
  { id: 33, name: 'North Carolina' },
  { id: 34, name: 'North Dakota' },
  { id: 35, name: 'Ohio' },
  { id: 36, name: 'Oklahoma' },
  { id: 37, name: 'Oregon' },
  { id: 38, name: 'Pennsylvania' },
  { id: 39, name: 'Rhode Island' },
  { id: 40, name: 'South Carolina' },
  { id: 41, name: 'South Dakota' },
  { id: 42, name: 'Tennessee' },
  { id: 43, name: 'Texas' },
  { id: 44, name: 'Utah' },
  { id: 45, name: 'Vermont' },
  { id: 46, name: 'Virginia' },
  { id: 47, name: 'Washington' },
  { id: 48, name: 'West Virginia' },
  { id: 49, name: 'Wisconsin' },
  { id: 50, name: 'Wyoming' },
]

export const educationTypes = {
  1: 'Elementary',
  2: 'Middle',
  3: 'High',
  4: 'College (university)',
  5: 'Vocational',
  6: 'Additional',
}
