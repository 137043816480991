import React from "react"
import InfoLayout from "../infoLayout"
import { Link, withRouter } from "react-router-dom"
import { Li, Section, SectionHeading, Ul } from "./accountsInfoMain"
import { Button } from "@material-ui/core"
import { ACCOUNTS_INFO } from "../../../library/constants/routes"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import { Text } from "../uknow/uknowInfoPage"
import bannerImg from "../../../assets/img/pages/info/educator/banner.png"
import schoolImg from "../../../assets/img/pages/info/educator/school.jpeg"
import feature1Img from "../../../assets/img/pages/info/educator/feature1.jpg"
import feature2Img from "../../../assets/img/pages/info/educator/feature2.jpg"
import feature3Img from "../../../assets/img/pages/info/educator/feature3.jpg"
import feature4Img from "../../../assets/img/pages/info/educator/feature4.jpg"
import step1Img from "../../../assets/img/pages/info/educator/step1.png"
import step2Img from "../../../assets/img/pages/info/educator/step2.png"
import step3Img from "../../../assets/img/pages/info/educator/step3.png"
import step4Img from "../../../assets/img/pages/info/educator/step4.png"
import laniImg from "../../../assets/img/pages/info/footer/LeiLani.png"
import footerImg1 from "../../../assets/img/pages/info/footer/podcasts.png"
import footerImg2 from "../../../assets/img/pages/info/footer/book.png"
import footerImg3 from "../../../assets/img/pages/info/footer/reports.png"
import footerImg4 from "../../../assets/img/pages/info/footer/other-articles.png"

export const HybridLogisticsFeatures = ({ isDark = false }) => {
  return (
    <Section isDark={isDark}>
      <div className="container">
        <div className="full-width">
          <div className="row">
            <div className="col-md-3 mb30xs text-center">
              <img src={feature1Img} alt={""} />
              <p>
                Create class meets as groups complete assignments for efficient
                teaching. Imagine learning delivery that can be dynamically
                individually scheduled to be flexibly remote or required as
                in-person in a classroom.
              </p>
            </div>
            <div className="col-md-3 mb30xs text-center">
              <img src={feature2Img} alt={""} />
              <p>
                True time and space logistics. Set up layered calendars for
                pacing guides, units, plans and mixes for entirely flexible
                schedules that dramatically lower burdens on teacher resources –
                saving money, saving time, saving sanity.
              </p>
            </div>
            <div className="col-md-3 mb30xs text-center">
              <img src={feature3Img} alt={""} />
              <p>
                Bring it all together! Learners, teachers and educational tech
                with social messaging, bookmarking, badging and a marketplace to
                find resources and academic standards. <br />
                Finally, a learning community for every kind of learner and
                every kind of teacher and school.
              </p>
            </div>
            <div className="col-md-3 text-center">
              <img src={feature4Img} alt={""} />
              <p>
                Trade normalization for true personalization. <br />
                Dynamic calendaring is a game-changer for learners and
                institutions. Building individual study mixes can include many
                steps of instructions, each with their own resources, quizzes,
                and individual meets planned as the learners complete each step.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export const HybridLogisticsFlow = ({ isDark = false }) => {
  return (
    <Section isDark={isDark}>
      <div className="container">
        <div className="full-width">
          <SectionHeading>How it works</SectionHeading>
          <div className="row mb35">
            <div className="col-md-6 f jcc">
              <img
                src={step1Img}
                alt={""}
                style={{ maxWidth: 440 }}
                className={"full-width-xs"}
              />
            </div>
            <div className="col-md-5">
              <p className={"mb15"}>
                Group or individual live-meets are “unset” until all invited
                students hit “finished,” and then a meet is set – there are no
                routine scheduled classes. Learners are in homerooms or remote.
                Meets can be long or short and use other spaces as needed.
                <br />
                This innovation in how time is used provides automatic cohorting
                to create learning community for learners. Meets, or “classes,”
                can be set to any number in a cohort. They are flexibly
                on-campus, virtual or hybrid based on the institutions or
                learner needs.
              </p>

              <p className={"mb15"}>
                Learners still have study schedules they make themselves or are
                made by the institution they are affiliated with.
              </p>
              <p className={"mb15"}>
                Teachers still teach but on-demand and “roaming.” Togetherness
                time for direct instruction may be short or long.
              </p>
              <p className={"mb15"}>
                Measurement of progress can be based on the assignment of
                instructions and study materials against a normed basic timeline
                of standards or merely completion of individual Courses by the
                learner.
              </p>
              <p className={"mb15"}>
                A Course is what to study by grade, subject and the resources or
                links needed and is agnostic – users choose the content. The mix
                anchors the Knowmeets which can be set to one learner or many. A
                Knowplan creates each learner’s needed set of Courses. A Knowmap
                shows learners and teachers in real-time what is happening for
                completed work in mixes and upcoming meets.{" "}
              </p>
              <p className={"mb15"}>
                A One-Teacher-School group can create a set of mixes and present
                them publicly in the marketplace for free or paid service
                directly to student consumers with meets all set virtually.
              </p>
              <p>
                A school and district groups have advanced calendaring, schedule
                and pacing units, digital inventory and reporting functions to
                organize part of their learning delivery or reimagine their
                entire educational structure, staffing and personalization
                strategies.
              </p>
            </div>
          </div>
          <div className="row aic mb35">
            <div className="col-md-6 f jcc full-width-xs">
              <img src={step2Img} alt={""} style={{ maxWidth: 300 }} />
            </div>
            <div className="col-md-5">
              <p className={"text-center"}>
                School or Teacher plans Calendar of academic standards, plans
                Courses (instructions on what to study) and sets firm or
                open-ended targets for the Knowmeets (like classes).
              </p>
            </div>
          </div>
          <div className="row aic mb35">
            <div className="col-md-6 f jcc full-width-xs">
              <img src={step3Img} alt={""} style={{ maxWidth: 300 }} />
            </div>
            <div className="col-md-5">
              <p className={"text-center"}>
                Courses get assigned to one or more learners who study at their
                own pace – usually in a homeroom study space with supervision.
                Reaching a Knowmeet, it Calendars for however many in the cohort
                were required to arrive to set it. Then the meet happens with
                the teacher in whatever space or virtual conference room has
                been designated.
              </p>
            </div>
          </div>
          <div className="row aic mb35">
            <div className="col-md-6 f jcc full-width-xs">
              <img src={step4Img} alt={""} style={{ maxWidth: 300 }} />
            </div>
            <div className="col-md-5">
              <p className={"text-center"}>
                When a teacher is not in a meet, they roam to help individual
                learners, grade ongoing work, prep activities or create custom
                mixes. Roaming virtually might be reaching out with a direct
                message.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export const InfoFooter = ({ isFirstDark = true }) => {
  return (
    <>
      <Section isDark={isFirstDark}>
        <div className="container">
          <div className="full-width">
            <SectionHeading>About the Mission</SectionHeading>
            <div className="row">
              <div className="col-md-10 col-md-offset-1">
                <Text>
                  Knowstory’s mission is to shift space and time in learning.
                  When all learners must keep up in all subjects with their age
                  group, that’s “normalization,” and is a seriously inequitable
                  practice given the fact that all humans are different and may
                  learn at different rates at different times and periods of
                  interest. In addition, the manufacturing-age model just isn’t
                  working for today’s busy families, but people also do not want
                  to sacrifice live teaching and group interaction entirely.{" "}
                  <br />
                  Knowstory started as The Hybrid Logistics Project (HLP), which
                  proposed educational transformation through the disaggregation
                  of the grade-and-class structure to a hybrid model of time and
                  space use, characterized as learning “uberization.” The
                  Project continues to work with education leaders on the
                  vision’s practical utilities for teachers, administrative
                  calendaring logistics, student personalization potentials,
                  helping disseminate the research, and developing the first
                  Hybrid Logistics Interchange (HLI) showcase software here in
                  Knowstory. Hybrid Logistics as a discipline and innovation
                  arena for schools was initially hypothesized by the founder,
                  LeiLani Cauthen, who is also the CEO of Learning Counsel News
                  Media and Research. Her continuous work with school and
                  district leaders helped frame many of the ideas and functions.
                  Ongoing research into consumer behavior and major surveys on
                  the pressures schools experience to personalize learning led
                  to the many parts that answer needs as full-function ecosystem
                  crossing all domains from schools to freelancers to
                  homeschoolers and students everywhere.
                </Text>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Section isDark={!isFirstDark}>
        <div className="container">
          <div className="full-width">
            <SectionHeading>KnowStory</SectionHeading>
            <div className="row mb30">
              <div className="col-md-10 col-md-offset-1">
                <div className="f jcsb aic fdc-xs full-width">
                  <img
                    src={laniImg}
                    alt="LeiLani Cauthen"
                    style={{ maxWidth: 230 }}
                  />
                  <p style={{ maxWidth: 515 }}>
                    LeiLani Cauthen is the Founder of Knowstory, an author,
                    podcaster, and futurist. As the CEO and Publisher at
                    Learning Counsel News Media and Research since 2014, which
                    has been the sole funding for Knowstory, she has been
                    helping define this century’s real change to teaching and
                    learning. She is an author and media personality with twenty
                    years of research, news media publishing and market thought
                    leadership in the high tech, education and government
                    industries.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 f fdc aic full-width-xs mb30xs">
                <div className={"mb15 "} style={{ height: 250 }}>
                  <img src={footerImg1} alt="Book" />
                </div>

                <p>Podcasts</p>
              </div>
              <div className="col-md-3 f fdc aic full-width-xs mb30xs">
                <div className={"mb15 "} style={{ height: 250 }}>
                  <img src={footerImg2} alt="The Consumerization of Learning" />
                </div>

                <p>The Consumerization of Learning</p>
              </div>
              <div className="col-md-3 f fdc aic full-width-xs mb30xs">
                <div className={"mb15 "} style={{ height: 250 }}>
                  <img src={footerImg3} alt="Major Reports" />
                </div>

                <p>Major Reports</p>
              </div>
              <div className="col-md-3 f fdc aic full-width-xs">
                <div className={"mb15 "} style={{ height: 250 }}>
                  <img src={footerImg4} alt="Other articles and videos" />
                </div>

                <p>Other articles and videos</p>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </>
  )
}

const AccountsInfoEducator = () => {
  return (
    <InfoLayout>
      <div className={"flexgrid"}>
        <Section pt={"30px"}>
          <div className="container">
            <div className="full-width">
              <Button
                component={Link}
                to={ACCOUNTS_INFO}
                color={"primary"}
                aria-label="Back"
                startIcon={<KeyboardArrowLeft />}
                className={"mb30"}
              >
                Back to accounts overview
              </Button>

              <SectionHeading>Educator</SectionHeading>
              <p className="mb60 text-center">
                Freelance or associate with your School or both! Knowstory is a
                social media ecosystem with advanced time logistics. <br /> It
                creates meetings, like traditional classes, on-demand as
                students or groups complete milestones.
              </p>
              <div className="row full-width mb60">
                <div className="col-md-8 col-md-offset-2">
                  <img src={bannerImg} alt={"Teacher/Parent relation"} />
                </div>
              </div>
              <div className="row full-width mb60">
                <div className="col-md-6 col-md-offset-1 pt30">
                  <Ul>
                    <Li>Create meetings with special features for groups.</Li>
                    <Li>
                      Create a One-Teacher-School, School/District, Interest
                      Group or Events
                    </Li>
                    <Li>
                      Post socially, create bookmarks and records of your
                      learning resources.
                    </Li>
                    <Li>Give and receive Creds (badges)</Li>
                    <Li>
                      Create Courses with preplanned dynamic class meetings.
                    </Li>
                    <Li>Find learning resources</Li>
                    <Li>
                      <p className={"mb15"}>
                        Paid individual accounts gain access to:
                      </p>
                      <Ul>
                        <Li>
                          Advanced Learning Planning and Calendaring functions
                        </Li>
                        <Li>
                          Package your lessons and services for sale in the
                          marketplace
                        </Li>
                      </Ul>
                    </Li>
                  </Ul>
                </div>
                <div className="col-md-4 f jcc">
                  <img src={schoolImg} alt={"School"} />
                </div>
              </div>
            </div>
          </div>
        </Section>
        <HybridLogisticsFeatures isDark />
        {/*<HybridLogisticsFlow />*/}
        <InfoFooter isFirstDark />
      </div>
    </InfoLayout>
  )
}

export default withRouter(AccountsInfoEducator)
