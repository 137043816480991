import { useSnackbar } from "notistack"

const errors = {
  // 200 - General:
  20001: "Server error",
  20002: "Request error",
  20003: "Invalid session token",
  20004: "Action not allowed",
  20005: "Your account is deactivated",
  20006: "Invalid email or password",
  20007: "Deprecated method",

  // 201 - User:
  20100: "User not found",
  20101: "User deleted",
  20102: "User blocked",
  20103: "Email already in use",
  20104: "User blacklisted",
  20105: "User already exists",

  // 202 - Education:
  20200: "Record not found",
  20201: "Record already highlighted",
  20202: "Record not highlighted",

  // 203 - Work:
  20300: "Record not found",
  20301: "Record already highlighted",
  20302: "Record not highlighted",

  // 204 - Email Confirmation:
  20400: "Invalid confirmation link",

  // 205 - Password reset Confirmation:
  20500: "Invalid confirmation link",

  // 206 - Friend:
  20600: "Friends request not found",
  20601: "You've already sent request",
  20602: "User already friend",
  20603: "User not friend",

  // 207 - Post:
  20700: "Post not found",
  20701: "Post not found",
  20702: "Post not deleted",
  20704: "Post already pinned",
  20705: "Post not pinned",

  // 208 - Like:
  20800: "Like not exist",
  20801: "Like exists",

  // 209 - Comment:
  20900: "Comment not found",
  20901: "Comment not found",
  20902: "Comment not deleted",
  20903: "Comment not found",
  20904: "Invalid comment hierarchy",

  // 210 - Knowcred:
  21000: "Cred not found",
  21001: "Cred not found",
  21002: "Cred not found",

  // 211 - File:
  21100: "File not found",

  // 212 - Group:
  21200: "Group not found",
  21201: "Group not found",
  21202: "Already participant",
  21203: "Not participant",
  21204: "Request not found",
  21205: "Group not found",

  // 213 - Group Connection:
  21300: "Group already related",
  21301: "Group not related",

  // 214 - Product:
  21400: "Product not found",
  21401: "Product not found",
  21402: "Product not found",
  21403: "Product already acquired",
  21404: "Product not acquired",
  21405: "Product not found",

  // 215 - Product Review:
  21500: "Review exists",
  21501: "Review not found",

  // 216 - Product Info Review:
  21600: "Product review not found",

  // 217 - Chat:
  21700: "Conversation not found",

  // 218 - Bookmark:
  21800: "Bookmark not found",
  21801: "Bookmark exists",

  // 219 - Standard:
  21900: "Standard not found",

  // 220 - Folder:
  22001: "Folder not found",

  // 221 - Bookmark Folder Relation:
  22100: "Not in folder",
  22101: "Already in folder",

  // 222 - Notifications:
  22200: "Notification not found",

  // 223 - Product Group Relation:
  22300: "Product not related",
  22301: "Product already related",

  // 230 - KnowMix:
  23000: "Course not found",
  23001: "Course not found",
  23002: "Course not found",
  23003: "Course finished",
  23004: "Course not finished",

  // 231 - KnowMix step:
  23100: "Course step not found",

  // 232 - KnowMix step question:
  23200: "Course step question not found",

  // 233 - KnowMix examiner:
  23300: "Course examiner not found",
  23301: "Already an examiner",

  // 234 - Knowmix Works
  23400: "Course work not found",
  23401: "Course work exists",
  23402: "Course work finished",

  // 270 - Invenstory connections
  27000: "Connection exists",
  27001: "Connection not found",
  27002: "Request not accepted yet",
  27003: "Request accepted",

  // 271 - Invenstory record
  27100: "Record exists",
  27101: "Record not found",

  // 272 - Link
  27200: "Link not found",

  // 273 - Message
  27300: "Message not found",

  // 274 - Calendar
  27400: "Calendar not found",
  27401: "Calendars function unavailable for this type of group",
  27402: "Calendar not found",
  27403: "Calendar already completed",

  // 275 - Subject
  27500: "Subject not found",
  27501: "Subject not found",

  // 275 - Unit
  27600: "Unit not found",
  27601: "Unit not found",

  // 277 - Classes
  27700: "Kourse not found",
  27701: "Kourse not found",

  // 278 - School student (grade)
  27800: "Already in grade",
  27801: "Student not found",

  // 280 - Schedule
  28000: "Schedule not found",
  28001: "Schedule not found",

  // 290 - Space
  29000: "Space not found",
  29001: "Space not found",

  // 300 - Block
  30000: "Block not found",
  30001: "Block not found",

  // 310 - Segment
  31000: "Segment not found",
  31001: "Segment not found",

  // 320 - Section
  32000: "Section not found",
  32001: "Section not found",

  // 330 - Demo
  33000: "Demo request not found",
  33001: "Demo request exists",
  33002: "Demo request not found",

  // 340 - Event
  34000: "Event not found",
  34001: "Event not found",

  // 350 - Knowmeet
  35000: "Knowmeet not found",
  35001: "Knowmeet not found",
  35002: "Knowmeet completed",
}

const getErrorText = (code) => errors[code] || "Unknown error"
export const getError = (error) => errors[error?.error?.code] || "Unknown error"

export const useErrorReporter = (error) => {
  const { enqueueSnackbar } = useSnackbar()

  if (error.error.code) {
    return enqueueSnackbar(getErrorText(error.error.code), { variant: "error" })
  }

  return console.error("ERROR:", error)
}

export default getErrorText
