import React, { Component } from "react"
import { generatePath, Link, Route, Switch } from "react-router-dom"
import * as routes from "../../../../../../../library/constants/routes"
import VerticalMenu from "../../../../../../../components/ui/verticalMenu"
import { bindActionCreators } from "redux"
import {
  clearCurrentGroup,
  getGroup,
} from "../../../../../../../library/store/actions/creators/groupsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import GroupSettingsCalendarSubjects from "../groupSettingsCalendarSubjects/groupSettingsCalendarSubjects"
import GroupSettingsCalendarUnits from "../groupSettingsCalendarSubjects/subpages/groupSettingsCalendarUnits/groupSettingsCalendarUnits"
import GroupSettingsCalendarGrades from "../groupSettingsCalendarGrades/groupSettingsCalendarGrades"
import GroupSettingsCalendarSegments from "../groupSettingsCalendarSegments/groupSettingsCalendarSegments"
import Loader from "../../../../../../../components/ui/loader"
import { compact, flatten } from "lodash"
import {
  clearCurrentCalendar,
  clearCurrentSchedule,
  getCalendar,
  getSchedule,
} from "../../../../../../../library/store/actions/creators/calendarCreators"
import withGroupSettingsGuard from "../../../../../../../hocs/guards/withGroupSettingsGuard"
import { getGroupLogo } from "../../../../../../../library/utils/getGroupLogo"
import GroupSettingsCalendarSchedule from "../groupSettingsCalendarSchedule/groupSettingsCalendarSchedule"
import SidebarEntityInfo from "../../../../../../../components/v2/layout/sidebarEntityInfo/sidebarEntityInfo"
import { formatDate } from "../../../../../../../library/utils/dateConvert"
import SEO from "../../../../../../../components/SEO"

class GroupSettingsCalendar extends Component {
  state = {
    dataReceived: false,
  }

  async componentDidMount() {
    const id = parseInt(this.props.match.params.groupId)

    try {
      // await this.props.getCalendar({id: parseInt(this.props.match.params.calendarId)});

      if (this.props.groups.currentGroup.type === 5) {
        await this.props.getCalendar({
          id: parseInt(this.props.match.params.calendarId),
        })
      } else {
        await this.props.getSchedule({
          id: parseInt(this.props.match.params.calendarId),
          school_id: parseInt(this.props.match.params.groupId),
        })
      }

      this.setState({ dataReceived: true })
    } catch ({ description }) {
      this.props.enqueueSnackbar(description, { variant: "error" })
    }
  }

  componentWillUnmount() {
    this.props.clearCurrentGroup()
    this.props.clearCurrentCalendar()
  }

  render() {
    const {
      match: { params },
    } = this.props

    const isDistrict = this.props.groups.currentGroup?.type === 5
    const isGovSchool = this.props.groups.currentGroup?.type === 4
    const isSchool = [4, 7].includes(this.props.groups.currentGroup?.type)
    // console.log({ isSchool, isDistrict })

    const menuItems = flatten(
      compact([
        // {
        //   path: generatePath(
        //     isDistrict
        //       ? routes.GROUP_SETTINGS_DISTRICT_CALENDAR
        //       : routes.GROUP_SETTINGS_SCHOOL_CALENDAR,
        //     {
        //       groupId: params.groupId,
        //       calendarId: params.calendarId,
        //     }
        //   ),
        //   type: 'parent',
        //   label: 'Info',
        // },
        isDistrict && {
          path: generatePath(routes.GROUP_SETTINGS_DISTRICT_CALENDAR_SUBJECTS, {
            groupId: params.groupId,
            calendarId: params.calendarId,
          }),
          type: "parent",
          label: "Subjects",
        },
        isSchool && {
          path: generatePath(routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SUBJECTS, {
            groupId: params.groupId,
            calendarId: params.calendarId,
          }),
          type: "parent",
          label: "Subjects",
        },
        // isSchool && {
        //   path: generatePath(routes.GROUP_SETTINGS_CALENDAR_KOURSES, {
        //     groupId: params.groupId,
        //     calendarId: params.calendarId,
        //   }),
        //   type: 'parent',
        //   label: 'Kourses',
        // },
        isSchool && {
          path: generatePath(routes.GROUP_SETTINGS_CALENDAR_GRADES, {
            groupId: params.groupId,
            calendarId: params.calendarId,
          }),
          type: "parent",
          label: "Grades",
        },
        isSchool && {
          path: generatePath(routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENTS, {
            groupId: params.groupId,
            calendarId: params.calendarId,
          }),
          type: "parent",
          label: "Segments",
        },
        isSchool &&
          this.state.dataReceived && [
            ...this.props.calendar.currentCalendar?.segments?.map((item) => ({
              path: generatePath(
                routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT_SECTIONS,
                {
                  groupId: params.groupId,
                  calendarId: params.calendarId,
                  segmentId: item.id,
                }
              ),
              type: "child",
              label: item.name,
            })),
          ],
        // isSchool && {
        //   path: generatePath(routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SCHEDULE, {
        //     groupId: params.groupId,
        //     calendarId: params.calendarId,
        //   }),
        //   type: 'parent',
        //   label: 'Manage schedule',
        // },
      ])
    )

    const infoItems = this.state.dataReceived
      ? [
          {
            label: "Name",
            value: this.props.calendar.currentCalendar.name,
          },
          {
            label: "Date from",
            value: formatDate(this.props.calendar.currentCalendar.date_from),
          },
          {
            label: "Date to",
            value: formatDate(this.props.calendar.currentCalendar.date_to),
          },
        ]
      : []

    return (
      <>
        <div className="common-page__content">
          <SEO title={"Calendar | Group Settings"} />
          <main className="common-page__main">
            {this.state.dataReceived ? (
              <Switch>
                {/*<Route*/}
                {/*  exact*/}
                {/*  path={routes.GROUP_SETTINGS_DISTRICT_CALENDAR}*/}
                {/*  component={GroupSettingsCalendarInfo}*/}
                {/*/>*/}
                {/*<Route*/}
                {/*  exact*/}
                {/*  path={routes.GROUP_SETTINGS_SCHOOL_CALENDAR}*/}
                {/*  component={GroupSettingsCalendarInfo}*/}
                {/*/>*/}
                <Route
                  exact
                  path={routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SUBJECTS}
                  component={GroupSettingsCalendarSubjects}
                />
                <Route
                  exact
                  path={routes.GROUP_SETTINGS_DISTRICT_CALENDAR_SUBJECTS}
                  component={GroupSettingsCalendarSubjects}
                />
                <Route
                  exact
                  path={routes.GROUP_SETTINGS_SCHOOL_CALENDAR_UNITS}
                  component={GroupSettingsCalendarUnits}
                />
                {/*<Route*/}
                {/*  exact*/}
                {/*  path={routes.GROUP_SETTINGS_CALENDAR_KOURSES}*/}
                {/*  component={GroupSettingsCalendarClasses}*/}
                {/*/>*/}
                <Route
                  exact
                  path={routes.GROUP_SETTINGS_CALENDAR_GRADES}
                  component={GroupSettingsCalendarGrades}
                />
                <Route
                  exact
                  path={routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENTS}
                  component={GroupSettingsCalendarSegments}
                />
                <Route
                  exact
                  path={routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SCHEDULE}
                  component={GroupSettingsCalendarSchedule}
                />
              </Switch>
            ) : (
              <Loader />
            )}
          </main>
          <aside className="common-page__sidebar">
            {this.state.dataReceived && (
              <div className="box aside-header">
                <Link
                  to={generatePath(
                    this.props.groups.currentGroup.type === 5
                      ? routes.GROUP_SETTINGS_DISTRICT_CALENDARS
                      : routes.GROUP_SETTINGS_SCHOOL_CALENDARS,
                    { groupId: params.groupId }
                  )}
                  className="box__content aside-header__content"
                >
                  <img
                    src={getGroupLogo(this.props.groups.currentGroup)}
                    className="aside-header__img"
                    alt=""
                  />
                  <div className="aside-header__info">
                    <div className="aside-header__name">
                      {this.props.groups.currentGroup.name}
                    </div>
                    <div className="aside-header__help">back to Calendars</div>
                  </div>
                </Link>
              </div>
            )}

            <VerticalMenu menuItems={menuItems} />

            <SidebarEntityInfo
              entityName={"Calendar"}
              records={infoItems}
              isLoading={!this.state.dataReceived}
            />
          </aside>
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ auth, groups, calendar }) => ({
  auth,
  groups,
  calendar,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getGroup,
      clearCurrentGroup,
      getCalendar,
      clearCurrentCalendar,
      getSchedule,
      clearCurrentSchedule,
    },
    dispatch
  )

export default withGroupSettingsGuard(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withSnackbar(GroupSettingsCalendar))
)
