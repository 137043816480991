import React from "react"
import { useContactLinks } from "../../../../screens/main/user/userProfileSettings/profileSettingsAppointmentLinks/useContactLinks"
import FormRadioGroup from "../../../formComponents/formRadioGroup"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core"
import FormHelperText from "@material-ui/core/FormHelperText"
import { colors } from "../../../../library/constants/styles/colors"

export const linkProviderNames = {
  zoom: "Zoom",
  webex: "Webex",
  teams: "Teams",
  outlook: "Outlook",
  google: "Google",
}

const ContactLinkPicker = ({
  value,
  onChange,
  leftLabel = true,
  error = "",
}) => {
  const { links, ...query } = useContactLinks()

  const [linkType, setLinkType] = React.useState("saved")
  const [customLink, setCustomLink] = React.useState("")
  const [savedLinkValue, setSavedLinkValue] = React.useState("")

  React.useEffect(() => {
    if (value && query.isSuccess) {
      if (Object.values(links).includes(value)) {
        setLinkType("saved")
        setSavedLinkValue(value)
      } else {
        setLinkType("custom")
        setCustomLink(value)
      }
    }
  }, [query.isSuccess])

  const handleChange = (e) => {
    if (linkType === "custom") {
      setCustomLink(e.target.value)
    } else {
      setSavedLinkValue(e.target.value)
    }

    onChange(e.target.value)
  }

  const fieldBox = query.isSuccess && (
    <div className="form__fieldbox">
      <div className="form__input">
        <FormRadioGroup
          leftLabel={false}
          options={[
            { label: "Pick from my saved links", value: "saved" },
            { label: "Custom one", value: "custom" },
          ]}
          value={linkType}
          onChange={setLinkType}
          name={"conferenceLink"}
        />

        {linkType === "saved" ? (
          <FormControl fullWidth className={"mt15"} error={!!error}>
            <InputLabel id={"savedLinkLabel"}>Link</InputLabel>
            <Select
              id={"savedLink"}
              name={"savedLink"}
              fullWidth
              onChange={handleChange}
              value={savedLinkValue}
              labelId={"savedLinkLabel"}
            >
              {Object.entries(links).map(([k, v]) => (
                <MenuItem value={v} key={k}>
                  <div>
                    <p>{linkProviderNames[k]}</p>
                    <p
                      className={
                        "color-black-54 font-13 overflow-x-hidden overflow-ellipsis max-w-[300px]"
                      }
                    >
                      {v}
                    </p>
                  </div>
                </MenuItem>
              ))}
            </Select>
            {!!error && (
              <FormHelperText className={"error-message"}>
                {error}
              </FormHelperText>
            )}
          </FormControl>
        ) : (
          <TextField
            onChange={handleChange}
            value={customLink}
            fullWidth
            placeholder="Type conference link"
            margin={"normal"}
            className={"mt15"}
            error={!!error}
            helperText={
              !!error && (
                <FormHelperText style={{ color: colors.error }}>
                  Please provide a valid link
                </FormHelperText>
              )
            }
          />
        )}
      </div>
    </div>
  )

  return leftLabel ? (
    <div className="form__fieldset">
      <label htmlFor={"conferenceLink"} className={"form__label"}>
        Conference Link
      </label>
      {fieldBox}
    </div>
  ) : (
    fieldBox
  )
}

export default ContactLinkPicker
