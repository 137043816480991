export const NOTIFICATION = {
    LIST: "notifications.list",
    SMALL_LIST: "notifications.small.list",
    CLEAR_LIST: "notifications.list.clear",
    CLEAR_SMALL_LIST: "notifications.small.list.clear",
    UPDATE: "notifications.list.update",
    GET_NOTIFICATION_COUNT: "notifications.count",
    GET_CHATS_COUNT: "messages.chats.count",
    INCREMENT_NOTIFICATION_COUNT: "notifications.count.increment",
    DECREMENT_UNREAD_COUNT: "notifications.unread.decrement",
    INCREMENT_CHATS_COUNT: "chats.count.increment",
    DECREMENT_CHATS_COUNT: "chats.count.decrement",
    CLEAR_CHATS_COUNT: "chats.count.clear",
    CLEAR_NOTIFICATIONS_COUNT: "notifications.count.clear",
    CLEAR_UNREAD_COUNT: "notifications.unread.count.clear",
    DELETE_NOTIFICATION: "notifications.delete",
};
