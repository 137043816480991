import React from "react"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import Dialog from "@material-ui/core/Dialog/Dialog"
import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import {
  getArrayByIds,
  getObjectById,
  renderArrayOfNames,
} from "../../../../library/utils/arrays"
import {
  grades as gradesList,
  subjects as subjectsList,
} from "../../../../library/constants/educationalDictionary"
import Button from "@material-ui/core/Button/Button"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import {
  productContentTypes,
  productFileTypes,
  productPlatforms,
} from "../../../../library/constants/productDictionary"
import returnExternalUrl from "../../../../library/utils/returnExternalUrl"
import Loader from "../../../../components/ui/loader/loader"

const InvenstoryRecordDialog = ({ record, ...rest }) => {
  const creator =
    !!record &&
    (record.creator_user
      ? {
          name: `${record.creator_user.first_name} ${record.creator_user.last_name}`,
          url: generatePath(routes.USER, { userId: record.creator_user.id }),
        }
      : {
          name: record.creator_group.name,
          url: generatePath(routes.GROUP, { groupId: record.creator_group.id }),
        })

  return (
    <Dialog
      aria-labelledby="create-record-dialog"
      onClose={rest.onClose}
      open={rest.open}
    >
      <DialogTitle id="alert-dialog-title" className="text-center">
        Record details
      </DialogTitle>
      <DialogContent>
        {!!record ? (
          <div className="f jcc full-width">
            <table className="product-details product-details--invenstory-record">
              <tbody>
                <tr className="product-details__row">
                  <td className="product-details__label">Name</td>
                  <td className="product-details__value">{record.name}</td>
                </tr>

                {record.standards.length > 0 && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Standards</td>
                    <td className="product-details__value">
                      <ul className="text-list">
                        {record.standards.map((s) => (
                          <li key={s.id}>{s.name}</li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                )}

                <tr className="product-details__row">
                  <td className="product-details__label">Added by</td>
                  <td className="product-details__value">
                    <Link to={creator.url} className="link">
                      {creator.name}
                    </Link>
                  </td>
                </tr>

                {!!record.content_type && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Content type</td>
                    <td className="product-details__value">
                      {
                        getObjectById(productContentTypes, record.content_type)
                          .name
                      }
                    </td>
                  </tr>
                )}

                {record.subjects.length > 0 && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Subjects</td>
                    <td className="product-details__value">
                      {renderArrayOfNames(
                        getArrayByIds(subjectsList, record.subjects)
                      )}
                    </td>
                  </tr>
                )}

                {record.grades.length > 0 && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Grades</td>
                    <td className="product-details__value">
                      {renderArrayOfNames(
                        getArrayByIds(gradesList, record.grades)
                      )}
                    </td>
                  </tr>
                )}

                {!!record.url && (
                  <tr className="product-details__row">
                    <td className="product-details__label">URL</td>
                    <td className="product-details__value">
                      <a
                        href={`${returnExternalUrl(record.url)}`}
                        className="link"
                      >
                        {record.url}
                      </a>
                    </td>
                  </tr>
                )}

                {record.file_types.length > 0 && (
                  <tr className="product-details__row">
                    <td className="product-details__label">File types</td>
                    <td className="product-details__value">
                      {renderArrayOfNames(
                        getArrayByIds(productFileTypes, record.file_types)
                      )}
                    </td>
                  </tr>
                )}

                {record.platforms.length > 0 && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Platforms</td>
                    <td className="product-details__value">
                      {renderArrayOfNames(
                        getArrayByIds(productPlatforms, record.platforms)
                      )}
                    </td>
                  </tr>
                )}

                {!!record.version && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Version</td>
                    <td className="product-details__value">{record.version}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <Loader />
        )}
      </DialogContent>
      <DialogActions>
        <Button type="submit" color="primary" onClick={rest.onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InvenstoryRecordDialog;

