import React, { Component } from "react"
import * as routes from "../../../../library/constants/routes"
import Button from "@material-ui/core/Button/Button"
import { generatePath, Link, withRouter } from "react-router-dom"
import avatarDefault from "../../../../assets/img/user_avatar.svg"
import unavailableAvatar from "../../../../assets/img/user_unavailable.svg"
import { userRolesIds } from "../../../../library/constants/userRoles"
import MoreDropdown from "../../../../components/ui/dropdowns/moreDropdown/moreDropdown"
import VerticalMenu from "../../../../components/ui/verticalMenu"
import { bindActionCreators } from "redux"
import {
  acceptFriendRequest,
  cancelFriendRequest,
  declineFriendRequest,
  deleteFriend,
  sendFriendRequest,
} from "../../../../library/store/actions/creators/friendsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import getErrorText from "../../../../library/constants/errorTypes"
import {
  createBookmark,
  deleteBookmark,
  foldersList,
} from "../../../../library/store/actions/creators/bookmarksCreators"
import AddToFolderModal from "../../bookmarks/folders/addToFolder/addToFolder"
import LinesEllipsis from "react-lines-ellipsis"
import ComplaintsModal from "../../../../components/complaints/complaintsModal"
import {
  composeUserHighlightedEducation,
  composeUserHighlightedWork,
} from "../../../../library/utils/getUserHighlightedInfo"
import { getUserLocationString } from "../../../../library/utils/getUserLocationString"

class ListItem extends Component {
  state = {
    user: this.props.user,
    isFriend: this.props.user.is_friend,
    isRequested: this.props.user.is_requested,
    isRequesting: this.props.user.is_requesting,
    noRelations:
      !this.props.user.is_friend &&
      !this.props.user.is_requested &&
      !this.props.user.is_requesting,
    deleted: false,
    userBlocked: this.props.user.blocked,
    userDeleted: this.props.user.deleted,
    bookmarked: !!this.props.user.bookmark,
    openBookmarksModal: false,
    complaintsModal: false,
    isReported: !!this.props.user.is_reported,
  }
  showComplaintsModal = () => {
    this.setState({ complaintsModal: true })
  }
  hideComplaintsModal = () => {
    this.setState({ complaintsModal: false })
  }
  handleClickOpen = () => {
    this.setState({ openBookmarksModal: true })
  }

  handleClose = () => {
    this.setState({ openBookmarksModal: false })
  }

  sendFriendRequest = async () => {
    const {
      user: { id },
    } = this.props

    try {
      await this.props.sendFriendRequest(id)
      this.setState({
        isRequested: true,
        noRelations: false,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  acceptFriendRequest = async () => {
    const {
      user: { id },
    } = this.props

    try {
      await this.props.acceptFriendRequest(id)
      await this.props.updateList()
      this.setState({ deleted: true })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  deleteFriend = async () => {
    const {
      user: { id },
      match: { path, params },
    } = this.props

    try {
      await this.props.deleteFriend(id)
      await this.props.updateList()
      if (
        path === routes.FRIENDS &&
        parseInt(params.userId) === this.props.auth.userData.id
      ) {
        this.setState({ deleted: true })
      } else {
        this.setState({ isFriend: false, noRelations: true })
      }
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  declineFriendsRequest = async () => {
    const {
      user: { id },
    } = this.props
    try {
      await this.props.declineFriendRequest(id)
      await this.props.updateList()
      this.setState({ deleted: true })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  cancelFriendRequest = async () => {
    const {
      user: { id },
      match: { path, params },
    } = this.props
    try {
      await this.props.cancelFriendRequest(id)
      await this.props.updateList()
      if (
        path === routes.FRIENDS &&
        parseInt(params.userId) === this.props.auth.userData.id
      ) {
        this.setState({ deleted: true })
      } else {
        this.setState({
          isRequested: false,
          noRelations: true,
        })
      }
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  addToBookmarks = async () => {
    const {
      user: { id },
    } = this.props

    try {
      await this.props.createBookmark({ user_id: id })
      await this.props.foldersList()
      this.setState({ bookmarked: true })
      await this.props.enqueueSnackbar("Saved to bookmarks", {
        autoHideDuration: 5000,
        action: (key) => {
          return (
            <>
              <Button
                color="primary"
                onClick={() => {
                  this.props.closeSnackbar(key)
                  this.props.history.push(routes.BOOKMARKS_PEOPLE)
                }}
              >
                View
              </Button>
              {this.props.bookmarks.folders.length > 0 && (
                <Button
                  color="primary"
                  onClick={() => this.handleClickOpen("addToFolder")}
                >
                  Add to folder
                </Button>
              )}
            </>
          )
        },
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  deleteBookmark = async () => {
    const {
      user: { id },
    } = this.props

    try {
      await this.props.deleteBookmark({ user_id: id })
      this.setState({ bookmarked: false })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  render() {
    const { user, deleted, userDeleted, userBlocked, bookmarked } = this.state
    const {
      type,
      match: { path },
      auth: { userData },
    } = this.props
    const fullName = `${user.first_name} ${user.last_name}`

    const mainControls = () => {
      switch (path) {
        case routes.FRIENDS:
        case routes.FRIENDS_ONLINE:
          return (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                this.props.history.push({
                  pathname: routes.CONVERSATION,
                  search: `?userId=${user.id}`,
                })
              }
            >
              Write message
            </Button>
          )

        case routes.FRIENDS_REQUESTS_PENDING:
          return (
            <Button
              variant="contained"
              color="primary"
              onClick={this.acceptFriendRequest}
            >
              Add
            </Button>
          )

        case routes.FRIENDS_REQUESTS_OUTGOING:
          return (
            <Button
              variant="contained"
              color="primary"
              onClick={this.cancelFriendRequest}
            >
              Cancel Request
            </Button>
          )

        case "DEFAULT":
          return (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                this.props.history.push({
                  pathname: routes.CONVERSATION,
                  search: `?userId=${user.id}`,
                })
              }
            >
              Write message
            </Button>
          )

        default:
          return null
      }
    }

    const controlsAlign = path === routes.FRIENDS_REQUESTS_OUTGOING ? "jcc" : ""

    const action = () => {
      const { isFriend, isRequested, noRelations } = this.state

      if (type === "my" || isFriend) {
        return {
          type: "button",
          action: this.deleteFriend,
          label: "Remove from friends",
        }
      } else if (
        noRelations &&
        !userBlocked &&
        !userDeleted &&
        !isFriend &&
        type !== "pending"
      ) {
        return {
          type: "button",
          action: this.sendFriendRequest,
          label: "Add",
        }
      } else if (
        (type === "pending" || isRequested) &&
        !userBlocked &&
        !userDeleted &&
        type !== "other"
      ) {
        return {
          type: "button",
          action: this.declineFriendsRequest,
          label: "Decline request",
        }
      } else if (
        (type === "outgoing" || isRequested) &&
        !userBlocked &&
        !userDeleted
      ) {
        return {
          type: "button",
          action: this.cancelFriendRequest,
          label: "Cancel request",
        }
      } else {
        return null
      }
    }

    const dropdownMenuItems = [
      {
        path: generatePath(routes.FRIENDS, { userId: user.id }),
        label: "Show friends",
      },
      !bookmarked
        ? {
            type: "button",
            action: this.addToBookmarks,
            label: "Add to bookmarks",
          }
        : {
            type: "button",
            action: this.deleteBookmark,
            label: "Remove from bookmarks",
          },
      this.props.auth.userData.id !== this.props.user.id && {
        type: "button",
        action: this.showComplaintsModal,
        label: "Report",
        disabled: this.state.isReported,
      },
      action() !== null && { type: "h-divider", id: "hd1" },
      action(),
    ]

    if (deleted) return null
    // const addressInfoBlock = (user.city ? user.city : "") + (user.country ? `, ${user.country}` : "");
    return (
      <div className="box list-item">
        <ComplaintsModal
          open={this.state.complaintsModal}
          user_id={this.props.user.id}
          onClose={this.hideComplaintsModal}
          callback={() => this.setState({ isReported: true })}
        />
        <div className="f aic full-width">
          <Link
            to={generatePath(routes.USER, { userId: user.id })}
            className="list-item__img"
          >
            {user.deleted ? (
              <img src={unavailableAvatar} alt={fullName} />
            ) : (
              <img src={user.photo || avatarDefault} alt={fullName} />
            )}
          </Link>
          <div className="list-item__info">
            <span className="font-12 color-black-54">
              {userRolesIds[user.role].name}
            </span>
            <Link
              to={generatePath(routes.USER, { userId: user.id })}
              className="link list-item__name"
            >
              {fullName}
            </Link>
            {/*<span className="list-item__description">{userRolesIds[user.role].name}</span>*/}

            {/*{user.role === 1 ? (*/}
            {/*    <span className="list-item__info-block">*/}
            {/*        {educationInfoBlock && <LinesEllipsis*/}
            {/*            text={educationInfoBlock}*/}
            {/*            maxLine="1"*/}
            {/*            ellipsis="..."*/}
            {/*            trimRight*/}
            {/*            basedOn="letters"*/}
            {/*        />}*/}
            {/*</span>*/}
            {/*) : (*/}
            {/*    <span className="list-item__info-block">*/}
            {/*        {workInfoBlock && <LinesEllipsis*/}
            {/*            text={workInfoBlock}*/}
            {/*            maxLine="1"*/}
            {/*            ellipsis="..."*/}
            {/*            trimRight*/}
            {/*            basedOn="letters"*/}
            {/*        />}*/}
            {/*    </span>*/}
            {/*)}*/}
            {!!user.education &&
              composeUserHighlightedEducation(user.education)}

            {!!user.work && composeUserHighlightedWork(user.work)}
            <span className="list-item__info-block">
              {getUserLocationString(user) && (
                <LinesEllipsis
                  text={getUserLocationString(user)}
                  maxLine="1"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
              )}
            </span>
          </div>
        </div>

        {user.id !== userData.id && (
          <div className={`list-item__control-panel ${controlsAlign}`}>
            {path !== routes.FRIENDS_REQUESTS_OUTGOING && (
              <MoreDropdown>
                <VerticalMenu
                  menuItems={dropdownMenuItems}
                  classes={"more-dropdown-menu__list"}
                />
              </MoreDropdown>
            )}
            {mainControls()}
          </div>
        )}

        {this.state.openBookmarksModal && (
          <AddToFolderModal
            open={this.state.openBookmarksModal}
            handleClose={this.handleClose}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = ({auth, friends, person, bookmarks}) => ({auth, friends, person, bookmarks});
const mapDispatchToProps = dispatch => bindActionCreators({
    sendFriendRequest,
    cancelFriendRequest,
    acceptFriendRequest,
    declineFriendRequest,
    deleteFriend,
    createBookmark,
    deleteBookmark,
    foldersList,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withSnackbar(ListItem)));
