import React from "react"
import { TextValidator } from "react-material-ui-form-validator"
import DeleteForeverOutlined from "@material-ui/icons/DeleteForeverOutlined"
import AddOutlined from "@material-ui/icons/AddOutlined"
import { compact } from "lodash"
import FormHelperText from "@material-ui/core/FormHelperText"

const letters = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
]
const MultipleTextField = ({
  formIsModal,
  values,
  setValues,
  limit,
  fieldLabel,
  fieldPlaceholder,
  addButtonLabel,
  validators,
  validatorMessages,
  multiline = false,
  withCounters = false,
  letterCounters = false,
  disabled = false,
  hasError = false,
  errorMessage = "Field is required",
}) => {
  const handleChange = (index, val) => {
    const newValues = values.map((item, i) => (i === index ? val : item))
    setValues(newValues)
  }

  const addValue = () => {
    setValues([...values, ""])
  }

  const deleteValue = (index) => {
    const newValues = values.filter((item, i) => i !== index)

    setValues(newValues)
  }

  return (
    <div className="form__fieldset">
      <label className="form__label">{fieldLabel}</label>
      <div className="form__fieldbox">
        <div
          className="form__input-group"
          style={formIsModal ? { width: 330 } : {}}
        >
          {values.map((item, index) => (
            <div className="form__controllable-input" key={index}>
              <div className="form__input">
                {withCounters ? (
                  <div className="f aib">
                    <span className={"mr5"}>
                      {letterCounters ? letters[index] : index + 1}.{" "}
                    </span>
                    <TextValidator
                      name={`multivalue-${index + String(new Date())}`}
                      value={item}
                      placeholder={fieldPlaceholder}
                      className="edit-form__input"
                      margin="normal"
                      fullWidth
                      multiline={multiline}
                      validators={compact(validators) || []}
                      errorMessages={compact(validatorMessages) || []}
                      onChange={(item) => {
                        handleChange(index, item.target.value)
                      }}
                      disabled={disabled}
                    />
                  </div>
                ) : (
                  <TextValidator
                    name={`multivalue-${index + String(new Date())}`}
                    value={item}
                    placeholder={fieldPlaceholder}
                    className="edit-form__input"
                    margin="normal"
                    fullWidth
                    multiline={multiline}
                    validators={compact(validators) || []}
                    errorMessages={compact(validatorMessages) || []}
                    onChange={(item) => {
                      handleChange(index, item.target.value)
                    }}
                    disabled={disabled}
                  />
                )}
              </div>
              <button
                className="form__manage-input"
                type="button"
                onClick={() => deleteValue(index)}
                disabled={disabled}
              >
                <DeleteForeverOutlined /> Delete
              </button>
            </div>
          ))}
          {values.length < limit && (
            <button
              className="form__add-input"
              type="button"
              onClick={addValue}
              disabled={disabled}
            >
              <AddOutlined /> {addButtonLabel}
            </button>
          )}
          {hasError && (
            <FormHelperText error={true}>{errorMessage}</FormHelperText>
          )}
        </div>
      </div>
    </div>
  )
}

export default MultipleTextField
