import React from "react"
import AlertDialog from "../ui/alertDialog"
import { withSnackbar } from "notistack"

const BlacklistModal = ({ open, handleClose, user, onAddToBlacklist }) => {
  const addToBlacklist = async () => {
    await onAddToBlacklist()
    handleClose()
  }

  return (
    <AlertDialog
      open={open}
      handleClose={handleClose}
      handleAccept={addToBlacklist}
      title={`Block ${user.first_name}?`}
      message="Blocked user can't see your profile info and message you. You can unblock blocked user any time if you want."
    />
  )
}

export default withSnackbar(BlacklistModal)
