import React from "react"
import "./boxedList.scss"
import { generatePath, Link } from "react-router-dom"
import { KeyboardArrowRight } from "@material-ui/icons"
import * as routes from "../../../library/constants/routes"
import logoDefault from "../../../assets/img/group-logo-default.png"
import unavailableAvatar from "../../../assets/img/user_unavailable.svg"
import productLogoDefault from "../../../assets/img/product.svg"
import avatarDefault from "../../../assets/img/user_avatar.svg"
import { getObjectById } from "../../../library/utils/arrays"
import { productContentTypes } from "../../../library/constants/productDictionary"
import GroupSettingsCalendarProvider from "../../../screens/main/groups/group/groupSettings/groupSettingsCalendar/GroupSettingsCalendarContext"
import { getGroupLogo } from "../../../library/utils/getGroupLogo"

const BoxedList = (props) => {
  return (
    <div className="box boxed-list">
      {!!props.linkShowAll ? (
        <Link to={props.linkShowAll}>
          <div className="boxed-list__header">
            {/*{props.title} <span className="color-black-54">{props.counter}</span>*/}
            {props.title}
          </div>
        </Link>
      ) : (
        <div className="boxed-list__header">
          {/*{props.title} <span className="color-black-54">{props.counter}</span>*/}
          {props.title}
        </div>
      )}

      <div className="boxed-list__list">
        {props.list.map((item, index) => {
          const link = () => {
            switch (props.entity) {
              case "users":
                return generatePath(routes.USER, { userId: item.id })

              case "products":
                return generatePath(routes.PRODUCT, { productId: item.id })

              case "groups":
                return generatePath(routes.GROUP, { groupId: item.id })

              default:
                return ""
            }
          }

          const image = () => {
            switch (props.entity) {
              case "users":
                return item.deleted
                  ? unavailableAvatar
                  : item.photo
                  ? item.photo
                  : avatarDefault

              case "products":
                return item.image || productLogoDefault

              case "groups":
                return getGroupLogo(item)

              default:
                return logoDefault
            }
          }

          return (
            <div key={item.id}>
              <div className="boxed-list-item">
                {props.entity !== "Calendars" ? (
                  <Link to={link()} className="mr8">
                    <img
                      src={image()}
                      alt=""
                      className="boxed-list-item__img"
                    />
                  </Link>
                ) : (
                  <GroupSettingsCalendarProvider key={item.id}>
                    {({ onCalendarDialogOpen }) => (
                      <img
                        src={image()}
                        alt={item.name}
                        className="boxed-list-item__img mr8"
                        onClick={() => onCalendarDialogOpen(item.id)}
                      />
                    )}
                  </GroupSettingsCalendarProvider>
                )}
                <div className="boxed-list-item__info">
                  <Link to={link()} className="link boxed-list-item__name">
                    {props.entity === "users"
                      ? `${item.first_name} ${item.last_name}`
                      : item.name}
                  </Link>
                  {(!!item.role ||
                    !!item.description ||
                    !!item.content_type) && (
                    <div className="boxed-list-item__description">
                      {/*{!!item.role && userRolesIds[item.role].name}*/}
                      {!!item.description && item.description.slice(0, 50)}
                      {!!item.content_type &&
                        getObjectById(productContentTypes, item.content_type)
                          .name}
                    </div>
                  )}
                </div>
              </div>
              {index < props.list.length - 1 ? (
                <div className="h-divider"></div>
              ) : null}
            </div>
          )
        })}
      </div>

      <div
        className={
          props.list.length >= 5 && props.linkShowAll
            ? "boxed-list__footer"
            : "pb15"
        }
      >
        {props.list.length >= 5 && props.linkShowAll && (
          <Link to={props.linkShowAll} className="link">
            Show all <KeyboardArrowRight />
          </Link>
        )}
      </div>
    </div>
  )
}

export default BoxedList
