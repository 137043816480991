import { STANDARDS } from "../types/standardsTypes"
import { call } from "../../../networking/API"
import { BOOKMARKS } from "../types/bookmarksTypes"

export const searchStandards = ({
  name,
  state,
  subjects,
  grades,
  bookmarked,
  folder_id,
  limit = 50,
  offset = 0,
}) => {
  return async (dispatch, getState) => {
    const response = await call(STANDARDS.SEARCH_LIST, {
      token: getState().auth.userData.token,
      name,
      state,
      subjects,
      grades,
      bookmarked,
      folder_id,
      limit,
      offset,
    })
    const json = await response.data
    dispatch({
      type: bookmarked ? BOOKMARKS.LIST : STANDARDS.SEARCH_LIST,
      payload: {
        number: json.standards_number,
        standards: json.standards,
      },
    })
  }
}

export const getStandard = ({id}) => {
    return async (dispatch, getState) => {
        const response = await call(STANDARDS.GET, {
            token: getState().auth.userData.token,
            id,
        });
        const json = response.data;
        dispatch({
            type: STANDARDS.GET,
            payload: json,
        });
    };
};

export const clearStandards = () => dispatch => {
    dispatch({
        type: STANDARDS.CLEAR,
    });
};
