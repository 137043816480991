import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import FormControl from "@material-ui/core/FormControl"
import FormGroup from "@material-ui/core/FormGroup"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button/Button"
import { withSnackbar } from "notistack"
import { generatePath, Link } from "react-router-dom"

import {
  clearCurrentKnowmix,
  createWork,
  finishWork,
  getKnowmix,
  stepsList,
  updateWork,
} from "../../../../../library/store/actions/creators/knowmixCreators"
import getErrorText from "../../../../../library/constants/errorTypes"
import Loader from "../../../../../components/ui/loader"
import * as routes from "../../../../../library/constants/routes"
import logoDefault from "../../../../../assets/img/product.svg"
import Select from "@material-ui/core/Select/Select"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import { letters } from "../../courseSettings/CourseSettingsSteps/questionForm/MatchingQuestionConstructor"
import SEO from "../../../../../components/SEO"

class KnowMixWalkthrough extends React.Component {
  state = {
    dataReceived: false,
    currentKnowmix: null,
    currentKnowmixSteps: [],
    currentStepIndex: parseInt(this.props.match.params.stepId),
  }

  async componentDidMount() {
    await this.getData()
    await this.createWork()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.currentStepIndex !== this.state.currentStepIndex) {
      this.createWork()
    }
  }

  getData = async () => {
    const {
      match: {
        params: { knowmixId, stepId },
      },
    } = this.props

    try {
      await this.props.getKnowmix({ id: parseInt(knowmixId) })
      const { currentKnowmix } = this.props.knowmix
      await this.props.stepsList({ id: parseInt(knowmixId) })
      this.setState({
        dataReceived: true,
        currentKnowmix: currentKnowmix,
        currentKnowmixSteps: this.props.knowmix.stepsList,
        currentStepIndex: parseInt(stepId),
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  freeFieldChangeHandler = (e, questionIndex) => {
    const { currentStepIndex, currentKnowmixSteps } = this.state

    this.setState({
      currentKnowmixSteps: currentKnowmixSteps.map((s, s_i) =>
        s_i === currentStepIndex
          ? {
              ...s,
              questions: s.questions.map((q, q_i) =>
                q_i === questionIndex
                  ? {
                      ...q,
                      user_answer_text: e.target.value,
                    }
                  : q
              ),
            }
          : s
      ),
    })
  }

  blankPartQuestionFieldChangeHandler = (e, questionIndex, answerIndex) => {
    const { currentStepIndex, currentKnowmixSteps } = this.state

    this.setState({
      currentKnowmixSteps: currentKnowmixSteps.map((s, s_i) =>
        s_i === currentStepIndex
          ? {
              ...s,
              questions: s.questions.map((q, q_i) =>
                q_i === questionIndex
                  ? {
                      ...q,
                      // user_answer_text_options: Array.isArray(q.user_answer_text_options) ? [e.target.value] : [],
                      user_answer_text_options: e.target.value
                        ? [e.target.value]
                        : undefined,
                    }
                  : q
              ),
            }
          : s
      ),
    })
  }

  matchingQuestionChangeHandler = (e, questionIndex, answerIndex) => {
    const { currentStepIndex, currentKnowmixSteps } = this.state

    this.setState(
      {
        currentKnowmixSteps: currentKnowmixSteps.map((s, s_i) => {
          return s_i === currentStepIndex
            ? {
                ...s,
                questions: s.questions.map((q, q_i) => {
                  if (q_i === questionIndex) {
                    const updatedAnswers = q.user_answer_options.length
                      ? q.user_answer_options
                      : q.questions.map(() => "")

                    return {
                      ...q,
                      user_answer_options: updatedAnswers.map((a_o, a_o_i) =>
                        a_o_i === answerIndex ? e.target.value : a_o
                      ),
                    }
                  }

                  return q
                }),
              }
            : s
        }),
      },
      () => {
        const answerOptions =
          this.state.currentKnowmixSteps[currentStepIndex].questions[
            questionIndex
          ].user_answer_options
        if (!answerOptions.some((a) => typeof a === "string")) {
          this.updateState()
        }
      }
    )
  }

  blurHandler = (e) => {
    this.updateState()
  }

  updateState = async () => {
    const { currentStepIndex, currentKnowmixSteps } = this.state
    const currentStep = currentKnowmixSteps[currentStepIndex]
    try {
      await this.props.updateWork({
        step_id: currentStep.id,
        questions: currentStep.questions.map((q) => {
          return {
            id: q.id,
            answer_text: q.user_answer_text || undefined,
            answer_options:
              q.user_answer_options && q.user_answer_options.length
                ? q.user_answer_options
                : undefined,
            answer_text_options:
              q.type === 4 ? q.user_answer_text_options : undefined,
          }
        }),
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  radioGroupChangeHandler = (e, questionIndex, answerIndex) => {
    const { currentStepIndex, currentKnowmixSteps } = this.state

    this.setState(
      {
        currentKnowmixSteps: currentKnowmixSteps.map((s, s_i) =>
          s_i === currentStepIndex
            ? {
                ...s,
                questions: s.questions.map((q, q_i) =>
                  q_i === questionIndex
                    ? {
                        ...q,
                        user_answer_options: [answerIndex],
                      }
                    : q
                ),
              }
            : s
        ),
      },
      this.updateState
    )
  }

  checkBoxGroupChangeHandler = (e, questionIndex, answerIndex) => {
    const { currentStepIndex, currentKnowmixSteps } = this.state

    this.setState(
      {
        currentKnowmixSteps: currentKnowmixSteps.map((s, s_i) =>
          s_i === currentStepIndex
            ? {
                ...s,
                questions: s.questions.map((q, q_i) =>
                  q_i === questionIndex
                    ? {
                        ...q,
                        user_answer_options: e.target.checked
                          ? q.user_answer_options
                            ? [...q.user_answer_options, answerIndex]
                            : [answerIndex]
                          : q.user_answer_options.filter(
                              (a_o) => a_o !== answerIndex
                            ),
                      }
                    : q
                ),
              }
            : s
        ),
      },
      this.updateState
    )
  }

  createWork = async () => {
    const { currentStepIndex, currentKnowmixSteps } = this.state
    const currentStep = currentKnowmixSteps[currentStepIndex]
    if (currentStep.type === 1) {
      try {
        await this.props.createWork({ step_id: currentStep.id })
        await this.getData()
      } catch ({ error }) {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    }
  }

  submitStep = async (e) => {
    e.preventDefault()
    const { currentStepIndex, currentKnowmixSteps } = this.state
    const currentStep = currentKnowmixSteps[currentStepIndex]

    try {
      await this.props.finishWork({ step_id: currentStep.id })
      this.props.history.push(
        generatePath(routes.KNOWMIX, {
          knowmixId: this.state.currentKnowmix.id,
        })
      )
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  getQuestionLayout = (
    {
      title,
      type,
      user_answer_text = "",
      options = [],
      user_answer_options = [],
      parts,
      questions,
      answers = [],
      user_answer_text_options = [],
    },
    index
  ) => {
    const { currentStepIndex, currentKnowmixSteps } = this.state
    const currentStep = currentKnowmixSteps[currentStepIndex]
    switch (type) {
      case 1:
        return (
          <div className="full-width" key={index}>
            <div className="form__fieldset">
              <label htmlFor={`field_${index}`} className="form__label">
                Question {index + 1}
              </label>
              <div className="form__fieldbox">
                <div className="form__masked-value">
                  <pre>{title}</pre>
                </div>
              </div>
            </div>
            <div className="form__fieldset">
              <label htmlFor={`field_${index}`} className="form__label">
                Answer
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <FormControl
                    component="fieldset"
                    id="group-type"
                    disabled={currentStep.type === 3}
                  >
                    <RadioGroup aria-label="group-type" name="groupType">
                      {options.map((v, i) => {
                        return (
                          <FormControlLabel
                            key={v}
                            checked={user_answer_options.includes(i)}
                            control={
                              <Radio
                                color="primary"
                                onChange={(e) =>
                                  this.radioGroupChangeHandler(e, index, i)
                                }
                              />
                            }
                            label={<pre>{v}</pre>}
                            labelPlacement="end"
                          />
                        )
                      })}
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
        )

      case 2:
        return (
          <div className="full-width" key={index}>
            <div className="form__fieldset">
              <label htmlFor={`field_${index}`} className="form__label">
                Question {index + 1}
              </label>
              <div className="form__fieldbox">
                <div className="form__masked-value">
                  <pre>{title}</pre>
                </div>
              </div>
            </div>
            <div className="form__fieldset">
              <label htmlFor={`field_${index}`} className="form__label">
                Answer
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <FormControl
                    component="fieldset"
                    className=""
                    disabled={currentStep.type === 3}
                  >
                    <FormGroup>
                      {options.map((v, i) => {
                        return (
                          <FormControlLabel
                            key={v}
                            control={
                              <Checkbox
                                checked={user_answer_options.includes(i)}
                                onChange={(e) =>
                                  this.checkBoxGroupChangeHandler(e, index, i)
                                }
                              />
                            }
                            label={<pre>{v}</pre>}
                          />
                        )
                      })}
                    </FormGroup>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
        )

      case 3:
        return (
          <div className="full-width" key={index}>
            <div className="form__fieldset">
              <label htmlFor={`field_${index}`} className="form__label">
                Question {index + 1}
              </label>
              <div className="form__fieldbox">
                <div className="form__masked-value">
                  <pre>{title}</pre>
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor={`field_${index}`} className="form__label">
                Answer
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <TextValidator
                    name={`field_${index}`}
                    id={`field_${index}`}
                    placeholder={"Type your answer"}
                    multiline
                    fullWidth
                    margin="normal"
                    onChange={(e) => this.freeFieldChangeHandler(e, index)}
                    onBlur={this.blurHandler}
                    value={user_answer_text}
                    disabled={currentStep.type === 3}
                  />
                </div>
              </div>
            </div>
          </div>
        )

      case 4:
        return (
          <div className="full-width" key={index}>
            <div className="form__fieldset">
              <span className="form__label">Question {index + 1}</span>
              <div className="form__fieldbox">
                <div className="form__masked-value">{title}</div>
              </div>
            </div>

            {parts.map((part, partIndex) =>
              part.type === 2 ? (
                <div className="form__fieldset" key={`text-part-${partIndex}`}>
                  <span className="form__label">{/*Text part*/}</span>
                  <div className="form__fieldbox">
                    <div className="form__masked-value">
                      <span>{part.text}</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="form__fieldset" key={`blank-part-${partIndex}`}>
                  <span className="form__label">Answer</span>
                  <div className="form__fieldbox">
                    <div className="form__input">
                      <TextValidator
                        name={`text-part-${partIndex + String(new Date())}`}
                        // value={user_answer_text_options[partIndex]}
                        value={user_answer_text_options[0]}
                        placeholder={"Type your answer"}
                        className="edit-form__input"
                        margin="normal"
                        fullWidth
                        multiline
                        validators={["required", "maxStringLength:250"]}
                        errorMessages={[
                          "Field is required",
                          "Max length is 250 characters",
                        ]}
                        onChange={(event) => {
                          this.blankPartQuestionFieldChangeHandler(
                            event,
                            index,
                            partIndex
                          )
                        }}
                        onBlur={this.blurHandler}
                        disabled={currentStep.type === 3}
                      />
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        )

      case 5:
        return (
          <div className="full-width" key={index}>
            <div className="form__fieldset">
              <span className="form__label">Question {index + 1}</span>
              <div className="form__fieldbox">
                <div className="form__masked-value">{title}</div>
              </div>
            </div>

            <div className="form__fieldset">
              <span className="form__label">Statements</span>
              <div className="form__fieldbox">
                <div className="form__masked-value">
                  {questions.map((q, statementIndex) => (
                    <div
                      className={
                        statementIndex < questions.length - 1 ? "mb8" : ""
                      }
                      key={`statement-${statementIndex}`}
                    >
                      <span>
                        {statementIndex + 1}. {q.value}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <span className="form__label">Options</span>
              <div className="form__fieldbox">
                <div className="form__masked-value">
                  {answers.map((a, optionIndex) => (
                    <div
                      className={optionIndex < answers.length - 1 ? "mb8" : ""}
                      key={`option-${optionIndex}`}
                    >
                      <span>
                        {letters[optionIndex]}. {a.value}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor="answer" className="form__label f aic">
                Right answers *
              </label>
              <div className="form__fieldbox">
                {questions.map((item, answerIndex) => (
                  <div className="f aib mb20" key={answerIndex}>
                    <span className={"mr5"}>{answerIndex + 1}: </span>
                    <Select
                      id={"right-answer-" + answerIndex}
                      name={"rightAnswer_" + answerIndex}
                      fullWidth
                      displayEmpty
                      value={user_answer_options[answerIndex]}
                      onChange={(e) =>
                        this.matchingQuestionChangeHandler(
                          e,
                          index,
                          answerIndex
                        )
                      }
                      style={{ width: 300 }}
                      renderValue={(value) => {
                        if (!Number.isInteger(value)) {
                          return (
                            <span className="color-black-38">
                              Select answer
                            </span>
                          )
                        }

                        return letters[value]
                      }}
                      disabled={currentStep.type === 3}
                    >
                      <MenuItem value={""}>
                        <span className={"color-black-38"}>Select answer</span>
                      </MenuItem>
                      {answers.map((item, answerOptionIndex) => (
                        <MenuItem
                          value={answerOptionIndex}
                          key={letters[answerOptionIndex]}
                        >
                          {letters[answerOptionIndex]}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )

      default:
        return <></>
    }
  }

  render() {
    const {
      dataReceived,
      currentKnowmix,
      currentKnowmixSteps,
      currentStepIndex,
    } = this.state
    const currentStep = currentKnowmixSteps[currentStepIndex]

    return (
      <div className="common-page__content common-page__content--static">
        <main className="common-page__main">
          <div className="box">
            {dataReceived && (
              <>
                <SEO title={`Step ${currentStepIndex + 1} quiz`} />
                <div className="box__heading">
                  <h1>
                    Step {currentStepIndex + 1}{" "}
                    {!!currentStep && `- ${currentStep.name}`}
                  </h1>
                </div>

                <div className="box__content">
                  <ValidatorForm onSubmit={this.submitStep} className="form">
                    {!currentStep.questions.length && (
                      <>
                        <div className="question-container text-secondary">
                          This step has no questions.
                        </div>
                        <div className="question-container text-secondary">
                          Complete step to notify an examiner about your
                          progress.
                        </div>
                      </>
                    )}

                    <div className="full-width">
                      <div className="form__fieldset">
                        <label className="form__label">Step name</label>
                        <div className="form__fieldbox">
                          <div className="form__masked-value">
                            {currentStep.name}
                          </div>
                        </div>
                      </div>
                      <div className="form__fieldset">
                        <label className="form__label">Instructions</label>
                        <div className="form__fieldbox">
                          <div className="form__masked-value">
                            {currentStep.description}
                          </div>
                        </div>
                      </div>
                    </div>

                    {dataReceived &&
                      currentStep.questions.map((q, i) => {
                        return (
                          <div className="question-container" key={i}>
                            {this.getQuestionLayout(q, i)}
                            {currentStep.questions.length > i + 1 && (
                              <div className="form__divider"></div>
                            )}
                          </div>
                        )
                      })}

                    <div className="btn-group jcfe pl15 pr15">
                      <Button
                        component={Link}
                        to={{
                          pathname: generatePath(routes.KNOWMIX, {
                            knowmixId: this.props.match.params.knowmixId,
                          }),
                          search: `?stepId=${currentStep.id}`,
                        }}
                        color="primary"
                        variant="outlined"
                      >
                        Back
                      </Button>

                      {currentStep.type !== 3 && (
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                        >
                          Complete step
                        </Button>
                      )}
                    </div>
                  </ValidatorForm>
                </div>
              </>
            )}
          </div>
          {!dataReceived && <Loader />}
        </main>
        <aside className="common-page__sidebar">
          {dataReceived && (
            <div className="box aside-header">
              <Link
                to={generatePath(routes.KNOWMIX, {
                  knowmixId: this.props.match.params.knowmixId,
                })}
                className="box__content aside-header__content"
              >
                <img
                  src={this.props.knowmix.currentKnowmix.photo || logoDefault}
                  className="aside-header__img"
                  alt=""
                />
                <div className="aside-header__info">
                  <div className="aside-header__name">
                    {this.props.knowmix.currentKnowmix.name}
                  </div>
                  <div className="aside-header__help">back to KnowMix</div>
                </div>
              </Link>
            </div>
          )}
          {/*<Ads/>*/}
        </aside>
      </div>
    )
  }
}

const mapState = ({ auth, knowmix }) => ({ auth, knowmix })
const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      getKnowmix,
      stepsList,
      clearCurrentKnowmix,
      updateWork,
      finishWork,
      createWork,
    },
    dispatch
  )

export default connect(mapState, mapDispatch)(withSnackbar(KnowMixWalkthrough))
