//0 - 600px:      Phone
//600 - 900px:    Tablet portrait
//900 - 1200px:   Tablet landscape
//[1200 - 1800] is where our normal styles apply
//1800px + :      Big desktop

export const devices = {
    xs: "only screen and (max-width: 37.5em)",
    s: "only screen and (max-width: 56.25em)",
    m: "only screen and (max-width: 75em)",
    xl: "only screen and (min-width: 112.5em)",
};
