import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { SCHEDULE } from "../../../library/store/actions/types/calendarTypes"
import { SEGMENTS } from "../../../library/store/actions/types/groupsTypes"
import { useAlert } from "../../useAlert"
import { call } from "../../../library/networking/API"

export const useSchedule = (id, options = {}) => {
  return useQuery({
    queryKey: [SCHEDULE.SCHEDULES.GET, { id }],
    refetchOnWindowFocus: false,
    staleTime: 60000,
    ...options,
  })
}
export const useSegment = (id, options = {}) => {
  return useQuery({
    queryKey: [SEGMENTS.GET, { id }],
    refetchOnWindowFocus: false,
    staleTime: 60000,
    ...options,
  })
}
export const useSection = (id, options = {}) => {
  return useQuery({
    queryKey: [SEGMENTS.SECTIONS.GET, { id }],
    refetchOnWindowFocus: false,
    staleTime: 60000,
    ...options,
  })
}
export const useTrack = (id, options = {}) => {
  return useQuery({
    queryKey: [SEGMENTS.SECTIONS.TRACKS.GET, { id }],
    refetchOnWindowFocus: false,
    staleTime: 60000,
    ...options,
  })
}
export const useBlock = (id, options = {}) => {
  return useQuery({
    queryKey: [SEGMENTS.SECTIONS.BLOCKS.GET, { id }],
    refetchOnWindowFocus: false,
    staleTime: 60000,
    ...options,
  })
}
export const useBlockTrackSlot = (id, options = {}) => {
  return useQuery({
    queryKey: [SEGMENTS.SECTIONS.SLOTS.GET, { id }],
    refetchOnWindowFocus: false,
    staleTime: 60000,
    ...options,
  })
}

export const useScheduleMutations = (queryKey = [SCHEDULE.SCHEDULES.LIST]) => {
  const queryClient = useQueryClient()
  const { errorAlert } = useAlert()

  const createSchedule = () => {}
  const updateSchedule = () => {}
  const deleteSchedule = () => {}

  return {
    createSchedule,
    updateSchedule,
    deleteSchedule,
  }
}

export const useSegmentMutations = (queryKey = [SEGMENTS.LIST]) => {
  const queryClient = useQueryClient()
  const { errorAlert } = useAlert()

  console.log(queryKey)

  const createSegment = useMutation(
    async ({
      schedule_id,
      name,
      grades,
      date_from,
      date_to,
      section_duration = 1,
    }) => {
      try {
        await call(SEGMENTS.CREATE, {
          schedule_id,
          name,
          grades,
          date_from,
          date_to,
          section_duration,
        })
      } catch (e) {
        errorAlert(e)
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    }
  )
  const updateSegment = useMutation(
    async ({ id, name, grades, date_from, date_to, section_duration = 1 }) => {
      try {
        await call(SEGMENTS.UPDATE, {
          id,
          name,
          grades,
          date_from,
          date_to,
          section_duration,
        })
      } catch (e) {
        errorAlert(e)
      }
    },
    {
      onSuccess: (_, { id }) => {
        console.log(id)
        queryClient.invalidateQueries({ queryKey })
        queryClient.invalidateQueries({ queryKey: [SEGMENTS.GET, { id }] })
      },
    }
  )
  const deleteSegment = useMutation(
    async ({ id }) => {
      try {
        await call(SEGMENTS.DELETE, { id })
      } catch (e) {
        errorAlert(e)
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    }
  )

  return {
    createSegment,
    updateSegment,
    deleteSegment,
  }
}
export const useSectionMutations = (queryKey = []) => {
  const queryClient = useQueryClient()
  const { errorAlert } = useAlert()

  const createSection = useMutation(
    async ({ segment_id, name, rotations }) => {
      try {
        await call(SEGMENTS.SECTIONS.CREATE, {
          segment_id,
          name,
          rotations,
        })
      } catch (e) {
        errorAlert(e)
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    }
  )
  const updateSection = useMutation(
    async ({ id, name, rotations }) => {
      try {
        await call(SEGMENTS.SECTIONS.UPDATE, {
          id,
          name,
          rotations,
        })
      } catch (e) {
        errorAlert(e)
      }
    },
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries({ queryKey })
        queryClient.invalidateQueries({
          queryKey: [SEGMENTS.SECTIONS.GET, { id }],
        })
      },
    }
  )
  const deleteSection = useMutation(
    async ({ id }) => {
      try {
        await call(SEGMENTS.SECTIONS.DELETE, {
          id,
        })
      } catch (e) {
        errorAlert(e)
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    }
  )

  return {
    createSection,
    updateSection,
    deleteSection,
  }
}
export const useTrackMutations = (queryKey = []) => {
  const queryClient = useQueryClient()
  const { errorAlert } = useAlert()

  const createTrack = useMutation(
    async ({ section_id }) => {
      try {
        await call(SEGMENTS.SECTIONS.TRACKS.CREATE, { section_id })
      } catch (e) {
        errorAlert(e)
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    }
  )
  const updateTrack = useMutation(
    async ({ id }) => {
      try {
        await call(SEGMENTS.SECTIONS.TRACKS.UPDATE, { id })
      } catch (e) {
        errorAlert(e)
      }
    },
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries({ queryKey })
        queryClient.invalidateQueries({
          queryKey: [SEGMENTS.SECTIONS.TRACKS.GET, { id }],
        })
      },
    }
  )
  const deleteTrack = useMutation(
    async ({ id }) => {
      try {
        await call(SEGMENTS.SECTIONS.TRACKS.DELETE, { id })
      } catch (e) {
        errorAlert(e)
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    }
  )

  return {
    createTrack,
    updateTrack,
    deleteTrack,
  }
}
export const useBlockMutations = (
  queryKey = [],
  shouldUpdateDetails = true
) => {
  const queryClient = useQueryClient()
  const { errorAlert } = useAlert()

  const createBlock = useMutation(
    async ({
      section_id,
      name,
      is_all_day,
      rotations = [],
      time_from,
      time_to,
      tracks = [],
      shouldUpdateList = false,
    }) => {
      try {
        await call(SEGMENTS.SECTIONS.BLOCKS.CREATE, {
          section_id,
          name,
          is_all_day,
          rotations,
          time_from,
          time_to,
          tracks,
        })
      } catch (e) {
        errorAlert(e)
      }
    },
    {
      onSuccess: (data, variables, context) => {
        if (variables.shouldUpdateList) {
          queryClient.invalidateQueries({ queryKey })
        }
      },
    }
  )
  const updateBlock = useMutation(
    async ({
      id,
      name,
      time_from,
      time_to,
      is_all_day,
      rotations,
      tracks = [],
    }) => {
      try {
        await call(SEGMENTS.SECTIONS.BLOCKS.UPDATE, {
          id,
          name,
          time_from,
          time_to,
          is_all_day,
          rotations,
          tracks,
        })
      } catch (e) {
        errorAlert(e)
      }
    },
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries({ queryKey })

        if (shouldUpdateDetails) {
          queryClient.invalidateQueries({
            queryKey: [SEGMENTS.SECTIONS.BLOCKS.GET, { id }],
          })
        }
      },
    }
  )
  const deleteBlock = useMutation(
    async ({ id }) => {
      try {
        await call(SEGMENTS.SECTIONS.BLOCKS.DELETE, { id })
      } catch (e) {
        errorAlert(e)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey })
      },
    }
  )

  return {
    createBlock,
    updateBlock,
    deleteBlock,
  }
}

export const useSlotMutations = (queryKey = [], shouldUpdateDetails = true) => {
  const queryClient = useQueryClient()
  const { errorAlert } = useAlert()

  const updateSlot = useMutation(
    async ({ id, title, teachers, students, subject_id }) => {
      try {
        await call(SEGMENTS.SECTIONS.SLOTS.UPDATE, {
          id,
          title,
          teachers,
          students,
          subject_id,
        })
      } catch (e) {
        errorAlert(e)
      }
    },
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries({ queryKey })

        if (shouldUpdateDetails) {
          queryClient.invalidateQueries({
            queryKey: [SEGMENTS.SECTIONS.SLOTS.GET, { id }],
          })
        }
      },
    }
  )

  return {
    updateSlot,
  }
}
