import BoxPage from "../../../../../components/v2/layout/boxPage/boxPage"
import { Button } from "@material-ui/core"

const ProfileVerification = () => {
  return (
    <BoxPage title={"Verification"}>
      <div className="f aic fdc text-center">
        <p className="color-black-54 mb15">
          You need to be verified if you want to add students to a Course
        </p>
        <p className="color-black-54 mb15">
          Please complete SentryLink’s background check and share your report to{" "}
          <a
            href="mailto:backgroundchecks@Knowstory.com"
            className={"link link--inline"}
          >
            backgroundchecks@Knowstory.com
          </a>
          . <br /> Knowstory will then open your access to invite students.
        </p>
        <Button
          color={"primary"}
          variant={"contained"}
          component={"a"}
          href={"https://www.sentrylink.com/web/criminal-check.action"}
          target={"_blank"}
        >
          Request verification
        </Button>
      </div>
    </BoxPage>
  )
}

export default ProfileVerification
