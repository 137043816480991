import { NOTIFICATION } from "../actions/types/notificationTypes"

const initialState = {
  notificationList: [],
  notificationSmallList: [],
  notificationCounter: 0,
  chatsCounter: 0,
  friendsCounter: 0,
  unreadCounter: 0,
}

export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION.LIST:
      return {
        ...state,
        notificationList: [...state.notificationList, ...action.payload],
      }
    case NOTIFICATION.SMALL_LIST:
      return {
        ...state,
        notificationSmallList: action.payload,
      }
    case NOTIFICATION.CLEAR_LIST:
      return {
        ...state,
        notificationList: [],
      }
    case NOTIFICATION.CLEAR_SMALL_LIST:
      return {
        ...state,
        notificationSmallList: [],
      }
    case NOTIFICATION.GET_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCounter: action.payload,
      }
    case NOTIFICATION.GET_CHATS_COUNT:
      return {
        ...state,
        chatsCounter: action.payload,
      }
    case NOTIFICATION.INCREMENT_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCounter: state.notificationCounter + action.payload,
        unreadCounter: state.unreadCounter + action.payload,
      }
    case NOTIFICATION.INCREMENT_CHATS_COUNT:
      return {
        ...state,
        chatsCounter: state.chatsCounter + action.payload,
      }
    case NOTIFICATION.DECREMENT_CHATS_COUNT:
      return {
        ...state,
        chatsCounter: state.chatsCounter - 1 > 0 ? state.chatsCounter - 1 : 0,
      }
    case NOTIFICATION.CLEAR_CHATS_COUNT:
      return {
        ...state,
        chatsCounter: 0,
      }
    case NOTIFICATION.CLEAR_NOTIFICATIONS_COUNT:
      return {
        ...state,
        notificationCounter: 0,
      }
    case NOTIFICATION.DECREMENT_UNREAD_COUNT:
      return {
        ...state,
        unreadCounter: state.unreadCounter - action.payload,
      }
    case NOTIFICATION.CLEAR_UNREAD_COUNT:
      return {
        ...state,
        unreadCounter: 0,
      }
    case NOTIFICATION.UPDATE:
      return {
        ...state,
        notificationList: action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}
