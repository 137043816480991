import React from "react"
import Dialog from "@material-ui/core/Dialog/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"

const AlertDialog = ({
  open,
  handleClose,
  title = "",
  message = "",
  canDecline = true,
  declineButtonTitle = "",
  handleAccept,
  acceptButtonTitle = "",
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <p className="color-black-54 font-16">{message}</p>
      </DialogContent>
      <DialogActions>
        {canDecline && (
          <Button onClick={handleClose} color="primary">
            {declineButtonTitle || "Decline"}
          </Button>
        )}
        <Button onClick={handleAccept} color="primary" autoFocus>
          {acceptButtonTitle || "Accept"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AlertDialog
