import React from "react"
import PostsList from "./postsList"
import { useSelector } from "react-redux"
import PostCreate from "./postCreate"
import "./news-feed.scss"
import PlainLink from "../../../components/ui/link"
import { generatePath } from "react-router-dom"
import { GROUP } from "../../../library/constants/routes"
import SEO from "../../../components/SEO"

const NewsFeed = () => {
  const { userData } = useSelector(({ auth }) => auth)

  return (
    <div className="common-page__content common-page__content--static">
      <SEO title={"News Feed"} />
      <main className="common-page__main news-feed">
        <PostCreate type="feed" />
        <PostsList type="feed" userInfo={userData} />
      </main>
      <aside className="common-page__sidebar">
        <div className="box boxed-list">
          <div className="boxed-list__header">Channels to follow</div>
          <div className="boxed-list__list px-4 pb-4 space-y-2">
            <PlainLink to={generatePath(GROUP, { groupId: 143227 })}>
              Learning Counsel News
            </PlainLink>
            <PlainLink to={generatePath(GROUP, { groupId: 143844 })}>
              EdTech Digest
            </PlainLink>
            <PlainLink to={generatePath(GROUP, { groupId: 143867 })}>
              KnowStory News Network
            </PlainLink>
          </div>
        </div>
      </aside>
    </div>
  )
}

export default NewsFeed
