import React from "react"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import Button from "@material-ui/core/Button"
import { call } from "../../../library/networking/API"
import * as endpoints from "../../../library/networking/apiEndpoints"
import getErrorText from "../../../library/constants/errorTypes"

class ContactForm extends React.Component {
  state = {
    userName: this.props.auth.userData
      ? `${this.props.auth.userData.first_name} ${this.props.auth.userData.last_name}`
      : "",
    email: this.props.auth.userData ? this.props.auth.userData.email : "",
    subject: "",
    description: "",
  }

  processSend = async () => {
    const { userName, email, subject, description } = this.state
    try {
      await call(endpoints.USERS.CONTACT, {
        name: userName,
        email,
        subject,
        message: description,
      })
      this.clearForm()
      await this.props.enqueueSnackbar(
        "Your message has been sent successfully. The answer from support will come to your e-mail.",
        { variant: "success" }
      )
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  clearForm = () => {
    if (this.props.auth.userData) {
      return this.setState({
        subject: "",
        description: "",
      })
    }
    return this.setState({
      userName: "",
      email: "",
      subject: "",
      description: "",
    })
  }

  render() {
    const { auth } = this.props
    const { userName, email, subject, description } = this.state
    const disabled = !userName || !email || !subject || !description
    if (auth.userData) {
      return (
        <div className="f jcc">
          <div className="sign-up-form hero-form">
            <div className="sign-up-form__header">
              <h2 className="sign-up-form__heading">CONTACT US</h2>
            </div>
            <ValidatorForm className="sign-up-form__form">
              <div className="sign-up-form__field-wrapper">
                <TextValidator
                  name="subject"
                  value={subject}
                  label="Subject *"
                  className="m-text-field"
                  margin="none"
                  fullWidth
                  helperText="&nbsp;"
                  validators={["required", "maxStringLength:150"]}
                  errorMessages={[
                    "Field is required",
                    "Max length is 150 characters",
                  ]}
                  onChange={(subject) =>
                    this.setState({ subject: subject.target.value })
                  }
                />
              </div>
              <div className="sign-up-form__field-wrapper">
                <TextValidator
                  name="description"
                  value={description}
                  label="Description *"
                  className="m-text-field"
                  margin="none"
                  fullWidth
                  multiline
                  helperText="&nbsp;"
                  validators={["required", "maxStringLength:500"]}
                  errorMessages={[
                    "Field is required",
                    "Max length is 500 characters",
                  ]}
                  onChange={(description) =>
                    this.setState({ description: description.target.value })
                  }
                />
              </div>
              <div className="f jcc aic full-width">
                <Button
                  disabled={disabled}
                  color="primary"
                  variant="contained"
                  onClick={this.processSend}
                >
                  SEND
                </Button>
              </div>
            </ValidatorForm>
          </div>
        </div>
      )
    }

    return (
      <div className="sign-up-form hero-form">
        <div className="sign-up-form__header">
          <h2 className="sign-up-form__heading">CONTACT US</h2>
        </div>
        <ValidatorForm
          className="sign-up-form__form"
          onSubmit={this.processSend}
        >
          <div className="sign-up-form__field-wrapper">
            <TextValidator
              name="userName"
              value={userName}
              label="Your name *"
              className="m-text-field"
              margin="none"
              fullWidth
              helperText="&nbsp;"
              validators={["required", "maxStringLength:75"]}
              errorMessages={[
                "Field is required",
                "Max length is 75 characters",
              ]}
              onChange={(userName) =>
                this.setState({ userName: userName.target.value })
              }
            />
          </div>
          <div className="sign-up-form__field-wrapper">
            <TextValidator
              name="email"
              value={email}
              label="Your email *"
              className="m-text-field"
              margin="none"
              fullWidth
              helperText="&nbsp;"
              validators={["required", "maxStringLength:75"]}
              errorMessages={[
                "Field is required",
                "Max length is 75 characters",
              ]}
              onChange={(email) => this.setState({ email: email.target.value })}
            />
          </div>
          <div className="sign-up-form__field-wrapper">
            <TextValidator
              name="subject"
              value={subject}
              label="Subject *"
              className="m-text-field"
              margin="none"
              fullWidth
              helperText="&nbsp;"
              validators={["required", "maxStringLength:150"]}
              errorMessages={[
                "Field is required",
                "Max length is 150 characters",
              ]}
              onChange={(subject) =>
                this.setState({ subject: subject.target.value })
              }
            />
          </div>
          <div className="sign-up-form__field-wrapper">
            <TextValidator
              name="description"
              value={description}
              label="Description *"
              className="m-text-field"
              margin="none"
              fullWidth
              multiline
              helperText="&nbsp;"
              validators={["required", "maxStringLength:500"]}
              errorMessages={[
                "Field is required",
                "Max length is 500 characters",
              ]}
              onChange={(description) =>
                this.setState({ description: description.target.value })
              }
            />
          </div>
          <div className="f jcc aic full-width">
            <Button
              disabled={disabled}
              color="primary"
              variant="contained"
              onClick={this.processSend}
            >
              SEND
            </Button>
          </div>
        </ValidatorForm>
      </div>
    )
  }
}

const mapStateToProps = ({auth}) => ({auth});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)((withSnackbar(ContactForm)));
