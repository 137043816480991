import React, { useEffect, useState } from "react"
import Table from "../../../../components/table"
import {
  getArrayByIds,
  getObjectById,
  renderArrayOfNames,
} from "../../../../library/utils/arrays"
import {
  productContentTypes,
  productFileTypes,
  productPlatforms,
} from "../../../../library/constants/productDictionary"
import {
  grades as gradesList,
  subjects as subjectsList,
} from "../../../../library/constants/educationalDictionary"
import { columns } from "../../../../library/constants/invenstory"
import CreateEditInvenstoryRecord from "../createEditInvenstoryRecord/createEditInvenstoryRecord"
import { bindActionCreators } from "redux"
import {
  clearCurrentRecord,
  createInvenstoryRecord,
  deleteInvenstoryRecord,
  getInvenstoryRecord,
  updateInvenstoryRecord,
} from "../../../../library/store/actions/creators/invenstoryCreators"
import {
  createBookmark,
  deleteBookmark,
  foldersList,
} from "../../../../library/store/actions/creators/bookmarksCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { withRouter } from "react-router-dom"
import getErrorText from "../../../../library/constants/errorTypes"
import InvenstoryRecordDialog from "../invenstoryRecordDialog/invenstoryRecordDialog"
import { updateInfo } from "../../../../library/store/actions/creators/authCreators"
import { omit } from "lodash"
import Button from "@material-ui/core/Button"
import * as routes from "../../../../library/constants/routes"
import AddToFolderModal from "../../bookmarks/folders/addToFolder/addToFolder"
import withErrorBoundary from "../../../../hocs/guards/withErrorBoundary"

const InvenstoryTable = (props) => {
  const [list, setList] = useState([])
  const [activeColumns, setActiveColumns] = useState({
    ids: [],
    columns: [],
  })
  const [inactiveColumns, setInactiveColumns] = useState([])
  const [editDialog, setEditDialog] = useState({
    open: false,
    recordId: "",
  })
  const [dataReceived, setDataReceived] = useState(false)
  const [openRecordDialog, setOpenRecordDialog] = useState(false)
  const [openAddToFolderDialog, setOpenAddToFolderDialog] = useState(false)

  useEffect(() => {
    setActiveColumns({
      ids: props.invenstory.activeTableColumns,
      columns: columns.filter((c) =>
        props.invenstory.activeTableColumns.includes(c.id)
      ),
    })
    setInactiveColumns(
      columns
        .filter(
          (c) =>
            !props.invenstory.activeTableColumns.includes(c.id) && !c.required
        )
        .map((c) => c.id)
    )
  }, [props.invenstory.activeTableColumns])

  useEffect(() => {
    composeRecords()
  }, [props.data, activeColumns])

  const composeRecords = () => {
    const listComposed = props.data.map((r) => {
      return {
        id: r.id,
        can_manage: r.can_manage,
        bookmark: r.bookmark,
        creator_user: r.creator_user,
        creator_group: r.creator_group,
        image: r.image,
        info: {
          1: r.name,

          2: !!r.creator_user
            ? `${r.creator_user.first_name} ${r.creator_user.last_name}`
            : !!r.creator_group && r.creator_group.name,

          3: getObjectById(productContentTypes, r.content_type)
            ? getObjectById(productContentTypes, r.content_type).name
            : "",

          4: renderArrayOfNames(getArrayByIds(subjectsList, r.subjects)) || "",

          5: renderArrayOfNames(getArrayByIds(gradesList, r.grades)) || "",

          6: r.url || "",

          7:
            renderArrayOfNames(getArrayByIds(productFileTypes, r.file_types)) ||
            "",

          8:
            renderArrayOfNames(getArrayByIds(productPlatforms, r.platforms)) ||
            "",

          9: r.version || "",
        },
        // info: {
        //     1: r.name,
        //
        //     2: renderArrayOfNames(r.standards) || '',
        //
        //     3: !!r.creator_user ? `${r.creator_user.first_name} ${r.creator_user.last_name}`
        //         : !!r.creator_group && r.creator_group.name ,
        //
        //     4: getObjectById(productContentTypes, r.content_type) ? getObjectById(productContentTypes, r.content_type).name : '',
        //
        //     5: renderArrayOfNames(getArrayByIds(subjectsList, r.subjects)) || '',
        //
        //     6: renderArrayOfNames(getArrayByIds(gradesList, r.grades)) || '',
        //
        //     7: r.url || '',
        //
        //     8: renderArrayOfNames(getArrayByIds(productFileTypes, r.file_types)) || '',
        //
        //     9: renderArrayOfNames(getArrayByIds(productPlatforms, r.platforms)) || '',
        //
        //     10: r.version || ''
        // }
      }
    })

    setList(
      listComposed.map((item) => ({
        ...item,
        info: omit(item.info, inactiveColumns),
      }))
    )
    setDataReceived(true)
  }

  const openEditDialog = (record_id) => {
    setEditDialog({ ...editDialog, open: true, recordId: record_id })
  }

  const closeEditDialog = () => {
    setEditDialog({ ...editDialog, open: false, recordId: "" })
  }

  const deleteRecord = async (id) => {
    try {
      await props.deleteInvenstoryRecord({ id })
      await props.updateData()
    } catch ({ error }) {
      props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  const openRecord = async (id) => {
    try {
      setOpenRecordDialog(true)
      await props.getInvenstoryRecord({ id, group_id: props.selectedGroupId })
    } catch ({ error }) {
      props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  const closeRecordDialog = () => {
    setOpenRecordDialog(false)
    props.clearCurrentRecord()
  }

  const saveInvenstoryColumns = async (columnsIds) => {
    try {
      await props.updateInfo({ invenstory_preferences: columnsIds })
      props.updateData()
    } catch ({ error }) {
      props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  const createBookmark = async (recordId) => {
    try {
      await props.createBookmark({ record_id: recordId })
      await props.foldersList()
      await props.enqueueSnackbar("Saved to bookmarks", {
        autoHideDuration: 5000,
        action: (key) => {
          return (
            <>
              <Button
                color="primary"
                onClick={() => {
                  props.closeSnackbar(key)
                  props.history.push(routes.BOOKMARKS_RECORDS)
                }}
              >
                View
              </Button>
              {props.bookmarks.folders.length > 0 && (
                <Button
                  color="primary"
                  onClick={() => setOpenAddToFolderDialog(true)}
                >
                  Add to folder
                </Button>
              )}
            </>
          )
        },
      })
    } catch ({ error }) {
      props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  const deleteBookmark = async (recordId) => {
    try {
      await props.deleteBookmark({ record_id: recordId })
      props.enqueueSnackbar("Record deleted from bookmarks", {
        variant: "success",
      })
    } catch (e) {
      props.enqueueSnackbar(getErrorText(e.code), { variant: "error" })
    }
  }

  return (
    <>
      {dataReceived && (
        <Table
          data={list}
          columns={columns}
          activeColumns={activeColumns}
          editRecord={openEditDialog}
          deleteRecord={deleteRecord}
          openRecord={openRecord}
          createProduct={props.createProduct}
          saveColumns={saveInvenstoryColumns}
          tableState={props.tableState}
          tableControls={props.tableControls}
          processTableSearch={props.processTableSearch}
          tableSearchQuery={props.tableSearchQuery}
          setFilterValues={props.setFilterValues}
          standards={props.standards}
          standardsDataReceived={props.standardsDataReceived}
          standardsName={props.standardsName}
          onStandardsScroll={props.onStandardsScroll}
          setStandardsName={props.setStandardsName}
          initialRender={props.initialRender}
          noResultsLabel={props.noResultsLabel}
          createBookmark={createBookmark}
          deleteBookmark={deleteBookmark}
          view={props.view}
          switchView={props.switchView}
          onScroll={props.onScroll}
          listDataReceived={props.listDataReceived}
        />
      )}

      {editDialog.open && (
        <CreateEditInvenstoryRecord
          open={editDialog.open}
          onClose={closeEditDialog}
          recordId={editDialog.recordId}
          updateData={props.updateData}
          edit
        />
      )}

      {openRecordDialog && (
        <InvenstoryRecordDialog
          open={openRecordDialog}
          onClose={closeRecordDialog}
          record={props.invenstory.currentRecord}
        />
      )}
      {openAddToFolderDialog && (
        <AddToFolderModal
          open={openAddToFolderDialog}
          handleClose={() => setOpenAddToFolderDialog(false)}
        />
      )}
    </>
  )
}

const mapStateToProps = ({ auth, invenstory, bookmarks }) => ({
  auth,
  invenstory,
  bookmarks,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createInvenstoryRecord,
      updateInvenstoryRecord,
      deleteInvenstoryRecord,
      getInvenstoryRecord,
      clearCurrentRecord,
      updateInfo,
      createBookmark,
      foldersList,
      deleteBookmark,
    },
    dispatch
  )

export default withErrorBoundary(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withSnackbar(withRouter(InvenstoryTable)))
)
