import React from "react"
import MoreDropdown from "../../../../../../../../../components/ui/dropdowns/moreDropdown/moreDropdown"
import VerticalMenu from "../../../../../../../../../components/ui/verticalMenu"
import PlainLink from "../../../../../../../../../components/ui/link"
import { generatePath, withRouter } from "react-router-dom"
import * as routes from "../../../../../../../../../library/constants/routes"
import {
  getArrayByIds,
  renderArrayOfNames,
} from "../../../../../../../../../library/utils/arrays"
import { calendarGrades } from "../../../../../../../../../library/constants/educationalDictionary"

const SegmentsListItem = ({
  segment,
  openEditSegment,
  openDeleteSegment,
  match,
}) => {
  const dropdownMenuItems = [
    {
      type: "button",
      action: () => openEditSegment(segment.id),
      label: "Edit segment",
    },
    {
      type: "h-divider",
      id: "hd1",
    },
    {
      type: "button",
      action: () => openDeleteSegment(segment.id),
      label: "Delete segment",
    },
  ]

  return (
    <div className={"box list-item"}>
      <div className="f aic full-width">
        <div className="list-item__info">
          <PlainLink
            to={generatePath(
              routes.GROUP_SETTINGS_SCHOOL_CALENDAR_SEGMENT_SECTIONS,
              {
                ...match.params,
                segmentId: segment.id,
              }
            )}
          >
            <span className="list-item__name">{segment.name}</span>
          </PlainLink>

          <span>
            Grades:{" "}
            {renderArrayOfNames(getArrayByIds(calendarGrades, segment.grades))}
          </span>
        </div>
      </div>
      <div className={`list-item__control-panel`}>
        <MoreDropdown>
          <VerticalMenu
            menuItems={dropdownMenuItems}
            classes={"more-dropdown-menu__list"}
          />
        </MoreDropdown>
      </div>
    </div>
  )
}

export default withRouter(SegmentsListItem)
