import React from "react"
import classes from "./promoPage.module.scss"
import Button from "@material-ui/core/Button"
import { Link } from "react-router-dom"
import * as routes from "../../../library/constants/routes"
import learner from "../../../assets/img/pages/promote/learner.png"

const PromoPage = () => {
  return (
    <div className={classes.page}>
      <div>
        <div className={classes.header}>
          <div className={classes.header__content}>
            <div className="f fdc aic jcc full-width">
              <h2 className={classes.header__heading}>
                Market, Sell, Set-up Meetings and Present Training for EdTech in
                One Place
              </h2>
              <Button
                color={"primary"}
                variant={"contained"}
                component={Link}
                to={routes.CONTACT_FORM}
                style={{ width: 210 }}
              >
                Contact us
              </Button>
            </div>
          </div>
        </div>

        <section className={classes.section}>
          <div className="flexgrid container">
            <div className="full-width">
              <h3 className={classes.section__heading}>KnowStory</h3>
              <div className="f jcc full-width mb30">
                <p className={classes.section__text}>
                  KnowStory is the first consumer-facing edtech marketplace with
                  social ecosystem for schools, districts, learners, and
                  companies. Look at all the amazing users! You get Free and
                  Permanent company group creation and unlimited product tiles
                  which refer interested parties back to you!
                </p>
              </div>
              <div className="row aic">
                <div className="col-md-5 mb30xs">
                  <ul className={classes.list}>
                    <li className={classes.list__item}>
                      Full social function with mobile Apps.
                    </li>
                    <li className={classes.list__item}>
                      Educators get the unique “InvenStory” function which
                      crowd-sources from all teachers all edtech inventory.
                    </li>
                    <li className={classes.list__item}>
                      Bookmarking for academic standards, products, people,
                      groups, posts, and more.
                    </li>
                    <li className={classes.list__item}>
                      Creds “badging” structure, free for social and
                      extra-curricular badges, paid for to create authenticated
                      grades/marks, certificates, and diplomas.
                    </li>
                    <li className={classes.list__item}>
                      Playlist creation called Knowmix that can add instructions
                      to steps that may use your learning product. Use Knowmix
                      for your professional development and user instructions.
                    </li>
                    <li className={classes.list__item}>
                      “Uberization” of meetings set up when sponsors make
                      live-intersection Knowmixes with their papers and
                      resources for visiting potential customers. This function
                      is part of the forthcoming Hybrid Logistics Project to
                      automate the live intersection of teacher-plus-student(s)
                      upon reaching learning steps marked as Live -- and can
                      also be used by companies.
                    </li>
                  </ul>
                </div>
                <div className="offset-md-1 col-md-6 f jcfe">
                  <img src={learner} alt="Learner" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className={[classes.section, classes.section_contrast].join(" ")}
        >
          <div className="container">
            <div className="flexgrid full-width">
              <h3 className={classes.section__heading}>
                Make yourself known to our identified 3,000 leading-edge
                educator users in Knowstory plus hundreds of thousands of other
                educators already affiliated with Knowstory’s mother-company,
                Learning Counsel, as subscribers, followers, events speakers and
                members of the EduJedi Leadership Society.
              </h3>
              <div className="full-width mb30">
                <h3 className={classes.list_heading}>
                  Starter Level – Free, value of $1,000 annually
                </h3>
                <ul className={[classes.list, "mb30"].join(" ")}>
                  <li className={classes.list__item}>
                    Free Company Group creation.
                  </li>
                  <li className={classes.list__item}>
                    Free purchase interest referrals for product tiles from
                    Knowstory site. Lead values differ for different types of
                    products.
                  </li>
                  <li className={classes.list__item}>
                    Unlimited Products tiles.
                  </li>
                  <li className={classes.list__item}>
                    Free Standards tagging to products.
                  </li>
                  <li className={classes.list__item}>Unlimited Posts.</li>
                  <li className={classes.list__item}>Unlimited Friending.</li>
                  <li className={classes.list__item}>
                    Unlimited Knowmixes and Kourse creation for your
                    Professional Development for customers as paid or free.
                  </li>
                  <li className={classes.list__item}>Free Events postings.</li>
                  <li className={classes.list__item}>
                    Free Creds social and extra-curricular (badging) creation.
                  </li>
                  <li className={classes.list__item}>
                    Automatic annual update notifications so you keep your
                    product tiles fresh.
                  </li>
                  <li className={classes.list__item}>
                    Option to purchase status of paid Company Group to grant
                    grades/marks or certificates with Creds – value $750.
                  </li>
                </ul>

                <h3 className={classes.list_heading}>
                  Outreacher Level – $1,000 annually, value $3,750
                </h3>
                <ul className={[classes.list, "mb30"].join(" ")}>
                  <li className={classes.list__item}>
                    Newsletter – up to five (5) product tiles that you create in
                    Knowstory will have an announcement with a link-line listing
                    in Knowstory News and Learning Counsel newsletters. 313,000
                    educators – value $1,000.
                  </li>
                  <li className={classes.list__item}>
                    Newsletter –same as above for up to five company events with
                    link-line posting in both newsletters. 313,000 educators –
                    value $1,000.
                  </li>
                  <li className={classes.list__item}>
                    Click-through and Knowstory market tracking lead generation
                    estimated at 50 leads.
                  </li>
                  <li className={classes.list__item}>
                    Includes paid Company Group status to grant grades/marks or
                    certificates with Creds – useful for your own professional
                    development and badges for your event attendance – value
                    $750.
                  </li>
                  <li className={classes.list__item}>
                    Paid level includes the “uberization” of meetings set up
                    when sponsors make live-intersection Courses with their
                    papers and resources for visiting potential customers. This
                    function is part of the forthcoming Hybrid Logistics Project
                    to automate the live intersection of teacher-plus-student(s)
                    upon reaching learning steps marked as Live -- and can also
                    be used by companies.
                  </li>
                </ul>

                <h3 className={classes.list_heading}>
                  Influencer Level - $7,000, value $10,250 with leads guarantee
                  at 250+
                </h3>
                <ul className={classes.list}>
                  <li className={classes.list__item}>
                    One EduJedi podcast on any product with posting to both
                    Knowstory and Learning Counsel newsletters with lead
                    driving-- value $5500. Guaranteed 250 leads.
                  </li>
                  <li className={classes.list__item}>
                    Part of national PR and marketing campaign with estimated 5
                    million PR views, valued at $1,000 for linked logo. Story
                    releases to AP wire include a custom info-on-sponsors page
                    on Knowstory (doesn’t require log-in to view) that will have
                    sponsor’s logo and link-through to your Group page with your
                    product list. New users to Knowstory will also see sponsor
                    thank-you ad after sign-up. Limit 30 companies.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className={classes.section}>
          <div className="container">
            <div className="full-width">
              <h3 className={classes.section__heading}>
                Make your Institution or Company story and digital mix known!
              </h3>
              <div className="f jcc full-width">
                <Button
                  color={"primary"}
                  variant={"contained"}
                  component={Link}
                  to={routes.CONTACT_FORM}
                  style={{ width: 210 }}
                >
                  Contact us
                </Button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default PromoPage
