import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import withScroll from "../../../hocs/withScroll/withScroll"
import getErrorText from "../../../library/constants/errorTypes"
import { PRODUCTS } from "../../../library/store/actions/types/productsTypes"
import { call } from "../../../library/networking/API"
import ListModal, {
  ListModalContentContainer,
} from "../../ui/modals/listModal/listModal"
import ProductsPickerListItem from "./productsPickerListItem"

class ProductsPicker extends Component {
  state = {
    list: [],
    name: "",
    canAdd: this.props.relatedProducts.length < this.props.limit,
    initialRender: true,
    dataReceived: false,
    listEndReached: false,
  }

  async componentDidMount() {
    await this.getList()
    this.props.setScrollContainer(this.scrollComponent)
    console.log(this.props.relatedProducts)
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.relatedProducts !== prevProps.relatedProducts) {
      this.setState({
        canAdd: this.props.relatedProducts.length < this.props.limit,
      })
    }
  }

  getList = async (offset = 0, limit = 15) => {
    const { name } = this.state

    this.setState({ dataReceived: false })

    try {
      const response = await call(PRODUCTS.SEARCH, {
        token: this.props.auth.userData.token,
        name: name || undefined,
        content_types: this.props.contentTypes,
        offset,
        limit,
      })
      const list = await response.data.products
      this.setState({
        list: offset > 0 ? [...this.state.list, ...list] : list,
        initialRender: false,
        dataReceived: true,
        listEndReached: !list.length || list.length < limit,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  handleChange = (event) => {
    this.setState({ name: event.target.value })
  }

  processSearch = (event) => {
    event.preventDefault()

    this.getList()
  }

  onAddProduct = (product) => {
    try {
      this.props.addProduct(product)

      if (this.props.limit === 1) {
        this.props.handleClose()
      }
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  onDeleteProduct = async (product_id) => {
    try {
      this.props.deleteProduct(product_id)
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  onScroll = async () => {
    if (!this.state.dataReceived || this.state.listEndReached) return
    await this.getList(this.state.list.length)
  }

  render() {
    const { list, name, canAdd, dataReceived, initialRender } = this.state

    return (
      <ListModal
        title={this.props.contentTypes[0] === 15 ? "KnowMix" : "Products"}
        list={list}
        name={name}
        handleNameChange={this.handleChange}
        placeholder="Type product name"
        onSearch={this.processSearch}
        onScroll={this.onScroll}
        dataReceived={dataReceived}
        initialRender={initialRender}
        ListContent={
          <ListModalContentContainer
            id="products-list"
            ref={(ref) => (this.scrollComponent = ref)}
          >
            <div className="products-list">
              {list.map((item) => (
                <ProductsPickerListItem
                  product={item}
                  addProduct={this.onAddProduct}
                  deleteProduct={this.onDeleteProduct}
                  relatedProducts={this.props.relatedProducts.map((p) => p.id)}
                  canAdd={canAdd}
                  key={item.id}
                />
              ))}
            </div>
          </ListModalContentContainer>
        }
        open={this.props.open}
        handleClose={this.props.handleClose}
      />
    )
  }
}

const mapStateToProps = ({auth}) => ({auth});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withScroll(ProductsPicker)));
