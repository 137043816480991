const defaultDate = '1900-01-01T00:00:00.000000Z'
export const defaultNumberDate = -2208988800000
export const defaultMaxDate = '2222-01-01T00:00:00.000000Z'
export const defaultNumberMaxDate = 7952342400000

export const MIN_DATE_ISO = '1900-01-01T00:00:00.000000Z'
export const MIN_DATE = new Date('1900-01-01T00:00:00.000000Z')
export const MAX_DATE_ISO = '2222-01-01T00:00:00.000000Z'
export const MAX_DATE = new Date('2222-01-01T00:00:00.000000Z')

export default defaultDate
