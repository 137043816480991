import React, { Component } from "react"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import Button from "@material-ui/core/Button/Button"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import Select from "@material-ui/core/Select/Select"
import {
  productEnhancements,
  productFileTypes,
  productIntegrationComplianceTypes,
  productPlatforms,
} from "../../../../../library/constants/productDictionary"
import { bindActionCreators } from "redux"
import {
  getProduct,
  updateProduct,
} from "../../../../../library/store/actions/creators/productsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { getObjectById } from "../../../../../library/utils/arrays"
import getErrorText from "../../../../../library/constants/errorTypes"

class ProductSettingsAdditionalInfo extends Component {
  state = {
    topic: this.props.products.currentProduct.topic,
    fileType: this.props.products.currentProduct.file_types,
    platforms: this.props.products.currentProduct.platforms,
    integration: this.props.products.currentProduct.integration,
    enhancements: this.props.products.currentProduct.enhancements,
    teks: this.props.products.currentProduct.teks_standards,
    standards: this.props.products.currentProduct.standards,
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    })
  }

  processInfoSave = async () => {
    const {
      products: {
        currentProduct: { id },
      },
    } = this.props
    const { topic, enhancements, integration, platforms, fileType, teks } =
      this.state
    try {
      await this.props.updateProduct({
        id,
        topic,
        enhancements,
        integration: integration !== 0 ? parseInt(integration) : undefined,
        platforms,
        file_types: fileType,
        teks_standards: teks,
      })
      await this.props.enqueueSnackbar("Information updated", {
        variant: "success",
      })
      await this.props.getProduct({ id })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  render() {
    const { topic, fileType, platforms, integration, enhancements } = this.state

    return (
      <div className="box">
        <div className="box__heading">Additional information</div>
        <div className="box__content">
          <ValidatorForm className="form" onSubmit={this.processInfoSave}>
            <div className="form__fieldset">
              <label htmlFor="topic" className="form__label">
                Topic
              </label>
              <div className="form__fieldbox">
                <div className="form__input">
                  <TextValidator
                    id="topic"
                    name="topic"
                    value={topic}
                    placeholder="Type topic of product"
                    className="edit-form__input"
                    margin="normal"
                    fullWidth
                    validators={["maxStringLength:300"]}
                    errorMessages={["Max length is 300 characters"]}
                    onChange={this.handleChange("topic")}
                  />
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor="file-type" className="form__label">
                File type
              </label>
              <div className="form__fieldbox">
                <div className="form__input form__input--select">
                  <Select
                    id="file-type"
                    name="fileType"
                    // margin="dense"
                    fullWidth
                    multiple
                    displayEmpty
                    value={fileType}
                    onChange={this.handleChange("fileType")}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <span className="color-black-38">
                            Select file type
                          </span>
                        )
                      }

                      let selectedNames = selected.map((item) => {
                        return getObjectById(productFileTypes, item).name
                      })

                      return selectedNames.join(", ")
                    }}
                  >
                    {productFileTypes.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor="platform" className="form__label">
                Platform
              </label>
              <div className="form__fieldbox">
                <div className="form__input form__input--select">
                  <Select
                    id="platform"
                    name="platform"
                    // margin="dense"
                    fullWidth
                    multiple
                    displayEmpty
                    value={platforms}
                    onChange={this.handleChange("platforms")}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <span className="color-black-38">
                            Select platform
                          </span>
                        )
                      }

                      let selectedNames = selected.map((item) => {
                        return getObjectById(productPlatforms, item).name
                      })

                      return selectedNames.join(", ")
                    }}
                  >
                    {productPlatforms.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor="integration" className="form__label">
                Integration
              </label>
              <div className="form__fieldbox">
                <div className="form__input form__input--select">
                  <Select
                    id="integration"
                    name="integration"
                    // margin="dense"
                    fullWidth
                    displayEmpty
                    value={integration}
                    onChange={this.handleChange("integration")}
                    renderValue={(value) => {
                      if (value.length === 0 || value === 0) {
                        return (
                          <span className="color-black-38">
                            Select integration compliance
                          </span>
                        )
                      }

                      return getObjectById(
                        productIntegrationComplianceTypes,
                        value
                      ).name
                    }}
                  >
                    {productIntegrationComplianceTypes.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            <div className="form__fieldset">
              <label htmlFor="enhancements" className="form__label">
                Enhancements
              </label>
              <div className="form__fieldbox">
                <div className="form__input form__input--select">
                  <Select
                    id="enhancements"
                    name="enhancements"
                    // margin="dense"
                    fullWidth
                    multiple
                    displayEmpty
                    value={enhancements}
                    onChange={this.handleChange("enhancements")}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <span className="color-black-38">
                            Select enhancements
                          </span>
                        )
                      }

                      let selectedNames = selected.map((item) => {
                        return getObjectById(productEnhancements, item).name
                      })

                      return selectedNames.join(", ")
                    }}
                  >
                    {productEnhancements.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            <Button
              color="primary"
              variant="contained"
              className="form__submit"
              type="submit"
            >
              Save
            </Button>
          </ValidatorForm>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, products }) => ({ auth, products })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getProduct, updateProduct }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ProductSettingsAdditionalInfo))
