import React from "react"
import { getTeacherKnowmapKnowmixKnowmeets } from "../../../../../library/store/actions/creators/knowmapCreators"
import { useDispatch, useSelector } from "react-redux"
import KnowmeetModalsProvider, {
  useKnowmeetModals,
} from "../../../../../hooks/entities/useKnowmeetModals"
import { Route, useHistory } from "react-router-dom"
import {
  KNOWMAP_INCOMING_KNOWMEET,
  KNOWMAP_UKNOW_KNOWMIX,
} from "../../../../../library/constants/routes"
import DeleteForeverOutlined from "@material-ui/icons/DeleteForeverOutlined"
import withErrorBoundary from "../../../../../hocs/guards/withErrorBoundary"
import UknowDialog from "../../../knowmix/knowmixSingle/knowmixSettings/knowmixSettingsKnowmeets/uknowDialog/uknowDialog"
import DataTable from "../../../../../components/v2/dataTable/components"
import BoxPage from "../../../../../components/v2/layout/boxPage/boxPage"

const columns = [
  { label: "Name", role: "name" },
  { label: "Target date/time", width: 120 },
  { label: "Cohort/\nArrivals", width: 80 },
  { label: "Members/\nComplete", width: 80 },
  { label: "Step" },
  { label: "Manage", width: 100, role: "actions" },
]

const TeacherKnowmixKnowmeetTable = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { teacherKnowmixKnowmeet } = useSelector(({ knowmap }) => knowmap)

  const { onFormOpen, onInfoOpen, onDeletionOpen } = useKnowmeetModals()

  return (
    <>
      <BoxPage title={"Courses Managed Meets"}>
        <DataTable
          columns={columns}
          data={teacherKnowmixKnowmeet.list}
          getData={async ({ offset, limit, name }) => {
            await dispatch(
              getTeacherKnowmapKnowmixKnowmeets({
                name,
                limit,
                offset,
              })
            )
          }}
          onDetailsOpen={(record) => onInfoOpen(record.id, "step")}
          onEditOpen={(record) => onFormOpen(record.id, "step")}
          availableOffsets={teacherKnowmixKnowmeet.availableOffsets}
          total={teacherKnowmixKnowmeet.total}
          isSearchable
          actionsPinned
          isFirstColumnPinned
          customColumnsWidth
          actions={(record) => [
            {
              type: "button",
              onClick: () => onDeletionOpen(record.id, "step"),
              label: () => (
                <>
                  <DeleteForeverOutlined />
                  <span>Delete</span>
                </>
              ),
            },
          ]}
          notFoundText={"You don't have managed Course Meets yet"}
        />
      </BoxPage>

      <Route
        path={KNOWMAP_INCOMING_KNOWMEET}
        render={() => (
          <UknowDialog
            open
            handleClose={(replace = false) => {
              replace
                ? history.replace(KNOWMAP_UKNOW_KNOWMIX)
                : history.push(KNOWMAP_UKNOW_KNOWMIX)
            }}
            type={"step"}
          />
        )}
      />
    </>
  )
}

const KnowmapTeacherKnowmixKnowmeets = () => {
  const dispatch = useDispatch()

  const getRecords = async ({ name, offset, limit } = {}) => {
    await dispatch(
      getTeacherKnowmapKnowmixKnowmeets({
        name,
        // knowmix_id: knowmixId || parseInt(k),
        limit,
        offset,
      })
    )
  }
  return (
    <KnowmeetModalsProvider updateList={getRecords}>
      <TeacherKnowmixKnowmeetTable />
    </KnowmeetModalsProvider>
  )
}

export default withErrorBoundary(KnowmapTeacherKnowmixKnowmeets)
