import React from "react"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import { useIdParam } from "../../../../../../../hooks/useIdParam"
import { GROUPS } from "../../../../../../../library/store/actions/types/groupsTypes"
import UserPicker from "../../../../../../../components/v2/inputs/pickers/userPicker"
import { useSelector } from "react-redux"
import { useAlert } from "../../../../../../../hooks/useAlert"
import { nestAPI } from "../../../../../../../library/networking/API"

const GroupCreatorTransferModal = ({ open, onClose }) => {
  const { errorAlert, successAlert } = useAlert()
  const groupId = useIdParam("groupId")
  const { userData } = useSelector(({ auth }) => auth)
  const [selectedUser, setSelectedUser] = React.useState(null)

  const onSubmit = async () => {
    try {
      await fetch(nestAPI + `groups/${groupId}/transfer-creator-role`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData.token}`,
        },
        body: JSON.stringify({
          user_id: selectedUser.id,
        }),
      })
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle className={"text-center"}>
        Choose group for subscription
      </DialogTitle>
      <DialogContent>
        <div className="f jcc">
          <div className="form form--modal">
            <UserPicker
              value={selectedUser}
              onChange={setSelectedUser}
              requestConfig={{
                fetchConfig: {
                  apiUrl: GROUPS.PARTICIPANTS.LIST,
                  params: {
                    group_id: groupId,
                    role: 2,
                    tags: [],
                  },
                },
                key: "users",
              }}
              fieldName={"users"}
              userLabel={"New group creator"}
              getDisabledValue={(user) => user.id === userData.id}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color={"primary"}>
          Cancel
        </Button>
        <Button onClick={onSubmit} color={"primary"} disabled={!selectedUser}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default GroupCreatorTransferModal
