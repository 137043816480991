import React from "react"
import { generatePath, Link } from "react-router-dom"
import userAvatarDefault from "../../../../../assets/img/user_avatar.svg"
import VerticalMenu from "../../../../../components/ui/verticalMenu/verticalMenu"
import MoreDropdown from "../../../../../components/ui/dropdowns/moreDropdown/moreDropdown"
import Button from "@material-ui/core/Button/Button"

const InvenstoryUserCard = (props) => {
  const {
    user,
    hasMenu,
    hasButton,
    buttonType,
    buttonText,
    buttonHandler,
    dropdownMenuItems,
  } = props
  if (user && !user.logo && user.logo !== "") {
    const { id, first_name, last_name, photo, role, city, country, ...rest } =
      user
    const photoUrl = photo !== "" ? photo : userAvatarDefault
    const fullName = `${first_name} ${last_name}`
    const profileUrl = generatePath("/users/:userId", { userId: id })
    return (
      <div className="box list-item">
        <div className="f aic full-width">
          <Link to={profileUrl} className="list-item__img">
            <img src={photoUrl} alt={fullName} />
          </Link>
          <div className="list-item__info">
            <Link to={profileUrl} className="link list-item__name">
              {fullName}
            </Link>
            {/*<span className="list-item__description">{userRolesIds[role].name}</span>*/}

            {!!rest.education && (
              <span>
                {rest.education && rest.education.name
                  ? rest.education.name
                  : ""}
                {rest.education &&
                rest.education.name &&
                rest.education.department
                  ? ", "
                  : ""}
                {rest.education && rest.education.department
                  ? rest.education.department
                  : ""}
              </span>
            )}

            {!!rest.work && (
              <span>
                {rest.work && rest.work.name ? rest.work.name : ""}
                {rest.work && rest.work.name && rest.work.role ? ", " : ""}
                {rest.work && rest.work.role ? rest.work.role : ""}
              </span>
            )}
            <span>
              {city ? city : ""}
              {city && country ? ", " : ""}
              {country ? country : ""}
            </span>
          </div>
        </div>
        <div className={`list-item__control-panel`}>
          {hasMenu && (
            <MoreDropdown>
              <VerticalMenu
                menuItems={dropdownMenuItems}
                classes={"more-dropdown-menu__list"}
              />
            </MoreDropdown>
          )}
          {hasButton && (
            <Button
              variant={buttonType}
              onClick={buttonHandler}
              color="primary"
            >
              {buttonText}
            </Button>
          )}
        </div>
      </div>
    )
  }
  return null
}

export default InvenstoryUserCard;
