import React from "react"
import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../../../../library/constants/routes"
import { howToArticles } from "../../../../../info/howTo/howToArticles"

const bannerTexts = [
  "How to get started",
  "How to build a project-based mini-course",
]

const ProfileHowToBanner = () => {
  const [activeBannerText, setActiveBannerText] = React.useState(0)
  React.useEffect(() => {
    const bannerTextInterval = setInterval(() => {
      setActiveBannerText((prev) => (prev + 1) % bannerTexts.length)
    }, 7000)

    return () => clearInterval(bannerTextInterval)
  }, [])

  return (
    <Link
      to={generatePath(routes.HOW_TO, {
        articleId: howToArticles[0].slug,
      })}
      className={"w-full text-primary text-center p-3 rounded-2 bg-primary15"}
    >
      {bannerTexts[activeBannerText]}
    </Link>
  )
}

export default ProfileHowToBanner
