import React from "react"
import SuperSelect from "../select/superSelect"
import { getLocalePeriod } from "../../../../library/utils/timeString/timeString"
import { Chip } from "@material-ui/core"
import { HtmlTooltip } from "../../../../screens/main/calendar/components/eventsCalendar/components/calendarUI"
import { colors } from "../../../../library/constants/styles/colors"
import InfoIcon from "@material-ui/icons/Info"
import { weekDays } from "../../../../library/utils/timeConvert"

const HoldsPicker = ({
  value,
  onChange,
  hasError = false,
  errorMessage,
  required = true,
  multiple = false,
  fieldName = "holds",
  requestConfig = {},
  emptyListMessage,
  isWaitingForOtherFields = false,
  isWaitingForOtherFieldsMessage = "First select group",
}) => {
  return (
    <SuperSelect
      value={value}
      onChange={onChange}
      // onChange={console.log}
      requestConfig={requestConfig}
      fieldName={fieldName}
      entityName={multiple ? "holds" : "hold"}
      required={required}
      multiple={multiple}
      hasError={hasError}
      errorMessage={errorMessage}
      emptyListMessage={emptyListMessage}
      isWaitingForOtherFields={isWaitingForOtherFields}
      isWaitingForOtherFieldsMessage={"First select group"}
      getValueHelperText={(hold) =>
        !hold.is_all_day
          ? getLocalePeriod(hold.time_from, hold.time_to)
          : "All day"
      }
      getOptionHelperText={(hold) =>
        !hold.is_all_day
          ? getLocalePeriod(hold.time_from, hold.time_to)
          : "All day"
      }
      withOptionTooltip
      getOptionTooltipText={(option) => option.name || ""}
      renderTags={(v, getTagProps) =>
        v.map((option, index) => (
          <Chip
            label={
              <div className={"f aic"}>
                <HtmlTooltip
                  placement={"bottom-end"}
                  title={
                    <div style={{ color: "inherit", fontSize: 13 }}>
                      <div>
                        <p className={"color-black-54 mt10"}>Time:</p>
                        <p style={{ fontSize: 14 }}>
                          {!option.is_all_day
                            ? `${getLocalePeriod(
                                option.time_from,
                                option.time_to
                              )}`
                            : "All day"}
                        </p>
                      </div>
                      {!!option.user_time_zone && (
                        <div>
                          <p className={"color-black-54 mt10"}>Time zone:</p>
                          <p style={{ fontSize: 14 }}>
                            {option.user_time_zone}
                          </p>
                        </div>
                      )}
                      <div>
                        <p className={"color-black-54 mt10"}>Days:</p>
                        <p style={{ fontSize: 14 }}>
                          {!!option.days_of_week && (
                            <p>
                              Days:{" "}
                              {option.days_of_week
                                .map((d) => weekDays[d])
                                .join(", ")}
                            </p>
                          )}
                        </p>
                      </div>
                    </div>
                  }
                >
                  <InfoIcon
                    style={{
                      marginRight: 5,
                      fontSize: 20,
                      color: colors.primary,
                      cursor: "pointer",
                    }}
                  />
                </HtmlTooltip>
                {option.name}
              </div>
            }
            {...getTagProps({ index })}
          />
        ))
      }
    />
  )
}

export default HoldsPicker
