import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import TableRow from "../tableRow"
import { colors } from "../../../library/constants/styles/colors"
import { devices } from "../../../library/constants/styles/responsive"

const Container = styled.tbody`
  display: flex;
  flex-direction: column;
  width: ${(props) =>
    props.fluid ? "100%" : props.cellsNumber * 115 + 48 + "px"};
  margin-bottom: ${(props) => (props.fluid ? 0 : "20px")};
`

const NoResultsContainer = styled.tr`
position: sticky;
left: 0;
margin-top: 30px;
max-width: 1150px;
`;

const NoResults = styled.td`
display: block;
color: ${colors.black54};
text-align: center;

@media ${devices.xs} {
    text-align: left;
  }
`;

const TableBody = (props) => {
    const [data, setData] = useState(props.data);

    useEffect(() => {
        setData(props.data);
    }, [props.data]);
    return (
        <Container cellsNumber={props.columnsNumber} fluid={props.fluid}>
            {!!data && !!data.length ? data.map(item => {
                return <TableRow
                    recordId={item.id}
                    data={item.info}
                    record={item}
                    editRecord={props.editRecord}
                    deleteRecord={props.deleteRecord}
                    openRecord={props.openRecord}
                    createProduct={props.createProduct}
                    fluid={props.fluid}
                    key={item.id}
                    canEdit={item.can_manage}
                    createBookmark={props.createBookmark}
                    deleteBookmark={props.deleteBookmark}
                    bookmarked={!!item.bookmark}
                />;
            }) : (
                !props.initialRender && <NoResultsContainer>
                    <NoResults cellsNumber={props.columnsNumber}>{props.noResultsLabel}</NoResults>
                </NoResultsContainer>
            )}
        </Container>
    );
};

export default TableBody;
