import React, { useState } from "react"
import * as yup from "yup"
import { Box } from "../../../../../components/tw/layout"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp"
import { Collapse } from "@material-ui/core"
import { useToggle } from "../../../../../hooks/useToggle"
import IconButton from "@material-ui/core/IconButton"
import DeleteForeverOutlined from "@material-ui/icons/DeleteForeverOutlined"
import { Formik } from "formik"
import Form from "../../../../../components/v2/layout/forms/form/form"
import TimeDurationPicker from "../../../../../components/formComponents/timeDurationPicker"
import { useCourse } from "../../../../../hooks/data/courses/courses"
import StandardPicker from "../../../../../components/v2/inputs/pickers/standardPicker"
import { useSelector } from "react-redux"
import {
  useCourseSteps,
  useCourseStepsMutations,
} from "../../../../../hooks/data/courses/steps"
import { isLast } from "../../../../../library/utils/arrays"
import AddOutlined from "@material-ui/icons/AddOutlined"
import EditOutlined from "@material-ui/icons/EditOutlined"
import { omit } from "lodash"
import { useAlert } from "../../../../../hooks/useAlert"
import QuestionForm from "./questionForm/QuestionForm"
import TrueFalseQuestion from "./questionInfo/TrueFalseQuestion"
import MultipleChoiceQuestion from "./questionInfo/MultipleChoiceQuestion"
import TextAnswerQuestion from "./questionInfo/TextAnswerQuestion"
import FillBlankQuestion from "./questionInfo/FillBlankQuestion"
import MatchingQuestion from "./questionInfo/MatchingQuestion"
import UnitInfo from "./UnitInfo"
import { PlainButton } from "../../../../../components/v2/controls"
import {
  useCourseMeetByStep,
  useCourseMeetMutations,
  useCourseMeets,
} from "../../../../../hooks/data/meets/courseMeets"
import InfoTable from "../../../../../components/v2/utils/infoTable"
import { safeFormatDate } from "../../../../../library/utils/dateConvert"
import StepMeetFormModal from "./StepMeetFormModal"
import MeetFormModal from "../../../calendar/components/meetFormModal/MeetFormModal"
import { minutesToString } from "../../../../../library/utils/timeConvert"
import AlertDialog from "../../../../../components/ui/alertDialog"

const validationSchema = yup.object({
  name: yup.string().required().min(3).max(200),
  description: yup.string().max(5000),
  links: yup.array().of(yup.string().url().required()),
  time: yup.number().min(0).required(),
  location: yup.string(),
})

const CourseStepForm = ({
  step = null,
  stepIdx,
  onCreated,
  unit = null,
  meetsListQuery,
}) => {
  const { successAlert } = useAlert()

  const { course } = useCourse()
  const { queryKey } = useCourseSteps()

  const { createCourseStep, updateCourseStep, deleteCourseStep } =
    useCourseStepsMutations({ queryKey })
  const { userData } = useSelector((state) => state.auth)

  const [isStepOpen, toggleStep] = useToggle(!step)

  const initialValues = {
    name: step ? step?.name : "",
    description: step ? step?.description : "",
    links: step ? step?.links : [],
    time: step ? step?.time : 0,
    location: step ? step?.location : "",
  }

  const [otherValues, setOtherValues] = React.useState({
    standards: step ? step?.standards : [],
    attachments: step ? step?.blobs : [],
  })

  const [questions, setQuestions] = React.useState(
    step
      ? step.questions.map((q) =>
          omit(q, ["user_answer_options", "user_answer_text"])
        )
      : []
  )
  const [isQuestionOpen, toggleQuestion] = useToggle(false)

  const [activeQuestion, setActiveQuestion] = useState({
    question: undefined,
    index: undefined,
  })

  const composeQuestionTemplate = (question, index) => {
    const getTemplate = () => {
      switch (question.type) {
        case 1:
          return <TrueFalseQuestion question={question} index={index} />

        case 2:
          return (
            <MultipleChoiceQuestion question={question} questionIdx={index} />
          )

        case 3:
          return <TextAnswerQuestion question={question} index={index} />

        case 4:
          return <FillBlankQuestion question={question} index={index} />

        case 5:
          return <MatchingQuestion question={question} index={index} />

        default:
          return null
      }
    }

    return (
      <>
        {getTemplate()}
        {!isLast(questions, index) && <hr className="border-divider my-4" />}
      </>
    )
  }

  const saveQuestion = ({ index, question }) => {
    const questionsUpdated =
      index !== undefined
        ? questions.map((item, i) => {
            return i === index ? question : item
          })
        : [...questions, question]

    setQuestions(questionsUpdated)
    setActiveQuestion({ question: undefined, index: undefined })
  }

  const deleteQuestion = (index) => {
    const updatedQuestions = questions.filter((item, i) => i !== index)
    setQuestions(updatedQuestions)
  }

  const onQuestionFormOpen = (type, question, index) => {
    if (type === "edit") {
      setActiveQuestion({ question, index })
    } else {
      setActiveQuestion({ question: undefined, index: undefined })
    }

    toggleQuestion()
  }

  const onSubmit = async (values) => {
    const data = {
      id: step ? step.id : undefined,
      knowmix_id: !step?.id ? course.id : undefined,
      name: values.name,
      description: values.description,
      links: values.links,
      time: values.time,
      location: values.location,
      standards: otherValues.standards.map((s) => s.id),
      attachments: otherValues.attachments,
      questions,
    }

    if (!step) {
      await createCourseStep.mutateAsync(data)

      if (typeof onCreated === "function") {
        onCreated()
      }
    } else {
      await updateCourseStep.mutateAsync(data)
      successAlert("Step updated")
    }
  }

  const meet = useCourseMeetByStep({ step_id: step.id })
  const [meetFormOpen, toggleMeetForm] = useToggle(false)
  const [meetDeletionOpen, toggleMeetDeletion] = useToggle(false)
  const [meetDetailsOpen, toggleMeetDetails] = useToggle(false)

  const { queryKey: meetsListQueryKey } = useCourseMeets()
  const { deleteCourseMeet } = useCourseMeetMutations(meetsListQueryKey)

  return (
    <Box className={"p-0 mb-4 last:mb-0"}>
      <div
        role={"button"}
        onClick={toggleStep}
        className="grid grid-cols-[1fr,_max-content] gap-8 items-center p-4 cursor-pointer hover:bg-primary15"
      >
        <p className={"line-clamp-1 text-lg"}>
          Step {stepIdx + 1}
          {!!step?.name && !step?.unit && ` - ${step?.name}`}
        </p>
        <div>
          {!!step?.id && (
            <IconButton
              size={"small"}
              onClick={() => {
                deleteCourseStep.mutate({ id: step.id })
              }}
            >
              <DeleteForeverOutlined />
            </IconButton>
          )}

          {!isStepOpen ? (
            <KeyboardArrowDown className="text-black38" />
          ) : (
            <KeyboardArrowUp className="text-black38" />
          )}
        </div>
      </div>
      <Collapse in={isStepOpen}>
        <div className={"p-4"}>
          {!!unit && <UnitInfo unit={unit} />}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(fk) => (
              <Form>
                <Form.Row name={"name"} label={"Step name"}>
                  <Form.TextField
                    name={"name"}
                    placeholder={"Type step name"}
                  />
                </Form.Row>

                <Form.Row name={"description"} label={"Instructions"}>
                  <Form.TextField
                    name={"description"}
                    placeholder={"Type step instructions"}
                    multiline
                  />
                </Form.Row>

                <Form.Row name={"links"} label={"Links"}>
                  <Form.InputsArray name={"links"} placeholder={"Type URL"} />
                </Form.Row>

                <TimeDurationPicker
                  label={"Estimated time"}
                  time={fk.values.time}
                  onChange={(t) => fk.setFieldValue("time", t)}
                />

                <StandardPicker
                  value={otherValues.standards}
                  onChange={(v) =>
                    setOtherValues((prevState) => ({
                      ...prevState,
                      standards: v,
                    }))
                  }
                />

                {!!otherValues.attachments?.length && (
                  <Form.Row label={"Attachments"} name={"attachments"}>
                    <Form.Attachments
                      token={userData.token}
                      attachments={otherValues.attachments}
                      setAttachments={({ blobs }) =>
                        setOtherValues((prevState) => ({
                          ...prevState,
                          attachments: blobs,
                        }))
                      }
                    />
                  </Form.Row>
                )}
                <div className="form__fieldset mb40">
                  <span className="form__label !text-black87">Questions</span>

                  <div className="form__fieldbox">
                    <div className="form__input-group">
                      {!questions.length && (
                        <button
                          type="button"
                          className="form__add-input"
                          onClick={() => onQuestionFormOpen("create")}
                        >
                          <AddOutlined /> Add
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                {questions.map((item, index) => (
                  <div
                    className="question-container relative"
                    key={item.id || item.title + index}
                  >
                    {composeQuestionTemplate(item, index)}
                    <div className="absolute right-0 top-0 flex">
                      <button
                        type="button"
                        className="btn btn--plain mr10"
                        onClick={() => onQuestionFormOpen("edit", item, index)}
                      >
                        <EditOutlined />
                      </button>
                      <button
                        className="btn btn--plain mr10"
                        type="button"
                        onClick={() => deleteQuestion(index)}
                      >
                        <DeleteForeverOutlined />
                      </button>
                    </div>
                  </div>
                ))}

                {!!questions.length && (
                  <div className="form__fieldset">
                    <span className="form__label"></span>

                    <div className="form__fieldbox">
                      <button
                        type="button"
                        className="form__add-input"
                        onClick={() => onQuestionFormOpen("create")}
                      >
                        <AddOutlined /> Add
                      </button>
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Formik>

          {!!step.id && (
            <div className={"mt-8"}>
              <div className="flex items-center gap-2 mb-4">
                <span>Meet:</span>
                {!!meet ? (
                  <div className={"flex items-center"}>
                    {/*<span>{minutesToString(meet?.duration)} added</span>*/}
                    <PlainButton onClick={() => console.log("open")}>
                      {minutesToString(70)} added
                    </PlainButton>
                    <div className={"flex gap-2 ml-8"}>
                      <PlainButton
                        onClick={toggleMeetForm}
                        inline
                        actionType={"edit"}
                      />
                      <PlainButton
                        onClick={toggleMeetDeletion}
                        inline
                        actionType={"delete"}
                      />
                    </div>
                  </div>
                ) : (
                  <PlainButton
                    inline
                    actionType={"add"}
                    onClick={toggleMeetForm}
                  />
                )}
              </div>
            </div>
          )}

          {isQuestionOpen && (
            <QuestionForm
              open={isQuestionOpen}
              handleClose={toggleQuestion}
              saveQuestion={(question) =>
                saveQuestion({
                  question,
                  index: activeQuestion.index,
                })
              }
              question={activeQuestion.question}
            />
          )}

          {meetFormOpen && (
            <StepMeetFormModal
              open={meetFormOpen}
              onClose={toggleMeetForm}
              step={step}
              stepIdx={stepIdx}
              activeMeet={meet?.id}
              // openKnowmeetCreatedDialog={onCreatedKnowmeetDialogOpen}
            />
          )}

          {meetDeletionOpen && (
            <AlertDialog
              open={meetDeletionOpen}
              handleClose={toggleMeetDeletion}
              handleAccept={() => deleteCourseMeet.mutate({ id: meet?.id })}
              title="Delete this Meet?"
              message="If you delete this Meet you will not be able to restore it"
            />
          )}
        </div>
      </Collapse>
    </Box>
  )
}

export default CourseStepForm
