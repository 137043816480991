import React from "react"
import { generatePath, Link } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import Loader from "../../../../components/ui/loader"
import { BoxHeading } from "../../../../components/v2/layout/boxPage/boxPage"
import { useHowTo } from "../useHowTo"

const HowToCategoriesPage = () => {
  const { categoriesQuery } = useHowTo({ categories: true })

  return (
    <main>
      {categoriesQuery.isSuccess ? (
        <>
          <BoxHeading>How To</BoxHeading>
          <div className={"grid grid-cols-4 gap-4 mt-4"}>
            <Link
              to={generatePath(routes.HOW_TO_CATEGORY, {
                categoryId: "all",
              })}
              className={
                "grid grid-rows-subgrid gap-2 row-span-2 rounded-sm bg-white p-3"
              }
            >
              <h3 className={"text-primary font-medium text-lg"}>
                All articles{" "}
                <span className={"text-black54 text-base font-normal"}>
                  ({categoriesQuery?.data?.all_posts_count})
                </span>
              </h3>
            </Link>
            {categoriesQuery?.data?.categories?.map((category) => (
              <Link
                to={generatePath(routes.HOW_TO_CATEGORY, {
                  categoryId: category.id,
                })}
                className={
                  "grid grid-rows-subgrid gap-2 row-span-2 rounded-sm bg-white p-3"
                }
              >
                <h3 className={"text-primary font-medium text-lg"}>
                  {category.name}{" "}
                  <span className={"text-black54 text-base font-normal"}>
                    ({category.count})
                  </span>
                </h3>
                <p className={"line-clamp-3"}>{category.description}</p>
              </Link>
            ))}
          </div>
        </>
      ) : (
        <Loader />
      )}
    </main>
  )
}

export default HowToCategoriesPage
