import { FRIENDS } from "../actions/types/friendsTypes"

const initialState = {
  refreshing: false,
  friendsList: [],
  friendsNumber: null,
  friendsSearch: [],
  friendsSearchNumber: null,
  pendingRequests: [],
  pendingRequestsNumber: null,
  outgoingRequests: [],
  outgoingRequestsNumber: null,
}

export const friendsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FRIENDS.LIST:
            return {
                ...state,
                friendsList: [...state.friendsList, ...action.payload.users],
                friendsNumber: action.payload.friends_number,
            };

        case FRIENDS.LIST_UPDATE:
            return {
                ...state,
                friendsList: action.payload.users,
                friendsNumber: action.payload.friends_number,
            };

        case FRIENDS.SEARCH:
            return {
                ...state,
                friendsSearch: [...state.friendsSearch, ...action.payload.users],
                friendsSearchNumber: action.payload.users_number,
            };

        case FRIENDS.REQUESTS.REFRESHING:
            return {
                ...state,
                refreshing: true,
            };

        case FRIENDS.REQUESTS.LIST:
            return {
                ...state,
                refreshing: false,
                pendingRequests: [...state.pendingRequests, ...action.payload.requesting],
                pendingRequestsNumber: action.payload.requesting_users_number,
                outgoingRequests: [...state.outgoingRequests, ...action.payload.requested],
                outgoingRequestsNumber: action.payload.requested_users_number,
            };

        case FRIENDS.REQUESTS.PENDING:
            return {
                ...state,
                pendingRequests: [...state.pendingRequests, ...action.payload.users],
                pendingRequestsNumber: action.payload.users_number,
            };

        case FRIENDS.REQUESTS.UPDATE_PENDING:
            return {
                ...state,
                pendingRequests: action.payload.users,
                pendingRequestsNumber: action.payload.users_number,
            };

        case FRIENDS.REQUESTS.OUTGOING:
            return {
                ...state,
                outgoingRequests: [...state.outgoingRequests, ...action.payload.users],
                outgoingRequestsNumber: action.payload.users_number,
            };
        case FRIENDS.REQUESTS.UPDATE_OUTGOING:
            return {
                ...state,
                outgoingRequests: action.payload.users,
                outgoingRequestsNumber: action.payload.users_number,
            };
        case FRIENDS.REQUESTS.SEND:
            return {
                ...state,
            };

        case FRIENDS.REQUESTS.CANCEL:
            return {
                ...state,
            };

        case FRIENDS.REQUESTS.ACCEPT:
            return {
                ...state,
            };

        case FRIENDS.REQUESTS.DECLINE:
            return {
                ...state,
            };

        case FRIENDS.DELETE:
            return {
                ...state,
            };

        case FRIENDS.CLEAR:
            return {
                ...state,
                refreshing: false,
                friendsList: [],
                friendsSearch: [],
                pendingRequests: [],
                outgoingRequests: [],
            };

        default:
            return state;
    }
};
