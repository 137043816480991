import React, { Component } from "react"
import Button from "@material-ui/core/Button/Button"
import Popper from "@material-ui/core/Popper/Popper"
import Grow from "@material-ui/core/Grow/Grow"
import Paper from "@material-ui/core/Paper/Paper"
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener"
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import { MenuList, withStyles } from "@material-ui/core"
import { colors } from "../../../../library/constants/styles/colors"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"

const CustomPaper = withStyles({
  elevation2: {
    boxShadow: "0 0 2px 0 rgba(210, 210, 210, 0.5)",
  },
})(Paper)

const CustomMenuItem = withStyles({
  root: {
    color: colors.primary,
  },
})(MenuItem)

class DropdownControl extends Component {
  state = {
    open: false,
  }

  handleToggle = () => {
    this.setState((state) => ({ open: !state.open }))
  }

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return
    }

    this.setState({ open: false })
  }

  render() {
    const { open } = this.state
    const { buttonVariant = "outlined" } = this.props

    return (
      <div className="btn-group">
        <div className="full-width">
          {/*<>*/}
          <Button
            variant={buttonVariant}
            size={this.props.buttonSize || "medium"}
            color="primary"
            ref={(node) => {
              this.anchorEl = node
            }}
            aria-owns={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={this.handleToggle}
            fullWidth
          >
            {this.props.buttonLabel}
            {!this.props.hideIcon &&
              (open ? <KeyboardArrowUp /> : <KeyboardArrowDown />)}
          </Button>
          <Popper
            open={open}
            style={{ zIndex: this.props.popperZIndex || 100 }}
            placement="bottom"
            anchorEl={this.anchorEl}
            transition
            // disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <CustomPaper>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    {this.props.children ? (
                      this.props.children
                    ) : (
                      <MenuList>
                        {this.props.options.map((option) => (
                          <CustomMenuItem
                            onClick={option.onClick}
                            key={option.label}
                          >
                            {option.label}
                          </CustomMenuItem>
                        ))}
                      </MenuList>
                    )}
                  </ClickAwayListener>
                </CustomPaper>
              </Grow>
            )}
          </Popper>
          {/*</>*/}
        </div>
      </div>
    )
  }
}

export default DropdownControl
