import React, { Component } from "react"
import ListSearchHeader from "../../../../../components/ui/listSearchHeader/listSearchHeader"
import List from "../../../../../components/ui/list/list"
import { generatePath, Link } from "react-router-dom"
import userAvatarDefault from "../../../../../assets/img/user_avatar.svg"
import Button from "@material-ui/core/Button/Button"
import { bindActionCreators } from "redux"
import {
  getProduct,
  productAcquisitionAccess,
  productAcquisitionClear,
  productAcquisitionSearch,
  updateProduct,
} from "../../../../../library/store/actions/creators/productsCreators"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import * as qs from "query-string"
import getErrorText from "../../../../../library/constants/errorTypes"
import withScroll from "../../../../../hocs/withScroll/withScroll"
import Loader from "../../../../../components/ui/loader"
import AcquisitionModal from "./acquisitionModal/acquisitionModal"
import * as routes from "../../../../../library/constants/routes"
import { defaultMaxDate } from "../../../../../library/constants/defaultDates"

class ProductSettingsSales extends Component {
  state = {
    list: [],
    initialRender: true,
    dataReceived: false,
  }

  async componentDidMount() {
    const {
      match: { params },
      location: { search },
    } = this.props
    const { q: query } = qs.parse(search)

    try {
      await this.props.productAcquisitionSearch({
        product_id: parseInt(params.productId),
        name: query || undefined,
      })
      this.setState({
        list: this.props.products.acquisitionsSearch,
        initialRender: false,
        dataReceived: true,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      match: { params },
      location: { search },
    } = this.props
    const { q: query } = qs.parse(search)

    if (this.props.location.search !== prevProps.location.search) {
      this.setState({ dataReceived: false })
      try {
        await this.props.productAcquisitionSearch({
          product_id: parseInt(params.productId),
          name: query || undefined,
        })
        this.setState({
          list: this.props.products.acquisitionsSearch,
          dataReceived: true,
        })
      } catch ({ error }) {
        this.props.enqueueSnackbar(getErrorText(error.code), {
          variant: "error",
        })
      }
    }
  }

  componentWillMount() {
    this.props.productAcquisitionClear()
  }

  searchUsers = async (name) => {
    const { history } = this.props
    this.props.productAcquisitionClear()
    history.push({
      search: `?q=${name}`,
    })
  }

  onScroll = async () => {
    if (!this.state.dataReceived) return
    const {
      match: { params },
      location: { search },
    } = this.props
    const { q: query } = qs.parse(search)
    this.setState({
      dataReceived: false,
    })
    try {
      await this.props.productAcquisitionSearch({
        product_id: parseInt(params.productId),
        name: query || undefined,
        // type: this.props.products.currentProduct.creator_user ? 1 : 2,
        offset: this.state.list.length,
      })
      this.setState({
        list: this.props.products.acquisitionsSearch,
        dataReceived: true,
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  processAcquisitionChange = async ({ user, date_from, date_to }) => {
    try {
      await this.props.productAcquisitionAccess({
        product_id: this.props.products.currentProduct.id,
        user_id: user.id,
        // date_from,
        date_to: date_to || defaultMaxDate,
      })
      console.log({
        product_id: this.props.products.currentProduct.id,
        user_id: user.id,
        date_from,
        date_to: date_to || defaultMaxDate,
      })
      this.props.enqueueSnackbar(
        `Now ${user.first_name} has access to this product`,
        { variant: "success" }
      )
    } catch ({ error }) {
      this.props.enqueueSnackbar(error.description, { variant: "error" })
    }
  }

  render() {
    const { list, initialRender, dataReceived } = this.state

    return (
      <>
        <ListSearchHeader
          title="People"
          placeholder="Search by people"
          search={this.searchUsers}
          noResults={list.length === 0 && !initialRender}
        />
        <List>
          {list.map((item) => (
            <ListItemUser
              user={item}
              processAcquisitionChange={this.processAcquisitionChange}
              key={item.id}
            />
          ))}
        </List>
        {!dataReceived && <Loader />}
      </>
    )
  }
}

class ListItemUser extends Component {
  state = {
    acquired: this.props.user.acquired,
    acquisitionModalOpen: false,
  }

  onAcquisitionModalOpen = () => {
    this.setState({ acquisitionModalOpen: true })
  }

  processAcquisitionChange = async ({ date_from, date_to }) => {
    await this.props.processAcquisitionChange({
      user: this.props.user,
      date_from,
      date_to,
    })
    console.log({ user: this.props.user, date_from, date_to })
    this.setState({ acquired: true, acquisitionModalOpen: false })
  }

  render() {
    const { acquired } = this.state
    const { user } = this.props
    const photoUrl = user.photo !== "" ? user.photo : userAvatarDefault
    const fullName = `${user.first_name} ${user.last_name}`
    const profileUrl = generatePath(routes.USER, { userId: user.id })

    return (
      <div className="box list-item">
        <div className="f aic full-width">
          <div className="list-item__img">
            <img src={photoUrl} alt={fullName} />
          </div>
          <div className="list-item__info">
            <Link to={profileUrl} className="link list-item__name">
              {fullName}
            </Link>
            {/*<span className="list-item__description">{userRolesIds[user.role].name}</span>*/}
            <span>
              {user.city ? user.city : ""}
              {user.city && user.country ? ", " : ""}
              {user.country ? user.country : ""}
            </span>
          </div>
        </div>
        {!acquired && (
          <div className="list-item__control-panel">
            <Button
              variant="contained"
              color="primary"
              onClick={this.onAcquisitionModalOpen}
            >
              Open access
            </Button>
          </div>
        )}
        {this.state.acquisitionModalOpen && (
          <AcquisitionModal
            open={this.state.acquisitionModalOpen}
            openAccess={this.processAcquisitionChange}
            handleClose={() => this.setState({ acquisitionModalOpen: false })}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ auth, products }) => ({ auth, products })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getProduct,
      updateProduct,
      productAcquisitionSearch,
      productAcquisitionAccess,
      productAcquisitionClear,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withScroll(ProductSettingsSales)))
